export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'PPA NAME',
    dataField: 'ppaName',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'Settlement Type',
    dataField: 'settlementType',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'Settlement Cycle ',
    dataField: 'settlementCycle',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING_HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'BD_NAME',
    dataField: 'determinantName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'PPA ID',
    dataField: 'ppaId',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING_INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }],
};