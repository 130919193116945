import Vue from 'vue';
import { confirm, alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

import CisoSearchForm from '@/components/Ciso/BaseStore/CisoSearchForm';
import { ObserveVisibility } from 'vue-observe-visibility';
import { useAuth } from './auth/authWrapper';
import router from './router';
import App from './App';
import store from './store';
import Pscs from './components/Shared';
import ElementUi from './elementui';

import './utils/alert';
import './filters';
import './components/index';

Vue.prototype.$auth = await useAuth({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  responseType: 'token id_token',
  scope: 'openid email',
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
  store,
});

Pscs(Vue);
Vue.use(ElementUi);
Vue.prototype.$confirmDx = confirm;
Vue.prototype.$alertDx = alert;
Vue.prototype.$notify = notify;
Vue.prototype.$notifyDx = notify;
Vue.component('TagConfirmMiscInfoDialog', () => import('@/components/Etag/Tag/TagConfirmMiscInfoDialog'));
Vue.component('CisoSearchForm', CisoSearchForm);
Vue.component('Draggable', () => import('vuedraggable'));
Vue.directive('ObserveVisibility', ObserveVisibility);

// eslint-disable-next-line no-new
window.vue = new Vue({
  el: '#app',
  router,
  store,
  data: () => ({
    isNavbarOpen: false,
  }),
  created() {
    window.addEventListener('keydown', this.triggerCLI);
    window.addEventListener('paste', this.clipboard);

    this.$store.dispatch('alert/setCallback', this.notificationCallback);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.triggerCLI);
    window.removeEventListener('paste', this.clipboard);
  },
  methods: {
    // For debugging purposes
    findInstance(name, instance = this) {
      const instanceName = instance?.$options?.name?.toLowerCase();
      if (instanceName === name.toLowerCase()) return instance;

      const { $children } = instance;
      for (let i = 0; i < $children.length; i += 1) {
        const child = $children[i];
        const result = this.findInstance(name, child);
        if (result) return result;
      }
      return undefined;
    },
    notificationCallback(item) {
      this.$notify(item);
    },
    clipboard(event) {
      const content = event.clipboardData.getData('text/plain');
      this.$store.dispatch('clipboard/setClipboard', { ready: true, content });
      // event.preventDefault();
    },
    triggerCLI(event) {
      if (event.keyCode === 192) {
        if (event.ctrlKey) {
          this.$store.dispatch('cli/toggleCLI');
        }
      }
    },
  },
  render: (h) => h(App),
});

window.addEventListener('unhandledrejection', (event) => {
  if (event && event.reason && event.reason.name === 'ChunkLoadError') {
    window.vue
      .$alertDx('The application has been updated. Please try again after the page reloads.', 'Application Update')
      .then(() => window.location.reload(true));
  }
});
