import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'commodityName', label: 'Commodity', sortable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
    style: { height: 500 },
    options: { searchFilter: true },
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    commodityName: null,
    marketName: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getEnergyCommodityList: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ dispatch, commit }) {
    STRUCTURES_API.get('/location-types').then(({ data }) => {
      commit('loadTableData', data.locationTypes);
      this.$notify(`${data.locationTypes.length} Location Types Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Location Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postLocationType({ state }, locationType) {
    STRUCTURES_API.post('/location-types', locationType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Location Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Location Type', 'error');
    });
  },
  updateLocationType({ commit }, locationType) {
    STRUCTURES_API.put(`/location-types/${locationType.id}`, locationType).then(({ data }) => {
      commit('updateLocationType', data);
      this.$notify('Location Type Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Location Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/location-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Location Type Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Location Type', 'error');
    });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateLocationType(state, locationType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === locationType.id);
    state.tableData.splice(rowIndex, 1, locationType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, locationTypes) {
    state.tableData = locationTypes;
  },
  createTableRow(state) {
    const locationType = clone(state.nullTableRow);
    locationType.id = state.tableKey++;
    locationType.versionId = 1;
    locationType.createdBy = this.getters['auth/getProfile'].userName;
    locationType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = locationType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((locationType) => locationType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};