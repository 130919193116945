import moment from 'moment';
import { VA_API } from '@/api';
import Vue from 'vue';
import { createMutations } from '@/utils/vuexHelper';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { saveAs } from 'file-saver';
import { svgToPng } from '@/components/VisualAnalytics/utils';
import VABaseStore from '../VABaseStore';

const state = {
  lastUpdate: '',
  selectedDate: '',
  isCurrentlyFetchingData: false,
  locationData: {},
  selectedLocations: [],
  selectedGroup: null,
  currentLayout: null,
  validLocations: [],
  seriesItems: [],
  legend: [],
  dataPoints: [],
  rtChartsTree: [],
  startTime: null,
  endTime: null,
  isInitialized: false,
  maxResources: 12,
  maxDataPoints: 5,
};

const getters = {
  maxResources: (state) => state.maxResources,
  maxDataPoints: (state) => state.maxDataPoints,
};

const actions = {
  async initialize({
    commit, dispatch, getters, state,
  }) {
    const firstLayoutLabel = '[Default] - EIM Energy';
    const secondLayoutLabel = 'MISO Energy';
    await dispatch('fetchLocations');

    if (Array.isArray(state.VABaseStore.locations) && state.VABaseStore.locations.length > 0) { // customer specific resource/datapoint limit
      if (state.VABaseStore.locations[0].entityName === 'NEIM') {
        commit('setMaxResources', 20);
        commit('setMaxDataPoints', 10);
      }
    }

    await dispatch('fetchLocationGroups');
    await dispatch('fetchLayouts');
    commit('setSelectedGroup', caisoStore.resourceGroupName);
    const { layoutList } = getters;
    const defaultLayout = layoutList.find((layout) => layout.label === firstLayoutLabel)
      || layoutList.find((layout) => layout.label === secondLayoutLabel);
    await dispatch('changeSelectedLayout', defaultLayout.value);
    dispatch('fetchLayout');

    setTimeout(() => {
      let initialSeries = [];
      commit('setCurrentLayout', defaultLayout.value);
      if (defaultLayout?.label === firstLayoutLabel) {
        initialSeries = ['FMM Energy Total', 'Internal Meter', 'BSAP Base Schedule', 'DOT', 'Meter Deviation'];
      } else if (defaultLayout?.label === secondLayoutLabel) {
        initialSeries = ['DA Energy', 'PI Internal Meter', 'RT Dispatch', 'RT Dispatch_Meter Dev'];
      }
      const defaultSeries = state.seriesItems.filter(({ label }) => initialSeries.includes(label));

      commit('setLegend', defaultSeries);
      commit('setDataPoints', defaultSeries.map((series) => series.seriesId));
      commit('setRtChartsTree', state.VABaseStore.chartsTree);
    }, 750);
  },
  async fetchData({
    state, commit, getters, dispatch,
  }) {
    const timeZone = dateStore.getTimeZoneDefinitions().find((x) => x.label === dateStore.getTimeZone()).tz;
    if (!state.legend) return;
    let initialTime = moment().utc().subtract(2, 'hour');
    let finalTime = moment().utc().add(1, 'hour');

    // round to a 5 minute interval
    initialTime = moment(initialTime).add(5 - (initialTime.minutes() % 5), 'minutes');
    finalTime = moment(finalTime).add(5 - (finalTime.minutes() % 5), 'minutes');
    initialTime.second(0).milliseconds(0);
    finalTime.second(0).milliseconds(0);
    // Uncomment for local testing
    // initialTime = initialTime.set('date', 7).set('month', 6).set('year', 2020);
    // finalTime = finalTime.set('date', 7).set('month', 6).set('year', 2020);
    commit('setStartTime', initialTime);
    commit('setEndTime', finalTime);
    const locationData = {};
    const ps = [];
    // eslint-disable-next-line no-async-promise-executor
    const fetchLocationIntervals = (location) => new Promise(async (resolve, reject) => {
      commit('setLastUpdate', moment.tz(moment.utc(), timeZone).format('hh:mm A'));
      // fetch data for location
      const params = {
        startTime: state.startTime.toISOString(),
        endTime: state.endTime.toISOString(),
        timeZone: dateStore.getTimeZone(),
        variants: 'T', // Add for testing: ,T_1,T_3,T_8
        objectReferences: state.legend?.map(({ seriesId }) => seriesId).join(','),
        objectTypes: 'series',
        types: location,
      };

      const { data: { data = [] } } = await VA_API.post('intervals/data', params);
      if (Array.isArray(data)) {
        // append metadata
        const metaData = state.legend.reduce((acc, meta) => {
          acc[meta.seriesName] = meta;
          return acc;
        }, {});

        locationData[location] = { data, metaData };
        resolve();
      } else {
        reject();
      }
    });

    state.validLocations.forEach((location) => {
      ps.push(fetchLocationIntervals(location));
    });

    await Promise.all(ps);
    // commit('setLocationData', locationData);

    dispatch('formatChartData', locationData);
  },
  async fetchLayout({ state, commit, getters }) {
    const { selectedLayout } = getters;
    let seriesItems = selectedLayout?.charts?.map(({ chartSeries }) => chartSeries).flat();
    seriesItems = seriesItems.map((chartSeries) => (
      { ...chartSeries, label: chartSeries.chartSeriesName, value: chartSeries.seriesId }));
    commit('setSeriesItems', seriesItems);
  },
  formatChartData({ state, commit }, locationData) {
    const formattedData = {};
    Object.keys(locationData).forEach((location) => {
      const { data, metaData } = locationData[location];
      let initialTime = state.startTime.clone();
      // chartData intervals instantiated with null series data for dxChart to correctly display missing data
      const times = [];
      while (initialTime.isBefore(state.endTime)) {
        const item = { time: initialTime.clone(), dstFlag: false };
        times.push(item);
        initialTime = initialTime.add(5, 'minutes');
      }
      times.reduce((acc, curr) => {
        const startTime = `${curr.time.toISOString().split('.')[0] }Z`;
        const endTime = `${curr.time.clone().add('5', 'minutes').toISOString().split('.')[0] }Z`;
        const he = curr.time.hours() + 1;
        const ie = curr.time.minutes() + 5;
        const settlementTime = `${(`0${he}`).slice(-2)}:${(`0${ie}`).slice(-2)}`;
        acc[startTime] = {
          location: state.selectedLocation,
          startTime,
          endTime,
          hourTime: curr.time.format('HH:mm'),
          settlementTime,
          he,
          ie,
          date: curr.time.format('L'),
          time: startTime,
          momentTime: curr.time.clone(),
          utcStartTime: startTime,
          utcEndTime: endTime,
        };
        Object.keys(metaData).forEach((name) => { acc[startTime][metaData[name].seriesName] = null; });
        return acc;
      }, {});

      data.forEach((d) => {
        // Charts series with metadata
        const mdt = dateStore.toMoment(d.startTime);
        const interval = {
          location: d.type,
          startTime: d.tzStartTime,
          endTime: d.tzEndTime,
          hourTime: mdt.clone().format('HH:mm'),
          settlementTime: `${(`0${d.he}`).slice(-2)}:${(`0${d.ie}`).slice(-2)}`,
          he: d.he,
          ie: d.ie,
          date: mdt.clone().format('L'),
          time: d.startTime,
          momentTime: mdt.clone(),
          utcStartTime: d.startTime, // keep UTC times as they should be the primary means of matching data across time.
          utcEndTime: d.endTime,
        };
        Object.keys(metaData).forEach((name) => {
          const { multiplier = 1, seriesName } = metaData[name];

          const seriesPoint = d[seriesName];
          if (seriesPoint) {
            if (typeof seriesPoint === 'object') {
              const multipliedValue = multiplier !== 1 ? multiplier * seriesPoint.value : seriesPoint.value;
              interval[name] = multipliedValue;

              // Checks if it has notes and add to data
              if (seriesPoint.notes) interval[`${seriesName}/notes`] = seriesPoint.notes;
            } else {
              interval[name] = seriesPoint;
            }
          } else {
            interval[name] = null;
          }
        });
        times[d.startTime] = interval;
      });
      formattedData[location] = Object.values(times);
    });
    commit('setLocationData', formattedData);
  },
  async setDataPoints({
    state, commit,
  }, value) {
    commit('setDataPoints', value);
    if (value.length > state.maxDataPoints) {
      await setTimeout(() => {
        value.splice(state.maxDataPoints);
        const dataPoints = state.seriesItems.filter((series) => value.includes(series.seriesId));
        commit('setLegend', dataPoints);
      }, 750);
    } else {
      const dataPoints = state.seriesItems.filter((series) => value.includes(series.seriesId));
      commit('setLegend', dataPoints);
    }
  },
  resetDataPoints({ state, commit }) {
    commit('setLegend', []);
    commit('setSeriesItems', []);
    commit('setDataPoints', []);
    commit('setRtChartsTree', state.VABaseStore.chartsTree);
  },
  setSelectedLocations({ commit }, value) {
    commit('setSelectedLocationsValue', value);
    if (value.length > state.maxResources) {
      setTimeout(() => {
        value.splice(state.maxResources);
        commit('setValidLocations', value);
      }, 750);
    } else {
      commit('setValidLocations', value);
    }
  },
  async exportRealtimeToExcel({ dispatch }, context) {
    const chartSeriesIds = state.VABaseStore.selectedLayout.charts
      .map(({ chartSeries }) => chartSeries)
      .flat()
      .map(({ seriesId }) => seriesId)
      .join(',');

    const locations = state.selectedLocations;
    const params = {
      startTime: state.startTime.toISOString(),
      endTime: state.endTime.toISOString(),
      timeZone: dateStore.getTimeZone(),
      variants: 'T', // Add for testing: ,T_1,T_3,T_8
      objectReferences: state.legend?.map(({ seriesId }) => seriesId).join(','),
      objectTypes: 'series',
      types: locations.join(','),
    };

    const { data: { data } } = await VA_API.post('intervals/data', params);

    // Groups locations' data
    let groupedData = {};
    for (let i = 0; i < data.length; i++) {
      const interval = data[i];
      // Flattens series value
      Object.entries(interval).forEach(([key, val]) => {
        if (val && typeof val === 'object' && 'value' in val) {
          interval[key] = val.value;
        }
      });
      if (groupedData[interval.type]) {
        groupedData[interval.type].push(interval);
      } else {
        groupedData[interval.type] = [interval];
      }
    }
    // Alphabetizes the object by key
    groupedData = Object.keys(groupedData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedData[key];
        return acc;
      }, {});
    // Sorts location data by time
    Object.entries(groupedData).forEach(([location, intervals]) => {
      groupedData[location] = intervals.sort((a, b) => {
        if (a.startTime < b.startTime) return -1;
        if (a.startTime > b.startTime) return 1;
        return 0;
      });
    });

    // Query Selector All returns a nodelist so it needs to be converted before use
    let charts = Array.from(document.querySelectorAll('.visual-charts'));
    charts = charts.map((chart) => (
      {
        location: chart.dataset?.location,
        svg: chart.querySelector('.dxc.dxc-chart').outerHTML,
      }));

    const workbook = await dispatch('workbookGenerator', charts);

    // Creates data sheet
    const dataSheet = workbook.addWorksheet('Data', {
      views: [{ state: 'frozen', ySplit: 1, xSplit: 2 }],
    });
    const columns = [{
      header: 'SC', key: 'entityName', width: 12,
    }, {
      header: 'Location', key: 'location', width: 16,
    }, {
      header: 'Date', key: 'tradingDate', width: 12,
    }, {
      header: 'Hour', key: 'tradingHour', width: 6,
    }, {
      header: 'Interval', key: 'tradingInterval', width: 7,
    }];
    const chartsTreeChartSeries = state.VABaseStore.chartsTree
      .filter(({ text }) => text !== 'Charts')
      .reduce((acc, { items, text }) => [...acc, ...items], []);

    chartsTreeChartSeries.forEach((chartSeries) => {
      columns.push({
        header: chartSeries.text, key: chartSeries.seriesName, width: 12,
      });
    });

    dataSheet.columns = columns;
    const header = dataSheet.getRow(1);
    header.font = { bold: true, size: 10, name: 'Arial' };
    header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };

    const locationData = state.VABaseStore.locations
      .filter(({ shortName }) => state.selectedLocations.includes(shortName));

    Object.entries(groupedData).forEach(([location, intervals]) => {
      const locationObj = locationData.find(({ shortName }) => shortName === location);
      intervals.forEach((interval) => {
        interval.entityName = locationObj.entityName;
        interval.location = locationObj.shortName;
        interval.tradingDate = moment(interval.startTime).format('L');
        interval.tradingHour = moment(interval.startTime).hours() + 1;
        interval.tradingInterval = Number(interval.ie) || 0;
        dataSheet.addRow(interval);
      });
    });

    const nowDateString = moment().format('YYYY-MM-DD_HH;mm;ss');
    const fileName = `${nowDateString}_T_${nowDateString}`;

    workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${fileName}.xlsx`);
      });
  },
  async workbookGenerator({ commit }, chartsArray) {
    const ExcelJS = await import('exceljs');
    const wb = new ExcelJS.Workbook();
    wb.creator = 'Power Settlements';
    wb.lastModifiedBy = 'Power Settlements';
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    if (chartsArray.length > 0) {
      const promises = [];

      const chartSheet = wb.addWorksheet('charts');
      let x = 0;
      let y = 0;
      let widthIncrement = 0;
      let heightIncrement = 0;
      let colCount = 0;

      const { length } = chartsArray;
      if (length === 1) {
        widthIncrement = 0;
      } else if (length === 2) {
        widthIncrement = 12;
      } else if (length === 3) {
        widthIncrement = 8;
      } else if (length === 4) {
        widthIncrement = 6;
      } else if (length < 9) {
        widthIncrement = 6;
        heightIncrement = 16;
      } else {
        widthIncrement = 6;
        heightIncrement = 11;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const image of chartsArray) {
        const { svg, location } = image;
        // eslint-disable-next-line no-await-in-loop
        const base64png = svg[0] === '<' ? await svgToPng(svg) : svg;
        const visualAnalysisChart = wb.addImage({ base64: base64png, extension: 'png' });
        const img = new Image();
        img.src = base64png;
        // eslint-disable-next-line no-await-in-loop
        img.onload = await function () { };
        chartSheet.addImage(visualAnalysisChart, {
          tl: { col: x, row: y },
          // br: { width: 6.5, height: 6.5 },
          ext: { width: img.width, height: img.height },
        });
        x += widthIncrement;
        colCount++;
        if (colCount >= 4) {
          y += heightIncrement;
          x = 0;
          colCount = 0;
        }
      }
    }
    return wb;
  },
};

const mutations = {
  setLastUpdate(state, value) {
    state.lastUpdate = value;
  },
  setLegend(state, value) {
    state.legend = value;
  },
  setSeriesItems(state, value) {
    state.seriesItems = value;
  },
  setDataPoints(state, value) {
    state.dataPoints = value;
  },
  setSelectedLocationsValue(state, value) {
    state.selectedLocations = value;
  },
  setValidLocations(state, value) {
    state.validLocations = value;
  },
  setMaxResources(state, value) {
    state.maxResources = value;
  },
  setMaxDataPoints(state, value) {
    state.maxDataPoints = value;
  },
  ...createMutations(
    'isCurrentlyFetchingData',
    'selectedDate',
    'selectedGroup',
    'locationData',
    'rtChartsTree',
    'startTime',
    'endTime',
    'currentLayout',
    'isInitialized',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};