import utils from '@/utils';
import { clone, handleError } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  deleteCache: [],
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'company', label: 'Company', sortable: true,
    }, {
      prop: 'entityType', label: 'Type', sortable: true,
    }, {
      prop: 'firstName', label: 'First Name', sortable: true,
    }, {
      prop: 'lastName', label: 'Last Name', sortable: true,
    }, {
      prop: 'title', label: 'Title', sortable: true,
    }, {
      prop: 'department', label: 'Department', sortable: true,
    }, {
      prop: 'email', label: 'Email', sortable: true,
    }, {
      prop: 'phone', label: 'Phone', sortable: true,
    }, {
      prop: 'mobile', label: 'Mobile', sortable: true,
    }, {
      prop: 'fax', label: 'Fax', sortable: true,
    }, {
      prop: 'assistantName', label: 'Assistant Name', sortable: true,
    }, {
      prop: 'assistantPhone', label: 'Assistant Phone', sortable: true,
    }, {
      prop: 'defaultTradeFlag', label: 'Default Trade?', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, visible: false,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, visible: false, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, visible: false,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, visible: false, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    company: null,
    companyId: null,
    firstName: null,
    lastName: null,
    title: null,
    department: null,
    phone: null,
    mobile: null,
    fax: null,
    email: null,
    assistantName: null,
    assistantPhone: null,
    defaultTradeFlag: false,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getCurrentRow: (state) => state.currentTableRow,
  getCompanyList: (state, localGetters, rootState, rootGetters) => rootGetters['lookup/getCompanyList'],
};

const actions = {
  async initialize({ dispatch, commit }) {
    await dispatch('lookup/fetchCompanyList', null, { root: true });
    const contactList = await dispatch('lookup/fetchContactList', null, { root: true });
    commit('loadTableData', contactList);
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postCompanyContact({ dispatch, state }, company) {
    STRUCTURES_API.post('/company-contacts', company).then(({ data }) => {
      state.tableData.push(data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Company Contact Added', 'success');
    }).catch((error) => {
      handleError(error, 'Failed to Add Company Contact')
    });
},
  updateCompanyContact({ dispatch, commit }, company) {
    STRUCTURES_API.put('/company-contacts', company).then(({ data }) => {
      commit('updateCompanyContact', data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Company Contact Updated', 'success');
    }).catch((error) => {
      handleError(error, 'Failed to Update Company Contact')
    });
},
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/company-contacts/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Company Contact Removed', 'success');
    }).catch((error) => {
      handleError(error, 'Failed to Remove Company Contact')
    });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateCompanyContact(state, companyContact) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === companyContact.id);
    state.tableData.splice(rowIndex, 1, companyContact);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, data) {
    state.tableData = data;
  },
  createTableRow(state) {
    const contact = clone(state.nullTableRow);
    contact.id = state.tableKey++;
    contact.versionId = 1;
    contact.createdBy = this.getters['auth/getProfile'].userName;
    contact.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = contact;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter(({ id }) => id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};