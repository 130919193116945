import { isNull } from '@/utils/dataUtil';

const ValueFormatStore = function () {
  this.numericTypeOptions = [
    { value: 'fixedPoint', label: 'Fixed Point' },
    { value: 'decimal', label: 'Decimal' },
    { value: 'percent', label: 'Percent' },
    { value: 'currency', label: 'Currency' },
  ];
  this.userValueFormatOptions = [];
  this.genericNumericFormats = {
    screen: null,
    fixedPoint: {
      customEnabled: false,
      customFormat: '#.##',
      basicFormat: {
        type: 'fixedPoint',
        precision: 3,
      },
    },
    decimal: {
      customEnabled: false,
      customFormat: '#.##',
      basicFormat: {
        type: 'decimal',
        precision: 2,
      },
    },
    percent: {
      customEnabled: false,
      customFormat: '#.00',
      basicFormat: {
        type: 'percent',
        precision: 2,
      },
    },
    currency: {
      customEnabled: false,
      customFormat: '#,###.##',
      basicFormat: {
        type: 'currency',
        precision: 2,
      },
    },
  };
};

ValueFormatStore.prototype.getFormatByScreen = function (screenName) {
  const nameAsLowerCase = screenName.toLowerCase();
  const formatByScreens = this.userValueFormatOptions.reduce((acc, formats) => {
    acc[formats.screen.toLowerCase().replace(/\s/g, '')] = formats;
    return acc;
  }, {});
  if (formatByScreens[nameAsLowerCase]) return formatByScreens[nameAsLowerCase];
  return this.genericNumericFormats;
};

ValueFormatStore.prototype.getDefaultByScreen = function (screenName) {
  const valueFormat = this.userValueFormatOptions.find(({ screen }) => screen.toLowerCase() === screenName.toLowerCase());
  if (valueFormat) return valueFormat;
  return this.genericNumericFormats;
};

ValueFormatStore.prototype.getDefaults = function () {
  return [{
    screen: '',
    fixedPoint: {
      customEnabled: false,
      customFormat: '#.##',
      basicFormat: {
        type: 'fixedPoint',
        precision: 3,
      },
    },
    decimal: {
      customEnabled: false,
      customFormat: '#.##',
      basicFormat: {
        type: 'decimal',
        precision: 2,
      },
    },
    percent: {
      customEnabled: false,
      customFormat: '#.00',
      basicFormat: {
        type: 'percent',
        precision: 2,
      },
    },
    currency: {
      customEnabled: false,
      customFormat: '#,###.##',
      basicFormat: {
        type: 'currency',
        precision: 2,
      },
    },
  }];
};

ValueFormatStore.prototype.getFormatByConfig = function (config) {
  const [screen, format] = config.split('.');
  const selectedFormat = this.getFormatByScreen(screen)[format];
  return selectedFormat.customEnabled ? selectedFormat.customFormat : selectedFormat.basicFormat;
};

ValueFormatStore.prototype.setDefaults = function (model) {
  this.userValueFormatOptions = isNull(model, this.getDefaults());
};

const valueFormatStore = new ValueFormatStore();
export default valueFormatStore;