import { CISO_METER_API, TOOLS_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { deepClone } from '@/utils/dataUtil';
import configs from './mrisSubmissionConfig';

const state = {
  submitHeaderTableData: [],
  selectedHeader: null,
  submitDataTableData: [],
  submitLogTableData: [],
  submitDiffTableData: [],
  selectedRows: [],
  mrisSubmissionConfig: configs,
};

const actions = {
  async fetchData({ commit }, parameters) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      dateType: parameters.dateTypeSelected,
    };
    try {
      const { data: { data } } = await CISO_METER_API.get('/mris-submission', { params });
      const formattedData = data.map((dataPoint) => ({
        ...dataPoint,
        createdDate: getMomentDateString(dataPoint.createdDate),
        endTradingDate: getMomentDateString(dataPoint.endTradingDate),
        startTradingDate: getMomentDateString(dataPoint.startTradingDate),
      }));
      commit('setTableData', formattedData);
    } catch {
      this.$notify('Failed to Load Data', 'error');
    }
  },
  async postData({ state, commit }) {
    const submitData = { importIds: [] };
    for (let i = 0; i < state.selectedRows.length; i++) {
      submitData.importIds.push(state.selectedRows[i].importId);
    }
    try {
      await TOOLS_API.post('/legacy-process/mri-s-submit', submitData);
      commit('clearData');
      this.$notify('Meter Data Submission triggered', 'success');
    } catch (err) {
      this.$notify('Failed to Initiate submission', 'error');
    }
  },
};

const mutations = {
  setSelectedHeader(state, {
    data, logs, compares,
  }) {
    const formattedData = data?.map((dataPoint) => ({
      ...dataPoint,
      tradingDate: dataPoint.tradingDate.split('T')[0],
    }));
    const indexedLogs = logs.map((log, index) => ({
      ...log,
      rowId: index,
      logTime: log.logTime.split('T')[0],
    }));
    const indexedDiffs = compares.map((comp, index) => ({
      ...comp,
      rowId: index,
      tradingDate: comp.tradingDate.split('T')[0],
    }));
    state.submitDataTableData = formattedData || [];
    state.submitLogTableData = indexedLogs || [];
    state.submitDiffTableData = indexedDiffs || [];
  },
  clearData(state) {
    state.submitHeaderTableData = [];
    state.submitDataTableData = [];
    state.submitLogTableData = [];
    state.submitDiffTableData = [];
    state.mrisSubmissionConfig = deepClone(configs);
  },
  setTableData(state, value) {
    state.submitHeaderTableData = value;
  },
  reset(state) {
    state.submitHeaderTableData = [];
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};