import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_SOURCE_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchPPAReservationData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      ppas: parameters.ppasSelected,
      startDate,
      endDate,
    };
    try {
      const { data: { data } } = await PPA_SOURCE_API.post('/reservation/get', params);
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startTime = moment(obj.startTime).utc().format('YYYY-MM-DD HH:mm:ss');
          obj.stopTime = moment(obj.stopTime).utc().format('YYYY-MM-DD HH:mm:ss');
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Reservation Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};