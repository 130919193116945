import { MODES, USE_PSCS_AG_GRID } from './constants';
import * as tagUtils from '@/utils/etag/tagUtils';
import helpers from '@/utils/helpers';

const state = {
  tagIDList_visibleFlag: false,
  tagIDList_tableConfiguration: {
    columns: [{ prop: 'value', label: 'value', editable: true }],
    options: { showAddRemove: true },
  },
  tagIDList_data: [],
  tagIDList_row: null,
  tagIDList_column: null,
  tagIDList_rowIndex: null,
  tagIDList_readonlyFlag: false,
};

const getters = {
  getTagIDListVisibleFlag: state => state.tagIDList_visibleFlag,
  getTagIDListTableConfiguration(state, getters) {
    const configuration = helpers.cloneDeep(state.tagIDList_tableConfiguration);
    const mode = getters.getMode;
    const compositeState = getters.getCompositeState;
    if (mode === MODES.VIEW_TAG || mode === MODES.VIEW_TEMPLATE) {
      tagUtils.setEditableFalse(configuration, {});
    } else if (compositeState === 'PENDING') {
      tagUtils.setEditableFalse(configuration, {});
    }
    return configuration;
  },
  getTagIDListData(state) {
    return state.tagIDList_data;
  },
};

const actions = {
  changeTagIDListReadonlyFlag({ commit }, value) {
    commit('setTagIDListReadonlyFlag', value);
  },
  changeTagIDList({ commit, state }, item) {
    if (!item.visible) {
      const countProp = state.tagIDList_column.countProp;
      const dataProp = state.tagIDList_column.dataProp;

      if (!state.tagIDList_readonlyFlag) {
        const result = [];

        const dataLength = state.tagIDList_data.length;
        const data = state.tagIDList_data;
        for (let dx = 0; dx < dataLength; dx++) {
          const d = data[dx];
          if (d.value !== '') { result.push(d.value); }
        }

        commit('setTagIDListProperty', {
          rowIndex: state.tagIDList_rowIndex,
          type: state.tagIDList_row.type,
          countProp,
          count: result.length,
          dataProp,
          data: result,
        });
      }
    }

    commit('setTagIDList', item);
  },
  changeTagIDListRowData({ commit }, item) {
    commit('setTagIDListRowData', item);
  },
  rowOperationTagIDList({ commit }, item) {
    if (item.type === 'ADD') {
      commit('addTagIDListRow', { index: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      commit('removeTagIDListRow', { index: item.rowIndex });
    }
  },
  changeMode(context, value) {
    const mode = value;
    const compositeState = context.getters.getCompositeState;
    if ([MODES.NEW_TAG, MODES.NEW_TEMPLATE, MODES.EDIT_TEMPLATE, MODES.VARIANT_TAG].includes(mode)) {
      context.commit('setTagIDListReadonlyFlag', false);
    } else if (mode === MODES.EDIT_TAG) {
      if (compositeState === 'PENDING') {
        context.commit('setTagIDListReadonlyFlag', true);
      } else {
        context.commit('setTagIDListReadonlyFlag', false);
      }
    } else {
      context.commit('setTagIDListReadonlyFlag', true);
    }
  },
};

const mutations = {
  setTagIDList(state, item) {
    state.tagIDList_visibleFlag = item.visible;
    if (state.tagIDList_visibleFlag) {
      if (item.column) {
        const countProp = USE_PSCS_AG_GRID ? item.column.field : item.column.prop;
        const dataProp = `${countProp.substring(0, countProp.indexOf('Count'))}List`;

        state.tagIDList_row = item.row;
        state.tagIDList_column = { dataProp, countProp };
        const count = state.tagIDList_row[countProp];

        const data = state.tagIDList_row[dataProp];
        const result = [];
        if (Array.isArray(data) && data.length > 0) {
          for (let x = 0; x < data.length; x++) {
            result.push({ value: data[x] });
          }
          state.tagIDList_data = result;
        }

        if (!state.tagIDList_readonlyFlag && count.toString() === '0') {
          if (!state.tagIDList_data) { state.tagIDList_data = []; }
          state.tagIDList_data.push({ value: '' });
        }
        state.tagIDList_rowIndex = item.rowIndex;
      }
    } else {
      state.tagIDList_rowIndex = null;
      state.tagIDList_row = null;
      state.tagIDList_column = null;
      state.tagIDList_data = [];
    }
  },
  setTagIDListReadonlyFlag(state, value) {
    state.tagIDList_readonlyFlag = value;
  },
  addTagIDListRow(state, item) {
    const data = [];
    for (let x = 0; x <= item.index; x++) {
      data.push(state.tagIDList_data[x]);
    }
    data.push({ value: '' });
    for (let x = item.index + 1; x < state.tagIDList_data.length; x++) {
      data.push(state.tagIDList_data[x]);
    }
    state.tagIDList_data = data;
  },
  removeTagIDListRow(state, item) {
    const data = [];
    for (let x = 0; x < item.index; x++) {
      data.push(state.tagIDList_data[x]);
    }
    for (let x = item.index + 1; x < state.tagIDList_data.length; x++) {
      data.push(state.tagIDList_data[x]);
    }
    if (data.length === 0) data.push({ value: '' });
    state.tagIDList_data = data;
  },
  setTagIDListRowData(state, item) {
    if (item.value.length <= 50) state.tagIDList_data[item.rowIndex] = { value: item.value };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};