import dateStore from '@/utils/dateStore';
import { has, HEColumns, cloneDeep } from '@/utils/dataUtil';

const PENDING_VALIDATION = 'PENDING_VALIDATION';

const filterTableData = (data, searchType = undefined, valueTypeFlag, splitConfigFlag) => {
  if (!data) return [];
  data.forEach((status) => {
    const { schdType } = status;
    const factor = status.factor || 1;
    let type;
    switch (schdType) {
    case 'base':
      type = valueTypeFlag ? 'marketBaseSchedulePointTotal' : 'systemBaseSchedulePointTotal';
      break;
    case 'sibr':
      type = valueTypeFlag ? 'marketSelfScheduleTotal' : 'systemSelfScheduleTotal';
      break;
    case 'ist':
      type = 'quantity';
      break;
    default:
    }

    if (type) {
      status.valueTypeFlag = valueTypeFlag;
      let total = 0;
      Object.keys(status).forEach((key) => {
        if (key.includes('he')) {
          if (status[key] && status[key][type]) {
            status[key][type] = Math.abs(status[key][type]) * factor;
            total += status[key][type];
            // written to override factor logic in PscsStatusCellContent.vue lines 109-110
            if (status.resourceType === 'LOAD' || status.resourceType === 'ETIE') status[key][type] *= factor;
          }
        }
      });
      status.total = total.toFixed(2);
    }
  });

  data = data.filter((o) => {
    if (searchType === 'ALL') return o;
    if (searchType === 'SAVED') return o.variant === 'ACTUAL' || o.variant === PENDING_VALIDATION;
    if (searchType === 'PENDING') return o.variant === PENDING_VALIDATION;
    if (searchType === 'NOTSCHEDULED') return o.variant === 'NOT_EXISTS';
    return false;
  });

  data = splitConfigFlag
    ? data.filter(({ isConfig, isResource }) => isConfig || !isResource)
    : data.filter(({ isConfig, isResource }) => !isConfig || isResource);

  return data;
};

export const systemStatusColors = {
  IN_PROGRESS: {
    backgroundColor: '#f7e967',
  },
  ERRORED: {
    backgroundColor: '#f1433f',
  },
  // SUBMITTED: {
  //   backgroundColor: '#0380fc',
  // },
  // RETRIEVED: {
  //   backgroundColor: '#a9cf54',
  // },
};

const scheduleVariantColors = {
  PROPOSED: {
    backgroundColor: '#f7e967',
  },
  NOT_READY: {
    backgroundColor: '#9d9d9d',
  },
  NOT_EXISTS: {
    backgroundColor: '#9d9d9d',
  },
  PENDING_VALIDATION: {
    backgroundColor: '#f7e967',
  },
  VALIDATING: {
    backgroundColor: '#f7e967',
  },
  FAILED_VALIDATION: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  INVALID: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  BIDS: {
    backgroundColor: '#a9cf54',
  },
  READY: {
    backgroundColor: '#0380fc',
  },
  SENT: {
    backgroundColor: '#ffa500',
  },
  ACTUAL: {
    backgroundColor: '#a9cf54',
  },
  IN_PROGRESS: {
    backgroundColor: '#f7e967',
  },
  ERRORED: {
    backgroundColor: '#f1433f',
  },
  REJECTED: {
    backgroundColor: '#f1433f',
  },
  CANCELED: {
    backgroundColor: '#9d9d9d',
  },
  CANCELLING: {
    backgroundColor: '#f7e967',
  },
  SUBMITTED: {
    backgroundColor: '#0380fc',
  },
  SUBMITTING: {
    backgroundColor: '#f7e967',
  },
  RETRIEVING: {
    backgroundColor: '#f7e967',
  },
  ACCEPTED: {
    backgroundColor: '#48dd48',
  },
};

const sumData = (opt) => {
  let val = 0;
  const curr = opt.value?.quantity
  || opt.value?.marketBaseSchedulePointTotal
  || opt.value?.systemBaseSchedulePointTotal
  || opt.value?.marketSelfScheduleTotal
  || opt.value?.systemSelfScheduleTotal;
  if (curr) val = curr;
  if (opt.value?.resourceType === 'LOAD') val *= -1;

  if (opt.summaryProcess === 'start') {
    opt.totalValue = 0;
  } else if (opt.summaryProcess === 'calculate') {
    opt.totalValue += val;
  } else if (opt.summaryProcess === 'finalize') opt.totalValue = opt.totalValue.toFixed(2);

  return opt;
};

const generateTableHourConfig = (date = dateStore.getDefaultDate().toISOString()) => ({
  columns: [{
    prop: 'row', label: ' ', fixed: 'left', width: 70, cssClass: 'header-column',
  },
  ...HEColumns({}, true, date),
  ],
});

const generateConfig = (date = dateStore.getDefaultDate().toISOString()) => ({
  columns: [
    {
      prop: 'sc',
      label: 'SC',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '50px',
    }, {
      prop: 'schdType',
      label: 'SCHD Type',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '100px',
      calculateDisplayValue: (e) => e.schdType ? e.schdType.toUpperCase() : '',
    }, {
      prop: 'product',
      label: 'PRD',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '50px',
    }, {
      prop: 'resource',
      label: 'Location',
      fixed: 'left',
      textAlign: 'left',
      sortable: true,
      filterable: true,
      cssClass: 'link',
    }, {
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      label: 'Status',
      prop: 'scheduleStatus',
      editorOptions: scheduleVariantColors,
      class: true,
      filterable: true,
      sortable: true,
    }, {
      prop: 'resourceType',
      label: 'Type',
      fixed: 'left',
      sortable: true,
      filterable: true,
    }, {
      prop: 'marketType',
      label: 'MT',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '50px',
    }, {
      prop: 'direction',
      label: 'B/S',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '50px',
    }, {
      prop: 'counterParty',
      label: 'CP',
      fixed: 'left',
      sortable: true,
      filterable: true,
      width: '50px',
    },
    ...HEColumns({
      cellTemplate: 'PscsStatusCellContentNew',
      editorOptions: {
        systemStatusColors,
        energyField: 'dynamic',
      },
    }, true, date),
    {
      prop: 'total',
      label: 'total',
      fixed: 'right',
      sortable: true,
    }],
  summary: [
    ...HEColumns({ alignment: 'left', summaryType: 'custom', label: '{0}' }, false, date),
    { prop: 'total', alignment: 'center', summaryType: 'sum' },
  ],
  customSummary: sumData,
  options: {
    filterHeader: true,
    multipleSelection: true,
  },
  isRowSelected: (row) => {
    if ((row?.scheduleName || has(row, 'rowSelected')) && (!row.isConfig && row.variant === 'ACTUAL')) {
      return true;
    }
    return false;
  },
});

const mapCbSchedule = (schedule) => schedule.map((cb, index) => {
  // sum values from bid schedules
  cb.bidSchedules?.forEach((bid) => {
    let volume = 0;
    let price = 0;
    const qty = Object.keys(bid).filter((key) => key.includes('qty'));
    qty.forEach((qtyKey) => {
      const prcKey = qtyKey.replace('qty', 'prc');
      if (bid[prcKey]) {
        price += (bid[qtyKey] * bid[prcKey]);
        volume += bid[qtyKey];
      }
    });
    const status = cb.statuses.find((status) => status.startTime === bid.startTime);
    status.cbVolume = volume;
    status.cbPrice = price;
  });
  const product = cb.virtualBidType?.split('_')[1];
  return {
    schdType: cb.schdType,
    scheduleName: cb.scheduleName,
    bsc: null,
    sc: cb.sc,
    resource: cb.resource,
    resourceType: cb.resourceType,
    configuration: null,
    configurations: [],
    configurationList: null,
    pmin: null,
    pmax: null,
    marketType: null,
    date: cb.date,
    variant: cb.variant,
    isResource: false,
    isConfig: false,
    scheduleStatus: cb.scheduleStatus,
    statuses: cb.statuses || [],
    product,
    factor: product === 'SUPPLY' ? -1 : 1,
  };
});

const flattenStatuses = (schedules) => schedules.map((summary) => {
  const flatStatuses = summary.statuses.reduce((acc, status) => {
    const key = `he${(`0${status.hour}`).slice(-2)}`;
    if (!acc[key]) {
      acc[key] = { ...status, resourceType: summary.resourceType, configuration: null };
    } else {
      if (status.marketSelfScheduleTotal) acc[key].marketSelfScheduleTotal += status.marketSelfScheduleTotal;
      if (status.systemSelfScheduleTotal) acc[key].systemSelfScheduleTotal += status.systemSelfScheduleTotal;
      if (status.marketBaseSchedulePointTotal) {
        acc[key].marketBaseSchedulePointTotal += status.marketBaseSchedulePointTotal;
      }
      if (status.systemBaseSchedulePointTotal) {
        acc[key].systemBaseSchedulePointTotal += status.systemBaseSchedulePointTotal;
      }
      acc[key].hasSystemBidSchedules = status.hasSystemBidSchedules || acc[key].hasSystemBidSchedules;
      acc[key].hasMarketBidSchedules = status.hasMarketBidSchedules || acc[key].hasMarketBidSchedules;
      // acc[key].cbVolume += status.cbVolume;
      // acc[key].cbPrice += status.cbPrice;

      if (!acc[key].errorMessage && status.errorMessage) {
        acc[key].errorMessage = status.errorMessage;
      } else if (acc[key].errorMessage && status.errorMessage) {
        acc[key].errorMessage += `\n${status.errorMessage}`;
      }

      if (!acc[key].systemStatus && status.systemStatus) {
        acc[key].systemStatus = status.systemStatus;
      }
      if (!acc[key].submittedDate && status.submittedDate) {
        acc[key].submittedDate = status.submittedDate;
      }
      if ((!acc[key].marketStatus && status.marketStatus)
              || (acc[key].marketStatus && status.marketStatus && status.marketStatus.toLowerCase().includes('i'))) {
        acc[key].marketStatus = status.marketStatus;
      }
    }
    return acc;
  }, {});
  return { ...summary, ...flatStatuses };
});

const highlightHours = (data, date) => {
  const types = [
    { type: 'systemSelfScheduleTotal', typeActual: 'marketSelfScheduleTotal' },
    { type: 'systemBidSegmentCount', typeActual: 'marketBidSegmentCount' },
    { type: 'systemBidSegmentString', typeActual: 'marketBidSegmentString' },
    { type: 'systemBaseSchedulePointTotal', typeActual: 'marketBaseSchedulePointTotal' },
  ];

  if (Array.isArray(data)) {
    const columns = HEColumns({}, true, date);
    data.forEach((summary) => {
      summary.highlightRow = true;
      summary.highlight = [];
      columns.forEach(({ prop }) => {
        const val = summary[prop];
        types.forEach((type) => {
          if (val && ((has(val, [type.type, type.typeActual]) && val[type.type] !== val[type.typeActual])
          || val.bidScheduleMismatch)) {
            summary.highlight.push(prop);
          }
        });
      });
    });
  }
  return data;
};

const formatSchedule = (schedules, date, schdType) => {
  let formatted = cloneDeep(schedules);
  if (schdType === 'base' || schdType === 'sibr') {
    formatted = formatted.reduce((acc, sum) => {
      const factor = ((sum.resourceType === 'LOAD' || sum.resourceType === 'ETIE')) ? -1 : 1;
      sum.factor = factor;
      sum.isResource = !!sum.configurations.length;
      sum.isConfig = false;
      acc.push(sum);
      sum.configurations.forEach((config) => {
        // Declare newSum to prevent the statuses from the base object getting overridden
        const newSum = cloneDeep(sum);
        newSum.resource = config;
        newSum.configuration = config;
        newSum.statuses = sum.statuses.filter(({ configuration }) => configuration === config);
        newSum.configurations = [];
        newSum.isConfig = true;
        newSum.isResource = false;
        acc.push(newSum);
      });
      return acc;
    }, []);
  }
  if (schdType === 'virtual') {
    formatted = mapCbSchedule(formatted);
  }
  formatted = flattenStatuses(formatted);
  return highlightHours(formatted, date);
};

export default {
  filterTableData,
  generateTableHourConfig,
  generateConfig,
  formatSchedule,
};