export const defaultTable1Config = {
  tableName: 'invoicesTable',
  columns: [{
    label: 'Payment ID',
    prop: 'id',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Company',
    prop: 'companyName',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Counter Party',
    prop: 'counterParty',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Payment Date',
    prop: 'invoiceDate',
    format: 'yyyy-MM-dd',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Due Date',
    prop: 'dueDate',
    format: 'yyyy-MM-dd',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Start Date',
    prop: 'startDate',
    format: 'yyyy-MM-dd',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'End Date',
    prop: 'endDate',
    format: 'yyyy-MM-dd',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Direction',
    prop: 'direction',
    sortable: false,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Amount',
    prop: 'totalAmount',
    sortable: true,
    filterable: true,
    dataType: 'number',
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'Payment Amount',
    prop: 'currentPaidAmount',
    sortable: true,
    filterable: true,
    dataType: 'number',
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'Complete',
    prop: 'paid',
    dataType: 'boolean',
    sortable: true,
    filterable: true,
  }, {
    label: 'Created By',
    prop: 'createdBy',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Created Date',
    prop: 'createdDate',
    sortable: true,
    filterable: true,
    alignment: 'left',
  // }, {
  //   label: 'Updated By',
  //   prop: 'updatdBy',
  //   sortable: true,
  //   filterable: true,
  //   alignment: 'left',
  // }, {
  //   label: 'Updated Date',
  //   prop: 'updatedDate',
  //   sortable: true,
  //   filterable: true,
  //   alignment: 'left',
  }],
  summary: [{
    prop: 'totalAmount', alignment: 'right', summaryType: 'sum', label: 'Total Amount: {0}', format: { type: 'currency', precision: 2 },
  }, {
    prop: 'currentPaidAmount', alignment: 'right', summaryType: 'sum', label: 'Total Payment: {0}', format: { type: 'currency', precision: 2 },
  }],
options: {
    filterRow: true,
    filterHeader: true,
    multipleSelection: false,
  },
};

export const defaultTable2Config = {
  tableName: 'invoiceLineItemsTable',
  columns: [{
      label: 'ID',
      prop: 'id',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Payment ID',
      prop: 'invoiceId',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Transaction ID',
      prop: 'objectReference',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Start Time',
      prop: 'startTime',
      sortable: true,
      filterable: true,
      format: 'yyyy-MM-dd',
      alignment: 'left',
    }, {
      label: 'Type',
      prop: 'type',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Commodity',
      prop: 'subType',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Amount',
      prop: 'value',
      sortable: true,
      filterable: true,
      dataType: 'number',
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    }, {
      label: 'Created By',
      prop: 'createdBy',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }, {
      label: 'Created Date',
      prop: 'createdDate',
      sortable: true,
      filterable: true,
      alignment: 'left',
    }],
    summary: [{
      prop: 'value', alignment: 'right', summaryType: 'sum', label: 'Total: {0}', format: { type: 'currency', precision: 2 },
    }],
  options: {
    filterRow: true,
    filterHeader: true,
    multipleSelection: false,
    summaryRows: true,
  },
};
