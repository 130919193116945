import { RECS_MANAGEMENT_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  selectedSystemNames: null,
  selectedEntityNames: [],
  systemNameOptions: ['WREGIS'],
  
  certificateQtyData: [],
  certificateEligibilityData: [],
  scs: [],
  
  certificateTableData: [],
  certificateConfig: {
    name: 'recAccountCertificates',
    columns: [
      { prop: 'account.name', label: 'Account', sortable: true, filterable: true, groupIndex: 0, },
      { prop: 'systemName', label: 'System Name', sortable: true, filterable: true, },
      { prop: 'account.shortId', label: 'Account Id', sortable: true, filterable: true, },
      { prop: 'account.accountType', label: 'Account Type', sortable: true, filterable: true, },
      { prop: 'account.status', label: 'Account Status', sortable: true, filterable: true, },
      { prop: 'certificateType', label: 'CertificateType', sortable: true, filterable: true, },
      { prop: 'vintageDate', label: 'VintageDate', sortable: true, filterable: true, },
      { prop: 'serialNumberBase', label: 'Serial# Base', sortable: true, filterable: true, },
      { prop: 'serialNumberStart', label: 'Serial# Start', sortable: true, filterable: true, },
      { prop: 'serialNumberEnd', label: 'Serial# End', sortable: true, filterable: true, },
      { prop: 'quantity', label: 'Quantity', sortable: true, filterable: true, },
      { prop: 'eligibility', label: 'Eligibility', sortable: true, filterable: true, },
      { prop: 'status', label: 'Status', sortable: true, filterable: true, },
      { prop: 'rrcQuantity', label: 'Rrc Qty', sortable: true, filterable: true, },
      { prop: 'hasEtagQuantities', label: 'Has Etag Qtys', sortable: true, filterable: true, },
      { prop: 'fuelType.name', label: 'Fuel Type', sortable: true, filterable: true, },
      { prop: 'generator.name', label: 'Generator', sortable: true, filterable: true, },
      { prop: 'generatorFuel.fuelSource.description', label: 'Fuel Source', sortable: true, filterable: true, visible: false, },
      { prop: 'organization.name', label: 'Organization', sortable: true, filterable: true, visible: false, },
      { prop: 'importSource', label: 'Import Source', sortable: true, filterable: true, visible: false, },
    ],
    groupSummary: [
      {
        prop: 'quantity',
        summaryType: 'custom',
        dataType: 'string',
        showInGroupFooter: false,
        showInColumn: 'account.name',
      }
    ],
    customSummary: sumData,
    options: {
      columnConfig: true,
      filterHeader: true,
      groupPanel: true,
    },
  },
};

function sumData(opt) {
  if (opt.summaryProcess === 'start') {
    opt.totalSum = 0;
    opt.totalCount = 0;
  }
  else if (opt.summaryProcess === 'calculate') {
    opt.totalSum += opt.value.quantity;
    opt.totalCount++;
  }
  else if (opt.summaryProcess === 'finalize') {
    opt.totalValue = 'Count: ' + opt.totalCount + ', Quantity Total: ' + opt.totalSum;
  }
}

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async initialize({ commit, dispatch }) {
    commit('resetTable');
    await dispatch('fetchScs');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit}) {
    commit('resetTable');
    await dispatch('loadCertificateData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'COMPANY'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async loadCertificateData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map(async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName
      };
      await RECS_MANAGEMENT_API.get('/recs-management/certificate/certificateQuantities', { params }).then(({ data }) => {
        commit('addCertificateQtyData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Certificate Qtys', 'error');
      });
      await RECS_MANAGEMENT_API.get('/recs-management/certificate/certificateEligibilities', { params }).then(({ data }) => {
        commit('addCertificateEligibilityData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Eligibilities', 'error');
      });
    }));

    this.$notify(`${state.certificateQtyData.length} Certificate Qtys Loaded`, 'info');
    commit('setCertificateTableData');
  },
};

const mutations = {
  resetTable(state) {
    state.certificateTableData = [];
    state.certificateQtyData = [];
  },
  setSelectedSystems(state, value) {
    state.selectedSystemNames = value;
  },
  setSelectedEntities(state, value) {
    state.selectedEntityNames = value;
  },
  setCertificateTableData(state) {
    const certificateData = state.certificateQtyData.map(x => {
      // removes unnecessary properties
      delete x.transactionDetail;
      return x;
    }).reduce((acc, item) => {
      // maps to a structure of [{ certificate: { ...certificateProps }, qtys: [{ ...qtyProps }] }]
      const {...certQty} = item
      const { certificate } = certQty;
      const elig = state.certificateEligibilityData.find(x => x.certificate.id === certificate.id);
      certificate.eligibility = elig ? elig.eligibility.shortName : null;
      delete certQty.certificate;
      const existingItem = acc.find(x => x.certificate.id === certificate.id);
      if (existingItem) {
        existingItem.qtys.push(certQty);
      } else {
        acc.push({ certificate, qtys: [certQty] });
      }
      return acc;
    }, []);

    state.certificateTableData = certificateData.reduce((acc, item) => {
      // flattens certificateData into a list of certificates and their qtys to match table config
      const { certificate, qtys } = item;
      const certificateRow = qtys.map(qty => {
        return ({ ...certificate, ...qty });
      });;
      acc.push(...certificateRow);
      return acc;
    }, []);
  },
  addCertificateEligibilityData(state, certificateEligibilities) {
    state.certificateEligibilityData = state.certificateEligibilityData.concat(certificateEligibilities);
  },
  addCertificateQtyData(state, certificateQtys) {
    state.certificateQtyData = state.certificateQtyData.concat(certificateQtys);
  },
  ...createMutations('scs'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};