import { CISO_SAMC_API } from '@/api';
import configs from './tagSettlementsMRTUVsCMRIConfig';

function flattenModel(model) {
  const flatArray = [];
  if (model && model.data && model.data.length > 0) {
    model.data.forEach((m) => {
      const data = m.data || [];
      data.forEach((d) => {
        flatArray.push({ ...m, ...d, data: null });
      });
    });
  }
  return flatArray;
}

const state = {
  tableData: [],
  tagConfig: configs,
  tagPivot: configs.fields,
  tradeDate: null,
};

const actions = {
  async fetchTagSettlementsMRTUVsCMRIData({ commit }, parameters = {}) {
    commit('setTableData', []);
    commit('setTradeDate', parameters.tradeDateSelected);
    const params = {
      tradingDate: parameters.tradeDateSelected,
      SCs: parameters.coordinatorListSelected,
    };
    try {
      const { data } = await CISO_SAMC_API.get('/tag-settlements/vs-cmri-mrtu', {
        headers: { 'api-version': '2.0' },
        params,
      });
      if (data) {
        commit('setTableData', flattenModel(data));
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Tag Settlements MRTU Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTradeDate', null);
    commit('setTableData', []);
  },
};

const mutations = {
  setTradeDate(state, value) {
    state.tradeDate = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tagPivot = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};