const createData = function(){
    var dat = [];
    for (var i = 0; i < 20; i++){
        dat[i] = { p1: i, p2: i+1, p3: i+2};
    }
    return dat
}

const state = {
    canResize: true,
    showLeft: true,
    showRight: true,
    showTop: true,
    showBottom: true,
    selectTableConfig: {
        columns:[
            {
                prop: 'p1',
                editable: true,
            },
            { 
                prop: 'p2', 
                editable: true,
            },
            {
                prop: 'p3', 
                editable: true,
            }
        ],
    },

    selectTableData: createData()
}

const getters = {

}

const actions = {
    setCanResize({commit}, value){
        commit('canResizeMutation', value);
    },
    toggleLeftPanel({commit}){
        commit('showLeftMutation');
    },
    toggleRightPanel({commit}){
        commit('showRightMutation');
    },
    toggleTopPanel({commit}){
        commit('showTopMutation');
    },
    toggleBottomPanel({commit}){
        commit('showBottomMutation');
    }
}

const mutations = {
    canResizeMutation(state, value) {
        state.canResize = value;
    },
    showLeftMutation(state){
        state.showLeft = !state.showLeft;
    },
    showRightMutation(state){
        state.showRight = !state.showRight;
    },
    showTopMutation(state){
        state.showTop = !state.showTop;
    },
    showBottomMutation(state){
        state.showBottom = !state.showBottom;
    }
}

export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}