import {
  gridConfig,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  configData: {
    fields: [
      {
        caption: 'SCID',
        dataField: 'scId',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BA',
        dataField: 'ba',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'CHARGE CODE',
        dataField: 'chargeCode',
        dataType: '',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'TRADING DATE',
        dataField: 'tradingDate',
        dataType: 'datetime',
        area: 'row',
        format: 'yyyy-MM-dd',
        expanded: true,
      },
      {
        caption: 'SUB COMPANY',
        dataField: 'subCompany',
        dataType: '',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'RSRC ID',
        dataField: 'rsrcId',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'RSRC TYPE',
        dataField: 'rsrcType',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING HOUR',
        dataField: 'tradingHour',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING INTERVAL',
        dataField: 'tradingInterval',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TAG CODE',
        dataField: 'tagCode',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'ALLOCATED AMOUNT',
        dataField: 'allocatedAmt',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'ALLOCATED QUANTITY',
        dataField: 'allocatedQty',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'ALLOCATEABLE AMOUNT',
        dataField: 'allocateableAmt',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'ALLOCATEABLE QUANTITY',
        dataField: 'allocateableQty',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'COMP ALLOCATED AMOUNT',
        dataField: 'compAllocatedAmt',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'COMP ALLOCATED QUANTITY',
        dataField: 'compAllocatedQty',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'COMP ALLOCATEABLE AMOUNT',
        dataField: 'compAllocateableAmt',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'COMP ALLOCATEABLE QUANTITY',
        dataField: 'compAllocateableQty',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
      {
        caption: 'VARIANCE ALLOCATED AMOUNT',
        dataField: 'varianceAllocatedAmt',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: gridConfig.valFormat2,
        expanded: false,
      },
    ],
  },
};