<template>
  <div class="ciso-search-form" :class="{ docked: dockConfig.visible }">
    <slot />
    <span
      v-for="[prop, {
        clearable,
        dependsOn,
        disabled,
        divider,
        fileId,
        grouped,
        headerValidation,
        allowedFileExtensions,
        collection,
        referenceId,
        maxFileSize,
        unzip,
        enableTemplate,
        hint,
        label,
        maxLimit,
        minSearchLength,
        options,
        dataKeys,
        placeholder,
        readOnly,
        maxHeight,
        height,
        required,
        searchable,
        searchExpr,
        showControls,
        showDropDownButton,
        showClearButton,
        type,
        customComponent,
        parseType,
        value,
        visible,
      }] in Object.entries(config)"
      v-show="isVisible(visible)"
      :key="prop"
      :class="type">
      <pscs-field-dx
        :label="type !== 'button' ? label : ''">
        <component
          :is="type !== 'custom' ? defaultComponentNames[type] : customComponent"
          :ref="prop"
          :clearable="!!clearable"
          :data-keys="dataKeys"
          :disabled="disabled"
          :file-id="fileId"
          :grouped="!!grouped"
          :header-validation="headerValidation"
          :allowed-file-extensions="allowedFileExtensions"
          :collection="collection"
          :reference-id="referenceId"
          :max-file-size="maxFileSize"
          :unzip="unzip"
          :hint="hint"
          :enable-template="enableTemplate"
          :min-search-length="minSearchLength"
          :options="params[prop]"
          :placeholder="placeholder"
          :read-only="readOnly"
          :height="height"
          :max-height="maxHeight"
          :required="required"
          :searchable="!!minSearchLength || !!searchable"
          :search-expr="searchExpr"
          :show-drop-down-button="(minSearchLength) ? false : true"
          :show-clear-button="showClearButton"
          :show-controls="showControls"
          :parse-type="parseType"
          :max-limit="maxLimit"
          :text="type !== 'checkbox' ? label : ''"
          :type="type === 'number' ? 'number' : undefined"
          :value-range="params[`${prop}Selected`]"
          :value="params[`${prop}Selected`]"
          @fileUploaded="handleFileUpload"
          @changed="selectedValueChanged({ prop, $event, config })"
          @clicked="clicked(config[prop])" />
      </pscs-field-dx>
      <hr v-if="divider">
    </span>
    <div v-if="hasDock" class="footer-buttons">
      <pscs-button-dx
        v-if="dockConfig.visible"
        text="Undock"
        @clicked="dockClicked" />
      <pscs-drop-down-button-dx
        v-else
        ref="dockButton"
        text="Dock"
        :data-source="['LEFT', 'RIGHT'].map((name) => ({ name, id: name }))"
        @buttonClicked="$refs.dockButton.instance().toggle()"
        @itemClicked="dockClicked" />
      <pscs-button-dx
        text="Search"
        type="default"
        @clicked="search" />
    </div>
  </div>
</template>

<script>
/*
  Requires a config with each field having metadata.
  Each field must have a label, value, and type.
  Add a divider after a field by adding a divider: true.
*/
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import { clone, has } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import dateListenerMixin from '@/utils/mixins/dateListenerMixins';
import userStore from '@/utils/userStore';
import { hasPermission } from '@/utils/authUtils';

const {
  mapActions, mapGetters, mapState, mapMutations,
} = createNamespacedHelpers('ciso');

export default {
  name: 'CisoSearchForm',
  mixins: [dateListenerMixin],
  props: {
    dockConfig: {
      type: Object,
      default: () => ({}),
    },
    config: {
      type: Object,
      required: true,
      validator(config) {
        Object.entries(config).forEach(([prop, field]) => {
          const hasProps = has(field, 'label') && has(field, 'value') && has(field, 'type');
          if (!hasProps) {
            console.error(`${prop}: Missing required properties (e.g. label, value, or type)`);
            return false;
          }
          const possibleFields = ['switch', 'checkbox', 'date', 'dateRange', 'input', 'textarea',
            'number', 'text', 'radio', 'select', 'tagbox', 'button', 'xlsxUpload', 'fileUpload', 'custom'];
          if (!possibleFields.includes(field.type)) {
            console.error(`${prop}: Type has to be either ${possibleFields.join(', ')}`);
            return false;
          }
          if ((field.type === 'select' || field.type === 'tagbox' || field.type === 'radio')
            && !has(field, 'options')) {
            console.error(`${prop}: Select, Tag box, or Radio type is missing options property`);
            return false;
          }
          if ((field.type === 'custom') && !has(field, 'customComponent')) {
            console.error(`${prop}: Custom type is missing customComponent property`);
            return false;
          }
          return true;
        });
        return true;
      },
    },
  },
  data: () => ({
    defaultComponentNames: {
      checkbox: 'pscs-checkbox-dx',
      date: 'pscs-date-dx',
      dateRange: 'pscs-date-range',
      input: 'pscs-input-dx',
      number: 'pscs-input-dx',
      text: 'pscs-input-dx',
      textarea: 'pscs-text-area-dx',
      radio: 'pscs-radio-group-dx',
      select: 'pscs-select-dx',
      tagbox: 'pscs-tag-box-dx',
      button: 'pscs-button-dx',
      switch: 'pscs-switch-dx',
      xlsxUpload: 'pscs-xlsx-upload-dx',
      fileUpload: 'pscs-file-upload-dx',
    },
  }),
  watch: {
    dockConfig() {
      Object.entries(this.config).forEach(async ([key, val], index) => {
        if (has(val, 'dockListener') && typeof val.dockListener === 'function') {
          val.dockListener(this.params);
        }
      });
    },
  },
  computed: {
    hasDock() {
      return Object.keys(this.dockConfig).length > 0;
    },
    ...mapGetters({ params: 'getParams' }),
  },
  beforeDestroy() {
    if (JSON.stringify(window.cisoSearchFetchCache) !== '{}') window.cisoSearchFetchCache = {};
  },
  async beforeMount() {
    window.cisoSearchFetchCache = {};
    // This will create the state components required for all of the current screen's search criteria
    Object.entries(this.config).forEach(([key, val], index) => {
      // Initializes params
      this.$store.commit('ciso/setParams', [`${key}Selected`, null]);
      if (['select', 'tagbox', 'radio'].includes(val.type)) {
        this.$store.commit('ciso/setParams', [key, val.options || null]);
        this.$store.commit('ciso/setParams', [`${key}Unfiltered`, val.options || null]);
      }

      // configure watchers now that the state objects are created
      if (has(val, 'watchers')) {
        val.watchers.forEach(({ propertyToWatch, handler, watchOptions }) => {
          const handlerCopy = handler;
          handler = function (...args) {
            handlerCopy.call(this, ...args);
            this.$emit('forceUpdate');
          };
          this.$watch(`params.${propertyToWatch}`, handler, watchOptions || null);
        });
      }
      // Check if the user has docked the settings.
      if (this.hasDock && userStore.getDock() !== 'Undocked') {
        const payload = {
          visible: true,
          alignment: userStore.dock,
        };
        this.dockConfig.visible = true;
        this.$emit('setSearchDocked', payload);
        this.$emit('close');
      } else {
        const payload = {
          visible: false,
          alignment: 'Left',
        };
        this.dockConfig.visible = false;
        this.$emit('setSearchDocked', payload);
        this.$emit('close');
      }
    });
    Object.entries(this.config).forEach(async ([key, val], index) => {
      if (has(val, 'fetchData')) {
        let hasPerm = true;
        if (has(val, 'permission')) {
          hasPerm = hasPermission(this.$store.getters['auth/getPermissions'], val.permission.name, val.permission.right);
        }
        if (hasPerm) {
          // this conditional is added to resolve duplicate fetch calls due to requirement of needing two search forms
          // for docking feature;
          let data;
          const exceptions = ['coordinatorList'];
          if (!has(this.$parent.$slots, 'dock') || exceptions.includes(key)) {
            if (window.cisoSearchFetchCache[key]) {
              data = window.cisoSearchFetchCache[key];
            } else {
              data = await val.fetchData();
              window.cisoSearchFetchCache[key] = data;
            }
            const initialOptions = val.initialFilter ? data.filter((x) => x[val.initialFilter.key] === val.initialFilter.value) : data;
            this.$store.commit('ciso/setParams', [key, initialOptions]);
            this.$store.commit('ciso/setParams', [`${key}Unfiltered`, data]);
          }
        }
      }
      if (typeof val.value === 'function') {
        const initialValue = val.value();
        this.$store.commit('ciso/setParams', [`${key}Selected`, initialValue || null]);
      } else {
        this.$store.commit('ciso/setParams', [`${key}Selected`, val.value || null]);
      }
    });
  },
  methods: {
    handleFileUpload(event) {
      this.$emit('fileUploaded', event);
    },
    dateListenerCallback(dateValues) {
      this.$store.commit('ciso/setParams', ['tradeDateRangeSelected', dateValues.rangeDate]);
    },
    clicked(config) {
      if (has(config, 'clickListener') && typeof config.clickListener === 'function') {
        config.clickListener(this.params);
      }
    },
    search() {
      let search = true;
      Object.entries(this.config).forEach(([key, val], index) => {
        if (has(this.$refs[key][0], 'isValid')) {
          search = (this.$refs[key][0].isValid() && search);
        }
      });
      if (search) {
        this.$emit('search', this.params);
      }
    },
    dockClicked(alignment) {
      const payload = {
        visible: !this.dockConfig.visible,
        alignment: alignment ? alignment.id : 'LEFT',
      };
      this.$emit('setSearchDocked', payload);
      this.$emit('close');
    },
    isVisible(visibility) {
      switch (typeof visibility) {
      case 'boolean':
        return visibility;
      case 'string':
        return this.params[`${visibility}Selected`];
      case 'undefined':
      case 'null':
        return true;
      default:
        return false;
      }
    },
    ...mapActions([
      'selectedValueChanged',
      'selectionSet',
    ]),
    ...mapMutations(['setParams']),
  },
};
</script>

<style lang="scss">
.ciso-search-form {
  hr {
    margin: 7px 7px;
  }

  .dx-field {
    padding-bottom: 5px;
    margin: 0 0 3px 0;
  }

  .footer-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
  }

  &.docked {
    align-self: flex-start;
    padding: 8px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 4px 11px -6px rgba(0, 0, 0, 0.75);

    .dx-field-label {
      width: 30%;
    }

    .dx-field-value {
      width: 66% !important;
    }
  }
}
</style>