
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_INVOICE_API } from '@/api';
import { buildApiQuery } from '@/utils/dataUtil';

const state = {
  tableData: [],
  lineItemData: [],
  summaryData: [],
  selectedRows: [],
  currentTableRow: null,
  editAction: 'Update',
};

const actions = {
  async fetchReadOnlyInvoiceData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.invoiceDateRangeSelected);
    const params = {
      invoiceEntities: parameters.invoiceEntitiesSelected,
      startDate,
      endDate,
    };
    try {
      const { data: { data } } = await BILLING_INVOICE_API.post('/invoices/approved-only/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.approvedDate = x.approvedDate === null ? null : getMomentDateString(x.approvedDate);
          x.createdDate = getMomentDateString(x.createdDate);
          x.updatedDate = getMomentDateString(x.updatedDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.lineItems.forEach((lineItem) => {
            lineItem.tradingDate = getMomentDateString(lineItem.tradingDate);
          });
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async generateRecord({ state }) {
    const invoice = { Data: state.selectedRows };
    try {
      await BILLING_INVOICE_API.post('/invoices/generate', invoice);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('setCurrentRow', currentTableRow);
  },
};

const mutations = {
  updateRow(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  setTableData(state, value) {
    state.tableData = value;
  },
  setCurrentRow(state, currentRow) {
    state.lineItemData = [];
    state.summaryData = [];
    state.currentTableRow = currentRow;
    state.lineItemData = currentRow.lineItems;
    state.summaryData = currentRow.summaries;
  },
  setEditAction(state, action) {
    state.editAction = action;
  },
  deleteRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
    state.lineItemData = [];
    state.summaryData = [];
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};