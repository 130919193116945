import { CISO_BID_SCHD_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import { HERows } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import { mapLabelValue, deconstructScheduleName } from '@/components/Scheduling/utils';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';

const getSchedule = async (scheduleName) => {
  let schedule = null;
  let variant = null;
  let scheduleStatus = null;

  try {
    const actualVariant = await CISO_BID_SCHD_API.get(`virtual/${scheduleName}?variant=ACTUAL`);
    schedule = actualVariant.data;
    variant = 'ACTUAL';
    scheduleStatus = schedule.status;
  } catch (e) {
    console.error(`Unable to retrieve schedule. ${e}`);
  }
  return { data: schedule, variant, scheduleStatus };
};

const state = {
  selectedFlowGate: null,
  scheduleName: '',
  marketType: '',
  locationType: null,
  variant: null,
  startTime: '',
  endTime: '',
  virtualBidType: null,
  scheduleStatus: null,
  sc: '',
  date: '',
  locationName: null,
  location: null,
  scheduleType: true,
  mode: null,
  configurations: [],
  virtualScheduleTableConfig: {
    columns: [{
      prop: 'he', editable: false, fixed: 'left', level: '1', minWidth: 60, width: 60,
    }, {
      label: 'energy', level: '3', isParent: true,
    }, {
      prop: 'en_qty_01', label: 'qty_01', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_01', label: 'prc_01', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_02', label: 'qty_02', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_02', label: 'prc_02', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_03', label: 'qty_03', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_03', label: 'prc_03', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_04', label: 'qty_04', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_04', label: 'prc_04', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_05', label: 'qty_05', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_05', label: 'prc_05', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_06', label: 'qty_06', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_06', label: 'prc_06', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_07', label: 'qty_07', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_07', label: 'prc_07', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_08', label: 'qty_08', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_08', label: 'prc_08', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_09', label: 'qty_09', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_09', label: 'prc_09', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_10', label: 'qty_10', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_10', label: 'prc_10', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_qty_11', label: 'qty_11', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'en_prc_11', label: 'prc_11', editable: true, level: '3-1', parent: '3', compare: true, width: 80,
    }, {
      prop: 'ancillary_service', level: '4', isParent: true, visible: false,
    }, {
      prop: 'ru_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'ru_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rd_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rd_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'sr_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'sr_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'nr_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'nr_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmu_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmu_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmd_qty', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }, {
      prop: 'rmd_prc', level: '4-1', parent: '4', editable: true, compare: true, width: 80, visible: false,
    }],
    options: { trackCompare: true, warningHighlight: true },
  },
  virtualSchedule: [],
  virtualScheduleValidations: [],
  virtualScheduleMeta: [],
};

const getters = {
  hasWritePermissionFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:convergence_bid', 'write');
  },
  getVirtualScheduleTableConfiguration(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:convergence_bid', 'write');
    console.log('user permission value', writePermission);
    return state.virtualScheduleTableConfig;
  },
  // flowGates: (state) => { return state.locations }
};

const actions = {
  async init({ dispatch }) {
    const payload = {
      referenceItemList: ['fetchLocationList'],
      market: 'CAISO',
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  reset({ commit }) {
    commit('reset');
  },
  async loadScheduleAction({ dispatch, commit, state }, params) {
    commit('setLocationType', params.locationType);
    commit('scheduleNameMutation', params.scheduleName);
    let location = null;
    try {
      location = await dispatch('REFERENCE_DATA_STORE/fetchLocation', {
        market: 'CAISO', locationType: state.locationType, locationName: state.locationName,
      });
    } catch (ex) {
      console.error(`Unable to retrieve location: ${ex}`);
    }

    commit('setLocation', location);
    const schedule = await getSchedule(params.scheduleName);

    commit('setVirtualScheduleMeta', schedule.data);
    commit('setVariant', schedule.variant);
    commit('setScheduleStatus', schedule.scheduleStatus);
    commit('setVirtualScheduleValidations', schedule.data?.validations);
  },

  async save({ dispatch, state }) {
    const schedule = {
      sc: state.sc,
      startTime: state.startTime,
      endTime: state.endTime,
      scheduleName: state.scheduleName,
      resource: state.locationName,
      resourceType: state.locationType,
      virtualBidType: state.virtualBidType,
      bidSchedules: state.virtualSchedule,
      flowgate: state.selectedFlowGate,
    };

    if (state.mode === 'NEW') {
      try {
        await CISO_BID_SCHD_API.post('virtual', schedule);
      } catch (e) {
        this.$notify({ type: 'error', message: 'Save Failed' });
      }
    } else {
      try {
        await CISO_BID_SCHD_API.put(`virtual/${schedule.scheduleName}`, schedule);
      } catch (e) {
        this.$notify({ type: 'error', message: 'Update Failed' });
      }
    }
  },
};

const mutations = {
  setVirtualBidType(state, item) {
    const dt = state.date.substring(0, 4) + state.date.substring(5, 7) + state.date.substring(8, 10);
    state.scheduleName = `${state.sc}-${item}-${dt}-${state.locationName}`;
    state.virtualBidType = item;
  },
  setSelectedFlowGate(state, item) {
    state.selectedFlowGate = item;
  },
  reset(state) {
    state.bidsVariantSchedule = null;
  },
  setLocationType(state, item) {
    state.locationType = item;
  },
  setLocation(state, location) {
    state.location = location;
    if (location?.locationSubtypes) {
      state.configurations = mapLabelValue(location.locationSubtypes, 'id', 'shortName');
    }
  },
  scheduleNameMutation(state, name) {
    const propList = ['sc', 'virtualBidType', 'date', 'location'];
    const item = deconstructScheduleName(name, propList);
    state.sc = item.sc;
    state.scheduleName = name;
    state.locationName = item.location;
    state.virtualBidType = item.virtualBidType;
    state.date = `${item.date.substring(0, 4)}-${item.date.substring(4, 6)}-${item.date.substring(6, 8)}`;

    const mt = dateStore.toMomentFromStringWithFormat(state.date, 'YYYY-MM-DD');
    state.startTime = mt.toISOString();
    state.endTime = mt.clone().add(1, 'days').toISOString();
  },
  setVirtualScheduleMeta(state, item) {
    if (!item) state.mode = 'NEW'; else state.mode = 'EXISTING';
    const startTimeMoment = moment(state.date).startOf('day');
    const emptyHours = HERows(undefined, true, startTimeMoment.toISOString());
    if (item && Array.isArray(item.bidSchedules)) {
      item.bidSchedules.forEach((data) => {
        const fillIndex = emptyHours.indexOf(emptyHours.find(({ he: hhe }) => hhe === data.he));
        emptyHours[fillIndex] = { ...data, ...emptyHours[fillIndex] };
      });
    }
    if (state.mode === 'NEW') {
      state.virtualBidType = 'VIRTUAL_SUPPLY';
    } else { state.virtualBidType = item.virtualBidType; }
    state.virtualSchedule = emptyHours;
    state.selectedFlowGate = item?.flowgate;
  },
  setVirtualScheduleValidations(state, item) {
    if (!item) return;
    state.virtualScheduleValidations = item;
  },
  setScheduleType(state, item) { state.scheduleType = item; },
  setConfiguration(state, item) { state.configuration = item; },
  setSelfScheduleTableConfig(state, data) { state.selfScheduleTableConfig = data; },
  setBidCurveTableConfig(state, data) { state.bidCurveTableConfig = data; },
  updateVirtualSchedule(state, item) {
    const updatedSchedule = [...state.virtualSchedule];
    updatedSchedule[item.rowIndex][item.prop] = item.value;
    state.virtualSchedule = updatedSchedule;
  },
  setScheduleStatus(state, item) { state.scheduleStatus = item; },

  // --------------------------------------
  ...createMutations(
    'virtualScheduleTableConfig',
    'virtualSchedule',
    'selectedFlowGate',
    'variant',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};