import { sortBy } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  tableConfig: {
    columns: [{
      prop: 'id',
      label: 'Id',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 50,
    }, {
      prop: 'tenant',
      label: 'Tenant',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'host',
      label: 'Host',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'userName',
      label: 'User Name',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'traceId',
      label: 'Trace Id',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'sessionId',
      label: 'Session Id',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'severity',
      label: 'Severity',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 50,
    }, {
      prop: 'messageId',
      label: 'Message Id',
      alignment: 'right',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 50,
    }, {
      prop: 'messageType',
      label: 'Message Type',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }, {
      prop: 'messageName',
      label: 'Message Name',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 120,
    }, {
      prop: 'messageBody',
      label: 'Message Body',
      alignment: 'left',
      filterable: false,
      sortable: false,
      width: 400,
    }, {
      prop: 'messageCreatedBy',
      label: 'Message Created By',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }, {
      prop: 'messageTransportedBy',
      label: 'Message Transported By',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }, {
      prop: 'scriptOrder',
      label: 'Script Order',
      alignment: 'right',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }, {
      prop: 'scriptName',
      label: 'Script Name',
      alignment: 'left',
      filterable: true,
      sortable: true,
      // width: 20,
    }, {
      prop: 'responseSuccess',
      label: 'Success',
      dataType: 'boolean',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'responseError',
      label: 'Error',
      alignment: 'left',
      filterable: true,
      sortable: true,
      // width: 20,
    }, {
      prop: 'responseContext',
      label: 'Response',
      alignment: 'left',
      filterable: true,
      sortable: true,
      // width: 20,
    }, {
      prop: 'executedBy',
      label: 'Executed By',
      alignment: 'left',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'executedOn',
      label: 'Executed On',
      alignment: 'left',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      prop: 'createdBy',
      label: 'Created By',
      alignment: 'left',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }, {
      prop: 'createdOn',
      label: 'Created Date',
      alignment: 'left',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
      visible: false,
      // width: 20,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  tableData: [],
  // variants: [],
  dateRange: dateStore.getDefaultRange(),
  responseSuccess: false,
};

const getters = {
  // getCommodities: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
  // getCompanies: (state, localGetters, rootState) => rootState.lookup.companyList,
};

const actions = {
  async fetchLookupData({ dispatch }) {
    // dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    // dispatch('lookup/fetchCompanyList', null, { root: true });
    // await dispatch('fetchVariants');
  },
  async fetchExecutionLogs({ commit, state }) {
    try {
      const params = {
        startDate: state.dateRange[0].toISOString(),
        endDate: state.dateRange[1].toISOString(),
        responseSuccess: state.responseSuccess,
      };
      const { data } = await STRUCTURES_API.get('connection-messages/execution', { params });
      commit('setTableData', data);
    } catch (error) {
      if (error.response) console.error(error.response);
      else console.error(error);
    }
  },
  // async fetchVariants({ commit, rootGetters }) {
  //   try {
  //     const { data: { data } } = await STRUCTURES_API.get('/variant');

  //     const variants = data
  //       .sort(sortBy('name'))
  //       .map((p) => ({ value: p.name, label: p.name }));

  //     commit('setVariants', variants);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = data;
  },
  ...createMutations(
    'dateRange',
    'responseSuccess',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
