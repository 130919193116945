import dateStore from '@/utils/dateStore';
import userStore from '@/utils/userStore';
import { deepClone, removeObjProps } from '@/utils/dataUtil';
import { ETAG_API } from '@/api';
import cellColors from '../util';
import lastActionTypeColors from '../lastActionTypeColors';
import registryStore from '../registryStore';

const state = {
  tableConfiguration: {
    tableName: 'tagSummary',
    columns: [{
      prop: 'toEntity', label: 'TO ENTITY', filterable: true, sortable: true,
    }, {
      prop: 'gca', label: 'GCA', filterable: true, sortable: true,
    }, {
      prop: 'cpse', label: 'CPSE', filterable: true, sortable: true,
    }, {
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ViewTag',
        params: [{ key: 'tagId', dataKey: 'tagID' }, { key: 'entity', dataKey: 'toEntity' }],
        query: {
          handler(data) {
            const query = {tagSummaryRef: data.id};
            if (data.requests) {
              for (let x = 0; x < data.requests.length; x++) {
                const req = data.requests[x];
                if (req.approvalRights && req.requestStatus === 'PENDING') {
                  query.requestID = req.requestID;
                  break;
                }
              }
            }
            return query;
          },
        },
      },
      filterable: true,
      label: 'CODE',
      prop: 'tagCode',
      sortable: true,
    }, {
      prop: 'lca', label: 'LCA', filterable: true, sortable: true,
    }, {
      prop: 'source', label: 'SOURCE', filterable: true, sortable: true,
    }, {
      prop: 'sink', label: 'SINK', filterable: true, sortable: true,
    }, {
      prop: 'gpe', label: 'GPE', filterable: true, sortable: true,
    }, {
      prop: 'lpe', label: 'LPE', filterable: true, sortable: true,
    }, {
      prop: 'implementationDateTime', label: 'START', filterable: true, sortable: true,
    }, {
      prop: 'terminationDateTime', label: 'END', filterable: true, sortable: true,
    }, {
      prop: 'originalGenMW', label: 'ORIGINAL(MWh)', filterable: true, sortable: true, width: 60,
    }, {
      prop: 'genMW', label: 'CURRENT(MWh)', filterable: true, sortable: true, width: 60,
    }, {
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      class: true,
      filterable: true,
      editorOptions: { ...cellColors },
      label: 'STATUS',
      prop: 'compositeState',
      sortable: true,
    }, {
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      class: true,
      filterable: true,
      sortable: true,
      editorOptions: Object.freeze({ ...lastActionTypeColors }),
      prop: 'lastAction',
      label: 'LAST MSG. TYPE',
    }, {
      alignment: 'center',
      cellTemplate: 'PscsApprovalStatusTemplate',
      editorOptions: { ...cellColors },
      label: 'APPROVAL',
      prop: 'approval',
      filterable: true,
      sortable: true,
    }, {
      prop: 'transactionType', label: 'TYPE', filterable: true, sortable: true,
    }, {
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        masterDetailTemplate: 'PscsMasterDetailListCellTemplate',
        multipleSelect: true,
        key: 'requests',
        pageSize: 5,
        columns: [
          { prop: 'requestID', label: 'REQ. ID', alignment: 'center' },
          { prop: 'toEntityName', label: 'TO ENTITY', alignment: 'center' },
          { prop: 'fromEntityName', label: 'FROM ENTITY', alignment: 'center' },
          { prop: 'requestTimeStamp', label: 'REQ. TIME', alignment: 'center' },
          { prop: 'resolutionTimeStamp', label: 'RES. TIME', alignment: 'center' },
          {
            prop: 'diffMWChange',
            label: 'REQ. MW',
            sortable: false,
            cellTemplate: 'PscsIncDecCellTemplate',
            alignment: 'center',
          },
          {
            prop: 'messageType',
            label: 'MSG. TYPE',
            cellTemplate: 'PscsColoredCellTemplate',
            alignment: 'center',
          },
          {
            prop: 'requestStatus',
            label: 'STATUS',
            cellTemplate: 'PscsColoredCellTemplate',
            alignment: 'center',
          },
          {
            alignment: 'center',
            cellTemplate: 'PscsTagApprovalCell',
            filterable: false,
            fixed: 'right',
            width: 100,
            label: 'Actions',
          },
        ],
        options: { ...cellColors, ...lastActionTypeColors },
      },
      label: 'P',
      prop: 'pendingRequestCount',
      sortable: true,
    }, {
      prop: 'marketChangeRequestCount', label: 'M.C.', filterable: false, sortable: true,
    }, {
      prop: 'reliabilityChangeRequestCount', label: 'R.C.', filterable: false, sortable: true,
    }, {
      prop: 'timeRangeChangeRequestCount', label: 'T.C.', filterable: false, sortable: true,
    }, {
      prop: 'lastActionDateTime', label: 'LAST MSG. TIME', filterable: true, sortable: true,
    }, {
      prop: 'actOnByTime', label: 'DEADLINE', filterable: true, sortable: true,
    }, {
      prop: 'wecReserve', label: 'WECC RESERVE', filterable: true, sortable: true,
    }, {
      prop: 'currentPath', label: 'PATH', filterable: true, sortable: true, width: 300,
    }, {
      prop: 'notes', label: 'Notes', filterable: false, sortable: true, width: 250,
    }, {
      alignment: 'center',
      cellTemplate: 'PscsTagActionsCell',
      filterable: false,
      fixed: 'right',
      label: 'Actions',
      prop: 'actions',
      width: 100,
    }],
    summary: [{
      prop: 'toEntity',
      label: 'TOTAL',
      alignment: 'center',
    }, {
      prop: 'genMW',
      summaryType: 'sum',
      alignment: 'right',
    }, {
      prop: 'originalGenMW',
      summaryType: 'sum',
      alignment: 'right',
    }],
    options: {
      excludeColumnExcel: ['tagID'],
      exportExcel: true,
      filterRow: true,
      filterPanel: false,
      filterHeader: true,
    },
  },
  tableData: [],
  datePropsAndFormats: [
    { name: 'implementationDateTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'terminationDateTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'lastActionDateTime', format: 'YYYY-MM-DD HH:mm:ss' },
    { name: 'actOnByTime', format: 'YYYY-MM-DD HH:mm:ss' },
  ],
  storedParams: null,
  expandPendingRequests: null,
  dateRange: dateStore.toMomentAndZoneFromJSDateArray(dateStore.getDefaultRange(), dateStore.getTimeZone()),
};

const getters = {
  getStoredParams: (state) => state.storedParams,
  getExpandPendingRequests(state) {
    if (state.expandPendingRequests !== null) return state.expandPendingRequests;
    return userStore.getExpandPendingRequests();
  },
};

const actions = {
  loadSummaryAction({ dispatch, commit }, item) {
    const tz = dateStore.getTimeZone();
    let start = dateStore.toMoment('1900-01-01').startOf('day').utc();
    let end = dateStore.toMoment('2099-12-31').startOf('day').utc();

    if (item.dateRange.length !== 0) {
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, tz);
      state.dateRange = momentRange;
      start = momentRange[0].startOf('day').utc();
      end = momentRange[1].startOf('day').add(1, 'days').utc();
    }

    const params = {
      timeZone: tz,
      start: start.format(),
      end: end.format(),
      inc_requests: true,
    };

    if (item.cpse && item.cpse.length > 0) params.cpse = item.cpse;
    if (item.grouping && item.grouping.length > 0) params.grouping = item.grouping;
    if (item.text && item.text.length > 0) params.text = item.text;
    if (item.gca && item.gca.length > 0) params.gca = item.gca;
    if (item.lca && item.lca.length > 0) params.lca = item.lca;
    if (item.source && item.source.length > 0) params.source = item.source;
    if (item.sink && item.sink.length > 0) params.sink = item.sink;
    if (item.path && item.path.length > 0) params.path = item.path;
    if (item.notes && item.notes.length > 0) params.notes = item.notes;

    const props = [
      'implementationDateTime',
      'terminationDateTime',
      'actOnByTime',
      'lastActionDateTime',
      'requests',
      'routeText',
      'formattedTimestamp',
      'timeAgo',
    ];
    return new Promise((resolve, reject) => {
      ETAG_API.get('/tags', { params }).then(({ data }) => {
        const clonedData = data.tagSummaries.map((row) => JSON.stringify(removeObjProps(deepClone(row), ...props)));
        const clonedTableData = state.tableData.map((row) => JSON.stringify(removeObjProps(deepClone(row), ...props)));

        const isNotEquals = clonedData.length !== clonedTableData.length
        || clonedData.some((obj, idx) => obj !== clonedTableData[idx]);
        if (isNotEquals) commit('loadSummaryMutation', data.tagSummaries);
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  configChanged({ commit }, value) { commit('setConfig', value); },
  clear({ commit }) { commit('clear'); },
  changeStoredParams({ commit }, item) {
    commit('setStoredParams', item);
  },
  changeExpandPendingRequests({ commit }, item) {
    commit('setExpandPendingRequests', item);
  },
};

const mutations = {
  loadSummaryMutation(state, data) {
    const format = dateStore.getZuluFormat();
    dateStore.formatDateTimeFields(data, state.datePropsAndFormats);
    data.forEach((v) => {
      const rt = dateStore.toMomentFromStringWithFormat(v.actOnByTime, format);
      if (rt._i) {
        v.timeAgo = rt.clone().fromNow();
        v.formattedTimestamp = rt.clone().format('YYYY-MM-DD HH:mm:ss');
        v.actOnByTime = `${v.timeAgo} (${v.formattedTimestamp})`;
      }
      v.routeText = v.tagCode;
      v.requests.forEach((req) => {
        req.tagSummaryRef = v.id;
        const reqTime = dateStore.toMomentFromStringWithFormat(req.requestTimeStamp, format);
        req.requestTimeStamp = `${reqTime.clone().fromNow()} (${reqTime.clone().format('YYYY-MM-DD HH:mm:ss')})`;

        if (req.resolutionTimeStamp && req.resolutionTimeStamp !== '') {
          const resTime = dateStore.toMomentFromStringWithFormat(req.resolutionTimeStamp, format);
          req.resolutionTimeStamp = `${resTime.clone().fromNow()} (${resTime.clone().format('YYYY-MM-DD HH:mm:ss')})`;
        }
      });
    });
    state.tableData = data;
  },
  clear(state) { state.tableData = []; },
  setConfig(state, value) { state.tableConfiguration = value; },
  readyRegistryCPSEList(state) { },
  setStoredParams(state, val) { state.storedParams = val; },
  setExpandPendingRequests(state, val) {
    state.expandPendingRequests = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    registryStore,
  },
};