import { STRUCTURES_API } from '@/api';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import LOOKUP_STORE from '@/store/lookupStore';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'activeFlag', label: 'Active?', sortable: true,
    }, {
      prop: 'companies', label: 'Companies', sortable: true, width: '50%'
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getCompanyList: (state, localGetters) => localGetters['LOOKUP_STORE/getCompanyList'],
};

const actions = {
  async initialize({ dispatch }) {
    await dispatch('loadTableData');
    await dispatch('LOOKUP_STORE/fetchCompanyList');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/commodities/with-companies').then(({ data }) => {
      const commodities = data?.energyCommodities;
      commit('loadTableData', commodities);
      this.$notify(`${commodities?.length} Commoditie(s) Loaded`);
    }).catch((err) => {
      console.log(err);
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  insertTableRow({ commit }, commodityType) {
    commit('insertTableRow', commodityType);
  },
  postCommodityType({ dispatch, commit, state }, commodityType) {
    STRUCTURES_API.post('/commodities', commodityType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Commodity Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Commodity', 'error');
    });
  },
  updateCommodityType({ dispatch, commit, state }, commodityType) {
    STRUCTURES_API.put(`/commodities/${commodityType.id}`, commodityType).then(({ data }) => {
      commit('updateCommodityType', data);
      this.$notify('Updated Commodity', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Commodity', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    const toDelete = state.currentTableRow;
    STRUCTURES_API.delete(`/commodities/${toDelete.id}`).then(({ data }) => {
      commit('deleteTableRow');
      this.$notify('Commodity Removed');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    }).catch((err) => {
      this.$notify('Failed to Remove Commodity', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit, state }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};
const mutations = {
  updateCommodityType(state, commodityType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === commodityType.id);
    state.tableData.splice(rowIndex, 1, commodityType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  setNotificationCallback(state, callback) {
    state.notificationCallback = callback;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, commodityTypes) {
    state.tableData = commodityTypes;
  },
  createTableRow(state) {
    const commodityType = clone(state.nullTableRow);
    commodityType.id = state.tableKey++;
    commodityType.createdBy = this.getters['auth/getProfile'].userName;
    commodityType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = commodityType;
  },
  insertTableRow(state, commodityType) {
    state.tableData.unshift(commodityType);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((commodityType) => commodityType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { LOOKUP_STORE },
};