import { accessRight, contractBilling } from '@/auth/permission';

export default {
  path: '/contract-billing',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'CONTRACT BILLING',
    permission: { name: contractBilling.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: '/contract-billing/reference-data',
    meta: {
      visible: true,
      rank: 4,
      requiresAuth: true,
      text: 'Reference Data',
      permission: { name: contractBilling.referenceData.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'invoice-entities',
      name: 'InvoiceEntities',
      meta: {
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Invoice Entities',
        permission: { name: contractBilling.referenceData.invoiceEntities, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/InvoiceEntities/InvoiceEntities'),
    },
    {
      path: 'invoicing-companies',
      name: 'ContractBillingInvoicingCompanies',
      meta: {
        visible: true,
        rank: 15,
        requiresAuth: true,
        text: 'Invoicing Companies',
        permission: { name: contractBilling.referenceData.invoicingCompanies, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/InvoicingCompanies/InvoicingCompanies'),
    },
    {
      path: 'contracts',
      name: 'ContractBillingContracts',
      meta: {
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Contracts',
        permission: { name: contractBilling.referenceData.contracts, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Contracts/Contracts'),
    },
    {
      path: 'charge-codes',
      name: 'ContractBillingChargeCodes',
      meta: {
        visible: true,
        rank: 4,
        requiresAuth: true,
        text: 'Charge Codes',
        permission: { name: contractBilling.referenceData.chargeCodes, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/ChargeCodes/ChargeCodes'),
    },
    {
      path: 'banks',
      name: 'ContractBillingBanks',
      meta: {
        visible: true,
        rank: 6,
        requiresAuth: true,
        text: 'Banks',
        permission: { name: contractBilling.referenceData.banks, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Banks/Banks'),
    },
    {
      path: 'points-of-contact',
      name: 'ContractBillingPointsOfContact',
      meta: {
        visible: true,
        rank: 7,
        requiresAuth: true,
        text: 'Points Of Contact',
        permission: { name: contractBilling.referenceData.pointOfContact, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/PointsOfContact/PointsOfContact'),
    },
    {
      path: 'bill-determinants',
      name: 'ContractBillingBillDeterminants',
      meta: {
        visible: true,
        rank: 5,
        requiresAuth: true,
        text: 'Bill Determinants',
        permission: { name: contractBilling.referenceData.billDeterminant, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/BillDeterminants/BillDeterminants'),
    },
    {
      path: 'contract-charges',
      name: 'ContractBillingContractCharges',
      meta: {
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Contract Charges',
        permission: { name: contractBilling.referenceData.contractCharges, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/ContractChargeRef/ContractChargeRef'),
    },
    {
      path: 'contract-groups',
      name: 'ContractBillingContractGroups',
      meta: {
        visible: true,
        rank: 6,
        requiresAuth: true,
        text: 'Contract Groups',
        permission: { name: contractBilling.referenceData.contractGroups, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/ContractGroups/ContractGroups'),
    },
    {
      path: 'counterparty-mapping',
      name: 'ContractBillingCounterpartyMapping',
      meta: {
        visible: true,
        rank: 7,
        requiresAuth: true,
        text: 'Counterparty Mapping',
        permission: { name: contractBilling.referenceData.counterpartyMapping, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/CounterpartyMapping/CounterpartyMapping'),
    },
    {
      path: 'settlement-reference',
      name: 'ContractBillingSettlementReference',
      meta: {
        visible: true,
        rank: 8,
        requiresAuth: true,
        text: 'Settlement Reference',
        permission: { name: contractBilling.referenceData.settlementReference, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/SettlementRef/SettlementRef'),
    },
    {
      path: 'price',
      name: 'ContractBillingPrice',
      meta: {
        visible: true,
        rank: 9,
        requiresAuth: true,
        text: 'Price',
        permission: { name: contractBilling.referenceData.price, rights: ['read', 'write'] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Price/Price'),
    },
    {
      path: 'meter',
      name: 'ContractBillingMeter',
      meta: {
        visible: true,
        rank: 10,
        requiresAuth: true,
        text: 'Meter',
        permission: { name: contractBilling.referenceData.meter, rights: ['read', 'write'] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Meter/Meter'),
    },
    {
      path: 'schedule',
      name: 'ContractBillingSchedule',
      meta: {
        visible: true,
        rank: 11,
        requiresAuth: true,
        text: 'Schedule',
        permission: { name: contractBilling.referenceData.schedule, rights: ['read', 'write'] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Schedule/Schedule'),
    },
    {
      path: 'reservation',
      name: 'ContractBillingReservation',
      meta: {
        visible: true,
        rank: 12,
        requiresAuth: true,
        text: 'Reservation',
        permission: { name: contractBilling.referenceData.reservation, rights: ['read', 'write'] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Reservation/Reservation'),
    }, {
      path: 'etag',
      name: 'ContractBillingEtag',
      meta: {
        visible: true,
        rank: 13,
        requiresAuth: true,
        text: 'Etag',
        permission: { name: contractBilling.referenceData.etag, rights: ['read', 'write'] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/Etag/Etag'),
    }, {
      path: 'InvoiceApprovalLimit',
      name: 'ContractBillingInvoiceApprovalLimit',
      meta: {
        visible: true,
        rank: 14,
        requiresAuth: true,
        text: 'Invoice Approval Limit',
        permission: { name: contractBilling.referenceData.invoiceApprovalLimit, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/InvoiceApprovalLimit/InvoiceApprovalLimit'),
    }, {
      path: 'interestRates',
      name: 'ContractBillingInterestRates',
      meta: {
        visible: true,
        rank: 15,
        requiresAuth: true,
        text: 'Interest Rates',
        permission: { name: contractBilling.referenceData.interestRates, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/ReferenceData/InterestRates/InterestRates'),
    }],
  }, {
    path: '/contract-billing/source-data',
    meta: {
      visible: true,
      rank: 3,
      requiresAuth: true,
      text: 'Source Data',
      permission: { name: contractBilling.sourceData.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'billing-meter',
      name: 'BillingMeter',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Billing Meter',
        permission: { name: contractBilling.sourceData.billingMeter, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/SourceData/BillingMeter/BillingMeter'),
    }, {
      path: 'billing-prices',
      name: 'BillingPrices',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Billing Prices',
        permission: { name: contractBilling.sourceData.billingPrices, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/SourceData/BillingPrices/BillingPrices'),
    }, {
      path: 'billing-schedules',
      name: 'BillingSchedules',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Billing Schedules',
        permission: { name: contractBilling.sourceData.billingSchedules, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/SourceData/BillingSchedules/BillingSchedules'),
    }, {
      path: 'billing-reservations',
      name: 'BillingReservations',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Billing Reservations',
        permission: { name: contractBilling.sourceData.billingReservations, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/SourceData/BillingReservations/BillingReservations'),
    }, {
      path: 'billing-source',
      name: 'BillingDeterminantData',
      meta: {
        visible: true,
        requiresAuth: true,
        permission: { name: contractBilling.sourceData.billingDeterminantData, rights: [accessRight.visible] },
        text: 'Billing Generic Determinant Data',
      },
      component: () => import('@/components/ContractBilling/SourceData/BillingDeterminantData/BillingDeterminantData'),
    }, {
      path: 'meter-check',
      name: 'MeterCheck',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Meter Check',
        permission: { name: contractBilling.sourceData.billingMeter, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/SourceData/MeterDataCounts/MeterDataCounts'),
    }],
  }, {
    path: '/contract-billing/invoicing',
    meta: {
      visible: true,
      rank: 2,
      requiresAuth: true,
      text: 'Invoicing',
      permission: { name: contractBilling.invoicing.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'billing-invoice-create',
      name: 'BillingInvoiceCreate',
      meta: {
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Create Invoice',
        permission: { name: contractBilling.invoicing.createInvoice, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Invoicing/CreateInvoice/CreateInvoice'),
    }, {
      path: 'billing-invoice-approve',
      name: 'BillingInvoiceApprove',
      meta: {
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Approve Invoice',
        permission: { name: contractBilling.invoicing.approveInvoice, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Invoicing/ApproveInvoice/ApproveInvoice'),
    }, {
      path: 'billing-subledger',
      name: 'BillingSubledger',
      meta: {
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Subledger',
        permission: { name: contractBilling.invoicing.subledger, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Invoicing/Subledger/Subledger'),
    }, {
      path: 'billing-invoice-read-only',
      name: 'BillingInvoiceReadOnly',
      meta: {
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Billing Invoice',
        permission: { name: contractBilling.invoicing.readOnlyApprovedInvoice, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Invoicing/ReadOnlyInvoice/ReadOnlyInvoice'),
    }],
  }, {
    path: '/contract-billing/calculation',
    meta: {
      visible: true,
      rank: 5,
      requiresAuth: true,
      text: 'Calculation',
      permission: { name: contractBilling.calculation, rights: [accessRight.visible] },
    },
    name: 'BillingCalculation',
    component: () => import('@/components/ContractBilling/Calculation/Calculation'),
  }, {
    path: '/contract-billing/reporting',
    meta: {
      visible: true,
      rank: 6,
      requiresAuth: true,
      text: 'Billing Reports',
      permission: { name: contractBilling.reporting, rights: [accessRight.visible] },
    },
    name: 'BillingReports',
    component: () => import('@/components/ContractBilling/Reporting/BillingReports'),
  }, {
    path: '/contract-billing/data-importer',
    meta: {
      visible: true,
      rank: 7,
      requiresAuth: true,
      text: 'Billing Data Import',
      permission: { name: contractBilling.dataImport.menu, rights: [accessRight.visible] },
    },
    name: 'BillingImports',
    component: () => import('@/components/ContractBilling/ImportData/BillingImports'),
  }, {
    path: '/contract-billing/settlements',
    meta: {
      visible: true,
      rank: 1,
      requiresAuth: true,
      text: 'Settlements',
      permission: { name: contractBilling.settlements.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'summary',
      name: 'CbSettlementsSummary',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Settlements Summary',
        permission: { name: contractBilling.settlements.statement, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Settlements/SettlementsSummary/SettlementsSummary'),
    }, {
      path: 'charge-details',
      name: 'CbChargeDetails',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'CB Charge Details',
        permission: { name: contractBilling.settlements.determinantDetail, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Settlements/SettlementsChargeDetails/SettlementsChargeDetails'),
    }, {
      path: 'determinant-details',
      name: 'CbDeterminantDetails',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'CB Determinant Details',
        permission: { name: contractBilling.settlements.determinantDetail, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Settlements/DeterminantDetails/DeterminantDetails'),
    }, {
      path: 'determinant-debug',
      name: 'CbDeterminantDetailsDebug',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'CB Determinant Debug',
        permission: { name: contractBilling.settlements.determinantDetail, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Settlements/DeterminantDebug/DeterminantDebug'),
    }, {
      path: 'bd-overrides',
      name: 'BDOverrides',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'CB BD Overrides',
        permission: { name: contractBilling.settlements.override, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ContractBilling/Settlements/BDOverrides/BDOverrides'),
    }],
  }],
};