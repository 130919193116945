const etrm: any = {
  CREDIT: 'etrm:credit',
  TRADE_LIMITS: 'etrm:tradelimits',
  TRADE_LOCKING: 'etrm:tradelocking',
  TRADES_TO_SCHEDULES: 'etrm:trades-to-schedules',
  TRADE_CONFIRMATION: 'etrm:trades:confirmation',
  MTM: 'etrm:mtm',
  INTERVAL_ADJUSTMENTS: 'etrm:interval_adjustments',
  EXPORT_TO_ACES: 'etrm:aces_export',
  IMPORT_FROM_ACES: 'etrm:aces_import',
  EXPORT_TO_ALLEGRO: 'etrm:allegro_export',
  TRADE_IMBALANCE: 'etrm:trade_imbalance',
};

export default etrm;