import { tradeDate, schedulingCoordinatorsWithSubCompany } from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDate,
    schedulingCoordinatorsWithSubCompany,
  },
  fields: [
    {
      caption: 'START DATE',
      dataField: 'start_date',
      dataType: 'date',
      format: 'yyyy-MM-dd',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'END DATE',
      dataField: 'end_date',
      dataType: 'date',
      format: 'yyyy-MM-dd',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'SOURCE',
      dataField: 'source',
      dataType: 'string',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'SINK',
      dataField: 'sink',
      dataType: 'string',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'BA',
      dataField: 'ba',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CRR_ID',
      dataField: 'crr_id',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'FILE TYPE',
      dataField: 'file_type',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CRR TYPE',
      dataField: 'crr_type',
      dataType: 'string',
      area: 'column',
      expanded: true,
      allowSorting: true,
    }, {
      caption: 'HEDGE TYPE',
      dataField: 'hedge_type',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'MKT NAME',
      dataField: 'market_name',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TIME OF USE',
      dataField: 'time_of_use',
      dataType: 'string',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'VOLUME',
      dataField: 'mw',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: { type: 'fixedPoint', precision: 2 },
      calculateSummaryValue: (e) => {
        const sum = 1;
        const file = 0;
        const compare = 0;
        return e.cell() && e.cell().originalCell.length > 0 ? e.cell().originalCell[0] : 0;
      },
    }],
};