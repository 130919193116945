import utils from '@/utils';
import { getMomentDateString } from '@/utils/dateUtil';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { CISO_METER_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  mapResourceList: [],
  nullTableRow: {
    id: -999,
    sc: null,
    mapResourceId: null,
    mapChildResourceId: null,
    mapRsrcName: null,
    mapChildRsrcName: null,
    multiplier: null,
    effectiveDate: null,
    terminationDate: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getMapResourceList: (state) => state.mapResourceList.map(({ mapResourceId, rsrcId }) => ({ value: mapResourceId, label: rsrcId })),
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('fetchAggregateMappings');
    await dispatch('fetchMapResourceList');
  },
  async fetchAggregateMappings({ commit }, args = {}) {
    const params = {
      scs: (args.schedulingCoordinatorsWithTagboxSelected) ? args.schedulingCoordinatorsWithTagboxSelected.join(',') : '',
    };
    try {
      const { data: { data } } = await CISO_METER_API.get('/meter-mapping/map-aggregate', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.effectiveDate = x.effectiveDate === null ? null : getMomentDateString(x.effectiveDate);
          x.terminationDate = x.terminationDate === null ? null : getMomentDateString(x.terminationDate);
        });
        commit('setTableData', data);
      } else if (data == null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Mapping Aggregate Data', 'error');
    }
  },
  async fetchMapResourceList({ commit }) {
    try {
      const { data: { data } } = await CISO_METER_API.get('/meter-mapping/map-resource');
      commit('loadMapResourceList', data);
    } catch (err) {
      console.error(err);
    }
  },
  postRecord({ state }, aggregate) {
    CISO_METER_API.post('/meter-mapping/map-aggregate', aggregate).then(({ data }) => {
      state.tableData.push(data.data[0]);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, aggregate) {
    CISO_METER_API.put(`/meter-mapping/map-aggregate/${aggregate.id}`, aggregate).then(({ data }) => {
      commit('updateRecord', data.data[0]);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    CISO_METER_API.delete(`/meter-mapping/map-aggregate/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadMapResourceList(state, mapResources) {
    state.mapResourceList = mapResources;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};