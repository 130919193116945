import Vue from 'vue';
import moment from 'moment';
import { VA_API, STRUCTURES_API } from '@/api';
import { updateUserResource, fetchUserResource } from '@/utils/structuresHelper';
import { clone } from '@/utils/dataUtil';
import { createMutations } from '@/utils/vuexHelper';
import VABaseStore from '../../VABaseStore';

const defaultLayout = {
  id: undefined,
  name: null,
  description: null,
  defaultLocation: null,
  defaultVariant: null,
  userOnlyFlag: false,
  createdBy: '',
  charts: [],
  ruleLocationChartSeries: [],
};

const state = {
  layout: { ...defaultLayout },
  series: [],
  rules: [],
  charts: [],
  currentRow: null,
  layouts: [],
  layoutChartIndex: 0,
  layoutConfig: {
    columns: [{
      label: 'ID',
      prop: 'id',
      filterable: true,
      sortable: true,
    }, {
      label: 'Name',
      prop: 'name',
      filterable: true,
      sortable: true,
      sortOrder: 'asc',
    }, {
      label: 'Description',
      prop: 'description',
      filterable: true,
      sortable: true,
    }, {
      label: 'Default Location',
      prop: 'defaultLocation',
      filterable: true,
      sortable: true,
    }, {
      label: 'Default Variant',
      prop: 'defaultVariant',
      filterable: true,
      sortable: true,
    }, {
      label: 'User Only',
      prop: 'userOnlyFlag',
      dataType: 'boolean',
      filterable: true,
      sortable: true,
    }, {
      label: 'Is Default',
      prop: 'isUserDefaultLayout',
      dataType: 'boolean',
      filterable: true,
      sortable: true,
    }, {
      label: 'Created By',
      prop: 'createdBy',
      filterable: true,
      sortable: true,
    }, {
      label: 'Created Date',
      prop: 'createdDate',
      dataType: 'date',
      filterable: true,
      sortable: true,
    }, {
      label: 'Updated By',
      prop: 'updatedBy',
      filterable: true,
      sortable: true,
    }, {
      label: 'Updated Date',
      prop: 'updatedDate',
      dataType: 'date',
      filterable: true,
      sortable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
};

const getters = {
  chartList: (state) => state.charts.map(({ name, id }) => ({ label: name, value: id })),
  seriesList: (state) => state.series
    .filter(({ name, description }) => !(name.includes('rule_') && !name.includes('_case')) // exclude series name with "rule" and not "case"
      && !(description.includes('Rule:') && !description.includes('Case')) // exclude series description with "rule" and not "case"
      && !description.includes('Rule Violation'))
    .map((s) => ({ label: s.description, value: s.id, ...s })),
  selectedLayoutChart: (state) => (state.layoutChartIndex >= 0) ? state.layout.charts[state.layoutChartIndex] : null,
  isAuthor: (state, getters, rootState, rootGetters) => {
    const currentUser = rootGetters['auth/getProfile'].userName;
    return state.layout.createdby === currentUser || state.currentRow?.createdBy === currentUser;
  },
};

const actions = {
  async initialize({ dispatch, commit }, id) {
    await dispatch('fetchLayouts');
    await dispatch('fetchSeries');
    await dispatch('fetchBusinessRules');
    await dispatch('fetchCharts');
    await dispatch('fetchLocations');
    await dispatch('fetchVariants');
    if (!Number.isNaN(Number(id))) dispatch('fetchLayout', id);
    else commit('reset');
  },
  async fetchLayout({ commit }, id) {
    try {
      const layout = state.VABaseStore.layouts.find(({ isUserDefaultLayout }) => isUserDefaultLayout);
      const { data } = await VA_API.get(`layouts/${id}/chart`);
      // Sets the users default layout if matches
      if (layout?.id === Number(id)) data.isUserDefaultLayout = true;
      const ruleLCS = data.grids.find(({ name }) => name === 'ruleLocationCount');
      data.ruleLocationChartSeries = ruleLCS?.columns || [];
      commit('setLayout', data);
    } catch (err) {
      this.$notify('Error getting layout', 'error');
      console.error(err);
    }
  },
  async fetchSeries({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('series');
      commit('setSeries', data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchBusinessRules({ commit }) {
    try {
      const { data: { data } } = await VA_API.get('business-rules');
      commit('setRules', data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCharts({ commit }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('charts');
      if (Array.isArray(data)) commit('setCharts', data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchChartSeries({ commit }, seriesId) {
    try {
      const { data: { data } } = await STRUCTURES_API.get(`series/${seriesId}/chart-series`);
      if (Array.isArray(data)) {
        data.forEach((chartSeries) => {
          chartSeries.configuration = {};
        });
        commit('setChartSeries', data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  changeSelectedLayoutChart({ commit, state }, value) {
    const idx = state.layout.charts.findIndex((c) => c.name === value.name);
    commit('setLayoutChartIndex', idx);
  },
  async saveLayout({ dispatch, state }) {
    try {
      const payload = {
        name: state.layout.name,
        description: state.layout.description,
        userOnlyFlag: state.layout.userOnlyFlag,
        configuration: {
          defaultLocation: state.layout.defaultLocation,
          defaultVariant: state.layout.defaultVariant,
          data: state.layout.charts,
          ruleLocationChartSeries: state.layout.ruleLocationChartSeries,
        },
      };
      const { isUserDefaultLayout } = state.layout;
      const { data } = await VA_API.post('layouts', payload);
      data.isUserDefaultLayout = isUserDefaultLayout;
      await dispatch('updateDefaultLayout', data);
    } catch (err) {
      console.error(err);
      this.$notify('Error saving new layout', 'error');
    }
  },
  async updateLayout({ state, dispatch }, isAuthor) {
    try {
      const ruleLocationChartSeries = state.layout.ruleLocationChartSeries.map((cs) => {
        delete cs.label;
        delete cs.value;
        return cs;
      });
      const payload = {
        id: state.layout.id,
        name: state.layout.name,
        description: state.layout.description,
        userOnlyFlag: state.layout.userOnlyFlag,
        configuration: {
          defaultLocation: state.layout.defaultLocation,
          defaultVariant: state.layout.defaultVariant,
          ruleLocationChartSeries,
          data: state.layout.charts.map((chart) => ({
            id: chart.id,
            name: chart.name,
            selected: (chart.selected ?? chart.configuration?.selected) || false,
            leftAxisTitle: (chart.leftAxisTitle ?? chart.configuration?.leftAxisTitle) || '',
            rightAxisTitle: (chart.rightAxisTitle ?? chart.configuration?.rightAxisTitle) || '',
            leftAxisFormat: (chart.leftAxisFormat ?? chart.configuration?.leftAxisFormat) || '',
            rightAxisFormat: (chart.rightAxisFormat ?? chart.configuration?.rightAxisFormat) || '',
            chartSeries: chart.chartSeries.map((chartSeries) => ({
              name: chartSeries.name || chartSeries.chartSeriesName,
              chartSeriesName: chartSeries.name || chartSeries.chartSeriesName,
              ...chartSeries,
            })),
          })),
        },
      };
      const { isUserDefaultLayout } = state.layout;

      if (!isAuthor) {
        const { data } = await VA_API.get(`layouts/${payload.id}`, payload);
        data.isUserDefaultLayout = isUserDefaultLayout;
        await dispatch('updateDefaultLayout', data);
      } else {
        const { data } = await VA_API.put(`layouts/${payload.id}`, payload);
        data.isUserDefaultLayout = isUserDefaultLayout;
        await dispatch('updateDefaultLayout', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Error updating layout', 'error');
    }
  },
  async updateDefaultLayout({ state }, layout) {
    if (layout.isUserDefaultLayout) {
      await updateUserResource('visualAnalytics', 'isUserDefaultLayout', layout);
    } else {
      const data = await fetchUserResource('visualAnalytics', 'isUserDefaultLayout');
      if (layout && data && (data.id === layout.id)) { // remove default layout only if layouts are the same
        await updateUserResource('visualAnalytics', 'isUserDefaultLayout', {});
      }
    }
  },
  async addChart({ commit, state }, chart) {
    if (chart.id === undefined) {
      const { data } = await STRUCTURES_API.post('charts', chart);
      chart = data;
      chart.selected = chart.configuration.selected;
      chart.expanded = chart.configuration.expanded;
      chart.leftAxisTitle = chart.configuration.leftAxisTitle ?? '';
      chart.rightAxisTitle = chart.configuration.rightAxisTitle ?? '';
      chart.leftAxisFormat = chart.configuration.leftAxisFormat ?? '';
      chart.rightAxisFormat = chart.configuration.rightAxisFormat ?? '';
    }
    Vue.set(chart, 'chartSeries', []);
    commit('addChart', chart);
    commit('setLayoutChartIndex', state.layout.charts.length - 1);
  },
  async updateChart({ commit }, chart) {
    const { data } = await STRUCTURES_API.put(`charts/${chart.id}`, chart);
    chart = data;
    chart.selected = chart.configuration.selected;
    chart.expanded = chart.configuration.expanded;
    chart.leftAxisTitle = chart.configuration.leftAxisTitle;
    chart.rightAxisTitle = chart.configuration.rightAxisTitle;
    chart.leftAxisFormat = chart.configuration.leftAxisFormat;
    chart.rightAxisFormat = chart.configuration.rightAxisFormat;
    commit('updateChart', chart);
  },
  async addChartSeries({ commit }, chartSeries) {
    if (chartSeries.id === undefined) {
      const { data } = await STRUCTURES_API.post(`series/${chartSeries.seriesId}/chart-series`, chartSeries);
      chartSeries = data;
      chartSeries.format = chartSeries.configuration.format;
      chartSeries.selected = chartSeries.configuration.selected;
      chartSeries.isDailySummary = chartSeries.configuration.isDailySummary;
      chartSeries.aggregateType = chartSeries.configuration.aggregateType;
      chartSeries.dailyAggregation = chartSeries.configuration.dailyAggregation;
      chartSeries.hourlyGridTotalOP = chartSeries.configuration.hourlyGridTotalOP;
      chartSeries.axis = chartSeries.configuration.axis;
    }
    commit('addChartSeries', chartSeries);
  },
  async updateChartSeries({ commit }, chartSeries) {
    chartSeries.format = chartSeries.configuration.format;
    chartSeries.selected = chartSeries.configuration.selected;
    chartSeries.ruleFlashFlag = chartSeries.configuration.ruleFlashFlag;
    chartSeries.isDailySummary = chartSeries.configuration.isDailySummary;
    chartSeries.aggregateType = chartSeries.configuration.aggregateType;
    chartSeries.dailyAggregation = chartSeries.configuration.dailyAggregation;
    chartSeries.hourlyGridTotalOP = chartSeries.configuration.hourlyGridTotalOP;
    chartSeries.axis = chartSeries.configuration.axis;
    commit('updateChartSeries', chartSeries);
  },
  async copyLayout({ dispatch, state }, copy) {
    try {
      const { data } = await VA_API.get(`layouts/${state.currentRow.id}/chart`);
      const findRelatedChartSeriesRightAxisName = (chartSeriesName, defaultReturn) => state.charts
        .find((e) => e.name === chartSeriesName)?.configuration?.rightAxisTitle ?? defaultReturn;
      const payload = {
        name: copy.name,
        description: copy.description,
        default: false,
        configuration: {
          defaultLocation: data.defaultLocation,
          defaultVariant: data.defaultVariant,
          data: data.charts.map((chart) => ({
            id: chart.id,
            name: chart.name,
            selected: chart.selected || false,
            leftAxisTitle: chart.leftAxisTitle || chart.name,
            rightAxisTitle: chart.rightAxisTitle || findRelatedChartSeriesRightAxisName(chart.name, ''),
            chartSeries: chart.chartSeries.map((chartSeries) => ({
              name: chartSeries.name || chartSeries.chartSeriesName,
              chartSeriesName: chartSeries.name || chartSeries.chartSeriesName,
              ...chartSeries,
            })),
          })),
        },
      };
      await VA_API.post('layouts', payload);
      dispatch('fetchLayouts');
    } catch (err) {
      this.$notify('Error copying layout', 'error');
      console.error(err);
    }
  },
  async deleteLayout({ commit, state, dispatch }) {
    if (!state.currentRow) return;
    try {
      await VA_API.delete(`layouts/${state.currentRow.id}`);
      if (state.currentRow.isUserDefaultLayout) {
        const basicLayout = state.layouts.find(({ name }) => name === 'Basic Chart');
        if (basicLayout) {
          basicLayout.isUserDefaultLayout = true;
          await dispatch('updateDefaultLayout', basicLayout);
        }
      }
      commit('deleteLayout');
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  reset(state) {
    state.layout = clone(defaultLayout);
  },
  deleteLayout(state) {
    const layoutIdx = state.layouts.findIndex(({ id }) => id === state.currentRow.id);
    state.layouts.splice(layoutIdx, 1);
    state.currentRow = null;
  },
  addChart(state, value) {
    state.layout.charts.push(value);
  },
  addChartSeries(state, value) {
    state.layout.charts[state.layoutChartIndex].chartSeries.push(value);
  },
  updateChartSeries(state, value) {
    const chartChartSeries = state.layout.charts[state.layoutChartIndex].chartSeries;
    const chartSeriesIdx = chartChartSeries.findIndex(({ id }) => id === value.id);
    if (chartSeriesIdx === -1) {
      chartChartSeries.push(value);
    } else {
      chartChartSeries.splice(chartSeriesIdx, 1, value);
    }
  },
  updateChart(state, value) {
    const { charts } = state.layout;
    const chartIdx = charts.findIndex(({ id }) => id === value.id);
    if (chartIdx === -1) {
      value.chartSeries = [];
      charts.push(value);
    } else {
      const { chartSeries } = charts[state.layoutChartIndex];
      value.chartSeries = chartSeries;
      charts.splice(chartIdx, 1, value);
    }
  },
  removeChart(state, value) {
    const { charts } = state.layout;
    state.layout.charts = charts.filter((v) => v.id !== value.id);
    state.layoutChartIndex = null;
  },
  removeChartSeries(state, value) {
    const { chartSeries } = state.layout.charts[state.layoutChartIndex];
    state.layout.charts[state.layoutChartIndex].chartSeries = chartSeries.filter((v) => v.name !== value.name);
  },
  ...createMutations(
    'currentRow',
    'layouts',
    'charts',
    'layout',
    'layoutChartIndex',
    'series',
    'rules',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};