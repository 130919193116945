<template>
  <div>
    <el-button type="text" size="mini" @click="setContractList({ visible: true, rowIndex: scope.rowIndex, row: scope.row, column: column})">
      {{ count }}
    </el-button>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('etag/tag');

export default {
  name: 'TagCreateContractCellLink',
  props: ['scope', 'column'],
  computed: {
    count() {
      return this.scope.row[this.column.prop];
    },
  },
  methods: mapActions({
    setContractList: 'changeContractList',
  }),
};
</script>
