import {
  coordinatorList,
  resourcesTagbox,
  resourceType,
  samcHeaders,
  tradeDateRange,
  gridConfig,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADING DATE',
    dataType: 'string',
    dataField: 'tradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC ID',
    dataType: 'string',
    dataField: 'resource',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC TYPE',
    dataType: 'string',
    dataField: 'rsrc_type',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INT VALUE',
    dataType: 'number',
    dataField: 'intValue',
    summaryType: 'sum',
    format: { type: 'fixedPoint', precision: 3 },
    area: 'data',
    expanded: true,
  }, {
    caption: 'BD',
    dataType: 'string',
    dataField: 'description',
    area: 'column',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    expanded: true,
    area: 'filter',
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'number',
    dataField: 'tradingInterval',
    area: 'filter',
  }, {
    caption: 'FILE TYPE',
    dataType: 'string',
    dataField: 'fileType',
    area: 'row',
    expanded: true,
  }],
  summaryFields: [{
    caption: 'TRADING DATE',
    dataType: 'string',
    dataField: 'tradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC ID',
    dataType: 'string',
    dataField: 'resource',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC TYPE',
    dataType: 'string',
    dataField: 'rsrc_type',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INT VALUE',
    dataType: 'number',
    dataField: 'intValue',
    summaryType: 'sum',
    format: { type: 'fixedPoint', precision: 3 },
    area: 'data',
    expanded: true,
  }, {
    caption: 'BD',
    dataType: 'string',
    dataField: 'description',
    area: 'column',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    expanded: true,
    area: 'filter',
  }, {
    caption: 'COMPARE TYPE',
    dataType: 'string',
    dataField: 'fileType',
    area: 'row',
    expanded: true,
  }, {
    caption: 'BA',
    dataType: 'string',
    dataField: 'ba',
    expanded: true,
    area: 'filter',
  }],
  searchFiltersConfig: {
    detailType: {
      label: '',
      value: 'summaryView',
      type: 'radio',
      options: [{ value: 'summaryView', label: 'Summary View' }, { value: 'detailView', label: 'Detail View' }],
      watchers: [
        {
          propertyToWatch: 'detailTypeSelected',
          handler(newValue, oldValue) {
            this.config.resourceList.required = (newValue !== 'ALL') && (newValue !== 'summaryView');
            this.config.resourceList.maxLimit = (newValue === 'summaryView') ? 30 : 6;
            this.params.resourceListSelected = [];
          },
        }],
    },
    samcHeaders,
    tradeDateRange,
    coordinatorList,
    resourceList: { ...resourcesTagbox, maxLimit: 6 },
    resourceType: {
      ...resourceType,
      watchers: [
        {
          propertyToWatch: 'resourceTypeSelected',
          handler(newValue, oldValue) {
            this.$store.commit('ciso/setParams', ['resourceList', this.params.resourceListUnfiltered]);
            if (newValue !== 'ALL') {
              let filteredResources = [];
              if (newValue === 'TIE') {
                filteredResources = this.params.resourceListUnfiltered
                  .filter((rsrc) => rsrc.label.includes('(ITIE)') || rsrc.label.includes('(ETIE)'));
              } else {
                filteredResources = this.params.resourceListUnfiltered
                  .filter((rsrc) => rsrc.label.includes(`(${newValue})`));
              }
              this.$store.commit('ciso/setParams', ['resourceList', filteredResources]);
            }
            this.config.resourceList.required = (newValue !== 'ALL') && (this.params.detailTypeSelected !== 'summaryView');
            this.params.resourceListSelected = newValue === 'ALL' ? [] : this.params.resourceListSelected;
          },
        },
      ],
    },
    fileTypes: {
      required: true,
      ...caisoStatementFileTypes,
    },
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
};
