import {
  coordinatorList, tradeDateRange, meterSubmitUnitList,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
  exportXlsx: true,
  exportCsv: true,
  tableOptions: true,

};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    meterSubmitUnitList,
  },
  submitHeadersConfig: {
    name: 'submitHeaders',
    columns: [
      {
        label: 'SCID',
        prop: 'scid',
        width: 90,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Resource ID',
        prop: 'resourceId',
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Measurement Quality',
        prop: 'measurementQuality',
        width: 160,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'UOM',
        prop: 'uom',
        width: 64,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Interval Length',
        prop: 'intervalLength',
        width: 160,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Measurement Type',
        prop: 'measurementType',
        width: 160,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'GMT End Time',
        prop: 'gmtEndTime',
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Trading Date',
        prop: 'tradingDate',
        format: 'yyyy-MM-dd',
        dataType: 'datetime',
        width: 140,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Trading Hour',
        prop: 'tradingHour',
        width: 140,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Trading Interval',
        prop: 'tradingInterval',
        width: 140,
        editable: false,
        filter: true,
        filterType: 'selector',
      }, {
        label: 'Original Value',
        prop: 'origVal',
        editable: false,
      }, {
        label: 'Backup Value',
        prop: 'backupVal',
        editable: false,
      }, {
        label: 'Override Value',
        prop: 'overrideVal',
        editable: true,
      }, {
        label: 'Value',
        prop: 'val',
        editable: false,
      }, {
        label: 'Message',
        prop: 'message',
        editable: false,
        filter: true,
        filterType: 'selector',
      }],
    options,
  },
  mappConfig: {
    name: 'mapping',
    columns: [{
      label: 'SCID',
      prop: 'scid',
      width: 100,
      filterable: true,
      sortable: true,
    }, {
      label: 'Resource ID',
      prop: 'resourceId',
      width: 400,
      filterable: true,
      sortable: true,
    }, {
      label: 'MSID',
      prop: 'msid',
      width: 300,
      filterable: true,
      sortable: true,
    }, {
      label: 'CHANNEL',
      prop: 'channel',
      width: 100,
      filterable: true,
      sortable: true,
    }, {
      label: 'Multiplier',
      prop: 'multiplier',
      width: 140,
      filterable: true,
      sortable: true,
    }, {
      label: 'MultiplierDenominator',
      prop: 'multiplierDenominator',
      width: 160,
      filterable: true,
      sortable: true,
    }, {
      label: 'PMAX',
      prop: 'pmax',
      width: 100,
      filterable: true,
      sortable: true,
    }, {
      label: 'PMIN',
      prop: 'pmin',
      width: 100,
      filterable: true,
      sortable: true,
    }, {
      label: 'SourceSystem',
      prop: 'sourceSystem',
      width: 160,
      filterable: true,
      sortable: true,
    }, {
      label: 'MeterAggregationType',
      prop: 'meterAggregationType',
      width: 160,
      filterable: true,
      sortable: true,
    }, {
      label: 'ConvertToMWH',
      prop: 'convertToMWH',
      width: 100,
      filterable: true,
      sortable: true,
    }, {
      label: 'MeterDescription',
      prop: 'meterDescription',
      width: 160,
      filterable: true,
      sortable: true,
    }],
    options,
  },
  tabs: [{
    title: 'Data',
    component: 'mris-pre-submit-data',
  }, {
    title: 'Mapping',
    component: 'mris-pre-submit-mapping',
  }],
};