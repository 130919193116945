import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_CMRI_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchResourceForecast({ commit }, parameters) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      rsrcId: parameters.resourceListSelected.join(','),
      startDate,
      endDate,
    };
    try {
      const { data } = await CISO_CMRI_API.get('/resource-forecast', { params });
      data.forEach((obj) => {
        obj.gmtMarketStartTime = moment(obj.gmtMarketStartTime).utc().format('YYYY-MM-DD HH:mm');
        obj.tzGmtStartTime = moment(obj.tzGmtStartTime).utc().format('YYYY-MM-DD HH:mm');
        obj.tzGmtEndTime = moment(obj.tzGmtEndTime).utc().format('YYYY-MM-DD HH:mm');
      });
      commit('setTableData', data);
    } catch (error) {
      this.$notify('Failed to Load Resource Forecast', 'error');
    }
  },
};

const mutations = {
  setTableData(state, tableData) {
    state.tableData = tableData;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};