import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import {
  resourcesTagboxMeter, resourceType, tradeDateRange, coordinatorList, meterRsrcScList,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { CISO_METER_API } from '@/api';

const config = {
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'datetime',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'METER SYSTEM ID',
    dataField: 'msid',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'val',
    dataType: 'number',
    customFormat: 'meter.fixedPoint',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'STATUS',
    dataField: 'status',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CHANNEL',
    dataField: 'channel',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'UOM',
    dataField: 'udm',
    dataType: '',
    area: 'filter',
    expanded: true,
    customizeText(info) {
      if (info.value === null) return '0';
      return info.value;
    },
  }, {
    caption: 'METER TYPE',
    dataField: 'meterType',
    dataType: '',
    area: 'filter',
    expanded: true,
  }],
};

const state = {
  tableData: [],
  tableConfig: config.fields,
  searchFiltersConfig: {
    tradeDateRange,
    meterRsrcScList,
    coordinatorList: { ...coordinatorList, required: true },
    resourceType: {
      ...resourceType,
      watchers: [
        {
          propertyToWatch: 'resourceTypeSelected',
          handler(newValue, oldValue) {
            this.$store.commit('ciso/setParams', ['resourceList', this.params.resourceListUnfiltered]);
            if (newValue !== 'ALL') {
              let filteredResources = [];
              if (newValue === 'TIE') {
                filteredResources = this.params.resourceListUnfiltered
                  .filter((rsrc) => rsrc.label.includes('(ITIE)') || rsrc.label.includes('(ETIE)'));
              } else {
                filteredResources = this.params.resourceListUnfiltered
                  .filter((rsrc) => rsrc.label.includes(`(${newValue})`));
              }
              this.$store.commit('ciso/setParams', ['resourceList', filteredResources]);
            }
            this.config.resourceList.required = (newValue !== 'ALL' && this.params.systemTypesSelected !== 'MRIS');
            this.params.resourceListSelected = newValue === 'ALL' ? [] : this.params.resourceListSelected;
          },
        },
      ],
    },
    resourceList: { ...resourcesTagboxMeter, maxLimit: 30 },
    systemTypes: {
      label: 'System Type',
      value: 'MRIS',
      type: 'select',
      clearable: true,
      required: true,
      async fetchData() {
        try {
          const { data: { meterSourceSystem } } = await CISO_METER_API.get('/meter/source-systems');
          return meterSourceSystem.map(({ sourceSystem }) => ({ value: sourceSystem, label: sourceSystem }));
        } catch (error) {
          vue.$notify('Failed to load Meter System Types', 'error');
          console.log(error);
        }
        return {};
      },
      options: [],
      watchers: [{
        propertyToWatch: 'meterSystemTypeOptions',
        handler(newValue, oldValue) {
          const mappedValues = newValue.map((x) => ({ label: x.system, value: x.system }));
          this.$store.commit('ciso/setParams', ['systemTypes', mappedValues]);
          this.$store.commit('ciso/setParams', ['systemTypesSelected', mappedValues !== null ? mappedValues[0].value : null]);
          return true;
        },
      }, {
        propertyToWatch: 'systemTypesSelected',
        handler(newValue, oldValue) {
          this.config.resourceList.required = (newValue !== 'MRIS' && this.params.resourceTypeSelected !== 'ALL');
          return true;
        },
      }],
    },
    isNegativeChannelOne: {
      label: 'Negate Channel 1',
      value: false,
      type: 'checkbox',
    },
  },
};

const actions = {
  async fetchMrisMeterData({ commit }, parameters = {}) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      resourceType: parameters.resourceTypeSelected,
      resourceId: parameters.resourceListSelected ? parameters.resourceListSelected.join() : '',
      dataSource: parameters.systemTypesSelected,
      SCs: parameters.coordinatorListSelected,
    };

    try {
      const { data: { data } } = await CISO_METER_API.get('/meter', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.tradingDate = getMomentDateString(x.tradingDate);
          if (parameters.isNegativeChannelOneSelected) {
            x.val = x.channel === 1 ? -x.val : x.val;
          }
        });
        commit('setTableData', data);
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Meter Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};