export default {
  columns: [
    {
      caption: 'BDORDER',
      dataField: 'ord',
      dataType: 'number',
      area: 'column',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'BDNAME',
      dataField: 'bd',
      dataType: '',
      area: 'column',
      expanded: true,
    }, {
      caption: 'AREF',
      dataField: 'aref',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'METERID',
      dataField: 'meter',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TRADINGDATE',
      dataField: 'dt',
      dataType: 'date',
      format: 'MM/dd/yyyy',
      area: 'row',
      expanded: true,
    }, {
      caption: 'TRADINGHOUR',
      dataField: 'he',
      dataType: 'number',
      area: 'row',
      expanded: true,
    }, {
      caption: 'TRADING INTERVAL',
      dataField: 'itvl',
      dataType: 'number',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CompareType',
      dataField: 'comp',
      dataType: 'string',
      area: 'row',
      expanded: true,
    }, {
      caption: 'VAL',
      dataField: 'val',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: '#.#####',
    },
  ],
};