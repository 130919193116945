import { STRUCTURES_API } from '@/api';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', width: 300, label: 'Name', sortable: true,
    }, {
      prop: 'description', width: 300, label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/event-types').then(({ data }) => {
      commit('loadTableData', data.eventTypes);
      this.$notify(`${data.eventTypes.length} Event Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Event Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postEventType({ state }, eventType) {
    STRUCTURES_API.post('/event-types', eventType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Event Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Event Type', 'error');
    });
  },
  updateEventType({ commit }, eventType) {
    STRUCTURES_API.put(`/event-types/${eventType.id}`, eventType).then(({ data }) => {
      this.$notify('Event Type Updated', 'success');
      commit('updateEventType', data);
    }).catch((err) => {
      this.$notify('Failed to Update Event Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/event-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      this.$notify('Event Type Removed', 'success');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    }).catch((err) => {
      this.$notify('Failed to Remove Event Type', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit, state }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateEventType(state, eventType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === eventType.id);
    state.tableData.splice(rowIndex, 1, eventType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, eventTypes) {
    state.tableData = eventTypes;
  },
  createTableRow(state) {
    const eventType = clone(state.nullTableRow);
    eventType.id = state.tableKey++;
    eventType.createdBy = this.getters['auth/getProfile'].userName;
    eventType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = eventType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter(eventType => eventType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};