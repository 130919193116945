<template>
  <pscs-dialog-dx
    width="830px"
    height="40%"
    title="Errors"
    :visible="getErrorDialogVisibilityFlag"
    :submit="() => setErrorDialogVisibleFlag(false)"
    :ok-button-visible="false"
    @close="changeErrorDialogVisibilityFlag">
    <div>
      <div v-if="getErrorMessagesTableData.length > 0">
        <pscs-callout
          type="danger"
          title="Messages"
          :data="getErrorMessagesTableData"
          data-property="message" />
      </div>
      <div>
        <p>Details</p>
        <pscs-table-dx
          name="noteErrorDetails"
          :config="getErrorDetailsTableConfiguration"          
          :data-source="getErrorDetailsTableData" />
      </div>
    </div>
  </pscs-dialog-dx>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('notification');

export default {
  computed: mapGetters([
    'getErrorDialogVisibilityFlag',
    'getErrorDetailsTableConfiguration',
    'getErrorDetailsTableData',
    'getErrorMessagesTableData',
  ]),
  methods: {
    ...mapActions(['changeErrorDialogVisibilityFlag']),
    ...mapMutations(['setErrorDialogVisibleFlag']),
  },
};
</script>
