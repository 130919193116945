import moment from 'moment';
import { BILLING_REF_API, BILLING_STLMTS_API } from '@/api';
import { has, cloneDeep, buildApiQuery } from '@/utils/dataUtil';

const state = {
  queryParams: [],
  bds: [],
  sql: '',
  tableData0: [],
  tableData1: [],
  tableData2: [],
  tableData3: [],
  tableData4: [],
  tableDataFinal: [],
};

const getters = {
  getBDList: (state) => state.bds.map(({ id, billDeterminantName, chargeCodeName }) => ({ value: id, label: `${billDeterminantName} (${chargeCodeName})` })),
};

const actions = {
  async fetchDeterminants({ dispatch, commit }, params) {
    try {
      const queryParams = { chargeCodes: params.chargeCode };
      commit('setQueryParams', params);
      const response = await BILLING_REF_API.get(buildApiQuery('/bill-determinants?', queryParams));
      commit('setBDs', response.data.data);
    } catch (error) {
      this.$notify('Failed to load CB Drmt Debug', 'error');
    }
  },
  async fetchDrmtDebug({ dispatch, commit, state }, selectedBD) {
    try {
      state.queryParams.bdId = selectedBD;
      const bd = state.bds.filter((element) => element.id === selectedBD)[0];
      state.queryParams.bdCalcTypeId = bd.calcTypeId;
      const response = await BILLING_STLMTS_API.put('/statements/drmt-debug', state.queryParams);
      commit('setSql', response.data.calcSql);
      commit('setData0', response.data.data0);
      commit('setData1', response.data.data1);
      commit('setData2', response.data.data2);
      commit('setData3', response.data.data3);
      commit('setData4', response.data.data4);
      commit('setDataFinal', response.data.dataFinal);
    } catch (error) {
      this.$notify('Failed to load CB Drmt Debug', 'error');
    }
  },
};

const mutations = {
  setBDs(state, value) {
    state.bds = value;
  },
  setQueryParams(state, value) {
    state.queryParams = value;
  },
  setSql(state, value) {
    state.sql = value;
  },
  setData0(state, value) {
    state.tableData0 = value;
  },
  setData1(state, value) {
    state.tableData1 = value;
  },
  setData2(state, value) {
    state.tableData2 = value;
  },
  setData3(state, value) {
    state.tableData3 = value;
  },
  setData4(state, value) {
    state.tableData4 = value;
  },
  setDataFinal(state, value) {
    state.tableDataFinal = value;
  },
  reset(state) {
    state.sql = '';
    state.tableData0 = [];
    state.tableData1 = [];
    state.tableData2 = [];
    state.tableData3 = [];
    state.tableData4 = [];
    state.tableDataFinal = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};