import { buildApiQuery } from '@/utils/dataUtil';
import { PPA_SOURCE_API } from '@/api';

const state = {
  guideText: '',
};

const actions = {
  async fetchGuideData({ commit }, parameters = {}) {
    const params = {
      chargeCode: parameters.ppaChargeCodeSelectSelected,
    };
    try {
      const { data: { data } } = await PPA_SOURCE_API.get(buildApiQuery('/billing-determinant/guide?', params));
      commit('setGuideText', data.data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load PPA Charge Ref Data', 'error');
    }
  },
};

const mutations = {
  setGuideText(state, item) {
    state.guideText = item;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};