import Vue from 'vue'
import router from "../../router";
import store from '../../store';

Vue.component('base-schedule-status-cell-ag',{
    router,
    template: `
    <div style="text-align: right; padding-right: 10px" >    
        <i style="font-size: 15px">{{Value}}</i>
        <i v-if="isReady" class="fa fa-file fa-lg" style="color: grey" aria-hidden="true"></i>     
        <i v-if="isInProgress" class="fa fa-circle-o-notch fa-spin fa-fw fa-lg"  style="color: grey"></i>  
        <i v-if="isEmpty" class="fa fa-minus fa-lg" aria-hidden="true" style="color: grey"></i>
        <i v-if="greenCheck" class="fa fa-check fa-lg" aria-hidden="true" style="color: #9cd433"></i> 
        <i v-if="greenCircleCheck" class="fa fa-check-circle fa-lg" aria-hidden="true" style="color: #9cd433"></i> 
        <i v-if="errored" class="fa fa-times fa-lg" aria-hidden="true" style="color: red"></i>
        <i v-if="redExcalamtion" class="fa fa-exclamation fa-lg" aria-hidden="true" style="color: red"></i>
        <i v-if="marketClosed" class="fa fa-window-close fa-lg" aria-hidden="true" style="color: red"></i>
    </div>`,
    computed:{
            isReady() {
                if(this.params)           
                return this.params.data[this.params.colDef.field].status === 'READY';
            },
            isInProgress() {
                if(this.params)
                return this.params.data[this.params.colDef.field].status === 'IN_PROGRESS';            
            },
            isEmpty() {
                if(this.params)            
                return (this.params.data[this.params.colDef.field].status === null);
            },
            errored(){
                if(this.params){
                let obj = this.params.data[this.params.colDef.field];            
                return (obj.previousStatus === 'READY' || obj.previousStatus === 'SUBMITTED') && obj.status === 'ERRORED' && obj.marketStatus == null;
                }
            },
            marketClosed(){
                if(this.params){
                let obj = this.params.data[this.params.colDef.field];            
                return obj.previousStatus === 'SUBMITTED' && obj.status === 'ERRORED' && obj.marketStatus != null;
                }
            },
            greenCheck(){
                if(this.params){
                let obj = this.params.data[this.params.colDef.field];            
                return obj.previousStatus === 'SUBMITTED' && obj.status === 'RETRIEVED' && (obj.marketStatus == 'V' || obj.marketStatus == 'M');
                }
            },
            greenCircleCheck(){
                if(this.params){
                let obj = this.params.data[this.params.colDef.field];            
                return obj.previousStatus === 'SUBMITTED' && obj.status === 'RETRIEVED' && (obj.marketStatus == 'CV' || obj.marketStatus == 'CM');
                }
            },
            redExcalamtion(){
                if(this.params){
                let obj = this.params.data[this.params.colDef.field];            
                return obj.previousStatus === 'SUBMITTED' && obj.status === 'RETRIEVED' && obj.marketStatus == 'I';
                }
            },
            // hasBid(){
            //     if(this.params){
            //     let obj = this.params.data[this.params.colDef.field];         
            //     return obj.hasBid && this.params.data.variant !== 'PROPOSED';
            //     }
            // },
            // isErrored() {
            //     return this.params.data[this.params.colDef.field].schedule === 'ERRORED';
            // },
            // isEmpty() {            
            //     return (this.params.data.variant === 'NOT_EXISTS');
            // },
            Value() {
                if(this.params){
                switch(this.productType){
                    case 'EN':
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].en
                        return this.params.data[this.params.colDef.field].enActual
                    break;
                    case 'RU':
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].ru
                        return this.params.data[this.params.colDef.field].ruActual
                    break;
                    case 'RD':
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].rd
                        return this.params.data[this.params.colDef.field].rdActual
                    break;
                    case 'SR':
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].sr
                        return this.params.data[this.params.colDef.field].srActual
                    break;
                    case 'NR':
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].nr
                        return this.params.data[this.params.colDef.field].nrActual
                    break;
                    default:
                        if(this.valueTypeFlag)
                            return this.params.data[this.params.colDef.field].en
                        return this.params.data[this.params.colDef.field].enActual
                }
            }
            }     
        },
        methods: {
            valueTypeFlag(e){
                store.dispatch("demo/bsapsummary/getValueTypeFlag", e)
            }, 
            productType(e){
                store.dispatch("demo/bsapsummary/getProductType", e)
            }
        }
    });