import trades from './Trades/sibrTradesStore';
import bids from './Bids/sibrBidsStore';
import cbbids from './CBBids/sibrCBBidsStore';

export default {
  namespaced: true,
  modules: {
    trades,
    bids,
    cbbids,
  },
};