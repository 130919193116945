import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';
import {
  has, HEColumns, removeObjProps, deepClone,
} from '@/utils/dataUtil';
import { hasPermission } from '@/utils/authUtils';
import { createMutations } from '@/utils/vuexHelper';
import cellColors from '../../util';

const mwhReportTableConfig = {
  tableName: 'mwhReport3',
  columns: [{
    prop: 'toEntity', label: 'ToEntity', filterable: true, sortable: true,
  }, {
    prop: 'date', width: 125, fixed: 'left', sortable: true,
  }, {
    prop: 'source', width: 125, filterable: true, fixed: 'left', sortable: true,
  }, {
    prop: 'sink', width: 125, filterable: true, fixed: 'left', sortable: true,
  }, {
    prop: 'tagID', width: 200, filterable: true, fixed: 'left', sortable: true,
  }, {
    cellTemplate: 'PscsRouteCellTemplate',
    editorOptions: {
      to: 'ViewTag',
      params: [{ key: 'tagId', dataKey: 'tagID' }, { key: 'entity', dataKey: 'toEntity' }],
      query: {
        handler(data) {
          return { tagSummaryRef: data.id };
        },
      },
    },
    filterable: true,
    label: 'code',
    prop: 'tagCode',
    sortable: true,
    width: 80,
  }, {
    alignment: 'center',
    cellTemplate: 'PscsColoredCellTemplate',
    filterable: true,
    editorOptions: { ...cellColors },
    label: 'status',
    prop: 'state',
    width: 100,
    sortable: true,
  }, {
    prop: 'notes', width: 300, filterable: true, sortable: true,
  }, {
    prop: 'marketPath', label: 'MARKET PATH', width: 200, filterable: true, sortable: true,
  }, {
    prop: 'path', width: 300, filterable: true, sortable: true,
  }, {
    prop: 'gca', width: 100, filterable: true, sortable: true,
  }, {
    prop: 'pse', width: 100, filterable: true, sortable: true,
  }, {
    prop: 'lca', width: 100, filterable: true, sortable: true,
  }, {
    prop: 'gpe', width: 100, filterable: true, sortable: true,
  }, {
    prop: 'lpe', width: 100, filterable: true, sortable: true,
  }, {
    prop: 'startTime', width: 125, sortable: true,
  }, {
    prop: 'endTime', width: 125, sortable: true,
  }, {
    prop: 'profileType', width: 80, visible: false,
  }, {
    prop: 'total', width: 80, sortable: false,
  },
  ...HEColumns({}, true, undefined),
  {
    dstFlag: false,
    he: '25',
    prop: 'he25',
    label: 'HE25',
    configurable: false,
    sortable: false,
    isHourEndingColumn: true,
  },
  ],
  summary: [
    { prop: 'total', summaryType: 'sum', alignment: 'right' },
    ...HEColumns({ summaryType: 'sum', alignment: 'right' }, false, undefined),
    {
      prop: 'he25', summaryType: 'sum', alignment: 'right', visible: true,
    },
  ],
  options: {
    exportExcel: true,
    filterRow: true,
    filterPanel: false,
    filterHeader: true,
  },
};

const state = {
  tableConfiguration: mwhReportTableConfig,
  dateRange: dateStore.toMomentAndZoneFromJSDateArray(dateStore.getDefaultRange(), dateStore.getTimeZone()),
  tableData: [],
  itemData: [],
};

const getters = {
  getTableData: (state) => state.tableData,
  getItemData: (state) => state.itemData,
  hasSettlementsReportFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'etag:bravos_settlements_report', 'write');
  },
};

const actions = {
  emailSettlementsFlatFile({ commit }, item) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, item.tz);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();
    const params = {
      tz: item.tz,
      startTime: start.format(),
      endTime: end.format(),
    };

    if (item.cpse && item.cpse.length > 0) params.cpse = item.cpse;
    if (item.grouping && item.grouping.length > 0) params.grouping = item.grouping;
    if (item.text && item.text.length > 0) params.text = item.text;
    if (item.gca && item.gca.length > 0) params.gca = item.gca;
    if (item.lca && item.lca.length > 0) params.lca = item.lca;
    if (item.source && item.source.length > 0) params.source = item.source;
    if (item.sink && item.sink.length > 0) params.sink = item.sink;
    if (item.notes && item.notes.length > 0) params.notes = item.notes;
    if (item?.marketPath?.length > 0) params.marketPath = item.marketPath;
    if (item?.path?.length > 0) params.path = item.path;

    ETAG_API.get('/reports/settlements', { params }).then(({ data }) => {
      if (has(data, 'message')) {
        this.$notify({ type: 'success', message: data.message });
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  searchAction({ commit, state }, item) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, item.tz);
    commit('setDateRange', momentRange);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();
    const { showCompared } = item;
    const params = {
      tz: item.tz,
      startTime: start.format(),
      endTime: end.format(),
      showCompared,
    };

    if (item.cpse && item.cpse.length > 0) params.cpse = item.cpse;
    if (item.grouping && item.grouping.length > 0) params.grouping = item.grouping;
    if (item.text && item.text.length > 0) params.text = item.text;
    if (item.gca && item.gca.length > 0) params.gca = item.gca;
    if (item.lca && item.lca.length > 0) params.lca = item.lca;
    if (item.source && item.source.length > 0) params.source = item.source;
    if (item.sink && item.sink.length > 0) params.sink = item.sink;
    if (item.notes && item.notes.length > 0) params.notes = item.notes;
    if (item?.marketPath?.length > 0) params.marketPath = item.marketPath;
    if (item?.path?.length > 0) params.path = item.path;

    const props = [
      'endTime',
      'startTime',
      'routeText',
    ];

    if ((start.diff(end, 'days') === 0)) {
      props.push(...HEColumns({}, true, start.toISOString()).map((x) => x.prop));
    } else {
      props.push(...HEColumns({}, true, start).map((x) => x.prop));
    }
    return new Promise((resolve, reject) => {
      ETAG_API.get('/reporting', { params }).then(({ data }) => {
        if (has(data, 'message')) {
          this.$notify({ type: 'success', message: data.message });
        } else {
          const clonedData = data.data.map((row) => JSON.stringify(removeObjProps(deepClone(row), ...props)));
          const clonedTableData = state.tableData.map((row) => JSON.stringify(removeObjProps(deepClone(row), ...props)));
          const isNotEquals = clonedData.length !== clonedTableData.length
        || clonedData.some((obj, idx) => obj !== clonedTableData[idx]);
          if (isNotEquals || showCompared) { commit('tableDataMutation', { data: data.data, showCompared }); }
        }
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

const mutations = {
  tableDataMutation(state, { data, showCompared }) {
    data = dateStore.formatDateTimeFields(data, [
      { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
      { name: 'endTime', format: 'YYYY-MM-DD HH:mm' }]);

    const itemData = {};
    const newData = [];
    state.tableConfiguration.columns.forEach((c) => {
      if (c.label === 'profileType') {
        c.visible = showCompared;
      }
    });

    data.forEach((d) => {
      d.routeText = d.tagID;
      const key = `${d.tagID}_${d.date}_${d.toEntity}`;
      itemData[key] = {};
      // assign mw values to HE columns
      const sD = { ...d, profileType: 'Scheduled', total: d.totalScheduled };
      const cD = { ...d, profileType: 'Curtailed', total: d.totalScheduled - d.total };
      d = { ...d, isActual: true };
      d.values.forEach((v) => {
        const he = `${v.he}`;
        d[`he${he.padStart(2, 0)}`] = v.value;
        sD[`he${he.padStart(2, 0)}`] = v.actualValue;
        cD[`he${he.padStart(2, 0)}`] = v.actualValue - v.value;
        itemData[key][`he${he.padStart(2, 0)}`] = v;
      });
      newData.push({ ...d, profileType: 'Actual' });

      if (showCompared) {
        newData.push(sD);
        newData.push(cD);
      }
    });

    state.itemData = itemData;
    state.tableData = newData;
  },
  setTimeZone(state, value) {
    state.selectedTimeZone = value;
  },
  ...createMutations(
    'dockedConfig',
    'tableConfiguration',
    'dateRange',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
