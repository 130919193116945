import referenceData from '@/components/PPA/ReferenceData/store';
import sourceData from '@/components/PPA/SourceData/store';
import settlements from '@/components/PPA/Settlements/store';
import invoicing from '@/components/PPA/Invoicing/store';
import ppaGuideStore from './Guide/guideStore';

export default {
  namespaced: true,
  state: {},
  modules: {
    referenceData,
    sourceData,
    settlements,
    invoicing,
    ppaGuideStore,
  },
};