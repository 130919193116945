import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { PPA_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  contractList: [],
  nullTableRow: {
    id: -999,
    ppaName: null,
    ppaId: null,
    eimSub: null,
    dataExchange: null,
    schdType: null,
    ruleName: null,
    headerName: null,
    multiplier: null,
    sch4activeFlag: false,
    sch56activeFlag: false,
    sch4deemedLoadFlag: false,
    schd4shareFlag: false,
    intrachangeActiveFlag: false,
    schdType2: null,
    effectiveDate: null,
    terminationDate: null,
    activeFlag: null,
    updatedBy: null,
    updatedDate: null,
    createdBy: null,
    createdDate: null,
  },
};

const getters = {
  getContractList: (state) => state.contractList.map(({ id, name }) => ({ value: id, label: name })),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchContractList');
  },
  async fetchSchedule({ commit }, parameters = {}) {
    const params = {
      contracts: parameters.ppasSelected,
    };
    try {
      const { data: { data } } = await PPA_REF_API.post('/schedule/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.effectiveDate = getMomentDateString(x.effectiveDate);
          x.terminationDate = x.terminationDate === null ? null : getMomentDateString(x.terminationDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Schedule Data', 'error');
    }
  },
  async fetchContractList({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/ppa');
      commit('loadContractList', data);
    } catch (err) {
      this.$notify('Failed to fetch Contract list', 'error');
      console.error(err);
    }
  },
  async postRecord({ state }, schedule) {
    PPA_REF_API.post('/schedule', schedule).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = (data.terminationDate === null) ? null : getMomentDateString(data.terminationDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      console.error(err);
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  async updateRecord({ commit }, schedule) {
    PPA_REF_API.put(`/schedule/${schedule.id}`, schedule).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = (data.terminationDate === null) ? null : getMomentDateString(data.terminationDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    });
  },
  async deleteTableRow({ dispatch, commit, state }) {
    try {
      await PPA_REF_API.delete(`/schedule/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Remove Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};