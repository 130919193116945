import { IDENTITY_API } from '@/api';
import utils from '@/utils';
import * as notifUtils from './notificationUtil';
import notificationService from './notificationService';

let _client = null;

const state = {
  developmentFlag: false,
  developmentWorkflowLinksFlag: false,
  channelName: '',
  notifications: notificationService,
  visible: false,
  callback: null,
  errorDialogVisibleFlag: false,
  currentItem: null,
  errorDetailTableConfiguration: {
    columns: [
      { prop: 'name', alignment: 'left', width: 200 },
      { prop: 'value', alignment: 'left' },
    ],
  },
  errorMessageTableConfiguration: {
    columns: [
      { prop: 'number' },
      { prop: 'message' },
      { prop: 'code' },
    ],
  },
  disableAllSound: false,
  notificationsEnabled: true,
};

const getters = {
  getDevelopmentFlag: (state) => process.env.NODE_ENV === 'production' ? false : state.developmentFlag,
  getDevelopmentWorkflowLinksFlag: (state) => process.env.NODE_ENV === 'production' ? false : state.developmentWorkflowLinksFlag,
  getMessageCount: (state) => state.notifications.messageCount,
  getVisibleFlag: (state) => state.visible,
  getMessages: (state) => state.notifications.entries,
  getErrorDialogVisibilityFlag: (state) => state.errorDialogVisibleFlag,
  getErrorDetailsTableConfiguration: (state) => state.errorDetailTableConfiguration,
  getErrorDetailsTableData({ currentItem }) {
    if (currentItem === null || !currentItem.error || !currentItem.error.details) return [];
    return currentItem.error.details;
  },
  getErrorMessagesTableConfiguration: (state) => state.errorMessageTableConfiguration,
  getErrorMessagesTableData({ currentItem }) {
    if (currentItem === null || !currentItem.error || !currentItem.error.messages) return [];
    return currentItem.error.messages;
  },
  getSoundDisabled: (state) => state.disableAllSound,
};

const actions = {
  async start({
    state, commit, dispatch, rootState,
  }) {
    if (rootState.auth.validProfile) {
      state.notifications.setProfileGetter(() => rootState.auth.profile);
      const notificationsEnabled = rootState.auth.profile.notificationsEnabled === true;
      dispatch('setNotificationsEnabled', notificationsEnabled);
      if (notificationsEnabled && _client === null) {
        const { Realtime } = await import('ably');
        _client = new Realtime({
          environment: 'power-settlements',
          fallbackHosts: ['a-fallback-us.ably.io', 'b-fallback-us.ably.io'],
          async authCallback(tokenParams, callback) {
            try {
              const { data } = await IDENTITY_API.get('auth/tokens/notification');

              dispatch('startChannel', data.channel);

              if (data.channels) {
                data.channels.forEach((c) => {
                  dispatch('startChannel', c.name);
                });
              }

              callback(null, data.token);
            } catch (error) {
              console.log(error);
              callback(error);
            }
          },
          recover(_, cb) {
          // console.log('notification recovery')
            cb(true);
          },
        });

        _client.connection.on('connected', () => {
        // console.log('notification started');
        });

        _client.connection.on('failed', () => {
          console.log('notification failed');
        });

        _client.connection.on('closed', () => {
        // console.log('notification closed');
        });
      }
    }
  },

  startChannel({ state, dispatch }, channelName) {
    if (state.notificationsEnabled && _client) {
      try {
        const channel = _client.channels.get(channelName);
        channel.subscribe((message) => {
          dispatch('messageReceived', message);
        });
      } catch (err) {
        console.error(err);
      }
    }
  },
  stop() {
    if (state.notificationsEnabled && _client) {
      _client.close();
    }
  },
  show({ commit }) {
    commit('setVisibleFlag', true);
  },
  hide({ commit }) {
    commit('setVisibleFlag', false);
  },
  clear({ commit }) {
    commit('clear');
  },
  removeMessage({ commit }, item) {
    commit('removeMessage', item);
  },
  toggleVisibility({ commit }) {
    commit('toggleVisibility');
  },
  updateMessage({ dispatch, state }, item) {
  },
  callback({ commit }, cb) {
    commit('setCallback', cb);
  },
  changeErrorVisibleFlag({ commit }) {
    commit('setItem', null);
  },
  changeItem({ commit }, item) {
    commit('setItem', item);
  },
  changeErrorDialogVisibilityFlag({ commit }) {
    commit('setErrorDialogVisibleFlag');
  },
  messageReceived({ commit, dispatch, state }, ablyMessage) {
    const messageOptions = { soundEnabled: !state.disableAllSound };
    const notificationItem = state.notifications.createMessage(ablyMessage, messageOptions);

    if (notificationItem) {
      commit('messageReceived', notificationItem);
      if (notificationItem.hasError()) {
        dispatch('alert/error', {
          message: notificationItem.errorMessageText(),
          title: 'Error',
          duration: 500,
        }, { root: true });
      }

      dispatch('notificationReceived', notificationItem);
    }
  },
  developmentAction({ dispatch, commit }, { name }) {
    if (name === 'addworkflow') {
      const tradeFailed = notifUtils.createFailedTradeNotification(1340);
      dispatch('messageReceived', tradeFailed);
    }

    if (name === 'soundlogic') {
      commit('changeSoundLogic');
    }
  },
  notificationReceived({ commit, dispatch }, item) {
    // using global vue instance so that event can be dispatched to any component on the page
    window.vue.$emit('notificationReceived', item);
  },
  toggleSound({ commit }) {
    commit('toggleSound');
  },
};
const mutations = {
  messageReceived(state, ablyMessage) {
    state.notifications.messageReceived(ablyMessage);
  },
  changeSoundLogic(state) {
    state.notifications.topDownSoundLogic = !state.notifications.topDownSoundLogic;
  },
  setVisibleFlag(state, value) {
    state.visible = value;
  },
  clear(state) {
    state.notifications.clear();
  },

  toggleVisibility(state) {
    state.visible = !state.visible;
  },
  setCallback(state, cb) {
    state.callback = cb;
  },
  setItem(state, item) {
    if (item === null) {
      state.currentItem = null;
      state.errorDialogVisibleFlag = false;
    } else {
      state.currentItem = item;
      state.errorDialogVisibleFlag = true;
    }
  },
  setErrorDialogVisibleFlag(state) {
    state.currentItem = null;
    state.errorDialogVisibleFlag = false;
  },
  toggleSound(state) {
    state.disableAllSound = !state.disableAllSound;
  },
  setNotificationsEnabled(state, value) {
    state.notificationsEnabled = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};