import moment from 'moment';
import { clone, sortBy } from '@/utils/dataUtil';
import { DELIVERY_MANAGEMENT_API, STRUCTURES_API } from '@/api';

const state = {
  deliveryGroups: [],
  variants: [],
  selectedDeliveryGroup: {
    id: 0,
    name: '',
    description: '',
    effectiveDate: moment().startOf('day').toISOString(),
    terminationDate: '2099-12-31T00:00:00Z',
    rule: {
      props: [],
    },
    links: [],
  },
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, editable: false,
    }, {
      prop: 'name', label: 'Name', sortable: true, visible: true, editable: true, sortOrder: 'asc', filterable: true,
    }, {
      prop: 'type', label: 'Type', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', dataType: 'date', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'terminationDate', label: 'Termination Date', dataType: 'date', sortable: true, visible: true, editable: true, filterable: true,
    }],
    options: {
      filterHeader: true,
    },
  },
  deliveryGroupDefault: {
    id: 0,
    name: '',
    description: '',
    effectiveDate: moment().startOf('day').toISOString(),
    terminationDate: '2099-12-31T00:00:00Z',
    rule: {
      props: [],
    },
    links: [],
  },
  wizardMode: null, // ADD, EDIT, CLONE
};

const getters = {};

const actions = {
  async fetchVariants({ commit, state }) {
    await STRUCTURES_API.get('/variant').then((response) => {
      if (response.data.data) {
        const distinct = (value, index, self) => self.indexOf(value) === index;
        const distinctList = response.data.data.sort(sortBy('name')).map(({ name }) => name).filter(distinct);
        commit('setVariants', distinctList);
      }
    }).catch((error) => {
      console.error(error);
    });
  },
  async fetchDeliveryGroups({ commit }) {
    try {
      const { data } = await DELIVERY_MANAGEMENT_API.get('groupings');
      const deliveryGroups = data.data.map((d) => {
        const model = JSON.parse(d.model);
        model.id = d.id;
        model.type = d.type;
        model.rule = model.rule || { props: [] };
        return model;
      });

      commit('setDeliveryGroups', deliveryGroups);
    } catch (error) {
      this.$notify('Failed to Load Delivery Groups', 'error');
      console.error(error);
    }
  },
  async updateDeliveryGroup({ commit, state }, deliveryGroup) {
    let isSuccess = true;

    try {
      // map links to fit current API model
      const mappedDeliveryGroup = clone(deliveryGroup);
      mappedDeliveryGroup.effectiveDate = moment(mappedDeliveryGroup.effectiveDate).startOf('day').toISOString();
      mappedDeliveryGroup.terminationDate = moment(mappedDeliveryGroup.terminationDate).startOf('day').toISOString();

      const { data } = await DELIVERY_MANAGEMENT_API.put(`/groupings/${deliveryGroup.id}`, mappedDeliveryGroup);

      // re-map object to fit UI model expectations
      const deliveryGroupModel = JSON.parse(data.model);
      const originalId = deliveryGroupModel.id;
      deliveryGroupModel.id = data.id;
      deliveryGroupModel.type = data.type;
      deliveryGroupModel.rule = deliveryGroupModel.rule || { props: [] };

      commit('updateDeliveryGroup', { originalId, deliveryGroupModel });
      commit('setSelectedDeliveryGroup', clone(state.deliveryGroupDefault));

      this.$notify('Delivery Group Updated', 'success');
    } catch (error) {
      console.log(error);
      isSuccess = false;
      this.$notify('Failed to Update Delivery Group', 'error');
    }

    return isSuccess;
  },
  async createDeliveryGroup({ commit, state }, deliveryGroup) {
    let isSuccess = true;

    try {
      // map links to fit current API model
      const mappedDeliveryGroup = clone(deliveryGroup);
      mappedDeliveryGroup.effectiveDate = moment(mappedDeliveryGroup.effectiveDate).startOf('day').toISOString();
      mappedDeliveryGroup.terminationDate = moment(mappedDeliveryGroup.terminationDate).startOf('day').toISOString();

      const { data } = await DELIVERY_MANAGEMENT_API.post('groupings', mappedDeliveryGroup);

      // re-map object to fit UI model expectations
      const deliveryGroupModel = JSON.parse(data.model);
      deliveryGroupModel.id = data.id;
      deliveryGroupModel.type = data.type;
      deliveryGroupModel.rule = deliveryGroupModel.rule || { props: [] };

      commit('addDeliveryGroup', deliveryGroupModel);
      commit('setSelectedDeliveryGroup', clone(state.deliveryGroupDefault));

      this.$notify('Delivery Group Added', 'success');
    } catch (error) {
      isSuccess = false;
      this.$notify('Failed to Add Delivery Group', 'error');
    }

    return isSuccess;
  },
  async inactivateDeliveryGroup({ commit, state }, id) {
    try {
      await await DELIVERY_MANAGEMENT_API.delete(`groupings/${id}`);
      commit('removeDeliveryGroup');
      commit('setSelectedDeliveryGroup', clone(state.deliveryGroupDefault));
      this.$notify('Delivery Group Removed', 'success');
    } catch (error) {
      this.$notify('Failed to Remove Delivery Group', 'error');
    }
  },
};

const mutations = {
  setVariants(state, value) {
    state.variants = value;
  },
  setDeliveryGroups(state, value) {
    state.deliveryGroups = value.sort(sortBy('name'));
  },
  addDeliveryGroup(state, value) {
    state.deliveryGroups.push(value);
  },
  updateDeliveryGroup(state, value) {
    const index = state.deliveryGroups.findIndex(({ id }) => id === value.originalId);
    if (index !== -1) state.deliveryGroups.splice(index, 1, value.deliveryGroupModel);
  },
  removeDeliveryGroup(state) {
    const index = state.deliveryGroups.findIndex(({ id }) => id === state.selectedDeliveryGroup.id);
    if (index !== -1) state.deliveryGroups.splice(index, 1);
  },
  setSelectedDeliveryGroup(state, value) {
    state.selectedDeliveryGroup = value;
  },
  setWizardMode(state, value) {
    state.wizardMode = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};