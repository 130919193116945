const PORT = window.location.port;

const path = (PORT !== null && PORT !== undefined && PORT.toString().trim() !== '' && PORT.toString() !== '80' && PORT.toString() !== '443')
  ? `${window.location.protocol}//${window.location.hostname}:${PORT}/api/`
  : `${window.location.protocol}//${window.location.hostname}/api/`;

export default {
  BALANCING_AUTHORITY_URL: `${path}balancing-authority`,
  IDENTITY_URL: `${path}identity`,
  ETAG_URL: `${path}etagging`,
  WORKFLOW_URL: `${path}workflow`,
  SCRIPTING_URL: `${path}scripting`,
  CISO_SCHD_URL: `${path}ciso/consolidated-scheduling`,
  CISO_BID_SCHD_URL: `${path}ciso/scheduling/bid-schedules`,
  CISO_BASE_SCHD_URL: `${path}ciso/scheduling/base-schedules`,
  CISO_MASTERDATA_URL: `${path}ciso/master-data`,
  CISO_TRADE_SCHD_URL: `${path}ciso/scheduling/trade-schedules`,
  CISO_SIBR_URL: `${path}ciso/sibr`,
  CISO_ADS_URL: `${path}ciso/market-data/ads`,
  CISO_CMRI_URL: `${path}ciso/market-data/cmri`,
  CISO_SAMC_URL: `${path}ciso/market-data/samc`,
  CISO_METER_URL: `${path}ciso/market-data/meter`,
  CISO_OASIS_URL: `${path}ciso/oasis`,
  CISO_MASTERFILE_URL: `${path}ciso/masterfile`,
  CISO_INTERNAL_URL: `${path}ciso/market-data/internal-data`,
  CISO_CIRA_URL: `${path}ciso/cira`,
  STRUCTURES_URL: `${path}structures`,
  POSITIONS_URL: `${path}positions`,
  CONNECTIONS_URL: `${path}connections`,
  ETRM_URL: `${path}etrm`,
  DELIVERY_MANAGEMENT_URL: `${path}dlvry-mgmt`,
  ISONE_IBT_URL: `${path}isone/ibt`,
  ISONE_METER_URL: `${path}isone/meter`,
  ISONE_PRICES_URL: `${path}isone/prices`,
  ISONE_STATEMENTS_URL: `${path}isone/statements`,
  ERCOT_MRKT_INFO_URL: `${path}ercot/market-information`,
  ERCOT_PRICES_URL: `${path}ercot/prices`,
  ERCOT_SETTLEMENTS_URL: `${path}ercot/settlements`,
  SPP_MARKET_URL: `${path}spp/market-data`,
  SPP_METER_URL: `${path}spp/metering`,
  SPP_STLMT_URL: `${path}spp/settlements`,
  SPP_SCHD_URL: `${path}spp/scheduling`,
  PJM_DATA_MINER_URL: `${path}pjm/data-miner`,
  PJM_INSCHEDULES_URL: `${path}pjm/inschedules`,
  PJM_METER_URL: `${path}pjm/meter`,
  CMS_URL: `${path}cms`,
  VA_URL: `${path}visual-analytics`,
  CISO_OMS_URL: `${path}ciso/oms`,
  CISO_TOOLS_URL: `${path}ciso/tools`,
  BILLING_REF_URL: `${path}contract-billing/reference`,
  BILLING_SOURCE_URL: `${path}contract-billing/source-data`,
  BILLING_STLMTS_URL: `${path}contract-billing/settlements`,
  BILLING_INVOICE_URL: `${path}contract-billing/invoice`,
  BILLING_CALC_URL: `${path}contract-billing/calc`,
  PPA_REF_URL: `${path}ppa/reference`,
  PPA_SOURCE_URL: `${path}ppa/source-data`,
  PPA_STLMTS_URL: `${path}ppa/settlements`,
  PPA_INVOICE_URL: `${path}ppa/invoicing`,
  BIDDING_URL: `${path}bidding`,
  REPORTING_URL: `${path}reporting`,
  PRE_SCHEDULING_URL: `${path}pre-scheduling`,
  PRESCHEDULING_URL: `${path}pre-scheduling`,
  ISO_REF_URL: `${path}iso-reference`,
  APX_SCHD_URL: `${path}apx-scheduling`,
  RECS_MANAGEMENT_URL: `${path}recs-management`,
};