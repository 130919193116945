import { fetchUserResource, updateUserResource } from '@/utils/structuresHelper';
import dateStore from './dateStore';
import caisoStore from './caiso/caisoUtils';
import valueFormatStore from './valueFormatStore';
import userStore from './userStore';

const DT_STORAGE_KEY = 'DFT_OPTIONS_KEY';

const UserStore = function () {
  this.options = {
    dateOptions: {
      style: 'relative',
      single: 'Today',
      range: 'Today',
      tz: 'PPT',
      ldh: false,
    },
    caisoOptions: caisoStore.getDefaults(),
    valueFormatOptions: valueFormatStore.getDefaults(),
    userOptions: userStore.getDefaults(),
  };
  dateStore.setDefaults(this.options.dateOptions);
  caisoStore.setDefaults(this.options.caisoOptions);
  valueFormatStore.setDefaults(this.options.valueFormatOptions);
  userStore.setDefaults(this.options.userOptions);
};

UserStore.prototype.getDefaultOptions = async function () {
  try {
    const data = await fetchUserResource('default', 'options');
    return data;
  } catch (err) {
    return null;
  }
};

UserStore.prototype.setDefaultOptions = function (options, storeInLocalStorage = true) {
  if (options !== null && options !== undefined) {
    this.options = options;
    if (storeInLocalStorage) window.localStorage.setItem(DT_STORAGE_KEY, JSON.stringify(this.options));

    if (this.options.dateOptions !== undefined) {
      dateStore.setDefaults(this.options.dateOptions);
    } if (this.options.caisoOptions !== undefined) {
      caisoStore.setDefaults(this.options.caisoOptions);
    } if (this.options.valueFormatOptions !== undefined) {
      valueFormatStore.setDefaults(this.options.valueFormatOptions);
    } if (this.options.userOptions !== undefined) {
      userStore.setDefaults(this.options.userOptions);
    }
  }
};

UserStore.prototype.loadDefaultOptions = async function () {
  const defaultOptions = window.localStorage.getItem(DT_STORAGE_KEY);
  try {
    this.setDefaultOptions(JSON.parse(defaultOptions), false);
  } catch (err) {
    fetchUserResource('default', 'options');
    const apiOptions = await this.getDefaultOptions();
    this.setDefaultOptions(apiOptions.data);
  }
};

UserStore.prototype.updateDefaultOptions = async function (model) {
  try {
    await updateUserResource('default', 'options', model);
    this.setDefaultOptions(model);
  } catch (error) {
    console.error(error);
  }
};

const user = new UserStore();
user.loadDefaultOptions();
export default user;