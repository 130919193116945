import * as CONSTS from './constants';

export default {
  keys: ['guid'],
  columns: [{
    prop: '',
    label: '',
    width: 40,
    editable: false,
  }, {
    prop: 'market_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH, 
  }, {
    prop: 'market_pse',
    label: 'pse',
    width: CONSTS.MS_PSE_WIDTH,
    minWidth: CONSTS.MS_PSE_MIN_WIDTH,
    maxWidth: CONSTS.MS_PSE_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'PSE',
      valueProperty: 'market_pse_name',
      labelProperty: 'market_pse_name',
    },
  }, {
    prop: 'market_product',
    label: 'product',
    width: CONSTS.USE_PSCS_AG_GRID ? CONSTS.MS_PRODUCT_WIDTH : null,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'Product',
      valueProperty: 'market_product_name',
      labelProperty: 'market_product_name',
    },
  }, {
    prop: 'market_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    minWidth: CONSTS.MISC_INFO_MIN_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  }, {
    prop: 'market_contractCount',
    label: 'c',
    width: CONSTS.CONTRACT_WIDTH,
    minWidth: CONSTS.CONTRACT_MIN_WIDTH,
    maxWidth: CONSTS.CONTRACT_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagCreateContractCellLinkRenderer' : 'tag-create-contract-cell-link',
      overrideReadonly: true,
    },
  }],
  style: { dynamicSizing: false },
  columnList(name) {
    if (name === 'product') {
      return this.registry.productList.filter((p) => p.productTypeID === '1');
    }
  },
  remoteSearch: null,
  registry: { productList: [] },
};