import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { BILLING_REF_API, IDENTITY_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  userList: [],
  nullTableRow: {
    id: -999,
    userName: null,
    limit: 0,
    updatedBy: null,
    updatedDate: null,
    createdBy: null,
    createdDate: null,
  },
};

const getters = {
  getUsers: (state) => state.userList.filter((x) => (!x.email.includes('cookie'))).map(({ userName, firstName, lastName }) => ({ value: userName, label: `${firstName } ${ lastName}` })),
};

const actions = {
  async initialize({ dispatch }) {
    dispatch('fetchLimits');
    dispatch('fetchUserList');
  },
  async fetchLimits({ commit }) {
    try {
      BILLING_REF_API.get('/InvoiceApprovalLimit').then(({ data }) => {
        commit('setTableData', data.data);
      }).catch((err) => {
        console.error(err);
      });
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Price Data', 'error');
    }
  },
  async fetchUserList({ commit }) {
    try {
      const { data } = await IDENTITY_API.get('/users');
      commit('loadUserList', data.users);
    } catch (err) {
      this.$notify('Failed to fetch User list', 'error');
      console.error(err);
    }
  },
  postRecord({ state }, limit) {
    try {
      if (state.tableData.some((e) => e.userName === limit.userName)) {
        throw new Error('User already has a limit configured');
      }
      BILLING_REF_API.post('/InvoiceApprovalLimit', limit).then(({ data }) => {
        state.tableData.push(data);
        this.$notify('Data Added', 'success');
      }).catch((err) => {
        this.$notify('Failed to Add Data', 'error');
      });
    } catch (err) { this.$notify(err, 'error'); }
  },
  async updateRecord({ commit, state }, limit) {
    BILLING_REF_API.put(`/InvoiceApprovalLimit/${state.currentTableRow.id}`, limit).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  async deleteTableRow({ dispatch, commit, state }) {
    try {
      await BILLING_REF_API.delete(`/InvoiceApprovalLimit/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Remove Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  loadUserList(state, users) {
    state.userList = users;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};