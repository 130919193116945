import helpers from '@/utils/helpers';
import * as CONSTS from './constants';

export default {
  columns: [{
    prop: 'market_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH, 
  }, {
    prop: 'market_pse',
    label: 'pse',
    width: CONSTS.MS_PSE_WIDTH,
    minWidth: CONSTS.MS_PSE_MIN_WIDTH,
    maxWidth: CONSTS.MS_PSE_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      list: 'pse',
      placeholder: 'PSE',
      valueProperty: 'market_pse_name',
      labelProperty: 'market_pse_name',
    },
  }, {
    prop: 'market_product',
    label: 'product',
    editable: true,
    width: CONSTS.USE_PSCS_AG_GRID ? CONSTS.MS_PRODUCT_WIDTH : null,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      list: 'product',
      placeholder: 'Product',
      valueProperty: 'market_product_name',
      labelProperty: 'market_product_name',
      clearable: true,
    },
  }, {
    prop: 'market_contractCount',
    label: 'c',
    width: CONSTS.CONTRACT_WIDTH,
    maxWidth: CONSTS.CONTRACT_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagCreateContractCellLinkRenderer' : 'tag-create-contract-cell-link',
      overrideReadonly: true,
    },
  }],
  options: { showAddRemove: true, grayRepeatedRows: true },
  style: { dynamicSizing: false },
  columnList(name) {
    if (name === 'pse') return this.registry.pseList;
    if (name === 'product') return helpers.filter(this.registry.productList, (p) => p.productTypeID === '1');
  },
  registry: { pseList: [], productList: [] },
};