import { CISO_OMS_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import { tradeDateRange } from '@/components/Ciso/BaseStore/CisoConfigs';
import moment from 'moment';
import dateStore from '@/utils/dateStore';
import { cloneDeep } from '@/utils/dataUtil';
import caisoStore from '@/utils/caiso/caisoUtils';
import VABaseStore from '../VABaseStore';

const state = {
  tableData: [],
  isCurrentlyFetchingData: false,
  config: {
    columns: [{
      label: 'Location',
      prop: 'Rsrc_Name',
    }],
    options: {
      multipleSelection: true,
    },
  },
  searchConfig: {
    tradeDateRange,
    locationGroup: {
      label: 'Location Group',
      value: 'ALL',
      type: 'select',
      options: [],
    },
    outageType: {
      label: 'Outage Types',
      value: 'ALL',
      type: 'select',
      options: [
        'ALL',
        'FORCED AMBIENT_DUE_TO_FUEL_INSUFFICIENCY',
        'FORCED AMBIENT_DUE_TO_TEMP',
        'FORCED AMBIENT_NOT_DUE_TO_TEMP',
        'FORCED AVR_EXCITER',
        'FORCED Derate',
        'FORCED ENVIRONMENTAL_RESTRICTIONS',
        'FORCED ICCP',
        'FORCED METERING_TELEMETRY',
        'FORCED OTHER_USE_LIMIT_REACHED',
        'FORCED PLANT_MAINTENANCE',
        'FORCED PLANT_TROUBLE',
        'FORCED POWER_SYSTEM_STABILIZER',
        'FORCED RAMP_RATE',
        'FORCED Rerate',
        'FORCED RTU_RIG',
        'FORCED SHORT_TERM_USE_LIMIT_REACHED',
        'FORCED TRANSITIONAL_LIMITATION',
        'FORCED TRANSMISSION_INDUCED',
        'FORCED UNIT_CYCLING',
        'FORCED UNIT_SUPPORTING_STARTUP',
        'FORCED USE_LIMIT_REACHED',
        'PLANNED AMBIENT_DUE_TO_TEMP',
        'PLANNED AMBIENT_NOT_DUE_TO_TEMP',
        'PLANNED Derate',
        'PLANNED ENVIRONMENTAL_RESTRICTIONS',
        'PLANNED ICCP',
        'PLANNED METERING_TELEMETRY',
        'PLANNED PLANT_MAINTENANCE',
        'PLANNED PLANT_TROUBLE',
        'PLANNED Rerate',
        'PLANNED RTU_RIG',
        'PLANNED TRANSITIONAL_LIMITATION',
        'PLANNED TRANSMISSION_INDUCED',
        'PLANNED UNIT_SUPPORTING_STARTUP',
      ],
    },
    barType: {
      label: '',
      value: 'Overlap',
      type: 'radio',
      options: ['Overlap', 'Side By Side'],
    },
  },
};

const getters = {};

const actions = {
  async initialize({ dispatch, state }) {
    state.searchConfig.locationGroup.value = caisoStore.resourceGroup;
    await dispatch('fetchLocationGroups');
  },
  async fetchOutages({ commit }, { tradeDateRangeSelected, outageTypeSelected, locationGroupSelected }) {
    try {
      const tz = dateStore.getTimeZone();
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(cloneDeep(tradeDateRangeSelected), tz);
      const start = momentRange[0].startOf('day');
      const end = momentRange[1].startOf('day');
      const params = {
        StartTime: start.toISOString(),
        EndTime: end.clone().add(1, 'days').toISOString(),
        OutageType: outageTypeSelected,
        LocationGroupId: locationGroupSelected,
      };

      commit('setIsCurrentlyFetchingData', true);
      // ?startTime=2017-06-20T07:00:00.000Z&endTime=2017-06-21T07:00:00.000Z&outageType=ALL
      const { data: { data } } = await CISO_OMS_API.get('/outages/gantt', { params });
      commit('setIsCurrentlyFetchingData', false);
      commit('setTableData', data);
      this.$notify('Outage Data Fetched', 'success');
    } catch (err) {
      console.error(err);
      commit('setIsCurrentlyFetchingData', false);
      this.$notify('Failed to Fetch Outage Data', 'error');
    }
  },
};

const mutations = {
  ...createMutations('locations', 'tableData', 'isCurrentlyFetchingData'),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};