import moment from 'moment';
import {
  cloneDeep, clone, has, HERows,
} from '@/utils/dataUtil';

export function generateDefault(location) {
  const defaultDailyBidMapping = {
    MIN_LOAD_COST: 'noLoadCost',
    ENERGY_MIN: 'energyMinDay',
    ENERGY_MAX: 'energyMaxDay',
    CHARGE_MIN: 'lowerChargeLimit',
    CHARGE_MAX: 'upperChargeLimit',
    INITIAL_STATE: 'stateOfCharge',
    DISPATCH_TYPE: 'bidOptionType',
    CONTINGENCY_AVAIL_FLAG: 'contingencyAvailabilityFlag',
    MIN_HOURLY_BLOCK: 'minHourlyBlock',
    MIN_EOH_SOC: 'minEohStateOfCharge',
    MAX_EOH_SOC: 'maxEohStateOfCharge',
    CURTAILMENT_ENERGY_LIMIT: 'curtailmentEnergyLimit',
    GHG_ADDER: 'greenHouseGasAdder',
  };

  const defaultHourlyBidMapping = {
    CAP_LIMIT_TYPE: 'capacityLimitType',
    CAP_LIMIT: 'capacityLimit',
    OFF_GRID_CHARGE: 'offGridCharge',
    BID_OPTION_TYPE: 'bidOptionType',
  };

  const stepCurveMapping = {
    STARTUP_COST: 'startupCostCurveData',
    STARTUP_TIME: 'startupTimeCurveData',
    RAMP_RATE: 'rampCurveData',
  };

  const defaultData = {
    dailyBidComponentData: {},
    hourlyBidComponentData: {},
    startupCostCurveData: [],
    startupTimeCurveData: [],
    rampCurveData: [],
    hasDailyBids: false,
    hasStepCurves: false,
  };

  const isSubLocation = has(location, 'locationId');

  location.parameters.forEach(({ name, value, valueType }) => {
    const dailyKeyName = defaultDailyBidMapping[name];
    if (dailyKeyName) {
      if (valueType === 'string') defaultData.dailyBidComponentData[dailyKeyName] = value;
      else defaultData.dailyBidComponentData[dailyKeyName] = value ? Number(value) : value;
    }

    const hourlyKeyName = defaultHourlyBidMapping[name];
    if (hourlyKeyName) {
      if (valueType === 'string') defaultData.hourlyBidComponentData[hourlyKeyName] = value;
      else defaultData.hourlyBidComponentData[hourlyKeyName] = value ? Number(value) : value;
    }
  });

  if (isSubLocation) {
    defaultData.hourlyBidComponentData.configuration = location.shortName;
    defaultData.dailyBidComponentData.configuration = location.shortName;
  } else {
    defaultData.hourlyBidComponentData.configuration = null;
    defaultData.dailyBidComponentData.configuration = null;
  }

  if (Object.keys(defaultData.dailyBidComponentData).length) defaultData.hasDailyBids = true;
  if (Object.keys(defaultData.hourlyBidComponentData).length) defaultData.hasHourlyBids = true;

  location.stepCurves.forEach((step) => {
    const keyName = stepCurveMapping[step.type];
    step = clone(step);
    if (isSubLocation) {
      step.configuration = location.shortName;
    }
    if (step.type === 'RAMP_CURVE') {
      if (step.subType === 'OP') {
        defaultData[keyName].push(step);
        defaultData.hasStepCurves = true;
      }
    } else if (keyName) {
      defaultData[keyName].push(step);
      defaultData.hasStepCurves = true;
    }
  });
  return defaultData;
}

function generateDefaultCurve(startTimeMoment, curves, startHour, endHour, state) {
  const newStartupTimeCurveData = [];
  const newStartupCostCurveData = [];
  const newRampCurveData = [];

  const startTime = startTimeMoment.clone().add(startHour, 'hour').toISOString();
  const endTime = startTimeMoment.clone().add(endHour, 'hour').toISOString();

  curves.rampCurveData.forEach(({
    stepNumber, xAxis, y1Axis, configuration, subType,
  }) => {
    const data = {
      segmentId: stepNumber,
      startTime,
      endTime,
      configuration: configuration ?? null,
      he: startHour + 1,
      rampRateType: subType,
      operatingLevel: xAxis,
      rampRate: y1Axis,
      constraintRampType: 'FAST',
    };
    let idx = -1;
    if (state.dailyComponent.rampCurveData) {
      idx = state.dailyComponent.rampCurveData.findIndex(
        ({ he, configuration }) => he == data.he
          && configuration === data.configuration,
      );
    } else state.dailyComponent.rampCurveData = [];
    if (idx === -1) {
      newRampCurveData.push(data);
    }
  });

  curves.startupCostCurveData.forEach(({
    stepNumber, type, xAxis, y1Axis: yAxis, configuration,
  }) => {
    const data = {
      segmentId: stepNumber,
      xAxis,
      yAxis,
      configuration: configuration ?? null,
      startupType: type,
      startTime,
      endTime,
      he: startHour + 1,
    };
    let idx = -1;
    if (state.dailyComponent.startupCostCurveData) {
      idx = state.dailyComponent.startupCostCurveData.findIndex(
        ({ he, configuration }) => he == data.he
          && configuration === data.configuration,
      );
    } else state.dailyComponent.startupCostCurveData = [];
    if (idx === -1) {
      newStartupCostCurveData.push(data);
    }
  });

  curves.startupTimeCurveData.forEach(({
    stepNumber, type, xAxis, y1Axis: yAxis, configuration,
  }) => {
    const data = {
      segmentId: stepNumber,
      xAxis,
      yAxis,
      configuration: configuration ?? null,
      startupType: type,
      startTime,
      endTime,
      he: startHour + 1,
    };
    let idx = -1;
    if (state.dailyComponent.startupTimeCurveData) {
      idx = state.dailyComponent.startupTimeCurveData.findIndex(
        ({ he, configuration }) => he == data.he
          && configuration === data.configuration,
      );
    } else state.dailyComponent.startupTimeCurveData = [];
    if (idx === -1) {
      newStartupTimeCurveData.push(data);
    }
  });

  if (newStartupTimeCurveData && newStartupTimeCurveData.length > 0) {
    state.dailyComponent.startupTimeCurveData.push(...newStartupTimeCurveData);
  }
  if (newStartupCostCurveData && newStartupCostCurveData.length > 0) {
    state.dailyComponent.startupCostCurveData.push(...newStartupCostCurveData);
  }
  if (newRampCurveData && newRampCurveData.length > 0) {
    state.dailyComponent.rampCurveData.push(...newRampCurveData);
  }
}

export function generateDefaultCurves(rampCurveData, startupCostCurveData, startupTimeCurveData, state) {
  const startTimeMoment = moment(state.date).startOf('day');
  const hours = HERows(state.dailyComponent.dailyBidComponentTableConfig, true, startTimeMoment.toISOString());

  if (state.marketType === 'RTM') {
    for (let i = 0; i < hours.length; i++) {
      generateDefaultCurve(startTimeMoment, {
        rampCurveData,
        startupCostCurveData,
        startupTimeCurveData,
      }, hours[i].he - 1, hours[i].he - 0, state);
    }
  } else {
    generateDefaultCurve(startTimeMoment, {
      rampCurveData,
      startupCostCurveData,
      startupTimeCurveData,
    }, hours[0].he - 1, hours[hours.length - 1].he - 0, state);
  }
}

function generateComponent(startTimeMoment, defaultComponentData, stateComponentData, startHour, endHour) {
  const startTime = startTimeMoment.clone().add(startHour, 'hour').toISOString();
  const endTime = startTimeMoment.clone().add(endHour, 'hour').toISOString();

  let data = clone(defaultComponentData);

  data = {
    ...data,
    startHour: startHour + 1,
    startTime,
    endHour,
    endTime,
  };

  let idx = -1;
  if (stateComponentData) {
    idx = stateComponentData.findIndex(
      ({ startHour, endHour, configuration }) => startHour === data.startHour
            && endHour === data.endHour
            && configuration === data.configuration,
    );
  } else stateComponentData = [];
  if (idx === -1) {
    stateComponentData.push(data);
  }
}

export function generateDefaultDailyBids(dailyBidComponentData, state) {
  const startTimeMoment = moment(state.date).startOf('day');
  const hours = HERows(state.dailyComponent.dailyBidComponentTableConfig, true, startTimeMoment.toISOString());
  if (state.marketType === 'RTM') {
    for (let i = 0; i < hours.length; i++) {
      generateComponent(startTimeMoment,
        dailyBidComponentData, state.dailyComponent.dailyBidComponentData, hours[i].he - 1, hours[i].he - 0);
    }
  } else {
    generateComponent(startTimeMoment,
      dailyBidComponentData,
      state.dailyComponent.dailyBidComponentData, hours[0].he - 1, hours[hours.length - 1].he - 0);
  }
}

export function generateDefaultHourlyBids(hourlyBidComponentData, state) {
  const startTimeMoment = moment(state.date).startOf('day');
  const hours = HERows(state.dailyComponent.hourlyBidComponentTableConfig, true, startTimeMoment.toISOString());

  for (let i = 0; i < hours.length; i++) {
    generateComponent(startTimeMoment,
      hourlyBidComponentData, state.dailyComponent.hourlyBidComponentData, hours[i].he - 1, hours[i].he - 0);
  }
}