import { IDENTITY_API } from '@/api';
import helpers from '@/utils/helpers';

const state = {
  disabledFlag: false,
  userName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  roles: [],
  entityGroups: [],
  userRoles: [],
  userEntityGroups: [],
  visibility: false,
};

const getters = {
  getDisabledFlag: (state) => state.disabledFlag,
  getUserName: (state) => state.userName,
  getFirstName: (state) => state.firstName,
  getLastName: (state) => state.lastName,
  getEmail: (state) => state.email,
  getPhone: (state) => state.phone,
  getRoles: (state) => state.roles,
  getUserRoles: (state) => state.userRoles,
  getEntityGroups: (state) => state.entityGroups,
  getUserEntityGroups: (state) => state.userEntityGroups,
  getLoaderName: (state) => state.loaderName,
  getVisibility: (state) => state.visibility,
};

const actions = {
  clear({ commit }) {
    commit('clear');
  },
  initialize({ commit }) {
    IDENTITY_API.get('roles').then((response) => {
      commit('setRoles', response.data);
    }).catch((err) => {
      console.log(err);
    });

    IDENTITY_API.get('entityGroups').then((response) => {
      commit('setEntityGroups', response.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  changeUserName({ commit }, value) {
    commit('setUserName', value);
  },
  changeFirstName({ commit }, value) {
    commit('setFirstName', value);
  },
  changeLastName({ commit }, value) {
    commit('setLastName', value);
  },
  changeEmail({ commit }, value) {
    commit('setEmail', value);
  },
  changePhone({ commit }, value) {
    commit('setPhone', value);
  },
  changeUserRoles({ commit }, values) {
    commit('setUserRoles', values);
  },
  changeUserEntityGroups({ commit }, values) {
    commit('setUserEntityGroups', values);
  },
  changeDialogVisibility({ commit }, values) {
    commit('setDialogVisibility', values);
  },
  async addUser({ commit, state, dispatch }) {
    const userModel = {
      userName: state.userName,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phone: state.phone,
      groups: state.userEntityGroups,
      roles: state.userRoles,
    };
    try {
      const response = await IDENTITY_API.post('/users', userModel);
      dispatch('clear');
      dispatch('alert/success', { message: 'User added' }, { root: true });
      state.visibility = false;
    } catch (err) {
      const msg = { title: 'Failed to add user.' };
      if (err.response && err.response.status === 400) {
        msg.message = err.response.data.message;
      }
      dispatch('alert/error', msg, { root: true });
    }
  },
};

const mutations = {
  clear(state) {
    state.userName = null;
    state.firstName = null;
    state.lastName = null;
    state.email = null;
    state.phone = null;
    state.userRoles = [];
    state.userEntityGroups = [];
  },
  setUserName(state, value) {
    state.userName = value;
  },
  setFirstName(state, value) {
    state.firstName = value;
  },
  setLastName(state, value) {
    state.lastName = value;
  },
  setEmail(state, value) {
    state.email = value;
  },
  setPhone(state, value) {
    state.phone = value;
  },
  setRoles(state, payload) {
    state.roles = payload.roles.map((e) => ({ value: e.name, label: e.description }));
  },
  setEntityGroups(state, payload) {
    state.entityGroups = payload.groups.map((e) => ({ value: e.name, label: e.description }));
  },
  setUserRoles(state, values) {
    state.userRoles = helpers.intersectionWith(values, state.roles, (l, r) => l === r.value);
  },
  setUserEntityGroups(state, values) {
    state.userEntityGroups = helpers.intersectionWith(values, state.entityGroups, (l, r) => l === r.value);
  },
  setDialogVisibility(state, visible) {
    state.visibility = visible;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};