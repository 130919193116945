import { IDENTITY_API } from '@/api';
import { clone } from '@/utils/dataUtil';
import sourceOptions from './sourceOptions';

const state = {
  names: [],
  name: '',
  severities: [],
  filters: [],
  tableConfigDx: {
    columns: [
      {
        prop: 'type', alignment: 'left', editable: false,
      }, {
        prop: 'description', alignment: 'left', editable: false,
      }, {
        prop: 'enabled', label: 'subscribe', editable: false,
      }, {
        prop: 'audio', editable: false,
      }, {
        prop: 'audioType', label: 'Audio Type', alignment: 'left', editable: false,
      }, {
        prop: 'soundType', label: 'Sound', alignment: 'left', editable: false,
      }, {
        prop: 'duration', alignment: 'left', editable: false,
      },
    ],
  },
  data: [],
  focusedRow: null,
  audioTypeOptions: [
    {
      value: 'Sound',
      label: 'Sound',
    },
    {
      value: 'Speech',
      label: 'Speech',
    },
  ],
  audioType: 'Sound',
  soundTypeOptions: [
    { value: 'bottle', label: 'Bottle' },
    { value: 'glass', label: 'Glass' },
    { value: 'funk', label: 'Funk' },
    { value: 'morse', label: 'Morse' },
    { value: 'purr', label: 'Purr' },
    { value: 'tink', label: 'Tink' },
    { value: 'submarine', label: 'Submarine' },
    { value: 'police', label: 'Police Siren' },
    { value: 'ambulance', label: 'Ambulance Siren' },
  ],
  soundType: 'bottle',
};

const getters = {
  getNames: (state) => state.names,
  getName: (state) => state.name,
  getTableData: (state) => state.data,
  getTableConfiguration: (state) => state.tableConfig,
  getAudioTypeOptions: (state) => state.audioTypeOptions,
  getSoundTypeOptions: (state) => state.soundTypeOptions,
  getOptionConfiguration(state, getters, rootState) {
    const config = sourceOptions.getConfigurations(state.name);
    const { profile } = rootState.auth;

    if (profile && profile.moduleOptions) {
      const moduleOptions = profile.moduleOptions.find((f) => f.name.toLowerCase() === state.name.toLowerCase());

      if (moduleOptions && moduleOptions.options) {
        const optionKeys = Object.keys(moduleOptions.options);
        config.forEach((c) => {
          const opt = optionKeys.find((o) => o.toLowerCase() === c.name.toLowerCase());
          if (opt) {
            let value = moduleOptions.options[opt];
            if (c.type === 'tagbox') value = value.split(',').filter((f) => f !== '');
            c.value = value;
          }
        });
      }
    }
    return config;
  },
  getFocusedRow: (state) => state.focusedRow,
};

const actions = {
  loadNames({ dispatch, commit }) {
    IDENTITY_API.get('/notifications').then((response) => {
      commit('setNames', response.data);
      if (response.data.names && response.data.names.length > 0) dispatch('changeName', response.data.names[0]);
    }).catch((err) => console.log(err));
  },
  changeName({ commit, dispatch }, value) {
    if (value === null) return;
    if (value === undefined) return;
    if (value === '') return;

    commit('setName', value);
    IDENTITY_API.get(`/notifications/${value}`).then((response) => {
      commit('setData', response.data);
    }).catch((err) => console.log(err));

    IDENTITY_API.get(`/notifications/${value}/filters`).then((response) => {
      commit('setFilters', response.data);
    }).catch((err) => console.log(err));
  },
  async updateFocusedRow({ dispatch, commit, state }, item) {
    try {
      const { data } = await IDENTITY_API.put(`/notifications/${state.name}`, item);

      if (data) {
        // if (Array.isArray(response.data.filters)) response.data.filters = response.data.filters[0];
        // else response.data.filters = null;

        commit('updateData', data);
      }
    } catch (err) {
      console.log(err);
      return;
    }

    dispatch('auth/setNotificationRow', item, { root: true });
  },
  clear({ commit }) {
    commit('clear');
  },
  async optionChanged({ dispatch, state }, data) {
    try {
      let { value } = data;

      if (data.config.type === 'tagbox') { value = value.join(','); }

      await IDENTITY_API.post(`notifications/modules/${state.name}/options`, {
        option: data.config.name,
        value,
      });

      dispatch('auth/setModuleOption', {
        module: state.name,
        type: data.config.name,
        value,
      }, { root: true });
    } catch (err) {
      console.log(err);
    }
  },
  focusedRowChanged({ commit }, payload) {
    commit('setFocusedRow', payload);
  },
};

const mutations = {
  setNames(state, value) {
    state.names = value.names.map((n) => ({ value: n, label: n.toUpperCase() }));
  },
  setName(state, value) {
    state.name = value;
  },
  setSeverities(state, value) {
    state.severities = value.names.map((n) => ({ value: n, label: n }));
  },
  setData(state, value) {
    value.notifications.forEach((e) => {
      if (Array.isArray(e.filters)) e.filters = e.filters[0];
      else e.filters = null;
    });
    state.data = value.notifications;
  },
  updateData(state, payload) {
    const index = state.data.findIndex((r) => r.type === payload.type);
    state.data[index].enabled = payload.enabled;
    state.data[index].audio = payload.audio;
    state.data[index].audioType = payload.audioType;
    state.data[index].soundType = payload.soundType;
    state.data[index].duration = payload.duration;

    // state.data[item.rowIndex][item.prop] = item.item[item.prop];
  },
  clear(state) {
    state.names = [];
    state.name = '';
    state.data = [];
    state.focusedRow = null;
  },
  setFilters(state, value) {
    state.filters = value.filters;
  },
  setFocusedRow(state, payload) {
    state.focusedRow = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
