export default {
  options: {
    exportExcel: true,
    filterHeader: true,
    filterRow: true,
  },
  columns: [{
    label: 'TYPE',
    prop: 'type',
    alignment: 'left',
    width: 150,
    filterable: true,
    sortable: true,
  }, {
    label: 'TAG CODE',
    prop: 'tagCode',
    alignment: 'left',
    filterable: true,
    sortable: true,
  }, {
    label: 'TAG ID',
    prop: 'tagID',
    alignment: 'left',
    width: 200,
    filterable: true,
    sortable: true,
  }, {
    label: 'RESOURCE ID',
    prop: 'resourceId',
    alignment: 'left',
    width: 300,
    filterable: true,
    sortable: true,
  }, {
    label: 'RESOURCE TYPE',
    prop: 'resourceType',
    alignment: 'left',
    filterable: true,
    sortable: true,
  }, {
    label: 'INTERTIE TYPE',
    prop: 'intertieType',
    alignment: 'left',
    filterable: true,
    sortable: true,
  }, {
    label: 'MAP START TIME',
    prop: 'mappingStartTimeGmt',
    alignment: 'left',
  }, {
    label: 'MAP END TIME',
    prop: 'mappingEndTimeGmt',
    alignment: 'left',
  }, {
    label: 'TAG START TIME',
    prop: 'tagStartTimeGmt',
    alignment: 'left',
  }, {
    label: 'TAG END TIME',
    prop: 'tagEndTimeGmt',
    alignment: 'left',
  }],
};