import {
  coordinatorList, tradeDateRange, gridConfig, baseSchedulesTMax, baseSchedulesOptions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { has } from '@/utils/dataUtil';

const pivotGridFieldGroups = {
  defaultFields: [{
    caption: 'SC',
    dataField: 'schedulingCoordinator',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'BASE SCHEDULING COORDINATOR',
    dataField: 'baseSchedulingCoordinator',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'BASE SCHEDULING TYPE',
    dataField: 'baseScheduleType',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataField: 'marketType',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET PRODUCT',
    dataField: 'marketProduct',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'startDate',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC ID',
    dataField: 'rsrC_ID',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'CONFIGURATION',
    dataField: 'configuration',
    dataType: '',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) {
        return '(Show Blanks)';
      }
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataField: 'hour',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'STATUS',
    dataField: 'status',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'DOWNLOAD TIME',
    dataField: 'downloadTime',
    dataType: 'datetime',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'LAST UPDATED',
    dataField: 'lastUpdated',
    dataType: 'datetime',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PUBLICATION',
    dataField: 'publication',
    dataType: '',
    area: 'column',
    expanded: true,
    customizeText(cellInfo) {
      if (cellInfo && cellInfo.value && cellInfo.value.indexOf('.') !== -1) {
        return cellInfo.value.substr(cellInfo.value.indexOf('.') + 1);
      }
      return cellInfo.value;
    },
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: gridConfig.valFormat,
    expanded: true,
  }],
  hourlyFields: [{
    caption: 'PUBLICATION',
    dataField: 'publication',
    dataType: '',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (cellInfo && cellInfo.value && cellInfo.value.indexOf('.') !== -1) {
        return cellInfo.value.substr(cellInfo.value.indexOf('.') + 1);
      }
      return cellInfo.value;
    },
  }, {
    caption: 'SC',
    dataField: 'schedulingCoordinator',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'MARKET PRODUCT',
    dataField: 'marketProduct',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'startDate',
    dataType: 'string',
    area: 'row',
    expanded: true,
    format: 'yyyy-MM-dd',
  }, {
    caption: 'RSRC ID',
    dataField: 'rsrC_ID',
    dataType: '',
    area: 'row',
    expanded: true,
  }, {
    caption: 'CONFIGURATION',
    dataField: 'configuration',
    dataType: '',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) {
        return '(Show Blanks)';
      }
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataField: 'hour',
    dataType: '',
    area: 'column',
    expanded: true,
  }, {
    caption: 'STATUS',
    dataField: 'status',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'DOWNLOAD TIME',
    dataField: 'downloadTime',
    dataType: 'date',
    format: 'longTime',
    area: 'column',
    expanded: true,
  }, {
    caption: 'LAST UPDATED',
    dataField: 'lastUpdated',
    dataType: 'datetime',
    area: 'filter',
    expanded: true,
  }, {
    caption: ' ',
    dataField: 'configChangeFlag',
    dataType: 'flag',
    area: 'data',
    expanded: true,
  }, {
    caption: 'BASE SCHEDULING COORDINATOR',
    dataField: 'baseSchedulingCoordinator',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BASE SCHEDULE TYPE',
    dataField: 'baseScheduleType',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataField: 'marketType',
    dataType: '',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: gridConfig.valFormat,
    expanded: true,
  }],
};

export default {
  options: {
    filterRow: true,
    filterHeader: true,
  },
  pivotGridFieldGroups,
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    removeZero: {
      label: 'Non-zero values only',
      value: false,
      type: 'checkbox',
      divider: true,
    },
    validModifiedStatusOnly: {
      label: 'Valid/Modified Status Only',
      value: false,
      type: 'checkbox',
      divider: true,
    },
    baseSchedulesTMax,
    baseSchedulesOptions,
  },
};