import { coordinatorList, tradeDateRange } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFilterConfig: {
    tradeDateRange,
    coordinatorList,
  },
  fields: [{
    caption: 'BA',
    dataField: 'ba',
    area: 'row',
    expanded: true,
  }, {
    caption: 'IS INTERTIE',
    dataField: 'isIntertie',
    area: 'row',
    expanded: true,
  }, {
    caption: 'FLOW GATE',
    dataField: 'flowGate',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RSRC TYPE',
    dataField: 'resource',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataField: 'tradingHour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MW',
    dataField: 'mw',
    area: 'data',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'tradingDate',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'VIRTUAL BID TYPE',
    dataField: 'virtualBidType',
    area: 'row',
    expanded: true,
  }, {
    caption: 'PRICE NODE ID',
    dataField: 'priceNodeId',
    area: 'row',
    expanded: true,
  }],
};