const routes = [];

// if (process.env.NODE_ENV !== 'production') {
//   routes.push(...[{
//     path: '/demo',
//     meta: {
//       visible: true, requiresAuth: true, text: 'DEMO',
//     },
//     component: () => import('@/components/InnerView.vue'),
//     children: [{
//       path: 'nav/param/:id',
//       name: 'ParamRoute',
//       meta: { visible: false, requiresAuth: true, text: 'Param Route' },
//       component: () => import('@/demo/Navigation/ParamRoute'),
//     }, {
//       path: 'walk-thru',
//       name: 'Walkthru',
//       meta: { visible: true, requiresAuth: false, text: 'Walk-thru' },
//       component: () => import('@/demo/Walkthru/Walkthru'),
//     }, {
//       path: 'BSAP-summary',
//       name: 'BSAPSubmissions',
//       meta: { visible: true, requiresAuth: false, text: 'BSAP Submissions' },
//       component: () => import('@/demo/BSAPSummary/BaseScheduleSummary'),
//     }, {
//       path: 'template-profile-select',
//       name: 'TemplateProfileSelect',
//       meta: { visible: true, requiresAuth: false, text: 'Select Tag Template Profile' },
//       component: () => import('@/demo/TagTemplateProfileSelectDialog/TagTemplateProfileSelectDialog'),
//     }, {
//       path: 'demo/wrapperPanels',
//       name: 'WrapperPanels',
//       meta: { visible: true, requiresAuth: false, text: 'Wrapper Panels Demo' },
//       component: () => import('@/demo/WrapperPanels/WrapperPanels'),
//     }],
//   }]);
// }
export default routes;