import {
  coordinatorList,
  tradeDateRange,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADING_DATE',
    dataField: 'tzTradingdate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'CHARGE_CODE',
    dataField: 'chargeCode',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC_ID',
    dataField: 'rsrcId',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RSRC_TYPE',
    dataField: 'rsrcType',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING_HOUR',
    dataField: 'tradinghour',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING_INTERVAL',
    dataField: 'tradingInterval',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'FILE_TYPE',
    dataField: 'fileType',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BAL_AUTHORITY_AREA',
    dataField: 'balAuthorityArea',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'QUANTITY',
    dataField: 'quantity',
    dataType: 'number',
    format: '#,##0.####',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'PRICE',
    dataField: 'price',
    dataType: 'number',
    format: '#,##0.####',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'AMOUNT',
    dataField: 'amount',
    dataType: 'number',
    format: '#,##0.####',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }],
  retrieveFields: false,
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};
