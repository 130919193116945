import * as CONSTS from './constants';

export default {
  columns: [{
    prop: 'trans_num',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH, 
  }, {
    prop: 'trans_tc',
    label: 'tc',
    width: CONSTS.AL_TC_WIDTH,
    minWidth: CONSTS.AL_TC_MIN_WIDTH,
    maxWidth: CONSTS.AL_TC_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'TC',
      valueProperty: 'trans_tc_name',
      labelProperty: 'trans_tc_name',
    },
  }, {
    prop: 'trans_contract',
    label: 'contracts',
    width: CONSTS.USE_PSCS_AG_GRID ? CONSTS.AL_CONTRACT_WIDTH : null,
    editable: true,
  }, {
    prop: 'trans_product',
    label: 'product',
    width: CONSTS.AL_PRODUCT_WIDTH,
    minWidth: CONSTS.AL_PRODUCT_MIN_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'Product',
      valueProperty: 'trans_product_name',
      labelProperty: 'trans_product_name',
    },
  }, {
    prop: 'trans_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    minWidth: CONSTS.MISC_INFO_MIN_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  }, {
    prop: 'trans_nits',
    label: 'nits',
    width: 50,
    editable: true,
    minWidth: 30,
  }],
  options: {
    showAddRemove: true,
    grayRepeatedRows: true,
  },
  style: { dynamicSizing: false },
  columnList(name) {
    if (name === 'product') this.registry.productList.filter(p => p.productTypeID === '3');
  },
  columnCellEditable(row, column) {
    if (row.trans_state === 'clean' || column.prop == 'trans_num' || column.prop == 'trans_miscInfoCount') return false;
    return true;
  },
  columnCellVisible(row, column) {
    if (row.trans_state === 'clean') return false;
    if (column.prop === 'trans_tc_name') return true;
  },
  columnCellProp(row, column) {
    if (row.trans_state === 'clean') return column.prop;
    if (column.prop === 'trans_tc_name') return 'trans_tc';
    if (column.prop === 'trans_product_name') return 'trans_product';
    return column.prop;
  },
  columnCellField(row, column) {
    if (row.trans_state === 'clean') return column.field;
    if (column.field === 'trans_tc_name') return 'trans_tc';
    if (column.field === 'trans_product_name') return 'trans_product';
    return column.field;
  },
  registry: { productList: [] },
};
