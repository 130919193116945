import { tradeDateRange, contracts } from '@/components/ContractBilling/BaseStore/contractsBase';
import moment from 'moment';


export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'CONTRACT NAME',
    dataField: 'contractName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'DETERMINANT NAME',
    dataField: 'determinantName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'Contract ID',
    dataField: 'contractId',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    contracts,
  },
};