<template>
  <router-link :to="{ name: 'Workflow', params:{ workflowName: scope.row.name }}">
    {{ scope.row.name }}
  </router-link>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('workflow');

export default {
  name: 'WorkflowRouteCell',
  props: {
    scope: {
      type: Object,
      required: true,
    },
  },
};
</script>