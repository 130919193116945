import { accessRight, visualAnalytics } from '@/auth/permission';

export default {
  path: '/visual-analytics',
  meta: {
    visible: true,
    requiresAuth: true,
    className: 'dropdown',
    text: 'VISUAL ANALYTICS',
    permission: { name: 'visual_analytics', rights: [accessRight.read, accessRight.write] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'heat-map',
    name: 'HeatMap',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 40,
      text: 'Heat Map',
      permission: { name: visualAnalytics.heatMap, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/HeatMap/HeatMap'),
  }, {
    path: 'daily-analysis',
    name: 'DailyAnalysis',
    meta: {
      showCert: true,
      visible: true,
      rank: 10,
      requiresAuth: true,
      text: 'Daily Analysis',
      permission: { name: visualAnalytics.dailyAnalysis, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/DailyAnalytics/DailyAnalytics'),
  }, {
    path: 'portfolio-analysis',
    name: 'PortfolioAnalysis',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 80,
      text: 'Portfolio Analysis',
      permission: { name: visualAnalytics.portfolioAnalysis, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/Portfolio/PortfolioAnalysis'),
    // component: () => import('@/components/VisualAnalytics/Portfolio/PnlPivot'),
  }, {
    path: 'energy-track-data',
    name: 'EnergyTrackData',
    meta: {
      showCert: true,
      visible: false,
      requiresAuth: true,
      rank: 30,
      text: 'Energy Track Data',
      permission: { name: 'visual_analytics:energy_track_data', rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/EnergyTrackData'),
  }, {
    path: 'run-engine',
    name: 'RunEngine',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 110,
      text: 'Run Engine',
      permission: { name: visualAnalytics.runEngine, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/RunEngine/RunEngine'),
  }, {
    path: 'pnl-pivot',
    name: 'PnL Pivot',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 70,
      text: 'PnL Pivot',
      permission: { name: visualAnalytics.pnlPivot, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/Portfolio/PnlPivot'),
  }, {
    path: 'eim-transfer-map',
    name: 'EimTransferMap',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 20,
      text: 'EIM Transfer Map',
      permission: { name: visualAnalytics.eimTransferMap, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/EimTransferMap/EimTransferMap'),
  }, {
    path: 'outages',
    name: 'Outages',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 60,
      text: 'Outages',
      permission: { name: visualAnalytics.outages, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/Outages/Outages'),
  }, {
    path: 'realtime-graphs',
    name: 'RealtimeGraphs',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 90,
      text: 'Realtime Graphs',
      permission: { name: visualAnalytics.realTimeGraphs, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/RealtimeGraphs/RealtimeGraphs'),
  },
  // Temporarily remove for demo - https://cottonmouth/WebCollection/settlecore-tracking/_workitems?id=6459&_a=edit
  // {
  //   path: 'intervals-notes',
  //   name: 'IntervalsNotes',
  //   meta: {
  //     visible: true, requiresAuth: true, text: 'Intervals Notes',
  //     permission: { name: visualAnalytics.outages, rights: [accessRight.read, accessRight.write] },
  //   },
  //   component: () => import('@/components/VisualAnalytics/IntervalsNotes/IntervalsNotes'),
  // },
  {
    path: 'multi-location-analysis',
    name: 'MultiLocationAnalysis',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      rank: 50,
      text: 'Multi-Location Analysis',
      permission: { name: visualAnalytics.multiLocationAnalysis, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/MultiResourceAnalysis/MultiLocationAnalysis'),
  },
  {
    path: 'inventory',
    name: 'Inventory',
    meta: {
      showCert: true,
      visible: true,
      rank: 120,
      requiresAuth: true,
      text: 'VA Inventory',
      permission: { name: visualAnalytics.va_inventory, rights: [accessRight.read] },
    },
    component: () => import('@/components/VisualAnalytics/Inventory/Inventory'),
  },
  {
    path: 'va-reports',
    name: 'VisualAnalyticsReports',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      text: 'Reporting',
      rank: 100,
      permission: { name: visualAnalytics.reports, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/VisualAnalytics/Reporting/VisualAnalyticsReports'),
  },
  {
    path: 'settings',
    meta: {
      visible: true,
      requiresAuth: true,
      rank: 200,
      text: 'Settings',
      permission: { name: visualAnalytics.settings, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'layouts',
      name: 'VALayouts',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Layouts',
        permission: { name: visualAnalytics.settings, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/VisualAnalytics/Settings/ChartLayouts/Layouts'),
    }, {
      path: 'layouts/:id',
      name: 'VALayout',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Create VA Layout',
        permission: { name: visualAnalytics.settings, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/VisualAnalytics/Settings/ChartLayouts/Layout'),
    }, {
      path: 'graph-config',
      name: 'Graph Config',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Graph Config',
        permission: { name: visualAnalytics.graphConfiguration, rights: [accessRight.read] },
      },
      component: () => import('@/components/VisualAnalytics/GraphConfig/GraphConfig'),
    }, {
      path: 'rules-config',
      name: 'Rules Config',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Rules Config',
        permission: { name: visualAnalytics.rulesConfig, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/VisualAnalytics/RulesConfig/RulesConfig'),
    }, {
      path: 'gen-cost-setup',
      name: 'Gen Cost Setup',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: false,
        text: 'Gen Cost Setup',
        permission: { name: visualAnalytics.genCostConfig, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/VisualAnalytics/Settings/GenCostSetup/GenCostSetup'),
    }],
  }],
};