import { accessRight, miso } from '@/auth/permission';

export default {
  path: '/MISO',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'MISO',
    permission: { name: miso.misoMenu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'tools',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      text: 'TOOLS',
      permission: { name: miso.tools.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'MISO-reports',
      name: 'CreateMISOReport',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Miso Reports',
        permission: { name: miso.tools.createAReport.menu, rights: [accessRight.visible] },
      },
      component: () => import('@/components/MISO/Tools/CreateAReport/CreateAReport'),
    }],
  }],
};