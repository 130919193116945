import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  nullTableRow: {
    id: -999,
    shortName: null,
    longName: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchContractGroups');
  },
  async fetchContractGroups({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contract-groups/all-charge-groups');
      if (Array.isArray(data)) {
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Contract Groups Data', 'error');
    }
  },
  postRecord({ state }, record) {
    record.startDate = getMomentDateString(record.startDate);
    record.endDate = record.endDate === null ? null : getMomentDateString(record.endDate);

    BILLING_REF_API.post('/contract-groups', record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  updateRecord({ dispatch, commit, state }, record) {
    BILLING_REF_API.put(`/contract-groups/${record.id}`, record).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/contract-groups/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};