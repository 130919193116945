import { CISO_OASIS_API } from '@/api';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { tradeDateRange, balancingAreaAuthority } from '@/components/Ciso/BaseStore/CisoConfigs';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import config from './elapPricesConfiguration';

const state = {
  baaSelected: [],
  basePricesTableData: [],
  dailyAverageModelsRt: [],
  dailyAverageModelsRtuc: [],
  monthlyAverageModels: [],
  weeklyAverageModels: [],
  unfilteredDailyAverageModelsRt: [],
  unfilteredDailyAverageModelsRtuc: [],
  unfilteredMonthlyAverageModels: [],
  unfilteredWeeklyAverageModels: [],
  isLoading: false,
  valueTypesDaily: [
    { value: 'daily', name: 'Daily' },
    { value: 'offPeak', name: 'OffPeak' },
    { value: 'onPeak', name: 'OnPeak' },
  ],
  valueTypesWeeklyAndMonthly: [
    { value: 'rT_Actual', name: 'RT_Actual' },
    { value: 'rtuC_Actual', name: 'RTUC_Actual' },
  ],
  searchFiltersConfig: {
    tradeDateRange,
    balancingAreaAuthority,
  },
  tableConfig: config.fields,
};

const actions = {
  async fetchElapPricesData({ commit }, parameters = {}) {
    commit('clearAllData');
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      baa: parameters.balancingAreaAuthoritySelected,
    };
    // pass selected BA to graph component for drop down
    const baaSelected = (parameters.balancingAreaAuthoritySelected === 'ALL')
      ? parameters.balancingAreaAuthorityUnfiltered
      : [parameters.balancingAreaAuthoritySelected];
    commit('setBaaSelected', baaSelected || []);
    try {
      const { data } = await CISO_OASIS_API.get('/elap-prices', { params });
      if (data) {
        commit('setAllData', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to load ELAP Prices data', 'error');
    }
  },
  filterBa({ commit }, BA = {}) {
    commit('filterGraphs', BA);
  },
  reset({ commit }) {
    commit('clearAllData');
  },
};

const mutations = {
  setAllData(state, value) {
    Object.keys(value).forEach((key) => {
      if (!key.includes('MonthlyAverage')) {
        value[key].forEach((x) => { x.tradingDate = moment.utc(x.tradingDate).format('MM/DD/YYYY'); });
      }
    });
    // default
    state.basePricesTableData = Object.freeze(value.elapPricesBasePricesModels);
    state.dailyAverageModelsRt = Object.freeze(value.elapPricesDailyAverageModelsRt);
    state.dailyAverageModelsRtuc = Object.freeze(value.elapPricesDailyAverageModelsRtuc);
    state.weeklyAverageModels = Object.freeze(value.elapPricesWeeklyAverageModels);
    state.monthlyAverageModels = Object.freeze(value.elapPricesMonthlyAverageModels);
    // fill unfiltered
    state.unfilteredDailyAverageModelsRt = Object.freeze(value.elapPricesDailyAverageModelsRt);
    state.unfilteredDailyAverageModelsRtuc = Object.freeze(value.elapPricesDailyAverageModelsRtuc);
    state.unfilteredWeeklyAverageModels = Object.freeze(value.elapPricesWeeklyAverageModels);
    state.unfilteredMonthlyAverageModels = Object.freeze(value.elapPricesMonthlyAverageModels);
  },
  filterGraphs(state, BA) {
    if (BA.name === 'ALL') {
      state.dailyAverageModelsRt = state.unfilteredDailyAverageModelsRt;
      state.dailyAverageModelsRtuc = state.unfilteredDailyAverageModelsRtuc;
      state.weeklyAverageModels = state.unfilteredWeeklyAverageModels;
      state.monthlyAverageModels = state.unfilteredMonthlyAverageModels;
    } else {
      state.dailyAverageModelsRt = state.unfilteredDailyAverageModelsRt.filter((data) => data.pricE_NODE_ID.includes(BA.name));
      state.dailyAverageModelsRtuc = state.unfilteredDailyAverageModelsRtuc.filter((data) => data.pricE_NODE_ID.includes(BA.name));
      state.weeklyAverageModels = state.unfilteredWeeklyAverageModels.filter((data) => data.pricE_NODE_ID.includes(BA.name));
      state.monthlyAverageModels = state.unfilteredMonthlyAverageModels.filter((data) => data.pricE_NODE_ID.includes(BA.name));
    }
  },
  clearAllData(state) {
    state.basePricesTableData = [];
    state.dailyAverageModelsRt = [];
    state.dailyAverageModelsRtuc = [];
    state.weeklyAverageModels = [];
    state.monthlyAverageModels = [];
    // filtered data
    state.unfilteredDailyAverageModelsRt = [];
    state.unfilteredDailyAverageModelsRtuc = [];
    state.unfilteredWeeklyAverageModels = [];
    state.unfilteredMonthlyAverageModels = [];
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  ...createMutations('baaSelected'),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};