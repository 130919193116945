import Vue from 'vue';
import moment from 'moment';

import { createMutations } from '@/utils/vuexHelper';
import { CISO_TRADE_SCHD_API, CISO_MASTERDATA_API, BIDDING_API } from '@/api';
import utils from '@/utils';
import { HEColumns } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import {
  mapLabelValue, computeSelfScheduleTotal, setScheduleDefaults, filterScheduleData,
  selfScheduleTotals, deconstructScheduleName,
} from '@/components/Scheduling/utils';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import { mergeTradesAndTradeSchedules, tableModelToScheduleModel } from '../store/util';

const state = {
  executionId: null,
  date: undefined,
  tradeName: '',
  scheduleName: '',
  marketType: '',
  istSchedule: {},
  direction: undefined,
  counterParty: undefined,
  sc: undefined,
  scheduleUpdated: false,
  product: undefined,
  location: undefined,
  currentVariant: undefined,
};

const getters = {
  hasWritePermissionFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:trades', 'write');
  },
  flatSchedule: (state) => {
    const heColumns = HEColumns({}, true, state.date).map(({ prop }) => prop);
    return Object.keys(state.istSchedule)
      .filter((key) => {
        if (key === 'he02*') key = 'he25';
        return heColumns.includes(key);
      }).map((he) => ({
        he,
        heVal: he === 'he02*' ? 25 : parseInt(he.replace('he', ''), 10),
        counterQuantity: state.istSchedule[he]?.counterQuantity,
        adjustedQuantity: state.istSchedule[he]?.adjustedQuantity,
        quantity: state.istSchedule[`${he}_val`],
      }));
  },
};

const actions = {
  async init({ commit }, props) {
    commit('scheduleNameMutation', props.scheduleName);
    commit('setCurrentVariant', props.variant);
    commit('setExecutionId', props.executionId);
  },
  async loadScheduleAction({ dispatch, commit, state }) {
    try {
      if (state.executionId) {
        try {
          const { data } = await BIDDING_API.get(`executions/${state.executionId}`);
          if (data?.output) {
            dispatch('loadIst', data?.output?.tradeSchedules);
          }
        } catch (error) {
          console.error(`Error Fetching Execution ${state.executionId}`, error);
        }
      } else if (state.date && state.tradeName) {
        const params = `tradeDate=${state.date}&name=${state.tradeName}`;
        const { data: ist_schedules } = await CISO_TRADE_SCHD_API.get(`/trades/schedules?${params}`);
        dispatch('loadIst', ist_schedules.tradeSchedules);
      }
    } catch (error) {
      console.error('Unable to fetch schedule', error);
    }
  },
  async loadIst({ commit, state, dispatch }, tradeSchedules) {
    const { date, tradeName, marketType } = state;

    try {
      const { data } = await CISO_TRADE_SCHD_API.get('trades', { params: { date, marketType } });
      let trades = data.trades.filter(({ name }) => name === tradeName);

      if (!trades || trades.length === 0) {
        trades = [{ name: tradeName }];
      }

      const [ist_schedule] = mergeTradesAndTradeSchedules(trades, tradeSchedules, [], state.date);

      if (ist_schedule.sc) commit('setSc', ist_schedule.sc);
      if (ist_schedule.direction) commit('setDirection', ist_schedule.direction);
      if (ist_schedule.counterParty) commit('setCounterParty', ist_schedule.counterParty);
      if (ist_schedule.location) commit('setLocation', ist_schedule.location);
      if (ist_schedule.tradeProductType) commit('setProduct', ist_schedule.tradeProductType);

      commit('setIstSchedule', ist_schedule);
    } catch (error) {
      console.error(error);
    }
  },
  async updateIstSchedule({ state, commit }, update) {
    const schedule = { ...state.istSchedule };
    if (update.prop === 'quantity') {
      schedule[`${update.row.he}_val`] = update.value;
    }
    commit('setIstSchedule', schedule);
    commit('setScheduleUpdated', true);
  },
  async save({ state, getters, commit }) {
    const hours = HEColumns({}, true, state.date).map((hour) => {
      const key = hour.dstFlag ? 'he02*_val' : `${hour.prop}_val`;
      const value = state.istSchedule[key];
      const floatValue = typeof (value) === 'string' ? parseFloat(value).toFixed(2) : value;
      const startTime = hour.time.toISOString();
      const endTime = moment(startTime).add(1, 'hours').toISOString();

      return ({ startTime, endTime, quantity: floatValue });
    });

    if (state.scheduleUpdated) {
      const updatedSchedule = {
        ...state.istSchedule,
        tradeDate: moment(state.date).toISOString(),
        startTime: moment(state.date).toISOString(),
        endTime: moment(state.date).add(1, 'day').toISOString(),
        isDirty: state.scheduleUpdated,
        hours,
      };
      if (state.istSchedule.variant === 'ACTUAL') {
        try {
          await CISO_TRADE_SCHD_API.put('trades/schedules/batch', { tradeSchedules: [updatedSchedule] });
          this.$notify({ type: 'success', message: 'Update Completed' });
          commit('setCurrentVariant', 'ACTUAL');
        } catch (e) {
          this.$notify({ type: 'error', message: 'Update Failed' });
          console.error(e);
        }
      } else {
        try {
          await CISO_TRADE_SCHD_API.post('trades/schedules/batch', { tradeSchedules: [updatedSchedule] });
          this.$notify({ type: 'success', message: 'Save Completed' });
          commit('setCurrentVariant', 'ACTUAL');
          state.tableHourData = [];
        } catch (e) {
          this.$notify({ type: 'error', message: 'Save Failed' });
          console.error(e);
        }
      }
    }
  },

  async accept({ dispatch, state }, executionId) {
    await BIDDING_API.put(`/executions/status/${executionId}/accepted`);
  },
};

const mutations = {
  setExecutionId(state, item) {
    state.executionId = item;
  },
  scheduleNameMutation(state, name) {
    const propList = ['sc', 'marketType', 'date', 'tradeName'];
    const item = deconstructScheduleName(name, propList);
    state.scheduleName = name;
    state.tradeName = item.tradeName;
    state.marketType = item.marketType;
    state.date = `${item.date.substring(0, 4)}-${item.date.substring(4, 6)}-${item.date.substring(6, 8)}`;
  },
  ...createMutations(
    'scheduleUpdated',
    'locationType',
    'date',
    'scheduleName',
    'marketType',
    'istSchedule',
    'direction',
    'counterParty',
    'sc',
    'product',
    'location',
    'currentVariant',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};