import { STRUCTURES_API, IDENTITY_API, ETRM_API } from '@/api';

const state = {
  brokerList: [],
  companyList: [],
  contactList: [],
  contractCoordinatorList: [],
  contractList: [],
  contractStateTypeList: [],
  contractTypeList: [],
  currencyList: [],
  dealTypeList: [],
  deliverableOccurrenceLogStatusTypeList: [],
  deliverableTypeList: [],
  endpointList: [],
  energyCommodityList: [],
  environmentalGhgAccountHoldingList: [],
  environmentalMarketList: [],
  environmentalSubAccountList: [],
  eventTypeList: [],
  externalIDList: [],
  gasMarketList: [],
  locationGroupList: [],
  locationList: [],
  locationTypeList: [],
  marketContractTypeList: [],
  marketList: [],
  marketParticipantList: [],
  marketProductTypeList: [],
  marketRecordTypeList: [],
  marketSelfScheduleTypeList: [],
  marketTradeProductList: [],
  marketTradeTypeList: [],
  paymentTermList: [],
  pipelineLocationGroupList: [],
  portfolioList: [],
  powerMarketList: [],
  priceCurveGroupList: [],
  priceFormulaList: [],
  priceIndexList: [],
  termTypeList: [],
  timeZoneDatePeriodList: [],
  tradeTemplateList: [],
  unitList: [],
  userList: [],
  userListFromEntityGroup: [],
  timeZone: [
    { value: 'PST', label: 'PST' },
    { value: 'PDT', label: 'PDT' },
    { value: 'MST', label: 'MST' },
    { value: 'MDT', label: 'MDT' },
    { value: 'CST', label: 'CST' },
    { value: 'CDT', label: 'CDT' },
    { value: 'EST', label: 'EST' },
    { value: 'EDT', label: 'EDT' },
  ],
  timeZoneOffset: {
    PST: 8,
    PDT: 7,
    MST: 7,
    MDT: 6,
    CST: 6,
    CDT: 5,
    EST: 5,
    EDT: 4,
  },
};

const getters = {
  getBrokerList: (state) => state.brokerList,
  getCompanyList: (state) => state.companyList,
  getContactList: (state) => state.contactList,
  getContractCoordinatorList: (state) => state.contractCoordinatorList,
  getContractList: (state) => state.contractList,
  getContractStateTypeList: (state) => state.contractStateTypeList,
  getContractTypeList: (state) => state.contractTypeList,
  getCurrencyList: (state) => state.currencyList,
  getDealTypeList: (state) => state.dealTypeList,
  getDeliverableOccurrenceLogStatusTypeList: (state) => state.deliverableOccurrenceLogStatusTypeList,
  getDeliverableTypeList: (state) => state.deliverableTypeList,
  getEndpointList: (state) => state.endpointList,
  getEnergyCommodityList: (state) => state.energyCommodityList,
  getEnvironmentalGhgAccountHoldingList: (state) => state.environmentalGhgAccountHoldingList,
  getEnvironmentalMarketList: (state) => state.environmentalMarketList,
  getEnvironmentalSubAccountList: (state) => state.environmentalSubAccountList,
  getEventTypeList: (state) => state.eventTypeList,
  getGasMarketList: (state) => state.gasMarketList,
  getLocationGroupList: (state) => state.locationGroupList,
  getLocationList: (state) => state.locationList,
  getLocationTypeList: (state) => state.locationTypeList,
  getMarketContractTypeList: (state) => state.marketContractTypeList,
  getMarketList: (state) => state.marketList,
  getMarketParticipantList: (state) => state.marketParticipantList,
  getMarketProductTypeList: (state) => state.marketProductTypeList,
  getMarketRecordTypeList: (state) => state.marketRecordTypeList,
  getMarketSelfScheduleTypeList: (state) => state.marketSelfScheduleTypeList,
  getMarketTradeProductList: (state) => state.marketTradeProductList,
  getMarketTradeTypeList: (state) => state.marketTradeTypeList,
  getPaymentTermList: (state) => state.paymentTermList,
  getPipelineLocationGroupList: (state) => state.pipelineLocationGroupList,
  getPortfolioList: (state) => state.portfolioList,
  getPowerMarketList: (state) => state.powerMarketList,
  getPriceCurveGroupList: (state) => state.priceCurveGroupList,
  getPriceFormulaList: (state) => state.priceFormulaList,
  getPriceIndexList: (state) => state.priceIndexList,
  getTermTypeList: (state) => state.termTypeList,
  getTimeZone: (state) => state.timeZone,
  getTimeZoneDatePeriodList: (state) => state.timeZoneDatePeriodList,
  getTimeZoneOffset: (state) => state.timeZoneOffset,
  getUnitList: (state) => state.unitList,
  getUserList: (state) => state.userList,
  getUserListFromEntityGroup: (state) => state.userListFromEntityGroup,
};

const actions = {
  async initializeReferenceData({ dispatch, state }, item) {
    const taskList = [];
    const { referenceItemList } = item;
    const params = { ...item };
    delete params.referenceItemList;
    referenceItemList.forEach((referenceItem) => {
      taskList.push(dispatch(referenceItem, { params }));
    });

    await Promise.all(taskList);
  },
  async fetchTermProducts({ commit }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('termProduct');
      commit('setTermProducts', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchExternalIDs({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('collections/external-data/resources');
      commit('setExternalIDs', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchBrokerList({ commit }) {
    const { data } = await STRUCTURES_API.get('/brokers');
    commit('setBrokerList', data);
    return state.brokerList;
  },
  async fetchMarketList({ commit }) {
    const { data } = await STRUCTURES_API.get('/markets');
    commit('setMarketList', data.energyMarkets);
    return state.marketList;
  },
  async fetchCompanyList({ commit }) {
    const { data } = await STRUCTURES_API.get('/companies');
    commit('setCompanyList', data.companies);
    return state.companyList;
  },
  async fetchContactList({ commit }) {
    const { data } = await STRUCTURES_API.get('/company-contacts');
    commit('setContactList', data.companyContacts);
    return state.contactList;
  },
  async fetchPortfolioList({ commit }) {
    const { data } = await STRUCTURES_API.get('/portfolios');
    commit('setPortfolioList', data.portfolios);
    return state.portfolioList;
  },
  async fetchContractList({ commit }) {
    const { data } = await STRUCTURES_API.get('/contracts');
    commit('setContractList', data.contracts);
    return state.contractList;
  },
  async fetchCurrencyList({ commit }) {
    const { data } = await STRUCTURES_API.get('/catalogs?types=Currency');

    // for backwards compatibility
    data.data.forEach((c) => {
      c.shortName = c.name;
    });

    commit('setCurrencyList', data.data);
    return state.currencyList;
  },
  async fetchEndpointList({ commit }) {
    const { data } = await STRUCTURES_API.get('/endpoints');
    commit('setEndpointList', data);
    return state.endpointList;
  },
  async fetchUserList({ commit }) {
    const { data } = await IDENTITY_API.get('/users');
    commit('setUserList', data.users);
    return state.userList;
  },
  async fetchUsersFromEntityGroupList({ commit }, userGroup) {
    const { data } = await IDENTITY_API.get(`/entity-groups/${userGroup}/users`);
    commit('setUserListFromEntityGroup', data.users);
    return state.userListFromEntityGroup;
  },
  async fetchEnvironmentalGhgAccountHolding({ commit }) {
    const { data } = await STRUCTURES_API.get('/environmental/sub-account-holdings');
    const holdings = data.environmentalGhgAccountHoldings;
    holdings.forEach((holding) => {
      holding.key = JSON.stringify(holding);
    });
    commit('setEnvironmentalGhgAccountHoldingList', holdings);
    return state.environmentalGhgAccountHoldingList;
  },
  async fetchPriceCurveGroupList({ commit }) {
    const { data } = await STRUCTURES_API.get('/price-curve-groups');
    commit('setPriceCurveGroupList', data.priceCurveGroups);
    return state.priceCurveGroupList;
  },
  async fetchLocationList({ commit, state }, params) {
    const { data } = await STRUCTURES_API.get('/locations', params);
    commit('setLocationList', data.data.locations);
    return state.locationList;
  },
  async fetchLocationGroupList({ commit, state }, params) {
    const { data } = await STRUCTURES_API.get('/location-groups', params);
    commit('setLocationGroupList', data.locationGroups);
    return state.locationGroupList;
  },
  async fetchLocationGroupLocations({ commit }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    return data.locationGroupLocations;
  },
  async fetchLocation({ commit, state }, params) {
    const { market, locationType, locationName } = params;
    const { data } = await STRUCTURES_API.get(`locations/${market || state.market}/${locationType}/${locationName}`, params);
    return data;
  },
  async fetchPipelineLocationGroupList({ commit }) {
    const { data } = await STRUCTURES_API.get('/location-groups?groupType=PIPELINE');
    commit('setPipelineLocationGroupList', data.locationGroups);
    return state.pipelineLocationGroupList;
  },
  async fetchTermTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/term-types');
    commit('setTermTypeList', data.termTypes);
    return state.termTypeList;
  },
  async fetchTimeZoneDatePeriodList({ commit }) {
    const { data } = await STRUCTURES_API.get('/time-zone-date-periods');
    commit('setTimeZoneDatePeriodList', data.timeZoneDatePeriods);
    return state.timeZoneDatePeriodList;
  },
  async fetchMarketProductTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/market-product-types');
    commit('setMarketProductTypeList', data.marketProductTypes);
    return state.marketProductTypeList;
  },
  async fetchMarketSelfScheduleTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/market-self-schedule-types');
    commit('setMarketSelfScheduleTypeList', data.marketSelfScheduleTypes);
    return state.marketSelfScheduleTypeList;
  },
  async fetchMarketTradeProductList({ commit }) {
    const { data } = await STRUCTURES_API.get('/market-trade-products');
    commit('setMarketTradeProductList', data.marketTradeProducts);
    return state.marketTradeProductList;
  },
  async fetchLocationTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/location-types');
    commit('setLocationTypeList', data.locationTypes);
    return state.locationTypeList;
  },
  async fetchMarketTradeTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/market-trade-types');
    commit('setMarketTradeTypeList', data.marketTradeTypes);
    return state.marketTradeTypeList;
  },
  async fetchMarketRecordTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/market-record-types');
    commit('setMarketRecordTypeList', data.marketRecordTypes);
    return state.marketRecordTypeList;
  },
  async fetchMarketParticipantList({ commit }) {
    const { data } = await STRUCTURES_API.get('/energy-market-participants');
    commit('setMarketParticipantList', data.marketParticipants);
    return state.marketParticipantList;
  },
  async fetchPriceIndexList({ commit }) {
    const { data } = await STRUCTURES_API.get('/price-indexes');
    commit('setPriceIndexList', data.priceIndexes);
    return state.priceIndexList;
  },
  async fetchUnitList({ commit }) {
    const { data } = await STRUCTURES_API.get('/units');
    commit('setUnitList', data.units);
    return state.unitList;
  },
  async fetchPaymentTermList({ commit }) {
    const { data } = await STRUCTURES_API.get('/catalogs?types=PaymentTerm');

    // for backwards compatibility
    data.data.forEach((c) => {
      c.shortName = c.name;
    });

    commit('setPaymentTermList', data.data);
    return state.paymentTermList;
  },
  async fetchContractTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/contract-types');
    commit('setContractTypeList', data.contractTypes);
    return state.contractTypeList;
  },
  async fetchContractStateTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/contract-state-types');
    commit('setContractStateTypeList', data.contractStates);
    return state.contractStateTypeList;
  },
  async fetchDeliverableTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/deliverable-type');
    commit('setDeliverableTypeList', data.deliverableTypes);
    return state.deliverableTypeList;
  },
  async fetchDeliverableOccurrenceLogStatusTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/occurrence-log-status-types');
    commit('setDeliverableOccurrenceLogStatusTypeList', data.occurrenceLogStatusTypes);
    return state.deliverableOccurrenceLogStatusTypeList;
  },
  async fetchEventTypeList({ commit }) {
    const { data } = await STRUCTURES_API.get('/event-types');
    commit('setEventTypeList', data.eventTypes);
    return state.eventTypeList;
  },
  async fetchContractCoordinatorList({ commit }) {
    const { data } = await STRUCTURES_API.get('/contract-coordinators');
    commit('setContractCoordinators', data.contractCoordinators);
    return state.contractCoordinatorList;
  },
  async fetchEnergyCommodityList({ commit }) {
    const { data } = await STRUCTURES_API.get('/commodities');
    commit('setEnergyCommodityList', data.energyCommodities);
    return state.energyCommodityList;
  },
  async fetchEnvironmentalSubAccountList({ commit }) {
    const { data } = await STRUCTURES_API.get('/environmental/sub-accounts');
    commit('setEnvironmentalSubAccountList', data.environmentalSubAccounts);
    return state.environmentalSubAccountList;
  },
  async fetchTradeTemplateList({ commit }) {
    const { data } = await ETRM_API.get('trades/templates');
    commit('setTradeTemplateList', data.tradeTemplates);
    return state.tradeTemplateList;
  },
};
const mutations = {
  setExternalIDs(state, list) {
    state.externalIDList = list || [];
  },
  setBrokerList(state, list) {
    state.brokerList = list || [];
  },
  setEnvironmentalSubAccountList(state, list) {
    state.environmentalSubAccountList = list || [];
  },
  setMarketList(state, list) {
    state.marketList = list || [];
  },
  setEndpointList(state, list) {
    state.endpointList = list || [];
  },
  setPowerMarketList(state, list) {
    state.powerMarketList = list || [];
  },
  setEnvironmentalMarketList(state, list) {
    state.environmentalMarketList = list || [];
  },
  setEnvironmentalGhgAccountHoldingList(state, list) {
    state.environmentalGhgAccountHoldingList = list || [];
  },
  setGasMarketList(state, list) {
    state.gasMarketList = list || [];
  },
  setCompanyList(state, list) {
    state.companyList = list || [];
  },
  setContactList(state, list) {
    state.contactList = list || [];
  },
  setPortfolioList(state, list) {
    state.portfolioList = list || [];
  },
  setPriceCurveGroupList(state, list) {
    state.priceCurveGroupList = list || [];
  },
  setLocationList(state, list) {
    state.locationList = list || [];
  },
  setTradeTemplateList(state, list) {
    state.tradeTemplateList = list || [];
  },
  setLocationGroupList(state, list) {
    state.locationGroupList = list || [];
  },
  setPipelineLocationGroupList(state, list) {
    state.pipelineLocationGroupList = list || [];
  },
  setTermTypeList(state, list) {
    state.termTypeList = list || [];
  },
  setTimeZoneDatePeriodList(state, list) {
    state.timeZoneDatePeriodList = list || [];
  },
  setMarketProductTypeList(state, list) {
    state.marketProductTypeList = list || [];
  },
  setMarketSelfScheduleTypeList(state, list) {
    state.marketSelfScheduleTypeList = list || [];
  },
  setMarketTradeProductList(state, list) {
    state.marketTradeProductList = list || [];
  },
  setLocationTypeList(state, list) {
    state.locationTypeList = list || [];
  },
  setMarketTradeTypeList(state, list) {
    state.marketTradeTypeList = list || [];
  },
  setMarketRecordTypeList(state, list) {
    state.marketRecordTypeList = list || [];
  },
  setMarketParticipantList(state, list) {
    state.marketParticipantList = list || [];
  },
  setPriceFormulaList(state, list) {
    state.priceFormulaList = list || [];
  },
  setCurrencyList(state, list) {
    state.currencyList = list || [];
  },
  setUnitList(state, list) {
    state.unitList = list || [];
  },
  setPaymentTermList(state, list) {
    state.paymentTermList = list || [];
  },
  setContractList(state, list) {
    state.contractList = list || [];
  },
  setContractTypeList(state, list) {
    state.contractTypeList = list || [];
  },
  setContractStateTypeList(state, list) {
    state.contractStateTypeList = list || [];
  },
  setDeliverableTypeList(state, list) {
    state.deliverableTypeList = list || [];
  },
  setDeliverableOccurrenceLogStatusTypeList(state, list) {
    state.deliverableOccurrenceLogStatusTypeList = list || [];
  },
  setEventTypeList(state, list) {
    state.eventTypeList = list || [];
  },
  setContractCoordinators(state, list) {
    state.contractCoordinatorList = list || [];
  },
  setPriceIndexList(state, list) {
    state.priceIndexList = list || [];
  },
  setUserList(state, list) {
    state.userList = list || [];
  },
  setUserListFromEntityGroup(state, list) {
    state.userListFromEntityGroup = list || [];
  },
  setEnergyCommodityList(state, list) {
    state.energyCommodityList = list || [];
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
