import { VA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import VABaseStore from '../VABaseStore';

const constValues = {
  defaultColumnWidth: 100,
};

const state = {
  dataFilter: 'All',
  selectedDateRange: dateStore.getDefaultRange(),
  availabilityTableConfig: {
    name: 'DataAvailabilityConfig',
    columns: [],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  availabilityTableData: [],
  availabilityTableDataMissingOnly: [],
};

const actions = {
  initialize({ state, dispatch, commit }) {
    dispatch('fetchVariants', state.selectedDateRange[0]);
    dispatch('reset');
  },
  reset({ commit }) {
    commit('setAvailabilityTableData', []);
    commit('setAvailabilityTableDataMissingOnly', []);
    commit('setAvailabilityTableConfig', []);
  },
  async fetchDataAvailability({ state, dispatch }) {
    // limit of 31 days
    const dateRangeDaysDiff = moment(state.selectedDateRange[1].toISOString())
      .diff(moment(state.selectedDateRange[0].toISOString()), 'days');
    if (dateRangeDaysDiff > 30) {
      this.$notify({
        message: 'Please select a date range less than 1 month',
        type: 'Error',
        ...(constValues.defaultNotificationAnimation),
      }, {
        position: 'bottom center',
        direction: 'up-stack',
      });
      return;
    }
    if (state.VABaseStore.selectedLocationGroup === null) {
      vue.$notify('Please select a location group before searching');
      return;
    }
    if (state.VABaseStore.selectedLayoutId === null) {
      vue.$notify('Please select a layout before searching');
      return;
    }

    // create single day date ranges
    const dayDateRanges = [];
    const currentTime = moment(state.selectedDateRange[0].toISOString());
    const endTimeMoment = moment(state.selectedDateRange[1].toISOString()).add(1, 'days');
    while (currentTime.isBefore(endTimeMoment)) {
      const currentDayDateRange = [];
      currentDayDateRange.push(currentTime.toISOString());
      currentTime.add(1, 'days');
      currentDayDateRange.push(currentTime.toISOString());
      dayDateRanges.push(currentDayDateRange);
    }

    // send out api call for each day
    const dataPerDay = await Promise.all(dayDateRanges.map(async (StartEndTimes) => {
      const params = {
        startTime: StartEndTimes[0],
        endTime: StartEndTimes[1],
        variants: state.VABaseStore.selectedVariant,
        locationGroup: state.VABaseStore.selectedLocationGroup,
        layout: state.VABaseStore.selectedLayoutId,
      };
      try {
        const availability = await VA_API.get('portfolio/data-availability', { params });
        const { data } = availability.data.data;
        if (data < 1) {
          vue.$notify('No data returned', 'error');
          return [];
        }
        return data;
      } catch (error) {
        console.error(error);
      }
      return [];
    }));
    const allData = dataPerDay.reduce((accumulator, currentValue) => {
      accumulator.push(...currentValue);
      return accumulator;
    }, []);
    await dispatch('processDataAvailability', allData);
  },
  processDataAvailability({ state, commit }, availability) {
    // create table configuration
    const config = [
      {
        label: 'Trade Date',
        dataType: 'string',
        prop: 'tradeDate',
        filterable: true,
        sortable: true,
        width: constValues.defaultColumns,
        columnType: 'default',
      },
      {
        label: 'Hour',
        dataType: 'number',
        prop: 'hour',
        filterable: true,
        sortable: true,
        width: constValues.defaultColumns,
        columnType: 'default',
      },
      {
        label: 'Interval',
        dataType: 'number',
        prop: 'interval',
        filterable: true,
        sortable: true,
        width: constValues.defaultColumns,
        columnType: 'default',
      },
      {
        label: 'Location Group',
        dataType: 'string',
        prop: 'locationGroup',
        filterable: true,
        sortable: true,
        width: constValues.defaultColumns,
        columnType: 'deafult',
      },
      {
        label: 'Variant',
        dataType: 'string',
        prop: 'variant',
        filterable: true,
        sortable: true,
        width: constValues.defaultColumns,
        columnType: 'deafult',
      },
    ];

    const defaultColumnList = config.map((column) => column.prop);
    const inDefaultColumnPropList = (prop) => defaultColumnList.some((defaultColumn) => defaultColumn === prop);
    const dataColumnsAnyMissingData = {};
    Object.keys(availability[0]).forEach((prop) => {
      switch (prop) {
      case 'StartTime':
      case 'EndTime':
        break;
      default:
        if (!inDefaultColumnPropList(prop)) {
          dataColumnsAnyMissingData[prop] = false;
          config.push({
            label: prop,
            dataType: 'string',
            prop,
            filterable: true,
            sortable: true,
            width: constValues.defaultColumns,
            columnType: 'dataColumn',
          });
        }
        break;
      }
    });
    commit('setAvailabilityTableConfigColumns', config);

    const formattedData = availability.map((a) => {
      Object.entries(a).forEach(([key, value]) => {
        if (key === 'StartTime') {
          // populate trade date, hour, interval columns
          const StartInterval = moment(value);
          const switchDSTDay = !(moment(value).add(-1, 'days').isDST() !== StartInterval.isDST());
          a.tradeDate = StartInterval.format('MM-DD-YYYY');
          a.hour = StartInterval.hour() + 1;
          // account for long day
          a.hour = dateStore.isLongDay(StartInterval) && a.hour === 2 && !switchDSTDay ? 25 : a.hour;
          a.interval = StartInterval.minute() + 5;
          a.locationGroup = state.VABaseStore.selectedLocationGroup !== null
            ? state.VABaseStore?.groups.find((locationGroup) => locationGroup.id === state.VABaseStore.selectedLocationGroup)?.label
            : null;
          a.variant = state.VABaseStore.selectedVariant;
          // populate fields from the search bar
        } else if (value === null) {
          a[key] = 'Missing';
        } else {
          const effectiveTime = moment(value);
          a[key] = effectiveTime.format('MM-DD-YYYY HH:mm:ss');
        }
      });
      return a;
    });

    // construct the missing only data set
    const availabilityTableDataMissingOnly = structuredClone(formattedData)
      .filter((data) => Object.values(data).includes('Missing')) // only grab missing rows
      .map((data) => { // remove the time stamp from the dataset
        Object.entries(data).forEach(([prop, value]) => {
          if (!inDefaultColumnPropList(prop)) {
            // remove timestamp for datapoint columns(non default) if available
            data[prop] = value === 'Missing' ? 'Missing' : null;
          }
        });
        return data;
      });

    const intervalSortingFunc = (a, b) => {
      // sort by tradedate, hour, interval
      if (a.tradeDate !== b.tradeDate) {
        return a.tradeDate < b.tradeDate ? -1 : 1;
      }
      if (a.hour !== b.hour) {
        return a.hour < b.hour ? -1 : 1;
      }
      return a.interval < b.interval ? -1 : 1;
    };

    availabilityTableDataMissingOnly.sort(intervalSortingFunc);
    formattedData.sort(intervalSortingFunc);

    commit('setAvailabilityTableDataMissingOnly', availabilityTableDataMissingOnly);
    commit('setAvailabilityTableData', formattedData);
  },
};

const mutations = {
  ...createMutations(
    'selectedDateRange',
    'availabilityTableData',
    'availabilityTableDataMissingOnly',
    'dataFilter',
  ),
  setAvailabilityTableConfigColumns(state, newColumns) {
    state.availabilityTableConfig.columns = newColumns;
  },
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  actions,
  mutations,
};