<template>
  <router-link :to="{ name: 'InstanceStatus', params:{ workflowName: scope.row.workflowName, caseId:scope.row.caseId }}">
    {{ scope.row.workflowName }}
  </router-link>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('workflow');

export default {
  name: 'InstanceRouteCell',
  props: {
    scope: {
      type: Object,
      required: true,
    },
  },
};
</script>
