import accountRefStore from './Accounts/AccountStore';
import eligibilityRefStore from './Eligibility/EligibilityStore';
import generationRefStore from './Generation/GenerationStore';
import organizationRefStore from './Organization/OrganizationStore';
import transactionRefStore from './Transaction/TransactionStore';

export default {
  namespaced: true,
  modules: {
    accountRef: accountRefStore,
    eligibilityRef: eligibilityRefStore,
    generationRef: generationRefStore,
    organizationRef: organizationRefStore,
    transactionRef: transactionRefStore,
  },
};