export default {
  name: 'stlmtrvwclndr',
  tableName: 'stlmtrvwclndr',
  columns: [{
    label: 'Publish Date',
    prop: 'publishDate',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Publish Day',
    prop: 'publishDay',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Trade Date',
    prop: 'flowDate',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Dispute Deadline',
    prop: 'dueDate',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Data Source',
    prop: 'datasource',
    filterable: true,
    sortable: true,
    visible: false,
  }, {
    label: 'File Type',
    prop: 'fileType',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Description',
    prop: 'desc',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'Comments',
    prop: 'comments',
    filterable: true,
    sortable: true,
    visible: false,
  }],
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
    showSelectFilter: false,
  },
};