import { REPORTING_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchReports({ commit }) {
    const { data } = await REPORTING_API.get('/reports/list');
    if (Array.isArray(data)) {
      commit('setTableData', data);
    }
  },
  async downloadReport(state, rowData) {
    const reportId = rowData.id;
    const { data } = await REPORTING_API.get(`/reports/reportFileId/${reportId}`);
    return data;
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};