import { PPA_REF_API } from '@/api';
import { clone } from '@/utils/dataUtil';
import utils from '@/utils';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  invoiceEntityList: [],
  invoiceEntityListOptions: [],
  nullTableRow: {
    id: -999,
    name: null,
    longName: null,
    companyId: null,
    counterPartyId: null,
    startDate: null,
    endDate: null,
    preProcessor: false,
    serviceAgreement: null,
    statClass: null,
    fercSchedule: null,
    sapAccountNumber: null,
    ppaGroup: null,
    oati: null,
    rateCase: null,
    scid: null,
    eimSub: null,
    energyReceivedFrom: null,
    energyDeliveredTo: null,
    pointOfReceipt: null,
    pointOfDelivery: null,
    billingDemand: null,
    noLosses: false,
    namePatternGroup: null,
    amountRounding: 2,
    volumeRounding: 3,
    volumeFactor: 1,
    deemedLoad: 'N',
    accountKey: null,
    dealNum: null,
    reference: null,
    porLocationName: null,
    instrumentType: null,
    portfolio: null,
    accountingDesignation: null,
    tradingCategory: null,
    loadArea: null,
    locTaxReferenceName: null,
    allocatedState: null,
    partyAgreementName: null,
    agreementName: null,
    fercTariffReference: null,
    externalLegalEntity: null,
    invoiceEntityName: null,
    invoiceEntityAccountNumber: null,
    invoiceEntityId: null,
    wregisId: null,
    unitId: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchInvoiceEntityList');
  },
  async fetchPPAs({ commit }, { invoiceEntitiesSelected: invoiceEntities }) {
    try {
      const { data: { data } } = await PPA_REF_API.post('/ppa/get', { invoiceEntities });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = x.endDate === null ? null : getMomentDateString(x.endDate);
        });
        commit('loadTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to fetch Invoice Entities', 'error');
    }
  },
  async fetchInvoiceEntityList({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/invoice-entity');
      commit('setInvoiceEntityList', data);
      commit('setInvoiceEntityListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Invoice Entity list', 'error');
      console.error(err);
    }
  },
  postRecord({ state }, PPA) {
    PPA_REF_API.post('/ppa', PPA).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = (data.endDate === null) ? null : getMomentDateString(data.endDate);
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  async updateRecord({ commit }, record) {
    PPA_REF_API.put(`/ppa/${record.id}`, record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = (data.endDate === null) ? null : getMomentDateString(data.endDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    });
  },
  async deleteRecord({ dispatch, commit, state }) {
    try {
      await PPA_REF_API.delete(`/ppa/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      this.$notify('Failed to Remove Data', 'error');
      console.error(err);
    }
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
  resetTable({ commit, state }) {
    commit('loadTableData', []);
    commit('currentTableRowChange', clone(state.nullTableRow));
  },
  reset({ commit }) {
    commit('currentTableRowChange', null);
    commit('loadTableData', []);
    commit('setInvoiceEntityList', []);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  setInvoiceEntityList(state, banks) {
    state.invoiceEntityList = banks;
  },
  setInvoiceEntityListOptions(state, invoiceEntityList) {
    state.invoiceEntityListOptions = invoiceEntityList?.map(({ id, shortName }) => ({ value: id, label: shortName }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};