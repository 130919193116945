import {
  tradeDateRange, contracts,
} from '@/components/ContractBilling/BaseStore/contractsBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'SCHEDULE ID',
    dataType: 'string',
    dataField: 'scheduleId',
    width: 120,
    area: 'column',
    expanded: true,
  }, {
    caption: 'CONTRACT NAME',
    dataType: 'string',
    dataField: 'contractName',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'number',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SCHEDULE TYPE',
    dataType: 'string',
    dataField: 'scheduleType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataType: 'number',
    format: '#,##0.#######',
    dataField: 'value',
    area: 'data',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataType: 'string',
    dataField: 'marketType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PRODUCT TYPE',
    dataType: 'string',
    dataField: 'productType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXECUTION TYPE',
    dataType: 'string',
    dataField: 'executionType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SOURCE SYSTEM',
    dataType: 'string',
    dataField: 'sourceSystem',
    area: 'filter',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    contracts,
  },
};