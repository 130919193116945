import { CISO_CIRA_API } from '@/api';
import config from './ciraPreCalcReportConfig';

const state = {
  tableData: [],
  tableConfig: config.fields,
  parameters: [],
};

const actions = {
  async fetchCIRAPreCalcReport({ commit, state }, { params }) {
    commit('setParameters', params);
    commit('setTableData', []);
    state.params = params;

    try {
      const { data } = await CISO_CIRA_API.get('/cirareport/precalc-report', { params });
      commit('setTableData', data);
    } catch (err) {
      if (err.response || err.message) {
        console.log(err);
      }
      this.$notify('Failed to Load CIRA Pre Calc Report', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = Object.freeze(data);
  },
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};