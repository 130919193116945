import moment from 'moment';
import utils from '@/utils';
import { has, clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  availableLocations: [],
  selectedLocations: [],
  dbSelectedLocations: [],
  deleteCache: [],
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'Group Id', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'shortName', label: 'Group Nm', filter: true, width: 200, editable: true,
    }, {
      prop: 'commodityName',
      label: 'Commodity',
      filter: true,
      width: 150,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'commodity',
        placeholder: 'Commodity',
        clearable: true,
      },
    }, {
      prop: 'marketName',
      label: 'Market',
      filter: true,
      width: 100,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'market',
        placeholder: 'Market',
        clearable: false,
      },
    }, {
      prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'createdDate',
      label: 'Created Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value === null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    }, {
      prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'updatedDate',
      label: 'Updated Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value === null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    }],
    columnList(name, row) {
      if (name === 'commodity') return this.registry.energyCommodityList;
      if (name === 'market') return this.registry.energyMarketList;
    },
    columnMapList(watcher, row) {
      return utils.data.columnSelectList([{}], watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
    },
    options: { searchFilter: false },
    registry: {
      energyCommodityList: [],
      energyMarketList: [],
      companyList: [],
      intrnlCompanyList: [],
    },
  },
  nullTableRow: {
    id: null,
    shortName: null,
    commodityName: null,
    marketName: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    recordState: 1,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getAvailableLocations: (state) => state.availableLocations,
  getSelectedLocations: (state) => state.selectedLocations,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('retrieveEnergyCommodityList');
    dispatch('retrieveMarketList');
    dispatch('loadTableData');
  },
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  reset({ commit, dispatch }) {
    commit('setNotificationCallback', null);
    commit('reset');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit, state }) {
    const response = await STRUCTURES_API.get('/location-groups?groupType=price_curve');
    const data = response.data.locationGroups;
    commit('loadTableData', data);
  },
  async loadChildTableData({ dispatch, commit, state }) {
    if (state.currentTableRow.recordState !== 1
        && (state.currentTableRow.priceCurveGroupLocationXrefs === undefined || state.currentTableRow.priceCurveGroupLocationXrefs === null || state.currentTableRow.termTypeDefinitions.length === 0)) {
      const response = await STRUCTURES_API.get(`/locations?market=${state.currentTableRow.marketName}&commodity=${state.currentTableRow.commodityName}`);
      const availableLocations = response.data.locations;
      const response2 = await STRUCTURES_API.get(`/location-groups/${state.currentTableRow.id}/locations`);
      const selectedLocations = response2.data.locations;
      commit('loadChildTableData', availableLocations);
      commit('setAvailableLocations', availableLocations);
      commit('setSelectedLocations', selectedLocations);
    } else {
      commit('loadChildTableData', state.currentTableRow);
    }
  },
  changeTableData({ dispatch, commit, state }, item) {
    commit('changeTableData', item);
  },
  insertTableRow({ dispatch, commit, state }) {
    commit('insertTableRow');
  },
  deleteTableRow({ dispatch, commit, state }) {
    commit('deleteTableRow');
  },
  async saveGroupLocations({ dispatch, commit, state }) {
    let response = [];
    state.selectedLocations.forEach(async (location) => {
      if (!(state.dbSelectedLocations.includes(location))) response = await STRUCTURES_API.post(`location-groups/${state.currentTableRow.id}/locations/${location}`);
    });
    state.dbSelectedLocations.forEach(async (location) => {
      if (!(state.selectedLocations.includes(location))) response = await STRUCTURES_API.delete(`location-groups/${state.currentTableRow.id}/locations/${location}`);
    });
    commit('saveGroupLocations');
  },
  changeSelectedLocations({ dispatch, commit, state }, item) {
    commit('changeSelectedLocations', item);
  },
  async saveTableData({ dispatch, commit, state }) {
    const dirtyRecs = state.tableData.filter((val) => val.recordState !== 0);
    let outputMsg = null;
    let success = 0;
    let failure = 0;
    console.log('dirtyRecs'); console.log(JSON.stringify(dirtyRecs));
    for (let d = 0; d < state.deleteCache.length; d++) {
      const deleteData = state.deleteCache[d];
      const response = await STRUCTURES_API.delete(`/price-curve-groups/${deleteData.id}`);
      if (response.status === 200) {
        success += 1;
        state.deleteCache.splice(deleteData, 1);
      } else {
        outputMsg = `Save record(s) failed to post delete. ${response.statusText}`;
        break;
      }
    }
    for (let i = 0; i < dirtyRecs.length; i++) {
      const data = dirtyRecs[i];
      console.log('data'); console.log(data);
      if (data.recordState === 1) {
        const response = await STRUCTURES_API.post('/location-groups', data);
        const returnRecord = response.data; console.log('response out'); console.log(response);
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id === data.id);
          console.log(`idx=${idx}`);
          state.tableData.splice(idx, 1, returnRecord);
          console.log('return record out'); console.log(returnRecord);
          if (returnRecord.validationMessage === null) success += 1;
          else failure += 1;
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      } else if (data.recordState === 2) {
        const response = await STRUCTURES_API.put(`/location-groups/${data.id}`, data);
        const returnRecord = response.data; console.log('response out'); console.log(response);
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id === data.id);
          state.tableData.splice(idx, 1, returnRecord);
          console.log('return record out'); console.log(returnRecord);
          if (returnRecord.validationMessage === null) success += 1;
          else failure += 1;
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      }
      outputMsg = `${success} record(s) saved successfully. `;
      if (failure > 0) outputMsg = `${outputMsg + failure} record(s) failed to save due to error.`;
    }
    console.log(`outputMsg=${outputMsg}`);
    return new Promise((resolve, reject) => resolve(outputMsg));
  },
  currentTableRowChange({ dispatch, commit, state }, selectedRows) {
    const currentRow = selectedRows[0];
    if (state.currentTableRow === undefined || (currentRow !== undefined && currentRow.id !== state.currentTableRow.id)) {
      state.currentTableRow = currentRow;
      state.selectedLocations = [];
      dispatch('loadChildTableData');
    }
  },
};
const mutations = {
  readyRegistryMarketList(state) {
    state.tableConfig.registry.energyMarketList = state.marketList.map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  readyRegistryEnergyCommodityList(state) {
    state.tableConfig.registry.energyCommodityList = state.energyCommodityList.map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  readyRegistryCompanyList(state) {
    state.tableConfig.registry.intrnlCompanyList = state.companyList
      .filter((val) => val.internalFlag)
      .map((m) => ({ value: m.shortName, label: m.shortName }));
    state.tableConfig.registry.companyList = state.companyList
      .map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  reset(state) {
  },
  setNotificationCallback(state, callback) {
    state.notificationCallback = callback;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = null;
  },
  loadTableData(state, model) {
    let companyCreditLimits = null;
    if (model !== null && has(model, 'priceCurveGroups')) {
      companyCreditLimits = model.companyCreditLimits;
      state.tableData = companyCreditLimits;
      state.tableData = model.priceCurveGroups;
    } else state.tableData = model;
  },
  setAvailableLocations(state, data) {
    const availableData = [];
    data.forEach((e) => availableData.push({ label: `${e.shortName} (${e.locationType})`, key: e.id }));
    state.availableLocations = availableData;
  },
  setSelectedLocations(state, data) {
    const selectedLocations = [];
    data.forEach((e) => selectedLocations.push(e.id));
    state.selectedLocations = selectedLocations;
    state.dbSelectedLocations = selectedLocations;
  },
  loadChildTableData(state, model) {
    let priceCurveGroupLocationXrefs = [];
    if (model !== null && has(model, 'priceCurveGroupLocationXrefs')) {
      priceCurveGroupLocationXrefs = model.priceCurveGroupLocationXrefs;
    }
    let priceGroup = null;
    for (let t = 0; t < state.tableData.length; t++) {
      priceGroup = state.tableData[t];
      if (priceGroup.id === state.currentTableRow.id) {
        break;
      }
    }
    priceGroup.priceCurveGroupLocationXrefs = priceCurveGroupLocationXrefs;
    state.selectedData = priceGroup.priceCurveGroupLocationXrefs.map((m) => ({ value: String(m.location), label: m.location }));
  },
  changeTableData(state, item) {
    const companyCreditLimit = state.tableData[item.rowIndex];
    console.log(`setting property ${item.prop} with value ${item.value}`); console.log(item);
    if (companyCreditLimit.recordState === 0 && companyCreditLimit[item.prop] !== item.value) {
      companyCreditLimit.recordState = 2;
      companyCreditLimit.updatedBy = this.getters['auth/getProfile'].userName;
      companyCreditLimit.updatedDate = utils.date.getNow().utc().format();
    }
    companyCreditLimit[item.prop] = item.value;
  },
  insertTableRow(state) {
    const companyCreditLimit = clone(state.nullTableRow);
    companyCreditLimit.creditLimitId = state.tableKey++;
    companyCreditLimit.versionId = 1;
    companyCreditLimit.recordState = 1;
    companyCreditLimit.createdBy = this.getters['auth/getProfile'].userName;
    companyCreditLimit.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = companyCreditLimit;
    state.tableData.splice(0, 0, companyCreditLimit);
  },
  deleteTableRow(state) {
    let companyCreditLimit = null;
    for (let t = 0; t < state.tableData.length; t++) {
      companyCreditLimit = state.tableData[t];
      if (companyCreditLimit.creditLimitId === state.currentTableRow.creditLimitId) {
        companyCreditLimit.recordState = 3;
        companyCreditLimit.isActive = false;
        companyCreditLimit.updatedBy = this.getters['auth/getProfile'].userName;
        companyCreditLimit.updatedDate = utils.date.getNow().utc().format();
        state.deleteCache.push(companyCreditLimit);
        state.tableData.splice(t, 1);
        break;
      }
    }
  },
  changeSelectedLocations(state, item) {
    state.selectedLocations = item;
  },
  saveGroupLocations(state) {
    state.dbSelectedLocations = state.selectedLocations;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};