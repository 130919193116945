import {
  coordinatorList, tradeDateRange, meterSubmitUnitList,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    meterSubmitUnitList,
  },
  fields: [{
    caption: 'SOURCE SYSTEM',
    dataField: 'sourceSystem',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'METER SYSTEM ID',
    dataField: 'meterSystemId',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'CHANNEL',
    dataField: 'channel',
    dataType: 'number',
    area: 'column',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataField: 'tradingInterval',
    area: 'row',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    format: '#,##0.####',
    area: 'data',
    expanded: true,
    summaryType: 'sum',
  }, {
    caption: 'METER DESCRIPTION',
    dataField: 'meterDescription',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EndTimeUtc',
    dataField: 'endTimeUtc',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EndTimeLocal',
    dataField: 'endTimeLocal',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'UpdatedTime',
    dataField: 'updatedTime',
    area: 'filter',
    expanded: true,
  }],
  mapConfig: {
    name: 'mapping',
    columns: [
      {
        label: 'SCID',
        prop: 'scid',
        width: 100,
        filterable: true,
        sortable: true,
      }, {
        label: 'Resource ID',
        prop: 'resourceId',
        width: 400,
        filterable: true,
        sortable: true,
      }, {
        label: 'MSID',
        prop: 'msid',
        width: 300,
        filterable: true,
        sortable: true,
      }, {
        label: 'CHANNEL',
        prop: 'channel',
        width: 100,
        filterable: true,
        sortable: true,
      }, {
        label: 'Multiplier',
        prop: 'multiplier',
        width: 140,
        filterable: true,
        sortable: true,
      }, {
        label: 'MultiplierDenominator',
        prop: 'multiplierDenominator',
        width: 160,
        filterable: true,
        sortable: true,
      }, {
        label: 'PMAX',
        prop: 'pmax',
        width: 100,
        filterable: true,
        sortable: true,
      }, {
        label: 'PMIN',
        prop: 'pmin',
        width: 100,
        filterable: true,
        sortable: true,
      }, {
        label: 'SourceSystem',
        prop: 'sourceSystem',
        width: 160,
        filterable: true,
        sortable: true,
      }, {
        label: 'MeterAggregationType',
        prop: 'meterAggregationType',
        width: 160,
        filterable: true,
        sortable: true,
      }, {
        label: 'ConvertToMWH',
        prop: 'convertToMWH',
        width: 100,
        filterable: true,
        sortable: true,
      }, {
        label: 'MeterDescription',
        prop: 'meterDescription',
        width: 160,
        filterable: true,
        sortable: true,
      }],
    options,
  },
  tabs: [{
    title: 'Raw Meter Data',
  }, {
    title: 'Mapping',
  }],
};