import moment from 'moment';
import Vue from 'vue';
import { clone, cloneDeep, has } from '@/utils/dataUtil';
import { CISO_SAMC_API } from '@/api';
import { upsertFieldConfig } from '../samcUtil';
import config from './gridConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  currentTableConfig: null,
  selectedCells: [],
  bdTree: [],
  currentBd: '',
  currentBdFormula: '',
  currentBpmFormula: '',
  initialParams: {},
  compareFlag: false,
  showRowTotals: false,
  attributes: [],
};

const actions = {
  async fetchAttributes({ commit, state }, params) {
    const { data } = await CISO_SAMC_API.get('/determinant-details/attributes', {
      params: {
        tradingDate: params.tradingDate,
        bdName: params.bdName,
      },
    });

    const defaultFields = cloneDeep(state.currentTableConfig ?? config.configData.fields);
    let currentFields = params.attributeFields || [];
    if (params.filteredFields) currentFields = params.filteredFields;
    defaultFields.forEach((d) => {
      const found = currentFields.find((c) => c.caption === d.caption);
      if (!found) {
        currentFields.push(d);
      } else {
        const currentFieldIdx = currentFields.indexOf(found);
        currentFields[currentFieldIdx] = { ...found, ...d };
      }
    });
    const hasCompareId = has(params, 'compareId');
    if (hasCompareId) commit('setShowRowTotals', true);
    if (!hasCompareId || (hasCompareId && params.compareId?.toString() === '0')) {
      commit('setCompareFlag', false);
      for (let x = 0; x < defaultFields.length; x++) {
        if (defaultFields[x].caption === 'COMPARE') {
          defaultFields[x].visible = false;
          break;
        }
      }
    } else {
      commit('setCompareFlag', true);
    }

    if (data && data.data) {
      commit('setTableConfig', upsertFieldConfig(currentFields, data.data));
      commit('setAttributes', data.data);
    }
  },
  async fetchDeterminantDetails({ dispatch, commit, state }, params) {
    commit('setTableData', []);
    try {
      const apiParams = {
        bdName: params.bdName,
        SCs: params.coordinatorList,
        chargeCode: params.chargeCode,
        rsrcId: params.rsrcId,
        tradingDate: params.tradingDate,
        fileType: params.fileType,
        filePublication: params.filePublication,
        fileVersion: params.fileVersion,
        compareFileType: params.compareFileType,
        comparePublication: params.comparePublication,
        compareVersion: params.compareVersion,
        fileId: params.fileId,
        cisoFileId: params.cisoFileId,
        compareId: params.compareId,
        cisoCompareId: params.cisoCompareId,
        he: params.he,
        ie: params.ie,
      };
      state.attributes
        .map((att) => att.name)
        .forEach((att) => apiParams[att] = params[att]);

      const response = await CISO_SAMC_API.get('/determinant-details', {
        headers: { 'api-version': '2.0' },
        params: apiParams,
      });

      dispatch('createTableData', response.data);
      commit('setCurrentBd', params.bdName);

      if (!state.bdTree.find((x) => x.bdName === params.bdName)) {
        commit('appendBdToTree', { bdName: params.bdName, params });
      }
    } catch (error) {
      this.$notify('Failed to load Determinant Details. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
  async fetchFormula({ dispatch, commit, state }, params) {
    try {
      const response = await CISO_SAMC_API.get('/configuration-file/formulas', {
        params,
      });
      commit('setCurrentBdFormula', `${response.data.data[0].name} = ${response.data.data[0].equation}`);
      commit('setCurrentBpmFormula', `${response.data.data[1].name} = ${response.data.data[1].equation}`);
    } catch (error) {
      this.$notify('Failed to load Formula. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
  createTableData({ commit, dispatch }, data) {
    if (data && data.data && data.data.length > 0) {
      const flatArray = [];
      data.data.forEach((head) => {
        head.attrs.forEach((mid) => {
          const dataArray = mid.data || [];
          dataArray.forEach((d) => {
            const newObj = {};
            Object.keys(d).forEach((key) => {
              if (!Array.isArray(d[key])) { newObj[key] = d[key]; }
            });
            Object.keys(mid).forEach((key) => {
              if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
            });
            Object.keys(head).forEach((key) => {
              if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
            });
            flatArray.push(newObj);
          });
        });
      });

      commit('setTableData', flatArray);
    }
  },

  async nextBdClicked({ dispatch }) {
    const currentBdNode = state.bdTree.find((x) => x.bdName === state.currentBd);
    const indexOfBd = state.bdTree.indexOf(currentBdNode);

    if (indexOfBd === state.bdTree.length - 1) {
      this.$notify('End of calculation tree reached', 'info', 3000);
    } else {
      const nextBdNode = state.bdTree[state.bdTree.indexOf(currentBdNode) + 1];
      dispatch('fetchAttributes', nextBdNode.params);
      dispatch('fetchDeterminantDetails', nextBdNode.params);
      dispatch('fetchFormula', nextBdNode.params);
    }
  },
  async prevBdClicked({ dispatch }) {
    const currentBdNode = state.bdTree.find((x) => x.bdName === state.currentBd);
    const indexOfBd = state.bdTree.indexOf(currentBdNode);

    if (indexOfBd === 0) {
      this.$notify('Beginning of calculation tree reached', 'info', 3000);
    } else {
      const prevBdNode = state.bdTree[state.bdTree.indexOf(currentBdNode) - 1];
      dispatch('fetchAttributes', prevBdNode.params);
      dispatch('fetchDeterminantDetails', prevBdNode.params);
      dispatch('fetchFormula', prevBdNode.params);
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setCurrentTableConfig(state, value) {
    state.currentTableConfig = value;
  },
  setCurrentBd(state, value) {
    state.currentBd = value;
  },
  setCurrentBdFormula(state, value) {
    state.currentBdFormula = value;
  },
  setCurrentBpmFormula(state, value) {
    state.currentBpmFormula = value;
  },
  setInitialParams(state, value) {
    state.initialParams = value;
  },
  appendBdToTree(state, value) {
    state.bdTree.push(value);
  },
  clearTableData(state) {
    state.tableData = [];
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  setAttributes(state, value) {
    state.attributes = value;
  },
  reset(state) {
    state.tableData = [];
    state.tableConfig = [];
    state.selectedCells = [];
    state.bdTree = [];
    state.currentBd = '';
    state.initialParams = [];
    state.compareFlag = false;
    state.attributes = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};