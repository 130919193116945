import moment from 'moment';
import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';

const state = {
  templateName: null,
  dateRange: [],
  templateSummaries: [],
  template: null,
  selectedSegment: null,
  volumes: [],
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getSelectedTemplateName: (state) => state.templateName,
  getTemplateSummaries: (state) => state.templateSummaries,
  getSelectedSegment: (state) => state.selectedSegment,
  getVolumes: (state) => state.volumes,
  getSegments(state) {
    const segments = [];
    let segmentId = 0;
    if (state.template) {
      for (let i = 0; i < state.template.segmentList.length; i++) {
        const segment = state.template.segmentList[i];
        for (let j = 0; j < segment.physicalSegmentList.length; j++) {
          const physicalSegment = segment.physicalSegmentList[j];
          if (i === 0 && j === 0) {
            segments.push({ value: segmentId, label: physicalSegment.pod });
            segmentId++;
          } else if (i === state.template.segmentList.length - 1 && j === segment.physicalSegmentList.length - 1) {
            segments.push({ value: segmentId, label: physicalSegment.por });
          } else {
            for (let k = 0; k < physicalSegment.transmissionAllocationList.length; k++) {
              const transmission = physicalSegment.transmissionAllocationList[k];
              segments.push({ value: segmentId, label: `${physicalSegment.por} -> ${physicalSegment.pod} (${transmission.contractNumber})` });
              segmentId++;
            }
          }
        }
      }
    }
    return segments;
  },
};

const actions = {
  initialize({ commit, dispatch }) {
    commit('initialize');
    dispatch('loadTemplateSummaries');
  },
  changeVolume({ commit }, item) {
    commit('setVolume', item);
  },
  changeDateRange({ commit }, item) {
    commit('setDateRange', item);
  },
  loadTemplateSummaries({ commit }) {
    ETAG_API.get('tags/variants/TEMPLATE').then((response) => {
      commit('setTemplateSummaries', response.data.tagSummaries);
    }).catch((error) => {
      console.log(error);
    });
  },
  loadSelectedTemplate({ commit }) {
    ETAG_API.get(`tags/variants/TEMPLATE/${state.templateName}`).then((response) => {
      commit('setTemplate', response.data);
    }).catch((error) => {
      console.log(error);
    });
  },
  changeTemplateName({ commit, dispatch }, item) {
    commit('setTemplateName', item);
    dispatch('loadSelectedTemplate');
  },
  changeSelectedSegment({ commit }, item) {
    commit('setSelectedSegment', item);
  },
  createTag({ state, dispatch }, item) {
    item.router.push({
      name: 'NewTagFromDeal',
      params: {
        templateName: state.templateName, segment: state.selectedSegment, dateRange: state.dateRange, volume: state.volumes,
      },
    });
    dispatch('reset');
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations = {
  initialize(state) {
    state.dateRange = dateStore.getDefaultRange();
  },
  setTemplateName(state, item) {
    state.templateName = item;
  },
  setTemplateSummaries(state, item) {
    state.templateSummaries = item;
  },
  setDateRange(state, item) {
    const start = moment(item[0]).add(-1, 'day').startOf('day').utc()
      .format();
    const end = moment(item[1]).add(-1, 'day').endOf('day').utc()
      .format();
    state.dateRange = [start, end];
  },
  setTemplate(state, item) {
    state.template = item;
  },
  setSelectedSegment(state, item) {
    state.selectedSegment = item;
  },
  setVolume(state, item) {
    state.volumes = item;
  },
  reset(state) {
    state.templateName = null;
    state.dateRange = [];
    state.template = null;
    state.selectedSegment = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};