import { CISO_SAMC_API } from '@/api';
import { has } from '@/utils/dataUtil';
import config from './EimAllocationValidationDetailsConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  params: null,
  showRowTotals: false,
  compareFlag: false,
  validationLabels: [],
  searchFiltersConfig: config.searchFiltersConfig,
};

const actions = {
  async fetchDeterminantDetailsValidation({ commit, state }, request) {
    commit('setTableData', []);
    try {
      const params = {
        scs: request.coordinatorListSelected,
        subCompany: request.subCompanyListSelected,
        startDate: request.tradeDateRangeSelected[0].toISOString(),
        endDate: request.tradeDateRangeSelected[1].toISOString(),
        fileType: request.fileTypesSelected,
        publication: request.publicationsSelected,
        version: request.fileVersionsSelected,
        chargeCode: request.eimChargeCodesSelected,
      };
      commit('setQueryParams', params);
      const { data } = await CISO_SAMC_API.get('/eim-sub-allocations/determinant-details/custom-client-validation', {
        headers: { 'api-version': '2.0' },
        params,
      });

      if (data && data.data) {
        const flatArray = [];
        data.data.forEach((head) => {
          const intervalData = head.data || [];

          intervalData.forEach((i) => {
            const newObj = {};
            Object.keys(i).forEach((key) => {
              if (!Array.isArray(i[key])) { newObj[key] = i[key]; }
            });
            Object.keys(head).forEach((key) => {
              if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
            });
            flatArray.push(newObj);
          });
        });

        commit('setTableData', flatArray);
      }
      if (data && data.validationData) {
        const headerLabels = [];
        let i = 1;
        data.validationData.forEach((obj) => {
          headerLabels.push({ id: i, data: [obj.validationCase, obj.status] });
          i++;
        });

        commit('setValidationLabels', headerLabels);
      }

      if (data && data.ptpData) {
        commit('setPTPData', data.ptpData);
      }
      if (data && data.dynamicRsrcData) {
        commit('setDynamicRsrcData', data.dynamicRsrcData);
      }
      if (data && data.wheelTagData) {
        commit('setWheelTagData', data.wheelTagData);
      }
    } catch (error) {
      this.$notify('Failed to load Determinant Details Validation', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  reset(state) {
    state.tableData = [];
    state.tableConfig = [];
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setQueryParams(state, value) {
    state.queryParams = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setValidationLabels(state, value) {
    state.validationLabels = value;
  },
  setPTPData(state, value) {
    state.ptpData = value;
  },
  setDynamicRsrcData(state, value) {
    state.dynamicRsrcData = value;
  },
  setWheelTagData(state, value) {
    state.wheelTagData = value;
  },

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};