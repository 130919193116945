import { clone } from '@/utils/dataUtil';
import { CMS_API } from '@/api';
import dateStore from '@/utils/dateStore';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    name: 'contractsTable',
    columns: [{
      prop: 'versionId',
      label: 'Version',
      dataType: 'number',
      filterable: false,
      sortable: true,
      alignment: 'left',
      width: '60px',
    }, {
      prop: 'status',
      label: 'Contract Status',
      dataType: 'string',
      filterable: true,
      sortable: true,
      // groupIndex: 0,
      alignment: 'left',
    }, {
      prop: 'shortName',
      label: 'Short Name',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
      width: '120px',
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ContractDetails',
        params: [{ key: 'guid', dataKey: 'guid' }],
      },
    }, {
      prop: 'description',
      label: 'Description',
      dataType: 'string',
      width: '200px',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'project',
      label: 'Project',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'contractType',
      label: 'Contract Type',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'contractNumber',
      label: 'Contract #',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'right',
      width: '100px',
    }, {
      prop: 'company',
      label: 'Company',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'counterParty',
      label: 'Counter Party',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'currencyName',
      label: 'Currency',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'paymentTerm',
      label: 'Payment Term',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'effectiveDate',
      label: 'Effective Date',
      filterable: true,
      sortable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'terminationDate',
      label: 'Termination Date',
      filterable: true,
      sortable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'createdBy',
      label: 'Created By',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'updatedBy',
      label: 'Updated By',
      dataType: 'string',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }],
    options: {
      exportExcel: true,
      groupPanel: true,
      columnConfig: true,
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: null,
    shortName: null,
    versionId: null,
    description: null,
    project: null,
    contractType: null,
    contractNumber: null,
    effectiveDate: null,
    terminationDate: null,
    company: null,
    counterParty: null,
    parentContract: null,
    currencyName: 'USD',
    paymentTerm: null,
    status: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchCompanyList', null, { root: true });
    dispatch('lookup/fetchContractList', null, { root: true });
    dispatch('lookup/fetchContractTypeList', null, { root: true });
    dispatch('lookup/fetchContractStateTypeList', null, { root: true });
    dispatch('lookup/fetchCurrencyList', null, { root: true });
    dispatch('lookup/fetchPaymentTermList', null, { root: true });
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  async loadTableData({ commit }, params) {
    try {
      const { data } = await CMS_API.get('/contracts', params);
      if (data.contracts.length > 0) {
        
        const timeZoneName = dateStore.getTimeZone();
        
        data.contracts.forEach((d, idx) => {
          d.effectiveDate = dateStore.toLocalFromDate(d.effectiveDate, timeZoneName);
          d.terminationDate = d.terminationDate ? dateStore.toLocalFromDate(d.terminationDate, timeZoneName) : null;
          d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
          d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
        });
          
        commit('loadTableData', data.contracts);
      } else {
        this.$notify('No Contracts Found');
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Contracts', 'error');
    }
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  async postContract({ state }, contract) {
    try {
      const { data } = await CMS_API.post('/contracts', contract);
      state.tableData.push(data);
      this.$notify('Contract Added', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Add Contract', 'error');
    }
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = null;
  },
  loadTableData(state, contracts) {
    state.tableData = contracts;
  },
  createTableRow(state) {
    const contract = clone(state.nullTableRow);
    contract.id = state.tableKey++;
    contract.versionId = 1;
    state.currentTableRow = contract;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};