import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  contractList: [],
  contractGroupList: [],
  contractGroupFlag: false,
  nullTableRow: {
    id: -999,
    bdName: null,
    contractId: null,
    contractGroupId: null,
    contractName: null,
    contractGroupName: null,
    startDate: null,
    endDate: null,
    val: null,
    valueFormat: null,
    description: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getContractList: (state) => state.contractList.map(({ id, name }) => ({ value: id, label: name })),
  getContractGroupList: (state) => state.contractGroupList.map(({ id, shortName }) => ({ value: id, label: shortName })),
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchContractList');
    dispatch('fetchContractGroupList');
  },
  async fetchSettlementRefData({ commit, state }, parameters = {}) {
    const params = {};
    if (state.contractGroupFlag) params.contractGroups = parameters.contractGroupsSelected;
    else params.contracts = parameters.contractsSelected;

    try {
      const { data: { data } } = await BILLING_REF_API.post(`/settlement-ref-${state.contractGroupFlag ? 'contract-group' : 'contract'}/get`, params);
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.endDate = obj.endDate === null ? null : getMomentDateString(obj.endDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Settlement Ref Data', 'error');
    }
  },
  fetchContractList({ commit }) {
    BILLING_REF_API.get('/contracts').then(({ data }) => {
      commit('loadContractList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  fetchContractGroupList({ commit }) {
    BILLING_REF_API.get('/contract-groups').then(({ data }) => {
      commit('loadContractGroupList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  postRecord({ state }, record) {
    record.startDate = getMomentDateString(record.startDate);
    record.endDate = record.endDate === null ? null : getMomentDateString(record.endDate);

    BILLING_REF_API.post(`/settlement-ref-${state.contractGroupFlag ? 'contract-group' : 'contract'}`, record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);
      data.valueFormat = record.valueFormat;

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  updateRecord({ dispatch, commit, state }, record) {
    record.startDate = getMomentDateString(record.startDate);
    record.endDate = record.endDate === null ? null : getMomentDateString(record.endDate);

    BILLING_REF_API.put(`/settlement-ref-${state.contractGroupFlag ? 'contract-group' : 'contract'}/${record.id}`, record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);
      data.valueFormat = record.valueFormat;

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/settlement-ref-${state.contractGroupFlag ? 'contract-group' : 'contract'}/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = clone(currentRow);
    // state.currentTableRow = undefined;
  },
  contractGroupFlagChange(state, contractGroupFlag) {
    state.contractGroupFlag = contractGroupFlag;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  loadContractGroupList(state, contractGroups) {
    state.contractGroupList = contractGroups;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};