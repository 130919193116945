import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import moment from 'moment';
import {
  coordinatorList,
  eimHeaders,
  subCompanyList,
  tradeDateRange,
  eimChargeCodes,
  gridConfig,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const intValue = {
  caption: 'INT VALUE',
  dataField: 'val',
  dataType: 'number',
  area: 'data',
  visible: true,
  summaryType: 'sum',
  format: '#,###.####',
  compareFiles: true,
  expanded: true,
  calculateSummaryValue: (e) => {
    let sum = 1;
    let file = 0;
    let compare = 0;
    const child = e.children('row');
    if (child && child.length > 0 && child[0]._rowPath.length > 0 && child[0]._rowPath[0].text === 'Compare') {
      compare = child[0]?._cell[0] ?? 0;
      file = child[1]?._cell[0] ?? 0;
      sum = compare - file;
      if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
      return sum;
    } if (child && child.length > 0 && child[0]._rowPath.length > 0 && child[0]._rowPath[0].text === 'File') {
      file = child[0]?._cell[0] ?? 0;
      compare = child[1]?._cell[0] ?? 0;
      sum = file - compare;
      if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
      return sum;
    }
    return e.cell() && e.cell().originalCell.length > 0 ? e.cell().originalCell[0] : 0;
  },
};

function getPub(pub, publications) {
  if (pub === 'MAX') {
    const filteredPublications = publications.filter(({ value }) => value !== pub);
    const filteredPub = filteredPublications.sort((firstVal, nextVal) => {
      const firstValLower = firstVal.value.toLowerCase();
      const nextValLower = nextVal.value.toLowerCase();
      if (firstValLower < nextValLower) { return 1; }
      if (firstValLower > nextValLower) { return -1; }
      return 0;
    })[0];
    return filteredPub.value;
  } return pub;
}

function fileTypeHelper({ $store, params }, dropDown) {
  const selectedCoordinator = params.coordinatorList.find(({ value }) => params.coordinatorListSelected === value);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);

  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }

  const filteredHeaders = params.eimHeaders.filter(({ ba, tradingDate }) => ba === selectedCoordinator.baId && (tradingDate.split('T')[0] >= startDateCompare && tradingDate.split('T')[0] <= endDateCompare));
  const filteredFileTypes = filteredHeaders.map((x) => x.fileType).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingFileTypes = filteredFileTypes.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
  $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingFileTypes]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, orderedDescendingFileTypes.length > 0 ? orderedDescendingFileTypes[0].value : null]);
  return true;
}

function publicationHelper(context, dropDown, fileTypeForPub) {
  const selectedCoordinator = context.params.coordinatorList.find(({ value }) => context.params.coordinatorListSelected === value);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(context.params.tradeDateRangeSelected);
  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = context.params.eimHeaders.filter(({ ba, tradingDate, fileType }) => ba === selectedCoordinator.baId && (tradingDate.split('T')[0] >= startDateCompare && tradingDate.split('T')[0] <= endDateCompare) && fileType === context.params[`${fileTypeForPub}Selected`]);
  const filteredPublications = filteredHeaders.map((x) => x.publication).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingPublications = filteredPublications.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
  if (orderedDescendingPublications.length > 1) {
    if (context.params.selectedCoordinator === 'ALL' || !moment(startDateCompare).isSame(moment(endDateCompare))) { orderedDescendingPublications.unshift({ value: 'MAX', label: 'MAX' }); }
  }
  const topPub = orderedDescendingPublications.length > 0 ? orderedDescendingPublications[0] : null;
  context.$store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingPublications]);
  context.$store.commit('ciso/setParams', [`${dropDown}Selected`, null]);
  context.$nextTick(() => context.$store.commit('ciso/setParams', [`${dropDown}Selected`, topPub ? topPub.value : null]));
  return true;
}

function versionHelper({ $store, params }, dropDown, fileTypeForVer, pubForVer) {
  const selectedCoordinator = params.coordinatorList.find(({ value }) => params.coordinatorListSelected === value);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);
  const pubCompare = getPub(params[`${pubForVer}Selected`], params[`${pubForVer}`]);
  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = params.eimHeaders.filter(({
    ba, tradingDate, fileType, publication,
  }) => ba === selectedCoordinator.baId && (tradingDate.split('T')[0] >= startDateCompare && tradingDate.split('T')[0] <= endDateCompare) && fileType === params[`${fileTypeForVer}Selected`] && publication === pubCompare);
  const filteredVersions = filteredHeaders.map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingVersions = filteredVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (orderedDescendingVersions.length > 1) {
    if (params.selectedCoordinator === 'ALL' || !moment(startDateCompare).isSame(moment(endDateCompare))) { orderedDescendingVersions.unshift({ value: -1, label: 'MAX' }); }
  }

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingVersions]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, topVersion ? topVersion.value : null]);
  return true;
}

export default {
  configData: {
    fields: [
      // Two instances of INT VALUE to allow filtering by field
      { ...intValue },
      { ...intValue, caption: 'VALUE FILTER', area: 'filter' },
      {
        caption: 'SCID',
        dataField: 'sc',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BA',
        dataField: 'ba',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING DATE',
        dataField: 'date',
        dataType: 'datetime',
        area: 'filter',
        format: 'yyyy-MM-dd',
        expanded: true,
      },
      {
        caption: 'TAG CODE',
        dataField: 'tagCode',
        dataType: 'string',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'RSRC ID',
        dataField: 'rsrcId',
        dataType: 'string',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'SUB COMPANY',
        dataField: 'subCompany',
        dataType: '',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'TRADING HOUR',
        dataField: 'he',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING INTERVAL',
        dataField: 'ie',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'File Type',
        dataField: 'cmp',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BD SourceType',
        dataField: 'bdSrc',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BD Order',
        dataField: 'bdOrder',
        dataType: 'number',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'BD Name',
        dataField: 'bd',
        dataType: 'string',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'RSRC TYPE',
        dataField: 'rty',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'PRICE NODE ID',
        dataField: 'priceNode',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'INTERTIE ID',
        dataField: 'intertie',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TAG ID',
        dataField: 'tagId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SRC',
        dataField: 'src',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SNK',
        dataField: 'snk',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'PATH',
        dataField: 'path',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'POR',
        dataField: 'por',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'POD',
        dataField: 'pod',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'GCA',
        dataField: 'gca',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'LCA',
        dataField: 'lca',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'PSE',
        dataField: 'pse',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SCHEDULE NAME',
        dataField: 'schdName',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
    ],
  },
  searchFiltersConfig: {
    eimHeaders,
    tradeDateRange,
    coordinatorList,
    eimChargeCodes,
    subCompanyList,
    fileTypes: {
      label: 'File Type',
      value: null,
      type: 'select',
      visible: true,
      options: [],
      watchers: [{
        propertyToWatch: 'eimHeaders',
        handler(newValue, oldValue) {
          fileTypeHelper(this, 'fileTypes');
          return true;
        },
      }, {
        propertyToWatch: 'tradeDateRangeSelected',
        handler(newValue, oldValue) {
          fileTypeHelper(this, 'fileTypes');
          return true;
        },
      }, {
        propertyToWatch: 'coordinatorListSelected',
        handler(newValue, oldValue) {
          fileTypeHelper(this, 'fileTypes');
          return true;
        },
      }],
    },
    publications: {
      label: 'Settlement Cycle',
      value: null,
      type: 'select',
      visible: true,
      options: [],
      watchers: [{
        propertyToWatch: 'fileTypesSelected',
        handler(newValue, oldValue) {
          publicationHelper(this, 'publications', 'fileTypes');
          return true;
        },
      }, {
        propertyToWatch: 'tradeDateRangeSelected',
        handler(newValue, oldValue) {
          publicationHelper(this, 'publications', 'fileTypes');
          return true;
        },
      }],
    },
    fileVersions: {
      label: 'Version',
      value: null,
      type: 'select',
      visible: true,
      options: [],
      watchers: [{
        propertyToWatch: 'publicationsSelected',
        handler(newValue, oldValue) {
          versionHelper(this, 'fileVersions', 'fileTypes', 'publications');
          return true;
        },
      }, {
        propertyToWatch: 'tradeDateRangeSelected',
        handler(newValue, oldValue) {
          versionHelper(this, 'fileVersions', 'fileTypes', 'publications');
          return true;
        },
      }, {
        propertyToWatch: 'coordinatorListSelected',
        handler(newValue, oldValue) {
          versionHelper(this, 'fileVersions', 'fileTypes', 'publications');
          return true;
        },
      }],
    },
  },
};