import moment from 'moment';
import { createMutations } from '@/utils/vuexHelper';
import {
  BIDDING_API, SCRIPTING_API, IDENTITY_API, STRUCTURES_API,
} from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { HERows } from '@/utils/dataUtil';
import REFERENCE_DATA_STORE from '../../referenceDataStore';
import { EMPTY_HOURLY_VALUE } from '../static';

const state = {
  // page params
  commodity: undefined,
  entityType: undefined,
  market: '',
  marketTypes: [],
  moduleName: '',
  tz: 'PPT',

  scripts: [],
  scriptCategories: [],
  editorCode: '',
  editor: null,

  strategyTableData: undefined,
  selectedLocations: [],
  marketType: '',
  startDate: dateStore.getDefaultDate().toISOString(),

  hourlyType: undefined,
  variableDialogVisibility: false,
  dailyGlobalVariablesData: [],
  hourlyValuesData: [],
  hourlyValuesConfig: undefined,
  strategiesSelected: [],
  auditData: [],

  scs: [],
  selectedSc: [],
  selectedLocationGroupId: undefined,
  selectedLocationGroup: undefined,
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scriptList: (state) => {
    const scriptCategory = state.scriptCategories.find(({ name }) => name === `${ state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`);
    const scripts = (scriptCategory) ? state.scripts.filter(({ categoryId }) => categoryId === scriptCategory.id) : [];
    return [...new Set(scripts.map(({ name }) => name))];
  },

  scriptCategoryName: (state) => `${ state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`,

  getEditorCode: (state) => state.editorCode,
  getEditor: (state) => state.editor,
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async init({ dispatch }) {
    await dispatch('fetchScripts');
    await dispatch('fetchScriptCategories');
    await dispatch('fetchScs');
    await dispatch('fetchReferenceData');
    dispatch('defaultUserSettings');
  },
  locationGroupSelected({ state, commit, dispatch }, value) {
    const name = state.REFERENCE_DATA_STORE.locationGroupList
      .find((locationGroup) => locationGroup.id === value)?.shortName;
    commit('setSelectedLocationGroupId', value);
    commit('setSelectedLocationGroup', name);

    if (value) {
      dispatch('fetchLocationGroupLocations', value);
    }
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    commit('setSelectedSc', [selectedSc]);

    const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (userLocationGroup) {
      dispatch('locationGroupSelected', userLocationGroup.id);
    }
  },

  setPageParams({ commit }, {
    commodity,
    entityType,
    market,
    marketTypes,
    moduleName,
  }) {
    commit('setCommodity', commodity);
    commit('setEntityType', entityType);
    commit('setMarket', market);
    commit('setMarketTypes', marketTypes);
    commit('setMarketType', marketTypes[0].value);
    commit('setModuleName', moduleName);
  },
  async fetchAuditData({ state, commit }, { selectedRecord }) {
    const { id, variableType } = selectedRecord;
    const startTime = state.startDate.split('T')[0];
    const params = { id, startTime, type: variableType };
    let mappedData = [];
    try {
      const { data } = await BIDDING_API.get(`/variables/${id}/revisions`, { params });
      if (data) {
        if (params.type === 'DAILY') {
          mappedData = data.variables.map(({
            revisionId, createdBy, createdDate, updatedDate, value,
          }) => ({
            id: revisionId,
            createdBy,
            createdDate,
            effectiveDate: updatedDate,
            value,
          })) ?? [];
        } else if (params.type === 'HOURLY') {
          const unformattedHourlyData = data.variables.reduce((acc, curr) => {
            if (curr.createdDate in acc) {
              acc[curr.createdDate][`he${ moment(curr.startTime).get('hour') + 1}`] = curr.value;
            } else {
              acc[curr.createdDate] = {
                createdBy: curr.createdBy,
                createdDate: curr.createdDate,
                effectiveDate: curr.updatedDate,
              };
              acc[curr.createdDate][`he${ moment(curr.startTime).get('hour') + 1}`] = curr.value;
            }
            return acc;
          }, {});

          Object.keys(unformattedHourlyData).forEach((item) => (mappedData.push(unformattedHourlyData[item])));
        }
      }
    } catch (ex) {
      mappedData = [];
      this.$notify('Failed to load audit logs for Variables', 'error');
    } finally {
      commit('setAuditData', mappedData);
    }
  },
  async fetchScriptCategories({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get('categories');
      commit('setScriptCategories', data.data);
    } catch (error) {
      console.error('Error fetching script categories', error);
    }
  },
  async fetchScripts({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get();
      commit('setScripts', data.data.map((props) => props));
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async fetchStrategies({ state, commit }) {
    commit('setStrategyTableData', []);

    const {
      commodity, entityType, market, marketType, location, moduleName, startDate, selectedSc, selectedLocationGroup,
    } = state;

    const reqBody = {
      commodity,
      market,
      entityType,
      marketType,
      location,
      startDate: moment(startDate).format('MM/DD/YYYY'),
      endDate: moment(startDate).add(1, 'days').format('MM/DD/YYYY'),
      module: moduleName,
      scs: selectedSc.length ? selectedSc.join() : null,
      locationGroup: selectedLocationGroup,
    };
    try {
      const { data } = await BIDDING_API.get('/strategies', { params: reqBody });
      commit('setStrategyTableData', data);
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async fetchScs({ commit, state }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === state.entityType));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchLocationGroupLocations({ commit, state }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    const lgl = Array.isArray(data.locationGroupLocations)
      ? data.locationGroupLocations.filter((location) => location.marketName === state.market)
      : [];
    commit('setLocationGroupLocations', lgl);
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationGroupList'],
      market: state.market,
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  async runStrategies({ state }) {
    const {
      commodity, market, entityType, moduleName, marketType, location, startDate, tz,
    } = state;
    const reqBody = {
      commodity,
      market,
      entityType,
      module: moduleName,
      marketType,
      location,
      startTime: moment(startDate).toISOString(),
      endTime: moment(startDate).add(1, 'days').toISOString(),
      tz,
      locations: state.selectedLocations,
    };

    try {
      await BIDDING_API.patch('/strategies', reqBody);
      this.$notify('Successfully Executed Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async updateStrategies({ state }, updatedStrategies) {
    try {
      await BIDDING_API.put('/strategies', { strategies: updatedStrategies });
      this.$notify('Successfully Updated Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async importStrategyScript({ state, commit }, fileName) {
    try {
      await SCRIPTING_API.post('/import', {
        configurationName: 'scripts-upload-configuration',
        fileName,
      });
      this.$notify({ type: 'success', message: 'Strategies Import Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Strategies Import Failed' });
      console.error(error);
    }
  },

  async fetchStrategyVariables({ state, dispatch }, params = { strategy: undefined }) {
    const {
      commodity, entityType, market, moduleName, startDate,
    } = state;
    const reqBody = {
      commodity,
      market,
      entityType,
      module: moduleName,
      startTime: startDate,
    };
    if (params.strategy) {
      try {
        const hourlyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData, strategy: params.strategy });
      } catch (error) {
        console.error('Error Fetching Hourly Strategy Variables', error);
      }
    } else {
      try {
        const hourlyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData });
      } catch (error) {
        console.error(`Error Fetching ${params.type} Variables`, error);
      }
    }
  },
  async createVariable({ state, dispatch }, { variableData, type, strategy }) {
    let resp;
    const reqBody = {
      commodity: state.commodity,
      market: state.market,
      entityType: state.entityType,
      module: state.moduleName,
      marketType: variableData.marketType,
      location: variableData.location,
      variables: [{
        ...variableData,
        startTime: state.startDate,
        endTime: null,
        type,
        scriptName: strategy,
      }],
    };

    if (strategy) {
      try { resp = await BIDDING_API.post(`/variables/${strategy}`, reqBody); } catch (error) { console.error('Error Posting Strategy Variable', error); }
    } else {
      try { resp = await BIDDING_API.post('/variables', reqBody); } catch (error) { console.error('Error Posting Global Variable', error); }
    }
    await dispatch('fetchStrategyVariables');
    return resp.data;
  },
  async updateVariable({ state, dispatch }, {
    variableData, type, strategy, marketType, location, visualOrder,
  }) {
    let variables;
    if (type === 'DAILY') {
      variables = [{
        ...variableData,
        startTime: state.startDate,
        endTime: null,
        type,
        scriptName: strategy,
      }];
    } else {
      const config = variableData || state.hourlyValuesConfig;
      const { id, name, valueType } = config;
      const formattedValuesData = [];
      marketType = state.hourlyValuesConfig?.marketType;
      strategy = strategy || state.hourlyValuesConfig?.strategies?.toString();
      Object.keys(state.hourlyValuesData[0]).forEach((x) => {
        if (x !== 'id') {
          formattedValuesData.push({ he: x.replace('he', ''), value: state.hourlyValuesData[0][x] });
        }
      });
      variables = formattedValuesData.map(({ he, value }) => ({
        id,
        name,
        type,
        value,
        valueType,
        startTime: moment(state.startDate).add(he - 1, 'hours').toISOString(),
        endTime: moment(state.startDate).add(he, 'hours').toISOString(),
        scriptName: strategy,
        marketType,
        location,
        visualOrder,
      }));
    }
    const marketTypeParsed = marketType || null;
    const reqBody = {
      commodity: state.commodity,
      market: state.market,
      entityType: state.entityType,
      module: state.moduleName,
      marketType: marketTypeParsed,
      location,
      variables,
    };
    if (strategy) {
      try { await BIDDING_API.post(`/variables/${strategy}`, reqBody); } catch (error) { console.error('Error Posting Strategy Variable', error); }
    } else {
      try { await BIDDING_API.post('/variables', reqBody); } catch (error) { console.error('Error Posting Global Variable', error); }
    }
    dispatch('fetchStrategyVariables');
  },
  async deleteVariable({ state, commit }, id) {
    try {
      await BIDDING_API.delete(`/variables/${id}`);
      const hourlyValues = HERows({}, true, state.startDate).map(({ he }) => ({ value: undefined, he }));
      commit('setHourlyValuesData', hourlyValues);
    } catch (error) {
      console.error('Error Deleting Variable', error);
    }
  },
  async mapVariables({ dispatch, state, commit }, { combinedData, type, strategy }) {
    let tableData = [];
    let map = [];
    let variables = combinedData?.daily?.variables || [];
    if (variables) {
      tableData = variables.map(({
        name, valueType, value, id, marketType, location, scriptName,
      }) => ({
        name,
        valueType,
        value,
        id,
        marketType,
        location,
        variableType: 'DAILY',
        strategies: scriptName?.split(',') || [],
      }));
    }
    variables = combinedData?.hourly?.variables || [];
    if (variables) {
      map = variables.reduce((acc, curr) => {
        if (curr.id in acc) {
          acc[curr.id].values.push({
            he: moment(curr.startTime).get('hour') + 1,
            value: curr.value,
          });
        } else {
          acc[curr.id] = {
            id: curr.id,
            name: curr.name,
            valueType: curr.valueType,
            marketType: curr.marketType,
            location: curr.location,
            variableType: 'HOURLY',
            value: EMPTY_HOURLY_VALUE,
            strategies: curr.scriptName?.split(',') || undefined,
            config: {
              id: curr.id,
              name: curr.name,
              valueType: curr.valueType,
              marketType: curr.marketType,
              location: curr.location,
              strategies: curr.scriptName?.split(',') || undefined,
            },
            values: [{
              he: moment(curr.startTime).get('hour') + 1,
              value: curr.value,
            }],
          };
        }
        return acc;
      }, {});

      Object.keys(map).forEach((item) => (tableData.push(map[item])));
    }

    if (state.strategiesSelected.length > 0) {
      tableData = tableData.filter((element) => state.strategiesSelected.some((val) => !!element.strategies && element.strategies.indexOf(val) !== -1));
    }

    commit('setDailyGlobalVariablesData', tableData);
  },
  updateHourlyValuesData({ state, commit }, { id, variableTypeChanged }) {
    const hourlyValues = [{ id }];
    const iteratingOverMap = state.dailyGlobalVariablesData;
    if (iteratingOverMap.length < 1) return;
    let hourlyData = {};
    iteratingOverMap.forEach((object) => {
      if (object.id === id) {
        hourlyData = object;
      }
    });
    if (variableTypeChanged) {
      [...Array(24).keys()].forEach((x) => hourlyValues[0][`he${ x + 1}`] = hourlyData.value);
      hourlyData.config = {
        id,
        name: hourlyData.name,
        valueType: hourlyData.valueType,
        marketType: hourlyData.marketType,
        location: hourlyData.location,
      };
    } else {
      hourlyData.values.forEach((data) => hourlyValues[0][`he${ data.he}`] = data.value);
    }

    commit('setHourlyType', 'GLOBAL');
    commit('setHourlyValuesData', hourlyValues);
    commit('setHourlyValuesConfig', hourlyData.config);
  },
};

const mutations = {
  changeHourlyValuesData(state, { rowIndex, prop, value }) {
    state.hourlyValuesData[0][prop] = value;
  },

  setEditorSession(state) {
    const editor = ace.edit('scriptEditor');
    editor.getSession().setMode('ace/mode/javascript');
    editor.setTheme('ace/theme/monokai');
    editor.setShowPrintMargin(false);
    editor.session.setValue('');
    editor.$blockScrolling = Infinity;
    editor.setFontSize(12);
    editor.setHighlightActiveLine(true);

    state.editor = editor;
  },
  setEditorCode(state, item) {
    let code = item;
    code = JSON.parse(JSON.stringify(code, null));

    state.editorCode = code;
    state.editor.session.setValue(state.editorCode);
  },

  ...createMutations(
    'scripts',
    'scriptCategories',
    'startDate',
    'strategyTableData',
    'selectedLocations',
    'marketType',
    'marketTypes',
    'location',
    'commodity',
    'entityType',
    'market',
    'moduleName',
    'hourlyType',
    'variableDialogVisibility',
    'dailyGlobalVariablesData',
    'hourlyValuesData',
    'hourlyValuesConfig',
    'auditData',
    'scs',
    'selectedSc',
    'selectedLocationGroupId',
    'selectedLocationGroup',
    'locationGroupLocations',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};
