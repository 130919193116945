const legacy_ppa: any = {
  menu: /legacy_ppa:*/,
  referenceData: {
    menu: /legacy_ppa:reference_data:*/,
    invoiceEntities: 'legacy_ppa:reference_data:invoice_entity_ref',
    ppas: 'legacy_ppa:reference_data:ppa_ref',
    customerMasterfile: 'legacy_ppa:reference_data:customer_masterfile',
    chargeCodes: 'legacy_ppa:reference_data:charge_code_ref',
    ppaCharges: 'legacy_ppa:reference_data:contract_charge_ref',
    banks: 'legacy_ppa:reference_data:bank_ref',
    pointOfContact: 'legacy_ppa:reference_data:point_of_contact_ref',
    settlementReference: 'legacy_ppa:reference_data:settlement_ref',
    meter: 'legacy_ppa:reference_data:meter_ref',
    schedule: 'legacy_ppa:reference_data:schedule_ref',
    reservation: 'legacy_ppa:reference_data:reservation_ref',
  },
  sourceData: {
    menu: /legacy_ppa:source_data:*/,
    ppaMeter: 'legacy_ppa:source_data:ppa_meter',
    ppaMv90: 'legacy_ppa:source_data:ppa_meter_mv90',
    ppaPrices: 'legacy_ppa:source_data:ppa_price',
    ppaSchedules: 'legacy_ppa:source_data:ppa_schedules',
    ppaReservations: 'legacy_ppa:source_data:ppa_reservation',
    ppaDeterminantData: 'legacy_ppa:source_data:ppa_determinant',
    customFileImport: 'legacy_ppa:source_data:custom_file_import',
  },
  invoicing: {
    menu: /legacy_ppa:invoicing/,
    createInvoice: 'legacy_ppa:invoicing:invoice_create',
    approveInvoice: 'legacy_ppa:invoicing:invoice_approve',
    subledger: 'legacy_ppa:invoicing:invoice_subledger',
    generateFile: 'legacy_ppa:invoicing:generate_file',
    sendToAccounting: 'legacy_ppa:invoicing:invoice_subledger:send_to_accounting',
    inactivateApprovedInvoice: 'legacy_ppa:invoicing:invoice_inactive_approved',
    batchApprovalDatesExclude: 'legacy_ppa:invoicing:approval_dates_exclude',
  },
  calculation: 'legacy_ppa:calculation:ppa_calc',
  reporting: 'legacy_ppa:reporting:ppa_reports',
  import: 'legacy_ppa:import:ppa_data_imports',
  import_eim: 'legacy_ppa:import:eim_ppa_transfers',
  settlements: {
    menu: /legacy_ppa:settlements/,
    statement: 'legacy_ppa:settlements:statement',
    determinantDetail: 'legacy_ppa:settlements:determinant_detail',
    override: 'legacy_ppa:settlements:override',
    invoiceDetails: 'legacy_ppa:settlements:invoice_details',
  },
};

export default legacy_ppa;