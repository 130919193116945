import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import config from './EimAllocationChargeCodeDetailsConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
};

const actions = {
  async fetchChargeCodeDetails({ commit }, params) {
    commit('setTableData', []);
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/charge-code-detail', { params });
      commit('setTableData', data);
    } catch (error) {
      this.$notify('Failed to load Charge Code Details', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    if (value) value.forEach((x) => { x.tradingDate = getMomentDateString(x.tradingDate); });
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    if (value && value.length > 0) state.tableConfig = value;
    else state.tableConfig = config.configData.fields;
  },
  reset(state) {
    state.tableData = [];
    state.tableConfig = config.configData.fields;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};