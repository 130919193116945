import wfInstancesStore from '../wfInstances/store';
import wfInstanceStatusStore from '../wfInstanceStatus/store';
import wfStore from '../Overview/store';

export default {
  namespaced: true,
  modules: {
    workflow: wfStore,
    workflowInstance: wfInstancesStore,
    workflowInstanceStatus: wfInstanceStatusStore,
  },
};
