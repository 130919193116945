import {
  coordinatorList, tradeDateRange, marketTypes, resourcesTagbox,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    dateType: {
      label: 'Date Type',
      value: 'CreatedDate',
      type: 'select',
      options: [
        'CreatedDate',
        'TradeDate',
      ].map((value) => value),
    },
  },
  submitHeadersConfig: {
    name: 'submitHeaders',
    columns: [
      {
        label: 'Import ID',
        prop: 'importId',
        filterable: true,
        sortable: true,
      }, {
        label: 'SCID',
        prop: 'scid',
        filterable: true,
        sortable: true,
      }, {
        label: 'File Name',
        prop: 'fileName',
        filterable: true,
        sortable: true,
      }, {
        label: 'Start Trading Date',
        prop: 'startTradingDate',
        format: 'yyyy-MM-dd',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'End Trading Date',
        prop: 'endTradingDate',
        format: 'yyyy-MM-dd',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'Created Date',
        prop: 'createdDate',
        format: 'yyyy-MM-dd',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'Status',
        prop: 'status',
        filterable: true,
        sortable: true,
      }],
    options,
  },
  submitDataConfig: {
    name: 'submitData',
    columns: [
      {
        label: 'Import ID',
        prop: 'importId',
        filterable: true,
        sortable: true,
      }, {
        label: 'Measurement Quality',
        prop: 'measurementQuality',
        filterable: true,
        sortable: true,
      }, {
        label: 'UOM',
        prop: 'uom',
        filterable: true,
        sortable: true,
      }, {
        label: 'Interval Length',
        prop: 'itvlLen',
        filterable: true,
        sortable: true,
      }, {
        label: 'Measurement Type',
        prop: 'measurementType',
        filterable: true,
        sortable: true,
      }, {
        label: 'Resource ID',
        prop: 'resourceId',
        filterable: true,
        sortable: true,
      }, {
        label: 'GMT End Time',
        prop: 'gmtEndTime',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Date',
        prop: 'tradingDate',
        format: 'yyyy-MM-dd',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Hour',
        prop: 'tradingHour',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Interval',
        prop: 'tradingInterval',
        filterable: true,
        sortable: true,
      }, {
        label: 'Value',
        prop: 'val',
        filterable: true,
        sortable: true,
      }],
    options,
  },
  submitLogConfig: {
    name: 'submitLog',
    columns: [
      {
        label: 'Import ID',
        prop: 'importId',
        filterable: true,
        sortable: true,
      }, {
        label: 'ISO Batch ID',
        prop: 'isoBatchID',
        filterable: true,
        sortable: true,
      }, {
        label: 'Action',
        prop: 'action',
        filterable: true,
        sortable: true,
      }, {
        label: 'Status',
        prop: 'status',
        filterable: true,
        sortable: true,
      }, {
        label: 'Error Code',
        prop: 'errorCode',
        filterable: true,
        sortable: true,
      }, {
        label: 'Resource ID',
        prop: 'resourceId',
        filterable: true,
        sortable: true,
      }, {
        label: 'Resource Type',
        prop: 'resourceType',
        filterable: true,
        sortable: true,
      }, {
        label: 'Measurement Type',
        prop: 'measurementType',
        filterable: true,
        sortable: true,
      }, {
        label: 'Log Time',
        prop: 'logTime',
        format: 'yyyy-MM-dd HH:mm',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'Message',
        prop: 'message',
        filterable: true,
        sortable: true,
      }],
    options,
  },
  submitDiffConfig: {
    name: 'submitDiff',
    columns: [
      {
        label: 'Import ID',
        prop: 'importId',
        filterable: true,
        sortable: true,
      }, {
        label: 'SCID',
        prop: 'scid',
        filterable: true,
        sortable: true,
      }, {
        label: 'Resource ID',
        prop: 'rsrcId',
        filterable: true,
        sortable: true,
      }, {
        label: 'Interval Length',
        prop: 'itvlLen',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Date',
        prop: 'tradingDate',
        format: 'yyyy-MM-dd',
        dataType: 'date',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Hour',
        prop: 'tradingHour',
        filterable: true,
        sortable: true,
      }, {
        label: 'Trading Interval',
        prop: 'tradingInterval',
        filterable: true,
        sortable: true,
      }, {
        label: 'RsrcTradeDateMaxItvls',
        prop: 'maxItvls',
        filterable: true,
        sortable: true,
      }, {
        label: 'IntervalsSubmitted',
        prop: 'submitItvls',
        filterable: true,
        sortable: true,
      }, {
        label: 'IntervalCheck',
        prop: 'itvlChk',
        filterable: true,
        sortable: true,
      }, {
        label: 'SubmittedValue',
        prop: 'submitVal',
        filterable: true,
        sortable: true,
      }, {
        label: 'DownloadedValue',
        prop: 'downloadVal',
        filterable: true,
        sortable: true,
      }, {
        label: 'Variance',
        prop: 'variance',
        filterable: true,
        sortable: true,
      }],
    options,
  },
  tabs: [{
    title: 'DATA',
  }, {
    title: 'LOG',
  }, {
    title: 'DIFF',
  }],
};