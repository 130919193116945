import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_CMRI_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchNonDispatchableTime({ commit }, parameters) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      rsrc_id: parameters.resourceListSelected.join(','),
      startDate,
      endDate,
    };
    try {
      const { data } = await CISO_CMRI_API.get('/non-dispatchable-time', { params });
      data.forEach((obj) => {
        obj.tzGmtStartTime = moment(obj.tzGmtStartTime).utc().format('YYYY-MM-DD hh:mm:ss A');
        obj.tzGmtEndTime = moment(obj.tzGmtEndTime).utc().format('YYYY-MM-DD hh:mm:ss A');
      });
      commit('setTableData', data);
    } catch (error) {
      this.$notify('Failed to Load Non-Dispatchable Time', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};