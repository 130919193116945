import * as tagUtils from '@/utils/etag/tagUtils';
import utils from '@/utils';
import helpers from '@/utils/helpers';
import { has } from '@/utils/dataUtil';
import * as CONSTS from './constants';
import marketPathTableConfiguration from './transmissionMarketPathConfiguration';
import physicalPathTableConfiguration from './transmissionPhysicalPathConfiguration';
import allocationPathTableConfiguration from './transmissionAllocationPathConfiguration';
import lossTableConfiguration from './transmissionLossAccountingConfiguration';
import { requestData } from '../../API';

function search(query, column, row) {
  let uri = '';
  let params = {};
  let mapper = null;
  let dataProperty = '';

  if (column.prop === 'market_pse') {
    uri = '/registry/pses';
    dataProperty = 'pseList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'market_product') {
    uri = '/registry/products?type=generation';
    dataProperty = 'productList';
    if (query !== null && query !== '') params = { name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  } else if (column.prop === 'physical_mo') {
    uri = '/registry/mos';
    dataProperty = 'moList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'physical_se') {
    uri = '/registry/bas';
    dataProperty = 'baList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'physical_tp') {
    uri = '/registry/tps';
    dataProperty = 'tpList';
    params = { select_by: 'tsp' };

    if (query !== null && query !== '') params = { ...params, name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'physical_por') {
    if (row.physical_se_name === null || row.physical_tp_name === null) {
      return new Promise((resolve, reject) => resolve([]));
    }

    uri = '/registry/porpods?type=por';
    dataProperty = 'porpodList';
    params = { select_by: 'por', tsp: row.physical_tp_name };

    if (query !== null && query !== '') params = { ...params, name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  } else if (column.prop === 'physical_pod') {
    if (row.physical_se_name === null || row.physical_tpname === null || row.physical_por_name === null) {
      return new Promise((resolve, reject) => resolve([]));
    }

    uri = '/registry/porpods?type=pod';
    dataProperty = 'porpodList';
    params = { select_by: 'pod', tsp: row.physical_tp_name };
    if (query !== null && query !== '') params = { ...params, name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  } else if (column.prop === 'trans_tc' || column.prop === 'trans_tc_name') {
    if (
      row.physical_se_name === null
      || row.physical_tp_name === null
      || row.physical_por_name === null
      || row.physical_pod_name === null
    ) {
      return new Promise((resolve, reject) => { resolve([]); });
    }
    uri = '/registry/pses';
    dataProperty = 'pseList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'trans_product' || column.prop === 'trans_product_name') {
    uri = '/registry/products?type=transmission';
    dataProperty = 'productList';
    if (query !== null && query !== '') params = { name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  }
  return requestData(uri, params, dataProperty, mapper);
}

const state = {
  marketPathTable: marketPathTableConfiguration,
  physicalPathTable: physicalPathTableConfiguration,
  allocationTable: allocationPathTableConfiguration,
  lossTable: lossTableConfiguration,
  nullTransmissionRow: {
    guid: null,
    type: 'TRAN',
    // position: null,
    market_id: null,
    market_pse_name: null,
    market_product_name: null,
    market_miscInfoCount: 0,
    market_miscInfoList: [],
    market_contractCount: 0,
    market_contractList: [],
    physical_id: null,
    physical_se_name: null,
    physical_tp_name: null,
    physical_por_name: null,
    physical_pod_name: null,
    physical_mo_name: null,
    physical_miscInfoCount: 0,
    physical_miscInfoList: [],
    trans_num: null,
    trans_id: null,
    trans_product_name: null,
    trans_contract: null,
    trans_tc_name: null,
    trans_miscInfoCount: 0,
    trans_miscInfoList: [],
    trans_nits: null,
    trans_state: 'dirty',
    loss_accountingCount: 0,
    loss_accountingList: [],
    loss_type: null,
    loss_start: null,
    loss_stop: null,
    loss_tagList: [],
    loss_tagCount: 0,
    loss_contractCount: 0,
    loss_contractList: [],
  },
  transmissions: [],
  transmissionErrors: [],
};

const getters = {
  getMarketPathTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = helpers.cloneDeep(state.marketPathTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullTransmissionRow, exclusionList);
    }
    configuration.remote = { search, nullColumnSearch: ['market_product', 'market_product_name'] };
    return configuration;
  },
  getPhysicalPathTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = helpers.cloneDeep(state.physicalPathTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullTransmissionRow, exclusionList);
    }
    configuration.remote = { search, nullColumnSearch: ['physical_por', 'physical_pod'] };
    return configuration;
  },
  getAllocationPathTableConfiguration(state, readonly = true, showAddRemove = false, exclusionList = null) {
    const configuration = helpers.cloneDeep(state.allocationTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullTransmissionRow, exclusionList);
    }
    if (showAddRemove) configuration.options.showAddRemove = true;
    configuration.remote = { search, nullColumnSearch: ['trans_product', 'trans_product_name'] };
    return configuration;
  },
  getLossAccountingTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = helpers.cloneDeep(state.lossTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullTransmissionRow, exclusionList);
    }
    return configuration;
  },
  getData: (state) => state.transmissions,
  getAllLossAccouting: (state) => state.transmissions.reduce((acc, curr) => {
    const lossData = curr.loss_accountingList[0];
    if (lossData) {
      const loss = {
        id: curr.physical_id,
        method: lossData.method,
        start: lossData.start,
        stop: lossData.stop,
      };

      return [...acc, loss];
    }
    return acc;
  }, []),
  getAllLossMisc: (state) => state.transmissions.reduce((acc, curr) => {
    const lossData = curr.loss_accountingList[0];
    if (lossData) {
      const tags = lossData.tagList.map((tagId) => ({ id: curr.physical_id, type: 'Tag Id', value: tagId }));
      const contracts = lossData.contractList.map((contract) => ({ id: curr.physical_id, type: 'Contract', value: contract }));
      if (contracts.length || tags.length) return [...acc, ...contracts, ...tags];
    }
    return acc;
  }, []),
  getCount: (state) => state.transmissions.length,
  cloneData: (state) => helpers.cloneDeep(state.transmissions),
};

const actions = {
  changeMarketPath({ commit, dispatch }, item) {
    commit('setTransmission', item);
    dispatch('calculateMarketSegmentIDs');
  },
  changePhysicalPath({ commit, dispatch }, state, item) {
    if (['physical_se', 'physical_tp', 'physical_por', 'physical_pod'].includes(item.prop)) {
      item.prop = `${item.prop}_name`;
    }
    commit('setTransmission', item);
    dispatch('calculatePhysicalSegmentIDs');
    const row = state.transmissions[item.rowIndex];

    if (item.prop === 'physical_tp_name') {
      if (row.physical_por_name !== '' && row.physical_por_name !== null) {
        search(row.physical_por_name, { prop: 'physical_por' }, row).then((res) => {
          const match = res.find((por) => por.label === row.physical_por_name);

          if (match !== undefined && match !== null) {
            commit('setTransmission', {
              eventData: match, value: match.value, rowIndex: item.rowIndex, prop: 'physical_por_name',
            });
          } else {
            commit('setTransmission', {
              eventData: { value: null, label: null }, value: null, rowIndex: item.rowIndex, prop: 'physical_por_name',
            });
          }
          dispatch('calculatePhysicalSegmentIDs');
        });
      }
      if (row.physical_pod_name !== '' && row.physical_pod_name !== null) {
        search(row.physical_pod_name, { prop: 'physical_pod' }, row).then((res) => {
          const match = res.find((pod) => pod.label === row.physical_pod_name);

          if (match !== undefined && match !== null) {
            commit('setTransmission', {
              eventData: match, value: match.value, rowIndex: item.rowIndex, prop: 'physical_pod_name',
            });
          } else {
            commit('setTransmission', {
              eventData: { value: null, label: null }, value: null, rowIndex: item.rowIndex, prop: 'physical_pod_name',
            });
          }
          dispatch('calculatePhysicalSegmentIDs');
        });
      }
    }
  },
  changeAllocationPath({ commit, dispatch }, item) {
    commit('setTransmission', item);
    if (item.prop === 'trans_contract') dispatch('changeProfileColumns');
  },
  marketPathRowOperation({ commit, dispatch }, item) {
    if (item.type === 'ADD') {
      dispatch('addTransmissionMarketPath', { rowIndex: item.rowIndex + 1 });
    } else if (item.type === 'REMOVE') {
      dispatch('removeTransmissionMarketPath', { rowIndex: item.rowIndex });
    }
  },
  addMarketPath({ commit, dispatch }, item) {
    commit('addTransmissionMarketPath', item);
    dispatch('calculateMarketSegmentIDs');
  },
  removeMarketPath({ commit, dispatch }, item) {
    commit('removeTransmissionMarketPath', item);
    dispatch('calculateMarketSegmentIDs');
    dispatch('changeProfileColumns');
  },
  physicalPathRowOperation({ commit, dispatch }, item) {
    if (item.type === 'ADD') {
      dispatch('addTransmissionPhysicalPath', { fromIndex: item.rowIndex, toIndex: item.rowIndex + 1 });
    } else if (item.type === 'REMOVE') {
      dispatch('removeTransmissionPhysicalPath', { rowIndex: item.rowIndex });
    }
  },
  addPhysicalPath({ commit, dispatch }, item) {
    commit('addTransmissionPhysicalPath', item);
    dispatch('calculatePhysicalSegmentIDs');
  },
  removePhysicalPath({ commit, dispatch }, item) {
    commit('removeTransmissionPhysicalPath', item);
    dispatch('calculateMarketSegmentIDs');
    dispatch('changeProfileColumns');
  },
  allocationPathRowOperation({ commit, dispatch }, item) {
    if (item.type === 'ADD') {
      dispatch('addTransmissionAllocationPath', { fromIndex: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      dispatch('removeTransmissionAllocationPath', { rowIndex: item.rowIndex });
    }
  },
  addAllocationPath({ commit, dispatch }, item) {
    commit('addTransmissionAllocationPath', item);
    dispatch('calculateAllocationSegmentIDs');
  },
  removeAllocationPath({ commit, dispatch }, item) {
    commit('removeTransmissionAllocationPath', item);
    dispatch('calculateMarketSegmentIDs');
    dispatch('changeProfileColumns');
  },
  print(state) {
    console.log(JSON.stringify(state.transmissions));
  },
};

const mutations = {
  setRegistry(state, registry) {
    state.marketPathTable.registry = { pseList: registry.pseList };
    state.physicalPathTable.registry.tpList = registry.tpList;
    state.physicalPathTable.registry.baList = registry.baList;
    state.physicalPathTable.registry.porpodList = registry.porpodList;
    state.physicalPathTable.registry.moList = registry.moList;
    state.allocationTable.registry = {
      pseList: registry.pseList,
      productList: registry.productList,
    };
  },
  setRegistryPSEList(state, pseList) {
    state.marketPathTable.registry.pseList = pseList;
    state.allocationTable.registry.pseList = pseList;
  },
  setRegistryTSPList(state, tpList) {
    state.physicalPathTable.registry.tpList = tpList;
  },
  setRegistryProductList(state, productList) {
    state.allocationTable.registry.productList = productList;
    state.marketPathTable.registry.productList = productList;
  },
  setRegistryBAList(state, baList) {
    state.physicalPathTable.registry.baList = baList;
  },
  setRegistryMOList(state, moList) {
    state.physicalPathTable.registry.moList = moList;
  },
  setRegistryTransmissionPaths(state, registry) {
    state.physicalPathTable.registry.transPathList = registry.transPathList;
  },
  setFromTagModel(state, tagModel, readonly = false) {
    state.transmissions = tagUtils.modelToTransmissionState(tagModel, state.nullTransmissionRow, readonly);
  },
  initialize(state) {
    state.transmissions = [];
  },
  setData(state, item) {
    const eventData = item.eventData || null;
    const row = state.transmissions[item.rowIndex];

    // if (row.market_id === item.loadMarketSegmentID || (row.market_id !== null && row.market_id.toString() === '1')) {
    //   if (item.prop === 'market_product' || item.prop === 'market_product_name') {
    //     row.market_product_name = null;
    //     return;
    //   }
    // }
    if (item.value !== null) { row[item.prop] = item.value.toString(); } else { row[item.prop] = null; }

    const tempName = (!item.prop.includes('_name')) ? `${item.prop}_name` : item.prop;
    if (has(row, tempName) && eventData !== null) {
      row[tempName] = item.eventData.label;
    }

    // if (item.prop === 'market_product' || item.prop === 'market_product_name') {
    //   for (let x = 0; x < state.transmissions.length; x++) {
    //     const t = state.transmissions[x];
    //     if (t.market_id === row.market_id) {
    //       t.market_product_name = row.market_product_name;
    //     }
    //   }
    // }

    // auto set tc if market and physical path are completely set

    if (!utils.data.isStringNullOrEmpty(row.physical_se_name)
        && !utils.data.isStringNullOrEmpty(row.physical_tp_name)
        && !utils.data.isStringNullOrEmpty(row.physical_por_name)
        && !utils.data.isStringNullOrEmpty(row.physical_pod_name)
        && utils.data.isStringNullOrEmpty(row.trans_tc_name)
    ) {
      row.trans_tc_name = row.market_pse_name;
    }
  },
  addMarketPath(state, item) {
    const rows = state.transmissions;
    const row = helpers.clone(state.nullTransmissionRow);
    row.guid = utils.data.uuid();

    if (has(item, 'market_pse_name')) row.market_pse_name = item.market_pse_name;
    if (has(item, 'market_contractList')) {
      row.market_contractList = item.market_contractList;
      if (item.market_contractList) row.market_contractCount = item.market_contractList.length;
    }

    const previousRows = helpers.slice(rows, 0, item.rowIndex);
    const afterRows = helpers.slice(rows, item.rowIndex);
    let newRows = helpers.concat([], previousRows);
    newRows.push(row);
    newRows = helpers.concat(newRows, afterRows);

    state.transmissions = newRows;

    // add rows to error object
    const errRows = state.transmissionErrors;
    const errRow = helpers.clone(state.nullTransmissionRow);
    if (errRow) {
      errRow.guid = utils.data.uuid();
      if (has(item, 'market_pse_name')) errRow.market_pse_name = item.market_pse_name;
      if (has(item, 'market_contractList')) {
        errRow.market_contractList = item.market_contractList;
        if (item.market_contractList) errRow.market_contractCount = item.market_contractList.length;
      }
      const previousErrRows = helpers.slice(errRows, 0, item.rowIndex);
      const afterErrRows = helpers.slice(errRows, item.rowIndex);
      let newErrRows = helpers.concat([], previousErrRows);
      newErrRows.push(errRow);
      newErrRows = helpers.concat(newErrRows, afterErrRows);
      state.transmissionErrors = newErrRows;
    }
  },
  removeMarketPath(state, item) {
    const rows = state.transmissions;
    const newRows = [];
    const removeRow = rows[item.rowIndex];

    for (let x = 0; x < rows.length; x++) {
      if (x === item.rowIndex) continue;
      const row = rows[x];
      if (!(row.market_id === removeRow.market_id
          && row.physical_id === removeRow.physical_id
          && row.trans_id === removeRow.trans_id)) {
        newRows.push(row);
      }
    }
    if (newRows.length === 0) newRows.push(rows[0]);
    state.transmissions = newRows;

    // remove rows from error object
    const errRows = state.transmissionErrors;
    const newErrRows = [];
    const removeErrRow = errRows[item.rowIndex];
    if (removeErrRow) {
      for (let x = 0; x < errRows.length; x++) {
        if (x === item.rowIndex) continue;
        const errRow = errRows[x];

        if (!(errRow.market_id === removeErrRow.market_id
            && errRow.physical_id === removeErrRow.physical_id
            && errRow.trans_id === removeRow.trans_id)) {
          newErrRows.push(errRow);
        }
      }
      if (newErrRows.length === 0) newErrRows.push(errRows[0]);
      state.transmissionErrors = newErrRows;
    }
  },
  addPhysicalPath(state, item) {
    const rows = state.transmissions;
    const fromRow = rows[item.fromIndex];
    let newRow = helpers.clone(state.nullTransmissionRow);
    newRow = Object.assign(newRow, {
      guid: utils.data.uuid(),
      market_id: fromRow.market_id,
      market_pse_name: fromRow.market_pse_name,
      market_product_name: fromRow.market_product_name,
      market_contractList: fromRow.market_contractList,
      market_contractCount: fromRow.market_contractCount,
    });
    const newRows = [];

    for (let x = 0; x <= item.fromIndex; x++) {
      newRows.push(rows[x]);
    }
    newRows.push(newRow);

    for (let x = item.fromIndex + 1; x < rows.length; x++) {
      newRows.push(rows[x]);
    }

    state.transmissions = newRows;

    // add rows in error object
    const errRows = state.transmissionErrors;
    const fromErrRow = errRows[item.fromIndex];
    if (fromErrRow) {
      let newErrRow = helpers.clone(state.nullTransmissionRow);
      newErrRow = Object.assign(newErrRow, {
        guid: utils.data.uuid(),
        market_id: fromErrRow.market_id,
        market_pse_name: fromErrRow.market_pse_name,
        market_product_name: fromErrRow.market_product_name,
        market_contractList: fromErrRow.market_contractList,
        market_contractCount: fromErrRow.market_contractCount,
      });
      const newErrRows = [];

      for (let x = 0; x <= item.fromIndex; x++) {
        newErrRows.push(errRows[x]);
      }
      newErrRows.push(newErrRow);

      for (let x = item.fromIndex + 1; x < rows.length; x++) {
        newErrRows.push(errRows[x]);
      }

      state.transmissionErrors = newErrRows;
    }
  },
  removePhysicalPath(state, item) {
    const rows = state.transmissions;
    const newRows = [];
    const removeRow = rows[item.rowIndex];

    for (let x = 0; x < rows.length; x++) {
      if (x === item.rowIndex) continue;

      const row = rows[x];

      // if(row.market_id !== removeRow.market_id){
      //     newRows.push(row);
      // }

      // if(row.market_id === removeRow.market_id &&
      //     row.physical_id !== removeRow.physical_id){
      //     newRows.push(row);
      // }

      newRows.push(row);
    }

    if (newRows.length === 0) {
      rows[0].physical_id = null;
      rows[0].physical_se_name = null;
      rows[0].physical_tp_name = null;
      rows[0].physical_por_name = null;
      rows[0].physical_pod_name = null;
      rows[0].physical_mo_name = null;
      rows[0].physical_miscInfoCount = 0;
      rows[0].physical_miscInfoList = [];
      rows[0].trans_num = null;
      rows[0].trans_id = null;
      rows[0].trans_product_name = null;
      rows[0].trans_contract = null;
      rows[0].trans_tc_name = null;
      rows[0].miscInfoCount = null;
      rows[0].miscInfoList = [];
      rows[0].contractCount = null;
      rows[0].contract = [];
      rows[0].loss_accountingCount = 0;
      rows[0].loss_accountingList = null;
      newRows.push(rows[0]);
    }
    state.transmissions = newRows;

    // remove rows from error object
    const errRows = state.transmissionErrors;
    if (errRows && errRows.length > 0) {
      const newErrRows = [];
      for (let x = 0; x < errRows.length; x++) {
        if (x === item.rowIndex) continue;
        newErrRows.push(errRows[x]);
      }
      if (newErrRows.length === 0) {
        errRows[0].physical_id = null;
        errRows[0].physical_se_name = null;
        errRows[0].physical_tp_name = null;
        errRows[0].physical_por_name = null;
        errRows[0].physical_pod_name = null;
        errRows[0].physical_mo_name = null;
        errRows[0].physical_miscInfoCount = 0;
        errRows[0].physical_miscInfoList = [];
        errRows[0].trans_num = null;
        errRows[0].trans_id = null;
        errRows[0].trans_product_name = null;
        errRows[0].trans_contract = null;
        errRows[0].trans_tc_name = null;
        errRows[0].miscInfoCount = null;
        errRows[0].miscInfoList = [];
        errRows[0].contractCount = null;
        errRows[0].contract = [];
        errRows[0].loss_accountingCount = 0;
        errRows[0].loss_accountingList = null;
        newErrRows.push(errRows[0]);
      }
      state.transmissionErrors = newErrRows;
    }
  },
  addAllocationPath(state, item) {
    const rows = state.transmissions;
    const fromRow = rows[item.fromIndex];
    if (fromRow.physical_id === '' || fromRow.physical_id === null) return;

    let newRow = helpers.clone(state.nullTransmissionRow);
    newRow = Object.assign(newRow, {
      guid: utils.data.uuid(),
      market_id: fromRow.market_id,
      market_pse_name: fromRow.market_pse_name,
      market_product_name: fromRow.market_product_name,
      market_contractList: fromRow.market_contractList,
      market_contractCount: fromRow.market_contractCount,
      physical_id: fromRow.physical_id,
      physical_se_name: fromRow.physical_se_name,
      physical_tp_name: fromRow.physical_tp_name,
      physical_por_name: fromRow.physical_por_name,
      physical_pod_name: fromRow.physical_pod_name,
      physical_mo_name: fromRow.physical_mo_name,
      physical_miscInfoList: fromRow.physical_miscInfoList,
      physical_miscInfoCount: fromRow.physical_miscInfoCount,
      trans_id: null,
      trans_num: null,
      trans_product_name: null,
      trans_contract: null,
      trans_tc_name: null,
      trans_miscInfoCount: 0,
      trans_miscInfoList: [],
      trans_state: 'dirty',
      loss_accountingCount: fromRow.loss_accountingCount,
      loss_accountingList: fromRow.loss_accountingList,
    });
    const newRows = [];
    for (let x = 0; x <= item.fromIndex; x++) {
      newRows.push(rows[x]);
    }
    newRows.push(newRow);

    for (let x = item.fromIndex + 1; x < rows.length; x++) {
      newRows.push(rows[x]);
    }
    state.transmissions = newRows;

    // add row in error object
    const errRows = state.transmissionErrors;
    const fromErrRow = errRows[item.fromIndex];
    if (fromErrRow) {
      if (fromErrRow.physical_id === '' || fromErrRow.physical_id === null) return;

      let newErrRow = helpers.clone(state.nullTransmissionRow);
      newErrRow = Object.assign(newErrRow, {
        guid: utils.data.uuid(),
        market_id: fromErrRow.market_id,
        market_pse_name: fromErrRow.market_pse_name,
        market_product_name: fromErrRow.market_product_name,
        market_contractList: fromErrRow.market_contractList,
        market_contractCount: fromErrRow.market_contractCount,
        physical_id: fromErrRow.physical_id,
        physical_se_name: fromErrRow.physical_se_name,
        physical_tp: fromErrRow.physical_tp,
        physical_tp_name: fromErrRow.physical_tp_name,
        physical_por_name: fromErrRow.physical_por_name,
        physical_pod_name: fromErrRow.physical_pod_name,
        physical_mo_name: fromErrRow.physical_mo_name,
        physical_miscInfoList: fromErrRow.physical_miscInfoList,
        physical_miscInfoCount: fromErrRow.physical_miscInfoCount,
        trans_id: null,
        trans_num: null,
        trans_product_name: null,
        trans_contract: null,
        trans_tc_name: null,
        trans_miscInfoCount: 0,
        trans_miscInfoList: [],
        trans_state: 'dirty',
        loss_accountingCount: fromErrRow.loss_accountingCount,
        loss_accountingList: fromErrRow.loss_accountingList,
      });
      const newErrRows = [];
      for (let x = 0; x <= item.fromIndex; x++) {
        newErrRows.push(errRows[x]);
      }
      newErrRows.push(newErrRow);

      for (let x = item.fromIndex + 1; x < rows.length; x++) {
        newErrRows.push(errRows[x]);
      }
      state.transmissionErrors = newErrRows;
    }
  },
  removeAllocationPath(state, item) {
    const rows = state.transmissions;
    const newRows = [];
    const removeRow = rows[item.rowIndex];

    if (removeRow.trans_state === 'clean') return;

    for (let x = 0; x < rows.length; x++) {
      if (x === item.rowIndex) continue;
      newRows.push(rows[x]);
    }

    if (newRows.length === 0) {
      rows[0].trans_id = null;
      rows[0].trans_num = null;
      rows[0].trans_product_name = null;
      rows[0].trans_contract = null;
      rows[0].trans_tc_name = null;
      rows[0].miscInfoCount = null;
      rows[0].miscInfoList = [];
      rows[0].trans_state = 'dirty';
      rows[0].loss_accountingList = [];
      rows[0].loss_accountingCount = 0;
      newRows.push(rows[0]);
    }
    state.transmissions = newRows;

    // remove rows from error object
    const errRows = state.transmissionErrors;
    const newErrRows = [];
    const removeErrRow = errRows[item.rowIndex];
    if (removeErrRow) {
      if (removeErrRow.trans_state === 'clean') return;

      for (let x = 0; x < errRows.length; x++) {
        if (x === item.rowIndex) continue;
        newErrRows.push(errRows[x]);
      }
      if (newErrRows.length === 0) {
        errRows[0].trans_id = null;
        errRows[0].trans_num = null;
        errRows[0].trans_product_name = null;
        errRows[0].trans_contract = null;
        errRows[0].trans_tc_name = null;
        errRows[0].miscInfoCount = null;
        errRows[0].miscInfoList = [];
        errRows[0].trans_state = 'dirty';
        errRows[0].loss_accountingList = [];
        errRows[0].loss_accountingCount = 0;
        newErrRows.push(errRows[0]);
      }
      state.transmissionErrors = newErrRows;
    }
  },
  calculateMarketSegmentIDs(state, genMarketPSE) {
    let id = genMarketPSE.market_id;
    let previous_pse = genMarketPSE.market_pse_name;
    let previous_product = genMarketPSE.market_product_name;

    const rows = state.transmissions;

    for (let x = 0; x < rows.length; x++) {
      const row = rows[x];
      if (row.market_pse_name === null) continue;
      if (row.market_pse_name === previous_pse && row.market_product_name == previous_product) {
        row.market_id = id;
        if (x !== 0) {
          row.market_contractCount = rows[x - 1].market_contractCount;
          row.market_contractList = rows[x - 1].market_contractList;
        }
      } else {
        id++;
        previous_pse = row.market_pse_name;
        previous_product = row.market_product_name;
        row.market_id = id;
      }
    }

    return { market_id: id, market_pse_name: previous_pse };
  },
  calculatePhysicalSegmentIDs(state) {
    let p_id = 1;
    let prev_row = null;
    const rows = state.transmissions;

    for (let x = 0; x < rows.length; x++) {
      const row = rows[x];

      const right_se = row.physical_se_name;
      const right_tp = row.physical_tp_name;
      const right_por = row.physical_por_name;
      const right_pod = row.physical_pod_name;
      const right_mo = row.physical_mo_name;

      if (right_se === null && right_tp === null && right_por === null && right_pod === null) {
        row.physical_id = null;
      } else if (prev_row === null) {
        if (row.market_id !== null
            && right_se !== ''
            && right_tp !== ''
            && right_por !== ''
            && right_pod !== '') {
          p_id++;
          row.physical_id = p_id;
        }
      } else {
        const left_se = prev_row.physical_se_name === null ? '' : prev_row.physical_se_name;
        const left_tp = prev_row.physical_tp_name;
        const left_por = prev_row.physical_por_name;
        const left_pod = prev_row.physical_pod_name;
        const left_mo = prev_row.physical_mo_name;

        if (prev_row.market_id === row.market_id
            && left_se === right_se
            && left_tp === right_tp
            && left_por === right_por
            && left_pod === right_pod
            && prev_row.physical_id !== null) {
          row.physical_id = p_id;
          row.physical_miscInfoCount = prev_row.physical_miscInfoCount;
          row.physical_miscInfoList = prev_row.physical_miscInfoList;
        } else if (row.market_id !== null
              && right_se !== ''
              && right_tp !== ''
              && right_por !== ''
              && right_pod !== '') {
          p_id++;
          row.physical_id = p_id;
        }
      }
      prev_row = helpers.cloneDeep(row);
    }

    return { physical_id: p_id + 1 };
  },
  calculateAllocationSegmentIDs(state) {
    let a_id = 1;
    const prev_row = null;
    const rows = state.transmissions;

    for (let x = 0; x < rows.length; x++) {
      const row = rows[x];
      const m_id = row.market_id === null ? '' : row.market_id.toString();
      const p_id = row.physical_id === null ? '' : row.physical_id.toString();
      const se = row.physical_se_name;
      const tp = row.physical_tp_name;
      const por = row.physical_por_name;
      const pod = row.physical_pod_name;
      const mo = row.physcial_mo_name;

      if (m_id !== '' && p_id !== '' && se !== ''
          && tp !== '' && por !== '' && pod !== '') {
        const v = a_id++;
        row.trans_num = v;
      }
    }

    let maxTransAllocId = -1;
    for (let x = 0; x < rows.length; x++) {
      const row = rows[x];
      if (row.trans_state === 'clean') {
        if (row.trans_id > maxTransAllocId) {
          maxTransAllocId = row.trans_id;
        }
      }
    }

    if (maxTransAllocId === -1) {
      for (let x = 0; x < rows.length; x++) {
        const row = rows[x];
        row.trans_id = row.trans_num;
      }
    } else {
      maxTransAllocId++;

      for (let x = 0; x < rows.length; x++) {
        const row = rows[x];
        if (row.trans_state === 'dirty') {
          row.trans_id = maxTransAllocId++;
        }
      }
    }
  },
  setMiscInfoProperty(state, item) {
    const row = state.transmissions[item.rowIndex];
    let matchProp = '';
    if (item.countProp.includes('trans')) matchProp = 'trans_id';
    else matchProp = 'physical_id';

    for (let i = 0; i < state.transmissions.length; i++) {
      const trans = state.transmissions[i];
      if (trans[matchProp] === row[matchProp]) {
        trans[item.countProp] = item.count;
        trans[item.dataProp] = item.data;
        break;
      }
    }
  },
  setContractProperty(state, item) {
    for (let i = 0; i < state.transmissions.length; i++) {
      if (item.dataProp === 'loss_contractList') {
        const row = state.transmissions[item.rowIndex];
        row[item.countProp] = item.count;
        row[item.dataProp] = item.data;
      } else {
        const trans = state.transmissions[i];
        if (trans.market_id === item.market_id) {
          trans[item.countProp] = item.count;
          trans[item.dataProp] = item.data;
        }
      }
    }
  },
  setTagIDListProperty(state, item) {
    const row = state.transmissions[item.rowIndex];
    row[item.countProp] = item.count;
    row[item.dataProp] = item.data;
  },
  setTransmissionsDirty(state) {
    const data = state.transmissions;
    for (let x = 0; x < data.length; x++) {
      data[x].trans_state = 'dirty';
    }
  },
  setTransmissionPhysicalPathLossAccounting(state, item) {
    const data = state.transmissions;
    for (let x = 0; x < data.length; x++) {
      const tran = data[x];
      if (tran.physical_id === item.physical_id) {
        tran.loss_accountingCount = item.data.length;
        tran.loss_accountingList = item.data;
      }
    }
  },
  clearMoData(state, guid) {
    const idx = state.transmissions.findIndex((t) => t.guid === guid);
    if (idx !== -1) {
      state.transmissions[idx].physical_mo = null;
      state.transmissions[idx].physical_mo_name = null;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};