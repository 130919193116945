import { has } from '@/utils/dataUtil';
import {
  chargeCodesTagbox, resourcesTagbox, schedulingCoordinatorsWithTagbox, tradeDateRange, dateType,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const pivotGridFieldGroups = {
  defaultFields: [{
    caption: 'CHARGE CODE',
    dataField: 'chargeCode',
    area: 'row',
    expanded: true,
  }, {
    caption: 'BA',
    dataType: 'string',
    dataField: 'ba',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'DESCRIPTION',
    dataType: 'string',
    dataField: 'description',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'NOTES',
    dataField: 'notes',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'VARIANCE CAUSE',
    dataField: 'varianceCause',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING DATE',
    dataType: 'datetime',
    dataField: 'tzTradingDate',
    area: 'row',
    expanded: true,
    format: 'yyyy-MM-dd',
  }, {
    caption: 'COUNTER BA ID',
    dataField: 'counterBaId',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TYPE',
    dataField: 'customType',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'GROUP',
    dataField: 'customGroup',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'ACCOUNT',
    dataField: 'customAccount',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'RESOURCE ID',
    dataField: 'rsrcId',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'RESOURCE TYPE',
    dataField: 'rsrcType',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'SETTLEMENT CYCLE',
    dataField: 'type',
    area: 'column',
    expanded: true,
    customizeText(cellInfo) {
      if (cellInfo && cellInfo.value && cellInfo.value.indexOf('.') !== -1) return cellInfo.value.substr(cellInfo.value.indexOf('.') + 1);
      return cellInfo.value;
    },
  }, {
    caption: 'CURRENT AMOUNT',
    dataField: 'val',
    area: 'data',
    summaryType: 'sum',
    dataType: 'number',
    expanded: true,
    format: '$#,##0.00',
  },
  ],
};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  pivotGridFieldGroups,
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
  searchFilterConfig: {
    tradeDateRange,
    coordinatorList: schedulingCoordinatorsWithTagbox,
    chargeCodeList: {
      ...chargeCodesTagbox,
    },
    resourceList: resourcesTagbox,
    dateType,
    runType: {
      label: 'Run Type',
      value: '1',
      type: 'radio',
      options: [{ value: '1', label: 'Statements' }, { value: '0', label: 'Allocations' }],
    },
    granularity: {
      label: 'Granularity',
      value: '1',
      type: 'radio',
      options: [{ value: '0', label: 'Daily' }, { value: '1', label: 'Hourly' }],
      watchers: [{
        propertyToWatch: 'tradeDateRangeSelected',
        handler() {
          const diffTime = Math.abs(this.params.tradeDateRangeSelected[0] - this.params.tradeDateRangeSelected[1]);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          if (diffDays > 3) {
            this.$store.commit('ciso/setParams', ['granularitySelected', '0']);
          }
          return true;
        },
      }],
    },
    latestCycle: {
      label: 'Latest Cycle(s) Only',
      value: false,
      type: 'checkbox',
    },
  },
};
