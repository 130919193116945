import dateStore from '@/utils/dateStore';
import { CISO_SAMC_API } from '@/api';
import configs from './TagSettlementsMRTUConfig';
import detailConfigs from './TagSettlementsMRTUDetailsConfig';

function flattenModel(model) {
  const flatArray = [];
  if (model && model.data && model.data.length > 0) {
    model.data.forEach((m) => {
      const data = m.data || [];
      data.forEach((d) => {
        flatArray.push({ ...m, ...d, data: null });
      });
    });
  }
  return flatArray;
}

const state = {
  tableData: [],
  mrtuConfig: configs,
  mrtuPivot: configs.fields,
  tradeDate: null,
  type: null,
  detailData: [],
  detailConfig: detailConfigs,
  detailPivot: detailConfigs.fields,
  detailParams: {},
  detailDatePropsAndFormats: [
    { name: 'procTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'reqTime', format: 'YYYY-MM-DD HH:mm' },
  ],
  errorMessageDatePropsAndFormats: [
    { name: 'mappingStartTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'mappingEndTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'tagStartTimeGmt', format: 'YYYY-MM-DD HH:mm' },
    { name: 'tagEndTimeGmt', format: 'YYYY-MM-DD HH:mm' },
  ],
};

const actions = {
  async fetchMrtuData({ commit }, parameters = {}) {
    commit('setTableData', []);
    commit('setTradeDate', parameters.tradeDateSelected);
    commit('setDetailParams', {});

    const params = {
      tradingDate: parameters.tradeDateSelected,
      type: parameters.typeSelected,
    };
    try {
      const { data } = await CISO_SAMC_API.get('/tag-settlements/mrtu', {
        headers: { 'api-version': '2.0' },
        params,
      });
      if (data) {
        commit('setTableData', flattenModel(data));
        commit('setType', parameters.typeSelected);
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load MRTU Data', 'error');
    }
  },
  async fetchDetailData({ state, commit }) {
    commit('setDetailData', []);
    const params = {
      tradingDate: state.tradeDate,
      type: state.type,
      resourceId: state.detailParams?.rsrcId,
      ...state.detailParams,
    };

    try {
      const { data } = await CISO_SAMC_API.get('/tag-settlements/mrtu-details', {
        headers: { 'api-version': '2.0' },
        params,
      });

      commit('setDetailData', dateStore.formatDateTimeFields(flattenModel(data), state.detailDatePropsAndFormats));
    } catch (err) {
      console.error(err);
    }
  },
  reset({ commit }) {
    commit('setDetailParams', {});
    commit('setTradeDate', null);
    commit('setTableData', []);
    commit('setDetailData', []);
    commit('setType', null);
  },
};

const mutations = {
  setDetailParams(state, value) {
    state.detailParams = value;
  },
  setTradeDate(state, value) {
    state.tradeDate = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setDetailData(state, value) {
    state.detailData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.mrtuPivot = value;
  },
  setDetailTableConfig(state, value) {
    state.detailConfig = value;
  },
  setType(state, value) {
    state.type = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};