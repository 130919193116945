import axios from 'axios';


const API = axios.create({
  baseURL: 'http://localhost:7813/',
});

API.interceptors.request.use((config) => {
  config.headers.common.Accept = 'application/json';
  config.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
  console.log(config);
  return config;
}, (error) => {
  console.log(error);
  return Promise.reject(error);
},
);

// state nothing more than data
const state = {
  deals: [],
  config: {
    columns: [
      { prop: 'id' },
      { prop: 'name', label: 'name', editable: true },
      { prop: 'firstName', label: 'first name', editable: true },
      { prop: 'lastName', label: 'last name', editable: true },
      { label: 'operations' },
    ],
  },
};

// think of them like functions that only return data
const getters = {
  getConfiguration: state => state.config,
  getDeals: state => state.deals,
};

// request to change data
const actions = {
  async loadDeals({ commit }) {
    console.log('request loading deals');

    const response = await API.get('deals');
    commit('loadDeals', response.data);
  },
  changeDeal({ dispatch, commit, state }, item) {
    commit('changeDeal', item);
  },
};

// where data is changed
const mutations = {
  loadDeals(state, model) {
    state.deals = model.deals;
    console.log(state.deals);
    console.log('deals loaded');
  },

  changeDeal(state, item) {
    const deal = state.deals[item.rowIndex];

    console.log(deal);
    if (item.prop === 'firstName') {
      const name = deal.name;
      const split = name.split(' ');

      deal.name = `${item.value} ${split[1]}`;
    }

    deal[item.prop] = item.value;
  },
};


// vuex structure
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};