import {
  coordinatorList, tradeDateRange, marketTypes, resourcesTagbox,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import userStore from '@/utils/userStore';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  dockConfig: {
    visible: false,
    alignment: userStore.dock,
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    marketTypes: {
      ...marketTypes,
      required: true,
      clearable: false,
    },
    sourceTypes: {
      label: 'Source Type',
      value: 'CBBidResults',
      type: 'select',
      options: [{ value: 'CBBidResults', label: 'VirtualBidResults' }, { value: 'CBCleanBidSet', label: 'VirtualCleanBidSet' }],
    },
  },
  bidsHeaderConfig: {
    name: 'cbBidsHeader',
    columns: [{
      label: 'SC',
      prop: 'sc',
      filterable: true,
      sortable: true,
    }, {
      label: 'SOURCE TYPE',
      prop: 'sourceType',
      filterable: true,
      sortable: true,
    }, {
      label: 'MARKET TYPE',
      prop: 'marketType',
      filterable: true,
      sortable: true,
    }, {
      label: 'TRADE DATE',
      prop: 'tradingDate',
      dataType: 'date',
      format: 'yyyy-MM-dd',
      filterable: true,
      sortable: true,
      width: 200,
    }, {
      label: 'VIRTUAL BID TYPE',
      prop: 'virtualBidType',
      filterable: true,
      sortable: true,
    }, {
      label: 'BID STATUS',
      prop: 'bidStatus',
      filterable: true,
      sortable: true,
    }, {
      label: 'BID CREDIT STATUS',
      prop: 'bidCreditStatus',
      filterable: true,
      sortable: true,
    }, {
      label: 'NODE TYPE',
      prop: 'nodeType',
      filterable: true,
      sortable: true,
      width: 100,
    }, {
      label: 'NODE',
      prop: 'node',
      filterable: true,
      sortable: true,
    }],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnsMovable: true,
    },
  },
  bidCurvesConfig: {
    name: 'cbBidCurve',
    columns: [{
      label: 'TRADING DATE',
      prop: 'tradingDate',
      format: 'yyyy-MM-dd',
      dataType: 'date',
      filterable: true,
      sortable: true,
    }, {
      label: 'VIRTUAL BID TYPE',
      prop: 'virtualBidType',
      filterable: true,
      sortable: true,
    }, {
      label: 'BID STATUS',
      prop: 'bidStatus',
      filterable: true,
      sortable: true,
    }, {
      label: 'NODE TYPE',
      prop: 'nodeType',
      filterable: true,
      sortable: true,
    }, {
      label: 'NODE',
      prop: 'node',
      filterable: true,
      sortable: true,
    }, {
      label: 'START HOUR',
      prop: 'startHour',
      filterable: true,
      sortable: true,
    }, {
      label: 'END HOUR',
      prop: 'endHour',
      filterable: true,
      sortable: true,
    }, {
      label: 'BID SEGMENT NUMBER',
      prop: 'bidSegmentNum',
      filterable: true,
      sortable: true,
    }, {
      label: 'QTY DATA',
      prop: 'xAxisData',
      filterable: true,
      sortable: true,
    }, {
      label: 'PRICE DATA',
      prop: 'y1AxisData',
      filterable: true,
      sortable: true,
    }],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnsMovable: true,
    },
  },
  bidErrorsConfig: {
    name: 'cbBidError',
    columns: [{
      label: 'BID ERROR ID',
      prop: 'bidErrorID',
      filterable: true,
      sortable: true,
    }, {
      label: 'RULE ID',
      prop: 'ruleID',
      filterable: true,
      sortable: true,
    }, {
      label: 'ERROR PRIORITY',
      prop: 'errPriority',
      filterable: true,
      sortable: true,
    }, {
      label: 'ERROR MESSAGE',
      prop: 'errMessage',
      filterable: true,
      sortable: true,
    }, {
      label: 'START TIME',
      prop: 'startTime',
      format: 'yyyy-MM-dd',
      dataType: 'date',
      filterable: true,
      sortable: true,
    }, {
      label: 'START TIME HOUR',
      prop: 'startTimeHr',
      filterable: true,
      sortable: true,
    }, {
      label: 'END TIME HOUR',
      prop: 'endTimeHr',
      filterable: true,
      sortable: true,
    }, {
      label: 'LOG TIMESTAMP',
      prop: 'logTimeStamp',
      filterable: true,
      sortable: true,
    }],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnsMovable: true,
    },
  },
};