import {
  CISO_SAMC_API, CISO_CMRI_API, CISO_OASIS_API,
} from '@/api';
import moment from 'moment';

const state = {
  detailTableData: [],
  summaryTableData: [],
  varianceTableData: [],
  parameters: [],
  isAverageHourlyValues: false,
};

const actions = {
  async fetchComparePrices({ commit }, { params, isSummary }) {
    commit('reset');
    commit('setParameters', params);

    let samcPrices = [];
    let oasisPrices = [];
    let cmriPrices = [];

    if (isSummary) {
      try {
        if (params.samcSource) {
          const { data: { data } } = await CISO_SAMC_API.get('compare-prices/summary', { params });
          samcPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during SaMC price retrieval' });
      }
      try {
        if (params.oasisSource) {
          const { data: { data } } = await CISO_OASIS_API.get('compare-prices/summary', { params });
          oasisPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during OASIS price retrieval' });
      }
      try {
        if (params.cmriSource) {
          const { data: { data } } = await CISO_CMRI_API.get('compare-prices/summary', { params });
          cmriPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during CMRI price retrieval' });
      }
    } else {
      try {
        if (params.samcSource) {
          const { data: { data } } = await CISO_SAMC_API.get('/compare-prices', { params });
          samcPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during SaMC price retrieval' });
      }
      try {
        if (params.oasisSource) {
          const { data: { data } } = await CISO_OASIS_API.get('/compare-prices', { params });
          oasisPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during OASIS price retrieval' });
      }
      try {
        if (params.cmriSource) {
          const { data: { data } } = await CISO_CMRI_API.get('/compare-prices', { params });
          cmriPrices = data;
        }
      } catch (error) {
        this.$notify({ type: 'error', message: 'Error occurred during CMRI price retrieval' });
      }
    }
    const tableData = [...samcPrices, ...oasisPrices, ...cmriPrices];
    commit('setTableData', { tableData, type: isSummary ? 'summaryTableData' : 'detailTableData' });
    commit('setAvgHrlyValues', ({
      averageHourlyValues: params.isAverageHourlyValues,
      intervalArea: 'filter',
      marketType: params.marketType,
    }));
  },
  async fetchVariance({ commit }, params) {
    commit('reset');
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/compare-prices/variance', { params });
      commit('setTableData', { tableData: data, type: 'varianceTableData' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Error occurred during price variance retrieval' });
    }
  },
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableData(state, { tableData, type }) {
    tableData.forEach((x) => {
      if (x.tzTradingDate) x.tzTradingDate = moment.utc(x.tzTradingDate).format('MM/DD/YYYY');
      if (x.lastUpdated) x.lastUpdated = moment.utc(x.lastUpdated).format('MM/DD/YYYY');
    });

    state[type] = Object.freeze(tableData);
  },
  setAvgHrlyValues(state, { averageHourlyValues, intervalArea, marketType }) {
    state.isAverageHourlyValues = averageHourlyValues;

    if (averageHourlyValues && intervalArea === 'filter' && state.detailTableData.length > 0) {
      switch (marketType) {
      case 'FMM':
        state.detailTableData.forEach((x) => { x.price /= 4; });
        break;
      case 'RTM':
        state.detailTableData.forEach((x) => { x.price /= 12; });
        break;
      default:
        break;
      }
    }
  },
  reset(state) {
    state.detailTableData = [];
    state.summaryTableData = [];
    state.varianceTableData = [];
    state.parameters = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};