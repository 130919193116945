<template>
     <div class="align-center">
          <i class="fa fa-spinner fa-spin fa-4x fa-fw"></i>
          <span class="sr-only">Loading...</span>
          
     </div>
</template>
<script>
export default {
     data() {
          return {

          };
     }  
}
</script>

<style lang="scss" scoped>
.align-center {
     text-align: center;
}
</style>
