import { IDENTITY_API } from '@/api';
import { clone } from '@/utils/dataUtil';
import { hasPermission, anyPermission, isFeatureFlagEnabled } from '@/utils/authUtils';
import routes from '../router/routes';

function constructNavigationRoutes(navigationRoutes, permissions, featureFlags) {
  if (!navigationRoutes?.length) return [];
  // Filters for visible screens and check to show using permissions recursively
  return navigationRoutes
    .filter(({ meta }) => meta.visible)
    .reduce((acc, route) => {
      if (route.meta.permission && !route.meta.featureFlag) {
        // Gets route permission and checks profile if has permission
        const { name, rights } = route.meta.permission;
        const foundPermission = permissions.find((perm) => {
          if (new RegExp(name).test(perm.name.toLowerCase())) {
            const value = perm.value.toLowerCase();
            return rights.map((val) => val.toLowerCase()).includes(value);
          }
          return false;
        });

        if (foundPermission) {
          acc.push({
            name: route.name,
            text: route.meta.text,
            className: route.meta.className,
            children: constructNavigationRoutes(route.children, permissions, featureFlags),
            rank: route.meta.rank,
            customFormatEnabled: route.meta.customFormatEnabled,
          });
        }
      } else if (route.meta.permission && route.meta.featureFlag) {
        // Gets route permission and checks profile if has permission
        const { name, rights } = route.meta.permission;
        const foundPermission = permissions.find((perm) => {
          if (new RegExp(name).test(perm.name.toLowerCase())) {
            const value = perm.value.toLowerCase();
            return rights.map((val) => val.toLowerCase()).includes(value);
          }
          return false;
        });
        const enabledFlag = isFeatureFlagEnabled(featureFlags, route.meta.featureFlag);

        if (foundPermission && enabledFlag) {
          acc.push({
            name: route.name,
            text: route.meta.text,
            className: route.meta.className,
            children: constructNavigationRoutes(route.children, permissions, featureFlags),
            rank: route.meta.rank,
            customFormatEnabled: route.meta.customFormatEnabled,
          });
        }
      } else if (route.meta.featureFlag) {
        const enabledFlag = isFeatureFlagEnabled(featureFlags, route.meta.featureFlag);
        if (enabledFlag) {
          acc.push({
            name: route.name,
            text: route.meta.text,
            className: route.meta.className,
            children: constructNavigationRoutes(route.children, permissions, featureFlags),
            rank: route.meta.rank,
            customFormatEnabled: route.meta.customFormatEnabled,
          });
        }
      } else {
        acc.push({
          name: route.name,
          text: route.meta.text,
          className: route.meta.className,
          children: constructNavigationRoutes(route.children, permissions, featureFlags),
          rank: route.meta.rank,
          customFormatEnabled: route.meta.customFormatEnabled,
        });
      }
      return acc;
    }, []);
}

export default {
  namespaced: true,
  state: {
    profile: null,
    routes: [],
    validProfile: false,
    defaultProfile: {
      firstName: null,
      lastName: null,
      permissions: [],
    },
  },
  getters: {
    getPermissions: (state) => state.profile ? state.profile.permissions : [],
    getRoutes: (state) => state.routes,
    getProfile: (state) => state.profile,
    getProfileValidFlag: (state) => state.validProfile,
    hasPermission: (state, getters) => ({ name, right }) => hasPermission(getters.getPermissions, name, right),
    anyPermission: (state, getters) => ({ name }) => anyPermission(getters.getPermissions, name),
    userFeatureFlags: (state, getters, rootState, rootGetters) => rootGetters['lookup/getUserFeatureFlagList'],
    hasFeatureFlagEnabled: (state, getters) => ({ name }) => isFeatureFlagEnabled(getters.userFeatureFlags, name),
  },
  actions: {
    async login({ commit, state, dispatch }) {
      try {
        const { data } = await IDENTITY_API.get('/auth/profile');
        const featureFlags = await dispatch('lookup/fetchUserFeatureFlagList', null, { root: true });
        commit('setProfile', { profile: data, valid: true, featureFlags });
        dispatch('notification/start', null, { root: true });
      } catch (err) {
        commit('setProfile', { profile: clone(state.defaultProfile), valid: false, featureFlags: [] });
        console.error(err);
      }
    },
    logout({ commit, dispatch }) {
      commit('setProfile', { profile: { permissions: [] }, valid: false, featureFlags: [] });
      dispatch('notification/hide', null, { root: true });
      dispatch('notification/stop', null, { root: true });
    },
    setConfirmProfile({ commit, state }) {
      commit('setProfile', { profile: clone(state.defaultProfile), valid: false, featureFlags: [] });
    },
    setModuleOption({ commit }, data) {
      // This method is dispatched in when a user changes
      // an option in the Settings/Notification tab
      // Dispatch is invoked in PscsNotification/notificationStore

      commit('setModuleOption', data);
    },
    setNotificationRow({ commit }, data) {
      // This method is dispatched in when a user changes
      // notification type in Settings/Notification tab
      // Dispatch is invoked in PscsUserOptions/UserNotification/store

      commit('setNotificationRow', data);
    },
  },
  mutations: {
    setProfile(state, data) {
      state.validProfile = data.valid;
      state.profile = data.profile;
      const { permissions } = state.profile;
      state.routes = constructNavigationRoutes(routes, permissions, data.featureFlags);
    },
    setModuleOption(state, data) {
      if (!state.profile.moduleOptions) state.profile.moduleOptions = [];

      let module = state.profile.moduleOptions.find((m) => m.name.toLowerCase() === data.module.toLowerCase());
      if (!module) {
        const option = {};
        option[data.type] = data.value;
        module = { name: data.module, options: [option] };
        state.profile.moduleOptions.push(module);
      }
      const props = Object.keys(module.options);

      for (let x = 0; x < props.length; x++) {
        if (props[x].toLocaleLowerCase() === data.type.toLowerCase()) {
          module.options[props[x]] = data.value;
          return;
        }
      }

      module.options[data.type] = data.value;
    },
    setNotificationRow(state, data) {
      const { notifications } = state.profile;
      const row = notifications.find((n) => n.type === data.type && n.source === data.module);
      if (row) {
        row.enabled = data.enabled;
        row.audio = data.audio;
        row.soundType = data.soundType;
        row.audioType = data.audioType;
        row.duration = data.duration;
        if (data.filterName) {
          row.filter = true;
          row.filters = [{ name: data.filterName, value: data.filters[0] }];
        } else {
          row.filter = false;
          row.filters = [];
        }
      }
    },
  },
};