import {
  tradeDateRange,
  coordinatorList,
  resourcesTagbox,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  rsiFields: { // RSI 17 columns
    columns: [
      {
        label: 'SC',
        prop: 'scid',
        filterable: true,
        sortable: true,
      },
      {
        label: 'RESOURCE ID',
        prop: 'resourceId',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING DATE',
        prop: 'tradingDate',
        dataType: 'date',
        format: 'MM-dd-yyyy',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING HOUR',
        prop: 'tradingHour',
        filterable: true,
        sortable: true,
      },
      {
        label: 'PMAX',
        prop: 'pMax',
        filterable: true,
        sortable: true,
      },
      {
        label: 'PMIN',
        prop: 'pMin',
        filterable: true,
        sortable: true,
      },
      {
        label: 'NQC',
        prop: 'nqc',
        filterable: true,
        sortable: true,
      },
      {
        label: 'EFC',
        prop: 'efc',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TAC',
        prop: 'tac',
        filterable: true,
        sortable: true,
      },
      {
        label: 'LOCAL AREA',
        prop: 'localArea',
        filterable: true,
        sortable: true,
      },
      {
        label: 'DISPATCHABLE',
        prop: 'dispatchable',
        area: 'filter',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FUEL TYPE',
        prop: 'fuelType',
        filterable: true,
        sortable: true,
      },
      {
        label: 'RES TYPE',
        prop: 'resType',
        filterable: true,
        sortable: true,
      },
      {
        label: 'USE LIMIT',
        prop: 'useLimit',
        area: 'filter',
        filterable: true,
        sortable: true,
      },
      {
        label: 'GENERIC RA',
        prop: 'genericRA',
        filterable: true,
        sortable: true,
      },
      {
        label: 'GENERIC CPM',
        prop: 'genericCPM',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX RA',
        prop: 'flexRA',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CPM',
        prop: 'flexCPM',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CAT',
        prop: 'flexCat',
        filterable: true,
        sortable: true,
      },
      {
        label: 'MOO FLAG',
        prop: 'mooFlag',
        area: 'filter',
        filterable: true,
        sortable: true,
      },
      {
        label: 'LOCAL MW',
        prop: 'localMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SYSTEM MW',
        prop: 'systemMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'LOCAL SUB MW',
        prop: 'localSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SYSTEM SUB MW',
        prop: 'systemSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'PLANNED SUB MW',
        prop: 'plannedSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW LOCAL SUB',
        prop: 'transferredMWLocalSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW SYSTEM SUB',
        prop: 'transferredMWSystemSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW PLANNED SUB',
        prop: 'transferredMWPlannedSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'CPM SUB MW',
        prop: 'cpmSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW CPM SUB',
        prop: 'transferredMWCpmSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX MW',
        prop: 'flexMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX SUB MW',
        prop: 'flexSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW FLEX SUB',
        prop: 'transferredMWFlexSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CPM SUB MW',
        prop: 'flexCpmSubMW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRANSFERRED MW FLEX CPM SUB',
        prop: 'transferredMWFlexCpmSub',
        filterable: true,
        sortable: true,
      },
      {
        label: 'RMR FLAG',
        prop: 'rmR_FLAG',
        area: 'filter',
        filterable: true,
        sortable: true,
      },
      {
        label: 'LOCAL RA MW',
        prop: 'localRAMw',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SYSTEM RA MW',
        prop: 'systemRAMw',
        filterable: true,
        sortable: true,
      },
      {
        label: 'LOCAL RMR MW',
        prop: 'localRMRMw',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SYSTEM RMR MW',
        prop: 'systemRMRMw',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX RMR',
        prop: 'flexRMR',
        filterable: true,
        sortable: true,
      },
    ],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnConfig: true,
      showSelectFilter: false,
    },
  },
  fields: { // non RSI 17 columns
    columns: [
      {
        label: 'RESOURCE ID',
        prop: 'resourceId',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING DATE',
        prop: 'tradingDate',
        dataType: 'date',
        format: 'MM-dd-yyyy',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING HOUR',
        prop: 'tradingHour',
        filterable: true,
        sortable: true,
      },
      {
        label: 'MOO FLAG',
        prop: 'mooFlag',
        area: 'filter',
        filterable: true,
        sortable: true,
      },
      {
        label: 'NQC',
        prop: 'nqc',
        filterable: true,
        sortable: true,
      },
      {
        label: 'PMAX',
        prop: 'pMax',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SUPPLY MW',
        prop: 'supplY_MW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'SUBST',
        prop: 'subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'ORIG RES SUBST',
        prop: 'orig_Res_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'CPM',
        prop: 'cpm',
        filterable: true,
        sortable: true,
      },
      {
        label: 'CPM SUBST',
        prop: 'cpM_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'ORIG RES CPM SUBST',
        prop: 'orig_Res_CPM_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CAT',
        prop: 'flex_Cat',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX SUPPLY MW',
        prop: 'flex_Supply_MW',
        filterable: true,
        sortable: true,
      },
      {
        label: 'ORG RES FLEX SUBST',
        prop: 'org_Res_Flex_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'ORG RES FLEX CPM SUBST',
        prop: 'org_Res_Flex_CPM_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CPM',
        prop: 'flexCPM',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CPM SUBST',
        prop: 'flex_CPM_Subst',
        filterable: true,
        sortable: true,
      },
      {
        label: 'GENERIC TOTAL',
        prop: 'generic_Total',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX TOTAL',
        prop: 'flex_Total',
        filterable: true,
        sortable: true,
      },
    ],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnConfig: true,
      showSelectFilter: false,
    },
  },
  searchFiltersConfig: {
    ciraHasRSI: {
      label: 'RSI 17',
      value: true,
      type: 'checkbox',
      visible: true,
      options: [],
      watchers: [{
        propertyToWatch: 'rsiSelected',
        handler(newValue, oldValue) {
          const required = !(this.params.rsiSelected === 'rsiView' || this.params.resourceListSelected.length === 1);
          this.config.resourceList.required = !(newValue === 'rsiView');
          this.config.resourceList.maxLimit = (newValue === 'rsiView') ? 50 : 6;
          this.config.coordinatorList.required = !(this.params.rsiSelected === 'rsiView');
          if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.instance.repaint());
          }
          return false;
        },
      }, {
        propertyToWatch: 'resourceListSelected',
        handler(newValue, oldValue) {},
      }],
    },
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: true },
    resourceList: { ...resourcesTagbox, maxLimit: 6, required: false },
  },
};