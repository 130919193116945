import { getMomentDateString } from '@/utils/dateUtil';
import { TOOLS_API } from '@/api';

const state = {
  parentTableData: [],
  childTableData: [],
  selectedParentId: '',
};

const actions = {
  async fetchShadowSources({ commit }, args) {
    const params = {
      scs: args.coordinatorListSelected,
      Date: args.tradeDateSelected,
    };
    try {
      const { data } = await TOOLS_API.get('/shadow-sources-parent', { params });
      data.forEach((obj) => {
        obj.tzTradeDate = getMomentDateString(obj.tzTradeDate);
      });
      commit('setChildTableData', []);
      commit('setParentTableData', data);
    } catch (error) {
      this.$notify('Failed to Load Shadow Sources', 'error');
    }
  },
};

const mutations = {
  setParentTableData(state, value) {
    state.parentTableData = value;
  },
  setChildTableData(state, value) {
    state.childTableData = value;
  },
  async setChild(state, value) {
    state.selectedParentId = value.jobId;
    try {
      const params = { Job_ID: state.selectedParentId };
      const { data } = await TOOLS_API.get('/shadow-sources-child', { params });
      data.forEach((obj) => {
        obj.tzTradeDate = getMomentDateString(obj.tzTradeDate);
      });
      state.childTableData = data;
    } catch (error) {
      this.$notify('Failed to Load Shadow Sources Child Data', 'error');
    }
  },
  reset(state) {
    state.parentTableData = [];
    state.childTableData = [];
    state.selectedParentId = '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};