import {
  coordinatorList, tradeDateRange, marketTypes, resourcesTagbox,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import userStore from '@/utils/userStore';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

const roundValue = (value) => Math.round(value * 100) / 100;

const bidDetails = {
  name: 'bidDetails',
  columns: [{
    label: 'DESCRIPTION',
    prop: 'description',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 1',
    prop: 'hE_01',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 2',
    prop: 'hE_02',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 3',
    prop: 'hE_03',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 4',
    prop: 'hE_04',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 5',
    prop: 'hE_05',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 6',
    prop: 'hE_06',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 7',
    prop: 'hE_07',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 8',
    prop: 'hE_08',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 9',
    prop: 'hE_09',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 10',
    prop: 'hE_10',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 11',
    prop: 'hE_11',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 12',
    prop: 'hE_12',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 13',
    prop: 'hE_13',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 14',
    prop: 'hE_14',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 15',
    prop: 'hE_15',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 16',
    prop: 'hE_16',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 17',
    prop: 'hE_17',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 18',
    prop: 'hE_18',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 19',
    prop: 'hE_19',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 20',
    prop: 'hE_20',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 21',
    prop: 'hE_21',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 22',
    prop: 'hE_22',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 23',
    prop: 'hE_23',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 24',
    prop: 'hE_24',
    filterable: true,
    sortable: true,
    width: 100,
  }, {
    label: 'HE 25',
    prop: 'hE_25',
    filterable: true,
    sortable: true,
    width: 100,
  }],
};

export default {
  dockConfig: {
    visible: false,
    alignment: userStore.dock,
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    marketTypes: {
      ...marketTypes,
      required: true,
      clearable: false,
    },
    resourceList: { ...resourcesTagbox, required: false },
    sourceTypes: {
      label: 'Source Type',
      value: 'BidResults',
      type: 'select',
      options: [{ value: 'BidResults', label: 'Bid Results' }, { value: 'CleanBidSet', label: 'Clean Bid Set' }],
    },
  },
  bidsHeaderConfig: {
    name: 'bidsHeader',
    columns: [{
      label: 'BID',
      prop: 'Bid',
      alignment: 'left',
      width: 1500,
      bandColumn: true,
      columns: [{
        caption: 'SC',
        prop: 'sc',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'BID STATUS',
        prop: 'bidStatus',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'MARKET TYPE',
        prop: 'marketType',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'TRADE DATE',
        prop: 'date',
        dataType: 'date',
        format: 'yyyy-MM-dd',
        filterable: true,
        sortable: true,
        width: 75,
      }, {
        caption: 'RESOURCE TYPE',
        prop: 'resourceType',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'RESOURCE',
        prop: 'resource',
        filterable: true,
        sortable: true,
        width: 150,
      }, {
        caption: 'CONFIGURATION',
        prop: 'configuration',
        filterable: true,
        sortable: true,
        width: 150,
      }, {
        caption: 'ENERGY MAX DAY',
        prop: 'energyMaxDay',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'ENERGY MIN DAY',
        prop: 'energyMinDay',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'CONTINGENCY AVAIL FLAG',
        prop: 'contingencyAvailFlag',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'MIN HRLY BLOCK',
        prop: 'minHourlyBlock',
        filterable: true,
        sortable: true,
        width: 50,
      }, {
        caption: 'BIDS',
        prop: 'bidsExist',
        filterable: true,
        sortable: true,
        width: 75,
      }, {
        caption: 'GREEN HOURSE GAS ADDER',
        prop: 'greenHouseGasAdder',
        filterable: true,
        sortable: true,
        width: 50,
      }],
    }, {
      label: 'REGISTERED INTERTIE',
      prop: 'RegisteredInterTie',
      alignment: 'left',
      width: 200,
      bandColumn: true,
      columns: [{
        caption: 'MRID',
        prop: 'interTie_mrid',
        filterable: true,
        sortable: true,
        width: 30,
      }, {
        caption: 'AGGREGATED PNODE',
        prop: 'interTie_AggregatedPnode',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'INDIVIDUAL PNODE',
        prop: 'interTie_IndividualPnode',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'PRIMARY FLOWGATE',
        prop: 'interTie_PrimaryFlowgate',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'SECONDARY FLOWGATE',
        prop: 'interTie_SecondaryFlowgate',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'SCHEDULING COORDINATOR',
        prop: 'interTie_SchedulingCoordinator',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'DIRECTION',
        prop: 'interTie_Direction',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'ENERGY PRODUCT TYPE',
        prop: 'interTie_EnergyProductType',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'PURCHASE SERVICE ENTITY',
        prop: 'interTie_PurchaseServiceEntity',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'REGISTERED FLAG',
        prop: 'interTie_RegisteredFlag',
        filterable: true,
        sortable: true,
        width: 70,
      }],
    }, {
      label: 'WHEELING RESOURCE',
      prop: 'WheelingResource',
      alignment: 'left',
      width: 200,
      bandColumn: true,
      columns: [{
        caption: 'MRID',
        prop: 'wheelingResource_mrid',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'AGGREGATED PNODE',
        prop: 'wheelingResource_AggregatedPnode',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'INDIVIDUAL PNODE',
        prop: 'wheelingResource_IndividualPnode',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'PRIMARY FLOWGATE',
        prop: 'wheelingResource_PrimaryFlowgate',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'SECONDARY FLOWGATE',
        prop: 'wheelingResource_SecondaryFlowgate',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'ENERGY PRODUCT TYPE',
        prop: 'wheelingResource_EnergyProductType',
        filterable: true,
        sortable: true,
        width: 70,
      }, {
        caption: 'PURCHASE SERVICE ENTITY',
        prop: 'wheelingResource_PurchaseServiceEntity',
        filterable: true,
        sortable: true,
        width: 70,
      }],
    }],
    options,
  },
  bidCurvesPivotedConfig: {
    name: 'bidCurvesPivoted',
    columns: [{
      label: 'MARKET PRODUCT TYPE',
      prop: 'productType',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID STATUS',
      prop: 'bidStatus',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'SC',
      prop: 'sc',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'MARKET TYPE',
      prop: 'marketType',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'TRADE DATE',
      prop: 'tradeDate',
      format: 'yyyy-MM-dd',
      dataType: 'date',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'CONFIGURATION',
      prop: 'configuration',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'RESOURCE TYPE',
      prop: 'rsrC_TYPE',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'RESOURCE ID',
      prop: 'rsrC_ID',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BEGIN HOUR',
      prop: 'beginHour',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'END HOUR',
      prop: 'endHour',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'NO LOAD COST',
      prop: 'noLoadCost',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_01_QTY',
      prop: 'bid_01_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_01_PRICE',
      prop: 'bid_01_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_02_QTY',
      prop: 'bid_02_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_02_PRICE',
      prop: 'bid_02_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_03_QTY',
      prop: 'bid_03_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_03_PRICE',
      prop: 'bid_03_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_04_QTY',
      prop: 'bid_04_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_04_PRICE',
      prop: 'bid_04_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_05_QTY',
      prop: 'bid_05_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_05_PRICE',
      prop: 'bid_05_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_06_QTY',
      prop: 'bid_06_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_06_PRICE',
      prop: 'bid_06_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_07_QTY',
      prop: 'bid_07_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_07_PRICE',
      prop: 'bid_07_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_08_QTY',
      prop: 'bid_08_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_08_PRICE',
      prop: 'bid_08_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_09_QTY',
      prop: 'bid_09_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_09_PRICE',
      prop: 'bid_09_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_10_QTY',
      prop: 'bid_10_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_10_PRICE',
      prop: 'bid_10_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_11_QTY',
      prop: 'bid_11_Qty',
      filterable: true,
      sortable: true,
      width: 145,
    }, {
      label: 'BID_11_PRICE',
      prop: 'bid_11_Price',
      filterable: true,
      sortable: true,
      width: 145,
    }],
    options,
  },
  bidSelfSchedulesConfig: {
    name: 'bidSelfSchedules',
    columns: [{
      caption: 'BID STATUS',
      prop: 'bidStatus',
      filterable: true,
      sortable: true,
      width: 50,
    }, {
      label: 'RESOURCE',
      prop: 'resource',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'CONFIGURATION',
      prop: 'configuration',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'PRODUCT TYPE',
      prop: 'productType',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'SELF SCHD TYPE',
      prop: 'selfSchdType',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'WHEELING REF',
      prop: 'wheelRefName',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'SUPPORT RSRC',
      prop: 'supportingResource',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'CONTRACT RIGHT',
      prop: 'contractRightId',
      filterable: true,
      sortable: true,
      width: 150,
    }, {
      label: 'HE_1',
      prop: 'hE_01',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_2',
      prop: 'hE_02',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_3',
      prop: 'hE_03',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_4',
      prop: 'hE_04',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_5',
      prop: 'hE_05',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_6',
      prop: 'hE_06',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_7',
      prop: 'hE_07',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_8',
      prop: 'hE_08',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_9',
      prop: 'hE_09',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_10',
      prop: 'hE_10',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_11',
      prop: 'hE_11',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_12',
      prop: 'hE_12',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_13',
      prop: 'hE_13',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_14',
      prop: 'hE_14',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_15',
      prop: 'hE_15',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_16',
      prop: 'hE_16',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_17',
      prop: 'hE_17',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_18',
      prop: 'hE_18',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_19',
      prop: 'hE_19',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_20',
      prop: 'hE_20',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_21',
      prop: 'hE_21',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_22',
      prop: 'hE_22',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_23',
      prop: 'hE_23',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_24',
      prop: 'hE_24',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'HE_25',
      prop: 'hE_25',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
    }, {
      label: 'TOTAL',
      prop: 'total',
      dataType: 'number',
      format: '#0.##',
      filterable: true,
      sortable: true,
      width: 90,
      calculateCellValue: (e) => roundValue(Object.keys(e)
        .filter((key) => key.includes('hE'))
        .reduce((acc, curr) => acc + Number(e[curr] || 0), 0)),
    }],
    options,
  },
  bidStartupCostConfig: {
    name: 'bidStartupCost',
    columns: [{
      label: 'RESOURCE',
      prop: 'resource',
      filterable: true,
      sortable: true,
      width: 140,
    }, {
      label: 'RESOURCE TYPE',
      prop: 'resourceType',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'CONFIGURATION',
      prop: 'configuration',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'BEGIN HOUR',
      prop: 'beginHour',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'END HOUR',
      prop: 'endHour',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 01 MINS',
      prop: 'startCost_01_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 01 AMOUNT',
      prop: 'startCost_01_Amount',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 02 MINS',
      prop: 'startCost_02_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 02 AMOUNT',
      prop: 'startCost_02_Amount',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 03 MINS',
      prop: 'startCost_03_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START COST 03 AMOUNT',
      prop: 'startCost_03_Amount',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 01 MINS',
      prop: 'startTime_01_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 01 QTY',
      prop: 'startTime_01_Qty',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 02 MINS',
      prop: 'startTime_02_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 02 QTY',
      prop: 'startTime_02_Qty',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 03 MINS',
      prop: 'startTime_03_Mins',
      filterable: true,
      sortable: true,
      width: 110,
    }, {
      label: 'START TIME 03 QTY',
      prop: 'startTime_03_Qty',
      filterable: true,
      sortable: true,
      width: 110,
    }],
    options,
  },
  bidsTransitionConfig: {
    name: 'bidsTransition',
    columns: [{
      label: 'BID STATUS',
      prop: 'bidStatus',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'TRADE DATE',
      prop: 'tradeDate',
      format: 'yyyy-MM-dd',
      dataType: 'date',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'RESOURCE ID',
      prop: 'rsrC_ID',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'CONFIGURATION',
      prop: 'configuration',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'BEGIN HOUR',
      prop: 'beginHour',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'END HOUR',
      prop: 'endHour',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'NOTIFICATION TIME',
      prop: 'notificationTime',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'TRANSITION COST',
      prop: 'transitionCost',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'FROM CONFIGURATION',
      prop: 'fromConfiguration',
      filterable: true,
      sortable: true,
      width: 190,
    }, {
      label: 'TO CONFIGURATION',
      prop: 'toConfiguration',
      filterable: true,
      sortable: true,
      width: 190,
    }],
    options,
  },
  bidDetailsConfig: bidDetails,
  bidDetailsExportConfig: {
    name: 'bidDetailsExport',
    columns: [{
      label: 'RESOURCE',
      prop: 'resource',
      filterable: true,
      sortable: true,
      width: 150,
    },
    ...bidDetails.columns],
  },
};