import { CISO_OASIS_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';
import config from './oasisPricesConfigs';

const state = {
  tableData: [],
  tableConfig: config.fields,
  tableExportConfig: {},
};

const actions = {
  async fetchOasisPrices({ commit }, args) {
    commit('setTableData', []);

    const params = {
      tradingDate: args.tradeDateSelected,
      report: args.oasisReportListSelected,
    };
    try {
      const headers = { Accept: 'application/json' };
      const { data } = await CISO_OASIS_API.get('/oasis-prices', { params, headers });
      data.forEach((obj) => {
        obj.tzTradingDate = getMomentDateString(obj.tzTradingDate);
      });
      commit('setTableData', data);

      const fileSuffix = `_${params.report}_${params.tradingDate.substring(0, 10)}`;

      // save parameters for export
      const tableExportConfig = {
        requestAPI: CISO_OASIS_API,
        requestURL: '/oasis-prices',
        requestParams: params,
        fileNameSuffix: fileSuffix,
      };
      commit('setTableExportConfig', tableExportConfig);
    } catch {
      this.$notify('Failed to retrieve Oasis Prices', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setTableExportConfig', {});
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setTableExportConfig(state, value) {
    state.tableExportConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};