import { accessRight, deliveryManagement } from '@/auth/permission';

const notRealScreen = [{
  path: 'unmatched-deals',
  name: 'UnmatchedDeals',
  meta: { visible: true, requiresAuth: true, text: 'Unmatched Deals' },
  component: () => import('@/components/DeliveryManagement/UnmatchedDeals/UnmatchedDeals'),
}, {
  path: 'book-out',
  name: 'BookOut',
  meta: { visible: true, requiresAuth: true, text: 'Book Out' },
  component: () => import('@/components/DeliveryManagement/UnmatchedDeals/UnmatchedDeals'),
}];

const routes = {
  path: '/delivery-management',
  meta: {
    visible: true,
    requiresAuth: true,
    className: 'dropdown',
    text: 'DELIVERY MANAGEMENT',
    permission: { name: 'delivery_management', rights: ['read', 'write'] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'positions',
    name: 'Positions',
    meta: { 
      visible: true, 
      requiresAuth: true, 
      text: 'Positions' 
    },
    props: {
      entityType: 'SC',
      commodity: 'POWER',
      market: 'CAISO',
      marketTypes: ['DAM', 'RTM'],
      moduleName: 'sibrSchedules',
    },
    component: () => import('@/components/DeliveryManagement/PositionManagement/PositionManagement'),
  }, {
    path: 'configuration',
    name: 'PositionConfiguration',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Positions Configuration',
      permission: { name: deliveryManagement.settings, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/DeliveryManagement/PositionManagement/PositionConfiguration'),
  }, {
    path: 'settings',
    name: 'DMSettings',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Settings',
      permission: { name: deliveryManagement.settings, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/DeliveryManagement/Settings/DMSettings'),
  }],
};

if (process.env.NODE_ENV !== 'production') {
  routes.children.push(notRealScreen[0]);
  routes.children.push(notRealScreen[1]);
}

export default routes;