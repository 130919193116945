import utils from '@/utils';
import authStore from '@/store/authStore';
import dateStore from '@/utils/dateStore';
import { WORKFLOW_API } from '@/api';

const state = {
  notificationCallback: null,
  tableConfiguration: {
    columns: [{
      searchItem: true, prop: 'workflowName', type: { name: 'instance-route-cell', overrideReadonly: true }, label: 'name', filter: true, sortable: true,
    }, {
      prop: 'status', filter: true, class: true, sortable: true,
    }, {
      prop: 'createdBy', label: 'created by', filter: true, sortable: true,
    }, {
      prop: 'startTime', label: 'start time', filter: true, sortable: true,
    }, {
      prop: 'endTime', label: 'end time', filter: true, sortable: true,
    }],
    footer: { count: true },
    style: { maxHeight: 700 },
    options: { searchFilter: true },
  },
  datePropsAndFormats: [
    { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'endTime', format: 'YYYY-MM-DD HH:mm' },
  ],
  tableData: [],
  refreshRate: 15,
  minRefreshRate: 5,
  maxRefreshRate: 60,
  counter: 0,
  selectedDate: utils.date.getTodayLocal(),
  searchText: '',
  userToggle: false,
};

const getters = {
  getTableConfiguration: state => state.tableConfiguration,
  getTableData: state => state.tableData,
  getCounter: state => state.counter,
  getRefreshRate: state => state.refreshRate,
  getMinRefreshRate: state => state.minRefreshRate,
  getMaxRefreshRate: state => state.maxRefreshRate,
  getMinHeight: state => (state.tableData && state.tableData.length > 0) ? 600 : 100,
  getInstanceDate: state => state.selectedDate,
  getInstanceSearchText: state => state.searchText,
  getUserToggle: state => state.userToggle,
};

const actions = {
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  loadwfInstancesAction({ commit, dispatch, state }) {
    const startTime = utils.date.toMomentFromDate(state.selectedDate, dateStore.getTimeZoneDefinition().tz).format('YYYY-MM-DD HH:mm');
    const userName = authStore.state.profile == null ? null : authStore.state.profile.userName;

    let routeString = '';

    if (state.userToggle && userName) { routeString = `instances/?startdate=${startTime}&&createdBy=${userName}`; } else { routeString = `instances/?startdate=${startTime}`; }

    WORKFLOW_API.get(routeString).then((response) => {
      response.data.cases.forEach((element) => {
        if (element.status === 'CLOSED') { element.status = 'COMPLETED'; }
        if (element.status === 'OPEN') { element.status = 'PROCESSING'; }
      });
      commit('loadwfInstanceMutation', response.data.cases);
    }).catch(error => console.log(error));
  },
  changeRefreshRateAction({ commit }, value) {
    commit('refreshRateMutation', value);
  },
  changeInstanceDateAction({ commit, dispatch }, value) {
    commit('setInstanceDate', value);
    dispatch('loadwfInstancesAction');
  },
  incrementCounterAction({ dispatch, commit, state }) {
    if (state.counter >= state.refreshRate) {
      dispatch('loadwfInstancesAction');
      commit('resetCounterMutation');
    } else {
      commit('incrementCounterMutation');
    }
  },
  changeUserToggle({ commit, dispatch }, value) {
    commit('setUserToggle', value);
    dispatch('loadwfInstancesAction');
  },
  clear({ commit }) {
    commit('clear');
  },
  changeInstanceSearchText({ commit }, value) {
    commit('setInstanceSearchText', value);
  },

};

const mutations = {
  refreshRateMutation(state, value) {
    state.refreshRate = value;
  },
  resetCounterMutation(state) {
    state.counter = 1;
  },
  incrementCounterMutation(state) {
    state.counter += 1;
  },
  loadwfInstanceMutation(state, data) {
    const dta = utils.date.formatDataTimes(data, state.datePropsAndFormats, dateStore.getTimeZoneDefinition().tz);
    state.tableData = dta;
  },
  clear(state) {
    state.tableData = [];
  },
  setInstanceDate(state, value) {
    state.selectedDate = value;
  },
  setInstanceSearchText(state, value) {
    state.searchText = value;
  },
  setUserToggle(state, value) {
    state.userToggle = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};