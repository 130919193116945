import { DELIVERY_MANAGEMENT_API, STRUCTURES_API } from '@/api';
import {
  fetchResource, fetchUserResource, updateResource, updateUserResource,
} from '@/utils/structuresHelper';
import { clone, sortBy } from '@/utils/dataUtil';

const chartTypeOptions = [
  { value: 'spline', label: 'SPLINE' },
  { value: 'bar', label: 'BAR' },
  { value: 'line', label: 'LINE' },
  { value: 'area', label: 'AREA' },
];

const state = {
  tableConfiguration: {
    columns: [{
      prop: 'name',
      editable: true,
      sortable: true,
      label: 'POSITION',
      filterType: 'agTextColumnFilter',
    }, {
      prop: 'description',
      editable: true,
      sortable: true,
      filterType: 'agTextColumnFilter',
    }, {
      prop: 'showNET',
      label: 'SHOW NET SERIES',
      editable: true,
      sortable: true,
      width: 50,
      cellDataType: 'boolean',
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
    }, {
      prop: 'shared',
      label: 'SHARED',
      editable: true,
      sortable: true,
      width: 50,
      cellDataType: 'boolean',
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
    }],
    footer: { count: false },
    style: { maxHeight: 400 },
    options: { cellSelection: true },
  },
  tableConfigurationChild: {
    columns: [{
      prop: 'label',
      editable: true,
      sortable: true,
      width: 140,
    }, {
      prop: 'order',
      label: 'Order',
      editable: true,
      sortable: true,
      width: 60,
    }, {
      prop: 'resourceGroupName',
      label: 'Delivery Group',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 150,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'groups',
        placeholder: 'Group',
        clearable: false,
      },
    }, {
      prop: 'variant',
      label: 'Variant',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 70,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'variants',
        placeholder: 'Variant',
        clearable: false,
      },
    }, {
      prop: 'type',
      label: 'Type',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 70,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'types',
        placeholder: 'Type',
        clearable: false,
      },
    }, {
      prop: 'format',
      label: 'Format',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 70,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'formats',
        placeholder: 'Format',
        clearable: false,
      },
    }, {
      prop: 'summary',
      label: 'Row Summary',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 70,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'summaries',
        placeholder: 'Summary',
        clearable: false,
      },
    }, {
      prop: 'groupSummary',
      label: 'Group Summary',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 70,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'summaries',
        placeholder: 'Group Summary',
        clearable: false,
      },
    }, {
      prop: 'factor',
      editable: true,
      sortable: true,
      width: 70,
    }, {
      prop: 'fixedDecimal',
      label: 'FIXED DECIMAL',
      editable: true,
      sortable: true,
      width: 80,
    }, {
      prop: 'color',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 60,
      cellDataType: 'object',
      cellClass: 'hide-text',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'colors',
        placeholder: 'Color',
        clearable: false,
        displayValue: '',
      },
      cellStyle: (params) => {
        if (params.value) {
          return { color: params.value, backgroundColor: `${params.value} !important` };
        }
        return null;
      },
    }, {
      prop: 'chartType',
      label: 'Chart Type',
      editable: true,
      suppressEditing: true,
      sortable: true,
      width: 80,
      cellDataType: 'object',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'chartTypes',
        placeholder: 'Chart Type',
        clearable: false,
      },
    }, {
      prop: 'fill',
      editable: true,
      sortable: true,
      width: 50,
      cellDataType: 'boolean',
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
    }, {
      prop: 'stack',
      editable: true,
      sortable: true,
      width: 60,
      cellDataType: 'boolean',
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
    }, {
      prop: 'stackOrder',
      label: 'Stack Order',
      editable: true,
      sortable: true,
      width: 60,
    }],
    customCellClassRules: {
      hideCellText(params) {
        return params.colDef.field === 'color';
      },
    },
    formats: [
      { value: 'decimal', label: 'Fixed Point' },
      { value: 'percent', label: 'Percent' },
      { value: 'currency', label: 'Currency' },
    ],
    summaries: [
      { value: 'sum', label: 'Sum' },
      { value: 'min', label: 'Min' },
      { value: 'max', label: 'Max' },
      { value: 'avg', label: 'Average' },
      { value: 'count', label: 'Count' },
    ],
    colors: [
      { value: 'rgb(255, 0, 0)', label: 'RED' },
      { value: 'rgb(255, 140, 0)', label: 'ORANGE' },
      { value: 'rgb(255, 255, 0)', label: 'YELLOW' },
      { value: 'rgb(0, 128, 0)', label: 'GREEN' },
      { value: 'rgb(0, 0, 255)', label: 'BLUE' },
      { value: 'rgb(128, 0, 128)', label: 'PURPLE' },
      { value: 'rgb(139, 69, 19)', label: 'BROWN' },
      { value: 'rgb(0, 0, 0)', label: 'BLACK' },
      { value: 'rgb(128, 128, 128)', label: 'GRAY' },
      { value: 'rgb(139, 0, 0)', label: 'DARK RED' },
      { value: 'rgb(255, 105, 180)', label: 'PINK' },
      { value: 'rgb(255, 215, 0)', label: 'GOLD' },
      { value: 'rgb(1, 50, 32)', label: 'DARK GREEN' },
      { value: 'rgb(0, 255, 0)', label: 'LIME' },
      { value: 'rgb(0, 255, 255)', label: 'CYAN' },
      { value: 'rgb(0, 0, 139)', label: 'DARK BLUE' },
      { value: 'rgb(255, 0, 255)', label: 'MAGENTA' },
    ],
    footer: { count: false },
    style: { maxHeight: 400 },
    options: { cellSelection: true },
    columnList(name) {
      if (name === 'chartTypes') return chartTypeOptions;
      if (name === 'groups') return this.groups;
      if (name === 'variants') return this.variants;
      if (name === 'types') return this.types;
      if (name === 'formats') return this.formats;
      if (name === 'summaries') return this.summaries;
      if (name === 'colors') return this.colors;
      return [];
    },
  },
  tableData: [],
  newTableData: [],
  tableDataChild: [],
  intervalTypes: [],
  selectedParentRowId: null,
  selectedChildRowId: null,
};

const getters = {
  getSettingsParams(state) {
    const selected = state.tableData.find((o) => o.rowId === state.selectedParentRowId);
    if (selected && selected.positionItems.length) {
      const index = selected.positionItems.findIndex(({ rowId }) => rowId === state.selectedChildRowId);
      if (index === -1) {
        // if selected name can't be found set it to null and do nothing
        state.selectedChildRowId = null;
        return;
      }
      return selected.positionItems[index].resourceGroupName;
    }
  },
};

const actions = {
  initialize({ dispatch }, isUserConfiguration) {
    dispatch('loadGroups');
    dispatch('loadVariants');
    dispatch('loadIntervalTypes');
    dispatch('loadPositions', isUserConfiguration);
  },
  rowSelected({ commit }, { row }) {
    if (row != null) {
      commit('setSelectedParentId', row.rowId);
      commit('setTableDataChild', row.positionItems);
    } else {
      state.tableHourData = [];
    }
  },
  childRowSelected({ commit }, { row }) {
    if (row != null) commit('setSelectedChildId', row.rowId);
  },
  async loadGroups({ commit }) {
    const { data } = await DELIVERY_MANAGEMENT_API.get('groupings');
    commit('setGroups', data.data);
  },
  async loadVariants({ commit }) {
    const { data } = await STRUCTURES_API.get('variant');
    commit('setVariants', data.data);
  },
  async loadIntervalTypes({ commit }) {
    const { data } = await STRUCTURES_API.get('intervals/types');
    commit('setIntervalTypes', data.types);
  },
  async loadPositions({ commit }, isUserConfiguration) {
    const userResource = await fetchUserResource('position-management', 'POSITIONS');
    const resource = await fetchResource('position-management', 'POSITIONS');

    const userResourcePositions = userResource?.POSITIONS?.map((x) => ({ ...x, shared: false })) || [];
    const resourcePositions = resource?.POSITIONS?.map((x) => ({ ...x, shared: true })) || [];

    const POSITIONS = userResourcePositions.concat(resourcePositions);
    if (Array.isArray(POSITIONS)) {
      POSITIONS.sort(sortBy('name'));
      // assign row id to each position
      POSITIONS.forEach((pos, idx) => {
        pos.rowId = idx;
        pos.positionItems.sort(sortBy('order'));
        pos.positionItems.forEach((posItem, childIdx) => {
          // assign row id to each delivery group within position
          posItem.rowId = childIdx;
          posItem.type = posItem.type || 'VOLUME'; // this will default to VOLUME for old configurations
        });
      });

      commit('setTableData', POSITIONS);
    }
  },
  async savePositions({ state, dispatch }) {
    const userPositions = state.tableData.filter((x) => !x.shared);
    const sharedPositions = state.tableData.filter((x) => x.shared);

    await updateUserResource('position-management', 'POSITIONS', { POSITIONS: userPositions });
    await updateResource('position-management', 'POSITIONS', { POSITIONS: sharedPositions });
    dispatch('deliveryManagement/positionManagement/positions/loadPositions', null, { root: true });
    dispatch('loadPositions');
  },
};

const mutations = {
  setGroups(state, value) {
    state.tableConfigurationChild.groups = value.map(({ name, id }) => ({ value: id, label: name }));
    state.tableConfigurationChild.groups.sort(sortBy('label'));
  },
  setVariants(state, value) {
    const names = [...new Set(value.map(({ name }) => name))];
    state.tableConfigurationChild.variants = names.map((name) => ({ value: name, label: name }));
  },
  setIntervalTypes(state, value) {
    const names = [...new Set(value.map(({ value }) => value))];
    state.tableConfigurationChild.types = names.map((name) => ({ value: name, label: name }));
    state.tableConfigurationChild.types.sort(sortBy('label'));
  },
  setTableData(state, value) { if (value) state.tableData = value; else state.tableData = []; },
  setTableDataChild(state, value) { state.tableDataChild = value; },
  setSelectedParentId(state, value) { state.selectedParentRowId = value; },
  setSelectedChildId(state, value) { state.selectedChildRowId = value; },
  createParentRow(state) {
    const row = {
      name: null,
      description: null,
      positionItems: [],
      rowId: state.tableData.length > 0 ? state.tableData[state.tableData.length - 1].rowId + 1 : 0,
    };
    state.tableData.push(row);
    state.newTableData.push(row);
  },
  copyPositionData(state) {
    let row = clone(state.tableData[state.selectedParentRowId]);
    row.rowId = state.tableData.length ? Math.max(...state.tableData.map(({ rowId }) => rowId)) + 1 : 0;
    const childRows = row.positionItems;
    row.name += ' - copy';
    state.tableData.push(row);
    state.newTableData.push(row);
    state.tableDataChild = childRows;
  },
  deleteParentRow(state) {
    const index = state.tableData.findIndex(({ rowId }) => rowId === state.selectedParentRowId);

    if (index === -1) {
      // if selected name can't be found set it to null and do nothing
      state.selectedParentRowId = null;
      return;
    }

    // check if last row was deleted
    if ((index + 1) === state.tableData.length) {
      // filter out the deleted row
      state.tableData.splice(index, 1);

      // reset selected parent and child data
      state.selectedParentRowId = null;
      state.tableDataChild = [];
    } else {
      // filter out the deleted row
      state.tableData.splice(index, 1);

      // set new selected row as the row at the deleted index
      state.selectedParentRowId = state.tableData[index] ? state.tableData[index].rowId : null;
      state.tableDataChild = state.tableData[index] ? state.tableData[index].positionItems : [];
    }
  },
  changeParentData(state, { rowIndex, prop, value }) {
    state.selectedParentRowId = state.tableData[rowIndex].rowId;
    state.tableData[rowIndex][prop] = value;
  },
  createChildRow(state) {
    const row = {
      positionId: 0,
      order: 1,
      resourceGroupName: null,
      groupId: null,
      factor: 1,
      color: null,
      chartType: null,
      fill: false,
      stack: false,
      stackOrder: 1,
      variant: '',
      groupSummary: null,
      summary: null,
      format: null,
      type: 'VOLUME',
      rowId: state.tableDataChild.length > 0 ? state.tableDataChild[state.tableDataChild.length - 1].rowId + 1 : 0,
    };
    const selected = state.tableData.find((o) => o.rowId === state.selectedParentRowId);
    if (selected) {
      selected.positionItems.push(row);
      state.tableDataChild = selected.positionItems;
    }
  },
  deleteChildRow(state) {
    const selected = state.tableData.find((o) => o.rowId === state.selectedParentRowId);
    if (selected && selected.positionItems.length) {
      const index = selected.positionItems.findIndex(({ rowId }) => rowId === state.selectedChildRowId);

      if (index === -1) {
        // if selected name can't be found set it to null and do nothing
        state.selectedChildRowId = null;
        return;
      }

      // check if last row was deleted
      if ((index + 1) === selected.positionItems.length) {
        // filter out the deleted row and set selected child to null
        selected.positionItems.splice(index, 1);
        state.tableDataChild = selected.positionItems;
        state.selectedChildRowId = null;
      } else {
        // filter out the deleted row
        selected.positionItems.splice(index, 1);
        state.tableDataChild = selected.positionItems;

        // set new selected row as the row at the deleted index
        state.selectedChildRowId = selected.positionItems[index] ? selected.positionItems[index].rowId : null;
      }
    }
  },
  changeChildData(state, { rowIndex, value, prop }) {
    const selected = state.tableData.find((o) => o.rowId === state.selectedParentRowId);
    if (selected) {
      if (prop === 'resourceGroupName') {
        const group = state.tableConfigurationChild.groups.find(((g) => g.value === Number(value)));
        state.tableDataChild[rowIndex].groupId = group.value;
        state.tableDataChild[rowIndex].resourceGroupName = group.label;
        state.selectedChildRowId = state.tableDataChild[rowIndex].rowId;
        selected.positionItems = state.tableDataChild;
      } else {
        const newVal = ['order', 'stackOrder'].includes(prop) ? parseInt(value, 10) : value;
        state.tableDataChild[rowIndex][prop] = newVal;
        selected.positionItems = state.tableDataChild;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};