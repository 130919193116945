import {
  gridConfig,
} from '@/components/PPA/BaseStore/ppasBase';

export default {
  columns: [
    {
      caption: 'PPA',
      dataField: 'ppaName',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'CHARGECODE',
      dataField: 'chargeCodeName',
      dataType: '',
      area: 'column',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'DESCRIPTION',
      dataField: 'description',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'AREF',
      dataField: 'aref',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'METERID',
      dataField: 'meterId',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'RESOURCE',
      dataField: 'resource',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'TRADINGDATE',
      dataField: 'tradingDate',
      dataType: 'string',
      area: 'row',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'TRADINGHOUR',
      dataField: 'tradingHour',
      dataType: 'number',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'TRADING INTERVAL',
      dataField: 'tradingInterval',
      dataType: '',
      area: 'filter',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'CURRENTAMOUNT',
      dataField: 'currentAmount',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.zeroCurrencyFormat,
    }, {
      caption: 'CURRENTQUANTITY',
      dataField: 'currentQuantity',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.valFormat,
    }, {
      caption: 'CURRENTPRICE',
      dataField: 'currentPrice',
      dataType: '',
      area: 'data',
      summaryType: 'avg',
      expanded: true,
      format: gridConfig.valFormat,
    }, {
      caption: 'PREVIOUSAMOUNT',
      dataField: 'previousAmount',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.zeroCurrencyFormat,
      visible: true,
    }, {
      caption: 'PREVIOUSQUANTITY',
      dataField: 'previousQuantity',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.valFormat,
      visible: true,
    }, {
      caption: 'PREVIOUSPRICE',
      dataField: 'previousPrice',
      dataType: '',
      area: 'data',
      summaryType: 'avg',
      expanded: true,
      format: gridConfig.valFormat,
      visible: true,
    }, {
      caption: 'NETAMOUNT',
      dataField: 'netAmount',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.zeroCurrencyFormat,
      visible: true,
    }, {
      caption: 'NETQUANTITY',
      dataField: 'netQuantity',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.valFormat,
      visible: true,
    }, {
      caption: 'NETPRICE',
      dataField: 'netPrice',
      dataType: '',
      area: 'data',
      summaryType: 'avg',
      expanded: true,
      format: gridConfig.valFormat,
      visible: true,
    }, {
      caption: 'COMPAREAMOUNT',
      dataField: 'currentAmountCompare',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.zeroCurrencyFormat,
      visible: false,
    }, {
      caption: 'COMPAREQUANTITY',
      dataField: 'currentQuantityCompare',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.valFormat,
      visible: false,
    }, {
      caption: 'COMPAREPRICE',
      dataField: 'currentPriceCompare',
      dataType: '',
      area: 'data',
      summaryType: 'avg',
      expanded: true,
      format: gridConfig.valFormat,
      visible: false,
    }, {
      caption: 'VARIANCEAMOUNT',
      dataField: 'varianceAmount',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.zeroCurrencyFormat,
      visible: false,
    }, {
      caption: 'VARIANCEQUANTITY',
      dataField: 'varianceQuantity',
      dataType: '',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: gridConfig.valFormat,
      visible: false,
    }, {
      caption: 'VARIANCEPRICE',
      dataField: 'variancePrice',
      dataType: '',
      area: 'data',
      summaryType: 'avg',
      expanded: true,
      format: gridConfig.valFormat,
      visible: false,
    },
  ],
};