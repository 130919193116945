<template>
  <div class="webcli" @click="onClick">
      <div class="webcli-input">
          <el-input ref="cliinput" spellcheck="false" autofocus>
          </el-input>
      </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data: function(){        
        return {  
            visible : this.$store.state.cli.visible          
        }
    },

    mounted: function(){                
    },
    methods: {
        focused(e) {
            console.log(e);
        },
        onClick(e){
            this.$refs.cliinput.$el.children[0].focus();
        },
        setFocus(){            
            
        }
    },
    watch: {
        'visible': function() {
            console.log(this.cliVisibility);
            if(this.cliVisibility)
            this.setFocus();
        }
    }
}
</script>
<style>
.webcli {
    font-size: 20px;
    background-color: rgba(0, 0, 0, .7);
    height: 300px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 5px;
    border: solid 1px rgba(255,255,255, .5);
    box-shadow: 0 0 3px #000, 0 0 8px #000 inset;
}

.webcli-input > .el-input > .el-input__inner{
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    color: #8AE234;
    font-size: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
}

.webcli-input::-ms-clear { display: none;}
</style>
