import Vue from 'vue';
import store from '../../../../store';

Vue.component('CisoBidStrategyCell', {
  props: {
    scope: {
      type: Object,
    },
    column: {
      type: Object,
    },
    configStore: {
      type: Object,
    },
  },
  computed: {
    className() {
      if (this.params.data[this.params.column.colId] === null) return 'empty-link';
    },
    selection() {
      if (this.params.data[this.params.column.colId] === null) return 'select';
      return this.params.data[`${this.params.column.colId}Name`];
    },
  },
  methods: {
    showDialog(e) {
      store.dispatch('ciso/bidstrategy/showHourStrategyDialog', e);
    },
  },
  template: `<el-button 
                type="text" 
                size="mini" 
                :class="selection" 
                v-on:click="showDialog({
                    visible: true, 
                    rowIndex: params.rowIndex, 
                    columnProp: params.column.colId 
                })">{{selection}}</el-button>`,

});
