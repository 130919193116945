import utils from '@/utils';
import dateStore from '@/utils/dateStore';
import { clone, cloneDeep, isEqual } from '@/utils/dataUtil';
import { ETAG_API, STRUCTURES_API } from '@/api';
import * as tagUtils from '@/utils/etag/tagUtils';
import moment from 'moment';
import generationStore from './generationStore';
import loadStore from './loadStore';
import transmissionStore from './transmissionStore';
import sampleTag from '../../sampleTag';
import registryStore from '../../registryStore';
import tagStore from './tagStore';
import profileStore from './profileStore';
import templateStore from './templateStore';
import requestDetailsStore from './requestDetailsStore';
import miscInfoStore from './miscInfoStore';
import contractListStore from './contractListStore';
import tagIDListStore from './tagIDListStore';
import holdTagStore from './holdTagStore';
import variantSummaryStore from '../../VariantSummary/store/index';
import actionStore from './actionStore';
import lossAccountingStore from './lossAccountingStore';
import computeStore from './computeStore';
import { MODES, COMMANDS } from './constants';

function stateToModel(state) {
  const model = tagStore.getters.getTagModel(state.tag, state.registry);
  const segmentList = tagUtils.createSegmentList(
    generationStore.getters.getData(state.generation)[0],
    transmissionStore.getters.getData(state.transmission),
    loadStore.getters.getData(state.load)[0],
  );
  const profileMapping = profileStore.getters.getProfileMappingModels(state.profile);
  const profileSetList = tagUtils.associateSegmentToProfile(segmentList, profileMapping);
  model.segmentList = segmentList;
  model.profileSetList = profileSetList;

  return model;
}

function correctionRequestModel(state) {
  const model = tagStore.getters.getCorrectionRequestData(state.tag);
  const segmentList = tagUtils.createSegmentList(
    generationStore.getters.getData(state.generation)[0],
    transmissionStore.getters.getData(state.transmission),
    loadStore.getters.getData(state.load)[0],
  );
  const profileMapping = profileStore.getters.getProfileMappingModels(state.profile);
  const profileSetList = tagUtils.associateSegmentToProfile(segmentList, profileMapping);
  model.segmentList = segmentList;
  return model;
}

function terminationRequestModel(state) {
  const request = tagStore.getters.getTerminationRequestData(state.tag);
  const model = {
    tagID: request.tagID,
    contactInfo: request.contactInfo,
    notes: request.notes,
    rampDuration: request.rampDuration, // leave empty
    absoluteStart: request.absoluteStart,
  };
  return model;
}

function profileChangeRequestModel(state) {
  const tz = dateStore.getTimeZone();
  const request = tagStore.getters.getProfileChangeRequestData(state.tag);
  const segmentList = tagUtils.createSegmentList(
    generationStore.getters.getData(state.generation)[0],
    transmissionStore.getters.getData(state.transmission),
    loadStore.getters.getData(state.load)[0],
  );
  const profileSetList = tagUtils.associateSegmentToProfile(segmentList, profileStore.getters.getProfileMappingModels(state.profile));
  const model = {
    timeZone: tz,
    segmentList,
    profileSetList,
    ...request,
  };
  return model;
}

function templateRequestModel(state) {
  const model = tagStore.getters.getTagModel(state.tag, state.registry);
  model.segmentList = tagUtils.createSegmentList(
    generationStore.getters.getData(state.generation)[0],
    transmissionStore.getters.getData(state.transmission),
    loadStore.getters.getData(state.load)[0],
  );

  return model;
}

function forceProfileChangeRequestModel(state, profiles) {
  const request = tagStore.getters.getProfileChangeRequestData(state.tag);
  const segmentList = tagUtils.createSegmentList(
    generationStore.getters.getData(state.generation)[0],
    transmissionStore.getters.getData(state.transmission),
    loadStore.getters.getData(state.load)[0],
  );
  // let profileSetList = tagUtils.associateSegmentToProfile(segmentList, profileStore.getters.getProfileMappingModels(state.profile));
  const forcedProfileSetList = [];
  // for now we only care about energy
  for (let i = 0; i < profiles.length; i++) {
    const prof = profiles[i];
    forcedProfileSetList.push({
      id: 1,
      rampStart: null,
      rampStop: null,
      profileProductType: 'ENERGY',
      profileType: 'MARKETLEVEL',
      mw: prof.source,
      start: prof.momentStartTime.utc().format(),
      stop: prof.momentEndTime.utc().format(),
    });
  }
  const model = {
    segmentList,
    profileSetList: forcedProfileSetList,
    contactInfo: request.contactInfo,
    notes: request.notes,
  };
  return model;
}

function getSegmentMiscInfo(segmentState, segmentName, segmentId, miscSegment) {
  return segmentState.reduce((acc, curr) => {
    if (Array.isArray(curr[segmentName])) {
      curr[segmentName].forEach((data) => {
        const segmentData = { id: curr[segmentId] };
        if (miscSegment) segmentData.segment = miscSegment;
        if (typeof (data) === 'string') {
          segmentData.value = data;
        } else {
          segmentData.value = data.value;
          segmentData.token = data.token;
        }
        acc.push(segmentData);
      });
    }
    return acc;
  }, []);
}

export default {
  namespaced: true,
  state: {
    mode: MODES.NA,
    developmentFlag: false,
    pathReadonly: true,
    profileReadonly: true,
    allocationsReadonly: true,
    miscInfoReadOnly: true,
    printViewFlag: false,
    generation: generationStore.state,
    transmission: transmissionStore.state,
    load: loadStore.state,
    tag: tagStore.state,
    profile: profileStore.state,
    template: templateStore.state,
    miscInfo: miscInfoStore.state,
    holdTag: holdTagStore.state,
    statusTable: requestDetailsStore.state,
    variantSummary: variantSummaryStore.state,
    copyStartDate: null,
    lossAccountingVisibilityFlag: false,
    lossAccountingLabel: 'L.A.',
    lossAccountingColSpan: 0,
    marketPathColSpan: 2,
    physicalPathColSpan: 6,
    allocationsColSpan: 4,
    lossAccountingVisibilityStyle: 'display: none',
    compute: computeStore.state,
    showCopyDialog: false,
    showProfileErrorPopup: false,
    profileErrorMessage: '',
    editButtonText: 'Edit',
    termTypes: [],
    addRangeRecordDialogVisibility: false,
  },
  modules: {
    actions: actionStore,
    registry: registryStore,
    tagIDList: tagIDListStore,
    contractList: contractListStore,
    lossAccounting: lossAccountingStore,
  },
  getters: {
    getMode: (state) => state.mode,
    getDevelopmentFlag: (state) => (process.env.NODE_ENV === 'production' ? false : state.developmentFlag),
    getPathReadOnlyFlag: (state) => state.pathReadonly,
    getProfileReadOnlyFlag: (state) => state.profileReadonly,
    getPrintViewFlag: (state) => state.printViewFlag,
    getCopyStartDate: (state) => state.copyStartDate,
    getShowCopyDialog: (state) => state.showCopyDialog,
    getShowProfileErrorPopup: (state) => state.showProfileErrorPopup,
    getProfileErrorMessage: (state) => state.profileErrorMessage,
    getLossAccountingVisibilityFlag: (state) => state.lossAccountingVisibilityFlag,
    getLossAccountingLabel: (state) => state.lossAccountingLabel,
    getLossAccountingVisibilityStyle: (state) => state.lossAccountingVisibilityStyle,
    getAllocationsColSpan: (state) => state.allocationsColSpan,
    getPhysicalPathColSpan: (state) => state.physicalPathColSpan,
    getLossAccountingColSpan: (state) => state.lossAccountingColSpan,
    getMarketPathColSpan: (state) => state.marketPathColSpan,

    getGenerationMarketPathTableConfiguration: (state) => generationStore.getters.getMarketPathTableConfiguration(state.generation, state.pathReadonly, state.exclusionList),
    getGenerationPhysicalPathTableConfiguration: (state) => generationStore.getters.getPhysicalPathTableConfiguration(state.generation, state.pathReadonly, state.exclusionList),
    getGenerationAllocationPathTableConfiguration: (state) => generationStore.getters.getAllocationPathTableConfiguration(state.generation, state.pathReadonly, state.exclusionList),
    getGenerationData: (state) => generationStore.getters.getData(state.generation),

    getTransmissionMarketPathTableConfiguration: (state) => transmissionStore.getters.getMarketPathTableConfiguration(state.transmission, state.pathReadonly, state.exclusionList),
    getTransmissionPhysicalPathTableConfiguration: (state) => transmissionStore.getters.getPhysicalPathTableConfiguration(state.transmission, state.pathReadonly, state.exclusionList),
    getTransmissionAllocationPathTableConfiguration: (state) => transmissionStore.getters.getAllocationPathTableConfiguration(state.transmission, state.pathReadonly, !state.allocationsReadonly, state.exclusionList),
    getTransmissionLossTableConfiguration: (state) => transmissionStore.getters.getLossAccountingTableConfiguration(state.transmission, state.pathReadonly, state.exclusionList),
    getTransmissionData: (state) => transmissionStore.getters.getData(state.transmission),
    getMarketMiscInfo: (state) => [
      ...getSegmentMiscInfo(state.generation.generation, 'market_miscInfoList', 'market_id', 'Generation'),
      ...getSegmentMiscInfo(state.load.load, 'market_miscInfoList', 'market_id', 'Load'),
    ],
    getMarketContractList: (state) => [
      ...getSegmentMiscInfo(state.generation.generation, 'market_contractList', 'market_id', 'Generation'),
      ...getSegmentMiscInfo(state.transmission.transmissions, 'market_contractList', 'market_id', 'Transmission'),
      ...getSegmentMiscInfo(state.load.load, 'market_contractList', 'market_id', 'Load'),
    ],
    getPhysicalMiscInfo: (state) => [
      ...getSegmentMiscInfo(state.generation.generation, 'physical_miscInfoList', 'physical_id', 'Generation'),
      ...getSegmentMiscInfo(state.transmission.transmissions, 'physical_miscInfoList', 'physical_id', 'Transmission'),
      ...getSegmentMiscInfo(state.load.load, 'physical_miscInfoList', 'physical_id', 'Load'),
    ],
    getAllLossAccountingData: (state) => transmissionStore.getters.getAllLossAccouting(state.transmission),
    getAllLossMiscInfo: (state) => transmissionStore.getters.getAllLossMisc(state.transmission),
    getAllocationMiscInfo: (state) => getSegmentMiscInfo(state.transmission.transmissions, 'trans_miscInfoList', 'trans_num', 'transmission'),

    // used to dynamically determine the height of the ETAG profile table on the first page of a print.
    getPrintTableOffset: (state, getters) => {
      let offset = transmissionStore.getters.getData(state.transmission).length;
      if (getters.getMarketMiscInfo.length) offset += (5 + getters.getMarketMiscInfo.length);
      if (getters.getMarketContractList.length) offset += (5 + getters.getMarketContractList.length);
      if (getters.getPhysicalMiscInfo.length) offset += (5 + getters.getPhysicalMiscInfo.length);
      if (getters.getAllLossAccountingData.length) offset += (5 + getters.getAllLossAccountingData.length);
      if (getters.getAllLossMiscInfo.length) offset += (5 + getters.getAllLossMiscInfo.length);
      if (getters.getAllocationMiscInfo.length) offset += (5 + getters.getAllocationMiscInfo.length);
      return offset;
    },

    getLoadMarketPathTableConfiguration: (state) => loadStore.getters.getMarketPathTableConfiguration(state.load, state.pathReadonly, state.exclusionList),
    getLoadPhysicalPathTableConfiguration: (state) => loadStore.getters.getPhysicalPathTableConfiguration(state.load, state.pathReadonly, state.exclusionList),
    getLoadAllocationPathTableConfiguration: (state) => loadStore.getters.getAllocationPathTableConfiguration(state.load, state.pathReadonly, state.exclusionList),
    getLoadData: (state) => loadStore.getters.getData(state.load, state.pathReadonly),

    getTagCodePhonic: (state) => tagStore.getters.getTagCodePhonic(state.tag),
    getTagID: (state) => tagStore.getters.getTagID(state.tag),
    getDisplayTagName: (state) => tagStore.getters.getDisplayTagName(state.tag),
    getTagName: (state) => tagStore.getters.getDisplayTagName(state.tag),
    getTagSummaryRef: (state) => tagStore.getters.getTagSummaryRef(state.tag),

    getCPSEList: (state) => tagStore.getters.getCPSEList(state.tag),
    getCPSE: (state) => tagStore.getters.getCPSE(state.tag, state.pathReadonly),
    getCPSEName: (state) => tagStore.getters.getCPSEName(state.tag),
    getGCAName: (state) => tagStore.getters.getGCAName(state.tag),
    getLCAName: (state) => tagStore.getters.getLCAName(state.tag),
    getTagCode: (state) => tagStore.getters.getTagCode(state.tag),
    getTestFlag: (state) => tagStore.getters.getTestFlag(state.tag),
    getNotes: (state) => tagStore.getters.getNotes(state.tag),
    getMessageNotes: (state) => tagStore.getters.getMessageNotes(state.tag),
    getTransactionType: (state) => tagStore.getters.getTransactionType(state.tag),
    getTransactionTypeList: (state) => tagStore.getters.getTransactionTypeList(state.tag),
    getVersionVisibility: (state) => state.mode === MODES.VIEW_TAG || state.mode === MODES.EDIT_TAG,
    getProfileChartVisibility: (state) => state.mode === MODES.VIEW_TAG || state.mode === MODES.EDIT_TAG,
    getVersion: (state) => tagStore.getters.getVersion(state.tag),
    getShowCorrectionDialog: (state) => tagStore.getters.getShowCorrectionDialog(state.tag),
    getVersionList: (state) => tagStore.getters.getVersionList(state.tag),
    getStatus: (state) => tagStore.getters.getStatus(state.tag),
    getCCMultipleSelectList: (state) => tagStore.getters.getCCMultipleSelectList(state.tag),
    getCCDisplayList: (state) => tagStore.getters.getCCDisplayList(state.tag),
    getCompositeState: (state) => tagStore.getters.getCompositeState(state.tag),
    getTagCodeEnabledFlag: (state) => tagStore.getters.getTagCodeEnabledFlag(state.tag),
    getTagBusyFlag: (state) => tagStore.getters.getBusyFlag(state.tag),
    getProfileVisibilityFlag: (state) => state.mode === MODES.EDIT_TAG || state.mode === MODES.VIEW_TAG
      || state.mode === MODES.NEW_TAG || state.mode === MODES.VARIANT_TAG,
    getPreviousVersionButtonVisible: (state) => ((state.mode === MODES.VIEW_TAG || state.mode === MODES.EDIT_TAG)
      && (state.profile.profileViewName === 'tag-profile-daily'
      || state.profile.profileViewName === 'tag-profile-consolidated')),
    getProfileTableConfiguration: (state) => profileStore.getters
      .getTableConfiguration(state.profile, state.profileReadonly),
    getProfileData: (state) => profileStore.getters
      .getData(state.profile, state.profileReadonly, state.transmission.transmissions),
    getTermInputTable: (state) => profileStore.getters.getTermInputTable(),
    getProfileSummaryData: (state) => profileStore.getters.getSummaryData(state.profile),
    getProfileConsolidatedSummaryData: (state) => profileStore.getters.getConsolidatedSummaryData(state.profile),
    getProfileViewName: (state) => profileStore.getters.getViewName(state.profile),
    getProfileViewIndex: (state) => profileStore.getters.getViewIndex(state.profile),
    getProfileDailyDate: (state) => profileStore.getters.getDailyDate(state.profile),
    getProfileDateRangeDisplay: (state) => profileStore.getters.getTimeRangeDisplay(state.profile),
    getProfileStartDate: (state) => profileStore.getters.getStartDate(state.profile),
    getProfileEndDate: (state) => profileStore.getters.getEndDate(state.profile),
    getTimeGranularity: (state) => profileStore.getters.getTimeGraunalrity(state.profile),
    getTimeGranularityList: (state) => profileStore.getters.getTimeGranularityList(state.profile),
    getProfileGraphData: (state) => profileStore.getters.getProfileGraphData(state.profile),
    getProfileDateRange: (state) => profileStore.getters.getProfileDateRange(state.profile),
    getProfileSeriesOptions: (state) => profileStore.getters.getProfileSeriesOptions(state.profile),
    getComparePreviousFlag: (state) => profileStore.getters.getComparePreviousFlag(state.profile),
    getModelProfileSet: (state) => profileStore.getters.getModelProfileSet(state.profile),
    getTemplateCPSEList: (state) => templateStore.getters.getTemplateCPSEList(state.template),
    getTemplateCPSE: (state) => templateStore.getters.getTemplateCPSE(state.template),
    getTemplateName: (state) => templateStore.getters.getTemplateName(state.template),
    getTemplateNameEditableFlag: (state) => state.mode === MODES.EDIT_TEMPLATE || state.mode === MODES.NEW_TEMPLATE,
    getTemplateDescriptionEditableFlag: (state) => state.mode === MODES.EDIT_TEMPLATE
    || state.mode === MODES.NEW_TEMPLATE,
    getTemplateDescription: (state) => templateStore.getters.getTemplateDescription(state.template),
    getTemplateVisibility: (state) => templateStore.getters.getTemplateVisibility(state.template),

    getMiscInfoDialogVisibility: (state) => miscInfoStore.getters.getVisibility(state.miscInfo),
    getMiscInfoTableConfiguration: (state) => miscInfoStore.getters
      .getTableConfiguration(state.miscInfo, state.miscInfoReadOnly),
    getMiscInfoData: (state) => miscInfoStore.getters.getData(state.miscInfo),
    getCustomUserMiscInfoList: (state) => miscInfoStore.getters.getCustomUserMiscInfoList(state.miscInfo),
    getCustomUserMiscInfoSelection: (state) => miscInfoStore.getters.getCustomUserMiscInfoSelection(state.miscInfo),
    getCustomMiscInfoNameField: (state) => miscInfoStore.getters.getCustomMiscInfoNameField(state.miscInfo),
    getMiscInfoReadOnlyFlag: (state) => miscInfoStore.getters.getMiscInfoReadOnlyFlag(state),

    getUpdateTemplateVisibilityFlag: (state) => state.mode === MODES.EDIT_TEMPLATE,
    getTagCodeVisibility: (state) => state.mode === MODES.VIEW_TAG
    || state.mode === MODES.EDIT_TAG || state.mode === MODES.NEW_TAG || state.mode === MODES.VARIANT_TAG,
    getCCOptions: (state) => registryStore.getters.getCCOptionsList(state.registry),
    getRequestDetailsVisibility: (state) => state.mode === MODES.VIEW_TAG || state.mode === MODES.EDIT_TAG,
    getStatusTableConfiguration: (state) => requestDetailsStore.getters.getTableConfiguration(state.statusTable),
    getStatusTableData: (state) => requestDetailsStore.getters.getTableData(state.statusTable),
    getStatusTableName: (state) => requestDetailsStore.getters.getTableName(state.statusTable),
    getStatusDialogVisible: (state) => requestDetailsStore.getters.getStatusDialogVisible(state.statusTable),
    getRequestor: (state) => requestDetailsStore.getters.getRequestor(state.statusTable),

    getStatusTableRefreshRate: (state) => requestDetailsStore.getters.getRefreshRate(state.statusTable),
    getStatusTableMinRefreshRate: (state) => requestDetailsStore.getters.getMinRefreshRate(state.statusTable),
    getStatusTableMaxRefreshRate: (state) => requestDetailsStore.getters.getMaxRefreshRate(state.statusTable),

    getHoldDescription: (state) => holdTagStore.getters.getDescription(state.holdTag),
    getHoldName: (state) => holdTagStore.getters.getName(state.holdTag),
    getHoldVisibility: (state) => holdTagStore.getters.getVisibility(state.holdTag),

    getShowTerminateDialog: (state) => tagStore.getters.getShowTerminateDialog(state.tag),
    getTerminationDateTime: (state) => tagStore.getters.getTerminationDateTime(state.tag),
    getSelectedTerminationDateTime: (state) => tagStore.getters.getSelectedTerminationDateTime(state.tag),
    getTerminationNotes: (state) => tagStore.getters.getTerminationNotes(state.tag),
    getImplementationDateTime: (state) => tagStore.getters.getImplementationDateTime(state.tag),
    getRampDuration: (state) => tagStore.getters.getRampDuration(state.tag),
    getRampDurationVisible: (state) => tagStore.getters.getRampDurationVisible(state.tag),
    getTerminateDialogTitle: (state) => tagStore.getters.getTerminateDialogTitle(state.tag),
    getTagIDSecurityKey: (state) => tagStore.getters.getTagIDSecurityKey(state.tag),
    getTerminationDates(state) {
      const zone = dateStore.getTimeZone();
      const dtend = tagStore.getters.getTerminationDateTime(state.tag);
      const dtstart = tagStore.getters.getImplementationDateTime(state.tag);

      const startDate = dateStore.toMomentAndZoneFromJSDate(dtstart, zone).toObject();
      const endDate = dateStore.toMomentAndZoneFromJSDate(dtend, zone).toObject();

      const item = {
        start: new Date(startDate.years, startDate.months, startDate.date),
        end: new Date(endDate.years, endDate.months, endDate.date),
      };

      return item;
    },
    getNameValidator(state) {
      const name = templateStore.getters.getTemplateName(state.template);
      if (name && name.match(/[^A-z0-9- ]/g)) {
        return { isValid: false, message: 'Name can only contain Letters, Numbers, and -' };
      }
      return { isValid: true, message: '' };
    },
    getDescriptionValidator(state) {
      const description = templateStore.getters.getTemplateDescription(state.template);
      if (description && description.match(/[^A-z0-9- ]/g)) {
        return { isValid: false, message: 'Description can only contain Letters, Numbers, and -' };
      }
      return { isValid: true, message: '' };
    },
    getShowTerminationDSTFlag: (state) => tagStore.getters.getShowTerminationDSTFlag(state.tag),
    getTerminationDSTFlag: (state) => tagStore.getters.getTerminationDSTFlag(state.tag),
    getShowProfileDialog: (state) => tagStore.getters.getShowProfileDialog(state.tag),
    getSubmitDialogVisibility: (state) => tagStore.getters.getSubmitDialogVisibility(state.tag),
    getSubmitItem: (state) => tagStore.getters.getSubmitItem(state.tag),
    getProfileChangeNotes: (state) => tagStore.getters.getProfileChangeNotes(state.tag),
    getCorrectionNotes: (state) => tagStore.getters.getCorrectionNotes(state.tag),
    getEditButtonText: (state) => state.editButtonText,
    getComputeFlag: (state) => computeStore.getters.getComputeFlag(state.compute),
    getComputeResultsVisibleFlag: (state) => computeStore.getters.getComputeResultsVisibleFlag(state.compute),
    getComputeResultsTableConfiguration: (state) => computeStore.getters
      .getComputeResultsTableConfiguration(state.compute),
    getComputeResultsTableData: (state) => computeStore.getters.getComputeResultsTableData(state.compute),
    getComputeResultsTableSummaryData: (state) => computeStore.getters.getComputeResultsTableSummaryData(state.compute),
    getComputeVisibilityFlag: (state) => computeStore.getters.getComputeVisibilityFlag(state.compute),

    getCurrentRequestID: (state) => state.tag.currentVersion_requestID !== null
      ? state.tag.currentVersion_requestID : 0,
    getCurrentRequestVersion: (state) => tagStore.getters.getCurrentRequestVersion(state.tag),
    getActOnByTime: (state) => requestDetailsStore.getters.getActOnByTime(state.statusTable),
    getApprovalNotes: (state) => requestDetailsStore.getters.getApprovalNotes(state.statusTable),
    getApprovalVisibleFlag: (state) => requestDetailsStore.getters.getApprovalVisibleFlag(state.statusTable),
    getApprovalNotesRequiredFlag: (state) => requestDetailsStore.getters
      .getApprovalNotesRequiredFlag(state.statusTable),
    getApprovalState: (state) => requestDetailsStore.getters.getApprovalState(state.statusTable),
    getApprovalStateList: (state) => requestDetailsStore.getters.getApprovalStateOptionList(state.statusTable),
    getApprovalSubmitFlag: (state) => requestDetailsStore.getters.getApprovalSubmitFlag(state.statusTable),
    getApprovalSubmitText: (state) => requestDetailsStore.getters.getApprovalSubmitText(state.statusTable),
    getShowForceProfileDialog: (state) => tagStore.getters.getShowForceProfileDialog(state.tag),
    getForceProfileTableConfiguration: (state) => profileStore.getters.getForceProfileTableConfiguration(state.profile),
    getForceProfileData(state, getters) {
      if (getters.getShowForceProfileDialog) { return profileStore.getters.getForceProfileData(state.profile); }

      return [];
    },
    getReadonlyFlag: (state) => tagStore.getters.getReadonlyFlag(state.tag),
    getLoadMarketSegmentID: (state) => loadStore.getters.getLoadMarketSegmentID(state.load),
    getCorrectionRequestModel: (state) => correctionRequestModel(state),
    getProfileChangeRequestModel: (state) => profileChangeRequestModel(state),
    getTemplateRequestModel: (state) => templateRequestModel(state),
    getRouteTagID: (state) => state.tag.routeTagID,
    getRouteEntity: (state) => state.tag.routeEntity,
    getTagModel: (state) => stateToModel(state),
    getAddRangeRecordDialogVisibility: (state) => state.addRangeRecordDialogVisibility,
    getCurrentVersionRequestID: (state) => tagStore.getters.getCurrentVersionRequestID(state.tag),
    getProfileColumns(state) {
      const gen = generationStore.getters.cloneData(state.generation);
      const tran = transmissionStore.getters.cloneData(state.transmission);
      const load = loadStore.getters.cloneData(state.load);
      const data = [...gen, ...tran, ...load];
      const profileIDs = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].trans_contract == null) {
          continue;
        } else if (data[i].type === 'GEN') {
          const sourceLabel = data[i].physical_source_name;
          profileIDs.push({ value: 'ENERGY-1', label: sourceLabel });
        } else if (data[i].type === 'TRAN') {
          const transLabel = `${data[i].trans_contract} (${data[i].physical_por_name} -> ${data[i].physical_pod_name})`;
          profileIDs.push({ value: `CAPACITY-${data[i].trans_id}`, label: transLabel });
        }
      }

      return profileIDs;
    },
  },
  actions: {
    initialize({
      commit, state, dispatch, rootGetters,
    }, context) {
      dispatch('miscStoreInitialize');
      dispatch('reset');
      dispatch('clearPreviousTagProfile');
      dispatch('changeMode', context.mode);
      commit('setUserProfile', rootGetters['auth/profile']);
      // dispatch('fetchComputeConfiguration');
      state.holdTag.isLoaded = false;
      if (context.mode === MODES.VIEW_TAG || context.mode === MODES.EDIT_TAG) {
        tagStore.actions.loadTagVersions(
          { commit, dispatch },
          {
            tagSummaryRef: context.tagSummaryRef, tagID: context.tagID, entity: context.entity, requestID: context.requestID,
          });
        if (context.editOnLoad) {
          state.editOnLoad = context.editOnLoad;
        } else {
          state.editOnLoad = false;
        }
      } else if (context.mode === MODES.NEW_TAG) {
        if (context.name && context.name !== '') {
          commit('setVariantType', context.variant);
          if (context.variant === 'HOLD') dispatch('loadHoldTag', context);
          else dispatch('loadTemplateTag', context);
        } else if (context.tagID && context.tagID !== '') {
          dispatch('loadCopyTag', {
            tagID: context.tagID,
            entity: context.entity,
            requestID: context.requestID,
            startTime: dateStore.toDateFromLocal(context.startTime),
            endTime: dateStore.toDateFromLocal(context.endTime),
            offset: context.offset,
            pse: context.pse,
            tagSummaryRef: context.tagSummaryRef,
          });
        } else if (context.wfId && context.wfId !== '') {
          commit('setVariantType', context.variant);
          if (context.variant === 'REQUESTS') {
            dispatch('loadRequestTag', { wfId: context.wfId, router: context.router });
          }
        }
      } else if (context.mode === MODES.VARIANT_TAG) {
        commit('setVariantType', context.variant);
        dispatch('loadHoldTag', { name: context.name, router: context.router });
      } else if (context.mode === MODES.VIEW_TEMPLATE) {
        dispatch('loadTemplateTag', context);
      }
      if (context.mode === MODES.NEW_TAG) {
        let date = null;
        if (!context.startTime) {
          date = utils.date.toMomentFromDate(
            utils.date.getTomorrowLocal(),
            dateStore.getTimeZoneDefinition().tz).startOf('day').format();
        } else {
          date = utils.date.toMomentFromDate(
            context.startTime,
            dateStore.getTimeZoneDefinition().tz).startOf('day').format();
        }
        dispatch('loadProductHours', date);
      }
    },
    changeCopyStartDate({ commit }, value) {
      commit('setCopyStartDate', value);
    },
    reset({ commit, dispatch }) {
      commit('reset');
      dispatch('changeProfileColumns');
    },
    loadSampleTag({ dispatch }) {
      dispatch('loadTagModel', sampleTag);
    },
    resetDailyProfileData({ dispatch, state }) {
      dispatch('changeDailyProfile');
    },
    fetchTermTypes({ commit }) {
      STRUCTURES_API.get('/term-types').then(({ data }) => {
        commit('setTermTypes', data.termTypes);
      }).catch((err) => {
        this.$notify('Failed to Load Term Types', 'error');
      });
    },
    fetchTermDefinition({ commit }, termId) {
      return new Promise((resolve) => {
        STRUCTURES_API.get(`/term-type-definitions/term/${termId}`).then(({ data }) => {
          resolve(data.termTypeDefinitions);
        }).catch((err) => {
          this.$notify('Failed to Load Term Types', 'error');
        });
      });
    },
    loadTagModel({ commit, dispatch, state }, model) {
      commit('loadTagPath', model);
      dispatch('calculateMarketSegmentIDs');
      dispatch('changeProfileColumns');
      commit('loadTagProfile', model);
      if (state.mode !== MODES.VIEW_TEMPLATE) {
        dispatch('loadInitialDailyProfileDate', model);
      }
      if (state.mode === MODES.NEW_TAG) {
        if (!state.tag.tagCode) tagStore.actions.newTagCode({ commit, dispatch }, state.tag);
      }

      if (state.editOnLoad) {
        dispatch('edit');
      }
    },
    changeLossAccountingVisibilityFlag({ commit }) {
      commit('setLossAccountingVisibilityFlag');
    },
    ChangeEditButtonText({ commit, state }, value) {
      if (value === 'Edit') {
        state.exclusionList = [];
        commit('setProfileReadOnlyFlag', true);
        commit('setPathReadOnlyFlag', true);
        commit('setMiscInfoReadOnlyFlag', true);
      }
      commit('setEditButtonText', value);
    },
    changeCCMultipleSelection: tagStore.actions.changeCCMultipleSelection,
    loadCopyTag: tagStore.actions.loadCopyTag,
    loadTemplateTag: templateStore.actions.loadTagTemplate,
    loadHoldTag: ({ dispatch, commit, state }, context) => holdTagStore.actions
      .loadVariantTag({ dispatch, commit }, state.holdTag, context.name, context.router),
    loadRequestTag: ({ dispatch, commit, state }, context) => holdTagStore.actions
      .loadRequestTag({ dispatch, commit }, state.holdTag, context.wfId, context.router),
    // changeTagCode: tagStore.actions.changeTagCode,
    newTagCode: ({ commit, dispatch, state }) => {
      commit('setTagCodecss');
      tagStore.actions.newTagCode({ commit, dispatch }, state.tag);
    },
    async changeMode({ commit, dispatch, state }, mode) {
      const currentMode = state.mode;
      await commit('setMode', mode);

      if (mode === MODES.NEW_TAG) {
        dispatch('fetchTermTypes');
        const localDate = dateStore.getDefaultDateLocal();
        const mdt = dateStore.toDateFromLocal(localDate);
        dispatch('changeDailyProfileDate', localDate.format());
        const endTime = mdt.clone().add(1, 'days');

        dispatch('changeProfileDateRange', [mdt.toDate(), endTime.toDate()]);
      }

      if (mode === MODES.NEW_TAG || mode === MODES.NEW_TEMPLATE || mode === MODES.VARIANT_TAG) {
        commit('setPathReadOnlyFlag', false);
        commit('setMiscInfoReadOnlyFlag', false);
        commit('setAllocationReadOnlyFlag', false);
        commit('clearTemplate');
      } else {
        commit('setPathReadOnlyFlag', false);
        state.exclusionList = [''];
        commit('setPathReadOnlyFlag', true);
        commit('setMiscInfoReadOnlyFlag', true);
        commit('setAllocationReadOnlyFlag', true);
      }

      if (mode === MODES.NEW_TAG || mode === MODES.VARIANT_TAG) {
        commit('setProfileReadOnlyFlag', false);
      } else {
        commit('setProfileReadOnlyFlag', true);
      }
      if (mode === MODES.EDIT_TEMPLATE) {
        commit('setPathReadOnlyFlag', false);
        commit('setMiscInfoReadOnlyFlag', false);
        commit('setAllocationReadOnlyFlag', false);
        commit('setTransmissionsDirty');
      }

      if (mode === MODES.EDIT_TAG) {
        const selectedVersion = state.tag.versionList.find((v) => v.value === state.tag.currentVersion);
        if (currentMode === MODES.VIEW_TAG
          && (selectedVersion && (selectedVersion.lastApproved || selectedVersion.requestStatus === 'PENDING'))) {
          if (state.tag.compositeState === 'PENDING') {
            commit('setPathReadOnlyFlag', false);
            commit('setMiscInfoReadOnlyFlag', false);
            commit('setTransmissionsDirty');
            state.exclusionList = [
              'market_product',
              'market_product_name',
              'trans_contract',
              'physical_por',
              'physical_pod',
              'trans_product',
              'trans_product_name',
              'trans_tc',
              'trans_nits',
              'loss_type',
              'loss_start',
              'loss_stop',
              'loss_tagID',
            ];
            commit('setPathReadOnlyFlag', true);
          } else {
            commit('setProfileReadOnlyFlag', false);
            commit('setAllocationReadOnlyFlag', false);
          }
        }
      }
    },
    stopEditingTag({ dispatch, state }, router) {
      if (state.mode === MODES.EDIT_TAG) {
        dispatch('loadTagVersion');
        dispatch('changeMode', MODES.VIEW_TAG);
      }
    },
    edit({ dispatch, state, commit }) {
      if (state.mode === MODES.VIEW_TAG) {
        dispatch('changeMode', MODES.EDIT_TAG);
      } else if (state.mode === MODES.VIEW_TEMPLATE) { dispatch('changeMode', MODES.EDIT_TEMPLATE); }
      // if(state.editButtonText === 'Submit'){
      //     if(state.tag.compositeState === 'PENDING'){
      //         dispatch('correctionRequest');
      //     }
      //     else{
      //         dispatch('profileChangeRequest');
      //     }
      // }
      // else{
      //     const selectedVersion =  state.tag.versionList.find(function(v){
      //         return v.value == state.tag.currentVersion;
      //     });

      //     if(state.mode === MODES.VIEW_TEMPLATE){
      //         dispatch('changeMode', MODES.EDIT_TEMPLATE);
      //     }

      //     else if(state.mode ===  MODES.VIEW_TAG && (selectedVersion && (selectedVersion.lastApproved
      //         || selectedVersion.requestStatus == 'PENDING'))){
      //         if(state.tag.compositeState === 'PENDING'){
      //             commit('setPathReadOnlyFlag', false);
      //             commit('setMiscInfoReadOnlyFlag', false);
      //             commit('setTransmissionsDirty');
      //             state.exclusionList = [
      //                 'market_product',
      //                 'market_product_name',
      //                 'trans_contract',
      //                 'physical_por',
      //                 'physical_pod',
      //                 'trans_product',
      //                 'trans_tc',
      //                 'trans_nits',
      //                 'loss_type',
      //                 'loss_start',
      //                 'loss_stop',
      //                 'loss_tagID'
      //                 ];
      //             commit('setPathReadOnlyFlag', true);
      //         }
      //         else{

      //             commit('setProfileReadOnlyFlag', false);
      //             commit('setAllocationReadOnlyFlag', false);
      //         }
      //         state.editButtonText = 'Submit';
      //     }
      // }
    },
    useTemplate({ state }, router) {
      router.push(
        {
          name: 'NewTagFromTemplate',
          params: { templateName: state.template.templateName, variant: 'TEMPLATE' },
        });
    },
    submit({ dispatch, state, commit }, command) {
      if (command.validate === false) {
        const compositeState = tagStore.getters.getCompositeState(state.tag);
        if (compositeState === 'PENDING') {
          tagStore.mutations.setCorrectionDialogVisiblity(state.tag, true);
        } else if (compositeState !== '') {
          tagStore.mutations.setProfileDialogVisibility(state.tag, true);
        } else {
          tagStore.actions.changeSubmitDialogVisibility({ commit }, true);
        }
      } else {
        dispatch('validateTag', command);
      }
    },
    validateTag({ dispatch, commit, state }, item) {
      let valid = true;
      const tag = cloneDeep(state);
      if (document.getElementById('cpse')) {
        const cpseElement = document.getElementById('cpse').getElementsByClassName('el-input__inner')[0];
        if (tag.tag.pse === null) {
          cpseElement.style.borderColor = 'red';
          cpseElement.style.borderWidth = '2px';
          cpseElement.title = 'CPSE is required';
          valid = false;
        }
      }

      if (item.command !== COMMANDS.CREATE_TEMPLATE) {
        if (document.getElementById('tagCode')) {
          const tagCodeElement = document.getElementById('tagCode').getElementsByClassName('el-input__inner')[0];
          if (tag.tag.tagCode === null || tag.tag.tagCode.length !== 7) {
            tagCodeElement.style.borderColor = 'red';
            tagCodeElement.style.borderWidth = '2px';
            if (tag.tag.tagCode === null || tag.tag.tagCode.length === 0) {
              tagCodeElement.title = 'TagCode is required';
            } else {
              tagCodeElement.title = 'TagCode must have length 7';
            }
            valid = false;
          }
        }
      }

      if (item.command === COMMANDS.CREATE_TEMPLATE) {
        const nameElement = document?.getElementById('template_name')?.getElementsByClassName('el-input__inner')?.[0];
        if (nameElement) {
          let name = state.template.templateName;
          if (name !== null) {
            name = name.replace(/ +/g, '-');
            if (name.charAt(name.length - 1) === '-') { name = `${name.substr(0, name.length - 1)}`; }
            state.template.templateName = name;
          }

          if (name === null || name === 'null' || name.length === 0) {
            nameElement.style.borderColor = 'red';
            nameElement.style.borderWidth = '2px';
            nameElement.title = 'Name is required';
            valid = false;
          } else if (!/^[a-zA-Z]/.test(name)) {
            nameElement.style.borderColor = 'red';
            nameElement.style.borderWidth = '2px';
            nameElement.title = 'Must begin with a letter';
            valid = false;
          } else if (!/^[0-9a-zA-Z-]+$/.test(name)) {
            nameElement.style.borderColor = 'red';
            nameElement.style.borderWidth = '2px';
            nameElement.title = 'Can only contain letters and numbers';
            valid = false;
          } else {
            nameElement.style = '';
            nameElement.title = '';
          }
        }
      }

      // validate generation
      const gen = tag.generation.generation[0];
      const genErrs = cloneDeep(gen);
      if (gen.market_product_name === null) {
        genErrs.market_product_name = 'ERROR: Source product name is required';
      }
      if (gen.market_pse_name === null) genErrs.market_pse_name = 'ERROR: PSE is required';
      if (gen.physical_gca_name === null) genErrs.physical_gca_name = 'ERROR: GCA is required';
      if (gen.physical_source_name === null) genErrs.physical_source_name = 'ERROR: Source POD is required';

      // validate load
      const load = tag.load.load[0];
      const loadErrs = cloneDeep(load);
      if (load.market_product_name === null) loadErrs.market_product_name = 'ERROR: Sink product name is required';
      if (load.market_pse_name === null) loadErrs.market_pse_name = 'ERROR: PSE is required';
      if (load.physical_lca_name === null) loadErrs.physical_lca_name = 'ERROR: LCA is required';
      if (load.physical_sink_name === null) loadErrs.physical_sink_name = 'ERROR: Sink POR is required';

      // validate transmission
      const trans = tag.transmission.transmissions;
      const transErrs = cloneDeep(trans);
      const transValid = [];
      const check = [];
      for (let t = 0; t < trans.length; t++) {
        if (trans[t].physical_mo_name !== null
          || trans[t].physical_se_name !== null
          || trans[t].physical_tp_name !== null
          || trans[t].physical_por_name !== null
          || trans[t].physical_pod_name !== null
          || trans[t].trans_tc_name !== null
          || (trans[t].trans_contract !== null && trans[t].trans_contract !== '')
          || transErrs[t].trans_product_name !== null) {
          if (trans[t].market_pse_name === null) {
            transErrs[t].market_pse_name = 'ERROR: PSE is required';
            transValid[t] = false;
          }
          if (trans[t].physical_se_name === null) {
            transErrs[t].physical_se_name = 'ERROR: SE is required';
            transValid[t] = false;
          }
          if (trans[t].physical_tp_name === null) {
            transErrs[t].physical_tp_name = 'ERROR: TP is required';
            transValid[t] = false;
          }
          if (trans[t].physical_por_name === null) {
            transErrs[t].physical_por_name = 'ERROR: Transmission POR is required';
            transValid[t] = false;
          }
          if (trans[t].physical_pod_name === null) {
            transErrs[t].physical_pod_name = 'ERROR: Transmission POD is required';
            transValid[t] = false;
          }
          if (trans[t].trans_tc_name === null) {
            transErrs[t].trans_tc_name = 'ERROR: TC is required';
            transValid[t] = false;
          }
          if (trans[t].trans_contract === null || trans[t].trans_contract === '') {
            transErrs[t].trans_contract = 'ERROR: Contract is required';
            transValid[t] = false;
          }
          if (trans[t].trans_product_name === null) {
            transErrs[t].trans_product_name = 'ERROR: Transmission product name is required';
            transValid[t] = false;
          }
          if (transValid[t] !== false) transValid[t] = true;
        }
      }
      for (let t = 0; t < trans.length; t++) {
        if (trans[t].market_pse_name === null
          && trans[t].market_product_name === null
            && trans[t].physical_mo_name === null
            && trans[t].physical_se_name === null
            && trans[t].physical_tp_name === null
            && trans[t].physical_por_name === null
            && trans[t].physical_pod_name === null
            && trans[t].trans_tc_name === null
            && trans[t].trans_contract === null && transErrs[t].trans_product_name === null) {
          if (trans[t].market_pse_name === null) {
            transErrs[t].market_pse_name = 'ERROR: PSE is required';
            check[t] = false;
          }
        }
      }

      if (!transValid.includes(true)) {
        for (let t = 0; t < trans.length; t++) {
          if (check[t] !== false) {
            if (trans[t].market_pse_name === null) transErrs[t].market_pse_name = 'ERROR: PSE is required';
            if (trans[t].physical_se_name === null) transErrs[t].physical_se_name = 'ERROR: SE is required';
            if (trans[t].physical_tp_name === null) transErrs[t].physical_tp_name = 'ERROR: TP is required';
            if (trans[t].physical_por_name === null) {
              transErrs[t].physical_por_name = 'ERROR: Transmission POR is required';
            }
            if (trans[t].physical_pod_name === null) {
              transErrs[t].physical_pod_name = 'ERROR: Transmission POD is required';
            }
            if (trans[t].trans_tc_name === null) transErrs[t].trans_tc_name = 'ERROR: TC is required';
            if (trans[t].trans_contract === null
              || trans[t].trans_contract === '') transErrs[t].trans_contract = 'ERROR: Contract is required';
            if (trans[t].trans_product_name === null) {
              transErrs[t].trans_product_name = 'ERROR: Transmission product name is required';
            }
          }
        }
      }

      tag.generation.generation[0] = gen;
      tag.generation.generationErrors[0] = genErrs;
      tag.load.load[0] = load;
      tag.load.loadErrors[0] = loadErrs;
      tag.transmission.transmissions = trans;
      tag.transmission.transmissionErrors = transErrs;
      if (!isEqual(gen, genErrs) || !isEqual(load, loadErrs) || !isEqual(trans, transErrs)) valid = false;
      commit('validationError', tag);
      if (valid) dispatch('validateProfiles', item);
    },
    validateProfiles({ dispatch, commit, state }, item) {
      if (item.command === COMMANDS.CREATE_TEMPLATE) {
        dispatch('submitCreateTemplate', item.router);
      } else if (item.command === COMMANDS.UPDATE_TEMPLATE) {
        dispatch('submitUpdateTemplate', item.router);
      } else if (item.command === COMMANDS.SAVE_HOLD) {
        holdTagStore.actions.changeHoldVisibility({ dispatch, commit }, item);
      } else {
        let valid = true;
        const model = stateToModel(state);
        if (model.profileSetList.length === 0) {
          const message = 'No profiles set';
          dispatch('setShowProfileErrorPopup', message);
          return;
        }

        const profileData = profileStore.getters.getData(profileStore.state, true, state.transmission.transmissions);
        if (profileData) {
          for (let rowIdx = 0; rowIdx < profileData.length; rowIdx++) {
            const row = profileData[rowIdx];
            if (row.highlight.length > 0) {
              valid = false;
              const message = 'Error in profiles';
              dispatch('setShowProfileErrorPopup', message);
              return;
            }
          }
        }
        if (valid) {
          const compositeState = tagStore.getters.getCompositeState(state.tag);
          if (compositeState === 'PENDING') {
            tagStore.mutations.setCorrectionDialogVisiblity(state.tag, true);
          } else if (compositeState !== '') {
            tagStore.mutations.setProfileDialogVisibility(state.tag, true);
          } else {
            tagStore.actions.changeSubmitDialogVisibility({ commit }, true);
          }
        }
      }
    },
    async submitToAPI({ dispatch, state }, command) {
      if (command.text === 'Submit (ATF)') {
        dispatch('afterTheFactProfileChangeRequest');
      } else if (state.tag.compositeState === 'PENDING') {
        dispatch('correctionRequest');
      } else if (state.tag.compositeState !== '') {
        dispatch('profileChangeRequest');
      } else {
        const model = stateToModel(state);
        const tagName = tagStore.getters.getTagName(state.tag);
        const startTime = model.profileSetList[0].start;
        const zone = dateStore.getTimeZoneDefinition().tz;
        const mdt = utils.date.toMomentFromDate(startTime, zone);
        try {
          const response = await ETAG_API.get(
            `/tags/${tagName}/available?implementationDateTime=${mdt.utc().format()}`);
          const { available } = response.data;

          if (!available) {
            this.$notify({ type: 'error', message: 'Tag code used within the last 11 months.' });
          } else if (state.mode === MODES.VARIANT_TAG) {
            holdTagStore.actions.submitVariant({ dispatch }, model, tagName, state.holdTag, command.router);
          } else {
            tagStore.actions.submit({ dispatch }, model, command.router);
          }
        } catch (err) {
          console.error(err);
        }
      }
    },
    submitCreateTemplate({ dispatch, state }, router) {
      const model = tagStore.getters.getTagModel(state.tag, state.registry);
      model.segmentList = tagUtils.createSegmentList(
        generationStore.getters.getData(state.generation)[0],
        transmissionStore.getters.getData(state.transmission),
        loadStore.getters.getData(state.load)[0],
      );
      const item = { template: state.template, tagModel: model, router };
      templateStore.actions.createTemplate({ dispatch }, item);
    },
    submitUpdateTemplate({ dispatch, state }, router) {
      const model = tagStore.getters.getTagModel(state.tag, state.registry);
      model.segmentList = tagUtils.createSegmentList(
        generationStore.getters.getData(state.generation)[0],
        transmissionStore.getters.getData(state.transmission),
        loadStore.getters.getData(state.load)[0],
      );
      templateStore.actions.updateTemplate({ dispatch }, state.template, { tagModel: model, router });
    },
    statusApprovalClear({ commit }) {
      commit('clearStatusApprovalData');
    },
    setShowCopyDialog({ commit }) {
      commit('setShowCopyDialog');
    },
    setShowProfileErrorPopup({ commit }, message) {
      commit('setShowProfileErrorPopup', message);
    },
    copy({ state, commit }, router) {
      commit('setShowCopyDialog');

      const tagName = tagStore.getters.getTagName(state.tag);
      const requestID = tagStore.getters.getVersion(state.tag);

      const startDate = dateStore.toMomentFromJSDate(state.copyStartDate).toDate();
      const implementationDateTime = state.profile.profiles[0].momentStartTime.clone().toDate();
      const terminationDateTime = state.profile.profiles[state.profile.profiles.length - 1]
        .momentEndTime.clone().toDate();

      const timeDiff = startDate.getTime() - implementationDateTime.getTime();
      const offset = Math.ceil(timeDiff / (1000 * 3600 * 24));

      const startTime = utils.date.formatDate(startDate, 'YYYY-MM-DDTHH:mm:ssZ');
      const endTime = utils.date.formatDate(
        utils.date.addDays(utils.date.formatDateAndTime(terminationDateTime), offset), 'YYYY-MM-DDTHH:mm:ssZ');

      router.push({
        name: 'NewTag',
        params: {
          tagID: tagName, requestID, startTime, endTime, offset,
        },
      });
    },
    changeCPSE: ({ dispatch, commit, state }, value) => {
      commit('setCPSEcss');
      tagStore.actions.changeCPSE({ commit }, state.tag, value);
      tagStore.actions.newTagCode({ commit, dispatch }, state.tag);
    },
    changeTagCode: ({ commit }, item) => {
      commit('setTagCodecss');
      tagStore.actions.changeTagCode({ commit }, item);
    },
    changeTemplateCPSE: ({ commit, state }, value) => templateStore.actions
      .changeTemplateCPSE({ commit }, state.template, value),
    changeTransactionType: tagStore.actions.changeTransactionType,
    changeTestFlag: tagStore.actions.changeTestFlag,
    changeNotes: tagStore.actions.changeNotes,
    changeMessageNotes: tagStore.actions.changeMessageNotes,
    changeGCA: tagStore.actions.changeGCA,
    changeSource: tagStore.actions.changeSource,
    changeSink: tagStore.actions.changeSink,
    changeLCA: tagStore.actions.changeLCA,
    changeVersion({
      commit, dispatch, state,
    }, item) {
      commit('setProfileReadOnlyFlag', true);
      commit('setMiscInfoReadOnlyFlag', true);
      tagStore.actions.changeVersion({ commit, dispatch }, item);
      state.editButtonText = 'Edit';
      state.exclusionList = [];
    },
    changeStatus: tagStore.actions.changeStatus,
    async loadTagVersion({
      commit, dispatch, state, getters,
    }) {
      await tagStore.actions.loadTagVersion({ dispatch }, state.tag);
      const dailyProfileDate = getters.getVersionList
        .find((x) => x.value === state.tag.currentVersion)
        .modifiedStartTime;
      dispatch('changeDailyProfileDate', moment(dailyProfileDate).format());
      if (state.profile.comparePreviousFlag === true) {
        commit('setComparePreviousFlag');
      }
    },
    comparePreviousVersion({ commit, dispatch, state }, item) {
      commit('setComparePreviousFlag');
      tagStore.actions.loadPreviousVersion({ dispatch }, state.tag);
    },

    changeGenerationMarketPath: generationStore.actions.changeMarketPath,
    changeGenerationPhysicalPath: generationStore.actions.changePhysicalPath,
    changeGenerationAllocationPath: generationStore.actions.changeAllocationPath,

    changeLoadMarketPath: loadStore.actions.changeMarketPath,
    changeLoadPhysicalPath: loadStore.actions.changePhysicalPath,
    changeLoadAllocationPath: loadStore.actions.changeAllocationPath,

    changeToGenerationMarketPSE({ commit, dispatch, state }, item) {
      if (transmissionStore.getters.getCount(state.transmission) === 0) {
        dispatch('addTransmissionMarketPath', {
          rowIndex: 0, market_pse: item.value, market_pse_name: item.label, market_contractList: item.contractList,
        });
      } else {
        dispatch('calculateMarketSegmentIDs');
      }
    },

    changeTransmissionMarketPath({ commit, dispatch, getters }, item) {
      const loadMarketSegmentID = getters.getLoadMarketSegmentID;
      const newItem = { ...item, loadMarketSegmentID };
      transmissionStore.actions.changeMarketPath({ commit, dispatch }, newItem);
    },
    changeTransmissionPhysicalPath({
      commit, dispatch, getters, state,
    }, item) {
      transmissionStore.actions.changePhysicalPath({ commit, dispatch }, state.transmission, item);
    },
    changeTransmissionAllocationPath: transmissionStore.actions.changeAllocationPath,

    addTransmissionMarketPath: transmissionStore.actions.addMarketPath,
    removeTransmissionMarketPath: transmissionStore.actions.removeMarketPath,
    addTransmissionPhysicalPath: transmissionStore.actions.addPhysicalPath,
    removeTransmissionPhysicalPath: transmissionStore.actions.removePhysicalPath,
    addTransmissionAllocationPath: transmissionStore.actions.addAllocationPath,
    removeTransmissionAllocationPath: transmissionStore.actions.removeAllocationPath,

    transmissionMarketPathRowOperation: transmissionStore.actions.marketPathRowOperation,
    transmissionPhysicalPathRowOperation: transmissionStore.actions.physicalPathRowOperation,
    transmissionAllocationPathRowOperation: transmissionStore.actions.allocationPathRowOperation,

    changeProfileViewName: profileStore.actions.changeView,
    changeProfileViewIndex: profileStore.actions.changeViewIndex,
    createProfilesFromDeal: profileStore.actions.createProfilesFromDeal,
    changeDailyProfileDate: profileStore.actions.changeDailyDate,
    changeDailyProfileTimeGranularity: profileStore.actions.changeTimeGranularity,
    changeDailyProfile: profileStore.actions.changeDailyProfile,
    changeConsolidatedProfile: profileStore.actions.changeConsolidatedProfile,
    changeRampProfile: profileStore.actions.changeRampProfile,
    changeProfileDateRange: profileStore.actions.changeProfileDateRange,
    loadProductHours: profileStore.actions.loadProductHours,
    loadProductHoursMap: profileStore.actions.loadProductHoursMap,
    loadInitialDailyProfileDate: profileStore.actions.loadInitialDailyProfileDate,
    loadPreviousTagProfile: profileStore.actions.loadPreviousTagProfile,
    clearPreviousTagProfile: profileStore.actions.clearPreviousTagProfile,
    calculateMarketSegmentIDs({ commit, dispatch }) {
      commit('calculateMarketSegmentIDs');
      dispatch('calculatePhysicalSegmentIDs');
    },
    calculatePhysicalSegmentIDs({ commit, dispatch }) {
      commit('calculatePhysicalSegmentIDs');
      dispatch('calculateAllocationSegmentIDs');
    },
    calculateAllocationSegmentIDs({ commit, dispatch }) {
      commit('calculateAllocationSegmentIDs');
    },
    printToConsole({ state }) {
      const gen = generationStore.getters.cloneData(state.generation);
      const tran = transmissionStore.getters.cloneData(state.transmission);
      const load = loadStore.getters.cloneData(state.load);

      const data = [...gen, ...tran, ...load];

      console.log(JSON.stringify(data));
    },
    printToConsoleModel({ state }) {
      const model = stateToModel(state);
      console.log(JSON.stringify(model));
    },
    changePathReadOnlyFlag({ commit }, value) {
      commit('setPathReadOnlyFlag', value);
    },
    changeProfileReadOnlyFlag({ commit }, value) {
      commit('setProfileReadOnlyFlag', value);
    },
    changeProfileColumns({ commit, dispatch }) {
      commit('setProfileColumns');
      dispatch('resetDailyProfileData');
    },
    changeTemplateName({ commit }, val) {
      commit('setTemplateNamecss');
      templateStore.actions.changeTemplateName({ commit }, val);
    },
    changeTemplateDescription: templateStore.actions.changeTemplateDescription,
    changeTemplateVisibility: templateStore.actions.changeTemplateVisibility,
    createTemplate({ dispatch, state }, router) {
      dispatch('validateTag', { command: COMMANDS.CREATE_TEMPLATE, router });
    },
    updateTemplate({ dispatch }, router) {
      dispatch('validateTag', { command: COMMANDS.UPDATE_TEMPLATE, router });
    },
    changeHoldName: holdTagStore.actions.changeHoldName,
    changeHoldDescription: holdTagStore.actions.changeHoldDescription,
    changeHoldVisibility({ dispatch }, router) {
      dispatch('validateTag', { command: COMMANDS.SAVE_HOLD, router });
    },
    UpdateHoldTag({ dispatch, state }, model) {
      holdTagStore.actions.updateHoldTag({ dispatch }, state.holdTag, model);
    },
    deleteVariantTag({ dispatch, state }, router) {
      if (state.mode === MODES.VIEW_TEMPLATE) {
        templateStore.actions.deleteTemplateTag({ dispatch }, state.template, router);
      } else {
        holdTagStore.actions.deleteHoldTag({ dispatch }, state.holdTag, router);
      }
    },
    checkSetReadOnlyFlag: ({ commit, state, dispatch }, item) => miscInfoStore.actions
      .checkSetReadOnlyFlag({ commit, dispatch }, state.miscInfo, item, state.mode),
    changeMiscInfo: ({ commit, state }, item) => miscInfoStore.actions
      .changeMiscInfo({ commit }, state.miscInfo, item, state.miscInfoReadOnly),
    // fetchCustomMiscInfoList: ({ commit, state }) => miscInfoStore.actions
    // .fetchCustomMiscInfoList({ commit, state }, state.miscInfo),
    // changeCustomUserMiscInfoSelection: ({ commit, state }, value) => miscInfoStore.actions
    // .changeCustomUserMiscInfoSelection({ commit, state }, value),
    // changeCustomMiscInfoNameField: ({ commit }, value) => miscInfoStore.actions
    // .changeCustomMiscInfoNameField({ commit }, value),
    // addCustomMiscInfo: ({ commit, state }) => miscInfoStore.actions
    // .addCustomMiscInfo({ commit, state }, state.miscInfo),
    // updateCustomMiscInfo: ({ commit, state }) => miscInfoStore.actions
    // .updateCustomMiscInfo({ commit, state }, state.miscInfo),
    // deleteCustomMiscInfo: ({ commit, state }) => miscInfoStore.actions
    // .deleteCustomMiscInfo({ commit, state }, state.miscInfo),
    miscStoreInitialize: miscInfoStore.actions.initialize,
    changeMiscInfoRow: miscInfoStore.actions.changeRow,
    miscInfoRowOperation: miscInfoStore.actions.rowOperation,
    changeStatusDialogVisiblility({ dispatch, commit, state }) {
      requestDetailsStore.actions.changeStatusDialogVisiblility({ dispatch, commit }, state.statusTable, state.tag);
    },
    downloadTagXML({ dispatch, state }) {
      tagStore.actions.downloadTagXML({ dispatch }, state.tag);
    },
    loadTagStatuses({ commit, state }) {
      if (state.mode === MODES.EDIT_TAG || state.mode === MODES.VIEW_TAG) {
        requestDetailsStore.actions.loadTagStatuses({ commit }, state.statusTable, state.tag);
      }
    },
    changeStatusTableRefreshRateAction: requestDetailsStore.actions.changeRefreshRateAction,
    incrementStatusTableCounterAction({ dispatch, commit, state }) {
      requestDetailsStore.actions.incrementCounterAction({ dispatch, commit }, state.statusTable, state.tag);
    },
    terminateTag({ commit }, item) {
      tagStore.actions.changeTerminateDialogVisibility({ commit }, item);
    },
    terminateTagFromActions({ commit, dispatch }, ctx) {
      dispatch('initialize', ctx);
      tagStore.actions.changeTerminateDialogVisibility({ commit }, ctx.type);
    },
    approveTagFromActions({ commit, dispatch, state }, ctx) {
      dispatch('initialize', ctx);
      requestDetailsStore.actions.changeStatusDialogVisiblility({ dispatch, commit }, state.statusTable, state.tag);
    },
    changeTerminateDialogVisibility: tagStore.actions.changeTerminateDialogVisibility,
    changeCorrectionNotes: tagStore.actions.changeCorrectionNotes,
    changeCorrectionVisibility: tagStore.actions.changeCorrectionVisibility,
    submitTermination({ dispatch, state }, router) {
      const model = terminationRequestModel(state);
      const tagName = state.tag.routeTagID;
      const entity = state.tag.routeEntity;
      tagStore.actions.submitTermination({ dispatch }, tagName, model, router);
    },
    changeTerminationDateTime: tagStore.actions.changeTerminationDateTime,
    changeRampDuration: tagStore.actions.changeRampDuration,
    changeSelectedTerminationDateTime: tagStore.actions.changeSelectedTerminationDateTime,
    changeTerminationNotes: tagStore.actions.changeTerminationNotes,
    profileChangeRequest({ commit }, item) {
      tagStore.actions.changeProfileDialogVisibility({ commit }, item);
    },
    afterTheFactProfileChangeRequest({ commit }) {
      tagStore.actions.changeForceProfileDialogVisibleFlag({ commit });
    },
    submitProfileChangeRequest({ dispatch, state }, router) {
      const model = profileChangeRequestModel(state);
      const tagName = state.tag.routeTagID;
      const entity = state.tag.routeEntity;
      tagStore.actions.submitProfileChangeRequest({ dispatch }, tagName, model, router, entity);
    },
    correctionRequest({ commit }, item) {
      tagStore.actions.changeCorrectionVisibility({ commit }, item);
    },
    submitCorrectionRequest({ dispatch, state }, router) {
      const model = correctionRequestModel(state);
      const tagName = state.tag.routeTagID;
      const entity = state.tag.routeEntity;
      tagStore.actions.submitCorrectionRequest({ dispatch }, tagName, model, router, entity);
    },
    registryInitialization({ commit, state, dispatch }) {
      registryStore.actions.initialize({ commit, dispatch }, state.registry);
      registryStore.actions.loadCPSEs({ commit });
    },
    changeProfileDialogVisibility: tagStore.actions.changeProfileDialogVisibility,
    changeSubmitDialogVisibility: tagStore.actions.changeSubmitDialogVisibility,
    changeProfileChangeNotes: tagStore.actions.changeProfileChangeNotes,
    changeForceProfileDialogVisibleFlag: tagStore.actions.changeForceProfileDialogVisibleFlag,

    simulateLoading({ commit }) {

    },
    changeComputeFlag({ commit, dispatch, state }, value) {
      const model = stateToModel(state);
      computeStore.actions.changeComputeFlag({ commit, dispatch }, state.compute, { flag: value, model });
    },
    // fetchComputeConfiguration({ commit, dispatch, state }) {
    //   computeStore.actions.fetchConfiguration({ commit, dispatch }, state.compute);
    // },
    changeApprovalState({ commit }, item) {
      requestDetailsStore.actions.changeApprovalState({ commit }, item);
    },
    changeApprovalNotes({ commit }, value) {
      requestDetailsStore.actions.changeApprovalNotes({ commit }, value);
    },
    submitApprovalState({ commit, state }) {
      const submitModel = tagStore.getters.getSetStateModel(state.tag);
      const tagName = state.tag.routeTagID;
      const entity = state.tag.routeEntity;
      requestDetailsStore.actions.submitApprovalState({ commit }, state.statusTable, submitModel, tagName, entity);
    },
    selectedForceProfiles: ({ dispatch, commit, state }, items) => profileStore.actions
      .selectedForceProfiles({ commit }, items),
    forceSubmit({ dispatch, commit, state }, router) {
      const profiles = profileStore.getters.getSelectedForceProfiles(state.profile);
      if (profiles.length === 0) {
        dispatch('alert/error', { message: 'No profiles selected' }, { root: true });
      } else {
        const model = forceProfileChangeRequestModel(state, profiles);
        const tagName = state.tag.routeTagID;
        const entity = state.tag.routeEntity;
        tagStore.actions.submitForceProfileChangeRequest({ dispatch, commit }, tagName, model, router, entity);
      }
    },
    changeTerminationDSTFlag({ commit }, item) {
      tagStore.actions.changeTerminationDSTFlag({ commit }, item);
    },
    addRangeRecord({ state, commit }, value) {
      profileStore.actions.addRangeRecord({ state: state.profile, commit }, value);
    },
    changeAddRangeRecordDialogVisibility({ commit }, value) {
      commit('setAddRangeRecordDialogVisibility', value);
    },
  },
  mutations: {
    setMode(state, mode) {
      state.mode = mode;
    },
    reset(state) {
      state.pathReadonly = false;
      state.profileReadonly = false;
      state.allocationsReadonly = false;
      state.miscInfoReadOnly = false;
      state.editOnLoad = false;
      state.exclusionList = [];
      tagStore.mutations.initialize(state.tag);
      generationStore.mutations.initialize(state.generation);
      loadStore.mutations.initialize(state.load);
      transmissionStore.mutations.initialize(state.transmission);
      profileStore.mutations.initialize(state.profile);
      computeStore.mutations.initialize(state.compute);
      state.copyStartDate = dateStore.getDefaultDate().add(1, 'days').toDate();
    },
    setShowCopyDialog(state) {
      state.showCopyDialog = !state.showCopyDialog;
    },
    setShowProfileErrorPopup(state, message) {
      state.profileErrorMessage = message;
      state.showProfileErrorPopup = !state.showProfileErrorPopup;
    },
    validationError(state, tag) {
      tagStore.mutations.setStateSegments(state, tag);
    },
    setPathReadOnlyFlag(state, value) { state.pathReadonly = value; },
    setProfileReadOnlyFlag(state, value) { state.profileReadonly = value; },
    setPrintViewFlag(state, value) { state.printViewFlag = value; },
    setAllocationReadOnlyFlag(state, value) { state.allocationsReadonly = value; },
    setMiscInfoReadOnlyFlag(state, value) { state.miscInfoReadOnly = value; },
    setCopyStartDate(state, value) { state.copyStartDate = value; },
    setCPSEcss: (state) => {
      if (document.getElementById('cpse')) {
        const cpseElement = document.getElementById('cpse').getElementsByClassName('el-input__inner')[0];
        cpseElement.style.removeProperty('border-color');
        cpseElement.style.removeProperty('border-width');
        cpseElement.title = '';
      }
    },
    setTagCodecss: (state) => {
      if (document.getElementById('tagCode')) {
        const tagCodeElement = document.getElementById('tagCode').getElementsByClassName('el-input__inner')[0];
        tagCodeElement.style.removeProperty('border-color');
        tagCodeElement.style.removeProperty('border-width');
        tagCodeElement.title = '';
      }
    },
    setTemplateNamecss: (state) => {
      if (document.getElementById('name')) {
        const nameElement = document.getElementById('name').getElementsByClassName('el-input__inner')[0];
        nameElement.style.removeProperty('border-color');
        nameElement.style.removeProperty('border-width');
        nameElement.title = '';
      }
    },
    loadTransmissionPaths(state, data) {
      registryStore.mutations.setTransmissionPath(state.registry, data); // registry
      transmissionStore.mutations.setRegistryTransmissionPaths(state.transmission, state.registry); // drop down
    },
    setRegistryVersion: (state, item) => registryStore.mutations.setRegistryVersion(state.registry, item),
    setRegistryTranmissionPath: (state, item) => registryStore.mutations
      .setRegistryTranmissionPath(state.registry, item),
    setLossAccountingVisibilityFlag(state) {
      state.lossAccountingVisibilityFlag = !state.lossAccountingVisibilityFlag;
      if (state.lossAccountingVisibilityFlag) {
        state.lossAccountingLabel = 'Loss Accounting';
        state.lossAccountingVisibilityStyle = 'display: inline';
        state.physicalPathColSpan = 3;
        state.lossAccountingColSpan = 3;
        state.allocationsColSpan = 3;
      } else {
        state.lossAccountingLabel = 'L.A.';
        state.lossAccountingVisibilityStyle = 'display: none';
        state.physicalPathColSpan = 5;
        state.lossAccountingColSpan = 1;
        state.allocationsColSpan = 3;
      }
    },
    loadTagPath(state, tagModel) {
      tagStore.mutations.setFromTagModel(state.tag, tagModel);
      generationStore.mutations.setFromTagModel(state.generation, tagModel);
      loadStore.mutations.setFromTagModel(state.load, tagModel);
      transmissionStore.mutations.setFromTagModel(state.transmission, tagModel, state.allocationsReadonly);
      templateStore.mutations.setFromTagModel(state.template, tagModel);
      holdTagStore.mutations.setFromTagModel(state.holdTag, tagModel);
    },
    loadTagProfile(state, tagModel) {
      profileStore.mutations.setFromTagModel(state.profile, tagModel);
      profileStore.mutations.setData(state.profile);
    },
    setTagBusy(state, value) {
      tagStore.mutations.setBusy(state.tag, value);
    },
    setTermTypes(state, termTypes) { state.termTypes = termTypes; },
    setCPSE: (state, item) => tagStore.mutations.setCPSE(state.tag, item),
    setCPSEs: (state, data) => tagStore.mutations.setCPSEs(state.tag, data),
    setTemplateCPSE: (state, item) => templateStore.mutations.setTemplateCPSE(state.template, item),
    setTemplateCPSEList: (state, data) => templateStore.mutations.setTemplateCPSEList(state.template, data),
    setTransactionType: (state, value) => tagStore.mutations.setTransactionType(state.tag, value),
    setTagCode: (state, value) => tagStore.mutations.setTagCode(state.tag, value),
    clearTagCode: (state) => tagStore.mutations.clearTagCode(state.tag),
    setTestFlag: (state, value) => tagStore.mutations.setTestFlag(state.tag, value),
    setNotes: (state, value) => tagStore.mutations.setNotes(state.tag, value),
    setMessageNotes: (state, value) => tagStore.mutations.setMessageNotes(state.tag, value),
    setGCA: (state, item) => tagStore.mutations.setGCA(state.tag, item),
    setSource: (state, item) => tagStore.mutations.setSource(state.tag, item),
    setSink: (state, item) => tagStore.mutations.setSink(state.tag, item),
    setLCA: (state, item) => tagStore.mutations.setLCA(state.tag, item),
    setVersion: (state, item) => tagStore.mutations.setVersion(state.tag, item),
    setVersions: (state, data) => tagStore.mutations.setVersions(state.tag, data),
    setTagSummaryRef: (state, item) => tagStore.mutations.setTagSummaryRef(state.tag, item),
    setStatus: (state, item) => tagStore.mutations.setStatus(state.tag, item),
    setRouteTagID: (state, item) => tagStore.mutations.setRouteTagID(state.tag, item),
    setSubmitDialogVisibility: (state, item) => tagStore.mutations.setSubmitDialogVisibility(state.tag, item),
    setSubmitItem: (state, item) => tagStore.mutations.setSubmitItem(state.tag, item),
    setGeneration: (state, item) => generationStore.mutations.setData(state.generation, item),
    setLoad: (state, item) => loadStore.mutations.setData(state.load, item),
    setTransmission: (state, item) => transmissionStore.mutations.setData(state.transmission, item),
    setCCMultipleSelection: (state, item) => tagStore.mutations.setCCMultipleSelection(state.tag, item),
    setEditButtonText(state, item) { state.editButtonText = item; },
    addTransmissionMarketPath(state, item) {
      transmissionStore.mutations.addMarketPath(state.transmission, item);
    },
    removeTransmissionMarketPath(state, item) {
      const count = transmissionStore.getters.getCount(state.transmission);
      if (count > 1) {
        transmissionStore.mutations.removeMarketPath(state.transmission, item);
      }
    },
    calculateMarketSegmentIDs(state) {
      const genMarketPSE = generationStore.getters.getMarketPSE(state.generation);
      const lastTranMarketPSE = transmissionStore.mutations.calculateMarketSegmentIDs(state.transmission, genMarketPSE);
      loadStore.mutations.setMarketSegmentID(state.load, lastTranMarketPSE);
    },
    addTransmissionPhysicalPath(state, item) {
      transmissionStore.mutations.addPhysicalPath(state.transmission, item);
    },
    removeTransmissionPhysicalPath(state, item) {
      const count = transmissionStore.getters.getCount(state.transmission);
      // if(count > 1) {
      transmissionStore.mutations.removePhysicalPath(state.transmission, item);
      // }
    },
    calculatePhysicalSegmentIDs(state) {
      const lastTranPhysicalSegment = transmissionStore.mutations.calculatePhysicalSegmentIDs(state.transmission);
      loadStore.mutations.setPhysicalSegmentID(state.load, lastTranPhysicalSegment);
    },
    addTransmissionAllocationPath(state, item) {
      transmissionStore.mutations.addAllocationPath(state.transmission, item);
    },

    removeTransmissionAllocationPath(state, item) {
      const count = transmissionStore.getters.getCount(state.transmission);
      // if(count > 1) {
      transmissionStore.mutations.removeAllocationPath(state.transmission, item);
      // }
    },
    calculateAllocationSegmentIDs: (state) => transmissionStore.mutations
      .calculateAllocationSegmentIDs(state.transmission),
    setProfileView: (state, value) => profileStore.mutations.setView(state.profile, value),
    setProfileViewIndex: (state, value) => profileStore.mutations.setViewIndex(state.profile, value),
    setProfilesFromDeal: (state, context) => profileStore.mutations.setProfilesFromDeal(state.profile, context),
    setComparePreviousFlag: (state) => profileStore.mutations.setComparePreviousFlag(state.profile),
    setProfileDailyDate: (state, item) => profileStore.mutations.setProfileDailyDate(state.profile, item),
    setProfileDailyDateMoment: (state, item) => profileStore.mutations.setProfileDailyDateMoment(state.profile, item),
    setProfileDateRange: (state, item) => profileStore.mutations.setProfileDateRange(state.profile, item),
    setOnPeakHours: (state, item) => profileStore.mutations.setOnPeakHours(state.profile, item),
    setOffPeakHours: (state, item) => profileStore.mutations.setOffPeakHours(state.profile, item),
    setOnPeakHoursMap: (state, item) => profileStore.mutations.setOnPeakHoursMap(state.profile, item),
    setOffPeakHoursMap: (state, item) => profileStore.mutations.setOffPeakHoursMap(state.profile, item),
    setPreviousTagProfile: (state, item) => profileStore.mutations.setPreviousTagProfile(state.profile, item),
    setProfileGraphTimeRanges: (state, item) => profileStore.mutations.setProfileGraphTimeRanges(state.profile, item),
    setProfileTimeGranularity: (state, item) => profileStore.mutations.setTimeGranularity(state.profile, item),
    clearPreviousTagProfileMutation: (state) => profileStore.mutations.clearPreviousTagProfileMutation(state.profile),
    setProfileColumns(state) {
      const gen = generationStore.getters.cloneData(state.generation);
      const tran = transmissionStore.getters.cloneData(state.transmission);
      const load = loadStore.getters.cloneData(state.load);

      const data = [...gen, ...tran, ...load];

      profileStore.mutations.setColumns(state.profile, data);
    },
    resetProfileData(state) {
      profileStore.mutations.resetProfileData(state.profile);
    },
    setProfileData(state, item) {
      profileStore.mutations.setData(state.profile, item);
    },
    setProfileRangeData(state, profiles) {
      profileStore.mutations.setProfileRangeData(state.profile, profiles);
    },
    setTermProfiles(state, profiles) {
      profileStore.mutations.setTermProfiles(state.profile, profiles);
    },
    setTermInputTable(state, event) {
      const inputTable = profileStore.getters.getTermInputTable();
      profileStore.mutations.setTermInputTable(state.profile, inputTable, event);
    },
    setTemplateName: (state, item) => templateStore.mutations.setTemplateName(state.template, item),
    setTemplateDescription: (state, item) => templateStore.mutations.setTemplateDescription(state.template, item),
    setTemplateVisibility: (state, item) => templateStore.mutations.setTemplateVisibility(state.template, item),

    setHoldName: (state, item) => holdTagStore.mutations.setHoldName(state.holdTag, item),
    setHoldDescription: (state, item) => holdTagStore.mutations.setHoldDescription(state.holdTag, item),
    setHoldVisibility: (state, item) => holdTagStore.mutations.setHoldVisibility(state.holdTag, item),

    setTagIDListProperty: (state, item) => transmissionStore.mutations.setTagIDListProperty(state.transmission, item),

    setContractListProperty(state, item) {
      if (item.type === 'GEN') {
        generationStore.mutations.setContractProperty(state.generation, item);
      } else if (item.type === 'LOAD') {
        loadStore.mutations.setContractProperty(state.load, item);
      }

      transmissionStore.mutations.setContractProperty(state.transmission, item);
    },

    setMiscInfo: (state, item) => miscInfoStore.mutations.setMiscInfo(state.miscInfo, item, state.miscInfoReadOnly),
    setMiscInfoRow: (state, item) => miscInfoStore.mutations.setMiscInfoRow(state.miscInfo, item),
    loadMiscTokens: (state, item) => miscInfoStore.mutations.loadMiscTokens(state.miscInfo, item),
    loadMiscValues: (state, item) => miscInfoStore.mutations.loadMiscValues(state.miscInfo, item),
    setMiscInfoProperty(state, item) {
      if (item.type === 'GEN') {
        generationStore.mutations.setMiscInfoProperty(state.generation, item);
      } else if (item.type === 'LOAD') {
        loadStore.mutations.setMiscInfoProperty(state.load, item);
      } else if (item.type === 'TRAN') {
        transmissionStore.mutations.setMiscInfoProperty(state.transmission, item);
      }
    },
    addMiscInfo: (state, item) => miscInfoStore.mutations.addRow(state.miscInfo, item),
    removeMiscInfo: (state, item) => miscInfoStore.mutations.removeRow(state.miscInfo, item),
    setCustomMiscInfoList: (state, list) => miscInfoStore.mutations.setCustomMiscInfoList(state.miscInfo, list),
    setCustomMiscInfoSelection: (state, value) => miscInfoStore.mutations
      .setCustomMiscInfoSelection(state.miscInfo, value),
    setCustomMiscInfoNameField: (state, value) => miscInfoStore.mutations
      .setCustomMiscInfoNameField(state.miscInfo, value),
    setStatusDialogVisiblility: (state) => requestDetailsStore.mutations.setStatusDialogVisiblility(state.statusTable),
    statusTableRefreshRateMutation: (state, value) => requestDetailsStore.mutations
      .statusTableRefreshRateMutation(state.statusTable, value),
    statusTableResetCounterMutation: (state) => requestDetailsStore.mutations
      .statusTableResetCounterMutation(state.statusTable),
    statusTableIncrementCounterMutation: (state) => requestDetailsStore.mutations
      .statusTableIncrementCounterMutation(state.statusTable),
    setStatusData: (state, item) => requestDetailsStore.mutations.setData(state.statusTable, item),

    setTerminateDialogVisibility: (state, item) => tagStore.mutations.setTerminateDialogVisibility(state.tag, item),
    setTerminationDateTime: (state, item) => tagStore.mutations.setTerminationDateTime(state.tag, item),
    setSelectedTerminationDateTime: (state, item) => tagStore.mutations.setSelectedTerminationDateTime(state.tag, item),
    setTerminationNotes: (state, item) => tagStore.mutations.setTerminationNotes(state.tag, item),
    setRampDuration: (state, item) => tagStore.mutations.setRampDuration(state.tag, item),

    setProfileDialogVisibility: (state, item) => tagStore.mutations.setProfileDialogVisibility(state.tag, item),
    setCorrectionDialogVisiblity: (state, item) => tagStore.mutations.setCorrectionDialogVisiblity(state.tag, item),
    setCorrectionNotes: (state, item) => tagStore.mutations.setCorrectionNotes(state.tag, item),
    setProfileChangeNotes: (state, item) => tagStore.mutations.setProfileChangeNotes(state.tag, item),
    setTransmissionsDirty: (state) => transmissionStore.mutations.setTransmissionsDirty(state.transmission),
    setLossTypes: (state, types) => transmissionStore.mutations.setLossTypes(state.transmission, types),
    setVariantType: (state, item) => holdTagStore.mutations.setVariantType(state.holdTag, item),
    setVariantMessageType: (state, item) => holdTagStore.mutations.setVariantMessageType(state.holdTag, item),
    setComputeFlag: (state, value) => computeStore.mutations.setComputeFlag(state.compute, value),
    setComputeResults: (state, value) => computeStore.mutations.setComputeResults(state.compute, value),
    setComputeConfiguration: (state, value) => computeStore.mutations.setComputeConfiguration(state.compute, value),
    readyRegistryBAList(state) {
      const { baList } = state.registry;

      tagStore.mutations.setRegistryBAList(state.tag, baList);
    },
    readyRegistryCPSEList(state) {
      const { cpseList } = state.registry;
      tagStore.mutations.setCPSEs(state.tag, cpseList);
    },
    readyRegistryPSEList(state) {
      const { pseList } = state.registry;
      tagStore.mutations.setRegistryPSEList(state.tag, pseList);
    },
    readyRegistryProductList(state) {
      const { productList } = state.registry;

      generationStore.mutations.setRegistryProductList(state.generation, productList);
      transmissionStore.mutations.setRegistryProductList(state.transmission, productList);
    },
    readyRegistryMOList(state) {
      const { moList } = state.registry;

      generationStore.mutations.setRegistryMOList(state.generation, moList);
      loadStore.mutations.setRegistryMOList(state.load, moList);
      transmissionStore.mutations.setRegistryMOList(state.transmission, moList);
      tagStore.mutations.setRegistryMOList(state.tag, moList);
    },
    setApprovalState(state, item) {
      requestDetailsStore.mutations.setApprovalState(state.statusTable, item);
    },
    clearStatusApprovalData(state) {
      requestDetailsStore.mutations.clear(state.statusTable);
    },
    setApprovalNotes(state, value) {
      requestDetailsStore.mutations.setApprovalNotes(state.statusTable, value);
    },
    setForceProfileDialogVisibleFlag(state, value) {
      tagStore.mutations.setForceProfileDialogVisibleFlag(state.tag, value);
      if (!state.forceProfileDialogVisibleFlag) { profileStore.mutations.setSelectedForceProfiles(state.profile, []); }
    },
    setSelectedForceProfiles(state, items) {
      profileStore.mutations.setSelectedForceProfiles(state.profile, items);
    },
    setTransmissionPhysicalPathLossAccounting(state, item) {
      transmissionStore.mutations.setTransmissionPhysicalPathLossAccounting(state.transmission, item);
    },
    clearTemplate(state) {
      templateStore.mutations.clear(state.template);
    },
    setTerminationDSTFlag(state, value) {
      tagStore.mutations.setTerminationDSTFlag(state.tag, value);
    },
    setUserProfile(state, value) {
      tagStore.mutations.setUserProfile(state.tag, value);
    },
    setAddRangeRecordDialogVisibility(state, value) {
      state.addRangeRecordDialogVisibility = value;
    },
    clearLoadMoData(state, guid) {
      loadStore.mutations.clearMoData(state.load, guid);
    },
    clearTransmissionMoData(state, guid) {
      transmissionStore.mutations.clearMoData(state.transmission, guid);
    },
    clearGenerationMoData(state, guid) {
      generationStore.mutations.clearMoData(state.generation, guid);
    },
  },
};