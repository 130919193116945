interface Right {
  visible: string,
  read: string,
  write: string,
  enabled: string,
  delete: string,
}

const accessRight: Right = {
  visible: 'visible',
  read: 'read',
  write: 'write',
  enabled: 'enabled',
  delete: 'delete',
};

export default accessRight;