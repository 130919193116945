import moment from 'moment';
import utils from '@/utils';
import { has, clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { ETRM_API } from '@/api';

const state = {
  notificationCallback: null,
  postingDateRange: [moment().startOf('month').add(1, 'days').format('YYYY-MM-DD'), moment().endOf('month').add(1, 'days').format('YYYY-MM-DD')],
  tableKey: -999,
  currentTableRow: null,
  deleteCache: [],
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'versionId', label: 'Ver#', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'description', label: 'Description', filter: true, width: 300, editable: true, cellEditor: 'textCellEditor',
    }, {
      prop: 'commodityName',
      label: 'Commodity',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'commodity', placeholder: 'Commodity', clearable: false },
    }, {
      prop: 'marketName',
      label: 'Market',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'market', placeholder: 'Market', clearable: false },
    }, {
      prop: 'formulaFlag',
      label: 'Formula?',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
    }, {
      prop: 'formula',
      label: 'Formula',
      filter: true,
      width: 300,
      editable: true,
      cellEditor: 'formulaCellEditor',
    }, {
      prop: 'priceCurveGroup',
      label: 'Location Group',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'priceCurveGroup', placeholder: 'Location Group', clearable: true },
    }, {
      prop: 'termType',
      label: 'Term Type',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'termType', placeholder: 'Term Type', clearable: true },
    }, {
      prop: 'unit',
      label: 'Unit',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'unit', placeholder: 'Unit', clearable: false },
    }, {
      prop: 'currency',
      label: 'Currency',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'currency', placeholder: 'Currency', clearable: false },
    }, {
      prop: 'forwardIndex',
      label: 'Fwd Index',
      filter: true,
      width: 150,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'priceIndex', placeholder: 'Fwd Index', clearable: true },
    }, {
      prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'createdDate',
      label: 'Created Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value === null) return;
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    }, {
      prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'updatedDate',
      label: 'Updated Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value === null) return;
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    },
    ],
    style: { height: 400 },
    columnCellEditable(row, column) {
      if (column.editable === false) return false;
      if (column.field === 'formulaFlag') return true;
      return true;
    },
    columnList(name, row) {
      if (name === 'priceCurveGroup') {
        return this.registry.priceCurveGroupList
          .filter((val) => val.commodityName === row.commodityName && val.marketName === row.marketName)
          .map((m) => ({ value: m.shortName, label: m.shortName }));
      }
      if (name === 'market') {
        return this.registry.marketList
          .filter((val) => val.commodityName === row.commodityName)
          .map((m) => ({ value: m.shortName, label: m.shortName }));
      }
      if (name === 'termType') {
        return this.registry.termTypeList
          .filter((val) => row.commodityName === 'POWER' && val.marketName === row.marketName)
          .map((m) => ({ value: m.shortName, label: m.shortName }));
      }
      if (name === 'commodity') return this.registry.energyCommodityList;
      if (name === 'priceIndex') {
        return this.registry.priceIndexList
          .filter((val) => val.commodityName === row.commodityName && val.marketName === row.marketName && val.indexType === 'FORWARD' && val.description !== row.description)
          .map((m) => ({ value: m.description, label: m.description }));
      }
      if (name === 'indexType') return [{ value: 'SETTLE', label: 'SETTLE' }, { value: 'FORWARD', label: 'FORWARD' }];
      if (name === 'marketType') return [{ value: 'DA', label: 'DA' }, { value: 'RT', label: 'RT' }];
      if (name === 'indexPriceType') {
        return [{ value: 'ASMP', label: 'ASMP' },
          { value: 'CONGESTION', label: 'CONGESTION' },
          { value: 'EN', label: 'EN' },
          { value: 'LMP', label: 'LMP' },
          { value: 'LOSS', label: 'LOSS' },
          { value: 'RUC', label: 'RUC' }];
      }
      if (name === 'unit') return this.registry.unitList;
      if (name === 'currency') return this.registry.currencyList;
      if (name === 'marketProductType') {
        return this.registry.marketProductTypeList
          .filter((val) => row.commodityName === 'POWER' && val.marketName === row.marketName)
          .map((m) => ({ value: m.shortName, label: m.shortName }));
      }
    },
    columnMapList(watcher, row) {
      const columnMappings = [{}];
      return utils.data.columnSelectList(columnMappings, watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
    },
    style: { height: 300 },
    options: {
      searchFilter: false,
      disableXAxisFill: true,
      disableYAxisFill: true,
    },
    registry: {
      energyCommodityList: [],
      marketList: [],
      priceCurveGroupList: [],
      priceIndexList: [],
      unitList: [],
      currencyList: [],
      termTypeList: [],
      marketProductTypeList: [],
    },
  },
  nullTableRow: {
    id: null,
    versionId: null,
    description: null,
    formulaFlag: false,
    formula: null,
    indexType: 'FORWARD',
    indexPriceType: null,
    commodityName: null,
    marketName: null,
    marketId: null,
    marketType: null,
    mrktProductTypeId: null,
    mrktProductType: null,
    locationId: null,
    termTypeId: null,
    unitId: null,
    currencyId: null,
    forwardIndexId: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    recordState: 1,
  },
  postingTableKey: -999,
  currentPostingRow: null,
  deletePostingCache: [],
  postingTableData: [],
  postingTableConfig: {
    columns: [
      {
        prop: 'id', label: 'ID', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'systemDate',
        label: 'System Dt',
        filter: true,
        width: 100,
        editable: true,
        valueFormatter: function dateValueGetter(params) {
          if (params.value === null) return;
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'startUtcTime', label: 'Start Dt', filter: true, width: 100, editable: true,
      }, {
        prop: 'endUtcTime', label: 'End Dt', filter: true, width: 100, editable: true,
      }, {
        prop: 'price', label: 'Price', filter: true, width: 100, editable: true,
      }, {
        prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'createdDate',
        label: 'Created Dt',
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value === null) return;
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }, {
        prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'updatedDate',
        label: 'Updated Dt',
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value === null) return;
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      },
    ],
    columnList(name, row) {},
    columnMapList(watcher, row) {
      const columnMappings = [{}];
      return utils.data.columnSelectList(columnMappings, watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
    },
    options: { searchFilter: false },
    registry: {},
  },
  nullPostingTableRow: {
    id: null,
    versionId: null,
    priceIndexId: null,
    transactionDate: null,
    productionMonth: null,
    hourEnding: null,
    minuteInterval: null,
    price: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    recordState: 1,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getPostingTableConfig: (state) => state.postingTableConfig,
  getPostingTableData: (state) => state.postingTableData,
  getPostingCurrentRow: (state) => state.currentPostingRow,
  getPostingDateRange: (state) => state.postingDateRange,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('retrieveEnergyCommodityList');
    dispatch('retrieveMarketList');
    dispatch('retrieveTermTypeList');
    dispatch('retrievePriceIndexList');
    dispatch('retrievePriceCurveGroupList');
    dispatch('retrieveCurrencyList');
    dispatch('retrieveUnitList');
    dispatch('retrieveMarketProductTypeList');
    dispatch('loadTableData');
  },
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  reset({ commit, dispatch }) {
    commit('setNotificationCallback', null);
    commit('reset');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit, state }) {
    const response = await ETRM_API.get('/price-indexes?indexType=FORWARD');
    const { data } = response;
    commit('loadTableData', data);
  },
  async loadPostingData({ dispatch, commit, state }) {
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.postingDateRange, tz);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();
    const response = await ETRM_API.get(`/price-index-postings/index/${state.currentTableRow.id}/startFlow/${start.format()}/endFlow/${end.format()}`);
    const { data } = response;
    commit('loadPostingData', data);
  },
  changeTableData({ dispatch, commit, state }, item) {
    commit('changeTableData', item);
  },
  insertTableRow({ dispatch, commit, state }) {
    commit('insertTableRow');
  },
  deleteTableRow({ dispatch, commit, state }) {
    commit('deleteTableRow');
  },
  async saveTableData({ dispatch, commit, state }) {
    const dirtyRecs = state.tableData.filter((val) => val.recordState !== null);
    let outputMsg = null;
    let success = 0;
    let failure = 0;
    for (let d = 0; d < state.deleteCache.length; d++) {
      const deleteData = state.deleteCache[d];
      const response = await ETRM_API.delete(`/price-indexes/${deleteData.id}`);
      if (response.status === 200) {
        success += 1;
        state.deleteCache.splice(deleteData, 1);
      } else {
        outputMsg = `Save record(s) failed to post delete. ${response.statusText}`;
        break;
      }
    }
    if (success > 0) outputMsg = `${success} record(s) inactivated successfully.`;
    for (let i = 0; i < dirtyRecs.length; i++) {
      const data = dirtyRecs[i];
      if (data.recordState === 1) {
        const response = await ETRM_API.post('/price-indexes', data);
        const returnRecord = response.data;
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id === data.id);
          state.tableData.splice(idx, 1, returnRecord);
          if (returnRecord.validationMessage === null) success += 1;
          else failure += 1;
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      } else if (data.recordState === 2) {
        const response = await ETRM_API.put(`/price-indexes/${data.id}`, data);
        const returnRecord = response.data;
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id === data.id);
          state.tableData.splice(idx, 1, returnRecord);
          if (returnRecord.validationMessage === null) success += 1;
          else failure += 1;
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      }
    }

    outputMsg = `${outputMsg + success} record(s) saved successfully. `;
    if (failure > 0) outputMsg = `${outputMsg + failure} record(s) failed to save due to error.`;
    return new Promise((resolve, reject) => resolve(outputMsg));
  },
  currentTableRowChange({ dispatch, commit, state }, selectedRows) {
    const currentRow = selectedRows[0];
    if (state.currentTableRow === undefined || (currentRow !== undefined && currentRow.id !== state.currentTableRow.id)) {
      state.currentTableRow = currentRow;
      state.postingTableData = [];
    }
  },
  changePostingDateRangeAction({ commit, dispatch }, value) {
    commit('changePostingDateRangeMutation', value);
  },
  changePostingData({ dispatch, commit, state }, item) {
    // commit('changePostingData', item);
  },
  async savePostingData({ dispatch, commit, state }) {

  },
};

const mutations = {
  readyRegistryEnergyCommodityList(state) {
    state.tableConfig.registry.energyCommodityList = state.energyCommodityList.map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  readyRegistryMarketList(state) {
    state.tableConfig.registry.marketList = state.marketList;
  },
  readyRegistryPriceCurveGroupList(state) {
    state.tableConfig.registry.priceCurveGroupList = state.priceCurveGroupList;
  },
  readyRegistryTermTypeList(state) {
    state.tableConfig.registry.termTypeList = state.termTypeList;
  },
  readyRegistryMarketProductTypeList(state) {
    state.tableConfig.registry.marketProductTypeList = state.marketProductTypeList;
  },
  readyRegistryUnitList(state) {
    state.tableConfig.registry.unitList = state.unitList.map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  readyRegistryCurrencyList(state) {
    state.tableConfig.registry.currencyList = state.currencyList.map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  readyRegistryPriceIndexList(state) {
    state.tableConfig.registry.priceIndexList = state.priceIndexList;
  },
  reset(state) {

  },
  setNotificationCallback(state, callback) {
    state.notificationCallback = callback;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = null;
  },
  loadTableData(state, model) {
    let priceIndexes = [];
    if (model !== null && has(model, 'priceIndexes')) {
      priceIndexes = model.priceIndexes;
      for (let x = 0; x < priceIndexes.length; x++) {
        const priceIndex = priceIndexes[x];
        for (let cx = 0; cx < state.tableConfig.columns.length; cx++) {
          const column = state.tableConfig.columns[cx];
          if (has(column, 'type') && column.type.name === 'pscs-select-cell-ag') {
            const v = priceIndex[column.prop];
            if (v !== null && Number.isNumber(v)) priceIndex[column.prop] = String(v);
          }
          if (has(column, 'type') && column.type.name === 'pscs-date-time-cell-ag') {
            const v = priceIndex[column.prop];
            if (v !== null) priceIndex[column.prop] = moment(v, 'YYYY-MM-DD');
          }
        }
      }
    }
    state.tableData = priceIndexes;
  },
  changeTableData(state, item) {
    const priceIndex = state.tableData[item.rowIndex];

    if (priceIndex.recordState === null && priceIndex[item.prop] !== item.value) {
      priceIndex.recordState = 2;
      priceIndex.updatedBy = this.getters['auth/getProfile'].userName;
      priceIndex.updatedDate = utils.date.getNow().utc().format();
    }
    if (item.prop === 'marketName') {
      state.tableConfig.registry.priceCurveGroupList = state.priceCurveGroupList.filter((val) => val.commodityName === priceIndex.commodityName && val.marketName === item.value);
      state.tableConfig.registry.termTypeList = state.termTypeList.filter((val) => priceIndex.commodityName === 'POWER' && val.marketName === item.value);
      state.tableConfig.registry.marketProductTypeList = state.marketProductTypeList.filter((val) => val.commodityName === priceIndex.commodityName && val.marketName === item.value);
      state.tableConfig.registry.forwardPriceIndexList = state.priceIndexList.filter((val) => val.commodityName === priceIndex.commodityName && val.indexType === 'FORWARD' && val.marketName === item.value);
    }
    priceIndex[item.prop] = item.value;
  },
  insertTableRow(state) {
    const priceIndex = clone(state.nullTableRow);
    priceIndex.id = state.tableKey++;
    priceIndex.recordState = 1;
    priceIndex.versionId = 1;
    priceIndex.createdBy = this.getters['auth/getProfile'].userName;
    priceIndex.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = priceIndex;
    state.tableData.splice(0, 0, priceIndex);
  },
  deleteTableRow(state) {
    let priceIndex = null;
    for (let t = 0; t < state.tableData.length; t++) {
      priceIndex = state.tableData[t];
      if (priceIndex.id === state.currentTableRow.id) {
        priceIndex.recordState = 3;
        priceIndex.activeFlag = false;
        priceIndex.updatedBy = this.getters['auth/getProfile'].userName;
        priceIndex.updatedDate = utils.date.getNow().utc().format();
        state.deleteCache.push(priceIndex);
        state.tableData.splice(t, 1);
        break;
      }
    }
  },
  changePostingDateRangeMutation(state, value) {
    state.postingDateRange = value;
  },
  loadPostingData(state, model) {
    state.postingTableData = model.priceIndexPostings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};