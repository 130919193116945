export default {
  Processing: {
    backgroundColor: '#f7e967',
  },
  Enqueued: {
    backgroundColor: '#0380fc',
  },
  Deleted: {
    backgroundColor: '#9d9d9d',
  },
  Failed: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  Succeeded: {
    backgroundColor: '#a9cf54',
  },
  Scheduled: {
    backgroundColor: '#ffa500',
  },
};