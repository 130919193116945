import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';

const state = {
  detailData: [],
  summaryData: [],
  params: null,
};

const actions = {
  async fetchEimSubAllocations({ commit }, request) {
    commit('setDetailData', []);
    commit('setSummaryData', []);
    const params = {
      scs: request.coordinatorListSelected,
      subCompany: request.subCompanyListSelected,
      startDate: request.tradeDateRangeSelected[0].toISOString(),
      endDate: request.tradeDateRangeSelected[1].toISOString(),
      fileType: request.fileTypesSelected,
      publication: request.publicationsSelected,
      version: request.fileVersionsSelected,
      compareFileType: request.compareFileTypesSelected,
      compareVersion: request.compareVersionsSelected,
      comparePublication: request.comparePublicationsSelected,
    };

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/detail', { params });
      commit('setDetailData', data);
      commit('setParams', params);
    } catch (error) {
      console.error(error);
      this.$notify({ message: 'Failed to Load EIM-Sub Allocation details', type: 'error' });
    }

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/eim-sub-allocation/summary', { params });
      commit('setSummaryData', data);
      commit('setParams', params);
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load EIM-Sub Allocation Details Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setDetailData', []);
    commit('setSummaryData', []);
    commit('setParams', []);
  },
};

const mutations = {
  setDetailData(state, value) {
    if (value) value.forEach((x) => { x.tradingDate = getMomentDateString(x.tradingDate); });
    state.detailData = value;
  },
  setSummaryData(state, value) {
    if (value) value.forEach((x) => { x.tradingDate = getMomentDateString(x.tradingDate); });
    state.summaryData = value;
  },
  setParams(state, value) {
    state.params = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
