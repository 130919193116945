export default {
  state: {
    tableConfig: {
      columns: [{
        prop: 'he', editable: false, fixed: 'left', width: 50, filter: true,
      }, {
        prop: 'level', editable: false, width: 50, filter: true,
      }, {
        prop: 'rule', editable: false, width: 60, filter: true,
      }, {
        prop: 'message', editable: false, textAlign: 'left',
      }],
      options: {
        wrapCellText: true,
      },
    },
    tableData: [],
    selectedSelfScheduleRow: null,
  },
  getters: {
    getTableConfig: (state) => state.tableConfig,
    getTableData: (state) => {
      if (state.selectedSelfScheduleRow && state.selectedSelfScheduleRow?.he !== 'TOTAL') {
        const { he: hour } = state.selectedSelfScheduleRow;
        return state.tableData.filter(({ he }) => he.toString() === hour);
      }
      return state.tableData;
    },
  },
  actions: {
    changeTableDataAction({ commit }, item) {
      commit('bidErrorTableDataMutation', item);
    },
  },
  mutations: {
    setSelectedSelfScheduleRow(state, { column, row }) {
      if (column.field === 'he') {
        if (row.he === 'TOTAL') {
          state.selectedSelfScheduleRow = null;
        } else {
          state.selectedSelfScheduleRow = row;
        }
      }
    },
    setDataMutation(state, item) {
      if (item) {
        state.tableData = item;
      } else state.tableData = [];
    },
    setTableDataMutation(state, item) {
      state.tableData = item;
    },
    reset(state) {
      state.tableData = [];
    },
  },
};