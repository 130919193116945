import { ETRM_API } from '@/api';
import { cloneDeep, formatValue } from '@/utils/dataUtil';

const state = {
  tableFields: [
    {
      caption: 'COMPANY',
      dataField: 'companyName',
      dataType: 'string',
      area: 'row',
      areaIndex: 0,
    },
    {
      caption: 'COUNTERPARTY',
      dataField: 'counterParty',
      dataType: 'string',
      area: 'row',
      areaIndex: 1,
    },
    {
      caption: 'COMMODITY',
      dataField: 'commodity',
      dataType: 'string',
      area: 'row',
      areaIndex: 2,
    },
    {
      caption: 'TYPE',
      dataField: 'type',
      dataType: 'string',
      area: 'column',
      areaIndex: 0,
      allowSorting: false,
      sortingMethod: function(a, b) {
        const remap = [
          "CREDIT LIMIT",
          "COLLATERAL",
          "ACCOUNTS PAYABLE",
          "PAID AMOUNT",
          "NET ACCOUNTS PAYABLE",
          "ACCOUNTS PAYABLE NOT PAID",
          "ACCOUNTS RECEIVABLE",
          "RECEIVED AMOUNT",
          "NET ACCOUNTS RECEIVABLE",
          "ACCOUNTS RECEIVABLE NOT RECEIVED",
          "USED CREDIT",
          "OPEN EXPOSURE",
          "MARKET VALUE",
          "MARKET VALUE DIFF",
          "MARKET OPEN EXPOSURE",
          "AVAILABLE CREDIT",
        ];
  
        const indexA = remap.indexOf(a.value);
        const indexB = remap.indexOf(b.value);
        return indexA - indexB;
      },
      },
    {
      caption: 'AMOUNT',
      dataField: 'amount',
      dataType: 'number',
      area: 'data',
      areaIndex: 0,
      format(value) {
        return formatValue(value, 'currency', 2, 'red');
      },
      // this grid needs to display the value of the first row in a group instead of adding values up
      summaryType: 'custom',
      calculateCustomSummary: function (options) {
        if (options.summaryProcess == 'start') {
          options.totalValue = 0;
          options.n = 0;
        }
        if (options.summaryProcess == 'calculate') {         
          // only keep the first value as pseudo total
          if (options.n === 0)
            options.totalValue = options.value;

          options.n++;
        }
        if (options.summaryProcess == 'finalize') {
          // nothing to do here
        }
      }
    },
  ],
  tableData: [],
  selectedCells: [],
};

const getters = {
  getTableData(state) {
    return state.tableData;
  },
  getMinHeight(state) {
    if (state.tableData && state.tableData.length > 0) return 600;

    return 100;
  },
};

const actions = {
  async fetchAvailableCredit({ commit }, item) {
    const params = {

    };
    const { data: { data } } = await ETRM_API.get('available-credit', { params });
    commit('setTableData', data);
  },
  reset({ commit }) {
    commit('reset');
  },
  changeMarketTypeAction({ commit, dispatch }, value) {
    commit('changeMarketTypeMutation', value);
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
  },
  changeResourceAction({ commit, dispatch }, item) {
    commit('changeResourceMutation', item);
  },
  changeMultipleSelectionAction({ commit }, val) {
    commit('multipleSelectionMutation', val);
  },
};

const mutations = {
  reset(state) {
    state.tableData = [];
    state.isSearched = false;
  },
  setTableData(state, items) {
    const clonedData = items.map((row) => JSON.stringify(cloneDeep(row)));
    const clonedTableData = state.tableData.map((row) => JSON.stringify(cloneDeep(row)));
    const isNotEquals = clonedData.length !== clonedTableData.length
        || clonedData.some((obj, idx) => obj !== clonedTableData[idx]);
    if (isNotEquals) state.tableData = Object.freeze(items);
  },
  setTableConfig(state, value) {
    state.tableFields = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};