const state = {
  tableConfig: {
    columns: [{
      prop: 'he', editable: false, fixed: 'left', width: 50, filter: true,
    }, {
      prop: 'configuration', editable: false, width: 175, filter: true,
    }, {
      prop: 'priority', editable: false, width: 70, filter: true,
    }, {
      prop: 'ruleId', editable: false, width: 60, filter: true,
    }, {
      prop: 'productType', label: 'product', editable: false, width: 70, filter: true,
    }, {
      prop: 'selfScheduleType', label: 'ss type', editable: false, width: 60, filter: true,
    }, {
      prop: 'message', editable: false, textAlign: 'left',
    }],
  },
  tableData: [],
  selectedSelfScheduleRow: null,
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => {
    if (state.selectedSelfScheduleRow && state.selectedSelfScheduleRow?.he !== 'TOTAL') {
      const { he: hour, configuration: config } = state.selectedSelfScheduleRow;
      return state.tableData.filter(({ he, configuration }) => he.toString() === hour && configuration === config);
    }
    return state.tableData;
  },
};

const actions = {
  changeTableDataAction({ commit }, item) {
    commit('bidErrorTableDataMutation', item);
  },
};

const mutations = {
  setSelectedSelfScheduleRow(state, { column, row }) {
    if (column.field === 'he') {
      if (row.he === 'TOTAL') {
        state.selectedSelfScheduleRow = null;
      } else {
        state.selectedSelfScheduleRow = row;
      }
    }
  },
  setDataMutation(state, item) {
    if (item) {
      const data = item.map((x) => ({
        ...x,
      }));
      state.tableData = data;
    } else state.tableData = [];
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};