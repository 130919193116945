import moment from 'moment';
import { getMomentDateString, formatDate } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import {
  schedulingCoordinatorsWithTagbox,
  tradeDateRange,
  coordinatorList,
  samcHeaders,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import configs from './chargeCodeByBAAConfigs';

const state = {
  tableData: [],
  tableConfig: configs.fields,
  searchFiltersConfig: {
    samcHeaders,
    tradeDateRange,
    coordinatorList,
    granularity: {
      label: 'Granularity',
      value: '0',
      type: 'radio',
      options: [{ value: '0', label: 'Daily' }, { value: '1', label: 'Hourly' }], // 2:interval
    },
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
};

const actions = {
  async fetchChargeCodeByBAA({ commit }, args) {
    const params = {
      scs: args.coordinatorListSelected,
      startDate: args.tradeDateRangeSelected[0].toISOString(),
      endDate: args.tradeDateRangeSelected[1].toISOString(),
      granularity: args.granularitySelected,
      fileType: args.fileTypesSelected,
      publication: args.publicationsSelected,
      version: args.fileVersionsSelected,
      compareFileType: args.compareFileTypesSelected,
      comparePublication: args.comparePublicationsSelected,
      compareVersion: args.compareVersionsSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/charge-code-by-baa', { params });
      data.forEach((obj) => {
        obj.tzTradingdate = getMomentDateString(obj.tzTradingdate);
      });
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Charge Code by BAA details', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};