import notify from 'devextreme/ui/notify';
import moment from 'moment';
import dateStore from './dateStore';
import { getTimeRange } from './dateUtil';
import helpers from './helpers';

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function has(obj, key) {
  if (obj === null) return false;
  if (Array.isArray(key)) return key.every((k) => has(obj, k));
  if (!(typeof key === 'string' || typeof key === 'number')) console.error('Key should be string or number');
  if (typeof obj !== 'object') console.error('Object should be object');
  return key in obj;
}

export function uniqueArrayByObjKey(array, key) {
  return array
    .filter((value, index, arr) => arr.map((x) => x[key])
      .indexOf(value[key]) === index);
}

export function formatReadableUSD(num) {
  // Adds $ and commas to number and returns string
  if (typeof num !== 'number') console.error('Argument should be number');
  return num.toLocaleString(undefined, {
    minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'USD',
  });
}

export function formatReadableNumber(num, fixedPlace = 2) {
  // Formats number and adds commas and optional argument for fixed decimal place (defaults to 2)
  if (typeof num !== 'number') console.error('Argument should be number');
  return num.toLocaleString(undefined, {
    minimumFractionDigits: fixedPlace, maximumFractionDigits: fixedPlace,
  });
}

export function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function innerJoinArrayObjects(arr1, key1, arr2, key2) {
  return arr1.filter((obj1) => arr2.map((obj2) => obj2[key2]).includes(obj1[key1]));
}

export function outerJoinArrayObjects(arr1, key1, arr2, key2) {
  return arr1.filter((obj1) => !arr2.map((obj2) => obj2[key2]).includes(obj1[key1]));
}

export const { cloneDeep } = helpers;
export const deepClone = helpers.cloneDeep;

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
}

export function uuid() {
  // let t =  new Date().getTime();
  // return t.toString();
  return s4() + s4();
}

export const HEProperty = (idx) => idx < 10 ? `he0${idx}` : `he${idx}`;

export const HEColumns = (
  options, hasLabel = true, momentDate = dateStore.getDefaultDate(), granularity = 60, tz = null,
  convertColumProp = true) => {
  const range = dateStore.getTimeRange(momentDate, granularity, tz);
  const heColumns = [];
  range.forEach((he) => {
    const formattedHE = HEProperty(he.he);
    const column = {
      ...he,
      prop: formattedHE,
      configurable: false,
      sortable: false,
      isHourEndingColumn: true,
      ...options,
    };
    if (column.prop === 'he2*' && convertColumProp) column.prop = 'he25';
    if (hasLabel) column.label = formattedHE.toUpperCase();
    heColumns.push(column);
  });
  return heColumns;
};

export const HERows = (options, addStartEndTime = true, momentDate = dateStore.getDefaultDate(), granularity = 60) => {
  const range = dateStore.getTimeRange(momentDate, granularity);
  const heRows = [];
  range.forEach(({ he, timeTZ }) => {
    const formattedHE = HEProperty(he);
    const row = {
      ...options,
      prop: formattedHE,
      he,
    };
    if (addStartEndTime) {
      row.startTime = timeTZ.toISOString().replace('.000', '');
      row.endTime = timeTZ.clone().add(1, 'hour').toISOString().replace('.000', '');
    }
    heRows.push(row);
  });
  return heRows;
};

export const PeriodColumns = (
  options, hasLabel = true, momentDateRange = dateStore.getDefaultRange(), period = 'Hourly', granularity = 60, tz = null,
) => {
  // fallback to default HE implementation
  if (period === 'Hourly') { return HEColumns(options, hasLabel, momentDateRange[0], granularity, tz); }

  const range = dateStore.getTimeRangeByPeriod(momentDateRange, period, tz);
  const periodColumns = [];
  range.forEach((item) => {
    const formattedHE = item.he;
    const column = {
      ...item,
      prop: formattedHE,
      configurable: false,
      sortable: false,
      isHourEndingColumn: false,
      ...options,
    };
    if (hasLabel) column.label = formattedHE.toUpperCase();
    periodColumns.push(column);
  });
  return periodColumns;
};

export const formatValue = (value, format, fixedDecimal, color) => {
  if (value === null) { return null; }
  const formatter = new Intl.NumberFormat('en-US', {
    style: format,
    currency: 'USD',
    minimumFractionDigits: fixedDecimal,
    maximumFractionDigits: fixedDecimal,
    color: color || 'black',
  });
  return formatter.format(value);
};

export const roundNumber = (number, fixedDecimal) => {
  const factor = Math.pow(10, fixedDecimal);
  return Math.round(number * factor) / factor;
}

export const toNumber = (value, def) => {
  const n = Number(value);
  return (Number.isNaN(n) ? (def || 0) : n);
};

export const totals = {
  // for sum we start with initial zero
  sum: (acc, curr) => Number.isNaN(acc) ? toNumber(curr?.value) : acc + toNumber(curr?.value),
  // for min and max we ignore initial undefined value
  min: (acc, curr) => Number.isNaN(acc) ? toNumber(curr?.value) : Math.min(acc, toNumber(curr?.value)),
  // for min and max we ignore initial undefined value
  max: (acc, curr) => Number.isNaN(acc) ? toNumber(curr?.value) : Math.max(acc, toNumber(curr?.value)),
  count: (acc, curr) => Number.isNaN(acc) ? 1 : acc + 1,
  avg: (acc, curr) => Number.isNaN(acc) ? toNumber(curr?.value) : acc + toNumber(curr?.value),
};

export const removeObjProps = (object, ...keys) => Object.entries(object)
  .reduce((prev, [key, value]) => ({ ...prev, ...(!keys.includes(key) && { [key]: value }) }), {});

export function debounce(func, wait) {
  const nativeMin = Math.min;
  let lastArgs;
  let lastThis;
  let maxWait;
  let result;
  let timerId;
  let lastCallTime;
  let lastInvokeTime = 0;
  const leading = false;
  const maxing = false;
  const trailing = true;

  function invokeFunc(time) {
    const args = lastArgs;
    const thisArg = lastThis;
    // eslint-disable-next-line
    lastArgs = lastThis = undefined;
    lastInvokeTime = time;
    result = func.apply(thisArg, args);
    return result;
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    // eslint-disable-next-line
    timerId = setTimeout(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;
    const result = wait - timeSinceLastCall;
    console.log('remainingWait');
    return maxing ? nativeMin(result, maxWait - timeSinceLastInvoke) : result;
  }

  function shouldInvoke(time) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;
    // Either this is the first call, activity has stopped and we're at the trailing
    // edge, the system time has gone backwards and we're treating it as the
    // trailing edge, or we've hit the `maxWait` limit.
    return (
      lastCallTime === undefined
      || (timeSinceLastCall >= wait)
      || (timeSinceLastCall < 0)
      || (maxing && timeSinceLastInvoke >= maxWait)
    );
  }

  function trailingEdge(time) {
    timerId = undefined;
    // Only invoke if we have `lastArgs` which means `func` has been debounced at
    // least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    // eslint-disable-next-line
    lastArgs = lastThis = undefined;
    return result;
  }
  // eslint-disable-next-line
  function timerExpired() {
    const time = Date.now();
    if (shouldInvoke(time)) return trailingEdge(time);
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time));
  }

  function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    // eslint-disable-next-line
    lastArgs = lastCallTime = lastThis = timerId = undefined;
  }

  function flush() {
    return timerId === undefined
      ? result
      : trailingEdge(Date.now());
  }

  function debounced() {
    const time = Date.now();
    const isInvoking = shouldInvoke(time);
    // eslint-disable-next-line
    lastArgs = arguments;
    lastThis = this;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
}

export function distinct(data, property) {
  return helpers.uniq(helpers.map(data, property));
}

export function toTextValueArray(data) {
  return helpers.map(data, (d) => ({ text: d, value: d }),
  );
}

export function filterOnProperty(item, property, value) {
  return item[property] === value;
}

export function addRowID(data) {
  let counter = 0;

  for (let x = 0; x < data.length; x++) {
    data[x].rowID = counter++;
  }

  return data;
}

export function setAllUneditable(config) {
  const newConfig = helpers.cloneDeep(config);
  if (!newConfig) return;

  delete newConfig.columns;
  const result = [];

  for (let i = 0; i < config.columns.length; i++) {
    const col = config.columns[i];

    const n = { ...col };
    n.editable = false;

    result.push(n);
  }

  newConfig.columns = result;
  // eslint-disable-next-line
  return newConfig;
}

export function mergeByPropList(defaultArray, dataArray, propList, excludeKeys) {
  dataArray.forEach((data) => {
    const idx = defaultArray.findIndex((defaultData) => propList.every((prop) => data[prop] === defaultData[prop]));
    if (idx !== -1) {
      Object.keys(defaultArray[idx]).forEach((key) => {
        if (!excludeKeys.includes(key)) {
          defaultArray[idx][key] = data?.[key];
        }
      });
    }
  });

  return defaultArray;
}

export function findByStartTime(items, startTime) {
  return helpers.find(items, (s) => s.startTime === startTime);
}

export function isNumericNullOrEmpty(val) {
  return (val === null) || (undefined === val) || (val === '') || (val === 0);
}

function columnFilterList(filterValue, data, dependentProperty, valueProperty, labelProperty, mapName = '') {
  const resultList = [];

  if (filterValue === null) { return resultList; }

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const hasLabelProperty = item[labelProperty];

      if (filterValue !== null && item[dependentProperty] !== null
                && item[dependentProperty].toString() === filterValue.toString()) {
        const value = item[valueProperty];
        let label = value;

        if (hasLabelProperty) { label = item[labelProperty]; }

        const findIndex = helpers.findIndex(resultList, (r) => r.value === value && r.label === label);

        if (findIndex === -1) { resultList.push({ value, label }); }
      }
    }
  }

  return resultList;
}

export function columnSelectList(columnMappings, watcher, row, dependentProperty, valueProperty, labelProperty) {
  if (!labelProperty) { labelProperty = valueProperty; }

  const mapping = helpers.find(columnMappings, (m) => m.name === watcher.map);

  return columnFilterList(
    row[watcher.prop],
    mapping.values,
    dependentProperty,
    valueProperty,
    labelProperty,
    watcher.map,
  );
}

function columnFilterAutocompleteList(filterValue, data, dependentProperty, valueProperty, labelProperty) {
  const resultList = [];

  if (filterValue === null) { return resultList; }

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const hasLabelProperty = item[labelProperty];

      if (filterValue !== null && item[dependentProperty] !== null
                && item[dependentProperty].toString() === filterValue.toString()) {
        const value = item[valueProperty];
        let label = value;

        if (hasLabelProperty) { label = item[labelProperty]; }

        resultList.push({ key: value, value: label });
      }
    }
  }

  return resultList;
}

export function columnAutocompleteList(columnMappings, watcher, row, dependentProperty, valueProperty, labelProperty) {
  if (!labelProperty) { labelProperty = valueProperty; }

  const mapping = helpers.find(columnMappings, (m) => m.name === watcher.map);

  return columnFilterAutocompleteList(
    row[watcher.prop],
    mapping.values,
    dependentProperty,
    valueProperty,
    labelProperty,
  );
}

export function cloneAndSetParent(state, configPropertyName) {
  const config = helpers.cloneDeep(state[configPropertyName]);
  config.parent = state;
  return config;
}

function arePropertyEqual(left, right, prop) {
  let leftValue = left[prop];
  let rightValue = right[prop];

  leftValue = (leftValue === null || leftValue === undefined) ? '' : leftValue.toString().toLowerCase();
  rightValue = (rightValue === null || rightValue === undefined) ? '' : rightValue.toString().toLowerCase();

  return leftValue === rightValue;
}

export function areEqualRows(columns, left, right, ignoreProps) {
  const excludeProps = ignoreProps || [];

  for (let cx = 0; cx < columns.length; cx++) {
    const col = columns[cx];

    if (helpers.findIndex(excludeProps, (e) => e.toLowerCase() === col.prop.toLowerCase()) > -1) { continue; }

    if (!arePropertyEqual(left, right, col.prop)) { return false; }
  }

  return true;
}

function toPhonetic(character) {
  switch (character) {
  case 'a': return 'alpha';
  case 'b': return 'bravo';
  case 'c': return 'charlie';
  case 'd': return 'delta';
  case 'e': return 'echo';
  case 'f': return 'foxtrot';
  case 'g': return 'golf';
  case 'h': return 'hotel';
  case 'i': return 'india';
  case 'j': return 'juliet';
  case 'k': return 'kilo';
  case 'l': return 'lima';
  case 'm': return 'mike';
  case 'n': return 'november';
  case 'o': return 'oscar';
  case 'p': return 'papa';
  case 'q': return 'quebec';
  case 'r': return 'romeo';
  case 's': return 'sierra';
  case 't': return 'tango';
  case 'u': return 'uniform';
  case 'v': return 'victor';
  case 'w': return 'whiskey';
  case 'x': return 'xray';
  case 'y': return 'yankee';
  case 'z': return 'zulu';
  case '1': return 'one';
  case '2': return 'two';
  case '3': return 'three';
  case '4': return 'four';
  case '5': return 'five';
  case '6': return 'six';
  case '7': return 'seven';
  case '8': return 'eight';
  case '9': return 'niner';
  case '0': return 'zero';
  default: return '';
  }
}

export function toPhoneticAlphaArray(word) {
  const len = word.length;

  const result = [];

  for (let x = 0; x < len; x++) {
    const ch = word[x].toLowerCase();
    result.push(`${ch} - ${toPhonetic(ch)}`);
  }

  return result;
}

export function findColumnIndex(columns, prop) {
  for (let x = 0; x < columns.length; x++) {
    const col = columns[x];
    if (col.prop === prop) {
      return {
        col: helpers.cloneDeep(col),
        index: x,
      };
    }
  }

  return null;
}

export function isStringNullOrEmpty(value) {
  if (value === null || value === undefined || typeof value !== 'string') { return true; }

  return value.trim() === '';
}

export function getObjectDefinition(columns) {
  const obj = {};
  for (let cx = 0; cx < columns.length; cx++) {
    const col = columns[cx];
    if (col.isParent && col.isParent === true) continue;
    obj[col.prop] = null;
  }

  return obj;
}

export function copyObject(obj, momentEndTime, momentStartTime, format, isDST = false, includeHourEnding = false) {
  const l = { ...obj };
  l.momentStartTime = momentStartTime.clone();
  l.startTime = momentStartTime.clone().format(format);

  l.momentEndTime = momentEndTime.clone();
  l.endTime = momentEndTime.clone().format(format);

  if (includeHourEnding) {
    if (isDST === true) { l.he = '2*'; } else { l.he = Number(momentStartTime.format('H')) + 1; }
  }
  l.displayIcon = [];
  l.highlight = [];
  return l;
}

export function createStyleObject(prop, style) {
  if (style !== null && style !== undefined && !isStringNullOrEmpty(style)) {
    return { prop, style };
  }

  return prop;
}

export function addDifferenceProperty(items, prop, style) {
  const { length } = items;
  let found = false;
  let idx = 0;
  for (let x = 0; x < length; x++) {
    const item = items[x];
    if (typeof item === 'object') {
      if (item.prop === prop) {
        idx = x;
        found = true;
        break;
      }
    } else if (typeof item === 'string') {
      if (item === prop) {
        idx = x;
        found = true;
        break;
      }
    }
  }

  if (found) {
    items[idx] = createStyleObject(prop, style);
  } else {
    items.push(createStyleObject(prop, style));
  }
}

export function iteratePropsAndMarkNonMatching(
  item, match, props, differencePropertyName, style, diffArray, diffPropertyName, index,
) {
  const excludeProps = ['startTime', 'momentStartTime', 'endTime', 'momentEndTime', 'he', 'displayIcon', 'highlight'];
  const diffItem = helpers.cloneDeep(item);
  if (!helpers.includes(excludeProps, differencePropertyName)) excludeProps.push(differencePropertyName);

  for (let x = 0; x < props.length; x++) {
    const prop = props[x];

    if (!helpers.includes(excludeProps, prop) && item[prop] !== match[prop]) {
      if (diffPropertyName === 'displayIcon') {
        diffItem[prop] = `${match[prop]} : ${item[prop]}`;
        diffArray[index][prop] = diffItem[prop];
      }
      addDifferenceProperty(item[differencePropertyName], prop, style);
    }
  }
}

export function findDifferences(currentArray, previousArray, differencePropertyName, style = null) {
  const diffArray = currentArray;
  for (let i = 0; i < currentArray.length; i++) {
    const currentItem = currentArray[i];

    if (!currentItem[differencePropertyName]) currentItem[differencePropertyName] = [];

    let match = previousArray.find((o) => o.startTime === currentItem.startTime && o.endTime === currentItem.endTime);

    const props = Object.getOwnPropertyNames(currentItem);

    if (match) {
      iteratePropsAndMarkNonMatching(
        currentItem, match, props, differencePropertyName, style, diffArray, differencePropertyName, i,
      );
    } else {
      match = previousArray.find((o) => o.startTime === currentItem.startTime);

      if (match) {
        addDifferenceProperty(currentItem[differencePropertyName], 'endTime', style);
        iteratePropsAndMarkNonMatching(
          currentItem, match, props, differencePropertyName, style, diffArray, differencePropertyName, i,
        );
      } else {
        match = previousArray.find((o) => o.endTime === currentItem.endTime);

        if (match) {
          addDifferenceProperty(currentItem[differencePropertyName], 'startTime', style);
          iteratePropsAndMarkNonMatching(
            currentItem, match, props, differencePropertyName, style, diffArray, differencePropertyName, i,
          );
        } else {
          addDifferenceProperty(currentItem[differencePropertyName], 'startTime', style);
          addDifferenceProperty(currentItem[differencePropertyName], 'endTime', style);

          // eslint-disable-next-line
          match = previousArray.find((o) => o.momentStartTime.isBefore(currentItem.momentStartTime) && o.momentEndTime.isAfter(currentItem.momentEndTime));

          if (match) {
            iteratePropsAndMarkNonMatching(
              currentItem, match, props, differencePropertyName, style, diffArray, differencePropertyName, i,
            );
          } else {
            iteratePropsAndMarkNonMatching(
              currentItem, {}, props, differencePropertyName, style, diffArray, differencePropertyName, i,
            );
          }
          // }
        }
      }
    }
  }

  return currentArray;
}

// Handles an invalid ResponseModel
export function handleError(context, fallbackMessage = 'Error') {
  // not sure what they were trying to do here with the previous implementation
  const { response } = context;
  if (response && response.data && response.data.messages) {
    response.data.messages.forEach((x) => notify(x, 'error', 10000));
  } else {
    notify(fallbackMessage, 'error');
  }
}

export function saveAs(fileData, fileName, fileType) {
  // reconstruct file contents from byte array
  const byteCharacters = atob(fileData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length;
    i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // create file from blob
  const blob = new Blob([byteArray], { type: fileType });
  const link = document.createElement('a');
  const URL = window.URL || window.webkitURL;

  link.target = '_blank';
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);

  link.click();

  // cleanup: remove element and revoke object URL
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
}

// Order matters
export function isEqual(originalObject, comparisonObject) {
  return JSON.stringify(originalObject) === JSON.stringify(comparisonObject);
}

export function isNull(value, ifNull) {
  if (value === null || value === undefined) return ifNull;
  return value;
}

export function buildApiQuery(path, params) {
  Object.entries(params).forEach((x) => {
    if (Array.isArray(x[1])) {
      x[1].forEach((value) => {
        path += x[0] === Object.keys(params)[0] && x[1][0] === value ? `${x[0]}=${value}` : `&${x[0]}=${value}`;
      });
    } else {
      path += x[0] === Object.keys(params)[0] ? `${x[0]}=${x[1]}` : `&${x[0]}=${x[1]}`;
    }
  });
  return path;
}

export function sortStringArrayDescending(stringArray) {
  return stringArray.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
}

export function nullOrEqualTo(value1, value2) {
  if (!value1) value1 = value2;
  if (`${value1}`.toUpperCase() === `${value2}`.toUpperCase()) return true;
  return false;
}

export function toTitleCase(str) {
  return str.toLowerCase().split(' ').map((word) => word.replace(word[0], word[0].toUpperCase())).join(' ');
}

export function capitalizeFirstLetter(str) {
  return isStringNullOrEmpty(str) ? str : (str.charAt(0).toUpperCase() + str.slice(1));
}

export const sortBy = (fieldToSort, sort = 'asc') => (a, b) => {
  if (
    RegExp(/^\d{4}-[0-1][0-3]-[0-3]\d{1}.*Z$/).test(a[fieldToSort])
    && RegExp(/^\d{4}-[0-1][0-3]-[0-3]\d{1}.*Z$/).test(b[fieldToSort])
  ) {
    const aMom = moment(a[fieldToSort]);
    const bMom = moment(b[fieldToSort]);
    if (aMom.isValid() && bMom.isValid()) {
      if (sort === 'asc') return aMom.isAfter(bMom) ? -1 : 0;
      return aMom.isBefore(bMom) ? -1 : 0;
    }
  }

  const aFormatted = typeof a[fieldToSort] === 'string' ? a[fieldToSort].toLowerCase() : a[fieldToSort];
  const bFormatted = typeof b[fieldToSort] === 'string' ? b[fieldToSort].toLowerCase() : b[fieldToSort];

  if (sort === 'asc') return aFormatted < bFormatted ? -1 : 0;
  return aFormatted > bFormatted ? -1 : 0;
};

export const dxCalculateSummaryValue = (e) => {
  const childRow = e.children('row');
  if (childRow && childRow.length > 0 && childRow[0]._rowPath.length > 0) {
    let file = 0;
    let compare = 0;
    let sum = 1;
    if (childRow[0]._rowPath[0].text === 'Compare') {
      compare = childRow[0] ? childRow[0]._cell[0] : 0;
      file = childRow[1] ? childRow[1]._cell[0] : 0;
    } else if (childRow[0]._rowPath[0].text === 'File') {
      file = childRow[0] ? childRow[0]._cell[0] : 0;
      compare = childRow[1] ? childRow[1]._cell[0] : 0;
    }
    sum = compare - file;
    if (sum > -0.0001 && sum < 0.0001) { sum = 0; }

    return sum;
  }
  return e.cell() && e.cell().originalCell.length > 0 ? e.cell().originalCell[0] : 0;
};

export const dxCalculateCustomSummary = (options) => {
  switch (options.summaryProcess) {
  case 'start': // initializing "totalValue"
    options.totalValue = 0;
    break;
  case 'calculate': // modifying "totalValue"
    options.totalValue += options.value;
    break;
  default:
    break;
  }
};

export const { isEmpty } = helpers;