import { accessRight, etag } from '@/auth/permission';

export default {
  path: '/tags',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'ETAG',
    permission: { name: etag.menu, rights: [accessRight.read, accessRight.write] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: '',
    name: 'Etag',
    meta: {
      rank: 1,
      visible: true,
      requiresAuth: true,
      text: 'Tag Summary',
      permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/TagSummary/TagSummary'),
  }, {
    path: 'requests',
    name: 'Requests',
    meta: {
      rank: 2,
      visible: true,
      requiresAuth: true,
      text: 'Request Summary',
      permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/RequestSummary/RequestSummary'),
  }, {
    path: 'new',
    name: 'NewTag',
    meta: {
      rank: 3,
      visible: true,
      requiresAuth: true,
      text: 'Create Tag',
      permission: { name: etag.tag, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/NewTag'),
  }, {
    path: 'holds',
    name: 'Holds',
    meta: {
      rank: 4,
      visible: true,
      requiresAuth: true,
      text: 'Holds',
      permission: { name: etag.tag, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/VariantSummary/HoldSummary'),
  }, {
    path: 'search',
    name: 'Search',
    meta: {
      rank: 6,
      visible: true,
      requiresAuth: true,
      text: 'Search',
      permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/TagSearch/TagSearch'),
  }, {
    path: 'holds/:holdName',
    name: 'HoldTag',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Hold Tag',
      permission: { name: etag.variant, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/Tag'),
  }, {
    path: 'holds/:holdName/new',
    name: 'NewTagFromHold',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'New Tag Hold',
      permission: { name: etag.tag, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/NewTag'),
  }, {
    path: 'requestTag/:wfId',
    name: 'RequestTag',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Request Tag',
      permission: { name: etag.variant, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/RequestTag'),
  }, {
    path: 'templates',
    name: 'Templates',
    meta: {
      rank: 5,
      visible: true,
      requiresAuth: true,
      text: 'Templates',
      permission: { name: etag.variant, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/VariantSummary/VariantSummary'),
  }, {
    path: 'templates/new',
    name: 'NewTagTemplate',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'New Tag Template',
      permission: { name: etag.variant, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/TemplateTag'),
  }, {
    path: 'templates/:templateName',
    name: 'ViewTagTemplate',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Edit Tag Template',
      permission: { name: etag.variant, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/TemplateTag'),
  }, {
    path: 'templates/:templateName/new',
    name: 'NewTagFromTemplate',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'Tag Template',
      permission: { name: etag.tag, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/NewTag'),
  }, {
    path: 'templates/:templateName/from-deal',
    props: true,
    name: 'NewTagFromDeal',
    meta: {
      visible: false,
      requiresAuth: true,
      text: 'New Tag From Deal',
      permission: { name: etag.tag, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/NewTag'),
  }, {
    path: 'settings',
    name: 'EtagSettings',
    meta: {
      rank: 8,
      visible: true,
      requiresAuth: true,
      text: 'Settings',
      permission: { name: etag.settings, rights: [accessRight.write] },
    },
    component: () => import('@/components/Etag/Settings/EtagSettings'),
  }, {
    path: 'tag/:entity/:tagId',
    name: 'ViewTag',
    props: true,
    meta: {
      visible: false,
      requiresAuth: true,
      permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/Etag/Tag/Tag'),
  }, {
    path: 'reports',
    meta: {
      rank: 20,
      visible: true,
      requiresAuth: true,
      text: 'Reports',
      permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
    },
    component: () => import('@/components/InnerView'),
    children: [
      {
        path: 'mwh',
        name: 'ReportTagMWh',
        meta: {
          rank: 1,
          visible: true,
          requiresAuth: true,
          text: 'Tag MWh',
          permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
        },
        component: () => import('@/components/Etag/Reports/MWhReport/MWhReport'),
      }, {
        path: 'recs',
        name: 'ReportRecs',
        meta: {
          rank: 2,
          visible: true,
          requiresAuth: true,
          text: 'Tag RECs',
          permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
        },
        component: () => import('@/components/Etag/Reports/RECReport/RECReport'),
      }, {
        path: 'recs/:recId',
        name: 'ReportRecTags',
        meta: {
          visible: false,
          requiresAuth: true,
          text: 'RECs',
          permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
        },
        component: () => import('@/components/Etag/Reports/RECTagsReport/RECTagsReport'),
      }, {
        path: 'mwhv2',
        name: 'TagMWhReport',
        meta: {
          rank: 4,
          visible: true,
          requiresAuth: true,
          text: 'MWh',
          permission: { name: etag.tag, rights: [accessRight.read, accessRight.write] },
          featureFlag: 'etag:reporting:mwh:v2',
        },
        component: () => import('@/components/Etag/Reports/MWhReportv2/MWhReportv2'),
      },
    ],
  }],
};