class Dictionary {
  constructor() {
    this.keys = [];
    this.values = [];
  }

  add(key, value) {
    this.keys.unshift(key);
    this.values.unshift(value);
  }

  remove(key) {
    const idx = this.keys.findIndex((k) => k === key);
    if(idx !== -1) {
      this.keys.splice(idx, 1);
      this.values.splice(idx, 1);
    }
  }

  get(key) {
    const idx = this.keys.findIndex((k) => k === key);
    if (idx === -1) return null;
    return this.values[idx];
  }

  contains(key) {
    return this.keys.findIndex((k) => k === key) !== -1;
  }

  entries() {
    return this.keys.map((k, i) => ({ key: k, value: this.values[i] }));
  }

  get count() {
    return this.keys.length;
  }

}

export default Dictionary;