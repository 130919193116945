export default [{
  gca: 'NWMT',
  gcaCode: 554,
  cpse: 'BEMLP1',
  pseCode: 2194,
  tagCode: 'MCE0075',
  lca: 'CISO',
  lcaCode: 167,
  tagID: 'NWMT_BEMLP1MCE0075_CISO',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-15T16:00:00Z',
  terminationDateTime: '2019-04-16T05:00:00Z',
  source: 'KERR',
  sink: 'MCE-NP15',
  genMW: 130,
  originalGenMW: 130,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-15T13:15:49Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-05-14T16:17:20.757Z',
  name: null,
  description: null,
  gpe: 'ENKP',
  gpeCode: 1000401,
  lpe: 'ZES001',
  lpeCode: 2479,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'NWMT_BEMLP1MCE0075_CISO_063908694458', id: 3367, tagID: 'NWMT_BEMLP1MCE0075_CISO', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-15T12:54:49Z', lastApproved: true, notes: '', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'BEMLP1', resolutionTimeStamp: '2019-04-15T12:59:19Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'ZES001',
  toEntityCode: 2479,
}, {
  gca: 'NWMT',
  gcaCode: 554,
  cpse: 'BEMLP1',
  pseCode: 2194,
  tagCode: 'MCE0076',
  lca: 'CISO',
  lcaCode: 167,
  tagID: 'NWMT_BEMLP1MCE0076_CISO',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-15T15:00:00Z',
  terminationDateTime: '2019-04-15T16:00:00Z',
  source: 'KERR',
  sink: 'MCE-NP15',
  genMW: 10,
  originalGenMW: 10,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-15T13:25:41Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-05-14T16:14:46.423Z',
  name: null,
  description: null,
  gpe: 'ENKP',
  gpeCode: 1000401,
  lpe: 'ZES001',
  lpeCode: 2479,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'NWMT_BEMLP1MCE0076_CISO_707708170654', id: 3366, tagID: 'NWMT_BEMLP1MCE0076_CISO', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-15T13:04:41Z', lastApproved: true, notes: '', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'BEMLP1', resolutionTimeStamp: '2019-04-15T13:08:07Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'ZES001',
  toEntityCode: 2479,
}, {
  gca: 'NEVP',
  gcaCode: 594,
  cpse: 'SMUD01',
  pseCode: 809,
  tagCode: 'SMU4596',
  lca: 'BANC',
  lcaCode: 2254,
  tagID: 'NEVP_SMUD01SMU4596_BANC',
  testFlag: false,
  compositeState: 'IMPLEMENTED',
  implementationDateTime: '2019-04-24T07:00:00Z',
  terminationDateTime: '2019-04-25T09:00:00Z',
  source: 'Patua',
  sink: 'SMUD.LOAD',
  genMW: 325,
  originalGenMW: 314,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-25T06:16:55Z',
  wecReserve: 'BA_BANC_7',
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-05-03T00:22:05.987Z',
  name: null,
  description: null,
  gpe: 'PATUA1',
  gpeCode: 1000174,
  lpe: 'SMUD01',
  lpeCode: 809,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3357, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-23T14:14:27Z', lastApproved: false, notes: 'Crrspndng    MW for 60596A, 60697A', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-23T14:30:07Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3358, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T16:03:54Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T16:07:55Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3359, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'WITHDRAWN', requestTimeStamp: '2019-04-24T17:32:42Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T17:46:12Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3360, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 3, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T17:46:40Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T17:49:49Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3361, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 4, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T19:04:40Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T19:06:08Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3362, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 5, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T21:24:05Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T21:32:20Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3363, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 6, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T22:33:14Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T22:35:36Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3364, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 7, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-25T00:13:27Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-25T00:15:00Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'NEVP_SMUD01SMU4596_BANC_969916684723', id: 3365, tagID: 'NEVP_SMUD01SMU4596_BANC', requestID: 8, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-25T05:55:55Z', lastApproved: true, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-25T05:56:38Z', timeClassification: 'ONTIME',
  }],
  readonly: true,
  toEntity: 'ZES001(R)',
  toEntityCode: 2479,
}, {
  gca: 'NEVP',
  gcaCode: 594,
  cpse: 'SMUD01',
  pseCode: 809,
  tagCode: 'SMU4640',
  lca: 'BANC',
  lcaCode: 2254,
  tagID: 'NEVP_SMUD01SMU4640_BANC',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-24T20:00:00Z',
  terminationDateTime: '2019-04-24T21:00:00Z',
  source: 'Patua',
  sink: 'SMUD.LOAD',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-24T18:17:52Z',
  wecReserve: 'BA_BANC_7',
  lastAction: 'NEW',
  lastActionDateTime: '2019-05-03T00:17:24.387Z',
  name: null,
  description: null,
  gpe: 'PATUA1',
  gpeCode: 1000174,
  lpe: 'SMUD01',
  lpeCode: 809,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'NEVP_SMUD01SMU4640_BANC_904117892853', id: 3356, tagID: 'NEVP_SMUD01SMU4640_BANC', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-24T17:56:52Z', lastApproved: true, notes: 'Crrspndng    MW for 60596A, 60697A', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'SMUD01', resolutionTimeStamp: '2019-04-24T18:05:56Z', timeClassification: 'ONTIME',
  }],
  readonly: true,
  toEntity: 'ZES001(R)',
  toEntityCode: 2479,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'ZES001',
  pseCode: 2479,
  tagCode: '9PR3640',
  lca: 'CISO',
  lcaCode: 167,
  tagID: 'IID_ZES0019PR3640_CISO',
  testFlag: false,
  compositeState: 'IMPLEMENTED',
  implementationDateTime: '2019-04-07T07:00:00Z',
  terminationDateTime: '2019-04-09T07:00:00Z',
  source: 'CalEnergy',
  sink: 'RVSD-SP15',
  genMW: 471,
  originalGenMW: 526,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-09T00:32:58Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-05-02T23:48:12.493Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'RVSRV',
  lpeCode: 751,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3344, tagID: 'IID_ZES0019PR3640_CISO', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-05T14:49:22Z', lastApproved: false, notes: 'CalEnergy Resource- Delivery at MIR2', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-05T15:44:34Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3345, tagID: 'IID_ZES0019PR3640_CISO', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-07T13:07:31Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-07T13:19:28Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3346, tagID: 'IID_ZES0019PR3640_CISO', requestID: 2, correctionID: 0, messageType: 'RELIABILITYCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-07T13:22:38Z', lastApproved: false, notes: 'schedule adjust', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'BA', requestor: 'IID', resolutionTimeStamp: '2019-04-07T13:23:01Z', timeClassification: 'LATE',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3347, tagID: 'IID_ZES0019PR3640_CISO', requestID: 3, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T02:24:42Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-08T02:26:16Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3348, tagID: 'IID_ZES0019PR3640_CISO', requestID: 4, correctionID: 0, messageType: 'RELIABILITYCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T04:27:42Z', lastApproved: false, notes: 'PARTIALTRIP', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'BA', requestor: 'IID', resolutionTimeStamp: '2019-04-08T04:28:04Z', timeClassification: 'LATE',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3349, tagID: 'IID_ZES0019PR3640_CISO', requestID: 5, correctionID: 0, messageType: 'RELIABILITYCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T05:11:32Z', lastApproved: false, notes: 'PARTIAL TRIP', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'BA', requestor: 'IID', resolutionTimeStamp: '2019-04-08T05:11:45Z', timeClassification: 'LATE',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3350, tagID: 'IID_ZES0019PR3640_CISO', requestID: 6, correctionID: 0, messageType: 'RELIABILITYCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T06:43:42Z', lastApproved: false, notes: 'PARTIAL TRIP', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'BA', requestor: 'IID', resolutionTimeStamp: '2019-04-08T06:44:04Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3351, tagID: 'IID_ZES0019PR3640_CISO', requestID: 7, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T07:19:05Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-08T07:25:41Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3352, tagID: 'IID_ZES0019PR3640_CISO', requestID: 8, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T07:20:17Z', lastApproved: false, notes: 'ISO market adjustment', toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'MO', requestor: 'CISO', resolutionTimeStamp: '2019-04-08T07:25:33Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3353, tagID: 'IID_ZES0019PR3640_CISO', requestID: 9, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T10:28:09Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-08T10:29:00Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3354, tagID: 'IID_ZES0019PR3640_CISO', requestID: 10, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T14:22:34Z', lastApproved: false, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-08T14:26:23Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_ZES0019PR3640_CISO_B1674982434C', id: 3355, tagID: 'IID_ZES0019PR3640_CISO', requestID: 11, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-09T00:11:58Z', lastApproved: true, notes: null, toEntityName: 'ZES001', toEntityCode: 2479, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'ZES001', resolutionTimeStamp: '2019-04-09T00:13:56Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'ZES001',
  toEntityCode: 2479,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0830',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0830_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-22T07:00:00Z',
  terminationDateTime: '2019-04-22T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-18T17:56:39Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-18T16:57:12.23Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 1,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0830_LDWP_EFE379D29004', id: 3323, tagID: 'IID_PSCS9PR0830_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T16:27:23Z', lastApproved: true, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T16:28:21Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0830_LDWP_EFE379D29004', id: 3324, tagID: 'IID_PSCS9PR0830_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-17T16:31:00Z', lastApproved: false, notes: 'request 1', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-18T16:46:28Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0830_LDWP_EFE379D29004', id: 3325, tagID: 'IID_PSCS9PR0830_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-18T16:27:03Z', lastApproved: false, notes: 'add trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-18T17:18:44Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0830_LDWP_EFE379D29004', id: 3326, tagID: 'IID_PSCS9PR0830_LDWP', requestID: 3, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'PENDING', requestTimeStamp: '2019-04-18T16:56:39Z', lastApproved: false, notes: 'request 3', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: null, timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0829',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0829_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-21T07:00:00Z',
  terminationDateTime: '2019-04-21T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-17T16:51:01Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-17T15:51:04.363Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0829_LDWP_A78670D9FE60', id: 3320, tagID: 'IID_PSCS9PR0829_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T15:48:07Z', lastApproved: true, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T15:49:26Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0829_LDWP_A78670D9FE60', id: 3321, tagID: 'IID_PSCS9PR0829_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-17T15:51:01Z', lastApproved: false, notes: 'add 2 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T15:51:32Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0828',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0828_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-20T07:00:00Z',
  terminationDateTime: '2019-04-20T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-17T16:34:01Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-17T15:34:16.437Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0828_LDWP_57D964ECCFB5', id: 3316, tagID: 'IID_PSCS9PR0828_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T15:19:56Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T15:24:06Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0828_LDWP_57D964ECCFB5', id: 3317, tagID: 'IID_PSCS9PR0828_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T15:25:14Z', lastApproved: true, notes: 'add 1 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T15:31:40Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0828_LDWP_57D964ECCFB5', id: 3318, tagID: 'IID_PSCS9PR0828_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-17T15:34:01Z', lastApproved: false, notes: 'add 3 trans to deny', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T15:35:40Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0827',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0827_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-20T07:00:00Z',
  terminationDateTime: '2019-04-20T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-17T01:17:43Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-17T00:17:52.957Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0827_LDWP_B068F4D94DC1', id: 3311, tagID: 'IID_PSCS9PR0827_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T00:10:30Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T00:14:33Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0827_LDWP_B068F4D94DC1', id: 3312, tagID: 'IID_PSCS9PR0827_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-17T00:17:43Z', lastApproved: true, notes: 'add trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-17T00:18:23Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0819',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0819_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-20T07:00:00Z',
  terminationDateTime: '2019-04-20T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-16T23:25:53Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-16T22:54:26.663Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0819_LDWP_F41CA6028B65', id: 3287, tagID: 'IID_PSCS9PR0819_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-16T21:08:03Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-16T21:08:29Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0819_LDWP_F41CA6028B65', id: 3288, tagID: 'IID_PSCS9PR0819_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-16T21:09:29Z', lastApproved: false, notes: 'add 1 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-16T22:24:48Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0819_LDWP_F41CA6028B65', id: 3290, tagID: 'IID_PSCS9PR0819_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-16T22:25:53Z', lastApproved: false, notes: 'add 2nd trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-16T23:10:29Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0818',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0818_LDWP',
  testFlag: false,
  compositeState: 'PENDING',
  implementationDateTime: '2019-04-16T07:00:00Z',
  terminationDateTime: '2019-04-16T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-16T22:05:03Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-16T21:05:25.137Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 1,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0818_LDWP_6EC1C877C141', id: 3285, tagID: 'IID_PSCS9PR0818_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'PENDING', requestTimeStamp: '2019-04-16T21:05:06Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: null, timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'CISO',
  gcaCode: 167,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0817',
  lca: 'CISO',
  lcaCode: 167,
  tagID: 'CISO_PSCS9PR0817_CISO',
  testFlag: true,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-16T07:00:00Z',
  terminationDateTime: '2019-04-16T08:00:00Z',
  source: 'PSCS.NP15',
  sink: 'PSCS.SP15',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-11T17:41:27Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-11T16:41:28.857Z',
  name: null,
  description: null,
  gpe: 'PSCS',
  gpeCode: 1000509,
  lpe: 'PSCS',
  lpeCode: 1000509,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'CISO_PSCS9PR0817_CISO_B380A373DCC5', id: 3270, tagID: 'CISO_PSCS9PR0817_CISO', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-11T16:35:21Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T16:35:47Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'CISO_PSCS9PR0817_CISO_B380A373DCC5', id: 3271, tagID: 'CISO_PSCS9PR0817_CISO', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-11T16:38:57Z', lastApproved: true, notes: 'add 2 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T16:39:32Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'CISO_PSCS9PR0817_CISO_B380A373DCC5', id: 3272, tagID: 'CISO_PSCS9PR0817_CISO', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-11T16:41:27Z', lastApproved: false, notes: 'added 3 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T16:44:21Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'CISO',
  gcaCode: 167,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0816',
  lca: 'CISO',
  lcaCode: 167,
  tagID: 'CISO_PSCS9PR0816_CISO',
  testFlag: true,
  compositeState: 'PENDING',
  implementationDateTime: '2019-04-11T07:00:00Z',
  terminationDateTime: '2019-04-11T08:00:00Z',
  source: 'PSCS.NP15',
  sink: 'PSCS.SP15',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-11T17:34:13Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-11T16:34:25.19Z',
  name: null,
  description: null,
  gpe: 'PSCS',
  gpeCode: 1000509,
  lpe: 'PSCS',
  lpeCode: 1000509,
  pendingRequestCount: 1,
  requests: [{
    tagIDSecurityKey: 'CISO_PSCS9PR0816_CISO_30C4732D2146', id: 3268, tagID: 'CISO_PSCS9PR0816_CISO', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'PENDING', requestTimeStamp: '2019-04-11T16:34:14Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'CISO', fromEntityCode: 167, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: null, timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0815',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0815_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-19T07:00:00Z',
  terminationDateTime: '2019-04-19T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-11T15:43:38Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-11T14:43:42.113Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0815_LDWP_688CDBEA1431', id: 3264, tagID: 'IID_PSCS9PR0815_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-11T14:21:56Z', lastApproved: false, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T14:22:17Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0815_LDWP_688CDBEA1431', id: 3265, tagID: 'IID_PSCS9PR0815_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-11T14:23:06Z', lastApproved: true, notes: 'added trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T14:41:39Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS9PR0815_LDWP_688CDBEA1431', id: 3266, tagID: 'IID_PSCS9PR0815_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-11T14:43:38Z', lastApproved: false, notes: 'add 2 trans', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T14:58:45Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS',
  pseCode: 1000509,
  tagCode: '9PR0814',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS9PR0814_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-11T07:00:00Z',
  terminationDateTime: '2019-04-11T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-11T15:19:08Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-11T14:19:21.003Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS9PR0814_LDWP_16245D208A0A', id: 3262, tagID: 'IID_PSCS9PR0814_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-11T14:19:09Z', lastApproved: true, notes: '', toEntityName: 'PSCS', toEntityCode: 1000509, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS', resolutionTimeStamp: '2019-04-11T14:19:52Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS',
  toEntityCode: 1000509,
}, {
  gca: 'BPAT',
  gcaCode: 104,
  cpse: 'AVRNW',
  pseCode: 1000989,
  tagCode: 'AVR1205',
  lca: 'BANC',
  lcaCode: 2254,
  tagID: 'BPAT_AVRNWAVR1205_BANC',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-04T04:00:00Z',
  terminationDateTime: '2019-04-04T07:00:00Z',
  source: 'TacomaBiomass',
  sink: 'SMUD.LOAD',
  genMW: 3,
  originalGenMW: 3,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-04T03:10:09Z',
  wecReserve: 'BA_BPAT_7',
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-11T03:26:57.17Z',
  name: null,
  description: null,
  gpe: 'AVRNW',
  gpeCode: 1000989,
  lpe: 'SMUD01',
  lpeCode: 809,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'BPAT_AVRNWAVR1205_BANC_675226579681', id: 3258, tagID: 'BPAT_AVRNWAVR1205_BANC', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-04T02:59:09Z', lastApproved: true, notes: 'Corresponding MWh for 60596A, 60697A', toEntityName: 'SMUD01', toEntityCode: 809, fromEntityName: 'BANC', fromEntityCode: 2254, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'AVRNW', resolutionTimeStamp: '2019-04-04T03:05:11Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'SMUD01',
  toEntityCode: 809,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0514',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS029PR0514_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-19T07:00:00Z',
  terminationDateTime: '2019-04-19T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-10T19:06:57Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-10T18:06:58.867Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS029PR0514_LDWP_671777E3B492', id: 3254, tagID: 'IID_PSCS029PR0514_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-10T18:02:37Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T18:03:22Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS029PR0514_LDWP_671777E3B492', id: 3255, tagID: 'IID_PSCS029PR0514_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-10T18:04:45Z', lastApproved: true, notes: 'add one trans', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T18:04:52Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS029PR0514_LDWP_671777E3B492', id: 3256, tagID: 'IID_PSCS029PR0514_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-10T18:06:57Z', lastApproved: false, notes: 'add 3 trans', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T18:07:35Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0813',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS029PR0813_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-10T07:00:00Z',
  terminationDateTime: '2019-04-10T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-10T18:59:50Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-10T17:59:57.867Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS029PR0813_LDWP_CFF638B8B66C', id: 3252, tagID: 'IID_PSCS029PR0813_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-10T17:59:52Z', lastApproved: true, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T18:00:05Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'PACW',
  gcaCode: 1189,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0513',
  lca: 'PACW',
  lcaCode: 1189,
  tagID: 'PACW_PSCS029PR0513_PACW',
  testFlag: true,
  compositeState: 'PENDING',
  implementationDateTime: '2019-04-11T07:00:00Z',
  terminationDateTime: '2019-04-11T10:00:00Z',
  source: 'MIDC-NNH',
  sink: 'YAKIMALOAD',
  genMW: 6,
  originalGenMW: 6,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-10T17:37:06Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-10T16:37:07.707Z',
  name: null,
  description: null,
  gpe: 'PAC01',
  gpeCode: 674,
  lpe: 'PAC01',
  lpeCode: 674,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'PACW_PSCS029PR0513_PACW_E3932FD67E93', id: 3248, tagID: 'PACW_PSCS029PR0513_PACW', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-10T16:32:28Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T16:33:06Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'PACW_PSCS029PR0513_PACW_E3932FD67E93', id: 3249, tagID: 'PACW_PSCS029PR0513_PACW', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-10T16:34:29Z', lastApproved: false, notes: 'add trans', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T17:46:39Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'PACW_PSCS029PR0513_PACW_E3932FD67E93', id: 3250, tagID: 'PACW_PSCS029PR0513_PACW', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-10T16:37:06Z', lastApproved: false, notes: 'add 3 trans', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T16:37:34Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'PACW',
  gcaCode: 1189,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0512',
  lca: 'PACW',
  lcaCode: 1189,
  tagID: 'PACW_PSCS029PR0512_PACW',
  testFlag: true,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-19T07:00:00Z',
  terminationDateTime: '2019-04-19T10:00:00Z',
  source: 'MIDC-NNH',
  sink: 'YAKIMALOAD',
  genMW: 6,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-09T21:49:41Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-09T20:49:42.92Z',
  name: null,
  description: null,
  gpe: 'PAC01',
  gpeCode: 674,
  lpe: 'PAC01',
  lpeCode: 674,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'PACW_PSCS029PR0512_PACW_244EA44386B0', id: 3243, tagID: 'PACW_PSCS029PR0512_PACW', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-09T16:04:53Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-09T16:05:37Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'PACW_PSCS029PR0512_PACW_244EA44386B0', id: 3244, tagID: 'PACW_PSCS029PR0512_PACW', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-09T16:06:33Z', lastApproved: false, notes: 'add 2s', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-09T17:46:19Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'PACW_PSCS029PR0512_PACW_244EA44386B0', id: 3245, tagID: 'PACW_PSCS029PR0512_PACW', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-09T17:48:54Z', lastApproved: false, notes: 'added 3s', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-09T20:48:26Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'PACW_PSCS029PR0512_PACW_244EA44386B0', id: 3246, tagID: 'PACW_PSCS029PR0512_PACW', requestID: 3, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-09T20:49:41Z', lastApproved: true, notes: 'added trans', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-10T15:40:30Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'PACW',
  gcaCode: 1189,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0511',
  lca: 'PACW',
  lcaCode: 1189,
  tagID: 'PACW_PSCS029PR0511_PACW',
  testFlag: true,
  compositeState: 'PENDING',
  implementationDateTime: '2019-04-10T07:00:00Z',
  terminationDateTime: '2019-04-10T08:00:00Z',
  source: 'MIDC-NNH',
  sink: 'YAKIMALOAD',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-09T17:02:32Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-09T16:02:34.033Z',
  name: null,
  description: null,
  gpe: 'PAC01',
  gpeCode: 674,
  lpe: 'PAC01',
  lpeCode: 674,
  pendingRequestCount: 1,
  requests: [{
    tagIDSecurityKey: 'PACW_PSCS029PR0511_PACW_64943B1020B6', id: 3241, tagID: 'PACW_PSCS029PR0511_PACW', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'PENDING', requestTimeStamp: '2019-04-09T16:02:33Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: null, timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'PACW',
  gcaCode: 1189,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0810',
  lca: 'PACW',
  lcaCode: 1189,
  tagID: 'PACW_PSCS029PR0810_PACW',
  testFlag: true,
  compositeState: 'PENDING',
  implementationDateTime: '2019-04-09T07:00:00Z',
  terminationDateTime: '2019-04-09T08:00:00Z',
  source: 'MIDC-NNH',
  sink: 'YAKIMALOAD',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-09T17:01:17Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-09T16:01:27.8Z',
  name: null,
  description: null,
  gpe: 'PAC01',
  gpeCode: 674,
  lpe: 'PAC01',
  lpeCode: 674,
  pendingRequestCount: 1,
  requests: [{
    tagIDSecurityKey: 'PACW_PSCS029PR0810_PACW_0B8710B0E7BE', id: 3239, tagID: 'PACW_PSCS029PR0810_PACW', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'PENDING', requestTimeStamp: '2019-04-09T16:01:20Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'PACW', fromEntityCode: 1189, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: null, timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0510',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS029PR0510_LDWP',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-09T07:00:00Z',
  terminationDateTime: '2019-04-09T09:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 3,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-08T23:56:18Z',
  wecReserve: null,
  lastAction: 'PROFILECHANGE',
  lastActionDateTime: '2019-04-08T22:56:20.453Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS029PR0510_LDWP_D275E83048A7', id: 3235, tagID: 'IID_PSCS029PR0510_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T22:49:21Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-08T22:50:17Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS029PR0510_LDWP_D275E83048A7', id: 3236, tagID: 'IID_PSCS029PR0510_LDWP', requestID: 1, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T22:52:39Z', lastApproved: false, notes: 'profile change', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-08T23:02:32Z', timeClassification: 'ONTIME',
  }, {
    tagIDSecurityKey: 'IID_PSCS029PR0510_LDWP_D275E83048A7', id: 3237, tagID: 'IID_PSCS029PR0510_LDWP', requestID: 2, correctionID: 0, messageType: 'MARKETCHANGE', requestStatus: 'DENIED', requestTimeStamp: '2019-04-08T22:56:18Z', lastApproved: false, notes: 'test', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-09T15:32:38Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'IID',
  gcaCode: 426,
  cpse: 'PSCS02',
  pseCode: 1001023,
  tagCode: '9PR0509',
  lca: 'LDWP',
  lcaCode: 480,
  tagID: 'IID_PSCS029PR0509_LDWP',
  testFlag: false,
  compositeState: 'IMPLEMENTED',
  implementationDateTime: '2019-04-08T07:00:00Z',
  terminationDateTime: '2019-04-08T08:00:00Z',
  source: 'HEBER1',
  sink: 'LASYSTEM',
  genMW: 1,
  originalGenMW: 1,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-08T23:43:01Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-08T22:43:18.32Z',
  name: null,
  description: null,
  gpe: 'IID',
  gpeCode: 426,
  lpe: 'LAWM01',
  lpeCode: 477,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'IID_PSCS029PR0509_LDWP_85EF2E8CF6BE', id: 3233, tagID: 'IID_PSCS029PR0509_LDWP', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-08T22:43:02Z', lastApproved: false, notes: '', toEntityName: 'PSCS02', toEntityCode: 1001023, fromEntityName: 'LDWP', fromEntityCode: 480, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'PSCS02', resolutionTimeStamp: '2019-04-08T22:47:29Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'PSCS02',
  toEntityCode: 1001023,
}, {
  gca: 'CISO',
  gcaCode: 167,
  cpse: 'EUMX18',
  pseCode: 1001084,
  tagCode: '0000633',
  lca: 'CEN',
  lcaCode: 1000487,
  tagID: 'CISO_EUMX180000633_CEN',
  testFlag: false,
  compositeState: 'CONFIRMED',
  implementationDateTime: '2019-04-04T07:00:00Z',
  terminationDateTime: '2019-04-05T07:00:00Z',
  source: 'SP15',
  sink: 'LAROSITA',
  genMW: 200,
  originalGenMW: 200,
  transactionType: 'NORMAL',
  actOnByTime: '2019-04-03T22:36:25Z',
  wecReserve: null,
  lastAction: 'NEW',
  lastActionDateTime: '2019-04-03T22:03:15.73Z',
  name: null,
  description: null,
  gpe: 'GPM',
  gpeCode: 1000209,
  lpe: 'EUMX18',
  lpeCode: 1001084,
  pendingRequestCount: 0,
  requests: [{
    tagIDSecurityKey: 'CISO_EUMX180000633_CEN_D5577A690702', id: 3226, tagID: 'CISO_EUMX180000633_CEN', requestID: 0, correctionID: 0, messageType: 'NEW', requestStatus: 'APPROVED', requestTimeStamp: '2019-04-03T20:35:25Z', lastApproved: true, notes: '', toEntityName: 'EUMX18', toEntityCode: 1001084, fromEntityName: 'CEN', fromEntityCode: 1000487, startDate: '0001-01-01T00:00:00Z', endDate: '0001-01-01T00:00:00Z', profileType: null, requestorType: 'PSE', requestor: 'EUMX18', resolutionTimeStamp: '2019-04-03T20:44:34Z', timeClassification: 'ONTIME',
  }],
  readonly: false,
  toEntity: 'EUMX18',
  toEntityCode: 1001084,
}];