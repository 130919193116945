import { accessRight } from '@/auth/permission';
import pages from './pages';

const defaultRoutes = [{
  path: '/login',
  name: 'Login',
  meta: { visible: false, requiresAuth: false },
  component: () => import('@/components/Login'),
}, {
  path: '/auth/callback',
  name: 'AuthCallback',
  meta: { visible: false, requiresAuth: false },
  component: () => import('@/components/AuthCallback'),
}, {
  path: '/',
  name: 'Home',
  meta: { visible: false, requiresAuth: false },
  component: () => import('@/components/Home'),
}, {
  path: '/error',
  name: 'Error',
  meta: { visible: false, requiresAuth: false },
  component: () => import('@/components/Error'),
}, {
  path: '/unavailable',
  name: 'Unavailable',
  meta: { visible: false, requiresAuth: false },
  component: () => import('@/components/Unavailable'),
}, {
  path: '/confirm/user',
  name: 'ConfirmUser',
  meta: { text: 'Confirm Account', visible: false, requiresAuth: true },
  component: () => import('@/components/PscsSetup/PscsConfirmUser'),
}, {
  path: '/settings',
  name: 'Settings',
  meta: { visible: false, requiresAuth: true, text: 'User Settings' },
  component: () => import('@/components/PscsUserOptions/UserOptions'),
}, {
  path: '/reports',
  name: 'Reports',
  meta: { visible: false, requiresAuth: true, text: 'Reports' },
  component: () => import('@/components/PscsReporting/Reporting'),
}, {
  path: '/help',
  name: 'Help',
  meta: { visible: false, requiresAuth: true, text: 'Help' },
  // component: () => import('@/components/PscsHelp/Help'),
  redirect: (data) => {
    // the doc parameter will allow us to open specific page
    // this is not yet working correctly in vuepress
    const doc = data?.params?.doc || '';
    window.open(`https://docs.settlecore.com/${doc}`, '_blank');
    window.history.pushState(window.location);
  },
}, {
  path: '/about',
  name: 'About',
  meta: { visible: false, requiresAuth: true, text: 'About' },
  component: () => import('@/components/PscsAbout/About'),
}, {
  path: '/access-denied',
  name: 'AccessDenied',
  meta: { visible: false, requiresAuth: true, text: 'Access Denied' },
  props: {
    msg: 'Unauthorized User',
    type: 'user_auth',
  },
  component: () => import('@/components/AccessDenied'),
}, {
  path: '/auth-service-failure',
  name: 'AuthServiceFailure',
  meta: { visible: false, requiresAuth: true, text: 'Authentication service Error' },
  props: {
    msg: 'Unable to establish a connection with the authentication service',
    type: 'auth0_server',
  },
  component: () => import('@/components/AccessDenied'),
}, {
  path: '/workflows',
  meta: {
    visible: true, requiresAuth: true, text: 'WORKFLOW', permission: { name: 'workflow', rights: [accessRight.read, accessRight.write] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'overview',
    name: 'Overview',
    meta: { visible: true, requiresAuth: true, text: 'Overview' },
    component: () => import('@/components/Workflow/Overview/wfOverview'),
  }, {
    path: 'instances',
    name: 'Instances',
    meta: { visible: true, requiresAuth: true, text: 'Instances' },
    component: () => import('@/components/Workflow/wfInstances/wfInstances'),
  }, {
    path: ':workflowName/instances/:caseId/tokens',
    name: 'InstanceStatus',
    meta: { visible: false, requiresAuth: true, text: 'InstanceStatus' },
    component: () => import('@/components/Workflow/wfInstanceStatus/wfInstanceStatus'),
  }, {
    path: ':workflowName',
    name: 'Workflow',
    meta: { visible: false, requiresAuth: true, text: 'Workflow' },
    component: () => import('@/components/Workflow/Overview/Workflow'),
  }],
},
  // , { path: "*", name: "NotFound", meta: { visible : false, requiresAuth: false }, component: () => import('@/components/NotFound'))}
];

const routes = [...defaultRoutes, ...pages].filter((route) => route.path);

export default routes;