import { clone } from '@/utils/dataUtil';
import { ETRM_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, alignment: 'left',
    }, {
      prop: 'commodity', label: 'Commodity', sortable: true, filterable: true, alignment: 'left', width: "90px",
    }, {
      prop: 'companyId', label: 'Company ID', sortable: true, filterable: true, visible: false, alignment: 'left', 
    }, {
      prop: 'companyName', label: 'Company', sortable: true, filterable: true, alignment: 'left', width: "100px",
    }, {
      prop: 'counterPartyId', label: 'Counterparty ID', sortable: true, filterable: true, visible: false, alignment: 'left',
    }, {
      prop: 'counterParty', label: 'Counterparty', sortable: true, filterable: true, alignment: 'left', width: "100px",
    }, {
      prop: 'effectiveStartDate', label: 'Start Date', sortable: true, filterable: true, dataType: 'date', alignment: 'left', width: "90px",
    }, {
      prop: 'effectiveEndDate', label: 'End Date', sortable: true, filterable: true, dataType: 'date', alignment: 'left', width: "90px",
    }, {
      prop: 'reviewedAsOfDate', label: 'Reviewed On', sortable: true, filterable: true, dataType: 'date', alignment: 'left', width: "90px",
    }, {
      prop: 'rating', label: 'Rating', sortable: true, filterable: true, width: "50px",
    }, {
      prop: 'amount', label: 'Limit', sortable: true, filterable: true, format: { type: 'currency', precision: 2 }, alignment: 'right', width: "100px",
    }, {
      prop: 'warningThreshold', label: 'Warning Threshold', sortable: true, filterable: true, width: "80px", alignment: 'right'
    }, {
      prop: 'warningThresholdType', label: 'CL Type', sortable: true, filterable: true, width: "100px",
    }, {
      prop: 'portfolioConcentrationLimit', label: 'Portfolio Concentration Limit', sortable: true, filterable: true, format: { type: 'currency', precision: 2 }, alignment: 'right', width: "100px",
    }, {
      prop: 'portfolioConcentrationLimitWarningThreshold', label: 'Warning Threshold', sortable: true, filterable: true, width: "80px", alignment: 'right'
    }, {
      prop: 'portfolioConcentrationLimitWarningThresholdType', label: 'PCL Type', sortable: true, filterable: true, width: "100px",
    }, {
      prop: 'collateral', label: 'Collateral', sortable: true, filterable: true, width: "100px",
    }, {
      prop: 'collateralNotes', label: 'Collateral Notes', sortable: true, filterable: true, width: "100px",
    }, {
      prop: 'checkOnEntryFlag', label: 'Check On Entry', sortable: true, filterable: true, width: "80px",
    }, {
      prop: 'includeMtmFlag', label: 'Include MTM', sortable: true, filterable: true, width: "80px",
    }, {
      prop: 'includeOteFlag', label: 'Include OTE', sortable: true, filterable: true, width: "80px",
    }, {
      prop: 'invoiceNettingFlag', label: 'Invoice Netting', sortable: true, filterable: true, width: "80px",
    }, {
      prop: 'suspendedFlag', label: 'Suspended', sortable: true, filterable: true, width: "80px",
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true, visible: true, alignment: 'left', width: "80px",
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, filterable: true, visible: true, dataType: 'datetime', alignment: 'left', width: "119px",
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, filterable: true, visible: true, alignment: 'left', width: "80px",
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, filterable: true, visible: true, dataType: 'datetime', alignment: 'left', width: "119px",
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    commodity: null,
    companyId: null,
    companyName: null,
    counterPartyId: -999,
    counterParty: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    reviewedAsOfDate: null,
    rating: null,
    amount: null,
    warningThreshold: null,
    warningThresholdType: null,
    portfolioConcentrationLimit: null,
    portfolioConcentrationLimitWarningThreshold: null,
    portfolioConcentrationLimitWarningThresholdType: null,
    collateral: null,
    collateralNotes: null,
    checkOnEntryFlag: false,
    includeMtmFlag: false,
    includeOteFlag: false,
    invoiceNettingFlag: false,
    suspendedFlag: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const convertDates = (data) => {
  data.effectiveStartDate = data.effectiveStartDate.split('T')[0];
  data.effectiveEndDate = data.effectiveEndDate.split('T')[0];
};

const actions = {
  async initialize({ dispatch }) {
    await dispatch('loadTableData');
  },
  initializeCounterPartyCommodityList({ dispatch }) {
    dispatch('lookup/fetchCompanyList', null, { root: true });
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await ETRM_API.get('credit-limits');

      // do not adjust for user time zones for effective start and end dates
      if (data && data.length > 0) {
        data.forEach(row => {
          convertDates(row);
        });
      }

      commit('loadTableData', data);
      this.$notify(`${state.tableData.length} record(s) Loaded`);
    } catch (error) {
      this.$notify('Failed to Load Credit Limit Data', 'error');
    }
  },
  async postCreditLimit({ commit, state }, creditLimit) {
    let isSuccess = true;
    try {
      const { data } = await ETRM_API.post('credit-limits', creditLimit);

      convertDates(data);

      state.tableData.push(data);
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Credit Limit Added', 'success');
    } catch (error) {
      if (error?.response?.data?.messages) {
        // log and display custom message with increased duration
        const [errorMessage] = error.response.data.messages;
        console.error(errorMessage);
        this.$notify(errorMessage, 'error', 10000);
      } else {
        // display generic message
        this.$notify('Failed to Add Credit Limit', 'error');
      }
      isSuccess = false;
    }
    return isSuccess;
  },
  async updateCreditLimit({ commit }, creditLimit) {
    let isSuccess = true;
    try {
      const { data } = await ETRM_API.put(`credit-limits/${state.currentTableRow.id}`, creditLimit);

      convertDates(data);

      commit('updateCreditLimit', data);
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Credit Limit Updated', 'success');
    } catch (error) {
      const errorMessage = error?.response?.data?.message 
        || error?.response?.data?.messages[0]
        || 'Failed to Add Credit Limit';

      this.$notify(errorMessage, 'error');

      isSuccess = false;
    }
    return isSuccess;
  },
  async deleteTableRow({ commit, state }) {
    try {
      await ETRM_API.delete(`credit-limits/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Credit Limit Removed', 'success');
    } catch (error) {
      const errorMessage = error?.response?.data?.message 
        || error?.response?.data?.messages[0]
        || 'Failed to Inactivate Credit Limit';

      this.$notify(errorMessage, 'error');
    }
  },
};

const mutations = {
  updateCreditLimit(state, creditLimit) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === creditLimit.id);
    state.tableData.splice(rowIndex, 1, creditLimit);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, data) {
    state.tableData = data;
  },
  createTableRow(state) {
    state.currentTableRow = clone(state.nullTableRow);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter(({ id }) => id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};