import moment from 'moment';
import { clone, cloneDeep, HEColumns } from '@/utils/dataUtil';

export function mapToOptions(items) {
  return items.map((i) => ({ value: i.id, label: i.shortName }));
}

export function findItemMatchingWithId(items, id) {
  return items.find((i) => i.id === id);
}

export function toTradeModel(trade) {
  return trade;
}

export function toTableModel(trade) {
  return trade;
}

export function mergeTradesAndTradeSchedules(trades, tradeSchedules, multipleSelection, date, dstKey = 'he02*') {
  const hour = () => ({
    quantity: null,
    previousQuantity: null,
    marketQuantity: null,
    counterQuantity: null,
    adjustedQuantity: null,
    systemStatus: null,
    previousSystemStatus: null,
    marketStatus: null,
    previousMarketStatus: null,
    lastModifiedTime: null,
    updatedBy: null,
    updatedDate: null,
  });

  return trades.map((trade) => {
    trade = cloneDeep(toTableModel(trade));
    // If there are changes to the row don't change it
    if (trade.isDirty) return trade;

    const tradeSchedule = tradeSchedules.find((o) => o.name === trade.name);
    if (!tradeSchedule) {
      trade.variant = 'NOT_EXISTS';
    } else trade.variant = 'ACTUAL';

    for (const p in tradeSchedule) if (tradeSchedule[p]) trade[p] = tradeSchedule[p];

    HEColumns({}, true, date).forEach(({ prop, timeTZ, dstFlag }) => {
      const tradeScheduleHour = tradeSchedule?.hours?.find(({ startTime }) => timeTZ.isSame(startTime));
      const tradeScheduleStatus = tradeSchedule?.statuses?.find(({ startTime }) => timeTZ.isSame(startTime));
      // If config has long day then add it
      const key = dstFlag ? dstKey : prop;
      trade[key] = tradeScheduleHour ?? hour();
      if (tradeScheduleStatus) trade[key] = { ...trade[key], ...tradeScheduleStatus };
      trade[`${key}_val`] = trade[key].quantity;
      trade.scheduleStatus = tradeSchedule?.scheduleStatus ?? 'NOT_EXISTS';
    });
    // Set rowSelected if it is present in MultipleSelect
    const match = multipleSelection.find(
      (o) => trade.resource === o.resource && trade.configuration === o.configuration,
    );
    if (match) trade.rowSelected = true;

    return trade;
  });
}

export function tableModelToScheduleModel(model, date) {
  model.tradeDate = date;
  if (!model.startTime) {
    model.startTime = date;
  }
  if (!model.endTime) {
    model.endTime = moment(date).add(1, 'day').toISOString();
  }
  model.hours = [];

  HEColumns({}, true, date).forEach(({ prop, timeTZ, dstFlag }) => {
    const hour = {
      startTime: timeTZ.toISOString(),
      endTime: timeTZ.add(1, 'hour').toISOString(),
      quantity: null,
    };
    if (dstFlag) {
      // For long hour
      hour.quantity = model['he02*_val'] ?? model.he25_val;
      delete model['he02*_val'];
      delete model.he25_val;
      model['he02*_val'] = hour.quantity ? String(hour.quantity) : null;
      model['he02*'] = model.he02;
      if (typeof hour.quantity === 'string') hour.quantity = Number(hour.quantity);
    } else if (model[prop] && model[`${prop}_val`]) {
      hour.quantity = (+model[`${prop}_val`]);
    }

    if (hour.quantity) model.hours.push(hour);
  });
  return model;
}
