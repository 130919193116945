import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_INVOICE_API, TOOLS_API } from '@/api';
import { dateRange, ppaGroupTagBox, ppaGroupInvoiceEntities } from '@/components/PPA/BaseStore/ppasBase';

const state = {
  tableData: [],
  unsentRecords: false,
  invoiceIds: [],
  searchFiltersConfig: {
    accountPeriod: {
      label: 'Account Period',
      value: null,
      type: 'select',
      searchable: true,
      options: [],
      async fetchData() {
        try {
          const { data: { data } } = await PPA_INVOICE_API.get('/subledgers/account-period');
          data.sort((a, b) => b.period - a.period);
          return data.map((item) => ({ label: `${item.period } - (${item.status})`, value: item.period }));
        } catch (err) {
          console.error(err);
          this.$notify('Failed to load Account Periods', 'error');
        }
        return [{}];
      },
      watchers: [{ // used to set selected value since fetchData method can't access 'this.params'
        propertyToWatch: 'accountPeriodUnfiltered',
        handler() {
          const periods = this.params.accountPeriodUnfiltered;

          let period = null;
          periods.every((x) => {
            if (x.label.includes('OPEN')) {
              period = x.value;
              return false; // break out of loop
            }
            return true;
          });

          if (period === null) {
            period = periods[0]?.value ?? null;
          }
          this.params.accountPeriodSelected = period;
          return true;
        },
      }],
    },
    dateRange: {
      ...dateRange,
      watchers: [{
        propertyToWatch: 'accountPeriodSelected',
        handler(newValue) {
          const period = [newValue.toString().slice(0, 4), newValue.toString().slice(4)]; // parse year/month
          const startOfMonth = new Date(period[0], period[1] - 1, 1);
          const endOfMonth = new Date(period[0], period[1], 0);

          this.params.dateRangeSelected = [startOfMonth, endOfMonth];
          return true;
        },
      }],
    },
    ppaGroupTagBox,
    ppaGroupInvoiceEntities,
    cycleType: {
      label: 'Cycle Type',
      value: 'Invoice',
      type: 'select',
      options: [
        { value: 'Invoice', label: 'Invoice' },
        { value: 'Forecast', label: 'Forecast' },
      ],
    },
  },
};

const actions = {
  async fetchSubledger({ commit }, args = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.dateRangeSelected);
    const params = {
      ppaGroups: args.ppaGroupTagBoxSelected,
      invoiceEntities: args.ppaGroupInvoiceEntitiesSelected,
      startDate,
      endDate,
      accountingPeriod: args.accountPeriodSelected,
      cycleType: args.cycleTypeSelected,
    };
    try {
      const { data: { data, unsentItems, hasUnsentData } } = await PPA_INVOICE_API.post('/subledgers/get', params);
      commit('setUnsentRecords', hasUnsentData);
      commit('setInvoiceIds', unsentItems);

      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.postDt = getMomentDateString(x.postDt);
          x.invDt = getMomentDateString(x.invDt);
          x.sendDt = x.sendDt === null ? null : moment(x.sendDt).format('YYYY-MM-DD HH:mm:ss');
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async sendToAccounting({ commit, state }) {
    const filteredInvoiceIds = state.invoiceIds.map((x) => x.invoiceNumber).filter((x, i, a) => a.indexOf(x) === i);
    const accountingRequest = {
      reportType: 'ppa-send-accounting',
      invoiceIds: filteredInvoiceIds,
    };
    try {
      await TOOLS_API.post('/legacy-process/ppa-report', accountingRequest);
      this.$notify('Data Sent', 'success');
      commit('reset');
    } catch (err) {
      console.error(err);
      commit('reset');
      this.$notify('Failed to Send Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setUnsentRecords(state, value) {
    state.unsentRecords = value;
  },
  setInvoiceIds(state, value) {
    state.invoiceIds = value;
  },
  reset(state) {
    state.tableData = [];
    state.unsentRecords = false;
    state.invoiceIds = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};