import {
  tradeDateRange, coordinatorList, resourcesTagbox, marketTypes,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { has } from '@/utils/dataUtil';

export default {
  dockConfig: {
    alignment: 'LEFT',
    visible: false,
  },
  fields: [{
    caption: 'BA',
    dataType: 'string',
    dataField: 'ba',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataType: 'datetime',
    format: 'yyyy-MM-dd',
    dataField: 'tzTradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'GMT START HOUR',
    dataType: 'number',
    dataField: 'gmtStartHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'GMT START INTERVAL',
    dataType: 'number',
    dataField: 'gmtStartInterval',
    area: 'row',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataType: 'string',
    dataField: 'marketType',
    area: 'row',
    expanded: true,
  }, {
    caption: 'RESOURCE',
    dataType: 'string',
    dataField: 'resource',
    area: 'column',
    expanded: true,
  }, {
    caption: 'EXECUTION TYPE',
    dataType: 'string',
    dataField: 'executionType',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'PRODUCT',
    dataType: 'string',
    dataField: 'product',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'MOVEMENT POINT',
    dataType: 'string',
    dataField: 'movementPoint',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'CONFIGURATION',
    dataType: 'string',
    dataField: 'configuration',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'MW',
    dataType: 'number',
    format: '#0.##',
    dataField: 'mw',
    area: 'data',
    expanded: true,
  }],
  searchFilterConfig: {
    tradeDateRange,
    coordinatorList,
    marketTypes: {
      ...marketTypes,
      options: [...marketTypes.options, { value: 'FMM', label: 'FMM' }],
    },
    resourceList: resourcesTagbox,
  },
};
