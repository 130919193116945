import moment from 'moment';
import { getMomentDateString } from '@/utils/dateUtil';
import { saveAs } from 'file-saver';
import { VA_API } from '@/api';
import { formatReadableUSD } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';

/*
* converts an svg string to base64 png using the domUrl
* @param {string} svgText the svgtext
* @return {Promise} a promise to the bas64 png image
*/
export const svgToPng = (svgText) => new Promise(((resolve, reject) => {
  try {
    const domUrl = window.URL || window.webkitURL || window;
    // figure out the height and width from svg text
    const matchH = svgText.match(/ height="(\d+)/m);
    const height = matchH && matchH[1] ? Number(matchH[1]) + 1200 : 200;
    const matchW = svgText.match(/ width="(\d+)/m);
    const width = matchW && matchW[1] ? Number(matchW[1]) + 1000 : 200;
    // it needs a namespace
    if (!svgText.match(/xmlns="/mi)) {
      svgText = svgText.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
    }
    // create a canvas element to pass through
    const canvas = document.createElement('canvas');
    canvas.width = height;
    canvas.height = width;
    const ctx = canvas.getContext('2d');
    // make a blob from the svg
    const svg = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' });
    // create a dom object for that image
    const url = domUrl.createObjectURL(svg);
    // create a new image to hold it the converted type
    const img = new Image();
    // when the image is loaded we can get it as base64 url
    img.onload = function () {
      // draw it to the canvas
      ctx.drawImage(this, 0, 0);
      // we don't need the original any more
      domUrl.revokeObjectURL(url);
      // now we can resolve the promise, passing the base64 url
      resolve(canvas.toDataURL());
      return canvas.toDataURL();
    };
    // load the image
    img.src = url;
  } catch (err) {
    reject(new Error(`failed to convert svg to png ${err}`));
  }
}));

export const workbookGenerator = async (image = null) => {
  const ExcelJS = await import('exceljs');
  const wb = new ExcelJS.Workbook();
  wb.creator = 'Power Settlements';
  wb.lastModifiedBy = 'Power Settlements';
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();

  if (image) {
    // Creates visual sheet
    const chartSheet = wb.addWorksheet('Charts');
    // svg images are identified with a '<' at image[0] and must be converted to base64png
    const base64png = image[0] === '<' ? await svgToPng(image) : image;
    const visualAnalysisChart = wb.addImage({ base64: base64png, extension: 'png' });
    const img = new Image();
    img.src = base64png;
    img.onload = await function () { };
    chartSheet.addImage(visualAnalysisChart, {
      tl: { col: 0, row: 0 },
      ext: { width: img.width, height: img.height },
    });
  }
  return wb;
};

export const exportToType = async (fileName, exportData, svg, type) => {
  const workbook = type === 'csv' ? await workbookGenerator() : await workbookGenerator(svg);

  // Creates data sheet
  const analysisSheet = workbook.addWorksheet('Daily Charts', {
    views: [{ state: 'frozen', ySplit: 1 }],
  });
  analysisSheet.columns = exportData.columns;
  const header = analysisSheet.getRow(1);
  header.font = { bold: true, size: 10, name: 'Arial' };
  header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };
  exportData.rows.forEach((r) => {
    if (!r.company) {
      r.company = exportData.location.entityName;
    }
    analysisSheet.addRow(r);
  });
  analysisSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;
    row.font = { bold: false, size: 10, name: 'Arial' };
  });

  // Download sheet
  workbook[type].writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.${type}`);
  });
};

export const exportToEXCELOutages = async (fileName, exportData, svg, type) => {
  if (!exportData.length) return;
  let workbook;
  if (type === 'csv') workbook = await workbookGenerator();
  else workbook = await workbookGenerator(svg);

  const excludeColumns = ['DATE_TIME_POINT', 'BarColor'];
  // Creates data sheet
  const analysisSheet = workbook.addWorksheet('Outages Charts', {
    views: [{ state: 'frozen', ySplit: 1 }],
  });
  analysisSheet.columns = Object.keys(exportData[0])
    .filter((key) => !excludeColumns.includes(key))
    .map((key) => ({ header: key, key, width: 20 }));
  const header = analysisSheet.getRow(1);
  header.font = { bold: true, size: 10, name: 'Arial' };
  header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };
  exportData.forEach((r) => {
    r.Period_Start = moment(r.Period_Start).format('YYYY-MM-DD[T]hh:mm');
    r.Period_End = moment(r.Period_End).format('YYYY-MM-DD[T]hh:mm');
    analysisSheet.addRow(r);
  });
  analysisSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;
    row.font = { bold: false, size: 10, name: 'Arial' };
  });

  // Download sheet
  workbook[type].writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.${type}`);
  });
};

export const exportToEXCELPortfolio = async (fileName, type, dataColumns, chartData, svg) => {
  const workbook = type === 'csv' ? await workbookGenerator() : await workbookGenerator(svg);
  // Creates data sheet
  const analysisSheet = workbook.addWorksheet('Portfolio Charts', {
    views: [{ state: 'frozen', ySplit: 1 }],
  });
  const columns = [{
    header: 'Date', key: 'tradingDate', width: 11,
  }, {
    header: 'Hour', key: 'tradingHour', width: 6,
  }, {
    header: 'Interval', key: 'tradingInterval', width: 7,
  }, {
    header: 'Location', key: 'location', width: 7,
  }];
  columns.push(
    ...dataColumns
      .filter((key) => !['subType', 'objectReference', 'variant', 'startTime', 'endTime', 'type']
        .includes(key)).map((key) => ({ header: key, key, width: 14 })),
  );
  analysisSheet.columns = columns;
  const header = analysisSheet.getRow(1);
  header.font = { bold: true, size: 10, name: 'Arial' };
  header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };
  chartData.forEach((data) => {
    data.tradingDate = moment(data.time).format('L');
    data.tradingHour = moment(data.time).hours() + 1;
    data.tradingInterval = Number(data.time.split(':')[1]) || 0;
    analysisSheet.addRow(data);
  });
  analysisSheet.eachRow((row, rowNumber) => {
    if (rowNumber === 1) return;
    row.font = { bold: false, size: 10, name: 'Arial' };
  });

  // Download sheet
  workbook[type].writeBuffer().then((data) => {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.${type}`);

    // Then delete generated fields
    chartData.forEach((d) => {
      delete d.tradingDate;
      delete d.tradingHour;
      delete d.tradingInterval;
    });
  });
};

export const exportAllToExcel = async (context) => {
  const chartSeriesIds = context.selectedLayout.charts
    .map(({ chartSeries }) => chartSeries)
    .flat()
    .map(({ seriesId }) => seriesId)
    .join(',');
  const locations = context.groupedLocations ? context.groupedLocations : context.locations;
  const params = {
    startTime: context.selectedDate,
    endTime: moment(context.selectedDate).add(1, 'day').toISOString(),
    timeZone: dateStore.getTimeZone(),
    variants: context.selectedVariant,
    objectReferences: chartSeriesIds,
    objectTypes: 'series',
    types: locations.map(({ shortName }) => shortName).join(','),
  };
  const { data: { data } } = await VA_API.post('intervals/data', params);
  // Groups locations' data
  let groupedData = {};
  for (let i = 0; i < data.length; i++) {
    const interval = data[i];
    // Flattens series value
    Object.entries(interval).forEach(([key, val]) => {
      if (val && typeof val === 'object' && 'value' in val) {
        interval[key] = val.value;
      }
    });
    if (groupedData[interval.type]) {
      groupedData[interval.type].push(interval);
    } else {
      groupedData[interval.type] = [interval];
    }
  }
  // Alphabetizes the object by key
  groupedData = Object.keys(groupedData)
    .sort()
    .reduce((acc, key) => {
      acc[key] = groupedData[key];
      return acc;
    }, {});
  // Sorts location data by time
  Object.entries(groupedData).forEach(([location, intervals]) => {
    groupedData[location] = intervals.sort((a, b) => {
      if (a.startTime < b.startTime) return -1;
      if (a.startTime > b.startTime) return 1;
      return 0;
    });
  });

  const workbook = await workbookGenerator();
  // Creates data sheet
  const dataSheet = workbook.addWorksheet('Data', {
    views: [{ state: 'frozen', ySplit: 1, xSplit: 2 }],
  });
  const columns = [{
    header: 'SC', key: 'entityName', width: 12,
  }, {
    header: 'Location', key: 'location', width: 16,
  }, {
    header: 'Date', key: 'tradingDate', width: 12,
  }, {
    header: 'Hour', key: 'tradingHour', width: 6,
  }, {
    header: 'Interval', key: 'tradingInterval', width: 7,
  }];
  context.chartsTreeChartSeries.forEach((chartSeries) => {
    columns.push({
      header: chartSeries.text, key: chartSeries.seriesName, width: 12,
    });
  });
  dataSheet.columns = columns;
  const header = dataSheet.getRow(1);
  header.font = { bold: true, size: 10, name: 'Arial' };
  header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };
  Object.entries(groupedData).forEach(([location, intervals]) => {
    const locationObj = context.locations.find(({ shortName }) => shortName === location);
    intervals.forEach((interval) => {
      interval.entityName = locationObj.entityName;
      interval.location = locationObj.shortName;
      interval.tradingDate = dateStore.toMoment(interval.startTime).format('L');
      interval.tradingHour = interval.he;
      interval.tradingInterval = interval.ie;
      dataSheet.addRow(interval);
    });
  });
  // Download sheet
  const nowDateString = moment().format('YYYY-MM-DD_HH;mm;ss');
  const fileName = `${getMomentDateString(context.selectedDate)}_${context.selectedVariant}_${nowDateString}`;
  workbook.xlsx.writeBuffer()
    .then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
};
