import moment from 'moment';
import utils from '@/utils';
import { clone, isEqual } from '@/utils/dataUtil';
import * as tagUtils from '@/utils/etag/tagUtils';
import * as profileUtils from '@/utils/etag/profileUtils';
import dateStore from '@/utils/dateStore';
import helpers from '@/utils/helpers';
import { ETAG_API } from '@/api';
import { VIEWS } from './constants';

const ST_COL = {
  prop: 'startTime',
  label: 'start',
  width: 60,
  minWidth: 60,
  maxWidth: 60,
  visible: true,
  editable: false,
  p_type: 'time',
  guid: 'start',
  position: 0,
  summaryColSpan: 3,
};
const ET_COL = {
  prop: 'endTime',
  label: 'end',
  width: 60,
  minWidth: 60,
  maxWidth: 60,
  visible: true,
  editable: false,
  p_type: 'time',
  guid: 'end',
  position: 1,
  summaryColSpan: 0,
};
const HE_COL = {
  prop: 'he',
  label: 'he',
  width: 35,
  minWidth: 35,
  maxWidth: 35,
  visible: true,
  editable: false,
  p_type: 'time',
  guid: 'he',
  position: 2,
  summaryColSpan: 0,
};
const GEN_COL = {
  prop: 'source',
  label: 'source',
  visible: true,
  editable: true,
  p_type: 'gen',
  guid: 'gen',
  position: 3,
  profileProductType: 'ENERGY',
};
const LOAD_COL = {
  prop: 'sink',
  label: 'sink',
  visible: true,
  editable: false,
  p_type: 'load',
  guid: 'load',
  position: 4,
  profileProductType: 'ENERGY',
};

const ST_DT_COL = {
  prop: 'startTime',
  label: 'start',
  width: 150,
  visible: true,
  editable: true,
  p_type: 'time',
  guid: 'start',
  position: 0,
  type: { name: 'PscsDateTimeCellRenderer' },
};
const ET_DT_COL = {
  prop: 'endTime',
  label: 'end',
  width: 150,
  visible: true,
  editable: true,
  p_type: 'time',
  guid: 'end',
  position: 1,
  type: { name: 'PscsDateTimeCellRenderer' },
};

const START_RAMP = {
  prop: 'rampStart', label: 'start', visible: true, editable: true, p_type: 'rampStart', guid: 'rampStart', position: 2,
};
const STOP_RAMP = {
  prop: 'rampStop', label: 'stop', visible: true, editable: true, p_type: 'rampStop', guid: 'rampStop', position: 3,
};

const defaultForceProfileColumns = [ST_COL, ET_COL, GEN_COL];
const defaultDailyProfileColumns = [ST_COL, ET_COL, HE_COL, GEN_COL, LOAD_COL];
const defaultRangeColumns = [ST_DT_COL, ET_DT_COL, GEN_COL, LOAD_COL];
const defaultRampColumns = [ST_COL, ET_COL, START_RAMP, STOP_RAMP];

function getObjectDefinition(columns) {
  const obj = {};
  for (let cx = 0; cx < columns.length; cx++) {
    const col = columns[cx];
    if (col.isParent && col.isParent === true) continue;
    obj[col.prop] = null;
  }

  return obj;
}

const HOUR_MIN = 'HH:mm';
const DATE_HOUR_MIN = 'YYYY-MM-DD HH:mm';
const DATE_HOUR_MIN_TZ = 'YYYY-MM-DDTHH:mm:ssZ';

function allProfilesDisplayArray(columns, profiles, minutes, format) {
  const startTime = tagUtils.findMinStartTime(profiles);
  const endTime = tagUtils.findMaxEndTime(profiles);
  let currentTime = startTime.clone();
  const obj = getObjectDefinition(columns);

  const data = [];
  while (true) {
    if (currentTime.isSame(endTime)) { break; }

    const item = { ...obj };
    const start = currentTime.clone();
    const end = currentTime.clone().add(minutes, 'minutes');

    item.momentStartTime = start;
    item.startTime = start.format(format);
    item.momentEndTime = end;
    item.endTime = end.format(format);

    currentTime = currentTime.clone().add(minutes, 'minutes');

    const prof = helpers.filter(profiles, (p) => p.momentEndTime > start && p.momentStartTime < end);

    if (prof.length === 1) {
      tagUtils.copyPropertyValues(columns, prof[0], item);
      if (prof[0].momentEndTime < item.momentEndTime) {
        item.momentEndTime = prof[0].momentEndTime.clone();
        item.endTime = prof[0].momentEndTime.clone().format(format);
        data.push(item);

        const l = { ...obj };
        l.momentStartTime = prof[0].momentEndTime.clone();
        l.momentEndTime = end.clone();
        l.startTime = prof[0].momentEndTime.clone().format(format);
        l.endTime = end.clone().format(format);
        data.push(l);
      } else {
        data.push(item);
      }
    } else if (prof.length > 1) {
      for (let px = 0; px < prof.length; px++) {
        const p = prof[px];
        const l = { ...obj };
        l.momentStartTime = p.momentStartTime.clone();
        l.momentEndTime = p.momentEndTime.clone();
        l.startTime = p.momentStartTime.clone().format(format);
        l.endTime = p.momentEndTime.clone().format(format);
        tagUtils.copyPropertyValues(columns, p, l);
        data.push(l);
      }
    } else {
      data.push(item);
    }
  }

  return data;
}

function toDisplayArray(viewName, columns, profiles, date, minutes, format) {
  let data = [];
  const obj = getObjectDefinition(columns);
  if (viewName === VIEWS.DAILY) {
    data = profileUtils.toProfileGranularity(columns, profiles, date, minutes, format, true);
  } else if (viewName === VIEWS.RANGE) {
    // if short day or long day roll the date back 1 day
    // this is purely so the range table renders as a 24
    // hour day(as opposed to 23 or 25)
    if (dateStore.isShortDay(date) || dateStore.isLongDay(date)) {
      date.subtract(1, 'days');
    }
    data = profileUtils.toProfileGranularity(columns, profiles, date, '60', format, true);
  } else {
    for (let px = 0; px < profiles.length; px++) {
      const p = profiles[px];
      const l = { ...obj };
      l.momentStartTime = p.momentStartTime.clone();
      l.momentEndTime = p.momentEndTime.clone();
      l.startTime = dateStore.toLocalFromDate(p.momentStartTime.clone()).format(format);
      l.endTime = dateStore.toLocalFromDate(p.momentEndTime.clone()).format(format);
      tagUtils.copyPropertyValues(columns, p, l);
      data.push(l);
    }
  }
  return data;
}

function highlightInvalidRamps(displayArray, profiles) {
  const { tz } = dateStore.getTimeZoneDefinition();

  displayArray.forEach((row) => {
    const startStop = (row.rampStart / 2) + (row.rampStop / 2);
    const start = utils.date.toMomentFromDate(row.startTime, tz);
    const end = utils.date.toMomentFromDate(row.endTime, tz);
    const duration = utils.date.duration(start, end);
    const min = duration.asMinutes();
    row.highlight = [];
    if (startStop > Math.min(60, min)) {
      profiles.forEach((prof) => {
        row.highlight.push('rampStart');
        row.highlight.push('rampStop');
      });
    }
  });
  return displayArray;
}

function splitProfilesToHourlySegments(profiles) {
  const hourlyProfiles = profiles.reduce((acc, profile) => {
    // get the profile's start and end time difference
    const timeDifferenceInHours = profile.momentEndTime.diff(profile.momentStartTime, 'hours');

    // if profile already spans only one hour
    if (timeDifferenceInHours === 1) {
      acc.push(profile);
    } else {
      // split profile to hourly segments
      for (let i = 0; i < timeDifferenceInHours; i++) {
        const momentStartTime = profile.momentStartTime.clone().add(i, 'hours');
        const momentEndTime = momentStartTime.clone().add(1, 'hours');
        const startTime = momentStartTime.clone().toISOString();
        const endTime = momentEndTime.clone().toISOString();

        // with the exception of start and end times the
        // hourly profiles should be identical to profile
        const hourlyProfile = {
          ...profile,
          ...{
            startTime,
            endTime,
            momentStartTime,
            momentEndTime,
          },
        };

        acc.push(hourlyProfile);
      }
    }

    return acc;
  }, []);
  return hourlyProfiles;
}

function consolidateHourlyProfiles(hourlyProfiles) {
  const sortedProfiles = helpers.sortBy(hourlyProfiles, (o) => o.momentStartTime);

  const consolidatedProfiles = sortedProfiles.reduce((acc, nextProfile) => {
    const lastProfile = acc.pop();

    if (!lastProfile) {
      // if it's the first profile in the iteration then just add it to the accumulator
      acc.push(nextProfile);
    } else {
      // compare last profile to next profile
      const isTimeSequential = lastProfile.momentEndTime.toISOString() === nextProfile.momentStartTime.toISOString();

      const timeProperties = ['startTime', 'endTime', 'momentStartTime', 'momentEndTime'];

      // 'other' meaning the non time properties
      const allOtherPropertiesEqual = Object.keys(lastProfile)
        .filter((prop) => !timeProperties.includes(prop))
        .every((prop) => lastProfile[prop] === nextProfile[prop]);

      if (isTimeSequential && allOtherPropertiesEqual) {
        const { endTime, momentEndTime } = nextProfile;
        // update the end times on last profile
        const consolidatedProfile = {
          ...lastProfile,
          ...{
            endTime,
            momentEndTime,
          },
        };
        acc.push(consolidatedProfile);
      } else {
        // push both profiles as separate profiles
        acc.push(lastProfile);
        acc.push(nextProfile);
      }
    }

    return acc;
  }, []);
  return consolidatedProfiles;
}

function mergeRangeProfiles(currentProfiles, newProfiles) {
  // check if current profiles are from initial state
  const isInitialProfileState = currentProfiles.filter((p) => p.isDefaultProfile).length > 0;
  const profiles = isInitialProfileState ? [] : currentProfiles;

  // split current and new profiles into hourly segments
  const profilesHourly = splitProfilesToHourlySegments(profiles);
  const newProfilesHourly = splitProfilesToHourlySegments(newProfiles);

  // get only the profiles that do not have overlap with the new profiles
  // new profiles take precedence if there is overlap
  const distinctProfilesHourly = profilesHourly.reduce((acc, profile) => {
    const hasOverlap = newProfilesHourly
      .filter((p) => p.momentStartTime.toISOString() === profile.momentStartTime.toISOString()
                  && p.momentEndTime.toISOString() === profile.momentEndTime.toISOString())
      .length > 0;

    if (!hasOverlap) {
      acc.push(profile);
    }
    return acc;
  }, []);

  const mergedProfilesHourly = [...distinctProfilesHourly, ...newProfilesHourly];
  const consolidatedProfiles = consolidateHourlyProfiles(mergedProfilesHourly);
  return consolidatedProfiles;
}

/**
 * Gets an array of physicalPathObjects for each unique (POR -> POD, SE, TP) set in the data.
 * Ex: [
 *      {
 *          se:"some se",
 *          tp:"some tp",
 *          "column": { label:"some label", level:"some level", isParent:true}
 *      },
 *      ...
 * ]
 * @param {any} data - trans data.
 * @param {number} levelOffset - the offset level that the column should start at.
 * @return - an array of physicalPathObjects for each unique (POR -> POD, SE, TP) set in the data.
 */
function getPhysicalPathObjects(data, levelOffset = 0) {
  const physicalPathObjects = [];

  // get the unique physical paths
  let physicalPaths = data.map((n) => {
    if (n.physical_se_name !== undefined
      && n.physical_tp_name !== undefined
      && n.physical_por_name !== undefined
      && n.physical_pod_name !== undefined
      && n.physical_se_name !== null
      && n.physical_tp_name !== null
      && n.physical_por_name !== null
      && n.physical_pod_name !== null
      && n.physical_se_name !== ''
      && n.physical_tp_name !== ''
      && n.physical_por_name !== ''
      && n.physical_pod_name !== '') {
      return {
        se: n.physical_se_name, tp: n.physical_tp_name, por: n.physical_por_name, pod: n.physical_pod_name,
      };
    }
    return undefined;
  }).filter((val) => val !== undefined); // filter out undefined objects

  // remove duplicate physical paths
  physicalPaths = [...new Set(physicalPaths.map((obj) => JSON.stringify(obj)))].map((obj) => JSON.parse(obj));

  // create the physical path objects
  for (let i = 0; i < physicalPaths.length; i++) {
    const pp = physicalPaths[i];

    physicalPathObjects.push({
      se: pp.se,
      tp: pp.tp,
      por: pp.por,
      pod: pp.pod,
      column: { label: `${pp.por} -> ${pp.pod}`, level: `${i + levelOffset}`, isParent: true },
    });
  }

  return physicalPathObjects;
}

function getColumns(state, path) {
  const newColumns = [];
  let startDate = helpers.clone(ST_DT_COL);
  let endDate = helpers.clone(ET_DT_COL);
  const he = helpers.clone(HE_COL);
  let physicalPathObjects = [];

  if ([VIEWS.DAILY, VIEWS.RANGE, VIEWS.TERM].includes(state.profileViewName)) {
    startDate = helpers.clone(ST_COL);
    endDate = helpers.clone(ET_COL);
  } else if (state.profileViewName === VIEWS.RAMP) {
    startDate.editable = false;
    endDate.editable = false;
  }

  newColumns.push(startDate);
  newColumns.push(endDate);

  if ([VIEWS.DAILY, VIEWS.RANGE, VIEWS.TERM].includes(state.profileViewName)) newColumns.push(he);

  const genColumn = helpers.clone(GEN_COL);
  const loadColumn = helpers.clone(LOAD_COL);

  const gen = path[0];
  const load = path[path.length - 1];

  genColumn.label = gen.physical_source_name;
  loadColumn.label = load.physical_sink_name;

  newColumns.push(genColumn);

  let pos = newColumns.length - 1;
  if ([VIEWS.DAILY, VIEWS.CONSOLIDATED, VIEWS.RANGE, VIEWS.TERM].includes(state.profileViewName)) {
    if ([VIEWS.DAILY, VIEWS.RANGE, VIEWS.TERM].includes(state.profileViewName)) {
      startDate.level = '0';
      endDate.level = '1';
      he.level = '2';
      genColumn.level = '3';
    } else if (state.profileViewName === VIEWS.CONSOLIDATED) {
      startDate.level = '0';
      endDate.level = '1';
      genColumn.level = '2';
      genColumn.positon = 2;
    }

    physicalPathObjects = getPhysicalPathObjects(path, pos + 1);
    physicalPathObjects.forEach((element) => {
      newColumns.push(element.column);
    });

    loadColumn.level = `${pos + 1 + physicalPathObjects.length}`;
  }

  for (let x = 1; x < (path.length - 1); x++) {
    const item = path[x];

    if (item.trans_contract !== null && item.trans_contract !== '') {
      const col = {
        prop: item.guid,
        label: item.trans_contract,
        trans_id: item.trans_id,
        visible: (state.profileViewName !== VIEWS.RAMP),
        p_type: 'tran',
        editable: true,
        position: pos,
        profileProductType: 'CAPACITY',
      };
      const ppObj = physicalPathObjects.find((o) => (
        o.se === item.physical_se_name
        && o.tp === item.physical_tp_name
        && o.por === item.physical_por_name
        && o.pod === item.physical_pod_name
      ));
      if (ppObj) {
        col.parent = ppObj.column.level;
        col.level = `${ppObj.column.level}-1`;
      }
      newColumns.push(col);
      pos++;
    }
  }

  newColumns.push(loadColumn);

  if (state.profileViewName === VIEWS.RAMP) {
    newColumns.push(helpers.clone(START_RAMP));
    newColumns.push(helpers.clone(STOP_RAMP));
    genColumn.visible = false;
  }

  return newColumns;
}

const state = {
  nullDailyProfileRow: {
    momentStartTime: null,
    startTime: null,
    momentEndTime: null,
    endTime: null,
    he: null,
    gen: null,
    load: null,
  },
  maxPorProfileRef: null,
  onPeakHourRanges: [],
  offPeakHourRanges: [],
  onPeakHoursMap: {},
  offPeakHoursMap: {},
  profileViewName: VIEWS.DAILY,
  dailyProfileDate: null,
  dailyProfileDateMoment: null,
  previousProfiles: null,
  profileDateRange: null,
  comparePreviousFlag: false,
  table: {
    columns: helpers.clone(defaultDailyProfileColumns),
    keys: ['momentStartTime', 'momentEndTime'],
    options: {},
    style: {
      dynamicSizing: false,
      maxHeight: 780,
    },
  },
  profiles: [],
  deleteProfileCache: [],
  profileRange: [],
  timeGranularity: '60',
  timeGranularityList: [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '30', label: '30' },
    { value: '60', label: '60' },
  ],
  path: [],
  profileGraphData: {
    labels: [],
    datasets: [{
      label: 'Reliability',
      data: [],
      type: 'line',
      borderColor: '#333CFF',
      backgroundColor: '#333CFF',
      cubicInterpolationMode: 'monotone',
      fill: false,
      steppedLine: true,
    }, {
      label: 'Market',
      data: [],
      type: 'line',
      borderColor: '#14e586',
      backgroundColor: '#14e586',
      cubicInterpolationMode: 'monotone',
      fill: false,
      steppedLine: true,
    }, {
      label: 'Current',
      data: [],
      backgroundColor: '#f87979',
    }],
  },
  modelProfileSet: [],
  selectedForceProfiles: [],
  originalProfiles: [],
  transmissionIsNull: [],
  termInputTable: [],
  profileViewIndex: 0,
};

const getters = {
  getViewName: (state) => state.profileViewName,
  getViewIndex: (state) => state.profileViewIndex,
  getTableConfiguration(state, readonly = true) {
    const configuration = helpers.cloneDeep(state.table);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullDailyProfileRow);
    }

    if ([VIEWS.DAILY, VIEWS.CONSOLIDATED, VIEWS.RANGE, VIEWS.TERM].includes(state.profileViewName)) {
      const options = {
        summaryRows: true,
        warningHighlight: true,
        displayIcon: true,
        cellStyling: true,
      };
      configuration.options = options;
    } else if (state.profileViewName === VIEWS.RAMP) {
      const options = {
        warningHighlight: true,
        cellStyling: true,
      };
      configuration.options = options;
    }

    return configuration;
  },
  getForceProfileTableConfiguration(state) {
    const configuration = helpers.cloneDeep(state.table);
    tagUtils.setEditableFalse(configuration, state.nullDailyProfileRow);
    configuration.columns = configuration.columns.slice(0, 3);
    configuration.columns[0].width = 100;
    configuration.columns[1].width = 100;
    configuration.options = {
      multipleSelection: true,
    };
    return configuration;
  },
  getTimeGranularityList: (state) => state.timeGranularityList,
  getComparePreviousFlag: (state) => state.comparePreviousFlag,
  getTimeGraunalrity: (state) => state.timeGranularity,
  getProfileDateRange: (state) => state.profileDateRange,
  getDailyDate: (state) => state.dailyProfileDate,
  getForceProfileData(state) {
    return allProfilesDisplayArray(state.table.columns, state.profiles, 60, DATE_HOUR_MIN);
  },

  getTermInputTable() {
    // relevant input properties are defined by the table (column) configuration
    // exclude parent and other irrelevant columns
    const relevantColumns = state.table.columns.filter((column) => !column.isParent
    && !['startTime', 'endTime', 'he'].includes(column.prop));

    // compare the relevant columns to current term input table
    const relevantProperties = relevantColumns.map((column) => column.prop).sort();
    // 'highlight' key is something we add for validation and not something we want to use to compare
    const existingProperties = state.termInputTable.length > 0
      ? Object.keys(state.termInputTable[0]).filter((key) => key !== 'highlight').sort() : [];

    // if the relevant and existing properties are the same, return the current term input table
    if (JSON.stringify(relevantProperties) === JSON.stringify(existingProperties)) {
      return state.termInputTable;
    }

    // otherwise, construct new term input table
    const inputScope = { highlight: [] }; // highlight property is used for validation
    relevantColumns.forEach((column) => {
      // initialize value input property to null (blank)
      inputScope[column.prop] = null;
    });

    // return one row with relevant properties
    return [inputScope];
  },
  getData(state, readonly = true, transmissions) {
    const date = state.dailyProfileDateMoment.clone();

    if (state.profileViewName === VIEWS.DAILY || state.profileViewName === VIEWS.TERM) {
      const data = toDisplayArray(
        state.profileViewName,
        state.table.columns,
        state.profiles,
        date,
        state.timeGranularity,
        HOUR_MIN);

      if (state.comparePreviousFlag && state.previousProfiles && state.previousProfiles.length > 0) {
        const previousProfileArray = toDisplayArray(
          state.profileViewName,
          state.table.columns,
          state.previousProfiles,
          date,
          state.timeGranularity,
          HOUR_MIN);
        utils.data.findDifferences(data, previousProfileArray, 'displayIcon');
      }

      if (state.originalProfiles && state.originalProfiles.length > 0) {
        const ogp = toDisplayArray(
          state.profileViewName,
          state.table.columns,
          state.originalProfiles,
          date,
          state.timeGranularity,
          HOUR_MIN);
        return utils.data.findDifferences(
          profileUtils.highlightEnergyGreaterThanCapacity(transmissions, data),
          ogp,
          'cellStyle',
          'cell-diff');
      }

      return profileUtils.highlightEnergyGreaterThanCapacity(transmissions, data);
    }
    if (state.profileViewName === VIEWS.RANGE) {
      const data = toDisplayArray(
        state.profileViewName,
        state.table.columns,
        state.deleteProfileCache,
        date,
        state.timeGranularity, HOUR_MIN);
      return profileUtils.highlightEnergyGreaterThanCapacity(transmissions, data);
    }
    if (state.profileViewName === VIEWS.CONSOLIDATED) {
      if (readonly) {
        const data = toDisplayArray(
          state.profileViewName,
          state.table.columns,
          state.profiles,
          date,
          state.timeGranularity,
          DATE_HOUR_MIN);
        if (state.comparePreviousFlag && state.previousProfiles && state.previousProfiles.length > 0) {
          const previousProfileArray = toDisplayArray(
            state.profileViewName,
            state.table.columns,
            state.previousProfiles,
            date,
            state.timeGranularity,
            DATE_HOUR_MIN);
          utils.data.findDifferences(data, previousProfileArray, 'displayIcon');
        }
        return profileUtils.highlightEnergyGreaterThanCapacity(transmissions, data);
      }
      const data = toDisplayArray(
        state.profileViewName,
        state.table.columns,
        state.profiles,
        date,
        state.timeGranularity,
        DATE_HOUR_MIN_TZ);
      if (state.comparePreviousFlag && state.previousProfiles && state.previousProfiles.length > 0) {
        const previousProfileArray = toDisplayArray(
          state.profileViewName,
          state.table.columns,
          state.previousProfiles,
          date,
          state.timeGranularity,
          DATE_HOUR_MIN_TZ);
        utils.data.findDifferences(data, previousProfileArray, 'displayIcon');
      }
      return profileUtils.highlightEnergyGreaterThanCapacity(transmissions, data);
    }
    if (state.profileViewName === VIEWS.RAMP) {
      const data = toDisplayArray(
        state.profileViewName,
        state.table.columns,
        state.profiles,
        date,
        state.timeGranularity,
        DATE_HOUR_MIN);
      return highlightInvalidRamps(data, state.profiles);
    }
    return [];
  },
  getProfileGraphData(state) {
    const { tz } = dateStore.getTimeZoneDefinition();
    const start = dateStore.toMoment(state.profileDateRange[0]);
    const end = dateStore.toMoment(state.profileDateRange[1]).endOf('day');
    const chartData = {};

    // Creates object of times to group up data
    state.profileGraphData.datasets.forEach((gData, idx) => {
      state.profileGraphData.datasets[idx].data.forEach(({ t, y }) => {
        const momentTime = utils.date.toMoment(t, ['YYYY-MM-DD H:mm', 'YYYY-MM-DD HH:mm'], tz);
        if (
          (start.isBefore(momentTime) && end.isAfter(momentTime))
            || start.isSame(momentTime)
        ) {
          const time = momentTime.format('YYYY-MM-DD HH:mm');
          if (chartData[time]) chartData[time][gData.label] = y;
          else chartData[time] = { time, [gData.label]: y };
        }
      });
    });

    // Turns grouped data into array and sorts by time
    return Object.values(chartData).sort((a, b) => {
      if (a.time < b.time) return -1;
      if (a.time > b.time) return 1;
      return 0;
    });
  },
  getProfileSeriesOptions(state) {
    return state.profileGraphData.datasets.reduce((acc, item) => {
      if (item.label === 'Current') item.chartType = 'line';
      else item.chartType = 'bar';

      acc.push({
        label: item.label || item.resourceGroupName,
        prop: item.label,
        visible: true,
        showInLegend: true,
        ...item,
      });
      return acc;
    }, []);
  },
  getSummaryData(state) {
    const columnDictionary = tagUtils.createProfileColumnDictionary(state.table.columns, state.profiles);
    const date = dateStore.toDateFromLocal(state.dailyProfileDate);
    const profiles = toDisplayArray(
      state.profileViewName,
      state.table.columns,
      state.profiles,
      date,
      state.timeGranularity,
      HOUR_MIN);
    const onPeakMatchingHours = profileUtils.CalculateDailySummary(state.onPeakHourRanges, profiles, columnDictionary);
    const offPeakMatchingHours = profileUtils.CalculateDailySummary(
      state.offPeakHourRanges,
      profiles,
      columnDictionary);
    const summaryTotals = profileUtils.TotalDailySummary(columnDictionary, onPeakMatchingHours, offPeakMatchingHours);

    return summaryTotals;
  },
  getConsolidatedSummaryData(state) {
    const consolidatedSummaryTotals = [[], [], []];
    const [start, end] = state.profileDateRange;
    const startStr = typeof (start) === 'string' ? start.split(' ')[0] : start.toISOString ? start.toISOString() : undefined;
    const endStr = typeof (end) === 'string' ? end.split(' ')[0] : end.toISOString ? end.toISOString() : undefined;
    if (start) {
      const mtStart = moment(startStr);
      const mtEnd = end ? moment(endStr) : moment(startStr);
      const columnDictionary = tagUtils.createProfileColumnDictionary(state.table.columns, state.profiles);
      while (mtEnd.diff(mtStart, 'days') >= 0) {
        const profiles = toDisplayArray(
          'tag-profile-daily',
          state.table.columns,
          state.profiles,
          mtStart,
          state.timeGranularity,
          HOUR_MIN,
        );

        // Get daily summary total for mtStart
        const currentOnPeakRange = state.onPeakHoursMap[mtStart.format('MM/DD/YYYY')];
        const currentOffPeakRange = state.offPeakHoursMap[mtStart.format('MM/DD/YYYY')];
        if (!currentOnPeakRange || !currentOffPeakRange) return;

        const onPeakMatchingHours = profileUtils.CalculateDailySummary(currentOnPeakRange, profiles, columnDictionary);
        const offPeakMatchingHours = profileUtils.CalculateDailySummary(currentOffPeakRange, profiles, columnDictionary);

        const currentTotals = profileUtils.TotalDailySummary(columnDictionary, onPeakMatchingHours, offPeakMatchingHours);

        // Aggregate consolidated summary totals
        currentTotals.forEach((total, index) => {
          consolidatedSummaryTotals[index].push(total);
        });
        mtStart.add(1, 'days');
      }
      const consolidatedSummaries = consolidatedSummaryTotals.map((total) => total.reduce((acc, curr) => {
        Object.keys(curr).forEach((key) => {
          if (!acc[key]) {
            acc[key] = curr[key];
          } else if (!['startTime', 'summaryFlag'].includes(key)) {
            acc[key] += curr[key];
          }
        });
        return acc;
      }, {}));
      consolidatedSummaries[2].startTime = 'Total';
      return consolidatedSummaries;
    }
  },
  getTimeRangeDisplay(state) {
    if (state.profiles.length > 0) {
      const starttimes = helpers.map(state.profiles, 'momentStartTime');
      const endtimes = helpers.map(state.profiles, 'momentEndTime');

      const startTime = moment.min(starttimes);
      const endTime = moment.max(endtimes);

      const st = startTime.clone().format('YYYY-MM-DD HH:mm');
      const et = endTime.clone().format('YYYY-MM-DD HH:mm');
      // if (state.profileDateRange){
      //     st = moment(state.profileDateRange[0]).format('YYYY-MM-DD HH:mm');
      //     et = moment(state.profileDateRange[1]).format('YYYY-MM-DD HH:mm');
      // }
      const tz = dateStore.getTimeZone();
      const zone = dateStore.getZone(tz);
      const s = `(${st} to ${et}) - ${zone.label}`;
      return s;
    }
    return '';
  },
  getStartDate: (state) => state.profiles[0].momentStartTime.clone().format('YYYY-MM-DD HH:mm'),
  getEndDate: (state) => state.profiles[state.profiles.length - 1].momentEndTime.clone().format('YYYY-MM-DD HH:mm'),
  getProfileMappingModels(state) {
    if (state.profiles.length > 0) {
      const dictionary = tagUtils.createProfileColumnDictionary(state.table.columns, state.profiles);
      return dictionary;
    }
    return [];
  },
  getSelectedForceProfiles: (state) => state.selectedForceProfiles,
  getModelProfileSet: (state) => state.modelProfileSet,
};
const actions = {
  createProfilesFromDeal({ commit, state, dispatch }, item) {
    commit('setProfilesFromDeal', item);
    dispatch('changeDailyProfileDate', state.profile.profiles[0].momentStartTime.startOf('day').format());
  },
  changeDailyDate({ commit, dispatch }, value) {
    const tdl = dateStore.toDateFromLocal(value);
    const targetMomentDateString = dateStore.toMoment(tdl).startOf('Day');

    commit('setProfileDailyDate', value);
    commit('setProfileDailyDateMoment', targetMomentDateString);
    commit('setProfileGraphTimeRanges', value);

    const profileDate = dateStore.toDateFromLocal(value);
    dispatch('loadProductHours', profileDate.format());
  },
  changeTimeGranularity({ commit }, value) {
    commit('setProfileTimeGranularity', value);
  },
  changeDailyProfile({ commit }, item) {
    commit('setProfileData', item);
  },
  changeConsolidatedProfile({ commit }, item) {
    commit('setProfileData', item);
  },
  changeRampProfile({ commit }, item) {
    commit('setProfileData', item);
  },
  changeView({ commit, state }, value) {
    commit('setProfileView', value);
    if (state.mode !== 'NEW_TAG') { commit('setProfileGraphTimeRanges'); }
  },
  changeViewIndex({ commit, state }, value) {
    commit('setProfileViewIndex', value);
  },
  loadInitialDailyProfileDate({ commit, dispatch, state }, item) {
    const localMomentDate = dateStore.getDefaultDateLocal().toISOString();
    const targetMomentDateString = dateStore.toDateFromLocal(localMomentDate).startOf('Day');

    let momentProfileDate = targetMomentDateString;

    if (item.profileSetList && item.profileSetList.length > 1) {
      const minStartTime = tagUtils.findMinStartTime(item.profileSetList);
      const maxEndTime = tagUtils.findMaxEndTime(item.profileSetList);

      if (!(targetMomentDateString.isSameOrAfter(minStartTime) && targetMomentDateString.isBefore(maxEndTime))) {
        // Conver MinStartTime to LocalTime
        momentProfileDate = minStartTime.clone().startOf('Day');
      }
    }

    const profileDate = momentProfileDate.format('YYYY-MM-DD');
    commit('setProfileDailyDateMoment', momentProfileDate);
    commit('setProfileDailyDate', profileDate);
    dispatch('loadProductHours', profileDate);

    const st = state.profile.profiles[0].momentStartTime.clone().format('YYYY-MM-DD HH:mm');
    const et = state.profile.profiles[state.profile.profiles.length - 1]
      .momentEndTime.clone().format('YYYY-MM-DD HH:mm');
    commit('setProfileDateRange', [st, et]);
    commit('setProfileGraphTimeRanges', profileDate);
  },
  loadProductHours({ commit, state }, date) {
    const tz = dateStore.getTimeZoneDefinition().value;
    ETAG_API.get(`productHours?timeZone=${tz}&date=${date}&productName=onpeak`)
      .then((response) => {
        commit('setOnPeakHours', response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    ETAG_API.get(`productHours?timeZone=${tz}&date=${date}&productName=offpeak`)
      .then((response) => {
        commit('setOffPeakHours', response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async loadProductHoursMap({ commit, state }, { start, end }) {
    const tz = dateStore.getTimeZoneDefinition().value;
    const mtStart = moment(start);
    const mtEnd = moment(end);
    const onPeakHoursMap = {};
    const offPeakHoursMap = {};

    const dates = [];
    while (mtEnd.diff(mtStart, 'days') >= 0) {
      dates.push(mtStart.format('MM/DD/YYYY'));
      mtStart.add(1, 'days');
    }

    await Promise.all(dates.map(async (date) => {
      try {
        const onPeakResp = await ETAG_API.get(`productHours?timeZone=${tz}&date=${date}&productName=onpeak`);
        onPeakHoursMap[date] = onPeakResp.data;
        const offPeakResp = await ETAG_API.get(`productHours?timeZone=${tz}&date=${date}&productName=offpeak`);
        offPeakHoursMap[date] = offPeakResp.data;
      } catch (error) {
        console.log(error);
      }
    }));

    commit('setOnPeakHoursMap', onPeakHoursMap);
    commit('setOffPeakHoursMap', offPeakHoursMap);
  },
  changeProfileDateRange({ commit }, value) {
    commit('setProfileDateRange', value);
  },
  loadPreviousTagProfile({ commit }, data) {
    if (data.profileSetList && data.profileSetList.length > 0) {
      commit('setPreviousTagProfile', data);
    }
  },
  clearPreviousTagProfile({ commit }) {
    commit('clearPreviousTagProfileMutation');
  },
  selectedForceProfiles({ commit }, items) {
    commit('setSelectedForceProfiles', items);
  },
  addRangeRecord({ state, commit }, value) {
    const profiles = profileUtils.createProfileObjects(state.table.columns, value);
    profiles.forEach((p) => {
      commit('setProfileData', p);
    });
  },
};
const mutations = {
  initialize(state) {
    const columns = helpers.clone(defaultDailyProfileColumns);
    state.table.columns = columns;
    state.path = [];
    state.timeGranularity = '60';
    const local = dateStore.getDefaultDateLocal();
    state.dailyProfileDateMoment = dateStore.toDateFromLocal(local).clone();
    state.dailyProfileDate = local.format();
    const { tz } = dateStore.getTimeZoneDefinition();

    state.profiles = profileUtils.createDefaultProfiles(state.dailyProfileDateMoment.clone(), columns, tz);
    state.deleteProfileCache = profileUtils.createDefaultProfiles(state.dailyProfileDateMoment.clone(), columns, tz);
    state.modelProfileSet = [];
    state.previousProfiles = [];
    state.originalProfiles = [];
    state.profileViewName = VIEWS.DAILY;
  },
  resetProfileData(state) {
    state.profiles = [];
  },
  setProfilesFromDeal(state, item) {
    const { context } = item;
    const { tagModel } = item;
    const { tz } = dateStore.getTimeZoneDefinition();

    // state.dailyProfileDate  = utils.date.toMomentFromDate(context.startTime, tz).format();
    // console.log(context);
    const profiles = [];
    if (context.volume) {
      for (let i = 0; i < context.volume.length; i++) {
        const volume = context.volume[i];
        const date = utils.date.toMomentFromDate(volume.day, tz).startOf('day').utc();
        if (date.isSameOrAfter(context.startTime) && date.isSameOrBefore(context.endTime)) {
          // console.log('creating profile');
          for (const property in volume) {
            if (utils.data.has(volume, property) && property.includes('he')) {
              const hour = Number(property.substring(2));
              if (hour < 25) {
                const start = date.clone().add(hour - 1, 'hours');
                const end = date.clone().add(hour, 'hours');
                const profile = {
                  momentStartTime: start, startTime: start.format(), momentEndTime: end, endTime: end.format(),
                };
                let prop = [state.path[context.segment].guid][0];
                if (prop === 'gen') { prop = 'source'; } else if (prop === 'load') { prop = 'sink'; }
                profile[prop] = volume[property];
                profiles.push(profile);
              }
            }
          }
        }
      }
    }

    const columns = getColumns(state, state.path);
    const objectDefinition = getObjectDefinition(columns);
    if (profiles.length > 0) {
      state.profiles = tagUtils.reduceProfiles(profiles, columns, objectDefinition);
    } else { state.profiles = profileUtils.createDefaultProfiles(context.startTime, columns, tz); }

    // state.dailyProfileDate  = state.profiles[0].momentStartTime.startOf('day').utc().format();
  },
  setProfileDailyDate(state, value) {
    state.dailyProfileDate = value;
  },
  setProfileDailyDateMoment(state, value) {
    state.dailyProfileDateMoment = value;
  },
  setProfileDateRange(state, value) {
    state.profileDateRange = value;
  },
  setComparePreviousFlag(state) {
    state.comparePreviousFlag = !state.comparePreviousFlag;
  },
  setProfileGraphTimeRanges(state) {
    const data = [];
    state.profileGraphData.datasets[0].data = [];
    state.profileGraphData.datasets[1].data = [];
    state.profileGraphData.datasets[2].data = [];
    state.profileGraphData.labels = [];
    const { tz } = dateStore.getTimeZoneDefinition();

    const filtered = helpers.filter(
      state.modelProfileSet, (o) => o.profileType === 'MARKETLEVEL'
      || (o.profileType === 'RELIABILITYLIMIT' && o.limitClearing === false));
    const sortedProfiles = helpers.sortBy(filtered, (o) => o.start);

    let addCounter = 0;
    for (let i = 0; i < sortedProfiles.length; i++) {
      if (addCounter !== 0) {
        i += addCounter;
        if (i >= sortedProfiles.length) {
          break;
        }
        addCounter = 0;
      }

      if (i === sortedProfiles.length - 1) {
        const profile = {};
        profile.start = dateStore.toMoment(sortedProfiles[i].start);
        profile.end = dateStore.toMoment(sortedProfiles[i].stop);
        profile[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
        data.push(profile);
        break;
      }
      let start1 = dateStore.toMoment(sortedProfiles[i].start);
      let end1 = dateStore.toMoment(sortedProfiles[i].stop);
      for (let j = i + 1; j < sortedProfiles.length; j++) {
        const start2 = dateStore.toMoment(sortedProfiles[j].start);
        const end2 = dateStore.toMoment(sortedProfiles[j].stop);

        if (end1.isBefore(start2) || end1.isSame(start2)) {
          const profile1 = {};
          profile1[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          profile1.start = start1;
          profile1.end = end1;
          data.push(profile1);
          break;
        } else if (start1.isSame(start2)) {
          const profile1 = {};
          profile1[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          profile1[sortedProfiles[j].profileType] = sortedProfiles[j].mw;
          profile1.start = start1;
          if (end1.isAfter(end2)) {
            profile1.end = end2;
            start1 = end2;
            data.push(profile1);
            addCounter++;
          } else if (end2.isAfter(end1)) {
            profile1.end = end1;
            start1 = end1;
            end1 = end2;
            sortedProfiles[i].mw = sortedProfiles[j].mw;
            sortedProfiles[i].profileType = sortedProfiles[j].profileType;
            data.push(profile1);
            addCounter++;
          } else {
            profile1.end = end1;
            data.push(profile1);
            addCounter++;
          }
        } else if (end1.isAfter(end2)) {
          const profile1 = {};
          profile1.start = start1;
          profile1.end = start2;
          profile1[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          data.push(profile1);
          const profile2 = {};
          profile2.start = start2;
          profile2.end = end2;
          profile2[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          profile2[sortedProfiles[j].profileType] = sortedProfiles[j].mw;
          data.push(profile2);
          start1 = end2;
          addCounter++;
        } else if (end1.isBefore(end2)) {
          const profile1 = {};
          profile1.start = start1;
          profile1.end = start2;
          profile1[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          data.push(profile1);
          const profile2 = {};
          profile2.start = start2;
          profile2.end = end1;
          profile2[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          profile2[sortedProfiles[j].profileType] = sortedProfiles[j].mw;
          data.push(profile2);
          start1 = end1;
          sortedProfiles[i].mw = sortedProfiles[j].mw;
          sortedProfiles[i].profileType = sortedProfiles[j].profileType;
          addCounter++;
        } else {
          const profile1 = {};
          profile1.start = start1;
          profile1.end = start2;
          profile1[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          data.push(profile1);
          const profile2 = {};
          profile2.start = start2;
          profile2.end = end1;
          profile2[sortedProfiles[i].profileType] = sortedProfiles[i].mw;
          profile2[sortedProfiles[j].profileType] = sortedProfiles[j].mw;
          data.push(profile2);
          addCounter++;
          break;
        }
      }
    }
    for (let i = 0; i < data.length; i++) {
      const hourDiff = data[i].end.diff(data[i].start, 'hours');
      if (hourDiff > 1) {
        for (let j = 0; j < hourDiff; j++) {
          if (data[i].MARKETLEVEL) {
            state.profileGraphData.datasets[1].data.push({
              y: data[i].MARKETLEVEL, t: data[i].start.format('YYYY-MM-DD HH:mm'),
            });
            if (!data[i].RELIABILITYLIMIT) {
              state.profileGraphData.datasets[0].data.push({
                y: null, t: data[i].start.format('YYYY-MM-DD H:mm'),
              });
              state.profileGraphData.datasets[2].data.push({
                y: data[i].MARKETLEVEL, t: data[i].start.format('YYYY-MM-DD HH:mm'),
              });
            }
          }
          if (data[i].RELIABILITYLIMIT) {
            state.profileGraphData.datasets[0].data.push({
              y: data[i].RELIABILITYLIMIT, t: data[i].start.format('YYYY-MM-DD HH:mm'),
            });
          }
          if (!data[i].MARKETLEVEL) {
            state.profileGraphData.datasets[1].data.push({ y: null, t: data[i].start.format('YYYY-MM-DD H:mm') });
            state.profileGraphData.datasets[2].data.push({
              y: data[i].RELIABILITYLIMIT, t: data[i].start.format('YYYY-MM-DD HH:mm'),
            });
          }
          if (data[i].RELIABILITYLIMIT && data[i].MARKETLEVEL) {
            state.profileGraphData.datasets[2].data.push({
              y: Math.min(data[i].MARKETLEVEL, data[i].RELIABILITYLIMIT), t: data[i].start.format('YYYY-MM-DD HH:mm'),
            });
          }
          data[i].start = data[i].start.add(1, 'hours');
        }
      } else {
        if (data[i].MARKETLEVEL) {
          state.profileGraphData.datasets[1].data.push({
            y: data[i].MARKETLEVEL, t: data[i].start.format('YYYY-MM-DD HH:mm'),
          });
          if (!data[i].RELIABILITYLIMIT) {
            state.profileGraphData.datasets[0].data.push({ y: null, t: data[i].start.format('YYYY-MM-DD H:mm') });
            state.profileGraphData.datasets[2].data.push({
              y: data[i].MARKETLEVEL, t: data[i].start.format('YYYY-MM-DD HH:mm'),
            });
          }
        }
        if (data[i].RELIABILITYLIMIT) {
          state.profileGraphData.datasets[0].data.push({
            y: data[i].RELIABILITYLIMIT, t: data[i].start.format('YYYY-MM-DD HH:mm'),
          });
        }
        if (!data[i].MARKETLEVEL) {
          state.profileGraphData.datasets[1].data.push({ y: null, t: data[i].start.format('YYYY-MM-DD H:mm') });
          state.profileGraphData.datasets[2].data.push({
            y: data[i].RELIABILITYLIMIT, t: data[i].start.format('YYYY-MM-DD HH:mm'),
          });
        }
        if (data[i].RELIABILITYLIMIT && data[i].MARKETLEVEL) {
          state.profileGraphData.datasets[2].data.push({
            y: Math.min(data[i].MARKETLEVEL, data[i].RELIABILITYLIMIT), t: data[i].start.format('YYYY-MM-DD HH:mm'),
          });
        }
      }

      if (i !== data.length - 1) {
        if (data[i].end.isBefore(data[i + 1].start)) {
          const minuteDiff = data[i + 1].start.diff(data[i].end, 'minutes');
          const diff = data[i + 1].start.diff(data[i].end, 'hours');

          if (minuteDiff > 60) {
            for (let j = 0; j < diff - 1; j++) {
              const time = data[i].end.add(1, 'hours').format('YYYY-MM-DD HH:mm');
              state.profileGraphData.datasets[0].data.push({ y: null, t: time });
              state.profileGraphData.datasets[1].data.push({ y: null, t: time });
              state.profileGraphData.datasets[2].data.push({ y: null, t: time });
            }
          } else if (minuteDiff > 5) {
            for (let j = 0; j < diff - 1; j++) {
              const time = data[i].end.add(5, 'minutes').format('YYYY-MM-DD HH:mm');
              state.profileGraphData.datasets[0].data.push({ y: null, t: time });
              state.profileGraphData.datasets[1].data.push({ y: null, t: time });
              state.profileGraphData.datasets[2].data.push({ y: null, t: time });
            }
          }
        }
      } else {
        const time = data[i].end.format('YYYY-MM-DD HH:mm');
        state.profileGraphData.datasets[0].data.push({ y: null, t: time });
        state.profileGraphData.datasets[1].data.push({ y: null, t: time });
        state.profileGraphData.datasets[2].data.push({ y: null, t: time });
      }
    }
  },
  setTimeGranularity(state, value) {
    state.timeGranularity = value;
  },
  setView(state, value) {
    state.profileViewName = value;
    state.table.columns = getColumns(state, state.path);
  },
  setViewIndex(state, value) {
    state.profileViewIndex = value;
  },
  setColumns(state, path) {
    state.table.columns = getColumns(state, path);
    state.path = path;
  },
  clearPreviousTagProfileMutation(state) {
    state.comparePreviousFlag = false;
    state.previousProfiles = [];
  },
  setPreviousTagProfile(state, tagModel) {
    const findTransmission = function (col, tran) {
      return col.trans_id && col.trans_id === tran.transmissionAllocationID;
    };
    const previousProfiles = profileUtils.toProfileRangesFromTagModel(tagModel, state.table.columns, findTransmission);
    state.previousProfiles = previousProfiles;
  },
  setOnPeakHours(state, values) {
    state.onPeakHourRanges = values;
  },
  setOffPeakHours(state, values) {
    state.offPeakHourRanges = values;
  },
  setOnPeakHoursMap(state, values) {
    state.onPeakHoursMap = values;
  },
  setOffPeakHoursMap(state, values) {
    state.offPeakHoursMap = values;
  },
  setFromTagModel(state, tagModel) {
    state.modelProfileSet = tagModel.profileSetList;
    if (tagModel.segmentList && tagModel.segmentList.length > 0) {
      const maxMarketSegment = helpers.maxBy(tagModel.segmentList, (o) => o.marketSegmentID);
      const maxPhysicalSegment = helpers.maxBy(maxMarketSegment.physicalSegmentList, (o) => o.physicalSegmentID);
      state.maxPorProfileRef = maxPhysicalSegment.porProfileRef;
    }

    let profiles = profileUtils.toProfileRangesFromTagModel(tagModel, state.table.columns);

    if (profiles.length === 0) {
      const { tz } = dateStore.getTimeZoneDefinition();
      const date = dateStore.toDateFromLocal(state.dailyProfileDate);
      profiles = profileUtils.createDefaultProfiles(date, state.table.columns, tz);
      state.originalProfiles = [];
    } else {
      state.originalProfiles = Object.freeze(helpers.cloneDeep(profiles));
    }

    state.profiles = profiles;
  },
  setData(state, item) {
    const profiles = (state.profileViewName === VIEWS.RANGE) ? state.deleteProfileCache : state.profiles;
    state.transmissionIsNull = [];
    const paths = [];
    state.path.forEach((path) => {
      if (path.type === 'TRAN') paths.push(path.guid);
    });
    profiles.forEach((profile) => {
      paths.forEach((path) => {
        if (!(path in profile)) {
          profile[path] = null;
        }
      });
    });

    if (item != null) {
      const equalFunc = utils.date.areEqual;
      const { columns } = state.table;
      const objectDefinition = getObjectDefinition(columns);
      const copy = tagUtils.copyProfiles(columns, profiles, objectDefinition);

      if (state.profileViewName === VIEWS.DAILY || state.profileViewName === VIEWS.RANGE) {
        const startTime = item.keys[0].value.clone(); // key contains momentStartTime moment value
        const endTime = item.keys[1].value.clone(); // key contains momentEndTime moment value

        const overlaps = helpers.filter(
          copy, (p) => p.momentEndTime.isAfter(startTime) && p.momentStartTime.isBefore(endTime));
        let lastOverlap = null;

        let x = 0;
        while (true) {
          if (x === overlaps.length) break;

          const overlap = overlaps[x];

          lastOverlap = helpers.clone(objectDefinition);
          tagUtils.copyPropertyValues(columns, overlap, lastOverlap);

          const originalStart = overlap.momentStartTime.clone();
          const originalEnd = overlap.momentEndTime.clone();

          if (equalFunc(originalStart, startTime) && equalFunc(originalEnd, endTime)) {
            const idx = helpers.findIndex(
              copy, (c) => equalFunc(c.momentStartTime, startTime) && equalFunc(c.momentEndTime, endTime));
            copy.splice(idx, 1);
            break;
          }

          if (originalStart.isBefore(startTime)) {
            overlap.momentEndTime = startTime.clone();
            overlap.endTime = startTime.clone().utc().format();
          } else if (originalStart >= startTime) {
            overlap.momentStartTime = endTime.clone();
            overlap.startTime = endTime.clone().utc().format();
          }

          if (originalEnd.isAfter(endTime) && originalStart.isBefore(startTime)) {
            const obj = helpers.clone(objectDefinition);
            obj.momentStartTime = endTime.clone();
            obj.startTime = endTime.clone().utc().format();
            obj.momentEndTime = originalEnd.clone();
            obj.endTime = originalEnd.clone().utc().format();
            tagUtils.copyPropertyValues(columns, overlap, obj);
            copy.push(obj);
          }

          x++;
        }

        const np = helpers.clone(objectDefinition);
        tagUtils.setDefaultPropertyValues(np, columns);
        np.momentStartTime = startTime.clone();
        np.momentEndTime = endTime.clone();
        np.startTime = startTime.clone().utc().format();
        np.endTime = endTime.clone().utc().format();

        if (lastOverlap != null) {
          tagUtils.copyPropertyValues(columns, lastOverlap, np);
        }

        np[item.prop] = item.value === '' ? null : item.value;
        const currentColumnDef = utils.data.findColumnIndex(columns, item.prop);

        // find the max energy column. set the sink to it's value.
        // a sink's value can never be set explicitly.
        if (currentColumnDef.col.profileProductType === 'ENERGY') {
          const maxEnergyColumnDef = tagUtils.findMaxEnergyColumn(columns);
          np.sink = np[maxEnergyColumnDef.col.prop];
        }
        copy.push(np);

        if (state.profileViewName === VIEWS.RANGE) {
          // Caches to delete from range because day of always gets added
          state.deleteProfileCache = tagUtils.reduceProfiles(copy, columns, objectDefinition);
        } else {
          state.profiles = tagUtils.reduceProfiles(copy, columns, objectDefinition);
        }
      } else {
        const row = copy[item.rowIndex];
        if (item.prop !== 'startTime' && item.prop !== 'endTime') {
          row[item.prop] = item.value === '' ? null : item.value;
        } else {
          const mdt = moment(item.value);
          row[item.prop] = mdt.clone().utc().format();
          if (item.prop === 'startTime') {
            row.momentStartTime = mdt.clone().utc();
          } else if (item.prop === 'endTime') {
            row.momentEndTime = mdt.clone().utc();
          }
        }

        const currentColumnDef = utils.data.findColumnIndex(columns, item.prop);

        // find the max energy column. set the sink to it's value.
        // a sink's value can never be set explicitly.
        if (currentColumnDef.col.profileProductType === 'ENERGY') {
          const maxEnergyColumnDef = tagUtils.findMaxEnergyColumn(columns);
          row.sink = row[maxEnergyColumnDef.col.prop];
        }

        state.profiles = tagUtils.reduceProfiles(copy, columns, objectDefinition);
      }
    }
    const transmissionIsNull = [];
    paths.forEach((path, index) => {
      clone(state.profiles).forEach((profile) => {
        if (profile[path] === null) {
          transmissionIsNull[index] = true;
        } else {
          transmissionIsNull[index] = false;
          return false;
        }
      });
    });
    state.transmissionIsNull = transmissionIsNull;
  },
  setProfileRangeData(state, dateRanges) {
    state.transmissionIsNull = [];

    const { columns } = state.table;
    const objectDefinition = getObjectDefinition(columns);

    const desiredProfiles = state.deleteProfileCache;

    const profiles = [];
    dateRanges.forEach((range) => {
      for (let i = 0; i < desiredProfiles.length; i++) {
        const profile = desiredProfiles[i];
        const item = { ...objectDefinition };
        tagUtils.copyPropertyValues(columns, profile, item);

        const [rangeMomentStartDate] = range.momentStartTime.clone().startOf('day').toISOString().split('T');
        const [profileMomentStartDate] = profile.momentStartTime.clone().startOf('day').toISOString().split('T');

        // get long day
        const shortDayLongDayInfo = dateStore.getShortAndLongDays(profile.momentStartTime);
        const [longDate] = shortDayLongDayInfo.longDay.toISOString().split('T');
        const [shortDate] = shortDayLongDayInfo.shortDay.toISOString().split('T');
        const [dailyProfileDate] = state.dailyProfileDateMoment.clone().toISOString().split('T');

        let rangeUtcStartOfDay = moment(`${rangeMomentStartDate}T00:00:00.000Z`);
        let profileUtcStartOfDay = moment(`${profileMomentStartDate}T00:00:00.000Z`);

        // special case for long day
        let isLongDayBetweenDateRange = false;
        if (moment(rangeMomentStartDate).isBefore(profileMomentStartDate)) {
          isLongDayBetweenDateRange = moment(longDate)
            .isBetween(rangeMomentStartDate, profileMomentStartDate, null, '[]');
          if (isLongDayBetweenDateRange) profileUtcStartOfDay = moment(`${profileMomentStartDate}T01:00:00.000Z`);
        } else if (moment(rangeMomentStartDate).isAfter(profileMomentStartDate)) {
          isLongDayBetweenDateRange = moment(longDate)
            .isBetween(profileMomentStartDate, rangeMomentStartDate, null, '[]');
          if (isLongDayBetweenDateRange) rangeUtcStartOfDay = moment(`${rangeMomentStartDate}T01:00:00.000Z`);
        }

        // calculate differences in days
        const differenceInDays = rangeUtcStartOfDay.diff(profileUtcStartOfDay, 'days');

        item.momentStartTime = profile.momentStartTime.clone().add(differenceInDays, 'days');
        item.momentEndTime = profile.momentEndTime.clone().add(differenceInDays, 'days');

        // special cases for long day and short day
        if (dateStore.isLongDay(item.momentStartTime)) {
          const differenceInHoursProfile = Math.abs(profile.momentStartTime.clone().add(1, 'days')
            .diff(profile.momentEndTime.clone().add(1, 'days'), 'hours'));

          const differenceInHoursItem = Math.abs(item.momentStartTime.diff(item.momentEndTime, 'hours'));

          // const [, startTimeFromSettings] = moment().startOf('day').toISOString().split('T');
          // const [startHourFromSettings] = startTimeFromSettings.split(':');
          // const hourStartFromSettings = Number(startHourFromSettings);
          // const [, startTime] = item.momentStartTime.toISOString().split('T');
          // const [startHour] = startTime.split(':');
          // const hourStart = Number(startHour);
          // const [, endTime] = item.momentEndTime.toISOString().split('T');
          // const [endHour] = endTime.split(':');
          // const hourEnd = Number(endHour);
          // const differenceInHoursStartAndEnd = Math.abs(item.momentStartTime.diff(item.momentEndTime, 'hours'));

          // console.log(hourStart);
          // console.log(hourEnd);
          // console.log(hourStartFromSettings)
          // console.log(dailyProfileDate)
          // console.log(longDate)
          // if (hourStart - hourStartFromSettings === 0
          //   && differenceInHoursStartAndEnd > 1 && !moment(dailyProfileDate).isSame(longDate)) {
          //   item.momentEndTime.add(1, 'hours');
          //   console.log('true c1')
          // } else if (hourStart - hourStartFromSettings === 2) {
          //   item.momentStartTime.subtract(1, 'hours');
          //   console.log('true c2')
          // } else if (hourEnd - hourStartFromSettings === 2) {
          //   item.momentEndTime.add(1, 'hours');
          //   console.log('true c3')
          // }
        } else if (dateStore.isShortDay(item.momentStartTime)) {
          if (item.momentStartTime.toISOString() === item.momentEndTime.toISOString()) {
            item.momentEndTime.add(1, 'hours');
          }

          const displayArray = toDisplayArray(
            state.profileViewName,
            state.table.columns,
            state.deleteProfileCache,
            item.momentStartTime.clone().startOf('day'),
            state.timeGranularity,
            HOUR_MIN);
          const displayRow = moment(dailyProfileDate).isSameOrBefore(shortDate)
            ? displayArray
              .find((f) => f.momentStartTime.add(1, 'days').toISOString() === item.momentStartTime.toISOString()
                && f.momentEndTime.add(1, 'days').toISOString() === item.momentEndTime.toISOString())
            : displayArray
              .find((f) => f.momentStartTime.add(1, 'days').toISOString()
                === item.momentStartTime.clone().subtract(1, 'hours').toISOString()
                && f.momentEndTime.add(1, 'days').toISOString()
                 === item.momentEndTime.clone().subtract(1, 'hours').toISOString());

          if (displayRow?.he === 3) {
            // if it's a short day and the user puts a value for hour ending 3,
            // then ignore it and do not add it to profiles
            continue;
          }

          const [, startTimeFromSettings] = moment().startOf('day').toISOString().split('T');
          const [startHourFromSettings] = startTimeFromSettings.split(':');
          const hourStartFromSettings = Number(startHourFromSettings);
          const [, startTime] = item.momentStartTime.toISOString().split('T');
          const [startHour] = startTime.split(':');
          const hourStart = Number(startHour);
          const differenceInHoursStartAndEnd = Math.abs(item.momentStartTime.diff(item.momentEndTime, 'hours'));

          if (hourStart - hourStartFromSettings === 2
            && differenceInHoursStartAndEnd > 1
            && moment(dailyProfileDate).isSameOrBefore(shortDate)) {
            item.momentStartTime.add(1, 'hours');
          }
        }
        item.startTime = item.momentStartTime.clone().utc().format();
        item.endTime = item.momentEndTime.clone().utc().format();
        profiles.push(item);
      }
    });
    state.profiles = mergeRangeProfiles(state.profiles, profiles);
  },
  setSelectedForceProfiles(state, items) {
    state.selectedForceProfiles = items;
  },
  setTermProfiles(state, profiles) {
    state.profiles = profiles;
    state.transmissionIsNull = [];
    state.profileRange = [];
    state.deleteProfileCache = [];
    state.termInputTable = [];
  },
  setTermInputTable(state, termInputTable, event) {
    const inputRow = termInputTable[0];

    // only set value if input is a number
    const isNumber = !Number.isNaN(Number(event.value));
    if (isNumber) {
      inputRow[event.prop] = event.value;
      if (event.prop === 'source') {
        inputRow.sink = inputRow.source;
      }
    }

    // validate allocations
    const childColumns = state.table.columns.filter((column) => !column.isParent
    && !['startTime', 'endTime', 'he', 'source', 'sink'].includes(column.prop));

    // utility function - groups items based on property that is passed in
    const groupBy = (key) => (array) => array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

    const groupByParent = groupBy('parent');

    const columnGroups = groupByParent(childColumns.map((column) => ({
      parent: column.parent,
      prop: column.prop,
      value: inputRow[column.prop],
    })));

    const allocationGoal = inputRow.source || 0;
    let highlight = [];
    Object.keys(columnGroups).forEach((prop) => {
      const allocations = columnGroups[prop].map((p) => Number(p.value) || 0);
      const sumAllocations = allocations.reduce((a, b) => a + b, 0);
      if (sumAllocations < allocationGoal) {
        const invalidChildColumns = columnGroups[prop].map((p) => p.prop);
        highlight = [...highlight, ...invalidChildColumns];
      }
    });

    inputRow.highlight = highlight;
    state.termInputTable = [inputRow];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};