import { tradeDateRange, marketTypes } from '@/components/PPA/BaseStore/ppasBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  isSearchDialogVisible: false,
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataField: 'marketType',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    dataType: 'number',
    area: 'row',
    expanded: true,
  }, {
    caption: 'PRICE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.##',
    expanded: true,
  }, {
    caption: 'PPA NAME',
    dataField: 'ppaName',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SOURCE SYSTEM',
    dataField: 'sourceSystem',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'PRICE TYPE',
    dataField: 'priceType',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'PRODUCT TYPE',
    dataField: 'productType',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'PRICE NODE',
    dataField: 'priceNode',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    marketTypes,
  },
};