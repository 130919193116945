export default [{
  id: 9, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-01-18T20:46:00Z', startDate: '2019-01-21T08:00:00Z', endDate: '2019-01-25T08:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-01-18T20:46:00Z', updatedBy: 'jkirby', updatedDate: '2019-01-18T20:46:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 10, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-01-22T21:19:00Z', startDate: '2019-02-01T08:00:00Z', endDate: '2019-02-28T08:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-01-22T21:21:00Z', updatedBy: 'jkirby', updatedDate: '2019-01-22T21:21:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 11, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-01-22T21:28:00Z', startDate: '2019-02-01T08:00:00Z', endDate: '2019-02-28T08:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-01-22T21:28:00Z', updatedBy: 'jkirby', updatedDate: '2019-01-22T21:28:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 12, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-01-22T23:48:00Z', startDate: '2019-01-28T08:00:00Z', endDate: '2019-01-28T08:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-01-22T23:52:00Z', updatedBy: 'jkirby', updatedDate: '2019-01-22T23:52:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 20, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-02-05T18:39:00Z', startDate: '2019-02-01T08:00:00Z', endDate: '2019-03-31T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'SCE', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-02-05T18:40:00Z', updatedBy: 'jkirby', updatedDate: '2019-02-05T18:40:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 21, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-02-13T21:03:00Z', startDate: '2019-02-18T08:00:00Z', endDate: '2019-02-28T08:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-02-13T21:07:00Z', updatedBy: 'jkirby', updatedDate: '2019-02-13T21:07:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 22, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-02-13T21:50:00Z', startDate: '2019-03-01T08:00:00Z', endDate: '2019-03-31T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-02-13T21:56:00Z', updatedBy: 'dpham', updatedDate: '2019-04-01T20:46:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 25, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-02-14T15:19:00Z', startDate: '2019-02-18T08:00:00Z', endDate: '2019-03-31T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: null, contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'jkirby', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'jkirby', createdDate: '2019-02-14T15:26:00Z', updatedBy: 'dpham', updatedDate: '2019-04-01T16:41:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 36, versionId: 1, dealType: 'POWER', commodityName: null, marketName: null, marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-03-21T23:15:00Z', startDate: '2019-03-21T07:00:00Z', endDate: '2019-03-21T07:00:00Z', location: null, internalCompany: null, internalCompanyId: null, company: null, companyId: null, contact: null, contactId: null, contract: null, contractId: null, portfolio: null, portfolioId: null, trader: 'dpham', timeZone: 'CST', currency: null, currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-03-21T23:15:00Z', updatedBy: 'dpham', updatedDate: '2019-03-21T23:15:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 37, versionId: 1, dealType: 'POWER', commodityName: null, marketName: null, marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-03-22T13:56:00Z', startDate: '2019-03-22T07:00:00Z', endDate: '2019-03-22T07:00:00Z', location: null, internalCompany: null, internalCompanyId: null, company: null, companyId: null, contact: null, contactId: null, contract: null, contractId: null, portfolio: null, portfolioId: null, trader: 'dpham', timeZone: 'EDT', currency: null, currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-03-22T13:57:00Z', updatedBy: 'dpham', updatedDate: '2019-03-22T13:57:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 39, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-02T22:34:00Z', startDate: '2019-04-02T07:00:00Z', endDate: '2019-04-04T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PST', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-02T22:36:00Z', updatedBy: 'dpham', updatedDate: '2019-04-04T15:40:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 40, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-03T18:57:00Z', startDate: '2019-04-03T07:00:00Z', endDate: '2019-04-05T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'CDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-03T19:02:00Z', updatedBy: 'dpham', updatedDate: '2019-04-04T15:39:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 41, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-04T15:42:00Z', startDate: '2019-04-04T07:00:00Z', endDate: '2019-04-06T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-04T15:44:00Z', updatedBy: 'dpham', updatedDate: '2019-04-04T20:02:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 43, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-04T16:00:00Z', startDate: '2019-04-03T07:00:00Z', endDate: '2019-04-05T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-04T16:05:00Z', updatedBy: 'dpham', updatedDate: '2019-04-04T19:57:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 44, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-04T21:09:00Z', startDate: '2019-04-04T07:00:00Z', endDate: '2019-04-06T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-04T21:17:00Z', updatedBy: 'dpham', updatedDate: '2019-04-11T18:59:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 45, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-11T23:00:00Z', startDate: '2019-04-11T23:00:00Z', endDate: '2019-04-30T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-04-11T23:01:00Z', updatedBy: 'dpham', updatedDate: '2019-04-12T15:02:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 46, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-04-29T23:15:00Z', startDate: '2019-04-29T23:15:00Z', endDate: '2019-04-30T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-01T15:09:00Z', updatedBy: 'dpham', updatedDate: '2019-05-01T15:09:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 65, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-01T20:57:00Z', startDate: '2019-05-01T20:57:00Z', endDate: '2019-05-08T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-01T20:57:00Z', updatedBy: 'dpham', updatedDate: '2019-05-01T20:57:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 68, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-01T21:22:00Z', startDate: '2019-04-29T21:22:00Z', endDate: '2019-05-10T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'eret', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-01T21:22:00Z', updatedBy: 'mabraham', updatedDate: '2019-05-07T01:22:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 69, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-01T22:22:00Z', startDate: '2019-05-01T22:22:00Z', endDate: '2019-05-03T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-01T22:23:00Z', updatedBy: 'dpham', updatedDate: '2019-05-02T17:27:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 70, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-02T17:01:00Z', startDate: '2019-05-01T17:01:00Z', endDate: '2019-05-04T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-02T17:02:00Z', updatedBy: 'dpham', updatedDate: '2019-05-02T17:02:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 75, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-07T00:15:00Z', startDate: '2019-05-07T00:15:00Z', endDate: '2019-05-11T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PSCS', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'FIN', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'mabraham', createdDate: '2019-05-07T00:16:00Z', updatedBy: 'mabraham', updatedDate: '2019-05-07T16:32:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 79, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-07T16:16:00Z', startDate: '2019-05-07T16:16:00Z', endDate: '2019-05-11T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'mabraham', createdDate: '2019-05-07T16:29:00Z', updatedBy: 'mabraham', updatedDate: '2019-05-09T16:36:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 80, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-07T18:22:00Z', startDate: '2019-05-07T18:22:00Z', endDate: '2019-05-11T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'mabraham', createdDate: '2019-05-07T18:23:00Z', updatedBy: 'mabraham', updatedDate: '2019-05-07T18:23:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 81, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-07T18:37:00Z', startDate: '2019-05-07T18:37:00Z', endDate: '2019-05-11T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'PASA', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'mabraham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'mabraham', createdDate: '2019-05-07T18:38:00Z', updatedBy: 'mabraham', updatedDate: '2019-05-09T18:03:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 87, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: false, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-14T18:18:00Z', startDate: '2019-05-14T18:18:00Z', endDate: '2019-05-23T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'Counterparty A', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'WEST POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-14T18:18:00Z', updatedBy: 'dpham', updatedDate: '2019-05-14T18:18:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 90, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-14T20:35:00Z', startDate: '2019-05-06T20:35:00Z', endDate: '2019-05-23T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'CPSF', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-14T20:35:00Z', updatedBy: 'dpham', updatedDate: '2019-05-15T23:22:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 91, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-14T21:19:00Z', startDate: '2019-05-14T21:19:00Z', endDate: '2019-05-15T04:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'CPSF', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'maparicio', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'dfasdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'maparicio', createdDate: '2019-05-14T21:21:00Z', updatedBy: 'maparicio', updatedDate: '2019-05-14T21:21:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 92, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: true, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-14T21:45:00Z', startDate: '2019-05-14T21:45:00Z', endDate: '2019-05-15T04:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'CPSF', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'maparicio', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: null, transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'maparicio', createdDate: '2019-05-14T21:46:00Z', updatedBy: 'maparicio', updatedDate: '2019-05-14T21:46:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}, {
  id: 93, versionId: 1, dealType: 'POWER', commodityName: 'POWER', marketName: 'CAISO', marketId: null, physicalFlag: false, scheduleDeliveryFlag: true, deliveryServiceType: null, buySellInd: 'BUY', tradeDate: '2019-05-15T15:46:00Z', startDate: '2019-05-07T15:46:00Z', endDate: '2019-05-24T07:00:00Z', location: null, internalCompany: 'PSCS', internalCompanyId: null, company: 'CPSF', companyId: null, contact: 'TRADE DESK', contactId: null, contract: 'Contract1', contractId: null, portfolio: 'PHYSICAL POWER', portfolioId: null, trader: 'dpham', timeZone: 'PDT', currency: 'USD', currencyId: null, groupName: 'asdf', transactionStatus: 'NOT IMPLEMENTED', extSourceSystem: null, extTransactionId: null, confirmedFlag: false, invoicedFlag: false, activeFlag: true, createdBy: 'dpham', createdDate: '2019-05-15T15:46:00Z', updatedBy: 'dpham', updatedDate: '2019-05-15T15:46:00Z', terms: null, notes: null, powerMarketDeliveryPlans: null, validationMessage: null,
}];