export const NUM_WIDTH = 32;
export const NUM_MIN_WIDTH = 20;
export const MS_PSE_WIDTH = 90;
export const MS_PSE_MIN_WIDTH = 50;
export const MS_PRODUCT_WIDTH = 80;

export const PS_MO_WIDTH = 80;
export const PS_MO_MIN_WIDTH = 35;
export const PS_GCA_LCA_WIDTH = 160;
export const PS_GCA_LCA_MIN_WIDTH = 35;
export const PS_SE_WIDTH = 80;
export const PS_SE_MIN_WIDTH = 35;
export const PS_TP_WIDTH = 80;
export const PS_TP_MIN_WIDTH = 35;
export const PS_POR_POD_WIDTH = 120;

export const AL_TC_WIDTH = 80;
export const AL_TC_MIN_WIDTH = 50;
export const AL_PRODUCT_WIDTH = 90;
export const AL_PRODUCT_MIN_WIDTH = 30;
export const AL_CONTRACT_WIDTH = 225;
export const MISC_INFO_WIDTH = 40;
export const MISC_INFO_MIN_WIDTH = 14;
export const CONTRACT_WIDTH = 40;
export const CONTRACT_MIN_WIDTH = 14;

export const USE_PSCS_AG_GRID = true;

export function setPositions(data) {
  for (let i = 0; i < data.length; i++) {
    data[i].position = i;
  }
}

export const TEMPLATE_STATES = {
  NA: 'NA',
  NEW: 'NEW',
  EDIT: 'EDIT',
  USE: 'USE',
};

export const MODES = {
  NA: 'NA',
  NEW_TAG: 'NEW_TAG',
  VIEW_TAG: 'VIEW_TAG',
  EDIT_TAG: 'EDIT_TAG',
  NEW_TEMPLATE: 'NEW_TEMPLATE',
  VIEW_TEMPLATE: 'VIEW_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  VARIANT_TAG: 'VARIANT_TAG',
};

export const COMMANDS = {
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  SAVE_HOLD: 'SAVE_HOLD',
};

export const TRANSACTION_TYPES = [
  { value: 'NORMAL', label: 'NORMAL' },
  { value: 'DYNAMIC', label: 'DYNAMIC' },
  { value: 'EMERGENCY', label: 'EMERGENCY' },
  { value: 'LOSSSUPPLY', label: 'LOSS SUPPLY' },
  { value: 'CAPACITY', label: 'CAPACITY' },
  { value: 'RECALLABLE', label: 'RECALLABLE' },
  { value: 'PSEUDOTIE', label: 'PSEUDOTIE' },
];

export const VIEWS = {
  DAILY: 'tag-profile-daily',
  CONSOLIDATED: 'tag-profile-consolidated',
  RAMP: 'tag-profile-ramp-duration',
  RANGE: 'tag-profile-range',
  TERM: 'tag-profile-term',
};