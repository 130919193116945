import moment from 'moment';
import { getMomentDateString } from '@/utils/dateUtil';
import { HEColumns, HEProperty, handleError } from '@/utils/dataUtil';
import utils from '@/utils';
import dateStore from '@/utils/dateStore';
import { ETRM_API } from '@/api';

const state = {
  currentSchedule: null,
  schedules: [],
  schedulesConfig: {
    columns: [{
      prop: 'location', label: 'Resource', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable', fixed: 'left',
    }, {
      prop: 'locationType', label: 'Resource Type', filter: true, width: 110, editable: false, cellClass: 'cell-uneditable', fixed: 'left',
    }, {
      prop: 'transactionId', label: 'Id', filter: true, width: 50, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'termId', label: 'Term Id', fitler: true, sort: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'dealType', label: 'dealType', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'marketNm', label: 'Market', filter: true, width: 80, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'scheduleDelivery',
      label: 'Schedule?',
      filter: true,
      width: 80,
      cellEditor: 'checkboxCellEditor',
      cellRenderer: 'checkboxCellRenderer',
      editable: false,
      cellClass: 'cell-uneditable',
    }, {
      prop: 'buySellInd', label: 'Position Type', filter: true, width: 80, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'cpartyMrktParticipant', label: 'Counter Party', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'variant', label: 'Variant', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    },
    // not sure if we want/need to show trade date, what this screen shows is a 24 hour snapshot of a term
    // {
    //   prop: 'tradeDate',
    //   label: 'Trade Date',
    //   filter: true,
    //   width: 80,
    //   editable: false,
    //   cellClass: 'cell-uneditable',
    //   valueFormatter(params) {
    //     if (params.value == null) return;
    //     return getMomentDateString(params.value);
    //   },
    // },
    {
      prop: 'intervalDate',
      label: 'Interval Date',
      filter: true,
      width: 80,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter(params) {
        if (params.value == null) return;
        return getMomentDateString(params.value);
      },
    },
    ...HEColumns({ filter: true, width: 75, editable: true }),
    {
      prop: 'total', label: 'TOTAL', cellClass: 'cell-uneditable', editable: false, width: 100,
    }],
    style: { maxHeight: 800 },
    columnList(name, row) {
      if (name === 'market') return this.registry.marketList;
    },
    columnMapList(watcher, row) {
      return utils.data.columnSelectList([{}], watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
    },
    options: {
      clearFilter: true,
      exportCsv: true,
      exportXlsx: true,
      colPreferences: true,
      // tableOptions: true,
      summaryRows: true,
      searchFilter: false,
    },
    registry: { marketList: [] },
  },
  searchFilterConfig: {
    startTime: {
      label: 'Start Date',
      value: dateStore.getDefaultRange()[0].toISOString(),
      type: 'date',
    },
    endTime: {
      label: 'End Date',
      value: dateStore.getDefaultRange()[1].toISOString(),
      type: 'date',
    },
    variant: {
      label: 'Variant',
      value: 'DayAhead',
      type: 'select',
      options: ['DayAhead', 'RealTime', 'AfterTheFact', 'Invoice'].map((x) => ({ value: x, label: x })),
    },
    volumeType: {
      label: 'Volume Type',
      value: null,
      type: 'select',
      options: [
        { value: 'TradeVolume', label: 'TradeVolume' },
        { value: 'DAMFinal', label: 'DAMFinal' },
        { value: 'RTMFinal', label: 'RTMFinal' },
        { value: 'Meter', label: 'Meter' },
        { value: 'Adjusted', label: 'Adjusted' },
      ],
    },
    sc: {
      label: 'Scheduling Coordinators',
      value: null,
      type: 'select',
      options: [],
    },
    resourceType: {
      label: 'Resource Type',
      value: null,
      type: 'select',
      options: [],
    },
    resource: {
      label: 'Resource',
      value: null,
      type: 'select',
      options: [],
    },
    marketType: {
      label: 'Market Type',
      value: null,
      type: 'select',
      options: [],
    },
    dealType: {
      label: 'Deal Type',
      value: null,
      type: 'select',
      options: [],
    },
    counterParty: {
      label: 'Counter Party',
      value: null,
      type: 'select',
      options: [],
    },
    selfScheduleTrade: {
      label: 'Self Schedule/Trade',
      value: null,
      type: 'select',
      options: [],
    },
    tradeType: {
      label: 'Trade Type',
      value: null,
      type: 'select',
      options: [],
    },
  },
};

const getters = {
  getSummaryData(state) {
    if (state.schedules.length > 0) {
      state.schedules.forEach((schedule) => {
        schedule.total = Object.entries(schedule).reduce((acc, [key, val]) => key.startsWith('he') ? acc + val : acc, 0);
      });
      const summaryItem = { rec: state.schedules[0].rec };
      state.schedulesConfig.columns.forEach(({ prop }) => {
        if (prop.startsWith('he') || prop === 'total') {
          summaryItem[prop] = Math.round(state.schedules.reduce((acc, val) => acc + val[prop], 0));
        }
      });
      summaryItem.summaryFlag = true;
      return [summaryItem];
    }
    return [];
  },
};

const actions = {
  initialize({ commit, dispatch, state }) {
    // dispatch('fetchSchedules');
  },
  async fetchSchedules({ dispatch, commit, state }, params) {
    params.endTime = moment(params.endTime).add(1, 'day').utc().format();
    ETRM_API.get('/trades/schedules', { params }).then(({ data }) => {
      console.log('get complete', data);
      commit('setSchedules', data);
    });
  },
  async saveSchedules({ commit, state }) {
    const heToArray = (model) => {
      const heArray = [];
      for (let i = 1; i <= 24; i++) {
        heArray.push(model[HEProperty(i)]);
      }
      return heArray;
    };

    const asModel = state.schedules.map((x) => ({
      id: x.id,
      termId: x.termId,
      transactionId: x.transactionId,
      heArray: heToArray(x),
      variant: x.variant,
      intervalDate: x.intervalDate,
    }));

    ETRM_API.put('/trades/schedules', asModel);
  },
  dateRangeChanged({ commit, dispatch }, value) {
    commit('setDateRange', value);
    dispatch('fetchSchedules');
  },
  selectedVariantChanged({ commit, dispatch }, value) {
    commit('setSelectedVariant', value);
    dispatch('fetchSchedules');
  },
};

const mutations = {
  setDateRange(state, value) {
    state.tradeDateRange = value;
  },
  setMarketType(state, value) {
    state.selectedMarketType = value;
  },
  setSelectedVariant(state, value) {
    state.selectedVariant = value;
  },
  reset(state) {
    state.schedules = [];
    state.currentSchedule = null;
  },
  setSchedules(state, value) {
    state.schedules = value;
  },
  setTableData(state, { rowIndex, prop, value }) {
    const convertedNum = Number(value);
    if (Number.isNaN(convertedNum) || value === '') {
      state.schedules[rowIndex][prop] = undefined;
      state.schedules[rowIndex][prop] = null;
    } else {
      state.schedules[rowIndex][prop] = Math.abs(convertedNum);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
