import moment from 'moment';
import { has } from '@/utils/dataUtil';
import { ETAG_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const nullEtagGroup = () => ({
  children: [],
  description: '',
  effectiveDate: moment().startOf('day').toISOString(),
  id: 0,
  name: '',
  physicalPaths: [],
  terminationDate: '2099-12-31T00:00:00Z',
  tag: {
    position: 0,
    props: [],
  },
  type: 'Single',
});

const state = {
  tagCodeDefinitions: [],
  etagGroups: [],
  etagGroupData: {},
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, editable: false,
    }, {
      prop: 'name', label: 'Name', sortable: true, visible: true, editable: true, sortOrder: 'asc',
    }, {
      prop: 'description', label: 'Description', sortable: true, visible: true, editable: true,
    }, {
      prop: 'type', label: 'Type', sortable: true, visible: true, editable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', dataType: 'date', sortable: true, visible: true, editable: true,
    }, {
      prop: 'terminationDate', label: 'Termination Date', dataType: 'date', sortable: true, visible: true, editable: true,
    }],
  },
  selectedEtagGroup: nullEtagGroup(),
};

const getters = {};

const actions = {
  async process({ state }, payload) {
    try {
      await ETAG_API.put('groupings/process', payload);
      this.$notify('Successfully added process', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to add process', 'error');
    }
  },
  async fetchEtagGroups({ commit }) {
    try {
      const { data: { data } } = await ETAG_API.get('groupings');
      data.forEach((obj) => {
        obj.effectiveDate = moment(obj.effectiveDate).utc().format('YYYY-MM-DD');
      });
      commit('setEtagGroups', data);
    } catch (error) {
      this.$notify('Failed to load Etag groups', 'error');
      console.error(error);
    }
  },
  async fetchEtagGroup({ commit, state }, group) {
    if (!has(state.etagGroupData, group.id)) {
      try {
        const { data } = await ETAG_API.get(`groupings/${group.id}`);
        commit('setEtagGroupData', data);
      } catch (error) {
        this.$notify('Failed to load Etag group', 'error');
        console.error(error);
      }
    }
    commit('setSelectedEtagGroup', state.etagGroupData[group.id]);
  },
  async inactivateEtagGroup({ commit, state }, id) {
    try {
      await ETAG_API.delete(`groupings/${id}`);
      this.$notify('Successfully deleted group', 'success');
      commit('deleteEtagGroup', id);
    } catch (error) {
      this.$notify('Failed to delete Etag group', 'error');
      console.error(error);
    }
  },
  async createEtagGroup({ commit, state }, segments) {
    state.selectedEtagGroup.physicalPaths = segments;
    try {
      const { data } = await ETAG_API.post('groupings', state.selectedEtagGroup);
      state.selectedEtagGroup.id = data.id;
      commit('addEtagGroup', state.selectedEtagGroup);
      this.$notify('Delivery Group Added', 'success');
    } catch (error) {
      this.$notify('Failed to Add Delivery Group', 'error');
    }
  },
  async updateEtagGroup({ commit, state }, segments) {
    state.selectedEtagGroup.physicalPaths = segments;
    try {
      const { data } = await ETAG_API.put(`groupings/${state.selectedEtagGroup.id}`, state.selectedEtagGroup);
      state.selectedEtagGroup.id = data.id;
      state.selectedEtagGroup.effectiveDate = moment(data.effectiveDate).utc().format('YYYY-MM-DD');
      commit('updateEtagGroup', state.selectedEtagGroup);
      this.$notify('Delivery Group Updated', 'success');
    } catch (error) {
      this.$notify('Failed to Update Delivery Group', 'error');
    }
  },
  async fetchTagCodeDefinition({ commit }, cpse) {
    try {
      const { data } = await ETAG_API.get(`/code/${cpse}/definition`);
      data.build.forEach((def) => {
        if (def.type === 'counter' && !has(def, 'length')) def.length = 4;
        if (def.offset === undefined) def.offset = 0;
      });
      return Promise.resolve(data.build);
    } catch ({ response }) {
      // console.error(response);
      this.$notify(`Tag Code configuration doesn't exist for ${cpse}`, 'warning');
      return Promise.resolve([]);
    }
  },
  async fetchDefinitions({ commit }) {
    try {
      const { data } = await ETAG_API.get('/code/definitions');
      commit('setTagCodeDefinitions', data.definitions);
    } catch ({ response }) {
      console.error(response);
    }
  },
  async saveDefinition({ commit }, { cpse, data }) {
    try {
      await ETAG_API.get(`/code/${cpse}/definition`);
      await ETAG_API.put(`/code/${cpse}/definition`, { build: data });
      this.$notify(`Successfully updated tag code for ${cpse}`, 'success');
    } catch ({ response }) {
      try {
        await ETAG_API.post(`/code/${cpse}/definition`, { build: data });
        this.$notify(`Successfully created tag code for ${cpse}`, 'success');
      } catch (error) {
        this.$notify('Error saving tag code customization', 'error');
      }
    }
  },
};

const mutations = {
  addEtagGroup(state, data) {
    state.etagGroupData[data.id] = data;
    state.etagGroups.push(data);
  },
  updateEtagGroup(state, data) {
    const idx = state.etagGroups.findIndex(({ id }) => id === data.id);
    state.etagGroupData[data.id] = data;
    state.etagGroups.splice(idx, 1, data);
  },
  setEtagGroupData(state, data) {
    state.etagGroupData[data.id] = data;
  },
  setEtagGroups(state, data) {
    state.etagGroupData = data;
  },
  addDefinition(state, definition) {
    state.selectedTagCodeDefinition.push(definition);
  },
  setSelectedEtagGroup(state, data) {
    state.selectedEtagGroup = data || nullEtagGroup();
  },
  deleteEtagGroup(state, id) {
    state.etagGroups = state.etagGroups.filter((group) => group.id !== id);
  },
  ...createMutations(
    'etagGroups',
    'tagCodeDefinitions',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
