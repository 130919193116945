export class EventEmitter {
  constructor(){
    this.events = {};
  }

  subscribe(eventName, handler) {
    const handlers = this.events[eventName];
    if (Array.isArray(handlers)) {
      handlers.push(handler);
    } else {
      this.events[eventName] = [handler];
    }
  }

  unsubscribe(event, handler) {
    if (!(event in this.events)) {
       return;
    }
    const idx = this.events[event].indexOf(handler);
    if (idx > -1) {
      this.events[event].splice(idx, 1);
    }
    if (this.events[event].length === 0) {
      delete this.events[event];
    }
  }

  unsubscribeAll(eventName) {
    Object.keys(this.events).forEach((k) => {
      if(eventName) {
        if (eventName == k)
          delete this.events[k];
      } else {
        delete this.events[k];
      }
    });
  }

  
  emit(event, ...args) {
    if (!(event in this.events)) {
        return;
     }
    this.events[event].forEach(handler => handler(...args));
  }
};