import caisoUtil from '@/utils/caiso/caisoUtils';
import moment from 'moment';
import { CISO_SAMC_API, TOOLS_API } from '@/api';
import config from './whatIfBuilderConfig';

const state = {
  chargeCodes: [],
  billDeterminants: [],
  selectedChargeCodes: null,
  tableData: [],
  baList: [],
  tableConfigColumns: {},
};

const getters = {
  getBAList: (state) => state.baList.map(({ shortName }) => shortName),
  getChargeCodeList: (state) => [...new Set(state.chargeCodes.map(({ aliasName }) => aliasName))],
};

function camelCaseFunction(str) {
  if (str === 'BAL_AUTHORITY_AREA') str = 'baa';
  return str.toLowerCase().replace('_', ' ').replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchBAList');
  },
  async fetchChargeCodes({ commit, state }) {
    const { data: { data } } = await CISO_SAMC_API.get('/what-if/bds');
    commit('setChargeCodes', data);
    commit('setBillDeterminants', data);
  },
  async fetchBAList({ commit }) {
    try {
      const { data: { schedulingCoordinators: scs } } = await TOOLS_API.get('/scheduling-coordinators/resources');
      const data = caisoUtil.configureCoordinators(scs);

      commit('setBAList', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchBDAttributes({ commit, dispatch }, request) {
    try {
      const params = {
        scs: request.ba,
        chargeCodes: request.chargeCodes.join(','),
        bdNames: request.bdNames.join(','),
        tradingDate: request.tradingDate,
      };
      const { data: { data } } = await CISO_SAMC_API.get('/what-if/create', { params });

      if (data.data?.length) {
        const attributeProps = data.config.map((x) => camelCaseFunction(x));
        const configProps = config.tableConfiguration.columns.map(({ prop }) => prop);
        const combineProps = attributeProps.filter((x) => !configProps.includes(x));

        const newFields = [];

        combineProps.forEach((x) => {
          const obj = ({
            label: x.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').toUpperCase(),
            prop: x,
            filterable: true,
            sortable: true,
          });
          newFields.push(obj);
        });

        data.data.forEach((x) => {
          x.tradingDate = moment.utc(x.tradingDate).format('MM/DD/YYYY');
        });

        const newConfigColumns = config.tableConfiguration.columns.concat(newFields);
        commit('setTableConfigColumns', newConfigColumns);
        commit('setTableData', data.data);
      } else {
        dispatch('resetTable');
      }
    } catch (err) {
      console.error(err);
    }
  },
  async fetchWhatIfData({ commit, dispatch }, request) {
    try {
      const params = {
        scs: request.coordinatorListSelected,
        startDate: request.tradeDateRangeSelected[0],
        endDate: request.tradeDateRangeSelected[1],
      };
      const { data: { data } } = await CISO_SAMC_API.get('/what-if/fetch', { params });

      if (data.data?.length) {
        const attributeProps = data.config.map((x) => camelCaseFunction(x));
        const configProps = config.tableConfiguration.columns.map(({ prop }) => prop);
        const combineProps = attributeProps.filter((x) => !configProps.includes(x));

        const newFields = [];

        combineProps.forEach((x) => {
          const obj = ({
            label: x.replace(/([A-Z]+)*([A-Z][a-z])/g, '$1 $2').toUpperCase(),
            prop: x,
            filterable: true,
            sortable: true,
          });
          newFields.push(obj);
        });

        data.data.forEach((x) => {
          x.tradingDate = moment.utc(x.tradingDate).format('MM/DD/YYYY');
        });

        const newConfigColumns = config.tableConfiguration.columns.concat(newFields);
        commit('setTableConfigColumns', newConfigColumns);
        commit('setTableData', data.data);
      } else {
        dispatch('resetTable');
      }
    } catch (err) {
      console.error(err);
    }
  },
  resetTable({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setChargeCodes(state, value) {
    state.chargeCodes = value.data;
  },
  setBillDeterminants(state, value) {
    state.billDeterminants = value.data;
  },
  setSelectedChargeCodes(state, value) {
    state.selectedChargeCodes = value;
  },
  setBAList(state, baList) {
    state.baList = baList;
  },
  setTableConfigColumns(state, value) {
    state.tableConfigColumns = value;
  },
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
    state.tableConfigColumns = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};