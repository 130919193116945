import * as CONSTS from './constants';

export default {
  columns: [{
    prop: 'physical_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH,
  }, {
    prop: 'physical_mo_name',
    label: 'mo',
    width: CONSTS.PS_MO_WIDTH,
    minWidth: CONSTS.PS_MO_MIN_WIDTH,
    maxWidth: CONSTS.PS_MO_WIDTH,
    editable: true,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectCellRenderer' : 'pscs-select-cell',
      list: 'mo',
      placeholder: 'MO',
      valueProperty: 'physical_mo_name',
      labelProperty: 'physical_mo_name',
      clearable: true,
    },
  }, {
    prop: 'physical_se',
    label: 'se',
    width: CONSTS.PS_SE_WIDTH,
    minWidth: CONSTS.PS_SE_MIN_WIDTH,
    maxWidth: CONSTS.PS_SE_WIDTH,
    editable: true,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'SE',
      valueProperty: 'physical_se_name',
      labelProperty: 'physical_se_name',
    },
  }, {
    prop: 'physical_tp',
    label: 'tp',
    width: CONSTS.PS_TP_WIDTH,
    minWidth: CONSTS.PS_TP_MIN_WIDTH,
    maxWidth: CONSTS.PS_TP_WIDTH,
    editable: true,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'TP',
      valueProperty: 'physical_tp_name',
      labelProperty: 'physical_tp_name',
    },
  }, {
    prop: 'physical_por',
    label: 'por',
    editable: true,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'POR',
      valueProperty: 'physical_por_name',
      labelProperty: 'physical_por_name',
    },
  }, {
    prop: 'physical_pod',
    label: 'pod',
    editable: true,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'POD',
      valueProperty: 'physical_pod_name',
      labelProperty: 'physical_pod_name',
    },
  }, {
    prop: 'loss_accountingCount',
    label: 'L',
    width: CONSTS.MISC_INFO_WIDTH,
    minWidth: CONSTS.MISC_INFO_MIN_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagLossAccountingCellLinkRenderer' : 'tag-loss-accounting-link',
      overrideReadonly: true,
    },
  }, {
    prop: 'physical_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    minWidth: CONSTS.MISC_INFO_MIN_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    cellDataType: 'object',
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  },
  ],
  options: { showAddRemove: true, grayRepeatedRows: true },
  style: { dynamicSizing: false },
  columnList(name) {
    if (name === 'se') return this.registry.baList;
    if (name === 'tp') return this.registry.tpList;
    if (name === 'mo') return this.registry.moList;
  },
  registry: { moList: [] },
};
