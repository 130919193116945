import { STRUCTURES_API } from '@/api';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ dispatch, commit, state }) {
    STRUCTURES_API.get('/deliverable-type').then(({ data }) => {
      commit('loadTableData', data.deliverableTypes);
      this.$notify(`${data.deliverableTypes.length} Deliverable Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Deliverable Type(s)', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postDeliverableType({ dispatch, commit, state }, deliverableType) {
    STRUCTURES_API.post('/deliverable-type', deliverableType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Deliverable Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Deliverable Type', 'error');
    });
  },
  updateDeliverableType({ dispatch, commit, state }, deliverableType) {
    STRUCTURES_API.put(`/deliverable-type/${deliverableType.id}`, deliverableType).then(({ data }) => {
      commit('updateDeliverableType', data);
      this.$notify('Deliverable Type Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Deliverable Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/deliverable-type/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      this.$notify('Deliverable Type Removed', 'success');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    }).catch((err) => {
      this.$notify('Failed to Remove Deliverable Type', 'error');
    });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateDeliverableType(state, deliverableType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === deliverableType.id);
    state.tableData.splice(rowIndex, 1, deliverableType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, deliverableTypes) {
    state.tableData = deliverableTypes;
  },
  createTableRow(state) {
    const deliverableType = clone(state.nullTableRow);
    deliverableType.id = state.tableKey++;
    deliverableType.createdBy = this.getters['auth/getProfile'].userName;
    deliverableType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = deliverableType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((deliverableType) => deliverableType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};