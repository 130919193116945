import {
  tradeDateRange, ppas,
} from '@/components/PPA/BaseStore/ppasBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'PPA NAME',
    dataType: 'string',
    dataField: 'ppaName',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'SCHEDULING ENTITY',
    dataType: 'string',
    dataField: 'schedulingEntity',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'number',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SCHEDULE TYPE',
    dataType: 'string',
    dataField: 'scheduleType',
    area: 'column',
    expanded: true,
  }, {
    caption: 'PRODUCT TYPE',
    dataType: 'string',
    dataField: 'productType',
    area: 'column',
    expanded: true,
  }, {
    caption: 'RESOURCE ID',
    dataType: 'string',
    dataField: 'resource',
    width: 120,
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataType: 'number',
    format: '#,##0.#######',
    dataField: 'value',
    area: 'data',
    expanded: true,
  }, {
    caption: 'PPA GROUP',
    dataType: 'string',
    dataField: 'ppaGroup',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'COUNTER PARTY',
    dataType: 'string',
    dataField: 'counterParty',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataType: 'string',
    dataField: 'marketType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXECUTION TYPE',
    dataType: 'string',
    dataField: 'executionType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SOURCE SYSTEM',
    dataType: 'string',
    dataField: 'sourceSystem',
    area: 'filter',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    ppas,
  },
};