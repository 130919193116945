import { CISO_MASTERDATA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import helpers from '@/utils/helpers';
import { clone, has } from '@/utils/dataUtil';
import { formatDate } from './util';

const state = {
  heatRateTableData: [],
  schedulingCoordinators: [],
  selectedSchedulingCoordinator: '',
  selectedResource: null,
  selectedSegment: null,
  dateTimeFormats: [
    { name: 'effDate', format: 'YYYY-MM-DD' },
    { name: 'termDate', format: 'YYYY-MM-DD' },
    { name: 'createdDate', format: 'YYYY-MM-DD' },
    { name: 'updatedDate', format: 'YYYY-MM-DD' },
  ],
  remoteSearch: null,
  heatRateTableConfiguration: {
    columns: [{
      prop: 'sc', label: 'SC', fixed: 'left', width: 70, sortable: true, filter: true, editable: false,
    }, {
      prop: 'resource', label: 'Resource', fixed: 'left', width: 140, sortable: true, filter: true, editable: false,
    }, {
      prop: 'configuration', width: 140, visible: true, fixed: 'left', editable: false,
    }, {
      prop: 'segment',
      label: 'Segment',
      fixed: 'left',
      width: 115,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        placeholder: 'Add Segment',
        clearable: false,
        listFromProperty: 'segmentList',
      },
    }, {
      prop: 'remove', label: 'remove segment', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, editable: true,
    }, {
      prop: 'operatingLevel', label: 'Operating Level', width: 100, editable: true,
    }, {
      prop: 'rate', label: 'Heat Rate', width: 100, editable: true,
    }, {
      prop: 'emissionRate', label: 'Emission Rate', width: 100, editable: true,
    }, {
      prop: 'averageCost', label: 'Average Cost', width: 100, editable: true,
    }, {
      prop: 'effDate', label: 'Effective Date', type: { name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: false }, width: 140, editable: true,
    }, {
      prop: 'termDate', label: 'Termination Date', type: { name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: false }, width: 140, editable: true,
    }],
    footer: { count: true },
    style: { maxHeight: 800 },
    options: {
      searchFilter: false,
      warningHighlight: true,
      disableYAxisFill: true,
      cellSelection: true,
    },
  },
};

const getters = {
  getHeatRateTableConfiguration: (state) => state.heatRateTableConfiguration,
  getHeatRateTableData: (state) => state.heatRateTableData,
  getMinHeight: (state) => (state.heatRateTableData && state.heatRateTableData.length > 0) ? 600 : 100,
  getSchedulingCoordinators: (state) => state.schedulingCoordinators.map((i) => ({ value: i.id, label: i.shortName })),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
};

const actions = {
  cellClickChanged({ commit, dispatch }, item) {},
  async save({ state, commit, dispatch }, item) {
    const innerHeatRates = [];
    state.heatRateTableData.forEach((o) => {
      if (o.heatRates.length > 0) {
        for (let i = 0; i < o.heatRates.length; i++) {
          innerHeatRates.push(o.heatRates[i]);
        }
      }
    });

    const heatRatesToSave = { heatRates: innerHeatRates.filter((o) => o.isDirty) };
    if (heatRatesToSave.heatRates.length > 0) {
      await CISO_MASTERDATA_API.put('/resources/batch/heatrates', heatRatesToSave).then((response) => {
        commit('updateHeatRateIds', response.data.heatRates);
        commit('cleanDirty');
        item.callback({ type: 'success', title: 'Success Saving Heat Rate(s)' });
      }).catch((err) => {
        item.callback({ type: 'error', title: 'Error Saving Heat Rate' });
        console.log(err);
      });
    }
  },
  async deleteSegment({ commit, dispatch, state }, item) {
    const heatRatesToRemove = state.heatRateTableData.filter((o) => o.remove);

    if (heatRatesToRemove.length > 0) {
      for (let i = 0; i < heatRatesToRemove.length; i++) {
        if (heatRatesToRemove[i].id !== -1) {
          CISO_MASTERDATA_API.delete(`/resources/heatRate/${heatRatesToRemove[i].id}`).then((response) => {
            commit('removeSegmentsFromHeatRateTable', heatRatesToRemove[i]);
            dispatch('save', item);
          }).catch((err) => {
            item.callback({ type: 'error', title: 'Error removing Heat Rate(s)' });
            console.log(err.response);
          });
        } else if (heatRatesToRemove[i].id === -1) {
          commit('removeSegmentsFromHeatRateTable', heatRatesToRemove[i]);
        }
      }
    }
  },
  loadData({ commit, dispatch }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators').then((response) => {
      commit('setSchedulingCoordinators', response.data.schedulingCoordinators);
      dispatch('loadDataBySCChangeAction');
    }).catch((err) => {
      console.log(err);
    });
  },
  dataChanged({ commit }, item) {
    commit('updateHeatRateTableData', item);
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
    dispatch('loadDataBySCChangeAction');
  },
  loadDataBySCChangeAction({ commit, state }) {
    if (state.selectedSchedulingCoordinator) {
      const sc = state.schedulingCoordinators.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;

      const params = { variant: 'Master_File', sc, includeConfig: true };
      CISO_MASTERDATA_API.get('resources/fullResource', { params }).then((response) => {
        commit('setHeatRateTableData', response.data.resources);
      }).catch((err) => {
        console.log(err);
      });
    }
  },
};

const mutations = {
  updateHeatRateTableData(state, item) {
    state.selectedResource = state.heatRateTableData[item.rowIndex];
    let segment = Number(state.selectedResource.segment);

    if (item.prop === 'segment') {
      if (item.value === 'Add Segment') {
        const { segmentList } = state.selectedResource;
        segment = segmentList.length;
        segmentList.splice(segmentList.length - 1, 0, { value: segmentList.length, label: segmentList.length });

        if (segmentList.length === 12) { segmentList.pop(); }

        const blankHeatRate = {
          resourceId: state.selectedResource.resourceId,
          averageCost: null,
          effDate: formatDate(new Date()),
          emissionRate: null,
          id: -1,
          operatingLevel: null,
          rate: null,
          segmentNumber: Number(segment),
          termDate: null,
          variant: 'MASTER_DATA',
          isDirty: true,
          remove: false,
        };
        state.selectedResource.heatRates.push(blankHeatRate);
      } else {
        segment = Number(item.value);
      }
    }

    const editHeatRate = state.selectedResource.heatRates.find((o) => o.segmentNumber == segment);
    if (item.value === 'Add Segment') editHeatRate[item.prop] = segment;
    else editHeatRate[item.prop] = item.value;

    if (item.prop !== 'segment') editHeatRate.isDirty = true;

    const propsToMap = Object.keys(editHeatRate);
    propsToMap.forEach((prop) => {
      state.selectedResource[prop] = editHeatRate[prop];
    });
  },
  setHeatRateTableData(state, items) {
    const result = [];
    for (let i = 0; i < items.length; i++) {
      const resource = items[i];
      if (resource.configurations && resource.configurations.length > 0) {
        for (let j = 0; j < resource.configurations.length; j++) {
          const config = resource.configurations[j];
          const segmentList = [];
          const segments = config.heatRates.map(({ segmentNumber }) => segmentNumber);
          segments.forEach((segment) => segmentList.push({ value: segment, label: segment }));

          const segment = (segmentList[0] !== undefined) ? segmentList[0].value : null;

          if (segment === null) segmentList.push({ value: 1, label: 1 });
          else if (segmentList.length <= 10) segmentList.push({ value: 'Add Segment', label: 'Add Segment' });

          const initialHeatRate = (config.heatRates.length > 0) ? config.heatRates[0] : null;
          result.push({
            id: (initialHeatRate) ? initialHeatRate.id : null,
            configurationId: config.id,
            resourceId: config.resourceId,
            sc: resource.sc,
            resource: resource.shortName,
            configuration: config.shortName,
            segment,
            segmentList,
            operatingLevel: (initialHeatRate) ? initialHeatRate.operatingLevel : null,
            rate: (initialHeatRate) ? initialHeatRate.rate : null,
            emissionRate: (initialHeatRate) ? initialHeatRate.emissionRate : null,
            averageCost: (initialHeatRate) ? initialHeatRate.averageCost : null,
            effDate: (initialHeatRate && initialHeatRate.effDate) ? dateStore.formatDateTimeFields(initialHeatRate.effDate, state.dateTimeFormats) : null,
            termDate: (initialHeatRate && initialHeatRate.termDate) ? dateStore.formatDateTimeFields(initialHeatRate.termDate, state.dateTimeFormats) : null,
            heatRates: (initialHeatRate) ? config.heatRates : [],
            isDirty: false,
            remove: false,
          });
        }
      } else {
        const segmentList = [];
        const segments = resource.heatRates.map(({ segmentNumber }) => segmentNumber);

        segments.forEach((segment) => {
          segmentList.push({ value: segment, label: segment });
        });

        const segment = (segmentList[0] != undefined) ? segmentList[0].value : null;

        if (segment === null) segmentList.push({ value: 'Add Segment', label: 'Add Segment' });
        else if (segmentList.length <= 10) segmentList.push({ value: 'Add Segment', label: 'Add Segment' });

        const initialHeatRate = (resource.heatRates.length > 0) ? resource.heatRates[0] : null;
        result.push({
          id: (initialHeatRate) ? resource.heatRates[0].id : null,
          resourceId: resource.id,
          sc: resource.sc,
          resource: resource.shortName,
          configuration: null,
          segment,
          segmentList,
          operatingLevel: (initialHeatRate) ? initialHeatRate.operatingLevel : null,
          rate: (initialHeatRate) ? initialHeatRate.rate : null,
          emissionRate: (initialHeatRate) ? initialHeatRate.emissionRate : null,
          averageCost: (initialHeatRate) ? initialHeatRate.averageCost : null,
          effDate: (initialHeatRate && initialHeatRate.effDate) ? dateStore.formatDateTimeFields(initialHeatRate.effDate, state.dateTimeFormats) : null,
          termDate: (initialHeatRate && initialHeatRate.termDate) ? dateStore.formatDateTimeFields(initialHeatRate.termDate, state.dateTimeFormats) : null,
          heatRates: (initialHeatRate) ? resource.heatRates : [],
          isDirty: false,
        });
      }
    }
    state.heatRateTableData = result;
  },
  cleanDirty(state) {
    const updatedTableData = clone(state.heatRateTableData);
    updatedTableData.forEach((o) => {
      if (o.isDirty) {
        o.isDirty = false;
        for (let i = 0; i < o.heatRates.length; i++) {
          o.heatRates[i].isDirty = false;
        }
      }
    });
    // Force table refresh
    state.heatRateTableData = updatedTableData;
  },
  setSchedulingCoordinators(state, item) {
    state.schedulingCoordinators = item;
    if (item.length > 0) state.selectedSchedulingCoordinator = item[0].id;
  },
  changeSchedulingCoordinatorMutation(state, item) {
    state.selectedSchedulingCoordinator = item;
  },
  changeSegmentMutation(state, item) {
    state.selectedSegment = item;
  },
  removeSegmentsFromHeatRateTable(state, item) {
    const td = clone(state.heatRateTableData);
    const updatedItem = td.find((o) => o.id === item.id);

    updatedItem.segmentList = updatedItem.segmentList.filter((o) => o.value === item.segment);
    updatedItem.heatRates = updatedItem.heatRates.filter((o) => updatedItem.segment === o.segmentNumber);
    updatedItem.segmentList = helpers.orderBy(updatedItem.segmentList, ['value']);

    for (let i = 1; i < updatedItem.segmentList.length + 1; i++) {
      if (updatedItem.segmentList[i - 1].value != 'Add Segment') {
        updatedItem.heatRates[i - 1].segmentNumber = i;
        updatedItem.heatRates[i - 1].segment = i;
        updatedItem.segmentList[i - 1].value = i;
        updatedItem.segmentList[i - 1].label = i;
        updatedItem.heatRates[i - 1].isDirty = true;
      }
    }

    if (updatedItem.heatRates.length > 0) {
      // map to next item
      const mapToNextItem = Object.keys(updatedItem.heatRates[0]);
      mapToNextItem.forEach((prop) => {
        updatedItem[prop] = updatedItem.heatRates[0][prop];
      });
      updatedItem.segment = updatedItem.segmentNumber;
      updatedItem.remove = false;
    } else {
      // clear out remaining data of final object being deleted
      updatedItem.heatRates = [];
      updatedItem.isDirty = false;
      updatedItem.remove = false;
      updatedItem.segment = null;
      updatedItem.segmentNumber = null;
      updatedItem.operatingLevel = null;
      updatedItem.rate = null;
      updatedItem.emissionRate = null;
      updatedItem.rate = null;
      updatedItem.averageCost = null;
      updatedItem.effDate = null;
      updatedItem.termDate = null;
    }

    if (updatedItem.segmentList.length === 10 && updatedItem.segmentList[9].value !== 'Add Segment') { updatedItem.segmentList.push({ value: 'Add Segment', label: 'Add Segment' }); }

    state.heatRateTableData = td;
  },
  updateHeatRateIds(state, updatedHeatRates) {
    const updatedTableData = clone(state.heatRateTableData);
    const oldHeatRates = updatedTableData.find((o) => o.isDirty);

    for (let i = 0; i < updatedHeatRates.length; i++) {
      let itemToUpdate = null;
      let innerHeatRate = null;
      if (has(updatedHeatRates[i], 'configuration') && updatedHeatRates[i].configuration !== null) {
        itemToUpdate = oldHeatRates.find((o) => (o.configurationId === updatedHeatRates[i].configurationId && updatedHeatRates[i].segmentNumber === o.segmentNumber));

        if (typeof itemToUpdate !== 'undefined') {
          innerHeatRate = itemToUpdate.heatRates.find((o) => o.configurationId === updatedHeatRates[i].configurationId && updatedHeatRates[i].segmentNumber === o.segmentNumber);
          itemToUpdate.id = updatedHeatRates[i].id;
          innerHeatRate.id = updatedHeatRates[i].id;
        }
      } else {
        itemToUpdate = oldHeatRates.find((o) => (o.resourceId == updatedHeatRates[i].resourceId && updatedHeatRates[i].segmentNumber == o.segmentNumber));
        if (typeof itemToUpdate !== 'undefined') {
          innerHeatRate = itemToUpdate.heatRates.find((o) => (o.resourceId == updatedHeatRates[i].resourceId && updatedHeatRates[i].segmentNumber == o.segmentNumber));
          itemToUpdate.id = updatedHeatRates[i].id;
          innerHeatRate.id = updatedHeatRates[i].id;
        }
      }
    }
    state.heatRateTableData = updatedTableData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};