import availableCreditStoreRef from './Credit/AvailableCredit/availableCreditStore';
import forwardPriceCurvesRef from './RiskManager/forwardPriceCurvesStore';
import generateInvoiceRef from './AccountingManager/generateInvoiceStore';
import invoiceRef from './AccountingManager/invoiceStore';
import markToMarketResultsRef from './MarkToMarket/markToMarketResultsStore';
import markToMarketRunBookRef from './RiskManager/markToMarketRunBookStore';
import mtmStoreRef from './Credit/mtmStore';
import notifyStoreRef from './Credit/notifyStore';
import priceCurveGroupRef from './RiskManager/priceCurveGroupStore';
import tagTemplateProfileRef from './TradeManager/tagTemplateProfileStore';
import viewSchedulesRef from './TradeManager/viewSchedulesStore';
import creditLimitRef from './CreditLimit/creditLimitStore';
import tradingGroup from './TradingGroup/tradingGroupStore';
import tradeTermGroupRef from './Settings/TradeTermGroup/tradeTermGroupStore';
import trades from './TradeManager/Trades/tradesStore';
import etrmSettings from './Settings/etrmSettingsStore';
import tradeValidation from './TradeValidation/tradeValidationStore';
import transactionLogRef from './TransactionLog/transactionLogStore';
import payment from './Payment/PaymentsStore';
import externalImportsRef from './Imports/externalImportsStore';

export default {
  namespaced: true,
  modules: {
    availableCreditStoreRef,
    forwardPriceCurvesRef,
    generateInvoiceRef,
    invoiceRef,
    markToMarketResultsRef,
    markToMarketRunBookRef,
    mtmStoreRef,
    notifyStoreRef,
    priceCurveGroupRef,
    tagTemplateProfileRef,
    viewSchedulesRef,
    creditLimitRef,
    trades,
    etrmSettings,
    tradingGroup,
    tradeTermGroupRef,
    tradeValidation,
    transactionLogRef,
    payment,
    externalImportsRef,
  },
};