import { tradeDateRange, coordinatorList } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    coordinatorList,
    tradeDateRange,
  },
  tableConfiguration: {
    name: 'what-if',
    columns: [{
      label: 'BA',
      prop: 'ba',
      filterable: true,
      sortable: true,
    }, {
      label: 'TRADING DATE',
      prop: 'tradingDate',
      filterable: true,
      sortable: true,
    }, {
      label: 'BD Name',
      prop: 'bdName',
      filterable: true,
      sortable: true,
      width: 200,
    }, {
      label: 'TRADING HOUR',
      prop: 'tradingHour',
      filterable: true,
      sortable: true,
    }, {
      label: 'TRADING INTERVAL',
      prop: 'tradingInterval',
      filterable: true,
      sortable: true,
    }, {
      label: 'VALUE',
      prop: 'val',
      dataType: 'number',
      format: '#,##0.##########',
      filterable: true,
      sortable: true,
    }],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnConfig: true,
      showSelectFilter: false,
    },
  },
};