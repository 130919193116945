import ads from '@/components/Ciso/ADS/store';
import cmri from '@/components/Ciso/CMRI/store';
import samc from '@/components/Ciso/SaMC/store';
import oasis from '@/components/Ciso/OASIS/store';
import sibr from '@/components/Ciso/SIBR/store';
import bsap from '@/components/Ciso/BSAP/store';
import masterFile from '@/components/Ciso/MasterFile/store';
import cira from '@/components/Ciso/CIRA/store';
import oms from '@/components/Ciso/OMS/store';
import meter from '@/components/Ciso/Meter/store';
import cisoBaseStore from '@/components/Ciso/BaseStore/CisoBaseStore';
import cisoBidStrategyStore from '@/components/Scheduling/BidStrategy/store';
import cisoSchedulingStore from '@/components/Scheduling/store';
import strategyStore from '@/components/Scheduling/Strategies/store';
import strategyVariableStore from '@/components/Scheduling/Strategies/store/StrategyVariablesStore';
import schedulesPreviewStore from '@/components/Scheduling/Preview/store';

export default {
  namespaced: true,
  state: {},
  modules: {
    scheduling: cisoSchedulingStore,
    bidstrategy: cisoBidStrategyStore,
    ads,
    cmri,
    samc,
    oasis,
    sibr,
    meter,
    bsap,
    masterFile,
    cira,
    oms,
    cisoBaseStore,
    strategyStore,
    strategyVariableStore,
    schedulesPreviewStore,
  },
};
