const lookup: any = {
  ui: 'lookup:ui',
  book: 'lookup:book',
  enablingAgreement: 'lookup:enablingAgreement',
  broker: 'lookup:broker',
  commodity: 'lookup:commodity',
  company: 'lookup:company',
  companyContact: 'lookup:company_contact',
  contractCoordinator: 'lookup:contract_coordinator',
  contractStateType: 'lookup:contract_state_type',
  contractType: 'lookup:contract_type',
  currency: 'lookup:currency',
  deliverableOccurrenceLogStatusType: 'lookup:deliverable_occurrence_log_status_type',
  deliverableType: 'lookup:deliverable_type',
  eventType: 'lookup:event_type',
  tradeType: 'lookup:trade_type',
  firmType: 'lookup:firm_type',
  fuelType: 'lookup:fuel_type',
  ghgSubAccount: 'lookup:ghg_sub_account',
  location: 'lookup:location',
  locationGroup: 'lookup:location_group',
  locationType: 'lookup:location_type',
  market: 'lookup:market',
  marketSystem: 'lookup:market_system',
  marketAccount: 'lookup:market_account',
  marketParticipant: 'lookup:market_participant',
  paymentTerm: 'lookup:payment_term',
  portfolio: 'lookup:portfolio',
  productType: 'lookup:product_type',
  recordType: 'lookup:record_type',
  renewableSubAccount: 'lookup:renewable_sub_account',
  marketSelfScheduleType: 'lookup:market:self_schedule_type',
  strategy: 'lookup:strategy',
  termProduct: 'lookup:term_product',
  termType: 'lookup:term_type',
  marketTradeProduct: 'lookup:market:trade_product',
  marketTradeType: 'lookup:market:trade_type',
  unit: 'lookup:unit',
  variant: 'lookup:variant',
  price: 'lookup:price',
  catalog: 'lookup:catalog',
  isoReference: 'lookup:iso_reference',

  blotter: 'lookup:blotter',
  resourceAvailability: 'lookup:resource_availability',
  downloader: 'lookup:downloader',
};

export default lookup;