export default {
  name: 'statementDetails2',
  columns: [{
    label: 'FILE ID',
    prop: 'stmtImport_Id',
    filterable: false,
    sortable: false,
    visible: false,
  }, {
    label: 'COMP ID',
    prop: 'compareStmtImport_Id',
    filterable: false,
    sortable: false,
    visible: false,
  }, {
    label: 'FILE TYPE 1',
    prop: 'fileType1',
    filterable: false,
    sortable: false,
    visible: false,
  }, {
    label: 'FILE TYPE 2',
    prop: 'fileType2',
    filterable: false,
    sortable: false,
    visible: false,
  }, {
    label: 'NULL ROW',
    prop: 'nullRow',
    filterable: true,
    // selectedFilterOperation: '=',
    // filterValue: false,
    // filterValues: [false],
    sortable: true,
    visible: false,
  }, {
    label: 'BA',
    prop: 'ba',
    filterable: true,
    sortable: true,
    visible: true,
    width: 75,
  }, {
    label: 'SC',
    prop: 'sc',
    filterable: true,
    sortable: true,
    visible: true,
    width: 75,
  }, {
    label: 'TRADING DATE',
    prop: 'tzTradingDate',
    format: 'yyyy-MM-dd',
    filterable: true,
    sortable: true,
    visible: true,
    width: 100,
  }, {
    label: 'TRADING DATE PPT',
    prop: 'tradingDate',
    format: 'yyyy-MM-dd',
    filterable: true,
    sortable: true,
    visible: false,
  }, {
    label: 'CHARGE CODE',
    prop: 'chargeCode',
    filterable: true,
    sortable: true,
    visible: true,
    width: 80,
  }, {
    label: 'DESCRIPTION',
    prop: 'description',
    filterable: true,
    sortable: true,
    visible: true,
    width: 275,
  }, {
    label: 'FILE VOLUME',
    prop: 'volume',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    format: '#,###.##',
    width: 150,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
  }, {
    label: 'CURRENT AMOUNT',
    prop: 'currentAmount',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'PREVIOUS AMOUNT',
    prop: 'previousAmount',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'NET AMOUNT',
    prop: 'netAmount',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  },
  {
    label: 'PTB AMOUNT',
    prop: 'ptbAmount',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  },
  {
    label: 'COMPARE AMOUNT',
    prop: 'compareAmount',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    alignment: 'right',
    width: 150,
  }, {
    label: 'VARIANCE AMOUNT',
    prop: 'variance',
    dataType: 'number',
    filterable: true,
    sortable: true,
    visible: true,
    calculateSortValue(value) {
      const nullValue = this.sortOrder === 'asc' ? Infinity : -Infinity; // empty values forced to bottom of grid
      return value[this.dataField] != null ? value[this.dataField] : nullValue;
    },
    format(value) {
      if (value === null) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'VARIANCE STATUS',
    prop: 'varianceStatus',
    filterable: true,
    sortable: true,
    visible: true,
    width: 125,
  }, {
    label: 'VARIANCE CAUSE',
    prop: 'varianceCause',
    filterable: true,
    sortable: true,
    visible: true,
    width: 125,
  }, {
    label: 'NOTES',
    prop: 'notes',
    filterable: true,
    sortable: true,
    visible: true,
    width: 125,
    cssClass: 'notes-col',
  }, {
    label: 'FILE VERSION',
    prop: 'fileVersion',
    filterable: true,
    sortable: true,
    visible: true,
    width: 75,
  }, {
    label: 'COMP VERSION',
    prop: 'compareFileVersion',
    filterable: true,
    sortable: true,
    visible: true,
    width: 75,
  }, {
    label: 'Disputes',
    prop: 'disputes',
    filterable: true,
    sortable: true,
    visible: true,
    width: 150,
    cellTemplate: 'PscsDropDownCellTemplate',
  }],
  summary: {
    fileVolumeSummary: {
      prop: 'FILE VOLUME',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'decimal',
        });
        return formatter.format(value);
      },
    },
    currentAmountSummary: {
      prop: 'CURRENT AMOUNT',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    previousAmountSummary: {
      prop: 'PREVIOUS AMOUNT',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    netAmountSummary: {
      prop: 'NET AMOUNT',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    compareAmountSummary: {
      prop: 'COMPARE AMOUNT',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    varianceSummary: {
      prop: 'VARIANCE AMOUNT',
      dataType: 'number',
      summaryType: 'sum',
      format(value) {
        if (value === null) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
  },
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
    showSelectFilter: false,
  },
};