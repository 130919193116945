import Vue from 'vue';
import VueRouter from 'vue-router';
import dateStore from '@/utils/dateStore';
import { getInstance } from '@/auth/authWrapper';
import { hasPermission } from '../utils/authUtils';
import store from '../store';
import routes from './routes';

const oidcEnabled = window?.OIDC_AUTH_ENABLED ?? false;

Vue.use(VueRouter);

const getProfile = async () => new Promise(((resolve, reject) => {
  const prof = store.state.auth.profile;

  if (prof === null || prof === undefined) {
    const unwatch = store.watch(
      () => store.state.auth.profile,
      (value) => {
        unwatch();
        resolve(value);
      },
    );
  } else {
    resolve(store.state.auth.profile);
  }
}));

const router = new VueRouter({ mode: 'history', routes });

const oidcNavigationGuard = async (to, from, next) => {
  const authService = getInstance();
  const fn = async () => {
    if (authService.error) {
      if (to.name === 'AuthServiceFailure') {
        next();
      } else {
        next({ name: 'AuthServiceFailure' });
      }
    } else if (localStorage.getItem('isAuthenticated') === 'true') {
      if (to.name === 'ConfirmUser') {
        const { 'user-code': userCode, 'user-state': userState, 'user-reqId': userReqId } = localStorage;
        if (userCode && userState) {
          localStorage.removeItem('user-code');
          localStorage.removeItem('user-state');
          localStorage.removeItem('user-reqId');
          next({ name: 'ConfirmUser', query: { code: userCode, state: userState, 'client-request-id': userReqId } });
        } else if (to.query?.code && to.query?.state) {
          next();
        } else next('/');
      } else {
        let p;
        try { p = await getProfile(); } catch (e) { console.error(e); return; }
        if (p.permissions.length === 0) {
          if (to.name === 'AccessDenied') next();
          else next({ name: 'AccessDenied' });
        } else if (to.matched.some((r) => r.meta.requiresAuth)) {
          const titleSuffix = ' - SettleCore - Power Settlements';
          let nextTitle = 'Welcome';
          if (to.name) nextTitle = to.name;
          if (to.meta.text) nextTitle = to.meta.text;
          if (to?.meta?.permission) {
            if (hasPermission(p.permissions, to.meta.permission.name, to.meta.permission.rights)) {
              document.title = nextTitle + titleSuffix;
              dateStore.handleRouteMetaRequiredTimeZone(to);
              next();
            } else {
              document.title = 'SettleCore - Power Settlements';
              next('/');
            }
          } else {
            document.title = nextTitle + titleSuffix;
            next();
          }
        } else if (to.matched.some((r) => !r.meta.requiresAuth)) {
          next();
        } else {
          next('/');
        }
      }
    } else if (to.name === 'Login') {
      next();
    } else if (oidcEnabled) {
      next('/Login');
    } else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
  };

  if (!authService.loading) {
    await fn();
  } else {
    const unwatch = authService.$watch('loading', async (newVal, oldVal) => {
      if (!newVal) {
        await fn();
        unwatch();
      }
    });
  }
};

const auth0NavigationGuard = (to, from, next) => {
  const authService = getInstance();
  const userCode = localStorage.getItem('user-code');
  const userState = localStorage.getItem('user-state');

  const fn = async () => {
    if (authService.error) {
      if (to.name === 'AuthServiceFailure') {
        next();
      } else {
        next({ name: 'AuthServiceFailure' });
      }
    } else if (to.name === 'ConfirmUser') {
      if (to.query?.code && to.query?.state) next();
      else next('/');
    } else if (localStorage.getItem('isAuthenticated') === 'true') {
      const p = await getProfile();
      if (p.permissions.length === 0) {
        if (to.name === 'AccessDenied') {
          next();
        } else {
          next({ name: 'AccessDenied' });
        }
      } else if (to.matched.some((r) => r.meta.requiresAuth)) {
        const titleSuffix = ' - SettleCore - Power Settlements';
        let nextTitle = 'Welcome';
        if (to.name) nextTitle = to.name;
        if (to.meta.text) nextTitle = to.meta.text;

        if (to?.meta?.permission) {
          if (hasPermission(p.permissions, to.meta.permission.name, to.meta.permission.rights)) {
            document.title = nextTitle + titleSuffix;

            dateStore.handleRouteMetaRequiredTimeZone(to);

            next();
          } else {
            document.title = 'SettleCore - Power Settlements';
            next('/');
          }
        } else {
          document.title = nextTitle + titleSuffix;
          next();
        }
      } else if (to.matched.some((r) => !r.meta.requiresAuth)) {
        if (to.name === 'ConfirmUser') next('/');
        else next();
      } else {
        next('/');
      }
    } else if (to.name === 'Login') {
      next();
    } else if (oidcEnabled) {
      next('/Login');
    } else {
      authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
  };

  if (!authService.loading) {
    fn();
  } else if (!userCode && !userState) {
    authService.$watch('loading', (loading) => {
      if (!loading) fn();
    });
  }
};

router.beforeEach((to, from, next) => {
  if (oidcEnabled) oidcNavigationGuard(to, from, next);
  else auth0NavigationGuard(to, from, next);
});

export default router;