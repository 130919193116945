import { createMutations } from '@/utils/vuexHelper';
import LOOKUP_STORE from '@/store/lookupStore';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import dateStore from '@/utils/dateStore';
import { ISO_REF_API, ETRM_API, STRUCTURES_API } from '@/api';
import { table_config } from './static';

const state = {
  tableData: [],
  userSelectedMarket: '',
  userSelectedEntity: '',
  userSelectedResource: '',
  userSelectedResourceType: '',
  userSelectedLocationSubTypes: [],
  locationList: [],
  tableConfig: table_config,
  defaultDate: dateStore.getDefaultDate().toISOString(),
};
const getters = {
  entities: () => [...new Set(REFERENCE_DATA_STORE.state.locationList
    .filter(({ activeFlag, entityType }) => activeFlag && entityType === 'SC')
    .map(({ entityName }) => entityName)
    .sort())],
  resources: (state) => [...new Set(LOOKUP_STORE.state.locationList
    .filter(({ activeFlag, marketName, entityName }) => activeFlag && marketName === state.userSelectedMarket && entityName === state.userSelectedEntity)
    .map(({ shortName }) => shortName)
    .sort())],
  resourceInfo: (state) => [...new Set(LOOKUP_STORE.state.locationList
    .filter(({ activeFlag, marketName, entityName }) => activeFlag && marketName === state.userSelectedMarket && entityName === state.userSelectedEntity)
    .map(({ shortName, locationType }) => { return {resourceName: shortName, resourceType: locationType} }))],
  tradeResources: (state) => [...new Set(LOOKUP_STORE.state.locationList
    .filter(({ activeFlag, marketName, entityName, locationType }) => activeFlag && marketName === state.userSelectedMarket 
        && entityName === state.userSelectedEntity && locationType?.toLowerCase() === state.userSelectedResourceType?.toLowerCase())
    .map(({ shortName }) => shortName)
    .sort())],
  tradeTypes: () => [...new Set(LOOKUP_STORE.state.tradeTypeList
    .map((tradeType) => tradeType.name))],
  marketProductTypes: (state) => [...new Set(LOOKUP_STORE.state.marketProductTypeList
    .filter(({ activeFlag, marketName }) => activeFlag && marketName === state.userSelectedMarket)
    .map(({ shortName }) => shortName)
    .sort())],
  counterPartySCs: (state) => [...new Set(LOOKUP_STORE.state.companyList
    .filter(({ activeFlag, shortName }) => activeFlag && shortName.length === 4)
    .map(({ shortName }) => shortName)
    .sort())],
  configurations: (state) => [...new Set(REFERENCE_DATA_STORE.state.locationList
    .filter(({ shortName }) => shortName === state.userSelectedResource)
    .flatMap(({ locationSubtypes}) => locationSubtypes.map(ls => ls.shortName))
    .sort())],

  // marketTradeTypes: () => [...new Set(LOOKUP_STORE.state.marketTradeTypeList
  //   .filter(({ activeFlag }) => activeFlag)
  //   .map(({ shortName }) => shortName)
  //   .sort())],
  // marketTradeProducts: () => [...new Set(LOOKUP_STORE.state.marketTradeProductList
  //   .filter(({ activeFlag }) => activeFlag)
  //   .map(({ shortName }) => shortName)
  //   .sort())],
  marketTradeTypes: () => ['AST', 'IST', 'UCT'],
  marketTradeProducts: () => ['APN', 'PHY'],
  marketSelfScheduleTypes: (state) => [...new Set(LOOKUP_STORE.state.marketSelfScheduleTypeList
    .filter(({ activeFlag, marketName }) => activeFlag && marketName === state.userSelectedMarket)
    .map(({ shortName }) => shortName)
    .sort())],
  forecastSourceTypes: () => [...new Set(LOOKUP_STORE.state.forecastSourceList
    .filter(({ activeFlag }) => activeFlag)
    .map(({ name }) => name)
    .sort())],
};

const actions = {
  async initialize({ state, dispatch }) {
    dispatch('LOOKUP_STORE/fetchLocationList');
    dispatch('LOOKUP_STORE/fetchTradeTypeList');
    dispatch('LOOKUP_STORE/fetchMarketProductTypeList');
    dispatch('LOOKUP_STORE/fetchMarketTradeTypeList');
    dispatch('LOOKUP_STORE/fetchMarketTradeProductList');
    dispatch('LOOKUP_STORE/fetchMarketSelfScheduleTypeList');
    dispatch('LOOKUP_STORE/fetchForecastSourceList');
    dispatch('LOOKUP_STORE/fetchCompanyList');

    await dispatch('fetchReferenceData');
    await dispatch('fetchData');
  },

  async fetchData({ state, dispatch, commit }) {
    try {
      const { data } = await ISO_REF_API.get('/iso-reference/caiso');
      commit('setTableData', data);
    } catch (error) {
      const message = error?.response?.data?.messages[0] ?? error?.message;
      this.$notify(`Failed to load ISO Reference: ${message}`, 'error');
    }
  },

  async addData({ state, dispatch, commit }, params) {
    try {
      const { data } = await ISO_REF_API.post('/iso-reference/caiso', params);
      if (state.tableData.map(({ id }) => id).includes(data.id)) {
        params.id = data.id;
        await actions.updateData({ commit, dispatch }, params);
        return;
      }
      commit('addToTableData', data);
      this.$notify('ISO Reference created', 'success');
    } catch (error) {
      const message = error?.response?.data?.messages[0] ?? error?.message;
      this.$notify(`Failed to create ISO Reference: ${message}`, 'error');
    }
  },

  async updateData({ commit, dispatch }, params) {
    await ISO_REF_API.put(`/iso-reference/caiso/${params.id}`, params).then(({ data }) => {
      commit('updateTableData', data);
      this.$notify('ISO Reference updated', 'success');
    }).catch((error) => {
      const message = error?.response?.data?.messages[0] ?? error?.message;
      this.$notify(`Failed to update ISO Reference: ${message}`, 'error');
    });
  },

  async deleteData({ commit, dispatch }, id) {
    await ISO_REF_API.delete(`/iso-reference/caiso/${id}`).then(() => {
      commit('deleteTableRow', id);
      this.$notify('ISO Reference deleted', 'success');
    }).catch((error) => {
      const message = error?.response?.data?.messages[0] ?? error?.message;
      this.$notify(`Failed to delete ISO Reference: ${message}`, 'error');
    });
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationList'],
      market: 'CAISO',
      commodity: 'POWER',
      activeFlag: 1,
      showSubTypes: true,
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    commit('setLocationList', REFERENCE_DATA_STORE.state.locationList);
  },

  mapData() {

  },

};

const mutations = {
  addToTableData(state, data) {
    state.tableData.push(data);
  },
  updateTableData(state, data) {
    const index = state.tableData.findIndex(({ id }) => id === data.id);
    if (index !== -1) state.tableData.splice(index, 1, data);
  },
  deleteTableRow(state, id) {
    state.tableData = state.tableData.filter((row) => row.id !== id);
  },

  ...createMutations(
    'tableData',
    'userSelectedMarket',
    'userSelectedEntity',
    'locationList',
    'userSelectedResourceType',
    'userSelectedResource'
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules: { LOOKUP_STORE, REFERENCE_DATA_STORE },
  actions,
};