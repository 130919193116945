import cellColors from '@/components/Etag/util';
import tagSummaries from './tagSummeriesMockData';
import deals from './powerDealsMockData';
import { HEColumns, clone } from '@/utils/dataUtil';

deals.forEach((deal) => {
  HEColumns().forEach((he) => {
    deal[he.label] = Math.round(Math.random() * 80);
  });
});
tagSummaries.forEach((tag) => {
  HEColumns().forEach((he) => {
    tag[he.label] = Math.round(Math.random() * 80);
  });
});

const state = {
  tagsConfig: {
    tableName: 'tagSummary',
    columns: [{
      prop: 'toEntity', label: 'ToEntity', filterable: true, sortable: true,
    }, {
      prop: 'gca', label: 'gca', filterable: true, sortable: true,
    }, {
      prop: 'cpse', label: 'cpse', filterable: true, sortable: true,
    }, {
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ViewTag',
        params: [{ key: 'tagId', dataKey: 'tagID' }, { key: 'entity', dataKey: 'toEntity' }],
        //TODO Add query for tagSummaryRef
      },
      filterable: true,
      label: 'code',
      prop: 'tagCode',
      sortable: true,
    }, {
      prop: 'lca', label: 'lca', filterable: true, sortable: true,
    }, {
      prop: 'source', label: 'source', filterable: true, sortable: true,
    }, {
      prop: 'sink', label: 'sink', filterable: true, sortable: true,
    }, {
      prop: 'gpe', label: 'gpe', filterable: true, sortable: true,
    }, {
      prop: 'lpe', label: 'lpe', filterable: true, sortable: true,
    }, {
      prop: 'implementationDateTime', label: 'start', filterable: true, sortable: true, dataType: 'datetime',
    }, {
      prop: 'terminationDateTime', label: 'end', filterable: true, sortable: true, dataType: 'datetime',
    }, {
      prop: 'originalGenMW', label: 'Original(MWh)', filterable: true, sortable: true,
    }, {
      prop: 'genMW', label: 'Current(MWh)', filterable: true, sortable: true,
    }, {
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      class: true,
      filterable: true,
      editorOptions: { ...cellColors },
      label: 'status',
      prop: 'compositeState',
      sortable: true,
    }, {
      prop: 'transactionType', label: 'type', filterable: true, sortable: true,
    }, {
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        masterDetailTemplate: 'PscsMasterDetailListCellTemplate',
        key: 'requests',
        columns: [
          { prop: 'requestID', label: 'Request ID' },
          { prop: 'toEntityName', label: 'To Entity' },
          { prop: 'fromEntityName', label: 'From Entity' },
          { prop: 'startDate', label: 'Start', dataType: 'datetime' },
          { prop: 'endDate', label: 'End', dataType: 'datetime' },
          { prop: 'messageType', label: 'Type' },
          { prop: 'requestStatus', label: 'Status', cellTemplate: 'PscsColoredCellTemplate' },
        ],
        options: { ...cellColors },
      },
      label: 'p',
      prop: 'pendingRequestCount',
      sortable: true,
    }, {
      prop: 'lastActionDateTime', label: 'last action', filterable: true, sortable: true,
    }, {
      prop: 'actOnByTime', label: 'deadline', filterable: true, sortable: true, dataType: 'datetime',
    }, {
      prop: 'wecReserve', label: 'wecc reserve', filterable: true, sortable: true,
    }],
    options: {
      filterRow: true,
      filterPanel: true,
      multipleSelection: true,
      columnsMovable: true,
      filterHeader: true,
    },
  },
  tagSummaries,
  dealsConfig: {
    columns: [{
      prop: 'id', label: 'ID', filterable: true,
    }, {
      prop: 'versionId', label: 'Version', filterable: true,
    }, {
      prop: 'dealType', label: 'Deal Type', filterable: true,
    }, {
      prop: 'marketName', label: 'Market', filterable: true,
    }, {
      prop: 'physicalFlag', label: 'Physical?', filterable: true,
    }, {
      prop: 'scheduleDeliveryFlag', label: 'Schedule?', filterable: true,
    }, {
      prop: 'buySellInd', label: 'Buy/Sell', filterable: true,
    }, {
      prop: 'tradeDate',
      label: 'Deal Date',
      filterable: true,
      editable: true,
      dataType: 'date',
    }, {
      prop: 'company', label: 'Company', filterable: true,
    }, {
      prop: 'contact', label: 'Contact', filterable: true,
    }, {
      prop: 'portfolio', label: 'Portfolio', filterable: true,
    }, {
      prop: 'startDate',
      label: 'Start',
      filterable: true,
      dataType: 'date',
    }, {
      prop: 'endDate',
      label: 'End',
      filterable: true,
      dataType: 'date',
    }, {
      prop: 'location', label: 'Location', filterable: true,
    }, {
      prop: 'contract', label: 'Contract', filterable: true,
    }, {
      prop: 'trader', label: 'Trader', filterable: true,
    }, {
      prop: 'timeZone', label: 'TZ', filterable: true,
    }, {
      prop: 'currency', label: 'Currency', filterable: true,
    }, {
      prop: 'project', label: 'Project', filterable: true,
    }, {
      prop: 'groupName', label: 'Group Name', filterable: true,
    }, {
      prop: 'transactionStatus', label: 'Trade Status', filterable: true,
    }, {
      prop: 'extSourceSystem', label: 'Ext Source', filterable: true,
    }, {
      prop: 'extTransactionId', label: 'Ext Tran#', filterable: true,
    }, {
      prop: 'confirmedFlag', label: 'Confirmed?', filterable: true,
    }, {
      prop: 'invoicedFlag', label: 'Invoiced?', filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true,
    }, {
      prop: 'createdDate',
      label: 'Created Dt',
      filterable: true,
      dataType: 'date',
      format: 'yyyy-MM-dd HH:mm',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true,
    }, {
      prop: 'updatedDate',
      label: 'Updated Dt',
      filterable: true,
      dataType: 'date',
      format: 'yyyy-MM-dd HH:mm',
    }],
    options: {
      filterRow: true,
      filterPanel: true,
      columnsMovable: true,
      filterHeader: true,
    },
  },
  deals,
  deals2: clone(deals),
  unmatchedConfig: {
    columns: [{
      prop: 'id', label: 'DEAL ID', filterable: true,
    }, {
      prop: 'tagCode',
      label: 'TAG CODE',
    },
    ...HEColumns({ minWidth: 45 }),
    ],
    options: {
      filterRow: true,
      filterPanel: true,
    },
  },
  bookoutConfig: {
    columns: [{
      prop: 'id', label: 'LEFT DEAL ID', filterable: true,
    }, {
      prop: 'dealId',
      label: 'RIGHT DEAL ID',
    },
    ...HEColumns({ minWidth: 45 }),
    ],
    options: {
      filterRow: true,
      filterPanel: true,
    },
  },
  bookOutSelectedConfig: {
    columns: [{
      prop: 'he', label: 'HE',
    }, {
      prop: 'profile', label: 'LEFT DEAL',
    }, {
      prop: 'profile2', label: 'RIGHT DEAL',
    }, {
      prop: 'mwh', label: 'MWH',
    }],
  },
  unmatchedSelectedConfig: {
    columns: [{
      prop: 'he', label: 'HE',
    }, {
      prop: 'profile', label: 'DEAL',
    }, {
      prop: 'profile2', label: 'TAG',
    }, {
      prop: 'mwh', label: 'MWH',
    }],
  },
};

const getters = {
};

const actions = {
};

const mutations = {
  removeTags(state, removeTags) {
    removeTags.forEach((tag) => {
      const tagIdx = state.tagSummaries.findIndex(({ tagID }) => tagID === tag.tagID);
      state.tagSummaries.splice(tagIdx, 1);
    });
  },
  removeDeals(state, removeDeal) {
    if (removeDeal.length) {
      removeDeal.forEach((deal) => {
        const dealIdx = state.deals2.findIndex(({ id }) => id === deal.id);
        state.deals2.splice(dealIdx, 1);
      });
    } else {
      const dealIdx = state.deals.findIndex(({ id }) => id === removeDeal.id);
      state.deals.splice(dealIdx, 1);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};