import loadedStatements from './LoadedStatements/LoadedStatementsStore';
import monthlyDailyDetails from './MonthlyDailyDetails/monthlyDailyDetailsStore';
import invoices from './Invoices/invoicesStore';
import invoicesSummary from './InvoicesSummary/InvoicesSummaryStore';
import settlementCycleSymmary from './SettlementCycleSummary/settlementCycleStore';
import meterComponent from './MeterComponent/meterComponentStore';
import meterStmtCompare from './MeterStmtCompare/MeterStmtCompareStore';
import EIMSubAllocation from './EIMSubAllocation/EIMSubAllocationStore';
import statementDetails from './StatementDetails/statementDetailsStore';
import chargeCodeDetails from './ChargeCodeDetails/store';
import determinantSummary from './DeterminantSummary';
import determinantDetails from './DeterminantDetails';
import determinantDetailsFullDrill from './DeterminantDetailsFullDrill';
import configurationFile from './ConfigurationFile/ConfigurationFileStore';
import viewChargeDetails from './ViewChargeDetails/viewChargeDetailsStore';
import EIMAllocationChargeCodeDetails from './EIMAllocationChargeCodeDetails/EIMAllocationChargeCodeDetailsStore';
import EIMAllocationDeterminantDetails from './EIMAllocationDeterminantDetails/EIMAllocationDeterminantDetailsStore';
import EIMAllocationValidationDetails from './EIMAllocationValidationDetails/EIMAllocationValidationDetailsStore';
import disputes from './Disputes/disputesStore';
import disputesForm from './Disputes/Forms/disputesFormStore';
import comparePrices from './ComparePrices/comparePricesStore';
import eimSubAllocationOverrides from './EimSubAllocationTemplateOverrides/eimSubAllocationTemplateStore';
import whatIfBuilder from './WhatIfBuilder/whatIfBuilderStore';
import tagSettlements from './TagSettlements/TagSettlementsStore';
import settlementReview from './SettlementReview/settlementReviewStore';
import settlementReviewCalendar from './SettlementReviewCalendar/settlementReviewCalendarStore';
import etsrStatements from './EtsrStatements/etsrStatementsStore';
import determinantTree from './DeterminantTree/determinantTreeStore';
import chargeCodeCategories from './ChargeCodeCategories';
import operationalAdjustments from './OperationalAdjustments/operationalAdjustmentsStore';
import tagSettlementsVsCMRI from './TagSettlementsVsCMRI/TagSettlementsVsCMRIStore';
import dynamicEtsr from './DynamicEtsr/dynamicEtsrStore';
import chargeCodeByBAA from './ChargeCodeByBAA/chargeCodeByBAAStore';
import stateAllocRef from './StateAllocReference/StateAllocationReferenceStore';
import tagSettlementsMRTU from './TagSettlementsMRTU/TagSettlementsMRTUStore';
import tagSettlementsMRTUVsCMRI from './TagSettlementsMRTUVsCMRI/TagSettlementsMRTUVsCMRIStore';
import tagSettlementsLog from './TagSettlementsLog/TagSettlementsLogStore';

export default {
  namespaced: true,
  modules: {
    loadedStatements,
    monthlyDailyDetails,
    invoices,
    invoicesSummary,
    settlementCycleSymmary,
    meterComponent,
    meterStmtCompare,
    EIMSubAllocation,
    statementDetails,
    chargeCodeCategories,
    chargeCodeDetails,
    determinantSummary,
    determinantDetails,
    determinantDetailsFullDrill,
    determinantTree,
    configurationFile,
    viewChargeDetails,
    EIMAllocationChargeCodeDetails,
    EIMAllocationDeterminantDetails,
    EIMAllocationValidationDetails,
    disputes,
    disputesForm,
    comparePrices,
    eimSubAllocationOverrides,
    whatIfBuilder,
    tagSettlements,
    settlementReview,
    settlementReviewCalendar,
    etsrStatements,
    operationalAdjustments,
    tagSettlementsVsCMRI,
    dynamicEtsr,
    chargeCodeByBAA,
    stateAllocRef,
    tagSettlementsMRTU,
    tagSettlementsMRTUVsCMRI,
    tagSettlementsLog,
  },
};