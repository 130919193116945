import {
  tradeDateRange,
  coordinatorList,
  resourcesTagbox,
  marketTypes,
  filteredExecutionTypes,
  gridConfig,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { has } from '@/utils/dataUtil';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [
    {
      caption: 'SC',
      dataField: 'sc',
      width: 60,
      area: 'row',
      expanded: true,
    },
    {
      caption: 'TRADING DATE',
      dataField: 'tradingDate',
      area: 'row',
      expanded: true,
      dataType: 'date',
      format: 'yyyy-MM-dd',
      width: 120,
    },
    {
      caption: 'RESOURCE TYPE',
      dataField: 'resourceType',
      width: 120,
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'RESOURCE ID',
      dataField: 'resource',
      width: 120,
      area: 'row',
      expanded: true,
    },
    {
      caption: 'MARKET TYPE',
      dataField: 'marketType',
      width: 120,
      area: 'row',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'EXECUTION TYPE',
      dataField: 'executionType',
      width: 100,
      area: 'row',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'PRODUCT',
      dataField: 'product',
      width: 120,
      area: 'row',
      expanded: false,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'LOCATION',
      dataField: 'location',
      area: 'filter',
      visible: true,
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'DIRECTION',
      dataField: 'direction',
      area: 'filter',
      visible: true,
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'CONFIGURATION',
      dataField: 'configuration',
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'BINDING FLAG',
      dataField: 'bindingFlag',
      area: 'filter',
      visible: true,
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'ENERGY TYPE',
      dataField: 'energyType',
      width: 120,
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'SCHEDULE TYPE',
      dataField: 'scheduleType',
      area: 'filter',
      width: 120,
      visible: true,
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'TRADING HOUR',
      dataField: 'he',
      area: 'row',
      expanded: false,
      width: 10,
    },
    {
      caption: 'TRADING INTERVAL',
      dataField: 'ie',
      area: 'column',
      expanded: true,
    },
    {
      caption: 'MW',
      dataField: 'mw',
      area: 'data',
      dataType: 'number',
      summaryType: 'sum',
      format: gridConfig.valFormat,
      expanded: true,
    },
  ],
  summaryFields: [
    {
      caption: 'SC',
      dataField: 'sc',
      width: 60,
      area: 'row',
      expanded: true,
    },
    {
      caption: 'TRADING DATE',
      dataField: 'date',
      area: 'row',
      expanded: true,
      dataType: 'date',
      format: 'yyyy-MM-dd',
      width: 120,
    },
    {
      caption: 'RESOURCE ID',
      dataField: 'rsrcId',
      width: 120,
      area: 'row',
      expanded: true,
    },
    {
      caption: 'RESOURCE TYPE',
      dataField: 'resourceType',
      width: 120,
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'MARKET TYPE',
      dataField: 'mktType',
      width: 120,
      area: 'row',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'EXECUTION TYPE',
      dataField: 'execType',
      width: 100,
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'PRODUCT',
      dataField: 'prod',
      width: 120,
      area: 'row',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'SCHEDULE TYPE',
      dataField: 'schedType',
      width: 120,
      area: 'filter',
      visible: true,
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'CONFIGURATION',
      dataField: 'config',
      area: 'filter',
      expanded: true,
      customizeText(cellInfo) {
        if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
        if (cellInfo.value === null) return '';
        return cellInfo.value;
      },
    },
    {
      caption: 'TRADING HOUR',
      dataField: 'he',
      area: 'column',
      expanded: true,
      width: 10,
    },
    {
      caption: 'MW',
      dataField: 'mw',
      area: 'data',
      dataType: 'number',
      summaryType: 'sum',
      format: gridConfig.valFormat,
      expanded: true,
    },
  ],
  searchFiltersConfig: {
    detailType: {
      label: '',
      value: 'summaryView',
      type: 'radio',
      options: [{ value: 'summaryView', label: 'Summary View' }, { value: 'detailView', label: 'Detail View' }],
      watchers: [
        {
          propertyToWatch: 'detailTypeSelected',
          handler(newValue, oldValue) {
            const required = !(this.params.detailTypeSelected === 'summaryView' || this.params.resourceListSelected.length === 1);
            this.config.resourceList.required = !(newValue === 'summaryView');
            this.config.resourceList.maxLimit = (newValue === 'summaryView') ? 50 : 6;
            this.config.coordinatorList.required = !(this.params.detailTypeSelected === 'summaryView');
            this.config.marketTypeList.required = false;
            this.config.executionTypeList.required = false;
            this.config.products.required = false;
            this.config.scheduleTypes.required = false;
            this.config.isAverageHourlyValue.enabled = !(newValue === 'summaryView');
            if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
              this.$nextTick(() => this.$parent.instance.repaint());
            }
          },
        }, {
          propertyToWatch: 'resourceListSelected',
          handler(newValue, oldValue) {
            const required = !(this.params.detailTypeSelected === 'summaryView' || this.params.resourceListSelected.length === 1);
            this.config.marketTypeList.required = required;
            this.config.executionTypeList.required = required;
            this.config.products.required = required;
            this.config.scheduleTypes.required = required;
          },
        }],
    },
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: false },
    resourceList: { ...resourcesTagbox, maxLimit: 6, required: true },
    marketTypeList: {
      ...marketTypes,
      required: false,
      watchers: [{
        propertyToWatch: 'detailTypeSelected',
        handler(newValue, oldValue) {
          if (newValue === 'summaryView') {
            this.$store.commit('ciso/setParams', ['marketTypeListSelected', '']);
            this.$store.commit('ciso/setParams', ['executionTypeListSelected', '']);
            this.$store.commit('ciso/setParams', ['productsSelected', '']);
          }
        },
      }],
    },
    executionTypeList: { ...filteredExecutionTypes, clearable: true, required: false },
    products: {
      label: 'Product',
      value: null,
      type: 'select',
      clearable: true,
      required: false,
      options: [
        { value: 'EN', label: 'Energy' },
        { value: 'SR', label: 'Spin' },
        { value: 'NR', label: 'Non-Spin' },
        { value: 'RD', label: 'Reg Down' },
        { value: 'RU', label: 'Reg Up' },
        { value: 'RC AWD', label: 'RUC Award' },
        { value: 'RC CAP', label: 'RUC Capacity' },
        { value: 'RMD', label: 'Reg Mileage Down' },
        { value: 'RMU', label: 'Reg Mileage Up' },
        { value: 'FRD', label: 'Flex Ramp Down' },
        { value: 'FRU', label: 'Flex Ramp Up' },
        { value: 'IEEA', label: 'IEEA' },
      ],
    },
    scheduleTypes: {
      label: 'Schedule Type',
      value: null,
      type: 'select',
      clearable: true,
      required: false,
      options: [
        { value: 'CLEARED', label: 'Cleared' },
        { value: 'MARKET', label: 'Market' },
        { value: 'SELF', label: 'Self' },
      ],
    },
    isAverageHourlyValue: {
      label: '',
      value: 'MWH',
      type: 'radio',
      options: [{ value: 'MWH', label: 'MWH' }, { value: 'MW', label: 'MW' }],
      watchers: [
        {
          propertyToWatch: 'detailTypeSelected',
          handler(newValue, oldValue) {
            this.$store.commit('ciso/setParams', ['isAverageHourlyValueSelected', 'MWH']);
            this.config.isAverageHourlyValue.visible = !(newValue === 'summaryView');
          },
        }],
    },
  },
};