
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import { VA_API } from '@/api';
import VABaseStore from '../VABaseStore';

const state = {
  queue: {},
  rules: [],
};

const actions = {
  async init({ dispatch }) {
    await dispatch('fetchBusinessRules');
    await dispatch('fetchLayouts');
  },
  async fetchBusinessRules({ commit }) {
    try {
      const { data: { data } } = await VA_API.get('business-rules');
      commit('setRules', data);
    } catch (error) {
      console.error(error);
    }
  },
  async saveFromQueue() {
    try {
      Object.keys(state.queue).forEach((rule) => {
        const params = {
          id: rule,
          layoutId: state.queue[rule].toString(),
        };
        VA_API.put('business-rules/associate-layout', params);
      });
      this.$notify('Successfully Saved Rule Configurations', 'success');
    } catch (error) {
      this.$notify('Error Saving Rule Configurations', 'error');
      console.error('Error', error);
    }
  },
};

const mutations = {
  ...createMutations(
    'queue',
    'rules',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  actions,
  mutations,
};