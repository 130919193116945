import { accessRight, legacy_ppa } from '@/auth/permission';

export default {
  path: '/ppa',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'PPA',
    permission: { name: legacy_ppa.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: '/ppa/reference-data',
    meta: {
      visible: true,
      rank: 8,
      requiresAuth: true,
      text: 'Reference Data',
      permission: { name: legacy_ppa.referenceData.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'charge-codes',
      name: 'PPAChargeCodes',
      meta: {
        visible: true,
        rank: 4,
        requiresAuth: true,
        text: 'Charge Codes',
        permission: { name: legacy_ppa.referenceData.chargeCodes, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/ChargeCodes/ChargeCodes'),
    }, {
      path: 'customer-masterfile-report',
      name: 'CustomerMasterfile',
      meta: {
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Customer Masterfile Report',
        permission: { name: legacy_ppa.referenceData.customerMasterfile, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/CustomerMasterfile/CustomerMasterfile'),
    }, {
      path: 'meter',
      name: 'PPAMeter',
      meta: {
        visible: true,
        rank: 7,
        requiresAuth: true,
        text: 'Meter',
        permission: { name: legacy_ppa.referenceData.meter, rights: ['read', 'write'] },
      },
      component: () => import('@/components/PPA/ReferenceData/Meter/Meter'),
    }, {
      path: 'reservation',
      name: 'PPAReservation',
      meta: {
        visible: false,
        rank: 9,
        requiresAuth: true,
        text: 'Reservation',
        permission: { name: legacy_ppa.referenceData.reservation, rights: ['read', 'write'] },
      },
      component: () => import('@/components/PPA/ReferenceData/Reservation/Reservation'),
    }, {
      path: 'schedule',
      name: 'PPASchedule',
      meta: {
        visible: true,
        rank: 8,
        requiresAuth: true,
        text: 'Schedule',
        permission: { name: legacy_ppa.referenceData.schedule, rights: ['read', 'write'] },
      },
      component: () => import('@/components/PPA/ReferenceData/Schedule/Schedule'),
    }, {
      path: 'ppa-charges',
      name: 'PPACharges',
      meta: {
        visible: true,
        rank: 5,
        requiresAuth: true,
        text: 'PPA Charges',
        permission: { name: legacy_ppa.referenceData.ppaCharges, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/PPAChargeRef/PPAChargeRef'),
    }, {
      path: 'banks',
      name: 'PPABanks',
      meta: {
        visible: true,
        rank: 11,
        requiresAuth: true,
        text: 'Banks',
        permission: { name: legacy_ppa.referenceData.banks, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/Banks/Banks'),
    }, {
      path: 'points-of-contact',
      name: 'PPAPointsOfContact',
      meta: {
        visible: true,
        rank: 12,
        requiresAuth: true,
        text: 'Points of Contact',
        permission: { name: legacy_ppa.referenceData.pointOfContact, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/PointsOfContact/PointsOfContact'),
    }, {
      path: 'invoice-entities',
      name: 'PPAInvoiceEntities',
      meta: {
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'Invoice Entities',
        permission: { name: legacy_ppa.referenceData.invoiceEntities, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/InvoiceEntities/InvoiceEntities'),
    }, {
      path: 'ppas',
      name: 'PPAs',
      meta: {
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'PPA',
        permission: { name: legacy_ppa.referenceData.ppas, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/PPAs/PPAs'),
    }],
  }, {
    path: '/ppa/source-data',
    meta: {
      visible: true,
      rank: 3,
      requiresAuth: true,
      text: 'Source Data',
      permission: { name: legacy_ppa.sourceData.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'ppa-meter',
      name: 'ppaMeter',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Meter',
        permission: { name: legacy_ppa.sourceData.ppaMeter, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaMeter/ppaMeter'),
    }, {
      path: 'ppa-prices',
      name: 'ppaPrices',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Prices',
        permission: { name: legacy_ppa.sourceData.ppaPrices, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaPrices/ppaPrices'),
    }, {
      path: 'ppa-schedules',
      name: 'ppaSchedules',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Schedules',
        permission: { name: legacy_ppa.sourceData.ppaSchedules, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaSchedules/ppaSchedules'),
    }, {
      path: 'ppa-reservations',
      name: 'ppaReservations',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Reservations',
        permission: { name: legacy_ppa.sourceData.ppaReservations, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaReservations/ppaReservations'),
    }, {
      path: 'ppa-curtailments',
      name: 'ppaCurtailments',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Curtailments',
        permission: { name: legacy_ppa.sourceData.ppaReservations, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaCurtailments/ppaCurtailments'),
    }, {
      path: 'ppa-losses',
      name: 'ppaLosses',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Losses',
        permission: { name: legacy_ppa.sourceData.ppaReservations, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/SourceData/ppaLosses/ppaLosses'),
    }, {
      path: 'ppa-source',
      name: 'ppaDeterminantData',
      meta: {
        visible: true,
        requiresAuth: true,
        permission: { name: legacy_ppa.sourceData.ppaDeterminantData, rights: [accessRight.visible] },
        text: 'Source BD Data',
      },
      component: () => import('@/components/PPA/SourceData/ppaDeterminantData/ppaDeterminantData'),
    }],
  }, {
    path: '/ppa/invoicing',
    meta: {
      visible: true,
      rank: 2,
      requiresAuth: true,
      text: 'Invoicing',
      permission: { name: legacy_ppa.invoicing.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'create-invoice',
      name: 'PpaCreateInvoice',
      meta: {
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Create',
        permission: { name: legacy_ppa.invoicing.createInvoice, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Invoicing/CreateInvoice/CreateInvoice'),
    }, {
      path: 'approve-invoice',
      name: 'PpaApproveInvoice',
      meta: {
        visible: true,
        rank: 2,
        requiresAuth: true,
        text: 'View/Approve',
        permission: { name: legacy_ppa.invoicing.approveInvoice, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Invoicing/ApproveInvoice/ApproveInvoice'),
    }, {
      path: 'subledger',
      name: 'PpaSubledger',
      meta: {
        visible: true,
        rank: 3,
        requiresAuth: true,
        text: 'Subledger',
        permission: { name: legacy_ppa.invoicing.subledger, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Invoicing/Subledger/Subledger'),
    }],
  }, {
    path: '/ppa/settlements',
    meta: {
      visible: true,
      rank: 1,
      requiresAuth: false,
      text: 'Settlements',
      permission: { name: legacy_ppa.settlements.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'summary',
      name: 'PPASettlementsSummary',
      meta: {
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Summary',
        permission: { name: legacy_ppa.settlements.statement, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Settlements/SettlementsSummary/SettlementsSummary'),
    }, {
      path: 'charge-details',
      name: 'PPAChargeDetails',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'PPA Charge Details',
        permission: { name: legacy_ppa.settlements.determinantDetail, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Settlements/SettlementsChargeDetails/SettlementsChargeDetails'),
    }, {
      path: 'determinant-details',
      name: 'PPADeterminantDetails',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'PPA Determinant Details',
        permission: { name: legacy_ppa.settlements.determinantDetail, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Settlements/DeterminantDetails/DeterminantDetails'),
    }, {
      path: 'bd-overrides',
      name: 'PPABDOverrides',
      meta: {
        visible: true,
        rank: 4,
        requiresAuth: true,
        text: 'BD Overrides',
        permission: { name: legacy_ppa.settlements.override, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Settlements/BDOverrides/BDOverrides'),
    }, {
      path: 'settlement-reference',
      name: 'PPASettlementReference',
      meta: {
        visible: true,
        rank: 5,
        requiresAuth: true,
        text: 'Settlement Reference',
        permission: { name: legacy_ppa.referenceData.settlementReference, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/ReferenceData/SettlementRef/SettlementRef'),
    }, {
      path: 'invoice-details',
      name: 'invoiceDetails',
      meta: {
        visible: true,
        requiresAuth: true,
        rank: 6,
        text: 'Invoice Details',
        permission: { name: legacy_ppa.settlements.invoiceDetails, rights: [accessRight.visible] },
      },
      component: () => import('@/components/PPA/Settlements/invoiceDetails/invoiceDetails'),
    }],
  }, {
    path: '/ppa/calculation',
    meta: {
      visible: true,
      rank: 4,
      requiresAuth: true,
      text: 'Calculation',
      permission: { name: legacy_ppa.calculation, rights: [accessRight.visible] },
    },
    name: 'PPACalculation',
    component: () => import('@/components/PPA/Calculation/Calculation/'),
  }, {
    path: '/ppa/reporting',
    meta: {
      visible: true,
      rank: 5,
      requiresAuth: true,
      text: 'PPA Reports',
      permission: { name: legacy_ppa.reporting, rights: [accessRight.visible] },
    },
    name: 'PPAReports',
    component: () => import('@/components/PPA/Reporting/PPAReports/'),
  }, {
    path: '/ppa/imports',
    meta: {
      visible: true,
      rank: 6,
      requiresAuth: true,
      text: 'Data Import',
      permission: { name: legacy_ppa.import, rights: [accessRight.visible] },
    },
    name: 'PPAImport',
    component: () => import('@/components/PPA/ImportData/ImportData/'),
  }, {
    path: '/ppa/guide',
    meta: {
      visible: true,
      rank: 7,
      requiresAuth: true,
      text: 'Guide',
      permission: { name: legacy_ppa.reporting, rights: [accessRight.visible] },
    },
    name: 'PPAGuide',
    component: () => import('@/components/PPA/Guide/Guide/'),
  }],
};
