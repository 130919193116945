export default ({
  name: 'Default Custom',
  path: 'default-custom',
  columns: [
    {
      prop: 'status',
      label: 'Status',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        required: false,
        visible: true,
      },
    },
    {
      prop: 'parentId',
      label: 'Parent',
      config: {
        visible: false,
        editable: false,
        filterable: true,
        alignment: 'right',
      }
    },
    {
      prop: 'tradeId',
      label: 'ID',
      config: {
        visible: true,
        editable: false,
        sortable: true,
        sortOrder: 'desc',
        filterable: true,
        alignment: 'right',
      }
    },
    {
      prop: 'hasAttachments',
      label: 'Attachments',
      config: {
        visible: false,
        editable: false,
        filterable: true,
        alignment: 'center',
        dataType: 'boolean',
      }
    },
    {
      prop: 'commodity',
      label: 'Commodity',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'EnergyCommodityList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        required: true,
        visible: true,
        sortBy: 'shortName',
        sort: null,
      },
    },
    {
      prop: 'company',
      label: 'Company',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CompanyList',
          value: 'id',
          label: 'shortName',
        },
        type: 'select',
        required: true,
        visible: true,
        filters: [
          {
            op: 'contains',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodities',
            column: 'commodity',
            columnField: 'shortName',
          },
          {
            op: '=',
            value: 'true',
            referenceField: 'internalFlag',
          },
        ],
        sortBy: 'shortName',
        sort: 'asc',
      },
    },
    {
      prop: 'counterparty',
      label: 'Counterparty',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CompanyList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        required: true,
        visible: true,
        filters: [
          {
            op: 'contains',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodities',
            column: 'commodity',
            columnField: 'shortName',
          },
        ],
        sortBy: 'shortName',
        sort: 'asc',
      },
    },
    {
      prop: 'counterparty2',
      label: 'Counterparty2',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CompanyList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        required: false,
        visible: true,
        filters: [
          {
            op: 'contains',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodities',
            column: 'commodity',
            columnField: 'shortName',
          },
        ],
        sortBy: 'shortName',
        sort: 'asc',
      },
    },
    {
      prop: 'effectiveStartDate',
      label: 'Start Date',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'date',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'effectiveEndDate',
      label: 'End Date',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        required: true,
        visible: true,
        type: 'date',
      },
    },
    {
      prop: 'market',
      label: 'Market',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'MarketList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        required: false,
        visible: true,
        sortBy: 'shortName',
        sort: 'asc',
      },
    },
    {
      prop: 'isoReferenceName',
      label: 'ISO Ref',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'IsoReferenceList',
          label: 'refName',
          value: 'refName',
        },
        type: 'select',
        required: false,
        visible: true,
        sortBy: 'refName',
        sort: 'asc',
      },
    },
    {
      prop: 'termTypeId',
      label: 'Term',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'TermTypeList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'period',
      label: 'Period',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'sysDef',
        },
        type: 'select',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'termInterval',
      label: 'Int',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'termQuantity',
      label: 'Qty',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        required: false,
        visible: true,
        type: 'number',
      },
    },
    {
      prop: 'termPrice',
      label: 'Price',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'number',
        required: false,
        visible: true,
      },
    },
    {
      prop: 'multiplier',
      label: 'Multiplier',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'number',
        required: false,
        visible: false,
      },
    },
    {
      prop: 'adder',
      label: 'Adder',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'number',
        required: false,
        visible: false,
      },
    },
    {
      prop: 'createdBy',
      label: 'Created By',
      config: {
        visible: true,
      }
    },
    {
      prop: 'createdDate',
      label: 'Created Date',
      config: {
        visible: true,
      }
    },
    {
      prop: 'termPriceIndex',
      label: 'Price Index',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'PriceList',
          label: 'name',
          value: 'id',
        },
        type: 'select',
        sortBy: 'name',
        sort: 'asc',
        required: false,
        visible: true,
      },
    },
    {
      prop: 'pointOfDeliveryId',
      label: 'POD',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'LocationList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'TRADE',
            referenceField: 'marketName',
          },
          {
            op: '=',
            value: 'POD',
            referenceField: 'locationType',
          },
          {
            op: '=',
            value: 'COMPANY',
            referenceField: 'entityType',
          },
          {
            op: 'contains',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodityName',
            column: 'commodity',
            columnField: 'shortName',
          },
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'entityName',
            column: 'company',
            columnField: 'shortName',
          },
        ],
        required: false,
        visible: true,
      },
    },
    {
      prop: 'pointOfReceiptId',
      label: 'POR',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'LocationList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'TRADE',
            referenceField: 'marketName',
          },
          {
            op: '=',
            value: 'POR',
            referenceField: 'locationType',
          },
          {
            op: '=',
            value: 'COMPANY',
            referenceField: 'entityType',
          },
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodityName',
            column: 'commodity',
            columnField: 'shortName',
          },
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'entityName',
            column: 'company',
            columnField: 'shortName',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'pipeline',
      label: 'Pipeline',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'LocationList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'TRADE',
            referenceField: 'marketName',
          },
          {
            op: '=',
            value: 'PIPELINE',
            referenceField: 'locationType',
          },
          {
            op: '=',
            value: 'COMPANY',
            referenceField: 'entityType',
          },
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodityName',
            column: 'commodity',
            columnField: 'shortName',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'meter',
      label: 'Meter',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'LocationList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'TRADE',
            referenceField: 'marketName',
          },
          {
            op: '=',
            value: 'METER',
            referenceField: 'locationType',
          },
          {
            op: '=',
            value: 'COMPANY',
            referenceField: 'entityType',
          },
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'commodityName',
            column: 'commodity',
            columnField: 'shortName',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'tradeDate',
      label: 'Trade Date',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'date',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'companyContactId',
      label: 'Contact',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'ContactList',
          label: 'fullName',
          value: 'id',
        },
        required: false,
        type: 'select',
        sortBy: 'fullName',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'company',
            column: 'company',
            columnField: 'shortName',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'counterpartyContactId',
      label: 'C/P Contact',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'ContactList',
          label: 'fullName',
          value: 'id',
        },
        sortBy: 'fullName',
        type: 'select',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: '',
            isColumnFilter: true,
            byValueField: false,
            referenceField: 'company',
            column: 'counterparty',
            columnField: 'shortName',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'environmentalSubAccount',
      label: 'Env. SubAccount',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'EnvironmentalSubAccountList',
          label: 'shortName',
          value: 'id',
        },
        type: 'select',
        sortBy: 'shortName',
        sort: 'asc',
        filters: [],
        visible: true,
      },
    },
    {
      prop: 'termProduct',
      label: 'Term Product',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CatalogList',
          label: 'name',
          value: 'id',
        },
        type: 'select',
        sortBy: 'name',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'termProduct',
            referenceField: 'type',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'book',
      label: 'Book',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CatalogList',
          label: 'id',
          value: 'name',
        },
        sortBy: 'name',
        type: 'select',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'book',
            referenceField: 'type',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'portfolio',
      label: 'Portfolio',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CatalogList',
          label: 'name',
          value: 'id',
        },
        sortBy: 'name',
        type: 'select',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'portfolio',
            referenceField: 'type',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'firmType',
      label: 'Firm Type',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CatalogList',
          label: 'name',
          value: 'id',
        },
        sortBy: 'name',
        type: 'select',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'firmType',
            referenceField: 'type',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'unit',
      label: 'Unit',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'CatalogList',
          label: 'id',
          value: 'name',
        },
        sortBy: 'name',
        type: 'select',
        sort: 'asc',
        filters: [
          {
            op: '=',
            value: 'uom',
            referenceField: 'type',
          },
        ],
        visible: true,
      },
    },
    {
      prop: 'custom01',
      label: 'Custom01',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        visible: false,
      },
    },
    {
      prop: 'custom02',
      label: 'Custom02',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        visible: false,
      },
    },
    {
      prop: 'custom03',
      label: 'Custom03',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        visible: false,
      },
    },
    {
      prop: 'custom04',
      label: 'Custom04',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        visible: false,
      },
    },
    {
      prop: 'notes',
      label: 'Notes',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {},
        type: 'string',
        visible: true,
      },
    },
    {
      prop: 'direction',
      label: 'B/S',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'sysDef',
        },
        type: 'select',
        required: true,
        visible: true,
      },
    },
    {
      prop: 'trader',
      label: 'Trader',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'sysDef',
        },
        type: 'select',
        visible: true,
      },
    },
    {
      prop: 'timeZone',
      label: 'TZ',
      config: {
        format: {
          currency: 'USD',
          type: 'default',
        },
        reference: {
          list: 'sysDef',
        },
        type: 'select',
        visible: true,
      },
    },
  ],
  id: 0,
});