import chartLayouts from './Settings/ChartLayouts/layoutStore';
import dailyAnalytics from './DailyAnalytics/dailyAnalyticsStore';
import heatmap from './HeatMap/heatMapStore';
import outages from './Outages/outagesStore';
import portfolio from './Portfolio/portfolioStore';
import realtimeGraphs from './RealtimeGraphs/realtimeGraphsStore';
import runEngine from './RunEngine/runEngineStore';
import multiLocationAnalysis from './MultiResourceAnalysis/multiLocationAnalysisStore';
import rulesConfig from './RulesConfig/rulesConfigStore';
import genCostSetup from './Settings/GenCostSetup/GenCostSetupStore';
import inventory from './Inventory/inventoryStore';

export default {
  namespaced: true,
  modules: {
    chartLayouts,
    dailyAnalytics,
    inventory,
    heatmap,
    outages,
    portfolio,
    realtimeGraphs,
    runEngine,
    multiLocationAnalysis,
    rulesConfig,
    genCostSetup,
  },
};