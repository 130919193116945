import moment from 'moment';
import { CISO_METER_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import configs from './mrisRawMeterConfig';

const state = {
  rawTableData: [],
  mapTableData: [],
  mrisRawMeterConfig: configs,
  rawMeterPivot: configs.fields,
};

const actions = {
  async getRawMeterData({ commit }, parameters = {}) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      resources: parameters.meterSubmitUnitListSelected ? parameters.meterSubmitUnitListSelected.join(',') : null,
      startDate,
      endDate,
      dateType: null,
    };
    try {
      const { data: { data } } = await CISO_METER_API.get('/mris-submission/raw-meter', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.tradingDate = getMomentDateString(x.tradingDate);
          x.endTimeUtc = moment(x.endTimeUtc).utc().format();
          x.endTimeLocal = moment(x.endTimeUtc).format();
        });
        commit('setTableData', data);
      } else if (data === null) {
        commit('setTableData', []);
      }
      const map = await CISO_METER_API.get('/mris-submission/mapping', { params });
      commit('setMapData', map.data.data);
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.message) {
        console.log(err.message);
      }
      this.$notify('Failed to Load meter data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, data) {
    state.rawTableData = Object.freeze(data);
  },
  setMapData(state, value) {
    state.mapTableData = value;
  },
  setTableConfig(state, value) {
    state.rawMeterPivot = value;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};