export default {
  configData: {
    fields: [
      {
        caption: 'BD_ORDER',
        dataField: 'orderNum',
        dataType: 'number',
        area: 'row',
        areaIndex: 0,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_NAME',
        dataField: 'bdName',
        dataType: 'string',
        area: 'row',
        areaIndex: 1,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BPM_NAME',
        dataField: 'bpmName',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'COMPARE',
        dataField: 'compareId',
        dataType: 'string',
        area: 'row',
        expanded: true,
        areaIndex: '999',
      },
      {
        caption: 'COMPARE_TYPE',
        dataField: 'compareType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
        areaIndex: '999',
      },
      {
        caption: 'TRADING_DATE',
        dataField: 'tzTradingDate',
        dataType: 'datetime',
        area: 'filter',
        visible: true,
        expanded: true,
      },
      {
        caption: 'RSRC_ID',
        dataField: 'rsrcId',
        area: 'column',
      },
      {
        caption: 'VALUE',
        dataField: 'value',
        dataType: 'number',
        area: 'data',
        expanded: true,
        summaryType: 'sum',
        format: '#,###.##',
      },
    ],
  },
};