import { CISO_CMRI_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  async fetchVERForecast({ commit, dispatch }, args) {
    dispatch('reset');
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.tradeDateRangeSelected);
    const params = {
      scs: args.coordinatorListSelected,
      rsrc_id: args.resourceListSelected.join(','),
      startDate,
      endDate,
    };
    try {
      const { data } = await CISO_CMRI_API.get('/ver-forecast', { params });
      data.forEach((obj) => { obj.tradingDate = getMomentDateString(obj.tradingDate); });
      commit('setTableData', data);
    } catch (error) {
      this.$notify('Failed to Load VER Forecast', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};