/* eslint-disable import/prefer-default-export */
export function hasPermission(permissions, name, right) {
  const localPermissions = permissions || [];
  const foundPermission = localPermissions.find((perm) => {
    let namesMatch = false;
    if (name instanceof RegExp) {
      namesMatch = name.test(perm.name.toLowerCase());
    } else {
      namesMatch = perm.name.toLowerCase() === name.toLowerCase();
    }

    if (namesMatch) {
      const value = perm.value.toLowerCase();
      if (Array.isArray(right)) {
        return right.map((val) => val.toLowerCase()).includes(value);
      }
      return value === right.toLowerCase();
    }
    return false;
  });
  return !!foundPermission;
}

export function anyPermission(permissions, name) {
  const regexLookup = new RegExp(name);
  const found = permissions.filter((p) => regexLookup.test(p.name.toLowerCase()));
  return found && found.length > 0;
}

export function isFeatureFlagEnabled(featureFlags, name) {  
  const flag = featureFlags.find((f) => f.name === name);
  return (flag && flag.enabledFlag === true);
}

export function getFeatureFlag(featureFlags, name) {
  return featureFlags.find((f) => f.name === name);
}