function formatUSCurrency(value) {
  if (value === null) { return null; }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
}

export default {
  summaryTableConfiguration: {
    name: 'Summary',
    columns: [{
      prop: 'ba', label: 'BA', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'invoiceNum', label: 'INVOICE NUM', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'description', label: 'DESCRIPTION', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'invoiceType', label: 'INVOICE TYPE', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'invoiceLineType', label: 'INVOICE LINE TYPE', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'version', label: 'VERSION', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'publishedDate', label: 'PUBLISHED DATE', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'invoiceAmount',
      label: 'INVOICE AMT',
      filterable: true,
      sortable: true,
      width: 100,
      format: formatUSCurrency,
    }, {
      prop: 'calcAmount',
      label: 'CALC AMT',
      filterable: true,
      sortable: true,
      width: 100,
      format: formatUSCurrency,
    }, {
      prop: 'variance',
      label: 'VARIANCE AMT',
      filterable: true,
      sortable: true,
      width: 100,
      format: formatUSCurrency,
    }, {
      prop: 'status', label: 'STATUS', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'name', label: 'NAME', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'dueDate', label: 'DUE DATE', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'comments', label: 'COMMENTS', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'participantComments', label: 'PARTICIPANT COMMENTS', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'lastModified', label: 'LAST MODIFIED', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'referenceNumber', label: 'REFFERENCE', filterable: true, sortable: true, width: 100,
    }, {
      prop: 'invoiceName', label: 'INVOICE NAME', filterable: true, sortable: true, width: 100,
    }],
    summary: [{
      prop: 'calcAmount', alignment: 'center', summaryType: 'sum', label: '{0}', format: { type: 'currency', precision: 2 },
    }, {
      prop: 'variance', alignment: 'center', summaryType: 'sum', label: '{0}', format: { type: 'currency', precision: 2 },
    }, {
      prop: 'invoiceAmount', alignment: 'center', summaryType: 'sum', label: '{0}', format: { type: 'currency', precision: 2 },
    }],
    options: {
      columnConfig: true,
      columnsMovable: true,
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      summaryRows: true,
      showSelectFilter: false,
    },
  },
  summaryChildTableConfiguration: {
    columns: [{
      prop: 'ba', label: 'BA', filterable: true, sortable: true,
    }, {
      prop: 'invoiceNum', label: 'INVOICE NUM', filterable: true, sortable: true,
    }, {
      prop: 'invoiceType', label: 'INVOICE TYPE', filterable: true, sortable: true,
    }, {
      prop: 'lineType', label: 'LINE TYPE', filterable: true, sortable: true,
    }, {
      prop: 'version', label: 'VERSION', filterable: true, sortable: true,
    }, {
      prop: 'tzStartDate', label: 'START DATE', filterable: true, sortable: true,
    }, {
      prop: 'tzEndDate', label: 'END DATE', filterable: true, sortable: true,
    }, {
      prop: 'aliasName', label: 'ALIAS NAME', filterable: true, sortable: true,
    }, {
      prop: 'chargeCode', label: 'CHARGE CODE', filterable: true, sortable: true,
    }, {
      prop: 'lineAmount', label: 'LINE AMT', filterable: true, sortable: true, format: { type: 'currency', precision: 2 },
    }, {
      prop: 'previousAmount', label: 'PREVIOUS AMT', filterable: true, sortable: true, format: { type: 'currency', precision: 2 },
    }, {
      prop: 'netAmount', label: 'NET AMT', filterable: true, sortable: true, format: { type: 'currency', precision: 2 },
    }, {
      prop: 'calcAmount', label: 'CALC AMT', filterable: true, sortable: true, format: { type: 'currency', precision: 2 },
    }, {
      prop: 'variance', label: 'VARIANCE AMT', filterable: true, sortable: true, format: { type: 'currency', precision: 2 },
    }, {
      prop: 'description', label: 'DESCRIPTION', filterable: true, sortable: true,
    }, {
      prop: 'majorAccount', label: 'MAJOR ACCOUNT', filterable: true, sortable: true,
    }, {
      prop: 'accountGl', label: 'ACCOUNT GL', filterable: true, sortable: true,
    }],
    options: {
      columnConfig: true,
      columnsMovable: true,
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      summaryRows: true,
    },
  },
  statementTableConfiguration: {
    name: 'Statement',
    columns: [{
      prop: 'aliasName', label: 'ALIAS NAME', filterable: true, sortable: true,
    },{
      prop: 'docTitle', label: 'DOC TITLE', filterable: true, sortable: true,
    }, {
      prop: 'tzTradeDate', label: 'TRADE DATE', filterable: true, sortable: true,
    }, {
      prop: 'source', label: 'SOURCE', filterable: true, sortable: true,
    }],
    options: {
      columnConfig: true,
      columnsMovable: true,
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      showSelectFilter: false,
    },
  },
};