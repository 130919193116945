import moment from 'moment';
import { clone, uniqueArrayByObjKey } from '@/utils/dataUtil';
import { TOOLS_API } from '@/api';
import caisoUtil from '@/utils/caiso/caisoUtils';

const state = {
  tableData: [],
  coordinatorList: [],
  subCompanyList: [],
  scSubCompanyList: [],
  resourceList: [],
  currentTableRow: null,
  nullTableRow: {
    id: 0,
    sc: null,
    subCompany: null,
    rsrcId: null,
    rsrcType: null,
    lossRate: 0,
    priceNodeId: null,
    intertieId: null,
    lossPriceNodeId: null,
    lossPriceIntertieId: null,
    effectiveDate: null,
    terminationDate: null,
    meterChannel: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  async fetchEimSuballocationMapping({ commit }, args) {
    try {
      const params = {
        scs: args.coordinatorListSelected,
        subCompanies: args.subCompanyListSelected,
      };
      const { data: { data } } = await TOOLS_API.get('/transmission-billing-info/resources', { params });
      data.forEach((obj) => {
        obj.effectiveDate = obj.effectiveDate === null ? null : moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
        obj.terminationDate = obj.terminationDate === null ? null : moment(obj.terminationDate).utc().format('MM/DD/YYYY');
        obj.createdDate = obj.createdDate === null ? null : moment(obj.createdDate).utc().format('MM/DD/YYYY');
        obj.updatedDate = obj.updatedDate === null ? null : moment(obj.updatedDate).utc().format('MM/DD/YYYY');
      });
      commit('setTableData', data);
    } catch (error) {
      this.$notify('Failed to load EIM Suballocation Resources', 'error');
    }
  },
  async fetchCoordinators({ commit }) {
    try {
      const { data: { schedulingCoordinators: scs } } = await TOOLS_API.get('/scheduling-coordinators/resources');
      commit('setCoordinatorList', caisoUtil.configureCoordinators(scs));
    } catch (error) {
      this.$notify('Failed to load Scheduling Coordinators', 'error');
      console.error(error);
    }
    return {};
  },
  async fetchSubCompanies({ commit }) {
    const params = {
      withResources: false,
    };
    try {
      const { data: { data } } = await TOOLS_API.get('/transmission-billing-info/sub-companies', { params });
      const transformedData = data.map(({ subCompany, sc, resources }) => {
        const results = {
          subCompany: { value: subCompany, label: subCompany },
          sc,
          resources,
        };
        if (Array.isArray(resources)) {
          resources.forEach((resource) => {
            resource.value = resource.rsrcId;
            resource.label = resource.rsrcId;
          });

          results.resources = uniqueArrayByObjKey(resources, 'label');
        }
        return results;
      });
      commit('setSubCompanyList', transformedData);
    } catch (error) {
      vue.$notify('Failed to load Trans Billing Sub Companies', 'error');
      console.error(error);
    }
    return [];
  },
  async upsertTableRow({ commit, state }, record) {
    const params = clone(record);
    params.sc = state.coordinatorList
      .find((x) => params.sc === x.baId.toString() || params.sc === x.shortName).shortName.toString();

    const ID = params.id;
    try {
      const { data: { data } } = await TOOLS_API.post('/transmission-billing-info/upsert', params);
      data.forEach((obj) => {
        obj.effectiveDate = obj.effectiveDate === null ? null : moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
        obj.terminationDate = obj.terminationDate === null ? null : moment(obj.terminationDate).utc().format('MM/DD/YYYY');
        obj.createdDate = obj.createdDate === null ? null : moment(obj.createdDate).utc().format('MM/DD/YYYY');
        obj.updatedDate = obj.updatedDate === null ? null : moment(obj.updatedDate).utc().format('MM/DD/YYYY');
      });
      if (ID === 0) { // ADD
        state.tableData.push(data[0]);
        this.$notify('Row Added', 'success');
      } else { // UPDATE
        commit('updateRecord', data[0]);
        this.$notify('Row Updated', 'success');
      }
    } catch (error) {
      this.$notify('Requested Operation Failed', 'error');
      console.error(error);
    }
  },
  async deleteTableRow({ commit, state }) {
    try {
      await TOOLS_API.delete(`/transmission-billing-info/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Row Deactivated', 'success');
    } catch {
      this.$notify('Failed to Deactivate Row', 'error');
    }
  },
  async adminDeleteData({ commit }, args) {
    const params = {
      dataType: args.dataType,
      sc: args.sc,
      subCompany: args.subCompany,
      resource: args.resource,
      startDate: moment(args.dateRange[0]).toISOString(),
      endDate: moment(args.dateRange[1]).toISOString(),
    };
    try {
      const { data } = await TOOLS_API.post('/transmission-billing-info/admin-delete', params);
      if (data?.successful) {
        this.$notify(`${args.dataType} Data Deleted`, 'success');
      } else {
        console.error(data?.message);
        this.$notify(`Failed to Delete ${args.dataType} Data`, 'error');
      }
    } catch (err) {
      console.error(err);
      this.$notify(`Error Occurred when attempting to delete ${args.dataType} data`, 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) { // TODO: assure that dates get formatted properly on record changes
    state.currentTableRow = clone(currentRow);
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = moment().utc().format();
    state.currentTableRow = record;
  },
  editTableRow(state) {
    const record = clone(state.currentTableRow);
    record.updatedBy = this.getters['auth/getProfile'].userName;
    record.updatedDate = moment().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  setCoordinatorList(state, value) {
    state.coordinatorList = value;
  },
  setResources(state, sc) {
    if (sc !== null) {
      const scResources = caisoUtil.getSchedulingCoordinatorResourcesOptions(state.coordinatorList, sc).map((x) => x.value);
      const tableResources = state.tableData.filter((row) => row.sc === sc).map((data) => data.rsrcId);
      const customResources = [...new Set([...scResources, ...tableResources])].map((x) => ({ value: x, label: x })); // creates list of sc and custom resources for drop down
      state.resourceList = customResources;
    }
  },
  setSubCompanyList(state, value) {
    state.subCompanyList = value;
  },
  setSubCompanies(state, sc) {
    if (sc !== null) {
      const subCompanies = caisoUtil.getSchedulingCoordinatorSubCompanyOptions(state.subCompanyList, sc);
      state.scSubCompanyList = subCompanies;
    }
  },
  setNewResource(state, value) {
    const resourceIdx = state.coordinatorList.findIndex(({ shortName }) => shortName === value.sc);
    if (resourceIdx !== -1) {
      state.coordinatorList[resourceIdx].resources.push(value.resource);
    }
    state.resourceList.push(value.resource);
  },
  setNewSubCompany(state, value) {
    state.subCompanyList.push(value);
    state.scSubCompanyList.push(value.subCompany);
  },
  clearDialog(state) {
    state.scSubCompanyList = [];
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};