import { RECS_MANAGEMENT_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  selectedSystemNames: null,
  selectedEntityNames: [],
  systemNameOptions: ['WREGIS'],

  transactionData: [],
  scs: [],
  
  transactionTableData: [],
  transactionConfig: {
    name: 'recTransactions',
    columns: [
      { prop: 'certificate.serialNumberBase', label: 'Serial# Base', sortable: true, filterable: true, groupIndex: 0 },
      { prop: 'systemName', label: 'System Name', sortable: true, filterable: true, },
      { prop: 'serialNumberStart', label: 'Serial # Start', sortable: true, filterable: true },
      { prop: 'serialNumberEnd', label: 'Serial # End', sortable: true, filterable: true },
      { prop: 'fromAccount.name', label: 'From Acct', sortable: true, filterable: true },
      { prop: 'fromOrganization.name', label: 'From Org', sortable: true, filterable: true },
      { prop: 'toAccount.name', label: 'To Acct', sortable: true, filterable: true },
      { prop: 'toOrganization.name', label: 'To Org', sortable: true, filterable: true },
      { prop: 'transactionType', label: 'Transaction Type', sortable: true, filterable: true },
      { prop: 'startedAt', label: 'Started At', sortable: true, filterable: true },
      { prop: 'endedAt', label: 'Ended At', sortable: true, filterable: true },
      { prop: 'status', label: 'Status', sortable: true, filterable: true },
      { prop: 'quantity', label: 'Qty', sortable: true, filterable: true },
      { prop: 'retirementKind', label: 'Retirement Kind', sortable: true, filterable: true },
      { prop: 'exportOrganization.name', label: 'Export Org', sortable: true, filterable: true },
      { prop: 'hourlyClaim', label: 'Hourly Claim', sortable: true, filterable: true },
      { prop: 'compliancePeriod', label: 'Compliance Period', sortable: true, filterable: true, visible: false },
      { prop: 'retirementType', label: 'Retirement Type', sortable: true, filterable: true, visible: false },
      { prop: 'retiredTo', label: 'Retired To', sortable: true, filterable: true, visible: false },
      { prop: 'retiredQuarter', label: 'Retired Quarter', sortable: true, filterable: true, visible: false },
      { prop: 'retirementReason', label: 'Retirement Reason', sortable: true, filterable: true, visible: false },
      { prop: 'exportSystem', label: 'Export System', sortable: true, filterable: true, visible: false },
      { prop: 'isMultiAccountDestination', label: 'Multi Account Destination', sortable: true, filterable: true, visible: false },
      { prop: 'user.name', label: 'User', sortable: true, filterable: true, visible: false },
      { prop: 'endedByUser.name', label: 'Ended By User', sortable: true, filterable: true, visible: false },
      { prop: 'retirementOption', label: 'Retirement Option', sortable: true, filterable: true, visible: false },
      { prop: 'notes', label: 'Notes', sortable: true, filterable: true, visible: false },
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
      groupPanel: true,
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async initialize({ commit, dispatch }) {
    commit('resetTable');
    await dispatch('fetchScs');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit }) {
    commit('resetTable');
    await dispatch('loadTransactionData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'COMPANY'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async loadTransactionData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map(async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName
      };
      await RECS_MANAGEMENT_API.get('/recs-management/transaction/transactionDetails', { params }).then(({ data }) => {
        commit('addTransactionDetailData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Transaction Details', 'error');
      });
    }));
    
    this.$notify(`${state.transactionData.length} Transaction Details Loaded`, 'info');
    commit('setTransactionTableData');
  },
};

const mutations = {
  resetTable(state) {
    state.transactionTableData = [];
    state.transactionData = [];
  },
  setSelectedSystems(state, value) {
    state.selectedSystemNames = value;
  },
  setSelectedEntities(state, value) {
    state.selectedEntityNames = value;
  },
  setTransactionTableData(state) {
    // transactionData has a structure of [{ transaction: { ...transactionProps }, details: [{ ...detailProps }] }]
    state.transactionData = state.transactionData.reduce((acc, item) => {
      const {...detail} = item
      const { transaction } = detail;
      delete detail.transaction;
      const existingItem = acc.find(x => x.transaction.id === transaction.id);
      if (existingItem) {
        existingItem.details.push(detail);
      } else {
        acc.push({ transaction, details: [detail] });
      }
      return acc;
    }, []);

    state.transactionTableData = state.transactionData.reduce((acc, item) => {
      // flattens transactionData into a list of transactions and their details to match table config
      const { transaction, details } = item;
      const transactionRow = details.map(detail => {
        return ({ ...transaction, ...detail });
      });;
      acc.push(...transactionRow);
      return acc;
    }, []);
  },
  addTransactionDetailData(state, transactionDetails) {
    state.transactionData = state.transactionData.concat(transactionDetails);
  },
  ...createMutations('scs'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};