import { VA_API, TOOLS_API, CISO_INTERNAL_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import VABaseStore from '../VABaseStore';

const constValues = {
  defaultColumnWidth: 100,
  defautNotificationOptions: {
    displayTime: 4000,
    animation: {
      show: {
        type: 'fade', duration: 400, from: 0, to: 1,
      },
      hide: { type: 'fade', duration: 40, to: 0 },
    },
  },
};
const state = {
  selectedBackfillType: null,
  backfillTypes: [{ value: 'Daily Pushes', label: 'Daily Pushes' },
    { value: 'Individual Reports', label: 'Individual Reports' },
    // { value: 'Real Time', label: 'Real Time' }
  ],
  selectedEngine: [],
  engines: [],
  selectedDateRange: dateStore.getDefaultRange(),
  selectedWorkflow: null,
  systems: [],
  selectedSystems: [],
  systemReports: [],
  filteredSystemReports: [],
  selectedSystemReports: [],
  schedulingCoordinatorsList: [],
  selectedSchedulingCoordinators: [],
  availabilityTableConfig: {
    name: 'DataAvailabilityConfig',
    columns: constValues.defaultColumns,
  },
  availabilityTableData: [],
};

const getters = {
  filteredSystemReports: (state) => state.systemReports.filter((systemReport) => state.selectedSystems.includes(systemReport.key) && systemReport.hide !== true),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('resetSelectedState');

    // initialize options
    dispatch('fetchEngines');
    dispatch('fetchVariants', state.selectedDateRange[0]);
    dispatch('fetchSystemsAndReports');
    dispatch('fetchSchedulingCoordinators');
  },
  resetSelectedState({ commit, state }) {
    commit('setSelectedBackfillType', null);
    commit('setSelectedEngine', []);
    commit('setSelectedWorkflow', null);
    commit('setSelectedSystems', []);
    commit('setSelectedSystemReports', []);
    commit('setSelectedSchedulingCoordinators', []);
  },
  async fetchSchedulingCoordinators({ commit }) {
    try {
      const { data: { schedulingCoordinators: scs } } = await TOOLS_API.get('/scheduling-coordinators/resources');
      const schedulingCoordinators = scs.map((sc) => ({ label: sc.shortName, value: sc.shortName }));
      commit('setSchedulingCoordinatorsList', schedulingCoordinators);
    } catch (error) {
      vue.$notify('Failed to load scheduling coordinators.', 'error');
      console.log(error);
    }
  },
  async addSystemsAndSystemReports({ state, commit }, { newSystemsArr, newSystemReportsArr }) {
    let systems = {};
    state.systems.forEach(({ label }) => { systems[label] = true; });
    newSystemsArr.forEach((systemName) => { systems[systemName] = true; });

    // convert systems dictionary to list
    systems = Object.keys(systems)
      .map((systemName) => ({ label: systemName, value: systemName }));
    // sort systems
    systems = systems.sort((a, b) => a.label > b.label ? 1 : -1);
    commit('setSystems', systems);

    let { systemReports } = state;
    newSystemReportsArr.forEach((systemReport) => { systemReports.push(systemReport); });
    // sort systemReports by system name then by report name
    systemReports = systemReports.sort((a, b) => (a.key === b.key) ? a.label > b.label
      : a.key > b.key);
    commit('setSystemReports', systemReports);
  },
  async fetchSystemsAndReports({ dispatch }) {
    // pull the reports that use legacy services
    let data;
    try {
      data = await TOOLS_API.get('/legacy-va/settings');
    } catch (error) {
      vue.$notify('Failed to load VA Push Reports.', 'error');
      console.log(error);
    }
    const systems = {};
    const systemReports = [];

    // retrieve the reports tied to their systems and distinct systems
    data.data.forEach((item) => {
      const reportRegex = /^WEB_VA_PUSH_REPORT$/g;
      const groupRegex = /^WEB_VA_PUSH_GROUP$/g;

      const isWebVAPushReport = item.key.match(reportRegex);
      const isWebVAPushGroup = item.key.match(groupRegex);
      // if it is not report entry skip entry or a group entry
      if (!(isWebVAPushReport || isWebVAPushGroup)) {
        return;
      }

      // value must be valid json
      let value;
      try {
        value = JSON.parse(item.value);
      } catch (error) {
        value = null;
      }

      if (value === null) {
        return;
      }

      if (isWebVAPushReport) {
        systems[value.systemName] = true;
        systemReports.push({
          systemReport: value,
          label: value.report,
          value: value.report,
          reportType: value.reportType,
          key: value.systemName,
        });
      } else if (isWebVAPushGroup) {
        systems['GROUPED REPORTS'] = true;
        systemReports.push({
          group: value,
          label: value.groupName,
          value: value.groupName,
          hide: value.hide === 'true',
          key: 'GROUPED REPORTS',
          reportType: 'GROUP',
        });
      }
    });

    dispatch('addSystemsAndSystemReports', { newSystemsArr: Object.keys(systems), newSystemReportsArr: systemReports });
  },
  async execute({ state, dispatch }) {
    switch (state.selectedWorkflow) {
    case 'Engine':
      dispatch('runEngine');
      break;
    case 'Backfill':
      dispatch('backfill');
      break;
    default:
      break;
    }
  },

  async backfill({ state, dispatch }) {
    const dateRangeDaysDiff = moment(state.selectedDateRange[1].toISOString())
      .diff(moment(state.selectedDateRange[0].toISOString()), 'days');
    if (dateRangeDaysDiff > 30) {
      this.$notify({
        message: 'Please select a date range less than 1 month',
        type: 'Error',
        ...(constValues.defaultNotificationAnimation),
      }, {
        position: 'bottom center',
        direction: 'up-stack',
      });
      return;
    }
    if (dateRangeDaysDiff > 6) {
      this.$notify({
        message: 'Selecting a push duration exceeding 7 days may result in extended processing time.',
        type: 'warning',
        ...(constValues.defaultNotificationAnimation),
      }, {
        position: 'bottom center',
        direction: 'up-stack',
      });
    }

    const internalDataSystemReports = {};
    const legacySystemReports = {};

    const markListOfReports = (listOfReports) => {
      listOfReports.forEach((systemReportName) => {
        const systemReport = state.systemReports.find((systemReport) => systemReport.value === systemReportName);
        switch (systemReport.reportType.toUpperCase()) {
        case 'GROUP':
          {
            // filter systemReports that are marked with the name of group in their 'group' object
            const groupName = systemReportName;
            const groupedReports = state.systemReports.filter((sr) => sr.reportType !== 'GROUP'
              && Boolean(sr.systemReport.group?.[groupName]))
              .map((systemReports) => (systemReports.value));
            markListOfReports(groupedReports);
          }
          break;
        case 'INTERNAL DATA':
          internalDataSystemReports[systemReport.label] = systemReport;
          break;
        case 'LEGACY':
          legacySystemReports[systemReport.label] = systemReport;
          break;
        default:
          break;
        }
      });
    };
    switch (state.selectedBackfillType) {
    case 'Daily Pushes':
      markListOfReports([`${state.VABaseStore.selectedVariant} Reports`]);
      break;
    case 'Individual Reports':
      if (state.selectedSystemReports.length === 0) {
        this.$notify({
          message: 'Please select at least one system and at least one system report to push.',
          type: 'Error',
          ...(constValues.defaultNotificationAnimation),
        }, {
          position: 'bottom center',
          direction: 'up-stack',
        });
        return;
      }
      markListOfReports(state.selectedSystemReports);
      break;
    case 'Real Time':
      break;
    default:
      break;
    }

    dispatch('backfillInternalData', Object.values(internalDataSystemReports));
    dispatch('backfillLegacyReport', Object.values(legacySystemReports));
    this.$notify({
      message: 'Successfully initiated download',
      type: 'success',
      ...(constValues.defaultNotificationAnimation),
    }, {
      position: 'bottom center',
      direction: 'up-stack',
    });
  },
  async backfillInternalData({ state, dispatch }, systemReportsList) {
    if (systemReportsList.length < 1) {
      return;
    }
    let tradingDate = moment(state.selectedDateRange[0].toISOString());// initialize to start date
    const endDate = moment(state.selectedDateRange[1].toISOString());

    await Promise.all(systemReportsList.map(async (sr) => {
      try {
        while (tradingDate.isSameOrBefore(endDate)) {
          const params = {
            tradingDate: tradingDate.toISOString(),
            minimumMinuteOffset: -1440,
            withNotification: true,
            Variant: state.VABaseStore.selectedVariant,
            extractType: sr.systemReport.extractType,
          };
          await CISO_INTERNAL_API.post(`/va-extract/workflow/${sr.systemReport.workflowName}`, params);
          tradingDate = tradingDate.add(1, 'days');
        }
      } catch (error) {
        console.log(error);
      }
    }));
  },
  async backfillLegacyReport({ state, dispatch }, systemReportsList) {
    if (systemReportsList.length < 1) {
      return;
    }
    // group systemReports by systemName
    const groupedSystemReports = {};
    systemReportsList.forEach((systemReport) => {
      if (groupedSystemReports[systemReport.systemReport.systemName]) {
        groupedSystemReports[systemReport.systemReport.systemName].push(systemReport.value);
      } else {
        groupedSystemReports[systemReport.systemReport.systemName] = [systemReport.value];
      }
    });

    // convert to array from object
    const arrGroupedSystemReports = Object.entries(groupedSystemReports).map(([system, reports]) => ({
      system,
      reports,
    }));

    try {
      // format parameters
      const selectedSCs = state.selectedSchedulingCoordinators.length > 0 ? state.selectedSchedulingCoordinators : null;
      const params = {
        startDate: state.selectedDateRange[0].toISOString(),
        endDate: state.selectedDateRange[1].toISOString(),
        entities: selectedSCs,
        coordinators: selectedSCs,
        systemReports: arrGroupedSystemReports,
        Variant: state.VABaseStore.selectedVariant,
      };
      await TOOLS_API.post('/legacy-process/web-va-push', params);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchEngines({ commit }) {
    try {
      const { data: { data } } = await VA_API.get('engines');
      commit('setEngines', data);
    } catch (error) {
      console.error('Error fetching Engines', error);
    }
  },
  runEngine({ state, getters }) {
    const { selectedEngine, selectedDateRange, engines } = state;
    const [startTime, endTime] = selectedDateRange;

    if (selectedEngine.length) {
      try {
        selectedEngine.forEach((engineid) => {
          const params = {
            startTime: moment.utc(startTime).toISOString(),
            endTime: moment.utc(endTime).add(1, 'days').toISOString(),
            variant: engines.find((engine) => engineid === engine.id).variant,
          };
          VA_API.put(`engines/${engineid}/trigger`, params);
        });
        this.$notify('Engine task sent');
      } catch (error) {
        console.error('Error making request', error);
      }
    } else {
      this.$notify('Please Select Engine(s)', 'warning');
    }
  },
};

const mutations = {
  ...createMutations(
    'engines',
    'selectedBackfillType',
    'selectedEngine',
    'selectedDateRange',
    'selectedWorkflow',
    'systems',
    'selectedSystemReports',
    'systemReports',
    'schedulingCoordinatorsList',
    'selectedSchedulingCoordinators',
    'availabilityTableData',
  ),
  setAvailabilityTableConfigColumns(state, newColumns) {
    state.availabilityTableConfig.columns = newColumns;
  },
  setSelectedSystems(state, newSelectedSystem) {
    // update selectedSystems
    state.selectedSystems = newSelectedSystem;
    // filter the selectedSystemReports to systemReports where the systems are still selected
    if (state.selectedSystemReports.length > 0) {
      state.selectedSystemReports = state.selectedSystemReports.filter((systemReportLabel) => {
        // find the system report
        const systemReport = state.systemReports.find((systemReport) => systemReport.label === systemReportLabel);
        // filter based on selected systems
        return state.selectedSystems.includes(systemReport.key);
      });
    }
  },

};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};