import * as dateUtil from '@/utils/dateUtil';
import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';

const state = {
  tableConfiguration: {
    columns: [{
      prop: 'rec', width: 100, summaryColSpan: 3, sortable: true,
    },
    {
      prop: 'toEntity', label: 'ToEntity', filterable: true, sortable: true,
    },
    {
      prop: 'tagID', width: 200, type: { name: 'PscsTagRouteCellRenderer', overrideReadonly: true }, summaryColSpan: 0, sortable: true,
    },
    {
      prop: 'state', width: 100, label: 'status', class: true, summaryColSpan: 0, sortable: true,
    },
    { prop: 'startTime', width: 125, sortable: true },
    { prop: 'endTime', width: 125, sortable: true },
    { prop: 'source', width: 100, sortable: true },
    { prop: 'sink', width: 100, sortable: true },
    { prop: 'cpse', width: 100, sortable: true },
    { prop: 'gca', width: 100, sortable: true },
    { prop: 'lca', width: 100, sortable: true },
    { prop: 'gpe', width: 100, sortable: true },
    { prop: 'lpe', width: 100, sortable: true },
    { prop: 'total', width: 80, sortable: true },
    { prop: 'he01', width: 50 },
    { prop: 'he02', width: 50 },
    { prop: 'he03', width: 50 },
    { prop: 'he04', width: 50 },
    { prop: 'he05', width: 50 },
    { prop: 'he06', width: 50 },
    { prop: 'he07', width: 50 },
    { prop: 'he08', width: 50 },
    { prop: 'he09', width: 50 },
    { prop: 'he10', width: 50 },
    { prop: 'he11', width: 50 },
    { prop: 'he12', width: 50 },
    { prop: 'he13', width: 50 },
    { prop: 'he14', width: 50 },
    { prop: 'he15', width: 50 },
    { prop: 'he16', width: 50 },
    { prop: 'he17', width: 50 },
    { prop: 'he18', width: 50 },
    { prop: 'he19', width: 50 },
    { prop: 'he20', width: 50 },
    { prop: 'he21', width: 50 },
    { prop: 'he22', width: 50 },
    { prop: 'he23', width: 50 },
    { prop: 'he24', width: 50 },
    ],
    options: {
      summaryRows: true,
      exportXlsx: true,
      tableOptions: true,
    },
    buttons: {
      exportCsv: true,
      exportXlsx: true,
    },
  },
  dateRange: [dateUtil.getTodayLocal(), dateUtil.getTodayLocal()],
  tableData: [],
};

const getters = {
  getTableConfiguration: (state) => state.tableConfiguration,
  getTableData: (state) => state.tableData,
  getDateRange: (state) => state.dateRange,
  getSummaryData(state) {
    if (state.tableData.length > 0) {
      const summaryItem = {
        rec: state.tableData[0].rec,
      };

      state.tableConfiguration.columns.forEach((c) => {
        if (c.prop.startsWith('he')) {
          summaryItem[c.prop] = Math.round(state.tableData.reduce((acc, data) => acc + data[c.prop], 0));
        }
      });

      summaryItem.total = Math.round(state.tableData.reduce((acc, { total }) => acc + total, 0));
      summaryItem.summaryFlag = true;
      return [summaryItem];
    }

    return [];
  },
};

const actions = {
  search({ commit }, param) {
    const start = dateStore.toMomentStartOfDay(param.start);
    const end = dateStore.toMomentStartOfDay(param.end);
    const formatString = 'YYYY-MM-DDTHH:mm:ss';

    const requestParams = {
      start: `${start.format(formatString)}Z`,
      end: `${end.format(formatString)}Z`,
      timeZone: 'pst',
    };

    return ETAG_API.get(`/reports/recs/${param.recId}`, {
      params: requestParams,
    }).then((response) => {
      commit('tableDataMutation', response.data.data);
    }).catch((error) => {
      console.log(error);
    });
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  changeDateRangeMutation(state, value) {
    state.dateRange = value;
  },
  tableDataMutation(state, data) {
    const dta = dateStore.formatDateTimeFields(data, [
      { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
      { name: 'endTime', format: 'YYYY-MM-DD HH:mm' }]);

    dta.forEach((d) => {
      d.routeText = d.tagID;
    });
    state.tableData = dta;
  },
  clear(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
