import moment from 'moment';
import { CISO_MASTERDATA_API, CISO_BASE_SCHD_API } from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import helpers from '@/utils/helpers';
import { setAllUneditable } from '@/utils/dataUtil';
import { hasPermission } from '@/utils/authUtils';
import {
  findBaseSchedulingCoordinator, findResources, mapToOptions, joinBaseScheduleStatusesAndResources, generateBasePointSchedule,
} from './util';

const DAY = 'DAY';
const LONG_DAY = 'LONG_DAY';
const SHORT_DAY = 'SHORT_DAY';

const state = {
  tableConfiguration: {
    columns: [{
      prop: 'sc', width: 100, fixed: 'left', sortable: true, filterable: true,
    }, {
      prop: 'resource', cellTemplate: 'PscsRouteCellTemplate', width: 180, fixed: 'left', sortable: true, filterable: true,
    }, {
      prop: 'configuration', fixed: 'left', width: 180, filterable: true, sortable: true,
    }, {
      prop: 'productType', width: 100, label: 'product', fixed: 'left',
    }, {
      prop: 'he01_val', width: 100, label: 'HE01', editable: true,
    }, {
      prop: 'he02_val', width: 100, label: 'HE02', editable: true,
    }, {
      prop: 'he02*_val', width: 100, label: 'HE02*', editable: true, visible: false,
    }, {
      prop: 'he03_val', width: 100, label: 'HE03', editable: true,
    }, {
      prop: 'he04_val', width: 100, label: 'HE04', editable: true,
    }, {
      prop: 'he05_val', width: 100, label: 'HE05', editable: true,
    }, {
      prop: 'he06_val', width: 100, label: 'HE06', editable: true,
    }, {
      prop: 'he07_val', width: 100, label: 'HE07', editable: true,
    }, {
      prop: 'he08_val', width: 100, label: 'HE08', editable: true,
    }, {
      prop: 'he09_val', width: 100, label: 'HE09', editable: true,
    }, {
      prop: 'he10_val', width: 100, label: 'HE10', editable: true,
    }, {
      prop: 'he11_val', width: 100, label: 'HE11', editable: true,
    }, {
      prop: 'he12_val', width: 100, label: 'HE12', editable: true,
    }, {
      prop: 'he13_val', width: 100, label: 'HE13', editable: true,
    }, {
      prop: 'he14_val', width: 100, label: 'HE14', editable: true,
    }, {
      prop: 'he15_val', width: 100, label: 'HE15', editable: true,
    }, {
      prop: 'he16_val', width: 100, label: 'HE16', editable: true,
    }, {
      prop: 'he17_val', width: 100, label: 'HE17', editable: true,
    }, {
      prop: 'he18_val', width: 100, label: 'HE18', editable: true,
    }, {
      prop: 'he19_val', width: 100, label: 'HE19', editable: true,
    }, {
      prop: 'he20_val', width: 100, label: 'HE20', editable: true,
    }, {
      prop: 'he21_val', width: 100, label: 'HE21', editable: true,
    }, {
      prop: 'he22_val', width: 100, label: 'HE22', editable: true,
    }, {
      prop: 'he23_val', width: 100, label: 'HE23', editable: true,
    }, {
      prop: 'he24_val', width: 100, label: 'HE24', editable: true,
    }, {
      prop: 'he25_val', width: 100, label: 'HE025', editable: true, visible: false,
    }],
    style: { maxHeight: 800 },
    options: {},
  },
  tableData: [],
  resourceTypes: [],
  selectedResourceType: null,
  date: dateStore.getDefaultDate().toDate(),
  selectedMarketType: caisoStore.getMarketType(),
  marketTypes: caisoStore.getMarketTypeOptions(),
  selectedBSC: null,
  productTypes: [{ value: 'EN', label: 'EN' }, { value: 'RU', label: 'RU' }, { value: 'RD', label: 'RD' }, { value: 'SR', label: 'SR' }, { value: 'NR', label: 'NR' }],
  selectedProductType: 'EN',
  isSearched: false,
  statuses: [],
  scToResources: [],
  selectedSchedulingCoordinator: '',
  selectedResource: '',
  multipleSelection: [],
  bscToResources: [],
  filteredResources: [],
  startHour: 1,
  endHour: 24,
  hours: [],
  showOpenHours: false,
  resourceGroups: [],
  selectedResourceGroup: null,
  mode: '',
  is2D: dateStore.getLongDayHour(),
};

const getters = {
  hasWritePermissionFlag(rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso_scheduling:caiso_bsap_sched', 'write');
  },
  getResourceGroups(state) {
    const groups = [];
    if (state.resourceGroups && state.resourceGroups.length > 0) {
      for (let i = 0; i < state.resourceGroups.length; i++) {
        const group = { value: state.resourceGroups[i].id, label: state.resourceGroups[i].name };
        groups.push(group);
      }
    }
    return groups;
  },
  getTableConfiguration(state, rootGetters) {
    const config = helpers.cloneDeep(state.tableConfiguration);

    for (let i = 0; i < config.columns.length; i++) {
      const col = config.columns[i];
      if (col.prop.includes('he')) {
        if (state.mode === LONG_DAY) {
          if (col.label.includes('02*')) { col.visible = state.is2D; }
          if (col.label.includes('25')) { col.visible = !(state.is2D); }
        } else if (state.mode === SHORT_DAY) {
          if (col.label.includes('03')) { col.visible = false; }
        } else if (col.label.includes('02*') || col.label.includes('25')) { col.visible = false; }
      }
    }

    if (state.showOpenHours && state.selectedMarketType === 'RTM') {
      const now = moment();
      const momentDate = moment(state.date);
      const diff = momentDate.diff(now, 'minutes');
      let hour = 0;
      for (let i = 0; i < config.columns.length; i++) {
        const col = config.columns[i];
        if (col.prop.includes('he')) {
          if (col.label.includes('25') && state.mode === LONG_DAY) {
            hour = 3;
          }
          if (col.label < `HE${state.startHour}` && state.date.toDateString() === new Date().toDateString()) {
            col.visible = false;
          }
          if (diff + ((hour) * 60) < 75) {
            col.visible = false;
          }
          if (col.label.includes('02*') && state.mode === LONG_DAY) {
            hour++;
          } else if (col.label.includes('03') && state.mode === SHORT_DAY) {
            continue;
          } else if (!col.label.includes('02*') && !col.label.includes('25')) {
            hour++;
          }
        }
      }
    }

    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso_scheduling:caiso_bsap_sched', 'write');
    if (writePermission) return config;
    return setAllUneditable(config);
  },
  getTableData: (state) => (state.isSearched) ? state.tableData : [],
  getSchedulingCoordinators: (state) => mapToOptions(state.scToResources),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
  getResources: (state) => mapToOptions(state.filteredResources),
  getResource: (state) => state.selectedResource,
  getDate: (state) => state.date,
  getMarketType: (state) => state.selectedMarketType,
  getMarketTypes: (state) => state.marketTypes,
  getBSC: (state) => state.selectedBSC,
  getBSCs: (state) => mapToOptions(state.bscToResources),
  getResourceTypes: (state) => state.resourceTypes,
  getResourceType: (state) => state.selectedResourceType,
  getStartHour: (state) => state.startHour,
  getEndHour: (state) => state.endHour,
  getHours: (state) => state.hours,
  getProductTypes: (state) => state.productTypes,
  getProductType: (state) => state.selectedProductType,
  getShowOpenHours: (state) => state.showOpenHours,
  getResourceGroup: (state) => state.selectedResourceGroup,
};

const actions = {
  loadData({ dispatch, commit }) {
    dispatch('loadBaseSchedulingCoordinatorsAction');
    dispatch('loadSchedulingCoordinatorsWithResourcesAction');
    dispatch('loadResourceGroups');
    dispatch('loadResourceTypes');
    dispatch('checkDST', state.date);
  },
  loadResourceGroups({ commit }) {
    CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE').then((response) => {
      if (response.data && response.data.resourceGroups && response.data.resourceGroups.length > 0) {
        commit('setResourceGroups', response.data.resourceGroups);
        commit('setFilteredResources');
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  dataChanged({ commit }, item) {
    commit('setData', item);
  },
  showOpenHoursChanged({ commit }) {
    commit('setShowOpenHours');
  },
  saveSchedules({ commit, state, dispatch }, callback) {
    const schedulesToSave = state.tableData.filter((o) => o.isDirty === true);
    if (schedulesToSave.length === 0) {
      callback({ type: 'warning', title: 'No Changes To Save' });
      return;
    }
    const newSchedules = [];
    const existingSchedules = [];
    for (let i = 0; i < schedulesToSave.length; i++) {
      const schedule = schedulesToSave[i];
      let momentStartDate = dateStore.toMoment(schedule.tradeDate, 'America/Los_Angeles');
      const momentEndDate = momentStartDate.clone().add(1, 'days');
      let second = false;
      if (schedule.scheduleName) {
        while (momentStartDate.isBefore(momentEndDate)) {
          const start = momentStartDate.clone().utc().format();
          const end = momentStartDate.clone().add(1, 'hours').utc().format();
          const j = momentStartDate.get('hours') + 1;

          let prop = '';
          if (j < 10) {
            if (j === 2) {
              prop = `he0${j}_val`;
              if (second) {
                prop = dateStore.getLongDayHour() ? `he0${j}*_val` : 'he25_val';
              }
              second = true;
            } else { prop = `he0${j}_val`; }
          } else {
            prop = `he${j}_val`;
          }

          let basePointSchedule = schedule.basePointSchedules.find((o) => o.startTime == start && o.endTime == end);
          if (basePointSchedule && schedule[prop]) {
            if (basePointSchedule[state.selectedProductType.toLowerCase()]) { basePointSchedule[state.selectedProductType.toLowerCase()].value = schedule[prop]; } else { basePointSchedule[state.selectedProductType.toLowerCase()] = { value: schedule[prop] }; }
          } else if ((!basePointSchedule) && schedule[prop]) {
            basePointSchedule = generateBasePointSchedule(momentStartDate, state.selectedProductType, schedule[prop]);
            schedule.basePointSchedules.push(basePointSchedule);
          } else if (basePointSchedule && (!schedule[prop])) {
            schedule.basePointSchedules = schedule.basePointSchedules.filter((o) => o.startTime == start && o.endTime == end);
          }

          momentStartDate = momentStartDate.add(60, 'minutes');
        }

        existingSchedules.push(schedule);
      } else {
        if (schedule.configuration) {
          schedule.scheduleName = `${schedule.bsc}-${schedule.sc}-${schedule.marketType}-${schedule.tradeDate}-${schedule.resource}-${schedule.configuration}`;
        } else {
          schedule.scheduleName = `${schedule.bsc}-${schedule.sc}-${schedule.marketType}-${schedule.tradeDate}-${schedule.resource}`;
        }
        const basePointSchedules = [];
        for (let j = 1; j <= 24; j++) {
          const start = moment(state.date).add(j - 1, 'hours').utc();
          const end = helpers.cloneDeep(start).add(1, 'hours').utc();

          let prop = '';
          if (j < 10) {
            if (j === 2) {
              prop = `he0${j}_val`;
              if (second) {
                prop = dateStore.getLongDayHour() ? `he0${j}*_val` : 'he25_val';
              }
              second = true;
            } else { prop = `he0${j}_val`; }
          } else {
            prop = `he${j}_val`;
          }

          if (schedule[prop]) {
            const basePointSchedule = generateBasePointSchedule(momentStartDate, state.selectedProductType, schedule[prop]);
            basePointSchedules.push(basePointSchedule);
          }
          momentStartDate = momentStartDate.add(60, 'minutes');
        }
        schedule.basePointSchedules = basePointSchedules;

        newSchedules.push(schedule);
      }
    }

    if (newSchedules.length > 0) {
      const submitSchedules = { baseSchedules: newSchedules };
      CISO_BASE_SCHD_API.post('/baseSchedules/batch', submitSchedules).then((response) => {
        callback({ type: 'success', title: 'New Schedules Created' });
        dispatch('loadSummaryAction');
      }).catch((err) => {
        callback({ type: 'error', title: 'New Schedule Creation Failed' });
        console.log(err);
      });
    }

    if (existingSchedules.length > 0) {
      const submitSchedules = { baseSchedules: existingSchedules };
      CISO_BASE_SCHD_API.put('/baseSchedules/batch', submitSchedules).then((response) => {
        callback({ type: 'success', title: 'Schedules Updated Successfully' });
        dispatch('loadSummaryAction');
      }).catch((err) => {
        callback({ type: 'error', title: 'Schedule Update Failed' });
        console.log(err);
      });
    }
  },
  resourceTypeChanged({ commit, dispatch }, item) {
    commit('setSelectedResourceType', item);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  checkDST({ state }, item) {
    const momentStartTime = dateStore.toMoment(item, 'America/Los_Angeles');
    const momentEndTime = momentStartTime.clone().add(1, 'days');
    let currentTime = momentStartTime.clone();
    const hours = [];
    let i = 1;
    while (currentTime.isBefore(momentEndTime)) {
      currentTime = currentTime.add(1, 'hours');
      hours.push({ label: i, value: i });
      i++;
    }
    if (hours.length > 24) {
      state.mode = LONG_DAY;
      if (state.is2D) {
        hours.forEach((x) => {
          if (x.value > 2) {
            if (x.value == 3) {
              x.label = '2*';
              x.value = '2*';
            } else {
              x.label -= 1;
              x.value -= 1;
            }
          }
        });
      }
    } else if (hours.length < 24) {
      hours.forEach((x) => {
        if (x.value >= 3) {
          x.label += 1;
          x.value += 1;
        }
      });
      state.mode = SHORT_DAY;
    } else {
      state.mode = DAY;
    }
  },
  loadResourceTypes({ commit }) {
    CISO_MASTERDATA_API.get('resources/types').then((response) => {
      if (response.data && response.data.length > 0) {
        commit('setResourceTypes', response.data);
        commit('setFilteredResources');
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  isSearchedChanged({ commit, dispatch }) {
    commit('setIsSearched');
    dispatch('loadSummaryAction');
  },
  loadSummaryAction({ commit, state }) {
    let sc = null;
    if (state.selectedSchedulingCoordinator) {
      sc = state.scToResources.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;
    }

    let resource = null;
    if (state.selectedResource) {
      for (let i = 0; i < state.scToResources.length; i++) {
        const found = state.scToResources.resources.find((o) => o.id === state.selectedResource);
        if (found) {
          resource = found.label;
          break;
        }
      }
    }

    let bsc = null;
    if (state.selectedBSC) bsc = state.bscToResources.find((o) => o.id === state.selectedBSC).shortName;

    const params = {
      tradeDate: state.date,
      bsc,
      sc,
      marketType: state.selectedMarketType,
    };

    if (resource) params.resource = resource;

    if (state.resourceType) params.resourceType = state.resourceType;

    CISO_BASE_SCHD_API.get('/BaseSchedules', { params }).then((response) => {
      if (response.data) {
        commit('loadSummaryMutation', response.data.baseSchedules);
      }
    }).catch((error) => {
      console.log(error);
    });
  },
  clear({ commit }) {
    commit('clear');
  },
  loadBaseSchedulingCoordinatorsAction({ commit }) {
    const bsc = findBaseSchedulingCoordinator(state.bscToResources, state.selectedBSC) || '';
    CISO_MASTERDATA_API.get('/baseschedulingcoordinator/resources').then((response) => {
      commit('setBscToResourceMutation', response.data);
      commit('setFilteredResources');
    }).catch((err) => {
      console.log(err);
    });
  },
  changeDateAction({ commit, dispatch }, value) {
    commit('changeDateMutation', value);
    dispatch('checkDST', value);
    dispatch('loadSummaryAction');
  },
  changeMarketTypeAction({ commit, dispatch }, value) {
    commit('changeMarketTypeMutation', value);
    dispatch('loadSummaryAction');
  },
  changeBSCAction({ commit, dispatch }, value) {
    commit('changeBSCMutation', value);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  loadSchedulingCoordinatorsWithResourcesAction({ commit }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true').then((response) => {
      commit('setScToResourceMutation', response.data);
      commit('setFilteredResources');
    }).catch((err) => {
      console.log(err);
    });
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  changeResourceAction({ commit, dispatch }, item) {
    commit('changeResourceMutation', item);
    dispatch('loadSummaryAction');
  },
  changeMultipleSelectionAction({ commit }, val) {
    commit('multipleSelectionMutation', val);
  },
  productTypeChanged({ commit, state }, item) {
    if (state.tableData) {
      let found = false;
      for (let i = 0; i < state.tableData.length; i++) {
        if (state.tableData[i].isDirty) {
          found = true;
        }
      }
      if (found) item.callback({ type: 'warning', title: 'Please save your changes' });
      else commit('setProductType', item.e);
      commit('updateTableData', item.e);
    }
  },
  resourceGroupChanged({ commit, dispatch }, value) {
    commit('setSelectedResourceGroup', value);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
};

const mutations = {
  updateTableData(state, value) {
    let resourceGroup = null;
    if (state.selectedResourceGroup) {
      resourceGroup = state.resourceGroups.find((o) => o.id === state.selectedResourceGroup);
    }

    state.tableData = joinBaseScheduleStatusesAndResources(
      state.baseSchedules,
      state.filteredResources,
      state.scToResources,
      state.bscToResources,
      state.selectedSchedulingCoordinator,
      state.selectedResource,
      state.date,
      state.selectedMarketType,
      state.selectedBSC,
      state.selectedResourceType,
      resourceGroup,
      value,
    );
  },
  setProductType(state, value) {
    state.selectedProductType = value;
    for (let i = 0; i < state.tableData.length; i++) {
      state.tableData[i].productType = value;
    }
  },
  setStartHour(state, value) {
    state.startHour = value;
  },
  setEndHour(state, value) {
    state.endHour = value;
  },
  setResourceTypes(state, items) {
    state.resourceTypes = items.map((value) => ({ value, label: value }));
  },
  loadSummaryMutation(state, data) {
    state.baseSchedules = data;

    let resourceGroup = null;
    if (state.selectedResourceGroup) {
      resourceGroup = state.resourceGroups.find((o) => o.id === state.selectedResourceGroup);
    }

    state.tableData = joinBaseScheduleStatusesAndResources(
      state.baseSchedules,
      state.filteredResources,
      state.scToResources,
      state.bscToResources,
      state.selectedSchedulingCoordinator,
      state.selectedResource,
      state.date,
      state.selectedMarketType,
      state.selectedBSC,
      state.selectedResourceType,
      resourceGroup,
      state.selectedProductType);
  },
  clear(state) {
    state.tableData = [];
    state.isSearched = false;
  },

  changeDateMutation(state, value) {
    state.date = value;
  },
  changeMarketTypeMutation(state, value) {
    state.selectedMarketType = value;
  },
  setBscToResourceMutation(state, item) {
    if (item && item.baseSchedulingCoordinators && item.baseSchedulingCoordinators.length > 0) {
      state.selectedBSC = item.baseSchedulingCoordinators[0].id;
      state.bscToResources = item.baseSchedulingCoordinators;
    }
  },
  changeBSCMutation(state, value) {
    state.selectedBSC = value;
    state.selectedResource = null;
  },
  setScToResourceMutation(state, item) {
    if (item && item.schedulingCoordinators && item.schedulingCoordinators.length > 0) {
      state.selectedSchedulingCoordinator = item.schedulingCoordinators[0].id;
      state.scToResources = item.schedulingCoordinators;
    }
  },
  changeSchedulingCoordinatorMutation(state, item) {
    state.selectedSchedulingCoordinator = item;
    state.selectedResource = null;
  },
  changeResourceMutation(state, item) {
    state.selectedResource = item;
  },
  setStatusesMutation(state, item) {
    state.statuses = item.summaries;
    state.isSearched = true;
  },
  multipleSelectionMutation(state, val) {
    state.multipleSelection = val;
  },
  setProposedStatusesMutation(state, item) {
    state.proposedStatuses = item.summaries;
  },
  setSelectedResourceType(state, item) {
    if (state.selectedResourceType != item) {
      state.selectedResource = null;
    }
    state.selectedResourceType = item;
  },
  setHours(state, item) {
    state.hours = item;
    state.endHour = item.length;
  },
  setIsSearched(state) {
    state.isSearched = true;
  },
  setShowOpenHours(state) {
    state.showOpenHours = !state.showOpenHours;
  },
  setData(state, item) {
    state.tableData[item.rowIndex][item.prop] = item.value;
    state.tableData[item.rowIndex].isDirty = true;
    if (state.tableData.highlight) {
      state.tableData[item.rowIndex].highlight.push(item.prop);
    } else {
      state.tableData[item.rowIndex].highlight = [];
      state.tableData[item.rowIndex].highlight.push(item.prop);
    }

    // Force re-render on data change
    const td = helpers.cloneDeep(state.tableData);
    state.tableData = td;
  },
  setResourceGroups(state, items) {
    state.resourceGroups = items;
  },
  setSelectedResourceGroup(state, item) {
    state.selectedResourceGroup = item;
  },
  setFilteredResources(state) {
    state.filteredResources = findResources(state.bscToResources, state.scToResources, state.selectedBSC, state.selectedSchedulingCoordinator, state.selectedResourceType, state.resourceGroups, state.selectedResourceGroup);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};