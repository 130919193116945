import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { TOOLS_API } from '@/api';

const state = {
  parentTableData: [],
  childTableData: [],
  defaultTransStates: 'Downloading, UnZipping, Waiting, Importing, Processing, Queued',
  defaultSourceSystemList: '',
  selectedParentId: '',
  selectedProcessState: '',
  prevRow: null,
};

const actions = {
  async fetchDefaultSourceSystemList({ commit }) {
    try {
      const { data } = await TOOLS_API.get('/server-queue-parent/source-system');
      commit('setDefaultSourceSystemList', data);
    } catch (error) {
      vue.$notify('Failed to fetch Source Systems', 'error');
      console.error(error);
    }
  },
  async fetchCurrentQueue({ commit }) {
    const date = moment().toISOString();
    const start = new Date('1900-01-01T12:00:00-06:30');
    const params = {
      Ba: null,
      StartDate: start,
      EndDate: date,
      DataItem: state.defaultSourceSystemList,
      TransState: state.defaultTransStates,
      IsProcessDate: true,
    };

    const response = await TOOLS_API.get('/server-queue-parent', { params });
    response.data.forEach((obj) => {
      obj.processDate = moment(obj.processDate).utc().format('MM/DD/YYYY');
      obj.tzStartTime = moment(obj.tzStartTime).utc().format('MM/DD/YYYY HH:mm:ss');
    });
    commit('setChildTableData', []);
    commit('setParentTableData', response.data);
  },
  async fetchParamQueue({ commit }, parameters) {
    const coordinatorList = parameters.coordinatorList.filter(
      (x) => parameters.coordinatorListSelected?.includes(x.value),
    );

    let sourceSystemList = [];
    if (parameters.sourceSystemListSelected.length > 0) {
      sourceSystemList = parameters.sourceSystemListSelected.map((source) => source).join(',');
    } else {
      sourceSystemList = state.defaultSourceSystemList;
    }

    let processStatusList = [];
    if (parameters.processStatusListSelected.length > 0) {
      processStatusList = parameters.processStatusListSelected.map((processStatus) => processStatus).join(',');
    } else {
      processStatusList = parameters.processStatusList.map(({ value }) => value).join(',');
    }

    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeListSelected);
    const params = {
      scs: coordinatorList.filter((x) => x.entityType === 'sc').map((a) => a.value).join(','),
      eimEntities: coordinatorList.filter((x) => x.entityType === 'eim_entity').map((a) => a.value).join(','),
      StartDate: startDate,
      EndDate: endDate,
      DataItem: sourceSystemList,
      TransState: processStatusList,
      IsProcessDate: parameters.processDateTypeListSelected,
    };
    const { data } = await TOOLS_API.get('/server-queue-parent', { params });

    data.forEach((obj) => {
      obj.processDate = moment(obj.processDate).utc().format('MM/DD/YYYY');
      obj.tzStartTime = moment(obj.tzStartTime).utc().format('MM/DD/YYYY HH:mm:ss');
      if (obj.tzEndTime !== null) obj.tzEndTime = moment(obj.tzEndTime).utc().format('MM/DD/YYYY HH:mm:ss');
    });
    commit('setChildTableData', []);
    commit('setParentTableData', data);
  },
  async fetchChildData({ commit, state }, value) {
    commit('setSelectedParentId', value.parentId);
    commit('setSelectedProcessState', value.processState);
    const params = {
      parentId: state.selectedParentId,
    };
    const { data } = await TOOLS_API.get('/server-queue-child', { params });

    data.forEach((obj) => {
      obj.tradeDate = moment(obj.tradeDate).utc().format('MM/DD/YYYY');
      obj.tzStartTime = moment(obj.tzStartTime).utc().format('MM/DD/YYYY HH:mm:ss');
      if (obj.tzEndTime !== null) obj.tzEndTime = moment(obj.tzEndTime).utc().format('MM/DD/YYYY HH:mm:ss');
    });

    commit('setChildTableData', data);
  },
  async deleteTableRow({ dispatch, commit, state }) {
    if (state.selectedProcessState === 'Processing' || state.selectedProcessState === 'Queued') {
      const { data } = await TOOLS_API.delete(`server-queue-parent/${state.selectedParentId}`);
      commit('deleteSelectedRow', data.data);
    }
  },
  highlightRow({ commit, state }, selected) {
    const { prevRow } = this.state;
    if (prevRow) {
      prevRow.forEach((cell) => {
        cell.cellElement.style.backgroundColor = '#FFCCCB';
      });
    }
    if (selected.row.cells[0].data.processState.toUpperCase() === 'ERRORED') {
      this.state.prevRow = selected.row.cells;
      selected.row.cells.forEach((cell) => {
        cell.cellElement.style.backgroundColor = '#FF6666';
      });
    } else {
      this.state.prevRow = null;
    }
  },
};

const mutations = {
  setParentTableData(state, value) {
    state.parentTableData = value;
  },
  setSelectedParentId(state, parentId) {
    state.selectedParentId = parentId;
  },
  setSelectedProcessState(state, processState) {
    state.selectedProcessState = processState;
  },
  setChildTableData(state, value) {
    state.childTableData = value;
  },
  deleteSelectedRow(state, value) {
    state.parentTableData = state.parentTableData.filter(({ parentId }) => parentId !== value[0].parentId);
    state.childTableData = [];
  },
  setDefaultSourceSystemList(state, value) {
    state.defaultSourceSystemList = value.map(({ sourceSystem }) => (sourceSystem)).join(',');
  },
  reset(state) {
    state.parentTableData = [];
    state.childTableData = [];
    state.selectedParentId = '';
    state.selectedProcessState = '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};