const spp: any = {
  menu: /^spp:*/,
  scheduling: {
    menu: /spp:schd:*/,
    submission: 'spp:schd:resource_offer_set',
  },
  settlements: {
    menu: /spp:stlmt:*/,
    calendar: 'spp:stlmt:calendar',
    invoice: 'spp:stlmt:invoice',
    statement: 'spp:stlmt:statement',
    determinant: 'spp:stlmt:determinant',
    meter: {
      menu: /spp:stlmt:meter:*/,
      calibrationReport: 'spp:stlmt:meter:calibration_report',
      interchangeReport: 'spp:stlmt:meter:interchange_report',
      meterReportCard: 'spp:stlmt:meter:meter_report_card',
    },
  },
  marketData: {
    menu: /spp:mkt:*/,
    lmp: 'spp:mkt:lmp_summary_pricing',
    mcp: 'spp:mkt:mcp_summary_pricing',
    resourceForecast: 'spp:mkt:resource_forecast_set',
    daClearedOffers: 'spp:mkt:da_cleared_offers',
    daClearedBids: 'spp:mkt:da_cleared_bids',
    rtDispatch: 'spp:mkt:rt_dispatch',
    demand: {
      menu: /spp:mkt:demand:*/,
      clearedFixedBidSet: 'spp:mkt:demand:cleared_fixed_bidset',
      clearedPriceSensitiveBidSet: 'spp:mkt:demand:cleared_price_sensitive_bidset',
      fixedBidSet: 'spp:mkt:demand:fixed_bidset',
      priceSensitiveBidSet: 'spp:mkt:demand:price_sensitive_bidset',
    },
    energy: {
      menu: /spp:mkt:energy:*/,
      cirInterestSet: 'spp:mkt:energy:cir_interest_set',
      clearedOfferSet: 'spp:mkt:energy:cleared_offer_set',
      clearedTransactionSet: 'spp:mkt:energy:cleared_transaction_set',
      commitmentHistorySet: 'spp:mkt:energy:commitment_history_set',
      commitmentStatusConflictSet: 'spp:mkt:energy:commitment_status_conflict_set',
      commitmentSet: 'spp:mkt:energy:commitment_set',
      dispatchSet: 'spp:mkt:energy:dispatch_set',
      emergencyLimit: 'spp:mkt:energy:emergency_limit',
      forecastedCommitmentHistorySet: 'spp:mkt:energy:forecasted_commitment_history_set',
      forecastedCommitmentSet: 'spp:mkt:energy:forecasted_commitment_set',
      mcrGroupMitigatedParameterSet: 'spp:mkt:energy:mcr_group_mitigated_parameter_set',
      mcrGroupOfferSet: 'spp:mkt:energy:mcr_group_offer_set',
      mcrTransitionMitigatedOfferSet: 'spp:mkt:energy:mcr_transition_mitigated_offer_set',
      mcrTransitionMitigatedParameterSet: 'spp:mkt:energy:mcr_transition_mitigated_parameter_set',
      mcrTransitionOfferSet: 'spp:mkt:energy:mcr_transition_offer_set',
      mitigatedParameterSet: 'spp:mkt:energy:mitigated_parameter_set',
      mitigatedResourceOfferSet: 'spp:mkt:energy:mitigated_resource_offer_set',
      offerVerificationStatusSet: 'spp:mkt:energy:offer_verification_status_set',
      overrideSet: 'spp:mkt:energy:override_set',
      qualificationStatusSet: 'spp:mkt:energy:qualification_status_set',
      resourceForecastSet: 'spp:mkt:energy:resource_forecast_set',
      resourceOfferSet: 'spp:mkt:energy:resource_offer_set',
    },
    reserve: {
      menu: /spp:mkt:reserve:*/,
      capSet: 'spp:mkt:reserve:cap_set',
      clearedOfferSet: 'spp:mkt:reserve:cleared_offer_set',
      crDeploymentSet: 'spp:mkt:reserve:cr_deployment_set',
      dispatchSet: 'spp:mkt:reserve:dispatch_set',
      mitigatedParameterSet: 'spp:mkt:reserve:mitigated_parameter_set',
      mitigatedResourceOfferSet: 'spp:mkt:reserve:mitigated_resource_offer_set',
      obligationSet: 'spp:mkt:reserve:obligation_set',
      resourceOfferSet: 'spp:mkt:reserve:resource_offer_set',
      systemRequirementSet: 'spp:mkt:reserve:system_requirement_set',
      regulationStatus: 'spp:mkt:reserve:regulation_status',
    },
    virtual: {
      menu: /spp:mkt:virtual:*/,
      clearedBidSet: 'spp:mkt:virtual:cleared_bid_set',
      clearedOfferSet: 'spp:mkt:virtual:cleared_offer_set',
      offerSet: 'spp:mkt:virtual:offer_set',
      bidSet: 'spp:mkt:virtual:bid_set',
    },
  },
  scheduling: {
    menu: /spp:scheduling:*/,
    resourceOfferSet: 'spp:scheduling:resource_offer_set',
  },
  reporting: 'spp:reporting:spp_reports',
};

export default spp;