export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADING DATE',
    dataType: 'string',
    dataField: 'tradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'string',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'string',
    dataField: 'tradingInterval',
    area: 'row',
    expanded: true,
  }, {
    caption: 'REPORT',
    dataType: 'string',
    dataField: 'report',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'COMPONENT',
    dataType: 'string',
    dataField: 'component',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PRICE NODE ID',
    dataType: 'string',
    dataField: 'pricE_NODE_ID',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EDITION',
    dataType: 'string',
    dataField: 'edition',
    area: 'column',
    expanded: true,
  }, {
    caption: 'INTERVAL PRICE',
    dataType: 'number',
    dataField: 'intervalPrice',
    area: 'data',
    format: '#,##0.##',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'DAILY AVG PRICE',
    dataType: 'number',
    format: '#,##0.##',
    dataField: 'dailyAvgPrice',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'DAILY AVG OFF PEAK PRICE',
    dataType: 'number',
    format: '#,##0.##',
    dataField: 'dailyAvgOffPeakPrice',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'DAILY AVG ON PEAK PRICE',
    dataType: 'number',
    format: '#,##0.##',
    dataField: 'dailyAvgOnPeakPrice',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'WEEKLY AVG PRICE',
    dataType: 'number',
    format: '#,##0.##',
    dataField: 'weeklyAvgPrice',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'HOURLY AVG PRICE',
    dataType: 'number',
    format: '#,##0.##',
    dataField: 'hourlyAvgPrice',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }],
};