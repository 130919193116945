<template>
  <div v-show="visibleFlag" class="pscs-notification" @click.self="toggleNotification">
    <aside class="pscs-notification-container">
      <div class="handler" />
      <div class="panel panel-default">
        <div v-if="developmentFlag" class="panel-heading">
          <pscs-notification-development />
        </div>
        <div class="panel-body pscs-notification__actions">
          <pscs-field-dx>
            <pscs-button-dx
              v-if="messageCount > 0"
              text="Clear All"
              type="danger"
              styling-mode="outlined"
              @clicked="clear" />
            <pscs-button-dx
              :icon="soundButtonIcon"
              styling-mode="outlined"
              :hint="soundHint"
              @clicked="toggleSound" />
          </pscs-field-dx>
        </div>
        <dx-list
          :data-source="messages"
          :selection-mode="selectionMode"
          no-data-text="No notifications">
          <template #item="{ data: item }">
            <pscs-notification-item :item="item" />
          </template>
        </dx-list>
      </div>
    </aside>
    <pscs-notification-error-dialog />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DxList from 'devextreme-vue/ui/list';
import PscsNotificationItem from './PscsNotificationItem';
import PscsNotificationErrorDialog from './PscsNotificationErrorDialog';
import PscsNotificationDevelopment from './PscsNoficationDevelopment';

export default {
  name: 'PscsNotification',
  components: {
    DxList,
    PscsNotificationItem,
    PscsNotificationErrorDialog,
    PscsNotificationDevelopment,
  },
  data: () => ({
    mouseDownListener: null,
    mouseUpListener: null,
    mouseMoveListener: null,
    isHandlerDragging: false,
    selectionMode: 'none',
  }),
  computed: {
    ...mapGetters({
      developmentFlag: 'notification/getDevelopmentFlag',
      visibleFlag: 'notification/getVisibleFlag',
      messages: 'notification/getMessages',
      messageCount: 'notification/getMessageCount',
      soundDisabled: 'notification/getSoundDisabled',
    }),
    soundButtonIcon() {
      if (this.soundDisabled) return 'fas fa-volume-mute';
      return 'fas fa-volume-up';
    },
    soundHint() {
      if (this.soundDisabled) return 'Sound is disabled';
      return 'Sound is enabled';
    },
  },
  mounted() {
    const handler = document.querySelector('.handler');
    const container = document.querySelector('.pscs-notification-container');
    const panel = container.querySelector('.panel-default');

    this.mouseDownListener = (e) => {
      // If mousedown event is fired from .handler, toggle flag to true
      this.isHandlerDragging = e.target === handler;
    };
    this.mouseMoveListener = (e) => {
      // Don't do anything if dragging flag is false
      if (this.isHandlerDragging) {
        const MIN_WIDTH = 150;
        const MAX_WIDTH = 700;
        let notifWidth = window.innerWidth - e.clientX;
        if (notifWidth < MIN_WIDTH) notifWidth = MIN_WIDTH;
        if (notifWidth > MAX_WIDTH) notifWidth = MAX_WIDTH;

        container.style.width = `${notifWidth}px`;
      }
    };
    this.mouseUpListener = (e) => {
      // Turn off dragging flag when user mouse is up
      this.isHandlerDragging = false;
    };
    document.addEventListener('mousedown', this.mouseDownListener);
    document.addEventListener('mousemove', this.mouseMoveListener);
    document.addEventListener('mouseup', this.mouseUpListener);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.mouseDownListener);
    document.removeEventListener('mousemove', this.mouseMoveListener);
    document.removeEventListener('mouseup', this.mouseUpListener);
  },
  methods: {
    toggleNotification() {
      if (!this.isHandlerDragging) this.toggleVisibility();
    },
    ...mapActions({
      close: 'notification/hide',
      clear: 'notification/clear',
      addRandom: 'notification/addRandom',
      addWorkflow: 'notification/addWorkflow',
      toggleVisibility: 'notification/toggleVisibility',
      toggleSound: 'notification/toggleSound',
    }),
  },
};
</script>

<style lang="scss">
.dx-list:not(.dx-list-select-decorator-enabled) {
  .dx-list-item.dx-state-focused,
  .dx-list-item.dx-state-active {
    color: #333 !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
}

.pscs-notification {
  position: absolute;
  top: 35px;
  z-index: 1500;
  width: 100%;
  height: calc(100% - 36px);
  overflow: hidden;

  .pscs-notification__actions {
    padding: 5px;
  }

  .pscs-notification-container {
    position: absolute;
    right: 0;
    display: flex;
    width: 300px;
    height: 100%;
    margin-top: 1px;
    overflow-y: scroll;
    background-color: white;

    .handler {
      flex: 0 0 auto;
      width: 5px;
      height: 100%;
      padding: 0;
      cursor: ew-resize;
      background-color: silver;

      &::before {
        display: block;
        width: 5px;
        margin: 0 auto;
        content: '';
      }
    }

    .panel {
      flex: 1 1 auto;
      margin: 0;
      color: black;
      border-radius: 0;
    }
  }
}

</style>
