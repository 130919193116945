import moment from 'moment';
import {
  systemStatusColors, scheduleVariantColors, verifySubmitActionBase,
} from '@/components/Scheduling/utils';


export const EMPTY_HOURLY_VALUE = '---';

export const EXECUTIONS_CONFIG = {
  tableName: 'ExecutionsTable',
  columns: [
    {
      label: 'Request ID',
      prop: 'requestId',
      width: '100',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
      groupIndex: 0,
      sortOrder: 'desc',
    },{
      label: 'Execution ID',
      prop: 'id',
      width: '110',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
      sortOrder: 'desc',
    },{
      label: 'Module',
      prop: 'module',
      width: '100',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
      calculateDisplayValue: (e) => e.module === 'sibrSchedules' ? 'SIBR' : 'IST',
    }, {
      label: 'Location',
      prop: 'location',
      width: '200',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
      cssClass: 'link',
      // calculateDisplayValue: (e) => e.location || e.output?.location || e.output?.tradeSchedules?.[0]?.location,
    }, {
      label: 'Sub Type',
      prop: 'locationSubType',
      width: '200',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
    }, {
      label: 'Type',
      prop: 'locationType',
      width: '100',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
    }, {
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      label: 'Status',
      prop: 'status',
      width: '100',
      editorOptions: scheduleVariantColors,
      class: true,
      filterable: true,
      sortable: true,
    }, {
      label: 'Strategies',
      prop: 'strategies',
      // width: '500',
      sortable: true,
      filterable: true,
      alignment: 'left',
      allowFixing: false,
    }, {
      label: 'Created By',
      prop: 'createdBy',
      filterable: true,
      sortable: true,
      visible: true,
      width: 120,
      alignment: 'left',
    }, {
      label: 'Created Date',
      prop: 'createdDate',
      filterable: true,
      sortable: true,
      visible: true,
      dataType: 'date',
      format: 'yyyy-MM-dd',
      width: 120,
      alignment: 'left',
    // }, {
    //   label: 'Updated By',
    //   prop: 'updatedBy',
    //   filterable: true,
    //   sortable: true,
    //   visible: true,
    //   width: 120,
    //   alignment: 'left',
    // }, {
    //   label: 'Updated Date',
    //   prop: 'updatedDate',
    //   filterable: true,
    //   sortable: true,
    //   visible: true,
    //   dataType: 'date',
    //   format: 'yyyy-MM-dd',
    //   width: 120,
    //   alignment: 'left',
    // // }, {
    //   label: 'Schedules',
    //   prop: 'output',
    //   width: '50%',
    //   //dataType: 'string',
    //   sortable: true,
    //   filterable: true,
    //   alignment: 'left',
    //   allowFixing: false,
    //   calculateDisplayValue: (e) => JSON.stringify(e.output || 'N/A', null, 2),
    }],
    options: {
      filterHeader: true,
      multipleSelection: true,
      allowSelectAll: true,
  },
};
