import { CISO_CMRI_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';
import convergenceBidAwardsConfigs from './convergenceBidAwardsConfigs';

const state = {
  tableData: [],
  tableConfig: convergenceBidAwardsConfigs.fields,
};

const actions = {
  async fetchConvergenceBidAwards({ commit }, parameters) {
    const params = {
      scs: parameters.coordinatorListSelected,
      effectiveDate: parameters.tradeDateRangeSelected[0].toISOString(),
      terminationDate: parameters.tradeDateRangeSelected[1].toISOString(),
    };
    try {
      const { data } = await CISO_CMRI_API.get('convergence-bid-awards', { params });
      data.forEach((obj) => {
        obj.tradingDate = getMomentDateString(obj.tradingDate);
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load Virtual Bid Awards', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, tableData) {
    state.tableData = Object.freeze(tableData);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};