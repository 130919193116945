import { gridConfig } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  config: {
    columns: [{
      label: 'DISPUTE #',
      prop: 'disputeId',
      filterable: true,
      sortable: true,
      visible: true,
    }, {
      label: 'DISPUTE CHILD ID',
      prop: 'disputeChildId',
      filterable: false,
      sortable: false,
      visible: false,
    }, {
      label: 'Attachments',
      prop: 'hasAttachments',
      filterable: true,
      sortable: true,
    }, {
      label: 'BA',
      prop: 'ba',
      filterable: true,
      sortable: true,
      visible: true,
      width: 75,
    }, {
      label: 'SC',
      prop: 'sc',
      filterable: true,
      sortable: true,
      visible: false,
      width: 75,
    }, {
      label: 'TRADING DATE',
      prop: 'tradingDate',
      format: 'yyyy-MM-dd',
      filterable: true,
      sortable: true,
      visible: true,
      width: 125,
    }, {
      label: 'TRADING DATE GMT',
      prop: 'tzTradingDate',
      format: 'yyyy-MM-dd',
      filterable: true,
      sortable: true,
      visible: false,
    }, {
      label: 'CAISO AMOUNT',
      prop: 'caisoAmount',
      filterable: true,
      sortable: true,
      visible: true,
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
      width: 150,
    },
    {
      label: 'CALC AMOUNT',
      prop: 'calcAmount',
      filterable: true,
      sortable: true,
      visible: true,
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
      width: 150,
    }, {
      label: 'DISPUTE AMOUNT',
      prop: 'disputeAmount',
      filterable: true,
      sortable: true,
      visible: true,
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
      width: 150,
    }, {
      label: 'CAISO APPROVED AMOUNT',
      prop: 'caisoApprovedAmount',
      filterable: true,
      sortable: true,
      visible: true,
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
      width: 175,
    }, {
      label: 'AWARD DATE',
      prop: 'awardDate',
      filterable: true,
      sortable: true,
      visible: true,
      width: 150,
    }, {
      label: 'DISPUTE STATUS',
      prop: 'disputeStatus',
      filterable: true,
      sortable: true,
      visible: true,
      // width: 125,
    }, {
      label: 'CASE REASON',
      prop: 'caseReason',
      filterable: true,
      sortable: true,
      visible: true,
      width: 125,
    }, {
      label: 'ORIGINATING GROUP',
      prop: 'originatingGroup',
      filterable: true,
      sortable: true,
      visible: true,
      width: 175,
    }, {
      label: 'ISO DISPUTE #',
      prop: 'isodisputeID',
      filterable: true,
      sortable: true,
      visible: true,
      width: 125,
    }, {
      label: 'PUBLISHED DATE',
      prop: 'publishedDate',
      filterable: true,
      sortable: true,
      visible: true,
      width: 150,
    }, {
      label: 'ADJUSTMENT PUBLICATION DATE',
      prop: 'adjustmentPublicationDate',
      filterable: true,
      sortable: true,
      visible: true,
      width: 215,
    }, {
      label: 'CREATED BY',
      prop: 'createdBy',
      filterable: true,
      sortable: true,
      visible: true,
      width: 100,
    }],
    summary: {
      caisoAmountSummary: {
        prop: 'CAISO AMOUNT',
        summaryType: 'sum',
        format(value) {
          if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return formatter.format(value);
        },
      },
      calcAmountSummary: {
        prop: 'CALC AMOUNT',
        summaryType: 'sum',
        format(value) {
          if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return formatter.format(value);
        },
      },
      disputeAmountSummary: {
        prop: 'DISPUTE AMOUNT',
        summaryType: 'sum',
        format(value) {
          if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return formatter.format(value);
        },
      },
      caisoApprovedAmountSummary: {
        prop: 'CAISO APPROVED AMOUNT',
        summaryType: 'sum',
        format(value) {
          if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
          const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          });
          return formatter.format(value);
        },
      },
    },
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      showSelectFilter: false,
    },
  },
  detailConfig: {
    fields: [{
      caption: 'DISPUTE #',
      dataField: 'disputeId',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'ISO DISPUTE #',
      dataField: 'isodisputeId',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'BA',
      dataField: 'ba',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'FILE TYPE',
      dataField: 'fileType',
      area: 'column',
      expanded: true,
      visible: true,
    }, {
      caption: 'TRADING DATE',
      dataField: 'tradingDate',
      area: 'row',
      expanded: true,
      dataType: 'datetime',
    }, {
      caption: 'TRADING DATE GMT',
      dataField: 'tzTradingDate',
      format: 'yyyy-MM-dd',
      area: 'filter',
      expanded: true,
      visible: false,
    }, {
      caption: 'TRADING HOUR',
      dataField: 'tradingHour',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'TRADING INTERVAL',
      dataField: 'tradingInterval',
      area: 'row',
      expanded: true,
      visible: true,
    }, {
      caption: 'CHARGE CODE',
      dataField: 'chargeCode',
      area: 'filter',
      expanded: true,
      visible: true,
    }, {
      caption: 'RSRC ID',
      dataField: 'rsrcId',
      area: 'filter',
      expanded: true,
      visible: true,
    }, {
      caption: 'RSRC TYPE',
      dataField: 'rsrcType',
      area: 'filter',
      expanded: true,
      visible: true,
    }, {
      caption: 'AMOUNT',
      dataField: 'amount',
      area: 'data',
      dataType: 'number',
      summaryType: 'sum',
      format: gridConfig.valFormat,
      expanded: true,
    }],
  },
};