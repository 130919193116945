import moment from 'moment';
import { getMomentDateString } from '@/utils/dateUtil';
import dateStore from '@/utils/dateStore';
import { TOOLS_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchReportStatus({ commit }, args) {
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(args.tradeDateRangeSelected, tz);

    const params = {
      SCs: args.coordinatorListSelected ? args.coordinatorListSelected.join(',') : null,
      startDate: momentRange[0].toISOString(),
      endDate: momentRange[1].toISOString(),
      statusType: args.statusTypeSelected,
    };
    try {
      const { data } = await TOOLS_API.get('/report-status', { params });
      data.forEach((obj) => {
        obj.tzReportStartDate = getMomentDateString(obj.tzReportStartDate);
        obj.tzReportEndDate = getMomentDateString(obj.tzReportEndDate);
        obj.tzStartTime = moment(obj.tzStartTime).utc().format('YYYY-MM-DD HH:mm:ss A');
        obj.tzEndTime = moment(obj.tzEndTime).utc().format('YYYY-MM-DD HH:mm:ss A');
      });
      const completed = data.filter((obj) => obj.status === 'Completed');
      const errored = data.filter((obj) => obj.status === 'Errored');
      errored.forEach((obj) => { obj.tzEndTime = null; });
      const processing = data.filter((obj) => obj.status === 'Processing');

      if (params.statusType === 'All') commit('setTableData', data);
      if (params.statusType === 'Completed') commit('setTableData', completed);
      if (params.statusType === 'Errored') commit('setTableData', errored);
      if (params.statusType === 'Processing') commit('setTableData', processing);
    } catch {
      this.$notify('Failed to Load Report Status', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};