import outages from './Outages/outagesStore';
import availability from './Availability/omsAvailabilityStore';
import supplyPlan from './RA/raSupplyPlanStore';

export default {
  namespaced: true,
  modules: {
    outages,
    availability,
    supplyPlan,
  },
};