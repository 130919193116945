import { CISO_ADS_API } from '@/api';
import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  async fetchDynamicEtsr({ state, commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      SCs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      DivideMW: (parameters.averageHourlyValueSelected === 'MWH'),
    };

    try {
      const { data } = await CISO_ADS_API.get('/ads-dynamic-etsr', { params });
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.tradingDate = getMomentDateString(obj.tradingDate);
          if (obj.processTime != null) obj.processTime = moment(obj.processTime).utc().format('YYYY-MM-DD HH:mm:ss');
        });
      }
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to load Dynamic ETSR Comparison Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};