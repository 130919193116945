import { clone } from '@/utils/dataUtil';
import helpers from '@/utils/helpers';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, editable: false,
    }, {
      prop: 'name', label: 'Name', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'type', label: 'Type', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'subType', label: 'Sub Type', sortable: true, visible: true, editable: true, filterable: true, 
    }, {
      prop: 'order', label: 'Order', sortable: true, visible: true, editable: true, filterable: true, 
    }, {
      prop: 'activeFlag', label: 'Active Flag', sortable: true, visible: false, editable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, visible: true, editable: false, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime', sortable: true, visible: true, editable: false, filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, visible: true, editable: false, filterable: true, 
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'datetime', sortable: true, visible: true, editable: false, filterable: true, 
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    namespaced: null,
    description: null,
    type: null,
    subType: null,
    order: -1,
    activeFlag: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  dialogMode: '',
};

const getters = {};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/variant');
      const sortedList = helpers.orderBy(data.data, ['type', 'subType', 'order']);
      commit('loadTableData', sortedList);
    } catch (error) {
      this.$notify('Failed to Load Variant', 'error');
    }
  },
  async addVariant({ commit, dispatch }, variant) {
    try {
      const { data } = await STRUCTURES_API.post('/variant', variant);
      dispatch('loadTableData');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Variant Added', 'success');
    } catch (error) {
      this.$notify('Failed to Add Variant', 'error');
    }
  },
  async updateVariant({ commit, dispatch }, variant) {
    try {
      const { data } = await STRUCTURES_API.put(`/variant/${variant.id}`, variant);
      dispatch('loadTableData');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Updated Variant', 'success');
    } catch (error) {
      this.$notify('Failed to Update Variant', 'error');
    }
  },
  async inactivateVariant({ dispatch, commit }, id) {
    try {
      await STRUCTURES_API.delete(`/variant/${id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Variant Removed', 'success');
    } catch (error) {
      this.$notify('Failed to Remove Variant', 'error');
    }
  },
  currentTableRowChange({ dispatch, commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  loadTableData(state, variant) {
    state.tableData = variant;
  },
  setTableData(state, updatedTableData) {
    state.tableData = updatedTableData;
  },
  addToTableData(state, data) {
    state.tableData.push(data);
  },
  updateTableData(state, data) {
    const index = state.tableData.findIndex(({ id }) => id === data.id);
    if (index !== -1) state.tableData.splice(index, 1, data);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((variant) => variant.id !== state.currentTableRow.id);
  },
  reset(state) {
    state.currentTableRow = null;
    state.tableData = [];
  },
  setDialogMode(state, mode) {
    state.dialogMode = mode;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};