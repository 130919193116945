import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_SOURCE_API } from '@/api';
import { tradeDateRange, ppas } from '@/components/PPA/BaseStore/ppasBase';
import { sortBy } from '@/utils/dataUtil';
import config from './ppaDeterminantDataConfigs';

const state = {
  contracts: [],
  meters: [],
  tableData: [],
  fileData: [],
  tabNames: [],
  tabData: [],
  importTypeList: [],
  tableConfig: config.fields,
  searchFiltersConfig: {
    tradeDateRange,
    ppas,
    bds: {
      label: 'BDs',
      value: [],
      type: 'tagbox',
      showDropDownButton: true,
      visible: true,
      searchable: true,
      options: [],
      async fetchData() {
        try {
          const { data: { data } } = await PPA_SOURCE_API.get('/billing-determinant/names');
          data.forEach((d) => {
            d.value = d.determinantName;
            d.label = d.determinantName;
          });
          data.sort(sortBy('label'));
          return data;
        } catch (error) {
          vue.$notify('Failed to Load BD Names', 'error');
          console.log(error);
        }
        return [{}];
      },
    },
  },
};

const getters = {
  getImportTypeList: (state) => state.importTypeList.map(({ name }) => ({ value: name, label: name })),
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('fetchImportTypes');
  },

  async fetchImportTypes({ commit }) {
    try {
      const { data: { data } } = await PPA_SOURCE_API.get('/billing-determinant/import-types');
      commit('loadImportTypeList', data);
    } catch (err) {
      console.error(err);
    }
  },

  async fetchPPADeterminantData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      ppas: parameters.ppasSelected,
      bds: parameters.bdsSelected,
      startDate,
      endDate,
    };
    try {
      const { data: { data } } = await PPA_SOURCE_API.post('/billing-determinant/get', params);

      data.forEach((sourceData) => {
        sourceData.tradingDate = moment(sourceData.tradingDate).utc().format('MM/DD/YYYY');
      });

      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load generic PPA determinant Data', 'error');
    }
  },
  async importPPADeterminantData({ state }) {
    const importBDData = { Data: state.tabData };
    try {
      const { data } = await PPA_SOURCE_API.post('/billing-determinant/upload', importBDData);
      if (data?.successful) {
        state.tabData = [];
        this.$notify('Source Billing Determinant Data Imported', 'success');
      } else {
        this.$notify(data?.message || 'Failed to Import Source Billing Determinant Data', 'error');
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Import Source Billing Determinant Data', 'error');
    }
  },
  async parseFileData({ commit, state }, importBDData) {
    try {
      const { data } = await PPA_SOURCE_API.post('/billing-determinant/parse', importBDData);
      commit('setFileData', data.data.tabs);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Parse BD File', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setFileData(state, value) {
    state.fileData = value.reduce((p, c) => {
      const propName = c.tabName.replace(/\s/g, '');
      p[propName] = c.data;
      return p;
    }, {});
    state.tabNames = value.map((v) => ({ value: v.tabName.replace(/\s/g, ''), label: v.tabName }));
  },
  setTabData(state, value) {
    state.tabData = Object.freeze(value);
  },
  loadImportTypeList(state, importTypes) {
    state.importTypeList = importTypes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};