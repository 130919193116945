const etrm: any = {
  menu: /etrm:*/,
  trades: 'etrm:trades',
  trades_sync: 'etrm:trades:sync',
  delete_trades: 'etrm:delete_trades',
  update_delivery: 'etrm:trades:update_delivery',
  
  tradeConfirm: 'etrm:trades:confirm',
  tradeUnconfirm: 'etrm:trades:unconfirm',
  tradeScheduleUnconfirmed: 'etrm:trades:schedule_unconfirmed',
  
  tradeTemplates: 'etrm:trade_templates',
  configuration: {
    menu: /etrm:configuration:*/,
    customViews: 'etrm:configuration:custom_views',
    tradeGroups: 'etrm:configuration:trade_groups',
    tradeTermGroups: 'etrm:configuration:trade_term_groups',
    creditLimits: 'etrm:configuration:credit_limits',
  },
  mtm: {
    menu: /etrm:mtm:*/,
    results: 'etrm:mtm:results',
  },
  credit: {
    menu: /etrm:credit:*/,
    availableCredit: 'etrm:credit:available_credit',
    transactionLog: 'etrm:credit:transaction_log',
  },
  tradeValidations: /etrm:trade_validations/,
  invoices: 'etrm:invoices',
  reporting: 'etrm:etrm_reports',
};

export default etrm;