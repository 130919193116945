import qs from 'qs';
import Vue from 'vue';
import { createMutations } from '@/utils/vuexHelper';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { STRUCTURES_API, IDENTITY_API } from '@/api';
import { HEColumns, HERows, clone } from '@/utils/dataUtil';
import { getZoneName } from '@/utils/dateUtil';

import moment from 'moment';

const cellDisabled = (hour, field) => {
  const now = moment().utc();

  const interval = parseInt(field.replace('e', ''), 10);
  const date = now.clone().format('YYYY-MM-DD');
  const day = moment(hour).utc().startOf('day');

  const dayDiff = moment(now.clone()).diff(moment(day).utc(), 'days');
  const currentDay = moment(date).utc().isSame(day, 'day');

  const gmtTime = moment(hour).utc();

  if (currentDay) {
    const currentHour = Math.floor(moment.duration(now.clone().diff(now.clone().startOf('day'))).asHours());
    const currentMinute = now.clone().minute();
    const currentInterval = currentMinute - (currentMinute % 5);

    if (((60 * gmtTime.hour()) + interval) <= ((60 * currentHour) + currentInterval + 10)) return true;

    return false;
  }
  if (dayDiff <= 0) return false;
  if (dayDiff > 0) return true;
  return true;
};

const validate = (limitType, value, limitValue, correspondingLimitValue) => {
  if (limitType === 'min') {
    if (value < limitValue) {
      return `Min value is less than min limit: ${limitValue}`;
    }
    if (correspondingLimitValue || correspondingLimitValue === 0) {
      if (value > correspondingLimitValue) {
        return `Min value is greater than corresponding max value: ${correspondingLimitValue}`;
      }
    }
  } else {
    if (value > limitValue) {
      return `Max value is greater than max limit: ${limitValue}`;
    }
    if (correspondingLimitValue || correspondingLimitValue === 0) {
      if (value < correspondingLimitValue) {
        return `Max value is less than corresponding min value: ${correspondingLimitValue}`;
      }
    }
  }
  return undefined;
};

// data intervals must start at the top of the hour
const mapData = (data, date) => {
  const hoursMap = {};

  let currentHour = { prop: undefined };
  return data.reduce((acc, interval) => {
    const {
      startTime,
      locationName,
      entityName,
      defaultMinValue,
      effectiveMinValue,
      actualMinValue,
      defaultMaxValue,
      effectiveMaxValue,
      actualMaxValue,
      note,
      createdBy,
      createdDate,
    } = interval;

    const mdt = moment(startTime);
    const day = mdt.format('YYYY-MM-DD');
    const minute = mdt.get('minute');
    const startTimeHour = `${mdt.add(mdt.minute() * -1, 'minutes').toISOString().split('.')[0]}Z`;

    let hours = hoursMap[day];
    if (!hours) {
      hours = HERows({}, true, day);
    }

    currentHour = hours.find((x) => x.startTime === startTime) || currentHour;
    const he = currentHour.prop?.toUpperCase();
    if (minute === 0) {
      acc.push({
        sc: entityName,
        location: locationName,
        date: day,
        hour: currentHour.startTime,
        he,
        limit: 'MIN',
        e00Note: note,
        e00: effectiveMinValue,
        d00: defaultMinValue,
        a00: actualMinValue,
        max: {
          e00: effectiveMaxValue,
          d00: defaultMaxValue,
          a00: actualMaxValue,
        },
        createdBy,
        createdDate,
        edits: [],
        validationErrors: {},
        type: 'resource-availability',
      });
      acc.push({
        sc: entityName,
        location: locationName,
        date: day,
        hour: currentHour.startTime,
        he,
        limit: 'MAX',
        e00Note: note,
        e00: effectiveMaxValue,
        d00: defaultMaxValue,
        a00: actualMaxValue,
        min: {
          e00: effectiveMinValue,
          d00: defaultMinValue,
          a00: actualMinValue,
        },
        createdBy,
        createdDate,
        edits: [],
        validationErrors: {},
        type: 'resource-availability',
      });
    } else {
      const fill = minute.toString().length === 1;
      const eKey = `e${fill ? '0' : ''}${minute}`;
      const eNoteKey = `e${fill ? '0' : ''}${minute}Note`;
      const dKey = `d${fill ? '0' : ''}${minute}`;
      const aKey = `a${fill ? '0' : ''}${minute}`;
      const minInterval = acc.find((i) => i.sc === entityName
      && i.hour === startTimeHour
      && i.location === locationName
      && i.limit === 'MIN');
      const maxInterval = acc.find((i) => i.sc === entityName
      && i.hour === startTimeHour
      && i.location === locationName
      && i.limit === 'MAX');
      if (minInterval && maxInterval) {
        const max = {
          ...minInterval.max,
          [eKey]: effectiveMaxValue,
          [dKey]: defaultMaxValue,
          [aKey]: actualMaxValue,
        };

        const min = {
          ...maxInterval.min,
          [eKey]: effectiveMinValue,
          [dKey]: defaultMinValue,
          [aKey]: actualMinValue,
        };

        minInterval[eNoteKey] = note;
        minInterval[eKey] = effectiveMinValue;
        minInterval[dKey] = defaultMinValue;
        minInterval[aKey] = actualMinValue;
        minInterval.max = max;

        maxInterval[eNoteKey] = note;
        maxInterval[eKey] = effectiveMaxValue;
        maxInterval[dKey] = defaultMaxValue;
        maxInterval[aKey] = actualMaxValue;
        maxInterval.min = min;
      }
    }

    return acc;
  }, []);
};

const validationRules = [{
  type: 'custom',
  message: 'Value Invalid',
  validationCallback: (event) => {
    if (event.data && Object.keys(event.data).length) {
      const limitType = event.data.limit;
      const limitValue = event.data[event.column.dataField.replace('e', 'd')];
      // const colIdx = event.validator.element().ariaColIndex;
      if (limitType === 'MIN') {
        const max = event.data.max[event.column.dataField];
        if (event.value < limitValue) {
          event.validator._validationRules[0].message = `Min value is less than min limit: ${limitValue}`;
          return false;
        }
        if (max || max === 0) {
          if (event.value > max) {
            event.validator._validationRules[0].message = `Min value is greater than corresponding max value: ${max}`;
            return false;
          }
        }
      } else {
        const min = event.data.min[event.column.dataField];
        if (event.value > limitValue) {
          event.validator._validationRules[0].message = `Max value is greater than max limit: ${limitValue}`;
          return false;
        }
        if (min || min === 0) {
          if (event.value < min) {
            event.validator._validationRules[0].message = `Max value is less than corresponding min value: ${min}`;
            return false;
          }
        }
      }
    }
    return true;
  },
}];

const state = {
  rData: [],
  dataEdited: false,
  dataCache: [],
  disabledCache: {},
  locationGroupLocations: [],
  scs: [],
  selectedLocationGroupId: undefined,
  selectedDate: dateStore.getDefaultDate().toISOString(),
  selectedSc: undefined,
  tableData: [],
  filteredTableData: [],
  isCurrentDay: undefined,
  showAllHoursFlag: false,
  validationCache: [],
  tableConfig: {
    columns: [{
      label: 'SC',
      prop: 'sc',
      alignment: 'left',
      filter: true,
      editable: false,
      dataType: 'string',
    }, {
      label: 'Location',
      prop: 'location',
      alignment: 'left',
      filter: true,
      editable: false,
      dataType: 'string',
    }, {
      label: 'Date',
      prop: 'date',
      alignment: 'left',
      filter: true,
      editable: false,
      dataType: 'string',
    }, {
      label: 'HE',
      prop: 'he',
      alignment: 'left',
      filter: true,
      editable: false,
      dataType: 'string',
    }, {
      label: 'Limit',
      prop: 'limit',
      alignment: 'left',
      filter: true,
      editable: false,
      dataType: 'string',
    }, {
      label: '0:00',
      prop: 'e00',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:05',
      prop: 'e05',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:10',
      prop: 'e10',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:15',
      prop: 'e15',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:20',
      prop: 'e20',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:25',
      prop: 'e25',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:30',
      prop: 'e30',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:35',
      prop: 'e35',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:40',
      prop: 'e40',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:45',
      prop: 'e45',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:50',
      prop: 'e50',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }, {
      label: '0:55',
      prop: 'e55',
      // alignment: 'left',
      dataType: 'number',
      validationRules,
      precision: 3,
      editable: true,
    }],
    options: {
      filterHeader: true,
      summaryRows: true,
      cellSelection: true,
    },
    // customValidation: (params) => true,
    customCellClassRules: {
      invalid: (event) => {
        const fields = ['sc', 'location', 'date', 'he', 'limit'];
        if (fields.includes(event.colDef.field)) return false;

        if (event.data && Object.keys(event.data).length) {
          if (event.data.edits.length && event.data.edits.includes(event.colDef.field)) {
            const limitType = event.data.limit.toLowerCase();
            const limitValue = event.data[event.colDef.field.replace('e', 'd')];
            const { value } = event;
            const correspondingLimitType = limitType === 'max' ? 'min' : 'max';
            const correspondingLimitValue = event.data[correspondingLimitType][event.colDef.field];

            const result = validate(limitType, value, limitValue, correspondingLimitValue);
            const validationKey = `${limitType}${event.colDef.field.replace('e', '')}`;

            if (result) {
              event.data.validationErrors[validationKey] = result;
              return true;
            }
            return false;
          }
        }
        return false;
      },
      edited(params) {
        const fields = ['sc', 'location', 'date', 'he', 'limit'];
        if (fields.includes(params.colDef.field)) return false;
        if (params.data.edits.length && params.data.edits.includes(params.colDef.field)) { return true; }
        return false;
      },
      submitted(params) {
        const actualValueField = params.colDef.field.replace('e', 'a');
        const valueField = params.colDef.field;
        const actualValue = params.data[actualValueField];
        const value = params.data[valueField];

        return params.data[`${valueField}Note`] === ' Processed with out any errors'
            // eslint-disable-next-line eqeqeq
            && actualValue == value;
      },
      hideText(params) {
        const fields = ['sc', 'location', 'date', 'he', 'limit'];
        if (!fields.includes(params.colDef.field)) return false;

        const previousRow = params.api.getDisplayedRowAtIndex(params.rowIndex - 1);
        return previousRow && previousRow.data[params.colDef.field] === params.value;
      },
      closed(params) {
        const fields = ['sc', 'location', 'date', 'he', 'limit'];
        if (fields.includes(params.colDef.field)) return false;
        params.colDef.cellEditor = null;
        const cachedVal = state.disabledCache[`${params.data.hour}-${params.colDef.field}`];
        if (cachedVal !== undefined) { return cachedVal; }
        const newVal = cellDisabled(params.data.hour, params.colDef.field);
        state.disabledCache[`${params.data.hour}-${params.colDef.field}`] = newVal;
        return newVal;
      },
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
  changes: (state) => state.showAllHoursFlag
    ? state.tableData.filter((x) => x.edits.length)
    : state.filteredTableData.filter((x) => x.edits.length),
};

const actions = {
  async initialize({ state, dispatch }) {
    await dispatch('fetchScs');
    await dispatch('fetchReferenceData');
    dispatch('defaultUserSettings');
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (userLocationGroup) {
      commit('setSelectedLocationGroupId', userLocationGroup.id);
      dispatch('fetchLocationGroupLocations', userLocationGroup.id);
    }

    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    if (!state.selectedSc) commit('setSelectedSc', [selectedSc]);
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'],
      market: 'CAISO',
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  async fetchLocationGroupLocations({ commit }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    const lgl = Array.isArray(data.locationGroupLocations)
      ? data.locationGroupLocations.filter((location) => location.marketName === 'CAISO')
      : [];
    commit('setLocationGroupLocations', lgl);
  },
  async fetchData({ state, commit }) {
    try {
      // Convert the selected date time to UTC.
      // The selected date time is a JS date in the clients local time
      const utc_SelectedDate = typeof (state.selectedDate) === 'string'
        ? moment(state.selectedDate)
        : dateStore.toMomentFromJSDate(state.selectedDate);

      // Format the start and end time to send in the request for data from the API
      const startTime = utc_SelectedDate;

      // If the current day is selected, the data should be filtered by now in UTC, to now + 6 hours.
      const is_curr_date = moment().startOf('day').isSame(utc_SelectedDate, 'day');
      commit('setIsCurrentDay', is_curr_date);
      let curr_hour = moment().hour();
      if (utc_SelectedDate.isDST()) curr_hour--;
      const hour_adder = is_curr_date ? curr_hour : 0;

      const filterStartTime = utc_SelectedDate.clone().add(hour_adder, 'hours');
      const filterEndTime = is_curr_date ? utc_SelectedDate.clone().add(hour_adder + 6, 'hours')
        : utc_SelectedDate.clone().add(1, 'day');

      const endTime = moment.max([utc_SelectedDate.clone().add(1, 'day'), filterEndTime]);

      const params = {
        market: 'CAISO',
        commodity: 'POWER',
        entityType: 'SC',
        entityName: state.selectedSc,
        locationTypes: ['GEN', 'TG'],
        locationNames: state.locationGroupLocations.map(({ shortName }) => shortName),
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
      };

      const { data } = await STRUCTURES_API.get('/resource-availability', {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      const dayData = data.filter((d) => moment(d.startTime).isBetween(startTime,
        startTime.clone().add(1, 'day'), null, '[)'));
      commit('setRData', data);
      const tableData = mapData(dayData, state.selectedDate);
      commit('setTableData', tableData);

      const filteredData = data.filter((d) => moment(d.startTime)
        .isBetween(filterStartTime, filterEndTime, null, '[)'));

      const filteredTableData = mapData(filteredData, state.selectedDate);
      commit('setFilteredTableData', filteredTableData);
    } catch (e) {
      commit('setFilteredTableData', []);
      console.error(e);
    }
    commit('setValidationCache', []);
  },

  async saveBatchData({
    state, getters, dispatch, commit,
  }) {
    const payload = [];

    getters.changes.forEach((change) => {
      change.edits.forEach((update) => {
        if (cellDisabled(change.hour, update)) return;
        const minutes = parseInt(update.replace('e', ''), 10);
        const hours = HEColumns({}, null, state.selectedDate);
        const time = hours.find((x) => change.he.toLowerCase() === x.prop)
          .time
          .add(minutes, 'minutes')
          .toISOString()
          .replace('00.000Z', '00Z');
        const vKey = change.limit === 'MIN' ? 'effectiveMinValue' : 'effectiveMaxValue';
        const value = change[update];
        const existing = payload.find((d) => d.startTime === time
          && d.locationName === change.location
          && d.entityName === change.sc,
        );
        if (existing) {
          const idx = payload.indexOf(existing);
          payload[idx][vKey] = value;
        } else {
          const data = state.rData.find((d) => d.startTime === time
          && d.locationName === change.location
          && d.entityName === change.sc,
          );
          if (data) payload.push(({ ...data, [vKey]: value }));
        }
      });
    });

    try {
      await STRUCTURES_API.post('/resource-availability', payload);
      dispatch('fetchData');
      commit('setDataEdited', false);
      return ({ type: 'success', msg: 'Save success' });
    } catch (e) {
      console.error('error saving data', e);
      return ({ type: 'error', msg: 'Error saving data' });
    }
  },
  cellUpdated({ state, commit, dispatch }, item) {
    commit('setAgData', item);
  },
  clearChanges({ state, commit }) {
    if (state.dataEdited) {
      commit('setTableData', state.dataCache.unfiltered);
      commit('setFilteredTableData', state.dataCache.filtered);
      commit('setDataEdited', false);
      commit('setValidationCache', []);
    }
  },
};

const mutations = {
  setAgData(state, item) {
    const {
      sc, location, date, he,
    } = item.data;

    if ((item.row[item.prop] === '') && !item.value) return;
    if (!state.dataEdited) {
      state.dataCache = { filtered: clone(state.filteredTableData), unfiltered: clone(state.tableData) };
      state.dataEdited = true;
    }
    if (item.value || item.value === 0) {
      item.value = parseFloat(parseFloat(item.value).toFixed(3));
    } else {
      item.value = null;
    }

    const tableData = state.showAllHoursFlag ? state.tableData : state.filteredTableData;
    const rowData = tableData.find((x) => {
      if (!item.data) return false;
      return x.he === item.data.he
        && x.limit === item.data.limit
        && x.location === item.data.location;
    });
    const correspondingLimit = item.data.limit === 'MAX' ? 'MIN' : 'MAX';
    const correspondingLimitData = tableData.find((x) => {
      if (!item.data) return false;
      return x.he === item.data.he
        && x.limit === correspondingLimit
        && x.location === item.data.location;
    });
    const limitCache = correspondingLimitData[item.data.limit.toLowerCase()];

    const limitType = item.data.limit.toLowerCase();
    const { value } = item;
    const limitValue = item.data[item.prop.replace('e', 'd')];
    const correspondingLimitType = limitType === 'max' ? 'min' : 'max';
    const correspondingLimitValue = item.data[correspondingLimitType][item.prop];

    // validate and update validation cache
    const result = validate(limitType, value, limitValue, correspondingLimitValue);
    const key = `${sc}-${location}-${date}-${he}-${limitType}`;
    const errorKeyIdx = state.validationCache.indexOf(key);
    if (!result && (errorKeyIdx > -1)) { state.validationCache.splice(errorKeyIdx, 1); }
    if (result && errorKeyIdx === -1) { state.validationCache.push(key); }

    Vue.set(limitCache, item.prop, item.value);
    Vue.set(rowData, item.prop, item.value);
    Vue.set(rowData, 'edits', [...rowData.edits, item.prop]);
  },
  ...createMutations(
    'rData',
    'disabledCache',
    'tableData',
    'filteredTableData',
    'scs',
    'selectedSc',
    'isCurrentDay',
    'selectedDate',
    'locationGroupLocations',
    'selectedLocationGroupId',
    'dataEdited',
    'showAllHoursFlag',
    'validationCache',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  mutations,
  actions,
};