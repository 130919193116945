
import Vue from 'vue';
import store from '../../store';
import router from "../../router";

Vue.component('base-schedule-route-cell-ag', {
    router,
    template:`<router-link :to="{ name: 'BaseScheduleDetail', params: {scheduleName: name}}">{{value}}</router-link>`,
    computed: {
        name () {      
            let row = this.params.data;
            if(row.scheduleName)     
                return row.scheduleName;
            else {
                let dt = row.tradeDate.substring(0, 4) + row.tradeDate.substring(5, 7) + row.tradeDate.substring(8, 10);
                if (row.configuration){
                    return `${row.bsc}-${row.sc}-${row.marketType}-${dt}-${row.resource}-${row.configuration}*` ;
                }
                else{
                    return `${row.bsc}-${row.sc}-${row.marketType}-${dt}-${row.resource}*` ;
                }
            }
        },
        value () {
            return this.params.data.resource;
        }
    }
});


