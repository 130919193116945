
export default {
  namespaced: true,

  state: {
    ready: false,
    content: '',
  },
  getters: {
    getContent: (state) => state.content,
  },
  actions: {
    setClipboard({ commit }, item) {
      commit('setClipboard', item);
    },
    getClipboard(state) {
      return state.content;
    },
  },
  mutations: {
    setClipboard(state, item) {
      if (item != null) {
        state.ready = item.ready;
        state.content = item.content;
      } else {
        state.ready = false;
        state.content = '';
      }
    },
  },
};