import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { PPA_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  ppaList: [],
  chargeCodeList: [],
  nullTableRow: {
    id: -999,
    ppaName: null,
    ppaId: null,
    chargeCode: null,
    chargeCodeId: null,
    effectiveDate: null,
    terminationDate: null,
    manualFlag: false,
    glAccountCode: null,
    glDescription: null,
    fercAccount: null,
    fercClass: null,
    profitCenter: null,
    costCenter: null,
    orderNumber: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getPPAList: (state) => state.ppaList.map(({ id, name }) => ({ value: id, label: name })),
  getChargeList: (state) => state.chargeCodeList.map(({ id, chargeCode }) => ({ value: id, label: chargeCode })),
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchPPAList');
    dispatch('fetchChargeCodeList');
  },
  async fetchPPAChargeRef({ commit }, parameters = {}) {
    const params = {
      ppas: parameters.ppasSelected,
      chargeCodes: parameters.chargeCodesSelected.join(','),
    };
    try {
      const { data: { data } } = await PPA_REF_API.post('/ppa-charge-ref/get', params);
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.manualFlag = obj.manualFlag === null ? false : obj.manualFlag;
          obj.effectiveDate = getMomentDateString(obj.effectiveDate);
          obj.terminationDate = obj.terminationDate === null ? null : getMomentDateString(obj.terminationDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load PPA Charge Ref Data', 'error');
    }
  },
  fetchPPAList({ commit }) {
    PPA_REF_API.get('/ppa').then(({ data }) => {
      commit('loadPPAList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  fetchChargeCodeList({ commit }) {
    PPA_REF_API.get('/charge-codes').then(({ data }) => {
      commit('loadChargeCodeList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  postRecord({ state }, record) {
    // eslint-disable-next-line prefer-destructuring
    record.effectiveDate = record.effectiveDate === null ? null : getMomentDateString(record.effectiveDate);
    record.terminationDate = record.terminationDate === null ? null : getMomentDateString(record.terminationDate);
    PPA_REF_API.post('/ppa-charge-ref', record).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = data.terminationDate === null ? null : getMomentDateString(data.terminationDate);
      data.createdDate = getMomentDateString(data.createdDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  updateRecord({ dispatch, commit, state }, record) {
    record.effectiveDate = record.effectiveDate === null ? null : getMomentDateString(record.effectiveDate);
    record.terminationDate = record.terminationDate === null ? null : getMomentDateString(record.terminationDate);
    PPA_REF_API.put(`/ppa-charge-ref/${record.id}`, record).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = data.terminationDate === null ? null : getMomentDateString(data.terminationDate);
      data.createdDate = getMomentDateString(data.createdDate);
      data.updatedDate = data.updatedDate === null ? null : getMomentDateString(data.updatedDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    PPA_REF_API.delete(`/ppa-charge-ref/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadPPAList(state, ppas) {
    state.ppaList = ppas;
  },
  loadChargeCodeList(state, chargeCodes) {
    state.chargeCodeList = chargeCodes;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  setManualFlag(state, item) {
    console.log(item);
    state.manualFlag = item;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};