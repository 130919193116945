import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import {
  resourcesTagbox, resourceType, tradeDateRange, coordinatorList, caisoStatementFileTypes, caisoStatementPublications, caisoStatementFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { CISO_METER_API, CISO_SAMC_API } from '@/api';
import { DxTextBox } from 'devextreme-vue';

const config = {
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'tradingdate',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradinghour',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradinginterval',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RSRC_ID',
    dataField: 'rsrc_id',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'METER TYPE',
    dataField: 'meterType',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RSRC_TYPE',
    dataField: 'rsrc_type',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'STATEMENT METER',
    dataField: 'statementMeter',
    dataType: 'number',
    format: '#,##0.000',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'INTERNAL METER',
    dataField: 'internalMeter',
    dataType: 'number',
    format: '#,##0.000',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'DIFF METER',
    dataField: 'diffMeter',
    dataType: 'number',
    format: '#,##0.000',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }],
};

const state = {
  tableData: [],
  tableConfig: config.fields,
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: true },
    resourceList: { ...resourcesTagbox, maxLimit: 30 },
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    meterSource: {
      label: 'Meter Source',
      value: 'MRIS',
      type: 'select',
      clearable: true,
      required: true,
      async fetchData() {
        try {
          const { data: { meterSourceSystem } } = await CISO_METER_API.get('/meter/source-systems');
          return meterSourceSystem.map(({ sourceSystem }) => ({ value: sourceSystem, label: sourceSystem }));
        } catch (error) {
          vue.$notify('Failed to load Meter System Types', 'error');
          console.log(error);
        }
        return {};
      },
      options: [],
      watchers: [{
        propertyToWatch: 'meterSystemTypeOptions',
        handler(newValue, oldValue) {
          const mappedValues = newValue.map((x) => ({ label: x.system, value: x.system }));
          this.$store.commit('ciso/setParams', ['systemTypes', mappedValues]);
          this.$store.commit('ciso/setParams', ['systemTypesSelected', mappedValues !== null ? mappedValues[0].value : null]);
          return true;
        },
      }, {
        propertyToWatch: 'systemTypesSelected',
        handler(newValue, oldValue) {
          this.config.resourceList.required = (newValue !== 'MRIS' && this.params.resourceTypeSelected !== 'ALL');
          return true;
        },
      }],
    },
    variation: {
      label: 'Threshold',
      value: 1,
      type: 'select',
      options: [0.01, 0.1, 1],
      required: true,
    },
  },
};

const actions = {
  async fetchMeterStmtCompareData({ commit }, parameters = {}) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      fileType: parameters.fileTypesSelected,
      publication: parameters.publicationsSelected,
      variation: parameters.variationSelected,
      meterSource: parameters.meterSourceSelected,
      resource: parameters.resourceListSelected ? parameters.resourceListSelected.join() : '',
      version: parameters.fileVersionsSelected,
      scs: parameters.coordinatorListSelected,
    };

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/meter-stmt-compare', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.tradingDate = getMomentDateString(x.tradingDate);
        });
        commit('setTableData', data);
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Meter Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};