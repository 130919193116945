import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';
import { cloneDeep, removeObjProps } from '@/utils/dataUtil';
import cellColors from '../util';
import lastActionTypeColors from '../lastActionTypeColors';

const state = {
  tableConfig: {
    tableName: 'requestSummary',
    columns: [
      {
        prop: 'tagID',
        label: 'TAG ID',
        filterable: true,
        sortable: true,
        cellTemplate: 'PscsRouteCellTemplate',
        editorOptions: {
          to: 'ViewTag',
          params: [{ key: 'id', dataKey: 'id' }, { key: 'tagId', dataKey: 'tagID' }, { key: 'entity', dataKey: 'toEntityName' }],
          query: {    
            handler(data) {
              return {tagSummaryRef: data.id};
            },
          },
        },
      },
      {
        prop: 'requestID', label: 'REQ. ID', filterable: true, sortable: true,
      },
      {
        prop: 'toEntityName', label: 'TO ENTITY', filterable: true, sortable: true,
      },
      {
        prop: 'fromEntityName', label: 'FROM ENTITY', filterable: true, sortable: true,
      },
      {
        prop: 'modifiedStartTime', label: 'START', filterable: true, sortable: true,
      },
      {
        prop: 'modifiedEndTime', label: 'END', filterable: true, sortable: true,
      },
      {
        alignment: 'center',
        cellTemplate: 'PscsColoredCellTemplate',
        filterable: true,
        editorOptions: { ...cellColors },
        label: 'STATUS',
        prop: 'requestStatus',
        sortable: true,
      },
      {
        prop: 'messageType',
        label: 'MSG. TYPE',
        filterable: true,
        sortable: true,
        alignment: 'center',
        cellTemplate: 'PscsColoredCellTemplate',
        editorOptions: { ...lastActionTypeColors },
      },
      {
        prop: 'timeClassification', label: 'CLASSIFICATION', filterable: true, sortable: true,
      },
      {
        prop: 'requestTimeStamp', label: 'REQ. TIME', sortable: true, sortOrder: 'desc',
      },
      {
        prop: 'resolutionTimeStamp', label: 'RES. TIME', sortable: true,
      },
      {
        prop: 'diffMWChange',
        label: 'REQ. MW',
        sortable: false,
        cellTemplate: 'PscsIncDecCellTemplate',
      },
      {
        prop: 'notes',
        label: 'Notes',
        sortable: false,
      },
      {
        alignment: 'center',
        cellTemplate: 'PscsTagApprovalCell',
        filterable: false,
        fixed: 'right',
        label: 'Actions',
      },
    ],
    options: {
      exportExcel: true,
      columnsMovable: true,
      filterRow: true,
      filterPanel: false,
      filterHeader: true,
    },
  },
  tableData: [],
  datePropsAndFormats: [
    { name: 'requestTimeStamp', format: 'YYYY-MM-DD HH:mm:ss' },
    { name: 'resolutionTimeStamp', format: 'YYYY-MM-DD HH:mm:ss' },
    { name: 'modifiedStartTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'modifiedEndTime', format: 'YYYY-MM-DD HH:mm' },
  ],
};

const actions = {
  loadSummary({ commit }, item) {
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, tz);

    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();

    const params = {
      timeZone: tz,
      start: start.format(),
      end: end.format(),
      inc_request: true,
    };

    const props = [
      'requestTimeStamp',
      'modifiedStartTime',
      'modifiedEndTime',
      'startDate',
      'endDate',
    ];

    return new Promise((resolve, reject) => {
      ETAG_API.get('/tags/requests', { params })
        .then(({ data }) => {
          dateStore.formatDateTimeFields(data.requests, state.datePropsAndFormats);

          const clonedData = data.requests.map((row) => JSON.stringify(removeObjProps(cloneDeep(row), ...props)));
          const clonedTableData = state.tableData.map((row) => JSON.stringify(removeObjProps(cloneDeep(row), ...props)));
          const isNotEquals = clonedData.length !== clonedTableData.length || clonedData.some((obj, idx) => obj !== clonedTableData[idx]);
          if (isNotEquals) commit('loadSummary', data.requests);
          resolve();
        }).catch((err) => {
          reject(err);
        });
    });
  },
  clear({ commit }) { commit('clear'); },
};

const mutations = {
  loadSummary(state, data) {
    state.tableData = data;
  },
  clear(state) { state.tableData = []; },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};