import helpers from '@/utils/helpers';
import { SCRIPTING_API } from '@/api';

import scriptStore from './index.js';

const state = {
  scriptName: '',
  scriptDescripiton: '',
  activeFlag: true,
  visible: false,
};
const getters = {
  getScriptEditorDialogVisibility: (state) => state.visible,
  getScriptName: (state) => state.scriptName,
  getScriptDescription: (state) => state.scriptDescripiton,
  getActiveFlag: (state) => state.activeFlag,
};

const actions = {
  changeDialogVisibility({ commit }, item) {
    commit('setDialogVisibility', item);
  },
  changeHeaderInformation({ state, dispatch }) {
    // console.log(scriptStore);

    if (state.scriptName !== '') { scriptStore.actions.changeScriptNameAction(state.scriptName); }

    if (state.scriptDescripiton !== '') { scriptStore.actions.changeScriptDescriptionAction(state.scriptDescripiton); }

    scriptStore.actions.saveScriptName();
    // scriptStore.dispatch('saveScriptName');

    state.visible = false;
  },
  // changeHeaderInformation(dispatch, scriptEditorState) {
  //   console.log(scriptEditorState);
  //   const scriptToSave = scriptEditorState.scripts.find((s) => s.name === scriptEditorState.selectedScript);
  //   scriptToSave.name = state.scriptName;
  //   scriptToSave.description = state.scriptDescripiton;

  //   SCRIPTING_API.put(`${scriptToSave.id}`, scriptToSave)
  //     .then((response) => {
  //       dispatch('changeDialogVisibility');

  //       // commit('setScriptName', response.data.name);
  //       // commit('setScriptDescription', response.data.description);

  //       scriptEditorState.selectedScript = response.data.name;

  //       // if (typeof item !== 'undefined') { item.callback({ type: 'success', title: 'Success Updating Script' }); }
  //     })
  //     .catch((err) => {
  //       console.log(err); // item.callback({ type: 'error', title: 'Failure Updating Script' });
  //     });
  // },
  addNewScript(dispatch, scriptEditorState) {
    SCRIPTING_API.post({})
      .then((response) => {
        scriptEditorState.scripts.push(response.data);
        scriptEditorState.scripts = helpers.sortBy(scriptEditorState.scripts, [function (o) { return o.name; }]);
        dispatch('changeDialogVisibility');
        dispatch('changeScriptAction', response.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  changeScriptName({ commit }, item) {
    commit('setDialogScriptName', item);
  },
  changeScriptDescription({ commit }, item) {
    commit('setDialogScriptDescription', item);
  },
};

const mutations = {
  setDialogVisibility(state, item) {
    state.visible = item === null ? item : !state.visible;
  },
  setDialogScriptName(state, name) {
    state.scriptName = name;
  },
  setDialogScriptDescription(state, desc) {
    state.scriptDescripiton = desc;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};