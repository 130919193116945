import moment from 'moment';
import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API, TOOLS_API } from '@/api';

const state = {
  tableData: [],
  selectedRows: [],
  selectedRunType: 'Shadow',
  rowsStatus: {
    invalidRows: [],
    validRows: [],
  },
  headers: [],
  errorMessages: [],
  errorMessageVisible: false,
};

const actions = {
  async fetchBatchData({ commit }, args) {
    const params = {
      scs: args.coordinatorListSelected ? args.coordinatorListSelected.join(',') : null,
      startDate: args.tradeDateRangeSelected[0].toISOString(),
      endDate: args.tradeDateRangeSelected[1].toISOString(),
      dateType: args.dateTypeSelected,
      meterSourceSystem: args.meterSourceSystemSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/shadow-batch', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.flowDate = getMomentDateString(x.flowDate);
          x.publishDate = getMomentDateString(x.publishDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify(`Failed to load ${ args.runTypeSelected } Batch Data`, 'error');
    }
  },
  async fetchHeaders({ commit }, args) {
    try {
      const params = {
        startDate: args.tradeDateRangeSelected[0].toISOString(),
        endDate: args.tradeDateRangeSelected[1].toISOString(),
        dataSources: 'SaMC,Drmt',
        dateType: args.dateTypeSelected,
      };
      const { data: { data } } = await CISO_SAMC_API.get('/statements/headers', {
        params,
      });

      data.forEach((d, idx) => {
        d.value = idx;
      });

      commit('setHeaders', data);
    } catch (error) {
      vue.$notify('Failed to Load Statement Headers', 'error');
      console.error(error);
    }
    return [];
  },
  async runShadowBatch({ state, commit }, records) {
    const batch = records.map((record) => ({
      calculationType: (() => {
        const str = record.fileType.split('_')[0];
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      })(), // need calcType in pascal case because middleware parses as an enum, exact case needs to match
      runType: state.selectedRunType,
      fileType: record.fileType,
      settlementCycle: record.settlementCycle,
      meterSourceSystem: record.meterSourceSystem,
      coordinators: [record.sc],
      entities: [record.sc],
      skipValidations: record.skipValidations,
      startDate: moment(record.flowDate).toISOString(),
      endDate: moment(record.flowDate).toISOString(),
    }));

    const endPoint = state.selectedRunType === 'Shadow' ? 'ciso-shadow-calc' : 'samc-calc';

    try {
      const { data: { valid } } = await TOOLS_API.post(`/legacy-process/batch/${endPoint}`, { batch });

      if (valid) this.$notify('Successfully initiated calculations', 'success');
    } catch (err) {
      console.error(err);
      const messages = err.response?.data?.messages;
      if (messages) {
        commit('setErrorMessages', messages);
      } else {
        this.$notify('Failed to initiate calculations. If the issue persists, please contact PSCS support.',
          'error');
      }
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
    state.selectedRows = [];
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
  setSelectedRunType(state, value) {
    state.selectedRunType = value;
  },
  setRowsStatus(state, value) {
    state.rowsStatus.invalidRows = value.invalidRows;
    state.rowsStatus.validRows = value.validRows;
  },
  setErrorMessages(state, value) {
    state.errorMessages = [...new Set(value)];
    state.errorMessageVisible = true;
  },
  setErrorMessagesVisible(state, value) {
    state.errorMessageVisible = value;
  },
  setHeaders(state, value) {
    state.headers = value;
  },
  reset(state) {
    state.selectedRows = [];
    state.tableData = [];
    state.rowsStatus.invalidRows = [];
    state.rowsStatus.validRows = [];
    state.headers = [];
    state.errorMessages = [];
    state.errorMessageVisible = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};