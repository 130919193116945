import moment from 'moment';
import cellColors from '../store/cellColors';

const config = {
  columns: [{
    label: 'Job Id',
    prop: 'jobId',
    editable: false,
    alignment: 'left',
    width: '61px',
    sortable: true,
    filterable: true,
    // calculateCellValue: (e) => e.timeDefinition?.startTimeDefinition,
  }, {
    label: 'Mkt',
    prop: 'market',
    editable: false,
    width: '65px',
    alignment: 'left',
    sortable: true,
    filterable: true,

  }, {
    label: 'Sys',
    prop: 'system',
    editable: false,
    width: '63px',
    alignment: 'left',
    sortable: true,
    filterable: true,

  }, {
    label: 'Description',
    prop: 'description',
    width: '200px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,

  }, {
    label: 'Entity',
    prop: 'entityName',
    editable: false,
    width: '77px',
    alignment: 'left',
    sortable: true,
    filterable: true,
  },
  {
    label: 'Trade Date',
    prop: 'tradingDate',
    dataType: 'date',
    width: '106px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
    //calculateDisplayValue: (e) => moment(e.tradingDate).utc().format('YYYY-MM-DD'),
  }, {
    label: 'HE',
    prop: 'tradingHour',
    editable: false,
    alignment: 'left',
    width: '56px',
    sortable: true,
    filterable: true,
    calculateDisplayValue: (e) => e.tradingHour ? e.tradingHour : null,
  }, {
    cellTemplate: 'PscsColoredCellTemplate',
    class: true,
    editorOptions: { ...cellColors },
    label: 'Status',
    prop: 'status',
    width: '81px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
  }, {
    label: 'Proc Start',
    prop: 'processStarted',
    editable: false,
    dataType: 'dateTime',
    width: '106px',
    alignment: 'left',
    sortable: true,
    filterable: true,
    calculateDisplayValue: (e) => moment(e.processStarted).utc().format('YYYY-MM-DD HH:mm:ss'),
  }, {
    label: 'Proc End',
    prop: 'processEnded',
    editable: false,
    dataType: 'dateTime',
    width: '106px',
    alignment: 'left',
    sortable: true,
    filterable: true,
    calculateDisplayValue: (e) => e.processEnded ? moment(e.processEnded).utc().format('YYYY-MM-DD HH:mm:ss') : '',
  }, {
    label: 'Elapsed Time',
    prop: 'elapsedTime',
    dataType: 'time',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
    calculateDisplayValue: (e) => e.elapsedTime === '-00:00:00.0000001' ? 0 : e.elapsedTime,
  }, {
    label: 'User',
    prop: 'queuedBy',
    width: '84px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
  }, {
    label: 'Que Date',
    prop: 'queuedDate',
    dataType: 'dateTime',
    width: '137px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
    calculateDisplayValue: (e) => moment(e.queuedDate).utc().format('YYYY-MM-DD HH:mm:ss'),
  }],
  options: {
    filterHeader: true,
  },
};

const statuses = [
  { name: 'Enqueued', description: 'The job has been added to a queue, but it has not yet been processed.' },
  { name: 'Scheduled', description: 'The job has been scheduled to run at a specific time in the future.' },
  { name: 'Processing', description: 'The job is currently being processed by a worker.' },
  { name: 'Succeeded', description: 'The job has been completed successfully.' },
  { name: 'Failed', description: 'The job has failed to complete due to an exception or other error.' },
  { name: 'Deleted', description: 'The job has been removed from the queue before it could be processed.' },
  { name: 'Awaiting State', description: 'The job is waiting for a state change.' },
  { name: 'Awaiting Authorization', description: 'The job is waiting for authorization to be executed.' },
  { name: 'Awaiting Parameters', description: 'The job is waiting for parameters to be provided before it can be executed.' },
  { name: 'Awaiting Creation', description: 'The job is waiting to be created.' },
  { name: 'Retries Exhausted', description: 'The job has failed after all retries have been attempted.' },
  { name: 'Failed State', description: 'The job has failed and is waiting for a state change.' },
  { name: 'Failing State', description: 'The job is failing and is waiting for a state change.' },
  { name: 'Processing State', description: 'The job is processing and is waiting for a state change.' },
  { name: 'Succeeded State', description: 'The job has succeeded and is waiting for a state change.' },
  { name: 'Deleted State', description: 'The job has been deleted and is waiting for a state change.' },
  { name: 'Awaiting Retry', description: 'The job is waiting to be retried after a failure.' },
  { name: 'Scheduled State', description: 'The job has been scheduled and is waiting for a state change.' },
  { name: 'Paused', description: 'The job has been paused and is not currently being processed.' },
  { name: 'Unhandled', description: 'The job has encountered an unhandled exception.' },
  { name: 'Continuations', description: 'The job has one or more continuations scheduled.' },
];

const statusTableConfig = {
  columns: [{
    cellTemplate: 'PscsColoredCellTemplate',
    class: true,
    editorOptions: { ...cellColors },
    label: 'Status',
    prop: 'name',
    width: '180px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
  }, {
    label: 'Description',
    prop: 'description',
    width: '300px',
    editable: false,
    alignment: 'left',
    sortable: true,
    filterable: true,
  }],
};

export default {
  config,
  statuses,
  statusTableConfig,
};