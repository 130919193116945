import contractStore from './Contracts/contractStore';
import contractDetailStore from './ContractDetails/contractDetailsStore';
import contractAttachmentStore from './ContractAttachments/contractAttachmentStore';
import deliverableDetailStore from './DeliverableDetails/deliverableDetailsStore';
import calendarStore from './Calendar/calendarStore';
import reportsStore from './Reports/reportsStore';

export default {
  namespace: true,
  modules: {
    calendarRef: calendarStore,
    contractRef: contractStore,
    contractDetailRef: contractDetailStore,
    contractAttachmentRef: contractAttachmentStore,
    deliverableDetailRef: deliverableDetailStore,
    reportStoreRef: reportsStore,
  },
};