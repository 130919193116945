import { CISO_OASIS_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import configs from './eimDemandForecastConfigurations';

const state = {
  tableData: [],
  tableConfig: configs.fields,
};

const actions = {
  async fetchEIMDemandForecast({ dispatch, commit }, args) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      baa: args.balancingAreaAuthoritySelected,
    };
    try {
      const { data: { eimDemandForecast } } = await CISO_OASIS_API.get('/eim-demand-forecast', { params });
      eimDemandForecast.forEach((element) => {
        element.tradingDate = getMomentDateString(element.tradingDate);
      });
      commit('setTableData', eimDemandForecast);
    } catch {
      this.$notify('Failed to Load EIM Demand Forecast', 'error');
    }
  },
};

const mutations = {
  ...createMutations(
    'dockedConfig',
    'tableConfig',
  ),

  reset(state) {
    state.tableData = [];
    state.isAverageHourlyValues = false;
    state.isRounded = false;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};