import moment from 'moment';
import { CISO_SAMC_API } from '@/api';
import config from './config';
import { clone, cloneDeep, has } from '@/utils/dataUtil';

const state = {
  tableData: [],
  tableConfig: [],
  defaultTableConfig: [],
};

const actions = {
  async fetchChargeCodeAttributes({ dispatch }, params) {
    try {
      const response = await CISO_SAMC_API.get('/charge-code-details/attributes', { params });
      dispatch('createTableConfig', { response, params });
    } catch (error) {
      this.$notify('Failed to load Charge Code Attributes', 'error');
    }
  },
  async fetchChargeCodeDetails({ dispatch, commit }, params) {    
    try {
      const response = await CISO_SAMC_API.get('/charge-code-details', { params });
      dispatch('createTableData', response.data);
    } catch (error) {
      this.$notify('Failed to load Charge Code Details', 'error');
    }    
  },
  async createTableConfig({ commit, state }, { response, params }) {
    const attributeFields = [];
    if (response.data !== undefined) {
      Object.keys(response.data).forEach((k) => {
        const obj = ({
          caption: response.data[k].toUpperCase(),
          dataField: k,
          dataType: '',
          area: 'filter',
          expanded: true,
        });
        attributeFields.push(obj);
      });
    }
    
    const newConfig = [];
    const defaultConfig = []
      .concat(params.stmtImportIdCompare === undefined ? config.stmtConfigData.fields : config.compareConfigData.fields)
      .concat(attributeFields);

    // keep existing attributes
    defaultConfig.forEach((newProp) => {
      const currentProp = state?.tableConfig?.find((p) => p.dataField === newProp.dataField);
      newConfig.push(currentProp || newProp);
    });
      
    commit('setDefaultTableConfig', defaultConfig);
    commit('setTableConfig', newConfig);
  },

  async createTableData({ commit }, data) {
    const flatArray = [];
    const res = data.data.forEach((head) => {
      head.tradingDate = moment.utc(head.tradingDate).format('MM/DD/YYYY');
      head.attributes.forEach((mid) => {
        const dataArray = mid.data === null ? mid.dataCompare : mid.data;
        dataArray.forEach((d) => {
          const newObj = {};
          Object.keys(d).forEach((key) => {
            if (!Array.isArray(d[key])) { newObj[key] = d[key]; }
          });
          Object.keys(mid).forEach((key) => {
            if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
          });
          Object.keys(head).forEach((key) => {
            if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
          });
          flatArray.push(newObj);
        });
      });
    });
    commit('setTableData', flatArray);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    if (value && value.length > 0)
      state.tableConfig = value;
    else
      state.tableConfig = state.defaultTableConfig;
  },
  setDefaultTableConfig(state, value) {
    state.defaultTableConfig = value;
  },
  reset(state) {
    state.tableData = [];
    state.tableConfig = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};