import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_SIBR_API } from '@/api';
import configs from './sibrCBBidsConfig';

const state = {
  bidsHeaderTableData: [],
  selectedHeader: null,
  bidCurvesTableData: [],
  bidErrorsTableData: [],
  sibrBidsConfig: configs,
  selectedIndex: 0,
};

const actions = {
  async fetchBids({ dispatch, commit, state }, inparams) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(inparams.tradeDateRangeSelected);
    const params = {
      scs: inparams.coordinatorListSelected,
      startDate,
      endDate,
      marketType: inparams.marketTypesSelected,
      sourceType: inparams.sourceTypesSelected,
    };

    try {
      const { data } = await CISO_SIBR_API.get('/cb-bids', { params });
      commit('clearSibr');
      if (data) {
        commit('setSibrBidsData', data);
      } else {
        this.$notify('No SIBR Bids Data Found');
      }
    } catch (error) {
      this.$notify('Failed to Load SIBR Bids Data', 'error');
    }
  },
};

const mutations = {
  setSibrBidsData(state, bids) {
    if (bids && bids.data) {
      bids.data.forEach((x) => {
        x.tradingDate = getMomentDateString(x.tradingDate);
        if (x.cbBidCurves) {
          x.cbBidCurves.forEach((y) => {
            y.tradingDate = getMomentDateString(y.tradingDate);
          });
        }
        if (x.cbBidErrors) {
          x.cbBidErrors.forEach((y) => {
            y.startTime = getMomentDateString(y.startTime);
          });
        }
      });
    }
    state.bidsHeaderTableData = bids.data;
  },
  setSelectedHeader(state, {
    cbBidCurves, cbBidErrors,
  }) {
    if (cbBidCurves !== undefined) state.bidCurvesTableData = cbBidCurves || [];
    if (cbBidErrors !== undefined) state.bidErrorsTableData = cbBidErrors || [];
  },
  clearSibr(state) {
    state.bidsHeaderTableData = [];
    state.bidCurvesTableData = [];
    state.bidErrorsTableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};