import ls from 'local-storage';
import { isNull, sortBy, uniqueArrayByObjKey } from '@/utils/dataUtil';

const CaisoStore = function () {
  this.style = 'SC';
  this.styleOptions = ['SC', 'BA ID'];
  this.coordinator = '';
  this.coordinatorId = '';
  this.eimEntity = '';
  this.resourceGroup = null;
  this.marketTypeOptions = [{
    value: 'DAM',
    label: 'DAM',
    executionTypes: [
      { value: 'IFM', label: 'IFM' },
    ],
  }, {
    value: 'RTM',
    label: 'RTM',
    executionTypes: [
      { value: 'HASP', label: 'HASP' },
      { value: 'RTUC', label: 'RTUC' },
      { value: 'RTD', label: 'RTD' },
      { value: 'RTPD', label: 'RTPD' },
    ],
  }];
  this.marketType = 'DAM';
};

CaisoStore.prototype.getDefaults = function () {
  return {
    style: 'SC',
    marketType: 'DAM',
    coordinator: null,
    resourceGroup: null,
    resourceGroupName: null,
  };
};

CaisoStore.prototype.setDefaults = function (model) {
  const settings = isNull(model, this.getDefaults());
  this.style = isNull(settings.style, 'SC');
  this.marketType = isNull(settings.marketType, 'DAM');
  this.coordinator = settings.coordinator;
  this.resourceGroup = settings.resourceGroup;
  this.resourceGroupName = settings.resourceGroupName;
};

/**
 * Gets the valid market types that a user may select
 */
CaisoStore.prototype.getStyleOptions = function () {
  return this.styleOptions;
};

/**
 * Gets the style of the CaisoStore object. either 'SC' or 'BA ID'
 */
CaisoStore.prototype.getStyle = function () {
  return this.style;
};

/**
 * Gets the currently selected scheduling coordinator
 */
CaisoStore.prototype.getCoordinator = function () {
  return this.coordinator;
};

/**
 * Gets the valid market types that a user may select
 */
CaisoStore.prototype.getMarketTypeOptions = function () {
  return this.marketTypeOptions;
};

/**
 * Gets the currently selected market type
 */
CaisoStore.prototype.getMarketType = function () {
  return this.marketType;
};

/**
 * Gets the currently selected resourceGroup
 */
CaisoStore.prototype.getResourceGroup = function () {
  return this.resourceGroup;
};

/**
 * Gets the currently selected resourceGroup's name
 */
CaisoStore.prototype.getResourceGroupName = function () {
  return this.resourceGroupName;
};

CaisoStore.prototype.configureCoordinators = function (coordinatorList) {
  const lst = isNull(coordinatorList, []);
  const style = this.getStyle();
  let data = lst.map((c) => {
    c.value = c.shortName;
    c.label = style === 'SC' ? c.shortName : c.baId;
    if (Array.isArray(c.resources)) {
      c.resources.forEach((resource) => {
        resource.value = resource.shortName;
        resource.label = `${resource.shortName} (${resource.resourceType})`;
      });

      c.resources = uniqueArrayByObjKey(c.resources, 'label');
    }
    return c;
  });
  data = data.sort((a, b) => {
    if (a.shortName < b.shortName) return -1;
    if (a.shortName > b.shortName) return 1;
    return 0;
  });
  return data;
};

CaisoStore.prototype.configureCoordinatorsMeter = function (coordinatorList) {
  const lst = isNull(coordinatorList, []);
  const style = this.getStyle();
  let data = lst.map((c) => {
    c.value = c.shortName;
    c.label = style === 'SC' ? c.shortName : c.baId;
    if (Array.isArray(c.meterResources)) {
      c.meterResources.forEach((resource) => {
        resource.value = resource.rsrcId;
        resource.label = `${resource.rsrcId} (${resource.rsrcType})`;
      });

      c.meterResources = uniqueArrayByObjKey(c.meterResources, 'label');
    }
    return c;
  });
  data = data.sort((a, b) => {
    if (a.shortName < b.shortName) return -1;
    if (a.shortName > b.shortName) return 1;
    return 0;
  });
  return data;
};

CaisoStore.prototype.mapCoordinatorList = function (coordinatorList) {
  const lst = isNull(coordinatorList, []);
  const style = this.getStyle();
  const data = lst.map(({ shortName, baId }) => ({
    value: shortName,
    label: style === 'SC' ? shortName : baId,
  }));
  return data;
};

CaisoStore.prototype.mapEntityCoordinatorList = function (coordinatorList) {
  const lst = isNull(coordinatorList, []);
  const style = this.getStyle();
  const data = lst.map(({ name, code }) => ({
    value: name,
    label: style === 'SC' ? name : code,
  }));
  return data;
};

CaisoStore.prototype.getSchedulingCoordinatorResourcesOptions = function (coordinatorList, coordinatorID) {
  if (!coordinatorID) {
    return [];
  }

  if (Array.isArray(coordinatorID)) {
    return coordinatorList
      .filter((c) => coordinatorID.includes(c.shortName))
      .reduce((acc, { resources }) => ([...acc, ...resources]), [])
      .sort(sortBy('shortName'));
  }

  return coordinatorList
    .filter((c) => c.shortName === coordinatorID)
    .reduce((acc, { resources }) => {
      if (Array.isArray(resources)) return [...acc, ...resources];
      return acc;
    }, [])
    .sort(sortBy('shortName'));
};

CaisoStore.prototype.getSchedulingCoordinatorMeterResourcesOptions = function (coordinatorList, coordinatorID) {
  if (!coordinatorID) {
    return [];
  }

  if (Array.isArray(coordinatorID)) {
    return coordinatorList
      .filter((c) => coordinatorID.includes(c.shortName))
      .reduce((acc, { meterResources }) => ([...acc, ...meterResources]), [])
      .sort(sortBy('shortName'));
  }

  return coordinatorList
    .filter((c) => c.shortName === coordinatorID)
    .reduce((acc, { meterResources }) => {
      if (Array.isArray(meterResources)) return [...acc, ...meterResources];
      return acc;
    }, [])
    .sort(sortBy('shortName'));
};

CaisoStore.prototype.getSchedulingCoordinatorSubCompanyOptions = function (SubCompanyList, coordinatorID) {
  if (!coordinatorID) {
    return [];
  }

  if (Array.isArray(coordinatorID)) {
    return SubCompanyList
      .filter((c) => coordinatorID.includes(c.sc))
      .map(({ subCompany }) => subCompany);
  }

  return SubCompanyList
    .filter((c) => c.sc === coordinatorID)
    .map(({ subCompany }) => subCompany);
};

CaisoStore.prototype.getSchedulingCoordinators = function (coordinatorList, coordinatorID) {
  if (coordinatorID === null || coordinatorID === undefined) return null;
  if (Array.isArray(coordinatorID)) {
    return coordinatorList.filter((c) => coordinatorID.includes(c.id));
  }
  return coordinatorList.filter((c) => c.id === coordinatorID);
};

CaisoStore.prototype.getSchedulingCoordinator = function (coordinatorList, coordinatorID) {
  if (coordinatorID === undefined || coordinatorID === null) return null;
  return coordinatorList.find((c) => c.id === coordinatorID);
};

CaisoStore.prototype.joinSchedulingCoordinatorShortNames = function (coordinatorList) {
  if (coordinatorList === null || coordinatorList === undefined) return null;
  return coordinatorList.map((c) => c.label).join(',');
};

CaisoStore.prototype.getExecutionTypesOptions = function (marketList, marketID) {
  if (marketID === undefined || marketID === null || marketID.length === 0) return [];
  return marketList.find((m) => m.value === marketID).executionTypes;
};

CaisoStore.prototype.getChargeCodeOptions = function (chargeCodeList) {
  if (chargeCodeList === null || chargeCodeList === undefined) return null;
  return chargeCodeList.map((i) => ({ value: i.chargeType, label: i.chargeType }));
};

CaisoStore.prototype.getOasisReportOptions = function (reportList) {
  return reportList
    .filter((r) => r.isActiveInUI === true)
    .map((i) => ({ value: i.reportName, label: i.reportName }))
    .sort(sortBy('label'));
};

/**
 * Registers a listener for coordinator refresh upcoordinators
 * @param {Object} listener - an object that implements the method 'coordinatorRefreshCallback(coordinatorValues)'
 */
CaisoStore.prototype.registerCoordinatorRefreshListener = function (listener) {
  // only if the listener exists
  if (listener) { ls.on('new-default-coordinator-options', listener.coordinatorRefreshCallback); }
};

/**
 * Call this method in order to send a message to all listeners with updated coordinator store information.
 * @param {Object} coordinatorList - an optional coordinatorList parameter. If no coordinatorOption is passed in then the current coordinatorList are used.
 */

const caisoStore = new CaisoStore();
export default caisoStore;