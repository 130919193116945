import moment from 'moment';
import { ETRM_API } from '@/api';
import config from './markToMarketResultsConfigs';
import { createTradeParams } from '../tradesUtil';
import dateStore from '@/utils/dateStore';
import * as dateUtil from '@/utils/dateUtil';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  valueDate: dateStore.getMomentNow().toISOString(),
  tableData: [],
  tableConfig: config.fields,
  tableExportConfig: {},

  dockConfig: {
    visible: false,
    alignment: 'left',
  },
};

const actions = {
  async initialize({ commit, dispatch }) {
    dispatch('lookup/initializeAllLookup', null, { root: true });
  },
  async fetchMarkToMarketResults({ commit, state }) {
    commit('setTableData', []);

    try {
      const valueDate = state.valueDate.substring(0, 10);
      const fileSuffix = `_${valueDate}`;
      
      const headers = { Accept: 'application/json' };
      
      const { data } = await ETRM_API.get(`/mark-to-markets/${valueDate}`, { headers });

      // removing AMOUNT since it's the same as NOTIONAL_VALUE
      let filtered = data.filter((obj) => !obj?.type.endsWith('AMOUNT'));
      
      filtered.forEach((obj) => {

        obj.valueDate = moment(obj.valueDate).utc().format('YYYY-MM-DD');
        // obj.effectiveStartTime = moment(obj.effectiveStartTime).utc().format('YYYY-MM-DD');
        // obj.effectiveEndTime = moment(obj.effectiveEndTime).utc().format('YYYY-MM-DD');
      });

      commit('setTableData', filtered);

      // save parameters for export
      const tableExportConfig = {
        requestAPI: ETRM_API,
        requestURL: `/mark-to-markets/${valueDate}`,
        requestParams: {},
        fileNameSuffix: fileSuffix,
      };
      commit('setTableExportConfig', tableExportConfig);
    } catch(error) {
      console.log(error);
      this.$notify('Failed to retrieve Mark to Market results', 'error');
    }
  },
  async calcMarkToMarket({ commit, state }, inputParams = {}) {
    try {
      const valueDate = moment.utc(state.valueDate).format('YYYY-MM-DD');
      const tradeRequest = createTradeParams(inputParams);

      const params = {
        marketToMarketRef: {
          valueDate,
        },
        tradeRequest,
      };

      const { data } = await ETRM_API.put(`/mark-to-markets/compute`, params);
      this.$notify(data.message, 'info');
    } catch(error) {
      console.log(error);
      this.$notify('Failed to start Mark to Market calculations', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setTableExportConfig', {});

    state.dockConfig = {
      visible: false,
      alignment: 'left',
    };
  },
  ...createMutations(
    'dockConfig',
  ),
};

const mutations = {
  setValueDate(state, data) {
    state.valueDate = data;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setTableExportConfig(state, value) {
    state.tableExportConfig = value;
  },
  setDockConfig(state, config) {
    state.dockConfig = config;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};