// https://auth0.com/docs/quickstart/spa/vuejs/01-login
import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

const oidcEnabled = window?.OIDC_AUTH_ENABLED ?? false;
const oidcAuthority = window?.OIDC_AUTHORITY ?? '';
const oidcClientId = window?.OIDC_CLIENT_ID ?? '';

let instance;

export const getInstance = () => instance;

export const useAuth = async ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.href,
  ...options
}) => {
  if (instance) return instance;

  instance = await new Vue({
    store: options.store,
    data: () => ({
      loading: true,
      // isAuthenticated: false,
      user: {},
      auth0Client: null,
      oidClient: null,
      popupOpen: false,
      error: null,
      expiryTime: null,
    }),
    async created() {
      const { pathname, origin } = window.location;

      let redirect_uri = window?.OIDC_REDIRECT_URI ?? '';
      if (pathname && pathname.toLowerCase() === '/confirm/user') {
        redirect_uri = `${window.location.origin}/confirm/user`;
      }

      if (oidcEnabled) {
        try {
          this.oidClient = new UserManager({
            authority: oidcAuthority,
            client_id: oidcClientId,
            redirect_uri,
            response_type: 'code',
            scope: 'openid profile offline_access',
            automaticSilentRenew: true,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
          });
        } catch (err) {
          console.error(err);
          localStorage.setItem('isAuthenticated', false);
          this.error = err;
          this.loading = false;
        }
        if (!this.error) {
          const containsCode = window.location.search.includes('code=');
          const containsState = window.location.search.includes('state=');
          const userCode = localStorage.getItem('user-code');
          const userState = localStorage.getItem('user-state');

          if (pathname && (pathname.toLowerCase() === '/confirm/user')
            && containsCode && containsState && !userCode && !userState) {
            const url = new URL(window.location.href);
            const params = new URLSearchParams(url.search);
            const code = params.get('code');
            const state = params.get('state');
            const reqId = params.get('client-request-id');
            localStorage.setItem('user-code', code);
            localStorage.setItem('user-state', state);
            localStorage.setItem('user-reqId', reqId);
          } else {
            try {
              if (containsCode && containsState) {
                const user = await this.oidClient.signinRedirectCallback();
                this.user = user;
                this.error = null;
              }
            } catch (e) {
              console.error(e);
              this.error = e;
            } finally {
              this.user = this.oidClient ? await this.oidClient.getUser() : null;
              localStorage.setItem('isAuthenticated', !!this.user);
              if (localStorage.getItem('isAuthenticated') === 'true') this.updateExpiryTime();
              this.loading = false;
            }
          }
        }
      } else {
        redirect_uri = origin;
        if (pathname && pathname.toLowerCase() === '/confirm/user') {
          redirect_uri = window.location.href;
        }
        const opts = {
          domain: options.domain,
          client_id: options.clientId,
          audience: options.audience,
          redirect_uri,
          scope: options.scope,
        };

        try {
          this.auth0Client = await createAuth0Client(opts);
        } catch (err) {
          console.error(err);
          localStorage.setItem('isAuthenticated', false);
          this.error = err;
          this.loading = false;
        }
        if (!this.error) {
          const containsCode = window.location.search.includes('code=');
          const containsState = window.location.search.includes('state=');

          if (pathname && pathname.toLowerCase() === '/confirm/user' && containsCode && containsState) {
            await this.auth0Client.getTokenSilently();
            localStorage.setItem('isAuthenticated', await this.auth0Client.isAuthenticated());
            this.loading = false;
          } else {
            try {
              if (containsCode && containsState) {
                const { appState } = await this.auth0Client.handleRedirectCallback();
                this.error = null;
                onRedirectCallback(appState);
              }
            } catch (e) {
              console.error(e);
              this.error = e;
            } finally {
              localStorage.setItem('isAuthenticated', await this.auth0Client.isAuthenticated());
              this.user = await this.auth0Client.getUser();
              if (localStorage.getItem('isAuthenticated') === 'true') this.updateExpiryTime();
              this.loading = false;
            }
          }
        }
      }
      await this.loadProfile((pathname && pathname.toLowerCase() === '/confirm/user') && !oidcEnabled);
      this.loading = false;
    },
    methods: {
      oidcLogin() {
        this.oidClient.signinRedirect();
      },
      async loginWithPopup(o) {
        this.popupOpen = true;

        try {
          await this.auth0Client.loginWithPopup(o);
          this.user = await this.auth0Client.getUser();
          localStorage.setItem('isAuthenticated', await this.auth0Client.isAuthenticated());
          this.error = null;
        } catch (e) {
          console.error(e);
          this.error = e;
        } finally {
          this.popupOpen = false;
        }
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          localStorage.setItem('isAuthenticated', true);
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o);
      },
      async getTokenSilently(o) {
        if (oidcEnabled) {
          await this.oidClient.signinSilent();
          this.user = await this.oidClient.getUser();
          return this.user.access_token;
        }
        return this.auth0Client.getTokenSilently(o);
      },
      getTokenWithPopup(o) {
        return this.auth0Client.getTokenWithPopup(o);
      },
      logout(o) {
        if (oidcEnabled) {
          this.oidClient.signoutSilent();
        } else {
          this.auth0Client.logout({
            ...o,
            returnTo: `${window.location.protocol}//${window.location.host}`,
          });
        }
        this.$store.dispatch('auth/logout');
        localStorage.setItem('isAuthenticated', 'false');
      },

      loadProfile(isConfirmPath) {
        if (isConfirmPath) this.$store.dispatch('auth/setConfirmProfile');
        else if (localStorage.getItem('isAuthenticated') === 'true') this.$store.dispatch('auth/login');
      },
      async updateExpiryTime() {
        if (oidcEnabled) {
          const user = this.user;
          if (!user) {
            console.error('no user to obtain expiry time');
            return;
          }
          const expiresAt = user.expires_at ? user.expires_at * 1000 : undefined;
          this.expiryTime = expiresAt;
        } else {
          const idToken = await this.auth0Client.getIdTokenClaims();
          // Multiply by 1000 to go from seconds to milliseconds for unix conversions
          this.expiryTime = idToken ? idToken.exp * 1000 : undefined;
        }
      },
    },
  });

  return instance;
};