import dateStore from '@/utils/dateStore';
import helpers from '@/utils/helpers';

export function mapToOptions(items) {
  return items.map((i) => ({ value: i.id, label: i.shortName }));
}

export function findSchedulingCoordinator(items, scId) {
  return items.find((i) => i.id === scId);
}

export function findBaseSchedulingCoordinator(items, bscId) {
  return items.find((i) => i.id === bscId);
}

export function findResources(bscToResource, scToResource, bscId, scId, rsrcType, resourceGroups, selectedResourceGroup) {
  let resources = [];
  if (bscId && bscToResource && bscToResource.length > 0) {
    const bsc = findBaseSchedulingCoordinator(bscToResource, bscId);
    resources = bsc.resources;
  }
  if (scId) {
    const sc = findSchedulingCoordinator(scToResource, scId);
    resources = resources.filter((o) => o.sc === sc.shortName);
  }

  if (rsrcType) {
    resources = resources.filter((o) => o.resourceType === rsrcType);
  }

  if (selectedResourceGroup) {
    const rsrcs = [];
    const group = resourceGroups.find((o) => o.id == selectedResourceGroup);

    for (let i = 0; i < group.resources.length; i++) {
      const rsrc = resources.find((o) => o.shortName == group.resources[i].shortName);
      if (rsrc) { rsrcs.push(rsrc); }
    }
    resources = rsrcs;
  }

  if (resources && resources.length > 0) {
    resources = helpers.sortBy(resources, (p) => p.shortName);
  }

  return resources;
}

export function findResource(items, scId, rsrcId) {
  const resource = null;
  const sc = findSchedulingCoordinator(items, scId);
  if (rsrcId) {
    return sc.resources.find((o) => o.id === rsrcId);
  }
  return null;
}

export function getStatus(item) {
  if (item) {
    if (item.schedule && item.market) return `${item.market} / ${item.schedule}`;
    if (item.schedule && !item.market) return item.schedule;
  }

  return '';
}

export function mapToViewModel(items) {
  if (items) {
    return items.map((i) => ({
      id: i.scheduleName,
      sc: i.sc,
      date: i.date,
      marketType: i.marketType,
      resource: i.resource,
      configuration: i.configuration,
      he01: getStatus(i.he01),
      he02: getStatus(i.he02),
      he03: getStatus(i.he03),
      he04: getStatus(i.he04),
      he05: getStatus(i.he05),
      he06: getStatus(i.he06),
      he07: getStatus(i.he07),
      he08: getStatus(i.he08),
      he09: getStatus(i.he09),
      he10: getStatus(i.he10),
      he11: getStatus(i.he11),
      he12: getStatus(i.he12),
      he13: getStatus(i.he13),
      he14: getStatus(i.he14),
      he15: getStatus(i.he15),
      he16: getStatus(i.he16),
      he17: getStatus(i.he17),
      he18: getStatus(i.he18),
      he19: getStatus(i.he19),
      he20: getStatus(i.he20),
      he21: getStatus(i.he21),
      he22: getStatus(i.he22),
      he23: getStatus(i.he23),
      he24: getStatus(i.he24),
    }));
  }

  return [];
}

export function joinProposed(bids, proposed) {
  const result = [];

  for (let i = 0; i < bids.length; i++) {
    result.push(bids[i]);
  }

  if (proposed) {
    for (let x = 0; x < proposed.length; x++) {
      const pb = proposed[x];

      let found = false;
      for (let k = 0; k < bids.length; k++) {
        const bb = bids[k];

        if (pb.resource === bb.resource) {
          found = true;
          break;
        }
      }

      if (!found) {
        result.push(pb);
      }
    }
  }
  return result;
}

export function generateBasePointSchedule(tradeDate, selectedProductType, val) {
  const start = tradeDate.clone().utc().format();
  const end = tradeDate.clone().add(1, 'hours').utc().format();

  const basePointSchedule = {
    startTime: start,
    endTime: end,
    en: { },
    ru: { },
    rd: { },
    sr: { },
    nr: { },
  };
  basePointSchedule[selectedProductType.toLowerCase()].value = val;
  return basePointSchedule;
}

export function joinBaseScheduleStatusesAndResources(statuses, filteredResources, scToResources, bscToResources, selectedSchedulingCoordinator, selectedResource, date, selectedMarketType, selectedBSC, selectedResourceType, selectedResourceGroup, selectedProductType) {
  selectedResource = findResource(scToResources, selectedSchedulingCoordinator, selectedResource);
  const sc = findSchedulingCoordinator(scToResources, selectedSchedulingCoordinator);
  const bsc = findBaseSchedulingCoordinator(bscToResources, selectedBSC);

  const result = [];

  const hour = {
    en: null,
    nr: null,
    rd: null,
    ru: null,
    sr: null,
  };

  const momentDate = dateStore.toMoment(date, 'America/Los_Angeles');

  for (let xr = 0; xr < filteredResources.length; xr++) {
    const resource = filteredResources[xr];

    if (selectedResource && resource.shortName !== selectedResource.shortName) { continue; }

    if (resource.configurations && resource.configurations.length > 0) {
      for (let j = 0; j < resource.configurations.length; j++) {
        const config = resource.configurations[j];
        const ss = statuses.find((b) => b.resource === resource.shortName && b.configuration === config.shortName && b.marketType === selectedMarketType && b.bsc === bsc.shortName);

        let second = false;
        if (ss) {
          for (let k = 0; k < ss.basePointSchedules.length; k++) {
            const basePointSchedule = ss.basePointSchedules[k];

            const he = (dateStore.toMoment(basePointSchedule.startTime, 'America/Los_Angeles').get('hour') + 1);
            let val = basePointSchedule[selectedProductType.toLowerCase()];
            if (val) { val = val.value; }

            let prop = '';
            if (he < 10) {
              if (he === 2) {
                prop = `he0${he}`;
                if (second) { prop = dateStore.getLongDayHour() ? `he0${he}*` : 'he25'; }
                second = true;
              } else { prop = `he0${he}`; }
            } else {
              prop = `he${he}`;
            }

            ss[`${prop}_val`] = val;
          }
          ss.productType = selectedProductType;
          result.push(ss);
        } else {
          result.push({
            configuration: config.shortName,
            tradeDate: momentDate.clone().utc().format(),
            basePointSchedules: [],
            he01_val: null,
            he02_val: null,
            'he02*_val': null,
            he03_val: null,
            he04_val: null,
            he05_val: null,
            he06_val: null,
            he07_val: null,
            he08_val: null,
            he09_val: null,
            he10_val: null,
            he11_val: null,
            he12_val: null,
            he13_val: null,
            he14_val: null,
            he15_val: null,
            he16_val: null,
            he17_val: null,
            he18_val: null,
            he19_val: null,
            he20_val: null,
            he21_val: null,
            he22_val: null,
            he23_val: null,
            he24_val: null,
            he25_val: null,
            productType: selectedProductType,
            marketType: selectedMarketType,
            resource: resource.shortName,
            resourceType: resource.resourceType,
            sc: sc.shortName,
            bsc: bsc.shortName,
            scheduleName: null,
            variant: 'NOT_EXISTS',
          });
        }
      }
    } else {
      const ss = statuses.find((b) => b.resource === resource.shortName && b.marketType === selectedMarketType && b.bsc === bsc.shortName);

      let second = false;
      if (ss) {
        for (let k = 0; k < ss.basePointSchedules.length; k++) {
          const basePointSchedule = ss.basePointSchedules[k];

          const he = (dateStore.toMoment(basePointSchedule.startTime, 'America/Los_Angeles').get('hour') + 1);
          let val = basePointSchedule[selectedProductType.toLowerCase()];
          if (val) { val = val.value; }

          let prop = '';
          if (he < 10) {
            if (he === 2) {
              prop = `he0${he}`;
              if (second) { prop = dateStore.getLongDayHour() ? `he0${he}*` : 'he25'; }
              second = true;
            } else { prop = `he0${he}`; }
          } else {
            prop = `he${he}`;
          }

          ss[`${prop}_val`] = val;
        }

        ss.productType = selectedProductType;
        result.push(ss);
      } else {
        result.push({
          configuration: null,
          tradeDate: momentDate.clone().utc().format(),
          basePointSchedules: [],
          he01_val: null,
          he02_val: null,
          'he02*_val': null,
          he03_val: null,
          he04_val: null,
          he05_val: null,
          he06_val: null,
          he07_val: null,
          he08_val: null,
          he09_val: null,
          he10_val: null,
          he11_val: null,
          he12_val: null,
          he13_val: null,
          he14_val: null,
          he15_val: null,
          he16_val: null,
          he17_val: null,
          he18_val: null,
          he19_val: null,
          he20_val: null,
          he21_val: null,
          he22_val: null,
          he23_val: null,
          he24_val: null,
          he25_val: null,
          productType: selectedProductType,
          marketType: selectedMarketType,
          resource: resource.shortName,
          resourceType: resource.resourceType,
          sc: sc.shortName,
          bsc: bsc.shortName,
          scheduleName: null,
          variant: 'NOT_EXISTS',
        });
      }
    }
  }
  return result;
}