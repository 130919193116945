import utils from '@/utils';
import { BILLING_REF_API } from '@/api';
import moment from 'moment';
import { clone, buildApiQuery } from '@/utils/dataUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  attributeMapping: [],
  attributeTableObject: {},
  templateListOptions: [],
  bankList: [],
  bankListOptions: [],
  invoicingCompanyList: [],
  invoicingCompanyListOptions: [],
  pointOfContactList: [],
  pointOfContactListOptions: [],
  invoiceEntityListOptions: [],
  nullTableRow: {
    id: -999,
    shortName: null,
    longName: null,
    accountNumber: null,
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    address5: null,
    address6: null,
    vendorName: null,
    vendorNumber: null,
    vendorLineItem: null,
    purchaseOrder: null,
    glAccount: null,
    glFercAccount: null,
    taskNumber: null,
    internalEntity: null,
    lineOfBusiness: null,
    costElement: null,
    accountingWork: null,
    fieldWorkOrder: null,
    apglAccount: null,
    orderNumber: null,
    billingPayee: null,
    invoiceEntityCode: null,
    invoiceDueDateAdder: null,
    invoiceDueDateOffsetType: null,
    bankId: 1,
    pointOfContactId: 1,
    templateName: 'DEFAULT',
    invoiceAutoDistributionList: null,
    invoiceAutoDistributeFlag: false,
    allowSendToAccounting: true,
    allowPowerPlanCurrent: true,
    allowPowerPlanAccrual: true,
    allowPowerPlanReversal: true,
    attribute1: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getAttributeList(state) {
    return state.attributeMapping.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('fetchAttributeData');
    dispatch('fetchTemplateList');
    dispatch('fetchBankList');
    dispatch('fetchInvoicingCompanyList');
    dispatch('fetchPointOfContactList');
    dispatch('fetchInvoiceEntities');
  },
  async fetchInvoiceEntities({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/invoice-entities');
      if (Array.isArray(data)) {
        commit('setTableData', data);
        commit('setInvoiceEntityListOptions', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to fetch Invoice Entities', 'error');
    }
  },
  async fetchAttributeData({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/ref-attributes/InvoiceEntity');
      commit('loadAttributeData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  async fetchTemplateList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/invoice-entities/template-types');
      commit('setTemplateListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Template list', 'error');
      console.error(err);
    }
  },
  async fetchBankList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/banks');
      commit('setBankList', data);
      commit('setBankListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Bank list', 'error');
      console.error(err);
    }
  },
  async fetchInvoicingCompanyList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/invoicing-companies');
      commit('setInvoicingCompanyList', data);
      commit('setInvoicingCompanyListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Invoicing Companies', 'error');
      console.error(err);
    }
  },
  async fetchPointOfContactList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/points-of-contact');
      commit('setPointOfContactList', data);
      commit('setPointOfContactListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch POC list', 'error');
      console.error(err);
    }
  },
  async postRecord({ dispatch, commit, state }, record) {
    try {
      const { data } = await BILLING_REF_API.post('/invoice-entities', record);
      commit('setRecord', data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      this.$notify('Failed to Add Data', 'error');
      console.error(err);
    }
  },
  async updateRecord({ dispatch, commit, state }, record) {
    try {
      const { data } = await BILLING_REF_API.put(`/invoice-entities/${record.id}`, record);
      commit('setRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      this.$notify('Failed to Update Data', 'error');
      console.error(err);
    }
  },
  async deleteRecord({ dispatch, commit, state }) {
    try {
      await BILLING_REF_API.delete(`/invoice-entities/${state.currentTableRow.id}`);
      commit('deleteUiTableRow');
      dispatch('currentTableRowChange', null);
      this.$notify('Data Removed', 'success');
    } catch (err) {
      this.$notify('Failed to Remove Data', 'error');
      console.error(err);
    }
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('setCurrentRow', currentTableRow);
  },
  resetTable({ commit }) {
    commit('setTableData', []);
    commit('setCurrentRow', null);
  },
  reset({ commit }) {
    commit('setCurrentRow', null);
    commit('setTableData', []);
    commit('setBankList', []);
    commit('setInvoicingCompanyList', []);
    commit('setPointOfContactList', []);
  },
};

const mutations = {
  setRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  setCurrentRow(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteUiTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  setTableData(state, records) {
    state.tableData = records;
  },
  setTemplateListOptions(state, templateList) {
    state.templateListOptions = templateList?.map((item) => ({ value: item, label: item }));
  },
  setBankList(state, banks) {
    state.bankList = banks;
  },
  loadAttributeData(state, records) {
    state.attributeMapping = records;
    state.attributeTableObject = records.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
  setBankListOptions(state, bankList) {
    state.bankListOptions = bankList?.map(({ id, accountName, name }) => ({ value: id, label: `${accountName} (${name})` }));
  },
  setPointOfContactList(state, pointOfContacts) {
    state.pointOfContactList = pointOfContacts;
  },
  setPointOfContactListOptions(state, pointOfContactList) {
    state.pointOfContactListOptions = pointOfContactList?.map(({ id, firstName, lastName }) => ({ value: id, label: `${firstName} ${lastName}` }));
  },
  setInvoicingCompanyList(state, invoicingCompanies) {
    state.invoicingCompanyList = invoicingCompanies;
  },
  setInvoicingCompanyListOptions(state, invoicingCompanyList) {
    state.invoicingCompanyListOptions = invoicingCompanyList?.map(({ id, shortName }) => ({ value: id, label: shortName }));
  },
  setInvoiceEntityListOptions(state, list) {
    state.invoiceEntityListOptions = list?.map(({ id, shortName }) => ({ value: id, label: shortName }));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};