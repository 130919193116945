import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';

const state = {
  tableConfiguration: {
    columns: [{
      prop: 'rec',
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ReportRecTags',
        params: [{ key: 'recId', dataKey: 'rec' }],
        query: [{ key: 'start', dataKey: 'startTime' }, { key: 'end', dataKey: 'endTime' }],
      },
      filterable: true,
      sortable: true,
    }, {
      prop: 'startTime', sortable: true, dataType: 'datetime',
    }, {
      prop: 'endTime', sortable: true, dataType: 'datetime',
    }, {
      prop: 'total',
    }],
    options: { exportExcel: true },
    summary: [{ prop: 'total', summaryType: 'sum' }],
  },
  dateRange: dateStore.getDefaultRange(),
  defaultRange: dateStore.getDefaultRange(),
  tableData: [],
};

const getters = {};

const actions = {
  changeDateRange({ commit, dispatch }, value) {
    commit('setDateRange', value);
    dispatch('search');
  },
  search({ commit, state }) {
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.dateRange, tz);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();

    const requestParams = {
      start: start.format(),
      end: end.format(),
      timeZone: tz,
    };
    ETAG_API.get('/reports/recs', {
      params: requestParams,
    }).then((response) => {
      commit('setTableData', response.data.data);
    }).catch((error) => {
      console.log(error);
    });
  },
  checkDefaultDate({ commit, state }, value) {
    const defaultDate = dateStore.getDefaultRange();

    if (state && (!defaultDate[0].isSame(state.defaultRange[0]) || !defaultDate[1].isSame(state.defaultRange[1]))) {
      state.dateRange = defaultDate;
      state.defaultRange = defaultDate;
    }
  },
};

const mutations = {
  setDateRange(state, value) {
    state.dateRange = value;
  },
  setTableData(state, data) {
    state.tableData = dateStore.formatDateTimeFields(data, [
      { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
      { name: 'endTime', format: 'YYYY-MM-DD HH:mm' },
    ]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
