import moment from 'moment';
import { TOOLS_API } from '@/api';

const state = {
  resourceViewerTableData: [],
  pnodeViewerTableData: [],
  meterChannelTableData: [],
};

const actions = {
  async fetchResourceViewer({ commit }, args) {
    const params = {
      date: args.tradeDateRangeSelected[0].toISOString(),
      scs: args.coordinatorListSelected,
      rsrcType: args.resourceTypeSelected,
      ignoreExpire: args.ignoreExpireSelected,
    };

    try {
      const response = await TOOLS_API.get('resource-viewer', { params });
      if (Array.isArray(response.data)) {
        response.data.forEach((obj) => {
          obj.effectiveDate = moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
          obj.terminationDate = obj.terminationDate !== null ? moment(obj.terminationDate).utc().format('MM/DD/YYYY') : null;
          obj.createdDate = moment(obj.createdDate).utc().format('MM/DD/YYYY');
        });
      }
      commit('setResourceViewerTableData', response.data);
    } catch {
      this.$notify('Failed to Load Resource Viewer', 'error');
    }
  },

  async fetchPnodeViewer({ commit }, args) {
    const params = {
      date: args.tradeDateRangeSelected[0].toISOString(),
    };

    try {
      const response = await TOOLS_API.get('pnode-viewer', { params });
      commit('setPnodeViewerTableData', response.data);
    } catch {
      this.$notify('Failed to Load Pnode Viewer', 'error');
    }
  },

  async fetchMeterChannelViewer({ commit }, args) {
    const params = {
      date: args.tradeDateRangeSelected[0].toISOString(),
      scs: args.coordinatorListSelected,
      rsrcType: args.resourceTypeSelected,
      ignoreExpire: args.ignoreExpireSelected,
    };

    try {
      const response = await TOOLS_API.get('meter-channel-config-viewer', { params });
      if (Array.isArray(response.data)) {
        response.data.forEach((obj) => {
          obj.effectiveDate = moment(obj.effectiveDate).utc().format('MM/DD/YYYY');
          obj.terminationDate = obj.terminationDate !== null ? moment(obj.terminationDate).utc().format('MM/DD/YYYY') : null;
        });
      }
      commit('setMeterChannelTableData', response.data);
    } catch {
      this.$notify('Failed to Load Meter Channel Configuration Viewer', 'error');
    }
  },
};

const mutations = {
  setResourceViewerTableData(state, data) {
    state.resourceViewerTableData = data;
  },
  setPnodeViewerTableData(state, data) {
    state.pnodeViewerTableData = data;
  },
  setMeterChannelTableData(state, data) {
    state.meterChannelTableData = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};