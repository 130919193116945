import * as tagUtils from '@/utils/etag/tagUtils';
import { has, clone, cloneDeep } from '@/utils/dataUtil';
import marketPathTableConfiguration from './generationMarketPathConfiguration';
import physicalPathTableConfiguration from './generationPhysicalPathConfiguration';
import allocationPathTableConfiguration from './generationAllocationPathConfiguration';
import { requestData } from '../../API';

function search(query, column, row) {
  let uri = '';
  let params = {};
  let mapper = null;
  let dataProperty = '';

  if (column.prop === 'market_pse') {
    uri = '/registry/pses';
    dataProperty = 'pseList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'market_product_name' || column.prop === 'market_product') {
    uri = '/registry/products?type=generation';
    dataProperty = 'productList';
    if (query !== null && query !== '') params = { name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  } else if (column.prop === 'physical_gca') {
    if (row.market_pse_name === null) { return new Promise((resolve, reject) => { resolve([]); }); }

    uri = '/registry/sources';
    dataProperty = 'sourceList';
    params = { pse: row.market_pse_name, select_by: 'ba' };

    if (query !== null && query !== '') { params = { ...params, name: query }; }

    mapper = (m) => ({ value: m.ba, label: m.ba });
  } else if (column.prop === 'physical_source') {
    if (row.market_pse_name === null && row.physical_gca_name === null) { return new Promise((resolve, reject) => { resolve([]); }); }

    uri = '/registry/sources';
    dataProperty = 'sourceList';
    params = { pse: row.market_pse_name, ba: row.physical_gca_name, select_by: 'source' };

    if (query !== null && query !== '') { params = { ...params, name: query }; }

    mapper = (m) => ({ value: m.name, label: m.name });
  }

  return requestData(uri, params, dataProperty, mapper);
}

export default {
  state: {
    marketPathTable: marketPathTableConfiguration,
    physicalPathTable: physicalPathTableConfiguration,
    allocationTable: allocationPathTableConfiguration,
    nullGenerationRow: {
      guid: 'gen',
      type: 'GEN',
      market_id: 1,
      market_pse_name: null,
      market_product_name: null,
      market_miscInfoCount: 0,
      market_miscInfoList: [],
      market_contractCount: 0,
      market_contractList: [],
      physical_id: 1,
      physical_gca_name: null,
      physical_source_name: null,
      physical_miscInfoCount: 0,
      physical_miscInfoList: [],
      physical_mo_name: null,
      trans_contract: null,
    },
    generation: [],
    generationErrors: [],
  },
  getters: {
    getMarketPathTableConfiguration(state, readonly = true, exclusionList = null) {
      const configuration = cloneDeep(state.marketPathTable);
      if (readonly) {
        tagUtils.setEditableFalse(configuration, state.nullGenerationRow, exclusionList);
        configuration.columns.splice(0, 1);
      }
      configuration.remote = { search, nullColumnSearch: ['market_product_name', 'market_product'] };
      return configuration;
    },
    getPhysicalPathTableConfiguration(state, readonly = true, exclusionList = null) {
      const configuration = cloneDeep(state.physicalPathTable);
      if (readonly) {
        tagUtils.setEditableFalse(configuration, state.nullGenerationRow, exclusionList);
        configuration.columns.splice(0, 1);
      }
      configuration.remote = { search, nullColumnSearch: ['physical_gca', 'physical_source'] };
      return configuration;
    },
    getAllocationPathTableConfiguration(state, readonly = true, exclusionList = null) {
      const configuration = cloneDeep(state.allocationTable);
      if (readonly) {
        tagUtils.setEditableFalse(configuration, state.nullGenerationRow, exclusionList);
      }
      return configuration;
    },
    getData(state) {
      return state.generation;
    },
    getMarketPSE(state) {
      return {
        market_id: state.generation[0].market_id,
        market_pse_name: state.generation[0].market_pse_name,
      };
    },
    cloneData: (state) => clone(state.generation),
  },
  actions: {
    changeMarketPath({ commit, dispatch, state }, item) {
      commit('setGeneration', item);
      if (item.prop === 'market_pse') {
        if (state.generation.generation[0].market_contractList && state.generation.generation[0].market_contractList.length > 0) {
          dispatch('changeToGenerationMarketPSE', {
            value: item.eventData.value,
            label: item.eventData.label,
            contractList: state.generation.generation[0].market_contractList,
          });
          const idx = state.generation.generation.findIndex((l) => l.guid === 'gen');
          if (idx !== -1) {
            state.generation.generation[idx].physical_source = null;
            state.generation.generation[idx].physical_source_name = null;
          }
        } else {
          dispatch('changeToGenerationMarketPSE', { value: item.eventData.value, label: item.eventData.label });
        }
      }
    },
    changePhysicalPath({ commit, dispatch }, item) {
      commit('setGeneration', item);

      if (item.prop === 'physical_source') {
        dispatch('changeProfileColumns');
        dispatch('changeSource', { value: item.eventData.value, label: item.eventData.label });
      } else if (item.prop === 'physical_gca') {
        dispatch('changeGCA', { value: item.eventData.value, label: item.eventData.label });
      }
    },
    changeAllocationPath({ commit }, item) {
      commit('setGeneration', item);
    },
  },
  mutations: {
    setRegistry(state, registry) {
      state.marketPathTable.registry = {
        pseList: registry.pseList,
        productList: registry.productList,
      };

      state.physicalPathTable.registry = {
        baList: registry.baList,
        sourceList: registry.sourceList,
        moList: registry.moList,
      };
    },
    setRegistryPSEList(state, pseList) {
      state.marketPathTable.registry.pseList = pseList;
      state.physicalPathTable.registry.pseList = pseList;
    },
    setRegistryProductList(state, productList) {
      state.marketPathTable.registry.productList = productList;
    },
    setRegistryBAList(state, baList) {
      state.physicalPathTable.registry.baList = baList;
    },
    setRegistrySourceList(state, sourceList) {
      state.physicalPathTable.registry.sourceList = sourceList;
    },
    setRegistryMOList(state, moList) {
      state.physicalPathTable.registry.moList = moList;
    },
    setFromTagModel(state, tagModel) {
      const marketSegment = tagModel.segmentList[0];
      const physicalSegment = marketSegment.physicalSegmentList[0];

      const contracts = physicalSegment.contractNumberList.join(',');
      const contractList = [];
      for (let i = 0; i < marketSegment.contractNumberList.length; i++) {
        const contract = marketSegment.contractNumberList[i];
        contractList.push(contract);
      }

      state.generation = [{
        guid: 'gen',
        type: 'GEN',
        market_id: marketSegment.marketSegmentID,
        market_pse_name: marketSegment.pse,
        market_product_name: marketSegment.energyProduct,
        market_contractCount: contractList.length,
        market_contractList: contractList,
        market_miscInfoCount: marketSegment.miscInfoList.length,
        market_miscInfoList: marketSegment.miscInfoList,
        physical_id: physicalSegment.physicalSegmentID,
        physical_mo_name: physicalSegment.mo,
        physical_gca_name: tagModel.tagID.gca,
        physical_source_name: physicalSegment.pod,
        physical_miscInfoCount: physicalSegment.miscInfoList.length,
        physical_miscInfoList: physicalSegment.miscInfoList,
        trans_contract: contracts,
      }];
    },
    initialize(state) {
      const row = clone(state.nullGenerationRow);
      state.generation = [row];
      state.generationErrors = [row];
    },
    setData(state, item) {
      const eventData = item.eventData || null;
      const row = state.generation[0];
      row[item.prop] = item.value;
      let tempName = item.prop;
      if (item.prop.indexOf('_name') < 0) { tempName += '_name'; }
      if (has(row, tempName) && eventData !== null) {
        row[tempName] = item.eventData.label;
      }
    },
    setMiscInfoProperty(state, item) {
      const row = state.generation[item.rowIndex];
      row[item.countProp] = item.count;
      row[item.dataProp] = item.data;
    },
    setContractProperty(state, item) {
      const gen = state.generation[0];
      if (gen.market_id === item.market_id) {
        gen[item.countProp] = item.count;
        gen[item.dataProp] = item.data;
      }
    },
    clearMoData(state, guid) {
      const idx = state.generation.findIndex((l) => l.guid === guid);
      if (idx !== -1) {
        state.generation[idx].physical_mo = null;
        state.generation[idx].physical_mo_name = null;
      }
    },
  },
};