import { isStringNullOrEmpty } from '@/utils/dataUtil';
import { isAfterNow } from '@/utils/dateUtil';
import dateStore from '@/utils/dateStore';
import cellColors from '../../util';
import { ETAG_API } from '@/api';

function isApprovalSubmissionAvailable(model) {
  if (model === null) return false;
  if (model.requestStatus !== 'PENDING') return false;
  const right = model.approvalRights;
  const expired = isAfterNow(model.actOnByTime);
  return right === true && expired === false;
}

const state = {
  tableConfiguration: {
    columns: [
      { prop: 'entity_name', label: 'Entity', width: 100 },
      { prop: 'approvalDateTime', label: 'Approval Time', width: 130 },
      {
        prop: 'approvalStatus',
        label: 'Approval Status',
        width: 130,
        cellTemplate: 'PscsColoredCellTemplate',
        editorOptions: { ...cellColors },
      },
      { prop: 'notes' },
      { prop: 'deliveryStatus', label: 'Delivery', width: 120 },
      { prop: 'contactName', label: 'Contact', width: 100 },
      { prop: 'contactPhone', label: 'Phone', width: 100 },
      { prop: 'contactFax', label: 'Fax', width: 100 },
    ],
    footer: { count: false },
  },
  tableData: [],
  refreshRate: 15,
  minRefreshRate: 5,
  maxRefreshRate: 60,
  counter: 0,
  tableName: 'tagStatus',
  statusDialogVisible: false,
  dateTimeFormats: [
    { name: 'approvalDateTime', format: 'YYYY-MM-DD HH:mm:ss' },
  ],
  timer: null,
  model: null,
  approvalNotes: null,
  approvalState: 'APPROVE',
  approvalStateOptions: [
    { value: 'STUDY', label: 'STUDY' },
    { value: 'APPROVE', label: 'APPROVE' },
    { value: 'DENY', label: 'DENY' },
  ],
};

const getters = {
  getTableConfiguration: (state) => state.tableConfiguration,
  getTableData: (state) => state.tableData,
  getTableName: (state) => state.tableName,
  getStatusDialogVisible: (state) => state.statusDialogVisible,
  getRefreshRate: (state) => state.refreshRate,
  getMinRefreshRate: (state) => state.minRefreshRate,
  getMaxRefreshRate: (state) => state.maxRefreshRate,
  getActOnByTime: (state) => state.model ? state.model.actOnByTime : null,
  getRequestor({ model }) {
    if (model) {
      return {
        id: model.requestRef,
        name: model.requestorName || '',
        type: model.requestorType || '',
        time: model.requestTime,
      };
    }
    return null;
  },
  getApprovalNotes: (state) => state.approvalNotes,
  getApprovalState: (state) => state.approvalState,
  getApprovalStateOptionList: (state) => state.approvalStateOptions,
  getApprovalNotesRequiredFlag: (state) => state.approvalState === 'DENY',
  getApprovalVisibleFlag: (state) => isApprovalSubmissionAvailable(state.model),
  getApprovalSubmitFlag(state) {
    if (isApprovalSubmissionAvailable(state.model)) {
      if (isStringNullOrEmpty(state.approvalState)) { return false; }
      if (state.approvalState === 'DENY' && isStringNullOrEmpty(state.approvalNotes)) { return false; }
      return true;
    }
    return false;
  },
  getApprovalSubmitText: (state) => state.approvalState ? `Submit (${state.approvalState})` : 'State Required',
};

const actions = {
  changeStatusDialogVisiblility({ commit, dispatch }, state, tag) {
    if (!state.statusDialogVisible) {
      dispatch('loadTagStatuses', state, tag);
      state.timer = setInterval(() => {
        dispatch('incrementStatusTableCounterAction');
      }, 1000);
    } else {
      clearInterval(state.timer);
    }
    commit('setStatusDialogVisiblility');
  },
  loadTagStatuses({ commit }, state, tag) {
    let requestID = 0;
    if (tag.currentVersion_requestID !== null) { requestID = tag.currentVersion_requestID; }
    ETAG_API.get(`/${tag.routeEntity}/${tag.routeTagID}/requests/${requestID}/statuses`).then((response) => {
      commit('setStatusData', response.data);
    }).catch((error) => {
      console.log(error);
    });
  },
  changeRefreshRateAction({ commit }, value) {
    commit('statusTableRefreshRateMutation', value);
  },
  incrementCounterAction({ dispatch, commit }, state, tag) {
    if (state.counter >= state.refreshRate) {
      dispatch('loadTagStatuses', state, tag);
      commit('statusTableResetCounterMutation');
    } else {
      commit('statusTableIncrementCounterMutation');
    }
  },
  changeApprovalState({ commit }, item) {
    commit('setApprovalState', item);
  },
  changeApprovalNotes({ commit }, value) {
    commit('setApprovalNotes', value);
  },
  submitApprovalState({ commit }, state, model, tagName) {
    model.requestRef = state.model.requestRef;
    model.notes = state.approvalNotes;
    if (state.approvalState === 'APPROVE') model.approvalStatus = 'APPROVED';
    else if (state.approvalState === 'DENY') model.approvalStatus = 'DENIED';
    else model.approvalStatus = 'STUDY';
    ETAG_API.post(`/${entity}/${tagName}/requests/${model.requestRef}/statuses`, model).then((response) => {
      console.log(response);
    }).catch((error) => {
      console.log(error);
    });
  },
};

const mutations = {
  initialize(state) {
    state.approvalNotes = null;
    state.approvalState = 'APPROVE';
    state.model = null;
  },
  setData(state, item) {
    const data = item.statuses;
    dateStore.formatDateTimeFields(data, state.dateTimeFormats);

    data.forEach((d) => {
      d.entity_name = `${d.entity} (${d.entityType})`;
    });
    state.tableData = data;
    state.model = item;
  },
  clear(state) {
    state.tableData = [];
  },
  setStatusDialogVisiblility(state) {
    state.statusDialogVisible = !state.statusDialogVisible;
    if (!state.statusDialogVisible) {
      state.timer = null;
      state.tableData = [];
    }
  },
  statusTableRefreshRateMutation(state, value) {
    state.refreshRate = value;
  },
  statusTableResetCounterMutation(state) {
    state.counter = 1;
  },
  statusTableIncrementCounterMutation(state) {
    state.counter += 1;
  },
  setApprovalState(state, item) {
    state.approvalState = item;
  },
  setApprovalNotes(state, value) {
    state.approvalNotes = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};