// import referenceData from '@/components/ContractBilling/ReferenceData/store';
// import sourceData from '@/components/ContractBilling/SourceData/store';
// import settlements from '@/components/ContractBilling/Settlements/store';
// import invoicing from '@/components/ContractBilling/Invoicing/store';

// export default {
//   namespaced: true,
//   state: {},
//   modules: {
//     referenceData,
//     sourceData,
//     settlements,
//     invoicing,
//   },
// };


import schedulerStore from './Scheduler/schedulerStore';
import executionLogStore from './ConnectionMessages/executionLogStore';

export default {
  namespaced: true,
  state: {},
  modules: {
    scheduler: schedulerStore,
    executionLog: executionLogStore,
  },
};
