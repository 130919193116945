export default {
  configData: {
    fields: [
      {
        caption: 'BD_ORDER',
        dataField: 'orderNum',
        dataType: 'number',
        area: 'row',
        areaIndex: 0,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_NAME',
        dataField: 'bdName',
        dataType: 'string',
        area: 'row',
        areaIndex: 3,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BPM_NAME',
        dataField: 'bpmName',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_GROUP',
        dataField: 'bdGroup',
        dataType: 'string',
        area: 'row',
        areaIndex: 1,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_CISO_GROUP',
        dataField: 'bdCisoGroup',
        dataType: 'string',
        area: 'row',
        areaIndex: 2,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_CALC_SOURCE',
        dataField: 'bdCalcSource',
        dataType: 'string',
        area: 'filter',
        areaIndex: 2,
        expanded: true,
        visible: true,
      },
      {
        caption: 'RSRC_ID',
        dataField: 'rsrcId',
        area: 'column',
      },
      {
        caption: 'TRADING_DATE',
        dataField: 'dt',
        dataType: 'datetime',
        area: 'filter',
        format: 'MM/DD/YYYY',
        expanded: true,
      },
      {
        caption: 'TRADING_HOUR',
        dataField: 'he',
        dataType: 'number',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'TRADING_INTERVAL',
        dataField: 'ie',
        dataType: 'number',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'COMPARE',
        dataField: 'compareId',
        dataType: 'string',
        area: 'row',
        areaIndex: 999,
        expanded: true,
      },
      {
        caption: 'COMPARE_TYPE',
        dataField: 'compareType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
        areaIndex: '999',
      },
      {
        caption: 'VALUE',
        dataField: 'val',
        dataType: 'number',
        area: 'data',
        expanded: true,
        summaryType: 'sum',
        format: '#.##',
        compareFiles: true,
      },
    ],
  },
};