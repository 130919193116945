import moment from 'moment';
import utils from '@/utils';
import dateStore from '@/utils/dateStore';
import { has } from '@/utils/dataUtil';
import { ETRM_API } from '@/api';

export default {
  namespaced: true,
  state: {
    notificationCallback: null,
    filterDateRange: [dateStore.getDefaultRange()[0].utc(), dateStore.getDefaultRange()[1].utc()],
    currentSettlementRow: null,
    settlements: [],
    settlementsConfig: {
      columns: [{
        prop: 'transactionId', label: 'Deal#', filter: true, width: 75, editable: false, checkboxSelection: true, cellClass: 'cell-uneditable',
      }, {
        prop: 'commodityName', label: 'Commodity', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'marketName', label: 'Market', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'dealType', label: 'Deal Type', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'company', label: 'Company', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'startDate',
        label: 'Start Dt',
        filter: true,
        width: 120,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          return moment(params.value).format('YYYY-MM-DD');
        },
      }, {
        prop: 'endDate',
        label: 'End Dt',
        filter: true,
        width: 120,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          return moment(params.value).format('YYYY-MM-DD');
        },
      }, {
        prop: 'totalAmount',
        label: 'Amount',
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function invoiceAmountValueFormatter(params) {
          if (params.value == null) {
            return;
          }
          return params.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      }, {
        prop: 'totalUnits', label: 'Volume', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'perUnitValue', label: 'Avg Price', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'invoiceId', label: 'Invoice#', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'internalCompany', label: 'Legal Entity', filter: true, width: 150, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'contract', label: 'Contract', filter: true, width: 200, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'locationType', label: 'Loc Type', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'location', label: 'Location', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'locationType2', label: 'Loc2 Type', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'location2', label: 'Location2', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }],

      // style: {maxHeight: 250, height: 250},
      columnList(name, row) {
        if (name === 'dealType') {
          return [{ value: 'REC', label: 'REC' },
            { value: 'TREC', label: 'TREC' }];
        }
        if (name === 'market') {
          return this.registry.marketList;
        }
        if (name === 'company') {
          return this.registry.companyList;
        }
        if (name === 'contact') {
          return this.registry.contactList
            .filter((val) => val.company === row.company)
            .map((m) => ({ value: m.contactNm, label: m.contactNm }));
        }
        if (name === 'user') {
          return this.registry.userList;
        }
        if (name === 'portfolio') {
          return this.registry.portfolioList;
        }
        if (name === 'buySellInd') {
          return [{ value: 'BUY', label: 'BUY' }, { value: 'SELL', label: 'SELL' }];
        }
        if (name === 'timeZone') {
          return [{ value: 'CPT', label: 'CPT' },
            { value: 'EPT', label: 'EPT' },
            { value: 'MPT', label: 'MPT' },
            { value: 'PPT', label: 'PPT' },
          ];
        }
      },
      columnMapList(watcher, row) {
        const columnMappings = [
          {
            name: 'companyToContact',
            values: this.registry.contactList,
          },
        ];
        return utils.data.columnSelectList(columnMappings, watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
      },
      options: {
        searchFilter: false,
      },
      registry: {
        marketList: [],
        companyList: [],
        contactList: [],
        portfolioList: [],
        userList: [],
      },
    },
    nullSettlementRow: {
      selected: false,
      intrnlCompany: null,
      commodityNm: null,
      marketNm: null,
      company: null,
      nettingAllowed: false,
      contract: null,
      dealType: null,
      buyCount: null,
      sellCount: null,
      accountsPayable: null,
      accountsReceivable: null,
      settlements: [],
    },
    settlementDetails: [],
    settlementDetailsConfig: {
      columns: [
        {
          prop: 'id', label: 'Line ID', visible: true, filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'lineRecordType', label: 'Record Type', visible: false, filter: true, width: 75, editable: false,
        }, {
          prop: 'lineItemType', label: 'Item Desc', filter: true, width: 200, editable: false,
        }, {
          prop: 'transactionId', label: 'Trade ID', visible: true, filter: true, width: 75, editable: false,
        }, {
          prop: 'startDate',
          label: 'Start Dt',
          filter: true,
          width: 100,
          editable: false,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD');
          },
        }, {
          prop: 'endDate',
          label: 'End Dt',
          filter: true,
          width: 100,
          editable: false,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD');
          },
        }, {
          prop: 'recPayInd', label: 'AR/AP', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'perUnitValue',
          label: 'Unit Cost',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function unitCostValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          },
        }, {
          prop: 'totalUnits',
          label: 'Total Units',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function totalUnitsValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString(undefined, { maximumFractionDigits: 0 });
          },
        }, {
          prop: 'totalAmount',
          label: 'Total Amt',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function totalAmountValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          },
        }, {
          prop: 'location', label: 'Location', filter: true, width: 150, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'location2', label: 'Location 2', filter: true, width: 150, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'createdDate',
          label: 'Created Dt',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD HH:mm');
          },
        }, {
          prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'updatedDate',
          label: 'Updated Dt',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD HH:mm');
          },
        }],
      style: { height: 200 },
      columnList(name) {},
      options: {
        searchFilter: false,
        warningHighlight: true,
        disableXAxisFill: true,
        disableYAxisFill: true,
      },
      registry: {},
      columnCellEditable(row, column) {
        if (column.editable === false) { return false; }
        return true;
      },
    },
    nullSettlementDetailsRow: {
      settlementId: null,
      settleDate: null,
      counterparty: null,
      company: null,
      portfolio: null,
      contract: null,
      transactionId: null,
      commodityNm: null,
      marketNm: null,
      dealType: null,
      startDate: null,
      endDate: null,
      invoiceId: null,
      recPayInd: null,
      totalAmount: null,
      perUnitValue: null,
      totalUnits: null,
      isActive: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      recordState: 1,
    },
    filterIntrnlCompanies: [],
    filterCommodities: [],
    filterMarkets: [],
    selectedIntrnlCompany: null,
    selectedCommodity: null,
    selectedMarket: null,
  },
  getters: {
    getFilterDateRange: (state) => state.filterDateRange,
    getSettlementConfig: (state) => state.settlementsConfig,
    getSettlementData: (state) => state.settlements,
    getSettlementCurrentRow: (state) => state.currentSettlementRow,
    getFilterIntrnlCompanies: (state) => state.filterIntrnlCompanies,
    getFilterCommodities: (state) => state.filterCommodities,
    getFilterMarkets: (state) => state.filterMarkets,
    getSelectedIntrnlCompany: (state) => state.selectedIntrnlCompany,
    getSelectedCommodity: (state) => state.selectedCommodity,
    getSelectedMarket: (state) => state.selectedMarket,
  },
  actions: {
    initialize({ commit, dispatch, state }) {
      dispatch('retrieveCompanyList');
      dispatch('retrieveMarketList');
      dispatch('retrieveEnergyCommodityList');
    },
    intrnlCompanyFilterChanged({ dispatch, commit, state }, data) {
      commit('intrnlCompanyFilterChanged', data);
    },
    commodityFilterChanged({ dispatch, commit, state }, data) {
      commit('commodityFilterChanged', data);
    },
    marketFilterChanged({ dispatch, commit, state }, data) {
      commit('marketFilterChanged', data);
    },
    notify({ state }, msg) {
      state.notificationCallback(msg);
    },
    reset({ commit, dispatch }) {
      commit('setNotificationCallback', null);
      commit('reset');
    },
    resetTable({ dispatch, commit, state }) {
      commit('resetTable');
    },
    async createInvoices({ dispatch, commit, state }, selectedRows) {
      const outputMsg = null;
      const tz = dateStore.getTimeZone();
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.filterDateRange, tz);
      const start = momentRange[0].startOf('day').utc();
      const end = momentRange[1].startOf('day').add(1, 'days').utc();
      console.log('selectedRows'); console.log(selectedRows);

      const response = await ETRM_API.put('/invoice-settlements', { invoiceStartDt: start.format(), invoiceEndDt: end.format(), data: selectedRows }());
      const returnRecord = response.data; console.log('response out'); console.log(response);
      return new Promise((resolve, reject) => {
        resolve(outputMsg);
      });
    },
    async loadSettlementData({ dispatch, commit, state }) {
      const tz = dateStore.getTimeZone();
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.filterDateRange, tz);
      const start = momentRange[0].startOf('day').utc();
      const end = momentRange[1].startOf('day').add(1, 'days').utc();
      let urlString = `/invoice-settlements?startDateRange=${start.format()}&endDateRange=${end.format()}`;
      if (state.selectedIntrnlCompany != null) urlString += `&internalCompanyId=${state.selectedIntrnlCompany}`;
      if (state.selectedCommodity != null) urlString += `&commodityName=${state.selectedCommodity}`;
      if (state.selectedMarket != null) urlString += `&marketId=${state.selectedMarket}`;
      const response = await ETRM_API.get(urlString);
      // const response = await ETRM_API.get(`/invoice-settlements/startFlow/${start.format()}/endFlow/${end.format()}`);
      const { data } = response; console.log('settlement data'); console.log(data);
      commit('loadSettlementData', data);
    },
    changeFilterDateRangeAction({ commit, dispatch }, value) {
      commit('changeFilterDateRangeMutation', value);
    },
    changeCheckAllAction({ commit, dispatch }, value) {
      commit('changeCheckAllMutation', value);
    },
    async saveTableData({ dispatch, commit, state }) {
      const dirtyRecs = state.settlements.filter((val) => val.recordState != 0);
      let outputMsg = null;
      let success = 0;
      let failure = 0;
      console.log('dirtyRecs'); console.log(JSON.stringify(dirtyRecs));
      for (let d = 0; d < state.deleteCache.length; d++) {
        const deleteData = state.deleteCache[d];
        const response = await ETRM_API.delete(`/invoice-settlements/${data.id}`);

        if (response.status === 200) {
          success += 1;
          state.deleteCache.splice(deleteData, 1);
        } else {
          outputMsg = `Save record(s) failed to post delete. ${response.statusText}`;
          break;
        }
      }

      for (let i = 0; i < dirtyRecs.length; i++) {
        const data = dirtyRecs[i];
        console.log('data'); console.log(data);
        if (data.recordState === 1) {
          const response = await ETRM_API.post('/invoice-settlements', data);
          const returnRecord = response.data; console.log('response out'); console.log(response);
          if (response.status === 200) {
            const idx = state.settlements.findIndex((x) => x.id == data.id);
            console.log(`idx=${idx}`);
            state.settlements.splice(idx, 1, returnRecord);
            console.log('return record out'); console.log(returnRecord);
            if (returnRecord.validationMessage == null) {
              success += 1;
            } else {
              failure += 1;
            }
          } else {
            outputMsg = `Save record(s) failed to post. ${response.statusText}`;
            break;
          }
        } else if (data.recordState === 2) {
          const response = await ETRM_API.put(`/invoice-settlements/${data.id}`, data);
          const returnRecord = response.data; console.log('response out'); console.log(response);
          if (response.status === 200) {
            const idx = state.settlements.findIndex((x) => x.id == data.id);
            state.settlements.splice(idx, 1, returnRecord);
            console.log('return record out'); console.log(returnRecord);
            if (returnRecord.validationMessage == null) {
              success += 1;
            } else {
              failure += 1;
            }
          } else {
            outputMsg = `Save record(s) failed to post. ${response.statusText}`;
            break;
          }
        }
        outputMsg = `${success} record(s) saved successfully. `;
        if (failure > 0) {
          outputMsg = `${outputMsg + failure} record(s) failed to save due to error.`;
        }
      }
      console.log(`outputMsg=${outputMsg}`);
      return new Promise((resolve, reject) => {
        resolve(outputMsg);
      });
    },
    currentSettlementRowChange({ dispatch, commit, state }, selectedRows) {

    },
  },
  mutations: {
    reset(state) {

    },
    setNotificationCallback(state, callback) {
      state.notificationCallback = callback;
    },
    readyRegistryCompanyList(state, data) {
      state.filterIntrnlCompanies = state.companyList.filter((val) => val.internalFlag).map((m) => ({ value: m.id, label: m.shortName }));
      state.selectedIntrnlCompany = state.filterIntrnlCompanies[0].value;
    },
    readyRegistryEnergyCommodityList(state, data) {
      state.filterCommodities = state.energyCommodityList.map((m) => ({ value: m.id, label: m.shortName }));
      state.selectedCommodity = 'POWER';
    },
    readyRegistryMarketList(state, data) {

    },
    intrnlCompanyFilterChanged(state, data) {
      state.selectedIntrnlCompany = data;
    },
    commodityFilterChanged(state, data) {
      state.selectedCommodity = data;
      state.filterMarkets = state.marketList.filter((val) => val.commodityName === state.selectedCommodity).map((m) => ({ value: m.id, label: m.shortName }));
    },
    marketFilterChanged(state, data) {
      state.selectedMarket = data;
    },
    resetTable(state) {
      state.settlements = [];
      state.currentSettlementRow = null;
    },
    loadSettlementData(state, model) {
      state.settlements = [];
      state.currentSettlementRow = null;

      let settlements = [];
      if (model !== null && has(model, 'invoiceSettlements')) {
        settlements = model.invoiceSettlements; console.log('setting invoices'); console.log(settlements);
      }
      state.settlements = settlements;
    },
    changeFilterDateRangeMutation(state, value) {
      state.filterDateRange = value;
    },
    changeCheckAllMutation(state, value) {
      for (let x = 0; x < state.settlements.length; x++) {
        const settlement = state.settlements[x]; console.log('check all value'); console.log(value);
        settlement.generateFlag = value;
      }
    },
  },
};