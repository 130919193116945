import moment from 'moment';
import utils from '@/utils';
import { has, clone } from '@/utils/dataUtil';
import { ETRM_API } from '@/api';

export default {
  namespaced: true,
  state: {
    notificationCallback: null,
    startMonthFilter: '201807',
    endMonthFilter: '201807',
    currentInvoiceRow: null,
    currenLineItemRow: null,
    currentNoteRow: null,
    currentPaymentRow: null,
    deletePaymentCache: [],
    deleteNoteCache: [],
    pendingNoteRow: {
      noteId: null,
      transactionId: null,
      department: null,
      title: null,
      body: null,
    },
    invoices: [],
    lineItems: [],
    payments: [],
    notes: [],
    noteDialogVisibility: false,
    invoicesConfig: {
      columns: [{
        prop: 'id', label: 'Invoice#', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'accountingMonth', label: 'Acct Month', filter: true, width: 120, editable: false,
      }, {
        prop: 'lockedFlag',
        label: 'Locked?',
        filter: true,
        width: 100,
        editable: true,
        cellEditor: 'checkboxCellEditor',
        cellRenderer: 'checkboxCellRenderer',
      }, {
        prop: 'intrnlCompany', label: 'Legal Entity', filter: true, width: 200, editable: false,
      }, {
        prop: 'invoiceDate',
        label: 'Invoice Dt',
        filter: true,
        width: 120,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'clearedDate',
        label: 'Cleared Dt',
        filter: true,
        width: 120,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'company', label: 'Counterparty', filter: true, width: 200, editable: false,
      }, {
        prop: 'contract', label: 'Contract', filter: true, width: 200, editable: false,
      }, {
        prop: 'companyInvoiceId', label: 'Cparty Invoice#', filter: true, width: 150, editable: true,
      }, {
        prop: 'startDate',
        label: 'Period Start',
        filter: true,
        width: 120,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'endDate',
        label: 'Period End',
        filter: true,
        width: 120,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'invoiceAmount',
        label: 'Invoice Amount',
        filter: true,
        width: 100,
        editable: false,
        valueFormatter: function invoiceAmountValueFormatter(params) {
          if (params.value == null) {
            return;
          }
          return params.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      }, {
        prop: 'dueDate',
        label: 'Due Dt',
        filter: true,
        width: 100,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD');
        },
      }, {
        prop: 'appliedPayment',
        label: 'Applied Payment',
        filter: true,
        width: 100,
        editable: false,
        valueFormatter: function appliedPaymentValueFormatter(params) {
          if (params.value == null) {
            return;
          }
          return params.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      }, {
        prop: 'commodityName', label: 'Commodity', filter: true, width: 100, editable: false,
      }, {
        prop: 'marketName', label: 'Market', filter: true, width: 100, editable: false,
      }, {
        prop: 'intrnlMrktParticipant', label: 'Intrnl Participant', filter: true, width: 100, editable: false,
      }, {
        prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'createdDate',
        label: 'Created Dt',
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }, {
        prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'updatedDate',
        label: 'Updated Dt',
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      },
      ],

      style: { maxHeight: 250, height: 250 },

      columnList(name, row) {
        if (name === 'dealType') {
          return [{ value: 'REC', label: 'REC' },
            { value: 'TREC', label: 'TREC' }];
        }
        if (name === 'market') return this.registry.marketList;
        if (name === 'company') return this.registry.companyList;
        if (name === 'contact') {
          return this.registry.contactList
            .filter((val) => val.company === row.company)
            .map((m) => ({ value: m.contactNm, label: m.contactNm }));
        }
        if (name === 'user') return this.registry.userList;
        if (name === 'portfolio') return this.registry.portfolioList;
        if (name === 'buySellInd') return [{ value: 'BUY', label: 'BUY' }, { value: 'SELL', label: 'SELL' }];
        if (name === 'timeZone') {
          return [{ value: 'CPT', label: 'CPT' },
            { value: 'EPT', label: 'EPT' },
            { value: 'MPT', label: 'MPT' },
            { value: 'PPT', label: 'PPT' },
          ];
        }
      },
      columnMapList(watcher, row) {
        const columnMappings = [{
          name: 'companyToContact',
          values: this.registry.contactList,
        }];
        return utils.data.columnSelectList(columnMappings, watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
      },
      options: {
        searchFilter: false,
      },
      registry: {
        marketList: [],
        companyList: [],
        contactList: [],
        portfolioList: [],
        userList: [],
      },
    },
    nullInvoiceRow: {
      id: null,
      versionId: null,
      accountingMonth: null,
      lockedFlag: false,
      intrnlCompanyId: null,
      intrnlCompany: null,
      contractId: null,
      contract: null,
      invoiceDate: null,
      companyId: null,
      company: null,
      startDate: null,
      endDate: null,
      invoiceAmount: null,
      appliedPayment: null,
      dueDate: null,
      clearedDate: null,
      commodityName: null,
      marketId: null,
      marketName: null,
      intrnlMrktParticipantId: null,
      intrnlMrktParticipant: null,
      activeFlag: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      recordState: 1,
      invoiceLineItems: [],
      invoicePayments: [],
      notes: [],
      validationMessage: null,
    },
    lineItemsConfig: {
      columns: [
        {
          prop: 'id', label: 'Line ID', visible: true, filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'lineRecordType', label: 'Record Type', visible: false, filter: true, width: 75, editable: false,
        }, {
          prop: 'lineItemType', label: 'Item Desc', filter: true, width: 200, editable: false,
        }, {
          prop: 'transactionId', label: 'Trade ID', visible: true, filter: true, width: 75, editable: false,
        }, {
          prop: 'startDate',
          label: 'Start Dt',
          filter: true,
          width: 100,
          editable: false,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD');
          },
        }, {
          prop: 'endDate',
          label: 'End Dt',
          filter: true,
          width: 100,
          editable: false,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD');
          },
        }, {
          prop: 'recPayInd', label: 'AR/AP', filter: true, width: 100, editable: true,
        }, {
          prop: 'perUnitValue',
          label: 'Unit Cost',
          filter: true,
          width: 100,
          editable: true,
          valueFormatter: function unitCostValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          },
        }, {
          prop: 'totalUnits',
          label: 'Total Units',
          filter: true,
          width: 100,
          editable: true,
          valueFormatter: function totalUnitsValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString(undefined, { maximumFractionDigits: 0 });
          },
        }, {
          prop: 'totalAmount',
          label: 'Total Amt',
          filter: true,
          width: 100,
          editable: true,
          valueFormatter: function totalAmountValueFormatter(params) {
            if (params.value == null) {
              return;
            }
            return params.value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            });
          },
        }, {
          prop: 'location', label: 'Location', filter: true, width: 150, editable: false,
        }, {
          prop: 'location2', label: 'Location 2', filter: true, width: 150, editable: false,
        }, {
          prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'createdDate',
          label: 'Created Dt',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD HH:mm');
          },
        }, {
          prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
        }, {
          prop: 'updatedDate',
          label: 'Updated Dt',
          filter: true,
          width: 100,
          editable: false,
          cellClass: 'cell-uneditable',
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) {
              return;
            }
            const momentTm = moment(params.value);
            return momentTm.format('YYYY-MM-DD HH:mm');
          },
        }],
      style: { height: 200 },
      columnList(name) {},
      options: {
        searchFilter: false,
        warningHighlight: true,
        disableXAxisFill: true,
        disableYAxisFill: true,
      },
      registry: {},
      columnCellEditable(row, column) {
        if (column.editable === false) return false;
        return true;
      },
    },
    nullLineItemRow: {
      id: null,
      versionId: null,
      invoiceId: null,
      lineRecordType: null,
      lineItemType: null,
      transactionId: null,
      startDate: null,
      endDate: null,
      locationId: null,
      locationId2: null,
      recPayInd: null,
      totalAmount: null,
      perUnitValue: null,
      totalUnits: null,
      activeFlag: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      recordState: 1,
    },
    paymentsConfig: {
      columns: [{
        prop: 'id', label: 'Payment ID', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'recPayInd', label: 'AR/AP', filter: true, width: 75, editable: false,
      }, {
        prop: 'paymentMethod', label: 'Payment Method', filter: true, width: 150, editable: false,
      }, {
        prop: 'transactionRefId', label: 'Transaction Ref', filter: true, width: 150, editable: false,
      }, {
        prop: 'paymentDate',
        label: 'Payment Dt',
        visible: false,
        filter: true,
        width: 100,
        editable: false,
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }, {
        prop: 'amount',
        label: 'Amount',
        filter: true,
        width: 100,
        editable: false,
        valueFormatter: function appliedPaymentAmountValueFormatter(params) {
          if (params.value == null) {
            return;
          }
          return params.value.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          });
        },
      }, {
        prop: 'createdBy', label: 'Created By', visible: false, filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'createdDate',
        label: 'Created Dt',
        visible: false,
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }, {
        prop: 'updatedBy', label: 'Updated By', visible: false, filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'updatedDate',
        label: 'Updated Dt',
        visible: false,
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      },
      ],
      options: { searchFilter: false },
    },
    nullPaymentRow: {
      id: null,
      versionId: null,
      invoiceId: null,
      recPayInd: null,
      paymentMethod: null,
      transactionRefId: null,
      paymentDate: null,
      amount: null,
      activeFlag: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      recordState: 1,
    },
    notesConfig: {
      columns: [{
        prop: 'id', label: 'ID', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'versionId', label: 'Ver#', filter: true, width: 75, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'externalFlag',
        label: 'External?',
        filter: true,
        width: 75,
        editable: false,
        cellEditor: 'checkboxCellEditor',
        cellRenderer: 'checkboxCellRenderer',
      }, {
        prop: 'title', label: 'Title', filter: true, width: 150, editable: false,
      }, {
        prop: 'body', label: 'Message', filter: true, width: 450, editable: false,
      }, {
        prop: 'createdBy', label: 'Created By', visible: false, filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'createdDate',
        label: 'Created Dt',
        visible: false,
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }, {
        prop: 'updatedBy', label: 'Updated By', visible: false, filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
      }, {
        prop: 'updatedDate',
        label: 'Updated Dt',
        visible: false,
        filter: true,
        width: 100,
        editable: false,
        cellClass: 'cell-uneditable',
        valueFormatter: function dateValueGetter(params) {
          if (params.value == null) {
            return;
          }
          const momentTm = moment(params.value);
          return momentTm.format('YYYY-MM-DD HH:mm');
        },
      }],
      options: { searchFilter: false },
    },
    nullNoteRow: {
      id: null,
      versionId: null,
      invoiceId: null,
      externalFlag: null,
      title: null,
      body: null,
      activeFlag: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
      recordState: 1,
    },
  },
  getters: {
    getStartMonthFilter: (state) => state.startMonthFilter,
    getEndMonthFilter: (state) => state.endMonthFilter,

    getInvoiceConfig: (state) => state.invoicesConfig,
    getInvoiceData: (state) => state.invoices,
    getInvoiceCurrentRow: (state) => state.currentInvoiceRow,

    getLineItemConfig: (state) => state.lineItemsConfig,
    getLineItemData: (state) => state.lineItems,
    getLineItemCurrentRow: (state) => state.currentLineItemRow,

    getPaymentConfig: (state) => state.paymentsConfig,
    getPaymentData: (state) => state.payments,
    getPaymentCurrentRow: (state) => state.currentPaymentRow,

    getNoteConfig: (state) => state.notesConfig,
    getNoteData: (state) => state.notes,
    getNoteDialogVisibility: (state) => state.noteDialogVisibility,
    getPendingNote: (state) => state.pendingNoteRow,

    getPendingNoteTitle: (state) => state.pendingNoteRow.title,
    getPendingNoteBody: (state) => state.pendingNoteRow.body,
  },
  actions: {
    initialize({ commit, dispatch, state }) {

    },
    notify({ state }, msg) {
      state.notificationCallback(msg);
    },
    reset({ commit, dispatch }) {
      commit('setNotificationCallback', null);
      commit('reset');
    },
    async saveTableData({ dispatch, commit, state }) {
      const dirtyRecs = state.invoices.filter((val) => val.recordState != 0);
      let outputMsg = null;
      let success = 0;
      let failure = 0;
      console.log('dirtyRecs'); console.log(JSON.stringify(dirtyRecs));
      for (let d = 0; d < state.deleteCache.length; d++) {
        const deleteData = state.deleteCache[d];
        const response = await ETRM_API.delete(`/invoices/${deleteData.id}`);
        if (response.status == 200) {
          success += 1;
          state.deleteCache.splice(deleteData, 1);
        } else {
          outputMsg = `Save record(s) failed to post delete. ${response.statusText}`;
          break;
        }
      }
      for (let i = 0; i < dirtyRecs.length; i++) {
        const data = dirtyRecs[i];
        console.log('data'); console.log(data);
        if (data.recordState == 1) {
          const response = await ETRM_API.post('/invoices', data);
          const returnRecord = response.data; console.log('response out'); console.log(response);
          if (response.status === 200) {
            const idx = state.invoices.findIndex((x) => x.id == data.id);
            console.log(`idx=${idx}`);
            state.invoices.splice(idx, 1, returnRecord);
            console.log('return record out'); console.log(returnRecord);
            if (returnRecord.validationMessage == null) success += 1;
            else failure += 1;
          } else {
            outputMsg = `Save record(s) failed to post. ${response.statusText}`;
            break;
          }
        } else if (data.recordState == 2) {
          const response = await ETRM_API.put(`/invoices/${data.id}`, data);
          const returnRecord = response.data; console.log('response out'); console.log(response);
          if (response.status === 200) {
            const idx = state.invoices.findIndex((x) => x.id == data.id);
            state.invoices.splice(idx, 1, returnRecord);
            console.log('return record out'); console.log(returnRecord);
            if (returnRecord.validationMessage == null) success += 1;
            else failure += 1;
          } else {
            outputMsg = `Save record(s) failed to post. ${response.statusText}`;
            break;
          }
        }
        outputMsg = `${success} record(s) saved successfully. `;
        if (failure > 0) {
          outputMsg = `${outputMsg + failure} record(s) failed to save due to error.`;
        }
      }
      console.log(`outputMsg=${outputMsg}`);
      return new Promise((resolve, reject) => resolve(outputMsg));
    },
    resetTable({ dispatch, commit, state }) {
      commit('resetTable');
    },
    async loadInvoiceData({ dispatch, commit, state }) {
      const response = await ETRM_API.get(`/accountingData/invoices/startMonth/${state.startMonthFilter}/endMonth/${state.endMonthFilter}/company/`);
      const { data } = response;
      commit('loadInvoiceData', data);
    },
    changeInvoiceData({ dispatch, commit, state }, item) {
      commit('changeInvoiceData', item);
    },
    changeTradeDateRangeAction({ commit, dispatch }, value) {
      commit('changeTradeDateRangeMutation', value);
    },
    currentInvoiceRowChange({ dispatch, commit, state }, selectedRows) {
      const currentRow = selectedRows[0];
      if (state.currentInvoiceRow === undefined || (currentRow !== undefined && currentRow.id !== state.currentInvoiceRow.id)) {
        state.currentInvoiceRow = currentRow;
        state.lineItems = [];
        state.payments = [];
        state.notes = [];
        dispatch('loadInvoiceChildren');
      }
    },
    async loadInvoiceChildren({ dispatch, commit, state }) {
      if (state.currentInvoiceRow.recordState !== 1 && (state.currentInvoiceRow.invoiceLineItems === undefined || state.currentInvoiceRow.invoiceLineItems === null
            || state.currentInvoiceRow.invoiceLineItems.length === 0)) {
        const response = await ETRM_API.get(`/accountingData/invoice/${state.currentInvoiceRow.id}/children`);
        commit('loadLineItemData', response.data);
        commit('loadPaymentData', response.data);
        commit('loadNoteData', response.data);
      } else {
        commit('loadLineItemData', state.currentInvoiceRow);
        commit('loadPaymentData', state.currentInvoiceRow);
        commit('loadNoteData', state.currentInvoiceRow);
      }
    },
    async loadLineItemData({ dispatch, commit, state }) {
      if (state.currentInvoiceRow.recordState !== 1 && (state.currentInvoiceRow.invoiceLineItems === undefined || state.currentInvoiceRow.invoiceLineItems === null
        || state.currentInvoiceRow.invoiceLineItems.length === 0)) {
        const lineItemResponse = await ETRM_API.get(`/accountingData/invoice/${state.currentInvoiceRow.id}/invoiceLineItems`);
        commit('loadLineItemData', lineItemResponse.data);
      } else {
        commit('loadLineItemData', state.currentInvoiceRow);
      }
    },
    changeLineItemData({ dispatch, commit, state }, item) {
      console.log(`setting term data item ${item.prop}`); console.log(item.value);
      commit('changeTermData', item);
    },
    currentLineItemRowChange({ dispatch, commit, state }, selectedRows) {
      const currentRow = selectedRows[0];
      if (state.currentTermRow === undefined || (currentRow !== undefined && currentRow.id !== state.currentLineItemRow.id)) {
        state.currentLineItemRow = currentRow;
        let invoice = null;
        for (let t = 0; t < state.invoices.length; t++) {
          invoice = state.invoices[t];
          if (invoice.id === state.currentLineItemRow.id) {
            break;
          }
        }
        let lineItem = null;
        for (let t = 0; t < invoice.invoiceLineItems.length; t++) {
          lineItem = invoice.invoiceLineItems[t];
          if (lineItem.id === state.currentLineItemRow.id && lineItem.id === state.currentLineItemRow.id) {
            break;
          }
        }
      }
    },
    async loadPaymentData({ commit, state }) {
      const response = await ETRM_API.get(`/accountingData/invoice/${state.currentInvoiceRow.id}/invoicePayments`);
      const { data } = response;
      commit('loadPaymentData', data);
    },
    currentNoteRowChange({ dispatch, commit, state }, selectedRows) {
      const currentRow = selectedRows[0];
      state.currentNoteRow = currentRow;
    },
    async loadNoteData({ commit, state }) {
      const response = await ETRM_API.get(`/accountingData/invoice/${state.currentInvoiceRow.id}/notes`);
      const { data } = response;
      commit('loadNoteData', data);
    },
    changeNoteData({ dispatch, commit, state }, item) {
      commit('changeNoteData', item);
    },
    insertNoteRow({ dispatch, commit, state }) {
      commit('insertNoteRow');
    },
    deleteNoteRow({ dispatch, commit, state }) {
      commit('deleteNoteRow');
    },
    cancelNote({ dispatch, commit, state }) {
      commit('cancelNote');
    },
    confirmNote({ dispatch, commit, state }) {
      commit('confirmNote');
    },
  },
  mutations: {
    reset(state) {

    },
    setNotificationCallback(state, callback) {
      state.notificationCallback = callback;
    },
    resetTable(state) {
      state.invoices = [];
      state.lineItems = [];
      state.payments = [];
      state.notes = [];

      state.currentInvoiceRow = null;
      state.currentLineItemRow = null;
      state.currentPaymentRow = null;
      state.currentNoteRow = null;
    },
    loadInvoiceData(state, model) {
      state.invoices = [];
      state.lineItems = [];
      state.payments = [];
      state.notes = [];
      state.currentInvoiceRow = null;
      state.currentLineItemRow = null;
      state.currentPaymentRow = null;
      state.currentNoteRow = null;
      let invoices = [];
      if (model !== null && has(model, 'invoices')) {
        invoices = model.invoices;
      }
      state.invoices = invoices;
    },
    loadLineItemData(state, model) {
      let lineItems = [];
      if (model !== null && has(model, 'invoiceLineItems')) lineItems = model.invoiceLineItems;
      let invoice = null;
      for (let t = 0; t < state.invoices.length; t++) {
        invoice = state.invoices[t];
        if (invoice.id == state.currentInvoiceRow.id) {
          break;
        }
      }
      invoice.invoiceLineItems = lineItems;
      state.lineItems = invoice.invoiceLineItems;
      // state.terms = trade.terms;
    },
    loadPaymentData(state, model) {
      let payments = [];
      if (model !== null && has(model, 'invoicePayments')) payments = model.invoicePayments;
      let invoice = null;
      for (let t = 0; t < state.invoices.length; t++) {
        invoice = state.invoices[t];
        if (invoice.id === state.currentInvoiceRow.id) {
          break;
        }
      }
      invoice.invoicePayments = payments;
      state.payments = invoice.invoicePayments;
    },
    loadNoteData(state, model) {
      let invoice = null;
      for (let t = 0; t < state.invoices.length; t++) {
        invoice = state.invoices[t];
        if (invoice.id === state.currentInvoiceRow.id) {
          break;
        }
      }
      invoice.notes = model.notes;
      state.notes = invoice.notes;
    },
    changeNoteData(state, item) {
      const note = state.currentTradeRow.notes[item.rowIndex];
      note[item.prop] = item.value;
    },
    insertNoteRow(state) {
      state.noteDialogVisibility = true;
    },
    cancelNote(state) {
      state.noteDialogVisibility = false;
    },
    confirmNote(state) {
      state.noteDialogVisibility = false;
      const note = clone(state.nullNoteRow);
      note.id = state.noteKey++;
      note.invoiceId = state.currentInvoiceRow.id;
      note.recordState = 1;
      note.createdBy = this.getters['auth/getProfile'].userName;
      note.createdDate = utils.date.getNow().utc().format();

      note.title = state.pendingNoteRow.title;
      note.externalFlag = state.pendingNoteRow.externalFlag;
      note.body = state.pendingNoteRow.body;
      state.currentNoteRow = note;
      let invoice = null;
      for (let t = 0; t < state.invoices.length; t++) {
        invoice = state.invoices[t];
        if (invoice.id === state.currentInvoiceRow.id) {
          break;
        }
      }
      invoice.notes.splice(0, 0, note);
      state.notes = invoice.notes;
    },
    deleteNoteRow(state) {
      let trade = null;
      let note = null;
      for (let t = 0; t < state.trades.length; t++) {
        trade = state.trades[t];
        if (trade.transactionId === state.currentTradeRow.transactionId) {
          break;
        }
      }
      for (let n = 0; n < trade.notes.length; n++) {
        note = trade.notes[n];
        if (note.noteId == state.currentNoteRow.noteId) {
          note.recordState = 3;
          note.isActive = false;
          note.updatedBy = this.getters['auth/getProfile'].userName;
          note.updatedDate = utils.date.getNow().utc().format();
          state.deleteNoteCache.push(note);
          state.notes.splice(n, 1);
          break;
        }
      }
    },
    changeTradeDateRangeMutation(state, value) {
      state.tradeDateRange = value;
    },
  },
};