import moment from 'moment';
import { CMS_API } from '@/api';

const state = {
  deliverableData: [],
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchDeliverableTypeList', null, { root: true });
    dispatch('lookup/fetchContractCoordinatorList', null, { root: true });
    dispatch('fetchDeliverableData');
  },
  async fetchDeliverableData({ commit }) {
    try {
      const { data } = await CMS_API.get('/deliverables');
      commit('setDeliverableData', data);
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Retrieve Deliverables', 'error');
    }
  },
  async fetchOccurrenceTasks({ state }, {
    startDate, endDate, id, contractGuid,
  }) {
    const params = {
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
      user: this.getters['auth/getProfile'].userName,
    };
    const occurrenceData = await CMS_API.get(`contracts/${contractGuid}/deliverables/${id}/tasks`, { params });
    if (occurrenceData) {
      return occurrenceData.data.deliverableTasks;
    }
    return [];
  },
  async updateDeliverable({ commit, state }, deliverable) {
    try {
      const data = CMS_API.put(`/contracts/${deliverable.contractGuid}/deliverables/${deliverable.id}/reschedule`, deliverable);
      this.$notify(`${deliverable.shortName} Updated`, 'success');
    } catch (error) {
      this.$notify('Failed to Update Deliverable', 'error');
      console.error(error);
    }
  },
  async deleteDeliverableOccurrence({ commit }, deliverable) {
    try {
      await CMS_API.delete(`/contracts/${deliverable.contractGuid}/deliverables/${deliverable.id}`);
      this.$notify('Deliverable Removed', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Delete Deliverable', 'error');
    }
  },
  async addOccurrenceFromDeliverable({ commit, state }, updatedDeliverable) {
    updatedDeliverable.parentId = updatedDeliverable.id;
    if (updatedDeliverable?.recurrenceRule == null || updatedDeliverable?.recurrenceRule?.length <= 0) {
      updatedDeliverable.isRepeat = false;
    }
    try {
      const { data } = await CMS_API.post(`contracts/${updatedDeliverable.contractGuid}/deliverables`, updatedDeliverable);
      commit('updateDeliverableInCalendar', data);
    } catch (error) {
      this.$notify('Failed to Update Deliverable', 'error');
      console.error(error);
    }
  },
};

const mutations = {
  setDeliverableData(state, { deliverables }) {
    state.deliverableData = deliverables;
  },
  updateDeliverableInCalendar(state, deliverable) {
    const rowIndex = state.deliverableData.findIndex(({ startDate }) => startDate === deliverable.startDate);
    state.deliverableData.splice(rowIndex, 1, deliverable);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};