<template>
  <div class="notify-item-container">
    <div v-if="developmentWorkflowLinksFlag" class="development">
      <DxButtonGroup
        :items="items"
        key-expr="name"
        styling-mode="outlined"
        @item-click="itemClicked" />
    </div>
    <div class="notify-header">
      <div class="name">
        {{ item.source }}
      </div>

      <router-link v-if="!!item.subTextRoute" :to="item.subTextRoute">
        {{ item.subText }}
      </router-link>
      <span v-else>{{ item.subText }}</span>
    </div>
    <div class="content">
      <div v-if="item.sound.enabled" class="content-sound">
        <pscs-button-dx
          icon="fas fa-volume-up"
          :disabled="soundButtonDisabled"
          @clicked="disableSoundClicked" />
      </div>
      <div v-if="item.isLoading" class="content-loading">
        <dx-load-indicator
          :height="18"
          :width="18" />
      </div>
      <div class="content-icon">
        <i :class="iconClass" />
      </div>
      <div class="content-message">
        <span v-if="!item.route">{{ item.text }}</span>
        <router-link
          v-if="item.route"
          :to="{ name: item.route.name, params: item.route.params, query: item.route.query }">
          {{ item.text }}
        </router-link>
      </div>
    </div>
    <div class="result">
      <div class="result-link">
        <a v-if="item.error" @click="linkClicked">Errors</a>
        <DxButtonGroup
          :items="item.actions"
          key-expr="name"
          styling-mode="text"
          @item-click="actionClicked" />
      </div>
      <div class="result-time">
        <span>{{ item.formattedTimestamp }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DxButtonGroup } from 'devextreme-vue/ui/button-group';
import { DxLoadIndicator } from 'devextreme-vue/ui/load-indicator';
import NotificationItem from './notificationItem';

export default {
  name: 'PscsNotificationItem',
  components: { DxButtonGroup, DxLoadIndicator },
  props: {
    /**
     * @type {import('vue').PropOptions<NotificationItem>}
     */
    item: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    items: [
      { icon: 'check', name: 'complete', hint: 'Complete' },
      { icon: 'clear', name: 'error', hint: 'Error' },
      { icon: 'video', name: 'start', hint: 'Start' },
    ],
    soundButtonDisabled: false,
  }),
  computed: {
    iconClass() {
      const cssClass = {
        'dx-icon': true,
      };

      if (this.item.isStateErrored) {
        cssClass['dx-icon-clear'] = true;
        cssClass['icon-error'] = true;
      }

      if (this.item.isStateCompleted) {
        cssClass['dx-icon-check'] = true;
        cssClass['icon-completed'] = true;
      }

      return cssClass;
    },
    isWorkflow() {
      return this.item.source === 'WORKFLOW';
    },
    ...mapGetters({
      developmentFlag: 'notification/getDevelopmentFlag',
      developmentWorkflowLinksFlag: 'notification/getDevelopmentWorkflowLinksFlag',
    }),
  },
  methods: {
    itemClicked({ itemData }) {
      if (itemData.name === 'complete') {
        this.item.state = 'COMPLETED';
        this.item.error = null;
      } else if (itemData.name === 'error') {
        this.item.state = 'ERRORED';
      } else {
        this.item.state = 'STARTED';
        this.item.error = null;
      }
    },
    linkClicked(e) {
      this.changeItem(this.item);
    },
    actionClicked({ itemData }) {
      this.item.actionTriggered(itemData.name);
    },
    disableSoundClicked() {
      this.soundButtonDisabled = true;
      this.item.sound.disable();
    },
    ...mapActions({
      removeHandler: 'notification/removeMessage',
      updateMessage: 'notification/updateMessage',
      changeItem: 'notification/changeItem',
    }),
  },
};
</script>

<style lang="scss">

.notify-item-container {

  display: flex;
  flex-direction: column;
  padding-left: 1px;
  padding-right: 1px;
  .notify-header {
    display: flex;
    flex-direction: row;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    justify-content: space-between;
    .workflow {
      font-size: .8em;
    }
  }
  .content {
    padding-top: 2px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .content-loading {
      padding-right: 5px;
    }

    .content-icon {
      padding-right: 5px;
      .dx-icon.icon-error {
        color: $red !important;
      }
      .dx-icon.icon-completed {
        color: $green !important;
      }
      .dx-icon-check {
        font-size: 18px;
      }
      .dx-icon-clear {
        font-size: 18px;
      }

    }

  }
  .result {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dotted lightgray;
    .fa-user-check {
      color: $blue !important;
    }
    .dx-item-selected  {
      background-color: inherit !important;
    }
    .result-link{
      float: left;
      a {
        color: $red;
      }
    }
    .result-time {
      font-size: .8em;
      span {
        bottom: 0;
        right: 0;
      }
    }
  }
}
</style>