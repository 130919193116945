import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import {
  tradeDateRange,
  coordinatorList,
  chargeCodes,
  samcHeaders,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import config from './config';

const state = {
  tableData: [],
  tableConfiguration: config,
  searchParams: {},
  selectedCells: [],
  searchFiltersConfig: {
    samcHeaders,
    tradeDateRange,
    coordinatorList: { ...coordinatorList, hasAll: true },
    chargeCodes: { ...chargeCodes, divider: true, hasAll: true, value: 'ALL' },
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
};

const actions = {
  async fetchStatementSummary({ commit, dispatch }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected === 'ALL' ? parameters.coordinatorList.filter(c => c.value !== 'ALL').map(c => c.value).join(',') : parameters.coordinatorListSelected,
      startDate,
      endDate,
      chargeCode: parameters.chargeCodesSelected,
      fileType: parameters.fileTypesSelected,
      publication: parameters.publicationsSelected,
      fileVersion: parameters.fileVersionsSelected,
      compareFileType: parameters.compareFileTypesSelected,
      comparePublication: parameters.comparePublicationsSelected,
      compareVersion: parameters.compareVersionsSelected,
    };

    const disputeParams = {
      scs: parameters.coordinatorListSelected,
      startDate,
      endDate,
    };

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/stmt-details', { params });
      const { data: { data: disputesData } } = await CISO_SAMC_API.get('/stmt-details/dispute-details', { params: disputeParams });
      if (Array.isArray(data)) {
        data.forEach((row) => {
          row.currentAmount = row.currentAmount === 0 ? null : row.currentAmount;
          row.previousAmount = row.previousAmount === 0 ? null : row.previousAmount;
          row.netAmount = row.netAmount === 0 ? null : row.netAmount;
          row.compareAmount = row.compareAmount === 0 ? null : row.compareAmount;
          row.ptbAmount = row.ptbAmount === 0 ? null : row.ptbAmount;
          row.variance = row.variance === 0 ? null : row.variance;
          row.volume = row.volume === 0 ? null : row.volume;
          row.compareVolume = row.compareVolume === 0 ? null : row.compareVolume;
          row.tzTradingDate = getMomentDateString(row.tzTradingDate);
          row.tradingDate = getMomentDateString(row.tzTradingDate);
          row.nullRow = (!row.currentAmount && !row.previousAmount && !row.compareAmount);
        });
        if (Array.isArray(disputesData)) {
          disputesData.forEach((dispute) => {
            const { isoDisputeId, disputeStatus } = dispute;
            if (isoDisputeId) {
              const currentData = data.find((dataEntry) => dataEntry.chargeCode === dispute.chargeCode);
              const dropDownOption = { name: `${isoDisputeId}: ${disputeStatus}`, data: dispute };
              if (!currentData) { return; }
              if (Array.isArray(currentData.disputes)) {
              // Handle dispute entry > 1
                currentData.disputes.push(`${isoDisputeId}: ${disputeStatus}`);
                currentData.dropDownData.push(dropDownOption);
              } else {
              // Handle first dispute entry
                currentData.disputes = [`${isoDisputeId}: ${disputeStatus}`];
                currentData.dropDownData = [dropDownOption];
              }
            }
          });
        }
        commit('setTableData', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Statement Summary', 'error');
      dispatch('reset');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setSelectedCells(state, value) {
    state.selectedCells = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};