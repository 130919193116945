import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'versionId', label: 'Ver#', sortable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    shortName: null,
    description: null,
    commodityName: 'POWER',
    marketName: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/market-trade-types').then(({ data }) => {
      commit('loadTableData', data.marketTradeTypes);
      this.$notify(`${data.marketTradeTypes.length} Market Trade Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Market Trade Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postMarketTradeType({ state }, tradeType) {
    STRUCTURES_API.post('/market-trade-types', tradeType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Market Trade Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Market Trade Type', 'error');
    });
  },
  updateMarketTradeType({ dispatch, commit, state }, marketTradeType) {
    STRUCTURES_API.put(`/market-trade-types/${marketTradeType.id}`, marketTradeType).then(({ data }) => {
      commit('updateMarketTradeType', data);
      this.$notify('Market Trade Type Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Market Trade Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/market-trade-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Market Trade Type Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Market Trade Type', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  updateMarketTradeType(state, marketTradeType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === marketTradeType.id);
    state.tableData.splice(rowIndex, 1, marketTradeType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, marketTradeTypes) {
    state.tableData = marketTradeTypes;
  },
  createTableRow(state) {
    const marketTradeType = clone(state.nullTableRow);
    marketTradeType.id = state.tableKey++;
    marketTradeType.versionId = 1;
    marketTradeType.createdBy = this.getters['auth/getProfile'].userName;
    marketTradeType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = marketTradeType;
  },

  deleteTableRow(state) {
    state.tableData = state.tableData.filter((marketTradeType) => marketTradeType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};