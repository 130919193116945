import { CISO_CMRI_API } from '@/api';
import config from './marketAwardsConfig';

const state = {
  tableData: [],
  summaryTableData: [],
  tableConfig: config.fields,
  summaryTableConfig: config.summaryFields,
  parameters: [],
};

const actions = {
  async fetchSearchMarketAwards({ commit, state }, { params, isSummary }) {
    commit('setParameters', params);
    commit('setTableData', []);
    commit('setSummaryTableData', []);
    try {
      if (isSummary) {
        const { data } = await CISO_CMRI_API.get('market-awards/hourly-summary',
          {
            headers: { 'api-version': '2.0' },
            params,
          });
        const { messages } = data;
        if (messages && messages[0] === 'A timeout exception occurred.') {
          this.$notify('Data volume caused timeout, please limit your search', 'error');
        } else {
          const result = data.data.data.map((m) => m.data.map((p) => ({ ...p, ...m, data: null })));
          commit('setSummaryTableData', result.flat());
        }
      } else {
        const { data } = await CISO_CMRI_API.get('market-awards', { params });
        const { messages } = data;
        if (messages && messages[0] === 'A timeout exception occurred.') {
          this.$notify('Data volume caused timeout, please limit your search', 'error');
        } else {
          const result = data.data.data.map((m) => m.data.map((p) => ({ ...p, ...m, data: null })));
          commit('setTableData', result.flat());
        }
      }
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.message) {
        console.log(err.message);
      }
      this.$notify('Failed to Load market awards', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setSummaryTableData', []);
  },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = Object.freeze(data);
  },
  setSummaryTableData(state, data) {
    state.summaryTableData = Object.freeze(data);
  },
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setSummaryTableConfig(state, value) {
    state.summaryTableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};