import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_INVOICE_API } from '@/api';
import {
  dateRange, contractGroupList, contractGroupInvoiceEntity, statusTypes, contractGroupTagBox,
} from '@/components/ContractBilling/BaseStore/contractsBase';

const state = {
  tableData: [],
  lineItemData: [],
  commentsData: [],
  summaryData: [],
  selectedRows: [],
  currentTableRow: null,
  editAction: 'Update',
  searchParams: {},
  searchFiltersConfig: {
    dateType: {
      label: 'Invoice Date',
      value: 'invoiceDate',
      type: 'radio',
      options: [
        { value: 'invoiceDate', label: 'Invoice Date' },
        { value: 'tradingDate', label: 'Trade Date' },
        { value: 'ids', label: 'ID' },
      ],
      watchers: [{
        propertyToWatch: 'dateTypeSelected',
        handler(newValue, oldValue) {
          if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.instance.repaint());
          }
          this.$store.commit('ciso/setParams', ['idVisibleSelected', newValue === 'ids']);
          this.$store.commit('ciso/setParams', ['dateVisibleSelected', newValue !== 'ids']);
          return true;
        },
      }],
    },
    ids: {
      label: 'ID(s)',
      value: '',
      type: 'text',
      visible: 'idVisible',
    },
    dateRange: { ...dateRange, visible: 'dateVisible' },
    contractGroupList: { ...contractGroupTagBox, visible: 'dateVisible' },
    invoiceEntityList: { ...contractGroupInvoiceEntity, visible: 'dateVisible' },
    statusTypes: { ...statusTypes, visible: 'dateVisible' },
  },
};

const actions = {
  async fetchApprovalInvoiceData({ commit, state }) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(state.searchParams.dateRangeSelected);
    const params = {
      invoiceEntities: state.searchParams.invoiceEntityListSelected,
      invoiceStatuses: state.searchParams.statusTypesSelected,
      startDate,
      endDate,
      dateType: state.searchParams.dateTypeSelected,
      ids: state.searchParams.idsSelected,
    };

    if (state.searchParams.invoiceEntityListSelected.length === 0 && state.searchParams.contractGroupListSelected.length > 0) {
      params.invoiceEntities = state.searchParams.invoiceEntityList.map((i) => i.id);
    }

    try {
      const { data: { data } } = await BILLING_INVOICE_API.post('/invoices/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.approvedDate = x.approvedDate === null ? null : getMomentDateString(x.approvedDate);
          x.createdDate = getMomentDateString(x.createdDate);
          x.updatedDate = getMomentDateString(x.updatedDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.lineItems.forEach((lineItem) => {
            lineItem.tradingDate = getMomentDateString(lineItem.tradingDate);
          });
          x.summaries.forEach((summary) => {
            summary.tradingDate = getMomentDateString(summary.tradingDate);
          });
          x.statuses.forEach((status) => {
            status.updatedDate = moment(status.updatedDate).format('YYYY-MM-DD HH:mm:ss');
          });
          if (x.interest) {
            x.interest.forEach((interestItem) => {
              x.lineItems.push({
                invoiceId: interestItem.invoiceId,
                chargeCodeName: interestItem.interestDescription,
                currentAmount: interestItem.interestAmount,
                netAmount: interestItem.interestAmount,
                previousAmount: 0,
                contractName: 'Interest',
                settlementCycle: 'Interest',
              });
            });
          }
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async approveRecord({ dispatch }, record) {
    dispatch('putRecord', { record, route: '/invoices/approve' });
  },
  async updateRecord({ dispatch }, record) {
    dispatch('putRecord', { record, route: '/invoices/update' });
  },
  async putRecord({ dispatch }, { record, route }) {
    const importRecords = { Data: record };
    const { data: { successful, message, messageType } } = await BILLING_INVOICE_API.post(route, importRecords);
    dispatch('fetchApprovalInvoiceData');
    if (successful === true) {
      this.$notify('Data Updated', 'success');
    } else {
      this.$notify(message, 'error');
      console.error(message);
    }
  },
  async updateRecordPayment({ dispatch }, record) {
    try {
      await BILLING_INVOICE_API.post('/invoices/update-payment', record);
      dispatch('fetchApprovalInvoiceData');
      this.$notify('Data Updated', 'success');
    } catch (err) {
      this.$notify('Failed to Update Data', 'error');
      console.error(err);
    }
  },
  async addLineItem({ commit }, record) {
    try {
      const { data } = await BILLING_INVOICE_API.put(`/invoices/add-line-item/${record.invoiceId}`, record);
      if (Array.isArray(data)) {
        data.forEach((d) => {
          d.dueDate = getMomentDateString(d.dueDate);
          d.invoiceDate = getMomentDateString(d.invoiceDate);
        });
      } else {
        data.dueDate = getMomentDateString(data.dueDate);
        data.invoiceDate = getMomentDateString(data.invoiceDate);
      }
      commit('updateRow', data);
      this.$notify('Line Item Added', 'success');
    } catch (err) {
      this.$notify('Failed to Add Line Item', 'error');
      console.error(err);
    }
  },
  async deleteRecord({ dispatch, commit }, record) {
    try {
      const importRecords = { Data: record };
      await BILLING_INVOICE_API.post('/invoices/inactivate', importRecords);
      commit('deleteRow');
      dispatch('currentTableRowChange', {});
      this.$notify('Data Removed', 'success');
    } catch (err) {
      this.$notify('Failed to Remove Data', 'error');
      console.error(err);
    }
  },
  async subledgerRecord({ dispatch, commit, state }, record) {
    try {
      const importRecords = { Data: record };
      await BILLING_INVOICE_API.post('/subledgers', importRecords);
      this.$notify('Data Added', 'success');
      dispatch('fetchApprovalInvoiceData');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async reviewAccounting({ state }) {
    const filteredInvoiceIds = state.selectedRows.map((x) => x.id);
    const params = {
      invoiceIds: filteredInvoiceIds,
      reviewAccountingFlag: true,
    };
    try {
      await BILLING_INVOICE_API.put('/subledgers/send-accounting', params);
      this.$notify('Review Accounting', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Review Accounting', 'error');
    }
  },
  async generateRecord({ state }) {
    const invoice = { Data: state.selectedRows };
    try {
      await BILLING_INVOICE_API.post('/invoices/generate', invoice);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  async sendRecordToCustomer({ state }) {
    const invoice = { Data: state.selectedRows };
    try {
      await BILLING_INVOICE_API.post('/invoices/send-to-customer', invoice);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('setCurrentRow', currentTableRow);
  },
};

const mutations = {
  updateRow(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  setTableData(state, value) {
    state.tableData = value;
  },
  setCurrentRow(state, currentRow) {
    state.lineItemData = [];
    state.commentsData = [];
    state.summaryData = [];
    state.currentTableRow = currentRow;
    state.lineItemData = currentRow.lineItems;
    state.commentsData = currentRow.statuses;
    state.summaryData = currentRow.summaries;
  },
  setEditAction(state, action) {
    state.editAction = action;
  },
  setSearchParams(state, value) {
    state.searchParams = value;
  },
  deleteRow(state) {
    for (let i = 0; i < state.selectedRows.length; i++) {
      state.tableData = state.tableData.filter((record) => record.id !== state.selectedRows[i].id);
    }
    state.currentTableRow = null;
    state.lineItemData = [];
    state.commentsData = [];
    state.summaryData = [];
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};