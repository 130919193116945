import { ETAG_API } from '@/api';
import helpers from '@/utils/helpers';
import * as tagUtils from '../../utils/etag/tagUtils';

const STORAGE_KEY = 'tag_reg_dat';
const STORAGE_VERSION_KEY = 'tag_reg_ver';
const PRODUCT_KEY = 'reg_products';
const SOURCE_KEY = 'reg_sources';
const SINK_KEY = 'reg_sinks';
const PSE_KEY = 'reg_pses';
const BA_KEY = 'reg_bas';
const TP_KEY = 'reg_tps';
const MO_KEY = 'reg_mos';

function setData(data, collectionName, valueProperty, labelProperty, storageKey, additionalProps) {
  let list = [];

  const items = data[collectionName];
  if (Array.isArray(items)) {
    for (let x = 0; x < items.length; x++) {
      const item = items[x];
      const listItem = {
        value: item[valueProperty].toString(),
        label: item[labelProperty].toString(),
      };

      if (Array.isArray(additionalProps)) {
        for (let y = 0; y < additionalProps.length; y++) {
          const prop = additionalProps[y];

          let mapTo = '';
          let mapFrom = '';

          if (String(prop)) {
            mapTo = prop;
            mapFrom = prop;
          } else {
            mapTo = prop.mapTo;
            mapFrom = prop.mapFrom;
          }

          listItem[mapTo] = item[mapFrom].toString();
        }
      }

      list.push(listItem);
    }

    list = helpers.sortBy(list, ['label']);

    window.localStorage.setItem(storageKey, JSON.stringify(list));
  }

  return list;
}

async function retrieveData({ commit, dispatch }, force, storageKey, apiAddress, mutationName, readyMutationName) {
  let makeAPIRequest = false;
  let storeData = null;

  if (force) {
    makeAPIRequest = true;
  } else {
    storeData = window.localStorage.getItem(storageKey);

    if (storeData === null || storeData === undefined) { makeAPIRequest = true; }
  }

  if (makeAPIRequest) {
    const response = await ETAG_API.get(apiAddress);
    commit(mutationName, { local: false, data: response.data });
    commit(readyMutationName);
  } else {
    commit(mutationName, { local: true, data: JSON.parse(storeData) });
    commit(readyMutationName);
  }
}

export default {
  state: {
    cpseList: [],
    productList: [],
    pseList: [],
    sourceList: [],
    porpodList: [],
    transPathList: [],
    sinkList: [],
    baList: [],
    tpList: [],
    moList: [],
    groupingList: [],
    loadedFlag: false,
    loadedTransPathFlag: false,
    loadedPSEList: false,
    loadedBAList: false,
    loadedTPList: false,
    loadedSourceList: false,
    loadedSinkList: false,
    loadedMOList: false,
    loadedProductList: false,
    loadedCPSEList: false,
  },
  getters: {
    getData: (state) => state,
    getCCOptionsList(state) {
      const entityList = [];
      const { pseList } = state;
      const { baList } = state;
      const { tpList } = state;

      for (let x = 0; x < pseList.length; x++) {
        const pse = tagUtils.createEntityValueLabel(`${pseList[x].label}_pse`, pseList[x].label, 'PSE');
        const exists = entityList.find((p) => p.value === pse.value);
        if (!exists) entityList.push(pse);
      }

      for (let x = 0; x < baList.length; x++) {
        const ba = tagUtils.createEntityValueLabel(`${baList[x].label}_ba`, baList[x].label, 'BA');
        const exists = entityList.find((p) => p.value === ba.value);
        if (!exists) entityList.push(ba);
      }

      for (let x = 0; x < tpList.length; x++) {
        const tp = tagUtils.createEntityValueLabel(`${tpList[x].label}_tp`, tpList[x].label, 'TP');
        const exists = entityList.find((p) => p.value === tp.value);
        if (!exists) entityList.push(tp);
      }

      return entityList;
    },
    getRegistryProductList: (state) => state.productList,
    getRegistryBAList: (state) => state.baList,
    getRegistryTPList: (state) => state.tpList,
    getRegistryPSEList: (state) => state.pseList,
    getRegistrySourceList: (state) => state.sourceList,
    getRegistrySinkList: (state) => state.sinkList,
    getRegistryTransmissionPathList: (state) => state.transPathList,
    getRegistryMOList: (state) => state.moList,
    getRegistryPORPODList: (state) => state.porpodList,
    getSinkOptions: (state) => state.sinkList,
    getSourceOptions: (state) => state.sourceList,
    getRegistryCPSEList: (state) => state.cpseList,
    getGroupingList: (state) => state.groupingList,
  },
  actions: {
    async initialize({ commit, dispatch }, state) {
      const publicationVersion = window.localStorage.getItem(STORAGE_VERSION_KEY);

      let publicationDT = null;

      if (publicationVersion !== null && publicationVersion !== undefined) {
        try {
          publicationDT = JSON.parse(publicationVersion);
        } catch (err) {
          publicationDT = null;
        }
      }

      dispatch('loadCPSEs');
      try {
        const response = await ETAG_API.get('/registry/version');

        const getFromApi = { force: false };

        if (response.data.publicationDT !== publicationDT) {
          getFromApi.force = true;

          commit('setRegistryVersion', response.data.publicationDT);
        }
        dispatch('retrieveRegistryBAList', getFromApi);
        dispatch('retrieveRegistryPSEList', getFromApi);
        dispatch('retrieveRegistryProductList', getFromApi);
        dispatch('retrieveRegistryMOList', getFromApi);
      } catch (error) {
        console.log(error);
      }
    },
    async retrieveTransmissionPaths({ commit, state }) {
      if (state.loadedTransPathFlag) { return; }

      const response = await ETAG_API.get('/registry/transmissionPaths');
      commit('loadTransmissionPaths', response.data);
    },
    async retrieveRegistryBAList({ commit, dispatch, state }, item) {
      item = item || { force: false };
      if (!item.force && state.loadedBAList) {
        commit('readyRegistryBAList');
        return;
      }

      await retrieveData({ commit, dispatch }, item.force, BA_KEY, '/registry/bas', 'setRegistryBAList', 'readyRegistryBAList');
    },
    async retrieveRegistryPSEList({ commit, dispatch, state }, item) {
      item = item || { force: false };
      if (!item.force && state.loadedPSEList) {
        commit('readyRegistryPSEList');
        return;
      }

      await retrieveData({ commit, dispatch }, item.force, PSE_KEY, '/registry/pses', 'setRegistryPSEList', 'readyRegistryPSEList');
    },
    async retrieveRegistryProductList({ commit, dispatch, state }, item) {
      item = item || { force: false };
      if (!item.force && state.loadedProductList) {
        commit('readyRegistryProductList');
        return;
      }

      await retrieveData({ commit, dispatch }, item.force, PRODUCT_KEY, '/registry/products', 'setRegistryProductList', 'readyRegistryProductList');
    },
    async retrieveRegistryMOList({ commit, dispatch, state }, item) {
      item = item || { force: false };
      if (!item.force && state.loadedMOList) {
        commit('readyRegistryMOList');
        return;
      }

      await retrieveData({ commit, dispatch }, item.force, MO_KEY, '/registry/mos', 'setRegistryMOList', 'readyRegistryMOList');
    },
    async retrieveGroupingList({ commit }) {
      try {
        const response = await ETAG_API.get('/groupings');

        commit('setGroupingList', response.data);
      } catch (err) {
        console.log(err);
      }
    },
    async loadCPSEs({ commit, dispatch }) {
      try {
        const { data } = await ETAG_API.get('/registry/cpses');
        // commit('setCPSEs', response.data);
        commit('setRegistryCPSEList', data);
        commit('readyRegistryCPSEList');
        // commit('setTemplateCPSEList', response.data);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mutations: {
    setRegistryVersion(state, item) {
      window.localStorage.setItem(STORAGE_VERSION_KEY, JSON.stringify(item));
    },
    setTransmissionPath(state, data) {
      state.transPathList = data.transmissionPathList;
      state.loadedTransPathFlag = true;
    },
    setRegistryBAList(state, item) {
      if (!item.local) { state.baList = setData(item.data, 'baList', 'taggingEntityID', 'code', BA_KEY); } else { state.baList = item.data; }

      state.loadedBAList = true;
    },
    setRegistryPSEList(state, item) {
      if (!item.local) { state.pseList = setData(item.data, 'pseList', 'taggingEntityID', 'code', PSE_KEY); } else { state.pseList = item.data; }

      state.loadedPSEList = true;
    },
    setRegistryProductList(state, item) {
      if (!item.local) { state.productList = setData(item.data, 'productList', 'id', 'name', PRODUCT_KEY, ['productTypeID']); } else { state.productList = item.data; }

      state.loadedProductList = true;
    },
    setRegistrySourceList(state, item) {
      if (!item.local) {
        state.sourceList = setData(item.data, 'sourceList', 'taggingPointID', 'name',
          SOURCE_KEY,
          [
            { mapFrom: 'pseTaggingEntityID', mapTo: 'pse_id' },
            { mapFrom: 'baTaggingEntityID', mapTo: 'ba_id' },
          ],
        );
      } else {
        state.sourceList = item.data;
      }

      state.loadedSourceList = true;
    },
    setRegistrySinkList(state, item) {
      if (!item.local) {
        state.sinkList = setData(item.data, 'sinkList', 'taggingPointID', 'name',
          SINK_KEY,
          [
            { mapFrom: 'pseTaggingEntityID', mapTo: 'pse_id' },
            { mapFrom: 'baTaggingEntityID', mapTo: 'ba_id' },
          ],
        );
      } else {
        state.sinkList = item.data;
      }

      state.loadedSinkList = true;
    },
    setRegistryTSPList(state, item) {
      if (!item.local) { state.tpList = setData(item.data, 'tpList', 'taggingEntityID', 'code', TP_KEY); } else { state.tpList = item.data; }

      state.loadedTPList = true;
    },
    setRegistryMOList(state, item) {
      if (!item.local) { state.moList = setData(item.data, 'moList', 'taggingEntityID', 'code', MO_KEY); } else { state.moList = item.data; }

      state.loadedMOList = true;
    },
    setRegistryCPSEList(state, item) {
      state.cpseList = item.cpseList;
      state.loadedCPSEList = true;
    },
    setGroupingList(state, item) {
      state.groupingList = item.data;
    },
    readyRegistryBAList() {},
    readyRegistryPSEList() {},
    readyRegistryProductList() {},
    readyRegistryMOList() {},
  },
};