export default {
  columns: [
    {
      caption: 'CONTRACT',
      dataField: 'contractName',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'BDORDER',
      dataField: 'bdOrder',
      dataType: 'number',
      area: 'column',
      expanded: true,
      showTotals: false,
    }, {
      caption: 'BDNAME',
      dataField: 'bdName',
      dataType: '',
      area: 'column',
      expanded: true,
    }, {
      caption: 'AREF',
      dataField: 'aref',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'PATH',
      dataField: 'pathName',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'METERID',
      dataField: 'meterId',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'PREMISEID',
      dataField: 'premiseId',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CHANNEL',
      dataField: 'channel',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TRADEID',
      dataField: 'tradeId',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'SCHEDULEID',
      dataField: 'scheduleId',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'PRICENODE',
      dataField: 'priceNode',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'RESOURCE',
      dataField: 'resource',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TAGID',
      dataField: 'tagId',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CP_NAME',
      dataField: 'cpName',
      dataType: '',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TRADINGDATE',
      dataField: 'tradingDate',
      dataType: 'date',
      format: 'MM/dd/yyyy',
      area: 'row',
      expanded: true,
    }, {
      caption: 'TRADINGHOUR',
      dataField: 'tradingHour',
      dataType: 'number',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'TRADING INTERVAL',
      dataField: 'tradingInterval',
      dataType: 'number',
      area: 'filter',
      expanded: true,
    }, {
      caption: 'CompareType',
      dataField: 'compareType',
      dataType: 'string',
      area: 'row',
      expanded: true,
    }, {
      caption: 'VAL',
      dataField: 'intValue',
      dataType: 'number',
      area: 'data',
      summaryType: 'sum',
      expanded: true,
      format: '#.######',
    },
  ],
};