import { DefaultOptions } from "./defaultOptions";
import { ETagOptions } from "./etagOptions";

class Options {
  constructor() {
    /**
     * @type {Array<DefaultOptions>}
     */
    this.items = [
      new ETagOptions(),
      new DefaultOptions('default')
    ]
  }

  getConfigurations(name) {
    if (name === null || name === undefined) name = 'default';
    
    for(let x = 0; x < this.items.length; x++) {
      if (this.items[x].name.toLowerCase() === name.toLowerCase()) {
        return this.items[x].configurations;
      }
    }
    return [];
  }
}

const options = new Options();

export default options;