import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'versionId', label: 'Version', sortable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    shortName: null,
    description: null,
    commodityName: 'POWER',
    marketName: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getMarketList: (state, getters, rootState) => rootState.lookup.marketList,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/market-self-schedule-types')
      .then(({ data }) => {
        commit('loadTableData', data.marketSelfScheduleTypes);
        this.$notify(`${data.marketSelfScheduleTypes.length} Market Self Schedule Type(s) Loaded`, 'info');
      })
      .catch((err) => {
        this.$notify('Failed to Load Market Self Schedule Types', 'error');
      });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postMarketSelfScheduleType({ state }, marketSelfScheduleType) {
    STRUCTURES_API.post('/market-self-schedule-types', marketSelfScheduleType)
      .then(({ data }) => {
        state.tableData.push(data);
        this.$notify('Market Self Schedule Type Added', 'success');
      })
      .catch((err) => {
        this.$notify('Failed to Add Market Self Schedule Type', 'error');
      });
  },
  updateMarketSelfScheduleType({ dispatch, commit, state }, marketSelfScheduleType) {
    STRUCTURES_API.put(`/market-self-schedule-types/${marketSelfScheduleType.id}`, marketSelfScheduleType)
      .then(({ data }) => {
        this.$notify('Market Self Schedule Type Updated', 'success');
        commit('updateMarketSelfScheduleType', data);
      })
      .catch((err) => {
        this.$notify('Failed to Update Market Self Schedule Type', 'error');
      });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/market-self-schedule-types/${state.currentTableRow.id}`)
      .then(({ data }) => {
        commit('deleteTableRow');
        this.$notify('Market Self Schedule Type Removed', 'success');
        dispatch('currentTableRowChange', clone(state.nullTableRow));
      })
      .catch((err) => {
        this.$notify('Failed to Remove Market Self Schedule Type', 'error');
      });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateMarketSelfScheduleType(state, marketSelfScheduleType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === marketSelfScheduleType.id);
    state.tableData.splice(rowIndex, 1, marketSelfScheduleType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, marketSelfScheduleTypes) {
    state.tableData = marketSelfScheduleTypes;
  },
  createTableRow(state) {
    const marketSelfScheduleType = clone(state.nullTableRow);
    marketSelfScheduleType.id = state.tableKey++;
    marketSelfScheduleType.versionId = 1;
    marketSelfScheduleType.createdBy = this.getters['auth/getProfile'].userName;
    marketSelfScheduleType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = marketSelfScheduleType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((marketSelfScheduleType) => marketSelfScheduleType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};