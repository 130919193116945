import { CISO_METER_API } from '@/api';
import { deepClone } from '@/utils/dataUtil';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import Vue from 'vue';
import configs from './mrisPreSubmitConfig';

const state = {
  submitHeaderTableData: [],
  mappTableData: [],
  selectedHeader: null,
  mrisPreSubmitConfig: configs,
};

const actions = {
  async fetchData({ commit }, parameters) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      resources: parameters.meterSubmitUnitListSelected ? parameters.meterSubmitUnitListSelected.join(',') : null,
      startDate,
      endDate,
      dateType: parameters.dateTypeSelected,
    };
    try {
      const { data: { data } } = await CISO_METER_API.get('/mris-submission/pre-validation', { params });
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.tradingDate = getMomentDateString(obj.tradingDate);
        });
      }
      commit('setTableData', data);

      const mapping = await CISO_METER_API.get('/mris-submission/mapping', { params });
      commit('setMapData', mapping.data.data);
    } catch {
      this.$notify('Failed to Load Meter Data', 'error');
    }
  },
  async postData({ state, commit }) {
    const submitData = { Data: state.submitHeaderTableData };
    try {
      await CISO_METER_API.post('/mris-submission', submitData);
      commit('clearData');
      this.$notify('Meter Data Batch Created', 'success');
    } catch (err) {
      this.$notify('Failed to Create Batch', 'error');
    }
  },
  async postAcceptOverrides({ state, commit }) {
    const data = { data: state.submitHeaderTableData };
    try {
      await CISO_METER_API.post('mris-submission/accept-overrides', data);
      commit('acceptOverrides', state);
    } catch (err) {
      this.$notify('Failed to Save Overrides', 'error');
    }
  },
  async postClearOverrides({ state, commit }) {
    const data = { data: state.submitHeaderTableData };
    try {
      await CISO_METER_API.post('mris-submission/clear-overrides', data);
      commit('clearOverrides', state);
    } catch (err) {
      this.$notify('Failed to Clear Overrides', 'error');
    }
  },
  dataChanged({ commit }, { rowIndex, prop, value }) {
    commit('setData', { rowIndex, prop, value });
  },
};

const mutations = {
  setSelectedHeader(state, {
    submitData, submitLogs,
  }) {
    state.submitDataTableData = submitData || [];
    state.submitLogTableData = submitLogs || [];
  },
  clearOverrides(state) {
    state.submitHeaderTableData.forEach((obj) => {
      obj.overrideVal = null;
      obj.message = '';
      obj.val = obj.origVal;
    });
    this.$notify('Successfully cleared overrides', 'success');
  },
  acceptOverrides(state) {
    state.submitHeaderTableData.forEach((obj) => {
      if (obj.overrideVal !== null) { obj.val = obj.overrideVal; }
    });
    this.$notify('Values successfully updated with overrides', 'success');
  },
  clearData(state) {
    state.submitHeaderTableData = [];
    state.mrisPreSubmitConfig = deepClone(configs);
  },
  setTableData(state, value) {
    state.submitHeaderTableData = value;
  },
  setMapData(state, value) {
    state.mappTableData = value;
  },
  reset(state) {
    state.submitHeaderTableData = [];
  },
  setData(state, { rowIndex, prop, value }) {
    const newValue = Number(value);
    if (Number.isNaN(newValue) || value === '' || value === null) {
      Vue.set(state.submitHeaderTableData[rowIndex], prop, null);
    } else {
      Vue.set(state.submitHeaderTableData[rowIndex], prop, newValue);
    }
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};