import { STRUCTURES_API } from '@/api';

const state = {
  editor: null,
  fontSizes: [],
  collections: [],
  selectedCollection: null,
  filteredResources: [],
  selectedResource: null,
  resourceSelectionDisabled: true,
};

const getters = {
};

const actions = {
  initialize({ dispatch, commit }) {
    commit('setEditorSession');
    commit('setFontSizeOptions');
    dispatch('loadCollections');
  },
  async loadCollections({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/collections');
      commit('setCollections', data.collections);
    } catch (error) {
      this.$notify('Failed to Load Collections', 'error');
    }
  },
  async loadResources({ commit }, collectionName) {
    try {
      const { data } = await STRUCTURES_API.get(`/collections/${collectionName}/resources`);
      commit('setResources', data);
    } catch (error) {
      this.$notify('Failed to Load Resources', 'error');
    }
  },
  async loadResourceData({ commit }, request) {
    const { collectionName } = request;
    const { resourceName } = request;
    try {
      const { data } = await STRUCTURES_API.get(`/collections/${collectionName}/resources/${resourceName}`);
      commit('setEditorCode', JSON.stringify(data));
    } catch (error) {
      this.$notify('Failed to Load Resource Data', 'error');
    }
  },
  async updateResourceData({ commit, state }, request) {
    const { collectionName } = request;
    const { resourceName } = request;

    // catches invalid JSON
    try {
      const editorCode = JSON.parse(state.editor.session.getValue());
      // catches other errors
      try {
        await STRUCTURES_API.put(`/collections/${collectionName}/resources/${resourceName}`, editorCode);
        this.$notify('Resource Data Updated', 'success');
      } catch (error) {
        this.$notify('Failed to Update Resource Data', 'error');
      }
    } catch (error) {
      this.$notify('Must submit valid JSON', 'error');
    }
  },
};

const mutations = {
  setEditorSession(state) {
    const editor = ace.edit('editor');
    editor.getSession().setMode('ace/mode/javascript');
    editor.setTheme('ace/theme/monokai');
    editor.setShowPrintMargin(false);
    editor.session.setValue('');
    editor.$blockScrolling = Infinity;
    editor.setFontSize(16);
    editor.setHighlightActiveLine(true);
    state.editor = editor;
  },
  setEditorCode(state, item) {
    let code = item;
    code = JSON.parse(JSON.stringify(code, null));
    state.editor.session.setValue(code);
  },
  clearEditorCode(state) {
    state.editor.session.setValue('');
  },
  setFontSizeOptions(state) {
    for (let i = 12; i <= 24; i++) {
      const option = { value: i, label: i };
      state.fontSizes.push(option);
    }
  },
  setFontSize(state, size) {
    state.editor.setFontSize(size);
  },
  setCollections(state, data) {
    state.collections = data.map((c) => ({ value: c.name, label: c.name }));
  },
  setResources(state, data) {
    state.filteredResources = data.map((r) => ({ value: r.name, label: r.name }));
    state.resourceSelectionDisabled = false;
  },
  reset(state) {
    state.editor = null;
    state.fontSizes = [];
    state.fontSize = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};