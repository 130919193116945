export function upsertFieldConfig(fields, attributeList) {
  const resultFieldList = [];

  const foundList = [];
  fields.forEach((field) => {
    const attr = attributeList.find((a) => a.name === field.dataField);
    if (attr) {
        foundList.push(attr.name);
        resultFieldList.push({
          dataField: attr.name,
          caption: attr.caption,
          dataType: 'string',
          area: field.area ?? 'filter',
          areaIndex: field.areaIndex ?? 200,
          index: field.index ?? null,
          expanded: field.expanded ?? true,
          format: field.format ?? null,
          visible: field.visible ?? true,
          //showTotals: field.showTotals ?? true,
        });
    } else {
        resultFieldList.push(field);
    }
  });

  attributeList.forEach((a) => {
    const found = foundList.find(f=>f === a.name);
    if (!found) {
      resultFieldList.push({
        dataField: a.name,
        caption: a.caption,
        dataType: 'string',
        area: 'filter',
        areaIndex: 200,
        index: null,
        expanded: true,
        format: null,
        visible: true,
        //showTotals: true,
      });
    }
  })
  
  return resultFieldList;
}