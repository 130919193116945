import scriptEditorStore from './ScriptEditor/store';
import scriptDialogStore from './ScriptEditor/store/scriptEditorStore';
import addUserStore from './Users/Add/AddUserStore';
import listUsersStore from './Users/View/ViewUsersStore';
import eventLogStore from './EventLog/eventLogStore';

export default {
  namespaced: true,
  modules: {
    scriptEditor: {
      namespaced: true,
      modules: {
        editor: scriptEditorStore,
        scriptEditorDialog: scriptDialogStore,
      },
    },
    users: {
      namespaced: true,
      modules: {
        add: addUserStore,
        list: listUsersStore,
      },
    },
    eventLogStore,
  },
};