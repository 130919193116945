import moment from 'moment';
import { CISO_SAMC_API } from '@/api';
import config from './viewChargeDetailsConfig';

const state = {
  tableData: [],
  tableConfiguration: config,
  backupDataSet: [],
  selectedRowKeys: [],
};

const actions = {
  async fetchViewChargeDetails({ commit, dispatch }, params = {}) {
    commit('setTableData', []);

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/stmt-details/view-charge-details', { params });
      if (Array.isArray(data)) {
        data.forEach((row) => {
          row.tzTradingDate = moment.utc(row.tzTradingDate).format('MM/DD/YYYY');
        });
        commit('setTableData', data);
        commit('setBackupDataSet', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Dispute Charge Details', 'error');
    }
  },
  async saveDispute({ commit, dispatch }, params = []) {
    try {
      const disputeRecords = { disputes: [] };

      params.forEach((row) => {
        disputeRecords.disputes.push(row);
      });

      await CISO_SAMC_API.post('/stmt-details/dispute-charge-details', disputeRecords);
      this.$notify('Dispute successfully created', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Save Dispute Charge Details', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setBackupDataSet(state, value) {
    state.backupDataSet = value;
  },
  setSelectedRowKeys(state, value) {
    state.selectedRowKeys = value;
  },
  reset(state, value) {
    state.tableData = [];
    state.tableConfig = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};