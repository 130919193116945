
export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [
    {
      caption: 'DATE',
      dataField: 'date',
      area: 'row',
      expanded: true,
      dataType: 'date',
      format: 'yyyy-MM-dd',
      width: 120,
    },
    {
      caption: 'SOURCE',
      dataField: 'source',
      width: 120,
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'SINK',
      dataField: 'sink',
      width: 120,
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'TAG ID',
      dataField: 'tagID',
      width: 120,
      area: 'row',
      expanded: true,
    },
    {
      caption: 'TAG CODE',
      dataField: 'tagCode',
      width: 60,
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'NOTES',
      dataField: 'notes',
      width: 100,
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'GCA',
      dataField: 'gca',
      width: 120,
      area: 'filter',
      expanded: true,
    },    
    {
      caption: 'LCA',
      dataField: 'lca',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'PSE',
      dataField: 'pse',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'P/PSE',
      dataField: 'mpse',
      expanded: true,
      area: 'row',
    },
    {
      caption: 'P/TP',
      dataField: 'tp',
      expanded: true,
      area: 'row',
    },
    {
      caption: 'P/SE',
      dataField: 'se',
      expanded: true,
      area: 'row',
    },
    {
      caption: 'P/POR',
      dataField: 'por',
      expanded: true,
      area: 'row',
    },
    {
      caption: 'P/POD',
      dataField: 'pod',
      expanded: true,
      area: 'row',
    },
    {
      caption: 'HE',
      dataField: 'he',
      area: 'column',
      expanded: true,
      width: 10,
    },     
    {
      caption: 'MW',
      dataField: 'curr',
      area: 'data',
      dataType: 'number',
      summaryType: 'sum',
      expanded: true,
    },
  ],
};