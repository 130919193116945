import { tradeDateRange, balancingAreaAuthority, gridConfig } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [
    {
      caption: 'TRADING HOUR', dataType: 'string', dataField: 'tradingHour', width: 120, area: 'column', expanded: true,
    },
    {
      caption: 'TRADING DATE', dataType: 'string', dataField: 'tradingDate', area: 'row', expanded: true,
    },
    {
      caption: 'BAA ID', dataType: 'string', dataField: 'baaId', width: 120, area: 'row', expanded: true,
    },
    {
      caption: 'DATA TYPE', dataType: 'string', dataField: 'data_type', area: 'row', expanded: true,
    },
    {
      caption: 'VALUE', dataType: 'number', format: gridConfig.valFormat, dataField: 'value', area: 'data', summaryType: 'sum', expanded: true,
    },
  ],
  searchFilterConfig: {
    tradeDateRange,
    balancingAreaAuthority,
  },
};