import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { isEmpty } from '@/utils/dataUtil';
import { PPA_INVOICE_API, TOOLS_API } from '@/api';
import { dateRange, ppaGroupTagBox, ppaGroupInvoiceEntities } from '@/components/PPA/BaseStore/ppasBase';

const state = {
  tableData: [],
  lineItemData: [],
  statementData: [],
  selectedRows: [],
  accountingPeriodListOptions: [],
  currentAccountingPeriod: null,
  currentTableRow: null,
  currentUserName: null,
  editAction: 'Update',
  searchParams: {},
  searchFiltersConfig: {
    dateType: {
      label: 'Search Type',
      value: 'invoiceDate',
      type: 'radio',
      options: [
        { value: 'invoiceDate', label: 'Invoice Date' },
        { value: 'tradingDate', label: 'Trade Date' },
        { value: 'ids', label: 'ID' },
      ],
      watchers: [{
        propertyToWatch: 'dateTypeSelected',
        handler(newValue, oldValue) {
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          this.$store.commit('ciso/setParams', ['idVisibleSelected', newValue === 'ids']);
          this.$store.commit('ciso/setParams', ['dateVisibleSelected', newValue !== 'ids']);
          return true;
        },
      }],
    },
    ids: {
      label: 'ID(s)',
      value: '',
      type: 'text',
      visible: 'idVisible',
    },
    dateRange: { ...dateRange, visible: 'dateVisible' },
    ppaGroupTagBox: { ...ppaGroupTagBox, visible: 'dateVisible' },
    ppaGroupInvoiceEntities: { ...ppaGroupInvoiceEntities, visible: 'dateVisible' },
    statusTypes: {
      label: 'Status Types',
      value: ['Approved', 'PendingApproval'],
      type: 'tagbox',
      visible: 'dateVisible',
      options: [
        { value: 'Approved', label: 'Approved' },
        { value: 'PendingApproval', label: 'Pending Approval' },
        { value: 'Inactivated', label: 'Inactivated' },
      ],
    },
    cycleType: {
      label: 'Cycle Type',
      value: 'Invoice',
      type: 'select',
      visible: 'dateVisible',
      options: [
        { value: 'Invoice', label: 'Invoice' },
        { value: 'Forecast', label: 'Forecast' },
      ],
    },
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchAccountPeriodList');
    dispatch('fetchCurrentUser');
  },
  async fetchCurrentUser({ commit, state }) {
    const user = this.getters['auth/getProfile'].userName;
    commit('setCurrentUser', user);
  },
  async fetchApprovalInvoiceData({ commit, state }) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(state.searchParams.dateRangeSelected);
    const params = {
      ppaGroups: state.searchParams.ppaGroupTagBoxSelected,
      invoiceEntities: state.searchParams.ppaGroupInvoiceEntitiesSelected,
      invoiceStatuses: state.searchParams.statusTypesSelected,
      cycleType: state.searchParams.cycleTypeSelected,
      startDate,
      endDate,
      dateType: state.searchParams.dateTypeSelected,
      ids: state.searchParams.idsSelected,
    };
    try {
      const { data: { data } } = await PPA_INVOICE_API.post('/invoices/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.approvedDate = x.approvedDate === null ? null : getMomentDateString(x.approvedDate);
          x.createdDate = getMomentDateString(x.createdDate);
          x.updatedDate = getMomentDateString(x.updatedDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.billPeriodDate = getMomentDateString(x.billPeriodDate);
          if (Array.isArray(x.lineItems)) {
            x.lineItems.forEach((lineItem) => {
              lineItem.tradingDate = getMomentDateString(lineItem.tradingDate);
            });
          }
          if (Array.isArray(x.statements)) {
            x.statements.forEach((statement) => {
              statement.tradingDate = getMomentDateString(statement.tradingDate);
              statement.publishDate = getMomentDateString(statement.publishDate);
            });
          }
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async fetchAccountPeriodList({ commit, state }) {
    try {
      const { data: { data } } = await PPA_INVOICE_API.get('/subledgers/account-period');
      commit('setAccountingPeriodListOptions', data);

      let period = null;
      state.accountingPeriodListOptions.every((x) => {
        if (x.label.includes('OPEN')) {
          period = x.value;
          return false; // break out of loop
        }
        return true;
      });
      if (period === null) {
        period = state.accountingPeriodListOptions[0]?.value ?? null;
      }
      commit('setCurrentAccountingPeriod', period);
    } catch (err) {
      this.$notify('Failed to fetch Account Period list', 'error');
      console.error(err);
    }
  },
  async approveRecord({ dispatch }, records) {
    dispatch('putRecord', { action: 'Approve', records, route: '/invoices/approve' });
  },
  async updateRecord({ dispatch }, records) {
    dispatch('putRecord', { action: 'Update', records, route: '/invoices/update' });
  },
  async putRecord({ dispatch }, { action, records, route }) {
    try {
      const { data } = await PPA_INVOICE_API.post(route, { Data: records });
      if (data?.successful) {
        await dispatch('fetchApprovalInvoiceData');
        this.$notify(`Data ${action}d`, 'success');
      } else {
        this.$notify(data?.message || `Failed to ${action} Records`, 'error');
      }
    } catch (err) {
      this.$notify(`Error occurred when attempting to ${action} Data`, 'error');
      console.error(err);
    }
  },
  async deleteRecord({ dispatch, commit }, records) {
    try {
      const { data } = await PPA_INVOICE_API.post('/invoices/inactivate', { Data: records });
      if (data?.successful) {
        commit('deleteRow');
        this.$notify('Data Removed', 'success');
      } else {
        this.$notify(data?.message || 'Failed to Remove Records', 'error');
      }
    } catch (err) {
      this.$notify('Error occurred when attempting to Remove Records', 'error');
      console.error(err);
    }
  },
  async subledgerRecord({ dispatch }, records) {
    try {
      const { data } = await PPA_INVOICE_API.post('/subledgers', { Data: records });
      if (data?.successful) {
        await dispatch('fetchApprovalInvoiceData');
        this.$notify('Data Added', 'success');
      } else {
        this.$notify(data?.message || 'Failed to Subledger Records', 'error');
      }
    } catch (err) {
      console.error(err);
      this.$notify('Error occurred when attempting to Subledger Records', 'error');
    }
  },
  async generateRecord({ state }) {
    const generateRequest = {
      reportType: 'ppa-customer-invoice',
      invoiceIds: state.selectedRows.map((s) => (s.id)),
    };
    try {
      const { data } = await TOOLS_API.post('/legacy-process/ppa-report', generateRequest);
      if (data?.successful) {
        this.$notify('Invoice Successfully Generated', 'success');
      } else {
        this.$notify(data?.message || 'Failed to Generate Invoice', 'error');
      }
    } catch (err) {
      console.error(err);
      this.$notify('Error occurred when attempting to Generate Records', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
    state.lineItemData = [];
    state.statementData = [];
    state.selectedRows = [];
  },
  setCurrentRow(state, currentRow) {
    state.lineItemData = [];
    state.statementData = [];
    const currRowEmpty = isEmpty(currentRow);
    state.currentTableRow = currRowEmpty ? null : currentRow;
    if (!currRowEmpty) {
      state.lineItemData = currentRow.lineItems;
      state.statementData = currentRow.statements;
    }
  },
  setEditAction(state, action) {
    state.editAction = action;
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
  setSearchParams(state, value) {
    state.searchParams = value;
  },
  setAccountingPeriodListOptions(state, list) {
    list.sort((a, b) => b.period - a.period);
    state.accountingPeriodListOptions = list?.map((item) => ({ label: `${item.period } - (${item.status})`, value: item.period }));
  },
  setCurrentAccountingPeriod(state, period) {
    state.currentAccountingPeriod = period;
  },
  setCurrentUser(state, user) {
    state.currentUserName = user;
  },
  deleteRow(state) {
    for (let i = 0; i < state.selectedRows.length; i++) {
      state.tableData = state.tableData.filter((record) => record.id !== state.selectedRows[i].id);
    }
    state.currentTableRow = null;
    state.selectedRows = [];
    state.lineItemData = [];
    state.statementData = [];
  },
  reset(state) {
    state.currentTableRow = null;
    state.selectedRows = [];
    state.tableData = [];
    state.lineItemData = [];
    state.statementData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};