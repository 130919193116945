export default {
  columns: [{
    label: 'FILE ID',
    prop: 'stmtImport_Id',
    filterable: false,
    sortable: false,
    visible: false,
    editable: false,
  }, {
    label: 'COMP ID',
    prop: 'compareStmtImport_Id',
    filterable: false,
    sortable: false,
    visible: false,
    editable: false,
  }, {
    label: 'BA',
    prop: 'ba',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'RSRC ID',
    prop: 'rsrcId',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'RSRC TYPE',
    prop: 'rsrcType',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  },
  // , {
  //   label: 'TRADE PLACE',
  //   prop: 'tradePlace',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'IST TYPE',
  //   prop: 'istType',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'DEPEND ON NAME',
  //   prop: 'dependOnName',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'COUNTER BA ID',
  //   prop: 'counterBaId',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'MARKET TYPE',
  //   prop: 'marketType',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'CRR ID',
  //   prop: 'crrId',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }, {
  //   label: 'CRR TYPE',
  //   prop: 'crrType',
  //   filterable: true,
  //   sortable: true,
  //   visible: true,
  //   editable: false,
  // }
  {
    label: 'TRADING DATE',
    prop: 'tzTradingDate',
    format: 'yyyy-MM-dd',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  },
  {
    label: 'TRADING HOUR',
    prop: 'tradingHour',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'TRADING INTERVAL',
    prop: 'tradingInterval',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'CHARGE CODE',
    prop: 'chargeCode',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'CURRENT AMOUNT',
    prop: 'currentAmount',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'COMPARE AMOUNT',
    prop: 'compareAmount',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'VARIANCE AMOUNT',
    prop: 'varianceAmount',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'CURRENT QUANTITY',
    prop: 'currentQuantity',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format: '#,###.###',
  }, {
    label: 'COMPARE QUANTITY',
    prop: 'compareQuantity',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format: '#,###.###',
  }, {
    label: 'VARIANCE QUANTITY',
    prop: 'varianceQuantity',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format: '#,###.###',
  }, {
    label: 'CURRENT PRICE',
    prop: 'currentPrice',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'COMPARE PRICE',
    prop: 'comparePrice',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'VARIANCE PRICE',
    prop: 'variancePrice',
    filterable: true,
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }, {
    label: 'FILE VERSION',
    prop: 'version1',
    filterable: true,
    sortable: true,
    visible: false,
    editable: false,
  }, {
    label: 'COMP VERSION',
    prop: 'version2',
    filterable: true,
    sortable: true,
    visible: false,
    editable: false,
  },
  ],
  summary: {
    currentAmountSummary: {
      prop: 'CURRENT AMOUNT',
      summaryType: 'sum',
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    compareAmountSummary: {
      prop: 'COMPARE AMOUNT',
      summaryType: 'sum',
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    varianceSummary: {
      prop: 'VARIANCE AMOUNT',
      summaryType: 'sum',
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    },
    currentQuantitySummary: {
      prop: 'CURRENT QUANTITY',
      summaryType: 'sum',
      format: '#,###.###',
    },
    compareQuantitySummary: {
      prop: 'COMPARE QUANTITY',
      summaryType: 'sum',
      format: '#,###.###',
    },
    varianceQuantitySummary: {
      prop: 'VARIANCE QUANTITY',
      summaryType: 'sum',
      format: '#,###.###',
    },
  },
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
    showSelectFilter: false,
    multipleSelection: true,
  },
};