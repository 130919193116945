import { ETAG_API } from '@/api';
import DefaultMapper from './defaultMapper';
import NotificationItem from '../notificationItem';
import { ETagOptions } from '../../PscsUserOptions/UserNotification/sourceOptions/etagOptions';

export default class ETagMapper extends DefaultMapper {
  constructor() {
    super('ETAG');
    this.optionConfiguration = new ETagOptions();
  }

  isSourceMapper(source) {
    return this.name === source.toUpperCase();
  }

  ignoreMessage(selectedConfigurationFilters, item) {
    return this.optionConfiguration.ignoreMessage(selectedConfigurationFilters, item);
  }

  createNotificationItem(item) {
    // The following two conditions are to use the first version
    // of the notification message
    if (!item.version) return super.createNotificationItem(item);

    if (item.version !== 'v2') super.createNotificationItem(item);

    const {
      tagID, tagIDSecurityKey, requestID, toEntityName, tagSummaryRef
    } = item.data;

    const uid = `${tagIDSecurityKey}_${requestID}_${item.type}`;

    let { text } = item;
    if (item.data) {
      if (item.data.marketType) text += ` (${item.data.marketType})`;
    }

    const notificationItem = new NotificationItem(
      item.version,
      item.type,
      text,
      item.state,
      item.source,
      item.severity,
      uid,
      item.ack,
      item,
      item.error,
    );

    notificationItem.showIfAcknowledgedAndNotAlreadyExists = item.type === 'RESOLUTION';
    notificationItem.subText = tagID;
    notificationItem.subTextRoute = {
      name: 'ViewTag',
      params: {
        tagId: tagID,
        entity: toEntityName,
      },
      query: {
        requestID,
        tagSummaryRef
      },
    };

    notificationItem.subscribe('action', (arg) => {
      ETAG_API.post(`${arg.item.message.data.tagIDSecurityKey}/acknowledgements`, {
        requestID: item.data.requestID,
        type: arg.item.type,
      });
    });

    return notificationItem;
  }
}