import invoiceEntityRefStore from './InvoiceEntities/invoiceEntityStore';
import contractRefStore from './Contracts/contractStore';
import chargeCodeRefStore from './ChargeCodes/chargeCodeStore';
import bankRefStore from './Banks/bankStore';
import pointOfContactRefStore from './PointsOfContact/pointOfContactStore';
import billDeterminantRefStore from './BillDeterminants/billDeterminantStore';
import settlementRefStore from './SettlementRef/settlementRefStore';
import contractChargeRefStore from './ContractChargeRef/contractChargeRefStore';
import priceRefStore from './Price/priceStore';
import meterRefStore from './Meter/meterStore';
import scheduleRefStore from './Schedule/scheduleStore';
import reservationRefStore from './Reservation/reservationStore';
import etagRefStore from './Etag/etagStore';
import contractGroupsStore from './ContractGroups/contractGroupsStore';
import counterpartyMappingStore from './CounterpartyMapping/counterpartyMappingStore';
import invoiceApprovalLimitStore from './InvoiceApprovalLimit/invoiceApprovalLimitStore';
import invoicingCompanyStore from './InvoicingCompanies/invoicingCompanyStore';
import interestRateRefStore from './InterestRates/interestRatesStore';

export default {
  namespaced: true,
  modules: {
    invoiceEntityRef: invoiceEntityRefStore,
    contractRef: contractRefStore,
    contractGroupsRef: contractGroupsStore,
    chargeCodeRef: chargeCodeRefStore,
    bankRef: bankRefStore,
    pointOfContactRef: pointOfContactRefStore,
    billDeterminantRef: billDeterminantRefStore,
    settlementRef: settlementRefStore,
    contractChargeRef: contractChargeRefStore,
    priceRef: priceRefStore,
    meterRef: meterRefStore,
    scheduleRef: scheduleRefStore,
    reservationRef: reservationRefStore,
    etagRef: etagRefStore,
    counterpartyMappingRef: counterpartyMappingStore,
    invoiceApprovalLimitRef: invoiceApprovalLimitStore,
    invoicingCompanyRef: invoicingCompanyStore,
    interestRateRef: interestRateRefStore,
  },
};