import {
  ETRM_API, ETAG_API, STRUCTURES_API, DELIVERY_MANAGEMENT_API, createAPIFromURL,
} from '@/api';
import { cloneDeep, sortBy } from '@/utils/dataUtil';
import Vue from 'vue';
import dateStore from '../../utils/dateStore';

export const _TRADE = 'TradeTerm';
export const _ETAG = 'ETAG';
export const _DELIVERY = 'Delivery';

let _ETAG_API = ETAG_API;
let _ETRM_API = ETRM_API;
let _STRUCTURES_API = STRUCTURES_API;
let _DELIVERY_MANAGEMENT_API = DELIVERY_MANAGEMENT_API;

// function connectionToState(connection) {
//   let tagKey = null;
//   let tradeKey = null;

//   if (connection.toKey.type === _ETAG) {
//     tagKey = 'toKey';
//   } else if (connection.toKey.type === _TRADE) {
//     tradeKey = 'toKey';
//   }

//   if (connection.fromKey.type === _ETAG) {
//     tagKey = 'fromKey';
//   } else if (connection.fromKey.type === _TRADE) {
//     tradeKey = 'fromKey';
//   }

//   return {
//     tradeID: connection[tradeKey].primaryKey,
//     tradeVariant: connection[tradeKey].variant,
//     tagID: connection[tagKey].primaryKey,
//     tagProfileID: connection[tagKey].secondaryKey,
//     tagVariant: connection[tagKey].variant,
//   };
// }

function connectionToQueryRequest(connection) {
  const params = {
    startTime: connection.startTime,
    endTime: connection.endTime,
    percentage: connection.percentage,
    toType: connection.toKey.type,
    toSubType: connection.toKey.subType,
    toPrimaryKey: connection.toKey.primaryKey,
    toSecondaryKey: connection.toKey.secondaryKey,
    toVariant: connection.toKey.variant,
    fromType: connection.fromKey.type,
    fromSubType: connection.fromKey.subType,
    fromPrimaryKey: connection.fromKey.primaryKey,
    fromSecondaryKey: connection.fromKey.secondaryKey,
    fromVariant: connection.fromKey.variant,
  };

  if (params.fromType === _ETAG) { params.toVariant = 'TAG'; }

  return params;
}

function connectionToOpposing(connection) {
  const fromKey = cloneDeep(connection.fromKey);
  const toKey = cloneDeep(connection.toKey);

  return { ...connection, toKey: fromKey, fromKey: toKey };
}

function dateRangeToAPIRequest(dateRange) {
  const tz = dateStore.getTimeZone();
  const momentRange = dateStore.toMomentAndZoneFromJSDateArray(dateRange, tz);
  const start = momentRange[0].startOf('day').utc();
  const end = momentRange[1].startOf('day').add(1, 'day').utc();

  return { start, end, tz };
}

function findPercentage(connection, pos) {
  let fromMWKey = 'leftMW';
  let toMWKey = 'rightMW';
  if (connection.fromKey.type === _ETAG) {
    fromMWKey = 'rightMW';
    toMWKey = 'leftMW';
    connection.toKey.variant = 'TAG';
  }
  const percentage = pos[fromMWKey] === 0 ? 0 : ((pos.connectedMW / pos[fromMWKey]) * 100);
  connection.percentage = percentage;
  connection.fromOriginalValue = pos[fromMWKey];
  connection.toOriginalValue = pos[toMWKey];
  connection.fromCurrentValue = connection.fromOriginalValue;
  connection.toCurrentValue = connection.toOriginalValue;
}

function createOrUpdateConnections(connection, possibleList, existingList) {
  const newConnectionList = [];
  possibleList.forEach((pos) => {
    const newConnection = cloneDeep(connection);
    newConnection.startTime = pos.startTime.toISOString();
    newConnection.endTime = pos.endTime.toISOString();
    const existingConnection = existingList.find((x) => x.momentStart.toISOString() === newConnection.startTime
                                && x.fromKey.type === newConnection.fromKey.type
                                && x.toKey.type === newConnection.toKey.type);
    if (existingConnection) newConnection.id = existingConnection.id;
    findPercentage(newConnection, pos);
    newConnectionList.push(newConnection);
  });
  return newConnectionList;
}

export default {
  namespaced: true,
  state: {
    possibleTableConfiguration: {
      columns: [
        {
          prop: 'startTime',
          label: 'Date',
          minWidth: 70,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) { return; }
            return dateStore.toMoment(params.value).format('l');
          },
        },
        {
          prop: 'startTime',
          label: 'Start',
          minWidth: 70,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) { return; }
            return dateStore.toMoment(params.value).format('HH:mm');
          },
        },
        {
          prop: 'endTime',
          label: 'End',
          minWidth: 70,
          valueFormatter: function dateValueGetter(params) {
            if (params.value == null) { return; }
            return dateStore.toMoment(params.value).format('HH:mm');
          },
        },
        { prop: 'leftMW', label: 'MWs', minWidth: 150 },
        { prop: 'rightMW', label: 'MWs', minWidth: 150 },
        {
          prop: 'connectedMW',
          label: 'Connected MWs',
          minWidth: 150,
          editable: true,
          cellStyle: (params) => {
            if (params.data.leftMW === params.data.rightMW && params.data.rightMW === params.data.connectedMW) {
              return { backgroundColor: '#ff863540' };
            }
            return null;
          },
        },
        { prop: 'tagPercentage', visible: false },
        { prop: 'dealPercentage', visible: false },
      ],
      footer: {
        count: false,
      },
      style: {
        maxHeight: 350,
      },
    },
    existingTableConfiguration: {
      columns: [
        { prop: 'date', label: 'Date' },
        { prop: 'startHour', label: 'Start' },
        { prop: 'endHour', label: 'End' },
        {
          prop: 'percentage',
          label: '%',
          format: {
            type: 'decimal',
            precision: 2,
          },
        },
        { prop: 'fromType', label: 'From Type' },
        { prop: 'fromPrimaryKey', label: 'From ID' },
        { prop: 'fromSecondaryKey', label: 'From Secondary Key' },
        { prop: 'fromVariant', label: 'From Variant' },
        { prop: 'toType', label: 'To Type' },
        { prop: 'toPrimaryKey', label: 'To Primary Key' },
        { prop: 'toSecondaryKey', label: 'To Secondary Key' },
        { prop: 'toVariant', label: 'To Variant' },
      ],
      options: {
        exportExcel: false,
        filterRow: false,
        filterHeader: false,
        columnConfig: false,
        showSelectFilter: false,
      },
    },
    tagIDs: [],
    tradeIDs: [],
    deliveryIDs: [],
    fromDeliveryIDs: [],
    toDeliveryIDs: [],
    variants: [],
    dateRange: [],
    profileIDs: [],
    tagProfiles: [],
    relationships: [],
    tradeIntervals: [],
    fromDeliveryIntervals: [],
    toDeliveryIntervals: [],
    possibleTableData: [],
    existingTableData: [],
    type: null,
    mode: null,
    supportedTypes: [],
    primaryKey: null,
    excludedDates: null,
    connectionState: null,
    twoWayRelationship: true,
    relationshipDialogVisible: false,
    viewName: 'possible-relationships',
  },
  actions: {
    async loadEndpoints({ commit }) {
      try {
        const { data: { url } } = await STRUCTURES_API.get('endpoints/etag');
        _ETAG_API = createAPIFromURL(url);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data: { url } } = await STRUCTURES_API.get('endpoints/etrm');
        _ETRM_API = createAPIFromURL(url);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data: { url } } = await STRUCTURES_API.get('endpoints/structures');
        _STRUCTURES_API = createAPIFromURL(url);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data: { url } } = await DELIVERY_MANAGEMENT_API.get('endpoints/delivery_management');
        _DELIVERY_MANAGEMENT_API = createAPIFromURL(url);
      } catch (error) {
        console.error(error);
      }
    },
    async loadConnections({ commit, state }, connection) {
      let opposingRelationships = [];

      if (state.twoWayRelationship) {
        const params = connectionToQueryRequest(connectionToOpposing(connection));
        const { data } = await _STRUCTURES_API.get('relationships/exists', { params });
        opposingRelationships = data;
      }

      const params = connectionToQueryRequest(connection);
      const { data } = await _STRUCTURES_API.get('relationships/exists', { params });

      const combinedRelationships = [...opposingRelationships, ...data];
      const relationships = combinedRelationships.map((x) => ({
        ...x,
        momentStart: dateStore.toMoment(x.startTime).utc(),
        momentStop: dateStore.toMoment(x.endTime).utc(),
      }));

      const existingRelationships = relationships.map((x) => ({
        ...connectionToQueryRequest(x),
        date: dateStore.toMoment(x.startTime).clone().format('l'),
        startHour: dateStore.toMoment(x.startTime).format('HH:mm'),
        endHour: dateStore.toMoment(x.endTime).format('HH:mm'),
      }));

      commit('setRelationships', relationships);
      commit('setExistingTableData', existingRelationships);
    },
    async loadPossibleConnections({ commit, state }, connection) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);

      const possibleConnections = [];
      const connectionQuery = connectionToQueryRequest(connection);
      const existingRelationships = state.relationships.filter((x) => x.fromKey.type === connectionQuery.fromType
      && x.toKey.type === connectionQuery.toType);
      let currentTime = start.clone();

      while (currentTime.isBefore(end)) {
        const curr_hr = currentTime.clone();
        const next_hr = currentTime.clone().add(1, 'hour');

        const from = (state.type === _DELIVERY ? state.fromDeliveryIntervals : state.tagProfiles).find((x) => x.momentStart.toISOString() === curr_hr.toISOString());
        const to = (state.type === _DELIVERY ? state.toDeliveryIntervals : state.tradeIntervals).find((x) => x.momentStart.toISOString() === curr_hr.toISOString());

        if (from && to) {
          const possibleConnection = {
            id: null,
            startTime: curr_hr.clone().utc(),
            endTime: next_hr.clone().utc(),
            leftMW: to.value,
            rightMW: from.value,
            connectedMW: Math.min(from.value, to.value),
          };

          const existingRelationship = existingRelationships.find(
            (x) => x.momentStart.toISOString() === possibleConnection.startTime.toISOString());
          if (existingRelationship) {
            possibleConnection.id = existingRelationship.id;
            let mwKey = 'leftMW';
            if (connection.fromKey.type === _ETAG) mwKey = 'rightMW';
            possibleConnection.connectedMW = Math.round(
              possibleConnection[mwKey] * (existingRelationship.percentage / 100));
          }
          possibleConnections.push(possibleConnection);
        }
        currentTime = currentTime.add(1, 'hour');
      }
      commit('setConnectionState', connection);
      if (possibleConnections.length === 0) throw new Error('No possible connections found.');
      commit('setPossibleTableData', possibleConnections);
    },
    async createConnections({ dispatch, state, commit }) {
      const connections = createOrUpdateConnections(
        state.connectionState,
        state.possibleTableData, state.relationships);

      if (state.twoWayRelationship) {
        const opposingConnections = createOrUpdateConnections(
          connectionToOpposing(state.connectionState),
          state.possibleTableData,
          state.relationships);
        connections.push(...opposingConnections);
      }

      const createdModels = connections.filter((x) => !x.id);
      const updatedModels = connections.filter((x) => x.id);

      let created = true;
      let updated = true;

      if (updatedModels.length > 0) {
        const response = await _STRUCTURES_API.put('relationships/bulk', updatedModels);
        updated = response.status === 200;
      }
      if (createdModels.length > 0) {
        const response = await _STRUCTURES_API.post('relationships/bulk', createdModels);
        created = response.status === 200;
      }
      if (!(created && updated)) throw new Error('Error ocurred while saving relationships.');
      else dispatch('loadConnections', state.connectionState);
    },
    async loadTrades({ commit, state }) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);
      const { data } = await _ETRM_API.get(
        `/trades?effectiveStartDateBefore=${end.format()}&effectiveEndDateAfter=${start.format()}`);
      const trades = data.map((trade) => ({
        value: trade.tradeId,
        label: `${trade.tradeName}/${trade.tradeId}`,
      }));
      if (!trades || trades.length === 0) throw new Error('Unable to retrieve Trade Ids.');
      commit('setTradeIDs', trades);
    },
    async loadTagIds({ commit, state }) {
      commit('setTagIds', []);
      const { start, end, tz } = dateRangeToAPIRequest(state.dateRange);
      const includedStates = ['IMPLEMENTED', 'CONFIRMED', 'TERMINATED'];
      let tags = [];

      try {
        tags = await _ETAG_API.get('/tags', {
          params: {
            timeZone: tz,
            start: start.format(),
            end: end.format(),
          },
        });
      } catch (err) {
        throw new Error('Error occurred while retrieving tags.');
      }

      if (tags) {
        const { data } = tags;

        const tagIDs = data?.tagSummaries
          .filter((x) => includedStates.includes(x.compositeState))
          ?.map((v) => ({ value: v.tagIDSecurityKey, label: v.tagID }));

        if (!tagIDs || tagIDs.length === 0) throw new Error('No Tags found for selected date range.');

        commit('setTagIds', tagIDs);
      }
    },
    async loadDeliveries({ commit, state }) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);
      const { data } = await _DELIVERY_MANAGEMENT_API.get(
        `/deliveries?startTimeBefore=${end.format()}&endTimeAfter=${start.format()}`);
      const deliveries = data?.deliveries?.map((delivery) => ({
        value: delivery.id,
        label: delivery.type ? `${delivery.type}/${delivery.name}` : `${delivery.name}`,
      }));
      if (!deliveries || deliveries.length === 0) throw new Error('Unable to retrieve Deliveries.');
      commit('setDeliveryIDs', deliveries);
    },
    async loadTradeIntervals({ commit, state }, item) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);

      const params = {
        objectTypes: _TRADE,
        objectReferences: item.tradeID,
        startTime: start.toISOString(),
        endTime: end.toISOString(),
        granularity: 60,
        variants: item.tradeVariant,
        types: 'VOLUME',
      };

      const { data: { data } } = await _STRUCTURES_API.get('intervals', { params });
      const tradeIntervals = data.map((x) => ({
        ...x,
        momentStart: dateStore.toMoment(x.startTime).utc(),
        momentStop: dateStore.toMoment(x.endTime).utc(),
      }));
      if (!tradeIntervals || tradeIntervals.length === 0) throw new Error('Unable to retrieve Trade Intervals.');
      commit('setTradeIntervals', tradeIntervals);
    },
    async loadDeliveryIntervals({ commit, state }, item) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);

      const params = {
        startTime: start.toISOString(),
        endTime: end.toISOString(),
        period: 'Hourly',
        granularity: 60,
        variants: item.deliveryVariant,
        intervalVariants: item.deliveryVariant,
        types: 'VOLUME',
        intervalTypes: 'VOLUME',
        outputTimeZone: dateStore.getTimeZone(),
      };

      const { data } = await _DELIVERY_MANAGEMENT_API.get(`deliveries/${item.deliveryID}`, { params });
      const deliveryIntervals = data?.intervals?.map((x) => ({
        ...x,
        momentStart: dateStore.toMoment(x.startTime).utc(),
        momentStop: dateStore.toMoment(x.endTime).utc(),
      }));

      if (!deliveryIntervals || deliveryIntervals.length === 0) throw new Error('Unable to retrieve Delivery Intervals.');
      commit(item.setter, deliveryIntervals);
    },

    async loadTagProfileIDs({ commit, state }, item) {
      if (state.mode === _ETAG) return;
      const { data } = await _ETAG_API.get(item);
      if (!data) throw new Error('Unable to retrieve Tag.');
      const { segmentList } = data;
      const source = segmentList[0].physicalSegmentList[0].pod;
      const profileIDs = [{ label: source, value: 'ENERGY-1' }];
      segmentList.forEach((ms) => {
        ms.physicalSegmentList.forEach((ps) => {
          ps.transmissionAllocationList.forEach((ta) => {
            profileIDs.push({
              label: `${ta.contractNumber} (${ps.pod} -> ${ps.por})`,
              value: `CAPACITY-${ta.profileRef}`,
            });
          });
        });
      });
      if (!profileIDs || profileIDs.length === 0) throw new Error('Unable to retrieve Tag profile Ids.');
      commit('setProfileIds', profileIDs);
    },
    async loadTagProfiles({ commit, state }, item) {
      const { start, end } = dateRangeToAPIRequest(state.dateRange);

      const [profileProductType, profileID] = item.tagProfileID.split('-');

      const { data: { data } } = await _ETAG_API.get('/reporting', {
        params: {
          tagIDSecurityKey: item.tagIDSecurityKey,
          startTime: start.toISOString(),
          endTime: end.toISOString(),
          profileProductType,
          profileID,
          granularity: 60,
        },
      });
      if (!data || data.length === 0) throw new Error('Unable to retrieve Tag Profiles.');

      const tagProfiles = data[0].values.map((x) => ({
        ...x,
        momentStart: dateStore.toMoment(x.startTime).utc(),
        momentStop: dateStore.toMoment(x.endTime).utc(),
      }));
      commit('setTagProfiles', tagProfiles);
    },
    loadVariants({ commit, state }) {
      _STRUCTURES_API.get('/variant').then((response) => {
        if (response.data.data) {
          const distinct = (value, index, self) => self.indexOf(value) === index;
          const distinctList = response.data.data.sort(sortBy('name')).map(({ name }) => name).filter(distinct);
          const variantsList = distinctList.map((v) => ({ value: v, label: v }));

          commit('setVariants', variantsList);
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    initialize({ commit, dispatch, state }, props) {
      commit('setMode', props.mode);
      commit('setSupportedTypes', props.mode);
      commit('setConnectionState', null);
      commit('setViewName', 'possible-relationships');
      commit('setStartEndDates', props.dateRange);
      dispatch('loadVariants');

      if (props.mode === _ETAG) {
        state.type = _ETAG;
        state.primaryKey = props.tagIDs[0].value;
        commit('setProfileIds', props.profileIDs);
        commit('setTagIds', props.tagIDs);
        dispatch('loadTrades');
      } else if (props.mode === _TRADE) {
        state.type = _TRADE;
        state.primaryKey = props.tradeIDs[0].value;
        commit('setTradeIDs', props.tradeIDs);
        commit('setTradeIntervals', props.tradeIntervals);
        dispatch('loadTagIds');
      } else if (props.mode === _DELIVERY) {
        state.type = _DELIVERY;
        dispatch('loadDeliveries');

        state.primaryKey = props.deliveryIDs && props.deliveryIDs.length > 0 ? props.deliveryIDs[0].value : null;
        commit('setDeliveryIDs', props.deliveryIDs);
        // commit('setDeliveryIntervals', props.deliveryIntervals);
      }
    },
  },
  mutations: {
    setMode(state, item) {
      state.mode = item;
    },
    setSupportedTypes(state, item) {
      console.log(item);
      if (item === _ETAG || item === _TRADE) {
        state.supportedTypes = [{ value: _TRADE, label: _TRADE }, { value: _ETAG, label: _ETAG }];
      } else if (item === _DELIVERY) {
        state.supportedTypes = [{ value: _DELIVERY, label: _DELIVERY }];
      }
    },
    setVariants(state, item) {
      const variants = [];
      if (item && item.length) variants.push(...item);
      if (!variants.map((x) => x.label).includes('TAG')) variants.push({ label: 'TAG', value: 'TAG' });
      state.variants = variants;
    },
    setRelationshipDialogVisibility(state, item) {
      state.relationshipDialogVisible = item;
    },
    setProfileIds(state, item) {
      state.profileIDs = item;
    },
    setTagProfiles(state, item) {
      state.tagProfiles = item;
    },
    setViewName(state, item) {
      state.viewName = item;
    },
    setExistingTableData(state, item) {
      state.existingTableData = item;
    },
    setDateRange(state, item) {
      state.dateRange = item;
    },
    setTagIds(state, item) {
      state.tagIDs = item;
    },
    setTradeIDs(state, item) {
      state.tradeIDs = item;
    },
    setDeliveryIDs(state, item) {
      state.deliveryIDs = item;
    },
    setTradeIntervals(state, item) {
      state.tradeIntervals = item;
    },
    setFromDeliveryIntervals(state, item) {
      state.fromDeliveryIntervals = item;
    },
    setToDeliveryIntervals(state, item) {
      state.toDeliveryIntervals = item;
    },
    setRelationships(state, item) {
      state.relationships = item;
    },
    setStartEndDates(state, item) {
      const zone = dateStore.getTimeZone();
      const dateRange = dateStore.toMomentAndZoneFromJSDateArray(item, zone);
      const start = dateRange[0].startOf('day');
      const end = dateRange[1].startOf('day').add(1, 'days');

      state.dateRange = [
        dateStore.toLocalFromDate(start).toDate(),
        dateStore.toLocalFromDate(end).toDate(),
      ];

      state.excludedDates = {
        start: dateStore.toLocalFromDate(start).toDate(),
        end: dateStore.toLocalFromDate(end).toDate(),
      };
    },
    setPossibleCellData(state, item) {
      if (!(item.value || item.value === 0)) {
        item.value = 0;
      }
      const dt = state.possibleTableData[item.rowIndex];
      dt[item.prop] = item.value;

      const bs = state.possibleTableData[item.rowIndex];
      const maxConnected = bs.leftMW > bs.rightMW ? bs.rightMW : bs.leftMW;
      const newValue = item.value <= maxConnected ? item.value : maxConnected;
      Vue.set(bs, item.prop, newValue);
    },
    setPossibleTableData(state, item) {
      state.possibleTableData = item;
    },
    setConnectionState(state, item) {
      state.connectionState = item;
    },
    setTwoWayRelationship(state, item) {
      state.twoWayRelationship = item;
    },
    setMwColumnLabel(state, item) {
      let label = 'MWs';

      if (item.type === _TRADE || item.type === _DELIVERY) {
        if (item.primaryKey) {
          label = `${item.primaryKey} ${label}`;
        }
      } else if (item.type === _ETAG) {
        if (item.primaryKey) {
          label = `${item.primaryKey.substring(0, 20)} ${label}`;
        }
      }

      state.possibleTableConfiguration.columns[item.index].label = label;
    },
  },
};