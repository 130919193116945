import Dictionary from '@/utils/dictionary';
import mappers from './sourceMappers';

class NotificationService {
  constructor() {
    this.messages = new Dictionary();
    this.profileGetter = () => null;
    this.topDownSoundLogic = true;
  }

  setProfileGetter(profileGetter) {
    this.profileGetter = profileGetter;
  }

  get messageCount() {
    return this.messages.count;
  }

  get entries() {
    return this.messages.values;
  }

  ignoreMessage(notificationItem) {
    const profile = this.profileGetter();

    if (!profile) return true;

    const { moduleOptions } = profile;

    if (!moduleOptions) return false;

    const module = moduleOptions.find((f) => f.name.toLowerCase() === notificationItem.source.toLowerCase());

    if (!module) return false;

    const mapper = mappers.getMapper(notificationItem.source);

    return mapper.ignoreMessage(module.options, notificationItem);
  }

  createMessage(ablyMessage, messageOptions) {
    if (!messageOptions) messageOptions = { soundEnabled: true };

    const item = mappers.createNotificationItem(ablyMessage);

    if (this.ignoreMessage(item)) return null;

    const profile = this.profileGetter();

    // if the profile has any notifications
    if (profile && profile.notifications) {
      for (let x = 0; x < profile.notifications.length; x++) {
        const setting = profile.notifications[x];
        // if the notification and user notification settings have an enabled item
        if (item.matchSourceAndType(setting.source, setting.type)) {
          if (!setting.enabled) return null;

          if (messageOptions.soundEnabled) {
            if (setting.audio) {
              item.sound.required = true;
              item.sound.setAudioType(setting.audioType);
              item.sound.setName(setting.soundType);
              item.sound.setDuration(setting.duration);
              item.sound.setSpeechText(setting.description);
              item.sound.subscribe('disabled', () => this.advanceSound());
            }
          }
        }
      }
    }

    return item;
  }

  messageReceived(newMessage) {
    if (newMessage) {
      try {
        const existingMessage = this.messages.get(newMessage.uid);

        // does there already exist a message with the same uid
        if (existingMessage) {
          // if the incoming message has been confirmed, remove thie existing one
          if (newMessage.isAcknowledged && newMessage.removeIfAcknowledged) {
            existingMessage.sound.disable();
            existingMessage.sound.unsubscribeAll('disabled');
            existingMessage.unsubscribeAll();
            this.messages.remove(existingMessage.uid);
          } else {
            existingMessage.message = newMessage.message;
            existingMessage.state = newMessage.state;
            existingMessage.error = newMessage.error;
            existingMessage.displayMessage = newMessage.displayMessage;
            existingMessage.setAck(newMessage.ack);
          }
        } else {
          // if the new message is already confirmed
          if (newMessage.isAcknowledged) {
            // should this message be shown if there does not exists a message for it already
            if (newMessage.showIfAcknowledgedAndNotAlreadyExists) {
              this.messages.add(newMessage.uid, newMessage);
            }
          } else {
            this.messages.add(newMessage.uid, newMessage);
          }
        }

        this.advanceSound();
      } catch (err) {
        console.error(err);
      }
    }
  }

  clear() {
    const keepMessages = new Dictionary();
    // if the notification is not in an end state or if ack is required
    // keep the messages
    this.messages.entries().reverse().forEach((n) => {
      if (n.value.isLoading || n.value.isAcknowledgementRequired) {
        keepMessages.add(n.key, n.value);
      } else {
        n.value.sound.unsubscribeAll('disabled');
      }
    });

    this.messages = keepMessages;
  }

  advanceSound() {
    let items = this.entries;

    for (let x = 0; x < items.length; x++) {
      if (items[x].sound.isPlaying) {
        return;
      }
    }

    if (!this.topDownSoundLogic) {
      items = [].concat(items).reverse();
    }

    for (let x = 0; x < items.length; x++) {
      if (items[x].sound.required) {
        items[x].sound.enable();
        return;
      }
    }
  }
}

const notificationService = new NotificationService();

export default notificationService;