import { accessRight, admin } from '@/auth/permission';

export default
{
  path: '/admin',
  meta: {
    visible: true, requiresAuth: true, text: 'ADMIN', permission: { name: admin.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'editor',
    name: 'Script Editor',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'Script Editor',
      permission: { name: admin.scriptEditor, rights: [accessRight.visible] },
    },
    component: () => import('@/components/Admin/ScriptEditor/ScriptEditor'),
  }],
};