import {
  tradeDateRange,
  coordinatorList,
  resourcesTagbox,
  marketTypes,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: {
    columns: [

      {
        label: 'RESOURCE ID',
        prop: 'rsrC_ID',
        filterable: true,
        sortable: true,
      },
      {
        label: 'MARKET TYPE',
        prop: 'markeT_TYPE',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING DATE',
        prop: 'tradingDate',
        dataType: 'date',
        format: 'yyyy-MM-dd',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TRADING HOUR',
        prop: 'tradingHour',
        filterable: true,
        sortable: true,
      },
      {
        label: 'TOTAL EXEMPT',
        prop: 'totalExempt',
        filterable: true,
        sortable: true,
      },
      {
        label: 'USE LIMIT EXEMPT',
        prop: 'useLimitExempt',
        filterable: true,
        sortable: true,
      },
      {
        label: 'NON USE LIMIT EXEMPT',
        prop: 'nonUseLimitExempt',
        filterable: true,
        sortable: true,
      },
      {
        label: 'GENERIC RA',
        prop: 'genericRA',
        filterable: true,
        sortable: true,
      },
      {
        label: 'GENERIC CPM RA',
        prop: 'genericCPMRA',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX RA',
        prop: 'flexRA',
        filterable: true,
        sortable: true,
      },
      {
        label: 'FLEX CPM RA',
        prop: 'flexCPMRA',
        filterable: true,
        sortable: true,
      },
    ],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
      columnConfig: true,
      showSelectFilter: false,
    },
  },
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: true },
    resourceList: { ...resourcesTagbox, maxLimit: 6, required: false },
    marketTypes: { ...marketTypes, required: true },
  },
};