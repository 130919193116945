import { ETRM_API } from '@/api';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'name', width: 300, label: 'Name', sortable: true,
    }, {
      prop: 'description', width: 300, label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    name: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    ETRM_API.get('/trade-types').then(({ data }) => {
      commit('loadTableData', data.data);
      this.$notify(`${data.data.length} Trade Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Trade Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postTradeType({ state }, tradeType) {
    ETRM_API.post('/trade-types', tradeType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Trade Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Trade Type', 'error');
    });
  },
  updateTradeType({ commit }, tradeType) {
    ETRM_API.put(`/trade-types/${tradeType.id}`, tradeType).then(({ data }) => {
      this.$notify('Trade Type Updated', 'success');
      commit('updateTradeType', data);
    }).catch((err) => {
      this.$notify('Failed to Update Trade Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    ETRM_API.delete(`/trade-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      this.$notify('Trade Type Removed', 'success');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    }).catch((err) => {
      this.$notify('Failed to Remove Trade Type', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit, state }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateTradeType(state, tradeType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === tradeType.id);
    state.tableData.splice(rowIndex, 1, tradeType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, tradeTypes) {
    state.tableData = tradeTypes;
  },
  createTableRow(state) {
    const tradeType = clone(state.nullTableRow);
    tradeType.id = state.tableKey++;
    tradeType.createdBy = this.getters['auth/getProfile'].userName;
    tradeType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = tradeType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter(tradeType => tradeType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};