import utils from '@/utils';
import { CISO_MASTERDATA_API, CISO_BID_SCHD_API } from '@/api';
import dateStore from '@/utils/dateStore';
import moment from 'moment';
import { has, cloneDeep } from '@/utils/dataUtil';
import {
  findSchedulingCoordinator, findResource, findResourcesByBsc, findResources, findBaseSchedulingCoordinator, mapToOptions, joinBaseScheduleStatusesAndResources,
} from './util';

const DAY = 'DAY';
const LONG_DAY = 'LONG_DAY';
const SHORT_DAY = 'SHORT_DAY';

const state = {
  tableConfiguration: {
    columns: [
      {
        prop: 'sc', fixed: 'left', sortable: true, filter: true,
      },
      {
        prop: 'resource', minWidth: 180, type: { name: 'base-schedule-route-cell-ag', to: 'BidDetail', overrideReadonly: true }, fixed: true, sortable: true, filter: true,
      },
      {
        prop: 'configuration', fixed: 'left', minWidth: 160, visible: true, sortable: true, filter: true,
      },
      {
        prop: 'productType', label: 'product', fixed: 'left', minWidth: 160, visible: true, sortable: false, filter: false,
      },
      { prop: 'he01', label: 'HE01', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he02', label: 'HE02', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      {
        prop: 'he02*', label: 'HE02*', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true }, visible: false,
      },
      { prop: 'he03', label: 'HE03', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he04', label: 'HE04', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he05', label: 'HE05', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he06', label: 'HE06', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he07', label: 'HE07', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he08', label: 'HE08', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he09', label: 'HE09', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he10', label: 'HE10', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he11', label: 'HE11', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he12', label: 'HE12', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he13', label: 'HE13', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he14', label: 'HE14', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he15', label: 'HE15', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he16', label: 'HE16', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he17', label: 'HE17', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he18', label: 'HE18', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he19', label: 'HE19', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he20', label: 'HE20', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he21', label: 'HE21', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he22', label: 'HE22', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he23', label: 'HE23', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      { prop: 'he24', label: 'HE24', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true } },
      {
        prop: 'he02*', label: 'HE25', type: { name: 'base-schedule-status-cell-ag', overrideReadonly: true }, visible: false,
      },

    ],
    footer: {
      count: true,
    },
    style: {
      maxHeight: 530,
    },
    options: {
      searchFilter: false,
      highlightRow: true,
      multipleSelection: true,
      cellSelection: true,
      warningHighlight: true,
    },
  },
  tableHourConfiguration:
    {
      columns: [
        { prop: 'row', label: ' ' },
        { prop: 'he01', label: 'HE01', width: 76 },
        { prop: 'he02', label: 'HE02', width: 76 },
        {
          prop: 'he02*', label: 'HE02*', width: 76, visible: false,
        },
        { prop: 'he03', label: 'HE03', width: 76 },
        { prop: 'he04', label: 'HE04', width: 76 },
        { prop: 'he05', label: 'HE05', width: 76 },
        { prop: 'he06', label: 'HE06', width: 76 },
        { prop: 'he07', label: 'HE07', width: 76 },
        { prop: 'he08', label: 'HE08', width: 76 },
        { prop: 'he09', label: 'HE09', width: 76 },
        { prop: 'he10', label: 'HE10', width: 76 },
        { prop: 'he11', label: 'HE11', width: 76 },
        { prop: 'he12', label: 'HE12', width: 76 },
        { prop: 'he13', label: 'HE13', width: 76 },
        { prop: 'he14', label: 'HE14', width: 76 },
        { prop: 'he15', label: 'HE15', width: 76 },
        { prop: 'he16', label: 'HE16', width: 76 },
        { prop: 'he17', label: 'HE17', width: 76 },
        { prop: 'he18', label: 'HE18', width: 76 },
        { prop: 'he19', label: 'HE19', width: 76 },
        { prop: 'he20', label: 'HE20', width: 76 },
        { prop: 'he21', label: 'HE21', width: 76 },
        { prop: 'he22', label: 'HE22', width: 76 },
        { prop: 'he23', label: 'HE23', width: 76 },
        { prop: 'he24', label: 'HE24', width: 76 },
        {
          prop: 'he02*', label: 'HE25', width: 76, visible: false,
        },

      ],
      footer: {
        count: true,
      },
      style: {
        maxHeight: 300,
      },
      options: {
        searchFilter: false,
        highlightRow: true,
      },
    },
  tableData: [],
  tableHourData: [],
  resourceTypes: [],
  selectedResourceType: null,
  datePropsAndFormats: [
    { name: 'tradeDate', format: 'YYYY-MM-DD' },
    { name: 'updatedDate', format: 'YYYY-MM-DD HH:mm:ss' },
  ],
  date: utils.date.getTomorrowLocal(),
  selectedMarketType: 'RTM',
  marketTypes: [{ value: 'DAM', label: 'DAM' }, { value: 'RTM', label: 'RTM' }],
  selectedBSC: '',
  BSCs: [{ value: 'EPACBSC', label: 'EPACBSC' }, { value: 'WPACBSC', label: 'WPACBSC' }],
  bscToResources: [],
  isSearched: false,
  statuses: [],
  scToResources: [],
  selectedSchedulingCoordinator: '',
  filteredResources: [],
  selectedResource: '',
  multipleSelection: [],
  startHour: 1,
  endHour: 24,
  hours: [],
  showOpenHours: false,
  hourDataVisibile: true,
  valueTypeFlag: true,
  productTypes: [{ value: 'EN', label: 'EN' }, { value: 'RU', label: 'RU' }, { value: 'RD', label: 'RD' }, { value: 'SR', label: 'SR' }, { value: 'NR', label: 'NR' }],
  selectedProductType: 'EN',
  resourceGroups: [],
  selectedResourceGroup: null,
  lastLoad: null,
  searchTypeList: [{ value: 'ALL', label: 'All' },
    { value: 'SAVED', label: 'Saved' },
    { value: 'SUBMITTED', label: 'Submitted' },
    { value: 'NOTSCHEDULED', label: 'Not Scheduled' }],
  searchType: 'ALL',
  mode: '',
  is2D: dateStore.getLongDayHour(),
};

function isRowSelected(row) {
  if (row) {
    return has(row, 'scheduleName') && (row.scheduleName !== '' && row.scheduleName);
  }
  return false;
}

const getters = {
  getResourceGroups(state) {
    const groups = [];
    if (state.resourceGroups && state.resourceGroups.length > 0) {
      for (let i = 0; i < state.resourceGroups.length; i++) {
        const group = { value: state.resourceGroups[i].id, label: state.resourceGroups[i].name };
        groups.push(group);
      }
    }
    return groups;
  },
  getDefaultSelection(state) {
    const defaultSelection = state.tableData.filter((o) => o.scheduleName);
    return defaultSelection;
  },
  getTableConfiguration(state) {
    const config = cloneDeep(state.tableConfiguration);

    for (let i = 0; i < config.columns.length; i++) {
      const col = config.columns[i];
      if (col.prop.includes('he')) {
        if (state.mode === LONG_DAY) {
          if (col.label.includes('02*')) col.visible = state.is2D;
          if (col.label.includes('25')) col.visible = !(state.is2D);
        } else if (state.mode === SHORT_DAY) {
          if (col.label.includes('03')) col.visible = false;
        } else if (col.label.includes('02*') || col.label.includes('25')) col.visible = false;
      }
    }

    if (state.showOpenHours && state.selectedMarketType === 'RTM') {
      const now = moment();
      const momentDate = moment(state.date);
      const diff = momentDate.diff(now, 'minutes');
      let hour = 0;
      for (let i = 0; i < config.columns.length; i++) {
        const col = config.columns[i];
        if (col.prop.includes('he')) {
          if (diff + ((hour) * 60) < 75) {
            col.visible = false;
          }
          hour++;
        }
      }
    }
    config.isRowSelected = isRowSelected;
    return config;
  },
  getTableHourConfiguration(state) {
    const config = cloneDeep(state.tableHourConfiguration);
    for (let i = 0; i < config.columns.length; i++) {
      const col = config.columns[i];
      if (col.prop.includes('he')) {
        if (state.mode === LONG_DAY) {
          if (col.label.includes('02*')) { col.visible = state.is2D; }
          if (col.label.includes('25')) { col.visible = !(state.is2D); }
        } else if (state.mode === SHORT_DAY) {
          if (col.label.includes('03')) { col.visible = false; }
        } else if (col.label.includes('02*') || col.label.includes('25')) col.visible = false;
      }
    }
    return config;
  },
  getTableData(state) {
    if (state.isSearched) {
      return state.tableData;
    }
    return [];
  },
  getTableHourData(state) {
    if (state.tableHourData != null) return state.tableHourData;
    return [];
  },
  getMinHeight(state) {
    if (state.tableData && state.tableData.length > 0) return 600;

    return 100;
  },
  getSchedulingCoordinators: (state) => mapToOptions(state.scToResources),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
  getResources: (state) => mapToOptions(state.filteredResources),
  getResource: (state) => state.selectedResource,
  getDate: (state) => state.date,
  getMarketType: (state) => state.selectedMarketType,
  getMarketTypes: (state) => state.marketTypes,
  getBSC: (state) => state.selectedBSC,
  getBSCs: (state) => mapToOptions(state.bscToResources),
  getResourceTypes: (state) => state.resourceTypes,
  getResourceType: (state) => state.selectedResourceType,
  getStartHour: (state) => state.startHour,
  getEndHour: (state) => state.endHour,
  getHours: (state) => state.hours,
  getShowOpenHours: (state) => state.showOpenHours,
  getHourDataVisibilityFlag: (state) => state.hourDataVisibile,
  getValueTypeFlag: (state) => state.valueTypeFlag,
  getProductTypeList: (state) => state.productTypes,
  getProductType: (state) => state.selectedProductType,
  getResourceGroup: (state) => state.selectedResourceGroup,
  getLastLoad: (state) => state.lastLoad,
  getSearchTypeList: (state) => state.searchTypeList,
  getSearchType: (state) => state.searchType,
};

const actions = {
  loadData({ dispatch, commit, state }) {
    dispatch('loadHours', state.date);
    dispatch('loadBaseSchedulingCoordinatorsAction');
    dispatch('loadSchedulingCoordinatorsWithResourcesAction');
    dispatch('loadResourceGroups');
    dispatch('loadResourceTypes');
  },
  loadResourceGroups({ commit }) {
    CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE')
      .then((response) => {
        if (response.data && response.data.resourceGroups && response.data.resourceGroups.length > 0) {
          commit('setResourceGroups', response.data.resourceGroups);
          commit('setFilteredResources');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  cellClickChanged({ commit }, item) {
    if (item.row != null) {
      commit('setTableHourData', item);
    } else { state.tableHourData = []; }
  },
  showOpenHoursChanged({ commit }) {
    commit('setShowOpenHours');
  },
  hourDataVisibilityChanged({ commit }) {
    commit('setHourDataVisibleFlag');
  },
  submitSchedules({ commit, state }, callback) {
    if (state.multipleSelection.length === 0) {
      callback({ type: 'warning', title: 'No Schedules Seleted' });
      return;
    }

    let sc = null;
    if (state.selectedSchedulingCoordinator) {
      sc = state.scToResources.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;
    }
    let bsc = null;
    if (state.selectedBSC) {
      bsc = state.bscToResources.find((o) => o.id === state.selectedBSC).shortName;
    }

    const schedules = {
      startHour: state.startHour.toString(),
      endHour: state.endHour.toString(),
      bsc,
      sc,
      marketType: state.selectedMarketType,
      schedules: [],
    };

    const currentDate = dateStore.toMoment(state.date, 'America/Los_Angeles');
    const endDate = currentDate.clone().add('1', 'days');

    let startTime = null;
    let endTime = null;

    let twoCount = 0;
    const mod = state.is2D ? '2*' : '25';

    while (currentDate.isBefore(endDate)) {
      if (currentDate.get('hour') + 1 === 2) {
        if ((twoCount === 0 && state.startHour === 2) || (twoCount === 1 && state.startHour === mod)) { startTime = currentDate.clone().utc().format(); }
        if ((twoCount === 0 && state.endHour === 2) || (twoCount === 1 && state.endHour === mod)) { endTime = currentDate.clone().utc().add('1', 'hours').format(); }

        twoCount++;
      } else {
        if (state.startHour === currentDate.get('hour') + 1) { startTime = currentDate.clone().utc().format(); }
        if (state.endHour === currentDate.get('hour') + 1) { endTime = currentDate.clone().utc().add('1', 'hours').format(); }
      }
      currentDate.add('1', 'hours');
    }

    if (state.multipleSelection) {
      for (let i = 0; i < state.multipleSelection.length; i++) {
        const ms = state.multipleSelection[i];
        for (const key in ms) {
          if (key.length <= 5 && key.includes('he')) {
            const hour = parseInt((key.replace('he', '')).replace('*', ''));
            const obj = ms[key];
            if (hour >= state.startHour && hour <= state.endHour) {
              if (obj.status) { obj.status = 'IN_PROGRESS'; }
            }
          }
        }
        const schedule = {
          name: ms.scheduleName,
          startTime,
          endTime,
        };
        schedules.schedules.push(schedule);
      }
      CISO_BID_SCHD_API.post('/baseSchedules/submit', schedules)
        .then((response) => {
          callback({ type: 'success', title: 'Submission Started' });
        })
        .catch((err) => {
          callback({ type: 'error', title: 'Submission Failed' });
          console.log(err);
        });
    }
  },
  loadHours({ commit, state }, item) {
    const momentStartTime = dateStore.toMoment(item, 'America/Los_Angeles');
    const momentEndTime = momentStartTime.clone().add(1, 'days');
    let currentTime = momentStartTime.clone();
    const hours = [];
    let i = 1;
    while (currentTime.isBefore(momentEndTime)) {
      currentTime = currentTime.add(1, 'hours');
      hours.push({ label: i, value: i });
      i++;
    }
    if (hours.length > 24) {
      state.mode = LONG_DAY;
      if (state.is2D) {
        hours.forEach((x) => {
          if (x.value > 2) {
            if (x.value === 3) {
              x.label = '2*';
              x.value = '2*';
            } else {
              x.label -= 1;
              x.value -= 1;
            }
          }
        });
      }
    } else if (hours.length < 24) {
      hours.forEach((x) => {
        if (x.value >= 3) {
          x.label += 1;
          x.value += 1;
        }
      });
      state.mode = SHORT_DAY;
    } else {
      state.mode = DAY;
    }

    commit('setHours', hours);
  },
  resourceTypeChanged({ commit, dispatch }, item) {
    commit('setSelectedResourceType', item);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  loadResourceTypes({ commit }) {
    CISO_MASTERDATA_API.get('resources/types')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          commit('setResourceTypes', response.data);
          commit('setFilteredResources');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  isSearchedChanged({ commit, dispatch }) {
    commit('setIsSearched');
    dispatch('loadSummaryAction');
  },
  loadSummaryAction({ commit, state }) {
    state.lastLoad = dateStore.toMomentFromJSDate(new Date()).format('MM/DD/YY h:mm A');

    let resource = null;
    if (state.selectedResource) {
      for (let i = 0; i < state.scToResources.length; i++) {
        const found = state.scToResources.resources.find((o) => o.id === state.selectedResource);
        if (found) {
          resource = found.label;
          break;
        }
      }
    }

    let sc = null;
    if (state.selectedSchedulingCoordinator) {
      sc = state.scToResources.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;
    }

    let bsc = null;
    if (state.selectedBSC) {
      bsc = state.bscToResources.find((o) => o.id === state.selectedBSC).shortName;
    }

    const params = {
      tradeDate: state.date,
      bsc,
      sc,
      marketType: state.selectedMarketType,
    };

    if (resource) { params.resource = resource; }

    if (state.resourceType) { params.resourceType = state.resourceType; }

    CISO_BID_SCHD_API.get('/BaseSchedules/Summaries', {
      params,
    })
      .then((response) => {
        if (response.data && response.data.summaries) {
          commit('loadSummaryMutation', response.data.summaries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  clear({ commit }) {
    commit('clear');
  },

  changeDateAction({ commit, dispatch }, value) {
    commit('changeDateMutation', value);
    dispatch('loadHours', value);
    dispatch('loadSummaryAction');
  },
  changeMarketTypeAction({ commit, dispatch }, value) {
    commit('changeMarketTypeMutation', value);
    dispatch('loadSummaryAction');
  },
  changeBSCAction({ commit, dispatch }, value) {
    commit('changeBSCMutation', value);
    // dispatch('mapBSCsWithResourcesAction');
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  loadSchedulingCoordinatorsWithResourcesAction({ commit }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true')
      .then((response) => {
        commit('setScToResourceMutation', response.data);
        commit('setFilteredResources');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  loadBaseSchedulingCoordinatorsAction({ commit }) {
    const bsc = findBaseSchedulingCoordinator(state.bscToResources, state.selectedBSC) || '';
    CISO_MASTERDATA_API.get('/baseschedulingcoordinator/resources')
      .then((response) => {
        commit('setBscToResourceMutation', response.data);
        commit('setFilteredResources');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  changeResourceAction({ commit, dispatch }, item) {
    commit('changeResourceMutation', item);
    dispatch('loadSummaryAction');
  },
  changeMultipleSelectionAction({ commit }, val) {
    commit('multipleSelectionMutation', val);
  },
  startHourChanged({ commit }, value) {
    commit('setStartHour', value);
  },
  endHourChanged({ commit }, value) {
    commit('setEndHour', value);
  },
  valueTypeFlagChanged({ commit }) {
    commit('setValueTypeFlag');
  },
  productTypeChanged({ commit }, value) {
    commit('setSelectedProductType', value);
  },
  resourceGroupChanged({ commit, dispatch }, value) {
    commit('setSelectedResourceGroup', value);
    commit('setFilteredResources');
    dispatch('loadSummaryAction');
  },
  searchTypeChanged({ commit, dispatch }, value) {
    commit('setSearchType', value);
    dispatch('loadSummaryAction');
  },
};

const mutations = {
  setStartHour(state, value) {
    state.startHour = value;
  },
  setEndHour(state, value) {
    state.endHour = value;
  },
  setResourceTypes(state, items) {
    const types = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        types.push({ value: items[i], label: items[i] });
      }
    }
    state.resourceTypes = types;
  },
  loadSummaryMutation(state, data) {
    const type = state.selectedProductType.toLowerCase();
    const typeActual = `${type}Actual`;

    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        data[i].tradeDate = data[i].tradeDate.substring(0, 10);
        data[i].highlight = [];
        for (const key in data[i]) {
          if (key.length <= 5 && key.includes('he')) {
            const obj = data[i][key];
            if (obj[type] != obj[typeActual]) { data[i].highlight.push(key); }
          }
        }
      }
    }
    state.statuses = data;
    let resourceGroup = null;
    if (state.selectedResourceGroup) {
      resourceGroup = state.resourceGroups.find((o) => o.id === state.selectedResourceGroup);
    }

    const tableData = joinBaseScheduleStatusesAndResources(state.statuses,
      state.filteredResources,
      state.scToResources,
      state.bscToResources,
      state.selectedSchedulingCoordinator,
      state.selectedResource,
      state.date,
      state.selectedMarketType,
      state.selectedBSC,
      state.selectedResourceType,
      resourceGroup,
      state.selectedProductType);

    state.tableData = tableData.filter((o) => {
      if (state.searchType === 'ALL') return o;
      if (state.searchType === 'SAVED') return o.scheduleStatus != null;
      if (state.searchType === 'NOTSCHEDULED') return o.scheduleStatus === null;
      return o.scheduleStatus === state.searchType;
      return o.scheduleStatus === (state.searchType === 'ALL' ? o.scheduleStatus : state.searchType);
    });
  },
  clear(state) {
    state.tableData = [];
    state.isSearched = false;
  },

  changeDateMutation(state, value) {
    state.date = value;
  },
  changeMarketTypeMutation(state, value) {
    state.selectedMarketType = value;
  },
  changeBSCMutation(state, value) {
    state.selectedBSC = value;
    state.selectedResource = null;
  },
  setScToResourceMutation(state, item) {
    if (item && item.schedulingCoordinators && item.schedulingCoordinators.length > 0) {
      state.selectedSchedulingCoordinator = item.schedulingCoordinators[0].id;
      state.scToResources = item.schedulingCoordinators;
    }
  },
  setBscToResourceMutation(state, item) {
    if (item && item.baseSchedulingCoordinators && item.baseSchedulingCoordinators.length > 0) {
      state.selectedBSC = item.baseSchedulingCoordinators[0].id;
      state.bscToResources = item.baseSchedulingCoordinators;
    }
  },
  changeSchedulingCoordinatorMutation(state, item) {
    state.selectedSchedulingCoordinator = item;
    state.selectedResource = null;
  },
  changeResourceMutation(state, item) {
    state.selectedResource = item;
  },
  setStatusesMutation(state, item) {
    state.statuses = item.summaries;
    state.isSearched = true;
  },
  multipleSelectionMutation(state, val) {
    state.multipleSelection = val;
  },
  setProposedStatusesMutation(state, item) {
    state.proposedStatuses = item.summaries;
  },
  setSelectedResourceType(state, item) {
    if (state.selectedResourceType != item) {
      state.selectedResource = null;
    }
    state.selectedResourceType = item;
  },
  setHours(state, item) {
    state.hours = item;
    state.endHour = item[item.length - 1].value;
  },
  setIsSearched(state) {
    state.isSearched = true;
  },
  setShowOpenHours(state) {
    state.showOpenHours = !state.showOpenHours;
  },
  setHourDataVisibleFlag(state) {
    console.log(state);
    state.hourDataVisibile = !state.hourDataVisibile;
    if (state.hourDataVisibile) { state.tableConfiguration.style.maxHeight = 530; } else { state.tableConfiguration.style.maxHeight = 800; }
  },
  setTableHourData(state, item) {
    const data = [];
    data.push({ row: 'System' });
    data.push({ row: 'Market' });
    data.push({ row: 'Status' });
    data.push({ row: 'UpdatedBy' });
    data.push({ row: 'Updated' });
    data.push({ row: 'Submitted' });

    let type = 'en';
    let typeActual = 'enActual';

    switch (state.selectedProductType) {
      case 'EN':
        type = 'en';
        typeActual = 'enActual';
        break;
      case 'RU':
        type = 'ru';
        typeActual = 'ruActual';
        break;
      case 'RD':
        type = 'rd';
        typeActual = 'rdActual';
        break;
      case 'SR':
        type = 'sr';
        typeActual = 'srActual';
        break;
      case 'NR':
        type = 'nr';
        typeActual = 'nrActual';
        break;
    }
    for (const key in item.row) {
      if (key.length <= 5 && key.includes('he')) {
        const obj = item.row[key];
        data[0][key] = obj[type];
        data[1][key] = obj[typeActual];
        data[2][key] = obj.marketStatus;
        data[3][key] = obj.updatedBy;
        data[4][key] = obj.updatedDate ? dateStore.toMomentFromJSDate(obj.updatedDate).format('MM/DD/YY hh:mm') : null;
        data[5][key] = obj.lastModifiedTime ? dateStore.toMomentFromJSDate(obj.lastModifiedTime).format('MM/DD/YY hh:mm') : null;
      }
    }
    state.tableHourData = data;
  },
  setValueTypeFlag(state) {
    state.valueTypeFlag = !state.valueTypeFlag;
  },
  setSelectedProductType(state, item) {
    state.selectedProductType = item;
    state.tableHourData = [];
    for (let i = 0; i < state.tableData.length; i++) {
      state.tableData[i].productType = item;
    }
  },
  setSearchType(state, item) {
    state.searchType = item;
  },
  setResourceGroups(state, items) {
    state.resourceGroups = items;
  },
  setSelectedResourceGroup(state, item) {
    state.selectedResourceGroup = item;
    const group = state.resourceGroup.find((o) => o.id === item);
    const resource = group.find((o) => o.id === state.selectedResource);
    if (!resource) { state.selectedResource = null; }
  },
  setFilteredResources(state) {
    state.filteredResources = findResources(state.bscToResources, state.scToResources, state.selectedBSC, state.selectedSchedulingCoordinator, state.selectedResourceType, state.resourceGroups, state.selectedResourceGroup);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};