import * as tagUtils from '@/utils/etag/tagUtils';
import { clone, has, cloneDeep } from '@/utils/dataUtil';
import * as CONSTS from './constants';
import { requestData } from '../../API';

function search(query, column, row) {
  let uri = '';
  let params = {};
  let mapper = null;
  let dataProperty = '';

  if (column.prop === 'market_pse') {
    uri = '/registry/pses';
    dataProperty = 'pseList';
    params = { name: query };
    mapper = (m) => ({ value: m.code, label: m.code });
  } else if (column.prop === 'physical_lca') {
    if (row.market_pse_name === null) return new Promise((resolve, reject) => resolve([]));
    uri = '/registry/sinks';
    dataProperty = 'sinkList';
    params = { pse: row.market_pse_name, select_by: 'ba' };
    if (query !== null && query !== '') params = { ...params, name: query };
    mapper = (m) => ({ value: m.ba, label: m.ba });
  } else if (column.prop === 'physical_sink') {
    if (row.market_pse_name === null && row.physical_lca_name === null) return new Promise((resolve, reject) => resolve([]));
    uri = '/registry/sinks';
    dataProperty = 'sinkList';
    params = { pse: row.market_pse_name, ba: row.physical_lca_name, select_by: 'sink' };
    if (query !== null && query !== '') params = { ...params, name: query };
    mapper = (m) => ({ value: m.name, label: m.name });
  }
  return requestData(uri, params, dataProperty, mapper);
}

const marketPathTableConfiguration = {
  columns: [{
    prop: '',
    label: '',
    width: 40,
    editable: false,
  }, {
    prop: 'market_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH,
  }, {
    prop: 'market_pse',
    label: 'pse',
    width: CONSTS.MS_PSE_WIDTH,
    minWidth: CONSTS.MS_PSE_MIN_WIDTH,
    maxWidth: CONSTS.MS_PSE_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'PSE',
      valueProperty: 'market_pse_name',
      labelProperty: 'market_pse_name',
    },
  }, {
    prop: 'market_product_name',
    label: 'product',
    width: CONSTS.USE_PSCS_AG_GRID ? CONSTS.MS_PRODUCT_WIDTH : null,
    editable: false,
  }, {
    prop: 'market_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  }, {
    prop: 'market_contractCount',
    label: 'c',
    width: CONSTS.CONTRACT_WIDTH,
    maxWidth: CONSTS.CONTRACT_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagCreateContractCellLinkRenderer' : 'tag-create-contract-cell-link',
      overrideReadonly: true,
    },
  }],
  style: { dynamicSizing: false },
  columnList: (name) => (name === 'pse') ? this.registry.pseList : undefined,
  registry: { pseList: [] },
};

const physicalPathTableConfiguration = {
  columns: [{
    prop: '',
    label: '',
    width: 55,
    maxWidth: 55,
    editable: false,
  }, {
    prop: 'physical_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
    minWidth: CONSTS.NUM_MIN_WIDTH,
  }, {
    prop: 'physical_mo_name',
    label: 'mo',
    width: CONSTS.PS_MO_WIDTH,
    maxWidth: CONSTS.PS_MO_WIDTH,
    editable: true,
    type:  {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectCellRenderer' : 'pscs-select-cell',
      list: 'mo',
      placeholder: 'MO',
      valueProperty: 'physical_mo_name',
      labelProperty: 'physical_mo_name',
      clearable: true,
    },
  }, {
    prop: 'physical_lca',
    label: 'lca',
    width: CONSTS.PS_GCA_LCA_WIDTH,
    maxWidth: CONSTS.PS_GCA_LCA_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'LCA',
      valueProperty: 'physical_lca_name',
      labelProperty: 'physical_lca_name',
    },
  }, {
    prop: 'physical_sink',
    label: 'sink',
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'Sink',
      valueProperty: 'physical_sink_name',
      labelProperty: 'physical_sink_name',
    },
  }, {
    prop: 'physical_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  }],
  style: { dynamicSizing: false },
  columnList(name) { 
    if (name === 'mo') return this.registry.moList;
  },
  remoteSearch: null,
  registry: { moList: [] },
};

const allocationPathTableConfiguration = {
  columns: [{ prop: 'trans_contract', label: 'contracts', editable: true }],
  style: { dynamicSizing: false },
};

const state = {
  marketPathTable: marketPathTableConfiguration,
  physicalPathTable: physicalPathTableConfiguration,
  allocationTable: allocationPathTableConfiguration,
  nullLoadRow: {
    guid: 'load',
    type: 'LOAD',
    market_id: null,
    market_pse_name: null,
    market_product_name: 'L',
    market_contractCount: 0,
    market_contractList: [],
    market_miscInfoCount: 0,
    market_miscInfoList: [],
    physical_id: null,
    physical_lca_name: null,
    physical_sink: null,
    physical_sink_name: null,
    physical_mo: null,
    physical_mo_name: null,
    trans_contract: null,
    physical_miscInfoCount: 0,
    physical_miscInfoList: [],
  },
  load: [],
  loadErrors: [],
};

const getters = {
  getMarketPathTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = cloneDeep(state.marketPathTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullLoadRow, exclusionList);
      configuration.columns.splice(0, 1);
    }
    configuration.remote = { search, nullColumnSearch: [] };
    return configuration;
  },
  getPhysicalPathTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = cloneDeep(state.physicalPathTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullLoadRow, exclusionList);
      configuration.columns.splice(0, 1);
    }
    configuration.remote = { search, nullColumnSearch: ['physical_lca', 'physical_sink'] };
    return configuration;
  },
  getAllocationPathTableConfiguration(state, readonly = true, exclusionList = null) {
    const configuration = cloneDeep(state.allocationTable);
    if (readonly) {
      tagUtils.setEditableFalse(configuration, state.nullLoadRow, exclusionList);
    }
    return configuration;
  },
  getData: (state) => state.load,
  cloneData: (state) => clone(state.load),
  getLoadMarketSegmentID: (state) => state.load[0].market_id,
};

const actions = {
  changeMarketPath({ commit, dispatch, state }, item) {
    commit('setLoad', item);
    const row = state.load.load[0];
    if (row.physical_lca_name !== '' && row.physical_lca_name !== null) {
      search(row.physical_lca_name, { prop: 'physical_lca' }, row).then((res) => {
        const match = res.find((lca) => (lca.label === row.physical_lca_name));
        if (match !== undefined && match !== null) {
          commit('setLoad', {
            eventData: match, value: match.value, rowIndex: item.rowIndex, prop: 'physical_lca_name',
          });
        } else {
          commit('setLoad', {
            eventData: { value: null, label: null }, value: null, rowIndex: item.rowIndex, prop: 'physical_lca_name',
          });
        }
      });
    }
    if (row.physical_sink !== '' && row.physical_sink !== null) {
      search(row.physical_sink_name, { prop: 'physical_sink' }, row).then((res) => {
        const match = res.find((sink) => (sink.label === row.physical_sink_name));
        if (match !== undefined && match !== null) {
          commit('setLoad', {
            eventData: match, value: match.value, rowIndex: item.rowIndex, prop: 'physical_sink_name',
          });
        } else {
          commit('setLoad', {
            eventData: { value: null, label: null }, value: null, rowIndex: item.rowIndex, prop: 'physical_sink_name',
          });
        }
      });
    }
  },
  changePhysicalPath({ commit, dispatch }, item) {
    commit('setLoad', item);
    if (item.prop === 'physical_sink') {
      dispatch('changeProfileColumns');
      dispatch('changeSink', { value: item.eventData.value, label: item.eventData.label });
    } else if (item.prop === 'physical_lca') {
      dispatch('changeLCA', { value: item.eventData.value, label: item.eventData.label });
    }
  },
  changeAllocationPath({ commit }, item) {
    commit('setLoad', item);
  },
};

const mutations = {
  setRegistry(state, registry) {
    state.marketPathTable.registry = { pseList: registry.pseList };
    state.physicalPathTable.registry = {
      baList: registry.baList,
      sinkList: registry.sinkList,
      moList: registry.moList,
    };
  },
  setRegistryPSEList(state, pseList) {
    state.marketPathTable.registry.pseList = pseList;
  },
  setRegistryBAList(state, baList) {
    state.physicalPathTable.registry.baList = baList;
  },
  setRegistrySinkList(state, sinkList) {
    state.physicalPathTable.registry.sinkList = sinkList;
  },
  setRegistryMOList(state, moList) {
    state.physicalPathTable.registry.moList = moList;
  },
  setFromTagModel(state, tagModel) {
    const marketSegment = tagModel.segmentList[tagModel.segmentList.length - 1];
    const physicalSegment = marketSegment.physicalSegmentList[marketSegment.physicalSegmentList.length - 1];
    const contracts = physicalSegment.contractNumberList.join(',');
    const contractList = [];
    for (let i = 0; i < marketSegment.contractNumberList.length; i++) {
      const contract = marketSegment.contractNumberList[i];
      contractList.push(contract);
    }

    state.load = [{
      guid: 'load',
      type: 'LOAD',
      market_id: marketSegment.marketSegmentID,
      market_pse_name: marketSegment.pse,
      market_product_name: 'L',
      market_contractCount: contractList.length,
      market_contractList: contractList,
      market_miscInfoCount: marketSegment.miscInfoList.length,
      market_miscInfoList: marketSegment.miscInfoList,
      physical_id: physicalSegment.physicalSegmentID,
      physical_lca_name: tagModel.tagID.lca,
      physical_sink: physicalSegment.por,
      physical_sink_name: physicalSegment.por,
      physical_mo: physicalSegment.mo,
      physical_mo_name: physicalSegment.mo,
      physical_miscInfoCount: physicalSegment.miscInfoList.length,
      physical_miscInfoList: physicalSegment.miscInfoList,
      trans_contract: contracts,
    }];
  },
  initialize(state) {
    const row = clone(state.nullLoadRow);
    state.load = [row];
    state.loadErrors = [row];
  },
  setData(state, item) {
    const eventData = item.eventData || null;
    const row = state.load[0];
    row[item.prop] = item.value !== null ? item.value.toString() : null;
    if (has(row, `${item.prop}_name`) && eventData !== null) {
      row[`${item.prop}_name`] = item.eventData.label;
    }
  },
  setMarketSegmentID(state, transMarketSegment) {
    const row = state.load[0];
    if (transMarketSegment.market_id === 1) {
      row.market_id = 2;
    } else if (transMarketSegment.market_pse_name === row.market_pse_name
      && (!transMarketSegment.market_product_name
        || transMarketSegment.market_product_name === row.market_product_name)) {
      row.market_id = transMarketSegment.market_id;
    } else {
      const id = transMarketSegment.market_id + 1;
      row.market_id = id;
    }
  },
  setPhysicalSegmentID(state, transPhysicalSegment) {
    const row = state.load[0];
    row.physical_id = transPhysicalSegment.physical_id;
  },
  setMiscInfoProperty(state, item) {
    const row = state.load[item.rowIndex];
    row[item.countProp] = item.count;
    row[item.dataProp] = item.data;
  },
  setContractProperty(state, item) {
    const row = state.load[item.rowIndex];
    row[item.countProp] = item.count;
    row[item.dataProp] = item.data;
  },
  clearMoData(state, guid) {
    const idx = state.load.findIndex((l) => l.guid === guid);
    if (idx !== -1) {
      state.load[idx].physical_mo = null;
      state.load[idx].physical_mo_name = null;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};