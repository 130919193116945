import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'name', label: 'Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'commodity', label: 'Commodity Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'market', label: 'Market', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'entity', label: 'Entity', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'entityType', label: 'Entity Type', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'type', label: 'Account Type', alignment: 'left', sortable: true, filterable: true,
    }, {
    //   prop: 'secretKeyName', label: 'Secret Key Name', alignment: 'left', sortable: true, filterable: true,
    // }, {
      prop: 'createdBy', label: 'Created By', alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', alignment: 'left', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    name: '',
    commodity: '',
    description: '',
    market: '',
    userNamePassword: {},
    certificateStore: {},
    key: {},
    certificateRaw: {},
    createdBy: '',
    createdDate: null,
    updatedBy: '',
    updatedDate: null,
  },
  childTableData: [],
  childTableConfig: {
    columns: [{
      prop: 'id', label: 'ID', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'marketName', label: 'Market', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'marketSystemName', label: 'Market System', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'marketAccountName', label: 'Market Account', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }],
  },
  marketSystems: [],
  currentChildTableRow: null,
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
  getChildTableConfig: state => state.childTableConfig,
  getChildTableData: state => state.childTableData,
  getCurrentChildRow: state => state.currentChildTableRow,

  getEntityTypeList: (state, localGetters, rootState) => rootState.lookup.entityTypeList,
  getEntityList: (state, localGetters, rootState) => rootState.lookup.entityList,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
  getEnergyCommodityList: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('lookup/fetchEntityTypeList', null, { root: true });
    dispatch('lookup/fetchEntityList', null, { root: true });
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
    commit('resetChildTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/market-accounts?includeMarketSystemAccounts=true').then(({ data }) => {
      commit('loadTableData', data.marketAccounts);
      this.$notify(`${data.marketAccounts.length} Market Account(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Market Accounts', 'error');
    });
  },
  loadMarketSystemsData({ commit }) {
    if (!state.currentTableRow)
      return;
    
    STRUCTURES_API.get(`/market-systems?commodity=${state.currentTableRow.commodity}&market=${state.currentTableRow.market}`).then(({ data }) => {
      commit('loadMarketSystemsData', data.marketSystems);
      this.$notify(`${data.marketSystems.length} Market Systems(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Market Systems', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  createMarketAccount({ commit }, marketAccount) {
    STRUCTURES_API.post(`/market-accounts`, marketAccount).then(({ data }) => {
      commit('addTableRow', data);
      this.$notify('Market Account Created', 'success');
    }).catch((err) => {
      this.$notify('Failed to Create Market Account', 'error');
    });
  },
  updateMarketAccount({ commit }, marketAccount) {
    STRUCTURES_API.put(`/market-accounts/${marketAccount.id}`, marketAccount).then(({ data }) => {
      commit('updateTableRow', data);
      this.$notify('Market Account Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Market Account', 'error');
    });
  },
  deleteMarketAccount({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/market-accounts/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      dispatch('currentChildTableRowChange', null);
      this.$notify('Market Account Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Market Account', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
    dispatch('currentChildTableRowChange', null);
    dispatch('loadMarketSystemsData');
  },
  currentChildTableRowChange({ dispatch, commit }, currentChildTableRow) {
    commit('currentChildTableRowChange', currentChildTableRow);
  },
  addMarketSystemToMarketAccount({ commit, dispatch }, marketSystemId) {
    STRUCTURES_API.post(`/market-accounts/${state.currentTableRow.id}/market-systems/${marketSystemId}`).then((response) => {
      if (response?.status === 200) {
        this.$notify('Market System added to Market Account', 'success');
        STRUCTURES_API.get(`/market-accounts/${state.currentTableRow.id}/market-systems`).then(({ data }) => {
          commit('setMarketSystemAccounts', data.marketSystems);
          // dispatch('loadMarketSystemsData');
        }).catch((err) => {
          this.$notify('Failed to reload Market System Accounts', 'error');
        });
      }
    }).catch((err) => {
      this.$notify('Failed to Market System to Market Account', 'error');
    });
  },
  removeMarketSystemFromMarketAccount({ commit }, marketSystemId) {
    STRUCTURES_API.delete(`/market-accounts/${state.currentTableRow.id}/market-systems/${marketSystemId}`).then((response) => {
      if (response?.status === 200) {
        this.$notify('Market System added to Market Account', 'success');
        STRUCTURES_API.get(`/market-accounts/${state.currentTableRow.id}/market-systems`).then(({ data }) => {
          commit('setMarketSystemAccounts', data.marketSystems);
          // dispatch('loadMarketSystemsData');
        }).catch((err) => {
          this.$notify('Failed to reload Market System Accounts', 'error');
        });
      }
    }).catch((err) => {
      this.$notify('Failed to Market System to Market Account', 'error');
    });
  }
};

const mutations = {
  updateTableRow(state, marketAccount) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === marketAccount.id);
    state.tableData.splice(rowIndex, 1, marketAccount);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
    state.childTableData = currentRow?.marketSystemAccounts;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  resetChildTable(state) {
    state.childTableData = [];
    state.currentChildTableRow = null;
  },
  loadTableData(state, marketAccounts) {
    state.tableData = marketAccounts;
  },
  loadMarketSystemsData(state, marketSystems) {
    state.marketSystems = marketSystems;
  },
  addTableRow(state, marketAccount) {
    state.tableData.push(marketAccount);
  },
  createTableRow(state) {
    const marketAccount = clone(state.nullTableRow);
    marketAccount.id = state.tableKey++;
    marketAccount.versionId = 1;
    marketAccount.createdBy = this.getters['auth/getProfile'].userName;
    marketAccount.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = marketAccount;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((marketParticipant) => marketParticipant.id !== state.currentTableRow.id);
    state.childTableData = [];
  },
  currentChildTableRowChange(state, row) {
    state.currentChildTableRow = row;
  },
  setMarketSystems(state, data) {
    state.marketSystems = data;
  },
  setMarketSystemAccounts(state, data) {
    state.currentTableRow.marketSystemAccounts = data;
    state.childTableData = data;
  },
  addChildTableRow(state, data) {
    state.childTableData.push(data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
