import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_INVOICE_API, PPA_REF_API } from '@/api';
import { ppaGroupTagBox, ppaGroupInvoiceEntities } from '@/components/PPA/BaseStore/ppasBase';
import dateUtil from '@/utils/dateStore';
import { debounce } from '@/utils/dataUtil';
import moment from 'moment';

async function tradeDateRangeHandler() {
  try {
    const { params: parameters } = this;
    const { startDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const startDates = this.params.selectedDateRanges.map((i) => i.startDate);
    startDates.push(startDate);
    const minDate = startDates.reduce((min, c) => c < min ? c : min);
    const params = { startDate: minDate, endDate: minDate }; // param object passed to API needs to be named 'params' or won't work

    const data = await PPA_REF_API.get('/settlement-cycles', { params });
    const cycles = data.data.data.map((item) => (item.name));
    parameters.settlementCyclesUnfiltered = cycles;
    parameters.settlementCycles = cycles;
  } catch (error) {
    vue.$notify('Failed to Load Settlement Cycles', 'error');
    console.log(error);
  }
}

const state = {
  tableData: [],
  dateRanges: [],
  detailsData: [],
  selectedRows: [],
  currentTableRow: null,
  searchFiltersConfig: {
    dateType: {
      label: 'Date Type',
      value: 'TradingDate',
      type: 'radio',
      options: [{ value: 'TradingDate', label: 'Trade Date' }, { value: 'RunDate', label: 'Calc Run Date' }],
    },
    addRange: {
      label: 'Add Additional Trade Date Range',
      value: 1,
      grouped: true,
      type: 'button',
      clickListener: (params) => { params.addRangeSelected++; },
      watchers: [{
        propertyToWatch: 'addRangeSelected',
        handler() {
          const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);

          if (!(this.params.selectedDateRanges)) {
            this.params.selectedDateRanges = [];
          }
          if (this.params.addRangeSelected > 1) {
            this.config.selectedDates.visible = true;
          }
          if (this.params.selectedDateRanges.findIndex((item) => item.startDate === startDate) === -1) {
            this.params.selectedDateRanges.push({ startDate, endDate });
          }
          
          this.params.selectedDatesSelected = this.params.selectedDateRanges.map((d) => `[${d.startDate}]  to  [${d.endDate}]`).join(' \r');

          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }, {
        propertyToWatch: 'selectedDatesSelected',
        handler() {
          console.log(this.$parent.$parent.instance);
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }, {
        propertyToWatch: 'dockTrackerSelected',
        handler() {
          this.params.selectedDatesSelected = '';
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$parent.$parent.instance.repaint();
          }
          this.$nextTick(() => this.params.selectedDatesSelected = this.params.selectedDateRanges.map((d) => `[${d.startDate}]  to  [${d.endDate}]`).join(' \r'));

          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }, {
        propertyToWatch: 'dateTypeSelected',
        handler(newValue) {
          this.params.selectedDateRanges = [];
          if (newValue === 'RunDate') {
            this.params.addRangeSelected = 1;
            this.config.addRange.visible = false;
            this.config.settlementCycles.visible = false;
            this.config.selectedDates.visible = false;
          }
          if (newValue === 'TradingDate') {
            this.config.addRange.visible = true;
            this.config.settlementCycles.visible = true;
          }
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }],
    },
    tradeDateRange: {
      label: 'Trade Dates',
      visible: true,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    selectedDates: {
      label: 'Additional Dates',
      visible: false,
      readOnly: true,
      divider: true,
      height: 'auto',
      maxHeight: 'auto',
      value: '',
      type: 'textarea',
    },
    dockTracker: {
      label: 'DockTracker',
      value: 1,
      visible: false,
      dockListener: (params) => { params.dockTrackerSelected++; },
      type: 'button',
    },
    settlementCycles: {
      label: 'Settlement Cycles',
      value: [],
      type: 'tagbox',
      showDropDownButton: true,
      visible: true,
      searchable: true,
      options: [],
      watchers: [{
        propertyToWatch: 'tradeDateRangeSelected',
        watchOptions: { immediate: true },
        handler: debounce(tradeDateRangeHandler, 100), // Debounce to avoid handler triggering twice
      }],
    },
    ppaGroupTagBox,
    ppaGroupInvoiceEntities,
  },
};

const actions = {
  async fetchCreateInvoiceData({ commit, state }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);

    if (!(parameters.selectedDateRanges)) {
      parameters.selectedDateRanges = [];
    }
    if (parameters.selectedDateRanges.findIndex((item) => item.startDate === startDate) === -1) {
      parameters.selectedDateRanges.push({ startDate, endDate });
    }
    const params = {
      ppaGroups: parameters.ppaGroupTagBoxSelected,
      invoiceEntities: parameters.ppaGroupInvoiceEntitiesSelected,
      settlementCycles: parameters.settlementCyclesSelected,
      startDate,
      endDate,
      dateRanges: parameters.selectedDateRanges,
    };
    let apiRoute = '/create-invoices?';
    if (parameters.dateTypeSelected === 'RunDate') apiRoute = '/create-invoices/run-date?';
    try {
      const { data: { data } } = await PPA_INVOICE_API.put(apiRoute, params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.tradingDate = x.tradingDate === null ? null : getMomentDateString(x.tradingDate);
          x.approvedDate = x.approvedDate === null ? null : getMomentDateString(x.approvedDate);
          x.createdDate = x.createdDate === null ? null : getMomentDateString(x.createdDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.updatedDate = x.updatedDate === null ? null : getMomentDateString(x.updatedDate);
          x.details.forEach((y) => {
            y.tradingDate = getMomentDateString(y.tradingDate);
          });
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async createInvoice({ commit, state }, record) {
    const invoices = { Data: state.selectedRows };
    try {
      await PPA_INVOICE_API.post('/create-invoices', invoices);
      commit('reset');
      this.$notify('Invoices Created', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Create Invoices', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
    state.detailsData = [];
  },
  reset(state) {
    state.tableData = [];
    state.detailsData = [];
    state.selectedRows = [];
    state.currentTableRow = null;
  },
  setCurrentRow(state, currentRow) {
    state.currentTableRow = currentRow;
    state.detailsData = currentRow.details;
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};