import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  attributeMapping: [],
  attributeTableObject: {},
  calcTypeList: [],
  chargeCodeList: [],
  editAction: 'ADD',
  nullTableRow: {
    id: -999,
    billDeterminantName: null,
    description: null,
    chargeCodeId: null,
    chargeCodeName: null,
    calcTypeId: null,
    calcOrder: null,
    sourceType: null,
    additionalFilter: null,
    granularity: null,
    unitOfMeasure: null,
    calcTypeName: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    rounding: null,
    startDate: null,
    endDate: null,
    inputBD1: null,
    inputBD2: null,
    inputBD3: null,
    inputBD4: null,
    fixedVal: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getCalcTypeList: (state) => state.calcTypeList.map(({ id, calcTypeName, calcTypeDescription }) => ({ value: id, label: calcTypeName, description: calcTypeDescription })),
  getChargeCodeList: (state) => state.chargeCodeList.map(({ id, chargeCodeName }) => ({ value: id, label: chargeCodeName })),
  getBDList: (state) => {
    const data = state.tableData.map(({ id, billDeterminantName, chargeCodeName }) => ({ value: id, label: `${billDeterminantName} (${chargeCodeName})` }));
    data.unshift({ value: -1, label: 'FIXED' });
    return data;
  },
  getAttributeList(state) {
    return state.attributeMapping.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('fetchAttributeData');
    dispatch('fetchCalcTypeList');
    dispatch('fetchChargeCodeList');
  },
  async fetchBillDeterminant({ commit }, parameters = {}) {
    const params = {
      chargeCodes: parameters.chargeCodesSelected.join(','),
    };
    try {
      const { data: { data } } = await BILLING_REF_API.get(buildApiQuery('/bill-determinants?', params));
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.endDate = obj.endDate === null ? null : getMomentDateString(obj.endDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Bill Determinant Data', 'error');
    }
  },
  async fetchCalcTypeList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/calc-types');
      commit('loadCalcTypeList', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchChargeCodeList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/charge-codes');
      commit('loadChargeCodeList', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchAttributeData({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/ref-attributes/BillingDeterminant');
      commit('loadAttributeData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  postRecord({ state }, record) {
    record.startDate = getMomentDateString(record.startDate);
    record.endDate = record.endDate === null ? null : getMomentDateString(record.endDate);

    BILLING_REF_API.post('/bill-determinants', record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, record) {
    record.startDate = getMomentDateString(record.startDate);
    record.endDate = record.endDate === null ? null : getMomentDateString(record.endDate);

    BILLING_REF_API.put(`/bill-determinants/${record.id}`, record).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/bill-determinants/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadAttributeData(state, records) {
    state.attributeMapping = records;
    state.attributeTableObject = records.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
  loadCalcTypeList(state, calcTypes) {
    state.calcTypeList = calcTypes;
  },
  loadChargeCodeList(state, chargeCodes) {
    state.chargeCodeList = chargeCodes;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};