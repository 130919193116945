import helpers from '@/utils/helpers';
import { SCRIPTING_API } from '@/api';
import SCRIPT_EDITOR_STORE from './scriptEditorStore';

const state = {
  categories: [],
  selectedCategory: null,
  selectedScript: '',
  scripts: [],
  filteredScripts: [],
  description: '',
  editorCode: '',
  fontSize: null,
  fontSizes: [],
  editor: null,
};
const getters = {
  getSelectedCategory: (state) => state.selectedCategory,
  getCategories: (state) => state.categories.map((s) => ({ value: s.name, label: s.name })),
  getSelectedScript: (state) => state.selectedScript,
  getScripts: (state) => state.filteredScripts,
  getScriptName: (state) => state.selectedScript,
  getScriptDescription: (state) => state.description,
  getFontSize: (state) => state.fontSize,
  getFontSizes: (state) => state.fontSizes,
  getEditorCode: (state) => state.editorCode,
  getEditor: (state) => state.editor,
};

const actions = {
  async loadData({ state, commit, dispatch }) {
    if (state.editor == null) { await dispatch('loadEditorAction'); }

    if (state.fontSize == null) {
      commit('setFontSize');
    }
    await dispatch('loadCategories');
    await dispatch('loadScripts');

    if (state.categories && state.categories.length > 0) {
      dispatch('changeCategoryAction', state.categories[0].name);
    }
  },
  async loadScripts({ commit }) {
    await SCRIPTING_API.get()
      .then((response) => {
        commit('setScriptListMutation', response.data);
      })
      .catch((err) => console.log(err));
  },
  async loadCategories({ commit }) {
    await SCRIPTING_API.get('categories')
      .then((response) => {
        commit('setCategoryListMutation', response.data);
      })
      .catch((err) => console.log(err));
  },
  reset({ state }) {
    state.editor = null;
  },
  loadEditorAction({ commit }) {
    commit('setEditorSession');
  },
  saveScript({ state, commit, dispatch }, item) {
    state.editorCode = state.editor.session.getValue();

    const scriptToSave = state.scripts.find((s) => s.name === state.selectedScript);
    scriptToSave.code = state.editorCode;

    SCRIPTING_API.put(`${scriptToSave.id}`, scriptToSave)
      .then((response) => {
        commit('setScriptName', response.data.name);
        commit('setScriptDescription', response.data.description);

        state.selectedScript = response.data.name;

        if (typeof item !== 'undefined') { item.callback({ type: 'success', title: 'Success Updating Script' }); }
      })
      .catch((err) => {
        console.log(err); item.callback({ type: 'error', title: 'Failure Updating Script' });
      });
  },
  changeCategoryAction({ commit, dispatch }, item) {
    commit('cleanEditor');
    commit('setCategorySelectionMutation', item);
    commit('setFilteredScripts', item);
  },
  loadScriptBySelection({ commit, dispatch }) {
    SCRIPTING_API.get(`/${state.selectedScript}`)
      .then((response) => {
        commit('setEditorCode', response.data.code);
        commit('setScriptName', response.data.name);
        commit('setScriptDescription', response.data.description);
      })
      .catch((err) => console.log(err));
  },
  changeScriptAction({ state, commit, dispatch }, item) {
    commit('setScriptSelectionMutation', item);
    dispatch('loadScriptBySelection');
  },
  changeScriptNameAction({ commit }, newName) {
    commit('setScriptName', newName);
  },
  changeScriptDescriptionAction({ commit }, newDesc) {
    commit('setScriptDescription', newDesc);
  },
  changeFontSizeAction({ commit }, value) {
    commit('setFontSize', value);
  },

  // dialog specific
  // changeDialogVisibility: scriptEditorStore.actions.changeScriptEditorVisibility,
  // changeScriptName: scriptEditorStore.actions.changeScriptName,
  // changeScriptDescription: scriptEditorStore.actions.changeScriptDescription,
  // changeHeaderInformation: ({ dispatch }) => scriptEditorStore.actions.changeHeaderInformation(scriptEditorStore.state, dispatch),
  // addNewScript: ({ dispatch, state }) => scriptEditorStore.actions.addNewScript(dispatch, state),
};

const mutations = {
  setCategorySelectionMutation(state, item) {
    state.selectedCategory = item;
  },
  setFilteredScripts(state, item) {
    if (item) {
      const categoryType = state.categories.find((x) => x.name === item).id;
      const filteredScripts = state.scripts.filter((p) => p.categoryId === categoryType);
      state.filteredScripts = helpers.map(filteredScripts, (s) => ({ value: s.id, label: s.name }));
    }
  },
  setScriptSelectionMutation(state, item) {
    state.selectedScript = item;
  },
  setScriptListMutation(state, items) {
    state.scripts = items.data;
  },
  setCategoryListMutation(state, item) {
    state.categories = helpers.orderBy(item.data, ['name']);
  },
  setScriptName(state, name) {
    state.selectedScript = name;
  },
  setScriptDescription(state, description) {
    state.description = description;
    const scriptToSave = state.scripts.find((s) => s.name === state.selectedScript);
    scriptToSave.description = description;
  },
  setEditorSession(state) {
    const editor = ace.edit('editor');
    editor.getSession().setMode('ace/mode/javascript');
    editor.setTheme('ace/theme/monokai');
    editor.setShowPrintMargin(false);
    editor.session.setValue('');
    editor.$blockScrolling = Infinity;
    editor.setFontSize(14);
    editor.setHighlightActiveLine(true);

    state.editor = editor;
  },
  setEditorCode(state, item) {
    let code = item;
    code = JSON.parse(JSON.stringify(code, null));

    state.editorCode = code;
    state.editor.session.setValue(state.editorCode);
  },
  cleanEditor(state) {
    state.selectedScript = '';
    state.filteredScripts = [];
    state.editorCode = '';
    state.editor.session.setValue(state.editorCode);
  },
  setFontSize(state, value) {
    if (state.fontSizes.length == 0) {
      for (let i = 12; i <= 24; i++) { state.fontSizes.push({ value: i, label: i }); }
      state.fontSize = state.fontSizes.find((size) => size.value === 16).value;
    } else { state.fontSize = value; }

    state.editor.setFontSize(state.fontSize);
  },

  // dialog specific
  // setScriptEditorVisibility: (state, item) => scriptEditorStore.mutations.setScriptEditorVisibility(state.scriptEditor, item),
  // setDialogScriptName: (state, item) => scriptEditorStore.mutations.setDialogScriptName(state.scriptEditor, item),
  // setDialogScriptDescription: (state, item) => scriptEditorStore.mutations.setDialogScriptDescription(state.scriptEditor, item),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { SCRIPT_EDITOR_STORE },
};