export const table_config = {
    columns: [ {
      label: 'ID',
      prop: 'id',
      filterable: true,
      sortable: true,
      dataType: 'number',
      alignment: "left",
    }, {
      label: 'REF NAME',
      prop: 'refName',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'START DATE',
      prop: 'startDate',
      dataType: "date",
      filterable: true,
      sortable: true,
      alignment: "left",
      format: "yyyy-MM-dd",
      width: null,
      visible: true,
    }, {
      label: 'END DATE',
      prop: 'endDate',
      dataType: "date",
      filterable: true,
      sortable: true,
      alignment: "left",
      format: "yyyy-MM-dd",
      width: null,
      visible: true,
    }, {
      label: 'ISO MARKET',
      prop: 'market',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'SCHEDULE TYPE',
      prop: 'selfSchedOrIst',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'PRODUCT TYPE',
      prop: 'product',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'RESOURCE ID',
      prop: 'resource',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'RESOURCE TYPE',
      prop: 'resourceType',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'FORECAST SOURCE',
      prop: 'forecastSource',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'SC',
      prop: 'sc',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'SELF-SCHEDULE TYPE',
      prop: 'selfSchdType',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'CONFIGURATION',
      prop: 'configuration',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'WHEEL REF NAME',
      prop: 'wheelRefName',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'SUPPORTING RESOURCE',
      prop: 'supportingResource',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'CONTRACT RIGHT ID',
      prop: 'contractRightId',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'TRADE TYPE',
      prop: 'tradeType',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'SUBMITTING SC',
      prop: 'submittingSC',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'TRADE PRODUCT',
      prop: 'tradeProduct',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'FROM SC',
      prop: 'fromSC',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'TO SC',
      prop: 'toSC',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'DEPEND ON NAME',
      prop: 'dependOnName',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'TRADE NAME',
      prop: 'tradeName',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'CREATED BY',
      prop: 'createdBy',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'CREATED DATE',
      prop: 'createdDate',
      filterable: true,
      sortable: true,
      alignment: "left",
      dataType: 'datetime'
    }, {
      label: 'UPDATED BY',
      prop: 'updatedBy',
      filterable: true,
      sortable: true,
      alignment: "left",
    }, {
      label: 'UPDATED DATE',
      prop: 'updatedDate',
      filterable: true,
      sortable: true,
      alignment: "left",
      dataType: 'datetime'
    }
  ],
    options: {
      exportExcel: true,
      filterRow: true,
      filterHeader: true,
    },
};

const columns = [
    "id",
    "refName",
    "startDate",
    "endDate",
    "market",
    "selfSchedOrIst",
    "product",
    "resource",
    "resourceType",
    "forecastSource",
    "sc",
    "selfSchdType",
    "configuration",
    "wheelRefName",
    "supportingResource",
    "contractRightId",
    "tradeType",
    "submittingSC",
    "tradeProduct",
    "fromSC",
    "toSC",
    "dependOnName",
    "createdBy",
    "createdDate"
];

export const table_columns = table_config.columns.map(({prop}) => prop);
