import { CISO_MASTERDATA_API, CISO_BASE_SCHD_API } from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import {
  findResource, findResources, mapToOptions,
} from './util';

const state = {
  tableConfiguration: {
    columns: [],
  },
  tableData: [],
  selectedMarketType: caisoStore.getMarketType(),
  marketTypes: caisoStore.getMarketTypeOptions(),
  scToResources: [],
  selectedSchedulingCoordinator: '',
  selectedBSC: null,
  bscToResources: [],
  selectedResource: '',
  selectedTradeDate: dateStore.getDefaultDate().toDate(),
  selectedRule: '',
  selectedLevel: '',
  selecedMessage: '',
  resourceGroups: [],
  selectedResourceGroup: null,
  resourceTypes: [],
  selectedResourceType: null,
};

const getters = {
  getTableConfiguration: (state) => state.tableConfiguration,
  getTableData: (state) => state.tableData,
  getMarketType: (state) => state.selectedMarketType,
  getMarketTypes: (state) => state.marketTypes,
  getSchedulingCoordinators: (state) => mapToOptions(state.scToResources),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
  getBaseSchedulingCoordinator: (state) => state.selectedBSC,
  getBaseSchedulingCoordinators: (state) => mapToOptions(state.bscToResources),
  getResource: (state) => state.selectedResource,
  getResources: (state) => findResources(state.bscToResources, state.selectedBSC, state.scToResources, state.selectedSchedulingCoordinator, state.resourceGroups, state.selectedResourceGroup, state.selectedResourceType),
  getTradeDate: (state) => state.selectedTradeDate,
  getResourceTypes: (state) => state.resourceTypes,
  getResourceGroups: (state) => state.resourceGroups.map((i) => ({ value: i.id, label: i.name })),
  getSelectedResourceType: (state) => state.selectedResourceType,
  getSelectedResourceGroup: (state) => state.selectedResourceGroup,
};

const actions = { // commit, state, dispatch
  loadData({ dispatch }) {
    dispatch('loadSchedulingCoordinatorsWithResources');
    dispatch('loadBaseSchedulingCoordinators');
    dispatch('loadResourceGroups');
    dispatch('loadResourceTypes');
  },
  loadResourceGroups({ commit }) {
    CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE')
      .then((response) => {
        if (response.data && response.data.resourceGroups) {
          commit('setResourceGroups', response.data.resourceGroups);
        } else commit('setResourceGroups', []);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  loadBaseSchedulingCoordinators({ commit }) {
    CISO_MASTERDATA_API.get('/baseschedulingcoordinator/resources')
      .then((response) => {
        commit('setBscToResource', response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  loadBidErrorsAction({ commit, state }) {
    let sc = null;
    if (state.selectedSchedulingCoordinator) {
      sc = state.scToResources.find((o) => o.id == state.selectedSchedulingCoordinator).shortName;
    }

    let resource = null;
    if (state.selectedResource) {
      resource = findResource(state.scToResources, state.selectedSchedulingCoordinator, state.selectedResource);
    }

    let bsc = null;
    if (state.selectedBSC) {
      bsc = state.bscToResources(({ id }) => id === state.selectedBSC).shortName;
    }

    const date = dateStore.toMomentFromJSDate(state.selectedTradeDate).format('MM/DD/YY');
    CISO_BASE_SCHD_API.get('/BidErrors', {
      params: {
        tradeDate: date,
        bsc,
        sc,
        resource,
      },
    })
      .then((response) => {
        commit('setBidErrors', response.data.baseScheduleBidErrors);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  marketTypeChanged({ commit }, item) {
    commit('setMarketType', item);
  },
  loadSchedulingCoordinatorsWithResources({ commit }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true')
      .then((response) => {
        commit('setScToResource', response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  schedulingCoordinatorChanged({ commit }, item) {
    commit('setSchedulingCoordinator', item);
  },
  changedBSC({ commit }, value) {
    commit('setBSC', value);
  },
  resourceChanged({ commit }, value) {
    commit('setResource', value);
  },
  configurationChanged({ commit }, value) {
    commit('setConfiguration', value);
  },
  tradeDateChanged({ commit }, value) {
    commit('setTradeDate', value);
  },
  loadResourceTypes({ commit }) {
    CISO_MASTERDATA_API.get('resources/types')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          commit('setResourceTypes', response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  resourceGroupChanged({ commit, dispatch }, value) {
    commit('setSelectedResourceGroup', value);
  },
  resourceTypeChanged({ commit, dispatch }, item) {
    commit('setSelectedResourceType', item);
  },
};

const mutations = {
  setBidErrors(state, data) {
    // let found = false;
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      item.he = (new Date(item.startTime).getHours() + 1);
    }
    state.tableData = data;
  },

  setMarketType(state, item) {
    state.selectedMarketType = item;
  },
  setScToResource(state, item) {
    if (item && item.schedulingCoordinators && item.schedulingCoordinators.length > 0) {
      state.selectedSchedulingCoordinator = item.schedulingCoordinators[0].id;
      state.scToResources = item.schedulingCoordinators;
    }
  },
  setSchedulingCoordinator(state, item) {
    state.selectedSchedulingCoordinator = item;
    state.selectedResource = null;
  },
  setBSC(state, value) {
    state.selectedBSC = value;
  },
  setResource(state, value) {
    state.selectedResource = value;
  },
  setConfiguration(state, value) {
    state.selectedConfiguration = value;
  },
  setTradeDate(state, value) {
    state.selectedTradeDate = value;
  },
  setResourceGroups(state, items) {
    state.resourceGroups = items;
  },
  setResourceTypes(state, items) {
    const types = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        types.push({ value: items[i], label: items[i] });
      }
    }
    state.resourceTypes = types;
  },
  setSelectedResourceGroup(state, item) {
    state.selectedResourceGroup = item;
    state.selectedResource = null;
  },
  setSelectedResourceType(state, item) {
    state.selectedResourceType = item;
    state.selectedResource = null;
  },
  setBscToResource(state, item) {
    state.bscToResources = item.baseSchedulingCoordinators;
    if (item && item.baseSchedulingCoordinators && item.baseSchedulingCoordinators.length > 0) {
      state.selectedBSC = item.baseSchedulingCoordinators[0].id;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};