import { sortBy } from '@/utils/dataUtil';
import {
  STRUCTURES_API, IDENTITY_API, ETRM_API, ISO_REF_API,
} from '@/api';

const state = {
  hasFetchedRegistry: false,
  brokerList: [],
  buySellList: [{ value: 'BUY', label: 'BUY' }, { value: 'SELL', label: 'SELL' }],
  catalogList: [],
  catalogTypeList: [],
  companyList: [],
  contactList: [],
  contractCoordinatorList: [],
  contractList: [],
  contractStateTypeList: [],
  contractTypeList: [],
  currencyList: [],
  deliverableOccurrenceLogStatusTypeList: [],
  deliverableTypeList: [],
  endpointList: [],
  energyCommodityList: [],
  fuelTypeList: [],
  environmentalGhgAccountHoldingList: [],
  environmentalMarketList: [],
  environmentalSubAccountList: [],
  intervalTypeList: [],
  intervalSubTypeList: [],
  isoReferenceList: [],
  eventTypeList: [],
  tradeTypeList: [],
  tradeTermGroupList: [],
  traderList: [],
  externalIDList: [],
  commonLocationList: [],
  locationGroupList: [],
  locationList: [],
  locationTypeList: [],
  marketContractTypeList: [],
  marketList: [],
  marketParticipantList: [],
  marketProductTypeList: [],
  marketRecordTypeList: [],
  marketSelfScheduleTypeList: [],
  marketTradeProductList: [],
  marketTradeTypeList: [],
  paymentTermList: [],
  priceList: [],
  termIntervalList: [{ value: 60, label: '60' }],
  termTypeList: [],
  periodList: [ // DAILY, WEEKLY, MONTHLY, QUARTERLY, YEARLY
    { value: 'DAILY', label: 'DAILY' },
    { value: 'WEEKLY', label: 'WEEKLY' },
    { value: 'MONTHLY', label: 'MONTHLY' },
    { value: 'QUARTERLY', label: 'QUARTERLY' },
    { value: 'YEARLY', label: 'YEARLY' },
  ],
  timeZone: [
    { value: 'PST', label: 'PST' },
    { value: 'PDT', label: 'PPT' },
    { value: 'MST', label: 'MST' },
    { value: 'MDT', label: 'MPT' },
    { value: 'CST', label: 'CST' },
    { value: 'CDT', label: 'CPT' },
    { value: 'EST', label: 'EST' },
    { value: 'EDT', label: 'EPT' },
  ],
  tradeTemplateList: [],
  entityTypeList: [],
  entityList: [],
  userList: [],
  userListFromEntityGroup: [],
  firmTypeList: [],
  forecastSourceList: [],
  priceIndexList: [],
  userFeatureFlagList: [],
};

const getters = {
  getBrokerList: (state) => state.brokerList,
  getCatalogList: (state) => state.catalogList,
  getCatalogTypeList: (state) => state.catalogTypeList,
  getCompanyList: (state) => state.companyList,
  getContactList: (state) => state.contactList,
  getContractCoordinatorList: (state) => state.contractCoordinatorList,
  getContractList: (state) => state.contractList,
  getContractStateTypeList: (state) => state.contractStateTypeList,
  getContractTypeList: (state) => state.contractTypeList,
  getCurrencyList: (state) => state.currencyList,
  getDeliverableOccurrenceLogStatusTypeList: (state) => state.deliverableOccurrenceLogStatusTypeList,
  getDeliverableTypeList: (state) => state.deliverableTypeList,
  getEndpointList: (state) => state.endpointList,
  getEnergyCommodityList: (state) => state.energyCommodityList,
  getFuelTypeList: (state) => state.fuelTypeList,
  getEnvironmentalSubAccountList: (state) => state.environmentalSubAccountList,
  getIsoReferenceList: (state) => state.isoReferenceList,
  getEventTypeList: (state) => state.eventTypeList,
  getTradeTypeList: (state) => state.tradeTypeList,
  getTradeTermGroupList: (state) => state.tradeTermGroupList,
  getCommonLocationList: (state) => state.commonLocationList,
  getIntervalTypeList: (state) => state.intervalTypeList,
  getIntervalSubTypeList: (state) => state.intervalSubTypeList,
  getLocationGroupList: (state) => state.locationGroupList,
  getLocationList: (state) => state.locationList,
  getLocationTypeList: (state) => state.locationTypeList,
  getMarketContractTypeList: (state) => state.marketContractTypeList,
  getMarketList: (state) => state.marketList,
  getMarketParticipantList: (state) => state.marketParticipantList,
  getMarketProductTypeList: (state) => state.marketProductTypeList,
  getMarketRecordTypeList: (state) => state.marketRecordTypeList,
  getMarketSelfScheduleTypeList: (state) => state.marketSelfScheduleTypeList,
  getMarketTradeProductList: (state) => state.marketTradeProductList,
  getMarketTradeTypeList: (state) => state.marketTradeTypeList,
  getPaymentTermList: (state) => state.paymentTermList,
  getPriceList: (state) => state.priceList,
  getTermIntervalList: (state) => state.termIntervalList,
  getTermTypeList: (state) => state.termTypeList,
  getTimeZone: (state) => state.timeZone,
  getTraderList: (state) => state.traderList,
  getTradeTemplateList: (state) => state.tradeTemplateList,
  getEntityTypeList: (state) => state.entityTypeList,
  getEntityList: (state) => state.entityList,
  getUserList: (state) => state.userList,
  getUserListFromEntityGroup: (state) => state.userListFromEntityGroup,
  getFirmTypeList: (state) => state.firmTypeList,
  getForecastSourceList: (state) => state.forecastSourceList,
  getPriceIndexList: (state) => state.priceIndexList,
  getUserFeatureFlagList: (state) => state.userFeatureFlagList,
};

const actions = {
  async initializeAllLookup({ dispatch, state, commit }, override = false) {
    if (!state.hasFetchedRegistry || override) {
      await Promise.all([
        dispatch('fetchBrokerList'),
        dispatch('fetchCompanyList'),
        dispatch('fetchContactList'),
        dispatch('fetchContractCoordinatorList'),
        dispatch('fetchContractList'),
        dispatch('fetchContractStateTypeList'),
        dispatch('fetchContractTypeList'),
        dispatch('fetchCurrencyList'),
        dispatch('fetchDeliverableOccurrenceLogStatusTypeList'),
        dispatch('fetchDeliverableTypeList'),
        dispatch('fetchEndpointList'),
        dispatch('fetchEnergyCommodityList'),
        dispatch('fetchFuelTypeList'),
        dispatch('fetchEnvironmentalGhgAccountHolding'),
        dispatch('fetchEnvironmentalSubAccountList'),
        dispatch('fetchIntervalTypeList'),
        dispatch('fetchIntervalSubTypeList'),
        dispatch('fetchIsoReferenceList'),
        dispatch('fetchEventTypeList'),
        dispatch('fetchTradeTypeList'),
        dispatch('fetchTradeTermGroupList'),
        dispatch('fetchCommonLocationList'),
        dispatch('fetchLocationList'),
        dispatch('fetchLocationTypeList'),
        dispatch('fetchMarketList'),
        dispatch('fetchMarketProductTypeList'),
        dispatch('fetchMarketRecordTypeList'),
        dispatch('fetchMarketSelfScheduleTypeList'),
        dispatch('fetchMarketTradeProductList'),
        dispatch('fetchMarketTradeTypeList'),
        dispatch('fetchTermTypeList'),
        dispatch('fetchTraderList'),
        // dispatch('fetchTradeTemplateList'),
        dispatch('fetchEntityTypeList'),
        dispatch('fetchEntityList'),
        dispatch('fetchUserList'),
        dispatch('fetchExternalIDs'),
        dispatch('fetchPriceList'),
        dispatch('fetchCatalogTypeList'),
        dispatch('fetchCatalogList'),
        dispatch('fetchFirmTypeList'),
        dispatch('fetchForecastSourceList'),
      ]);
    }

    commit('setHasFetchedRegistry', true);
  },
  async fetchExternalIDs({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('collections/external-data/resources');
      commit('setExternalIDs', data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchBrokerList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/brokers');
      commit('setBrokerList', data);
      return state.brokerList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/markets');
      commit('setMarketList', data.energyMarkets);
      return state.marketList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchCompanyList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/companies');
      // Join commodities to csv string
      if (Array.isArray(data?.companies) && data?.companies.length) {
        data.companies.forEach((company, index) => {
          const { commodities } = company;
          if (Array.isArray(commodities)) {
            data.companies[index].commodities = commodities.join(',');
          }
        });
      }
      commit('setCompanyList', data.companies);
      return state.companyList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchContactList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/company-contacts');
      commit('setContactList', data.companyContacts);
      return state.contactList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchContractList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/contracts');
      commit('setContractList', data.contracts);
      return state.contractList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchCurrencyList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs?types=Currency');

      // for backwards compatibility
      data.data.forEach((c) => {
        c.shortName = c.name;
      });

      commit('setCurrencyList', data.data);
      return state.currencyList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEndpointList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/endpoints');
      commit('setEndpointList', data.data);
      return state.endpointList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEntityTypeList({ commit }) {
    try {
      const { data } = await IDENTITY_API.get('/entityTypes');
      commit('setEntityTypeList', data.entityTypes);
      return state.entityTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEntityList({ commit }) {
    try {
      const { data } = await IDENTITY_API.get('/entities');
      commit('setEntityList', data.entities);
      return state.entityList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchUserList({ commit }) {
    try {
      const { data } = await IDENTITY_API.get('/users');
      commit('setUserList', data.users);
      return state.userList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchUsersFromEntityGroupList({ commit }, userGroup) {
    const { data } = await IDENTITY_API.get(`/entity-groups/${userGroup}/users`);
    commit('setUserListFromEntityGroup', data.users);
    return state.userListFromEntityGroup;
  },
  async fetchEnvironmentalGhgAccountHolding({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/environmental/sub-account-holdings');
      const holdings = data.environmentalGhgAccountHoldings;
      holdings.forEach((holding) => {
        holding.key = JSON.stringify(holding);
      });
      commit('setEnvironmentalGhgAccountHoldingList', holdings);
      return state.environmentalGhgAccountHoldingList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchCommonLocationList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/locations/common');
      commit('setCommonLocationList', data.data.locations);
      return state.commonLocationList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchLocationList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/locations');
      commit('setLocationList', data.data.locations);
      return state.locationList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchLocationGroupList({ commit }) {
    const { data } = await STRUCTURES_API.get('/location-groups');
    commit('setLocationGroupList', data.locationGroups);
    return state.locationGroupList;
  },
  async fetchTermTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/term-types');
      commit('setTermTypeList', data.termTypes);
      return state.termTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketProductTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/market-product-types');
      commit('setMarketProductTypeList', data.marketProductTypes);
      return state.marketProductTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketSelfScheduleTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/market-self-schedule-types');
      commit('setMarketSelfScheduleTypeList', data.marketSelfScheduleTypes);
      return state.marketSelfScheduleTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketTradeProductList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/market-trade-products');
      commit('setMarketTradeProductList', data.marketTradeProducts);
      return state.marketTradeProductList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchLocationTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/location-types');
      commit('setLocationTypeList', data.locationTypes);
      return state.locationTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketTradeTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/market-trade-types');
      commit('setMarketTradeTypeList', data.marketTradeTypes);
      return state.marketTradeTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchPaymentTermList({ commit }) {
    const { data } = await STRUCTURES_API.get('/catalogs?types=PaymentTerm');

    // for backwards compatibility
    data.data.forEach((c) => {
      c.shortName = c.name;
    });

    commit('setPaymentTermList', data.data);
    return state.paymentTermList;
  },
  async fetchMarketRecordTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/market-record-types');
      commit('setMarketRecordTypeList', data.marketRecordTypes);
      return state.marketRecordTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchMarketParticipantList({ commit }) {
    const { data } = await STRUCTURES_API.get('/energy-market-participants');
    commit('setMarketParticipantList', data.marketParticipants);
    return state.marketParticipantList;
  },
  async fetchContractTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/contract-types');
      commit('setContractTypeList', data.contractTypes?.sort(sortBy('shortName')));
      return state.contractTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchContractStateTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/contract-state-types');
      commit('setContractStateTypeList', data.contractStates?.sort(sortBy('name')));
      return state.contractStateTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchDeliverableTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/deliverable-type');
      commit('setDeliverableTypeList', data.deliverableTypes?.sort(sortBy('shortName')));
      return state.deliverableTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchDeliverableOccurrenceLogStatusTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/occurrence-log-status-types');
      commit('setDeliverableOccurrenceLogStatusTypeList', data.occurrenceLogStatusTypes?.sort(sortBy('shortName')));
      return state.deliverableOccurrenceLogStatusTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEventTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/event-types');
      commit('setEventTypeList', data.eventTypes);
      return state.eventTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchTradeTypeList({ commit }) {
    try {
      const { data } = await ETRM_API.get('/trade-types');
      commit('setTradeTypeList', data.data);
      return state.tradeTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchTradeTermGroupList({ commit }) {
    try {
      const { data } = await ETRM_API.get('/trades/groups');
      commit('setTradeTermGroupList', data.data);
      return state.tradeTermGroupList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchTraderList({ commit }) {
    try {
      const { data } = await ETRM_API.get('/traders');
      commit('setTraderList', data.data);
      return state.traderList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchContractCoordinatorList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/contract-coordinators');
      commit('setContractCoordinators', data.contractCoordinators);
      return state.contractCoordinatorList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEnergyCommodityList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/commodities');
      commit('setEnergyCommodityList', data.energyCommodities);
      return state.energyCommodityList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchFuelTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs?types=FuelType');
      commit('setFuelTypeList', data.data);
      return state.fuelTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchEnvironmentalSubAccountList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/environmental/sub-accounts');
      commit('setEnvironmentalSubAccountList', data.environmentalSubAccounts);
      return state.environmentalSubAccountList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchIsoReferenceList({ commit }) {
    try {
      const { data } = await ISO_REF_API.get('/iso-reference');
      commit('setIsoReferenceList', data);
      return state.isoReferenceList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchTradeTemplateList({ commit }) {
    const { data } = await ETRM_API.get('trades/templates');
    commit('setTradeTemplateList', data.tradeTemplates);
    return state.tradeTemplateList;
  },
  async fetchPriceList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/prices');
      commit('setPriceList', data.data);
      return state.priceList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchCatalogTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs/types');
      commit('setCatalogTypeList', data.data);
      return data.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchCatalogList({ commit }, params) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs', { params });

      data.data.forEach((c) => {
        c.shortName = c.name;
      });

      commit('setCatalogList', data.data);
      return data.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchFirmTypeList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs?types=FirmType');
      commit('setFirmTypeList', data.data);
      return data.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchForecastSourceList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/catalogs?types=ForecastSource');
      commit('setForecastSourceList', data.data);
      return data.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchUserFeatureFlagList({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/feature-flags/user');
      commit('setUserFeatureFlagList', data.data);
      return data.data;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  // these two queries are very slow against a database with large volume of data in dbo.Interval table
  // if this data is needed, it should be queried for explicitly, like DM Positions screen does
  // we will update backend to make these faster, then we can enable them here again
  async fetchIntervalTypeList({ commit }) {
    try {
      const data = { types: [] }; // await STRUCTURES_API.get('intervals/types');
      commit('setIntervalTypeList', data.types);
      return state.intervalTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
  async fetchIntervalSubTypeList({ commit }) {
    try {
      const data = { types: [] }; // await STRUCTURES_API.get('intervals/subtypes');
      commit('setIntervalSubTypeList', data.types);
      return state.intervalSubTypeList;
    } catch (err) {
      console.error(err);
    }
    return [];
  },
};
const mutations = {
  setHasFetchedRegistry(state, value) {
    state.hasFetchedRegistry = value;
  },
  setExternalIDs(state, list) {
    state.externalIDList = list || [];
  },
  setBrokerList(state, list) {
    state.brokerList = list || [];
  },
  setEnvironmentalSubAccountList(state, list) {
    state.environmentalSubAccountList = list || [];
  },
  setIsoReferenceList(state, list) {
    state.isoReferenceList = list || [];
  },
  setMarketList(state, list) {
    list = list || [];
    state.marketList = list.map((l) => ({ ...l, value: l.id, label: `${l.shortName}:${l.commodityName}` }));
  },
  setEndpointList(state, list) {
    state.endpointList = list || [];
  },
  setPowerMarketList(state, list) {
    state.powerMarketList = list || [];
  },
  setEnvironmentalMarketList(state, list) {
    state.environmentalMarketList = list || [];
  },
  setEnvironmentalGhgAccountHoldingList(state, list) {
    state.environmentalGhgAccountHoldingList = list || [];
  },
  setCompanyList(state, list) {
    state.companyList = list || [];
  },
  setContactList(state, list) {
    state.contactList = list || [];
  },
  setCommonLocationList(state, list) {
    state.commonLocationList = list || [];
  },
  setLocationList(state, list) {
    state.locationList = list || [];
  },
  setTradeTemplateList(state, list) {
    state.tradeTemplateList = list || [];
  },
  setLocationGroupList(state, list) {
    state.locationGroupList = list || [];
  },
  setTermTypeList(state, list) {
    state.termTypeList = list || [];
  },
  setMarketProductTypeList(state, list) {
    state.marketProductTypeList = list || [];
  },
  setMarketSelfScheduleTypeList(state, list) {
    state.marketSelfScheduleTypeList = list || [];
  },
  setMarketTradeProductList(state, list) {
    state.marketTradeProductList = list || [];
  },
  setLocationTypeList(state, list) {
    state.locationTypeList = list || [];
  },
  setMarketTradeTypeList(state, list) {
    state.marketTradeTypeList = list || [];
  },
  setMarketRecordTypeList(state, list) {
    state.marketRecordTypeList = list || [];
  },
  setPaymentTermList(state, list) {
    state.paymentTermList = list || [];
  },
  setMarketParticipantList(state, list) {
    state.marketParticipantList = list || [];
  },
  setContractList(state, list) {
    state.contractList = list || [];
  },
  setContractTypeList(state, list) {
    state.contractTypeList = list || [];
  },
  setContractStateTypeList(state, list) {
    state.contractStateTypeList = list || [];
  },
  setCurrencyList(state, list) {
    state.currencyList = list || [];
  },
  setDeliverableTypeList(state, list) {
    state.deliverableTypeList = list || [];
  },
  setDeliverableOccurrenceLogStatusTypeList(state, list) {
    state.deliverableOccurrenceLogStatusTypeList = list || [];
  },
  setEventTypeList(state, list) {
    state.eventTypeList = list || [];
  },
  setTradeTypeList(state, list) {
    state.tradeTypeList = list || [];
  },
  setTradeTermGroupList(state, list) {
    state.tradeTermGroupList = list || [];
  },
  setTraderList(state, list) {
    state.traderList = list || [];
  },
  setContractCoordinators(state, list) {
    state.contractCoordinatorList = list || [];
  },
  setEntityTypeList(state, list) {
    state.entityTypeList = list || [];
  },
  setEntityList(state, list) {
    state.entityList = list || [];
  },
  setUserList(state, list) {
    state.userList = list || [];
  },
  setUserListFromEntityGroup(state, list) {
    state.userListFromEntityGroup = list || [];
  },
  setEnergyCommodityList(state, list) {
    state.energyCommodityList = list || [];
  },
  setFuelTypeList(state, list) {
    state.fuelTypeList = list || [];
  },
  setPriceList(state, list) {
    state.priceList = list || [];
  },
  setCatalogTypeList(state, list) {
    state.catalogTypeList = list || [];
  },
  setCatalogList(state, list) {
    state.catalogList = list || [];
  },
  setFirmTypeList(state, list) {
    state.firmTypeList = list || [];
  },
  setForecastSourceList(state, list) {
    state.forecastSourceList = list || [];
  },
  setUserFeatureFlagList(state, list) {
    state.userFeatureFlagList = list || [];
  },
  setIntervalTypeList(state, list) {
    state.intervalTypeList = list || [];
  },
  setIntervalSubTypeList(state, list) {
    state.intervalSubTypeList = list || [];
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
