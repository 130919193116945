import moment from 'moment';
import { PPA_STLMTS_API } from '@/api';
import { has, cloneDeep } from '@/utils/dataUtil';
import config from './determinantDetailsConfig';

const state = {
  tableData: [],
  tableConfig: [],
  selectedCells: [],
  queryParams: [],
  showRowTotals: false,
  compareCycle: false,
};

const actions = {
  async fetchDrmtDetails({ dispatch, commit }, params) {
    try {
      commit('setQueryParams', params);
      const response = await PPA_STLMTS_API.put('/statements/drmt-details', params);
      dispatch('createTableData', response.data);
    } catch (error) {
      this.$notify('Failed to load PPA Drmt Details', 'error');
    }
  },

  async createTableData({ commit }, data) {
    const hasCompareCycle = has(state.queryParams, 'compareCycle');

    if (hasCompareCycle && state.queryParams.compareCycle === null) {
      const defaultColumns = cloneDeep(config.columns);
      for (let x = 0; x < defaultColumns.length; x++) {
        if (defaultColumns[x].caption === 'CompareType') {
          defaultColumns[x].area = 'filter';
          break;
        }
      }
      commit('setTableConfig', defaultColumns);
      commit('setCompareCycle', false);
      commit('setShowRowTotals', false);
    } else {
      commit('setTableConfig', config.columns);
      commit('setCompareCycle', true);
      commit('setShowRowTotals', true);
    }

    if (data && data.data && data.data.length > 0) {
      commit('reset');
      const flatArray = [];
      data.data.forEach((head) => {
        if (head && head.data && head.data.length > 0) {
          head.data.forEach((mid) => {
            mid.dt = moment.utc(mid.dt).format('MM/DD/YYYY');
            const newObj = {};
            Object.keys(mid).forEach((key) => {
              if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
            });
            Object.keys(head).forEach((key) => {
              if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
            });
            flatArray.push(newObj);
          });
        }
      });
      commit('setTableData', flatArray);
    }
  },
};

const mutations = {
  setCompareCycle(state, value) {
    state.compareCycle = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setQueryParams(state, value) {
    state.queryParams = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};