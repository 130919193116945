import moment from 'moment';
import { CISO_TRADE_SCHD_API, IDENTITY_API } from '@/api';
import { hasPermission } from '@/utils/authUtils';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { accessRight, caiso } from '@/auth/permission';
import {
  cloneDeep, has, HEColumns, uniqueArrayByObjKey, setAllUneditable,
} from '@/utils/dataUtil';
import { createMutations } from '@/utils/vuexHelper';
import REFERENCE_DATA_STORE from '../../../referenceDataStore';
import { mergeTradesAndTradeSchedules, tableModelToScheduleModel } from './util';

// For DST
const generateConfig = (date) => ({
  columns: [{
    prop: 'name',
    minWidth: 150,
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'sc',
    label: 'SC',
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'direction',
    label: 'B/S',
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'counterParty',
    label: 'CP',
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'location',
    label: 'Location',
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'tradeType',
    label: 'Type',
    resizable: true,
    sortable: true,
    editable: false,
  }, {
    prop: 'tradeProductType',
    label: 'Product',
    resizable: true,
    sortable: true,
    editable: false,
  },
  ...HEColumns().map((he) => {
    // Workaround for util code to work
    if (he.dstFlag) he.prop = 'he02*_val';
    else he.prop += '_val';
    he.width = 40;
    he.minWidth = 40;
    he.editable = true;
    return he;
  }),
  ],
  buttons: {
    clearFilter: false,
    exportCsv: false,
    exportXlsx: false,
  },
  footer: { count: true },
  options: {
    multipleSelection: true,
    cellSelection: true,
    warningHighlight: true,
    highlightRow: true,
    wrapCellText: true,
  },
  style: { maxHeight: '96%' },
});

const generateTableHourConfig = (date) => ({
  columns: [{
    prop: 'row', label: ' ', width: 70, cssClass: 'header-column',
  },
  ...HEColumns({}, true, date),
  ],
  footer: { count: true },
  style: {
    maxHeight: 230,
    dynamicSizing: false,
  },
  options: { searchFilter: false },
});

const state = {
  date: dateStore.getDefaultDate().toISOString(),
  selectedMarketType: caisoStore.getMarketType(),
  marketTypes: caisoStore.getMarketTypeOptions(),
  tableData: [],
  tableConfig: generateConfig(),
  tableHourConfiguration: generateTableHourConfig(),
  tradeDialogVisible: false,
  schedulingCoordinators: [],
  selectedSchedulingCoordinator: '',
  counterParties: [],
  selectedCounterParty: '',
  selectedLocation: '',
  multipleSelection: [],
  startHour: 1,
  endHour: 24,
  hourDataVisible: true,
  hours: [],
  submitOptions: [
    { id: 0, name: 'Create', icon: 'fa fa-stop-circle-o' },
    { id: 1, name: 'Save', icon: 'fa fa-stop-circle-o' },
    { id: 2, name: 'Retrieve', icon: 'fa fa-stop-circle-o' },
    { id: 3, name: 'Cancel', icon: 'fa fa-stop-circle-o' },
  ],
  searchType: 'ALL',
  tableHourData: [],
  showOpenHours: false,
  storedParams: null,
};

const getters = {
  hasWritePermissionFlag(_state, _getters, _rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], caiso.scheduling.trades, accessRight.write);
  },
  getTableHourConfiguration(_state, _getters, _rootState, rootGetters) {
    const config = cloneDeep(state.tableHourConfiguration);
    const writePermission = hasPermission(
      rootGetters['auth/getPermissions'],
      caiso.scheduling.trades,
      accessRight.write,
    );
    if (writePermission) return config;
    return setAllUneditable(config);
  },
};

const actions = {
  async initialize({ state, dispatch }) {
    const payload = {
      referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'],
      market: 'CAISO',
      commodity: 'POWER',
      date: moment(state.selectedDate).format('L'),
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    await dispatch('loadSchedulingCoordinators');
    dispatch('loadHours');
    dispatch('loadTradeSchedules');
  },
  async loadTradeSchedules({ dispatch, commit, state }) {
    try {
      const { data } = await CISO_TRADE_SCHD_API.get(`/trades/schedules?tradeDate=${state.date}`);
      dispatch('loadTrades', data.tradeSchedules);
      if (state.selectedMarketType === 'DAM') commit('setShowOpenHours', false);
      else commit('setShowOpenHours', state.showOpenHours);
    } catch (error) {
      console.error(error);
    }
  },
  async loadTrades({ commit, state, dispatch }, tradeSchedules) {
    const { date } = state;
    const locationR = state.REFERENCE_DATA_STORE.locationList
      .find(({ shortName }) => shortName === state.selectedLocation);
    const location = locationR ? locationR.label : null;
    const tradeType = state.searchType !== 'ALL' ? state.searchType : null;
    const marketType = state.selectedMarketType;
    const params = {
      date, location, tradeType, marketType,
    };

    try {
      const { data } = await CISO_TRADE_SCHD_API.get('trades', { params });
      const tradeItem = { trades: data.trades, tradeSchedules };
      commit('tradeScheduleMutator', tradeItem);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('loadHours');
    }
  },
  cellClickChanged({ commit }, item) {
    if (item.row != null) {
      commit('setTableHourData', item.row);
    } else {
      commit('setTableHourData', []);
    }
  },
  loadHours({ commit, state }, item) {
    const hours = dateStore.getTimeRange(state.date, 60).map((v) => ({ value: v, label: v.he }));
    commit('setStartHour', hours[0].value);
    commit('setEndHour', hours[hours.length - 1].value);

    const idx = moment().add(135, 'minutes').hours();
    if (state.selectedMarketType === 'RTM' && moment(state.date).isSame(moment(), 'day')) {
      commit('setStartHour', hours[idx].value);
    }
    commit('setHours', hours);
  },
  async loadSchedulingCoordinators({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      if (entities) {
        commit('setSelectedSchedulingCoordinators', entities.filter((x) => x.type === 'SC'));
        commit('setSelectedCounterParties', entities.filter((x) => x.type === 'COUNTER_PARTY'));
      }
    } catch (error) {
      this.$notify('Failed to load Scheduling Coordinators', 'error');
      console.error(error);
    }
  },
  async saveNewTrade({ commit, dispatch }, payload) {
    try {
      await CISO_TRADE_SCHD_API.post('/trades', payload);
      await dispatch('loadTradeSchedules');
      this.$notify({ type: 'success', message: 'Save Completed' });
    } catch (error) {
      this.$notify({ type: 'warning', message: 'Save Error' });
      console.error(error);
    } finally {
      commit('setTradeDialogVisible', false);
    }
  },
  async submitAction({ state }, item) {
    if (state.multipleSelection.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    }

    const columns = HEColumns({}, true, state.date);
    state.multipleSelection.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const names = state.multipleSelection.map((x) => x.name);
    const params = {
      startTime: state.startHour.timeTZ.toISOString(),
      endTime: state.endHour.timeTZ.add(1, 'hour').toISOString(),
      tradeDate: state.date,
      names,
    };

    try {
      await CISO_TRADE_SCHD_API.post(`/trades/schedules/${item.name.toLowerCase()}`, params);
      this.$notify({ type: 'success', message: `${item.name} Started` });
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: `${item.name} Failed` });
    }
  },
  buttonAction({ commit, dispatch }, item) {
    switch (item.id) {
    case 0: // CREATE
      commit('setTradeDialogVisible', true);
      break;
    case 1: // SAVE
      dispatch('saveDirtyTrades', item);
      break;
    case 2: // RETRIEVE
    case 3: // CANCEL
      dispatch('submitAction', item);
      break;
    default:
      break;
    }
  },
  async saveDirtyTrades({ commit, dispatch, state }) {
    const newDirtyTrades = state.tableData.filter((o) => o.isDirty && o.variant === 'CREATED');
    const oldDirtyTrades = state.tableData.filter((o) => o.isDirty && (!o.variant || o.variant !== 'CREATED'));

    if (newDirtyTrades.length === 0 && oldDirtyTrades.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Altered' });
      return;
    }

    const newSchedules = [];
    for (let i = 0; i < newDirtyTrades.length; i++) {
      newSchedules.push(tableModelToScheduleModel(newDirtyTrades[i], state.date));
    }

    const oldSchedules = [];
    for (let i = 0; i < oldDirtyTrades.length; i++) {
      oldSchedules.push(tableModelToScheduleModel(oldDirtyTrades[i], state.date));
    }

    if (newSchedules.length > 0) {
      try {
        await CISO_TRADE_SCHD_API.post('trades/schedules/batch', { tradeSchedules: newSchedules });
        this.$notify({ type: 'success', message: 'Save Completed' });
        dispatch('loadTradeSchedules');
        state.tableHourData = [];
      } catch (error) {
        this.$notify({ type: 'error', message: 'Save Failed' });
        console.error(error);
      }
    }

    if (oldSchedules.length > 0) {
      try {
        await CISO_TRADE_SCHD_API.put('trades/schedules/batch', { tradeSchedules: oldSchedules });
        this.$notify({ type: 'success', message: 'Save Completed' });
        dispatch('loadTradeSchedules');
      } catch (error) {
        console.error(error);
        this.$notify({ type: 'error', message: 'Save Failed' });
      }
    }
  },
};

const mutations = {
  tradeScheduleMutator(state, item) {
    state.tableData = mergeTradesAndTradeSchedules(item.trades, item.tradeSchedules, state.multipleSelection, state.date);
  },
  setTableData(state, item) {
    state.tableData[item.rowIndex][item.prop] = item.value;
    state.tableData[item.rowIndex].isDirty = true;
  },
  setStartHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour > state.endHour) state.startHour = state.endHour;
    else state.startHour = value;
  },
  setEndHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour < state.startHour) state.endHour = state.startHour;
    else state.endHour = value;
  },
  setSelectedSchedulingCoordinators(state, items) {
    state.schedulingCoordinators = uniqueArrayByObjKey(items, 'name').map(({ name }) => ({ value: name, label: name }));
    if (state.schedulingCoordinators?.length > 0) {
      state.selectedSchedulingCoordinator = state.schedulingCoordinators[0].value;
    }
  },
  setSelectedCounterParties(state, items) {
    state.counterParties = uniqueArrayByObjKey(items, 'name').map(({ name }) => ({ value: name, label: name }));
    if (state.counterParties?.length > 0) {
      state.selectedCounterParty = state.counterParties[0].value;
    }
  },
  setTableHourData(state, item) {
    const data = [
      'System',
      'Market',
      'Counter',
      'Adjusted',
      'Status',
      'SystemStatus',
      'UpdatedBy',
      'Updated',
      'Submitted',
    ].map((row) => ({ row }));
    const type = 'quantity';
    const typeActual = 'marketQuantity';

    HEColumns({}, true, state.date).forEach(({ prop }) => {
      const obj = item[prop];
      if (obj) {
        data[0][prop] = obj?.[type];
        data[1][prop] = obj?.[typeActual];
        data[2][prop] = obj.counterQuantity;
        data[3][prop] = obj.adjustedQuantity;
        data[4][prop] = obj.marketStatus;
        data[5][prop] = obj.systemStatus;
        data[6][prop] = obj.updatedBy;
        data[7][prop] = obj.updatedDate;
        data[8][prop] = obj.lastModifiedTime;
      }
    });
    state.tableHourData = data;
  },
  setMultipleSelection(state, val) {
    state.multipleSelection = val;
  },
  reset(state) {
    state.tableData = [];
    state.tableHourData = [];
  },
  setShowOpenHours(state, value) {
    state.showOpenHours = value;
    const config = generateConfig(state.date);
    if (value && state.selectedMarketType === 'RTM') {
      config.columns.forEach((col) => {
        if (
          col.isHourEndingColumn
          && col.timeTZ.isBefore(moment())
        ) {
          col.visible = false;
        }
      });
    }
    state.tableConfig = config;
    state.tableHourConfiguration = generateTableHourConfig(state.date);

    const clonedData = config.columns.map((row) => JSON.stringify(cloneDeep(row)));
    const clonedTableData = state.tableConfig.columns.map((row) => JSON.stringify(cloneDeep(row)));
    const isNotEquals = clonedData.length !== clonedTableData.length
      || clonedData.some((obj, idx) => obj !== clonedTableData[idx]);
    if (isNotEquals) {
      state.tableConfig.columns = config.columns;
      state.tableConfig.isRowSelected = (row) => {
        if (row) return !has(row, 'variant') || has(row, 'rowSelected');
        return false;
      };
    }
  },
  addNewTrade(state, item) {
    state.tableData.push(item);
  },
  ...createMutations(
    'date',
    'hourDataVisible',
    'hours',
    'searchType',
    'selectedLocation',
    'selectedMarketType',
    'selectedSchedulingCoordinator',
    'storedParams',
    'tradeDialogVisible',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules: { REFERENCE_DATA_STORE },
  actions,
};