import Vue from 'vue';
import router from "../../../../router";

Vue.component('bid-strategy-schedule-route-cell', {
    router,
    template:`<router-link :to="{ name: 'BidDetail', params: {scheduleName: name}}">{{value}}</router-link>`,
    computed: {
        name () {      
            let row = this.params.data;

            if (row.configuration){
                return `${row.sc}-${row.marketType}-${row.date}-${row.resourceShortName}-${row.configuration}` ;
            }
            else{
                return `${row.sc}-${row.marketType}-${row.date}-${row.resourceShortName}` ;
            }

        },
        value () {
            return this.params.data.resourceShortName;
        }
    }
});

