import { STRUCTURES_API, ETRM_API } from '@/api';
import { clone, handleError, sortBy } from '@/utils/dataUtil';
import utils from '@/utils';

const state = {
  tableKey: -999,
  tableData: [],
  childTableData: [],
  lockingTableData: [],
  usersTableData: [],
  userList: [],
  variantsList: [],
  currentRow: null,
  currentChildRow: null,
  currentLockingRow: null,
  currentUserRow: null,
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false,
    }, {
      prop: 'name', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  childTableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: false, visible: false,
    }, {
      prop: 'commodity', label: 'Commodity', sortable: false,
    }, {
      prop: 'effectiveStartDate', label: 'Effective Start Date', sortable: false, dataType: 'date',
    }, {
      prop: 'effectiveEndDate', label: 'Effective End Date', sortable: false, dataType: 'date',
    }, {
      prop: 'volumeInterval', label: 'Volume Interval', sortable: false,
    }, {
      prop: 'volumeUnitOfMeasureType', label: 'Volume Unit of Measure Type', sortable: false,
    }, {
      prop: 'volumeBuyLimit', label: 'Volume Buy Limit', sortable: false,
    }, {
      prop: 'volumeSellLimit', label: 'Volume Sell Limit', sortable: false,
    }, {
      prop: 'transactionAmountLimit',
      label: 'Transaction amount Limit',
      sortable: false,
      format(value) {
        if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        return formatter.format(value);
      },
    }, {
      prop: 'transactionVolumeLimit', label: 'Transaction Volume Limit', sortable: false,
    }, {
      prop: 'startTimeHorizonType', label: 'Start Time Horizon Type', sortable: false,
    }, {
      prop: 'startTimeHorizonLength', label: 'Start Time Horizon Limit', sortable: false,
    }, {
      prop: 'endTimeHorizonType', label: 'End Time Horizon Type', sortable: false,
    }, {
      prop: 'endTimeHorizonLength', label: 'End Time Horizon Limit', sortable: false,
    }, {
      prop: 'tradeTimeHorizonType', label: 'Trade Time Horizon Type', sortable: false,
    }, {
      prop: 'tradeTimeHorizonLength', label: 'Trade Time Horizon Limit', sortable: false,
    }],
  },
  lockingTableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: false, visible: false,
    }, {
      prop: 'tradeTermGroup', label: 'Trade Term Group', sortable: false,
    }, {
      prop: 'effectiveStartDate', label: 'Effective Start Date', sortable: false, dataType: 'date',
    }, {
      prop: 'effectiveEndDate', label: 'Effective End Date', sortable: false, dataType: 'date',
    }, {
      prop: 'startTimeHorizonType', label: 'Start Time Horizon Type', sortable: false,
    }, {
      prop: 'startTimeHorizonLimit', label: 'Start Time Horizon Limit', sortable: false,
    }, {
      prop: 'startTimeHour', label: 'Start Time Hour', sortable: false,
    }, {
      prop: 'endTimeHorizonType', label: 'End Time Horizon Type', sortable: false,
    }, {
      prop: 'endTimeHorizonLimit', label: 'End Time Horizon Limit', sortable: false,
    }, {
      prop: 'endTimeHour', label: 'End Time Hour', sortable: false,
    }, {
      prop: 'hourOffest', label: 'Hour Offest', sortable: false,
    }, {
      prop: 'variants', label: 'Variants', sortable: false, width: 300
    }],
  },
  userTableConfig: {
    columns: [{
      prop: 'userName', label: 'User Name',
    }, {
      prop: 'firstName', label: 'First Name',
    }, {
      prop: 'lastName', label: 'Last Name',
    }],
  },
  nullTradingGroupRow: {
    id: -999,
    name: null,
    description: null,
  },
  nullTradingGroupSettingRow: {
    id: -999,
    commodity: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    volumeInterval: null,
    volumeUnitOfMeasureType: null,
    volumeBuyLimit: null,
    volumeSellLimit: null,
    transactionAmountLimit: null,
    transactionVolumeLimit: null,
    
    startTimeHorizonLength: null,
    startTimeHorizonType: null,
    endTimeHorizonLength: null,
    endTimeHorizonType: null,
    tradeTimeHorizonLength: null,
    tradeTimeHorizonType: null,
  },
  nullTradingGroupLockingSettingRow: {
    id: -999,
    tradeTermGroup: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
    
    startTimeHorizonLength: null,
    startTimeHorizonLimit: null,
    startTimeHour: null,
    endTimeHorizonLength: null,
    endTimeHorizonLimit: null,
    endTimeHour: null,
    hourOffest: null,
    variants: null,
  },
};

const getters = {
  isSelectingRow() {
    if (!state.currentRow) return false;

    if (state.currentRow.description !== state.nullTradingGroupRow.description) return true;
    if (state.currentRow.id !== state.nullTradingGroupRow.id) return true;
    if (state.currentRow.name !== state.nullTradingGroupRow.name) return true;

    return false;
  },
  getCommodities: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
  getTradeTermGroups: (state, localGetters, rootState) => rootState.lookup.tradeTermGroupList,
  getUserList: (state) => state.userList,
  getVariantsList: (state) => state.variantsList,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('resetTable');
    dispatch('loadTableData');
  },
  initializeAddAndEditTradingGroupSettingForm({ dispatch }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
  },
  initializeAddAndEditTradingGroupLockingSettingForm({ dispatch }) {
    dispatch('lookup/fetchTradeTermGroupList', null, { root: true });
    dispatch('initializeVariantsList', null);
  },
  async initializeUsersList({ commit }) {
    try {
      const { data } = await ETRM_API.get('traders');
      commit('loadUsersList', data.data);
    } catch (error) {
      handleError(error, 'Failed to load traders');
    }
  },
  async initializeVariantsList({ commit, state }) {
    await STRUCTURES_API.get('/variant').then((response) => {
      if (response.data.data) {
        const distinct = (value, index, self) => self.indexOf(value) === index;
        const distinctList = response.data.data.sort(sortBy('name')).map(({ name }) => name).filter(distinct);
        const variantsList = distinctList.map((v) => ({ value: v, label: v }));
        commit('loadVariantsList', variantsList);
      }
    }).catch((error) => {
      console.error(error);
    });
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  createTradingGroupRow({ commit }) {
    commit('createTradingGroupRow');
  },
  createTradingGroupSettingRow({ commit }) {
    commit('createTradingGroupSettingRow');
  },
  createTradingGroupLockingSettingRow({ commit }) {
    commit('createTradingGroupLockingSettingRow');
  },
  async loadTableData({ commit }) {
    try {
      const { data } = await ETRM_API.get('trade-limit-groups');
      commit('loadTableData', data.data);
    } catch (error) {
      handleError(error, 'Failed to load trade limit groups.');
    }
  },
  async postTradingGroupSetting({ state }, tradingGroupSetting) {
    try {
      const { data } = await ETRM_API.post(`trade-limit-groups/${state.currentRow.name}/limits`, tradingGroupSetting);
      state.childTableData.push(data);
      this.$notify('Trade limit added', 'success');
    } catch (error) {
      handleError(error, 'Failed to add trade limit');
    }
  },
  async postTradingGroupLockingSetting({ state }, tradingGroupLockingSetting) {
    try {
      const { data } = await ETRM_API.post(`trade-limit-groups/${state.currentRow.name}/locking`, tradingGroupLockingSetting);
      state.lockingTableData.push(data);
      this.$notify('Trade locking added', 'success');
    } catch (error) {
      handleError(error, 'Failed to add trade locking');
    }
  },
  async postTradingGroup({ state }, tradingGroup) {
    try {
      const { data } = await ETRM_API.post('trade-limit-groups', tradingGroup);
      state.tableData.push(data);
      this.$notify('Trade limit group added', 'success');
    } catch (error) {
      handleError(error, 'Failed to add trade limit group.');
    }
  },
  async postTrader({ state, dispatch }, userName) {
    try {
      const { data } = await ETRM_API.post(`trade-limit-groups/${state.currentRow.name}/traders`, {
        userName,
      });
      dispatch('currentRowChange', state.currentRow);
      return { state: 'success' };
    } catch (error) {
      if (error.response && error.response.data) {
        return { state: 'error', messages: error.response.data.messages };
      }
      console.error(error.response);
      return { state: 'error', messages: ['Error occurred when attempting to add Trader'] };
    }
  },
  async updateTradingGroup({ commit }, tradingGroup) {
    try {
      const { data } = await ETRM_API.patch(`trade-limit-groups/${tradingGroup.id}`, tradingGroup);
      commit('updateTradingGroup', data);
      this.$notify('Trade limit group updated', 'success');
    } catch (error) {
      handleError(error, 'Failed to update trade limit group.');
    }
  },
  async updateTradingGroupSetting({ commit }, tradingGroupSetting) {
    try {
      const path = `trade-limit-groups/${state.currentRow.name}/limits/${state.currentChildRow.id}`;
      const { data } = await ETRM_API.put(path, tradingGroupSetting);
      commit('updateTradingGroupSetting', data);
      this.$notify('Trade limit updated', 'success');
    } catch (error) {
      handleError(error, 'Failed to update trade limit.');
    }
  },
  async updateTradingGroupLockingSetting({ commit }, tradingGroupLockingSetting) {
    try {
      const path = `trade-limit-groups/${state.currentRow.name}/locking/${state.currentLockingRow.id}`;
      const { data } = await ETRM_API.put(path, tradingGroupLockingSetting);
      commit('updateTradingGroupLockingSetting', data);
      this.$notify('Trade locking updated', 'success');
    } catch (error) {
      handleError(error, 'Failed to update trade locking.');
    }
  },
  async currentRowChange({ commit }, currentRow) {
    commit('currentRowChange', currentRow);

    // fetch children
    try {
      const { data } = await ETRM_API.get(`trade-limit-groups/${currentRow.name}`);
      commit('loadLimitsTableData', data.limits);
      commit('loadLockingTableData', data.locking);
      commit('loadUsersTableData', data.traders);

    } catch (error) {
      handleError(error, 'Failed to fetch trade limit group.');
    }
  },
  currentChildRowChange({ commit }, currentChildRow) {
    commit('currentChildRowChange', currentChildRow);
  },
  currentLockingRowChange({ commit }, currentLockingRow) {
    commit('currentLockingRowChange', currentLockingRow);
  },
  currentUserRowChange({ commit }, row) {
    commit('currentUserRowChange', row);
  },
  async deleteTableRow({ dispatch, commit, state }) {
    try {
      await ETRM_API.delete(`trade-limit-groups/${state.currentRow.name}`);
      this.$notify('Trade limit group inactivated', 'success');
      commit('resetTable');
      dispatch('loadTableData');
    } catch (error) {
      handleError(error, 'Failed to inactivate trade limit group.');
    }
  },
  async deleteChildTableRow({ dispatch, commit, state }) {
    try {
      await ETRM_API.delete(`trade-limit-groups/${state.currentRow.name}/limits/${state.currentChildRow.id}`);
      commit('deleteChildTableRow');
      dispatch('currentChildRowChange', clone(state.nullTradingGroupSettingRow));
      this.$notify('Trade limit inactivated', 'success');
    } catch (error) {
      handleError(error, 'Failed to inactivate trade limit.');
    }
  },
  async deleteLockingTableRow({ dispatch, commit, state }) {
    try {
      await ETRM_API.delete(`trade-limit-groups/${state.currentRow.name}/locking/${state.currentLockingRow.id}`);
      commit('deleteLockingTableRow');
      dispatch('currentLockingRowChange', clone(state.nullTradingGroupLockingSettingRow));
      this.$notify('Trade Locking inactivated', 'success');
    } catch (error) {
      handleError(error, 'Failed to inactivate Trade Locking.');
    }
  },
  async deleteUserTableRow({ dispatch, commit, state }) {
    try {
      await ETRM_API.delete(`trade-limit-groups/${state.currentRow.name}/traders/${state.currentUserRow.userName}`);
      dispatch('currentRowChange', state.currentRow);
      return { state: 'success' };
    } catch (error) {
      return { state: 'error' };
    }
  },
};

const mutations = {
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((tradingGroup) => tradingGroup.id !== state.currentRow.id);
  },
  deleteChildTableRow(state) {
    state.childTableData = state.childTableData.filter((x) => x.id !== state.currentChildRow.id);
  },
  deleteLockingTableRow(state) {
    state.lockingTableData = state.lockingTableData.filter((x) => x.id !== state.currentLockingRow.id);
  },
  updateTradingGroup(state, tradingGroup) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === tradingGroup.id);
    state.tableData.splice(rowIndex, 1, tradingGroup);
  },
  updateTradingGroupSetting(state, tradingGroupSetting) {
    const rowIndex = state.childTableData.findIndex(({ id }) => id === tradingGroupSetting.id);
    state.childTableData.splice(rowIndex, 1, tradingGroupSetting);
  },
  updateTradingGroupLockingSetting(state, tradingGroupLockingSetting) {
    const rowIndex = state.lockingTableData.findIndex(({ id }) => id === tradingGroupLockingSetting.id);
    state.lockingTableData.splice(rowIndex, 1, tradingGroupLockingSetting);
  },
  currentRowChange(state, currentRow) {
    state.currentRow = currentRow;
  },
  currentChildRowChange(state, currentChildRow) {
    state.currentChildRow = currentChildRow;
  },
  currentLockingRowChange(state, currentLockingRow) {
    state.currentLockingRow = currentLockingRow;
  },
  currentUserRowChange(state, row) {
    state.currentUserRow = row;
  },
  loadTableData(state, tradingGroups) {
    state.tableData = tradingGroups;
  },
  loadLimitsTableData(state, data) {
    state.childTableData = data;
  },
  loadLockingTableData(state, data) {
    state.lockingTableData = data;
  },
  loadUsersTableData(state, data) {
    state.usersTableData = data;
  },
  loadUsersList(state, data) {
    state.userList = data
      .sort(sortBy('firstName'))
      .map((m) => ({ value: m.userName, label: `${m.firstName} ${m.lastName} (${m.userName})`, ...m }));
  },
  loadVariantsList(state, data) {
    state.variantsList = data;
  },
  resetTable(state) {
    state.tableData = [];
    state.childTableData = [];
    state.lockingTableData = [];
    state.usersTableData = [];
    state.currentRow = clone(state.nullTradingGroupRow);
    state.currentChildRow = null;
    state.currentLockingRow = null;
    state.currentUserRow = null;
  },
  createTradingGroupRow(state) {
    const group = clone(state.nullTradingGroupRow);
    group.id = state.tableKey++;
    group.createdBy = this.getters['auth/getProfile'].userName;
    group.createdDate = utils.date.getNow().utc().format();
    state.currentRow = group;
  },
  createTradingGroupSettingRow(state) {
    const setting = clone(state.nullTradingGroupSettingRow);
    setting.createdBy = this.getters['auth/getProfile'].userName;
    setting.createdDate = utils.date.getNow().utc().format();
    state.currentChildRow = setting;
  },
  createTradingGroupLockingSettingRow(state) {
    const setting = clone(state.nullTradingGroupLockingSettingRow);
    setting.createdBy = this.getters['auth/getProfile'].userName;
    setting.createdDate = utils.date.getNow().utc().format();
    state.currentLockingRow = setting;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};