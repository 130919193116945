import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  bdData: [],
  contractList: [],
  editAction: 'ADD',
  nullTableRow: {
    id: -999,
    bdid: null,
    contract_ID: null,
    billDeterminantName: null,
    chargeCodeName: null,
    counterpartyBDName: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getBDList: (state) => {
    const data = state.bdData.map(({
      bdid, billDeterminantName, chargeCodeName,
    }) => ({ value: bdid, label: `${billDeterminantName} - ${chargeCodeName}` }));
    data.unshift({ value: -1, label: 'FIXED' });
    return data;
  },
  getContractList: (state) => state.contractList.map(({ contract_ID, name }) => ({ value: contract_ID, label: name })),
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchCounterpartyMapping');
    dispatch('fetchContractList');
  },
  async fetchBillDeterminant({ commit }, parameters = {}) {
    try {
      const { data: { data } } = await BILLING_REF_API.get(buildApiQuery(`/counterparty-mapping/options/${parameters}`, ''));
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.endDate = obj.endDate === null ? null : getMomentDateString(obj.endDate);
        });
        commit('setBdData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Bill Determinant Data', 'error');
    }
  },
  async fetchCounterpartyMapping({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/counterparty-mapping');
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
    }
  },
  fetchContractList({ commit }) {
    BILLING_REF_API.get('/contracts').then(({ data }) => {
      commit('loadContractList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  postRecord({ state }, record) {
    BILLING_REF_API.post('/counterparty-mapping', record).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, record) {
    BILLING_REF_API.put(`/counterparty-mapping/${record.id}`, record).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/counterparty-mapping/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    value.sort((a) => a.id);
    state.tableData = value;
  },
  setBdData(state, value) {
    state.bdData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  loadContractList(state, contracts) {
    contracts.forEach((contract) => { contract.contract_ID = contract.id; });
    state.contractList = contracts;
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};