import {
  getObjectDefinition, copyObject, findColumnIndex, cloneDeep,
} from '../dataUtil';
import dateStore from '../dateStore';
import * as tagUtils from './tagUtils';
import helpers from '../helpers';
import { toMomentFromDate } from '../dateUtil';

export function toProfileGranularity(columns, profiles, date, minutes, format, includeHourEnding = false) {
  const data = [];
  const obj = getObjectDefinition(columns);

  const times = dateStore.getTimeRangeByMinutes(date, minutes);

  const timesLength = times.length;

  for (let x = 0; x < timesLength; x++) {
    const t = times[x];

    const start = t.time.clone();
    const end = t.time.clone().add(minutes, 'minutes');

    const item = copyObject(obj, end, start, format, t.dstFlag, includeHourEnding);

    const prof = helpers.filter(profiles, (p) => p.momentEndTime.isAfter(start) && p.momentStartTime.isBefore(end));
    const profLength = prof.length;

    if (profLength === 1) {
      tagUtils.copyPropertyValues(columns, prof[0], item);
      if (prof[0].momentEndTime.isBefore(item.momentEndTime)) {
        item.momentEndTime = prof[0].momentEndTime.clone();
        item.endTime = prof[0].momentEndTime.clone().format(format);
        data.push(item);

        const l = copyObject(obj, end, prof[0].momentEndTime, format, t.dstFlag, includeHourEnding);

        data.push(l);
      } else {
        data.push(item);
      }
    } else if (profLength > 1) {
      for (let px = 0; px < profLength; px++) {
        const p = prof[px];
        let l = null;

        if (p.momentEndTime.isBefore(item.momentEndTime)) {
          if (p.momentStartTime.isAfter(item.momentStartTime)) {
            l = copyObject(obj, p.momentEndTime, p.momentStartTime, format, t.dstFlag, includeHourEnding);
          } else {
            l = copyObject(obj, p.momentEndTime, item.momentStartTime, format, t.dstFlag, includeHourEnding);
          }
        } else if (p.momentStartTime.isBefore(item.momentEndTime)) {
          l = copyObject(obj, item.momentEndTime, p.momentStartTime, format, t.dstFlag, includeHourEnding);
        }

        tagUtils.copyPropertyValues(columns, p, l);
        data.push(l);
      }
    } else {
      data.push(item);
    }
  }

  return data;
}

export function toProfileRangesFromTagModel(tagModel, columns, findTransmission) {
  const { profileSetList } = tagModel;

  if (profileSetList && profileSetList.length > 0) {
    // add start/stop properties of type moment
    tagUtils.formatProfileSetList(profileSetList);
    const { segmentList } = tagModel;
    const segmentListCount = segmentList.length;

    const profileTimeRanges = tagUtils.getProfileTimeRangesByProduct(profileSetList);

    const profiles = tagUtils.getProfilesFromTimeRanges(profileTimeRanges, columns);

    // Gen
    const genPS = segmentList[0].physicalSegmentList[0];
    const genSegment = genPS.podProfileRef.toString();
    // Load
    const loadPs = segmentList[segmentListCount - 1].physicalSegmentList[segmentList[segmentListCount - 1].physicalSegmentList.length - 1];
    const loadSegment = loadPs.porProfileRef.toString();

    const energyProfiles = profileSetList.filter((x) => x.profileProductType === 'ENERGY' && x.profileType === 'CURRENT');
    const capacityProfiles = profileSetList.filter((x) => x.profileProductType === 'CAPACITY' && x.profileType === 'RESERVATION');

    const transmissionProfiles = tagUtils.findTransmissionProfiles(segmentList, segmentListCount, columns, findTransmission);

    for (let x = 0; x < profiles.length; x++) {
      const obj = profiles[x];

      const genSet = tagUtils.findProfileWithinRespectiveProfileSetList(obj.momentStartTime, obj.momentEndTime, genSegment, energyProfiles);
      if (genSet) {
        obj.source = genSet.mw;
        obj.rampStart = genSet.rampStart;
      }

      const loadSet = tagUtils.findProfileWithinRespectiveProfileSetList(obj.momentStartTime, obj.momentEndTime, loadSegment, energyProfiles);
      if (loadSet) {
        obj.sink = loadSet.mw;
        obj.rampStop = loadSet.rampStop;
      }

      const filteredCapacityProfiles = capacityProfiles.filter((cp) => obj.momentEndTime.isAfter(cp.momentStartTime) && obj.momentStartTime.isBefore(cp.momentEndTime));

      if (filteredCapacityProfiles && filteredCapacityProfiles.length > 0) {
        transmissionProfiles.forEach((ta) => {
          const capSet = filteredCapacityProfiles.find((cp) => cp.id === ta.profileRef.toString());
          if (capSet) obj[ta.column] = capSet.mw;
        });
      }
    }

    const objectDefinition = getObjectDefinition(columns);

    return tagUtils.reduceProfiles(profiles, columns, objectDefinition);
  }
  return [];
}

function findTransmissionAllocations(transmissions, transmission) {
  const tran_market_pse = transmission.market_pse_name;
  const tran_physical_pod = transmission.physical_pod_name;
  const tran_physical_por = transmission.physical_por_name;
  const tran_physical_se = transmission.physical_se_name;

  return helpers.filter(transmissions, (o) => o.market_pse_name === tran_market_pse
      && o.physical_pod_name === tran_physical_pod
      && o.physical_por_name === tran_physical_por
      && o.physical_se_name === tran_physical_se,
  );
}

export function highlightEnergyGreaterThanCapacity(transmissions, displayArray) {
  const displayArrayLength = displayArray.length;

  for (let x = 0; x < displayArrayLength; x++) {
    const row = displayArray[x];

    if (!row.hasOwnProperty('highlight')) row.highlight = [];

    let energySum = 0;
    const sink = Number.isNaN(parseFloat(row.sink)) ? 0 : row.sink;
    const source = Number.isNaN(parseFloat(row.source)) ? 0 : row.source;

    if (sink >= source) {
      energySum = parseFloat(energySum) + parseFloat(sink);
    } else {
      energySum = parseFloat(energySum) + parseFloat(source);
    }

    const transmissionLength = transmissions.length;

    for (let i = 0; i < transmissionLength; i++) {
      let capacitySum = 0;
      const transmission = transmissions[i];

      const transAllocs = findTransmissionAllocations(transmissions, transmission);

      const allocationsLength = transAllocs.length;

      for (let tx = 0; tx < allocationsLength; tx++) {
        const alloc = transAllocs[tx];

        if (alloc.trans_id !== null) {
          if (row[alloc.guid]) {
            const add = Number.isNaN(row[alloc.guid]) ? 0 : row[alloc.guid];
            capacitySum = parseFloat(capacitySum) + parseFloat(add);
          }
        }
      }

      if (energySum > capacitySum) {
        for (let tx = 0; tx < allocationsLength; tx++) {
          const alloc = transAllocs[tx];
          if (alloc.trans_id !== null) {
            row.highlight.push(alloc.guid);
          }
        }
      }

      i += (allocationsLength - 1);
    }
  }

  return displayArray;
}

export function createDefaultProfiles(date, columns, zone) {
  const momentDate = dateStore.isMoment(date) ? date : dateStore.toMoment(date, zone);
  const startTime = momentDate.clone();
  const endTime = momentDate.clone().add(1, 'days');

  const np = getObjectDefinition(columns);
  tagUtils.setDefaultPropertyValues(np, columns);
  np.momentStartTime = startTime;
  np.momentEndTime = endTime;
  np.startTime = startTime.clone().utc().format();
  np.endTime = endTime.clone().utc().format();
  np.highlight = [];
  np.displayIcon = [];
  np.cellStyle = [];
  np.isDefaultProfile = true;

  return [np];
}

export function createProfileObjects(columns, item) {
  const profiles = [];

  const keys = [
    { value: dateStore.toDateFromLocal(item.startDateTime) },
    { value: dateStore.toDateFromLocal(item.endDateTime) },
  ];

  columns.forEach((c) => {
    if (!c.isParent || c.isParent !== true) {
      if (c.prop !== 'startTime' && c.prop !== 'endTime' && c.prop !== 'he') {
        if (!item.fillCapacity) {
          if (c.prop === 'source' || c.prop === 'sink') {
            profiles.push({
              prop: c.prop, value: item.value, keys: cloneDeep(keys), rowIndex: -1,
            });
          } else {
            profiles.push({
              prop: c.prop, value: null, keys: cloneDeep(keys), rowIndex: -1,
            });
          }
        } else {
          profiles.push({
            prop: c.prop, value: item.value, keys: cloneDeep(keys), rowIndex: -1,
          });
        }
      }
    }
  });

  return profiles;
}

export function setProfile(profiles, columns, item, startTime, endTime) {
  const objectDefinition = getObjectDefinition(columns);
  const copy = tagUtils.copyProfiles(columns, profiles, objectDefinition);

  const overlaps = helpers.filter(copy, (p) => p.momentEndTime.isAfter(startTime) && p.momentStartTime.isBefore(endTime));
  let lastOverlap = null;

  let x = 0;
  while (true) {
    if (x === overlaps.length) break;

    const overlap = overlaps[x];

    lastOverlap = helpers.clone(objectDefinition);
    tagUtils.copyPropertyValues(columns, overlap, lastOverlap);

    const originalStart = overlap.momentStartTime.clone();
    const originalEnd = overlap.momentEndTime.clone();

    if (originalStart.isSame(startTime) && originalEnd.isSame(endTime)) {
      const idx = helpers.findIndex(copy, (c) => c.momentStartTime.isSame(startTime) && c.momentEndTime.isSame(endTime));
      copy.splice(idx, 1);
      break;
    }

    if (originalStart.isBefore(startTime)) {
      overlap.momentEndTime = startTime.clone();
      overlap.endTime = startTime.clone().utc().format();
    } else if (originalStart.isSameOrAfter(startTime)) {
      overlap.momentStartTime = endTime.clone();
      overlap.startTime = endTime.clone().utc().format();
    }

    if (originalEnd.isAfter(endTime) && originalStart.isBefore(startTime)) {
      const obj = helpers.clone(objectDefinition);
      obj.momentStartTime = endTime.clone();
      obj.startTime = endTime.clone().utc().format();
      obj.momentEndTime = originalEnd.clone();
      obj.endTime = originalEnd.clone().utc().format();
      tagUtils.copyPropertyValues(columns, overlap, obj);
      copy.push(obj);
    }

    x++;
  }

  const np = helpers.clone(objectDefinition);
  tagUtils.setDefaultPropertyValues(np, columns);
  np.momentStartTime = startTime.clone();
  np.momentEndTime = endTime.clone();
  np.startTime = startTime.clone().utc().format();
  np.endTime = endTime.clone().utc().format();

  if (lastOverlap != null) {
    tagUtils.copyPropertyValues(columns, lastOverlap, np);
  }

  np[item.prop] = item.value === '' ? null : item.value;

  const currentColumnDef = findColumnIndex(columns, item.prop);

  // find the max energy column. set the sink to it's value.
  // a sink's value can never be set explicitly.
  if (currentColumnDef.col.profileProductType === 'ENERGY') {
    const maxEnergyColumnDef = tagUtils.findMaxEnergyColumn(columns);
    np.sink = np[maxEnergyColumnDef.col.prop];
  }

  copy.push(np);
}

export function CalculateDailySummary(hourRange, profiles, columnDictionary) {
  const matchingHours = [];
  const { tz } = dateStore.getTimeZoneDefinition();
  hourRange.forEach((hr) => {
    hr.momentStartTime = dateStore.toMoment(hr.start, tz);
    hr.momentEndTime = dateStore.toMoment(hr.end, tz);
  });

  for (let i = 0; i < hourRange.length; i++) {
    const hourMatch = helpers.filter(profiles, (o) => hourRange[i].momentStartTime.isBefore(o.momentEndTime) && hourRange[i].momentEndTime.isAfter(o.momentStartTime));
    if (hourMatch) {
      // Weighted Average
      let count = 0;
      let finalHour = null;
      hourMatch.forEach((hour) => {
        if (count === 0) {
          finalHour = cloneDeep(hour);
          finalHour.startTime = hourRange[i].start;
          finalHour.endTime = hourRange[i].end;
        }
        const end = toMomentFromDate(hour.momentEndTime, tz);
        const start = toMomentFromDate(hour.momentStartTime, tz);
        const duration = end.diff(start, 'minutes');

        columnDictionary.forEach((col) => {
          if (count === 0) finalHour[col.key] = (hour[col.key] * duration) / 60;
          else finalHour[col.key] += (hour[col.key] * duration) / 60;
        });
        count++;
      });
      if (finalHour) matchingHours.push(finalHour);
    }
  }
  return matchingHours;
}

function ColumnSummaryMapper(matchingHours, columnDictionary) {
  const columns = [];

  if (matchingHours && matchingHours.length > 0) {
    columnDictionary.forEach((col) => {
      columns.push({ columnName: col.key, sum: 0 });
    });

    columns.forEach((col) => {
      matchingHours.forEach((hour) => {
        if (hour[col.columnName] != null && (!Number.isNaN(hour[col.columnName]))) {
          col.sum += Math.round(hour[col.columnName]);
        }
      });
    });
  }
  return columns;
}

export function TotalDailySummary(columnDictionary, onPeakMatchingHours, offPeakMatchingHours) {
  const summaryTotals = [{ startTime: 'On Peak', summaryFlag: true }, { startTime: 'Off Peak', summaryFlag: true }, { startTime: 'Daily Total', summaryFlag: true }];
  for (let i = 0; i < 3; i++) {
    columnDictionary.forEach((col) => {
      summaryTotals[i][col.key] = 0;
    });
  }

  const onPeakColumns = ColumnSummaryMapper(onPeakMatchingHours, columnDictionary);
  const offPeakColumns = ColumnSummaryMapper(offPeakMatchingHours, columnDictionary);

  onPeakColumns.forEach((col) => {
    summaryTotals[0][col.columnName] = col.sum;
    summaryTotals[2][col.columnName] = col.sum;
  });

  offPeakColumns.forEach((col) => {
    summaryTotals[1][col.columnName] = col.sum;
    summaryTotals[2][col.columnName] += col.sum;
  });
  for (let i = 0; i < 3; i++) {
    for (const key in summaryTotals[i]) {
      if (Object.prototype.hasOwnProperty.call(summaryTotals[i], key)) {
        if (key !== 'startTime' && key !== 'summaryFlag') {
          summaryTotals[i][key] = summaryTotals[i][key];
        }
      }
    }
  }

  return summaryTotals;
}