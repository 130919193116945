import Vue from 'vue';
import { dailyParameterConfiguration, hourlyParameterConfiguration } from './static';

function getColumns(marketType, locationType, msg) {
  const columns = [];

  if (marketType === 'RTM') {
    columns.push(...[{
      prop: 'startHour', label: 'Hour', editable: false, fixed: 'left',
    }]);
  }

  if (msg) {
    columns.push({
      prop: 'configuration', editable: false, fixed: 'left',
    });
  }

  switch (locationType) {
  case 'GEN':
  case 'TG':
    columns.push(...dailyParameterConfiguration.columns.filter((x) => x.prop !== 'minHourlyBlock'));
    break;
  case 'LOAD':
    columns.push(...dailyParameterConfiguration.columns.filter((x) => x.prop === 'contingencyAvailabilityFlag'));
    break;
  case 'ITIE':
  case 'ETIE':
    columns.push(...dailyParameterConfiguration.columns.filter((x) => x.prop === 'contingencyAvailabilityFlag'
    || x.prop === 'minHourlyBlock'));
    break;
  default:
    break;
  }
  return columns;
}

function getHourlyColumns(marketType, locationType, msg) {
  const columns = [{
    prop: 'startHour', label: 'Hour', editable: false, fixed: 'left',
  }];

  if (msg) {
    columns.push({
      prop: 'configuration', editable: false, fixed: 'left',
    });
  }

  switch (locationType) {
  case 'GEN':
  case 'TG':
    columns.push(...hourlyParameterConfiguration.columns.filter((x) => x.prop !== 'bidOptionType'));
    if (marketType === 'RTM') {
      columns.push(...hourlyParameterConfiguration.columns
        .filter((x) => x.prop === 'bidOptionType'));
    }
    break;
  case 'LOAD':
    break;
  case 'ITIE':
  case 'ETIE':
    if (marketType === 'RTM') {
      columns.push(...hourlyParameterConfiguration.columns
        .filter((x) => x.prop === 'bidOptionType'));
    }
    break;
  default:
    break;
  }

  return columns;
}

const state = {
  dailyBidComponentTableConfig: {
    columns: [],
  },
  hourlyBidComponentTableConfig: {
    columns: [],
  },
  rampCurveTableConfig: {
    columns: [{
      prop: 'segmentId', label: 'Segment', editable: false,
    }, {
      prop: 'operatingLevel', label: 'Operating Level', editable: true,
    }, {
      prop: 'rampRate', label: 'Ramp Rate', editable: true,
    }],
  },
  startupCostCurveTableConfig: {
    columns: [{
      prop: 'segmentId', label: 'Segment', editable: false,
    }, {
      prop: 'xAxis', label: 'Cooling Time', editable: true,
    }, {
      prop: 'yAxis', label: 'Cost', editable: true,
    }],
  },
  startupTimeCurveTableConfig: {
    columns: [{
      prop: 'segmentId', label: 'Segment', editable: false,
    }, {
      prop: 'xAxis', label: 'Cooling Time', editable: true,
    }, {
      prop: 'yAxis', label: 'Startup Time', editable: true,
    }],
  },
  transitionsTableConfig: {
    columns: [{
      prop: 'fromConfiguration', label: 'From Configuration', editable: true,
    }, {
      prop: 'toConfiguration', label: 'To Configuration', editable: true,
    }, {
      prop: 'notificationTime', label: 'Notification Time', editable: true,
    }, {
      prop: 'rampTime', label: 'Ramp Time', editable: true,
    }, {
      prop: 'cost', label: 'Cost', editable: true,
    }],
  },
  dailyBidComponentData: [],
  hourlyBidComponentData: [],
  rampCurveData: [],
  startupCostCurveData: [],
  startupTimeCurveData: [],
  transitionsData: [],
  selectedDailyBidRow: null,
};

const getters = {
  getDailyBidComponentTableConfig: (state) => state.dailyBidComponentTableConfig,
  getHourlyBidComponentTableConfig: (state) => state.hourlyBidComponentTableConfig,
  getDailyBidComponentData: (state) => state.dailyBidComponentData,
  getHourlyBidComponentData: (state) => state.hourlyBidComponentData,
  getRampCurveTableConfig: (state) => state.rampCurveTableConfig,
  getTransitionsTableConfig: (state) => state.transitionsTableConfig,
  getRampCurveData: (state) => {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      return state.rampCurveData.filter(({
        he,
        configuration,
        rampRateType,
      }) => he == startHour && configuration === conf && rampRateType === 'OP');
    }
    return [];
  },
  getAllRampCurveData: (state) => state.rampCurveData,
  getStartupCostTableConfig: (state) => state.startupCostCurveTableConfig,
  getStartupCostData: (state) => {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      return state.startupCostCurveData.filter(({ he, configuration }) => he == startHour && configuration === conf);
    }
    return [];
  },
  getAllStartupCostData: (state) => state.startupCostCurveData,
  getStartupTimeTableConfig: (state) => state.startupTimeCurveTableConfig,
  getStartupTimeData: (state) => {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      return state.startupTimeCurveData.filter(({ he, configuration }) => he == startHour && configuration === conf);
    }
    return [];
  },
  getAllStartupTimeData: (state) => state.startupTimeCurveData,
  getTransitionsData: (state) => state.transitionsData,
  getSelectedDailyBidRow: (state) => state.selectedDailyBidRow,
};

const actions = {};

const mutations = {
  setSelectedDailyBidRow(state, value) {
    if (value) {
      state.selectedDailyBidRow = value;
    }
  },
  setDailyBidComponentTableConfig(state, marketType, locationType) {
    const msg = state.configurations && state.configurations.length > 0;
    state.dailyBidComponentTableConfig.columns = getColumns(marketType, locationType, msg);
    state.dailyBidComponentTableConfig.options = {
      ignoreEdit: ['selected'],
    };
  },
  setHourlyBidComponentTableConfig(state, marketType, locationType) {
    const msg = state.configurations && state.configurations.length > 0;
    state.hourlyBidComponentTableConfig.columns = getHourlyColumns(marketType, locationType, msg);
    state.hourlyBidComponentTableConfig.options = {
      ignoreEdit: ['selected'],
    };
  },
  setDailyBidComponentData(state, item) {
    const stringProps = ['bidOptionType', 'capacityLimitType'];
    const rowIdx = state.dailyBidComponentData
      .findIndex(({ startHour, endHour, configuration }) => startHour === item.row.startHour
        && endHour === item.row.endHour
        && configuration === item.row.configuration,
      );
    if (item.value) {
      if (!stringProps.includes(item.prop)) item.value = parseFloat(parseFloat(item.value).toFixed(3));
    } else item.value = null;

    const dc = state.dailyBidComponentData[rowIdx];
    Vue.set(dc, item.prop, item.value);
  },
  setHourlyBidComponentData(state, item) {
    const stringProps = ['bidOptionType', 'capacityLimitType'];
    const rowIdx = state.hourlyBidComponentData
      .findIndex(({ startHour, endHour, configuration }) => startHour === item.row.startHour
        && endHour === item.row.endHour
        && configuration === item.row.configuration,
      );
    if (item.value) {
      if (!stringProps.includes(item.prop)) item.value = parseFloat(parseFloat(item.value).toFixed(3));
    } else item.value = null;

    const dc = state.hourlyBidComponentData[rowIdx];
    Vue.set(dc, item.prop, item.value);
  },
  loadDailyBidComponentData(state, item) {
    state.dailyBidComponentData = item;
  },
  loadHourlyBidComponentData(state, item) {
    state.hourlyBidComponentData = item;
  },
  setRampCurveData(state, { value, rowIndex, prop }) {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      const ramps = state.rampCurveData.filter(
        ({ he, configuration, segmentId }) => he >= startHour && configuration === conf && segmentId === rowIndex,
      );

      value = Number(value);

      if (Number.isNaN(value)) value = null;
      else value = Number(Number(value).toFixed(3));

      ramps.forEach((ramp) => Vue.set(ramp, prop, value));
    }
  },
  loadRampCurveData(state, item) {
    state.rampCurveData = item;
  },
  setStartupCostCurveData(state, { value, rowIndex, prop }) {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      const startups = state.startupCostCurveData.filter(
        ({ he, configuration, segmentId }) => he >= startHour && configuration === conf && segmentId === rowIndex,
      );

      value = Number(value);

      if (Number.isNaN(value)) value = null;
      else value = Number(Number(value).toFixed(3));

      startups.forEach((startup) => Vue.set(startup, prop, value));
    }
  },
  loadStartupCostCurveData(state, item) {
    state.startupCostCurveData = item;
  },
  setStartupTimeCurveData(state, { value, rowIndex, prop }) {
    if (state.selectedDailyBidRow) {
      const { startHour, configuration: conf } = state.selectedDailyBidRow;
      const startups = state.startupTimeCurveData.filter(
        ({ he, configuration, segmentId }) => he >= startHour && configuration === conf && segmentId === rowIndex,
      );

      value = Number(value);

      if (Number.isNaN(value)) value = null;
      else value = Number(Number(value).toFixed(3));

      startups.forEach((startup) => Vue.set(startup, prop, value));
    }
  },
  loadStartupTimeCurveData(state, item) {
    state.startupTimeCurveData = item;
  },
  addTransition(state) {
    state.transitionsData.push({
      fromConfiguration: '',
      toConfiguration: '',
      notificationTime: null,
      rampTime: null,
      cost: null,
    });
  },
  loadTransitionsData(state, item) {
    state.transitionsData = item;
  },
  reset(state) {
    state.dailyBidComponentData = [];
    state.hourlyBidComponentData = [];
    state.rampCurveData = [];
    state.startupCostCurveData = [];
    state.startupTimeCurveData = [];
    state.transitionsData = [];
    state.selectedDailyBidRow = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};