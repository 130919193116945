import { accessRight, system } from '@/auth/permission';

export default
{
  path: '/system',
  meta: {
    visible: true,
    requiresAuth: false,
    text: 'SYSTEM',
    permission: { name: system.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'scheduler',
    name: 'Scheduler',
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Scheduler',
      permission: { name: system.scheduler, rights: [accessRight.visible] },
    },
    component: () => import('@/components/System/Scheduler/Scheduler'),
  },{
    path: 'execution',
    name: 'ExecutionLog',
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Execution Log',
      permission: { name: system.executionLog, rights: [accessRight.visible] },
    },
    component: () => import('@/components/System/ConnectionMessages/ExecutionLog'),
  }],
};