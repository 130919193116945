import { CISO_MASTERDATA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { clone } from '@/utils/dataUtil';

const state = {
  tableData: [],
  resourceTableData: [],
  resources: [],
  selectedResource: null,
  selectedGroup: null,
  configuration: {
    columns: [{
      prop: 'remove', label: 'Remove', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, editable: true, width: 100, maxWidth: 100,
    }, {
      prop: 'name', label: 'Group Name', editable: true,
    }, {
      prop: 'description', label: 'Description', editable: true,
    }, {
      prop: 'createdBy', label: 'Created By',
    }, {
      prop: 'createdDate', label: 'Created Date',
    }, {
      prop: 'updatedBy', label: 'Updated By',
    }, {
      prop: 'updatedDate', label: 'Updated Date',
    }],
    options: { cellSelection: true },
    style: {
      maxHeight: 380,
      dynamicSizing: false,
    },
  },
  resourceTableConfig: {
    columns: [{
      prop: 'remove', label: 'Remove', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, editable: true, width: 100, maxWidth: 100,
    }, {
      prop: 'sc', label: 'Scheduling Coordinator', editable: false,
    }, {
      prop: 'resourceType', label: 'Resource Type', editable: false,
    }, {
      prop: 'shortName', label: 'Short Name', editable: false,
    }, {
      prop: 'longName', label: 'Long Name', editable: false,
    }],
    style: { maxHeight: 400 },
  },
  resourceGroups: [],
};

const getters = {
  getTableData: (state) => state.tableData,
  getTableConfiguration: (state) => state.configuration,
  getResourceTableConfig: (state) => state.resourceTableConfig,
  getResourceTableData: (state) => state.resourceTableData,
  getSelectedResource: (state) => state.selectedResource,
  getResourceList: (state) => state.resources.map((i) => ({ value: i.id, label: i.shortName })),
  getResourceGroups: (state) => state.resourceGroups.map((i) => ({ value: i.id, label: i.name })),
};

const actions = {
  addResource({ commit, state }, item) {
    if (!state.selectedGroup) {
      item.callback({ type: 'warning', title: 'Please click on group you wish to add to' });
    } else if (!state.selectedResource) {
      item.callback({ type: 'warning', title: 'Please select a resource to add' });
    } else {
      commit('setNewResource', item);
    }
  },
  selectedResourceChanged({ commit }, item) {
    commit('setSelectedResource', item);
  },
  clear({ commit }) {
    commit('clear');
  },
  load({ commit }) {
    CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE').then((response) => {
      commit('setTableData', response.data.resourceGroups);
    }).catch((err) => {
      console.log(err);
    });
    CISO_MASTERDATA_API.get('resources?variant=MASTER_FILE').then((response) => {
      commit('setResources', response.data.resources);
    }).catch((err) => {
      console.log(err);
    });
  },
  async loadResourceGroups({ commit }) {
    try {
      const { data } = await CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE');
      commit('setResourceGroups', data.resourceGroups);
    } catch (error) {
      console.error(error);
    }
  },
  dataChanged({ commit }, item) {
    commit('updateTableData', item);
  },
  save({ commit, state }, item) {
    const incomplete = state.tableData.filter((o) => (!o.name || o.name === '') || (!o.description || o.description === ''));
    if (incomplete.length > 0) {
      item.callback({ type: 'warning', title: 'Please give each group a name and description' });
    } else {
      const dirtyGroups = state.tableData.filter((o) => o.isDirty && !o.isNew);
      const newGroups = state.tableData.filter((o) => o.isNew);

      if (dirtyGroups.length > 0) {
        CISO_MASTERDATA_API.put('resourcegroups/batch', { resourceGroups: dirtyGroups }).then((response) => {
          item.callback({ type: 'success', title: 'Group(s) updated successfully' });
          commit('cleanDirty', response.data.resourceGroups);
        }).catch((err) => {
          item.callback({ type: 'error', title: 'Error updating resource group(s)' });
        });
      }

      if (newGroups.length > 0) {
        CISO_MASTERDATA_API.post('resourcegroups/batch', { resourceGroups: newGroups }).then((response) => {
          item.callback({ type: 'success', title: 'New Group(s) saved successfully' });
          commit('cleanNew', response.data.resourceGroups);
        }).catch((err) => {
          item.callback({ type: 'error', title: 'Error saving new resource group(s)' });
        });
      }
    }
  },
  addGroup({ commit }) {
    commit('addGroup');
  },
  cellClickChanged({ commit }, item) {
    if (item.row != null) commit('setResourceTableData', item.row);
    else state.resourceTableData = [];
  },
  resourceTableDataChanged({ commit }, item) {
    commit('updateResourceTableData', item);
  },
  removeResource({ commit }, item) {
    const resourcesToRemove = state.resourceTableData.filter((o) => o.remove);
    if (resourcesToRemove.length > 0) {
      const resources = resourcesToRemove.map((i) => ({ id: i.id, shortName: i.shortName }));

      if (state.selectedGroup.id !== -1 && !state.selectedGroup.remove) {
        for (let i = 0; i < resources.length; i++) {
          CISO_MASTERDATA_API.delete(`resourcegroups/${state.selectedGroup.id}/${resources[i].id}`).then((response) => {
            commit('removeResourceFromGroup', resources[i]);
          }).catch((err) => {
            item.callback({ type: 'error', title: `Error removing resource: ${resources[i].shortName}` });
            console.log(err.response);
          });
        }
      } else if (state.selectedGroup.id === -1) {
        for (let i = 0; i < resources.length; i++) {
          commit('removeResourceFromGroup', resources[i]);
        }
      }
    }
  },
  removeGroup({ commit }, item) {
    const groupsToRemove = state.tableData.filter((o) => o.remove);

    if (groupsToRemove.length > 0) {
      for (let i = 0; i < groupsToRemove.length; i++) {
        if (groupsToRemove[i].id) {
          CISO_MASTERDATA_API.delete(`resourcegroups/${groupsToRemove[i].id}`).then((response) => {
            commit('removeGroup', groupsToRemove[i]);
          }).catch((err) => {
            item.callback({ type: 'error', title: `Error removing resource group: ${groupsToRemove[i].name}` });
            console.log(err.response);
          });
        } else {
          groupsToRemove[i].id = -1;
        }
      }
    }
  },
};

const mutations = {
  clear(state) {
    state.tableData = [];
    state.resourceTableData = [];
  },
  setSelectedResource(state, item) {
    state.selectedResource = item;
  },
  setResourceGroups(state, item) {
    state.resourceGroups = item;
  },
  addGroup(state) {
    state.tableData.unshift({
      id: -1, name: null, description: null, isNew: true, resources: [], variant: 'MASTER_FILE',
    });
  },
  updateTableData(state, item) {
    if (item.value === 'true') { item.value = true; } else if (item.value === 'false') { item.value = false; }

    let setSelected = false;
    if (state.selectedGroup.id === state.tableData[item.rowIndex].id && state.selectedGroup.name === state.tableData[item.rowIndex].name && state.selectedGroup.description === state.tableData[item.rowIndex].description) { setSelected = true; }

    state.tableData[item.rowIndex][item.prop] = item.value;
    state.tableData[item.rowIndex].isDirty = true;

    if (setSelected) { state.selectedGroup = state.tableData[item.rowIndex]; }
  },
  setTableData(state, items) {
    for (let i = 0; i < items.length; i++) {
      items[i].updatedDate = items[i].updatedDate ? dateStore.toMomentFromJSDate(items[i].updatedDate).format('YYYY-MM-DD, hh:mm a') : null;
      items[i].createdDate = items[i].createdDate ? dateStore.toMomentFromJSDate(items[i].createdDate).format('YYYY-MM-DD, hh:mm a') : null;
      items[i].remove = false;
    }
    state.tableData = items;
  },
  setResourceTableData(state, item) {
    state.selectedGroup = item;
    const resources = [];
    if (item.resources) {
      for (let i = 0; i < item.resources.length; i++) {
        const rsrc = state.resources.find((o) => o.id === item.resources[i].id);
        if (rsrc) { resources.push(rsrc); }
      }
    }
    state.resourceTableData = resources;
  },
  setResources(state, items) {
    state.resources = items;
  },
  setNewResource(state, item) {
    const resource = state.resources.find((o) => o.id === state.selectedResource);
    const found = state.selectedGroup.resources.find((o) => o.id === state.selectedResource);
    if (!found) {
      const group = state.tableData.find((o) => o.name === state.selectedGroup.name && o.description === state.selectedGroup.description);
      resource.remove = false;
      group.resources.push({ id: resource.id, shortName: resource.shortName });
      state.resourceTableData.push(resource);
      group.isDirty = true;
      state.selectedGroup = group;
    }
  },
  cleanDirty(state, items) {
    const tableData = clone(state.tableData);
    for (let i = 0; i < items.length; i++) {
      const item = tableData.find((o) => o.name === items[i].name && o.description === items[i].description);
      if (item) {
        item.createdBy = items[i].createdBy;
        item.updatedBy = items[i].updatedBy;
        item.createdDate = items[i].createdDate ? dateStore.toMomentFromJSDate(items[i].createdDate).format('MM/DD/YY hh:mm') : null;
        item.updatedDate = items[i].updatedDate ? dateStore.toMomentFromJSDate(items[i].updatedDate).format('MM/DD/YY hh:mm') : null;
        item.isDirty = false;
      }
    }
    // Force table refresh
    state.tableData = tableData;
  },
  cleanNew(state, items) {
    const tableData = clone(state.tableData);
    for (let i = 0; i < items.length; i++) {
      const item = tableData.find((o) => o.name === items[i].name && o.description === items[i].description);
      if (item) {
        item.id = items[i].id;
        item.createdBy = items[i].createdBy;
        item.updatedBy = items[i].updatedBy;
        item.createdDate = items[i].createdDate ? dateStore.toMomentFromJSDate(items[i].createdDate).format('MM/DD/YY hh:mm') : null;
        item.updatedDate = items[i].updatedDate ? dateStore.toMomentFromJSDate(items[i].updatedDate).format('MM/DD/YY hh:mm') : null;
        item.isNew = false;
        item.isDirty = false;
      }
    }
    // Force table refresh
    state.tableData = tableData;
  },
  updateResourceTableData(state, item) {
    if (item.value === 'true') item.value = true;
    else if (item.value === 'false') item.value = false;
    state.resourceTableData[item.rowIndex][item.prop] = item.value;
  },
  removeResourceFromGroup(state, item) {
    let resourceTableData = clone(state.resourceTableData);
    const tableData = clone(state.tableData);
    const group = tableData.find((o) => o.id === state.selectedGroup.id && o.name === state.selectedGroup.name);
    if (group) {
      group.resources = group.resources.filter((o) => o.id === item.id);
      resourceTableData = resourceTableData.filter((o) => o.id === item.id);
    }

    state.selectedGroup = group;
    state.resourceTableData = resourceTableData;
    state.tableData = tableData;
  },
  removeGroup(state, item) {
    const tableData = clone(state.tableData).filter((o) => o.id === item.id && o.name === item.name && o.remove);
    if (state.selectedGroup.id === item.id && state.selectedGroup.name === item.name) {
      state.resourceTableData = [];
    }
    state.tableData = tableData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};