import mwhReport from './Reports/MWhReport/store';
import summary from './TagSummary/tagSummaryStore';
import request from './RequestSummary/store';
import VariantSummary from './VariantSummary/store';
import tag from './Tag/store';
import recsReport from './Reports/RECReport/store';
import recTagsReport from './Reports/RECTagsReport/store';
import tagSearch from './TagSearch/store';
import settings from './Settings/store';
import reporting from './Reports/reportingStore';
import balancingSources from './BalancingSources/store';

export default {
  namespaced: true,
  modules: {
    mwhReport,
    recsReport,
    recTagsReport,
    request,
    summary,
    tag,
    VariantSummary,
    tagSearch,
    settings,
    reporting,
    balancingSources,
  },
};