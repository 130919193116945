import utils from '@/utils';
import {POSITIONS_API} from '@/api';
import moment from 'moment';
import dateStore from '@/utils/dateStore';

import dataMtm from '../Credit/mtm.json';


const DAY = 'DAY';
const LONG_DAY = 'LONG_DAY';
const SHORT_DAY = 'SHORT_DAY';

const state = {
    tableConfiguration:{
        columns: [                 
          { prop: "ValueDate"      , label:"Value Date", width: 65, editable: false,}, 
          { prop: "Deal"    		, width: 65, editable: false,},
          { prop: "BuySell"		,label: "Buy Sell", width: 65, filter: true,editable: false,},
          { prop: "StartDate"		,label: "Start Date",  width: 65, editable: false,},
          { prop: "EndDate" 		,label: "End Date",  width: 65, editable: false,},
          { prop: "CounterParty" 	,label: "Counter Party",  width: 65,filter: true, editable: false,},
          { prop: "Product"		, width: 65, filter: true,editable: false,},
          { prop: "PortfolioName"  ,label: "Portfolio",  width: 65, editable: false,},
          { prop: "Quantity"		, width: 65, editable: false,
          valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "MarketPrice"	,label: "Market Price", width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "Price"			, width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },,
          { prop: "MarketValue"	, label:"Market Value", width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "Value"			, width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "MtmValue"		,label : "Mtm Value", width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "CreditExposure"	, label: "Credit Exposure",  width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "MtmChange"		, label: "Mtm Change",  width: 65, editable: false, valueFormatter: function CurrencyFormat(params) {
            if (params.value == null)
            {
                return;
            } 
            
            let val =  params.value
            val = parseFloat(val).toFixed(0);
            val  =  '$' + val.replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
            return val; 
            }
          },
          { prop: "Year"			, width: 65, filter: true,editable: false,},
          { prop: "Month"    		, width: 65,filter: true, editable: false,},
          { prop: "Settlement"		, width: 65,filter: true, editable: false,},
          { prop: "Invoice"  		, width: 65, filter: true,editable: false,},
          { prop: "DiscountFactor" , label: "Discount Factor",  width: 65, editable: false,}
        ],        
        footer: {
            count: false
        },
        style: {
            maxHeight: 800
        },
        options: {           
                clearFilter: true,
                exportCsv: true,
                exportXlsx: true,
                colPreferences: true,
                tableOptions: true 

        }
    },
    tableData: [],
    date: utils.date.getTomorrowLocal(), 
    is2D:  dateStore.getLongDayHour(), 
};
const getters = {  
    getTableConfiguration: function(state){       
        return state.tableConfiguration;
    }, 
    getTableData: function(state) { 
            return state.tableData;
    },   
    getMinHeight: function(state) {
        if(state.tableData && state.tableData.length > 0)
            return 600;

        return 100;      
    }, 
};

const actions = {
    loadResourceSchedules({dispatch, commit, state}, item){
        // TODO: Get Position Management Data from API
     /*   let momentDate = moment(item.date).format('YYYY-MM-DD');
        POSITIONS_API.get(`v1/groupflat?sc=${item.sc}&date=${momentDate}&marketType=${item.marketType}&group=${item.group}`)
        .then(function(response){ 
            commit('setData', response.data.schedules);
            console.log(response.data);
        })
        .catch(function(error){
            console.log(error);
        }); */

        commit('setData', dataMtm.MTM.row);

        console.log(dataMtm.MTM.row);


        /*
        CONFIG_API.get('/MTM/row')
        .then((response)=>{                    
            commit('setData', response.data);
        }, (err) =>{
            console.log(err);
        });  
        
        */




    },
    schedulingCoordinatorChanged({commit, dispatch}, item){
        commit('setSchedulingCoordinator', item);
    },
    loadData({dispatch}, item){
        dispatch('checkDST', state.date);
        dispatch('loadResourceSchedules', item);
    },
    dataChanged({commit}, item){
        commit('setData', item);
    },       
    checkDST({state}, item){
        let momentStartTime = dateStore.toMoment(item, 'America/Los_Angeles');
        let momentEndTime = momentStartTime.clone().add(1, 'days');
        let currentTime = momentStartTime.clone();
        let hours = [];
        let i = 1;
        while (currentTime.isBefore(momentEndTime)){
            currentTime = currentTime.add(1, 'hours');
            hours.push({label: i, value: i})
            i++;
        }
        if(hours.length > 24){
            state.mode = LONG_DAY;
            if(state.is2D){
                hours.forEach(x => {
                    if(x.value > 2){
                        if(x.value == 3){
                            x.label = "2*";
                            x.value = "2*";
                        }
                        else{
                            x.label = x.label -1;
                            x.value = x.value -1;
                        }
                    }

                });
            }
        }
        else if(hours.length < 24){
            hours.forEach(x => {
                if(x.value >= 3){
                    x.label = x.label + 1;
                    x.value = x.value + 1;
                }
            });
            state.mode = SHORT_DAY;
        }
        else {
            state.mode = DAY;
        }
    },
    clear({commit}){
        commit('clear');
    },

    changeDateAction({commit, dispatch}, value){
        commit('changeDateMutation', value);
        dispatch('checkDST', value);
    },
    changeMarketTypeAction({commit, dispatch}, value){
        commit('changeMarketTypeMutation', value);
    },
    changeSchedulingCoordinatorAction({commit, dispatch}, item){
        commit('changeSchedulingCoordinatorMutation', item);
    },
    changeResourceAction({commit, dispatch}, item){ 
        commit('changeResourceMutation', item);
    },
    changeMultipleSelectionAction({commit}, val){
        commit('multipleSelectionMutation', val);
    }
   
};

const mutations = {
    setSchedulingCoordinator(state, item){
        state.selectedSchedulingCoordinator = item;
    },
    clear(state){
        state.tableData = [];
        state.isSearched = false;
    },

    changeDateMutation(state, value){
        state.date = value;
    },
    changeMarketTypeMutation(state, value){
        state.selectedMarketType = value;
    },
    setScToResourceMutation(state, item) {
        if (item && item.schedulingCoordinators && item.schedulingCoordinators.length > 0){
            state.selectedSchedulingCoordinator = item.schedulingCoordinators[0].id;
            state.scToResources = item.schedulingCoordinators;
        }
    },
    changeSchedulingCoordinatorMutation(state, item){
        state.selectedSchedulingCoordinator = item;
        state.selectedResource = null;
    },
    changeResourceMutation(state, item){
        state.selectedResource = item;
    },
    setStatusesMutation(state, item){
        state.statuses = item.summaries;
        state.isSearched = true;
    },
    multipleSelectionMutation(state, val){     
        state.multipleSelection = val;
    },
    setProposedStatusesMutation(state, item) {
        state.proposedStatuses = item.summaries;
    },
    setHours(state, item){
        state.hours = item;
        state.endHour = item.length;
    },
    setIsSearched(state){
        state.isSearched = true;
    },
    setShowOpenHours(state){
        state.showOpenHours = !state.showOpenHours;
    },
    setData(state, item){
        state.tableData = item;                    
    },
    setIsDirtyFalse(state){
        if (state.tableData){
            for(let i = 0; i < state.tableData.length; i++){
                state.tableData[i].isDirty = false;
            }
        }
    }
   
};

export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}