import moment from 'moment';
import { VA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import caisoStore from '@/utils/caiso/caisoUtils';
import VABaseStore from '../VABaseStore';
import defaultLocationsConfig from './Charts/locationsConfig';

const roundValue = (value) => Math.round(value * 100) / 100;

const state = {
  isCurrentlyFetchingData: false,
  showInterval: true,
  showRolling: false,
  showDaily: false,
  intervalData: {},
  vaChartData: [],
  chartSeries: [],
  dataPoints: [],
  selectedLocations: [],
  selectedLocationIds: [],
  selectedGroup: 'ALL',
  selectedInterval: 'ALL',
  selectedVariant: null,
  selectedDataPoint: undefined,
  selectedDataPointId: null,
  selectedRefreshRate: null,
  setIsCurrentlyFetchingData: false,
  selectedDate: dateStore.getDefaultDate().toISOString(),
  locationsConfig: defaultLocationsConfig,
};

const getters = {
  heArray: (state) => state.vaChartData.length ? [...new Set(state.vaChartData.map(({ hourTime }) => hourTime))] : [],
  isRealTime: (state) => moment(state.selectedDate).isSame(moment(), 'day'),
  selectedLocationsObject: (state) => state.VABaseStore.locations.filter((locationsObject) => state.selectedLocations.includes(locationsObject.shortName)),

  exportData: (state, getters) => {
    const locations = getters.selectedLocationsObject;
    const { vaChartData } = state;
    // const chartSeries = getters.chartSeriesById;
    const dataPoint = state.selectedDataPoint.name;
    const columns = [{
      header: 'COMPANY', key: 'company', width: 12,
    }, {
      header: 'LOCATION', key: 'location', width: 12,
    }, {
      header: 'DATE', key: 'tradingDate', width: 12,
    }, {
      header: 'HOUR', key: 'tradingHour', width: 12,
    }, {
      header: 'INTERVAL', key: 'tradingInterval', width: 12,
    }, {
      header: `${dataPoint}_interval`, key: 'dataPoint', width: 12,
    }];
    if (state.showRolling) {
      columns.push({ header: `${dataPoint}_rolling`, key: 'dataPointRolling', width: 12 });
    }
    if (state.showDaily) {
      columns.push({ header: `${dataPoint}_daily`, key: 'dataPointDaily', width: 12 });
    }
    const rows = [];
    vaChartData.forEach((data) => {
      const sc = locations.find((location) => location.shortName === data.location).entityName;
      const item = {
        company: sc,
        location: data.location,
        tradingDate: data.date,
        tradingHour: data.he,
        tradingInterval: data.ie,
        dataPoint: data[`${data.location}_interval`],
        dataPointRolling: data[`${data.location}_rolling`],
        dataPointDaily: data[`${data.location}_daily`],
      };
      rows.push(item);
    });
    return { locations, columns, rows };
  },
};

const actions = {
  init({ dispatch, commit }, allowLayoutChange = true) {
    commit('setSelectedGroup', caisoStore.resourceGroupName);
    dispatch('fetchLocations');
    dispatch('fetchLayouts', allowLayoutChange);
    dispatch('fetchLocationGroups');
  },
  setChartData({ state, getters, commit }, chartData) {
    if (!chartData.data?.length && !chartData.preventReset) {
      commit('setVaChartData', []);
    } else {
      const intervals = [];
      const previousInterval = {};
      const { aggregateType } = state.selectedDataPoint;
      chartData.data.forEach((d) => {
        const mdt = dateStore.toMoment(d.startTime);
        const interval = {
          location: d.type,
          startTime: d.tzStartTime,
          endTime: d.tzEndTime,
          hourTime: mdt.clone().format('HH:mm'),
          settlementTime: `${(`0${d.he}`).slice(-2)}:${(`0${d.ie}`).slice(-2)}`,
          he: d.he,
          ie: d.ie,
          date: mdt.clone().format('L'),
          time: d.startTime,
          momentTime: mdt.clone(),
        };
        const seriesPoint = d[state.selectedDataPoint.seriesName];

        const resourceName = d.type;
        if (seriesPoint) {
          if (typeof seriesPoint === 'object') {
            const intervalCount = ((d.he - 1) * 12) + (d.ie / 5);
            const sum = previousInterval[`${resourceName}_sum`] ?? 0;
            const unroundedSum = previousInterval[`${resourceName}_urSum`] ?? 0;
            const val = roundValue(seriesPoint.value);
            const currentVal = (aggregateType === 'AVERAGE') ? roundValue(val / 12) : val;
            const newSum = sum ? currentVal + sum : currentVal;
            const newUnroundedSum = unroundedSum ? seriesPoint.value + unroundedSum : seriesPoint.value;
            previousInterval[`${resourceName}_sum`] = newSum;
            previousInterval[`${resourceName}_urSum`] = newUnroundedSum;
            interval[`${resourceName}_interval`] = val;
            interval[`${resourceName}_rolling`] = (aggregateType === 'AVERAGE')
              ? roundValue(newSum / intervalCount)
              : newSum;
          } else {
            // currently not occurring with test data.
            // to be revised if real data contains non object data.
            interval[resourceName] = seriesPoint;
          }
        } else {
          interval[resourceName] = null;
        }
        intervals.push(interval);
      });
      intervals.forEach((interval) => {
        const resourceName = interval.location;
        const sum = roundValue(previousInterval[`${resourceName}_urSum`]);
        interval[`${resourceName}_daily`] = (aggregateType === 'AVERAGE')
          ? (sum / 288)
          : sum;
      });

      commit('setVaChartData', [...state.vaChartData, ...intervals]);
    }
    commit('setIsCurrentlyFetchingData', false);
  },
  async fetchIntervals({
    commit, dispatch, getters, state,
  }, locations = undefined) {
    if (!state.selectedDate
      || !state.selectedVariant
      || !state.selectedDataPoint
      || !state.selectedLocations.length) return;
    commit('setIsCurrentlyFetchingData', true);

    try {
      const params = {
        startTime: moment(state.selectedDate).toISOString(),
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        timeZone: dateStore.getTimeZone(),
        variants: getters.selectedVariant,
        objectReferences: state.selectedDataPoint.seriesId,
        objectTypes: 'series',
        types: locations?.join(',') || state.selectedLocations.join(','),
        realTimeFlag: getters.isRealTime,
      };
      const { data: { data } } = await VA_API.post('intervals/data', params);
      const preventReset = !!locations;
      dispatch('setChartData', { data: data ?? [], preventReset });
      commit('updateChartSeries', state.selectedDataPoint.type || 'line');
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  updateChartSeries(state, lineType) {
    state.chartSeries = state.chartSeries.map((series) => {
      if (series.interval.toLowerCase() === 'interval') series.type = lineType;
      return series;
    });
  },

  ...createMutations(
    'showInterval',
    'showRolling',
    'showDaily',
    'chartSeries',
    'selectedDataPointId',
    'selectedDate',
    'intervalData',
    'vaChartData',
    'selectedGroup',
    'selectedLocations',
    'selectedLocationIds',
    'selectedInterval',
    'selectedVariant',
    'selectedDataPoint',
    'selectedRefreshRate',
    'isCurrentlyFetchingData',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};
