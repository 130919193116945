import { CISO_SIBR_API } from '@/api';
import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import config from './baseSchedulesConfig';

const state = {
  tableData: [],
  tableConfig: config.pivotGridFieldGroups.defaultFields,
};

const actions = {
  async fetchBaseSchedules({ state, commit }, parameters = {}) {
    let publicationNames;
    if (parameters.baseSchedulesTMaxSelected) {
      publicationNames = 'T-MAX';
    } else {
      publicationNames = parameters.baseSchedulesOptionsSelected.join();
    }
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      SCs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      publications: publicationNames,
    };

    try {
      const { data: { baseSchedules } } = await CISO_SIBR_API.get('/base-schedules', { params });

      baseSchedules.forEach((x) => {
        x.downloadTime = moment(x.downloadTime).utc().format('MM/DD/YY HH:mm');
      });
      commit('setTableData', {
        baseSchedules,
        nonZeroValues: parameters.removeZeroSelected,
        validModifiedStatusOnly: parameters.validModifiedStatusOnlySelected,
      });
    } catch {
      this.$notify('Failed to load Base Schedules', 'error');
    }
  },
};

const mutations = {
  setTableData(state, {
    baseSchedules, nonZeroValues,
    validModifiedStatusOnly,
  }) {
    let tmpTableData = baseSchedules;
    if (nonZeroValues) {
      tmpTableData = tmpTableData.filter((x) => x.value !== 0);
    }
    tmpTableData.forEach((x) => {
      if (x.startDate) x.startDate = moment.utc(x.startDate).format('MM/DD/YYYY');
      if (x.lastUpdated) x.lastUpdated = moment.utc(x.lastUpdated).format('MM/DD/YYYY');
    });

    if (validModifiedStatusOnly) {
      const targetStatuses = ['V', 'M', 'MI'];
      tmpTableData = tmpTableData.filter((x) => targetStatuses.includes(x.status));
    }

    state.tableData = Object.freeze(tmpTableData);
  },
  reset(state) {
    state.tableData = [];
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};