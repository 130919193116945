<template>
  <DxButtonGroup
    :items="items"
    key-expr="name"
    styling-mode="outlined"
    @item-click="itemClicked" />
</template>

<script>
import { DxButtonGroup } from 'devextreme-vue/ui/button-group';

export default {
  name: 'PscsNotificationDevelopment',
  components: { DxButtonGroup },
  data: () => ({
    items: [
      { icon: 'plus', name: 'addworkflow', hint: 'Add Workflow' },
      // { icon: 'arrowdown', name: 'soundlogic', hint: 'Sound Logic' },
    ],
  }),
  methods: {
    itemClicked({ itemData }) {
      this.$store.dispatch('notification/developmentAction', { name: itemData.name, delay: 2000 });
      // this.items[1].icon = this.items[1].icon === 'arrowdown' ? 'arrowup' : 'arrowdown';
    },
  },
};
</script>