import * as tagUtils from '@/utils/etag/tagUtils';
import { cloneDeep } from '@/utils/dataUtil';
import { ETAG_API } from '@/api';
import { USE_PSCS_AG_GRID } from './constants';
import tagStore from './tagStore';

const state = {
  visible: false,
  table: {
    columns: [{
      prop: 'token',
      label: 'token',
      editable: true,
      type: {
        name: USE_PSCS_AG_GRID ? 'PscsAutocompleteCellRenderer' : 'pscs-autocomplete-cell',
        list: 'tokenList',
        remote: true,
        allowUnmatched: true,
      },
    }, {
      prop: 'value',
      label: 'value',
      editable: true,
      type: {
        name: USE_PSCS_AG_GRID ? 'PscsAutocompleteCellRenderer' : 'pscs-autocomplete-cell',
        list: 'valueList',
        remote: true,
        allowUnmatched: true,
      },
    }],
    options: { showAddRemove: true },
    columnList(name) {
      if (name === 'tokenList') return this.tokenList;
      if (name === 'valueList') return this.valueList;
      return [];
    },
    tokenList: [],
    valueList: [],
  },
  data: [],
  row: null,
  column: null,
  rowIndex: null,
  customUserMiscInfoList: [],
  customUserMiscInfoSelection: null,
  customMiscInfoNameField: '',
};

const getters = {
  getVisibility: (state) => state.visible,
  getTableConfiguration(state, readonly = true) {
    const configuration = cloneDeep(state.table);
    if (readonly) tagUtils.setEditableFalse(configuration, {});
    return configuration;
  },
  getData: (state) => state.data,
  getCustomUserMiscInfoSelection: (state) => state.customUserMiscInfoSelection,
  getCustomUserMiscInfoList: (state) => state.customUserMiscInfoList,
  getMiscInfoReadOnlyFlag: (state) => state.miscInfoReadOnly,
  getCustomMiscInfoNameField: (state) => state.customMiscInfoNameField,
};

const actions = {
  checkSetReadOnlyFlag({ commit, dispatch }, state, item, mode) {
    const correctionFlag = tagStore.getters.getCorrectionFlag(tagStore.state);
    if (item.row) {
      if ((item.row.trans_state === 'dirty' && item.column.tableConfig.tableName === 'transAllocation'
            && mode === 'EDIT_TAG')
          || (correctionFlag && mode === 'EDIT_TAG')
          || ['NEW_TAG', 'VARIANT_TAG', 'NEW_TEMPLATE', 'EDIT_TEMPLATE'].includes(mode)) {
        // dispatch('fetchCustomMiscInfoList');
        commit('setMiscInfoReadOnlyFlag', false);
      } else commit('setMiscInfoReadOnlyFlag', true);
    }
  },
  changeMiscInfo({ commit }, state, item, readonly) {
    // reset custom misc info props when selecting new misc info
    state.customUserMiscInfoSelection = '';
    state.customMiscInfoNameField = '';
    if (!item.visible) {
      let countProp = '';
      if (state.column) { countProp = USE_PSCS_AG_GRID ? state.column.field : state.column.prop; }
      const dataProp = `${countProp.substring(0, countProp.indexOf('Count'))}List`;
      if (!readonly) {
        const result = [];
        state.data.forEach((d) => {
          if (d.token !== '' && d.value !== '') result.push(d);
        });
        commit('setMiscInfoProperty', {
          rowIndex: state.rowIndex,
          type: (state.row) ? state.row.type : null,
          countProp,
          count: result.length,
          dataProp,
          data: result,
        });
      }
    }
    commit('setMiscInfo', item);
  },
  changeRow({ commit }, item) {
    commit('setMiscInfoRow', item);
  },
  async initialize({ commit, state, dispatch }) {
    if (!state.miscInfo.table.tokenList.length) {
      await ETAG_API.get('tags/miscs/token').then((response) => {
        commit('loadMiscTokens', response.data);
      }).catch((error) => console.log(error));
      await ETAG_API.get('tags/miscs/value').then((response) => {
        commit('loadMiscValues', response.data);
      }).catch((error) => console.log(error));
    }
    // dispatch('fetchCustomMiscInfoList');
  },
  rowOperation({ commit }, item) {
    if (item.type === 'ADD') {
      commit('addMiscInfo', { index: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      commit('removeMiscInfo', { index: item.rowIndex });
    }
  },
  // changeCustomUserMiscInfoSelection({ commit, state }, value) {
  //   commit('setCustomMiscInfoSelection', value);
  //   commit('setCustomMiscInfoNameField', value);
  //   STRUCTURES_API.get(`userCollections/miscInfo/resources/${value}`).then((response) => {
  //     // clear out data in table
  //     state.miscInfo.data = [];
  //     response.data.miscInfo.forEach((item) => {
  //       if (item.value.length === 0 || item.value === '') {
  //         state.miscInfo.data.push({ token: item.token, value: ' ' });
  //       } else {
  //         state.miscInfo.data.push(item);
  //       }
  //     });
  //   }).catch((err) => {
  //     state.miscInfo.data = [{ token: '', value: '' }];
  //     console.log(err);
  //   });
  // },
  // fetchCustomMiscInfoList({ commit, state }) {
  //   STRUCTURES_API.get('userCollections/miscInfo/resources').then((response) => {
  //     commit('setCustomMiscInfoList', response.data);
  //   }).catch((error) => console.log(error));
  // },
  // changeCustomMiscInfoNameField({ commit }, value) {
  //   commit('setCustomMiscInfoNameField', value);
  // },
  // addCustomMiscInfo({ commit, state }) {
  //   state.miscInfo.customMiscInfoNameField = state.miscInfo.customMiscInfoNameField.replace(/\s/g, '');
  //   commit('setCustomMiscInfoNameField', state.miscInfo.customMiscInfoNameField);
  //   const { customUserMiscInfoList, customMiscInfoNameField, data } = state.miscInfo;

  //   if (customUserMiscInfoList.findIndex((i) => i.label === customMiscInfoNameField) === -1 && customMiscInfoNameField.length > 0) {
  //     STRUCTURES_API.post(`userCollections/miscInfo/resources/${customMiscInfoNameField}`, {
  //       miscInfo: data,
  //     }).then((response) => {
  //       customUserMiscInfoList.push({ label: customMiscInfoNameField, value: customMiscInfoNameField });
  //       commit('setCustomMiscInfoSelection', customMiscInfoNameField);
  //       this.$notify(`${customMiscInfoNameField} Successfully Added`, 'success');
  //     }).catch((error) => {
  //       this.$notify(`${String(error)}, Please Contact Support`, 'error');
  //     });
  //   } else if (customMiscInfoNameField.length === 0) {
  //     this.$notify('The Misc Info Name Field Cannot Be Empty', 'warning');
  //   } else {
  //     this.$notify('A Misc Info With That Name Already Exists', 'warning');
  //   }
  // },
  // updateCustomMiscInfo({ commit, state }) {
  //   const { customUserMiscInfoList, customMiscInfoNameField, data } = state.miscInfo;
  //   if (customUserMiscInfoList.findIndex((i) => i.label === customMiscInfoNameField) !== -1) {
  //     STRUCTURES_API.put(`userCollections/miscInfo/resources/${customMiscInfoNameField}`, {
  //       miscInfo: data,
  //     }).then((response) => {
  //       commit('setCustomMiscInfoSelection', customMiscInfoNameField);
  //       this.$notify(`${customMiscInfoNameField} Successfully Updated`, 'success');
  //     }).catch((error) => {
  //       this.$notify(`${String(error)}, Please Contact Support`, 'error');
  //       console.log(error);
  //     });
  //   } else {
  //     this.$notify('A Misc info With That Name Does Not Exist', 'error');
  //   }
  // },
  // deleteCustomMiscInfo({ commit, state }) {
  //   const { miscInfo } = state;
  //   if (miscInfo.customUserMiscInfoList.findIndex((i) => i.label === miscInfo.customMiscInfoNameField) !== -1) {
  //     STRUCTURES_API.delete(`userCollections/miscInfo/resources/${miscInfo.customMiscInfoNameField}`).then((response) => {
  //       miscInfo.customUserMiscInfoList = miscInfo.customUserMiscInfoList.filter((i) => i.label !== miscInfo.customMiscInfoNameField);
  //       miscInfo.data = [{ token: '', value: '' }];
  //       this.$notify(`${miscInfo.customMiscInfoNameField} Successfully Deleted`, 'success');
  //       commit('setCustomMiscInfoSelection', null);
  //       commit('setCustomMiscInfoNameField', '');
  //     }).catch((error) => {
  //       this.$notify(`${String(error)}, Please Contact Support`, 'error');
  //     });
  //   } else {
  //     this.$notify('A Misc info With That Name Does Not Exist', 'error');
  //   }
  // },
};

const mutations = {
  setMiscInfo(state, item, readonly = true) {
    state.visible = item.visible;
    if (state.visible) {
      if (item.column) {
        const countProp = USE_PSCS_AG_GRID ? item.column.field : item.column.prop;
        const dataProp = `${countProp.substring(0, countProp.indexOf('Count'))}List`;
        state.row = item.row;
        state.column = item.column;
        const count = state.row[countProp];
        state.data = state.row[dataProp];
        if (!readonly && count.toString() === '0') {
          state.data.push({ token: '', value: '' });
        }
        state.rowIndex = item.rowIndex;
      }
    } else {
      state.rowIndex = null;
      state.row = null;
      state.column = null;
      state.data = [];
    }
  },
  addRow(state, item) {
    const data = [];
    for (let x = 0; x <= item.index; x++) {
      data.push(state.data[x]);
    }
    data.push({ token: '', value: '' });
    for (let x = item.index + 1; x < state.data.length; x++) {
      data.push(state.data[x]);
    }
    state.data = data;
  },
  removeRow(state, item) {
    const data = [];
    for (let x = 0; x < item.index; x++) {
      data.push(state.data[x]);
    }
    for (let x = item.index + 1; x < state.data.length; x++) {
      data.push(state.data[x]);
    }
    if (data.length === 0) data.push({ token: '', value: '' });
    state.data = data;
  },
  setMiscInfoRow(state, item) {
    if (item.value && item.value.length <= 128) state.data[item.rowIndex][item.prop] = item.value;
  },
  loadMiscValues(state, { miscInfos }) {
    state.table.valueList = miscInfos.map((item) => ({ value: item, label: item }));
  },
  loadMiscTokens(state, { miscInfos }) {
    state.table.tokenList = miscInfos.map((item) => ({ value: item, label: item }));
  },
  setCustomMiscInfoList(state, list) {
    list.forEach((item) => {
      if (state.customUserMiscInfoList.findIndex((i) => i.label === item.name) === -1) {
        state.customUserMiscInfoList.push({ label: item.name, value: item.name });
      }
    });
  },
  setCustomMiscInfoSelection(state, value) {
    state.customUserMiscInfoSelection = value;
  },
  setCustomMiscInfoNameField(state, value) {
    state.customMiscInfoNameField = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  modules: { tagStore },
};