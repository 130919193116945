import { STRUCTURES_API, IDENTITY_API, CISO_OASIS_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { mapLabelValue } from '../../../utils';

const createLocationParameters = (transaction, propList) => {
  const params = [];
  propList.forEach((prop) => {
    const value = transaction[prop];
    if (value) {
      params.push({
        value,
        name: prop.charAt(0).toUpperCase() + prop.slice(1),
        effectiveDate: transaction.effectiveDate,
        valueType: 0,
      });
    }
  });
  return params;
};

const replaceAll = (string, search, replace) => string.split(search).join(replace);

export default {
  namespaced: true,
  state: {
    schedulingCoordinators: [],
    energyTypeList: ['Dynamic', 'Firm', 'NonFirm', 'UnitContingent', 'Wheeling'],
    schedulingPointTieList: [],
    schedulingPointList: [],
    interTieList: [],
  },
  getters: {
    getSchedulingCoordinators: (state) => state.schedulingCoordinators,
    getEnergyTypeList: (state) => state.energyTypeList,
    getSchedulingPointList: (state) => state.schedulingPointList,
    getInterTieList: (state) => state.interTieList,
    getSelectedSchedulePoint: (state) => state.selectedSchedulePoint,
    getSelectedSchedulePointTieList: (state) => state.schedulingPointTieList,
    getSelectedPrimaryTie: (state) => state.selectedPrimaryTie,
    getSelectedAlternateTie: (state) => state.selectedAlternateTie,
  },
  actions: {
    initialize({ dispatch }, effectiveDate) {
      dispatch('loadSchedulingCoordinators');
      dispatch('loadTieNames', effectiveDate);
      dispatch('loadSchedulingPoints', effectiveDate);
    },
    showTransactionDialog: ({ commit }) => commit('changeTransactionDialog'),
    async createTransaction({ commit, dispatch }, item) {
      const propList = ['schedulingNodeName',
        'primaryIntertieName',
        'secondaryIntertieName',
        'purchaseSellingEntity',
        'interTieDirection',
        'energyType',
        'DISPATCH_TYPE'];

      const location = {
        shortName: replaceAll(item.transactionName, '-', '_'),
        fullName: item.transactionName,
        entityName: item.schedulingCoordinator,
        entityType: 'SC',
        commodityName: 'POWER',
        marketName: 'CAISO',
        locationType: item.interTieDirection === 'I' ? 'ITIE' : 'ETIE',
        activeFlag: true,
        parameters: [...createLocationParameters(item, propList), {
          value: false,
          name: 'IsRegisteredIntertie',
          valueType: 4,
          effectiveDate: item.effectiveDate,
        }],
      };

      const { data: { data } } = await STRUCTURES_API.post('/locations', location);
      if (data) {
        await STRUCTURES_API.post(`location-groups/${item.locationGroupId}/locations/${data.id}`);
      }
    },
    async loadSchedulingCoordinators({ commit }) {
      try {
        const { data: { entities } } = await IDENTITY_API.get('entities');
        if (entities) commit('setSchedulingCoordinators', entities.filter((x) => x.type === 'SC'));
      } catch (error) {
        vue.$notify('Failed to load Scheduling Coordinators', 'error');
        console.log(error);
      }
    },
    async loadTieNames({ commit }, effectiveDate) {
      try {
        const { data } = await CISO_OASIS_API.get(`/oasis-reports/atlas/tie-names-v2?tradingDate=${effectiveDate}`);
        if (data) commit('setSchedulingPointTieList', data);
      } catch (error) {
        // vue.$notify('Failed to load Scheduling Point Ties', 'error');
        console.log(error);
      }
    },
    async loadSchedulingPoints({ commit }, effectiveDate) {
      try {
        const { data } = await CISO_OASIS_API.get(`/oasis-reports/atlas/scheduling-points-v2?tradingDate=${effectiveDate}`);
        if (data) commit('setSchedulingPointList', data);
      } catch (error) {
        // vue.$notify('Failed to load Scheduling Points', 'error');
        console.log(error);
      }
    },
  },
  mutations: {
    setEnergyTypeList(state, items) {
      state.energyTypeList = items;
    },
    setSchedulingCoordinators(state, items) {
      state.schedulingCoordinators = items;
    },
    setSchedulingPointTieList(state, items) {
      state.schedulingPointTieList = items;
    },
    setSchedulingPointList(state, items) {
      state.schedulingPointList = items;
    },
  },
};