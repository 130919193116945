import moment from 'moment';
import { getTodayLocal, getTomorrowLocal } from '@/utils/dateUtil';
import { clone } from '@/utils/dataUtil';
import { CMS_API, STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';

const state = {
  contract: {
    id: null,
    versionId: null,
    description: null,
    project: null,
    contractType: null,
    contractNumber: null,
    effectiveDate: null,
    terminationDate: null,
    company: null,
    counterParty: null,
    parentContract: null,
    currencyName: null,
    paymentTerm: null,
    status: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  contractRegistry: {
    companyList: [],
    internalCompanyList: [],
    contractTypeList: [],
    contractStateTypeList: [],
    currencyList: [],
    paymentTermList: [],
  },
  auditLogConfig: {
    columns: [{
      prop: 'eventType', label: 'Type', filterable: true, sortable: true, alignment: 'left',
    }, {
      prop: 'attributeChanged', label: 'Attribute', filterable: true, sortable: true, alignment: 'left',
    }, {
      prop: 'oldValue', label: 'Old Value', filterable: true, sortable: true, alignment: 'left',
    }, {
      prop: 'newValue', label: 'New Value', filterable: true, sortable: true, alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, sortable: true, alignment: 'left',
    }, {
      prop: 'timestamp', label: 'Timestamp', filterable: true, sortable: true, dataType: 'datetime', alignment: 'left',
    }, {
      prop: 'comment', label: 'Comment', filterable: true, alignment: 'left',
    }],
    options: {
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
    },
  },
  deliverableTableKey: -999,
  currentDeliverable: null,
  deliverableTableData: [],
  deliverableTableConfig: {
    columns: [{
      prop: 'versionId', label: 'Ver', filterable: true, sortable: true, alignment: 'left', width: '50px'
    }, {
      prop: 'shortName',
      label: 'Name',
      filterable: true,
      cellTemplate: 'PscsRouteCellTemplate',
      alignment: 'left',
      editorOptions: {
        to: 'DeliverableDetails',
        params: [
          { key: 'id', dataKey: 'id' },
        ],
      },
    }, {
      prop: 'description', label: 'Description', filterable: true, alignment: 'left',
    }, {
      prop: 'deliverableType', label: 'Deliverable Type', filterable: true, alignment: 'left',
    }, {
      prop: 'coordinator', label: 'Department Coordinator', filterable: true, alignment: 'left',
    }, {
      prop: 'startDate',
      label: 'From Start Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'endDate',
      label: 'To End Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left', width: '60px'
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left', width: '60px'
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullDeliverableRow: {
    id: null,
    versionId: null,
    shortName: null,
    deliverableType: null,
    description: null,
    coordinator: null,
    allDay: false,
    recurrenceRule: '',
    startDate: getTodayLocal(),
    endDate: getTomorrowLocal(),
    contractGuid: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  eventTableKey: -999,
  currentEvent: null,
  eventTableData: [],
  eventTableConfig: {
    columns: [{
      prop: 'versionId', label: 'Version', filterable: true, alignment: 'left',
    }, {
      prop: 'eventType', label: 'Event Type', filterable: true, alignment: 'left',
    }, {
      prop: 'eventDate', label: 'Event Date', filterable: true, dataType: 'date', alignment: 'left',
    }, {
      prop: 'description', label: 'Description', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullEventTableRow: {
    id: null,
    versionId: null,
    eventType: null,
    eventDate: null,
    description: null,
    contractGuid: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },

  currentAttachment: null,
  attachmentTableData: [],
  attachmentTableConfig: {
    columns: [{
      prop: 'displayName', label: 'Name', filterable: true, alignment: 'left',
    }, {
      prop: 'fileSize', label: 'File Size', filterable: true, alignment: 'right',
    }, {
      prop: 'fileType', label: 'File Type', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullAttachmentTableRow: {
    id: null,
    displayName: null,
    fileName: null,
    fileSize: null,
    fileType: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchDeliverableTypeList', null, { root: true });
    dispatch('lookup/fetchEventTypeList', null, { root: true });
    dispatch('lookup/fetchCompanyList', null, { root: true });
    dispatch('lookup/fetchContractTypeList', null, { root: true });
    dispatch('lookup/fetchContractStateTypeList', null, { root: true });
    dispatch('lookup/fetchContractCoordinatorList', null, { root: true });
    dispatch('lookup/fetchCurrencyList', null, { root: true });
    dispatch('lookup/fetchPaymentTermList', null, { root: true });
  },
  resetContract({ commit }) {
    commit('resetContract');
  },
  resetDeliverableTable({ commit }) {
    commit('resetDeliverableTable');
  },
  resetEventTable({ commit }) {
    commit('resetEventTable');
  },
  resetAttachmentTable({ commit }) {
    commit('resetAttachmentTable');
  },
  loadHighLevelContractDetails({ commit, dispatch }, contractGuid) {
    CMS_API.get(`/contracts/${contractGuid}`).then(({ data }) => {
      commit('updateContract', data);
      dispatch('loadDeliverableTableData');
      dispatch('loadEventTableData');
      dispatch('loadAttachmentTableData');
    }).catch((err) => {
      this.$notify('Failed to Retrieve Contract', 'error');
    });
  },
  async fetchContractAuditLogs({ commit, state }) {
    const { data } = await CMS_API.get(`/contracts/${state.contract.guid}/audit-logs`);
    let auditLogs = [];
    if (data) {

      const timeZoneName = dateStore.getTimeZone();
        
      data?.contractAuditLogs?.forEach((d, idx) => {
        d.timestamp = d.timestamp ? dateStore.toLocalFromDate(d.timestamp, timeZoneName) : null;
      });
      
      auditLogs = data.contractAuditLogs;
    }

    return auditLogs;
  },
  loadDeliverableTableData({ commit }) {
    CMS_API.get(`/contracts/${state.contract.guid}/deliverables`).then(({ data }) => {
      
      const timeZoneName = dateStore.getTimeZone();
        
      data?.deliverables?.forEach((d, idx) => {
        d.startDate = dateStore.toLocalFromDate(d.startDate, timeZoneName);
        d.endDate = d.endDate ? dateStore.toLocalFromDate(d.endDate, timeZoneName) : null;
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });
      
      commit('loadDeliverableTableData', data.deliverables);
    }).catch((err) => {
      this.$notify('Failed to Load Deliverables', 'error');
    });
  },
  loadEventTableData({ commit, state }) {
    CMS_API.get(`/contracts/${state.contract.guid}/events`).then(({ data }) => {
      
      const timeZoneName = dateStore.getTimeZone();
        
      data?.events?.forEach((d, idx) => {
        d.eventDate = dateStore.toLocalFromDate(d.eventDate, timeZoneName);
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });
      
      commit('loadEventTableData', data.events);
    }).catch((err) => {
      this.$notify('Failed to Load Events', 'error');
    });
  },
  loadAttachmentTableData({ commit, state }) {
    STRUCTURES_API.get(`/attachments/contract/referenceId/${state.contract.id}`).then(({ data }) => {
      
      const timeZoneName = dateStore.getTimeZone();
        
      data?.forEach((d, idx) => {
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });
      
      commit('loadAttachmentTableData', data);
    }).catch((err) => {
      this.$notify('Failed to Load Attachments', 'error');
    });
  },
  createDeliverableTableRow({ commit }) {
    commit('createDeliverableTableRow');
  },
  createEventTableRow({ commit }) {
    commit('createEventTableRow');
  },
  updateContract({ dispatch, commit }, contract) {
    CMS_API.put(`/contracts/${contract.guid}`, contract).then(({ data }) => {
      commit('updateContract', data);
      this.$notify(`${data.shortName} Updated`, 'success');
      
      dispatch('loadDeliverableTableData');
      dispatch('loadEventTableData');
      dispatch('loadAttachmentTableData');
      
    }).catch((err) => {
      this.$notify(`Failed to Update ${contract.shortName}`, 'error');
    });
  },
  deleteContract({ dispatch, commit, state }) {
    CMS_API.delete(`/contracts/${state.contract.guid}`).then(({ data }) => {
      state.contract.status = 'Inactive';
      this.$notify('Contract Inactivated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Contract', 'error');
    });
  },

  postDeliverable({ state, commit }, deliverable) {
    CMS_API.post(`/contracts/${state.contract.guid}/deliverables`, deliverable).then(({ data }) => {
      state.deliverableTableData.push(data);
      commit('currentDeliverableTableRowChange', data);
      this.$notify('Deliverable Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Deliverable', 'error');
    });
  },
  updateDeliverable({ commit }, deliverable) {
    CMS_API.put(`/contracts/${state.contract.guid}/deliverables/${deliverable.id}`, deliverable).then(({ data }) => {
      commit('updateDeliverable', data);
      this.$notify(`${data.shortName} Updated`, 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Deliverable ', 'error');
    });
  },
  deleteDeliverableTableRow({ dispatch, commit, state }) {
    CMS_API.delete(`/contracts/${state.contract.guid}/deliverables/${state.currentDeliverable.id}`).then(({ data }) => {
      commit('deleteDeliverableTableRow');
      dispatch('currentDeliverableTableRowChange', clone(state.nullDeliverableRow));
      this.$notify('Deliverable Deleted', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Deliverable', 'error');
    });
  },
  currentDeliverableTableRowChange({ commit }, currentRow) {
    commit('currentDeliverableTableRowChange', currentRow);
  },
  postEvent({ state }, event) {
    CMS_API.post(`/contracts/${state.contract.guid}/events`, event).then(({ data }) => {
      state.eventTableData.push(data);
      this.$notify('Event Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Event', 'error');
    });
  },
  updateEvent({ commit }, event) {
    CMS_API.put(`/contracts/${state.contract.guid}/events/${event.id}`, event).then(({ data }) => {
      commit('updateEvent', data);
      this.$notify('Event Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Event', 'error');
    });
  },
  deleteEventTableRow({ dispatch, commit, state }) {
    CMS_API.delete(`/contracts/${state.contract.guid}/events/${state.currentEvent.id}`).then(({ data }) => {
      commit('deleteEventTableRow');
      dispatch('currentEventTableRowChange', clone(state.nullEventTableRow));
      this.$notify('Event Deleted', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Event', 'error');
    });
  },
  currentEventTableRowChange({ commit }, currentRow) {
    commit('currentEventTableRowChange', currentRow);
  },
  updateContractAttachmentDisplayName({ dispatch, commit, state }, updatedAttachment) {
    STRUCTURES_API.put(`/attachments/contract/${state.currentAttachment.id}`, updatedAttachment).then(({ data }) => {
      commit('setAttachmentDisplayName', updatedAttachment);
      this.$notify('Attachment Name Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Attachment Name', 'error');
    });
  },
  deleteAttachmentTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/attachments/contract/${state.currentAttachment.id}`).then(({ data }) => {
      commit('deleteAttachmentTableRow');
      dispatch('currentAttachmentTableRowChange', null);
      this.$notify('Attachment Deleted', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Attachment', 'error');
    });
  },
  viewAttachmentTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.get(`/attachments/contract/attachmentId/${state.currentAttachment.id}`).then(({ data }) => {
      // reconstruct file contents from byte array
      const byteCharacters = atob(data.fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // create file from blob
      const blob = new Blob([byteArray], { type: data.fileType });
      const link = document.createElement('a');
      const URL = window.URL || window.webkitURL;
      const { fileName } = data;

      link.target = '_blank';
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);

      link.click();

      // cleanup: remove element and revoke object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      this.$notify(`Downloaded ${data.fileName}`, 'success');
    }).catch((err) => {
      this.$notify('Attachment Failed to Download', 'error');
    });
  },
  currentAttachmentTableRowChange({ commit }, currentRow) {
    commit('currentAttachmentTableRowChange', currentRow);
  },
};

const mutations = {
  updateDeliverable(state, deliverable) {
    const rowIndex = state.deliverableTableData.findIndex(({ id }) => id === deliverable.id);
    state.deliverableTableData.splice(rowIndex, 1, deliverable);
  },
  currentDeliverableTableRowChange(state, currentRow) {
    state.currentDeliverable = currentRow;
  },
  updateEvent(state, event) {
    const rowIndex = state.eventTableData.findIndex(({ id }) => id === event.id);
    state.eventTableData.splice(rowIndex, 1, event);
  },
  currentEventTableRowChange(state, currentRow) {
    state.currentEvent = currentRow;
  },
  currentAttachmentTableRowChange(state, currentRow) {
    state.currentAttachment = currentRow;
  },
  updateContract(state, contract) {
    const createdDate = moment(contract.createdDate);
    const updatedDate = moment(contract.updatedDate);

    contract.createdBy = `${contract.createdBy.toUpperCase()} - ${createdDate.format('MM/DD/YYYY, hh:mm A')}`;
    contract.updatedBy = `${contract.updatedBy.toUpperCase()} - ${updatedDate.format('MM/DD/YYYY, hh:mm A')}`;

    state.contract = contract;
  },
  resetContract() {
    state.contract = {
      id: null,
      versionId: null,
      description: null,
      contractType: null,
      contractNumber: null,
      effectiveDate: null,
      terminationDate: null,
      company: null,
      counterParty: null,
      parentContract: null,
      currencyName: null,
      paymentTerm: null,
      status: null,
      activeFlag: true,
      createdBy: null,
      createdDate: null,
      updatedBy: null,
      updatedDate: null,
    };
  },
  resetDeliverableTable(state) {
    state.deliverableTableData = [];
    state.currentDeliverable = null;
  },
  resetEventTable(state) {
    state.eventTableData = [];
    state.currentEvent = null;
  },
  resetAttachmentTable(state) {
    state.attachmentTableData = [];
    state.currentAttachment = null;
  },
  loadDeliverableTableData(state, deliverables) {
    state.deliverableTableData = deliverables;
  },
  loadEventTableData(state, events) {
    state.eventTableData = events;
  },
  loadAttachmentTableData(state, attachments) {
    state.attachmentTableData = attachments;
  },
  createDeliverableTableRow(state) {
    const deliverable = clone(state.nullDeliverableRow);
    deliverable.id = state.deliverableTableKey++;
    deliverable.contractGuid = state.contract.guid;
    deliverable.startDate = moment().startOf('hour');
    deliverable.endDate = moment().startOf('hour').add('30', 'minutes');
    deliverable.versionId = 1;
    state.currentDeliverable = deliverable;
  },
  deleteDeliverableTableRow(state) {
    state.deliverableTableData = state.deliverableTableData.filter((deliverable) => deliverable.id !== state.currentDeliverable.id);
  },
  createEventTableRow(state) {
    const newEvent = clone(state.nullEventTableRow);
    newEvent.id = state.eventTableKey++;
    newEvent.contractGuid = state.contract.guid;
    newEvent.versionId = 1;
    state.currentEvent = newEvent;
  },
  deleteEventTableRow(state) {
    state.eventTableData = state.eventTableData.filter((event) => event.id !== state.currentEvent.id);
  },
  setAttachmentDisplayName(state, updatedAttachment) {
    const rowIndex = state.attachmentTableData.findIndex(({ id }) => id === state.currentAttachment.id);
    state.attachmentTableData.splice(rowIndex, 1, updatedAttachment);
  },
  deleteAttachmentTableRow(state) {
    state.attachmentTableData = state.attachmentTableData.filter((attachment) => attachment.id !== state.currentAttachment.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};