import moment from 'moment';

export const findResources = (scToResources, scId) => {
  const filteredResources = scToResources.filter((sc) => sc.id === scId);
  return filteredResources.reduce((acc, { resources }) => ([...acc, ...resources]), []);
};

export const findResourcesByName = (scToResources, scName) => {
  const filteredResources = scToResources.filter((sc) => sc.shortName === scName);
  return filteredResources.reduce((acc, { resources }) => ([...acc, ...resources]), []);
};

export const resourceArrayToString = (scToResources, resourceIdArray, scId) => {
  const shortNameArr = [];
  const filteredResources = findResources(scToResources, scId);
  filteredResources.forEach(({ id, shortName }) => {
    if (resourceIdArray.includes(id)) shortNameArr.push(shortName);
  });
  return shortNameArr.join(',');
};

export const findShortName = (list, id) => list.find((i) => i.id === id).shortName;

export const convertCaisoStatementPublicationToNumberForOrdering = (publication) => {
  // First extract the number in the middle of the string.
  // If it's monthly, multiply the string by 56 so that it will always be larger than the daily files
  if (publication.toLowerCase().includes('_m')) return parseInt(publication.toLowerCase().replace('_m', '').replace('t_', ''), 10) * 56;
  return parseInt(publication.toLowerCase().replace('_bd', '').replace('t_', ''), 10);
};

export const orderCaisoStatementPublicationsDescending = (publications) => publications.sort((firstVal, nextVal) => {
  if (convertCaisoStatementPublicationToNumberForOrdering(firstVal.value) < convertCaisoStatementPublicationToNumberForOrdering(nextVal.value)) { return 1; }
  if (convertCaisoStatementPublicationToNumberForOrdering(firstVal.value) > convertCaisoStatementPublicationToNumberForOrdering(nextVal.value)) { return -1; }
  return 0;
});

export const filterAndSortCaisoStatementFileTypesDescending = (filteredHeaders) => {
  const filteredFileTypes = filteredHeaders.map((x) => x.fileType).filter((x, i, a) => a.indexOf(x) === i && (x.includes('DAILY') || x.includes('SHADOW') || x.includes('MONTHLY') || x.includes('WHATIF') || x.includes('RC') || x.includes('ESTIMATED'))).map((f) => ({ value: f, label: f }));
  return filteredFileTypes.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
};

export const filterAndSortCaisoStatementVersionsDescending = (filteredHeaders, params) => {
  const data = filteredHeaders.map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));

  const filteredData = data.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (filteredData.length > 1) {
    if (params.selectedCoordinator === 'ALL' || !moment(params.startDateCompare).isSame(moment(params.endDateCompare))) { filteredData.unshift({ value: -1, label: 'MAX' }); }
  }

  return filteredData;
};