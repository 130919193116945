import { CISO_CMRI_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import config from './resourceLevelMovementConfigs';

const state = {
  tableData: [],
  tableConfig: config.fields,
};

const actions = {
  async fetchResourceLevelMovement({ commit }, args) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      scs: args.coordinatorListSelected,
      marketType: args.marketTypesSelected,
      resources: args.resourceListSelected ? args.resourceListSelected.join(',') : null,
    };
    try {
      const { data } = await CISO_CMRI_API.get('/resource-level-movement', { params });
      data.forEach((obj) => {
        obj.tzTradingDate = getMomentDateString(obj.tzTradingDate);
      });
      commit('setTableData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Retrieve Resource Level Movement', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  }
};

const mutations = {
  setTableData(state, tableData) {
    state.tableData = Object.freeze(tableData);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};