import { accessRight, caiso } from '@/auth/permission';

export default {
  path: '/ciso',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'CAISO',
    permission: { name: caiso.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: '/ads',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'ADS',
      permission: { name: caiso.ads.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'dispatch',
      name: 'Dispatch',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Dispatches',
        permission: { name: caiso.ads.dispatches, rights: [accessRight.visible] },
        requiredTimeZone: 'PPT',
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/ADS/Dispatches/Dispatch'),
    }],
  }, {
    path: '/cmri',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'CMRI',
      permission: { name: caiso.cmri.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'contract-usage',
      name: 'Contract Usage',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Contract Usage',
        permission: { name: caiso.cmri.contractUsage, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/ContractUsage/ContractUsage'),
    }, {
      path: 'expected-energy',
      name: 'Expected Energy',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Expected Energy',
        permission: { name: caiso.cmri.expectedEnergy, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/ExpectedEnergy/ExpectedEnergy'),
    }, {
      path: 'Virtual-bid-awards',
      name: 'Virtual Bid Awards',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Virtual Bid Awards',
        permission: { name: caiso.cmri.convergenceBidAwards, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/ConvergenceBidAwards/ConvergenceBidAwards'),
    }, {
      path: 'market-schedules',
      name: 'MarketSchedules',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Market Schedules',
        permission: { name: caiso.cmri.marketSchedules, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/MarketSchedules/MarketSchedules'),
    }, {
      path: 'market-awards',
      name: 'Market Awards',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Market Awards',
        permission: { name: caiso.cmri.marketAwards, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/MarketAwards/MarketAwards'),
    }, {
      path: 'resource-forecast',
      name: 'Resource Forecast',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Resource Forecast',
        permission: { name: caiso.cmri.resourceForecast, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/ResourceForecast/ResourceForecast'),
    }, {
      path: 'commitment-cost',
      name: 'Commitment Cost',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Commitment Cost',
        permission: { name: caiso.cmri.commitmentCost, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/CommitmentCost/CommitmentCost'),
    }, {
      path: 'non-dispatchable-time',
      name: 'Non-Dispatchable Time',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Non-Dispatchable Time',
        permission: { name: caiso.cmri.nonDispatchableTime, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/NonDispatchableTime/NonDispatchableTime'),
    }, {
      path: 'ver-forecast',
      name: 'VER Forecast',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'VER Forecast',
        permission: { name: caiso.cmri.verForecast, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/VERForecast/VERForecast'),
    }, {
      path: 'resource-level-movement',
      name: 'Resource Level Movement',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Resource Level Movement',
        permission: { name: caiso.cmri.resourceLevelMovement, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/ResourceLevelMovement/ResourceLevelMovement'),
    }, {
      path: 'crr',
      name: 'CRR',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Congestion Revenue Rights',
        permission: { name: caiso.cmri.crrReport, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CMRI/CRR/CongestionRevenueRights'),
    }],
  }, {
    path: '/MRIS',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'MRI-S',
      permission: { name: caiso.samc.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'loaded-statements',
      name: 'Loaded Statements',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Loaded Statements',
        permission: { name: caiso.samc.loadedStatements, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/LoadedStatements/LoadedStatements'),
    }, {
      path: 'monthly-daily-details',
      name: 'Monthly Daily Details',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Monthly Daily Details',
        permission: { name: caiso.samc.monthlyDailyDetail, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/MonthlyDailyDetails/MonthlyDailyDetails'),
    }, {
      path: 'charge-code-by-baa',
      name: 'Charge Code by BAA',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Charge Code by BAA',
        permission: { name: caiso.samc.chargeCodeByBAA, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ChargeCodeByBAA/ChargeCodeByBAA'),
    }, {
      path: 'invoices',
      name: 'Invoices',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Invoices',
        permission: { name: caiso.samc.invoices, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/Invoices/Invoices'),
    }, {
      path: 'invoices-summary',
      name: 'InvoicesSummary',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Invoices Summary',
        permission: { name: caiso.samc.invoicesSummary, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/InvoicesSummary/InvoicesSummary'),
    }, {
      path: 'settlement-cycle-summary',
      name: 'SettlementCycleSummary',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Settlement Cycle Summary',
        permission: { name: caiso.samc.settlementCycleSummary, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/SettlementCycleSummary/SettlementCycleSummary'),
    }, {
      path: 'meter-component',
      name: 'MeterComponent',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Meter Component',
        permission: { name: caiso.samc.meterComponent, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/MeterComponent/MeterComponent'),
    }, {
      path: 'meter-stmt-compare',
      name: 'MeterStmtCompare',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Meter and Statement Comparison',
        permission: { name: caiso.samc.meterStmtCompare, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/MeterStmtCompare/MeterStmtCompare'),
    }, {
      path: 'charge-code-details',
      name: 'ChargeCodeDetails',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Charge Code Details',
        permission: { name: caiso.samc.chargeCodeDetails, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ChargeCodeDetails/ChargeCodeDetails'),
    }, {
      path: 'eim-sub-allocation',
      name: 'EIMSubAllocation',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Sub Allocations',
        permission: { name: caiso.samc.eimSubAllocation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/EIMSubAllocation/EIMSubAllocation'),
    }, {
      path: 'eim-sub-allocation-template-overrides',
      name: 'EimSubAllocationTemplateOverrides',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'BD Template Overrides',
        permission: { name: caiso.samc.eimSubAllocation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/EimSubAllocationTemplateOverrides/eimSubAllocationTemplate'),
    }, {
      path: 'statement-details',
      name: 'StatementDetails',
      meta: {
        showCert: true,
        visible: true,
        rank: 1,
        requiresAuth: true,
        text: 'Statement Details',
        permission: { name: caiso.samc.statementDetails, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/StatementDetails/StatementDetails'),
    }, {
      path: 'determinant-summary',
      name: 'DeterminantSummary',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Determinant Summary',
        permission: { name: caiso.samc.determinantSummary, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/DeterminantSummary/DeterminantSummary'),
    }, {
      path: 'determinant-details',
      name: 'DeterminantDetails',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Determinant Details',
        permission: { name: caiso.samc.determinantSummary, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/DeterminantDetails/DeterminantDetails'),
    }, {
      path: 'determinant-details-fulldrill',
      name: 'DeterminantDetailsFullDrill',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Determinant Details Resource Drill',
        permission: { name: caiso.samc.determinantSummary, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/DeterminantDetailsFullDrill/DeterminantDetailsFullDrill'),
    }, {
      path: 'charge-code-categories',
      name: 'ChargeCodeCategories',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Charge Code Categories',
        permission: { name: caiso.samc.chargeCodeCategories, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ChargeCodeCategories/ChargeCodeCategories'),
    },
    {
      path: 'eim-allocation-validation-details',
      name: 'EIMAllocationValidationDetails',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Allocation Validation Details',
        permission: { name: caiso.samc.eimSubAllocationValidation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      // eslint-disable-next-line max-len
      component: () => import('@/components/Ciso/SaMC/EimAllocationValidationDetails/EimAllocationValidationDetails'),
    },
    // {
    //   path: 'determinant-tree',
    //   name: 'DeterminantTree',
    //   meta: {
    //     showCert: true,
    //     visible: true,
    //     requiresAuth: true,
    //     text: 'Determinant Tree',
    //     permission: { name: caiso.samc.configurationFile, rights: [accessRight.visible] },
    //     customFormatEnabled: true,
    //   },
    //   component: () => import('@/components/Ciso/SaMC/DeterminantTree/DeterminantTree'),
    // },
    {
      path: 'configuration-file',
      name: 'ConfigurationFile',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Configuration File',
        permission: { name: caiso.samc.configurationFile, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ConfigurationFile/ConfigurationFile'),
    }, {
      path: 'view-charge-details',
      name: 'ViewChargeDetails',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'View Charge Details',
        permission: { name: caiso.samc.statementDetails, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ViewChargeDetails/ViewChargeDetails'),
    }, {
      path: 'eim-allocation-charge-code-details',
      name: 'EIMAllocationChargeCodeDetails',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'EIM Allocation Charge Code Details',
        permission: { name: caiso.samc.eimSubAllocation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/EIMAllocationChargeCodeDetails/EIMAllocationChargeCodeDetails'),
    }, {
      path: 'eim-allocation-determinant-details',
      name: 'EIMAllocationDeterminantDetails',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'EIM Allocation Determinant Details',
        permission: { name: caiso.samc.eimSubAllocation, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/EIMAllocationDeterminantDetails/EIMAllocationDeterminantDetails'),
    }, {
      path: 'disputes',
      name: 'Disputes',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Disputes',
        permission: { name: caiso.samc.disputes, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/SaMC/Disputes/Disputes'),
    }, {
      path: 'disputes-form',
      name: 'DisputesForm',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Disputes Form',
        permission: { name: caiso.samc.disputes, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/SaMC/Disputes/Forms/DisputesForm'),
    }, {
      path: 'dynamic-etsr',
      name: 'Dynamic Etsr',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Dynamic ETSR Compare',
        permission: { name: caiso.ads.dynamicEtsr, rights: [accessRight.visible] },
        requiredTimeZone: 'PPT',
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/DynamicEtsr/DynamicEtsr'),
    }, {
      path: 'compare-prices',
      name: 'ComparePrices',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Compare Prices',
        permission: { name: caiso.samc.comparePrices, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/ComparePrices/ComparePrices'),
    }, {
      path: 'what-if-builder',
      name: 'WhatIfBuilder',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'What-If Builder',
        permission: { name: caiso.samc.whatif, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/SaMC/WhatIfBuilder/WhatIfBuilder'),
    }, {
      path: 'tag-settlements',
      name: 'TagSettlements',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Tag Settlements',
        permission: { name: caiso.samc.tagSettlements, rights: [accessRight.visible] },
        customFormatEnabled: true,
        requiredTimeZone: 'PPT',
      },
      component: () => import('@/components/Ciso/SaMC/TagSettlements/TagSettlements'),
    }, {
      path: 'tag-settlements-vs-cmri',
      name: 'TagSettlementsVsCMRI',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Tag Settlements Vs CMRI',
        permission: { name: caiso.samc.tagSettlementsVsCmri, rights: [accessRight.visible] },
        customFormatEnabled: true,
        requiredTimeZone: 'PPT',
      },
      component: () => import('@/components/Ciso/SaMC/TagSettlementsVsCMRI/TagSettlementsVsCMRI'),
    }, {
      path: 'tag-settlements-mrtu-vs-cmri',
      name: 'TagSettlementsMRTUVsCMRI',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Tag Settlements MRTU Vs CMRI',
        permission: { name: caiso.samc.tagSettlementsVsCmri, rights: ['visible'] },
        featureFlag: 'etag:snapshot:mrtu',
        customFormatEnabled: true,
        requiredTimeZone: 'PPT',
      },
      component: () => import('@/components/Ciso/SaMC/TagSettlementsMRTUVsCMRI/TagSettlementsMRTUVsCMRI'),
    }, {
      path: 'tag-settlements-MRTU',
      name: 'TagSettlementsMRTU',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Tag Settlements MRTU',
        permission: { name: caiso.samc.tagSettlements, rights: [accessRight.visible] },
        featureFlag: 'etag:snapshot:mrtu',
        customFormatEnabled: true,
        requiredTimeZone: 'PPT',
      },
      component: () => import('@/components/Ciso/SaMC/TagSettlementsMRTU/TagSettlementsMRTU'),
    }, {
      path: 'settlement-review',
      name: 'SettlementReview',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Settlement Review',
        permission: { name: caiso.samc.settlementReview, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/SettlementReview/SettlementReview'),
    }, {
      path: 'settlement-review-calendar',
      name: 'SettlementReviewCalendar',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Settlement Review Calendar',
        permission: { name: caiso.samc.settlementReviewCalendar, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/SettlementReviewCalendar/SettlementReviewCalendar'),
    }, {
      path: 'etsr-statements',
      name: 'EtsrStatements',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'ETSR Statements',
        permission: { name: caiso.samc.etsrStatements, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/EtsrStatements/EtsrStatements'),
    }, {
      path: 'operational-adjustments',
      name: 'OperationalAdjustments',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Operational Adjustments',
        permission: { name: caiso.samc.operationalAdjustments, rights: [accessRight.write] },
      },
      component: () => import('@/components/Ciso/SaMC/OperationalAdjustments/OperationalAdjustments'),
    }, {
      path: 'state-alloc-reference',
      name: 'StateAllocReference',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'State Allocation Reference',
        permission: { name: caiso.samc.stateAllocRef, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/SaMC/StateAllocReference/StateAllocationReference'),
    }, {
      path: 'tag-logs',
      name: 'TagSettlementsLog',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Tag Settlements Log',
        permission: { name: caiso.samc.tagSettlementsLogging, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SaMC/TagSettlementsLog/TagSettlementsLog'),
    },

    ],
  }, {
    path: '/oasis',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'OASIS',
      permission: { name: caiso.oasis.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'e-i-m-transfer-tie',
      name: 'EIM Transfer Tie',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Transfer Tie',
        permission: { name: caiso.oasis.eimTransferTie, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OASIS/EIMTransferTie/EIMTransferTie'),
    }, {
      path: 'elap-prices',
      name: 'ELAP Prices',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'ELAP Prices',
        permission: { name: caiso.oasis.elapPrices, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OASIS/ELAPPrices/ELAPPrices'),
    }, {
      path: 'trans-tie-report',
      name: 'TransTieReport',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Trans Tie Report',
        permission: { name: caiso.oasis.transTieReport, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OASIS/TransTieReport/TransTieReport'),
    }, {
      path: 'e-i-m-demand-forecast',
      name: 'EIMDemandForecast',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Demand Forecast',
        permission: { name: caiso.oasis.eimDemandForecast, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OASIS/EIMDemandForecast/EIMDemandForecast'),
    }, {
      path: 'sufficiency-evaluation-forecast',
      name: 'SufficiencyEvaluationForecast',
      meta: {
        showCert: true,
        visible: false, // set to invisible for now because deprecated, being replaced with above screen, though we're keeping the code incase a client asks for the screen.
        requiresAuth: true,
        text: 'Sufficiency Evaluation Forecast',
        permission: { name: caiso.oasis.sufficiencyEvaluationForecast, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OASIS/SufficiencyEvaluationForecast/SufficiencyEvaluationForecast'),
    }, {
      path: 'oasis-prices',
      name: 'OasisPrices',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'OASIS Prices',
        permission: { name: caiso.oasis.prices, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/OASIS/OasisPrices/OasisPrices'),
    }],
  }, {
    path: '/sibr',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'SIBR',
      permission: { name: caiso.sibr.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'sibr-trades',
      name: 'SibrTrades',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Trades',
        permission: { name: caiso.sibr.trades, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SIBR/Trades/Trades'),
    },
    {
      path: 'sibr-bids',
      name: 'SibrBids',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Bids',
        permission: { name: caiso.sibr.bids, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SIBR/Bids/Bids'),
    }, {
      path: 'sibr-Virtualbids',
      name: 'SibrVirtualBids',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Virtual Bids',
        permission: { name: caiso.sibr.cbbids, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/SIBR/CBBids/CBBids'),
    }],
  }, {
    path: '/bsap',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'BSAP',
      permission: { name: caiso.bsap.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'base-schedules',
      name: 'BaseSchedules',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Base Schedules',
        permission: { name: caiso.bsap.baseSchedules, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/BSAP/BaseSchedules/BaseSchedules'),
    }, {
      path: 'balancing-tests',
      name: 'BalancingTests',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Balancing Tests',
        permission: { name: caiso.bsap.balancingTests, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/BSAP/BalancingTests/BalancingTests'),
    }],
  }, {
    path: '/master-file',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'MASTER FILE',
      permission: { name: caiso.masterFile.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'general',
      name: 'General',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'General',
        permission: { name: caiso.masterFile.resources, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/MasterFile/MasterFile/MasterFile'),
    }],
  }, {
    path: '/meter',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'METER',
      permission: { name: caiso.meter.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'meter',
      name: 'meter',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Meter',
        permission: { name: caiso.meter.read, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/Meter/MrisMeter/MrisMeter'),
    },
    {
      path: 'mris-submit',
      name: 'mris-submit',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Submission',
        permission: { name: caiso.meter.submissions, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisSubmission/MrisSubmission'),
    },
    {
      path: 'mris-pre-submit',
      name: 'mris-pre-submit',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Submission Pre-Validation',
        permission: { name: caiso.meter.preSubmission, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisPreSubmit/MrisPreSubmit'),
    },
    {
      path: 'mris-raw-meter',
      name: 'mris-raw-meter',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Submission Raw Meter Data',
        permission: { name: caiso.meter.preSubmission, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisRawMeter/MrisRawMeter'),
    },
    {
      path: 'mris-map-resource',
      name: 'mris-map-resource',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Map Resource',
        permission: { name: caiso.meter.meterSubmitMappings, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisMapResource/MrisMapResource'),
    },
    {
      path: 'mris-map-meter',
      name: 'mris-map-meter',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Map Meter',
        permission: { name: caiso.meter.meterSubmitMappings, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisMapMeter/MrisMapMeter'),
    },
    {
      path: 'mris-map-aggregate',
      name: 'mris-map-aggregate',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'MRI-S Map Aggregate',
        permission: { name: caiso.meter.meterSubmitMappings, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisMapAggregate/MrisMapAggregate'),
    },
    {
      path: 'mris-override',
      name: 'mris-override',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Meter Adjustment',
        permission: { name: caiso.meter.meterOverride, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Ciso/Meter/MrisOverride/MrisOverride'),
    }],
  }, {
    path: '/outages',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'OUTAGES',
      permission: { name: caiso.oms.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'outages',
      name: 'outages',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Outages',
        permission: { name: caiso.oms.outages, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OMS/Outages/Outages'),
    },
    {
      path: 'availability',
      name: 'availability',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Availability',
        permission: { name: caiso.oms.availability, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OMS/Availability/Availability'),
    }],
  }, {
    path: '/ra',
    meta: {
      visible: true,
      requiresAuth: true,
      text: 'RA',
      permission: { name: caiso.oms.raReport, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'supply-plan',
      name: 'RA Supply Plan',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'RA Supply Plan',
        permission: { name: caiso.oms.raReport, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/OMS/RA/raSupplyPlan'),
    },
    {
      path: 'cira-report',
      name: 'CIRA Report',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'CIRA Report',
        permission: { name: caiso.cira.ciraReport, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CIRA/CIRAReport/CIRAReport'),
    }, {
      path: 'cira-precalc-report',
      name: 'CIRA Pre Calc Report',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'CIRA Pre Calc Report',
        permission: { name: caiso.cira.ciraPreCalcReport, rights: [accessRight.visible] },
        customFormatEnabled: true,
      },
      component: () => import('@/components/Ciso/CIRA/CIRAPreCalcReport/CIRAPreCalcReport'),
    }],
  }, {
    path: '/tools',
    meta: {
      showCert: true,
      visible: true,
      requiresAuth: true,
      text: 'TOOLS',
      permission: { name: caiso.tools.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'event-log',
      name: 'EventLog',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Event Log',
        permission: { name: caiso.tools.eventLog, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Admin/EventLog/eventLog'),
    }, {
      path: 'create-a-report',
      name: 'CreateAReport',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Create a Report',
        permission: { name: caiso.tools.createAReport.menu, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/CreateAReport/CreateAReport'),
    }, {
      path: '/Calculations',
      name: 'Calculations',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Calculations',
        permission: { name: caiso.tools.calculations, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/Calculations/Calculations'),
    }, {
      path: '/ISO-Downloader',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        permission: { name: caiso.tools.isoDownloader, rights: [accessRight.visible] },
        text: 'ISO Downloader',
      },
      name: 'ISODownloader',
      component: () => import('@/components/Tools/ISODownloader/ISODownloader'),
    }, {
      path: 'eim-suballocation-mapping',
      name: 'EimSuballocationMapping',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Suballocation Mapping',
        permission: { name: caiso.tools.eimSuballocationMapping, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/Monitor/EimSuballocationMapping/EimSuballocationMapping'),
    }, {
      path: 'resource-viewer',
      name: 'ResourceViewer',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Resource Viewer',
        permission: { name: caiso.tools.resourceViewer, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/Monitor/ResourceViewer/ResourceViewer'),
    }, {
      path: 'EIMTcPseMap',
      name: 'EIM Tc Pse Map',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'EIM Tc Pse Map',
        permission: { name: caiso.tools.eimTcPseMapping, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/EimTcPseMap/EIMTcPseMap'),
    }, {
      path: 'server-queue',
      name: 'ServerQueue',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Server Queue',
        permission: { name: caiso.tools.serverQueue, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ServerQueue/ServerQueue'),
    }, {
      path: 'issue-tracking',
      name: 'IssueTracking',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Issue Tracking',
        permission: { name: caiso.tools.issueTracking, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/IssueTracking/IssueTracking'),
    }, {
      path: 'issue-tracking-form',
      name: 'IssueTrackingForm',
      meta: {
        showCert: true,
        visible: false,
        requiresAuth: true,
        text: 'Issue Tracking Form',
        permission: { name: caiso.tools.issueTracking, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/IssueTracking/Forms/IssueTrackingForm'),
    }, {
      path: 'report-status',
      name: 'ReportStatus',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Report Status',
        permission: { name: caiso.tools.reportStatus, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ReportStatus/ReportStatus'),
    }, {
      path: 'shadow-sources',
      name: 'ShadowSources',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Shadow Sources',
        permission: { name: caiso.tools.shadowSources, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ShadowSources/ShadowSources'),
    }, {
      path: 'iso-environment',
      name: 'ISOEnvironment',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'ISO Environment',
        permission: { name: caiso.tools.isoEnvironment, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ISOEnvironment/ISOEnvironment'),
    }, {
      path: 'remove-data',
      name: 'RemoveData',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Remove Data',
        permission: { name: caiso.tools.removeData, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/RemoveData/RemoveData'),
    }, {
      path: 'resource-count',
      name: 'ResourceCount',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Resource Count',
        permission: { name: caiso.tools.resourceCount, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ResourceCount/ResourceCount'),
    }, {
      path: 'shadow-batch',
      name: 'ShadowBatch',
      meta: {
        showCert: true,
        visible: true,
        requiresAuth: true,
        text: 'Shadow Batch Run',
        permission: { name: caiso.tools.shadowSources, rights: [accessRight.visible] },
      },
      component: () => import('@/components/Tools/ShadowBatch/ShadowBatch'),
    }],
  },
  ],
};