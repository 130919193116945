import { RECS_MANAGEMENT_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  selectedSystemNames: null,
  selectedEntityNames: [],
  systemNameOptions: ['WREGIS'],
  organizationUserData: [],
  scs: [],

  organizationTableData: [],
  userTableData: [],
  organizationConfig: {
    name: 'recOrganizations',
    columns: [
      { prop: 'systemName', label: 'System Name', sortable: true, filterable: true, },
      { prop: 'name', label: 'Name', sortable: true, filterable: true, }, 
      { prop: 'status', label: 'Status', sortable: true, filterable: true, },
      { prop: 'effectiveDate', label: 'Effective Date', sortable: true, filterable: true, },
      { prop: 'organizationType', label: 'Organization Type', sortable: true, filterable: true, },
      { prop: 'organizationSubType', label: 'Organization SubType', sortable: true, filterable: true, },
      { prop: 'contact.name', label: 'Contact', sortable: true, filterable: true, },
      { prop: 'billingContact.name', label: 'Billing Contact', sortable: true, filterable: true, },
      { prop: 'accountType', label: 'Account Type', sortable: true, filterable: true, },
      { prop: 'subscriptionRenewalMonth', label: 'Subscription Renewal Month', sortable: true, filterable: true, },
      { prop: 'resourceType', label: 'Resource Type', sortable: true, filterable: true, },
      { prop: 'region', label: 'Region', sortable: true, filterable: true, },
      { prop: 'friendlyId', label: 'Friendly Id', sortable: true, filterable: true, },
      { prop: 'hasMarketAccounts', label: 'Market Accounts', sortable: true, filterable: true, visible: false },
      { prop: 'qre', label: 'Qre', sortable: true, filterable: true, visible: false },
      { prop: 'customerHasLoad', label: 'Customer Load', sortable: true, filterable: true, visible: false },
      { prop: 'mfa', label: 'Mfa', sortable: true, filterable: true, visible: false },
      { prop: 'canRegisterDggs', label: 'Register Dggs', sortable: true, filterable: true, visible: false },
      { prop: 'apiAccessByOrg', label: 'Api Access By Org', sortable: true, filterable: true, visible: false },
      { prop: 'isIesoServiceProvider', label: 'Ieso Service Provider', sortable: true, filterable: true, visible: false },
      { prop: 'isWregisThermalQre', label: 'Wregis Thermal Qre', sortable: true, filterable: true, visible: false },
      { prop: 'readOntarioRules', label: 'Read Ontario Rules', sortable: true, filterable: true, visible: false },
      { prop: 'ontarioCustomerAccount', label: 'Ontario Customer Account', sortable: true, filterable: true, visible: false }
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
    },
  },
  userConfig: {
    name: 'recUsers',
    columns: [
      { prop: 'firstName', label: 'First Name', sortable: true, filterable: true },
      { prop: 'lastName', label: 'Last Name', sortable: true, filterable: true },
      { prop: 'fullName', label: 'Full Name', sortable: true, filterable: true },
      { prop: 'email', label: 'Email', sortable: true, filterable: true },
      { prop: 'phone', label: 'Phone', sortable: true, filterable: true },
      { prop: 'userOrganizationStatus', label: 'User Org Status', sortable: true, filterable: true },
      { prop: 'currentOrganization', label: 'Current Org', sortable: true, filterable: true },
      { prop: 'signInCount', label: 'Sign In Count', sortable: true, filterable: true },
      { prop: 'isMfaEnabled', label: 'Is Mfa Enabled', sortable: true, filterable: true },
      { prop: 'mfa', label: 'Mfa', sortable: true, filterable: true },
      { prop: 'countryCode', label: 'Country Code', sortable: true, filterable: true, visible: false },
      { prop: 'adminModePermission', label: 'Admin Mode Permission', sortable: true, filterable: true, visible: false },
      { prop: 'loginType', label: 'Login Type', sortable: true, filterable: true, visible: false },
      { prop: 'regulator', label: 'Regulator', sortable: true, filterable: true, visible: false },
      { prop: 'reportingEntity', label: 'Reporting Entity', sortable: true, filterable: true, visible: false },
      { prop: 'superAdmin', label: 'Super Admin', sortable: true, filterable: true, visible: false },
      { prop: 'regionalAdmin', label: 'Regional Admin', sortable: true, filterable: true, visible: false },
      { prop: 'marketAdmin', label: 'Market Admin', sortable: true, filterable: true, visible: false },
      { prop: 'active', label: 'Active', sortable: true, filterable: true, visible: false },
      { prop: 'canEmulateAll', label: 'Can Emulate All', sortable: true, filterable: true, visible: false },
      { prop: 'adminMode', label: 'Admin Mode', sortable: true, filterable: true, visible: false },
    ],
    options: {
      columnConfig: true,
      filterHeader: true,
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async initialize({ commit, dispatch }) {
    commit('resetTable');
    await dispatch('fetchScs');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  organizationSelected({ commit }, currentRow) {
    commit('organizationSelected', currentRow);
  },
  async loadTableData({ dispatch, commit }) {
    commit('resetTable');
    await dispatch('loadOrganizationUserData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'COMPANY'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async loadOrganizationUserData({ state, commit }) {
    await Promise.all(state.selectedEntityNames.map( async (entityName) => {
      const params = {
        systemName: state.selectedSystemNames,
        entityName
      };
      await RECS_MANAGEMENT_API.get('/recs-management/organization/organizationUsers', { params }).then(({ data }) => {
        commit('addOrganizationUserData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Organizations', 'error');
      });
    }));
    this.$notify(`${state.organizationUserData.length} Organization Users Loaded`, 'info');
  },
  makeUniqueList(list) {
    let uniqueLists = Array.from(new Set(list.map(JSON.stringify))).map(JSON.parse);;
    return uniqueLists;
  }
};

const mutations = {
  resetTable(state) {
    state.organizationTableData = [];
    state.userTableData = [];
    state.organizationUserData = [];
  },
  setSelectedSystems(state, value) {
    state.selectedSystemNames = value;
  },
  setSelectedEntities(state, value) {
    state.selectedEntityNames = value;
  },
  addOrganizationUserData(state, organizationUsers) {
    state.organizationTableData = actions.makeUniqueList(state.organizationTableData.concat(organizationUsers.map((o) => o.organization)));
    state.organizationUserData = state.organizationUserData.concat(organizationUsers);
  },
  organizationSelected(state, currentRow) {
    state.userTableData = actions.makeUniqueList(state.organizationUserData.filter((o) => JSON.stringify(o.organization) === JSON.stringify(currentRow)).map((o) => o.user));
  },
  ...createMutations('scs'),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};