/*
GEN/TG
Hourly Parameters (1 Value per Hour)
 - BiddableCapacityLimit
 - BiddableCapacityKind
 - OffGridCharge
 - ResourceBidOption (RTM Only)

Daily Paramters (1 Value per Schedule)
 - NoLoadCost
 - EnergyMaxDay
 - EnergyMinDay
 - LowerChargeLimit
 - UpperChargeLimit
 - StateOfCharge
 - CurtailmentEnergyLimit
 - GreenHouseGasAdder
 - StartupRampTime
 - ContingencyAvailabilityFlag
 - MaxEohStateOfCharge
 - MinEohStateOfCharge

LOAD
Daily Paramters (1 Value Per Schedule)
 - ContingencyAvailabilityFlag

TIE
Daily Paramters (1 Value Per Schedule)
 - ContingencyAvailabilityFlag
 - MinHourlyBlock

Hourly Paramters (1 Value per Hour)
 - ResourceBidOption (RTM Only) */
const dailyParameterConfiguration = {
  columns: [{
    prop: 'noLoadCost', label: 'Min Load Cost', editable: true, dataType: 'number',
  }, {
    // Derived from Ramprates, not parameters. Caiso portal shows these so should we.
    label: 'Ramp Rates',
    columns: [{
      prop: 'operatingReserve', label: 'Op. Reserve', editable: true, dataType: 'number',
    }, {
      prop: 'regulating', label: 'Regulating', editable: true, dataType: 'number',
    }],
  }, {
    label: 'Energy Limits',
    columns: [{
      prop: 'energyMinDay', label: 'Min', editable: true, dataType: 'number',
    }, {
      prop: 'energyMaxDay', label: 'Max', editable: true, dataType: 'number',
    }],
  }, {
    label: 'Charge Limits',
    columns: [{
      prop: 'lowerChargeLimit', label: 'Min', editable: true, dataType: 'number',
    }, {
      prop: 'upperChargeLimit', label: 'Max', editable: true, dataType: 'number',
    }, {
      prop: 'stateOfCharge', label: 'Init', editable: true, dataType: 'number',
    }],
  }, {
    // In Caiso portal this is displayed in the Hourly Section, but from the xml it can only be submited daily.
    // Daily - meaning it's submitted by schedule. 1 value for DA, 24 values for RT.
    label: 'EOH State Of Charge',
    columns: [{
      prop: 'minEohStateOfCharge', label: 'Min', editable: true, dataType: 'number',
    }, {
      prop: 'maxEohStateOfCharge', label: 'Max', editable: true, dataType: 'number',
    }],
  }, {
    prop: 'curtailmentEnergyLimit', label: 'Curtailment Energy Limit', editable: true, dataType: 'number',
  }, {
    prop: 'greenHouseGasAdder', label: 'GHG Adder', editable: true, dataType: 'number',
  }, {
    prop: 'startupRampTime', label: 'Startup Ramp Time', editable: true, dataType: 'number',
  }, {
    prop: 'contingencyAvailabilityFlag',
    label: 'Contingency Avail Flag',
    editable: true,
    dataType: 'boolean',
    type: {
      name: 'PscsSelectCellRenderer',
      list: 'yesNo',
      placeholder: 'Option',
      clearable: true,
    },
  }, {
    // TIE Only
    prop: 'minHourlyBlock', label: 'Min Hourly Block', editable: true, dataType: 'number',
  }],
};
const hourlyParameterConfiguration = {
  columns: [
    {
      prop: 'capacityLimitType',
      label: 'Capacity Type',
      editable: true,
      width: 150,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'capacityTypes',
        placeholder: 'Option',
        clearable: true,
      },
    }, {
      prop: 'capacityLimit', label: 'Capacity Limit', editable: true, dataType: 'number',
    }, {
      prop: 'bidOptionType',
      label: 'Bid Option',
      width: 150,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'bidOptions',
        placeholder: 'Option',
        clearable: true,
      },
    }, {
      prop: 'offGridCharge',
      label: 'Off Grid Charge',
      editable: true,
      dataType: 'boolean',
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'yesNo',
        placeholder: 'Option',
        clearable: true,
      },
    }],
};

export { dailyParameterConfiguration, hourlyParameterConfiguration };
