import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_SOURCE_API, BILLING_REF_API } from '@/api';
import { sortBy } from '@/utils/dataUtil';
import config from './billingDeterminantDataConfigs';

const state = {
  contracts: [],
  customContracts: [],
  meters: [],
  tableData: [],
  tableConfig: config.fields,
};

const actions = {
  async fetchBillingDeterminantData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      contracts: parameters.contractsSelected,
      startDate,
      endDate,
    };
    try {
      const { data: { data } } = await BILLING_SOURCE_API.post('/billing-determinant/get', params);

      data.forEach((sourceData) => {
        sourceData.tradingDate = moment(sourceData.tradingDate).utc().format('MM/DD/YYYY');
      });

      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load generic billing determinant Data', 'error');
    }
  },
  async fetchContracts({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contracts');

      const filtered = data.filter((p) => String(p.name).startsWith('DR'));
      filtered.forEach((d, idx) => {
        d.value = d.name;
        d.label = d.name;
      });
      filtered.sort(sortBy('label'));
      commit('setCustomContracts', filtered);

      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.name;
      });
      data.sort(sortBy('label'));
      commit('setContracts', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load contracts', 'error');
    }
  },
  async fetchMeters({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/meter');
      const filtered = data
        .filter((p) => String(p.contractName).startsWith('DR'))
        .map((x) => x.premiseId).filter((x, i, a) => a.indexOf(x) === i)
        .map((f) => ({ value: f, label: f }));
      filtered.sort(sortBy('label'));

      commit('setMeters', filtered);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load contracts', 'error');
    }
  },
  async importBillingDeterminantData({ commit, state }, billingDeterminantDetails) {
    const importBDData = { Data: billingDeterminantDetails };
    try {
      await BILLING_SOURCE_API.post('/billing-determinant/upload', importBDData);
      this.$notify('Source Billing Determinant Data Imported', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Import Source Billing Determinant Data', 'error');
    }
  },
  async deleteBillingDeterminantData({ commit, state }, selectedData) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(selectedData.selectedDeleteDates);
    const params = {
      contracts: selectedData.selectedContracts,
      startDate,
      endDate,
    };
    try {
      await BILLING_SOURCE_API.post('/billing-determinant/delete', params);
      this.$notify('Source Billing Determinant Data Deleted', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Delete Source Billing Determinant Data', 'error');
    }
  },
  async importGroupData({ commit, state }, billingDeterminantDetails) {
    const importBDData = { Data: billingDeterminantDetails };
    try {
      await BILLING_SOURCE_API.post('/billing-determinant/upload/group/', importBDData);
      this.$notify('Data Imported', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Import Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setContracts(state, value) {
    state.contracts = value;
  },
  setCustomContracts(state, value) {
    state.customContracts = value;
  },
  setMeters(state, value) {
    state.meters = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};