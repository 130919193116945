import helpers from '@/utils/helpers';

export function mapToOptions(items) {
  return items.map((i) => ({ value: i.id, label: i.shortName }));
}

export function findResource(items, scId, rsrcId) {
  if (scId && rsrcId) {
    const rsrcs = items.find(({ id }) => id === scId).resources;
    const rs = rsrcs.find((r) => r.value === rsrcId);
    return rs.label;
  }
  return null;
}

function findMatchingResourceByIds(resources, arrayToMatch) {
  const result = [];
  for (let i = 0; i < arrayToMatch.length; i++) {
    const item = resources.find((o) => o.id === arrayToMatch[i].id);
    if (item) { result.push(item); }
  }
  return result;
}

export function findResources(bscToResources, bscId, scToResources, scId, resourceGroups, resourceGroupId, selectedResourceType) {
  let resources = [];
  for (let i = 0; i < scToResources.length; i++) {
    if (scId && scId !== scToResources[i].id) {
      continue;
    }
    for (let j = 0; j < scToResources[i].resources.length; j++) {
      resources.push(scToResources[i].resources[j]);
    }
  }

  if (bscId && bscToResources && bscToResources.length > 0) {
    const bsc = bscToResources.find(({ id }) => id === bscId);
    resources = findMatchingResourceByIds(resources, bsc.resources);
  }

  if (resourceGroupId) {
    const group = resourceGroups.find(({ id }) => id === resourceGroupId);
    resources = findMatchingResourceByIds(resources, group.resources);
  }

  if (selectedResourceType) {
    resources = resources.filter((o) => o.resourceType === selectedResourceType);
  }

  if (resources && resources.length > 0) return helpers.sortBy(mapToOptions(resources), (p) => p.label);
  return [];
}
