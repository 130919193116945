const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  configData: {
    fields: [
      {
        caption: 'BD_ORDER',
        dataField: 'orderNum',
        dataType: 'number',
        area: 'row',
        areaIndex: 1,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_NAME',
        dataField: 'bdName',
        dataType: 'string',
        area: 'row',
        areaIndex: 3,
        expanded: true,
        visible: true,
      },
      {
        caption: 'BPM_NAME',
        dataField: 'bpmName',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'BD_GROUP',
        dataField: 'bdGroup',
        dataType: 'string',
        area: 'row',
        areaIndex: 2,
        expanded: true,
        visible: true,
      },
      {
        caption: 'COMPARE',
        dataField: 'compareId',
        dataType: 'string',
        area: 'row',
        expanded: true,
        areaIndex: '999',
      },
      {
        caption: 'COMPARE_TYPE',
        dataField: 'compareType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
        areaIndex: '999',
      },
      {
        caption: 'TRADING_DATE',
        dataField: 'tzTradingDate',
        dataType: 'datetime',
        area: 'row',
        areaIndex: 0,
        visible: true,
        expanded: true,
      },
      {
        caption: 'RSRC_ID',
        dataField: 'rsrcId',
        area: 'column',
      },
      {
        caption: 'VALUE',
        dataField: 'value',
        dataType: 'number',
        area: 'data',
        expanded: true,
        summaryType: 'sum',
        format: '#.##',
      },
      {
        caption: 'APN_ID',
        dataField: 'apnId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'APN_TYPE',
        dataField: 'apnType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'BAL_AUTHORITY_AREA',
        dataField: 'baa',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'BID_SEGMENT_NUM',
        dataField: 'bidSegmentNum',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'ENTITY_COMPONENT_SUBTYPE',
        dataField: 'entityComponentSubtype',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'ENTITY_COMPONENT_TYPE',
        dataField: 'entityComponentType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'ENTITY_TYPE',
        dataField: 'entityType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'EXCP_DISPATCH_TYPE',
        dataField: 'excpDispatchType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'EXT_CONT_AREA',
        dataField: 'extContArea',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'LOAD_FOLLOWING_FLAG',
        dataField: 'loadFollowingFlag',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'MSS_EMISSION_PAY_FLAG',
        dataField: 'mssEmissionPayFlag',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'MSS_GROSS_OR_NET_FLAG',
        dataField: 'mssGrossOrNetFlag',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'MSS_SUBGROUP',
        dataField: 'mssSubGroup',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'PENALTY_RSRC_ID',
        dataField: 'penaltyRsrcId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'PTB_ID',
        dataField: 'ptbId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'PTO_ID',
        dataField: 'ptoId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'RUC_PARTICIPATION_FLAG',
        dataField: 'rucFlag',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'UDC_ID',
        dataField: 'udcId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'RSRC_TYPE',
        dataField: 'rsrcType',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'PRICE_NODE_ID',
        dataField: 'priceNodeId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
      {
        caption: 'INTERTIE_ID',
        dataField: 'intertieId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
        visible: true,
      },
    ],
  },
  mapConfig: {
    name: 'mapping',
    columns: [
      {
        label: 'BD NAME',
        prop: 'bdName',
        filterable: true,
        sortable: true,
      },
      {
        label: 'BD GROUP',
        prop: 'bdGroup',
        filterable: true,
        sortable: true,
      }],
    options,
  },
  tabs: [{
    title: 'Charge Code Categories',
  }, {
    title: 'BD Group Mapping',
  }],
};