import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  currentChildTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'name', label: 'Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'commodity', label: 'Commodity Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'market', label: 'Market', alignment: 'left', sortable: true, filterable: true,
    }, {
    //   prop: 'activeFlag', label: 'Active?', sortable: true,
    // }, {
      prop: 'createdBy', label: 'Created By', alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', alignment: 'left', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    name: '',
    commodity: '',
    description: '',
    market: '',
    activeFlag: true,
    createdBy: '',
    createdDate: null,
    updatedBy: '',
    updatedDate: null,
  },
  childTableData: [],
  childTableConfig: {
    columns: [{
      prop: 'id', label: 'ID', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'shortName', label: 'Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'version', label: 'Version', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'url', label: 'URL', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'environment', label: 'Environment', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'soapAction', label: 'Soap Action', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'soapNamespace', label: 'Soap Namespace', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'activeFlag', label: 'Active?', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', alignment: 'left', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }],
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getChildTableConfig: (state) => state.childTableConfig,
  getChildTableData: (state) => state.childTableData,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    const params = {
      includeEndpoints: true,
      includeInactiveEndpoints: true,
    };

    STRUCTURES_API.get('/market-systems', { params }).then(({ data }) => {
      commit('loadTableData', data.marketSystems);
      this.$notify(`${data.marketSystems.length} Market System(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Market Systems', 'error');
    });
  },
  createMarketSystem({ commit }, marketSystem) {
    STRUCTURES_API.post('/market-systems', marketSystem).then(({ data }) => {
      commit('createTableRow', data);
      this.$notify('Market System Created', 'success');
    }).catch((err) => {
      this.$notify('Failed to Create Market System', 'error');
    });
  },
  updateMarketSystem({ commit }, marketSystem) {
    STRUCTURES_API.put(`/market-systems/${marketSystem.id}`, marketSystem).then(({ data }) => {
      commit('updateTableRow', data);
      this.$notify('Market System Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Market System', 'error');
    });
  },
  deleteMarketSystem({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/market-systems/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Market System Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Market System', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
  currentChildTableRowChange({ commit }, currentTableRow) {
    commit('currentChildTableRowChange', currentTableRow);
  },
  toggleSelectedUrl({ dispatch, commit, state }) {
    const c = state.currentChildTableRow;
    const p = clone(state.currentTableRow);
    const f = p.marketSystemEndpoints
      .map((x) => {
        if (x.shortName === c.shortName && x.id !== c.id) {
          x.activeFlag = false;
        }
        if (x.id === c.id) {
          x.activeFlag = !x.activeFlag;
        }
        return x;
      });
    dispatch('updateMarketSystem', p);
    commit('currentTableRowChange', p);
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
    state.childTableData = currentRow?.marketSystemEndpoints;
    state.currentChildTableRow = null;
  },
  currentChildTableRowChange(state, currentRow) {
    state.currentChildTableRow = currentRow;
  },
  createTableRow(state, marketSystem) {
    state.tableData.push(marketSystem);
  },
  updateTableRow(state, marketSystem) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === marketSystem.id);
    state.tableData.splice(rowIndex, 1, marketSystem);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((marketSystem) => marketSystem.id !== state.currentTableRow.id);
    state.childTableData = [];
  },
  resetTable(state) {
    state.tableData = [];
    state.childTableData = [];
    state.currentTableRow = clone(state.nullTableRow);
    state.currentChildTableRow = null;
  },
  loadTableData(state, marketSystems) {
    state.tableData = marketSystems;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
