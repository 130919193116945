import moment from 'moment';
import { CISO_CMRI_API } from '@/api';
import configs from './crrConfig';

const state = {
  tableData: [],
  crrConfig: configs,
  crrPivot: configs.fields,
  tradeDate: null,
};

const actions = {
  async fetchCrrData({ commit }, parameters = {}) {
    commit('setTableData', []);
    commit('setTradeDate', parameters.tradeDateSelected);

    const payload = Object.entries({
      BA: parameters.schedulingCoordinatorsWithSubCompanySelected,
      StartDate: moment(parameters.tradeDateSelected).toISOString(),
    }).reduce((acc, [key, val]) => {
      acc += `${key}=${val}&`;
      return acc;
    }, '').slice(0, -1);
    try {
      const { data } = await CISO_CMRI_API.get(`crr/data?${payload}`);

      if (data) {
        commit('setTableData', data);
      } else if (data === null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load CRR Data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTradeDate', null);
    commit('setTableData', []);
  },
};

const mutations = {
  setTradeDate(state, value) {
    state.tradeDate = value;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.crrPivot = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};