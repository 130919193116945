export default {
  tableName: 'mlaLocationsTable',
  columns: [{
    label: 'Company',
    prop: 'entityName',
    width: 40,
    sortable: true,
    filterable: true,
    alignment: 'left',
  }, {
    label: 'Location',
    prop: 'shortName',
    sortOrder: 'asc',
    sortable: true,
    filterable: true,
    alignment: 'left',
  }],
  options: {
    filterRow: true,
    filterHeader: true,
    multipleSelection: false,
    allowSelectAll: false,
  },
};