import Vue from 'vue';
import TagCreateContractCellLink from './Etag/Tag/TagCreateContractCellLink';
import TagIDListCellLink from './Etag/Tag/TagIDListCellLink';
import TagLossAccountingCellLink from './Etag/Tag/TagLossAccountingCellLink';
import TagMiscInfoLink from './Etag/Tag/TagMiscInfoLink';

// Schedule Status
// Schedule Strategy
import './Scheduling/BidStrategy/UnusedComponents/ChangeStrategyCell';
import './Scheduling/BidStrategy/UnusedComponents/CisoBidStrategyCell';
import './Scheduling/BidStrategy/UnusedComponents/BidStrategyScheduleRouteCell';

import '../demo/BSAPSummary/BaseScheduleRouteCellAg';
import '../demo/BSAPSummary/BaseScheduleStatusCellAg';

import InstancesRouteCell from './Workflow/wfInstances/InstanceRouteCell';
import WorkflowRouteCell from './Workflow/Overview/WorkflowRouteCell';

Vue.component(TagCreateContractCellLink.name, TagCreateContractCellLink);
Vue.component(TagIDListCellLink.name, TagIDListCellLink);
Vue.component(TagLossAccountingCellLink.name, TagLossAccountingCellLink);
Vue.component(TagMiscInfoLink.name, TagMiscInfoLink);

Vue.component(InstancesRouteCell.name, InstancesRouteCell);
Vue.component(WorkflowRouteCell.name, WorkflowRouteCell);