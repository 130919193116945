import * as CONSTS from './constants';

export default {
  columns: [{
    prop: '', label: '', width: 55, minWidth: 55, maxWidth: 55, editable: false,
  }, {
    prop: 'physical_id',
    label: '#',
    width: CONSTS.NUM_WIDTH,
    minWidth: CONSTS.NUM_MIN_WIDTH, 
    maxWidth: CONSTS.NUM_WIDTH,
    editable: false,
  }, {
    prop: 'physical_mo_name',
    label: 'mo',
    width: CONSTS.PS_MO_WIDTH,
    minWidth: CONSTS.PS_MO_MIN_WIDTH,
    maxWidth: CONSTS.PS_MO_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectCellRenderer' : 'pscs-select-cell',
      list: 'mo',
      placeholder: 'MO',
      valueProperty: 'physical_mo_name',
      labelProperty: 'physical_mo_name',
      clearable: true,
    },
  }, {
    prop: 'physical_gca',
    label: 'gca',
    width: CONSTS.PS_GCA_LCA_WIDTH,
    minWidth: CONSTS.PS_GCA_LCA_MIN_WIDTH,
    maxWidth: CONSTS.PS_GCA_LCA_WIDTH,
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'GCA',
      valueProperty: 'physical_gca_name',
      labelProperty: 'physical_gca_name',
    },
  }, {
    prop: 'physical_source',
    label: 'source',
    editable: true,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsSelectRemoteCellRenderer' : 'pscs-select-remote-cell',
      placeholder: 'Source',
      valueProperty: 'physical_source_name',
      labelProperty: 'physical_source_name',
    },
  }, {
    prop: 'physical_miscInfoCount',
    label: 'm',
    width: CONSTS.MISC_INFO_WIDTH,
    minWidth: CONSTS.MISC_INFO_MIN_WIDTH,
    maxWidth: CONSTS.MISC_INFO_WIDTH,
    type: {
      name: CONSTS.USE_PSCS_AG_GRID ? 'PscsTagMiscInfoLinkRenderer' : 'tag-misc-info-link',
      overrideReadonly: true,
    },
  }],
  style: { dynamicSizing: false },
  columnList(name) {
    if (name === 'ba') return this.registry.baList;
    if (name === 'mo') return this.registry.moList;
  },
  remoteSearch: null,
  registry: { moList: [] },
};