import createInvoiceStore from './CreateInvoice/createInvoiceStore';
import approveInvoiceStore from './ApproveInvoice/approveInvoiceStore';
import readOnlyInvoiceStore from './ReadOnlyInvoice/readOnlyInvoiceStore';
import subledgerStore from './Subledger/subledgerStore';

export default {
  namespaced: true,
  modules: {
    createInvoice: createInvoiceStore,
    approveInvoice: approveInvoiceStore,
    readOnlyInvoice: readOnlyInvoiceStore,
    subledger: subledgerStore,
  },
};