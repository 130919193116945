import moment from 'moment';
import { CISO_SAMC_API, TOOLS_API } from '@/api';
import caisoUtil from '@/utils/caiso/caisoUtils';
import dateUtil from '@/utils/dateStore';
import { clone } from '@/utils/dataUtil';
import configs from './disputesFormConfig';

const state = {
  tableData: [],
  ...configs,
  registry: {
    disputeStatus: [],
    caseReason: [],
  },
  inputs: {
    summary: '',
    userDescription: '',
    caisoResponse: '',
  },
  audit: {
    createdBy: null,
    updatedBy: null,
    createdDate: null,
    updatedDate: null,
  },
};

const actions = {
  async fetchDisputesDetails({ commit }, request = {}) {
    const params = {
      scs: request.scs,
      startDate: moment(request.startDate).toISOString(),
      endDate: moment(request.endDate).toISOString(),
      rsrcId: request.resourceListSelected ? request.resourceListSelected.join(',') : null,
      chargeCode: request.chargeCode,
      dateType: request.dateType,
      disputeStatus: request.disputeStatus,
      disputeIds: request.disputeIds,
      isDetailsRequest: request.isDetailsRequest,
    };

    try {
      // TODO: Implement dispute details endpoint and reduce payload from data (returns two objects but only 1 is used)

      const { data: { data } } = await CISO_SAMC_API.get('/disputes', { params });

      if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0].dataModel)) {
        const detailsData = data[0].dataModel;
        detailsData.forEach((detailRow) => {
          detailRow.tradingDate = moment.utc(detailRow.tradingDate).toISOString();
        });

        // overwrite parent details with updated information from returned data
        // not all parent details are found within returned data set from disputes endpoint.

        request.parent = { ...data[0], details: detailsData };

        request.parent.tradingDate = moment.utc(request.parent.tradingDate).format('MM/DD/YYYY');
        request.parent.adjustmentPublicationDate = moment.utc(request.parent.adjustmentPublicationDate).format('MM/DD/YYYY');
        request.parent.awardDate = request.parent.awardDate ? moment.utc(request.parent.awardDate).format('MM/DD/YYYY') : null;
        request.parent.publishedDate = moment.utc(request.parent.publishedDate).format('MM/DD/YYYY');

        commit('setTableData', request.parent);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch Disputes Details', 'error');
    }
  },
  async fetchReferenceData({ commit }) {
    try {
      const { data } = await TOOLS_API.get('/dispute-reference-data');

      commit('setRefData', data);
    } catch (error) {
      this.$notify('Failed to load Dispute Reference Data', 'error');
      console.error(error);
    }
  },
  async editDispute({ commit }, parameters = {}) {
    const params = {
      disputeId: state.tableData[0].disputeId,
      isoDisputeId: state.tableData[0].isodisputeID,
      ba: state.tableData[0].ba,
      tradingDate: dateUtil.toMoment(state.tableData[0].tradingDate).toISOString(),
      summary: state.inputs.summary,
      userDescription: state.inputs.userDescription,
      caisoResponse: state.inputs.caisoResponse,
      caisoAmount: state.tableData[0].caisoAmount,
      calcAmount: state.tableData[0].calcAmount,
      disputeAmount: state.tableData[0].disputeAmount,
      disputeStatus: state.tableData[0].disputeStatus,
      publishedDate: dateUtil.toMoment(state.tableData[0].publishedDate).toISOString(),
      caseReason: state.tableData[0].caseReason,
      adjustmentPublicationDate: dateUtil.toMoment(state.tableData[0].adjustmentPublicationDate).toISOString(),
      caisoApprovedAmount: state.tableData[0].caisoApprovedAmount,
      awardDate: dateUtil.toMoment(state.tableData[0].awardDate).toISOString(),
      originatingGroup: state.tableData[0].originatingGroup,
      createdDate: dateUtil.toMoment(state.audit.createdDate).toISOString(),
      createdBy: state.audit.createdBy,
      updatedDate: dateUtil.toMoment(state.audit.updatedDate).toISOString(),
      updatedBy: state.audit.updatedBy,
    };

    // TODO: After Saving, need to update main disputes screen with updated information
    try {
      await CISO_SAMC_API.put('/disputes/edit', params);
      this.$notify('Successfully updated Dispute', 'success');
    } catch (error) {
      this.$notify('Failed to save Dispute', 'error');
      console.error(error);
    }
  },

  deleteDispute({ commit }, parameters = {}) {
    const params = {
      disputeId: state.tableData[0].disputeId,
    };

    try {
      const result = CISO_SAMC_API.delete('/disputes/delete', { params });
      commit('reset');

      this.$notify('Successfully deleted Dispute', 'success');

      setTimeout(() => { window.close(); }, 2000);
    } catch {
      this.$notify('Failed to delete Dispute', 'error');
    }
  },

};

const mutations = {
  setTableData(state, value) {
    state.tableData = [value];
    state.inputs.summary = value.summary;
    state.inputs.userDescription = value.userDescription;
    state.inputs.caisoResponse = value.caisoResponse;
    state.audit.createdBy = value.createdBy;
    state.audit.createdDate = value.createdDate;
    state.audit.updatedBy = this.getters['auth/getProfile'].userName;
    state.audit.updatedDate = moment().utc().format('YYYY/MM/DD');
  },
  setRefData(state, value) {
    state.registry.disputeStatus = value.disputeStatus.map((val) => ({ label: val, value: val }));
    state.registry.caseReason = value.caseReason.map((val) => ({ label: val, value: val }));
  },
  reset(state) {
    state.tableData = [];
    state.inputs.summary = '';
    state.inputs.userDescription = '';
    state.inputs.caisoResponse = '';
    state.audit.createdBy = '';
    state.audit.createdDate = null;
    state.audit.updatedBy = '';
    state.audit.updatedDate = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};