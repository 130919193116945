export function mapToOptions(items) {
  if (Array.isArray(items)) return items.map((i) => ({ value: i.id, label: i.shortName }));
  return [];
}

export function formatDate(date) {
  date = new Date(date.toString());
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dt = date.getDate();
  return `${year}-${month}-${dt}`;
}