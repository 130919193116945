import dateStore from '@/utils/dateStore';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  currentChildTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'accountType', label: 'Account Type', sortable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true,
    }, {
      prop: 'company', label: 'Company', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true,
    }],
    accountTypes: [
      { value: 'ACTIVE', label: 'ACTIVE' }, { value: 'COMPLIANCE', label: 'COMPLIANCE' }, { value: 'GENERAL', label: 'GENERAL' }, { value: 'RETIRE', label: 'RETIRE' },
    ],
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    accountType: null,
    shortName: null,
    description: null,
    commodityName: 'RECS',
    marketName: null,
    company: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  childTableData: [],
  childTableConfig: {
    columns: [{
      prop: 'generatingUnitName', label: 'Unit Name', sortable: true,
    }, {
      prop: 'primaryFacilityName', label: 'Facility Name', sortable: true,
    }, {
      prop: 'serialNumbers', label: 'Serial#', sortable: true,
    }, {
      prop: 'totalQuantity', label: 'Quantity', sortable: true,
    }, {
      prop: 'transferQuantity', label: 'Transferred', sortable: true,
    }, {
      prop: 'remainingQuantity', label: 'Remaining', sortable: true,
    }, {
      prop: 'vintageMonth', label: 'Vintage', sortable: true,
    }, {
      prop: 'etagMatchedFlag', label: 'Etag Matched?', sortable: true,
    }, {
      prop: 'greeneEnergy', label: 'Green-e Energy', sortable: true,
    }, {
      prop: 'rpsSelections', label: 'RPS Selections', sortable: true,
    }, {
      prop: 'genPeriodStartDate', label: 'Generation Start', sortable: true,
    }, {
      prop: 'genPeriodEndDate', label: 'Generation End', sortable: true,
    }, {
      prop: 'certificateCreateDate', label: 'Certificate Created', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true,
    }],
  },
  nullChildTableRow: {
    generatingUnitName: null,
    primaryFacilityName: null,
    serialNumbers: null,
    totalQuantity: null,
    transferQuantity: null,
    remainingQuantity: null,
    vintageMonth: null,
    etag: null,
    greeneEnergy: null,
    rpsSelections: null,
    genPeriodStartDate: null,
    genPeriodEndDate: null,
    certificateCreateDate: null,
    createdBy: null,
    createdDate: null,
  },
  datePropsAndFormats: [
    { name: 'effectiveDate', format: 'YYYY-MM-DD HH:mm' },
    { name: 'terminationDate', format: 'YYYY-MM-DD HH:mm' },
    { name: 'createdDate', format: 'YYYY-MM-DD HH:mm' },
    { name: 'updatedDate', format: 'YYYY-MM-DD HH:mm' },
    { name: 'genPeriodStartDate', format: 'YYYY-MM-DD' },
    { name: 'genPeriodEndDate', format: 'YYYY-MM-DD' },
    { name: 'certificateCreateDate', format: 'YYYY-MM-DD' },
  ],
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getCurrentRow: (state) => state.currentTableRow,
  getChildTableConfig: (state) => state.childTableConfig,
  getCurrentChildRow: (state) => state.currentChildTableRow,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
  getCompanyList: (state, localGetters, rootState) => rootState.lookup.companyList,

};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('lookup/fetchCompanyList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/environmental/sub-accounts/RECS').then(({ data }) => {
      commit('loadTableData', data);
      this.$notify(`${data.environmentalSubAccounts.length} Renewable Sub Account(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Renewable Sub Accounts', 'error');
    });
  },
  loadChildTableData({ dispatch, commit, state }) {
    if (state.currentTableRow) {
      STRUCTURES_API.get(`/environmental/sub-account-certificates/${state.currentTableRow.id}`).then(({ data }) => {
        commit('loadChildTableData', data);
        this.$notify(`${data.environmentalAccountCertificates.length} Renewable Sub Account Certificate(s) Loaded`, 'info');
      }).catch((err) => {
        this.$notify('Failed to Load Renewable Sub Account Certificates', 'error');
      });
    }
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postRenewableSubAccount({ state }, renewableSubAccount) {
    STRUCTURES_API.post('environmental/sub-accounts', renewableSubAccount).then(({ data }) => {
      dateStore.formatDateTimeFields([data], state.datePropsAndFormats);
      state.tableData.push(data);
      this.$notify('Renewable Sub Account Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Renewable Sub Account', 'error');
    });
  },
  updateRenewableSubAccount({ commit }, renewableSubAccount) {
    STRUCTURES_API.put(`/environmental/sub-accounts/${renewableSubAccount.id}`, renewableSubAccount).then(({ data }) => {
      commit('updateRenewableSubAccount', data);
      this.$notify('Renewable Sub Account Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Renewable Sub Account', 'error');
    });
  },
  deleteRenewableSubAccount({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/environmental/sub-accounts/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteRenewableSubAccount');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      dispatch('currentChildTableRowChange', null);
      this.$notify('Renewable Sub Account Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Renewable Sub Account', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
    dispatch('loadChildTableData');
  },
  currentChildTableRowChange({ commit }, currentRow) {
    commit('currentChildTableRowChange', currentRow);
  },
};

const mutations = {
  updateRenewableSubAccount(state, renewableSubAccount) {
    dateStore.formatDateTimeFields([renewableSubAccount], state.datePropsAndFormats);
    const rowIndex = state.tableData.findIndex(({ id }) => id === renewableSubAccount.id);
    state.tableData.splice(rowIndex, 1, renewableSubAccount);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  currentChildTableRowChange(state, currentRow) {
    state.currentChildTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
    state.childTableData = [];
    state.currentChildTableRow = clone(state.nullChildTableRow);
  },
  loadTableData(state, { environmentalSubAccounts }) {
    dateStore.formatDateTimeFields(environmentalSubAccounts, state.datePropsAndFormats);
    state.tableData = environmentalSubAccounts;
  },
  createTableRow(state) {
    const environmentalSubAccount = clone(state.nullTableRow);
    environmentalSubAccount.id = state.tableKey++;
    environmentalSubAccount.versionId = 1;
    environmentalSubAccount.createdBy = this.getters['auth/getProfile'].userName;
    environmentalSubAccount.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = environmentalSubAccount;
  },
  deleteRenewableSubAccount(state) {
    state.tableData = state.tableData.filter(({ id }) => id !== state.currentTableRow.id);
    state.childTableData = [];
  },
  loadChildTableData(state, { environmentalAccountCertificates }) {
    environmentalAccountCertificates.forEach((eac) => {
      eac.remaining = eac.totalQuantity - eac.transferQuantity;
    });
    dateStore.formatDateTimeFields(environmentalAccountCertificates, state.datePropsAndFormats);
    state.currentTableRow.environmentalGhgAccountHoldings = environmentalAccountCertificates;
    state.childTableData = environmentalAccountCertificates;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};