import { CISO_MASTERDATA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import helpers from '@/utils/helpers';
import { mapToOptions, formatDate } from './util';

const state = {
  resourceTableData: [],
  resourceConfigurationTableData: [],
  schedulingCoordinators: [],
  selectedSchedulingCoordinator: '',
  selectedResource: null,
  dateTimeFormats: [
    { name: 'effDate', format: 'YYYY-MM-DD' },
    { name: 'termDate', format: 'YYYY-MM-DD' },
    { name: 'createdDate', format: 'YYYY-MM-DD' },
    { name: 'updatedDate', format: 'YYYY-MM-DD' },
  ],
  resourceTypes: [],
  technologyTypes: [],
  remoteSearch: null,
  resourceTableConfiguration: {
    columns: [{
      label: 'Remove', prop: 'remove', fixed: 'left', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, editable: true,
    }, {
      label: 'SC', prop: 'sc', fixed: 'left', width: 120, sortable: true, filter: true, editable: false,
    }, {
      label: 'Short Name', prop: 'shortName', fixed: 'left', width: 150, sortable: true, filter: true, editable: true,
    }, {
      label: 'Long Name', prop: 'longName', width: 150, sortable: true, filter: true, editable: true,
    }, {
      label: 'Resource Type',
      prop: 'resourceType',
      width: 100,
      sortable: true,
      filter: true,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'resourceTypes',
        placeholder: 'Rsrc Type',
        clearable: false,
      },
    }, {
      label: 'Effective Date', prop: 'effDate', type: { name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: false }, width: 160, sortable: true, filter: true, editable: true,
    }, {
      label: 'Termination Date',
      prop: 'termDate',
      type: {
        name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: true, placeholder: 'Date',
      },
      width: 160,
      sortable: true,
      filter: true,
      editable: true,
    }, {
      label: 'PMin', prop: 'pmin', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'PMax', prop: 'pmax', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'MSG', prop: 'isMsg', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Aggregate', prop: 'isAggregate', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Registered Intertie', prop: 'isRegisteredIntertie', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'BAA', prop: 'baa', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Energy Type', prop: 'energyType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Fuel Type', prop: 'fuelType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Prime Mover Technology',
      prop: 'technologyType',
      width: 100,
      sortable: true,
      filter: true,
      editable: true,
      type: {
        name: 'PscsSelectCellRenderer',
        list: 'technologyTypes',
        placeholder: 'Tech Type',
        clearable: true,
      },
    }, {
      label: 'Generator Type', prop: 'generatorType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Fuel Region', prop: 'fuelRegion', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Electric Region', prop: 'electricRegion', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Air Quality Mgmt. Distr.', prop: 'airQualityManagmentDistrict', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max Gen Cap', prop: 'maximumGenerationCapacity', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Gen Cap', prop: 'minimumGenerationCapacity', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Dispatch Lvl', prop: '', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min on Time', prop: 'minimumOnTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max on Time', prop: 'maximumOnTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min off TIme', prop: 'minimumOffTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max Startups Per Day', prop: 'maximumStartupsPerDay', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Market Max Startups Per Day', prop: 'marketMaximumStartupsPerDay', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Load Cost', prop: 'minimumLoadCost', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Load Cost Basis', prop: 'minimumLoadCostBasis', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Startup Cost Basis', prop: 'startupCostBasis', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max Pump Capacity', prop: 'maximumPumpCapacity', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pumping Min Cost', prop: 'pumpingMinimumCost', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pumping Factor', prop: 'pumpingFactor', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump Max Daily Startups', prop: 'pumpMaximumDailyStartups', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump Min Up Time', prop: 'pumpMinimumUpTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump Min Down Time', prop: 'pumpMinimumDownTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Gen to Pump Min Down Time', prop: 'gentoPumpMinimumDownTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump to Gen Min Down Time', prop: 'pumptoGenMinimumDownTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump Max Shutdown Cost', prop: 'pumpMaximumShutdownCost', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Pump Shutdown Time', prop: 'pumpShutdownTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Variable Cost Option', prop: 'variableCostOption', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Negotiated Rate Option', prop: 'negotiatedRateOption', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'LMP Option', prop: 'lmpOption', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Reserve Capacity Spin', prop: 'reserveCapacitySpin', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Reserve Capacity Non-Spin', prop: 'reserveCapacityNonSpin', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified PIRP', prop: 'certifiedPIRP', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Must Offer Obligation Qualified', prop: 'mustOfferObligationQualified', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Startup Code Type', prop: 'startupCodeType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Participating Gen. Agreement Flag', prop: 'participatingGeneratorAgreementFlag', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Commercial Operation for Market MW', prop: 'commercialOperationforMarketMW', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Constrained Output Generator Flag', prop: 'constrainedOutputGeneratorFlag', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for Black Start', prop: 'certifiedforBlackStart', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for DAMarket', prop: 'certifiedforDAMarket', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for RUC', prop: 'certifiedforRUC', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Market Power Mitigration Pacticipation Flag', prop: 'marketPowerMitigationParticipationFlag', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for ASRegulation Down', prop: 'certifiedforASRegulationDown', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for ASSPin', prop: 'certifiedforASSpin', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for ASDAMN Non-Spin', prop: 'certifiedforASDAMNonSpin', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Certified for ASRTM Non-Spin', prop: 'certifiedforASRTMNonSpin', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'MSS Load Following Down', prop: 'mssLoadFollowingDown', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'MSS Load Following Up', prop: 'mssLoadFollowingUp', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'FERC Qualifying Facility Flag', prop: 'fercQualifyingFacilityFlag', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Use Limit', prop: 'useLimit', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Operating Maintenance Cost', prop: 'operatingMaintenanceCost', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Operating Maintencance Adder Type', prop: 'operatingandMaintenanceAdderType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Opportunity Cost Addr Type Energy', prop: 'opportunityCostAdderTypeEnergy', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Opportunity Cost Addr Type Run Hours', prop: 'opportunityCostAdderTypeRunhours', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Opportunity Cost Addr Type Starts', prop: 'opportunityCostAdderTypeStarts', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Priority Type', prop: 'priorityType', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Dispatachable', prop: 'dispatchable', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Discrete Dispatch', prop: 'discreteDispatch', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'RMR', prop: 'rmr', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max Ramp Rate', prop: 'maximumRampRate', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Price Setter DAM', prop: 'priceSetterDAM', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Price Setter RTM', prop: 'priceSetterRTM', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Multi Stage Gen Flag', prop: 'multiStageGeneratorFlag', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Startup Ramp Time', prop: 'startupRampTime', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Supplied Configuration Flag', prop: 'suppliedConfigurationFlag', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Hourly Pre Dispatch', prop: 'hourlyPreDispatch', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Non-Generator Resource', prop: 'nonGeneratorResource', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Regulation Energy Mngmt.', prop: 'regulationEnergyManagement', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Continous Energy Limit', prop: 'minimumContinuousEnergyLimit', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Max Continous Energy Limit', prop: 'maximumContinuousEnergyLimit', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Curtailment Energy Limit', prop: 'curtailmentEnergyLimit', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Energy Efficiency', prop: 'energyEfficiency', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Combined Heat and Power Resource', prop: 'combinedHeatandPowerResource', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'RMTG Max on Peak', prop: 'rmtgMaxOnPeak', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'RMTG Max on Peak Expiration Date', prop: 'rmTmaxOnPeakExpirationDate', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'RMTG Max off Peak', prop: 'rmtMaxOffPeak', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'RMTG Max off Peak Expiration Date', prop: 'rmtOffPeakExpirationDate', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Green House Gas Emission Rate', prop: 'greenHouseGasEmissionRate', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Green House Gas Compliance Obligation', prop: 'greenHouseGasComplianceObligation', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Min Load Major Maintenance Adder', prop: 'minLoadMajorMaintenanceAdder', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Variable Energy Resource Flag', prop: 'variableEnergyResourceFlag', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Forecast Selection', prop: 'forecastSelection', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Energy Imbalance Market Participation Flag', prop: 'energyImbalanceMarketParticipatingFlag', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Primary Intertie Name', prop: 'primaryIntertieName', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Secondary Intertie Name', prop: 'secondaryIntertieName', width: 100, sortable: true, filter: true, editable: true,
    }, {
      label: 'Scheduling Node Name', prop: 'schedulingNodeName', width: 100, sortable: true, filter: true, editable: false,
    }, {
      label: 'Created By', prop: 'createdBy', width: 100, sortable: true, filter: true, editable: false,
    }, {
      label: 'Created Date', prop: 'createdDate', width: 100, sortable: true, filter: true, editable: false,
    }, {
      label: 'Updated By', prop: 'updatedBy', width: 100, sortable: true, filter: true, editable: false,
    }, {
      label: 'Updated Date', prop: 'updatedDate', width: 100, sortable: true, filter: true, editable: false,
    },
      // { label: 'Variant', prop:'variant', width: 100, sortable: true, filter: true, editable: true },
    ],
    style: { maxHeight: 380 },
    options: {
      searchFilter: false,
      warningHighlight: true,
      disableYAxisFill: true,
      cellSelection: true,
    },
    columnList(name) {
      if (name === 'resourceTypes') return this.resourceTypes;
      if (name === 'technologyTypes') return this.technologyTypes;
      return [];
    },
  },
  resourceConfigurationTableConfig: {
    columns: [{
      prop: 'remove', label: 'Remove', type: { name: 'PscsCheckboxCellRenderer', overrideReadonly: true }, editable: true, width: 100, maxWidth: 100,
    }, {
      prop: 'shortName', label: 'Short Name', editable: true,
    }, {
      prop: 'longName', label: 'Long Name', editable: true,
    }, {
      prop: 'effDate', label: 'Effective Date', type: { name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: false }, width: 160, sortable: true, filter: true, editable: true, visible: false,
    }, {
      prop: 'termDate',
      label: 'Termination Date',
      type: {
        name: 'PscsDateTimeCellRenderer', overrideReadonly: true, clearable: true, placeholder: 'Date',
      },
      width: 160,
      sortable: true,
      filter: true,
      editable: true,
      visible: false,
    }, {
      prop: 'pmin', label: 'PMin', width: 100, sortable: true, filter: true, editable: true,
    }, {
      prop: 'pmax', label: 'PMax', width: 100, sortable: true, filter: true, editable: true,
    }, {
      prop: 'createdBy', label: 'Created By', width: 100, sortable: true, filter: true, editable: false,
    }, {
      prop: 'createdDate', label: 'Created Date', width: 100, sortable: true, filter: true, editable: false,
    }, {
      prop: 'updatedBy', label: 'Updated By', width: 100, sortable: true, filter: true, editable: false,
    }, {
      prop: 'updatedDate', label: 'Updated Date', width: 100, sortable: true, filter: true, editable: false,
    }],
    style: {
      maxHeight: 400,
      dynamicSizing: false,
    },
    options: {
      searchFilter: false,
      warningHighlight: true,
      disableYAxisFill: true,
      cellSelection: true,
    },
  },
};

const getters = {
  getResourceTableConfiguration: (state) => state.resourceTableConfiguration,
  getResourceTableData: (state) => state.resourceTableData,
  getResourceConfigurationTableConfig: (state) => state.resourceConfigurationTableConfig,
  getResourceConfigurationTableData: (state) => state.resourceConfigurationTableData,
  getMinHeight(state) {
    if (state.resourceTableData && state.resourceTableData.length > 0) return 600;

    return 100;
  },
  getSchedulingCoordinators: (state) => mapToOptions(state.schedulingCoordinators),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
};

const actions = {
  addResourceConfiguration({ commit, state }, item) {
    if (!state.selectedResource) {
      item.callback({ type: 'warning', title: 'Please click on resource you wish to add to' });
    } else if (state.selectedResource.isMsg == 'false' || state.selectedResource.isMsg == false) {
      item.callback({ type: 'warning', title: 'Configurations can only be added to resource if MSG option is set' });
    } else {
      const newConfiguration = {
        id: -1, shortName: (`${state.selectedResource.shortName}_`), longName: null, pmax: null, pmin: null, isNew: true, effDate: new Date(), termDate: null, rampRates: [], startups: [], heatRates: [], parentName: state.selectedResource.shortName, ResourceId: state.selectedResource.id, isDirty: true,
      };
      if (!state.selectedResource.configurations) {
        state.selectedResource.configurations = [];
        state.selectedResource.configurations.push(newConfiguration);
      } else {
        state.selectedResource.configurations.push(newConfiguration);
      }

      state.resourceConfigurationTableData.unshift(newConfiguration);
    }
  },
  addResource({ state, commit }) {
    let newRes = helpers.cloneDeep(state.resourceTableData[0]);
    newRes = helpers.mapValues(newRes, () => null);
    newRes.isAggregate = false;
    newRes.isMsg = false;
    newRes.id = -1;
    newRes.isNew = true;
    newRes.sc = state.resourceTableData[0].sc;
    newRes.isDirty = true;
    newRes.effDate = new Date();
    newRes.variant = 'MASTER_FILE';
    newRes.remove = false;
    newRes.heatRates = [];
    newRes.rampRates = [];
    newRes.startups = [];
    newRes.configurations = [];

    state.resourceTableData.unshift(newRes);
  },
  removeResources({ state, commit }, item) {
    const resourcesToDelete = { resources: state.resourceTableData.filter((d) => d.remove === 'true') };
    let response = null;
    if (resourcesToDelete.resources.length > 0) {
      for (let i = 0; i < resourcesToDelete.resources.length; i++) {
        response = CISO_MASTERDATA_API.delete(`/resources/${resourcesToDelete.resources[i].id}`)
          .then((response) => {
            commit('cleanResourceData');
          })
          .catch((err) => {
            item.callback({ type: 'error', title: 'Resources Failed to Delete' });
          });
      }

      if (response) item.callback({ type: 'success', title: 'Resources Delete Initiated' });
    }
    state.resourceTableData = state.resourceTableData.filter((resource) => resource.remove === 'true');

    // re-render table
    const td = helpers.cloneDeep(state.resourceTableData);
    state.resourceTableData = td;
  },
  removeResourceConfiguration({ commit }, item) {
    const configurationsToRemove = state.resourceConfigurationTableData.filter((o) => o.remove);
    if (configurationsToRemove.length > 0) {
      const resource = state.selectedResource;
      const configurations = configurationsToRemove.map((i) => ({ id: i.id, shortName: i.shortName }));

      if (state.selectedResource.id !== -1 && !state.selectedResource.remove) {
        for (let i = 0; i < configurations.length; i++) {
          CISO_MASTERDATA_API.delete(`/resources/configuration/${configurations[i].id}`)
            .then((response) => {
              item.callback({ type: 'success', title: `Success Removing configurations: ${configurations[i].shortName}` });
              commit('removeConfigurationFromResource', configurations[i]);
            }).catch((err) => {
              item.callback({ type: 'error', title: `Error removing configuration: ${configurations[i].shortName}` });
              console.log(err.response);
            });
        }
      } else if (state.selectedResource.id === -1) {
        for (let i = 0; i < configurations.length; i++) {
          commit('removeConfigurationFromResource', configurations[i]);
        }
      }
    }

    // re-render data
    const td = helpers.cloneDeep(state.resourceConfigurationTableData);
    state.resourceConfigurationTableData = td;
  },
  save({ state, commit, dispatch }, item) {
    const updatedResources = { resources: state.resourceTableData.filter((d) => d.isDirty === true) };
    const configsToAddOrEdit = state.resourceConfigurationTableData.filter((d) => d.isDirty === true);

    for (let i = 0; i < updatedResources.resources.length; i++) {
      updatedResources.resources[i].effDate = formatDate(updatedResources.resources[i].effDate);
      if (updatedResources.resources[i].termDate) updatedResources.resources[i].termDate = formatDate(updatedResources.resources[i].termDate);
      if (!updatedResources.resources[i].heatRates) updatedResources.resources[i].heatRates = [];
      if (!updatedResources.resources[i].startups) updatedResources.resources[i].startups = [];
      if (!updatedResources.resources[i].rampRates) updatedResources.resources[i].rampRates = [];

      if (updatedResources.resources[i].isMsg === 'false' || updatedResources.resources[i].isMsg === null) updatedResources.resources[i].configurations = [];
    }

    if (updatedResources.resources.length > 0) {
      CISO_MASTERDATA_API.put('/resources/batch', updatedResources)
        .then((response) => {
          dispatch('reloadUpdatedResourceDataAction', response.data.resources);
          commit('cleanResourceData');
          item.callback({ type: 'success', title: 'Resources Save Initiated', saveConfig: item });
        })
        .catch((err) => {
          item.callback({ type: 'error', title: 'Resources Save Failed' });
        });
    }

    if (updatedResources.resources.length <= 0 && configsToAddOrEdit.length > 0) dispatch('saveResourceConfigurations', item);
  },
  saveResourceConfigurations({ state, commit }, item) {
    const configsToAddOrEdit = state.resourceConfigurationTableData.filter((d) => d.isDirty === true);

    for (let i = 0; i < configsToAddOrEdit.length; i++) {
      configsToAddOrEdit[i].effDate = formatDate(configsToAddOrEdit[i].effDate);
      if (configsToAddOrEdit[i].termDate) configsToAddOrEdit[i].termDate = formatDate(configsToAddOrEdit[i].termDate);
    }

    for (let i = 0; i < configsToAddOrEdit.length; i++) {
      if (configsToAddOrEdit[i].ResourceId == -1) {
        configsToAddOrEdit[i].ResourceId = state.selectedResource.id;
      }
    }

    if (configsToAddOrEdit.length > 0) {
      for (let i = 0; i < configsToAddOrEdit.length; i++) {
        if (configsToAddOrEdit[i].id !== -1) {
          CISO_MASTERDATA_API.put(`/resources/${configsToAddOrEdit[i].id}/configurations`, configsToAddOrEdit[i])
            .then((response) => {
              item.callback({ type: 'success', title: 'Resource Configuration Save Initiated' });
              commit('cleanConfigurationsData');
            })
            .catch((err) => {
              item.callback({ type: 'error', title: 'Resource Configuration Save Failed' });
            });
        } else {
          CISO_MASTERDATA_API.post(`/resources/${configsToAddOrEdit[i].ResourceId}/configurations`, configsToAddOrEdit[i])
            .then((response) => {
              item.callback({ type: 'success', title: 'Resource Configuration Save Initiated' });
              commit('cleanConfigurationsData');
            })
            .catch((err) => {
              item.callback({ type: 'error', title: 'Resource Configuration Save Failed' });
            });
        }
      }
    }
  },
  cellClickChanged({ commit }, item) {
    if (item.row != null) {
      commit('setResourceConfigurationTableData', item.row);
    } else state.resourceConfigurationTableData = [];
  },
  loadData({ commit, dispatch }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators')
      .then((response) => {
        commit('setSchedulingCoordinators', response.data.schedulingCoordinators);
        dispatch('loadResourceDataBySCAction');
      })
      .catch((err) => {
        console.log(err);
      });
    CISO_MASTERDATA_API.get('resources/types')
      .then((response) => {
        if (response) {
          commit('setResourceTypes', response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    CISO_MASTERDATA_API.get('resources/technologyTypes')
      .then((response) => {
        if (response) {
          commit('setResourceTechnologies', response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  dataChanged({ commit }, item) {
    commit('updateTableData', item);
  },
  resourceConfigurationDataChanged({ commit }, item) {
    commit('updateResourceConfigurationTableData', item);
  },
  clear({ commit }) {
    commit('clear');
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
    dispatch('loadResourceDataBySCAction');
  },
  loadResourceDataBySCAction({ commit, state }) {
    if (state.selectedSchedulingCoordinator) {
      const sc = state.schedulingCoordinators.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;

      const params = {
        variant: 'Master_File',
        sc,
        includeConfig: true,
      };

      CISO_MASTERDATA_API.get('resources/fullResource', { params })
        .then((response) => {
          commit('setResourceTableData', response.data.resources);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  reloadUpdatedResourceDataAction({ commit, state, dispatch }, items) {
    const sc = state.schedulingCoordinators.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;
    for (let i = 0; i < items.length; i++) {
      const params = {
        variant: 'Master_File',
        sc,
        includeConfig: true,
        resourceShortName: items[i].shortName,
      };

      CISO_MASTERDATA_API.get('resources/fullResource', { params })
        .then((response) => {
          commit('setUpdatedResourceInTableData', response.data.resources[0]);
          dispatch('saveResourceConfigurations', null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};

const mutations = {
  updateTableData(state, item) {
    let setSelected = false;

    if (state.selectedResource.id === state.resourceTableData[item.rowIndex].id && state.selectedResource.shortName === state.resourceTableData[item.rowIndex].shortName) setSelected = true;

    state.resourceTableData[item.rowIndex][item.prop] = item.value;
    state.resourceTableData[item.rowIndex].isDirty = true;

    if (setSelected) state.selectedResource = state.resourceTableData[item.rowIndex];
  },
  setResourceTableData(state, items) {
    dateStore.formatDateTimeFields(items, state.dateTimeFormats);
    for (let i = 0; i < items.length; i++) { items[i].remove = false; }
    state.resourceTableData = helpers.sortBy(items, (o) => o.shortName);
  },
  setUpdatedResourceInTableData(state, item) {
    const updateResource = state.resourceTableData.find((o) => o.shortName === item.shortName);
    updateResource.id = item.id;
    state.selectedResource.id = updateResource.id;
  },
  setResourceConfigurationTableData(state, item) {
    state.selectedResource = item;
    let resourceConfigurations = [];

    if ((item.isMsg === true || item.isMsg === 'true') && item.configurations.length > 0) {
      resourceConfigurations = item.configurations;
      dateStore.formatDateTimeFields(resourceConfigurations, state.dateTimeFormats);
      for (let i = 0; i < resourceConfigurations.length; i++) { resourceConfigurations[i].remove = false; }
    }

    state.resourceConfigurationTableData = helpers.sortBy(resourceConfigurations, (o) => o.shortName);
  },
  updateResourceConfigurationTableData(state, item) {
    state.resourceConfigurationTableData[item.rowIndex][item.prop] = item.value;
    state.resourceConfigurationTableData[item.rowIndex].isDirty = true;
  },
  cleanResourceData(state) {
    for (let i = 0; i < state.resourceTableData.length; i++) {
      state.resourceTableData[i].isDirty = false;
      state.resourceTableData[i].isNew = false;
    }
  },
  cleanConfigurations(state) {
    for (let i = 0; i < state.resourceConfigurationTableData.length; i++) {
      state.resourceConfigurationTableData[i].isDirty = false;
      state.resourceConfigurationTableData[i].isNew = false;
    }
  },
  clear(state) {
    state.resourceTableData = [];
    state.isSearched = false;
  },
  setSchedulingCoordinators(state, item) {
    state.schedulingCoordinators = item;
    if (item.length > 0) {
      state.selectedSchedulingCoordinator = item[0].id;
    }
  },
  changeSchedulingCoordinatorMutation(state, item) {
    state.selectedSchedulingCoordinator = item;
  },
  setResourceTypes(state, items) {
    const types = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        types.push({ value: items[i], label: items[i] });
      }
    }
    state.resourceTableConfiguration.resourceTypes = types;
  },
  setResourceTechnologies(state, items) {
    const types = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        types.push({ value: items[i], label: items[i] });
      }
    }
    state.resourceTableConfiguration.technologyTypes = types;
  },
  removeConfigurationFromResource(state, item) {
    let resourceConfigTableData = helpers.cloneDeep(state.resourceConfigurationTableData);
    const resourceTblData = helpers.cloneDeep(state.resourceTableData);
    const resource = resourceTblData.find((o) => o.id === state.selectedResource.id && o.shortName === state.selectedResource.shortName);

    if (resource) {
      resource.configurations = resource.configuration.filter((o) => o.id === item.id);
      resourceConfigTableData = resourceConfigTableData.filter((o) => o.id === item.id);
    }

    state.selectedResource = resource;
    state.resourceConfigurationTableData = resourceConfigTableData;
    state.resourceTableData = resourceTblData;

    const td = helpers.cloneDeep(state.resourceConfigurationTableData);
    state.resourceConfigurationTableData = td;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};