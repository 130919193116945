import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_SAMC_API, IDENTITY_API, TOOLS_API } from '@/api';
import { param } from 'jquery';
import dateUtil from '@/utils/dateStore';
import configs from './settlementReviewConfigurations';

const state = {
  tableData: [],
  currentCell: null,
  dateRange: [],
  statusList: [],
  taskList: [],
  userList: [],
  publicationCycles: [],
  coordinatorList: [],
  tableConfig: configs.fields,
  searchFiltersConfig: configs.searchFiltersConfig,
  parameters: null,
  statusViewFlag: false,
};

const getters = {
  getDateRange: (state) => state.dateRange,
  getStatusList: (state) => state.statusList.map(({ statusId, statusName }) => ({ value: statusId, label: statusName })),
  getUsers: (state) => state.userList
    .filter((x) => (x.email === 'qa-testing@powersettlements.com') || (!x.email.includes('powersettlements.com')))
    .map(({ userName, firstName, lastName }) => ({ value: userName, label: `${firstName } ${ lastName}` })),
  getTaskList: (state) => state.taskList.map(({ taskName, taskId }) => ({ value: taskId, label: taskName })),
  getCoordinatorsList: (state) => state.coordinatorList.map(({ shortName }) => ({ value: shortName, label: shortName })),
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('fetchStatusList');
    dispatch('fetchTaskList');
    dispatch('fetchUserList');
    dispatch('fetchCoordinatorsList');
  },
  changeDateRangeAction({ commit, dispatch }, value) {
    commit('changeDateRangeMutation', value);
    dispatch('fetchSettlementReviewCalendar', value);
  },
  async fetchSettlementReview({ commit }, parameters = {}) {
    commit('saveParameters', parameters);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      dateType: parameters.dateTypeSelected,
      currentUserFlag: parameters.currentUserFlagSelected,
    };
    try {
      const { data } = await CISO_SAMC_API.get('/settlementapproval', { params });
      data.forEach((x) => {
        x.publishedDate = getMomentDateString(x.publishedDate);
        x.tradingDate = getMomentDateString(x.tradingDate);
        if (x.createdTimestamp) x.createdTimestamp = moment(x.createdTimestamp).format('YYYY-MM-DD HH:mm:ss');
      });
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Settlement Review', 'error');
    }
  },
  async fetchUserList({ commit }) {
    try {
      const { data } = await IDENTITY_API.get('/users');
      commit('loadUserList', data.users);
    } catch (err) {
      this.$notify('Failed to fetch User list', 'error');
      console.error(err);
    }
  },
  async fetchCoordinatorsList({ commit }) {
    try {
      const { data: { schedulingCoordinators } } = await TOOLS_API.get('/scheduling-coordinators');
      commit('loadCoordinatorList', schedulingCoordinators);
    } catch (error) {
      this.$notify('Failed to load Scheduling Coordinators', 'error');
      console.log(error);
    }
  },
  async fetchSettlementReviewCalendar({ commit, dispatch }, parameters = {}) {
    const params = {
      startDate: parameters[0].toISOString(),
      endDate: parameters[1].toISOString(),
      dateType: 'TradeDate',
    };

    try {
      const { data } = await CISO_SAMC_API.get('/settlementapproval/calendar', { params });
      if (Array.isArray(data)) {
        const publicationCycles = data.map((d) => d.desc).filter((value, index, array) => array.indexOf(value) === index);
        commit('loadPublicationCycles', publicationCycles);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Publication Cycles', 'error');
      dispatch('reset');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
  async fetchStatusList({ commit }) {
    try {
      const { data } = await CISO_SAMC_API.get('/settlementapproval/status');
      const orderedStatuses = data.sort((a, b) => a.statusId - b.statusId);
      commit('loadStatusList', orderedStatuses);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Statuses', 'error');
    }
  },
  async fetchTaskList({ commit }) {
    try {
      const { data } = await CISO_SAMC_API.get('/settlementapproval/taskType');
      commit('loadTaskList', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Tasks', 'error');
    }
  },
  cellChange({ commit }, cell) {
    commit('currentCellChanged', cell);
  },
  postRecord({ state, dispatch }, settlement) {
    const data = {
      data: [{
        statusId: settlement.statusId,
        taskName: settlement.taskName,
        publishedDate: settlement.publishedDate,
        tradingDate: settlement.tradingDate,
        fileType: settlement.fileType,
        cycle: settlement.cycle,
        scid: settlement.scid,
        taskId: settlement.taskId,
        note: settlement.note,
      }],
    };
    CISO_SAMC_API.post('/settlementApproval', data).then(() => {
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
    dispatch('fetchSettlementReview', state.parameters);
  },
  postAssignment({ state, dispatch }, settlement) {
    const data = {
      data: [{
        userName: settlement.userName,
        taskId: settlement.task,
        scid: settlement.scid,
        publicationCycle: settlement.publicationCycle,
        effectiveStartDate: getMomentDateString(settlement.effectiveStartDate),
        effectiveEndDate: getMomentDateString(settlement.effectiveEndDate),
      }],
    };
    CISO_SAMC_API.post('/settlementApproval/addTask', data).then(() => {
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
    if (state.parameters != null) {
      dispatch('fetchSettlementReview', state.parameters);
    }
  },
};

const mutations = {
  setTableData(state, tableData) {
    state.tableData = tableData;
  },
  changeDateRangeMutation(state, value) {
    state.dateRange = value;
  },
  loadStatusList(state, status) {
    state.statusList = status;
  },
  loadTaskList(state, taskList) {
    state.taskList = taskList;
  },
  loadUserList(state, users) {
    state.userList = users;
  },
  loadCoordinatorList(state, Coordinator) {
    state.coordinatorList = Coordinator;
  },
  loadPublicationCycles(state, Cycles) {
    state.publicationCycles = Cycles;
  },
  saveParameters(state, parameters) {
    state.parameters = parameters;
  },
  currentCellChanged(state, cell) {
    state.currentCell = cell;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setStatusFlag(state, value) {
    state.statusViewFlag = value;
    if (value === true) {
      state.tableConfig[state.tableConfig.indexOf(state.tableConfig.find((x) => x.caption === 'StatusID'))].area = 'filter';
      state.tableConfig[state.tableConfig.indexOf(state.tableConfig.find((x) => x.caption === 'Assignee'))].area = 'data';
    } else {
      state.tableConfig[state.tableConfig.indexOf(state.tableConfig.find((x) => x.caption === 'StatusID'))].area = 'data';
      state.tableConfig[state.tableConfig.indexOf(state.tableConfig.find((x) => x.caption === 'Assignee'))].area = 'filter';
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
