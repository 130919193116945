import { WORKFLOW_API, STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import { workflow, accessRight } from '@/auth/permission';

const state = {
  workflows: [],
  customWorkflows: [],
  date: dateStore.getDefaultDateLocal().toISOString(),
};

const getters = {
  workflowList: (state) => state.workflows.map(({ name, description }) => ({ value: name, label: description })),
  customWorkflowList: (state) => state.customWorkflows.map(({ name }, idx) => ({ value: idx, label: name })),
  hasWritePermission(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], workflow.dashboard, accessRight.write);
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchWorkflows');
    dispatch('fetchCustomWorkflows');
  },
  fetchWorkflows({ commit }) {
    WORKFLOW_API.get().then(({ data }) => {
      commit('setWorkflows', data.workflows);
    }).catch((error) => console.log(error));
  },
  createCustomWorkflow({ dispatch, state }, payload) {
    const customWorkflows = [...state.customWorkflows];
    customWorkflows.splice(payload.order - 1, 0, payload);
    dispatch('apiUpdateWorkflow', customWorkflows);
  },
  applyWorkflow({ state }, workflow) {
    let params = {};
    const tradeDate = dateStore.toDateFromLocal(state.date).startOf('day').toISOString();
    params = { tradeDate };
    WORKFLOW_API.post(`${workflow.name}/instances`, params).then(({ data }) => {
      console.log(data);
    });
  },
  apiUpdateWorkflow({ commit }, customWorkflows) {
    customWorkflows.forEach((flow, idx) => {
      flow.order = idx + 1;
    });
    STRUCTURES_API.put('usercollections/options/resources/custom-workflows', { customWorkflows }).then(({ data }) => {
      if (data && data.customWorkflows) commit('setCustomWorkflows', data.customWorkflows);
    });
  },
  updateCustomWorkflow({ dispatch, state }, { data, idx }) {
    const customWorkflows = [...state.customWorkflows];
    customWorkflows.splice(idx, 1);
    customWorkflows.splice(data.order, 0, data);
    dispatch('apiUpdateWorkflow', customWorkflows);
  },
  deleteCustomWorkflow({ dispatch, state }, flowIdx) {
    const customWorkflows = [...state.customWorkflows];
    customWorkflows.splice(flowIdx, 1);
    dispatch('apiUpdateWorkflow', customWorkflows);
  },
  async fetchCustomWorkflows({ commit }) {
    // await STRUCTURES_API.delete('usercollections/options');
    try {
      await STRUCTURES_API.get('usercollections/options');
      const { data } = await STRUCTURES_API.get('usercollections/options/resources/custom-workflows');
      if (data && data.customWorkflows) commit('setCustomWorkflows', data.customWorkflows);
    } catch ({ response }) {
      if (response.status === 404) {
        await STRUCTURES_API.post('usercollections/options', {});
        await STRUCTURES_API.post('usercollections/options/resources/custom-workflows', { customWorkflows: [] });
      }
    }
  },
};

const mutations = {
  setWorkflows(state, value) {
    state.workflows = value;
  },
  setCustomWorkflows(state, value) {
    state.customWorkflows = value.sort((a, b) => a.order - b.order);
  },
  setDate(state, value) {
    state.date = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
