import { STRUCTURES_API, CMS_API } from '@/api';
import { saveAs } from '@/utils/dataUtil';

const state = {
  currentAttachmentRow: null,
  tableConfig: {
    columns: [{
      prop: 'contractNumber',
      label: 'Contract Number',
      filterable: true,
      sortable: true,
      alignment: 'left',
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ContractDetails',
        params: [{ key: 'guid', dataKey: 'guid' }],
      },
    }, {
      prop: 'counterParty',
      label: 'Counter Party',
      filterable: true,
      sortable: true,
      alignment: 'left',
    }, {
      prop: 'displayName', label: 'Name', filterable: true, alignment: 'left',
    }, {
      prop: 'fileSize', label: 'File Size', filterable: true, alignment: 'right', dataType: 'number', format: { type: 'fixedPoint', precision: 0 },
    }, {
      prop: 'fileType', label: 'File Type', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      alignment: 'left',
      dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      alignment: 'left',
      dataType: 'datetime',
    }],
    options: {
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
    },
  },
  tableData: [],
  assignedContractConfig: {
    columns: [{
      prop: 'versionId', label: 'Version', sortable: true, alignment: 'left',
    }, {
      prop: 'shortName',
      label: 'Name',
      filterable: true,
      sortable: true,
      alignment: 'left',
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ContractDetails',
        params: [{ key: 'guid', dataKey: 'guid' }],
      },
    }, {
      prop: 'description', label: 'Description', filterable: true, alignment: 'left', sortable: true, 
    }, {
      prop: 'contractType',
      label: 'Contract Type',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'contractNumber', label: 'Contract Number', filterable: true, alignment: 'left', sortable: true,
    }, {
      prop: 'status',
      label: 'Contract State',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'company',
      label: 'Company',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'counterParty',
      label: 'Counter Party',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'currencyName',
      label: 'Currency',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'paymentTerm',
      label: 'Payment Terms',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'effectiveDate',
      label: 'Effective Date',
      filterable: true,
      alignment: 'left',
      sortable: true,
      dataType: 'datetime',
    }, {
      prop: 'terminationDate',
      label: 'Termination Date',
      filterable: true,
      alignment: 'left',
      sortable: true,
      dataType: 'datetime',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left', sortable: true,
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
      sortable: true,
    }],
    options: {
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
    },
  },

};

const actions = {
  async fetchTableData({ commit, dispatch, state }) {
  await STRUCTURES_API.get('/attachments/contract').then(({ data }) => {
      dispatch('fetchContractInformation', data);
    }).catch((err) => {
      this.$notify('Failed to Load Attachments', 'error');
    });
  },
  attachmentRowChange({ dispatch, commit, state }, attachment) {
    commit('attachmentRowChange', attachment);
  },
  async fetchContractInformation({ state, commit, dispatch }, attachments) {
    const contractIds = [...new Set(attachments.map(({ referenceId }) => referenceId))];
    const contractList = await Promise.all(
      contractIds.map(id => CMS_API.get('contracts', { params: { id } })),
    );
    commit('setTableData', { attachments, contracts: contractList.flatMap(({ data }) => data.contracts) });
  },
  downloadAttachment({ dispatch, commit, state }) {
    STRUCTURES_API.get(`/attachments/contract/attachmentId/${state.currentAttachmentRow.id}`).then(({ data }) => {
      const { fileData, fileName, fileType } = data;

      saveAs(fileData, fileName, fileType);
      this.$notify(`Downloaded ${fileName}`, 'success');
    }).catch((err) => {
      this.$notify('Failed to Download Attachment', 'error');
    });
  },

};

const mutations = {
  attachmentRowChange(state, attachment) {
    state.currentAttachmentRow = attachment;
  },
  setTableData(state, { attachments, contracts }) {
    state.tableData = attachments.map((attachment) => {
      const contract = contracts.find(({ id }) => id === attachment.referenceId);
      return { ...contract, ...attachment, id: attachment.id };
    });
  },
};


export default {
  namespaced: true,
  state,
  actions,
  mutations,
};