import { DefaultOptions } from "./defaultOptions";

export class ETagOptions extends DefaultOptions {

  constructor() {
    super('ETAG');
    this.configurations = [{
      name: 'MarketType',
      label: 'Filter on Market Type',
      type: 'tagbox',
      clearable: false,
      value: ['DAM' , 'RTM' , 'ATF'],
      options: [{
        value: 'DAM',
        label: 'Day-Ahead',
      }, {
        value: 'RTM',
        label: 'Real-Time',
      }, {
        value: 'ATF',
        label: 'After-the-Fact',
      }]
    }];
  }
}