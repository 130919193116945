import { tradeDateRange, balancingAreaAuthority } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'RTD EXPORT',
    dataType: 'number',
    summaryType: 'sum',
    format: '#,##0.####',
    dataField: 'rtD_Export',
    area: 'data',
    expanded: true,
  }, {
    caption: 'RTD IMPORT',
    dataType: 'number',
    summaryType: 'sum',
    format: '#,##0.####',
    dataField: 'rtD_Import',
    area: 'data',
    expanded: true,
  }, {
    caption: 'RTPD EXPORT',
    dataType: 'number',
    summaryType: 'sum',
    format: '#,##0.####',
    dataField: 'rtpD_Export',
    area: 'data',
    expanded: true,
  }, {
    caption: 'RTPD IMPORT',
    dataType: 'number',
    summaryType: 'sum',
    format: '#,##0.####',
    dataField: 'rtpD_Import',
    area: 'data',
    expanded: true,
  }, {
    caption: 'REPORT',
    dataType: 'string',
    dataField: 'report',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TIE NAME',
    dataType: 'string',
    dataField: 'tiE_NAME',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'trading_Hour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'FROM BAA',
    dataType: 'string',
    dataField: 'froM_BAA',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataType: 'string',
    dataField: 'trading_Date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TO BAA',
    dataType: 'string',
    dataField: 'tO_BAA',
    area: 'column',
    expanded: true,
  }],
  searchFilterConfig: {
    tradeDateRange,
    balancingAreaAuthorityList: balancingAreaAuthority,
    marketTypeList: {
      label: 'Market Type',
      value: 'ALL',
      type: 'select',
      options: [
        { value: 'ALL', label: 'ALL' },
        { value: 'RTD', label: 'RTD' },
        { value: 'RTPD', label: 'RTPD' },
      ],
    },
    isWeekEndOnly: {
      label: 'Weekend Only',
      value: false,
      type: 'checkbox',
    },
  },
};