import unmatchedDeals from './UnmatchedDeals/unmatchedDealsStore';
import positionManagement from './PositionManagement/store';
import dmSettings from './Settings/dmSettingsStore';

export default {
  namespaced: true,
  modules: {
    unmatchedDeals,
    positionManagement,
    dmSettings,
  },
};