<template>
  <el-button type="text" size="mini" @click="setLossAccounting({ visible: true, rowIndex: scope.rowIndex, row: scope.row, column: column})">
    {{ count }}
  </el-button>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('etag/tag');

export default {
  name: 'TagLossAccountingLink',
  props: ['scope', 'column'],
  computed: {
    count() {
      return this.scope.row[this.column.prop];
    },
  },
  methods: mapActions({
    setLossAccounting: 'changeLossAccounting',
  }),
};
</script>
