import bidStatusStore from './Bid/Status/store';
import bidDetailStore from './Bid/Detail/store';
import bidHorizontalDetailStore from './Bid/BidHorizontalDetail/store';
import baseScheduleDetailStore from './BaseSchedule/Detail/store';
import baseScheduleSummaryStore from './BaseSchedule/Summary/store';
import baseScheduleHorizontalDetailStore from './BaseSchedule/BaseScheduleHorizontalDetail/store';
import bidErrorsStore from './BaseSchedule/BidErrors/store';
import tradeStore from './Trades/Submission/store';
import transactionStore from './Dialogs/CreateTransactionDialog/store';
import BlotterStore from './Blotter/BlotterStore';
import consolidatedDetailStore from './ConsolidatedDetail/store';
import consolidatedSchedulingStore from './ConsolidatedSchedule/store';
import virtualScheduleDetailStore from './Virtual/Detail/store';
import istScheduleDetailStore from './Trades/Submission/Detail/IstDetailStore';
import resourceAvailabilityStore from './ResourceAvailability/store';
import schedulingDownloadDialogStore from './Dialogs/SchedulingDownloadDialog/store';

export default {
  namespaced: true,
  state: {},
  modules: {
    bidding: {
      namespaced: true,
      state: {},
      modules: {
        status: bidStatusStore,
        detail: bidDetailStore,
        summary: baseScheduleSummaryStore,
        bidSummary: bidHorizontalDetailStore,
        baseDetail: baseScheduleDetailStore,
        baseHorizontalDetail: baseScheduleHorizontalDetailStore,
        bidErrors: bidErrorsStore,
        trades: tradeStore,
        transactions: transactionStore,
        scheduling: consolidatedSchedulingStore,
        virtualDetail: virtualScheduleDetailStore,
        istDetail: istScheduleDetailStore,
        resourceAvailability: resourceAvailabilityStore,
      },
    },
    blotter: BlotterStore,
    consolidatedDetail: consolidatedDetailStore,
    dialogs: {
      namespaced: true,
      state: {},
      modules: {
        schedulingDownload: schedulingDownloadDialogStore,
      },
    },
  },
};