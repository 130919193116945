import moment from 'moment';
import utils from '@/utils';
import { has, clone } from '@/utils/dataUtil';
import { ETRM_API } from '@/api';
import dateStore from '@/utils/dateStore';

const state = {
  notificationCallback: null,
  filterDate: null,
  tableKey: -999,
  currentTableRow: null,
  deleteCache: [],
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'runFlag', label: 'Run?', width: 60, checkboxSelection: true,
    }, {
      prop: 'id', label: 'Run Id', filter: true, width: 60, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'systemDate',
      label: 'System Dt',
      filter: true,
      width: 125,
      editable: false,
      cellClass: 'cell-uneditable',
      cellEditor: 'dateCellEditor',
      valueFormatter: function dateValueGetter(params) {
        if (params.value == null || params.value == undefined) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD');
      },
    }, {
      prop: 'intrnlCompany',
      label: 'Intrnl Company',
      filter: true,
      width: 150,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'intrnlCompany', placeholder: 'Intrnl Company', clearable: false },
    }, {
      prop: 'commodityName',
      label: 'Commodity',
      filter: true,
      width: 150,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'commodity', placeholder: 'Commodity', clearable: true },
    }, {
      prop: 'marketName',
      label: 'Market',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'market', placeholder: 'Market', clearable: false },
    }, {
      prop: 'dealType',
      label: 'Deal Type',
      filter: true,
      width: 100,
      editable: true,
      cellEditor: 'selectCellEditor',
      cellEditorParams: { list: 'dealType', placeholder: 'Deal Type', clearable: false },
    }, {
      prop: 'runStartTime',
      label: 'Run Start',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value == null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm:ss');
      },
    }, {
      prop: 'runEndTime',
      label: 'Run End',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value == null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm:ss');
      },
    }, {
      prop: 'createdBy', label: 'Created By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'createdDate',
      label: 'Created Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value == null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    }, {
      prop: 'updatedBy', label: 'Updated By', filter: true, width: 100, editable: false, cellClass: 'cell-uneditable',
    }, {
      prop: 'updatedDate',
      label: 'Updated Dt',
      filter: true,
      width: 100,
      editable: false,
      cellClass: 'cell-uneditable',
      valueFormatter: function dateValueGetter(params) {
        if (params.value == null) {
          return;
        }
        const momentTm = moment(params.value);
        return momentTm.format('YYYY-MM-DD HH:mm');
      },
    }],
    columnList(name, row) {
      if (name === 'commodity') return this.registry.energyCommodityList;
      if (name === 'intrnlCompany') return this.registry.intrnlCompanyList;
      if (name === 'company') return this.registry.companyList;
    },
    columnMapList(watcher, row) {
      const columnMappings = [{}];
      return utils.data.columnSelectList(columnMappings, watcher, row, watcher.filterProperty, watcher.valueProperty, watcher.labelProperty);
    },
    options: { searchFilter: false },
    registry: {
      energyCommodityList: [],
      companyList: [],
      intrnlCompanyList: [],
    },
  },
  nullTableRow: {
    id: null,
    versionId: null,
    company: null,
    intrnlCompany: null,
    commodityName: null,
    reviewDate: null,
    creditRating: null,
    buyLimit: null,
    sellLimit: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    recordState: 1,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getFilterDate: (state) => state.filterDate,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('retrieveCompanyList');
    dispatch('retrieveEnergyCommodityList');
    // dispatch('loadTableData');
  },
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  changeFilterDateAction({ commit, dispatch }, value) {
    commit('changeFilterDateMutation', value);
  },
  reset({ commit, dispatch }) {
    commit('setNotificationCallback', null);
    commit('reset');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  async loadTableData({ dispatch, commit, state }) {
    console.log('loading table data');
    const tz = dateStore.getTimeZone();
    const moment = dateStore.toMomentAndZoneFromJSDate(state.filterDate, tz);
    const sysDate = moment.utc(); console.log('date out'); console.log(sysDate.format());
    const response = await ETRM_API.get(`/m2m-run-books/systemDate/${sysDate.format()}`);
    const { data } = response; console.log('system date'); console.log(sysDate.format());
    commit('loadTableData', data);
  },
  changeTableData({ dispatch, commit, state }, item) {
    commit('changeTableData', item);
  },
  insertTableRow({ dispatch, commit, state }) {
    commit('insertTableRow');
  },
  deleteTableRow({ dispatch, commit, state }) {
    commit('deleteTableRow');
  },
  async saveTableData({ dispatch, commit, state }) {
    const dirtyRecs = state.tableData.filter((val) => val.recordState != 0);
    let outputMsg = null;
    let success = 0;
    let failure = 0;
    console.log('dirtyRecs'); console.log(JSON.stringify(dirtyRecs));
    for (let d = 0; d < state.deleteCache.length; d++) {
      const deleteData = state.deleteCache[d];
      const response = await ETRM_API.delete(`/m2m-run-books/${deleteData.id}`);

      if (response.status == 200) {
        success += 1;
        state.deleteCache.splice(deleteData, 1);
      } else {
        outputMsg = `Save record(s) failed to post delete. ${response.statusText}`;
        break;
      }
    }
    for (let i = 0; i < dirtyRecs.length; i++) {
      const data = dirtyRecs[i];
      console.log('data'); console.log(data);
      if (data.recordState == 1) {
        const response = await ETRM_API.post('/m2m-run-books', data);
        const returnRecord = response.data; console.log('response out'); console.log(response);
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id == data.id);
          console.log(`idx=${idx}`);
          state.tableData.splice(idx, 1, returnRecord);
          console.log('return record out'); console.log(returnRecord);
          if (returnRecord.validationMessage == null) {
            success += 1;
          } else {
            failure += 1;
          }
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      } else if (data.recordState == 2) {
        const response = await ETRM_API.put(`/m2m-run-books/${data.id}`, data);
        const returnRecord = response.data; console.log('response out'); console.log(response);
        if (response.status === 200) {
          const idx = state.tableData.findIndex((x) => x.id == data.id);
          state.tableData.splice(idx, 1, returnRecord);
          console.log('return record out'); console.log(returnRecord);
          if (returnRecord.validationMessage == null) {
            success += 1;
          } else {
            failure += 1;
          }
        } else {
          outputMsg = `Save record(s) failed to post. ${response.statusText}`;
          break;
        }
      }
      outputMsg = `${success} record(s) saved successfully. `;
      if (failure > 0) {
        outputMsg = `${outputMsg + failure} record(s) failed to save due to error.`;
      }
    }
    console.log(`outputMsg=${outputMsg}`);
    return new Promise((resolve, reject) => {
      resolve(outputMsg);
    });
  },
  async runM2M({ dispatch, commit, state }, selectedRows) {
    let outputMsg = null;
    let success = 0;
    let failure = 0;
    for (let i = 0; i < selectedRows.length; i++) {
      const data = selectedRows[i];
      console.log('data'); console.log(data);
      const response = await ETRM_API.post('/m2m-run-books/execute', data);
      const returnRecord = response.data; console.log('response out'); console.log(response);
      if (response.status === 200) {
        const idx = state.tableData.findIndex((x) => x.id == data.id);
        console.log(`idx=${idx}`);
        state.tableData.splice(idx, 1, returnRecord);
        console.log('return record out'); console.log(returnRecord);
        if (returnRecord.validationMessage == null) {
          success += 1;
        } else {
          failure += 1;
        }
      } else {
        outputMsg = `Save record(s) failed to post. ${response.statusText}`;
        break;
      }
    }
    console.log(`outputMsg=${outputMsg}`);
    return new Promise((resolve, reject) => resolve(outputMsg));
  },
  currentTableRowChange({ dispatch, commit, state }, selectedRows) {
    const currentRow = selectedRows[0];
    if (state.currentTableRow == undefined
           || (currentRow != undefined && currentRow.id != state.currentTableRow.id)) {
      state.currentTableRow = currentRow;
    }
  },
};

const mutations = {
  readyRegistryEnergyCommodityList(state) {
    state.tableConfig.registry.energyCommodityList = state.energyCommodityList.map((m) => ({ value: m.commodityName, label: m.commodityName }));
  },
  readyRegistryCompanyList(state) {
    state.tableConfig.registry.intrnlCompanyList = state.companyList
      .filter((val) => val.internalFlag)
      .map((m) => ({ value: m.shortName, label: m.shortName }));
    state.tableConfig.registry.companyList = state.companyList
      .map((m) => ({ value: m.shortName, label: m.shortName }));
  },
  reset(state) {

  },
  changeFilterDateMutation(state, value) {
    state.filterDate = value;
  },
  setNotificationCallback(state, callback) {
    state.notificationCallback = callback;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = null;
  },
  loadTableData(state, model) {
    let markToMarketRunBooks = [];
    if (model !== null && has(model, 'markToMarketRunBooks')) {
      markToMarketRunBooks = model.markToMarketRunBooks;
    }
    state.tableData = markToMarketRunBooks;
  },
  changeTableData(state, item) {
    const companyCreditLimit = state.tableData[item.rowIndex];
    console.log(`setting property ${item.prop} with value ${item.value}`); console.log(item);
    if (companyCreditLimit.recordState === 0 && companyCreditLimit[item.prop] !== item.value) {
      companyCreditLimit.recordState = 2;
      companyCreditLimit.updatedBy = this.getters['auth/getProfile'].userName;
      companyCreditLimit.updatedDate = utils.date.getNow().utc().format();
    }
    companyCreditLimit[item.prop] = item.value;
  },
  insertTableRow(state) {
    const companyCreditLimit = clone(state.nullTableRow);
    companyCreditLimit.creditLimitId = state.tableKey++;
    companyCreditLimit.versionId = 1;
    companyCreditLimit.recordState = 1;
    companyCreditLimit.createdBy = this.getters['auth/getProfile'].userName;
    companyCreditLimit.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = companyCreditLimit;
    state.tableData.splice(0, 0, companyCreditLimit);
  },
  deleteTableRow(state) {
    let companyCreditLimit = null;
    for (let t = 0; t < state.tableData.length; t++) {
      companyCreditLimit = state.tableData[t];
      if (companyCreditLimit.creditLimitId == state.currentTableRow.creditLimitId) {
        companyCreditLimit.recordState = 3;
        companyCreditLimit.isActive = false;
        companyCreditLimit.updatedBy = this.getters['auth/getProfile'].userName;
        companyCreditLimit.updatedDate = utils.date.getNow().utc().format();
        state.deleteCache.push(companyCreditLimit);
        state.tableData.splice(t, 1);
        break;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};