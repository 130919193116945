export default {
    namespaced: true,

    state: {
        alertCallback: null
    },
    actions: {
        setCallback({commit}, cb){
            commit('setCallback', cb);
        },
        success({commit}, msg){
            commit('send', {...msg, type: 'success'});
        },
        info({commit}, msg){
            commit('send', {...msg, type: 'info'});
        },
        warning({commit}, msg){
            commit('send', {...msg, type: 'warning'});
        },
        error({commit}, msg){
            commit('send', {...msg, type: 'error'});
        }
    },
    mutations: {
        setCallback(state, cb){
            state.alertCallback = cb;
        },
        send(state, msg){
            if(state.alertCallback != null) {
                state.alertCallback(msg);
            }
        }
    }
}