import { ETAG_API } from '@/api';

export function requestData(uri, params, dataProperty, map) {
  return new Promise((resolve, reject) => {
    ETAG_API.get(uri, { params })
      .then((response) => {
        const list = response.data[dataProperty];
        resolve(list.map(map));
      })
      .catch((err) => {
        if (err.response) console.log(err.response);
        else console.log(err);

        resolve([]);
      });
  });
}

function queryPSEs({ id = null, name = null, eq = false } = {}) {
  const params = {};

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (eq) params.eq = eq;

  return requestData('/registry/pses', params, 'pseList', (m) => ({ value: m.code, label: m.code }));
}

function queryBAs({ id = null, name = null, eq = false } = {}) {
  const params = {};

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (eq) params.eq = eq;

  return requestData('/registry/bas', params, 'baList', (m) => ({ value: m.code, label: m.code }));
}

function querySources({
  id = null, pse_id = null, ba_id = null, name = null, eq = false, select_by = null,
} = {}) {
  const params = {};

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (pse_id !== null) params.pse_id = pse_id;
  if (ba_id !== null) params.ba_id = ba_id;
  if (eq) params.eq = eq;
  if (select_by !== null) params.select_by = select_by;

  return requestData('/registry/sources', params, 'sourceList', (m) => ({ value: m.name, label: m.name }));
}

function querySinks({
  id = null, pse_id = null, ba_id = null, name = null, eq = false, select_by = null,
} = {}) {
  const params = {};

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (pse_id !== null) params.pse_id = pse_id;
  if (ba_id !== null) params.ba_id = ba_id;
  if (eq) params.eq = eq;
  if (select_by !== null) params.select_by = select_by;

  return requestData('/registry/sinks', params, 'sinkList', (m) => ({ value: m.name, label: m.name }));
}

function queryTPs({ id = null, name = null, eq = false } = {}) {
  const params = {};

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (eq) params.eq = eq;

  return requestData('/registry/tps', params, 'tpList', (m) => ({ value: m.code, label: m.code }));
}

function queryPORs({
  id = null, name = null, tsp_id = null, eq = null,
} = {}) {
  const params = { type: 'por', select_by: 'por' };

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (eq) params.eq = eq;
  if (tsp_id !== null) params.tsp_id = tsp_id;

  return requestData('/registry/porpods', params, 'porpodList', (m) => ({ value: m.name, label: m.name }));
}

function queryPODs({
  id = null, name = null, tsp_id = null, eq = null,
} = {}) {
  const params = { type: 'pod', select_by: 'pod' };

  if (id !== null) params.id = id;
  if (name !== null) params.name = name;
  if (eq) params.eq = eq;
  if (tsp_id !== null) params.tsp_id = tsp_id;

  return requestData('/registry/porpods', params, 'porpodList', (m) => ({ value: m.name, label: m.name }));
}

export const REGISTRY_API = {
  queryPSEs,
  querySources,
  querySinks,
  queryBAs,
  queryTPs,
  queryPORs,
  queryPODs,
};
