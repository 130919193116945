import dateStore from '@/utils/dateStore';
import { PPA_INVOICE_API } from '@/api';

const state = {
  tableData: [],
  selectedRow: {},
  searchParams: {
    status: null,
    year: dateStore.getDefaultDate().toISOString(),
  },
};

const actions = {
  async fetchAccountPeriods({ commit, state }, args = {}) {
    const params = {
      status: state.searchParams.status,
      year: dateStore.toMoment(state.searchParams.year).year(),
    };
    try {
      const { data: { data } } = await PPA_INVOICE_API.get('/subledgers/account-period', { params });
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async updateAccountPeriod({ commit, state }) {
    const params = {
      ...state.selectedRow,
    };
    params.status = params.status === 'CLOSED' ? 'OPEN' : 'CLOSED';
    try {
      const { data } = await PPA_INVOICE_API.put('/subledgers/account-period/update', params);
      commit('updateTable', data);
      this.$notify('Record Updated Successfully', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Record', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setSelectedRow(state, value) {
    state.selectedRow = value;
  },
  updateTable(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  reset(state) {
    state.tableData = [];
    state.selectedRow = {};
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};