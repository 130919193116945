import notification from './UserNotification/store';
import general from './UserGeneral/store';
import workflowDashboard from './WorkflowDashboard/store';
import valueFormatting from './ValueFormatting/store';

export default {
  namespaced: true,
  modules: {
    notification,
    general,
    workflowDashboard,
    valueFormatting,
  },
};