import eimTransferTie from './EIMTransferTie/eimTransferTieStore';
import elapPrices from './ELAPPrices/elapPricesStore';
import transTieReport from './TransTieReport/transTieReportStore';
import eimDemandForecast from './EIMDemandForecast/eimDemandForecastStore';
import sufficiencyEvaluationForecast from './SufficiencyEvaluationForecast/sufficiencyEvaluationForecastStore';
import oasisPrices from './OasisPrices/oasisPricesStore';

export default {
  namespaced: true,
  modules: {
    eimTransferTie,
    elapPrices,
    transTieReport,
    eimDemandForecast,
    sufficiencyEvaluationForecast,
    oasisPrices,
  },
};