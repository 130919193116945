import { CISO_BASE_SCHD_API, CISO_MASTERDATA_API } from '@/api';
import Vue from 'vue';
import utils from '@/utils';
import { deepClone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import {
  mapLabelValue, setBaseScheduleDefaults, filterScheduleData, deconstructScheduleName,
} from '@/components/Scheduling/utils';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import bidErrorStore from './BidErrorStore';

const { setAllUneditable, mergeByPropList } = utils.data;

const emptySchedule = (state) => ({
  sc: state.sc,
  bsc: state.bsc,
  resource: state.locationName,
  resourceType: state.locationType,
  marketType: state.marketType,
  startTime: state.startTime,
  endTime: state.endTime,
  baseSchedulePoints: [],
  configuration: state.configuration,
  variant: undefined,
});

const getSchedule = async (scheduleName) => {
  let actual = null;
  let variant = null;
  let scheduleStatus = null;

  try {
    const bidVariant = await CISO_BASE_SCHD_API.get(`/${scheduleName}`, { params: { variant: 'ACTUAL' } });
    actual = bidVariant.data;
    scheduleStatus = actual.scheduleStatus;
    variant = 'ACTUAL';
  } catch (ex) {
    console.error(`Unable to retrieve schedule with variant ACTUAL. ${ex}`);
  }

  return { actual, variant, scheduleStatus };
};

const state = {
  bidError: bidErrorStore.state,
  scheduleName: '',
  marketType: '',
  locationType: null,
  variant: '',
  startTime: '',
  endTime: '',
  sc: '',
  date: '',
  locationName: null,
  scheduleStatus: null,
  location: null,
  scheduleType: true,
  bidsVariantSchedule: null,
  proposedVariantSchedule: null,
  configuration: '',
  pMin: 0,
  pMax: 0,
  mode: null,
  configurations: [],
  transitions: [],
  baseSchedulePoints: [],
  bsc: '',
  nullTransitionRow: {
    rowIndex: null,
    startTime: null,
    endTime: null,
    fromConfiguration: null,
    toConfiguration: null,
    notificationTime: null,
    transitionCost: null,
    transitionRampTime: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  tableConfigurationReady: {
    baseSchedulePointTableReady: false,
  },
  transitionMatrixVisibilityFlag: false,
  baseSchedulePointTableConfig: {
    columns: [],
  },
  bidCurveTableConfig: {
    columns: [],
  },
  transitionMatrixTableConfig: {
    columns: [],
    columnList(name, row) {
      if (name === 'configurations') {
        return this.configurations;
      }
      return null;
    },
  },
};

const getters = {
  hasWritePermissionFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
  },
  hasBidStrategies: (state) => false,
  getBidErrorTableData: (state) => bidErrorStore.getters.getTableData(state.bidError),
  getBidErrorData: (state) => bidErrorStore.getters.getData(state.bidError),
  getBaseSchedulePointTableConfiguration(state, getters, rootState, rootGetters) {
    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write');
    if (writePermission && state.scheduleType) { return state.baseSchedulePointTableConfig; }
    return setAllUneditable(state.baseSchedulePointTableConfig);
  },
  getTransitions: (state) => state.transitions,
  getPMin: (state) => state.pMin,
  getPMax: (state) => state.pMax,
};

const actions = {
  ...bidErrorStore.actions,
  reset({ commit }) {
    commit('reset');
    bidErrorStore.mutations.reset(state.bidError);
  },
  async loadScheduleAction({ dispatch, commit, state }, params) {
    commit('setLocationType', params.locationType);
    commit('scheduleNameMutation', params.scheduleName);

    let location = null;
    try {
      location = await dispatch('REFERENCE_DATA_STORE/fetchLocation', {
        market: 'CAISO', locationType: state.locationType, locationName: state.locationName,
      });
    } catch (ex) {
      console.error(`Unable to retrieve location: ${ex}`);
    }

    commit('setLocation', location);
    commit('setPMinPMax', location);
    dispatch('configurationChanged', params.configuration);

    const schedule = await getSchedule(params.scheduleName);

    commit('loadBidsScheduleMutation', schedule.actual);
    commit('setVariant', schedule.variant);
    commit('setScheduleStatus', schedule.scheduleStatus);
    dispatch('filterTableData');
  },
  changeVariantAction({ commit, state }, item) {
    commit('variantMutation', item);
  },
  changeBaseSchedulePointAction({ commit }, item) {
    commit('baseSchedulePointMutation', item);
  },
  async save({ dispatch, state }) {
    dispatch('filterTableData');
    const schedule = deepClone(state.bidsVariantSchedule);
    if (Array.isArray(state.transitions)) schedule.transitions = state.transitions;

    if (state.mode === 'NEW') {
      await CISO_BASE_SCHD_API.post('', schedule);
    } else {
      await CISO_BASE_SCHD_API.put(`${schedule.scheduleName}`, schedule);
    }
  },
  async createParentTransitions({ commit, dispatch, state }) {
    try {
      const { data: { data } } = CISO_MASTERDATA_API.get(`/resources/${state.locationName}/transitions`);
      if (Array.isArray(data?.resourceTransitions)) {
        const schedule = {
          ...emptySchedule(state),
          configuration: null,
          transitions: data.resourceTransitions.map(({ transition }) => ({
            startTime: state.startTime,
            endTime: state.endTime,
            fromConfiguration: transition.configurationIdFrom,
            toConfiguration: transition.configurationIdTo,
            cost: transition.transitionCost,
            rampTime: transition.transitionRampTime,
            notificationTime: transition.notificationTime,
          })),
        };
        dispatch('createParentSchedule', schedule);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async createParentSchedule({ commit }, schedule) {
    try {
      const { data } = CISO_BASE_SCHD_API.post(`?variant=${'ACTUAL'}`, schedule);
      commit('setParentScheduleTransitions', data);
    } catch (error) {
      console.error(error);
    }
  },
  changeTransitionMatrixAction({ commit }, item) {
    commit('setTransitionMatrixData', item);
  },
  transitionRowOperation({ commit }, item) {
    if (item.type === 'ADD') {
      commit('addTransition', { index: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      commit('removeTransition', { index: item.rowIndex });
    }
  },
  async loadParentScheduleTransitions({ commit, dispatch }, scheduleName) {
    try {
      if (scheduleName[scheduleName.length - 1] === '*') {
        scheduleName = scheduleName.substring(0, scheduleName.length - 1);
      }
      scheduleName = scheduleName.substring(0, scheduleName.lastIndexOf('-'));
      const params = { variant: 'ACTUAL' };
      const { data } = await CISO_BASE_SCHD_API.get(`/${scheduleName}`, { params });
      commit('setParentScheduleTransitions', data);
    } catch (error) {
      console.error('Creating Parent transitions');
      dispatch('createParentTransitions');
    }
  },
  // FIX: Issue 1255
  // fired if the bid schedule table table has loaded a config from the database
  bidTableConfigLoaded({ dispatch, commit, state }, data) {
    commit('setBidTableConfigMutation', data);
  },
  baseSchedulePointTableConfigLoaded({ commit }, data) {
    commit('setBaseSchedulePointTableConfigMutation', data);
  },
  scheduleTypeChanged({ commit }, item) {
    commit('setScheduleType', item);
  },
  configurationChanged({ dispatch, commit, state }, item) {
    let id = item;
    if (item) {
      const configuration = state.location.locationSubtypes.find((x) => x.id === item || x.shortName === item);
      id = configuration.id;
      commit('setPMinPMax', configuration);
    } else {
      commit('setPMinPMax', state.location);
    }

    commit('setConfiguration', id);
    dispatch('filterTableData');
  },
  filterTableData({ state }) {
    state.baseSchedulePoints = filterScheduleData(state, 'baseSchedulePoints');
  },
};

const mutations = {
  ...Object.entries(bidErrorStore.mutations).reduce((acc, [mutation, func]) => ({
    ...acc,
    [mutation]: (state, value) => func(state.bidError, value),
  }), {}),

  reset(state) {
    state.bidsVariantSchedule = null;
    state.proposedVariantSchedule = null;
    state.baseSchedulePoints = [];
    state.transitions = [];
    state.transitionMatrixVisibilityFlag = false;
    state.configuration = null;
    state.variant = undefined;
    state.pMin = 0;
    state.pMax = 0;
    state.tableConfigurationReady.baseSchedulePointTableReady = false;
    state.tableConfigurationReady.bidTableReady = false;
  },
  setLocationType(state, item) {
    state.locationType = item;
  },
  setLocation(state, location) {
    state.location = location;
    if (location?.locationSubtypes) {
      state.configurations = mapLabelValue(location.locationSubtypes, 'id', 'shortName');
    }
  },
  setPMinPMax(state, item) {
    state.pMin = Number(item?.parameters?.find((x) => x.name === 'PMIN')?.value);
    state.pMax = Number(item?.parameters?.find((x) => x.name === 'PMAX')?.value);
  },
  scheduleNameMutation(state, name) {
    const propList = ['bsc', 'sc', 'marketType', 'date', 'location'];
    const item = deconstructScheduleName(name, propList);

    state.bsc = item.bsc;
    state.sc = item.sc;
    state.scheduleName = name;
    state.locationName = item.location;
    state.marketType = item.marketType;
    state.date = `${item.date.substring(0, 4)}-${item.date.substring(4, 6)}-${item.date.substring(6, 8)}`;

    const mt = dateStore.toMomentFromStringWithFormat(state.date, 'YYYY-MM-DD');
    state.startTime = mt.toISOString();
    state.endTime = mt.clone().add(1, 'days').toISOString();
  },
  loadBidsScheduleMutation(state, item) {
    if (!item) state.mode = 'NEW'; else state.mode = 'EXISTING';
    const schedule = item || emptySchedule(state);
    const tableData = setBaseScheduleDefaults(schedule, state);

    const highlightStartTimes = new Set();
    if (tableData && tableData.validations) {
      const validations = tableData.validations.filter((o) => o.priority === -1);
      for (let i = 0; i < validations.length; i++) {
        const validation = validations[i];
        highlightStartTimes.add(validation.startTime);
      }
    }

    state.bidsVariantSchedule = tableData;

    if (schedule && schedule.transitions && schedule.transitions.length > 0) {
      for (let i = 0; i < schedule.transitions.length; i++) {
        const transition = schedule.transitions[i];
        transition.createdDate = dateStore.toMomentFromJSDate(transition.createdDate).format('YYYY-MM-DD');
        if (transition.updatedDate) {
          transition.updatedDate = dateStore.toMomentFromJSDate(transition.updatedDate).format('YYYY-MM-DD');
        }
        transition.rowIndex = i;
      }
      if (schedule.scheduleName.match(/-/g).length === 3) {
        state.transitions = schedule.transitions;
      }
    }

    bidErrorStore.mutations.setDataMutation(state.bidError, tableData.bidErrors);
  },
  loadProposedScheduleMutation(state, item) {
    let schedule = item;

    if (item === null) { schedule = emptySchedule(state); }

    schedule = setBaseScheduleDefaults(schedule, state);

    state.proposedVariantSchedule = schedule;
  },
  setScheduleType(state, item) {
    state.scheduleType = item;
  },
  baseSchedulePointMutation(state, item) {
    if (item.value) {
      if (item.value === '0' || parseFloat(item.value)) {
        item.value = parseFloat(parseFloat(item.value).toFixed(3));
      } else {
        const ss = state.baseSchedulePoints[item.rowIndex];
        ss[item.prop] = item.value;

        item.value = null;
      }
    }
    Vue.set(state.baseSchedulePoints[item.rowIndex], item.prop, item.value);
  },
  useProposedSchedule(state) {
    const current = state.bidsVariantSchedule;
    const proposed = state.proposedVariantSchedule;

    current.baseSchedulePoints = mergeByPropList(current.baseSchedulePoints, proposed.baseSchedulePoints, ['startTime']);
    state.bidsVariantSchedule = current;
  },
  setTransitionMatrixVisibilityFlag(state) {
    state.transitionMatrixVisibilityFlag = true;
  },
  setConfiguration(state, item) {
    state.configuration = item;
  },
  setMFResourceMutation(state, item) {
    state.mfResource = item;
  },
  setTransitionMatrixData(state, item) {
    if (state.transitions[item.rowIndex][item.prop] !== item.value) {
      if (item.prop === 'toConfiguration' || item.prop === 'fromConfiguration') {
        const value = state.transitionMatrixTableConfig.configurations.find((o) => o.value === item.value).label;
        state.transitions[item.rowIndex][item.prop] = value;
      } else {
        Vue.set(state.transitions[item.rowIndex], item.prop, item.value);
      }
    }
  },
  addTransition(state, item) {
    const data = [];
    for (let x = 0; x <= item.index; x++) {
      const transition = state.transitions[x];
      transition.rowIndex = x;
      data.push(transition);
    }
    const newTransition = deepClone(state.nullTransitionRow);
    newTransition.rowIndex = item.index;
    data.push(newTransition);

    for (let x = item.index + 1; x < state.transitions.length; x++) {
      data.push(state.transitions[x]);
    }

    state.transitions = data;
  },
  removeTransition(state, item) {
    const data = [];

    for (let x = 0; x < item.index; x++) {
      data.push(state.transitions[x]);
    }

    for (let x = item.index + 1; x < state.transitions.length; x++) {
      data.push(state.transitions[x]);
    }

    if (data.length === 0) {
      const newTransition = deepClone(state.nullTransitionRow);
      newTransition.rowIndex = 0;
      data.push(newTransition);
    }

    state.transitions = data;
  },
  setParentScheduleTransitions(state, item) {
    if (item && item.transitions && item.transitions.length > 0) {
      for (let i = 0; i < item.transitions.length; i++) {
        const transition = item.transitions[i];
        transition.createdDate = dateStore.toMomentFromJSDate(transition.createdDate).format('YYYY-MM-DD');
        if (transition.updatedDate) {
          transition.updatedDate = dateStore.toMomentFromJSDate(transition.updatedDate).format('YYYY-MM-DD');
        }

        transition.rowIndex = i;
      }
      state.transitions = item.transitions;
    }
  },
  setBaseSchedulePointTableConfigMutation(state, data) {
    state.baseSchedulePointTableConfig = data;
    state.tableConfigurationReady.baseSchedulePointTableReady = true;
  },
  setVariant(state, item) { state.variant = item; },
  setScheduleStatus(state, item) { state.scheduleStatus = item; },
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};