import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';

// if max pub is selected, this grabs the actual max pub value to use when filtering version
export function eimGetPub(selectedPub, publications) {
  if (selectedPub === 'MAX') {
    const filteredPublications = publications.filter(({ value }) => value !== selectedPub);
    const filteredPub = filteredPublications.sort((firstVal, nextVal) => {
      const firstValLower = firstVal.value.toLowerCase();
      const nextValLower = nextVal.value.toLowerCase();
      if (firstValLower < nextValLower) { return 1; }
      if (firstValLower > nextValLower) { return -1; }
      return 0;
    })[0];
    return filteredPub.value;
  } return selectedPub;
}

export function FileTypeHandler(params, withShadow = true, withWhatIf = true, withMonthly = true) {
  if (!params.coordinatorListSelected) { return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const [startDate, endDate] = params.tradeDateRangeSelected;
  let filteredHeaders = [];

  filteredHeaders = params.samcHeadersUnfiltered.filter(
    ({ dataSource, ba, fileType }) => dataSource === 'SaMC'
      && selectedCoordinatorBAs.includes(ba)
      && (!fileType.includes('SHADOW') || withShadow)
      && (!fileType.includes('WHATIF') || withWhatIf)
      && (!fileType.includes('MONTHLY') || withMonthly));

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null };
  }

  const start = moment(startDate).utc().startOf('day');
  const end = moment(endDate).utc().startOf('day');
  filteredHeaders = filteredHeaders.filter((header) => {
    const tradeDate = moment(header.tradeDate).utc().startOf('day');
    return moment(tradeDate).isBetween(start, end, null, '[]');
  });

  const filteredFileTypes = [...new Set(filteredHeaders.map(
    ({ fileType }) => fileType))].map((f) => ({ value: f, label: f }));
  const fileTypeSelected = filteredFileTypes.find((x) => x);
  const selectedValue = (fileTypeSelected) ? fileTypeSelected.value : null;

  return { filteredFileTypes, filteredHeaders, selectedValue };
}

// use this if fed by FileType dropdown. if only need Publication dropdown, use publicationOnlyHandler() instead
export function publicationHandler(params, fileTypeSelected) {
  if (!fileTypeSelected) { return { filteredPublications: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const [startDate, endDate] = params.tradeDateRangeSelected;

  let filteredHeaders = params.samcHeadersUnfiltered.filter(
    ({ dataSource, ba, fileType }) => dataSource === 'SaMC'
      && selectedCoordinatorBAs.includes(ba)
      && fileType === fileTypeSelected);

  const start = moment(startDate).utc().startOf('day');
  const end = moment(endDate).utc().startOf('day');
  filteredHeaders = filteredHeaders.filter((header) => {
    const tradeDate = moment(header.tradeDate).utc().startOf('day');
    return moment(tradeDate).isBetween(start, end, null, '[]');
  });

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredPublications: [], selectedValue: null };
  }

  const filteredPublications = [...new Set(filteredHeaders
    .map(({ publication }) => publication))].map((f) => ({ value: f, label: f }));

  const filteredPublicationSelected = filteredPublications.find((x) => x);
  const selectedValue = (filteredPublicationSelected) ? filteredPublicationSelected.value : null;

  return { filteredPublications, selectedValue };
}

// use this if only need Publication dropdown. if fed by FileType dropdown, use publicationHandler() instead
export function publicationOnlyHandler(params, withShadow = true, withWhatIf = true, withMonthly = true) {
  if (!params.coordinatorListSelected) { return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const [startDate, endDate] = params.tradeDateRangeSelected;
  let filteredHeaders = [];

  filteredHeaders = params.samcHeadersUnfiltered.filter(
    ({ dataSource, ba, fileType }) => dataSource === 'SaMC'
      && selectedCoordinatorBAs.includes(ba)
      && (!fileType.includes('SHADOW') || withShadow)
      && (!fileType.includes('WHATIF') || withWhatIf)
      && (!fileType.includes('MONTHLY') || withMonthly));

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null };
  }

  const start = moment(startDate).utc().startOf('day');
  const end = moment(endDate).utc().startOf('day');
  filteredHeaders = filteredHeaders.filter((header) => {
    const tradeDate = moment(header.tradeDate).utc().startOf('day');
    return moment(tradeDate).isBetween(start, end, null, '[]');
  });

  const filteredPublications = [...new Set(filteredHeaders.map(
    ({ publication }) => publication))].map((f) => ({ value: f, label: f }));
  const pubSelected = filteredPublications.find((x) => x);
  const selectedValue = (pubSelected) ? pubSelected.value : null;

  return { filteredPublications, filteredHeaders, selectedValue };
}

export function versionHandler(params, fileTypeSelected, publicationSelected) {
  if (!fileTypeSelected || !publicationSelected) { return { filteredVersions: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);

  const [startDate, endDate] = params.tradeDateRangeSelected;

  const filteredHeaders = params.samcHeadersUnfiltered.filter(({
    dataSource, ba, fileType, publication,
  }) => dataSource === 'SaMC'
      && selectedCoordinatorBAs.includes(ba)
      && fileType === fileTypeSelected
      && publication === publicationSelected);

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredVersions: [], selectedValue: null };
  }

  const filteredVersions = [...new Set(filteredHeaders
    .map(({ version }) => version))].map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = filteredVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (orderedDescendingVersions.length > 1) {
    if (params.coordinatorListSelected.length === params.coordinatorList.length
      || !moment(startDate).utc().startOf('day').isSame(moment(endDate).utc().startOf('day'))) {
      orderedDescendingVersions.unshift({ value: -1, label: 'MAX' });
    }
  }

  const versionSelected = orderedDescendingVersions.find((x) => x);
  const selectedValue = (versionSelected) ? versionSelected.value : null;

  return { filteredVersions: orderedDescendingVersions, selectedValue };
}

export function fileTypeVersionHandler(params, fileTypeSelected) {
  if (!fileTypeSelected) { return { filteredVersions: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);

  const [startDate, endDate] = params.tradeDateRangeSelected;

  const filteredHeaders = params.samcHeadersUnfiltered.filter(({
    dataSource, ba, fileType,
  }) => selectedCoordinatorBAs.includes(ba)
      && fileType === fileTypeSelected);

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredVersions: [], selectedValue: null };
  }

  const filteredVersions = [...new Set(filteredHeaders
    .map(({ version }) => version))].map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = filteredVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (orderedDescendingVersions.length > 1) {
    if (params.coordinatorListSelected.length === params.coordinatorList.length
      || !moment(startDate).utc().startOf('day').isSame(moment(endDate).utc().startOf('day'))) {
      orderedDescendingVersions.unshift({ value: -1, label: 'MAX' });
    }
  }

  const versionSelected = orderedDescendingVersions.find((x) => x);
  const selectedValue = (versionSelected) ? versionSelected.value : null;

  return { filteredVersions: orderedDescendingVersions, selectedValue };
}

export function InvoiceNumHandler(params) {
  if (!params.coordinatorListSelected) { return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const [startDate, endDate] = params.tradeDateRangeSelected;
  let filteredHeaders = [];

  filteredHeaders = params.samcHeadersUnfiltered.filter(
    ({ dataSource, ba }) => dataSource === 'Invoice'
      && selectedCoordinatorBAs.includes(ba));

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredFileTypes: [], filteredHeaders: [], selectedValue: null };
  }

  const start = moment(startDate).utc().startOf('day');
  const end = moment(endDate).utc().startOf('day');

  if (params.INVOICEVSSTATEMENTdateTypeSelected === 'Trade Date') {
    filteredHeaders = filteredHeaders.filter((header) => {
      const tradeDate = moment(header.tradeDate).utc().startOf('day');
      return moment(tradeDate).isBetween(start, end, null, '[]');
    });
  } else {
    filteredHeaders = filteredHeaders.filter((header) => {
      const publishedDate = moment(header.publishedDate).utc().startOf('day');
      return moment(publishedDate).isBetween(start, end, null, '[]');
    });
  }

  const filteredFileTypes = [...new Set(filteredHeaders.map(
    ({ invoiceNum }) => invoiceNum))].map((f) => ({ value: f, label: f }));

  const InvoiceNumSelected = filteredFileTypes.find((x) => x);
  const selectedValue = (InvoiceNumSelected) ? InvoiceNumSelected.value : null;

  return { filteredFileTypes, filteredHeaders, selectedValue };
}

export function DocTypeHandler(params, withShadow = true) {
  if (!params.coordinatorListSelected) { return { filteredDocTypes: [], filteredHeaders: [], selectedValue: null }; }

  const selectedCoordinatorBAs = params.coordinatorListSelected.map(
    (value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);

  const [startDate, endDate] = params.tradeDateRangeSelected;
  let filteredHeaders = [];

  filteredHeaders = params.samcHeadersUnfiltered.filter(
    ({ dataSource, ba, docType }) => dataSource === 'SaMC'
      && selectedCoordinatorBAs.includes(ba) && (!docType.includes('SHADOW') || withShadow));

  if (!filteredHeaders || filteredHeaders.length === 0) {
    return { filteredDocTypes: [], filteredHeaders: [], selectedValue: null };
  }

  const start = moment(startDate).utc().startOf('day');
  const end = moment(endDate).utc().startOf('day');
  filteredHeaders = filteredHeaders.filter((header) => {
    const tradeDate = moment(header.tradeDate).utc().startOf('day');
    return moment(tradeDate).isBetween(start, end, null, '[]');
  });

  const filteredDocTypes = [...new Set(filteredHeaders.map(
    ({ docType }) => docType))].map((f) => ({ value: f, label: f }));
  const docTypeSelected = filteredDocTypes.find((x) => x);
  const selectedValue = (docTypeSelected) ? docTypeSelected.value : null;

  return { filteredDocTypes, filteredHeaders, selectedValue };
}

export function DefaultFileTypeHandler({ $store, params }, dropDown, withShadow = true, withWhatIf = true, withMonthly = true) {
  const fileType = FileTypeHandler(params, withShadow, withWhatIf, withMonthly);

  params.samcHeaders = fileType.filteredHeaders;
  $store.commit('ciso/setParams', [`${dropDown}`, fileType.filteredFileTypes]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, fileType.selectedValue]);
  return true;
}

export function DefaultPubHandler({ $store, params }, dropDown, selectedFileType) {
  const publication = publicationHandler(params, selectedFileType);

  $store.commit('ciso/setParams', [`${dropDown}`, publication.filteredPublications]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, publication.selectedValue]);
  return true;
}

export function DefaultVersionHandler({ $store, params }, dropDown, selectedFileType, selectedPublication) {
  const publication = versionHandler(params, selectedFileType, selectedPublication);

  $store.commit('ciso/setParams', [`${dropDown}`, publication.filteredVersions]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, publication.selectedValue]);
  return true;
}

export function FileTypeVersionHandler({ $store, params }, dropDown, selectedFileType) {
  const publication = fileTypeVersionHandler(params, selectedFileType);

  $store.commit('ciso/setParams', [`${dropDown}`, publication.filteredVersions]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, publication.selectedValue]);
  return true;
}

export function DefaultPubOnlyHandler({ $store, params }, dropDown, withShadow = true, withWhatIf = true, withMonthly = true) {
  const publication = publicationOnlyHandler(params, withShadow, withWhatIf, withMonthly);

  $store.commit('ciso/setParams', [`${dropDown}`, publication.filteredPublications]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, publication.selectedValue]);
}

export function CustomAllocationInvoiceReportFileTypeHandler() {
  const { params } = this;

  if (params.coordinatorListSelected) {
    const selectedCoordinatorBAs = params.coordinatorListSelected
      .map((value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
    const [startDate, endDate] = params.tradeDateRangeSelected;
    let filteredHeaders = [];

    filteredHeaders = params.samcHeadersUnfiltered
      .filter(({ dataSource, ba }) => dataSource === 'Invoice' && selectedCoordinatorBAs.includes(ba));

    const start = moment(startDate).utc().startOf('day');
    const end = moment(endDate).utc().startOf('day');
    if (params.CUSTOMALLOCATIONINVOICEREPORTdateTypeSelected === 'Trade Date') {
      filteredHeaders = filteredHeaders.filter((header) => {
        const tradeDate = moment(header.tradeDate).utc().startOf('day');
        return moment(tradeDate).isBetween(start, end, null, '[]');
      });
    } else {
      filteredHeaders = filteredHeaders.filter((header) => {
        const publishedDate = moment(header.publishedDate).utc().startOf('day');
        return moment(publishedDate).isBetween(start, end, null, '[]');
      });
    }

    const filteredFileTypes = [...new Set(filteredHeaders
      .map(({ fileType }) => fileType))].map((f) => ({ value: f, label: f }));
    const fileFileTypeSelected = filteredFileTypes.find((x) => x);
    const selectedValue = (fileFileTypeSelected) ? fileFileTypeSelected.value : null;

    filteredHeaders.sort((a, b) => a.version - b.version);
    params.samcHeaders = filteredHeaders;
    params.CUSTOMALLOCATIONINVOICEREPORTfileType = filteredFileTypes;
    params.CUSTOMALLOCATIONINVOICEREPORTfileTypeSelected = selectedValue;
  }
}

export function CustomAllocationSummaryFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CUSTOMALLOCATIONSUMMARYREPORTfileType');
}

export function NetPositionReportsFileTypeHandler() {
  DefaultFileTypeHandler(this, 'NETPOSITIONREPORTSfileType');
}

export function CcToGlReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CCTOGLREPORTfileType');
}

export function BidCostRecoveryFileTypeHandler() {
  DefaultFileTypeHandler(this, 'BIDCOSTRECOVERYREPORTfileType');
}

export function LapPriceFileTypeHandler() {
  DefaultFileTypeHandler(this, 'LAPPRICEREPORTfileType', false);
}

export function CongestionPriceFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CONGESTIONPRICEREPORTfileType');
}

export function PriceCompareBdFileTypeHandler() {
  DefaultFileTypeHandler(this, 'PRICEBDCOMPAREREPORTfileType', false, false, false);
}

export function EimSufficiencyCheckFileTypeHandler() {
  DefaultFileTypeHandler(this, 'EIMSUFFICIENCYCHECKREPORTfileType');
}

export function AncillaryServicePublicationHandler() {
  DefaultPubOnlyHandler(this, 'ANCILLARYSERVICEsettlementCycle');
}

export function CongestionCollectionsFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CONGESTIONCOLLECTIONSREPORTfileType');
}

export function NprLoadFileTypeHandler() {
  DefaultFileTypeHandler(this, 'NPRANDLOADREPORTfileType');
}

export function CustomStatementVsShadowFileTypeHandler() {
  DefaultFileTypeHandler(this, 'STATEMENTVSSHADOWfileType', false);
}

export function CrrReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CRRREPORTfileType');
}

export function OperationStatementReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'OPERATIONSTATEMENTREPORTfileType');
}

export function MarketValueReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'MARKETVALUEREPORTfileType');
}

export function CustomFercElectricityQuarterlyFileTypeHandler() {
  DefaultFileTypeHandler(this, 'FERCELECTRICITYQUARTERLYREPORTfileType', false, false);
}

export function SettlementDetailFileTypeHandler() {
  DefaultFileTypeHandler(this, 'SETTLEMENTDETAILREPORTfileType', false, false);
}

export function ISTSFileTypeHandler() {
  DefaultFileTypeHandler(this, 'ISTSREPORTfileType');
}

export function CustomBDReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CUSTOMBDREPORTfileType');
}

export function TAGSETTLEMENTQUANTITYCOMPAREREPORTFileTypeHandler() {
  DefaultFileTypeHandler(this, 'TAGSETTLEMENTQUANTITYCOMPAREREPORTfileType');
}

export function IIETAGSETTLEMENTSQUANTITYCOMPAREFileTypeHandler() {
  DefaultFileTypeHandler(this, 'IIETAGSETTLEMENTSQUANTITYCOMPAREfileType');
}

export function MARKETINVOICECUSTOMFileTypeHandler() {
  DefaultFileTypeHandler(this, 'MARKETINVOICECUSTOMfileType');
}

export function SettlementDetailPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'SETTLEMENTDETAILREPORTpublication', params.SETTLEMENTDETAILREPORTfileTypeSelected);
}

export function PriceCompareBdPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'PRICEBDCOMPAREREPORTpublication', params.PRICEBDCOMPAREREPORTfileTypeSelected);
}

export function ISTSPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'ISTSREPORTpublication', params.ISTSREPORTfileTypeSelected);
}

export function CustomAllocationSummaryPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'CUSTOMALLOCATIONSUMMARYREPORTpublication', params.CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected);
}

export function CcToGlReportPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'CCTOGLREPORTpublication', params.CCTOGLREPORTfileTypeSelected);
}

export function LapPricePublicationHandler() {
  DefaultPubOnlyHandler(this, 'LAPPRICEREPORTpublication');
}

export function OperationStatementPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'OPERATIONSTATEMENTREPORTsettlementCycle', params.OPERATIONSTATEMENTREPORTfileTypeSelected);
}

export function TAGSETTLEMENTQUANTITYCOMPAREPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'TAGSETTLEMENTQUANTITYCOMPAREREPORTsettlementCycle', params.TAGSETTLEMENTQUANTITYCOMPAREREPORTfileTypeSelected);
}

export function IIETAGSETTLEMENTSQUANTITYCOMPAREPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'IIETAGSETTLEMENTSQUANTITYCOMPAREsettlementCycle', params.IIETAGSETTLEMENTSQUANTITYCOMPAREfileTypeSelected);
}

export function MARKETINVOICECUSTOMPublicationHandler() {
  const { params } = this;
  DefaultPubHandler(this, 'MARKETINVOICECUSTOMsettlementCycle', params.MARKETINVOICECUSTOMfileTypeSelected);
}

export function EimSufficiencyCheckPublicationHandler() {
  DefaultPubOnlyHandler(this, 'EIMSUFFICIENCYCHECKREPORTpublication');
}

export function RtieoCongestionPublicationHandler() {
  DefaultPubOnlyHandler(this, 'RTIEOCONGESTIONREPORTpublication');
}

export function SalesAndPurchasesReportPublicationHandler() {
  DefaultPubOnlyHandler(this, 'SALESPURCHASESREPORTpublication');
}
export function CustomPurchaseAndSaleChargeCodeSummaryReportFileTypeHandler() {
  DefaultFileTypeHandler(this, 'CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileType');
}

export function CustomPurchaseAndSaleChargeCodeSummaryReportVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTversion',
    params.CUSTOMPURCHASEANDSALECHARGECODESUMMARYREPORTfileTypeSelected);
}

export function CongestionCollectionsPublicationHandler() {
  DefaultPubOnlyHandler(this, 'CONGESTIONCOLLECTIONSREPORTpublication');
}

export function NprLoadPublicationHandler() {
  DefaultPubOnlyHandler(this, 'NPRANDLOADREPORTpublication');
}

export function UfeReportPublicationHandler() {
  DefaultPubOnlyHandler(this, 'UFEREPORTpublication');
}

export function crrReportPublicationHandler() {
  DefaultPubOnlyHandler(this, 'CRRREPORTpublication');
}

export function EqrReportPublicationHandler() {
  DefaultPubOnlyHandler(this, 'EQRREPORTpublication');
}

export function settlementReportPublicationHandler() {
  DefaultPubOnlyHandler(this, 'SETTLEMENTREPORTpublication');
}

export function SaMCVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'SAMCREPORTversion',
    params.SAMCREPORTfileTypeSelected);
}

export function CustomBDReportVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'CUSTOMBDREPORTversion',
    params.CUSTOMBDREPORTfileTypeSelected);
}

export function CustomAllocationInvoiceReportVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'CUSTOMALLOCATIONINVOICEREPORTversion',
    params.CUSTOMALLOCATIONINVOICEREPORTfileTypeSelected);
}

export function CustomFercElectricityQuarterlyVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'FERCELECTRICITYQUARTERLYREPORTversion',
    params.FERCELECTRICITYQUARTERLYREPORTfileTypeSelected);
}

export function NetPositionReportsVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'NETPOSITIONREPORTSversion',
    params.NETPOSITIONREPORTSfileTypeSelected);
}

export function CustomSettlementsOperationMonthlyVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'CUSTOMSAMCMONTHLYMGMTversion',
    params.CUSTOMSAMCMONTHLYMGMTfileTypeSelected);
}

export function CongestionPriceVersionHandler() {
  const { params } = this;
  FileTypeVersionHandler(this, 'CONGESTIONPRICEREPORTversion',
    params.CONGESTIONPRICEREPORTfileTypeSelected);
}

export function SettlementDetailVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'SETTLEMENTDETAILREPORTversion',
    params.SETTLEMENTDETAILREPORTfileTypeSelected, params.SETTLEMENTDETAILREPORTpublicationSelected);
}

export function PriceCompareBdVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'PRICEBDCOMPAREREPORTversion',
    params.PRICEBDCOMPAREREPORTfileTypeSelected, params.PRICEBDCOMPAREREPORTpublicationSelected);
}

export function ISTSVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'ISTSREPORTversion',
    params.ISTSREPORTfileTypeSelected, params.ISTSREPORTpublicationSelected);
}

export function CustomAllocationSummaryVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'CUSTOMALLOCATIONSUMMARYREPORTversion',
    params.CUSTOMALLOCATIONSUMMARYREPORTfileTypeSelected, params.CUSTOMALLOCATIONSUMMARYREPORTpublicationSelected);
}

export function LapPriceVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'LAPPRICEREPORTversion',
    params.LAPPRICEREPORTfileTypeSelected, params.LAPPRICEREPORTpublicationSelected);
}

export function CcToGlReportVersionHandler() {
  const { params } = this;
  DefaultVersionHandler(this, 'CCTOGLREPORTversion',
    params.CCTOGLREPORTfileTypeSelected, params.CCTOGLREPORTpublicationSelected);
}

export function CustomSettlementsOperationMonthlyFileTypeHandler() {
  const { params } = this;
  const fileType = DocTypeHandler(params, false);

  params.samcHeaders = fileType.filteredHeaders;
  params.CUSTOMSAMCMONTHLYMGMTfileType = fileType.filteredDocTypes;
  params.CUSTOMSAMCMONTHLYMGMTfileTypeSelected = fileType.selectedValue;
}

export function CustomInvoiceVsStatementInvoiceNumHandler() {
  const { params } = this;
  const invoiceNum = InvoiceNumHandler(params);

  params.samcHeaders = invoiceNum.filteredHeaders;
  params.INVOICEVSSTATEMENTinvoiceNum = invoiceNum.filteredFileTypes;
  params.INVOICEVSSTATEMENTinvoiceNumSelected = invoiceNum.selectedValue;
}

export function AncillaryServiceFileTypeHandler() {
  DefaultFileTypeHandler(this, 'ANCILLARYSERVICEfileType');
}

export function SMUDCustomInvoiceVsStatementInvoiceNumberHandler() {
  const { params } = this;
  const invoiceNum = InvoiceNumHandler(params);

  params.samcHeaders = invoiceNum.filteredHeaders;
  params.SMUDINVOICEVSSTATEMENTinvoiceNumber = invoiceNum.filteredFileTypes;
  params.SMUDINVOICEVSSTATEMENTinvoiceNumberSelected = invoiceNum.selectedValue;
}

export function SaMCFileTypeHandler() {
  const { params } = this;
  if (params.coordinatorListSelected) {
    const selectedCoordinatorBAs = params.coordinatorListSelected
      .map((value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);
    const [startDate, endDate] = params.tradeDateRangeSelected;
    let filteredHeaders = [];

    if (params.SAMCREPORTreportTypeSelected === 'Invoice') {
      filteredHeaders = params.samcHeadersUnfiltered
        .filter(({ dataSource, ba }) => dataSource === 'Invoice' && selectedCoordinatorBAs.includes(ba));
    } else if (params.SAMCREPORTreportTypeSelected.includes('Allocation')) {
      filteredHeaders = params.samcHeadersUnfiltered
        .filter(({ dataSource, ba }) => dataSource === 'Allocation' && selectedCoordinatorBAs.includes(ba));
    } else {
      filteredHeaders = params.samcHeadersUnfiltered
        .filter(({ dataSource, ba }) => dataSource === 'SaMC' && selectedCoordinatorBAs.includes(ba));
    }
    const start = moment(startDate).utc().startOf('day');
    const end = moment(endDate).utc().startOf('day');
    if (params.SAMCREPORTdateTypeSelected === 'Trade Date') {
      filteredHeaders = filteredHeaders.filter((header) => {
        const tradeDate = moment(header.tradeDate).utc().startOf('day');
        return moment(tradeDate).isBetween(start, end, null, '[]');
      });
    } else {
      filteredHeaders = filteredHeaders.filter((header) => {
        const publishedDate = moment(header.publishedDate).utc().startOf('day');
        return moment(publishedDate).isBetween(start, end, null, '[]');
      });
    }

    const filteredFileTypes = [...new Set(filteredHeaders
      .map(({ fileType }) => fileType))].map((f) => ({ value: f, label: f }));
    const fileFileTypeSelected = filteredFileTypes.find((x) => x);
    const selectedValue = (fileFileTypeSelected) ? fileFileTypeSelected.value : null;

    filteredHeaders.sort((a, b) => a.version - b.version);
    params.samcHeaders = filteredHeaders;
    params.SAMCREPORTfileType = filteredFileTypes;
    params.SAMCREPORTfileTypeSelected = selectedValue;
  }
}

export function eimFileTypeHandler({ $store, params }, dropDown, withMonthly = true) {
  if (params.coordinatorListSelected) {
    const selectedCoordinatorBAs = params.coordinatorListSelected
      .map((value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);

    const { startDate, endDate } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);

    const filteredHeaders = params.eimHeadersUnfiltered
      .filter(({ ba, tradingDate, fileType }) => selectedCoordinatorBAs.includes(ba)
        && (tradingDate.split('T')[0] >= startDate
        && tradingDate.split('T')[0] <= endDate)
        && (!fileType.includes('MONTHLY') || withMonthly));

    const filteredFileTypes = filteredHeaders
      .map((x) => x.fileType).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
    const orderedDescendingFileTypes = filteredFileTypes.sort((firstVal, nextVal) => {
      const firstValLower = firstVal.value.toLowerCase();
      const nextValLower = nextVal.value.toLowerCase();
      if (firstValLower < nextValLower) { return 1; }
      if (firstValLower > nextValLower) { return -1; }
      return 0;
    });

    params.eimHeaders = filteredHeaders;
    $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingFileTypes]);
    $store.commit('ciso/setParams',
      [`${dropDown}Selected`, orderedDescendingFileTypes.length > 0 ? orderedDescendingFileTypes[0].value : null]);
    return true;
  }
  return false;
}

export function eimPublicationHandler(context, dropDown, fileTypeForPub) {
  if (!context.params.coordinatorListSelected
    || !context.params.tradeDateRangeSelected[0]
    || !context.params.tradeDateRangeSelected[1]) { return null; }
  const selectedCoordinator = context.params.coordinatorListSelected
    .map((value) => context.params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(context.params.tradeDateRangeSelected);
  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = context.params.eimHeaders
    .filter(({ ba, tradingDate, fileType }) => selectedCoordinator[0] === ba
    && (tradingDate.split('T')[0] >= startDateCompare
    && tradingDate.split('T')[0] <= endDateCompare)
    && fileType === context.params[`${fileTypeForPub}Selected`]);
  const filteredPublications = filteredHeaders
    .map((x) => x.publication).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingPublications = filteredPublications.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
  if (orderedDescendingPublications.length > 1) {
    if (!moment(startDateCompare).isSame(moment(endDateCompare))) {
      orderedDescendingPublications.unshift({ value: 'MAX', label: 'MAX' });
    }
  }
  const topPub = orderedDescendingPublications.length > 0 ? orderedDescendingPublications[0] : null;

  context.$store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingPublications]);
  context.$store.commit('ciso/setParams', [`${dropDown}Selected`, null]);
  context.$nextTick(() => {
    const topPubVal = topPub ? topPub.value : null;
    context.$store.commit('ciso/setParams', [`${dropDown}Selected`, topPubVal]);
  });
  return true;
}

export function eimVersionHandler({ $store, params }, dropDown, fileTypeForVer, pubForVer) {
  const selectedCoordinator = params.coordinatorList.find(({ value }) => params.coordinatorListSelected[0] === value);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);
  const pubCompare = eimGetPub(params[`${pubForVer}Selected`], params[`${pubForVer}`]);

  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = params.eimHeaders
    .filter(({
      ba, tradingDate, fileType, publication,
    }) => ba === selectedCoordinator.baId
      && (tradingDate.split('T')[0] >= startDateCompare
      && tradingDate.split('T')[0] <= endDateCompare)
      && fileType === params[`${fileTypeForVer}Selected`]
      && publication === pubCompare);
  const filteredVersions = filteredHeaders
    .map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingVersions = filteredVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (orderedDescendingVersions.length > 1) {
    if (params.selectedCoordinator === 'ALL' || !moment(startDateCompare).isSame(moment(endDateCompare))) {
      orderedDescendingVersions.unshift({ value: -1, label: 'MAX' });
    }
  }

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingVersions]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, topVersion ? topVersion.value : null]);
  return true;
}

export function stateAllocFileTypeHandler({ $store, params }, dropDown) {
  if (params.coordinatorListSelected) {
    const selectedCoordinatorBAs = params.coordinatorListSelected
      .map((value) => params.coordinatorList.find(({ shortName }) => shortName === value).baId);

    const { startDate, endDate } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);

    const filteredHeaders = params.stateHeadersUnfiltered
      .filter(({ ba, tradingDate }) => selectedCoordinatorBAs.includes(ba)
        && (tradingDate.split('T')[0] >= startDate
        && tradingDate.split('T')[0] <= endDate));

    const filteredFileTypes = filteredHeaders
      .map((x) => x.fileType).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
    const orderedDescendingFileTypes = filteredFileTypes.sort((firstVal, nextVal) => {
      const firstValLower = firstVal.value.toLowerCase();
      const nextValLower = nextVal.value.toLowerCase();
      if (firstValLower < nextValLower) { return 1; }
      if (firstValLower > nextValLower) { return -1; }
      return 0;
    });

    params.stateHeaders = filteredHeaders;
    $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingFileTypes]);
    $store.commit('ciso/setParams',
      [`${dropDown}Selected`, orderedDescendingFileTypes.length > 0 ? orderedDescendingFileTypes[0].value : null]);
    return true;
  }
  return false;
}

export function stateAllocPublicationHandler(context, dropDown, fileTypeForPub) {
  if (!context.params.coordinatorListSelected
    || !context.params.tradeDateRangeSelected[0]
    || !context.params.tradeDateRangeSelected[1]) { return null; }
  const selectedCoordinator = context.params.coordinatorListSelected
    .map((value) => context.params.coordinatorList.find(({ shortName }) => shortName === value).baId);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(context.params.tradeDateRangeSelected);
  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = context.params.stateHeaders
    .filter(({ ba, tradingDate, fileType }) => selectedCoordinator[0] === ba
    && (tradingDate.split('T')[0] >= startDateCompare
    && tradingDate.split('T')[0] <= endDateCompare)
    && fileType === context.params[`${fileTypeForPub}Selected`]);
  const filteredPublications = filteredHeaders
    .map((x) => x.publication).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingPublications = filteredPublications.sort((firstVal, nextVal) => {
    const firstValLower = firstVal.value.toLowerCase();
    const nextValLower = nextVal.value.toLowerCase();
    if (firstValLower < nextValLower) { return 1; }
    if (firstValLower > nextValLower) { return -1; }
    return 0;
  });
  if (orderedDescendingPublications.length > 1) {
    if (!moment(startDateCompare).isSame(moment(endDateCompare))) {
      orderedDescendingPublications.unshift({ value: 'MAX', label: 'MAX' });
    }
  }
  const topPub = orderedDescendingPublications.length > 0 ? orderedDescendingPublications[0] : null;

  context.$store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingPublications]);
  context.$store.commit('ciso/setParams', [`${dropDown}Selected`, null]);
  context.$nextTick(() => {
    const topPubVal = topPub ? topPub.value : null;
    context.$store.commit('ciso/setParams', [`${dropDown}Selected`, topPubVal]);
  });
  return true;
}

export function stateAllocVersionHandler({ $store, params }, dropDown, fileTypeForVer, pubForVer) {
  const selectedCoordinator = params.coordinatorList.find(({ value }) => params.coordinatorListSelected[0] === value);
  const { startDate: startDateCompare, endDate: endDateCompare } = getMomentDateStringFromRangeArray(params.tradeDateRangeSelected);
  const pubCompare = eimGetPub(params[`${pubForVer}Selected`], params[`${pubForVer}`]);

  // Race Condition check
  if (!selectedCoordinator || !startDateCompare || !endDateCompare) { return null; }
  const filteredHeaders = params.stateHeaders
    .filter(({
      ba, tradingDate, fileType, publication,
    }) => ba === selectedCoordinator.baId
      && (tradingDate.split('T')[0] >= startDateCompare
      && tradingDate.split('T')[0] <= endDateCompare)
      && fileType === params[`${fileTypeForVer}Selected`]
      && publication === pubCompare);
  const filteredVersions = filteredHeaders
    .map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i).map((f) => ({ value: f, label: f }));
  const orderedDescendingVersions = filteredVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  if (orderedDescendingVersions.length > 1) {
    if (params.selectedCoordinator === 'ALL' || !moment(startDateCompare).isSame(moment(endDateCompare))) {
      orderedDescendingVersions.unshift({ value: -1, label: 'MAX' });
    }
  }

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  $store.commit('ciso/setParams', [`${dropDown}`, orderedDescendingVersions]);
  $store.commit('ciso/setParams', [`${dropDown}Selected`, topVersion ? topVersion.value : null]);
  return true;
}

export function gerPurchaseSaleCCSummaryBeforeSubmitCC(chargeCodes) {
  if (!chargeCodes.includes('CAISO Energy Group')) return chargeCodes.join();

  const updatedCCs = [...chargeCodes, '6011', '6460', '6470', '6475'].filter((value, index, array) => array.indexOf(value) === index && value !== 'CAISO Energy Group');
  return updatedCCs.join();
}
