import moment from 'moment';
import dateStore from '@/utils/dateStore';
import { CMS_API } from '@/api';

const state = {
  reportType: 'Deliverables',
  reportTableData: [],
  deliverableTableConfig: {
    columns: [{
      prop: 'company',
      label: 'Entity',
      filterable: true,
      alignment: 'left',
    },{
      prop: 'counterParty',
      label: 'CounterParty',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'contractNumber',
      label: 'Contract #',
      filterable: true,
      sortable: true,
      cellTemplate: 'PscsRouteCellTemplate',
      alignment: 'right',
      editorOptions: {
        to: 'ContractDetails',
        params: [
          { key: 'id', dataKey: 'contractId' }, { key: 'guid', dataKey: 'contractGuid' },
        ],
      },
    },{
      prop: 'contractStatus',
      label: 'Status',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'deliverableName',
      label: 'Deliverable Name',
      filterable: true,
      cellTemplate: 'PscsRouteCellTemplate',
      alignment: 'left',
      editorOptions: {
        to: 'DeliverableDetails',
        params: [
          { key: 'id', dataKey: 'deliverableId' }, { key: 'guid', dataKey: 'contractGuid' },
        ],
      },
    }, {
      prop: 'deliverableType', label: 'Deliverable Type', filterable: true, alignment: 'left',
    }, {
      prop: 'contractCoordinator', label: 'Department Coordinator', filterable: true, groupIndex: 0, alignment: 'left',
    }, {
      prop: 'startDate',
      label: 'From Start Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'endDate',
      label: 'To End Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'occurrenceStatus',
      label: 'Occurrence Status',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'logStatus',
      label: 'Log Status',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'logNotes',
      label: 'Log Description',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }],
    options: {
      groupPanel: true,
      columnsMovable: true,
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  requestDateRange: [
    moment().startOf('month').add(1, 'days').format('YYYY-MM-DD'),
    moment().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
  ],
};

const actions = {
  resetData({ commit }) {
    commit('setReportTableData', []);
    commit('setRequestDateRange', [
      moment().startOf('month').add(1, 'days').format('YYYY-MM-DD'),
      moment().endOf('month').add(1, 'days').format('YYYY-MM-DD'),
    ]);
  },
  changeRequestDateRange({ commit, dispatch }, value) {
    dispatch('resetData');
    commit('setRequestDateRange', value);
    dispatch('fetchReportByType', state.reportType);
  },
  fetchReportByType({ commit, state }, reportType) {
    const type = reportType || state.reportType;
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.requestDateRange, tz);
    const params = {
      requestStartDate: momentRange[0].format(),
      requestEndDate: momentRange[1].format(),
    };
    CMS_API.get(`reports/${type}`, { params }).then(({ data }) => {
      commit('setReportTableData', data);
    }).catch((err) => {
      this.$notify('Failed to Retrieve Report on Deliverable Data', 'error');
    });
  },
};

const mutations = {
  setRequestDateRange(state, value) { state.requestDateRange = value; },
  setReportTableData(state, { reports }) {
    state.reportTableData = reports;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};