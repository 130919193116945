import moment from 'moment';
import { TOOLS_API, CISO_SAMC_API } from '@/api';
import caisoUtil from '@/utils/caiso/caisoUtils';
import dateUtil from '@/utils/dateStore';
import { clone } from '@/utils/dataUtil';

const state = {
  status: [],
  priority: [],
  varianceCause: [],
  category: [],
  subCategory: [],
  department: [],
  settlementCycleList: [],
  coordinatorList: [],
  subCompanyList: [],
  resourceList: [],
  chargeCodeList: [],
  scDistinctSet: {},
  issue: {
    trackingID: 0,
    ba: '',
    resource: [],
    tradingDate: null,
    settlement_cycle: '',
    startTime: null,
    endTime: null,
    category: '',
    subCategory: '',
    chargeCode: '',
    varianceCause: '',
    variance: 0,
    dispute: 0,
    mwCurtailed: 0,
    revisedAvailability: 0,
    notes: '',
    department: '',
    settlementsFlag: false,
    eimSubAllocFlag: false,
    visualAnalyticsFlag: false,
    status: '',
    priority: '',
    createdBy: '',
    createdDate: null,
    updatedBy: '',
    updatedDate: null,
    followupDate: null,
    jira_ticket: '',
    cidi_ticket: '',
  },
};

function isValid(params) {
  const validationMsg = [];
  // Form Validation for APS
  if (state.scDistinctSet.has('AZPE') || state.scDistinctSet.has('APS4') || state.scDistinctSet.has('APS5')) {
    if (!params.ba) {
      validationMsg.push('Company');
    }
    if (!params.tradingDate) {
      validationMsg.push('TradingDate');
    }
    if (!params.status) {
      validationMsg.push('Status');
    }
    if (!params.priority) {
      validationMsg.push('Priority');
    }

    if (params.chargeCode) {
      if (!params.varianceCause) {
        validationMsg.push('VarianceCause');
      }

      if (params.variance === null) {
        validationMsg.push('Variance');
      }
    }
    if (validationMsg.length > 0) {
      return {
        isValidParams: false,
        validationMessage: `${validationMsg.join(', ')} ${validationMsg.length > 1 ? 'fields are' : 'field is'} required.`,
      };
    }
  }
  return {
    isValidParams: true,
    validationMessage: '',
  };
}

const actions = {
  async fetchCoordinators({ commit }) {
    try {
      const { data: { schedulingCoordinators: scs } } = await TOOLS_API.get('/scheduling-coordinators/resources');

      state.scDistinctSet = new Set();
      scs.forEach((c) => { state.scDistinctSet.add(c.longName); });

      commit('setCoordinatorList', caisoUtil.configureCoordinators(scs));
    } catch (error) {
      this.$notify('Failed to load Scheduling Coordinators', 'error');
      console.error(error);
    }
    return {};
  },
  async fetchSubCompanies({ commit }) {
    try {
      let { data: { data } } = await TOOLS_API.get('/transmission-billing-info/sub-companies');

      if (data) {
        data = data.map((c) => {
          c.value = c.subCompany;
          c.label = c.subCompany;
          return c;
        });
      }

      commit('setSubCompanyList', data);
    } catch (error) {
      console.error(error);
    }
    return {};
  },
  async fetchReferenceData({ commit }) {
    try {
      const { data } = await TOOLS_API.get('/issue-reference-data');
      commit('setRefData', data);
    } catch (error) {
      this.$notify('Failed to load Issue Reference Data', 'error');
      console.error(error);
    }
  },
  async fetchChargeCodes({ commit }) {
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/statements/charge-types');
      if (this.hasAll) data.unshift({ chargeType: 'ALL' });
      commit('setChargeCodes', caisoUtil.getChargeCodeOptions(data));
    } catch (error) {
      this.$notify('Failed to load Charge Codes', 'error');
      console.error(error);
    }
    return {};
  },
  async fetchSettlementCycles({ commit }) {
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/statements/headers');
      data.forEach((d, idx) => {
        d.value = idx;
      });
      commit('setSettlementCycleList', data);
    } catch (error) {
      this.$notify('Failed to load Settlement Cycles', 'error');
      console.error(error);
    }
  },
  async fetchIssue({ commit }, parameters = {}) {
    const params = {
      trackingID: parameters,
    };
    try {
      const { data: { data } } = await TOOLS_API.get('/issue-tracking/fetch-one', { params });
      commit('setUpdateIssueData', data[0], 0);
      this.$notify('Successfully fetched Issue', 'success');
    } catch (error) {
      this.$notify('Failed to fetch Issue', 'error');
      console.error(error);
    }
  },
  async upsertIssue({ commit }) {
    const params = clone(state.issue);

    const isValidObj = isValid(params);
    if (isValidObj.isValidParams === false) {
      this.$notify(isValidObj.validationMessage, 'error');
    } else {
      params.ba = state.coordinatorList.find((x) => params.ba === x.baId.toString() || params.ba === x.shortName).baId.toString();
      params.createdDate = state.issue.createdDate === null ? moment().toISOString() : moment(state.issue.createdDate).utc().toISOString();
      params.tradingDate = state.issue.tradingDate === null ? moment().utc().toISOString() : moment(state.issue.tradingDate).utc().toISOString();
      params.startTime = state.issue.startTime === null ? null : moment(state.issue.startTime).utc().toISOString();
      params.endTime = state.issue.endTime === null ? null : moment(state.issue.endTime).utc().toISOString();
      params.updatedDate = state.issue.updatedDate === null ? null : moment(state.issue.updatedDate).utc().toISOString();
      params.followupDate = state.issue.followupDate === null ? null : moment(state.issue.followupDate).utc().toISOString();
      params.variance = state.issue.variance ?? 0;
      params.dispute = state.issue.dispute ?? 0;
      params.mwCurtailed = state.issue.mwCurtailed ?? 0;
      params.revisedAvailability = state.issue.revisedAvailability ?? 0;
      try {
        await TOOLS_API.put('/issue-tracking/upsert', params);
        this.$notify('Successfully added Issue', 'success');
        commit('reset');
      } catch (error) {
        this.$notify('Failed to add Issue', 'error');
        console.error(error);
      }
    }
  },
};

const mutations = {
  setRefData(state, item) {
    state.status = item.status;
    state.priority = item.priority;
    state.varianceCause = item.varianceCause;
    state.category = item.category;
    state.subCategory = item.subCategory;
    state.department = item.department;
  },
  setCoordinatorList(state, value) {
    state.coordinatorList = value;
  },
  setSubCompanyList(state, value) {
    state.subCompanyList = value;
  },
  setResourceList(state, item) {
    state.resourceList = item;
  },
  setChargeCodes(state, value) {
    state.chargeCodeList = value;
  },
  setSettlementCycleList(state, value) {
    state.settlementCycleList = value;
  },
  setAddIssueData(state, value) {
    value.createdBy = this.getters['auth/getProfile'].userName;
    value.createdDate = moment().format('YYYY/MM/DD HH:mm');
    value.ba = value.ba === undefined ? caisoUtil.getCoordinator() || state.coordinatorList[0].shortName : value.ba;
    value.tradingDate = moment(value.tradingDate).utc().format('YYYY/MM/DD');
    value.settlementsFlag = value.type === 'Settlements';
    value.eimSubAllocFlag = value.type === 'EIM Sub Allocations';
    value.visualAnalyticsFlag = value.type !== 'Settlements' && value.type !== 'EIM Sub Allocations';
    value.department = value.type;
    Object.assign(state.issue, value);
  },
  setUpdateIssueData(state, value) {
    value.ba = state.coordinatorList.find((x) => value.ba === x.baId.toString()).shortName.toString();
    value.updatedBy = this.getters['auth/getProfile'].userName;
    value.updatedDate = moment().format('YYYY/MM/DD HH:mm');
    value.createdDate = moment(value.createdDate).format('YYYY/MM/DD HH:mm');
    value.tradingDate = moment(value.tradingDate).utc().format('YYYY/MM/DD');
    value.followupDate = value.followupDate === null ? null : moment(value.followupDate).format('YYYY/MM/DD');
    value.startTime = value.startTime === null ? null : moment(value.startTime).format('YYYY/MM/DD HH:mm');
    value.endTime = value.endTime === null ? null : moment(value.endTime).format('YYYY/MM/DD HH:mm');
    Object.assign(state.issue, value);
  },
  setResources(state, ba) {
    if (ba !== null) {
      const resources = caisoUtil.getSchedulingCoordinatorResourcesOptions(state.coordinatorList, ba);
      state.resourceList = resources;
    }
  },
  setAppendDateToNote(state, value) {
    const date = moment().format('YYYY/MM/DD');

    if (!value.includes(`***${date}`)) {
      state.issue.notes = value.trim().length !== 0 ? `${value.trim()}\n\n***${date}\n\n` : `***${date}\n\n`;
    }
  },
  setRemoveDateFromNote(state, value) {
    const date = moment().format('YYYY/MM/DD');

    if (value.trim().substr(value.lastIndexOf('***')) === `***${date}`) {
      state.issue.notes = value.replace(`***${date}`, '').trim();
    }
  },
  reset(state) {
    state.issue.trackingID = 0;
    state.issue.resource = [];
    state.issue.startTime = null;
    state.issue.endTime = null;
    state.issue.category = '';
    state.issue.subCategory = '';
    state.issue.chargeCode = '';
    state.issue.varianceCause = '';
    state.issue.variance = 0;
    state.issue.dispute = 0;
    state.issue.mwCurtailed = 0;
    state.issue.revisedAvailability = 0;
    state.issue.notes = '';
    state.issue.status = '';
    state.issue.priority = '';
    state.issue.followupDate = null;
    state.issue.jira_ticket = '';
    state.issue.cidi_ticket = '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};