import { CISO_SIBR_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  async fetchBalancingTests({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      tz: dateStore.getTimeZone(),
    };
    try {
      const { data } = await CISO_SIBR_API.get('balancing-tests', { params });
      data.forEach((obj) => {
        obj.tzTradeDate = getMomentDateString(obj.tzTradeDate);
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to load Balancing Tests', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};