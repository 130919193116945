import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_STLMTS_API } from '@/api';
import {
  tradeDateRange, ppaGroupTagBox, ppaGroupPPAs, ppaSettlementsHeaders,
  cycles, versions, hasCompare, compareCycles, compareVersions,
} from '@/components/PPA/BaseStore/ppasBase';
import config from './settlementsSummaryConfig';

const state = {
  tableData: [],
  tableConfiguration: config,
  searchParams: {},
  currentRow: null,
  searchFiltersConfig: {
    ppaSettlementsHeaders,
    tradeDateRange,
    ppaGroupTagBox,
    ppaGroupPPAs,
    cycles,
    versions,
    hasCompare,
    compareCycles,
    compareVersions,
  },
};

const actions = {
  async fetchSettlementsSummary({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      ppas: parameters.ppaGroupPPAsSelected,
      startDate,
      endDate,
      cycle: parameters.cyclesSelected,
      version: parameters.versionsSelected === 'MAX' ? -1 : parameters.versionsSelected,
      compareCycle: parameters.compareCyclesSelected,
      compareVersion: parameters.compareVersionsSelected === 'MAX' ? -1 : parameters.compareVersionsSelected,
    };

    try {
      const { data: { data } } = await PPA_STLMTS_API.put('/statements/summary', params);
      if (Array.isArray(data)) {
        data.forEach((row) => { row.tradingDate = moment.utc(row.tradingDate).format('MM/DD/YYYY'); });
        commit('setTableData', data);
      }
      commit('setSearchParams', params);
    } catch (error) {
      console.log(error);
      this.$notify('Failed to Load Settlements Summary', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  hideComparisons(state, value) {
    // if (value.compareCyclesSelected != null) {
    //   state.tableConfiguration.columns[6].visible = false;
    //   state.tableConfiguration.columns[7].visible = false;
    //   state.tableConfiguration.columns[8].visible = true;
    //   state.tableConfiguration.columns[9].visible = true;
    //   state.tableConfiguration.columns[10].visible = true;
    // } else {
    //   state.tableConfiguration.columns[6].visible = true;
    //   state.tableConfiguration.columns[7].visible = true;
    //   state.tableConfiguration.columns[8].visible = false;
    //   state.tableConfiguration.columns[9].visible = false;
    //   state.tableConfiguration.columns[10].visible = false;
    // }
  },
  setCurrentRow(state, currentRow) {
    state.currentRow = currentRow;
  },
  setSearchParams(state, params) {
    state.searchParams = params;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};