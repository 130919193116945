import Vue from 'vue';
import store from '../../../../store';

Vue.component('ChangeStrategyCell', {
  props: {
    scope: {
      type: Object,
    },
    column: {
      type: Object,
    },
    configStore: {
      type: Object,
    },
  },
  computed: {
    selection() {
      return this.params.data[this.params.column.colId];
    },
    isMixed() {
      return this.selection === 'MIXED';
    },
  },
  methods: {
    showDialog(e) {
      store.dispatch('ciso/bidstrategy/showDialog', e);
    },
  },
  template:
        `<div>
        <el-button type="text" size="mini" v-if="isMixed" 
            v-on:click="showDialog({visible: true, rowIndex: params.rowIndex})">
            {{selection}}
        </el-button>
        <input v-else
            style= "text-align:center;"
            v-model.lazy="selection"                     
            v-bind:readonly="true" />
        </div>`,
});