import Vue from 'vue';
import Vuex from 'vuex';
import notify from 'devextreme/ui/notify';
import createLogger from 'vuex/dist/logger';

import admin from '@/components/Admin/store';
import ciso from '@/components/Ciso/store';
import cli from '@/components/PscsCli/cliStore';
import cms from '@/components/CMS/store';
import connections from '@/components/Connections/store';
import contractBilling from '@/components/ContractBilling/store';
import ppa from '@/components/PPA/store';
import deliveryManagement from '@/components/DeliveryManagement/store';
import etag from '@/components/Etag/store';
import etrm from '@/components/ETRM/store';
import notification from '@/components/PscsNotification/notificationStore';
import referenceData from '@/components/ReferenceDataManager/store';
import reporting from '@/components/PscsReporting/reportingStore';
import spp from '@/components/SPP/store';
import tools from '@/components/Tools/store';
import userOptions from '@/components/PscsUserOptions/store';
import visualAnalytics from '@/components/VisualAnalytics/store';
import workflow from '@/components/Workflow/store';
import lookup from './lookupStore';
import system from '@/components/System/store';
import demoStore from '../demo/store';
import clipboard from './clipboardStore';
import auth from './authStore';
import alert from './alertStore';
import recsManagement from '@/components/RecsManagement/store';

Vue.use(Vuex);
Vue.config.devtools = process.env.NODE_ENV === 'development';

// const logger = createLogger({
//     logger: console, // implementation of the `console` API, default `console`
//     logMutations: true,
//     logActions: true
// });

function repeat(str, times) {
  return (new Array(times + 1)).join(str);
}

function pad(num, maxLength) {
  return repeat('0', maxLength - num.toString().length) + num;
}

function getFormattedTime() {
  const time = new Date();
  const fmt = ` @ ${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`;
  return fmt;
}

const logPlugin = (store) => {
  store.subscribe((mutation, state) => {
    const formattedTime = getFormattedTime();
    const message = `${formattedTime} - [mutation] - ${mutation.type}`;

    console.log(message);
  });

  store.subscribeAction((action, state) => {
    const time = new Date();
    const formattedTime = getFormattedTime();
    const message = `${formattedTime} - [action] - ${action.type}`;

    console.log(message);
  });
};

const config = {
  // plugins: [logPlugin],
  modules: {
    admin,
    alert,
    auth,
    ciso,
    cli,
    clipboard,
    cms,
    connections,
    contractBilling,
    ppa,
    deliveryManagement,
    etag,
    etrm,
    lookup,
    notification,
    referenceData,
    reporting,
    tools,
    spp,
    system,
    userOptions,
    visualAnalytics,
    workflow,
    recsManagement,
  },
};

if (process.env.NODE_ENV !== 'production') {
  config.modules.demo = demoStore;
}

const store = new Vuex.Store(config);
store.$notify = notify;

export default store;