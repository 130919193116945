import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'color', label: 'Color', sortable: true,
      cellTemplate: 'PscsColoredCellTemplate',
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime', sortable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'datetime', sortable: true,
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    color: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  userTableKey: -999,
  currentUserTableRow: null,
  userTableData: [],
  userTableConfig: {
    columns: [{
      prop: 'userName', label: 'User Name', sortable: true,
    }, {
      prop: 'firstName', label: 'First Name', sortable: true,
    }, {
      prop: 'lastName', label: 'Last Name', sortable: true,
    }, {
      prop: 'email', label: 'email', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime', sortable: true,
    }],
  },
  nullUserTableRow: {
    id: -999,
    userName: null,
    firstName: null,
    lastName: null,
    email: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getCurrentRow: (state) => state.currentTableRow,
  getCurrentUserRow: (state) => state.currentUserTableRow,
  getUserListFromEntityGroup: (state, getters, rootState) => rootState.lookup.userListFromEntityGroup,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
    dispatch('lookup/fetchUsersFromEntityGroupList', 'CMSUsers', { root: true });
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  resetUserTable({ commit }) {
    commit('resetUserTable');
  },
  loadTableData({ dispatch, commit, state }) {
    STRUCTURES_API.get('/contract-coordinators').then(({ data }) => {
      commit('loadTableData', data.contractCoordinators);
    }).catch((err) => {
      this.$notify('Failed to Load Contract Groups', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postContractCoordinator({ dispatch, commit, state }, contractCoordinator) {
    STRUCTURES_API.post('/contract-coordinators', contractCoordinator).then(({ data }) => {
      state.tableData.push(data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract Coordinator Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Contract Coordinator', 'error');
    });
  },
  updateContractCoordinator({ dispatch, commit, state }, contractCoordinator) {
    STRUCTURES_API.put(`/contract-coordinators/${contractCoordinator.id}`, contractCoordinator).then(({ data }) => {
      this.$notify('Contract Coordinator Updated', 'success');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      commit('updateContractCoordinator', data);
    }).catch((err) => {
      this.$notify('Failed to Update Contract Coordinator', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/contract-coordinators/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract Group Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Removed Contract Coordinator', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
    dispatch('resetUserTable');
    dispatch('loadUserTableData');
  },
  loadUserTableData({ dispatch, commit, state }) {
    STRUCTURES_API.get(`/contract-coordinators/${state.currentTableRow.id}/users`).then(({ data }) => {
      commit('loadUserTableData', data.contractCoordinatorUsers);
    }).catch((err) => {
      this.$notify('Failed to Load Contract Group', 'error');
    });
  },
  postContractCoordinatorUser({ dispatch, commit, state }, contractCoordinatorUser) {
    contractCoordinatorUser.coordinatorId = state.currentTableRow.id;
    STRUCTURES_API.post(`/contract-coordinators/${state.currentTableRow.id}/user`, contractCoordinatorUser).then(({ data }) => {
      state.userTableData.push(data);
      dispatch('currentUserTableRowChange', clone(state.nullUserTableRow));
      this.$notify('User Added to Contract Coordinator Group', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add User to Contract Coordinator Group', 'error');
    });
  },
  deleteUserTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/contract-coordinators/${state.currentTableRow.id}/user/${state.currentUserTableRow.id}`).then(({ data }) => {
      commit('deleteUserTableRow');
      dispatch('currentUserTableRowChange', clone(state.nullUserTableRow));
      this.$notify('User Removed from Contract Coordinator Group', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove User from Contract Coordinator Group', 'error');
    });
  },
  currentUserTableRowChange({ commit }, currentUserRow) {
    commit('currentUserTableRowChange', currentUserRow);
  },
};

const mutations = {
  updateContractCoordinator(state, contractCoordinator) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === contractCoordinator.id);
    state.tableData.splice(rowIndex, 1, contractCoordinator);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  currentUserTableRowChange(state, currentUserRow) {
    state.currentUserTableRow = currentUserRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  resetUserTable(state) {
    state.userTableData = [];
    state.currentUserTableRow = clone(state.nullUserTableRow);
  },
  loadTableData(state, contractCoordinators) {
    state.tableData = contractCoordinators;
  },
  createTableRow(state) {
    const contractCoordinator = clone(state.nullTableRow);
    contractCoordinator.id = state.tableKey++;
    state.currentTableRow = contractCoordinator;
  },
  loadUserTableData(state, userList) {
    state.userTableData = userList;
  },
  createUserRow() {
    const contractCoordinatorUser = clone(state.nullUserTableRow);
    contractCoordinatorUser.id = state.tableKey++;
    state.currentUserTableRow = contractCoordinatorUser;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((contractCoordinator) => contractCoordinator.id !== state.currentTableRow.id);
  },
  deleteUserTableRow(state) {
    state.userTableData = state.userTableData.filter(({ userName }) => userName !== state.currentUserTableRow.userName);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};