import { accessRight, cms } from '@/auth/permission';

export default {
  path: '/cms',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'CMS',
    permission: { name: cms.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'contracts',
    name: 'Contracts',
    props: true,
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Contracts',
      permission: { name: cms.contract, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/Contracts/Contracts'),
  }, {
    path: 'contracts/attachments',
    name: 'ContractAttachment',
    props: true,
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Contract Attachments',
      permission: { name: cms.contract_attachments, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/ContractAttachments/ContractAttachment'),
  }, {
    path: 'contracts/:guid',
    name: 'ContractDetails',
    props: true,
    meta: {
      visible: false,
      requiresAuth: false,
      text: 'Contract Details',
      permission: { name: cms.contract, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/ContractDetails/ContractDetails'),
  }, {
    path: 'contracts/:guid/deliverables/:id/occurrences',
    name: 'DeliverableDetails',
    props: true,
    meta: {
      visible: false,
      requiresAuth: false,
      text: 'Deliverable Occurrence Details',
      permission: { name: cms.deliverable, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/DeliverableDetails/DeliverableDetails'),
  }, {
    path: 'calendar',
    name: 'Calendar',
    props: true,
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Calendar',
      permission: { name: cms.calendar, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/Calendar/Calendar'),
  }, {
    path: 'reports',
    name: 'cms-reports',
    props: true,
    meta: {
      visible: true,
      requiresAuth: false,
      text: 'Reports',
      permission: { name: cms.report, rights: [accessRight.visible] },
    },
    component: () => import('@/components/CMS/Reports/Reports'),
  }],
};
