import {
  tradeDate, coordinatorList, resourcesTagbox, marketTypes,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { has } from '@/utils/dataUtil';

export default {
  dockConfig: {
    alignment: 'LEFT',
    visible: false,
  },
  searchFilterConfig: {
    tradeDate,
    coordinatorList: { ...coordinatorList, required: true },
    resourceList: { ...resourcesTagbox, required: true, maxLimit: 5 },
    marketTypes: {...marketTypes, required: true },
    averageHourlyValue: {
      label: 'Average Hourly Values',
      value: 'MWH',
      type: 'radio',
      options: [{ value: 'MWH', label: 'MWH' }, { value: 'MW', label: 'MW' }],
    },
  },
  fields: [{
    caption: 'RSRC TYPE',
    dataType: 'string',
    dataField: 'rsrcType',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'EXECUTION TYPE',
    dataType: 'string',
    dataField: 'executionType',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERVAL DURATION',
    dataType: 'string',
    dataField: 'intervalDuration',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '0';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING DATE',
    dataField: 'tzTradingDate',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONFIGURATION',
    dataType: 'string',
    dataField: 'configuration',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BINDING FLAG',
    dataType: 'string',
    dataField: 'bindingFlag',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'number',
    dataField: 'tradingInterval',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataType: 'string',
    dataField: 'marketType',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'SCHEDULE TYPE',
    dataType: 'string',
    dataField: 'scheduleType',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TIE POINT USED FOR FLOW',
    dataType: 'number',
    dataField: 'tiePointUsedForFlow',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'MW',
    dataType: 'number',
    dataField: 'mw',
    format: '#,##0.###',
    width: 120,
    area: 'data',
    summaryType: 'sum',
    expanded: true,
  }, {
    caption: 'PRODUCT',
    dataType: 'string',
    dataField: 'product',
    width: 120,
    area: 'column',
    expanded: true,
  }, {
    caption: 'BA',
    dataType: 'string',
    dataField: 'sc',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'RSRC ID',
    dataType: 'string',
    dataField: 'rsrcId',
    width: 140,
    area: 'row',
    expanded: true,
  }, {
    caption: 'MKT START TIME',
    dataField: 'tzMktStartTime',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'INTVL START TIME',
    dataField: 'tzIntvlStartTime',
    width: 120,
    area: 'row',
    expanded: true,
  }, {
    caption: 'INTVL END TIME',
    dataField: 'tzIntvlEndTime',
    width: 120,
    area: 'row',
    expanded: true,
  }],
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};