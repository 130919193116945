import Vue from 'vue';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  ColorPicker,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  InputNumber,
  Message,
  MessageBox,
  Option,
  Popover,
  Row,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  Transfer,
} from 'element-ui';

locale.use(lang);

const components = {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  ColorPicker,
  DatePicker,
  Dialog,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Popover,
  Row,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  Transfer,
};

const install = function (vue, opts = {}) {
  if (install.installed) return;
  Object.values(components).map((c) => vue.component(c.name, c));
};

export default {
  install,
  ...components,
};

Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;