import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { WORKFLOW_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  availableJobList: [],
  availableJobListOptions: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, filterable: true,
    }, {
      prop: 'taskName', label: 'Job Name', sortable: true, filterable: true,
    }, {
      prop: 'cron', label: 'CRON', sortable: true, filterable: true,
    }, {
      prop: 'cronDescription', label: 'CRON Description', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Job Type', sortable: true, filterable: true,
    }, {
      prop: 'startDateOffset', label: 'Start Date Offset', sortable: true, filterable: true,
    }, {
      prop: 'offsetType', label: 'Offset Type', sortable: true, filterable: true,
    }, {
      prop: 'spanInDays', label: 'Span In Days', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'CreatedBy', sortable: true, filterable: true,
    }, {
      prop: 'createdDate', label: 'CreatedDate', sortable: true, filterable: true,
    }, {
      prop: 'updatedBy', label: 'UpdatedBy', sortable: true, filterable: true,
    }, {
      prop: 'updatedDate', label: 'UpdatedDate', sortable: true, filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  nullTableRow: {
    id: -999,
    taskName: null,
    description: null,
    cron: null,
    startDateOffset: null,
    spanInDays: null,
    offsetType: null,
  },
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
    dispatch('fetchAvailableJobList');
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await WORKFLOW_API.get('/task-scheduler/scheduled-tasks');
      commit('loadTableData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  async fetchAvailableJobList({ commit }) {
    try {
      const { data: { data } } = await WORKFLOW_API.get('/task-scheduler/available-tasks');
      commit('setAvailableJobList', data);
      commit('setAvailableJobListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch AvailableJob list', 'error');
      console.error(err);
    }
  },
  postRecord({ state }, availableJob) {
    WORKFLOW_API.post('/task-scheduler', availableJob).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ commit }, availableJob) {
    WORKFLOW_API.post('/task-scheduler', availableJob).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  triggerJob({ state }) {
    WORKFLOW_API.put(`/task-scheduler/${state.currentTableRow.id}`, state.currentTableRow).then(() => {
      this.$notify('Job Triggered', 'success');
    }).catch((err) => {
      this.$notify('Failed to Trigger', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    WORKFLOW_API.delete(`/task-scheduler/${state.currentTableRow.id}`).then(() => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  setAvailableJobList(state, availableJobList) {
    state.availableJobList = availableJobList;
  },
  setAvailableJobListOptions(state, availableJobList) {
    state.availableJobListOptions = availableJobList?.map(({ id, description }) => ({ value: id, label: description }));
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};