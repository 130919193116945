import { STRUCTURES_API } from '@/api';
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', width: 300, label: 'Name', sortable: true,
    }, {
      prop: 'description', width: 300, label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/occurrence-log-status-types').then(({ data }) => {
      commit('loadTableData', data.occurrenceLogStatusTypes);
      this.$notify(`${data.occurrenceLogStatusTypes.length} Occurrence Log Status Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Occurrence Log Status Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postOccurrenceLogStatusType({ state }, occurrenceLogStatusType) {
    STRUCTURES_API.post('/occurrence-log-status-types', occurrenceLogStatusType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Occurrence Log Status Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Occurrence Log Status Type', 'error');
    });
  },
  updateOccurrenceLogStatusType({ commit }, occurrenceLogStatusType) {
    STRUCTURES_API.put(`/occurrence-log-status-types/${occurrenceLogStatusType.id}`, occurrenceLogStatusType).then(({ data }) => {
      this.$notify('Occurrence Log Status Type Updated', 'success');
      commit('updateOccurrenceLogStatusType', data);
    }).catch((err) => {
      this.$notify('Failed to Update Occurrence Log Status Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/occurrence-log-status-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      this.$notify('Occurrence Log Status Type Removed', 'success');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
    }).catch((err) => {
      this.$notify('Failed to Remove Occurrence Log Status Type', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit, state }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateOccurrenceLogStatusType(state, occurrenceLogStatusType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === occurrenceLogStatusType.id);
    state.tableData.splice(rowIndex, 1, occurrenceLogStatusType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, occurrenceLogStatusTypes) {
    state.tableData = occurrenceLogStatusTypes;
  },
  createTableRow(state) {
    const occurrenceLogStatusType = clone(state.nullTableRow);
    occurrenceLogStatusType.id = state.tableKey++;
    occurrenceLogStatusType.createdBy = this.getters['auth/getProfile'].userName;
    occurrenceLogStatusType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = occurrenceLogStatusType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter(occurrenceLogStatusType => occurrenceLogStatusType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};