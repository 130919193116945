import moment from 'moment';
import qs from 'qs';

import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import LOOKUP_STORE from '@/store/lookupStore';

import { createMutations } from '@/utils/vuexHelper';
import { hasPermission } from '@/utils/authUtils';
import { caiso, accessRight } from '@/auth/permission';
import { has, HEColumns, cloneDeep } from '@/utils/dataUtil';
import { getMomentDateString, getZoneName } from '@/utils/dateUtil';
import { verifyConsolidatedSubmit, getOpenMarkets } from '@/components/Scheduling/utils';
import {
  CISO_SCHD_API,
  CISO_TRADE_SCHD_API,
  CISO_OASIS_API,
  STRUCTURES_API,
  IDENTITY_API,
} from '@/api';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import saveAs from 'file-saver';
import { mergeTradesAndTradeSchedules, tableModelToScheduleModel } from '../../Trades/Submission/store/util';
import consolidatedUtils from './static';
import detailConfigs from './static/detailConfigs';

const ACTION_TYPES = {
  0: 'submit',
  1: 'cancel',
  2: 'retrieve',
};

const CREATE_PROPOSED = 2;
const COPY_PROPOSED = 3;
const CREATE_TRANSACTION = 4;

const PROPOSED = 'PROPOSED';

const compareHourEndings = (h1, h2) => {
  const lookupPairs = {
    '2*': '25',
    '25': '2*',
  };
  return h1 === h2 || h1 === lookupPairs[h2];
}

const mapColumns = (columns, data) => {
  data.forEach((heData) => {
    const columnIndex = columns.indexOf(columns.find((he) => compareHourEndings(he.he, heData.he.toString()) ));
    // Needed to match user preference for long day: 2* or 25
    const hourEnding = columns[columnIndex].he;
    columns[columnIndex] = {...heData, he: hourEnding};
  });
}

const state = {
  hideClosedHours: false,
  initialized: false,
  permissions: {},
  selectedDate: dateStore.getDefaultDate().toISOString(),
  defaultDate: dateStore.getDefaultDate().toISOString(),
  selectedMarketType: caisoStore.getMarketType(),
  selectedLocation: undefined,
  selectedLocationName: undefined,
  selectedLocationId: undefined,
  selectedLocationGroup: undefined,
  selectedLocationGroupId: undefined,
  locationGroupLocations: [],
  selectedScheduleTypes: [],
  selectedProducts: [],
  selectedSearchType: 'ALL',
  sibrValueTypeFlag: false,
  bsapValueTypeFlag: false,
  istValueTypeFlag: false,
  showExistingFlag: true,
  cbValueTypeFlag: false,
  statusIndicator: false,
  statuses: [],
  pendingStatuses: [],
  multipleSelection: [],
  startHour: '1',
  endHour: '24',
  valueTypeFlag: false,
  hours: [],
  submitOptions: [{
    id: CREATE_PROPOSED, name: 'Create Proposed', icon: 'fa fa-file-text-o',
  }, {
    id: COPY_PROPOSED, name: 'Copy All Proposed', icon: 'fa fa-files-o',
  }, {
    id: CREATE_TRANSACTION, name: 'Create Transaction', icon: 'fa fa-plus-square-o',
  }],
  showOpenHours: false,
  splitConfig: false,
  overrideSearch: true,

  // data parts
  baseSchedules: [],
  sibrSchedules: [],
  istSchedules: [],
  cbSchedules: [],

  tableData: [],
  tableConfig: consolidatedUtils.generateConfig(),
  tableHourData: [],

  tableHourConfig: consolidatedUtils.generateTableHourConfig(),
  transactionDialogVisible: false,
  // isMarketOpen: true,
  isISTOpen: true,
  selectedSchedules: [],
  selectedSchedulesCache: [],
  schedulingCoordinators: [],
  counterParties: [],
  hourStatus: null,
  timeZone: 'PPT',
  openMarkets: null,
  cbNodes: [],
  selectedCbNode: undefined,
  detailPreviewData: [],
  detailPreviewConfig: detailConfigs.base,
  errorMessageMap: {},
  tradeDialogVisible: false,
  isCurrentlyFetchingData: false,
};

const getters = {
  params: (state) => ({
    date: state.selectedDate,
    marketType: state.selectedMarketType,
    location: state.selectedLocationId,
    locationName: state.selectedLocationName,
    schdType: state.selectedScheduleTypes.join(','),
    product: state.selectedProducts.join(','),
    locationGroup: state.selectedLocationGroup,
    locationGroupId: state.selectedLocationGroupId,
  }),
  displayDate: (state) => getMomentDateString(state.selectedDate),
  // getIsMarketOpen: (state) => state.isMarketOpen,
  getFeatureFlags: () => LOOKUP_STORE.state.userFeatureFlagList,
  getTableData: (state, getters) => {
    // filter by permissions
    const filter = [];
    const { getConsolidatedPermissions: permissions } = getters;
    if (state.bsapValueTypeFlag && permissions.base.read) filter.push('base');
    if (state.sibrValueTypeFlag && permissions.bid.read) filter.push('sibr');
    if (state.istValueTypeFlag && permissions.ist.read) filter.push('ist');
    if (state.cbValueTypeFlag && permissions.cb.read) filter.push('virtual');
    let data = filter.length ? state.tableData.filter((data) => filter.includes(data.schdType)) : [];
    if (state.showExistingFlag) data = data.filter((location) => location.variant !== 'NOT_EXISTS');
    return consolidatedUtils.filterTableData(data, state.selectedSearchType, state.valueTypeFlag, state.splitConfig);
  },
  getConsolidatedPermissions: (state, getters, rootState, rootGetters) => {
    const perms = rootGetters['auth/getPermissions'];

    const base = {
      write: hasPermission(perms, caiso.scheduling.baseSchedules, accessRight.write),
      read: hasPermission(perms, caiso.scheduling.baseSchedules, accessRight.write)
      || hasPermission(perms, caiso.scheduling.baseSchedules, accessRight.read),
    };

    const bid = {
      read: hasPermission(perms, caiso.scheduling.bids, accessRight.read)
      || hasPermission(perms, caiso.scheduling.bids, accessRight.write),
      write: hasPermission(perms, caiso.scheduling.bids, accessRight.write),
    };

    const ist = {
      read: hasPermission(perms, caiso.scheduling.trades, accessRight.read)
      || hasPermission(perms, caiso.scheduling.trades, accessRight.write),
      write: hasPermission(perms, caiso.scheduling.trades, accessRight.write),
    };

    const cb = {
      read: hasPermission(perms, caiso.scheduling.virtual, accessRight.read)
      || hasPermission(perms, caiso.scheduling.virtual, accessRight.write),
      write: hasPermission(perms, caiso.scheduling.virtual, accessRight.write),
    };

    return {
      base,
      bid,
      ist,
      cb,
      read: base.read || base.write || bid.read || bid.write || ist.read || ist.write || cb.read || cb.write,
      write: base.write || bid.write || ist.write || cb.write,
    };
  },
  selectedScheduleTypes: (state) => {
    let types = [...new Set([...state.selectedSchedules.map(({ schdType }) => schdType)])];
    // CB schedule type is only for DAM.
    if (state.selectedMarketType === 'RTM') {
      types = types.filter((type) => type !== 'virtual');
    }
    return types;
  },
  companies: (state) => state.schedulingCoordinators,

  locationList: (state) => {
    const { locationGroupLocations } = state;
    const locations = locationGroupLocations || [];
    return [...new Set(locations.map(({ shortName }) => shortName))]
      .map((name) => ({ label: name, value: name }));
  },
  momentDate: (state) => {
    const today = getMomentDateString(state.selectedDate);
    const fullTz = getZoneName(state.timeZone)?.tz;
    return dateStore.toMoment(today, fullTz);
  },
};

const actions = {
  async initialize({ dispatch, commit }) {
    await dispatch('fetchReferenceStoreData');
    await dispatch('initCheckboxes');
    dispatch('LOOKUP_STORE/fetchUserFeatureFlagList');
    dispatch('loadSchedulingCoordinators');
    commit('setShowOpenHours', state.showOpenHours);
    commit('setInitialized', true);
  },
  initProps({ state, commit, dispatch }, props) {
    const date = props.date ? props.date : dateStore.getDefaultDate().toISOString();
    commit('setSelectedDate', date);
    const marketType = props.marketType ? props.marketType : caisoStore.getMarketType();
    commit('setSelectedMarketType', marketType);
    if (props.locationGroupId) {
      const locationGroupName = state.REFERENCE_DATA_STORE.locationGroupList
        .find((group) => group.id === parseInt(props.locationGroupId, 10))
        ?.shortName;
      commit('setSelectedLocationGroup', locationGroupName);
      commit('setSelectedLocationGroupId', props.locationGroupId);
      dispatch('fetchLocationGroupLocations', props.locationGroupId);
    }
    commit('setSelectedScheduleTypes', []);
    commit('setSelectedProducts', []);
  },
  async resetProps({ commit }) {
    commit('setSelectedDate', dateStore.getDefaultDate().toISOString());
    commit('setSelectedMarketType', caisoStore.getMarketType());
    commit('setSelectedLocationId', undefined);
    commit('setSelectedLocationGroup', undefined);
    commit('setSelectedLocationGroupId', undefined);
  },
  async fetchReferenceStoreData({ dispatch }) {
    const payload = {
      referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'],
      market: 'CAISO',
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
  },
  async fetchNodes({ state, commit }) {
    try {
      const date = getMomentDateString(state.selectedDate);
      const { data } = await CISO_OASIS_API.get(`/oasis-reports/atlas/cb-nodes-v2?tradingDate=${date}`);
      commit('setCbNodes', data);
    } catch (e) {
      this.$notify({ type: 'error', message: 'Failed to fetch Virtual Bidding Nodes' });
    }
  },
  async defaultUserSettings({ state, commit, dispatch }) {
    let userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (!userLocationGroup) {
      [userLocationGroup] = state.REFERENCE_DATA_STORE.locationGroupList;
    }
    const date = dateStore.getDefaultDate().toISOString();
    if (date !== state.defaultDate) {
      commit('setSelectedDate', date);
      commit('setDefaultDate', date);
    }

    const mt = caisoStore.getMarketType();
    if (mt !== state.selectedMarketType) commit('setSelectedMarketType', mt);

    commit('setSelectedLocationGroup', userLocationGroup.shortName);
    commit('setSelectedLocationGroupId', userLocationGroup.id);
    dispatch('fetchLocationGroupLocations', userLocationGroup.id);
  },
  async loadSchedulingCoordinators({ commit }) {
    const schedulingCoordinatorList = [];
    const counterPartyList = [];
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      if (entities) {
        schedulingCoordinatorList.push(...caisoStore.mapEntityCoordinatorList(entities.filter((x) => x.type === 'SC')));
        counterPartyList.push(
          ...caisoStore
            .mapEntityCoordinatorList(
              entities.filter((x) => x.type === 'COUNTER_PARTY'),
            ),
        );
        schedulingCoordinatorList.sort((x, y) => x.label.localeCompare(y.label, 'en', { numeric: true }));
        counterPartyList.sort((x, y) => x.label.localeCompare(y.label, 'en', { numeric: true }));
      }
    } catch (error) {
      vue.$notify('Failed to load Scheduling Coordinators', 'error');
      console.log(error);
    }
    commit('setSchedulingCoordinators', schedulingCoordinatorList);
    commit('setCounterParties', counterPartyList);
  },

  initCheckboxes({ getters, commit }) {
    Object.keys(getters.getConsolidatedPermissions).forEach((schedType) => {
      if (getters.getConsolidatedPermissions[schedType].read) {
        switch (schedType) {
        case 'base': commit('setBsapValueTypeFlag', true); break;
        case 'bid': commit('setSibrValueTypeFlag', true); break;
        case 'ist': commit('setIstValueTypeFlag', true); break;
        case 'cb': commit('setCbValueTypeFlag', true); break;
        default:
        }
      }
    });
  },
  async fetchLocationGroupLocations({ commit }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    commit('setLocationGroupLocations', data.locationGroupLocations);
  },
  async loadIst({ commit, state, dispatch }, tradeSchedules) {
    const { selectedDate } = state;
    // state.selectedLocationName should not be used here
    const locationR = state.REFERENCE_DATA_STORE.locationList
      .find(({ shortName }) => shortName === state.selectedLocationName);
    const location = locationR ? locationR.label : null;
    const tradeType = state.searchType !== 'ALL' ? state.searchType : null;
    const marketType = state.selectedMarketType;
    const params = {
      date: selectedDate, location, tradeType, marketType, locationGroup: state.selectedLocationGroup,
    };
    try {
      const { data } = await CISO_SCHD_API.get('ist', { params });
      const tradeItem = { trades: data.trades, tradeSchedules };

      const ist_schedules = mergeTradesAndTradeSchedules(
        tradeItem.trades,
        tradeItem.tradeSchedules,
        [],
        state.selectedDate,
        'he25');

      commit('setIstSchedules', ist_schedules);
    } catch (error) {
      console.error(error);
    }
  },
  async mapData({ state, commit, dispatch}) {
    // resets config ?
    // state.tableConfig = generateConfig(state.selectedDate);
    commit('setTableHourData', []);
    commit('setMultipleSelection', []);
    const base = consolidatedUtils.formatSchedule(state.baseSchedules, state.selectedDate, 'base');
    const sibr = consolidatedUtils.formatSchedule(state.sibrSchedules, state.selectedDate, 'sibr');
    const cb = consolidatedUtils.formatSchedule(state.cbSchedules, state.selectedDate, 'virtual');
    // ist schedules
    const ist = state.istSchedules.map((sched) => ({
      schdType: 'ist',
      scheduleName: sched.name,
      sc: sched.sc,
      resource: sched.name,
      marketType: sched.marketType,
      date: sched.effectiveDate,
      counterParty: sched.counterParty,
      direction: sched.direction,
      resourceType: sched.tradeType,
      product: sched.tradeProductType,
      variant: sched.variant,
      statuses: sched.statuses || [],
      factor: sched.direction === 'SELL' ? -1 : 1,
      ...sched,
    }));
    ist.forEach((schedule) => {
      Object.keys(schedule).forEach((key) => {
        if (key.includes('_val')) {
          delete schedule[key];
        }
      });
    });

    if (state.selectedMarketType === 'RTM')
      dispatch('toggleClosedHours');

    const schedules = [...base, ...sibr, ...cb, ...ist];
    commit('setTableData', [...schedules]);
  },
  async fetchDetails({ commit, state }, schedule) {
    const {
      schdType,
      variant,
      scheduleName,
      resource,
      marketType,
    } = schedule;
    const config = detailConfigs[schdType];
    let columns = HEColumns({}, true, state.selectedDate);
    switch (schdType) {
    case 'base': {
      const baseDetails = await CISO_SCHD_API.get(`base/${scheduleName}`, { params: { variant } });
      mapColumns(columns, baseDetails.data.baseSchedulePoints);
      break;
    }
    case 'sibr': {
      const bidDetails = await CISO_SCHD_API.get(`bid/${scheduleName}`, { params: { variant } });
      mapColumns(columns, bidDetails.data.bidSchedules);

      bidDetails.data.selfSchedules.forEach((heData) => {
        const columnIndex = columns.indexOf(columns.find((he) => compareHourEndings(he.he, heData.he.toString()) ));
        columns[columnIndex].ss = heData.pt;
      });
      break;
    }
    case 'virtual': {
      const cbDetails = await CISO_SCHD_API.get(`virtual/${scheduleName}?variant=${ variant }`);
      mapColumns(columns, cbDetails.data.bidSchedules);
      break;
    }
    case 'ist': {
      const istDetails = await CISO_SCHD_API.get(`ist/schedules?tradeDate=${state.selectedDate}&name=${scheduleName}`);
      const tradeDetails = await CISO_SCHD_API.get('ist', { params: { date: state.selectedDate, marketType } });
      const tradeDetail = tradeDetails.data.trades.filter(({ name }) => name === resource);
      const heColumns = HEColumns({}, true, state.selectedDate).map(({ prop }) => prop);

      const [ist_schedule] = mergeTradesAndTradeSchedules(
        tradeDetail,
        istDetails.data.tradeSchedules,
        [],
        state.selectedDate,
        'he25',
      );
      columns = Object.keys(ist_schedule).filter((key) => heColumns.includes(key)).map((he) => ({
        he,
        heVal: parseInt(he.replace('he', ''), 10),
        counterQuantity: ist_schedule[he].counterQuantity,
        adjustedQuantity: ist_schedule[he].adjustedQuantity,
        quantity: ist_schedule[`${he}_val`],
      }));
      break;
    }
    default: break;
    }

    commit('setDetailPreviewData', columns);
    commit('setDetailPreviewConfig', config);
  },
  async fetchData({
    commit, state, getters, dispatch,
  }) {
    if (!state.selectedLocationGroup) {
      this.$notify('Please Select a Location Group', 'warning');
      return;
    }
    const { getConsolidatedPermissions: permissions } = getters;
    // const props = ['highlight', 'highlightRow', 'productType', 'total', 'rowType', 'valueTypeFlag',
    //   'isResource', 'isConfig', 'he25', ...HEColumns().map((x) => x.prop)];
    const { params } = getters;

    commit('setIsCurrentlyFetchingData', true);

    // fetch base scheduels
    if (permissions.base.read) {
      try {
        const {
          date, marketType, locationGroup, locationGroupId, location, locationName,
        } = params;
        const reqBody = {
          searchType: state.selectedSearchType,
          start: date,
          marketType,
          locationGroup,
          locationGroupId,
          location,
          locationName,
        };
        const { data: base_schedules } = await CISO_SCHD_API.get('base/summary', { params: reqBody });

        if (base_schedules.summaries && base_schedules.summaries.length) {
          commit('setBaseSchedules', base_schedules?.summaries?.map((s) => ({ ...s, schdType: 'base' })) || []);
        } else {
          commit('setBaseSchedules', []);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      commit('setBaseSchedules', []);
    }
    // fetch bid sibr schedules
    if (permissions.bid.read) {
      try {
        const {
          date, marketType, locationGroup, locationGroupId, locationName, location,
        } = params;
        const reqBody = {
          searchType: state.selectedSearchType,
          start: date,
          marketType,
          locationGroup,
          locationGroupId,
          location,
          locationName,
        };
        const { data: sibr_schedules } = await CISO_SCHD_API.get('bid/summary', {
          params: reqBody,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        });
        if (sibr_schedules.summaries && sibr_schedules.summaries.length) {
          commit('setSibrSchedules', sibr_schedules?.summaries?.map((s) => ({ ...s, schdType: 'sibr' })) || []);
        } else {
          commit('setSibrSchedules', []);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      commit('setSibrSchedules', []);
    }
    // fetch ist schedules
    if (permissions.ist.read) {
      try {
        const { data: ist_schedules } = await CISO_SCHD_API
          .get(`/ist/schedules?tradeDate=${state.selectedDate}`);
        await dispatch('loadIst', ist_schedules.tradeSchedules);
      } catch (error) {
        console.error(error);
      }
    }
    // fetch cb schedules
    if (permissions.cb.read && params.marketType === 'DAM') {
      try {
        const { data: cb_schedules } = await CISO_SCHD_API.get('virtual/summary', {
          params: {
            searchType: 'ALL',
            start: params.date,
            fullSchedules: true,
          },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        });
        if (cb_schedules.summaries && cb_schedules.summaries.length) {
          let cbSchedules = cb_schedules.summaries;
          const lgl = state.locationGroupLocations.map(({ shortName }) => shortName);
          cbSchedules = cbSchedules.filter(({ resource }) => lgl.includes(resource));
          commit('setCbSchedules', cbSchedules?.map((s) => ({ ...s, schdType: 'virtual' })) || []);
        } else {
          commit('setCbSchedules', []);
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      commit('setCbSchedules', []);
    }

    commit('setIsCurrentlyFetchingData', false);

    commit('setTableConfig', consolidatedUtils.generateConfig(state.selectedDate));
    commit('setTableHourConfig', consolidatedUtils.generateTableHourConfig(state.selectedDate));
    await dispatch('loadHours');
    dispatch('mapData');
  },
  async loadHours({ commit, state }) {
    const hours = dateStore.getTimeRange(state.selectedDate, 60).map((v) => ({ value: v, label: v.he }));
    commit('setHours', hours);

    const data = await getOpenMarkets(state.selectedDate);
    commit('setOpenMarkets', data);
  },
  async copyForward({ state }, item) {
    const bidSchedules = state.selectedSchedules
      .filter(({ variant, schdType }) => variant === 'ACTUAL' && schdType === 'sibr')
      .map(({ scheduleName }) => scheduleName);
    const baseSchedules = state.selectedSchedules
      .filter(({ variant, schdType }) => variant === 'ACTUAL' && schdType === 'base')
      .map(({ scheduleName }) => scheduleName);
    const virtualSchedules = state.selectedSchedules
      .filter(({ variant, schdType }) => variant === 'ACTUAL' && schdType === 'virtual')
      .map(({ scheduleName }) => scheduleName);
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, 'PPT');
    const [startTime, endTime] = momentRange;

    const payload = {
      startTime: startTime.toISOString(),
      endTime: endTime.add('days', 1).toISOString(),
      marketType: item.copyMarketType,
      variant: 'ACTUAL',
      actionType: 'Copy',
    };

    if (!bidSchedules.length && !baseSchedules.length && !virtualSchedules) {
      this.$notify({ type: 'warning', message: 'No submittable schedules selected' });
    }

    if (baseSchedules.length) {
      try {
        await CISO_SCHD_API.put('base/copy', { ...payload, scheduleNames: baseSchedules });
        this.$notify({ type: 'success', message: 'Submit Started' });
      } catch (error) {
        this.$notify({ type: 'error', message: 'Submit Failed' });
        console.error(error);
      }
    }

    if (bidSchedules.length) {
      try {
        await CISO_SCHD_API.put('bid/copy', { ...payload, scheduleNames: bidSchedules });
        this.$notify({ type: 'success', message: 'Submit Started' });
      } catch (error) {
        this.$notify({ type: 'error', message: 'Submit Failed' });
        console.error(error);
      }
    }

    if (virtualSchedules.length) {
      try {
        await CISO_SCHD_API.put('virtual/copy', { ...payload, scheduleNames: virtualSchedules });
        this.$notify({ type: 'success', message: 'Submit Started' });
      } catch (error) {
        this.$notify({ type: 'error', message: 'Submit Failed' });
        console.error(error);
      }
    }
  },
  async submitSchedules({ state, getters, dispatch }, item) {
    getters.selectedScheduleTypes.forEach(async (schdType) => {
      switch (schdType) {
      case 'base': dispatch('baseSubmitAction', item); break;
      case 'sibr': dispatch('bidSubmitAction', item); break;
      case 'ist': dispatch('istSubmitAction', item); break;
      case 'virtual': dispatch('cbSubmitAction', item); break;
      default:
      }
    });
  },
  async deleteSchedule({ state, getters, dispatch }) {
    const {
      selectedDate, startHour, endHour, selectedMarketType,
    } = state;
    if (state.selectedSchedules.length > 1) {
      this.$notify({ type: 'warning', message: 'Cannot Delete Multiple Schedules' });
      return;
    }
    const schedule = state.selectedSchedules[0];
    if (schedule.variant.toUpperCase() === 'SUBMITTED' || schedule.variant.toUpperCase() === 'RETRIEVED') {
      this.$notify({ type: 'warning', message: 'Cannot Delete Submitted Schedule' });
      return;
    }

    switch (schedule.schdType) {
    case 'base':
      await CISO_SCHD_API.delete(`base/${schedule.scheduleName}`, { params: { variant: schedule.variant } });
      break;
    case 'sibr':
      await CISO_SCHD_API.delete(`bid/${schedule.scheduleName}`, { params: { variant: schedule.variant } });
      break;
    case 'ist':
      await CISO_SCHD_API.delete(`/ist/${schedule.scheduleName}`, { params: { tradeDate: state.selectedDate } });
      break;
    case 'virtual':
      await CISO_SCHD_API.delete(`virtual/${schedule.scheduleName}`, { params: { variant: schedule.variant } });
      break;
    default:
      break;
    }
    this.$notify({ type: 'info', message: 'Delete Completed' });
    await dispatch('fetchData');
  },
  async istSubmitAction({ state, dispatch }, item) {
    const istSchedules = state.selectedSchedules.filter((schedule) => schedule.schdType === 'ist');

    if (istSchedules.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    }

    const columns = HEColumns({}, true, state.selectedDate);
    istSchedules.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const names = istSchedules.map((x) => x.name);
    const params = {
      startTime: state.startHour.timeTZ.toISOString(),
      endTime: state.endHour.timeTZ.add(1, 'hour').toISOString(),
      tradeDate: state.startHour.timeTZ.clone().startOf('day').toISOString(),
      names,
    };

    if (ACTION_TYPES[item.id] === 'cancel') dispatch('cancelIstSchedules', params);
    else if (ACTION_TYPES[item.id] === 'retrieve') dispatch('retrieveIstSchedules', params);
    else { dispatch('submitIstSchedules', params); }
  },
  async retrieveIstSchedules({ state, commit }, params) {
    try {
      await CISO_SCHD_API.post('/ist/schedules/retrieve', params);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
    }
  },
  async submitIstSchedules({ state, commit }, params) {
    try {
      await CISO_SCHD_API.post('/ist/schedules/submit', params);
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Submit Failed' });
    }
  },
  async cancelIstSchedules({ state, commit }, params) {
    try {
      await CISO_SCHD_API.post('/ist/schedules/cancel', params);
      this.$notify({ type: 'success', message: 'Cancel Started' });
    } catch (error) {
      console.error(error);
      this.$notify({ type: 'error', message: 'Cancel Failed' });
    }
  },
  cbSubmitAction({ state, dispatch }, item) {
    const cbSchedules = state.selectedSchedules.filter((schedule) => schedule.schdType === 'virtual');
    if (cbSchedules.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    } if (cbSchedules.find((m) => m.variant.toLowerCase() === PROPOSED)) {
      this.$notify({ type: 'warning', message: `Cannot ${ACTION_TYPES[item.id]} proposed schedules` });
      return;
    }

    const columns = HEColumns({}, true, state.selectedDate);
    cbSchedules.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const scheduleNames = cbSchedules.filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);
    if (!scheduleNames || scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: `Unable to ${ACTION_TYPES[item.id]} selected schedules.` });
      return;
    }
    const startTimeParam = state.startHour.timeTZ.clone();
    const endTimeParam = state.endHour.timeTZ.clone();
    const params = {
      startTime: startTimeParam.toISOString(),
      endTime: endTimeParam.add(1, 'hour').toISOString(),
      marketType: state.selectedMarketType,
      variant: 'ACTUAL',
      scheduleNames,
    };

    if (ACTION_TYPES[item.id] === 'cancel') dispatch('cancelCbSchedules', params);
    else if (ACTION_TYPES[item.id] === 'retrieve') dispatch('retrieveCbSchedules', params);
    else { dispatch('submitCbSchedules', params); }
  },

  async submitCbSchedules({ state, commit }, params) {
    try {
      await CISO_SCHD_API.patch('virtual', { ...params });
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async cancelCbSchedules({ state, commit }, params) {
    try {
      const payload = { url: 'virtual', method: 'delete', data: { ...params } };
      await CISO_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Cancel Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Cancel Failed' });
      console.error(error);
    }
  },
  async retrieveCbSchedules({ state }, params) {
    try {
      const payload = { url: 'virtual/sibr', method: 'put', data: { ...params } };
      await CISO_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
      console.error(error);
    }
  },
  bidSubmitAction({ state, commit, dispatch }, item) {
    const bidSchedules = state.selectedSchedules.filter((schedule) => schedule.schdType === 'sibr');
    if (bidSchedules.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    } if (bidSchedules.find((m) => m.variant.toLowerCase() === PROPOSED)) {
      this.$notify({ type: 'warning', message: `Cannot ${ACTION_TYPES[item.id]} proposed schedules` });
      return;
    }

    const columns = HEColumns({}, true, state.selectedDate);
    bidSchedules.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const scheduleNames = bidSchedules.filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);
    if (!scheduleNames || scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: `Unable to ${ACTION_TYPES[item.id]} selected schedules.` });
      return;
    }
    const startTimeParam = state.startHour.timeTZ.clone();
    const endTimeParam = state.endHour.timeTZ.clone();
    const params = {
      startTime: startTimeParam.toISOString(),
      endTime: endTimeParam.add(1, 'hour').toISOString(),
      marketType: state.selectedMarketType,
      variant: 'ACTUAL',
      scheduleNames,
    };

    if (ACTION_TYPES[item.id] === 'cancel') dispatch('cancelBidSchedules', params);
    else if (ACTION_TYPES[item.id] === 'retrieve') dispatch('retrieveBidSchedules', params);
    else { dispatch('submitBidSchedules', params); }
  },
  async submitBidSchedules({ state, commit }, params) {
    try {
      await CISO_SCHD_API.patch('bid', { ...params });
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async cancelBidSchedules({ state, commit }, params) {
    try {
      const payload = { url: 'bid', method: 'delete', data: { ...params } };
      await CISO_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Cancel Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Cancel Failed' });
      console.error(error);
    }
  },

  async retrieveBidSchedules({ state }, params) {
    try {
      const payload = { url: 'bid/sibr', method: 'put', data: { ...params } };
      await CISO_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
      console.error(error);
    }
  },

  baseSubmitAction({ state, dispatch, commit }, item) {
    const baseSchedules = state.selectedSchedules.filter((schedule) => schedule.schdType === 'base');
    if (baseSchedules.length <= 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    } if (baseSchedules.find((m) => m.variant.toLowerCase() === PROPOSED)) {
      this.$notify({ type: 'warning', message: `Cannot ${ACTION_TYPES[item.id]} proposed schedules` });
      return;
    }
    const columns = HEColumns({}, true, state.selectedDate);
    baseSchedules.forEach((schedule) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (schedule && has(schedule, prop)) schedule[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });
    const scheduleNames = baseSchedules.filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);
    if (!scheduleNames || scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: `Unable to ${ACTION_TYPES[item.id]} selected schedules.` });
      return;
    }
    const params = {
      startTime: state.startHour.timeTZ.toISOString(),
      endTime: state.endHour.timeTZ.add(1, 'hour').toISOString(),
      marketType: state.selectedMarketType,
      variant: 'ACTUAL',
      scheduleNames,
    };

    if (ACTION_TYPES[item.id] === 'cancel') {
      this.$notify({ type: 'warning', message: 'Cannot Cancel BSAP Schedules' });
    } else if (ACTION_TYPES[item.id] === 'retrieve') dispatch('retrieveBaseSchedules', params);
    else { dispatch('submitBaseSchedules', params); }
  },

  async submitBaseSchedules({ state }, params) {
    try {
      await CISO_SCHD_API.patch('base', { ...params });
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async retrieveBaseSchedules({ state, commit }, params) {
    try {
      const payload = { url: 'base/bsap', method: 'put', data: { ...params } };
      await CISO_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
      console.error(error);
    }
  },

  async createCb({ state, dispatch }, params) {
    const {
      resource, resourceType, virtualBidType, sc,
    } = params;
    const dateStr = moment(state.selectedDate).format('YYYYMMDD');

    const reqBody = {
      startTime: state.selectedDate,
      endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
      variant: 'ACTUAL',
      scheduleName: `${sc}-${virtualBidType}-${dateStr}-${resource}`,
      sc,
      resource,
      resourceType,
      virtualBidType,
      locationGroupName: state.selectedLocationGroup,
    };
    try {
      const response = await CISO_SCHD_API.post('virtual', reqBody);
      if (response) {
        dispatch('fetchReferenceStoreData');
        if (state.selectedLocationGroupId) dispatch('fetchLocationGroupLocations', state.selectedLocationGroupId);
        dispatch('fetchData');
      }
    } catch (error) {
      this.$notify({ type: 'error', message: 'Unable to create convergence bid' });
      console.error((error));
    }
  },
  updateHourTableData({ state, commit }, item) {
    const data = ['System', 'Market', 'Status', 'Message', 'Updated By', 'Updated', 'Submitted']
      .map((row) => ({ row }));
    const errorMessageMap = {};
    HEColumns({}, true, state.selectedDate).forEach(({ prop }) => {
      const obj = item[prop];
      const msgKey = item.schdType === 'ist' ? 'message' : 'errorMessage';
      if (obj) {
        const type = obj.systemBaseSchedulePointTotal || obj.systemSelfScheduleTotal || obj.quantity;
        const typeActual = obj.marketBaseSchedulePointTotal || obj.marketSelfScheduleTotal || obj.marketQuantity;
        data[0][prop] = type;
        data[1][prop] = typeActual;
        data[2][prop] = obj.marketStatus || obj.systemStatus;
        data[3][prop] = obj[msgKey] ? '[msg]' : '';
        data[4][prop] = obj.updatedBy;
        data[5][prop] = moment(obj.updatedDate).format('MM/DD/YY HH:mm:ss');
        data[6][prop] = obj.submittedDate ? moment(obj.submittedDate).format('MM/DD/YY HH:mm:ss') : obj.submittedDate;
        errorMessageMap[prop] = obj[msgKey];
      }
    });
    commit('setTableHourData', data);
    commit('setErrorMessageMap', errorMessageMap);
  },
  async generateScheduleExport({ getters, state }, inputParams = []) {
    if (!state.selectedLocationGroup) {
      this.$notify('Please Select a Location Group', 'warning');
      return;
    }
    try {
      const fullName = `STANDARD_SCHEDULE_DETAILS_RPT_${state.selectedDate}.xlsx`;
      const headers = { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
      const scheduleTypes = {
        base: state.bsapValueTypeFlag,
        bid: state.sibrValueTypeFlag,
        ist: state.istValueTypeFlag,
        cb: state.cbValueTypeFlag,
      };
      const selectedTypes = Object.keys(scheduleTypes).filter((type) => scheduleTypes[type]);
      this.$notify({ type: 'info', message: 'Generating file...' });

      const params = {
        start: state.selectedDate,
        marketType: state.selectedMarketType,
        locationGroup: state.selectedLocationGroup,
        scheduleTypes: selectedTypes,
      };

      await CISO_SCHD_API.get('consolidated/export', {
        params,
        headers,
        responseType: 'blob',
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      }).then((response) => {
        this.$notify({ type: 'info', message: 'Download completed, saving...' });
        saveAs(response.data, fullName);
      }).catch((error) => {
        this.$notify({ type: 'error', message: 'Error downloading the file' });
      });
    } catch (error) {
      this.$notify('Error creating accounting report', 'warning');
    }
  },
  async saveNewTrade({ commit, dispatch }, payload) {
    try {
      await CISO_TRADE_SCHD_API.post('/trades', payload);
      await dispatch('fetchData');
      this.$notify({ type: 'success', message: 'Save Completed' });
    } catch (error) {
      this.$notify({ type: 'warning', message: 'Save Error' });
      console.error(error);
    } finally {
      commit('setTradeDialogVisible', false);
    }
  },
  async toggleClosedHours({ state, getters }) {
    let config = consolidatedUtils.generateConfig(state.selectedDate);

    if (state.hideClosedHours) {
      const { getConsolidatedPermissions: permissions } = getters;

      let markets = {
        "SIBR": state.sibrValueTypeFlag && permissions.bid.read,
        "BSAP": state.bsapValueTypeFlag && permissions.base.read,
        "IST": state.istValueTypeFlag && permissions.ist.read
      }
     
      let filteredMarkets = state.openMarkets ? state.openMarkets.filter(market => market.status === 'OPEN' && 
        market.marketType ==='RTM' && markets[market.system]) : [];

      if (filteredMarkets.length !== 0) {
        let minHour = Math.min.apply(null, filteredMarkets.map(m => m.startHour))
        let maxHour = Math.max.apply(null, filteredMarkets.map(m => m.endHour))

        config.columns.forEach((col) => {
            if (col.isHourEndingColumn && (Number(col.he) < minHour || Number(col.he) > maxHour) ) {
              col.visible = false;
            }
          });
      }
      else {
        config.columns.forEach((col) => {
          if (col.isHourEndingColumn)
            col.visible = false;
        });
      }
    }
      
    state.tableConfig = config;
  },
};

const mutations = {
  setStartHour(state, value) {
    const hour = value?.timeTZ?.hours();
    if (hour > state.endHour) state.startHour = state.endHour;
    else state.startHour = value;
  },
  setEndHour(state, value) {
    const hour = value?.timeTZ?.hours();
    if (hour < state.startHour) state.endHour = state.startHour;
    else state.endHour = value;
  },
  reset(state) {
    state.tableHourData = [];
    state.multipleSelection = [];
    state.overrideSearch = true;
  },
  setShowOpenHours(state, value) {
    state.showOpenHours = value;
    const config = consolidatedUtils.generateConfig(state.selectedDate);
    if (value) {
      config.columns.forEach((col) => {
        if (
          col.isHourEndingColumn
          && state.selectedMarketType === 'RTM'
          && col.timeTZ.isBefore(moment())
        ) {
          col.visible = false;
        }
      });
    }
    state.tableConfig = config;
  },
  ...createMutations(
    'hideClosedHours',
    'initialized',
    'permissions',
    'tableData',
    'selectedDate',
    'defaultDate',
    'selectedMarketType',
    'selectedLocation',
    'selectedLocationName',
    'selectedLocationId',
    'selectedLocationGroup',
    'selectedLocationGroupId',
    'selectedScheduleTypes',
    'selectedProducts',
    'locationGroupLocations',
    'hours',
    'multipleSelection',
    'tableHourData',
    'splitConfig',
    'transactionDialogVisible',
    'valueTypeFlag',
    'sibrValueTypeFlag',
    'bsapValueTypeFlag',
    'istValueTypeFlag',
    'showExistingFlag',
    'cbValueTypeFlag',
    'statusIndicator',
    'baseSchedules',
    'sibrSchedules',
    'istSchedules',
    'cbSchedules',
    'selectedSchedules',
    'hourStatus',
    'openMarkets',
    'schedulingCoordinators',
    'counterParties',
    'tableHourConfig',
    'tableConfig',
    'cbNodes',
    'selectedCbNode',
    'scheduleDetails',
    'detailPreviewData',
    'detailPreviewConfig',
    'errorMessageMap',
    'tradeDialogVisible',
    'isCurrentlyFetchingData',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
  state,
  getters,
  mutations,
  actions,
};