import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'commodityName', label: 'Commodity Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'activeFlag', label: 'Active?', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    shortName: '',
    commodityName: '',
    description: '',
    activeFlag: true,
    createdBy: '',
    createdDate: null,
    updatedBy: '',
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/markets').then(({ data }) => {
      commit('loadTableData', data.energyMarkets);
      this.$notify(`${data.energyMarkets.length} Market(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Markets', 'error');
    });
  },
  updateMarket({ commit }, market) {
    STRUCTURES_API.put(`/markets/${market.id}`, market).then(({ data }) => {
      commit('updateMarket', data);
      this.$notify('Market Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Market', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  updateMarket(state, market) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === market.id);
    state.tableData.splice(rowIndex, 1, market);
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, markets) {
    state.tableData = markets;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};