import { CISO_OASIS_API } from '@/api';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { createMutations } from '@/utils/vuexHelper';
import { clone } from '@/utils/dataUtil';
import moment from 'moment';
import config from './eimTransferTieConfigurations';

const state = {
  eimTransferTieModels: [],
  tableConfig: config.fields,
  isAverageHourlyValues: false,
  tableExportConfig: {},
};

const actions = {
  async fetchEIMTransferTie({ state, commit }, parameters) {
    commit('setEimTransferTieData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      marketType: parameters.marketTypesSelected,
      fromBaa: parameters.fromBaaSelected,
      toBaa: parameters.toBaaSelected,
      startDate,
      endDate,
      negativeCheck: parameters.negativeImportValuesSelected,
      averageHourlyValuesCheck: parameters.averageHourlyValuesSelected,
      addTotalsCheck: parameters.addTotalsSelected,
    };

    try {
      const headers = { Accept: 'application/json' };
      const { data } = await CISO_OASIS_API.get('/eim-transfer-tie', { params, headers});
      if (data) {
        commit('setEimTransferTieData', data);
        if (state.isAverageHourlyValues) commit('setAverageHourlyValues');

        const fileSuffix = `_${params.startDate.substring(0, 10)}_${params.endDate.substring(0, 10)}`;

        // save parameters for export
        const tableExportConfig = {
          requestAPI: CISO_OASIS_API,
          requestURL: '/eim-transfer-tie',
          requestParams: params,
          fileNameSuffix: fileSuffix,
        };
        commit('setTableExportConfig', tableExportConfig);

      } else {
        this.$notify('No trans tie report data found');
      }
    } catch (error) {
      this.$notify('Failed to Load EIM Transfer Ties', 'error');
    }
  },
  averageHourlyValuesAction({ commit }, isAverageHourlyValues) {
    commit('setIsAverageHourlyValues', isAverageHourlyValues);
    commit('setAverageHourlyValues');
  },
};

const mutations = {
  ...createMutations(
    'isAverageHourlyValues',
    'tableConfig',
  ),
  setEimTransferTieData(state, eimTransferTieModels) {
    eimTransferTieModels.forEach((x) => { x.tradinG_DATE = moment.utc(x.tradinG_DATE).format('MM/DD/YYYY'); });
    state.eimTransferTieModels = Object.freeze(eimTransferTieModels);
  },
  setAverageHourlyValues(state) {
    const avgValuesSet = clone(state.eimTransferTieModels);
    if (avgValuesSet.length > 0) {
      if (state.isAverageHourlyValues) {
        avgValuesSet.forEach((elem) => {
          elem.marketType === 'RTD' ? elem.value /= 12 : elem.value /= 4;
        });
      } else {
        avgValuesSet.forEach((elem) => {
          elem.marketType === 'RTD' ? elem.value *= 12 : elem.value *= 4;
        });
      }
      state.eimTransferTieModels = avgValuesSet;
    }
  },
  setTableExportConfig(state, value) {
    state.tableExportConfig = value;
  },
  reset({state, commit}) {
    state.eimTransferTieModels = [];
    state.isAverageHourlyValues = false;

    commit('setTableExportConfig', {});
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};