import {
  has, sortBy, formatValue, totals,
} from '@/utils/dataUtil';

export default {
  dockConfig: {
    alignment: 'LEFT',
    visible: false,
  },
  fields: [{
    caption: 'COMPANY',
    dataType: 'string',
    dataField: 'company',
    area: 'row',
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'LOCATION',
    dataType: 'string',
    dataField: 'location',
    area: 'row',
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'COUNTERPARTY',
    dataType: 'string',
    dataField: 'counterparty',
    area: 'row',
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'COMMODITY',
    dataType: 'string',
    dataField: 'commodity',
    area: 'row',
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'BUY/SELL',
    dataType: 'string',
    dataField: 'direction',
    area: 'row',
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'PARENT ID',
    dataType: 'number',
    dataField: 'parentObjectReference',
    area: 'filter',
    expanded: true,
    isMeasure: false,
    // summaryType: 'count',
  }, {
    caption: 'TRADE ID',
    dataType: 'number',
    dataField: 'objectReference',
    area: 'row',
    expanded: true,
    isMeasure: false,
    // summaryType: 'count',
  }, {
    caption: 'START DATE',
    dataType: 'date',
    dataField: 'effectiveStartTime',
    area: 'row',
    expanded: true,
    groupName: "dateGroup",
    isMeasure: false,
  }, {
    caption: 'YEAR',
    dataType: 'date',
    dataField: 'effectiveStartTime',
    area: 'row',
    groupName: "dateGroup",
    groupIndex: 0,
    groupInterval: "year",
    expanded: true,
    isMeasure: false,
  }, {
    caption: 'MONTH',
    dataType: 'date',
    dataField: 'effectiveStartTime',
    area: 'row',
    groupName: "dateGroup",
    groupIndex: 1,
    groupInterval: "month",
    expanded: false,
    isMeasure: false,
    // format: "MMM",
    // selector: function(data) {
    //     const year = new Date(data.effectiveStartTime).getFullYear();
    //     const month = new Date(data.effectiveStartTime).getMonth();
    //     return new Date(year, month);
    // } 
    // }, {
    // caption: 'END DATE',
    // dataType: 'string',
    // dataField: 'effectiveEndTime',
    // area: 'row',
    // expanded: true,
  }, {
    caption: 'DAY',
    dataType: 'date',
    dataField: 'effectiveStartTime',
    area: 'row',
    groupName: "dateGroup",
    groupIndex: 2,
    groupInterval: "day",
    expanded: false,
    isMeasure: false,
  }, {
    caption: 'VALUE DATE',
    dataType: 'string',
    dataField: 'valueDate',
    area: 'filter',
    expanded: true,
    isMeasure: false,
  // }, {
  //   caption: 'OBJECT TYPE',
  //   dataType: 'string',
  //   dataField: 'objectType',
  //   area: 'filter',
  //   expanded: true,
  //   isMeasure: false,
  }, {
    caption: 'TYPE',
    dataType: 'string',
    dataField: 'type',
    area: 'column',
    areaIndex: 0,
    expanded: true,
    isMeasure: false,
    allowSorting: false,
    sortingMethod: function(a, b) {
      const remap = [
        'VOLUME',          
        'PRICE',
        'AMOUNT',
        'NOTIONAL_VALUE',
        'MARKET_PRICE',
        'MARKET_VALUE',
        'PNL_VALUE',
      ];

      const indexA = remap.indexOf(a.value);
      const indexB = remap.indexOf(b.value);
      return indexA - indexB;
    },
  }, {
    caption: 'INDEX',
    dataType: 'string',
    dataField: 'indexName',
    area: 'filter',
    // areaIndex: 1,
    expanded: true,
    isMeasure: false,
  // }, {
  //   caption: 'SUB TYPE',
  //   dataType: 'string',
  //   dataField: 'subType',
  //   area: 'filter',
  //   expanded: false,
  //   isMeasure: false,
  }, {
    caption: 'VARIANT',
    dataType: 'string',
    dataField: 'variant',
    area: 'filter',
    expanded: false,
    isMeasure: false,
  }, {
    caption: 'TIME ZONE',
    dataType: 'string',
    dataField: 'timeZone',
    area: 'filter',
    expanded: false,
    isMeasure: false,
  }, {
    caption: 'VALUE',
    dataType: 'number',
    // dataField: 'value',
    // format: '#,##0.####',
    // summaryType: 'sum',
    area: 'data',
    expanded: true,
    isMeasure: true,
    summaryType: 'custom',

    calculateCustomSummary: function (options) {
      
      switch(options.summaryProcess) {
        case "start":
          options.totalValue = 0;
          break;

        case "calculate":
          
          if (!options.totalValue && options.value)
            options.totalValue = {
              value: 0,
              count: 0,
              column: options.value?.type,
              format: options.value?.type.endsWith('VOLUME') ? 'decimal' : 'currency',
              summary: options.value?.type.endsWith('PRICE') ? 'avg' : 'sum',
              fixedDecimal: 2,
            };

            const value = parseFloat(options.value.value) || 0;

            options.totalValue.value = totals[options.totalValue.summary](options.totalValue.value, { value });
            options.totalValue.count++;
            break;

        case "finalize":
          if (options.totalValue.summary === 'avg' && options.totalValue.count > 0)
            options.totalValue.value /= options.totalValue.count;

          options.totalValue = formatValue(
            options.totalValue.value,
            options.totalValue.format,
            options.totalValue.fixedDecimal,
            'red',
          );
          break;
        default:
          options.totalValue = 0; // options.totalValue.value;
          break;
      }
    }
}],
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};