import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'versionId', label: 'Ver#', sortable: true, filterable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true, filterable: true,
    }, {
      prop: 'commodityName', label: 'Commodity', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime', filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      columnConfig: true,
      showSelectFilter: false,
    },
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    shortName: null,
    description: null,
    commodityName: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getCommodities: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('loadTableData');
  },
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ dispatch, commit, state }) {
    STRUCTURES_API.get('/contract-types').then(({ data }) => {
      commit('loadTableData', data.contractTypes);
      this.$notify(`${data.contractTypes.length} Contract Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Contract Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  insertTableRow({ dispatch, commit, state }, contractType) {
    commit('insertTableRow', contractType);
  },
  postContractType({ dispatch, commit, state }, contractType) {
    STRUCTURES_API.post('/contract-types', contractType).then(({ data }) => {
      state.tableData.push(data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Contract Type', 'error');
    });
  },
  updateContractType({ dispatch, commit, state }, contractType) {
    STRUCTURES_API.put(`/contract-types/${contractType.id}`, contractType).then(({ data }) => {
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract Type Updated', 'success');
      commit('updateContractType', data);
    }).catch((err) => {
      this.$notify('Failed to Update Contract Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/contract-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract Type Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Contract Type', 'error');
    });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateContractType(state, contractType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === contractType.id);
    state.tableData.splice(rowIndex, 1, contractType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, contractTypes) {
    state.tableData = contractTypes;
  },
  createTableRow(state) {
    const contractType = clone(state.nullTableRow);
    contractType.id = state.tableKey++;
    contractType.versionId = 1;
    contractType.createdBy = this.getters['auth/getProfile'].userName;
    contractType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = contractType;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((contractType) => contractType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};