import dateStore from '@/utils/dateStore';
import userStore from '@/utils/userStore';
import { createMutations } from '@/utils/vuexHelper';
import utils from '@/utils';
import helpers from '@/utils/helpers';
import { hasPermission, anyPermission } from '@/utils/authUtils';
import { TOOLS_API, BILLING_REF_API } from '@/api';
import caisoStore from '@/utils/caiso/caisoUtils';
import { fetchUserResource, fetchUserCollection } from '@/utils/structuresHelper';
import { sortBy } from '@/utils/dataUtil';

const state = {
  singleDate: dateStore.getSingleDateOption(),
  singleDateOptions: helpers.map(dateStore.getSingleDateOptions().shortcuts, (x) => ({ value: x.text, label: x.text })),
  multiDate: dateStore.getRangeDateOption(),
  multipleDateOptions: helpers.map(dateStore.getRangeDateOptions().shortcuts, (x) => ({ value: x.text, label: x.text })),

  marketTypeEnabled: false,
  timeZone: dateStore.getTimeZone(),
  timeZoneOptions: utils.date.TZ_DEFINE,
  longDayHourOptions: [{ value: true, label: '2*' }, { value: false, label: '25' }],
  longDayHourSelection: dateStore.getLongDayHour(),
  resourceGroups: [],
  defaultsConfigured: false,
  defaultRefreshTime: userStore.getDefaultRefreshTime(),
  dateRange: null,
  dateStyle: (dateStore.getStyle() === 'relative'), // default to relative selection
  absoluteDate: dateStore.getDefaultRange(),
  coordinatorStyleOptions: (caisoStore.getStyleOptions()),
  coordinatorStyle: (caisoStore.getStyle() === 'SC'),
  coordinatorOptions: [],
  coordinatorTypeEnabled: false,
  expandPendingRequests: userStore.getExpandPendingRequests(),
  collapseNavigationMenusOnClose: userStore.getCollapseNavigationMenusOnClose(),
  caisoToolsPermission: false,
  caisoSchedulingPermission: false,
  landingPage: userStore.getLandingPage(),
  screens: [],
  screen: null,
  routesTree: [],
  filters: userStore.getDefaultFilters(),
  coordinatorSelection: caisoStore.getCoordinator(),
  resourceGroupSelection: caisoStore.getResourceGroup(),
  resourceGroupNameSelection: caisoStore.getResourceGroupName(),
  marketTypeSelection: caisoStore.getMarketType(),
  marketTypeOptions: caisoStore.getMarketTypeOptions(),
  dock: userStore.getDock(),

  cbmPermission: false,
  defaultContract: userStore.getDefaultContract(),
  contractOptions: [],
};

const getters = {
  getTimeZoneDSTFlag: (state) => utils.date.getZoneName(state.timeZone).dstFlag,
  getTimeZoneDescription(state) {
    const zone = utils.date.getZoneName(state.timeZone);
    if (zone.dstFlag) {
      return `${zone.label} (${zone.description}) observes DST and will change between offsets ${zone.utcOffset} and ${zone.utcDSTOffset}`;
    }
    return `${zone.label} (${zone.description}) does not observe DST and will always be at offset ${zone.utcOffset}`;
  },
  getSingleDatePrintExample: (state) => utils.date.singleDateFromOption(state.singleDate, state.timeZone).format(),
  getMultipleDatePrintExample(state) {
    const dates = utils.date.multipleDateFromOption(state.multiDate, state.timeZone);
    return `[${dateStore.toDateFromLocal(dates[0], state.timeZone).format()}, ${dateStore.toDateFromLocal(dates[1], state.timeZone).format()}]`;
  },
};

const actions = {
  changeScreen({ commit, dispatch }, value) {
    commit('setScreen', value);
    dispatch('loadFilters', value);
  },
  createRoutesTree({ commit, rootGetters }) {
    // Creates a tree to be displayed
    const routesTree = rootGetters['auth/getRoutes'].map((route) => ({
      text: route.text,
      name: route.name,
      isParent: route.children.length > 0,
      customFormatEnabled: route.customFormatEnabled ?? false,
      expanded: false,
      items: route.children.reduce((acc, {
        text, children, name, customFormatEnabled,
      }) => {
        acc.push({
          isParent: children.length > 0,
          name,
          text,
          customFormatEnabled,
          expanded: false,
          items: children ? children.map((c) => ({ text: c.text, name: c.name, customFormatEnabled: c.customFormatEnabled })) : [],
        });
        return acc;
      }, []),
    }));
    routesTree.unshift({ text: 'User Settings', name: 'Settings', customFormatEnabled: true });

    commit('setRoutesTree', routesTree);
  },
  async loadScreens({ commit, dispatch }) {
    const collections = await fetchUserCollection('tables');
    if (collections) {
      const screens = await Promise.all(collections.map(async ({ name }) => {
        const children = await dispatch('loadFilters', name);
        return {
          name,
          children,
        };
      }));
      commit('setScreens', screens);
    }
  },
  async loadFilters({ commit }, screen) {
    const resources = await fetchUserResource('tables', screen);
    if (resources && resources.filters) {
      const filters = resources.filters.map(({ name }) => name);
      return filters || [];
    }
    return [];
  },
  async checkPermissions({
    commit, state, dispatch, rootGetters,
  }) {
    await dispatch('auth/login', null, { root: true });
    const profile = rootGetters['auth/getProfile'];
    // only show market type/coordinators if we have permission to use caiso_enabled
    commit('setCaisoToolsPermission', anyPermission(profile.permissions, /caiso:tools:*/));
    commit('setCaisoSchedulingPermission', anyPermission(profile.permissions, /caiso:scheduling:*/));
    commit('setCbmPermission', anyPermission(profile.permissions, /contract_billing:reference_data::*/));
    if (state.caisoToolsPermission) {
      try {
        const coordinators = await dispatch('fetchSchedulingCoordinators');
        commit('setCoordinatorSelections', coordinators);
        const data = await utils.user.getDefaultOptions();
        if (data && data.caisoOptions) {
          commit('setCoordinatorSelection', data.caisoOptions.coordinator);
        } else {
          commit('setCoordinatorSelection', null);
        }
      } catch (ex) {
        console.error(ex);
        // this.$notify('Failed to Load Scheduling Coordinators', 'error');
      }
    }
    if (state.cbmPermission) {
      const contracts = await dispatch('fetchContracts');
      state.contractOptions = contracts;
    }
  },
  async fetchSchedulingCoordinators({ commit, state }) {
    try {
      const { data: { schedulingCoordinators } } = await TOOLS_API.get('/scheduling-coordinators/resources');
      return schedulingCoordinators;
    } catch (error) {
      console.error(error);
      // this.$notify('Failed to Load Scheduling Coordinators', 'error');
    }
    return [];
  },
  async fetchContracts({ commit, state }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contracts');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.name;
      });
      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Contracts', 'error');
      console.log(error);
    }
    return [];
  },
  async saveDefaultSelections({ dispatch, commit }) {
    const { data } = await utils.user.getDefaultOptions();
    const model = {
      ...data,
      dateOptions: {
        style: state.dateStyle ? 'relative' : 'absolute',
        single: state.singleDate,
        range: state.multiDate,
        absoluteDate: state.absoluteDate,
        tz: state.timeZone,
        ldh: state.longDayHourSelection,
      },
      caisoOptions: {
        style: state.coordinatorStyle ? 'SC' : 'BA ID',
        coordinator: state.coordinatorSelection,
        marketType: state.marketTypeSelection,
        resourceGroup: state.resourceGroupSelection,
        resourceGroupName: !state.resourceGroupSelection ? 'ALL' : state.resourceGroupNameSelection,
      },
      userOptions: {
        expandPendingRequests: state.expandPendingRequests,
        collapseNavigationMenusOnClose: state.collapseNavigationMenusOnClose,
        defaultFilters: state.filters,
        defaultRefreshTime: state.defaultRefreshTime,
        landingPage: state.landingPage,
        dock: state.dock,
        defaultContract: [state.defaultContract],
      },
    };

    dateStore.refreshDateListeners(model.dateOptions);

    try {
      await utils.user.updateDefaultOptions(model);
      this.$notify('Defaults saved', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to save defaults', 'error');
    }
  },
  forceRefreshDateOptions({ state, dispatch, commit }) {
    const dateOptions = {
      style: state.dateStyle ? 'relative' : 'absolute',
      tz: state.timeZone,
      single: state.singleDate,
      range: state.multiDate,
      absoluteDate: state.absoluteDate,
      ldh: state.longDayHourSelection,
    };
    dateStore.refreshDateListeners(dateOptions);

    // const coordinatorOptions = {
    //   style: state.coordinatorStyle ? 'SC' : 'BA ID',
    //   coordinator: state.coordinatorSelection,
    //   coordinatorList: state.coordinatorOptions,
    //   marketType: state.marketTypeSelection,
    // };
    // caisoStore.refreshDateListeners(coordinatorOptions);

    this.$notify('Default Settings Updated on Active Tabs', 'success');
  },
  coordinatorStyleChanged({ commit }, value) {
    commit('setCoordinatorStyle', value);
    commit('setCoordinatorSelections', state.coordinatorOptions);
  },
};

const mutations = {
  setDefaultSelections(state, item) {
    state.defaultsConfigured = true;
    state.singleDate = item.dateOptions.single;
    state.multiDate = item.dateOptions.range;
    state.timeZone = item.dateOptions.tz;
    state.dock = 'Undocked';
    state.defaultContract = '';
  },
  setCoordinatorSelections(state, options) {
    const coordinator = options.find(({ shortName, baId }) => shortName === state.coordinatorSelection || baId === state.coordinatorSelection);
    state.coordinatorSelection = coordinator ? coordinator.shortName : null;
    if (state.coordinatorStyle) {
      state.coordinatorOptions = options.map(({ shortName, baId }) => ({
        value: shortName, label: shortName, shortName, baId,
      }));
    } else {
      state.coordinatorOptions = options.map(({ shortName, baId }) => ({
        value: shortName, label: baId, shortName, baId,
      }));
    }
  },
  ...createMutations(
    'absoluteDate',
    'caisoToolsPermission',
    'caisoSchedulingPermission',
    'coordinatorSelection',
    'coordinatorStyle',
    'dateStyle',
    'expandPendingRequests',
    'collapseNavigationMenusOnClose',
    'defaultFilters',
    'landingPage',
    'longDayHourSelection',
    'marketTypeSelection',
    'multiDate',
    'resourceGroupSelection',
    'resourceGroupNameSelection',
    'singleDate',
    'timeZone',
    'screen',
    'screens',
    'routesTree',
    'filters',
    'defaultRefreshTime',
    'dock',
    'defaultContract',
    'contractOptions',
    'cbmPermission',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
