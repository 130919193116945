import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { TOOLS_API } from '@/api';

const state = {
  settlementsTableData: [],
  operationsTableData: [],
  eimSubAllocsTableData: [],
  currentRow: null,
};

const actions = {
  async fetchIssues({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      SCs: parameters.coordinatorListSelected,
      startDate,
      endDate,
      chargeCode: parameters.chargeCode === undefined ? null : parameters.chargeCode,
    };
    try {
      const { data: { data } } = await TOOLS_API.get('/issue-tracking/fetch-all', { params });

      const settlementsData = data.filter((x) => x.settlementsFlag);
      const operationsData = data.filter((x) => x.visualAnalyticsFlag && !x.settlementsFlag);
      const eimSubAllocationData = data.filter((x) => x.eimSubAllocFlag);

      commit('setSettlementsTableData', settlementsData);
      commit('setOperationsTableData', operationsData);
      commit('setEimSubAllocsTableData', eimSubAllocationData);
    } catch {
      this.$notify('Failed to Load Issue Tracking Data', 'error');
    }
  },
  deleteIssue({ commit }, parameters = {}) {
    const params = {
      trackingID: parameters.trackingID,
    };
    try {
      const result = TOOLS_API.delete('/issue-tracking/delete', { params });
      this.$notify('Successfully deleted Issue', 'success');
    } catch {
      this.$notify('Failed to delete Issue', 'error');
    }
  },
};

const mutations = {
  setSettlementsTableData(state, value) {
    state.settlementsTableData = value;
  },
  setOperationsTableData(state, value) {
    state.operationsTableData = value;
  },
  setEimSubAllocsTableData(state, value) {
    state.eimSubAllocsTableData = value;
  },
  setCurrentRow(state, value) {
    state.currentRow = value;
  },
  setHasAttachments(state, { value, table }) {
    if (Array.isArray(value)) {
      const rowIndex = state[`${table}TableData`]?.findIndex(({ trackingID }) => trackingID === value[0]?.referenceId
          || trackingID === state.currentRow?.trackingID);
      if (rowIndex !== -1) state[`${table}TableData`][rowIndex].hasAttachments = value.length > 0;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};