import { STRUCTURES_API } from '@/api';
import utils from '@/utils';
import { clone, handleError } from '@/utils/dataUtil';

const state = {
  tableKey: -999,
  currentRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'name', label: 'Name', sortable: true,
    }, {
      prop: 'feeAmount', label: 'Fee Amount', sortable: true,
    }, {
      prop: 'feePercentage', label: 'Fee Percentage', sortable: true,
    }, {
      prop: 'commissionAmount', label: 'Commission Amount', sortable: true,
    }, {
      prop: 'commissionPercentage', label: 'Commission Percentage', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    name: null,
    feeAmount: null,
    feePercentage: null,
    commissionAmount: null,
    commissionPercentage: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getCurrentRow: (state) => state.currentRow,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/brokers').then(({ data }) => {
      commit('loadTableData', data);
      this.$notify(`${data.length} Brokers Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Brokers', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postBroker({ state }, broker) {
    STRUCTURES_API.post('/brokers', broker).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Broker Added', 'success');
    })
      .catch(handleError('Failed to Add Broker'));
  },
  updateBroker({ commit }, updateBroker) {
    STRUCTURES_API.put('/brokers', updateBroker).then(({ data }) => {
      commit('updateBroker', data);
      this.$notify('Broker Updated', 'success');
    })
      .catch(handleError('Failed to Update Broker'));
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/brokers/${state.currentRow.id}`).then(() => {
      commit('deleteTableRow');
      dispatch('currentRowChange', clone(state.nullTableRow));
      this.$notify('Broker Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Broker', 'error');
    });
  },
  currentRowChange({ commit }, currentRow) {
    commit('currentRowChange', currentRow);
  },
};

const mutations = {
  updateBroker(state, broker) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === broker.id);
    state.tableData.splice(rowIndex, 1, broker);
  },
  currentRowChange(state, currentRow) {
    state.currentRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentRow = clone(state.nullTableRow);
  },
  loadTableData(state, brokers) {
    state.tableData = brokers;
  },
  createTableRow(state) {
    const broker = clone(state.nullTableRow);
    broker.id = state.tableKey++;
    broker.versionId = 1;
    broker.createdBy = this.getters['auth/getProfile'].userName;
    broker.createdDate = utils.date.getNow().utc().format();
    state.currentRow = broker;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((broker) => broker.id !== state.currentRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};