import {
  gridConfig,
} from '@/components/ContractBilling/BaseStore/contractsBase';

export default {
  columns: [{
    label: 'CONTRACT',
    prop: 'contractName',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'CALC VERSION',
    prop: 'versionName',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'TRADING DATE',
    prop: 'tradingDate',
    dataType: 'date',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'CHARGE CODE',
    prop: 'chargeCodeName',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'DESCRIPTION',
    prop: 'description',
    filterable: true,
    sortable: true,
    visible: true,
  }, {
    label: 'CURRENT AMOUNT',
    prop: 'currentAmount',
    filterable: true,
    sortable: true,
    visible: true,
    alignment: 'right',
    format: gridConfig.zeroCurrencyFormat,
  }, {
    label: 'PREVIOUS AMOUNT',
    prop: 'previousAmount',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'right',
    format: gridConfig.zeroCurrencyFormat,
  }, {
    label: 'NET AMOUNT',
    prop: 'netAmount',
    filterable: true,
    sortable: true,
    visible: true,
    alignment: 'right',
    format: gridConfig.zeroCurrencyFormat,
  }, {
    label: 'COMPARE CALC VERSION',
    prop: 'compareVersion',
    filterable: true,
    sortable: true,
    visible: false,
  }, {
    label: 'COMPARE AMOUNT',
    prop: 'compareCurrentAmount',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'right',
    format: gridConfig.zeroCurrencyFormat,
  }, {
    label: 'VARIANCE AMOUNT',
    prop: 'varianceAmount',
    filterable: true,
    sortable: true,
    visible: false,
    alignment: 'right',
    format: gridConfig.zeroCurrencyFormat,
  }, {
    label: 'VOLUME',
    prop: 'volume',
    filterable: true,
    sortable: true,
    visible: true,
    alignment: 'right',
    format: gridConfig.valFormat,
  }],
  summary: {
    currentAmountSummary: {
      prop: 'currentAmount',
      summaryType: 'sum',
      format: gridConfig.zeroCurrencyFormat,
    },
    previousAmountSummary: {
      prop: 'previousAmount',
      summaryType: 'sum',
      format: gridConfig.zeroCurrencyFormat,
    },
    netAmountSummary: {
      prop: 'netAmount',
      summaryType: 'sum',
      format: gridConfig.zeroCurrencyFormat,
    },
    compareAmountSummary: {
      prop: 'compareCurrentAmount',
      summaryType: 'sum',
      format: gridConfig.zeroCurrencyFormat,
    },
    varianceSummary: {
      prop: 'varianceAmount',
      summaryType: 'sum',
      format: gridConfig.zeroCurrencyFormat,
    },
    volumeSummary: {
      prop: 'volume',
      summaryType: 'sum',
      format: gridConfig.valFormat,
    },
  },
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};