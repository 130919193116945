import NotificationItem from '../notificationItem';
import * as notifUtils from '../notificationUtil';

export default class DefaultMapper {
  constructor(name) {
    this.name = name;
  }

  /**
   *
   * @param {Object} selectedConfigurationFilters
   * @param {NotificationItem} item
   * @returns {boolean}
   */
  ignoreMessage(selectedConfigurationFilters, notificationItem) {
    return false;
  }

  /**
   *
   * @param {string} source
   * @return {boolean}
   */
  isSourceMapper(source) {
    return true;
  }

  /**
   *
   * @param {Object} item
   * @return {NotificationItem} Returns NotificationItem
   */
  createNotificationItem(item) {
    if (!item.message)
      item.message = item.text;

    if (!item.message)
      return null;  
      
    const displayMessage = notifUtils.createNotificationMessage(item);

    const uid = item.correlation_id
      ? `${item.name}_${item.correlation_id}`
      : `${item.name}_${item.type}`;

    return new NotificationItem(
      item.version ?? 'v1',
      item.type,
      displayMessage.message,
      item.state,
      item.source,
      item.severity,
      uid,
      'None',
      item,
      item.error,
      null,
    );
  }
}