import moment from 'moment';
import { CISO_BID_SCHD_API } from '@/api';
import { hasPermission } from '@/utils/authUtils';
import {
  clone, has, cloneDeep, HEColumns, removeObjProps,
} from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import {
  systemStatusColors, scheduleVariantColors, verifySubmitActionBase,
} from '@/components/Scheduling/utils';
import { createMutations } from '@/utils/vuexHelper';
import LOOKUP_STORE from '@/store/lookupStore';
import qs from 'qs';
import { saveAs } from 'file-saver';
import REFERENCE_DATA_STORE from '../../../referenceDataStore';

const SUBMIT = 0;
const CANCEL = 1;
const CREATE_PROPOSED = 2;
const COPY_PROPOSED = 3;
const CREATE_TRANSACTION = 4;
const RETRIEVE = 5;

const PENDING_VALIDATION = 'PENDING_VALIDATION';
const FAILED_VALIDATION = 'FAILED_VALIDATION';
const PROPOSED = 'PROPOSED';

// For DST
const generateConfig = (date) => ({
  columns: [{
    prop: 'sc', label: 'SC', fixed: 'left', sortable: true, filterable: true,
  }, {
    prop: 'resource',
    label: 'Resource ID',
    cellTemplate: 'PscsRouteCellTemplate',
    fixed: 'left',
    sortable: true,
    filterable: true,
  }, {
    prop: 'resourceType', label: 'Type', fixed: 'left', sortable: true, filterable: true,
  }, {
    alignment: 'center',
    cellTemplate: 'PscsColoredCellTemplate',
    label: 'Status',
    prop: 'scheduleStatus',
    editorOptions: scheduleVariantColors,
    class: true,
    filterable: true,
    sortable: true,
  },
  ...HEColumns({
    cellTemplate: 'PscsStatusCellContent',
    editorOptions: {
      systemStatusColors,
      energyField: 'SelfScheduleTotal',
    },
  },
  true,
  date,
  ), {
    prop: 'total', label: 'total', fixed: 'right', sortable: true,
  }],
  summary: [
    ...HEColumns({ alignment: 'left', summaryType: 'custom', label: '{0}' }, false, date),
    {
      prop: 'total', alignment: 'center', summaryType: 'sum', fixed: 'right', label: '{0}',
    },
  ],
  // eslint-disable-next-line
  customSummary: sumData,
  options: {
    filterHeader: true,
    exportExcel: false,
    multipleSelection: true,
    cellSelection: true,
    warningHighlight: true,
    highlightRow: true,
    isBidHighlight: true,
  },
  isRowSelected: (row) => {
    if ((row?.scheduleName || has(row, 'rowSelected')) && (!row.isConfig && row.variant === 'ACTUAL')) {
      return true;
    }
    return false;
  },
});

const generateTableHourConfig = (date) => ({
  columns: [{
    prop: 'row', label: ' ', fixed: 'left', width: 70, cssClass: 'header-column',
  },
  ...HEColumns({}, true, date),
  ],
});

const state = {
  date: dateStore.getDefaultDate().toISOString(),
  selectedMarketType: caisoStore.getMarketType(),
  statuses: [],
  multipleSelection: [],
  pendingStatuses: [],
  startHour: 1,
  endHour: 24,
  valueTypeFlag: false,
  hours: [],
  submitOptions: [{
    id: CANCEL, name: 'Cancel', icon: 'fa fa-stop-circle-o',
  },
  // {
  //   id: CREATE_PROPOSED, name: 'Create Proposed', icon: 'fa fa-file-text-o',
  // }, {
  //   id: COPY_PROPOSED, name: 'Copy All Proposed', icon: 'fa fa-files-o',
  // }, {
  {
    id: CREATE_TRANSACTION, name: 'Create Transaction', icon: 'fa fa-plus-square-o',
  }],
  showOpenHours: false,

  splitConfig: false,
  storedParams: null,
  overrideSearch: true,
  tableData: [],
  tableConfig: generateConfig(),
  tableHourData: [],
  tableHourConfig: generateTableHourConfig(),
  transactionDialogVisible: false,
};

function sumData(opt) {
  const type = state.valueTypeFlag ? 'marketSelfScheduleTotal' : 'systemSelfScheduleTotal';
  let val = 0;
  if (opt.value?.[type]) val = opt.value[type];
  if (opt.value?.resourceType === 'LOAD') val *= -1;

  if (opt.summaryProcess === 'start') opt.totalValue = 0;
  else if (opt.summaryProcess === 'calculate') opt.totalValue += val;
  else if (opt.summaryProcess === 'finalize') opt.totalValue = opt.totalValue.toFixed(2);
}

function highlightHours(data) {
  const types = [
    { type: 'systemSelfScheduleTotal', typeActual: 'marketSelfScheduleTotal' },
    { type: 'systemBidSegmentCount', typeActual: 'marketBidSegmentCount' },
    { type: 'systemBidSegmentString', typeActual: 'marketBidSegmentString' },
  ];

  if (Array.isArray(data)) {
    const columns = HEColumns({}, true, state.date);
    data.forEach((summary) => {
      summary.highlightRow = true;
      summary.highlight = [];
      columns.forEach(({ prop }) => {
        const val = summary[prop];
        types.forEach((type) => {
          if (val && has(val, [type.type, type.typeActual]) && val[type.type] !== val[type.typeActual]) {
            summary.highlight.push(prop);
          }
        });
      });
    });
  }

  return data;
}

function filterTableData(data, valueTypeFlag, splitConfigFlag) {
  if (!data) return [];
  const type = valueTypeFlag ? 'marketSelfScheduleTotal' : 'systemSelfScheduleTotal';
  data.forEach((status) => {
    status.valueTypeFlag = valueTypeFlag;
    let total = 0;
    Object.keys(status).forEach((key) => {
      if (key.includes('he')) {
        let factor = 1;
        if (status[key] && status[key].resourceType === 'LOAD') factor = -1;
        if (status[key] && status[key][type]) total += status[key][type] * factor;
      }
    });
    status.total = total.toFixed(2);
  });

  data = splitConfigFlag
    ? data.filter(({ isConfig, isResource }) => isConfig || !isResource)
    : data.filter(({ isConfig, isResource }) => !isConfig || isResource);

  return data;
}

const getters = {
  hasWritePermissionFlag: (_state, _getters, _rootState, rootGetters) =>
    // eslint-disable-next-line
    hasPermission(rootGetters['auth/getPermissions'], 'caiso:scheduling:bid', 'write'),
  getTableData: (state) => filterTableData(state.tableData, state.valueTypeFlag, state.splitConfig),
  getFeatureFlags: () => LOOKUP_STORE.state.userFeatureFlagList,
};

const actions = {
  async initialize({ dispatch, commit }) {
    // eslint-disable-next-line
    const payload = { referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'], market: 'CAISO', commodity: 'POWER' };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    dispatch('loadHours');
    dispatch('LOOKUP_STORE/fetchUserFeatureFlagList');
    commit('setShowOpenHours', state.showOpenHours);
  },
  loadHours({ commit, state }) {
    const hours = dateStore.getTimeRange(state.date, 60).map((v) => ({ value: v, label: v.he }));

    commit('setStartHour', hours[0].value);
    commit('setEndHour', hours[hours.length - 1].value);

    const idx = moment().add(135, 'minutes').hours();

    if (state.selectedMarketType === 'RTM' && moment(state.date).isSame(moment(), 'day')) {
      commit('setStartHour', hours[idx].value);
    }
    commit('setHours', hours);
  },
  async loadSummaries({ commit, state, dispatch }, params) {
    const props = ['highlight', 'highlightRow', 'productType', 'total', 'rowType', 'valueTypeFlag',
      'isResource', 'isConfig', 'he25', ...HEColumns().map((x) => x.prop)];

    try {
      const { data } = await CISO_BID_SCHD_API.get('summaries', {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      const clonedData = JSON.stringify(data.summaries.map((row) => removeObjProps(cloneDeep(row), ...props)));
      const clonedTableData = JSON.stringify(state.statuses.map((row) => removeObjProps(cloneDeep(row), ...props)));
      if (clonedData !== clonedTableData) {
        commit('setStatuses', data.summaries);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('loadHours');
    }
  },
  async copyForward({ state }, item) {
    if (state.multipleSelection.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    }
    const scheduleNames = state.multipleSelection.filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, 'PPT');
    const [startTime, endTime] = momentRange;

    if (scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: 'No Valid Schedules Selected' });
      return;
    }

    const body = {
      startTime: startTime.toISOString(),
      endTime: endTime.add('days', 1).toISOString(),
      marketType: item.copyMarketType,
      variant: 'ACTUAL',
      actionType: 'Copy',
      scheduleNames,
    };

    try {
      await CISO_BID_SCHD_API.put('copy', body);
      this.$notify({ type: 'success', message: 'Copy Forward Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Copy Forward Failed' });
      console.error(error);
    }
  },
  async verifySubmitAction({ state, dispatch }, item) {
    verifySubmitActionBase({ state, dispatch }, item, true);
  },
  retrieve({ dispatch }) {
    dispatch('submitAction', { id: 5 });
  },
  submitAction({ state, commit, dispatch }, item) {
    if (item.id === CREATE_TRANSACTION) {
      commit('setTransactionDialogVisible', true);
      return;
    } if (state.multipleSelection.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    } if (state.multipleSelection.find((m) => m.variant.toLowerCase() === PROPOSED)) {
      this.$notify({ type: 'warning', message: 'Cannot submit proposed schedules' });
      return;
    }

    const columns = HEColumns({}, true, state.date);
    state.multipleSelection.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const scheduleNames = state.multipleSelection
      .filter((x) => x.variant === 'ACTUAL')
      .map((x) => x.scheduleName);

    if (!scheduleNames || scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: 'No selected schedules can be submitted.' });
      return;
    }
    const startTimeParam = state.startHour.timeTZ.clone();
    const endTimeParam = state.endHour.timeTZ.clone();
    const params = {
      startTime: startTimeParam.toISOString(),
      endTime: endTimeParam.add(1, 'hour').toISOString(),
      marketType: state.selectedMarketType,
      variant: 'ACTUAL',
      scheduleNames,
    };
    if (item.id === CANCEL) dispatch('cancelSchedules', params);
    else if (item.id === RETRIEVE) dispatch('retrieveSchedules', params);
    else dispatch('submitSchedules', params);
  },
  async submitSchedules({ state, commit }, params) {
    try {
      await CISO_BID_SCHD_API.patch('', { ...params });
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async cancelSchedules({ state, commit }, params) {
    try {
      const payload = { url: '', method: 'delete', data: { ...params } };
      await CISO_BID_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Cancel Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Cancel Failed' });
      console.error(error);
    }
  },
  async retrieveSchedules({ state, commit }, params) {
    try {
      const payload = { url: 'sibr', method: 'put', data: { ...params } };
      await CISO_BID_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
      console.error(error);
    }
  },
  async importSchedule({ state, commit }, { fileName, tradingDate }) {
    try {
      await CISO_BID_SCHD_API.post('import', {
        configurationName: 'schedules-upload-configuration',
        fileName,
        tradingDate,
      });
      this.$notify({ type: 'success', message: 'Schedule Import Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Schedule Import Failed' });
      console.error(error);
    }
  },
  async exportToExcel({ state, commit }, data) {
    const ExcelJS = await import('exceljs');
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Power Settlements';
    workbook.lastModifiedBy = 'Power Settlements';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    // Creates data sheet
    const dataSheet = workbook.addWorksheet('Data', {
      views: [{ state: 'frozen', ySplit: 1, xSplit: 2 }],
    });
    const fullHeColumns = HEColumns({
      cellTemplate: 'PscsStatusCellContent',
      editorOptions: {
        systemStatusColors,
        energyField: 'SelfScheduleTotal',
      },
    }, true, data.params.start);

    const columns = [{
      header: 'SC', key: 'sc', width: 6,
    }, {
      header: 'Resource ID', key: 'resource', width: 20,
    }, {
      header: 'Type', key: 'resourceType', width: 6,
    }, {
      header: 'Status', key: 'scheduleStatus', width: 12,
    },
    ...fullHeColumns.map((column) => ({ header: column.label, key: column.prop, width: 6 })),
    {
      header: 'Total:', key: 'total', width: 12,
    },
    ];

    dataSheet.columns = columns;
    const header = dataSheet.getRow(1);
    header.font = { bold: true, size: 10, name: 'Arial' };
    header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };

    const { tableData } = data;
    const unlinkedData = tableData.map((e) => ({ ...e }));
    const columnTotals = { total: 0 };

    unlinkedData.forEach((resource) => {
      resource.total = parseInt(resource.total, 10);
      const cellStates = [];

      for (let i = 1; i <= 25; i++) {
        const key = `he${String(i).padStart(2, '0')}`;
        if (resource[key]) {
          const checked = ['V', 'M', 'CV', 'CM', 'MI'].includes(resource[key].marketStatus);
          const failed = ['I', 'RJ'].includes(resource[key].marketStatus);
          if (checked || failed) {
            cellStates.push({ index: i, failed, checked });
          }
          resource[key] = resource[key].systemSelfScheduleTotal;
          columnTotals.total += resource[key];
          if (columnTotals[key]) {
            columnTotals[key] += resource[key];
          } else {
            columnTotals[key] = resource[key];
          }
        }
      }
      dataSheet.addRow(resource);
      const row = dataSheet.getRow(dataSheet.rowCount);
      cellStates.forEach((currCell) => {
        const cell = row._cells[currCell.index + 3];
        if (currCell.checked) {
          cell.border = {
            top: { style: 'thin', color: { argb: '0000FF00' } },
            left: { style: 'thin', color: { argb: '0000FF00' } },
            bottom: { style: 'thin', color: { argb: '0000FF00' } },
            right: { style: 'thin', color: { argb: '0000FF00' } },
          };
        } else if (currCell.failed) {
          cell.border = {
            top: { style: 'thin', color: { argb: '00FF0000' } },
            left: { style: 'thin', color: { argb: '00FF0000' } },
            bottom: { style: 'thin', color: { argb: '00FF0000' } },
            right: { style: 'thin', color: { argb: '00FF0000' } },
          };
        }
      });
    });
    dataSheet.addRow(columnTotals);
    const fileName = `Bid_Schedule_${moment(data.params.start).format('YYYY-MM-DD')}`;

    workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${fileName}.xlsx`);
      });
  },
};

const mutations = {
  setStartHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour > state.endHour) state.startHour = state.endHour;
    else state.startHour = value;
  },
  setEndHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour < state.startHour) state.endHour = state.startHour;
    else state.endHour = value;
  },
  setTableHourData(state, item) {
    const data = ['System', 'Market', 'Status', 'Message', 'Updated By', 'Updated', 'Submitted']
      .map((row) => ({ row }));

    const type = 'systemSelfScheduleTotal';
    const typeActual = 'marketSelfScheduleTotal';

    HEColumns({}, true, state.date).forEach(({ prop }) => {
      const obj = item[prop];
      if (obj) {
        data[0][prop] = obj?.[type];
        data[1][prop] = obj?.[typeActual];
        data[2][prop] = obj.marketStatus;
        data[3][prop] = obj.errorMessage;
        data[4][prop] = obj.updatedBy;
        data[5][prop] = moment(obj.updatedDate).format('MM/DD/YY HH:mm:ss');
        data[6][prop] = obj.submittedDate ? moment(obj.submittedDate).format('MM/DD/YY HH:mm:ss') : obj.submittedDate;
      }
    });
    state.tableHourData = data;
  },
  setStatuses(state, summaries) {
    // resets config
    state.tableConfig = generateConfig(state.date);
    state.tableHourConfig = generateTableHourConfig(state.date);

    state.tableHourData = [];
    state.multipleSelection = [];
    summaries = summaries.reduce((acc, sum) => {
      sum.isResource = !!sum.configurations.length;
      sum.isConfig = false;
      acc.push(cloneDeep(sum));
      sum.configurations.forEach((config) => {
        const newSum = cloneDeep(sum);
        newSum.resource = config;
        newSum.configuration = config;
        newSum.statuses = sum.statuses.filter(({ configuration }) => configuration === config);
        newSum.configurations = [];
        newSum.isConfig = true;
        newSum.isResource = false;
        acc.push(newSum);
      });
      return acc;
    }, []);

    const flattened = summaries.map((summary) => {
      const flatStatuses = summary.statuses.reduce((acc, status) => {
        const key = `he${(`0${status.hour}`).slice(-2)}`;
        if (!acc[key]) {
          acc[key] = { ...status, resourceType: summary.resourceType, configuration: null };
        } else {
          acc[key].marketSelfScheduleTotal += status.marketSelfScheduleTotal;
          acc[key].systemSelfScheduleTotal += status.systemSelfScheduleTotal;
          acc[key].systemBidSegmentCount += status.systemBidSegmentCount;

          if (!acc[key].errorMessage && status.errorMessage) {
            acc[key].errorMessage = status.errorMessage;
          } else if (acc[key].errorMessage && status.errorMessage) {
            acc[key].errorMessage += `\n${status.errorMessage}`;
          }

          if (!acc[key].systemStatus && status.systemStatus) acc[key].systemStatus = status.systemStatus;
          if (!acc[key].submittedDate && status.submittedDate) acc[key].submittedDate = status.submittedDate;

          if ((!acc[key].marketStatus && status.marketStatus)
            || (acc[key].marketStatus && status.marketStatus && status.marketStatus.toLowerCase().includes('i'))) {
            acc[key].marketStatus = status.marketStatus;
          }
        }
        return acc;
      }, {});
      return { ...summary, ...flatStatuses };
    });
    state.statuses = flattened;
    state.tableData = highlightHours(flattened);
  },
  reset(state) {
    state.tableHourData = [];
    state.multipleSelection = [];
    state.overrideSearch = true;
  },
  setShowOpenHours(state, value) {
    state.showOpenHours = value;
    const config = generateConfig(state.date);
    if (value) {
      config.columns.forEach((col) => {
        if (
          col.isHourEndingColumn
          && state.selectedMarketType === 'RTM'
          && col.timeTZ.isBefore(moment())
        ) {
          col.visible = false;
        }
      });
    }
    state.tableConfig = config;
  },
  ...createMutations(
    'date',
    'hours',
    'multipleSelection',
    'storedParams',
    'selectedMarketType',
    'splitConfig',
    'transactionDialogVisible',
    'valueTypeFlag',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
  state,
  getters,
  mutations,
  actions,
};