import { cloneDeep, sortBy } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { ETRM_API, STRUCTURES_API } from '@/api';

export function attachIntervalsToTrade(currentTrade, changedIntervals) {
  const intervalChanges = Object.values(changedIntervals).flat();

  currentTrade.intervals = currentTrade.intervals || [];

  intervalChanges.forEach((row) => {
    const variantInt = [];
    Object.entries(row).forEach(([key, value]) => {
      if (!['startTime', 'endTime', 'he', 'momentEndTime', 'momentStartTime', '$updated', 'cascaded'].includes(key)) {
        variantInt.push({ key, value });
      }
    });

    variantInt.forEach(({ key, value }) => {
      if (!row.cascaded[key]) {
        currentTrade.intervals.push({
          objectType: 'TradeTerm',
          objectReference: currentTrade.tradeId,
          startTime: row.momentStartTime.toISOString(),
          endTime: row.momentEndTime.toISOString(),
          type: key.split('-')[1],
          value,
          variant: key.split('-')[0],
        });
      }
    });
  });
  return currentTrade;
}

export function createTradeParams(params) {
  const tz = dateStore.getTimeZone();
  let effectiveStartDate = dateStore.toMoment('2099-12-31').startOf('day').utc();
  let effectiveEndDate = dateStore.toMoment('1900-01-01').startOf('day').utc();

  if (params.dateRange?.length) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(params.dateRange, tz);
    effectiveStartDate = momentRange[1].startOf('day').utc();
    effectiveEndDate = momentRange[0].startOf('day').utc();
  }

  let tradeDateStart;
  let tradeDateEnd;
  if (params.tradeDateRange?.length) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(params.tradeDateRange, tz);
    tradeDateStart = momentRange[1].startOf('day').utc();
    tradeDateEnd = momentRange[0].startOf('day').utc();
  }

  let createdDateStart;
  let createdDateEnd;
  if (params.createdDateRange?.length) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(params.createdDateRange, tz);
    createdDateStart = momentRange[1].startOf('day').utc();
    createdDateEnd = momentRange[0].startOf('day').utc();
  }

  let modifiedDateStart;
  let modifiedDateEnd;
  if (params.modifiedDateRange?.length) {
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(params.modifiedDateRange, tz);
    modifiedDateStart = momentRange[1].startOf('day').utc();
    modifiedDateEnd = momentRange[0].startOf('day').utc();
  }

  const tradeParams = cloneDeep(params);

  // support for comma-separated values so that we can query for multiple values
  if (tradeParams?.tradeIds) { tradeParams.tradeIds = [tradeParams.tradeIds]; }
  if (tradeParams?.tradeNames) { tradeParams.tradeNames = tradeParams.tradeNames.split(','); }
  if (tradeParams?.externalNames) { tradeParams.externalNames = tradeParams.externalNames.split(','); }
  if (tradeParams?.pointOfDeliveryNames) { tradeParams.pointOfDeliveryNames = tradeParams.pointOfDeliveryNames.split(','); }
  if (tradeParams?.pointOfReceiptNames) { tradeParams.pointOfReceiptNames = tradeParams.pointOfReceiptNames.split(','); }
  if (tradeParams?.commodityIds) { tradeParams.commodityIds = [tradeParams.commodityIds]; }
  if (tradeParams?.marketIds) { tradeParams.marketIds = [tradeParams.marketIds]; }
  if (tradeParams?.companyIds) { tradeParams.companyIds = [tradeParams.companyIds]; }
  if (tradeParams?.counterPartyIds) { tradeParams.counterPartyIds = [tradeParams.counterPartyIds]; }
  if (tradeParams?.regionIds) { tradeParams.regionIds = [tradeParams.regionIds]; }
  if (tradeParams?.portfolioIds) { tradeParams.portfolioIds = [tradeParams.portfolioIds]; }
  if (tradeParams?.bookIds) { tradeParams.bookIds = [tradeParams.bookIds]; }
  if (tradeParams?.strategyIds) { tradeParams.strategyIds = [tradeParams.strategyIds]; }
  if (tradeParams?.brokerIds) { tradeParams.brokerIds = [tradeParams.brokerIds]; }
  if (tradeParams?.directions) { tradeParams.directions = [tradeParams.directions]; }
  if (tradeParams?.trader) { tradeParams.trader = [tradeParams.trader]; }

  tradeParams.effectiveStartDateBefore = effectiveStartDate.toISOString();
  tradeParams.effectiveEndDateAfter = effectiveEndDate.toISOString();
  if (tradeDateStart) {
    tradeParams.tradeDateBefore = tradeDateStart.toISOString();
  }
  if (tradeDateEnd) {
    tradeParams.tradeDateAfter = tradeDateEnd.toISOString();
  }

  if (createdDateStart) {
    tradeParams.createdDateBefore = createdDateStart.toISOString();
  }
  if (createdDateEnd) {
    tradeParams.createdDateAfter = createdDateEnd.toISOString();
  }

  if (modifiedDateStart) {
    tradeParams.updatedDateBefore = modifiedDateStart.toISOString();
  }
  if (modifiedDateEnd) {
    tradeParams.updatedDateAfter = modifiedDateEnd.toISOString();
  }

  delete tradeParams.dateRange;
  delete tradeParams.tradeDateRange;
  delete tradeParams.createdDateRange;
  delete tradeParams.modifiedDateRange;

  Object.keys(tradeParams).forEach((key) => {
    if (tradeParams[key] == null) {
      delete tradeParams[key];
    } else if (typeof tradeParams[key] === 'string' && tradeParams[key].trim() === '') {
      delete tradeParams[key];
    }
  });

  return tradeParams;
}

export const companies = {
  label: 'Companies',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data } = await STRUCTURES_API.get('/companies');
      console.log(data);
      const companies = data.companies.filter((x) => x.internalFlag).map((lg) => ({
        value: lg.id,
        label: lg.shortName,
      })).sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
      return companies;
    } catch (error) {
      vue.$notify('Failed to Load Companies', 'error');
      console.log(error);
    }
    return {};
  },
};