import resourceViewerStore from './Monitor/ResourceViewer/resourceViewerStore';
import eimSuballocationMappingStore from './Monitor/EimSuballocationMapping/EimSuballocationMappingStore';
import serverQueueStore from './ServerQueue/serverQueueStore';
import createAReportBase from './CreateAReport/CreateAReport';
import issueTrackingStore from './IssueTracking/IssueTrackingStore';
import issueTrackingFormStore from './IssueTracking/Forms/IssueTrackingFormStore';
import reportStatusStore from './ReportStatus/reportStatusStore';
import shadowSourcesStore from './ShadowSources/shadowSourcesStore';
import isoEnvironmentStore from './ISOEnvironment/isoEnvironmentStore';
import EIMTcPseMapStore from './EimTcPseMap/EIMTcPseMapStore';
import removeDataStore from './RemoveData/removeDataStore';
import shadowBatchStore from './ShadowBatch/shadowBatchStore';
import resourceCountStore from './ResourceCount/resourceCountStore'

export default {
  namespaced: true,
  modules: {
    resourceViewerStore,
    eimSuballocationMappingStore,
    serverQueueStore,
    createAReportBase,
    issueTrackingStore,
    issueTrackingFormStore,
    reportStatusStore,
    shadowSourcesStore,
    isoEnvironmentStore,
    EIMTcPseMapStore,
    removeDataStore,
    resourceCountStore,
    shadowBatchStore,
  },
};