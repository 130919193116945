import utils from '@/utils';
import { WORKFLOW_API } from '@/api';

const state = {
  notificationCallback: null,
  tableConfiguration: {
    columns: [
      {
        searchItem: true, prop: 'name', type: { name: 'workflow-route-cell', overrideReadonly: true }, label: 'name', filter: true, sortable: true,
      },
      // { prop: "name", filter: true, class: true },
      { prop: 'description', filter: true, sortable: true },
      { prop: 'source', filter: true, sortable: true },
    ],
    footer: { count: true },
    style: { maxHeight: 700 },
    options: { searchFilter: true },
  },
  datePropsAndFormats: [
    { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'endTime', format: 'YYYY-MM-DD HH:mm' },
  ],
  tableData: [],
  refreshRate: 15,
  minRefreshRate: 5,
  maxRefreshRate: 60,
  counter: 0,
  selectedDate: utils.date.getTodayLocal(),
  searchText: '',
  userToggle: false,
};

const getters = {
  getwfTableConfiguration: state => state.tableConfiguration,
  getwfTableData: state => state.tableData,
  getwfCounter: state => state.counter,
  getwfRefreshRate: state => state.refreshRate,
  getwfMinRefreshRate: state => state.minRefreshRate,
  getwfMaxRefreshRate: state => state.maxRefreshRate,
  getwfMinHeight: state => (state.tableData && state.tableData.length > 0) ? 600 : 100,
};

const actions = {
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  loadWorkflowList({ commit }) {
    WORKFLOW_API.get().then((response) => {
      commit('changeWorkflowList', response.data.workflows);
    }).catch(error => console.log(error));
  },
  changewfRefreshRateAction({ commit }, value) {
    commit('refreshwfRateMutation', value);
  },
  incrementwfCounterAction({ dispatch, commit, state }) {
    if (state.counter >= state.refreshRate) {
      dispatch('loadWorkflowList');
      commit('resetwfCounterMutation');
    } else {
      commit('incrementwfCounterMutation');
    }
  },
  clearwf({ commit }) {
    commit('clearwf');
  },
};

const mutations = {
  refreshwfRateMutation(state, value) {
    state.refreshRate = value;
  },
  resetwfCounterMutation(state) {
    state.counter = 1;
  },
  incrementwfCounterMutation(state) {
    state.counter += 1;
  },
  changeWorkflowList(state, data) {
    state.tableData = data;
  },
  clearwf(state) {
    state.tableData = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};