import { CISO_MASTERFILE_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  resourceTableData: [],
  childrenTableData: [],
  configurationsTableData: [],
  curveDataTableData: [],
  forbiddenRegionsTableData: [],
  regulatingLimitsTableData: [],
  regulatoryMustTakesTableData: [],
  resourceCapsTableData: [],
  transitionsTableData: [],
  hybridComponentTableData: [],
  gHGEmissionTableData: [],
  attributeTableData: [],

  childrenTableDataRaw: [],
  configurationsTableDataRaw: [],
  curveDataTableDataRaw: [],
  forbiddenRegionsTableDataRaw: [],
  regulatingLimitsTableDataRaw: [],
  regulatoryMustTakesTableDataRaw: [],
  resourceCapsTableDataRaw: [],
  transitionsTableDataRaw: [],
  hybridComponentTableDataRaw: [],
  gHGEmissionTableDataRaw: [],
};

const keys = ['sc', 'tradeDate', 'resource', 'pgaName', 'resourceType', 'fuelSource', 'pmax', 'pmin', 'priceNodeId', 'children', 'configurations', 'forbiddenRegions', 'regulatingLimits', 'regulatoryMustTakes', 'resourceCaps', 'transitions', 'hybridComponent', 'gHGEmission', 'curveData'];

const actions = {
  async fetchMasterFile({ dispatch }, args = {}) {
    const params = {
      scs: args.coordinatorListSelected,
      resources: args.resourceListSelected ? args.resourceListSelected.join(',') : null,
      startTime: getMomentDateString(args.tradeDateSelected),
      endTime: getMomentDateString(args.tradeDateSelected),
    };

    try {
      const { data: { data } } = await CISO_MASTERFILE_API.get('/resources', { params });
      dispatch('createMasterfileTableData', data || []);
    } catch {
      this.$notify('Failed to Load Master file', 'error');
    }
  },

  createMasterfileTableData({ commit, dispatch }, data) {
    const childObj = {
      children: [],
      configurations: [],
      curveData: [],
      forbiddenRegions: [],
      regulatingLimits: [],
      regulatoryMustTakes: [],
      resourceCaps: [],
      transitions: [],
      hybridComponent: [],
      gHGEmission: [],
    };

    data.forEach((resource) => {
      Object.entries(childObj).forEach(([prop, arr]) => {
        const newArr = [];
        resource[`${prop}`].forEach((obj) => {
          newArr.push({ resource: resource.resource, ...obj });
        });
        childObj[`${prop}`].push(...newArr);
      });
    });
    commit('setResourceTableData', data);
    commit('setProp', { prop: 'childArrays', value: childObj });

    Object.entries(childObj).forEach(([prop, value]) => {
      commit('setProp', { prop: `${prop}TableData`, value });
    });

    if (data && data.length > 0) {
      dispatch('setSelectedResource', data[0]);
    }
  },
  setSelectedResource({ state, commit }, row) {
    const attr = Object.entries(row).filter((r) => keys.indexOf(r[0]) < 0).map((r) => ({ key: r[0].replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()), value: r[1] }));
    commit('setAttributeTableData', attr);
    Object.entries(state.childArrays).forEach(([prop, array]) => {
      commit('setProp', { prop: `${prop}TableDataRaw`, value: array });

      const filteredArray = array.filter((child) => child.resource === row.resource);
      commit('setProp', { prop: `${prop}TableData`, value: filteredArray });
    });
  }
};

const mutations = {
  ...createMutations(
    'resourceTableData',
    'childrenTableData',
    'configurationsTableData',
    'curveDataTableData',
    'forbiddenRegionsTableData',
    'regulatingLimitsTableData',
    'regulatoryMustTakesTableData',
    'resourceCapsTableData',
    'transitionsTableData',
    'hybridComponentTableData',
    'gHGEmissionTableData',
    'attributeTableData',
  ),
  setProp(state, { prop, value }) {
    state[`${prop}`] = value;
  },
  reset(state) {
    Object.keys(state).forEach((key) => {
      Array.isArray(state[key]) ? state[key] = [] : state[key] = null;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};