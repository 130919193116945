import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_SOURCE_API } from '@/api';
import config from './billingMeterConfigs';

const state = {
  tableData: [],
  tableConfig: config.fields,
  isCurrentlyFetchingData: false,
};

const actions = {
  async fetchBillingMeterData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      contracts: parameters.contractsSelected,
      sourceSystem: parameters.sourceSystemSelected,
      startDate,
      endDate,
      meters: parameters.metersSelected,
    };
    commit('setIsCurrentlyFetchingData', true);
    try {
      const { data: { data } } = await BILLING_SOURCE_API.post('/meter/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.trDate = moment(x.trDate).utc().format('MM/DD/YYYY');
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Meter Data', 'error', 5000);
    } finally {
      commit('setIsCurrentlyFetchingData', false);
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setIsCurrentlyFetchingData(state, value) {
    state.isCurrentlyFetchingData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};