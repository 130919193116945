import moment from 'moment';
import { CISO_ADS_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  async fetchDispatches({ commit }, args = {}) {
    const params = {
      scs: args.coordinatorListSelected,
      rsrc_id: args.resourceListSelected,
      startDate: args.tradeDateSelected,
      endDate: args.tradeDateSelected,
    };
    try {
      const { data } = await CISO_ADS_API.get('/ads-dispatch', { params });
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.instStartDate = getMomentDateString(obj.instStartDate);
          obj.instStartTime = moment(obj.instStartTime).format('LTS').toString();
          if (obj.instEndDate !== null) {
            obj.instEndDate = getMomentDateString(obj.instEndDate);
            obj.instStrEndTime = moment(obj.instStrEndTime).format('LTS').toString();
          }
        });
        commit('setTableData', data);
      }
    } catch {
      this.$notify('Failed to Load dispatches', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};