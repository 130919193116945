import {
  tradeDateRange,
  coordinatorList,
  resourcesTagbox,
  gridConfig,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { has } from '@/utils/dataUtil';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'SC',
    dataField: 'sc',
    dataType: 'string',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONFIGURATION',
    dataType: 'string',
    dataField: 'configuration',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'string',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BID SEGMENT',
    dataType: 'number',
    dataField: 'bidSegmentNum',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'ENERGY BID TYPE',
    dataType: 'string',
    dataField: 'energyBidType',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'RT MARKET KIND',
    dataType: 'string',
    dataField: 'rtMarketKind',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'BID PRICE',
    datatype: 'number',
    customFormat: 'expectedEnergy.fixedPoint',
    dataField: 'bidPrice',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXPECTED ENERGY',
    dataType: 'number',
    customFormat: 'expectedEnergy.fixedPoint',
    dataField: 'expectedEnergy',
    area: 'data',
    expanded: true,
    summaryType: 'sum',
  }, {
    caption: 'RSRC ID',
    dataType: 'string',
    dataField: 'resource',
    area: 'column',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    dataField: 'tradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'ENERGY TYPE CODE',
    datatype: 'number',
    dataField: 'energyTypeCode',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'MARKET SERVICE TYPE',
    dataType: 'string',
    dataField: 'marketServiceType',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'SETTLEMENT CYCLE',
    dataType: 'string',
    dataField: 'publication',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }],
  summaryFields: [{
    caption: 'SC',
    dataField: 'sc',
    dataType: 'string',
    width: 120,
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONFIGURATION',
    dataType: 'string',
    dataField: 'configuration',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BID SEGMENT',
    dataType: 'number',
    dataField: 'bidSegmentNum',
    width: 120,
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'ENERGY BID TYPE',
    dataType: 'string',
    dataField: 'energyBidType',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'RT MARKET KIND',
    dataType: 'string',
    dataField: 'rtMarketKind',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'BID PRICE',
    datatype: 'number',
    customFormat: 'expectedEnergy.fixedPoint',
    dataField: 'bidPrice',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXPECTED ENERGY',
    dataType: 'number',
    customFormat: 'expectedEnergy.fixedPoint',
    dataField: 'expectedEnergy',
    area: 'data',
    expanded: true,
    summaryType: 'sum',
  }, {
    caption: 'RSRC ID',
    dataType: 'string',
    dataField: 'resource',
    area: 'column',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    dataField: 'tradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'ENERGY TYPE CODE',
    datatype: 'number',
    dataField: 'energyTypeCode',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'MARKET SERVICE TYPE',
    dataType: 'string',
    dataField: 'marketServiceType',
    area: 'row',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }, {
    caption: 'SETTLEMENT CYCLE',
    dataType: 'string',
    dataField: 'publication',
    area: 'filter',
    expanded: true,
    customizeText(cellInfo) {
      if (has(this, ['filterValues', 'sortOrder', 'sortBySummaryField']) && cellInfo.value === null) return '(Show Blanks)';
      if (cellInfo.value === null) return '';
      return cellInfo.value;
    },
  }],
  searchFilterConfig: {
    detailType: {
      label: '',
      value: 'summaryView',
      type: 'radio',
      options: [{ value: 'summaryView', label: 'Summary View' }, { value: 'detailView', label: 'Detail View' }],
      watchers: [
        {
          propertyToWatch: 'detailTypeSelected',
          handler(newValue, oldValue) {
            this.config.coordinatorList.required = !(this.params.detailTypeSelected === 'summaryView');
            this.config.resourceList.required = !(newValue === 'summaryView');
            this.config.resourceList.maxLimit = (newValue === 'summaryView') ? 50 : 6;
            if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
              this.$nextTick(() => this.$parent.instance.repaint());
            }
          },
        }],
    },
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: false },
    resourceList: { ...resourcesTagbox, maxLimit: 6, required: true },
    reportType: {
      label: 'Report Type',
      value: 'ExpectedEnergy',
      type: 'select',
      options: [{
        value: 'ExpectedEnergy', label: 'ExpectedEnergy',
      }, {
        value: 'ExpectedEnergyAllocation', label: 'Expected Energy Allocation',
      }],
    },
  },
};