import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import { MODES } from './constants';

const isEntityCPSE = (cpseList, item) => !!cpseList.find((o) => o.label === item);
export default {
  namespaced: false,
  state: {
    editOnLoad: false,
    showCopyDialog: false,
  },
  getters: {
    getEditVisibleFlag(state, getters, rootState, rootGetters) {
      const isCPSE = isEntityCPSE(getters.getCPSEList, getters.getCPSE);
      if (!isCPSE) return false;
      const mode = getters.getMode;
      const readonlyTag = getters.getReadonlyFlag;
      const terminationDateTime = getters.getTerminationDateTime;
      const compositeState = getters.getCompositeState;
      const transactionType = getters.getTransactionType;
      const status = getters.getStatus;
      if (mode === MODES.VIEW_TAG) {
        if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
        if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:submit', 'enabled')) return false;

        if (readonlyTag || status === 'DENIED') return false;
        const zone = dateStore.getTimeZone();
        const endDate = dateStore.toMomentAndZoneFromJSDate(terminationDateTime, zone);
        const futureDate = endDate.clone().add(7, 'days');
        const currentTime = dateStore.getMomentNowLocal();
        if ((transactionType === 'PSEUDOTIE' || transactionType === 'DYNAMIC') && futureDate.isAfter(currentTime)) return true;
        if (['CONFIRMED', 'IMPLEMENTED', 'TERMINATED'].includes(compositeState) && endDate.isBefore(currentTime)) return false;
        if (['CONFIRMED', 'IMPLEMENTED', 'PENDING', 'TERMINATED'].includes(compositeState)) return true;
      } else if (mode === MODES.VIEW_TEMPLATE) {
        return (hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')
          || hasPermission(rootGetters['auth/getPermissions'], 'etag:variant', 'write'));
      }
      return false;
    },
    getUseTemplateVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      return getters.getMode === MODES.VIEW_TEMPLATE;
    },
    getSubmitVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:submit', 'enabled')) return false;
      return [MODES.NEW_TAG, MODES.EDIT_TAG].includes(getters.getMode);
    },
    getSubmitATFVisibility(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      const mode = getters.getMode;
      const transactionType = getters.getTransactionType;
      if (mode === MODES.EDIT_TAG && (transactionType === 'PSEUDOTIE' || transactionType === 'DYNAMIC')) return true;
      return false;
    },
    getCopyVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;

      const mode = getters.getMode;
      return mode === MODES.VIEW_TAG;
    },
    getSaveVisibleFlag(state, getters) {
      const mode = getters.getMode;
      if (mode === MODES.NEW_TAG || mode === MODES.VARIANT_TAG) {
        return true;
      }
      return false;
    },
    getStopEditButtonVisible(state, getters) {
      if (getters.getMode === MODES.EDIT_TAG) return true;
      return false;
    },
    getCancelVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:submit', 'enabled')) return false;
      const isCPSE = isEntityCPSE(getters.getCPSEList, getters.getCPSE);
      if (!isCPSE) return false;
      const readonlyTag = getters.getReadonlyFlag;
      if (readonlyTag) return false;
      const mode = getters.getMode;
      const implementationDateTime = getters.getImplementationDateTime;
      const compositeState = getters.getCompositeState;
      if (implementationDateTime == null) return false;
      const startDate = dateStore.toMomentFromJSDate(implementationDateTime);
      if (mode === MODES.VIEW_TAG && compositeState === 'CONFIRMED' && startDate.isAfter()) return true;
      return false;
    },
    getTerminateVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:submit', 'enabled')) return false;
      const isCPSE = isEntityCPSE(getters.getCPSEList, getters.getCPSE);
      if (!isCPSE) return false;
      const readonlyTag = getters.getReadonlyFlag;
      const mode = getters.getMode;
      const status = getters.getStatus;
      const implementationDateTime = getters.getImplementationDateTime;
      const terminationDateTime = getters.getTerminationDateTime;
      const compositeState = getters.getCompositeState;
      if (implementationDateTime === null || terminationDateTime == null) return false;
      if (status !== 'APPROVED' || readonlyTag) return false;
      const startDate = dateStore.toMoment(implementationDateTime);
      const endDate = dateStore.toMoment(terminationDateTime);
      const nowDate = dateStore.getMomentNow();
      if ((mode === MODES.VIEW_TAG || mode === MODES.EDIT_TAG)
          && (compositeState === 'IMPLEMENTED' || compositeState === 'TERMINATED')
          && endDate.isAfter(nowDate) && startDate.isBefore(nowDate)) {
        return true;
      }
      return false;
    },
    getWithdrawVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:submit', 'enabled')) return false;
      const isCPSE = isEntityCPSE(getters.getCPSEList, getters.getCPSE);
      if (!isCPSE) return false;
      const readonlyTag = getters.getReadonlyFlag;
      const mode = getters.getMode;
      const status = getters.getStatus;
      return mode === MODES.VIEW_TAG && status === 'PENDING' && !readonlyTag;
    },
    getReloadVisibleFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:reload', 'write')) return false;
      const currentTime = Date.now();
      const implementationDateTime = Date.parse(getters.getImplementationDateTime);
      if (!!implementationDateTime
        && ((currentTime - implementationDateTime) / (1000 * 60 * 60 * 24) >= 45)) return false;

      return true;
    },
    getDeleteButtonVisible(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      return getters.getMode === MODES.VARIANT_TAG || getters.getMode === MODES.VIEW_TEMPLATE;
    },
    getCreateTemplateVisibilityFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      return getters.getMode === MODES.VIEW_TAG;
    },
    getCreateNewTemplateVisibilityFlag(state, getters, rootState, rootGetters) {
      if (!hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')) return false;
      return getters.getMode === MODES.NEW_TEMPLATE;
    },
  },
};