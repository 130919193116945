import ls from 'local-storage';
import { has } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

class StructurePathCache {
  constructor() {
    this.cache = ls.get('structurePathCache');
    if (!this.cache) {
      ls.set('structurePathCache', {
        collections: {},
        usercollections: {},
      });
      this.cache = ls.get('structurePathCache');
    }
  }

  hasCollection({ collection, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    return has(this.cache[collections], collection);
  }

  addCollection({ collection, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    this.cache[collections][collection] = {};
    ls.set('structurePathCache', this.cache);
  }

  async checkCollection({ collection, isUser }) {
    if (!collection) {
      throw Error('Must have collection name');
    }
    const collections = isUser ? 'usercollections' : 'collections';
    const result = { completed: false };

    try {
      if (!this.hasCollection({ collection })) {
        await STRUCTURES_API.get(`${collections}/${collection}`);
        this.addCollection({ collection });
      }
      result.completed = true;
    } catch (err) {
      if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
        if (err.response.status === 404) {
          try {
            await STRUCTURES_API.post(`${collections}/${collection}`, {});
            this.addCollection({ collection });
            result.completed = true;
          } catch (innerErr) {
            console.error(innerErr);
          }
        }
      } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
        console.error(err.request);
      } else {
      // Something happened in setting up the request that triggered an Error
        console.error('Error', err.message);
      }
    }

    return result;
  }

  hasResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    return has(this.cache[collections], collection) && has(this.cache[collections][collection], resource);
  }

  addResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    if (has(this.cache[collections], collection)) {
      this.cache[collections][collection][resource] = {};
    } else {
      this.cache[collections] = {
        [collection]: {
          [resource]: {},
        },
      };
    }
    ls.set('structurePathCache', this.cache);
  }

  async checkResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    resource = resource.replace(/ /g, '');
    const result = { completed: false };

    try {
      if (!this.hasResource({ collection, resource })) {
        await STRUCTURES_API.get(`/${collections}/${collection}/resources/${resource}`);
        this.addResource({ collection, resource });
      }
      result.completed = true;
    } catch (err) {
      if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
        if (err.response.status === 404) {
          try {
            await STRUCTURES_API.post(`/${collections}/${collection}/resources/${resource}`, {});
            this.addResource({ collection, resource });
            result.completed = true;
          } catch (innerErr) {
            console.error(innerErr);
          }
        }
      } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
        console.error(err.request);
      } else {
      // Something happened in setting up the request that triggered an Error
        console.error('Error', err.message);
      }
    }

    return result;
  }

  async fetchResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    resource = resource.replace(/ /g, '');

    let result = null;

    const collectionResponse = await this.checkCollection({ collection, isUser });
    if (!collectionResponse.completed) return result;

    const resourceResponse = await this.checkResource({ collection, resource, isUser });
    if (!resourceResponse.completed) return result;

    try {
      const { data } = await STRUCTURES_API.get(`/${collections}/${collection}/resources/${resource}`);
      result = data;
    } catch (err) {
      if (err.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
        console.error(err.response);
        if (err.response.status === 404) {
          await STRUCTURES_API.post(`/${collections}/${collection}/resources/${resource}`, {});
          this.addResource({ collection, resource });
        }
      } else if (err.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
        console.error(err.request);
      } else {
      // Something happened in setting up the request that triggered an Error
        console.error('Error', err.message);
      }
    }
    return result;
  }

  async updateResource({
    collection, resource, payload, isUser,
  }) {
    const collections = isUser ? 'usercollections' : 'collections';
    resource = resource.replace(/ /g, '');
    await this.checkCollection({ collection, isUser });
    await this.checkResource({ collection, resource, isUser });
    const { data } = await STRUCTURES_API.put(`/${collections}/${collection}/resources/${resource}`, payload);
    return data;
  }

  removeResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    if (has(this.cache[collections], collection)) {
      delete this.cache[collections][collection][resource];
    }
    ls.set('structurePathCache', this.cache);
  }

  async deleteResource({ collection, resource, isUser }) {
    const collections = isUser ? 'usercollections' : 'collections';
    resource = resource.replace(/ /g, '');
    try {
      await STRUCTURES_API.delete(`/${collections}/${collection}/resources/${resource}`);
      this.removeResource({ collection, resource, isUser });
    } catch (e) {
      console.error(e);
    }
  }
}

const structurePath = new StructurePathCache();

export const fetchResource = async (collection, resource) => structurePath.fetchResource({ collection, resource });
export const updateResource = async (collection, resource, payload) => structurePath.updateResource({ collection, resource, payload });
export const deleteResource = async (collection, resource) => structurePath.deleteResource({ collection, resource });

export const fetchUserResource = async (collection, resource) => structurePath.fetchResource({ collection, resource, isUser: true });
export const updateUserResource = async (collection, resource, payload) => structurePath.updateResource({
  collection, resource, payload, isUser: true,
});
export const deleteUserResource = async (collection, resource) => structurePath.deleteResource({ collection, resource, isUser: true });
export const fetchUserCollection = async (collection) => {
  await structurePath.checkCollection({ collection, isUser: true });
  const { data } = await STRUCTURES_API.get(`/usercollections/${collection}/resources`);
  return data;
};

export const collectionService = structurePath;