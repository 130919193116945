// #TODO: this logic is applied to multiple screens. Will need to move this function to CisoConfig and update other screens
// screens: disuputesConfig, disputesFormConfig, ViewChargeDetailsConfig, EtrmInvoice > tableconfig

function customCurrencyFormatter(initialValue, format = { style: 'currency', currency: 'USD', locale: 'en-US' }) {
  if (initialValue === null) { return null; }

  const currencyValue = parseFloat(parseFloat(initialValue, 10).toFixed(2));

  if (currencyValue === 0) { return null; }

  const formatter = new Intl.NumberFormat(format.locale, {
    ...format,
  });

  return formatter.format(currencyValue);
}

export default {
  stmtConfigData: {
    fields: [
      {
        caption: 'SC',
        dataField: 'sc',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BA',
        dataField: 'ba',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'CHARGE CODE',
        dataField: 'chargecode',
        dataType: '',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'DESCRIPTION',
        dataField: 'description',
        dataType: '',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'TRADING DATE',
        dataField: 'tradingDate',
        dataType: 'datetime',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'TRADING HOUR',
        dataField: 'tradinghour',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING INTERVAL',
        dataField: 'tradinginterval',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'CURRENT AMOUNT',
        dataField: 'currentamount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'CURRENT QUANTITY',
        dataField: 'currentquantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'CURRENT PRICE',
        dataField: 'currentprice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'PREVIOUS AMOUNT',
        dataField: 'previousamount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'PREVIOUS QUANTITY',
        dataField: 'previousquantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'PREVIOUS PRICE',
        dataField: 'previousprice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'NET AMOUNT',
        dataField: 'netamount',
        dataType: '',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'NET QUANTITY',
        dataField: 'netquantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'NET PRICE',
        dataField: 'netprice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'PTB CURRENT AMOUNT',
        dataField: 'ptbcurrentamount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'PTB NET AMOUNT',
        dataField: 'ptbnetamount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
    ],
  },
  compareConfigData: {
    fields: [
      {
        caption: 'SC',
        dataField: 'sc',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BA',
        dataField: 'ba',
        dataType: '',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'CHARGE CODE',
        dataField: 'chargecode',
        dataType: '',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'DESCRIPTION',
        dataField: 'description',
        dataType: '',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'TRADING DATE',
        dataField: 'tradingDate',
        dataType: 'datetime',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'TRADING HOUR',
        dataField: 'tradinghour',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING INTERVAL',
        dataField: 'tradinginterval',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'FILE AMOUNT',
        dataField: 'fileAmount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'FILE QUANTITY',
        dataField: 'fileQuantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'FILE PRICE',
        dataField: 'filePrice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'COMPARE AMOUNT',
        dataField: 'compareAmount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'COMPARE QUANTITY',
        dataField: 'compareQuantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'COMPARE PRICE',
        dataField: 'comparePrice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'VARIANCE AMOUNT',
        dataField: 'varianceAmount',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
      {
        caption: 'VARIANCE QUANTITY',
        dataField: 'varianceQuantity',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: '#,###.##',
        expanded: false,
      },
      {
        caption: 'VARIANCE PRICE',
        dataField: 'variancePrice',
        dataType: 'number',
        area: 'data',
        summaryType: 'sum',
        format: (value) => customCurrencyFormatter(value),
        expanded: false,
      },
    ],
  },
};