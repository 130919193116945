import { CISO_BID_SCHD_API, CISO_MASTERDATA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import util from '@/utils';
import { hasPermission } from '@/utils/authUtils';
import { cloneDeep } from '@/utils/dataUtil';

const LONG_DAY = 'LONG_DAY';
const SHORT_DAY = 'SHORT_DAY';

const CREATE_PROPOSED = 1;
const CREATE_SUBMIT_PROPOSED = 2;
const CLEAR_SCREEN = 3;

function setAllHoursToSameStrategy(strategy, item) {
  const currentDate = dateStore.toMoment(item.date, 'America/Los_Angeles');

  const hours = dateStore.getHours(currentDate);
  const mod = dateStore.getLongDayHour() ? '2*' : '25';
  strategy.he02D = null;
  strategy.he02DName = null;
  strategy.changed = true;
  hours.forEach((hour) => {
    const hr = (`0${hour}`).slice(-2);
    if (item.selectedHours.includes(hour.toString())) {
      if (hour === mod) {
        if (item.strategy.label === 'None') {
          strategy.he02D = null;
          strategy.he02DName = item.strategy.label;
        } else {
          strategy.he02D = item.strategy.value;
          strategy.he02DName = item.strategy.label;
        }
      }
      if (item.strategy.label === 'None') {
        strategy[`he${hr}`] = null;
        strategy[`he${hr}Name`] = item.strategy.label;
      } else {
        strategy[`he${hr}`] = item.strategy.value;
        strategy[`he${hr}Name`] = item.strategy.label;
      }
    }
  });
}

function setAllHoursToString(strategy, strategies) {
  for (var key in strategy) {
    if (key.includes('he') && !key.includes('Name')) {
      if (strategy[key]) {
        const { name } = strategies.find(({ value }) => value === strategy[key].toString());

        strategy[`${key}Name`] = name;
        strategy[key] = strategy[key].toString();
      }
    }
  }
}

function setSelectionText(strategy) {
  const notNull = strategy.he01 != null
    && strategy.he02 != null
    && strategy.he03 != null
    && strategy.he04 != null
    && strategy.he05 != null
    && strategy.he06 != null
    && strategy.he07 != null
    && strategy.he08 != null
    && strategy.he09 != null
    && strategy.he11 != null
    && strategy.he12 != null
    && strategy.he13 != null
    && strategy.he14 != null
    && strategy.he15 != null
    && strategy.he16 != null
    && strategy.he17 != null
    && strategy.he18 != null
    && strategy.he19 != null
    && strategy.he20 != null
    && strategy.he21 != null
    && strategy.he22 != null
    && strategy.he23 != null
    && strategy.he24 != null;

  const match = strategy.he01 === strategy.he02
    && strategy.he02 === strategy.he03
    && strategy.he03 === strategy.he04
    && strategy.he04 === strategy.he05
    && strategy.he05 === strategy.he06
    && strategy.he06 === strategy.he07
    && strategy.he07 === strategy.he08
    && strategy.he08 === strategy.he09
    && strategy.he09 === strategy.he10
    && strategy.he10 === strategy.he11
    && strategy.he11 === strategy.he12
    && strategy.he12 === strategy.he13
    && strategy.he13 === strategy.he14
    && strategy.he14 === strategy.he15
    && strategy.he15 === strategy.he16
    && strategy.he16 === strategy.he17
    && strategy.he17 === strategy.he18
    && strategy.he18 === strategy.he19
    && strategy.he19 === strategy.he20
    && strategy.he20 === strategy.he21
    && strategy.he21 === strategy.he22
    && strategy.he22 === strategy.he23
    && strategy.he23 === strategy.he24;

  let text = 'SAME';

  if (notNull != true || match != true) text = 'MIXED';
  strategy.selection = text;
}

export default {
  namespaced: true,

  state: {
    tableData: [],
    marketTypes: caisoStore.getMarketTypeOptions(),
    scs: [],
    selectMarketType: caisoStore.getMarketType(),
    selectSc: '',
    resources: [],
    strategies: [],
    multipleSelection: [],
    notification: '',
    startDate: dateStore.getDefaultDate(),
    product: 'EN',
    productList: [
      { label: 'Energy', value: 'EN' },
      { label: 'Reg Up', value: 'RU' },
      { label: 'Reg Down', value: 'RD' },
      { label: 'Spin', value: 'SR' },
      { label: 'Non-Spin', value: 'NR' }],
    tableConfig: {
      columns: [
        { prop: 'marketType', label: 'MARKET' },
        { prop: 'product', label: 'PRODUCT' },
        { prop: 'resourceId', label: 'RESOURCE ID' },
        {
          prop: 'effectiveDate', label: 'EFFECTIVE DATE', dataType: 'date', format: 'yyyy-MM-dd',
        },
        {
          prop: 'terminationDate', label: 'TERMINATION DATE', dataType: 'date', format: 'yyyy-MM-dd',
        },
        { prop: 'he01', label: 'HE01' },
        { prop: 'he02', label: 'HE02' },
        { prop: 'he02D', visible: false, label: 'HE02*' },
        { prop: 'he03', label: 'HE03' },
        { prop: 'he04', label: 'HE04' },
        { prop: 'he05', label: 'HE05' },
        { prop: 'he06', label: 'HE06' },
        { prop: 'he07', label: 'HE07' },
        { prop: 'he08', label: 'HE08' },
        { prop: 'he09', label: 'HE09' },
        { prop: 'he10', label: 'HE10' },
        { prop: 'he11', label: 'HE11' },
        { prop: 'he12', label: 'HE12' },
        { prop: 'he13', label: 'HE13' },
        { prop: 'he14', label: 'HE14' },
        { prop: 'he15', label: 'HE15' },
        { prop: 'he16', label: 'HE16' },
        { prop: 'he17', label: 'HE17' },
        { prop: 'he18', label: 'HE18' },
        { prop: 'he19', label: 'HE19' },
        { prop: 'he20', label: 'HE20' },
        { prop: 'he21', label: 'HE21' },
        { prop: 'he22', label: 'HE22' },
        { prop: 'he23', label: 'HE23' },
        { prop: 'he24', label: 'HE24' },
      ],
    },
    saveOptions: [{
      id: CREATE_PROPOSED, name: 'Create Proposed', icon: 'fa fa-file-text-o',
    }, {
      id: CREATE_SUBMIT_PROPOSED, name: 'Create and Submit', icon: 'fa fa-files-o',
    }, {
      id: CLEAR_SCREEN, name: 'Clear Screen', icon: 'fa fa-trash-o',
    }],
    strategyDialogFlag: false,
    selectedStrategy: null,
    focusedRowIndex: -1,
    focusedColumnProp: null,
    hourStrategyDialogFlag: false,
    selectedHourStrategy: null,
    changes: false,
    refreshVal: 15,
    minRefreshVal: 5,
    maxRefreshVal: 60,
    resetRefreshBool: false,
    disableAutoRefresh: false,
  },
  getters: {
    hasWritePermissionFlag(state, getters, rootState, rootGetters) {
      return hasPermission(rootGetters['auth/getPermissions'], 'caiso_scheduling:caiso_strategy', 'write');
    },
    getSaveOptions: (state) => state.saveOptions,
    getStrategies: (state) => state.strategies,
    getResources: (state) => state.resources,
    getStartDate: (state) => state.startDate,
    getMarketType: (state) => state.selectMarketType,
    getMarketTypeList: (state) => state.marketTypes,
    getSC: (state) => state.selectSc,
    getSCList: (state) => state.scs,
    getProduct: (state) => state.product,
    getProductList: (state) => state.productList,
    getDisableAutoRefresh: (state) => state.disableAutoRefresh,
    getTableConfig(state) {
      const tableConfig = cloneDeep(state.tableConfig);
      for (let i = 0; i < tableConfig.columns.length; i++) {
        const col = tableConfig.columns[i];
        if (col.prop.includes('he')) {
          if (state.mode === LONG_DAY) {
            if (col.label.includes('02*')) { col.visible = state.is2D; }
            if (col.label.includes('25')) { col.visible = !(state.is2D); }
          } else if (state.mode === SHORT_DAY) {
            if (col.label.includes('03')) { col.visible = false; }
          } else if (col.label.includes('02*') || col.label.includes('25')) { col.visible = false; }
        }
      }
      return tableConfig;
    },
    getReplacementStrategyName(state) {
      if (state.selectedStrategy == null || state.selectedStrategy.rowIndex == null) { return ''; }

      const ss = state.selectedStrategy;
      const strategy = state.tableData[ss.rowIndex];
      const strategyNames = state.strategies;

      const item = strategyNames.find(({ value }) => value.toString() === strategy.he01.toString());
      return item.label;
    },
    getReplacementStrategyResourceName(state) {
      if (state.selectedStrategy == null || state.selectedStrategy.rowIndex == null) { return ''; }

      const ss = state.selectedStrategy;
      const strategy = state.tableData[ss.rowIndex];
      return strategy.resourceShortName;
    },
    getChangeStrategyDialogFlag: (state) => state.strategyDialogFlag,
    getHourStrategyDialogFlag: (state) => state.hourStrategyDialogFlag,
    getFocusedRow(state) {
      if (state.focusedRowIndex >= 0) {
        const ite = state.tableData[state.focusedRowIndex];
        return ite;
      }

      return { resourceShortName: null };
    },
    getFocusedColumnProp: (state) => state.focusedColumnProp,
  },
  actions: {
    reset({ commit }) {
      commit('resetMutation');
    },
    setSearchFilterParams({ dispatch, state }, params) {
      dispatch('changeScAction', params.schedulingCoordinator);
      dispatch('changeMarketTypeAction', params.marketType);
      dispatch('changeStartDateAction', params.startDate);
      dispatch('changeProduct', params.productType);
    },
    async initialize({ commit, state }) {
      state.changes = false;
      await CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true')
        .then((response) => {
          commit('setScsMutation', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      await CISO_BID_SCHD_API.get('/bidstrategies/names')
        .then((response) => {
          commit('setStrategyNamesMutation', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      await CISO_BID_SCHD_API.get('/bidstrategies', {
        params: {
          startDate: state.startDate,
          marketType: state.selectMarketType,
          sc: state.selectSc,
          product: state.product,
        },
      }).then((response) => {
        commit('searchMutation', response.data);
      }).catch((err) => {
        console.log(err);
      });
    },
    changeMarketTypeAction({ commit, dispatch }, e) {
      commit('resetRefreshTimerMutation'); // reset the refresh timer
      commit('changeMarketTypeMutation', e);
    },
    changeMultipleSelectionAction({ commit }, item) {
      commit('setMultipleSelection', item);
    },
    changeScAction({ commit, dispatch, state }, e) {
      commit('resetRefreshTimerMutation'); // reset the refresh timer
      commit('changeScMutation', e);
    },
    changeStartDateAction({ commit, dispatch, state }, e) {
      commit('resetRefreshTimerMutation'); // reset the refresh timer
      commit('changeStartDateMutation', e);
    },
    dataChangedAction({ commit, state }, item) {
      commit('dataMutation', item);
    },
    changeProduct({ commit, dispatch }, item) {
      commit('resetRefreshTimerMutation'); // reset the refresh timer
      commit('setProduct', item);
    },
    updateResourceStrategies({ commit, state }, command) {
      if (command.id === CLEAR_SCREEN) {
        commit('clearScreen');
      } else {
        const batch = state.tableData.filter((o) => o.changed === true);
        CISO_BID_SCHD_API.put('/bidstrategies/batch',
          {
            startDate: state.startDate,
            bidStrategies: batch,
          })
          .then((response) => {
            const message = command.id === CREATE_PROPOSED || command.id === CREATE_SUBMIT_PROPOSED ? 'Generating Bids' : 'Save Successful';
            this.$notify({ type: 'success', message });

            const format = util.date.ZULU_FORMAT;
            const zone = dateStore.getTimeZoneDefinition().tz;
            const startTime = util.date.formatDate(util.date.toUtcDateFromDate(state.startDate, zone), format);
            const submitAfter = command.id === CREATE_SUBMIT_PROPOSED;

            if (command.id === CREATE_PROPOSED || command.id === CREATE_SUBMIT_PROPOSED) {
              const body = {
                startTime,
                sc: state.selectSc,
                marketType: state.selectMarketType,
                product: state.product,
                variant: 'PROPOSED',
                submitAfter,
              };

              CISO_BID_SCHD_API.post('/bidstrategies/bids', body)
                .catch((err) => {
                  this.$notify({ type: 'error', message: 'Error during bid creation.' });
                });
            }
            state.tableData.forEach((e) => {
              e.changed = false;
            });
          })
          .catch((err) => {
            console.log(err);
            this.$notify({ type: 'error', message: 'Error saving strategies' });
          });
      }
    },
    createBids({ commit, state }, command) {
      const format = util.date.ZULU_FORMAT;
      const zone = dateStore.getTimeZoneDefinition().tz;
      const startTime = util.date.formatDate(util.date.toUtcDateFromDate(state.startDate, zone), format);

      const body = {
        startDate: startTime,
        sc: state.selectSc,
        marketType: state.selectMarketType,
        product: state.product,
        variant: 'PROPOSED',
      };

      CISO_BID_SCHD_API.post('/bidstrategies/bids', body)
        .then(() => {
          this.$notify({ type: 'success', message: 'Bid creation started' });
        })
        .catch((err) => {
          this.$notify({ type: 'error', message: 'Error during bid creation.' });
        });
    },
    fetchBidStrategies({ commit, state }) {
      CISO_BID_SCHD_API.get('/bidstrategies', {
        params: {
          startDate: state.startDate,
          marketType: state.selectMarketType,
          sc: state.selectSc,
          product: state.product,
        },
      })
        .then((response) => {
          commit('searchMutation', response.data);
        })
        .catch((err) => {

        });
    },
    getStrategyNamesAction({ commit, dispatch }) {
      CISO_BID_SCHD_API.get('/bidstrategies/names')
        .then((response) => {
          commit('setStrategyNamesMutation', response.data);
        })
        .catch((err) => {
        });
    },
    getScsAction({ commit, dispatch }) {
      CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true')
        .then((response) => {
          commit('setScsMutation', response.data);
        })
        .catch((err) => {

        });
    },
    changeStrategy({ commit }, item) {
      commit('setAllStrategies', item);
    },
    showMultiSelectStrategyDialog({ commit }, value) {
      commit('setMultiSelectStrategyDialogFlag', value);
    },
    showHourStrategyDialog({ commit }, value) {
      commit('setHourStrategyDialogFlag', value);
    },
    changeHourStrategy({ commit }, value) {
      commit('setHourStrategy', value);
    },
    clearAllStrategies({ commit }) {
      commit('clearAllStrategiesMutation');
    },
    refreshRateChanged({ commit }, value) {
      commit('refreshRateMutation', value);
    },
    disableAutoRefreshChanged({ commit }, val) {
      commit('setDisableAutoRefresh', val);
    },
  },
  mutations: {
    resetMutation(state) {
      state.tableData = [];
      state.strategies = [];
      state.resources = [];
      state.scs = [];
      state.changes = false;
      state.selectMarketType = caisoStore.getMarketType();
      state.selectSc = '';
      state.startDate = dateStore.getDefaultDate();
      state.product = 'EN';
      state.selectedStrategy = null;
      state.selectedHourStrategy = null;
    },
    setDisableAutoRefresh(state, val) {
      state.disableAutoRefresh = val;
    },
    changeMarketTypeMutation(state, text) {
      state.selectMarketType = text;
    },
    changeScMutation(state, sc) {
      if (typeof sc === 'number') {
        const schedulingCoordinator = state.scs.find(({ id }) => sc === id);
        state.selectSc = schedulingCoordinator.value;
      } else {
        state.selectSc = sc;
      }
    },
    changeStartDateMutation(state, date) {
      state.startDate = date;
    },
    searchMutation(state, data) {
      state.changes = false;
      state.tableData = [];
      const result = [];
      const sc = state.selectSc;
      const date = dateStore.toDate(state.startDate);
      const year = date.getFullYear();
      let month = date.getMonth() + 1;

      if (month < 10) { month = `0${month}`; }

      let dt = date.getDate();
      if (dt < 10) { dt = `0${dt}`; }

      const dateString = `${year}${month}${dt}`;

      for (let xr = 0; xr < state.resources.length; xr++) {
        const rsrc = state.resources[xr];

        if (rsrc.sc !== sc) { continue; }
        if (rsrc.configurations && rsrc.configurations.length > 0) {
          for (let i = 0; i < rsrc.configurations.length; i++) {
            const configuration = rsrc.configurations[i].shortName;
            let bs = data.bidStrategies.find((res) => res.resourceId === rsrc.id && res.configuration === configuration);
            if (bs) {
              bs.resourceShortName = rsrc.shortName;
              bs.changed = false;
              bs.date = dateString;
              bs.sc = state.selectSc;
              bs.updatedDate = bs.updatedDate ? dateStore.toMomentFromJSDate(bs.updatedDate).format('MM/DD/YY hh:mm') : null;
              bs.createdDate = bs.createdDate ? dateStore.toMomentFromJSDate(bs.updatedDate).format('MM/DD/YY hh:mm') : null;
              bs.effectiveDate = state.startDate;
              setAllHoursToString(bs, state.strategies);
            } else {
              bs = {
                date: dateString,
                resourceId: rsrc.id,
                resourceShortName: rsrc.shortName,
                changed: false,
                editedBy: null,
                editedDate: null,
                effectiveDate: state.startDate,
                terminationDate: null,
                marketType: state.selectMarketType,
                product: state.product,
                configuration,
                sc: state.selectSc,
                he01: null,
                he01Name: null,
                he02: null,
                he02Name: null,
                he02D: null,
                he02DName: null,
                he03: null,
                he03Name: null,
                he04: null,
                he04Name: null,
                he05: null,
                he05Name: null,
                he06: null,
                he06Name: null,
                he07: null,
                he07Name: null,
                he08: null,
                he08Name: null,
                he09: null,
                he09Name: null,
                he10: null,
                he10Name: null,
                he11: null,
                he11Name: null,
                he12: null,
                he12Name: null,
                he13: null,
                he13Name: null,
                he14: null,
                he14Name: null,
                he15: null,
                he15Name: null,
                he16: null,
                he16Name: null,
                he17: null,
                he17Name: null,
                he18: null,
                he18Name: null,
                he19: null,
                he19Name: null,
                he20: null,
                he20Name: null,
                he21: null,
                he21Name: null,
                he22: null,
                he22Name: null,
                he23: null,
                he23Name: null,
                he24: null,
                he24Name: null,
              };
            }

            setSelectionText(bs);
            result.push(bs);
          }
        } else {
          let bs = data.bidStrategies.find(({ resourceId }) => resourceId === rsrc.id);
          if (bs) {
            bs.resourceShortName = rsrc.shortName;
            bs.changed = false;
            bs.date = dateString;
            bs.sc = state.selectSc;
            bs.updatedDate = bs.updatedDate ? dateStore.toMomentFromJSDate(bs.updatedDate).format('MM/DD/YY hh:mm') : null;
            bs.createdDate = bs.createdDate ? dateStore.toMomentFromJSDate(bs.updatedDate).format('MM/DD/YY hh:mm') : null;
            bs.effectiveDate = state.startDate;
            setAllHoursToString(bs, state.strategies);
          } else {
            bs = {
              date: dateString,
              resourceId: rsrc.id,
              resourceShortName: rsrc.shortName,
              changed: false,
              editedBy: null,
              editedDate: null,
              effectiveDate: state.startDate,
              terminationDate: null,
              marketType: state.selectMarketType,
              product: state.product,
              configuration: null,
              sc: state.selectSc,
              he01: null,
              he01Name: null,
              he02: null,
              he02Name: null,
              he02D: null,
              he02DName: null,
              he03: null,
              he03Name: null,
              he04: null,
              he04Name: null,
              he05: null,
              he05Name: null,
              he06: null,
              he06Name: null,
              he07: null,
              he07Name: null,
              he08: null,
              he08Name: null,
              he09: null,
              he09Name: null,
              he10: null,
              he10Name: null,
              he11: null,
              he11Name: null,
              he12: null,
              he12Name: null,
              he13: null,
              he13Name: null,
              he14: null,
              he14Name: null,
              he15: null,
              he15Name: null,
              he16: null,
              he16Name: null,
              he17: null,
              he17Name: null,
              he18: null,
              he18Name: null,
              he19: null,
              he19Name: null,
              he20: null,
              he20Name: null,
              he21: null,
              he21Name: null,
              he22: null,
              he22Name: null,
              he23: null,
              he23Name: null,
              he24: null,
              he24Name: null,
            };
          }

          setSelectionText(bs);
          result.push(bs);
        }
      }

      state.tableData = result.sort(({ resourceShortName }) => resourceShortName);
    },
    setAllStrategies(state, item) {
      state.changes = true;
      const strategy = state.tableData[state.focusedRowIndex];
      setAllHoursToSameStrategy(strategy, item);

      setSelectionText(strategy);
      state.strategyDialogFlag = false;
      state.selectedStrategy = null;
    },
    clearAllStrategiesMutation(state) {
      state.changes = true;
      const strategy = state.tableData[state.focusedRowIndex];
      strategy.changed = true;
      for (const key in strategy) {
        if (key.includes('he')) {
          strategy[key] = null;
        }
      }
      state.strategyDialogFlag = false;
      state.selectedStrategy = null;
    },
    dataMutation(state, item) {
      state.changes = true;
      const strategy = state.tableData[item.rowIndex];
      strategy[item.prop] = item.value;
      strategy.startDate = state.startDate;
      strategy.changed = true;

      setSelectionText(strategy);
    },
    setStrategyNamesMutation(state, data) {
      const result = [];
      const { strategyNames } = data;
      const { product } = state;

      for (let x = 0; x < strategyNames.length; x++) {
        const item = strategyNames[x];
        item.value = item.id.toString();
        item.label = item.name;
        result.push(item);
      }
      state.strategies = result;
      // push an object of null's with the label "None" to the front of the list of strategies
      // to give users the option to select no strategy.
      state.strategies.unshift({
        id: null, label: 'None', name: null, productType: null, value: -1,
      });
    },
    setNotificationMutation(state, item) {
      state.notification = item;
    },
    setScsMutation(state, data) {
      state.scs = data.schedulingCoordinators
        .map(({ shortName, id }) => ({ value: shortName, label: shortName, id }));

      if (state.scs.length > 0) {
        state.selectSc = state.scs[0].value;
      }

      for (let i = 0; i < data.schedulingCoordinators.length; i++) {
        const sc = data.schedulingCoordinators[i];
        for (let j = 0; j < sc.resources.length; j++) {
          const resource = sc.resources[j];
          resource.sc = data.schedulingCoordinators[i].shortName;
          state.resources.push(resource);
        }
      }
    },
    setProduct(state, item) {
      state.product = item;
    },
    clearScreen(state) {
      state.tableData.forEach((strategy) => {
        for (const key in strategy) {
          if (key.includes('he')) {
            if (strategy[key]) {
              strategy[key] = null;
              strategy.changed = true;
              state.changes = true;
            }
          }
        }
      });
    },
    setMultiSelectStrategyDialogFlag(state, params) {
      state.strategyDialogFlag = params.visible;
      if (state.strategyDialogFlag) {
        state.focusedRowIndex = params.rowIndex;
      } else {
        state.focusedRowIndex = -1;
      }
    },
    setMultipleSelection(state, item) {
      state.multipleSelection = item;
    },
    setHourStrategyDialogFlag(state, item) {
      state.hourStrategyDialogFlag = !state.hourStrategyDialogFlag;
      if (state.hourStrategyDialogFlag) {
        state.focusedColumnProp = item.columnProp;
        state.focusedRowIndex = item.rowIndex;
      } else {
        state.focusedColumnProp = null;
        state.focusedRowIndex = -1;
      }
    },
    setHourStrategy(state, hour) {
      state.changes = true;
      const item = state.tableData[state.focusedRowIndex];
      const s = state.strategies.find(({ value }) => value === hour.value);

      if (s.label === 'None') {
        item[`${state.focusedColumnProp}Name`] = s.label;
        item[state.focusedColumnProp] = null;
      } else {
        item[`${state.focusedColumnProp}Name`] = s.label;
        item[state.focusedColumnProp] = hour.value;
      }

      state.focusedColumnProp = null;
      state.focusedRowIndex = -1;
      state.hourStrategyDialogFlag = false;
      item.changed = true;
      setSelectionText(item);
    },
    refreshRateMutation(state, value) {
      state.refreshVal = value;
    },
    resetRefreshTimerMutation(state) {
      state.resetRefreshBool = !state.resetRefreshBool;
    },
  },
};
