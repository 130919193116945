import Vue from 'vue';
import utils from '@/utils';
import moment from 'moment';
import qs from 'qs';
import {
  clone, HEColumns, has, cloneDeep, HERows,
} from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import {
  TOOLS_API, IDENTITY_API, ISO_REF_API, STRUCTURES_API, CISO_INTERNAL_API, CISO_METER_API,
} from '@/api';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import { createMutations } from '@/utils/vuexHelper';
import LOOKUP_STORE from '@/store/lookupStore';
import caisoStore from '@/utils/caiso/caisoUtils';

const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const cellDate = moment(dateAsString, 'YYYY-MM-DD HH:mm').toDate();
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

const roundValue = (value) => Math.round(value * 100) / 100;

const state = {
  dataEdited: false,
  dataCache: [],
  selectedDates: dateStore.getDefaultRange().map((val) => val.toDate()),
  sourceSystems: [],
  scs: [],
  locationList: [],
  resourceList: [],
  selectedSourceSystem: undefined,
  selectedSc: undefined,
  selectedResource: undefined,
  tableData: [],
  tableConfig: {
    columns: [{
      label: 'Date',
      prop: 'tradingDate',
      dataType: 'date',
      alignment: 'left',
      editable: false,
      sortable: true,
      filterable: true,

    }, {
      label: 'Entity',
      prop: 'entity',
      alignment: 'left',
      editable: false,
      sortable: true,
      filterable: true,
    }, {
      label: 'Location',
      prop: 'resource',
      alignment: 'left',
      editable: false,
      sortable: true,
      filterable: true,
    }, {
      label: 'System',
      prop: 'sourceSystem',
      alignment: 'left',
      filterable: true,
      editable: false,
      sortable: true,
    }, {
      label: 'Type',
      prop: 'measurementType',
      alignment: 'left',
      filterable: true,
      editable: false,
      sortable: true,
    }, ...HEColumns({
      editable: true,
      fixed: 'right',
      dataType: 'number',
      decimalFormat: '#,##0.00',
      width: '60px',
    }, true, undefined),
    {
      prop: 'he25',
      label: 'HE25',
      fixed: 'right',
      dataType: 'number',
      width: '60px',
      editable: true,
    }, {
      prop: 'total',
      label: 'TOTAL',
      alignment: 'right',
      fixed: 'right',
      cssClass: 'bold',
      editable: false,
      calculateCellValue: (e) => roundValue(Object.keys(e)
        .filter((key) => key.includes('he') && !key.includes('ToolTip'))
        .reduce((acc, curr) => acc + (e[curr] || 0), 0)),
    }],
    summary: [
      { prop: 'total', summaryType: 'sum', alignment: 'right' },
      ...HEColumns({ summaryType: 'sum', alignment: 'right' }, false, undefined),
      {
        prop: 'he25', summaryType: 'sum', alignment: 'right', visible: true,
      },
    ],
    options: {
      exportExcel: true,
      filterHeader: true,
      summaryRows: true,
      cellSelection: true,
    },
  },
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
  resources: (state) => [...new Set(LOOKUP_STORE.state.locationList
    .filter(({ activeFlag, marketName, entityName }) => activeFlag && entityName === state.selectedEntity)
    .map(({ shortName }) => shortName)
    .sort())],
  changes: (state) => state.tableData.filter((x) => x.edits.length),
};

const actions = {
  async initialize({ dispatch, state }) {
    dispatch('LOOKUP_STORE/fetchLocationList');
    await dispatch('fetchScs');
    await dispatch('fetchReferenceData');
    await dispatch('fetchSourceSystems');
    await dispatch('fetchData');
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationList'],
      market: 'CAISO',
      commodity: 'POWER',
      activeFlag: 1,
      showSubTypes: true,
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    commit('setLocationList', REFERENCE_DATA_STORE.state.locationList);
  },
  async fetchSourceSystems({ state, dispatch, commit }) {
    try {
      const { data } = await CISO_INTERNAL_API.get('/meter/mapping');
      const distinct = (value, index, self) => self.indexOf(value) === index;
      const meterSourceSystems = data?.data?.map((x) => x.sourceSystem).filter(distinct).sort();
      commit('setSourceSystems', meterSourceSystems);
      return meterSourceSystems.map(({ sourceSystem }) => ({ value: sourceSystem, label: sourceSystem }));
    } catch (error) {
      vue.$notify('Failed to load Meter Source Systems', 'error');
      console.error(error);
    }
  },
  async fetchData({ state, dispatch, commit }) {
    try {
      commit('setTableData', []);

      const tz = dateStore.getTimeZone();
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.selectedDates, tz);
      const start = momentRange[0].startOf('day').utc();
      const end = momentRange[1].startOf('day').utc();

      const params = {
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        sourceSystem: state.selectedSourceSystem || [],
        entity: state.selectedSc || [],
        intervalLength: 60, // Pass interval length to only query data that is hourly
      };

      const { data } = await CISO_INTERNAL_API.get(
        '/meter/byresource', {
          params,
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
        });

      const pivotData = data.reduce((acc, interval) => {
        const key = {
          key: `${interval.entity} ${interval.resource} ${interval.sourceSystem} ${interval.measurementType} ${interval.tradingDate}`,
          date: interval.tradingDate,
          sourceSystem: interval.sourceSystem,
          entity: interval.entity,
          resource: interval.resource,
          intervalLength: 60,
          measurementType: interval.measurementType,
        };
        const item = acc.find((x) => x.key.key === key.key);
        if (!item) {
          const t = { ...interval, key };
          delete t.endTimeUtc;
          delete t.intervalLength;
          delete t.startTimeUtc;
          delete t.tradingInterval;
          t[`he${(`0${t.tradingHour}`).slice(-2)}`] = roundValue(t.overrideValue ?? t.value);
          t[`he${(`0${t.tradingHour}`).slice(-2)}ToolTip`] = {
            overrideValue: roundValue(t.overrideValue),
            value: roundValue(t.value),
            overrideCreatedBy: t.overrideCreatedBy,
            overrideCreatedDate: t.overrideCreatedDate,
          };
          delete t.tradingHour;
          delete t.value;
          delete t.overrideValue;

          acc.push(t);
        } else {
          const heString = `he${(`0${interval.tradingHour}`).slice(-2)}`;
          const heToolTipString = `${heString}ToolTip`;
          if (!has(item, heString)) item[heString] = interval.overrideValue ?? interval.value;
          else item[heString] += interval.overrideValue ?? interval.value;
          if (!has(item, heToolTipString)) {
            item[heToolTipString] = {
              value: interval.value,
              overrideValue: interval.overrideValue,
              overrideCreatedBy: interval.overrideCreatedBy,
              overrideCreatedDate: interval.overrideCreatedDate,
            };
          } else {
            item[heToolTipString].value += interval.value;
            item[heToolTipString].overrideValue += interval.overrideValue;
            item[heToolTipString].overrideValue = interval.overrideCreatedBy;
            item[heToolTipString].overrideValue = interval.overrideCreatedDate;
          }
          item[heString] = roundValue(item[heString]);
        }
        return acc;
      }, []);

      pivotData.forEach((data) => {
        data.tradingDate = dateStore.toMomentFromStringWithFormat(data.tradingDate, 'YYYY-MM-DD');
      });

      commit('setTableData', pivotData);
    } catch (error) {
      vue.$notify('Failed to load Meter Data', 'error');
      console.error(error);
    }
  },
  defaultUserSettings({ state, commit, dispatch }) {
    // const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
    //   .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    // if (userLocationGroup) {
    //   commit('setSelectedLocationGroupId', userLocationGroup.id);
    //   commit('setSelectedLocationGroup', userLocationGroup.shortName);
    //   dispatch('fetchLocationGroupLocations', userLocationGroup.id);
    // }

    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    if (!state.selectedSc) commit('setSelectedSc', [selectedSc]);
  },
  async saveBatchData({
    state, getters, dispatch, commit,
  }, event) {
    if (event.changes && event.changes?.length) {
      const payload = [];

      await Promise.all(event.changes.map(async (change) => {
        const { key, data } = change;
        const updates = {};
        if (data) {
          const hours = HERows({}, true, moment(key.date));
          const intervals = Object.keys(data).filter((prop) => prop.startsWith('he')).map((prop) => {
            let hourKey = `${parseInt(prop.replace('he', ''), 10)}`;
            if (prop.replace('he', '') === '2*') hourKey = '2*';
            const hour = hours.find((x) => x.he === hourKey);
            const { startTime, endTime } = hour;
            const value = data[prop] ? Math.abs(data[prop]) : data[prop];
            return {
              sourceSystem: key.sourceSystem,
              entity: key.entity,
              resource: key.resource,
              intervalLength: key.intervalLength,
              measurementType: key.measurementType,
              tradingDate: key.date,
              tradingHour: parseInt(hourKey, 10),
              tradingInterval: 0,
              // startTime,
              // endTime,
              value,
            };
          });
          payload.push(...intervals);
        }
      }));
      try {
        await CISO_INTERNAL_API.post('/meter/override', payload);
        await dispatch('fetchData');
        return ({ type: 'success', msg: 'Save success' });
      } catch (e) {
        console.error('error saving data', e);
        return ({ type: 'error', msg: 'Error saving data' });
      }
    }
    return null;
  },
};

const mutations = {
  ...createMutations(
    'tableData',
    'selectedDates',
    'sourceSystems',
    'selectedSourceSystem',
    'locationList',
    'scs',
    'selectedSc',
    'dataEdited',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules: { LOOKUP_STORE, REFERENCE_DATA_STORE },
  actions,
};
