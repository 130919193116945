import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API, IDENTITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  tableKey: -999,
  currentTableRow: null,
  entitiesNames: [],
  entitiesTypes: [],
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'entityName', label: 'Entity Name', sortable: true, filterable: true,
    }, {
      prop: 'entityType', label: 'Entity Type', sortable: true, filterable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true, filterable: true,
    }, {
      prop: 'fullName', label: 'Full Name', sortable: true, filterable: true,
    }, {
      prop: 'locationSubtypes',
      label: '# of Subtypes',
      dataType: 'number',
      sortable: true,
      filterable: true,
      calculateCellValue: (val) => val.locationSubtypes?.length,
    }, {
      prop: 'parameters',
      label: '# of Params',
      dataType: 'number',
      sortable: true,
      filterable: true,
      calculateCellValue: (val) => val.parameters?.length,
    }, {
      prop: 'commodityName', label: 'Commodity', sortable: true, filterable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true, filterable: true,
    }, {
      prop: 'locationType', label: 'Type', sortable: true, filterable: true,
    }, {
      prop: 'city', label: 'City', sortable: true, filterable: true,
    }, {
      prop: 'county', label: 'County', sortable: true, filterable: true,
    }, {
      prop: 'state', label: 'State', sortable: true, filterable: true,
    }, {
      prop: 'country', label: 'Country', sortable: true, filterable: true,
    }, {
      prop: 'longitude', label: 'Longitude', sortable: true, filterable: true,
    }, {
      prop: 'latitude', label: 'Latitude', sortable: true, filterable: true,
    }, {
      prop: 'externalRef', label: 'External Ref', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime', filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  subtypeConfig: {
    columns: [{
      prop: 'id', label: 'ID', filterable: true,
    }, {
      prop: 'shortName', label: 'Name', filterable: true,
    }, {
      prop: 'fullName', label: 'Full Name', filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'date', filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'date', filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  parameterConfig: {
    columns: [{
      prop: 'id', label: 'ID', filterable: true,
    }, {
      prop: 'name', label: 'Name', filterable: true,
    }, {
      prop: 'valueType', label: 'Type', filterable: true,
    }, {
      prop: 'value', label: 'Value', filterable: true,
    }, {
      prop: 'subtype', label: 'Subtype', filterable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', dataType: 'date', filterable: true,
    }, {
      prop: 'terminationDate', label: 'Termination Date', dataType: 'date', filterable: true,
    // }, {
    //   prop: 'inactivatedDate', label: 'Inactivated Date', dataType: 'date', filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'date', filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'date', filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: null,
    entityName: null,
    entityType: null,
    versionId: null,
    shortName: null,
    fullName: null,
    marketId: null,
    commodityName: null,
    marketName: null,
    locationType: null,
    locationSubtypes: [],
    locationParameters: [],
    city: null,
    county: null,
    state: null,
    country: null,
    longitude: null,
    latitude: null,
    externalRef: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
  getEnergyCommodityList: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
  getCommonLocationList: (state, localGetters, rootState) => rootState.lookup.commonLocationList,
  getLocationTypeList: (state, localGetters, rootState) => rootState.lookup.locationTypeList,
  locationSubtypes: (state) => state.currentTableRow?.locationSubtypes ?? [],
  
  locationParameters: (state) => { 
    const subtypes = state.currentTableRow?.locationSubtypes ?? [];
    const params = [];

    if (state.currentTableRow?.parameters) {
      params.push.apply(params, state.currentTableRow?.parameters);
    }
    
    // add parameters from subtypes
    if (subtypes) {
      subtypes.forEach((subtype) => {
        if (subtype.parameters) {
          params.push.apply(params, subtype.parameters);
        }
      });
    }

    if (params) {
      params.forEach((param) => {
        if (param.locationSubTypeId) {
          param.subtype = subtypes.find((x) => x.id === param.locationSubTypeId)?.shortName;
        }
      });
    }

    return params;
  },
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    
    await Promise.all([
      dispatch('fetchEntityType'),
      dispatch('fetchEntityName'),
      dispatch('lookup/fetchMarketList', null, { root: true }),
      dispatch('lookup/fetchEnergyCommodityList', null, { root: true }),
      dispatch('lookup/fetchLocationTypeList', null, { root: true }),
      dispatch('fetchCommonLocations'),
    ])
    .then((result) => {
      dispatch('loadTableData');
    });
  },
  async fetchCommonLocations({ dispatch }) {
    await dispatch('lookup/fetchCommonLocationList', null, { root: true });
  },
  async fetchEntityType({ commit }) {
    try {
      const { data: { entityTypes } } = await IDENTITY_API.get('/entityTypes');
      commit('setEntitiesTypes', entityTypes);
    } catch (error) {
      vue.$notify('Failed to load entities type', 'error');
      console.log(error);
    }
  },
  async fetchEntityName({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('/entities');
      commit('setEntitiesNames', entities);
    } catch (error) {
      vue.$notify('Failed to load entities name', 'error');
      console.log(error);
    }
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('/locations?ShowSubtypes=true');
      commit('setTableData', data.locations);
      this.$notify(`${data.locations.length} Location(s) Loaded`, 'info');
    } catch (error) {
      this.$notify('Failed to Load Locations', 'error');
      console.error(error);
    }
  },
  async postLocation({ dispatch, state, getters }, location) {
    // map market and commodity based on market id
    const market = getters.getMarketList.find((m) => m.id === location.marketId);
    if (market) {
      location.marketName = market.shortName;
      location.commodityName = market.commodityName;
    }
    try {
      const { data: { data } } = await STRUCTURES_API.post('/locations', location);
      state.tableData.push(data);
      this.$notify('Location Added', 'success');
      
      await dispatch('fetchCommonLocations');

    } catch (error) {
      this.$notify('Failed to Add Location', 'error');
      console.error(error);
    }
  },
  async updateLocation({ dispatch, commit }, location) {
    try {
      const { data: { data } } = await STRUCTURES_API.put(`/locations/${location.id}`, location);
      commit('updateLocation', data);
      this.$notify('Location Updated', 'success');
      
      await dispatch('fetchCommonLocations');
      
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Update Location', 'error');
    }
  },
  async deleteTableRow({ dispatch, commit, state }) {
    try {
      await STRUCTURES_API.delete(`/locations/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('setCurrentTableRow', clone(state.nullTableRow));
      this.$notify('Location Removed', 'success');
      
      await dispatch('fetchCommonLocations');
      
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Remove Location', 'error');
    }
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
};

const mutations = {
  updateLocation(state, location) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === location.id);
    state.tableData.splice(rowIndex, 1, location);
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  createTableRow(state) {
    const location = clone(state.nullTableRow);
    location.id = state.tableKey++;
    state.currentTableRow = location;
  },
  copyTableRow(state) {
    const location = clone(state.currentTableRow);
    location.id = state.tableKey++;
    state.currentTableRow = location;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((location) => location.id !== state.currentTableRow.id);
  },
  ...createMutations(
    'entitiesNames',
    'entitiesTypes',
    'currentTableRow',
    'tableData',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};