import { CISO_SAMC_API } from '@/api';
import { has } from '@/utils/dataUtil';
import config from './EIMAllocationDeterminantDetailsConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  queryParams: [],
  showRowTotals: false,
  compareFlag: false,
};

const actions = {
  async fetchDeterminantDetails({ commit, state }, params) {
    commit('setTableData', []);
    try {
      commit('setQueryParams', params);
      const { data } = await CISO_SAMC_API.get('/eim-sub-allocations/determinant-details', {
        headers: { 'api-version': '2.0' },
        params,
      });

      const hasCompareFileType = has(state.queryParams, 'compareFileType');
      if (hasCompareFileType) commit('setShowRowTotals', true); commit('setCompareFlag', true);
      if (!hasCompareFileType) commit('setCompareFlag', false);
      if (data && data.data) {
        state.tableConfig.forEach((field) => {
          if (!hasCompareFileType && field.dataField === 'cmp') {
            field.area = 'filter';
          }
        });

        const flatArray = [];
        data.data.forEach((head) => {
          const intervalData = head.data || [];

          intervalData.forEach((i) => {
            const newObj = {};
            Object.keys(i).forEach((key) => {
              if (!Array.isArray(i[key])) { newObj[key] = i[key]; }
            });
            Object.keys(head).forEach((key) => {
              if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
            });
            flatArray.push(newObj);
          });
        });

        commit('setTableData', flatArray);
      }
    } catch (error) {
      this.$notify('Failed to load Determinant Details', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  reset(state) {
    state.tableData = [];
    state.tableConfig = [];
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setQueryParams(state, value) {
    state.queryParams = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};