import { accessRight, recs } from '@/auth/permission';

export default {
  path: '/rec-management',
  meta: {
    visible: true,
    requiresAuth: true,
    text: 'REC MANAGEMENT',
    permission: { name: recs.ui, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [
    {
      path: 'accounts',
      name: 'accounts',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Accounts',
        permission: { name: recs.accounts, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/RecsManagement/Accounts/Accounts'),
    }, {
      path: 'eligibilities',
      name: 'eligibilities',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Eligibility',
        permission: { name: recs.eligibility, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/RecsManagement/Eligibility/Eligibilities'),
    }, {
      path: 'generations',
      name: 'generations',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Generators',
        permission: { name: recs.generation, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/RecsManagement/Generation/Generations'),
    }, {
      path: 'organizations',
      name: 'organizations',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Organization',
        permission: { name: recs.organization, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/RecsManagement/Organization/Organizations'),
    }, {
      path: 'transactions',
      name: 'transactions',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Transactions',
        permission: { name: recs.transaction, rights: [accessRight.read, accessRight.write] },
      },
      component: () => import('@/components/RecsManagement/Transaction/Transactions'),
    }
  ],
};
