import { clone, deepClone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, editable: false, filterable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'commodityName', label: 'Commodity', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'groupType', label: 'Group Type', sortable: true, visible: true, editable: true, filterable: true,
    }, {
      prop: 'activeFlag', label: 'Active Flag', sortable: true, visible: false, editable: true, filterable: true,
    }, {
      prop: 'inactivatedDate', label: 'InactivatedDate', dataType: 'datetime', sortable: true, visible: false, editable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, visible: true, editable: false, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime', sortable: true, visible: true, editable: false, filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, visible: true, editable: false, filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'datetime', sortable: true, visible: true, editable: false, filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    marketId: -999,
    shortName: null,
    description: null,
    groupType: null,
    activeFlag: null,
    inactivatedDate: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  locationTableKey: -999,
  currentLocationTableRow: null,
  locationTableData: [],
  locationTableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, visible: false, filterable: true,
    }, {
      prop: 'locationGroupId', label: 'Location Group ID', sortable: true, visible: false, filterable: true,
    }, {
      prop: 'locationId', label: 'Location ID', sortable: true, visible: false, filterable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'fullName', label: 'Full Name', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'marketId', label: 'Market ID', sortable: true, visible: false, filterable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'entityName', label: 'Entity Name', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'entityType', label: 'Entity Type', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'commodityName', label: 'Commodity', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'locationTypeId', label: 'Location Type ID', sortable: true, visible: false, filterable: true,
    }, {
      prop: 'locationType', label: 'Location Type', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'city', label: 'City', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'state', label: 'State', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'county', label: 'County', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'country', label: 'Country', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'longitude', label: 'Longitude', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'latitude', label: 'Latitude', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'externalRef', label: 'External Ref', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, visible: true, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', visible: true, filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullLocationTableRow: {
    id: -999,
    locationGroupId: -999,
    locationId: -999,
    shortName: null,
    fullName: null,
    marketId: -999,
    marketName: null,
    commodityName: null,
    locationTypeId: -999,
    locationType: null,
    city: null,
    state: null,
    county: null,
    country: null,
    longitude: null,
    latitude: null,
    activeFlag: null,
    externalRef: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  filteredLocations: [],
  locationGroups: [],
  dialogMode: '', // ADD or EDIT
  locationsFilter: [],
};

const getters = {
  getLocationGroups: (state) => state.locationGroups,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('lookup/fetchLocationList', null, { root: true });
    await dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('loadTableData');
  },
  async loadLocationGroups({ commit }) {
    try {
      const { data: { locationGroups } } = await STRUCTURES_API.get('/location-groups');
      commit('setLocationGroups', locationGroups);
    } catch (error) {
      this.$notify('Failed to Load Location Groups', 'error');
    }
  },
  async loadTableData({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/location-groups');
      commit('loadTableData', data.locationGroups);
    } catch (error) {
      this.$notify('Failed to Load Location Groups', 'error');
    }
  },
  async loadLocationTableData({
    dispatch, commit, state,
  }) {
    try {
      const { data } = await STRUCTURES_API.get(`/location-groups/${state.currentTableRow.id}/locations`);
      commit('loadLocationTableData', data.locationGroupLocations);
      commit('setFilteredLocations', state.filteredLocations);
      if (data.locationGroupLocations.length > 0) {
        data.locationGroupLocations.forEach((x) => {
          dispatch('removeFromFilteredLocations', x);
        });
      }
    } catch (error) {
      this.$notify('Failed to Load Locations', 'error');
    }
  },
  async addLocationGroup({ commit, dispatch }, locationGroup) {
    try {
      const { data } = await STRUCTURES_API.post('/location-groups', locationGroup);
      commit('addToTableData', data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Location Group Added', 'success');
    } catch (error) {
      this.$notify('Failed to Add Location Group', 'error');
    }
  },
  async addLocationToLocationGroup({ commit, dispatch }, addLocationToGroupRequest) {
    const { locationGroupId } = addLocationToGroupRequest;
    const { locationId } = addLocationToGroupRequest;
    try {
      const { data } = await STRUCTURES_API.post(`/location-groups/${locationGroupId}/locations/${locationId}`);
      commit('addToLocationTableData', data);
      commit('currentLocationTableRowChange', clone(state.nullTableRow));
      dispatch('removeFromFilteredLocations', state.filteredLocations.find((x) => x.id === addLocationToGroupRequest.locationId));
      this.$notify('Location Added to Group', 'success');
    } catch (error) {
      this.$notify('Failed to Add Location to Group', 'error');
    }
  },
  async updateLocationGroup({ commit, dispatch }, locationGroup) {
    try {
      const { data } = await STRUCTURES_API.put(`/location-groups/${locationGroup.id}`, locationGroup);
      commit('updateTableData', data);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Location Group Updated', 'success');
    } catch (error) {
      this.$notify('Failed to Update Location Group', 'error');
    }
  },
  async inactivateLocationGroup({ dispatch, commit }, id) {
    try {
      await STRUCTURES_API.delete(`/location-groups/${id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Location Group Removed', 'success');
    } catch (error) {
      this.$notify('Failed to Remove Location Group', 'error');
    }
  },
  async removeLocationFromGroup({ commit }, removeLocationToGroupRequest) {
    const { locationGroupId } = removeLocationToGroupRequest;
    const { locationId } = removeLocationToGroupRequest;
    try {
      await STRUCTURES_API.delete(`/location-groups/${locationGroupId}/locations/${locationId}`);
      commit('addToLocationsFilterData');
      commit('deleteLocationTableRow');
      commit('currentLocationTableRowChange', clone(state.nullLocationTableRow));
      this.$notify('Location Removed from Group', 'success');
    } catch (error) {
      this.$notify('Failed to Remove Location from Group', 'error');
    }
  },
  currentTableRowChange({ dispatch, commit, rootState }, currentRow) {
    commit('currentTableRowChange', currentRow);
    const filteredLocations = rootState.lookup.locationList
      .filter((location) => location.marketName === currentRow.marketName
        && location.commodityName === currentRow.commodityName);
    commit('setMainFilteredLocations', filteredLocations);
    commit('resetLocationTable');

    // only load locations if a location group is selected
    if (currentRow.id > 0) {
      dispatch('loadLocationTableData');
    }
  },

  removeFromFilteredLocations({ commit, state }, location) {
    const locationsToFilter = deepClone(state.locationsFilter);
    const index = locationsToFilter.findIndex((x) => x.fullName === location.fullName);
    if (index >= 0) {
      locationsToFilter.splice(index, 1);
    }
    commit('setFilteredLocations', locationsToFilter);
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  currentLocationTableRowChange(state, currentLocationRow) {
    state.currentLocationTableRow = currentLocationRow;
  },
  resetLocationTable(state) {
    state.locationTableData = [];
    state.currentLocationTableRow = clone(state.nullLocationTableRow);
  },
  setMainFilteredLocations(state, data) {
    state.filteredLocations = data;
  },
  setFilteredLocations(state, data) {
    state.locationsFilter = data;
  },
  loadTableData(state, locationGroups) {
    state.tableData = locationGroups;
  },
  addToTableData(state, data) {
    state.tableData.push(data);
  },
  updateTableData(state, data) {
    const index = state.tableData.findIndex(({ id }) => id === data.id);
    if (index !== -1) state.tableData.splice(index, 1, data);
  },
  loadLocationTableData(state, locations) {
    state.locationTableData = locations;
  },
  addToLocationTableData(state, data) {
    state.locationTableData.push(data);
  },
  addToLocationsFilterData(state) {
    const location = state.filteredLocations.find((x) => x.fullName === state.currentLocationTableRow.fullName);
    if (location) {
      state.locationsFilter.push(location);
    }
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((locationGroup) => locationGroup.id !== state.currentTableRow.id);
  },
  deleteLocationTableRow(state) {
    state.locationTableData = state.locationTableData
      .filter((location) => location.id !== state.currentLocationTableRow.id);
  },
  reset(state) {
    state.currentTableRow = null;
    state.tableData = [];
    state.currentLocationTableRow = null;
    state.locationTableData = [];
  },
  setDialogMode(state, mode) {
    state.dialogMode = mode;
  },
  setLocationGroups(state, list) {
    if (list) {
      state.locationGroups = list.map((x) => ({ label: x.shortName, value: x.id }));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};