import { sortBy } from '@/utils/dataUtil';
import { ETRM_API, STRUCTURES_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';

const state = {
  tableConfig: {
    columns: [{
      prop: 'commodity',
      label: 'Commodity',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'companyId', 
      label: 'Company ID', 
      sortable: true, 
      filterable: true, 
      visible: false, 
      alignment: 'left', 
    }, {
      prop: 'companyName', 
      label: 'Company', 
      sortable: true, 
      filterable: true, 
      alignment: 'left',
    }, {
      prop: 'counterParty',
      label: 'Counter Party',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'objectType',
      label: 'Object Type',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'objectReference',
      alignment: 'left',
      label: 'Object Ref.',
      filterable: true,
      sortable: true,
    }, {
      prop: 'type',
      label: 'Type',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'subType',
      label: 'SubType',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'variant',
      label: 'Variant',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'discountValue',
      label: 'Discount Value',
      alignment: 'right',
    }, {
      prop: 'currentValue',
      label: 'Current Value',
      alignment: 'right',
      sortable: true,
    }, {
      prop: 'previousValue',
      label: 'Previous Value',
      alignment: 'right',
      sortable: true,
    }, {
      prop: 'differenceValue',
      label: 'Diff. Value',
      alignment: 'right',
      sortable: true,
    }, {
      prop: 'note',
      label: 'Note',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'status',
      label: 'Status',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'statusTime',
      label: 'Status Time',
      alignment: 'left',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
    }, {
      prop: 'statusUser',
      label: 'Status User',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'createdBy',
      label: 'Created By',
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      alignment: 'left',
      dataType: 'datetime',
      sortable: true,
    }, {
      prop: 'effectiveTime',
      label: 'Eff. Time',
      alignment: 'left',
      sortOrder: 'desc',
      dataType: 'datetime',
      filterable: true,
      sortable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  tableData: [],
  variants: [],
  dateRange: dateStore.getDefaultRange(),
};

const getters = {
  getCommodities: (state, localGetters, rootState) => rootState.lookup.energyCommodityList,
  getCompanies: (state, localGetters, rootState) => rootState.lookup.companyList.filter(({ internalFlag }) => internalFlag),
  getCounterparties: (state, localGetters, rootState) => rootState.lookup.companyList.filter(({ internalFlag }) => !internalFlag),
  getVariants: (state, localGetters, rootState) => state.variants,
};

const actions = {
  async fetchLookupData({ dispatch }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('lookup/fetchCompanyList', null, { root: true });
    await dispatch('fetchVariants');
  },
  async fetchTransactionLogs({ commit, state }) {
    try {
      const params = {
        startDate: state.dateRange[0].toISOString(),
        endDate: state.dateRange[1].toISOString(),
      };
      const { data } = await ETRM_API.get('transaction-logs', { params });
      commit('setTableData', data.data);
    } catch (error) {
      if (error.response) console.error(error.response);
      else console.error(error);
    }
  },
  async addTransactionLog({ commit }, item) {
    try {
      const { data } = await ETRM_API.post('transaction-logs', item);
      commit('addRow', data);
    } catch (err) {
      if (err.response) console.log(err.response);
      else console.error(err);
      throw Error('Failed to add transaction log');
    }
  },
  async fetchVariants({ commit, rootGetters }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('/variant');

      const variants = data
        .sort(sortBy('name'))
        .map((p) => ({ value: p.name, label: p.name }));

      commit('setVariants', variants);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = data;
  },
  addRow(state, data) {
    state.tableData.push(data);
  },
  setVariants(state, data) {
    state.variants = data;
  },
  ...createMutations(
    'dateRange',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
