import { ETAG_API } from '../../api';
import dateStore from '../../utils/dateStore';
import moment from 'moment'

const state = {
    dialogVisibility: false,
    templateName: null,
    dateRange: [],
    templateSummaries: [],
    template: null,
    selectedSegment: null,
    volumes: [],
};

const getters = {
    getDialogVisibility: (state) => state.dialogVisibility,
    getDateRange: (state) => state.dateRange,
    getSelectedTemplateName: (state) => state.templateName,
    getTemplateSummaries: (state) => state.templateSummaries,
    getSelectedSegment: (state) => state.selectedSegment,
    getDateRange: function(state){
        return [moment("2018-09-04"), moment("2018-09-04")]
    },
    getVolumes: function(state){
        return [{
            day: "2018-09-04",
            he1: 50,
            he2: 50,
            he3: 50, 
            he4: 50, 
            he5: 50, 
            he6: 50, 
            he7: 50, 
            he8: 50, 
            he9: 50, 
            he10: 50, 
            he11: 50, 
            he12: 50, 
            he13: 50, 
            he14: 50, 
            he15: 50, 
            he16: 50, 
            he17: 50, 
            he18: 50, 
            he19: 50, 
            he20: 50, 
            he21: 50, 
            he22: 50, 
            he23: 50, 
            he24: 50, 
        }];
    },
    getSegments: function(state){
        let segments = [];
        let segmentId = 0;
        if (state.template){
            for (let i = 0; i < state.template.segmentList.length; i++){
                let segment = state.template.segmentList[i];
                for(let j = 0; j < segment.physicalSegmentList.length; j++){
                    let physicalSegment = segment.physicalSegmentList[j];
                    if (i === 0 && j === 0){
                        segments.push({value: segmentId, label: physicalSegment.pod});
                        segmentId++;
                    }
                    else if (i === state.template.segmentList.length - 1 && j === segment.physicalSegmentList.length - 1){
                        segments.push({value:segmentId, label: physicalSegment.por});
                    }
                    else {
                        for (let k = 0; k < physicalSegment.transmissionAllocationList.length; k++){
                            let transmission = physicalSegment.transmissionAllocationList[k];
                            segments.push({value: segmentId, label: `${physicalSegment.por} -> ${physicalSegment.pod} (${transmission.contractNumber})`});
                            segmentId++;
                        }
                    }
                }
            }
        }
        return segments;
    }
};

const actions = {
    initialize({commit, dispatch}){
        commit('initialize');
        dispatch('loadTemplateSummaries');
    },
    changeVolume({commit}, item){
        commit('setVolume', item);
    },
    changeDateRange({commit}, item){
        commit('setDateRange', item);
    },
    loadTemplateSummaries({commit}){
        ETAG_API.get('tags/variants/TEMPLATE')
        .then(function(response){      
            commit('setTemplateSummaries', response.data.tagSummaries);
        })
        .catch(function(error){
            console.log(error);
        });
    },
    loadSelectedTemplate({commit}){
        ETAG_API.get(`tags/variants/TEMPLATE/${state.templateName}`)
        .then(function(response){      
            commit('setTemplate', response.data);
        })
        .catch(function(error){
            console.log(error);
        });
    },
    changeDialogVisibility({commit}){
        commit('setDialogVisibility');
    },
    changeTemplateName({commit, dispatch}, item){
        commit('setTemplateName', item);
        dispatch('loadSelectedTemplate');
    },
    changeSelectedSegment({commit}, item){
        commit('setSelectedSegment', item);
    },
    createTag({state, dispatch}, item){
        item.router.push({name: 'NewTagFromDeal', params:{ templateName: state.templateName, segment: state.selectedSegment, dateRange: state.dateRange, volume: state.volumes }});
        dispatch('reset');
    },
    reset({commit}){
        commit('reset');
    }
};

const mutations = {
    initialize(state){
        state.dateRange = dateStore.getDefaultRange();
    },
    setDialogVisibility(state){
        state.dialogVisibility = !state.dialogVisibility;
    },
    setTemplateName(state, item){
        state.templateName = item;
    },
    setTemplateSummaries(state, item){
        state.templateSummaries = item;
    },
    setDateRange(state, item){
        state.dateRange = item;
    },
    setTemplate(state, item){
        state.template = item;
    },
    setSelectedSegment(state, item){
        state.selectedSegment = item;
    },
    setVolume(state, item){
        state.volumes = item;
    },
    reset(state){
        state.dialogVisibility = false;
        state.templateName = null;
        state.dateRange = [];
        state.template = null;
        state.selectedSegment = null;
    }
};


export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
};