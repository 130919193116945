import { tradeDate } from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDate,
    type: {
      label: 'Type',
      value: 'CAPACITY',
      type: 'radio',
      options: [{ value: 'CAPACITY', label: 'Capacity' },
        { value: 'ENERGY', label: 'Energy' }],
    },
  },
  fields: [{
    caption: 'TRADING DATE',
    dataField: 'date',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TAG CODE',
    dataField: 'tagCode',
    dataType: 'string',
    area: 'row',
    expanded: true,
    visible: true,
  }, {
    caption: 'TAG ID',
    dataField: 'tagID',
    dataType: 'string',
    area: 'filter',
    expanded: true,
    visible: true,
  }, {
    caption: 'RESOURCE ID',
    dataField: 'rsrcId',
    dataType: 'string',
    area: 'row',
    expanded: true,
    width: 400,
  }, {
    caption: 'MARKET TYPE',
    dataField: 'mktType',
    dataType: 'string',
    area: 'column',
    expanded: true,
    allowSorting: false,
    sortingMethod(a, b) {
      const indexFinder = (nm) => {
        if (nm === 'DAM') return 1;
        if (nm === 'BASE') return 2;
        if (nm === 'FMM') return 3;
        if (nm === 'FMM-DAM') return 4;
        if (nm === 'FMM-BASE') return 5;
        if (nm === 'RTD' || nm === 'ATF') return 6;
        if (nm === 'ATF-FMM') return 7;
        return 8;
      };
      const indexA = indexFinder(a.value);
      const indexB = indexFinder(b.value);
      if (indexA < indexB) return -1;
      if (indexA > indexB) return 0;
      return 1;
    },
  }, {
    caption: 'SOURCE',
    dataField: 'src',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SINK',
    dataField: 'snk',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'GCA',
    dataField: 'gca',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PSE',
    dataField: 'pse',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'LCA',
    dataField: 'lca',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'he',
    dataType: 'number',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'VOLUME',
    dataField: 'vol',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    expanded: true,
    format: { type: 'fixedPoint', precision: 2 },
    calculateSummaryValue: (e) => {
      let sum = 1;
      let file = 0;
      let compare = 0;
      if (e.children('row') && e.children('row').length > 0 && e.children('row')[0]._rowPath.length > 0 && e.children('row')[0]._rowPath[0].text === 'Settlement') {
        compare = e.children('row')[0] ? e.children('row')[0]._cell[0] : 0;
        file = e.children('row')[1] ? e.children('row')[1]._cell[0] : 0;
        sum = compare - file;
        if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
        return sum;
      } if (e.children('row') && e.children('row').length > 0 && e.children('row')[0]._rowPath.length > 0 && e.children('row')[0]._rowPath[0].text === 'Tag') {
        file = e.children('row')[0] ? e.children('row')[0]._cell[0] : 0;
        compare = e.children('row')[1] ? e.children('row')[1]._cell[0] : 0;
        sum = file - compare;
        if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
        return sum;
      }

      return e.cell() && e.cell().originalCell.length > 0 ? e.cell().originalCell[0] : 0;
    },
  }],
};