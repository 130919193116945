import { BALANCING_AUTHORITY_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';

const nullConfig = {
  outOfService: null,
  adder: null,
  pmax: null,
  startHour: null,
  endHour: null,
  tokens: [],
};

const validationRules = [{
  type: 'custom',
  message: 'Value Invalid',
  validationCallback: (event) => {
    if (event.data && Object.keys(event.data).length) {
      const column = event.column.dataField;
      const { startHour, endHour, adder } = event.data.configuration;

      if ((!startHour || !endHour) && startHour !== 0) {
        event.validator._validationRules[0].message = 'Start Hour and End Hour are required';
        return false;
      }

      if (column === 'startHour') {
        if (startHour < 0 || startHour > 23) {
          event.validator._validationRules[0].message = 'Invalid Start Hour';
          return false;
        }
        if (endHour && (startHour >= endHour)) {
          event.validator._validationRules[0].message = 'Start Hour must be less than End Hour';
          return false;
        }
        return true;
      }
      if (column === 'endHour') {
        if (endHour < 1 || endHour > 24) {
          event.validator._validationRules[0].message = 'Invalid End Hour';
          return false;
        }
        if (startHour && (startHour >= endHour)) {
          event.validator._validationRules[0].message = 'Start Hour must be less than End Hour';
          return false;
        }
        return true;
      }
      if (column === 'adder') {
        if (!adder && adder !== 0) {
          event.validator._validationRules[0].message = 'Adder can not be null';
          return false;
        }
      }
    }
    return true;
  },
}];

const state = {
  tableData: undefined,
  tableConfiguration: {
    name: 'eventLog',
    columns: [{
      label: 'BA Source',
      prop: 'baSource',
      filterable: true,
      sortable: true,
      editable: false,
      alignment: 'left',
    }, {
      label: 'EIR Source',
      prop: 'eirSource',
      filterable: true,
      sortable: true,
      editable: false,
      alignment: 'left',
    }, {
      label: 'Max',
      prop: 'pmax',
      filterable: false,
      sortable: true,
      dataType: 'number',
      alignment: 'left',
      editable: false,
      calculateCellValue: (e) => e.configuration?.pmax,
    }, {
      label: 'Start Hour',
      prop: 'startHour',
      filterable: false,
      sortable: true,
      editable: true,
      required: true,
      alignment: 'left',
      validationRules,
      calculateCellValue: (e) => e.configuration?.startHour,
      setCellValue(newData, value, currentRowData, prop) {
        newData.configuration = { [prop]: value };
      },
    }, {
      label: 'End Hour',
      prop: 'endHour',
      filterable: false,
      sortable: true,
      editable: true,
      required: true,
      alignment: 'left',
      validationRules,
      calculateCellValue: (e) => e.configuration?.endHour,
      setCellValue(newData, value, currentRowData, prop) {
        newData.configuration = { [prop]: value };
      },
    }, {
      prop: 'outOfService',
      label: 'Out of Service',
      clearable: false,
      config: {
        format: {
          type: 'default',
        },
        type: 'select',
        required: true,
      },
      calculateCellValue: (e) => e.configuration?.outOfService,
      setCellValue(newData, value, currentRowData, prop) {
        newData.configuration = { [prop]: value };
      },
    }, {
      label: 'Adder',
      prop: 'adder',
      filterable: false,
      sortable: true,
      dataType: 'number',
      alignment: 'left',
      validationRules,
      calculateCellValue: (e) => e.configuration?.adder,
      setCellValue(newData, value, currentRowData, prop) {
        newData.configuration = { [prop]: value };
      },
    }, {
      label: 'Created By',
      prop: 'createdBy',
      alignment: 'left',
      filterable: false,
      sortable: true,
      editable: false,
    }, {
      label: 'Created Date',
      prop: 'createdDate',
      alignment: 'left',
      filterable: false,
      sortable: true,
      editable: false,
      calculateDisplayValue: (e) => moment(e.createdDate).format('MM/DD/YY HH:mm'),
    }, {
      label: 'Updated By',
      prop: 'updatedBy',
      alignment: 'left',
      filterable: false,
      sortable: true,
      editable: false,
    }, {
      label: 'Updated Date',
      prop: 'updatedDate',
      alignment: 'left',
      filterable: false,
      sortable: true,
      editable: false,
      calculateDisplayValue: (e) => moment(e.updatedDate).format('MM/DD/YY HH:mm'),
    }],
    editConfig: {
      mode: 'batch',
      allowUpdating: true,
      selectTextOnEditStart: true,
      startEditAction: 'click',
    },
    options: {
      filterHeader: true,
      multipleSelection: false,
      allowSelectAll: false,
      summaryRows: true,
    },
  },
};

const getters = {};

const actions = {
  async initialize({ state, commit, dispatch }) {
    dispatch('fetchData');
  },
  async fetchData({ state, commit }) {
    try {
      const { data } = await BALANCING_AUTHORITY_API.get('sources');
      commit('setTableData', data.data);
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch Balancing Sources', 'error');
    }
  },
  async saveBatchData({ state }, { changes }) {
    const updates = [];
    if (changes.length) {
      changes.forEach((change) => {
        const original = state.tableData.find((d) => d.id === change.key);
        if (!original.configuration) original.configuration = nullConfig;
        Object.keys(change.data.configuration).forEach((config) => {
          original.configuration[config] = change.data.configuration[config];
        });
        updates.push(original);
      });
      updates.forEach(async (update) => {
        const name = update.baSource;
        try {
          const { data } = await BALANCING_AUTHORITY_API.put(`sources/${name.toLowerCase()}`, update);
        } catch (error) {
          console.error(error);
          this.$notify(`Failed to save ${name} configuration`, 'error');
        }
      });
    }
  },
};

const mutations = {
  ...createMutations(
    'tableData',
  ),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
