import { tradeDateRange, balancingAreaAuthority } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [
    {
      caption: 'VALUE',
      dataType: 'number',
      dataField: 'value',
      area: 'data',
      summaryType: 'sum',
      format: '#,##0.##',
      expanded: true,
    },
    {
      caption: 'FROM BAA',
      dataField: 'froM_BAA',
      dataType: 'string',
      area: 'column',
      expanded: true,
    },
    {
      caption: 'TIE NAME',
      dataType: 'string',
      dataField: 'tiE_NAME',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'TRADING HOUR',
      dataType: 'number',
      dataField: 'tradinG_HOUR',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'TRADING INTERVAL',
      dataType: 'number',
      dataField: 'tradinG_INTERVAL',
      area: 'filter',
      expanded: true,
    },
    {
      caption: 'TRADING DATE',
      dataType: 'string',
      dataField: 'tradinG_DATE',
      format: 'yyyy-MM-dd',
      area: 'row',
      expanded: true,
    },
    {
      caption: 'MARKET TYPE',
      dataType: 'string',
      dataField: 'markeT_TYPE',
      area: 'row',
      expanded: true,
    },
    {
      caption: 'TO BAA',
      dataType: 'string',
      dataField: 'tO_BAA',
      area: 'row',
      expanded: true,
    },
    {
      caption: 'DIRECTION',
      dataType: 'string',
      dataField: 'direction',
      area: 'row',
      expanded: true,
    },
  ],
  searchFilterConfig: {
    tradeDateRange,
    fromBaa: { ...balancingAreaAuthority, label: 'From BAA' },
    toBaa: { ...balancingAreaAuthority, label: 'To BAA' },
    marketTypes: {
      label: 'Market Type',
      value: 'RTD',
      type: 'select',
      options: [
        { value: 'RTD', label: 'RTD' },
        { value: 'RTPD', label: 'RTPD' },
      ],
    },
    negativeImportValues: {
      label: 'Negative Import Values',
      value: false,
      type: 'checkbox',
    },
  },
};