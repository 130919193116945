import ppaMeterStore from './ppaMeter/ppaMeterStore';
import ppaPricesStore from './ppaPrices/ppaPricesStore';
import ppaLossesStore from './ppaLosses/ppaLossesStore';
import ppaSchedulesStore from './ppaSchedules/ppaSchedulesStore';
import ppaReservationsStore from './ppaReservations/ppaReservationsStore';
import ppaCurtailmentsStore from './ppaCurtailments/ppaCurtailmentsStore';
import ppaDeterminantDataStore from './ppaDeterminantData/ppaDeterminantDataStore';

export default {
  namespaced: true,
  modules: {
    ppaMeter: ppaMeterStore,
    ppaPrices: ppaPricesStore,
    ppaLosses: ppaLossesStore,
    ppaSchedules: ppaSchedulesStore,
    ppaReservations: ppaReservationsStore,
    ppaCurtailments: ppaCurtailmentsStore,
    ppaDeterminantData: ppaDeterminantDataStore,
  },
};