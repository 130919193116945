import { IDENTITY_API } from '@/api';

const state = {
  users: [],
  configuration: {
    columns: [
      { prop: 'userName', label: 'user name' },
      { prop: 'firstName', label: 'first name' },
      { prop: 'lastName', label: 'lastName' },
    ],
  },
};

const getters = {
  getUsers: (state) => state.users,
  getTableConfiguration: (state) => state.configuration,
};

const actions = {
  clear({ commit }) {
    commit('clear');
  },
  load({ commit }) {
    IDENTITY_API.get('users').then((response) => {
      commit('setUsers', response.data.users);
    }).catch((err) => {
      console.log(err.response);
    });
  },
};

const mutations = {
  clear(state) {
    state.users = [];
  },
  setUsers(state, items) {
    state.users = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};