import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_STLMTS_API } from '@/api';
import {
  tradeDateRange, contracts, contractSettlementsHeaders, cycles, versions, hasCompare, compareCycles, compareVersions,
} from '@/components/ContractBilling/BaseStore/contractsBase';
import config from './settlementsSummaryConfig';

const state = {
  tableData: [],
  tableConfiguration: config,
  searchParams: {},
  currentRow: null,
  searchFiltersConfig: {
    contractSettlementsHeaders,
    tradeDateRange,
    contracts,
    cycles,
    versions,
    hasCompare,
    compareCycles,
    compareVersions,
  },
};

const actions = {
  async fetchSettlementsSummary({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      contracts: parameters.contractsSelected,
      startDate,
      endDate,
      cycle: parameters.cyclesSelected,
      version: parameters.versionsSelected === 'MAX' ? -1 : parameters.versionsSelected,
      compareCycle: parameters.compareCyclesSelected,
      compareVersion: parameters.compareVersionsSelected === 'MAX' ? -1 : parameters.compareVersionsSelected,
    };

    try {
      const { data: { data } } = await BILLING_STLMTS_API.put('/statements/summary?', params);
      if (Array.isArray(data)) {
        data.forEach((row) => {
          row.tradingDate = moment.utc(row.tradingDate).format('MM/DD/YYYY');
          row.endTradingDate = moment.utc(row.endTradingDate).format('MM/DD/YYYY');
        });

        commit('setTableData', data);
      }
      state.searchParams = params;
    } catch (error) {
      console.log(error);
      this.$notify('Failed to Load Settlements Summary', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
    // Used instead of the config to access the rounding values from contracts screen
    state.tableData.forEach((value) => {
      state.tableConfiguration.columns[5].format = { type: 'currency', precision: value.amountRounding };
      state.tableConfiguration.columns[6].format = { type: 'currency', precision: value.amountRounding };
      state.tableConfiguration.columns[7].format = { type: 'currency', precision: value.amountRounding };
      state.tableConfiguration.columns[9].format = { type: 'currency', precision: value.amountRounding };
      state.tableConfiguration.columns[10].format = { type: 'currency', precision: value.amountRounding };
      state.tableConfiguration.columns[11].format = { type: 'fixedPoint', precision: value.volumeRounding };
    });
  },
  hideComparisons(state, value) {
    if (value.compareCyclesSelected != null) {
      state.tableConfiguration.columns[6].visible = false;
      state.tableConfiguration.columns[7].visible = false;
      state.tableConfiguration.columns[8].visible = true;
      state.tableConfiguration.columns[9].visible = true;
      state.tableConfiguration.columns[10].visible = true;
    } else {
      state.tableConfiguration.columns[6].visible = true;
      state.tableConfiguration.columns[7].visible = true;
      state.tableConfiguration.columns[8].visible = false;
      state.tableConfiguration.columns[9].visible = false;
      state.tableConfiguration.columns[10].visible = false;
    }
  },
  setCurrentRow(state, currentRow) {
    state.currentRow = currentRow;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};