import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_SIBR_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchTrades({ commit, dispatch }, searchArgs) {
    dispatch('reset');
    const { startDate, endDate } = getMomentDateStringFromRangeArray(searchArgs.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      sc: searchArgs.coordinatorListSelected,
      marketType: searchArgs.marketTypesSelected,
      sourceType: searchArgs.sourceTypesSelected,
    };

    try {
      const { data: { data } } = await CISO_SIBR_API.get('trades', { params });
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.stopDate = getMomentDateString(obj.stopDate);
        });
        commit('setTableData', data);
      } else {
        this.$notify('No trades found');
      }
    } catch {
      this.$notify('Failed to Load Trades', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};