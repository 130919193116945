import utils from '@/utils';
import { getMomentDateString } from '@/utils/dateUtil';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  attributeMapping: [],
  attributeTableObject: {},
  invoiceEntityList: [],
  nullTableRow: {
    id: -999,
    invoiceEntityId: null,
    invoiceEntityName: null,
    name: null,
    startDate: null,
    endDate: null,
    calcGranularity: null,
    scid: null,
    eimSub: null,
    baid: null,
    calcType: null,
    contractCategory: null,
    amountRounding: 2,
    volumeRounding: 3,
    contractGroupName: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getInvoiceEntityList: (state) => state.invoiceEntityList.map(({ id, shortName }) => ({ value: id, label: shortName })),
  getContractGroupList: (state) => state.contractGroupList.map(({ id, shortName }) => ({ value: id, label: shortName })),
  getAttributeList(state) {
    return state.attributeMapping.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('fetchAttributeData');
    dispatch('fetchInvoiceEntityList');
    dispatch('fetchContractGroupList');
  },
  async fetchContract({ commit }, args = {}) {
    const params = {
      invoiceEntities: args.invoiceEntitiesSelected,
    };
    try {
      const { data: { data } } = await BILLING_REF_API.post('/contracts/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = x.endDate === null ? null : getMomentDateString(x.endDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Contract Data', 'error');
    }
  },
  async fetchAttributeData({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/ref-attributes/Contract');
      commit('loadAttributeData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  fetchInvoiceEntityList({ commit }) {
    BILLING_REF_API.get('/invoice-entities').then(({ data }) => {
      commit('loadInvoiceEntityList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  fetchContractGroupList({ commit }) {
    BILLING_REF_API.get('/contract-groups/all-charge-groups').then(({ data }) => {
      commit('loadContractGroupList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  postRecord({ state }, Bank) {
    BILLING_REF_API.post('/contracts', Bank).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, Bank) {
    BILLING_REF_API.put(`/contracts/${Bank.id}`, Bank).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/contracts/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadAttributeData(state, records) {
    state.attributeMapping = records;
    state.attributeTableObject = records.reduce((obj, item) => Object.assign(obj, { [item.propertyName]: item }), {});
  },
  loadInvoiceEntityList(state, invoiceEntities) {
    state.invoiceEntityList = invoiceEntities;
  },
  loadContractGroupList(state, contractGroups) {
    state.contractGroupList = contractGroups;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};