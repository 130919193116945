import contractUsage from './ContractUsage/contractUsageStore';
import expectedEnergy from './ExpectedEnergy/expectedEnergyStore';
import convergenceBidAwards from './ConvergenceBidAwards/convergenceBidAwardsStore';
import marketSchedules from './MarketSchedules/marketScheduleStore';
import marketAwards from './MarketAwards/marketAwardsStore';
import resourceForecast from './ResourceForecast/resourceForecastStore';
import commitmentCost from './CommitmentCost/commitmentCostStore';
import verForecast from './VERForecast/verForecastStore';
import nonDispatchableTime from './NonDispatchableTime/nonDispatchableTimeStore';
import resourceLevelMovement from './ResourceLevelMovement/resourceLevelMovementStore';
import crr from './CRR/crrStore';

export default {
  namespaced: true,
  modules: {
    contractUsage,
    expectedEnergy,
    convergenceBidAwards,
    marketSchedules,
    marketAwards,
    resourceForecast,
    commitmentCost,
    verForecast,
    nonDispatchableTime,
    resourceLevelMovement,
    crr,
  },
};