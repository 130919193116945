import { tradeDate, oasisReportList } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    alignment: 'LEFT',
    visible: false,
  },
  searchFilterConfig: {
    tradeDate,
    oasisReportList: { ...oasisReportList, required: true },
  },
  fields: [{
    caption: 'TRADING DATE',
    dataField: 'tzTradingDate',
    area: 'row',
    expanded: true,
  }, {
    caption: 'REPORT',
    dataType: 'string',
    dataField: 'report',
    area: 'row',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataType: 'string',
    dataField: 'marketType',
    area: 'row',
    expanded: true,
  }, {
    caption: 'PRICE NODE ID',
    dataType: 'string',
    dataField: 'priceNodeId',
    area: 'column',
    expanded: true,
  }, {
    caption: 'PRODUCT',
    dataField: 'product',
    area: 'column',
    expanded: true,
    sortingMethod(a, b) {
      if (a.text === 'LMP_PRC') {
        return -1;
      }
      if (b.text === 'LMP_PRC') {
        return 1;
      }
      return a.text.localeCompare(b.text);
    },
  }, {
    caption: 'TIE NAME',
    dataType: 'string',
    dataField: 'tieName',
    area: 'filter',
    expanded: true,
  },
  {
    caption: 'TRADING HOUR',
    dataType: 'number',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataType: 'number',
    dataField: 'tradingInterval',
    area: 'row',
    expanded: true,
  }, {
    caption: 'PRICE',
    dataType: 'number',
    dataField: 'price',
    format: '#,##0.####',
    summaryType: 'sum',
    area: 'data',
    expanded: true,
  }],
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};