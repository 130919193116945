
const state = {
    visible: false
};

const getters = {
    getCLIVisibility(state) {
        return state.visible;
    }
}

const actions = {
    toggleCLI({commit, state}){
        commit('TOGGLE_CLI');
    }
}

const mutations = {
    ['TOGGLE_CLI'] (state) {                
        state.visible = !state.visible;
    }
}

export default {
    namespaced: true,

    state,
    getters,
    actions,
    mutations
}