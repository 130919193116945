import helpers from '@/utils/helpers';
import { ETAG_API } from '@/api';
import * as tagUtils from '@/utils/etag/tagUtils';
import notify from 'devextreme/ui/notify';

const state = {
  templateName: null,
  templateDescription: null,
  templateCPSE: null,
  templateCPSEList: [],
  templateVisible: false,
};

const getters = {
  getTemplateName: (state) => state.templateName,
  getTemplateDescription: (state) => state.templateDescription,
  getTemplateCPSE: (state) => state.templateCPSE,
  getTemplateCPSEList: (state) => state.templateCPSEList,
  getTemplateVisibility: (state) => state.templateVisible,
};

const actions = {
  changeTemplateName({ commit }, item) {
    commit('setTemplateName', item);
  },
  changeTemplateDescription({ commit }, item) {
    commit('setTemplateDescription', item);
  },
  changeTemplateCPSE({ commit }, state, value) {
    const cpse = helpers.find(state.templateCPSEList, (c) => c.value.toString() === value.toString());
    commit('setTemplateCPSE', cpse.label);
  },
  changeTemplateVisibility({ commit }, item) {
    commit('setTemplateVisibility', item);
  },
  async createTemplate({ dispatch }, item) {
    item.tagModel.tagID.tagCode = '0000000';
    item.tagModel.name = item.template.templateName;
    item.tagModel.description = item.template.templateDescription;
    if (item.template.templateVisible && item.template.templateCPSE != null) {
      const pse = helpers.find(item.template.templateCPSEList, (c) => c.label === item.template.templateCPSE);
      item.tagModel.tagID.pse = pse.label;
      item.tagModel.tagID.pseCode = null;
    }

    try {
      const response = await ETAG_API.post('tags/variants/TEMPLATE', item.tagModel);
      notify({ type: 'success', message: 'Template Saved' });
      item.template.templateVisible = false;
      item.router.push({ name: 'Templates' });
    } catch (err) {
      console.log(err);
    }
  },
  async deleteTemplateTag({ dispatch }, state, router) {
    try {
      const response = await ETAG_API.delete(`/tags/variants/TEMPLATE/${state.templateName}`);
      notify({ type: 'success', message: 'Template Deleted' });
      router.push({ name: 'Templates' });
    } catch (err) {
      console.log(err);
      notify({ type: 'error', message: 'Incomplete Delete' });
    }
  },
  async updateTemplate({ dispatch }, state, item) {
    const previousName = item.router.currentRoute.params.templateName;
    item.tagModel.name = state.templateName;
    item.tagModel.description = state.templateDescription;
    item.tagModel.tagID.tagCode = '0000000';
    try {
      const response = await ETAG_API.put(`tags/variants/TEMPLATE/${previousName}`, item.tagModel);
      notify({ type: 'success', message: 'Template Saved' });
      item.router.push({ name: 'Templates' });
    } catch (err) {
      console.log(err);
      notify({ type: 'error', message: 'Unable to save template changes.' });
    }
  },
  async loadTagTemplate({ dispatch, state }, context) {
    const templateName = context.name;
    try {
      const response = await ETAG_API.get(`tags/variants/TEMPLATE/${templateName}`);
      const tagModel = response.data;
      tagModel.tagID.tagCode = null;
      tagModel.compositeState = '';
      // await tagUtils.resetSegmentIDs(REGISTRY_API, tagModel);
      await dispatch('loadTagModel', tagModel);
      if (context.segment || context.segment === 0) {
        dispatch('createProfilesFromDeal', { context, tagModel });
      }
    } catch (err) {
      if (err.response) console.log(err.response);
      else console.log(err);
    }
  },
};

const mutations = {
  clear(state) {
    state.templateDescription = null;
    state.templateName = null;
  },
  setFromTagModel(state, model) {
    state.templateName = model.name;
    state.templateDescription = model.description;
  },
  setTemplateName(state, item) {
    state.templateName = item;
  },
  setTemplateDescription(state, item) {
    state.templateDescription = item;
  },
  setTemplateVisibility(state, item) {
    state.templateName = null;
    state.templateDescription = null;
    state.templateVisible = !state.templateVisible;
  },
  setTemplateCPSEList(state, data) {
    const cpseList = [];
    for (let x = 0; x < data.cpseList.length; x++) {
      const item = data.cpseList[x];
      if (item.right === 'Write') {
        cpseList.push({
          taggingEntityID: item.taggingEntityID,
          code: item.code,
          right: item.right,
          value: item.code,
          label: item.code,
        });
      }
    }
    state.templateCPSEList = cpseList;
    if (state.templateCPSEList.length > 0) { state.templateCPSE = state.templateCPSEList[0].label; }
  },
  setTemplateCPSE(state, item) {
    state.templateCPSE = item;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};