import { MODES, USE_PSCS_AG_GRID } from './constants';
import * as tagUtils from '@/utils/etag/tagUtils';
import helpers from '@/utils/helpers';

const state = {
  contractList_visibleFlag: false,
  contractList_tableConfiguration: {
    columns: [{ prop: 'value', label: 'value', editable: true }],
    options: { showAddRemove: true },
  },
  contractList_data: [],
  contractList_row: null,
  contractList_column: null,
  contractList_rowIndex: null,
  contractList_readonlyFlag: false,
};

const getters = {
  getContractListVisibleFlag: state => state.contractList_visibleFlag,
  getContractListTableConfiguration(state, getters) {
    const configuration = helpers.cloneDeep(state.contractList_tableConfiguration);
    const mode = getters.getMode;
    if (mode === MODES.VIEW_TAG || mode === MODES.VIEW_TEMPLATE) {
      tagUtils.setEditableFalse(configuration, {});
    } else if (state.contractList_readonlyFlag === true) {
      tagUtils.setEditableFalse(configuration, {});
    }
    return configuration;
  },
  getContractListData: state => state.contractList_data,
};

const actions = {
  changeContractList({ commit, state, getters }, item) {
    if (item.row !== null && item.row !== undefined && item.row.type === 'TRAN') {
      if (item.row.market_id === null || item.row.market_id === undefined) return;
      if (item.row.market_pse_name !== null && item.row.market_id.toString() === '1') return;
      const load_market_id = getters.getLoadMarketSegmentID;
      if (load_market_id !== null && item.row.market_id.toString() === load_market_id.toString()) return;
    }
    if (!item.visible) {
      const countProp = state.contractList_column.countProp;
      const dataProp = state.contractList_column.dataProp;
      if (!state.contractList_readonlyFlag) {
        const result = [];
        const dataLength = state.contractList_data.length;
        const data = state.contractList_data;
        for (let dx = 0; dx < dataLength; dx++) {
          const d = data[dx];
          if (d.value !== '') { result.push(d.value); }
        }
        commit('setContractListProperty', {
          rowIndex: state.contractList_rowIndex,
          type: state.contractList_row.type,
          countProp,
          count: result.length,
          dataProp,
          data: result,
          market_id: state.contractList_row.market_id,
        });
      }
    }
    commit('setContractList', item);
  },
  changeContractListRowData({ commit }, item) {
    commit('setContractListRowData', item);
  },
  rowOperationContractList({ commit }, item) {
    if (item.type === 'ADD') {
      commit('addContractListRow', { index: item.rowIndex });
    } else if (item.type === 'REMOVE') {
      commit('removeContractListRow', { index: item.rowIndex });
    }
  },
  changeContractListReadonlyFlag({ commit }, value) {
    commit('setContractListReadonlyFlag', value);
  },
  changeMode(context, value) {
    const mode = value;
    const compositeState = context.getters.getCompositeState;

    if ([MODES.NEW_TAG, MODES.NEW_TEMPLATE, MODES.EDIT_TEMPLATE, MODES.VARIANT_TAG].includes(mode)) {
      context.commit('setContractListReadonlyFlag', false);
    } else if (mode === MODES.EDIT_TAG) {
      if (compositeState === 'PENDING') {
        context.commit('setContractListReadonlyFlag', true);
      } else {
        context.commit('setContractListReadonlyFlag', false);
      }
    } else {
      context.commit('setContractListReadonlyFlag', true);
    }
  },
};

const mutations = {
  setContractList(state, item) {
    state.contractList_visibleFlag = item.visible;
    if (state.contractList_visibleFlag) {
      if (item.column) {
        const countProp = USE_PSCS_AG_GRID ? item.column.field : item.column.prop;
        const dataProp = `${countProp.substring(0, countProp.indexOf('Count'))}List`;

        state.contractList_row = item.row;
        state.contractList_column = { dataProp, countProp };
        const count = state.contractList_row[countProp];

        const data = state.contractList_row[dataProp];
        if (Array.isArray(data)) {
          state.contractList_data = data.map(value => ({ value }));
        }

        if (!state.contractList_readonlyFlag && count.toString() === '0') {
          if (!state.contractList_data) state.contractList_data = [];
          state.contractList_data.push({ value: '' });
        }
        state.contractList_rowIndex = item.rowIndex;
      }
    } else {
      state.contractList_rowIndex = null;
      state.contractList_row = null;
      state.contractList_column = null;
      state.contractList_data = [];
    }
  },
  setContractListReadonlyFlag(state, value) {
    state.tagIDList_readonlyFlag = value;
  },
  addContractListRow(state, item) {
    const data = [];
    for (let x = 0; x <= item.index; x++) {
      data.push(state.contractList_data[x]);
    }
    data.push({ value: '' });
    for (let x = item.index + 1; x < state.contractList_data.length; x++) {
      data.push(state.contractList_data[x]);
    }
    state.contractList_data = data;
  },
  removeContractListRow(state, item) {
    const data = [];
    for (let x = 0; x < item.index; x++) {
      data.push(state.contractList_data[x]);
    }
    for (let x = item.index + 1; x < state.contractList_data.length; x++) {
      data.push(state.contractList_data[x]);
    }
    if (data.length === 0) data.push({ value: '' });
    state.contractList_data = data;
  },
  setContractListRowData(state, item) {
    if (item.value.length <= 50) { state.contractList_data[item.rowIndex] = { value: item.value }; }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};