import utils from '@/utils';
import dateStore from '@/utils/dateStore';
import helpers from '@/utils/helpers';
import * as tagUtils from '@/utils/etag/tagUtils';
import { ETAG_API } from '@/api';
import notify from 'devextreme/ui/notify';
import { MODES, TRANSACTION_TYPES } from './constants';

function createTagIDModel(state) {
  return {
    gca: state.gca,
    gcaCode: null,
    lca: state.lca,
    lcaCode: null,
    pse: state.pse,
    pseCode: null,
    tagCode: state.tagCode,
  };
}

const state = {
  gcaCode: null,
  gca: null,
  lcaCode: null,
  lca: null,
  source: null,
  sink: null,
  tagCode: null,
  pseCode: null,
  pse: null,
  cpse: null,
  transactionType: 'NORMAL',
  testFlag: false,
  notes: null,
  messageNotes: null,
  ccList: [],
  cpseList: [],
  currentVersion: '',
  currentVersion_requestID: '',
  currentVersion_correctionID: '',
  versionList: [],
  status: '',
  routeTagID: '',
  routeEntity: '',
  compositeState: '',
  busy: false,
  terminateDialogVisible: false,
  profileDialogVisible: false,
  submitDialogVisible: false,
  forceProfileDialogVisibleFlag: false,
  correctionDialogVisible: false,
  terminationDateTime: null,
  selectedTerminationDateTime: null,
  terminationDSTVisibleFlag: false,
  terminationDSTFlag: false,
  implementationDateTime: null,
  terminationNotes: '',
  terminateDialogTitle: '',
  rampDurationVisible: false,
  profileChangeNotes: '',
  correctionNotes: '',
  contactInfo: null,
  rampDuration: null,
  registry: {
    pseList: [],
    baList: [],
    tpList: [],
    moList: [],
  },
  readonlyFlag: true,
  userProfile: null,
  tagIDSecurityKey: null,
  tagSummaryRef: null,
};
const getters = {
  getTransactionTypeList: (state) => TRANSACTION_TYPES,
  getDisplayTagName(state) {
    const gca = state.gca === null ? '' : state.gca;
    const lca = state.lca === null ? '' : state.lca;
    const tagCode = state.tagCode === null ? '' : state.tagCode;
    const pse = state.pse === null ? '' : state.pse;
    if (gca === '' && lca === '' && tagCode === '' && pse === '') {
      if (state.routeTagID !== '' && state.routeTagID !== null) { return state.routeTagID; }
      return '';
    }
    return `${gca}_${pse} ${tagCode}_${lca}`;
  },
  getTagCodePhonic(state) {
    if (state.tagCode === null) { return ''; }
    return utils.data.toPhoneticAlphaArray(state.tagCode);
  },
  getTagName(state) {
    const gca = state.gca === null ? '' : state.gca;
    const lca = state.lca === null ? '' : state.lca;
    const tagCode = state.tagCode === null ? '' : state.tagCode;
    const pse = state.pse === null ? '' : state.pse;
    if (gca === '' && lca === '' && tagCode === '' && pse === '') {
      if (state.routeTagID !== '' && state.routeTagID !== null) { return state.routeTagID; }
      return '';
    }
    return `${gca}_${pse}${tagCode}_${lca}`;
  },
  getTagID(state) {
    return createTagIDModel(state);
  },
  getCorrectionFlag: (state) => state.compositeState === 'PENDING',
  getTagSummaryRef: (state) => state.tagSummaryRef,
  getTagIDSecurityKey: (state) => state.tagIDSecurityKey,
  getGCAName: (state) => (state.gca !== null ? state.gca.toUpperCase() : ''),
  getLCAName: (state) => (state.lca !== null ? state.lca.toUpperCase() : ''),
  getCPSEName: (state) => (state.pse !== null ? state.pse.toUpperCase() : ''),
  getTagCode: (state) => (state.tagCode !== null ? state.tagCode.toUpperCase() : ''),
  getTransactionType: (state) => state.transactionType,
  getNotes: (state) => state.notes,
  getMessageNotes: (state) => state.messageNotes,
  getTestFlag: (state) => state.testFlag,
  getCPSE: (state, readonly = true) => state.pse,
  getCPSEList: (state) => state.cpseList,
  getCCMultipleSelectList: (state) => state.ccList,
  getVersion: (state) => state.currentVersion,
  getVersionList: (state) => state.versionList,
  getCurrentRequestVersion(state) {
    if (state.currentVersion !== null) {
      return state.versionList.find((item) => item.value === state.currentVersion);
    }
    return null;
  },
  getStatus: (state) => state.status,
  getCCDisplayList(state) {
    let ccList = '';
    if (state.ccList) {
      for (let i = 0; i < state.ccList.length; i++) {
        const id = state.ccList[i].toString();
        const parts = id.split('_');
        const name = parts[0];
        const type = parts[1].toUpperCase();
        const displayName = `${name} (${type})`;

        if (ccList === '') {
          ccList = displayName;
        } else {
          ccList += `, ${displayName}`;
        }
      }
    }
    return ccList;
  },
  getCompositeState: (state) => state.compositeState,
  getTerminationRequestData(state) {
    const dt = dateStore.toDateFromLocal(state.selectedTerminationDateTime);
    if (state.terminationDSTVisibleFlag === true && state.terminationDSTFlag === true) {
      dt.add(60, 'minutes');
    }

    return {
      tagID: createTagIDModel(state),
      notes: state.terminationNotes,
      contactInfo: state.contactInfo,
      rampDuration: state.rampDuration,
      absoluteStart: dt.utc().format(),
    };
  },
  getProfileChangeRequestData(state) {
    return {
      notes: state.profileChangeNotes,
      contactInfo: state.contactInfo,
      requestID: state.currentVersion_requestID,
    };
  },
  getCorrectionRequestData(state) {
    return {
      tagID: createTagIDModel(state),
      notes: state.correctionNotes,
      contactInfo: state.contactInfo,
      segmentList: [],
    };
  },
  getSetStateModel(state) {
    let contactInfo = null;

    if (state.userProfile) {
      contactInfo = {
        contact: `${state.userProfile.firstName} ${state.userProfile.lastName}`,
        phone: state.userProfile.phone,
        fax: '',
      };
    }

    return {
      tagID: createTagIDModel(state),
      contactInfo,
      notes: null,
      approvalStatus: null,
      requestRef: null,
    };
  },
  getTagModel(state, registry) {
    const profile = state.userProfile;
    let contactInfo = null;

    if (profile) {
      contactInfo = {
        contact: `${profile.firstName} ${profile.lastName}`,
        phone: profile.phone,
        fax: '',
      };
    }

    const ccList = [];
    state.ccList.forEach((c) => {
      const split = c.split('_');
      const tid = split[0];
      const typ = split[1].toUpperCase();

      ccList.push({
        entity: tid,
        entityCode: 0,
        entityType: typ,
      });
    });

    return {
      tagID: {
        gca: state.gca,
        gcaCode: null,
        lca: state.lca,
        lcaCode: null,
        pse: state.pse,
        pseCode: null,
        tagCode: state.tagCode,
      },
      notes: state.notes,
      testFlag: state.testFlag,
      transactionType: state.transactionType,
      segmentList: [],
      description: '',
      name: '',
      contactInfo,
      ccList,
    };
  },
  getTagCodeEnabledFlag(state) {
    return !state.busy && state.pse !== null && state.pse !== '';
  },
  getBusyFlag: (state) => state.busy,
  getTerminationDateTime: (state) => state.terminationDateTime,
  getSelectedTerminationDateTime: (state) => state.selectedTerminationDateTime,
  getTerminationNotes: (state) => state.terminationNotes,
  getRampDuration: (state) => state.rampDuration,
  getRampDurationVisible: (state) => state.rampDurationVisible,
  getTerminateDialogTitle: (state) => state.terminateDialogTitle,
  getProfileChangeNotes: (state) => state.profileChangeNotes,
  getCorrectionNotes: (state) => state.correctionNotes,
  getShowCorrectionDialog: (state) => state.correctionDialogVisible,
  getShowTerminateDialog: (state) => state.terminateDialogVisible,
  getShowProfileDialog: (state) => state.profileDialogVisible,
  getImplementationDateTime: (state) => state.implementationDateTime,
  getShowForceProfileDialog: (state) => state.forceProfileDialogVisibleFlag,
  getReadonlyFlag: (state) => state.readonlyFlag,
  getShowTerminationDSTFlag: (state) => state.terminationDSTVisibleFlag,
  getTerminationDSTFlag: (state) => state.terminationDSTFlag,
  getSubmitDialogVisibility: (state) => state.submitDialogVisible,
  getCurrentVersionRequestID: (state) => state.currentVersion_requestID,
};

const actions = {
  changeCPSE({ commit }, state, value) {
    const cpse = state.cpseList.find((c) => c.value.toString() === value.toString());
    commit('setCPSE', { value: cpse.label, label: cpse.label });
  },
  changeTransactionType({ commit }, item) {
    commit('setTransactionType', item);
  },
  changeNotes({ commit }, item) {
    commit('setNotes', item);
  },
  changeMessageNotes({ commit }, item) {
    commit('setMessageNotes', item);
  },
  changeTagCode({ commit }, item) {
    commit('setTagCode', item);
  },
  changeTestFlag({ commit }, item) {
    commit('setTestFlag', item);
  },
  changeGCA({ commit }, item) {
    commit('setGCA', item);
  },
  changeSource({ commit }, item) {
    commit('setSource', item);
  },
  changeSink({ commit }, item) {
    commit('setSink', item);
  },
  changeCCMultipleSelection({ commit }, item) {
    commit('setCCMultipleSelection', item);
  },
  changeLCA({ commit }, item) {
    commit('setLCA', item);
  },
  changeVersion({ commit, dispatch }, item) {
    commit('setVersion', item);

    dispatch('loadTagVersion');
  },
  changeStatus({ commit, dispatch }, item) {
    commit('setStatus', item);
  },
  downloadTagXML({ dispatch }, state) {
    const version = state.versionList.find((item) => item.value === state.currentVersion);
    ETAG_API.get(`/${state.routeEntity}/${state.routeTagID}/requests/${state.currentVersion_requestID}/correction/${version.correctionID}/xml`, {
      params: {
        tagSummaryRef: state.tagSummaryRef,
      },
    })
      .then((response) => {
        const xmltext = response.data;

        if (xmltext.toLowerCase() === 'not found.') {
          notify({ type: 'error', message: 'Error could not find tag version.' });
        } else {
          const filename = `${version.tagID}_${version.text}_${version.formattedTimestamp}.xml`;
          const downloadElem = document.createElement('a');
          const blob = new Blob([xmltext], { type: 'application/octet-stream' });

          downloadElem.setAttribute('href', window.URL.createObjectURL(blob));
          downloadElem.setAttribute('download', filename);

          downloadElem.dataset.downloadurl = ['application/octet-stream', downloadElem.download, downloadElem.href].join(':');
          downloadElem.click();
        }
      }).catch(() => notify({ type: 'error', message: 'Error when trying to download tag xml.' }));
  },
  newTagCode({ commit, dispatch }, state) {
    commit('setTagBusy', true);
    commit('clearTagCode');
    // construct the post body
    const postBody = {
      gca: state.gca,
      lca: state.lca,
      source: state.source,
      sink: state.sink,
    };
    ETAG_API.post(`/code/${state.pse}`, postBody).then((response) => {
      commit('setTagCode', response.data.tagCode);
      commit('setTagBusy', false);
    }).catch((error) => {
      commit('clearTagCode');
      commit('setTagBusy', false);
      const errorMessage = error.response.data.message;
      notify({ type: 'error', message: (errorMessage && errorMessage.length > 0) ? errorMessage : 'Error generating tag code.' });
    });
  },
  loadTagVersions({ commit, dispatch }, tag) {
    console.log('loadTagVersions');
    commit('setRouteTagID', tag);

    return ETAG_API.get(`/${tag.entity}/${tag.tagID}/requests`, {
      params: {
        requestID: tag.requestID,
        tagSummaryRef: tag.tagSummaryRef,
      },
    }).then((response) => {
      const { data } = response;
      commit('setVersions', data);

      const requestID = Number(tag.requestID || data.tag.tag.requestID);
      const { correctionID } = data.tag.tag;
      const item = data.requests.find((x) => x.requestID === requestID && x.correctionID === correctionID);
      const requestString = `${requestID}_${correctionID}`;

      data.tag.tag.requestID = requestID;

      commit('setVersion', requestString);
      commit('setTagSummaryRef', tag.tagSummaryRef);
      dispatch('changeStatus', item.requestStatus);
      dispatch('loadTagModel', data.tag.tag);
      dispatch('changeMessageNotes', data.tag.notes);

      if (item.requestStatus === 'PENDING' && item.requestID !== 0) {
        dispatch('comparePreviousVersion');
      }
    }).catch((error) => console.error(error));
  },
  loadTagVersion({ dispatch }, state) {
    console.log('loadTagVersion');
    console.log(state.tagSummaryRef);
    dispatch('clearPreviousTagProfile');
    return ETAG_API.get(`/${state.routeEntity}/${state.routeTagID}/requests/${state.currentVersion_requestID}/corrections/${state.currentVersion_correctionID}`, {
      params: {
        tagSummaryRef: state.tagSummaryRef,
      },
    }).then((response) => {
      dispatch('loadTagModel', response.data.tag);
      dispatch('changeMessageNotes', response.data.notes);
    }).catch((error) => console.log(error));
  },
  loadPreviousVersion({ dispatch }, state) {
    console.log('loadPreviousVersion');
    let prevIdx = state.currentVersion_requestID - 1;
    let version = state.versionList.find((item) => item.requestID === prevIdx);
    if (version) {
      while (version.requestStatus !== 'APPROVED') {
        prevIdx -= 1;
        if (prevIdx === -1) break;
        version = state.versionList.find((item) => item.requestID === prevIdx);
      }

      const sp = version.value.split('_');
      const requestId = sp[0];
      const correctionID = sp[1];

      if (prevIdx !== -1) {
        return ETAG_API.get(`/${state.routeEntity}/${state.routeTagID}/requests/${requestId}/corrections/${correctionID}`, {
          params: {
            tagSummaryRef: state.tagSummaryRef,
          },
        }).then((response) => {
          dispatch('loadPreviousTagProfile', response.data.tag);
        }).catch((error) => {
          console.log(error);
        });
      }
    }
    dispatch('clearPreviousTagProfile');
  },
  async loadCopyTag({ dispatch }, item) {
    const sp = item.requestID.split('_');
    const requestId = sp[0];
    const correctionID = sp[1];

    const response = await ETAG_API.get(`/${item.entity}/${item.tagID}/requests/${requestId}/corrections/${correctionID}`, {
      params: {
        tagSummaryRef: item.tagSummaryRef,
      },
    });
    const tagModel = response.data.tag;

    tagModel.tagID.pse = item.pse;
    tagModel.tagID.pseCode = null;
    tagModel.tagID.tagCode = null;
    tagModel.compositeState = '';
    tagModel.implementationDateTime = item.startTime;
    tagModel.terminationDateTime = item.endTime;

    tagUtils.shiftProfilesAndLossAccountingByOffset(tagModel, item.offset);

    dispatch('loadTagModel', tagModel);
  },
  submit({ dispatch }, model, router) {
    ETAG_API.post('/tags', model).then((response) => {
      const result = response.data;
      if (result.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request New Tag Sent.' });
        router.push({ name: 'Etag' });
      } else if (result.state === 'FAILURE') {
        notify({ type: 'error', message: result.message });
      }
      dispatch('loading', false);
    }).catch((err) => {
      console.error(err);
      notify({ type: 'error', message: 'Request New Tag failed.' });
      dispatch('loading', false);
    });
  },
  changeTerminateDialogVisibility({ commit }, item) {
    commit('setTerminateDialogVisibility', item);
  },
  changeProfileDialogVisibility({ commit }, item) {
    commit('setProfileDialogVisibility', item);
  },
  changeSubmitDialogVisibility({ commit }, item) {
    commit('setSubmitDialogVisibility', item);
  },
  changeForceProfileDialogVisibleFlag({ commit }, item) {
    commit('setForceProfileDialogVisibleFlag', item);
  },
  changeCorrectionVisibility({ commit }, item) {
    commit('setCorrectionDialogVisiblity', item);
  },
  changeTerminationDateTime({ commit }, item) {
    commit('setTerminationDateTime', item);
  },
  changeSelectedTerminationDateTime({ commit }, item) {
    commit('setSelectedTerminationDateTime', item);
  },
  changeTerminationNotes({ commit }, item) {
    commit('setTerminationNotes', item);
  },
  changeTerminateDialogTitle({ commit }, item) {
    commit('setTerminateDialogTitle', item);
  },
  changeRampDurationVisible({ commit }, item) {
    commit('setRampDurationVisible', item);
  },
  changeRampDuration({ commit }, item) {
    commit('setRampDuration', item);
  },
  changeProfileChangeNotes({ commit }, item) {
    commit('setProfileChangeNotes', item);
  },
  changeCorrectionNotes({ commit }, item) {
    commit('setCorrectionNotes', item);
  },
  submitTermination({ dispatch }, tagName, model, router, entity) {
    ETAG_API.patch(`/${entity}/${tagName}`, model).then((response) => {
      const result = response.data;
      if (result.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request Termination Tag Sent' });
        dispatch('changeTerminateDialogVisibility', false);
        router.push({ name: 'Etag' });
      } else if (result.state === 'FAILURE') {
        helpers.forEach(result.errorList, (e) => {
          notify({ type: 'error', message: e.message });
        });
      }
    }).catch((err) => console.log(err));
  },
  submitProfileChangeRequest({ dispatch, commit }, tagName, model, router, entity) {
    ETAG_API.post(`/${entity}/${tagName}/requests`, model).then((response) => {
      const results = response.data;
      if (results.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request ProfileChange Tag Sent' });
        dispatch('changeProfileDialogVisibility');
        dispatch('ChangeEditButtonText', 'Edit');
        router.push({ name: 'Etag' });
      } else if (results.state === 'FAILURE') {
        results.errorList.forEach((e) => {
          notify({ type: 'error', message: e.message });
        });
      }
    }).catch((err) => console.log(err));
  },
  submitForceProfileChangeRequest({ dispatch, commit }, tagName, model, router, entity) {
    ETAG_API.patch(`/${entity}/${tagName}/requests`, model).then((response) => {
      const results = response.data;
      if (results.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request ProfileChange Tag Sent' });
        dispatch('changeForceProfileDialogVisibleFlag');
        router.push({ name: 'Etag' });
      } else if (results.state === 'FAILURE') {
        results.errorList.forEach((e) => {
          notify({ type: 'error', message: e.message });
        });
      }
    }).catch((err) => console.log(err));
  },
  submitCorrectionRequest({ dispatch, commit }, tagName, model, router, entity) {
    ETAG_API.post(`/${entity}/${tagName}/corrections`, model).then((response) => {
      const results = response.data;
      if (results.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request Correction Tag Sent' });
        dispatch('changeCorrectionVisibility');
        dispatch('ChangeEditButtonText', 'Edit');
        router.push({ name: 'Etag' });
      } else if (results.state === 'FAILURE') {
        results.errorList.forEach((e) => {
          notify({ type: 'error', message: e.message });
        });
      }
    }).catch((err) => console.log(err));
  },
  changeTerminationDSTFlag({ commit }, item) {
    commit('setTerminationDSTFlag', item);
  },
};

const mutations = {
  setBusy(state, value) {
    state.busy = value;
  },
  initialize(state) {
    state.gcaCode = null;
    state.gca = null;
    state.lcaCode = null;
    state.lca = null;
    state.tagCode = null;
    state.pseCode = null;
    state.pse = null;
    state.transactionType = 'NORMAL';
    state.testFlag = false;
    state.notes = null;
    state.ccList = ['FERC_pse'];
    state.currentVersion = null;
    state.currentVersion_requestID = null;
    state.currentVersion_correctionID = null;
    state.versionList = [];
    state.routeTagID = '';
    state.routeEntity = '';
    state.compositeState = '';
    state.busy = false;
    state.readonlyFlag = true;
    state.terminationDSTFlag = false;
    state.terminationDSTVisibleFlag = false;
  },

  setFromTagModel(state, tagModel) {
    state.implementationDateTime = tagModel.implementationDateTime;
    state.terminationDateTime = tagModel.terminationDateTime;
    state.gcaCode = null;
    state.gca = tagModel.tagID.gca;
    state.lcaCode = null;
    state.lca = tagModel.tagID.lca;
    state.pseCode = null;
    state.pse = tagModel.tagID.pse;
    state.tagCode = tagModel.tagID.tagCode;
    state.notes = tagModel.notes;
    state.transactionType = tagModel.transactionType;
    state.ccList = tagModel.ccList;
    state.testFlag = tagModel.testFlag;
    state.compositeState = tagModel.compositeState;
    state.contactInfo = tagModel.contactInfo;
    state.tagIDSecurityKey = tagModel.tagIDSecurityKey;
    if (tagModel.ccList) {
      state.ccList = [];
      for (let i = 0; i < tagModel.ccList.length; i++) {
        state.ccList.push(`${tagModel.ccList[i].entity}_${tagModel.ccList[i].entityType.toString().toLowerCase()}`);
      }
    }
    state.readonlyFlag = tagModel.readonly;
  },
  setCPSE(state, item) {
    state.pse = item.label;
    state.pseCode = null;
  },
  setCPSEs(state, data) {
    const cpseList = [];
    for (let x = 0; x < data.length; x++) {
      const item = data[x];
      if (item.right === 'Write') {
        cpseList.push({
          taggingEntityID: item.taggingEntityID,
          code: item.code,
          right: item.right,
          value: item.code,
          label: item.code,
        });
      }
    }
    state.cpseList = cpseList;
  },
  clearTagCode(state) {
    state.tagCode = null;
  },
  setTransactionType(state, item) {
    state.transactionType = item;
  },
  setNotes(state, value) {
    state.notes = value;
  },
  setMessageNotes(state, value) {
    state.messageNotes = value;
  },
  setTestFlag(state, value) {
    state.testFlag = value;
  },
  setGCA(state, item) {
    state.gca = item.label;
    state.gcaCode = null;
  },
  setSelectedGCA(state, item) {
    state.selectedGCA = item.label;
  },
  setSelectedLCA(state, item) {
    state.selectedLCA = item.label;
  },
  setSource(state, item) {
    state.source = item.label;
  },
  setSink(state, item) {
    state.sink = item.label;
  },
  setTagCode(state, item) {
    item = item.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    state.tagCode = helpers.toUpper(item);
  },
  setCCMultipleSelection(state, item) {
    if (item.length > state.ccList.length) {
      state.ccList.push(item[item.length - 1]);
    } else {
      const currentSet = new Set(item);
      const toRemove = state.ccList.filter((x) => !currentSet.has(x));

      const indexToRemove = state.ccList.indexOf(toRemove[0]);
      state.ccList.splice(indexToRemove, 1);
    }
  },
  setLCA(state, item) {
    state.lca = item.label;
    state.lcaCode = null;
  },
  setRouteTagID(state, tag) {
    const item = tag.tagID;

    state.routeTagID = item;
    state.routeEntity = tag.entity;

    const split = item.split('_');
    state.gca = split[0];
    state.tagCode = split[1].substring(split[1].length - 7);
    state.pse = split[1].substring(0, split[1].length - 7);
    state.lca = split[2];
  },
  setStatus(state, item) {
    state.status = item;
  },
  setTagSummaryRef(state, item) {
    state.tagSummaryRef = item;
  },
  setVersion(state, item) {
    const selectedVersion = helpers.find(state.versionList, (v) => v.value === item);
    state.currentVersion = item;
    state.currentVersion_correctionID = selectedVersion.correctionID.toString();
    state.currentVersion_requestID = selectedVersion.requestID.toString();
    state.status = selectedVersion.requestStatus;
  },
  setVersions(state, data) {
    const zone = dateStore.getTimeZoneDefinition().tz;
    const format = utils.date.ZULU_FORMAT;

    helpers.forEach(data.requests, (v) => {
      const rt = utils.date.toMoment(v.requestTimeStamp, format, zone);

      v.value = `${v.requestID}_${v.correctionID}`;
      v.timeAgo = rt.clone().fromNow();
      if (v.messageType === 'PROFILECHANGE') {
        v.formattedMessageType = 'Profile Change';
      } else if (v.messageType === 'RELIABILITYCHANGE') {
        v.formattedMessageType = 'Reliability Change';
      } else if (v.messageType === 'MARKETCHANGE') {
        v.formattedMessageType = 'Market Change';
      } else {
        v.formattedMessageType = v.messageType;
      }
      v.formattedTimestamp = rt.clone().format('YYYY-MM-DD HH:mm:ss');
      v.label = `${v.requestID} - ${helpers.startCase(v.formattedMessageType)} (${helpers.startCase(v.requestStatus.toLowerCase())})    ${v.timeAgo} (${v.formattedTimestamp})`;
      v.text = `${v.messageType}(${v.requestStatus})`;
    });

    state.versionList = data.requests;
  },
  setTerminateDialogVisibility(state, item) {
    const now = dateStore.getMomentNowLocal();
    const tz = dateStore.getTimeZone();

    let datetime = dateStore.toMomentAndZoneFromJSDate(now, tz);

    if (item === 'CANCEL') {
      datetime = dateStore.toMomentAndZoneFromJSDate(state.implementationDateTime, tz);
      state.rampDurationVisible = false;
      state.terminateDialogTitle = 'Cancel Tag';
    } else if (item === 'TERMINATE') {
      state.rampDurationVisible = true;
      state.terminateDialogTitle = 'Terminate Tag';
    }

    state.selectedTerminationDateTime = dateStore.toDate(datetime.utc().format(), tz);
    state.terminationNotes = '';
    state.rampDuration = null;

    const dt = dateStore.toDateFromLocal(state.selectedTerminationDateTime);
    const dst = dateStore.getDST(state.selectedTerminationDateTime);

    if (dst !== null && dt.format('YYYY-MM-DD') === dst.format('YYYY-MM-DD')) {
      if (dt.hour() === dst.hour()) {
        state.terminationDSTVisibleFlag = true;
      } else {
        state.terminationDSTVisibleFlag = false;
      }
    } else {
      state.terminationDSTVisibleFlag = false;
    }
    state.terminationDSTFlag = false;
    state.terminateDialogVisible = !state.terminateDialogVisible;
  },
  setProfileDialogVisibility(state, item) {
    state.profileChangeNotes = '';
    state.profileDialogVisible = item ?? !state.profileDialogVisible;
  },
  setSubmitDialogVisibility(state, item) {
    state.submitDialogVisible = item;
  },
  setForceProfileDialogVisibleFlag(state) {
    state.profileChangeNotes = '';
    state.forceProfileDialogVisibleFlag = !state.forceProfileDialogVisibleFlag;
  },
  setCorrectionDialogVisiblity(state, item) {
    state.correctionNotes = '';
    state.correctionDialogVisible = item ?? !state.correctionDialogVisible;
  },
  setTerminationDateTime(state, item) {
    state.terminationDateTime = item;
  },
  setSelectedTerminationDateTime(state, item) {
    state.selectedTerminationDateTime = item;
    const dt = dateStore.toDateFromLocal(state.selectedTerminationDateTime);
    const dst = dateStore.getDST(state.selectedTerminationDateTime, null);

    if (dt.format('YYYY-MM-DD') === dst.format('YYYY-MM-DD')) {
      if (dt.hour() === dst.hour()) {
        state.terminationDSTVisibleFlag = true;
      } else {
        state.terminationDSTVisibleFlag = false;
      }
    } else {
      state.terminationDSTVisibleFlag = false;
    }
  },
  setTerminationNotes(state, item) {
    state.terminationNotes = item;
  },
  setRampDurationVisible(state, item) {
    state.rampDurationVisible = item;
  },
  setTerminateDialogTitle(state, item) {

  },
  setRampDuration(state, item) {
    state.rampDuration = item;
  },
  setProfileChangeNotes(state, item) {
    state.profileChangeNotes = item;
  },
  setCorrectionNotes(state, item) {
    state.correctionNotes = item;
  },
  setRegistryPSEList(state, pseList) {
    state.registry.pseList = pseList;
  },
  setRegistryBAList(state, baList) {
    state.registry.baList = baList;
  },
  setRegistryMOList(state, moList) {
    state.registry.moList = moList;
  },
  setRegistryTSPList(state, tpList) {
    state.registry.tpList = tpList;
  },
  setTerminationDSTFlag(state, value) {
    state.terminationDSTFlag = value;
  },
  setStateSegments(state, tag) {
    state.generation = tag.generation;
    state.transmission = tag.transmission;
    state.load = tag.load;
  },
  setUserProfile(state, userProfile) {
    state.userProfile = userProfile;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};