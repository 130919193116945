import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  contractList: [],
  contractGroupList: [],
  contractGroupFlag: false,
  nullTableRow: {
    id: -999,
    contract_Id: null,
    contractName: null,
    contractGroup_Id: null,
    contractGroupName: null,
    sourceType: null,
    sourceSystem: null,
    meterSourceAlias: null,
    meterId: null,
    channel: null,
    meterType: null,
    meterType2: null,
    meterType3: null,
    rateSchedule: null,
    premiseId: null,
    spid: null,
    spType: null,
    multiplier: null,
    effectiveDate: null,
    terminationDate: null,
    meterGroup: null,
    updatedBy: null,
    updatedDate: null,
    createdBy: null,
    createdDate: null,
  },
};

const getters = {
  getContractList: (state) => state.contractList.map(({ id, name }) => ({ value: id, label: name })),
  getContractGroupList: (state) => state.contractGroupList.map(({ id, shortName }) => ({ value: id, label: shortName })),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchContractList');
    dispatch('fetchContractGroupList');
  },
  async fetchMeter({ commit, state }, parameters = {}) {
    const params = {};
    if (state.contractGroupFlag) params.contractGroups = parameters.contractGroupsSelected;
    else params.contracts = parameters.contractsSelected;
    try {
      const { data: { data } } = await BILLING_REF_API.post(`/${state.contractGroupFlag ? 'meter-group' : 'meter'}/get`, params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.effectiveDate = getMomentDateString(x.effectiveDate);
          x.terminationDate = x.terminationDate === null ? null : getMomentDateString(x.terminationDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Meter Data', 'error');
    }
  },
  async fetchContractList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contracts');
      commit('loadContractList', data);
    } catch (err) {
      this.$notify('Failed to fetch Contract list', 'error');
      console.error(err);
    }
  },
  fetchContractGroupList({ commit }) {
    BILLING_REF_API.get('/contract-groups').then(({ data }) => {
      commit('loadContractGroupList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  async postRecord({ state }, meter) {
    try {
      const result = await BILLING_REF_API.post(`/${state.contractGroupFlag ? 'meter-group' : 'meter'}`, meter);
      state.tableData.push(result.data);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    }
  },
  async updateRecord({ dispatch, commit, state }, meter) {
    try {
      const result = await BILLING_REF_API.put(`/${state.contractGroupFlag ? 'meter-group' : 'meter'}/${meter.id}`, meter);
      commit('updateRecord', result.data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    }
  },
  async deleteTableRow({ commit, state }) {
    try {
      await BILLING_REF_API.delete(`/${state.contractGroupFlag ? 'meter-group' : 'meter'}/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Remove Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  contractGroupFlagChange(state, contractGroupFlag) {
    state.contractGroupFlag = contractGroupFlag;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  loadContractGroupList(state, contractGroups) {
    state.contractGroupList = contractGroups;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};