import createInvoiceStore from './CreateInvoice/createInvoiceStore';
import approveInvoiceStore from './ApproveInvoice/approveInvoiceStore';
import subledgerStore from './Subledger/subledgerStore';
import accountPeriodsDialogStore from './Subledger/AccountPeriodsDialog/accountPeriodsDialogStore';

export default {
  namespaced: true,
  modules: {
    createInvoice: createInvoiceStore,
    approveInvoice: approveInvoiceStore,
    subledger: subledgerStore,
    accountPeriodsDialog: accountPeriodsDialogStore,
  },
};