import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { PPA_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  nullTableRow: {
    id: -999,
    ppaGroup: null,
    chargeCode: null,
    chargeCodeDescription: null,
    glAccountCode: null,
    glDescription: null,
    forecastGLAccountCode: null,
    taxCode: null,
    unitOfMeasure: null,
    profitCenter: null,
    costCenter: null,
    companyCode: null,
    fercAccount: null,
    fercClass: null,
    orderNumber: null,
    volumeFactor: 1,
    cflowType: null,
    feeName: null,
    accountKey: null,
    caisoCharge: null,
    rsrcType: null,
    activeFlag: null,
    startDate: null,
    endDate: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/charge-codes');
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.endDate = obj.endDate === null ? null : getMomentDateString(obj.endDate);
        });
        commit('loadTableData', data);
      }
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  async fetchPPAChargeCodes({ commit }, parameters = {}) {
    const params = {
      ppas: parameters.ppasSelected,
    };
    try {
      const { data: { data } } = await PPA_REF_API.post('/charge-codes/PPA', params);
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.startDate = getMomentDateString(obj.startDate);
          obj.endDate = obj.endDate === null ? null : getMomentDateString(obj.endDate);
        });
        commit('loadTableData', data);
      }
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  postRecord({ state }, bank) {
    // eslint-disable-next-line prefer-destructuring
    bank.startDate = getMomentDateString(bank.startDate);
    bank.endDate = bank.endDate === null ? null : getMomentDateString(bank.endDate);

    PPA_REF_API.post('/charge-codes', bank).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, bank) {
    // eslint-disable-next-line prefer-destructuring
    bank.startDate = getMomentDateString(bank.startDate);
    bank.endDate = bank.endDate === null ? null : getMomentDateString(bank.endDate);

    PPA_REF_API.put(`/charge-codes/${bank.id}`, bank).then(({ data }) => {
      data.startDate = getMomentDateString(data.startDate);
      data.endDate = data.endDate === null ? null : getMomentDateString(data.endDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    PPA_REF_API.delete(`/charge-codes/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },

  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};