import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import {
  convertCaisoStatementPublicationToNumberForOrdering,
} from '@/components/Ciso/BaseStore/dataUtil';
import configs from './invoicesSummaryConfigs';

const state = {
  tableData: [],
  tableConfig: configs.fields,
  dockConfig: configs.dockConfig,
  searchConfig: configs.searchConfig,
};

const actions = {
  async fetchInvoicesSummary({ dispatch, commit }, {
    tradeDateRangeSelected, coordinatorListSelected, dateTypeSelected, aliasNameSelected,
  }) {
    dispatch('reset');
    const params = {
      scs: coordinatorListSelected,
      startDate: tradeDateRangeSelected[0].toISOString(),
      endDate: tradeDateRangeSelected[1].toISOString(),
      dateType: dateTypeSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/invoices-summary', { params });
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.publishedDate = getMomentDateString(obj.publishedDate);
          obj.tradeDate = getMomentDateString(obj.tradeDate);
        });
        if (aliasNameSelected === 'ALL') commit('setTableData', data);
        else commit('setTableData', data.filter(({ aliasName }) => aliasName === aliasNameSelected));
      }
      const chargeCodeConfig = configs.fields.find(({ dataField }) => dataField === 'cc');
      let lastRowIndex = 0;
      configs.fields.forEach((value) => {
        if (value.area === 'row') lastRowIndex++;
      });
      if (chargeCodeConfig && aliasNameSelected === 'CHARGE_CODE') {
        chargeCodeConfig.area = 'row';
        chargeCodeConfig.areaIndex = lastRowIndex + 1;
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Invoices Summary', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};