import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import { has } from '@/utils/dataUtil';
import config from './meterComponentConfig';

const state = {
  tableData: [],
  summaryTableData: [],
  tableConfig: config.fields,
  summaryTableConfig: config.summaryFields,
  parameters: [],
  compareFlag: false,
  showRowTotals: false,
};

const actions = {
  async fetchMeterComponentData({ commit }, { params, isSummary }) {
    try {
      commit('setParameters', params);
      commit('setTableData', []);
      commit('setSummaryTableData', []);

      const hasCompare = has(params, 'compareFileType');
      if (hasCompare && params.compareFileType) {
        commit('setShowRowTotals', true);
        commit('setCompareFlag', true);
      } else if (!hasCompare || !params.compareFileType || (hasCompare && params.compareFileType.toString() === '')) {
        commit('setShowRowTotals', false);
        commit('setCompareFlag', false);
      }

      if (isSummary) {
        const { data: { data } } = await CISO_SAMC_API.get('meter-component/summary', { params });
        data.forEach((obj) => {
          obj.tradingDate = getMomentDateString(obj.tradingDate);
        });
        commit('setSummaryTableData', data);
      } else {
        const { data: { data } } = await CISO_SAMC_API.get('meter-component', { params });
        data.forEach((obj) => {
          obj.tradingDate = getMomentDateString(obj.tradingDate);
        });
        commit('setTableData', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Meter Component', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setSummaryTableData', []);
  },
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setSummaryTableData(state, value) {
    state.summaryTableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setSummaryTableConfig(state, value) {
    state.summaryTableConfig = value;
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
