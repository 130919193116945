const baseScheduleDetailConfig = {
  columns: [
    {
      prop: 'he',
      editable: false,
      level: '1',
      fixed: 'left',
      minWidth: 60,
      width: 60,
    },
    {
      prop: 'configuration',
      editable: false,
      level: '1',
      fixed: 'left',
      width: 146,
    },
    {
      prop: 'startTime',
      visible: false,
      editable: false,
    },
    {
      label: 'energy',
      level: '2',
      isParent: true,
    },
    {
      prop: 'en',
      editable: true,
      level: '2-1',
      parent: '2',
      visible: true,
      compare: true,
    },
    {
      label: 'ancillary service',
      level: '3',
      visible: true,
      isParent: true,
    },
    {
      prop: 'ru',
      editable: true,
      level: '3-1',
      parent: '3',
      visible: true,
      compare: true,
      width: 80,
    },
    {
      prop: 'rd',
      editable: true,
      level: '3-1',
      parent: '3',
      visible: true,
      compare: true,
      width: 80,
    },
    {
      prop: 'sr',
      editable: true,
      level: '3-1',
      parent: '3',
      visible: true,
      compare: true,
      width: 80,
    },
    {
      prop: 'nr',
      editable: true,
      level: '3-1',
      parent: '3',
      visible: true,
      compare: true,
      width: 80,
    },
    {
      prop: 'distribution',
      editable: false,
      level: '4',
      width: 80,
    },
  ],
  options: {
    trackCompare: true,
    summaryRows: true,
    cellSelection: true,
  },
};

const bidScheduleDetailConfig = {
  columns: [
    {
      prop: 'he',
      editable: false,
      fixed: 'left',
      level: '1',
      minWidth: 60,
      width: 60,
    },
    {
      prop: 'configuration',
      editable: false,
      level: '1',
      fixed: 'left',
      width: 146,
    },
    {
      prop: 'da_ss',
      editable: false,
      fixed: 'left',
      level: '2',
      label: 'da',
      visible: true,
    },
    {
      prop: 'ss',
      editable: false,
      fixed: 'left',
      level: '2',
    },
    {
      label: 'energy',
      level: '3',
      isParent: true,
    },
    {
      prop: 'en_qty_01',
      label: 'qty_01',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_01',
      label: 'prc_01',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_02',
      label: 'qty_02',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_02',
      label: 'prc_02',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_03',
      label: 'qty_03',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_03',
      label: 'prc_03',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_04',
      label: 'qty_04',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_04',
      label: 'prc_04',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_05',
      label: 'qty_05',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_05',
      label: 'prc_05',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_06',
      label: 'qty_06',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_06',
      label: 'prc_06',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_07',
      label: 'qty_07',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_07',
      label: 'prc_07',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_08',
      label: 'qty_08',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_08',
      label: 'prc_08',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_09',
      label: 'qty_09',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_09',
      label: 'prc_09',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_10',
      label: 'qty_10',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_10',
      label: 'prc_10',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_qty_11',
      label: 'qty_11',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'en_prc_11',
      label: 'prc_11',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
    },
    {
      prop: 'ancillary_service',
      level: '4',
      isParent: true,
      visible: false,
    },
    {
      prop: 'ru_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'ru_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rd_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rd_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'sr_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'sr_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'nr_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'nr_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rmu_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rmu_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rmd_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
    {
      prop: 'rmd_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      visible: false,
    },
  ],
  options: {
    trackCompare: true,
    warningHighlight: true,
  },
};

const istScheduleDetailConfig = {
  columns: [{
    label: 'he',
    prop: 'heVal',
    editable: false,
    fixed: 'left',
    minWidth: 60,
    width: 60,
  }, {
    label: 'quantity',
    prop: 'quantity',
    editable: true,
    minWidth: 60,
    width: 100,
  }, {
    label: 'counter quantity',
    prop: 'counterQuantity',
    editable: false,
    minWidth: 60,
    width: 100,
    cellClass: 'disabled',
  }, {
    label: 'adjusted quantity',
    prop: 'adjustedQuantity',
    editable: false,
    minWidth: 60,
    width: 100,
    cellClass: 'disabled',
  }],
  options: {
    trackCompare: true,
    warningHighlight: true,
  },
};

const cbScheduleDetailConfig = {
  columns: [
    {
      prop: 'he',
      editable: false,
      fixed: 'left',
      level: '1',
      minWidth: 60,
      width: 60,
    },
    {
      label: 'energy',
      level: '3',
      isParent: true,
    },
    {
      prop: 'en_qty_01',
      label: 'qty_01',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_01',
      label: 'prc_01',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_02',
      label: 'qty_02',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_02',
      label: 'prc_02',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_03',
      label: 'qty_03',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_03',
      label: 'prc_03',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_04',
      label: 'qty_04',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_04',
      label: 'prc_04',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_05',
      label: 'qty_05',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_05',
      label: 'prc_05',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_06',
      label: 'qty_06',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_06',
      label: 'prc_06',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_07',
      label: 'qty_07',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_07',
      label: 'prc_07',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_08',
      label: 'qty_08',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_08',
      label: 'prc_08',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_09',
      label: 'qty_09',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_09',
      label: 'prc_09',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_10',
      label: 'qty_10',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_10',
      label: 'prc_10',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_qty_11',
      label: 'qty_11',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'en_prc_11',
      label: 'prc_11',
      editable: true,
      level: '3-1',
      parent: '3',
      compare: true,
      width: 80,
    },
    {
      prop: 'ancillary_service',
      level: '4',
      isParent: true,
      visible: false,
    },
    {
      prop: 'ru_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'ru_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rd_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rd_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'sr_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'sr_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'nr_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'nr_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rmu_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rmu_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rmd_qty',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
    {
      prop: 'rmd_prc',
      level: '4-1',
      parent: '4',
      editable: true,
      compare: true,
      width: 80,
      visible: false,
    },
  ],
  options: {
    trackCompare: true,
    warningHighlight: true,
  },
};

export default {
  base: baseScheduleDetailConfig,
  sibr: bidScheduleDetailConfig,
  ist: istScheduleDetailConfig,
  virtual: cbScheduleDetailConfig,
};