import * as data from './dataUtil';
import * as date from './dateUtil';
import user from './userUtil';

const utilities = {
  data,
  date,
  user,
};

export default utilities;