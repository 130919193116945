import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', sortable: true, filterable: true, alignment: 'center',
    }, {
      prop: 'interestRate', label: 'Interest Rate', sortable: true, filterable: true,
    }, {
      prop: 'startDate', label: 'Start Date', sortable: true, filterable: true, visible: true, dataType: 'date'
    }, {
      prop: 'endDate', label: 'End Date', sortable: true, filterable: true, visible: true, dataType: 'date',
    }, {
      prop: 'createdInfo', label: 'Created By', sortable: true, filterable: true
    }, {
      prop: 'updatedInfo', label: 'Updated By', sortable: true, filterable: true 
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  nullTableRow: {
    id: -999,
    interestRate: null,
    startDate: null,
    endDate: null,
    activeFlag: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/interest-rates');
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = x.endDate === null ? null : getMomentDateString(x.endDate);
        });     
    }
      commit('loadTableData', data);
    } catch (err) {
      console.log(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  postRecord({ state }, Rates) {
    BILLING_REF_API.post('/interest-rates', Rates).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, Rates) {
    BILLING_REF_API.put(`/interest-rates/${Rates.id}`, Rates).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    BILLING_REF_API.delete(`/interest-rates/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },

  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};