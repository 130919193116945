import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'id', label: 'ID', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'referenceId', label: 'Reference ID', alignment: 'right', sortable: true, filterable: true,
    }, {
      prop: 'displayName', label: 'Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'fileName', label: 'File Name', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'fileSize', label: 'Size', alignment: 'right', sortable: true, filterable: true, dataType: 'number',
    }, {
      prop: 'fileType', label: 'Type', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', alignment: 'left', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', alignment: 'left', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', alignment: 'left', sortable: true, dataType: 'datetime',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    name: '',
    commodity: '',
    description: '',
    market: '',
    activeFlag: true,
    createdBy: '',
    createdDate: null,
    updatedBy: '',
    updatedDate: null,
  },
  childTableData: [],
  childTableConfig: {
    columns: [{
      prop: 'TRADEID', label: 'TRADEID', alignment: 'right', sortable: true, filterable: true,
      calculateDisplayValue: (row, column) => {
        return (row.TRADEID?.['@xsi:nil'] === 'true') ? null : row.TRADEID; 
      }
    }, {
      prop: 'EXTERNALID', label: 'EXTERNALID', alignment: 'right', sortable: true, filterable: true,
    }, {
      prop: 'POSITIONTYPE', label: 'POSITIONTYPE', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'TRADEBEGTIME', label: 'TRADEBEGTIME', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'TRADEENDTIME', label: 'TRADEENDTIME', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'TRADEDATE', label: 'TRADEDATE', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'CONTRACT', label: 'CONTRACT', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'SCHEDULENAME', label: 'SCHEDULENAME', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'TIMEZONE', label: 'TIMEZONE', alignment: 'left', sortable: true, filterable: true,
    }, {
      prop: 'COUNTERPARTY', label: 'COUNTERPARTY', alignment: 'left', sortable: true,
    }, {
      prop: 'TRADER', label: 'TRADER', alignment: 'left', sortable: true,
    }, {
      prop: 'MARKETAREA', label: 'MARKETAREA', alignment: 'left', sortable: true,
    }, {
      prop: 'POINT', label: 'POINT', alignment: 'left', sortable: true,
    }, {
      prop: 'HUB', label: 'HUB', alignment: 'left', sortable: true,
    }, {
      prop: 'HUBPOINT', label: 'HUBPOINT', alignment: 'left', sortable: true,
    }, {
      prop: 'GROUPCODE1', label: 'GROUPCODE1', alignment: 'left', sortable: true,
    }, {
      prop: 'GROUPCODE2', label: 'GROUPCODE2', alignment: 'left', sortable: true,
    }, {
      prop: 'FIRMNESS', label: 'FIRMNESS', alignment: 'left', sortable: true,
    }, {
      prop: 'ISOFLAG', label: 'ISOFLAG', alignment: 'left', sortable: true,
    }, {
      prop: 'MARKET', label: 'MARKET', alignment: 'left', sortable: true,
    }, {
      prop: 'SCHEDULETYPE', label: 'SCHEDULETYPE', alignment: 'left', sortable: true,
    }, {
      prop: 'USETYPE', label: 'USETYPE', alignment: 'left', sortable: true,
    }, {
      prop: 'TERM', label: 'TERM', alignment: 'left', sortable: true,
    }],
  },
};

const getters = {
  getTableConfig: state => state.tableConfig,
  getTableData: state => state.tableData,
  getCurrentRow: state => state.currentTableRow,
  getChildTableConfig: state => state.childTableConfig,
  getChildTableData: state => state.childTableData,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/attachments/ACES_TRADES_IMPORT/headers').then(({ data }) => {
      commit('loadTableData', data);
      this.$notify(`${data?.length} Import Files(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to load files', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    STRUCTURES_API.get(`/attachments/ACES_TRADES_IMPORT/attachmentId/${currentTableRow.id}/json`).then(({ data }) => {
      commit('currentTableRowChange', data);
      this.$notify(`${data?.length || ''} Record(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to load file records', 'error');
    });
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
    state.childTableData = [];
    
    if (currentRow?.textFileData) {
      var fileData = JSON.parse(`${currentRow?.textFileData}`);
      const trades = fileData?.TRADES?.TRADE;
      state.childTableData = Array.isArray(trades) ? trades : [trades];
    }
  },
  resetTable(state) {
    state.tableData = [];
    state.childTableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, data) {
    state.tableData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
