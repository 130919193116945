import {
  gridConfig,
} from '@/components/Ciso/BaseStore/CisoConfigs';

const intValue = {
  caption: 'INT VALUE',
  dataField: 'val',
  dataType: 'number',
  area: 'data',
  visible: true,
  summaryType: 'sum',
  format: '#,###.####',
  compareFiles: true,
  expanded: true,
  calculateSummaryValue: (e) => {
    let sum = 1;
    let file = 0;
    let compare = 0;
    const child = e.children('row');
    if (child && child.length > 0 && child[0]._rowPath.length > 0 && child[0]._rowPath[0].text === 'Compare') {
      compare = child[0]?._cell[0] ?? 0;
      file = child[1]?._cell[0] ?? 0;
      sum = compare - file;
      if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
      return sum;
    } if (child && child.length > 0 && child[0]._rowPath.length > 0 && child[0]._rowPath[0].text === 'File') {
      file = child[0]?._cell[0] ?? 0;
      compare = child[1]?._cell[0] ?? 0;
      sum = file - compare;
      if (sum > -0.0001 && sum < 0.0001) { sum = 0; }
      return sum;
    }
    return e.cell() && e.cell().originalCell.length > 0 ? e.cell().originalCell[0] : 0;
  },
};

export default {
  configData: {
    fields: [
      // Two instances of INT VALUE to allow filtering by field
      { ...intValue },
      { ...intValue, caption: 'VALUE FILTER', area: 'filter' },
      {
        caption: 'SCID',
        dataField: 'sc',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BA',
        dataField: 'ba',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING DATE',
        dataField: 'date',
        dataType: 'datetime',
        area: 'filter',
        format: 'yyyy-MM-dd',
        expanded: true,
      },
      {
        caption: 'SUB COMPANY',
        dataField: 'subCompany',
        dataType: '',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'TRADING HOUR',
        dataField: 'he',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TRADING INTERVAL',
        dataField: 'ie',
        dataType: 'number',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'File Type',
        dataField: 'cmp',
        dataType: 'string',
        area: 'row',
        expanded: true,
      },
      {
        caption: 'BD SourceType',
        dataField: 'bdSrc',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'BD Order',
        dataField: 'bdOrder',
        dataType: 'number',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'BD Name',
        dataField: 'bd',
        dataType: 'string',
        area: 'column',
        expanded: true,
      },
      {
        caption: 'RSRC ID',
        dataField: 'rsrcId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'RSRC TYPE',
        dataField: 'rty',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },

      {
        caption: 'PRICE NODE ID',
        dataField: 'priceNode',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'INTERTIE ID',
        dataField: 'intertie',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TAG ID',
        dataField: 'tagId',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'TAG CODE',
        dataField: 'tagCode',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SRC',
        dataField: 'src',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SNK',
        dataField: 'snk',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'PATH',
        dataField: 'path',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'POR',
        dataField: 'por',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'POD',
        dataField: 'pod',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'GCA',
        dataField: 'gca',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'LCA',
        dataField: 'lca',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'PSE',
        dataField: 'pse',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'SCHEDULE NAME',
        dataField: 'schdName',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
      {
        caption: 'AREF',
        dataField: 'aref',
        dataType: 'string',
        area: 'filter',
        expanded: true,
      },
    ],
  },
};