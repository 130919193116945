import moment from 'moment';
import { CISO_CMRI_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';
import config from './marketScheduleConfigs';

const state = {
  tableData: [],
  tableConfig: config.fields,
};

const actions = {
  async fetchMarketSchedules({ commit }, args) {
    commit('setTableData', []);

    const params = {
      scs: args.coordinatorListSelected,
      resources: args.resourceListSelected.join(','),
      startDate: args.tradeDateSelected,
      endDate: args.tradeDateSelected,
      marketType: args.marketTypesSelected,
      DivideMW: (args.averageHourlyValueSelected === 'MWH'),
    };

    try {
      const { data } = await CISO_CMRI_API.get('/market-schedules', { params });
      data.forEach((obj) => {
        obj.tzTradingDate = getMomentDateString(obj.tzTradingDate);
        obj.tzMktStartTime = moment(obj.tzMktStartTime).utc().format('YYYY-MM-DD HH:mm');
        obj.tzIntvlStartTime = moment(obj.tzIntvlStartTime).utc().format('YYYY-MM-DD HH:mm');
        obj.tzIntvlEndTime = moment(obj.tzIntvlEndTime).utc().format('YYYY-MM-DD HH:mm');
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to retrieve Market Schedules', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};