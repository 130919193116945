import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import dateUtil from '@/utils/dateStore';
import { PPA_REF_API, PPA_STLMTS_API } from '@/api';
import { isNull, sortBy } from '@/utils/dataUtil';

function fileCycleHandler() {
  const selectedPPAs = this.params.ppaGroupPPAsSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedPPAs) { return null; }

  const filteredHeaders = this.params.ppaSettlementsHeaders.filter(
    ({ tradingDate, ppaId }) => (selectedPPAs.length === 0 || selectedPPAs.includes(ppaId))
      && tradingDate.split('T')[0] >= startDate
      && tradingDate.split('T')[0] <= endDate);

  const selCycles = filteredHeaders
    .map((x) => x.settlementCycle).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const topCycle = selCycles.length > 0 ? selCycles[0] : null;
  this.$store.commit('ciso/setParams', ['cycles', selCycles]);
  this.$store.commit('ciso/setParams', ['cyclesSelected', null]);
  this.$nextTick(() => this.$store.commit('ciso/setParams', ['cyclesSelected', topCycle ? topCycle.value : null]));
  return true;
}

function compareCycleHandler() {
  const selectedPPAs = this.params.ppaGroupPPAsSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedPPAs || !this.params.hasCompareSelected) { return null; }

  const filteredHeaders = this.params.ppaSettlementsHeaders.filter(
    ({ tradingDate, ppaId }) => (selectedPPAs.length === 0 || selectedPPAs.includes(ppaId))
      && tradingDate.split('T')[0] >= startDate
      && tradingDate.split('T')[0] <= endDate);

  const selCycles = filteredHeaders
    .map((x) => x.settlementCycle).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const topCycle = selCycles.length > 0 ? selCycles[0] : null;
  this.$store.commit('ciso/setParams', ['compareCycles', selCycles]);
  this.$store.commit('ciso/setParams', ['compareCyclesSelected', null]);
  this.$nextTick(() => this.$store.commit('ciso/setParams', ['compareCyclesSelected', topCycle ? topCycle.value : null]));
  return true;
}

function fileVersionHandler() {
  const selectedPPAs = this.params.ppaGroupPPAsSelected;
  const selectedCycle = this.params.cyclesSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedPPAs) { return null; }
  if (selectedPPAs.length !== 1 || startDate < endDate) {
    this.$store.commit('ciso/setParams', ['versions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['versionsSelected', 'MAX']);
    return true;
  }

  const filteredHeaders = this.params.ppaSettlementsHeaders.filter(({ tradingDate, ppaId, settlementCycle }) => selectedPPAs.includes(ppaId)
                                                                          && settlementCycle === selectedCycle
                                                                          && tradingDate.split('T')[0] >= startDate
                                                                          && tradingDate.split('T')[0] <= endDate);

  const distinctVersions = filteredHeaders
    .map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = distinctVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  this.$store.commit('ciso/setParams', ['versions', orderedDescendingVersions]);
  this.$store.commit('ciso/setParams', ['versionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

function compareVersionHandler() {
  const selectedPPAs = this.params.ppaGroupPPAsSelected;
  const selectedCycle = this.params.compareCyclesSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);
  // Race Condition check
  if (!startDate || !endDate || !selectedPPAs || !this.params.hasCompareSelected) { return null; }
  if (selectedPPAs.length !== 1 || startDate < endDate) {
    this.$store.commit('ciso/setParams', ['compareVersions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['compareVersionsSelected', 'MAX']);
    return true;
  }

  const filteredHeaders = this.params.ppaSettlementsHeaders.filter(({ tradingDate, ppaId, settlementCycle }) => selectedPPAs.includes(ppaId)
                                                                          && settlementCycle === selectedCycle
                                                                          && tradingDate.split('T')[0] >= startDate
                                                                          && tradingDate.split('T')[0] <= endDate);

  const distinctVersions = filteredHeaders
    .map((x) => x.version).filter((x, i, a) => a.indexOf(x) === i)
    .map((f) => ({ value: f, label: f }));

  const orderedDescendingVersions = distinctVersions.sort((firstVal, nextVal) => {
    if (firstVal.value < nextVal.value) { return 1; }
    if (firstVal.value > nextVal.value) { return -1; }
    return 0;
  });

  const topVersion = orderedDescendingVersions.length > 0 ? orderedDescendingVersions[0] : null;
  this.$store.commit('ciso/setParams', ['compareVersions', orderedDescendingVersions]);
  this.$store.commit('ciso/setParams', ['compareVersionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

export const gridConfig = {
  valFormat: '#,##0.####',
  valFormat2: '#,##0.##',
  currencyFormat: (value) => {
    if (value === null || parseFloat(value, 10) === 0) { return null; }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  },
  zeroCurrencyFormat: (value) => {
    if (value === null || parseFloat(value, 10) === 0) { return '$0'; }
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  },
  valueFormat: (value) => {
    if (value.valueFormat === 'Percentage') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    if (value.valueFormat === 'Currency') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    if (value.valueFormat === 'Fixed Point') {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      });
      return formatter.format(value.val);
    }
    return value.val;
  },
};

export const dateRange = {
  label: 'Date Range',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const tradeDate = {
  label: 'Trade Date',
  value: dateUtil.getDefaultDate().toISOString(),
  type: 'date',
};

export const tradeDateRange = {
  label: 'Trade Dates',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const invoiceDateRange = {
  label: 'Invoice Dates',
  value: (() => dateUtil.getDefaultRange()),
  type: 'dateRange',
};

export const ppaSettlementsHeaders = {
  label: 'ppaSettlementsHeaders',
  value: null,
  type: 'select',
  visible: false,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_STLMTS_API.get('/statements/headers');
      data.forEach((d, idx) => {
        d.value = idx;
      });
      return data;
    } catch (error) {
      vue.$notify('Failed to Load PPA Settlements Headers', 'error');
      console.log(error);
    }
    return [{}];
  },
};

export const marketTypes = {
  label: 'Market Type',
  value: 'RTM',
  type: 'select',
  options: [{ value: 'RTM', label: 'RTM' }, { value: 'DAM', label: 'DAM' }],
  clearable: true,
};

export const ppas = {
  label: 'PPA',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_REF_API.get('/ppa');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.name;
      });
      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load PPAs', 'error');
      console.log(error);
    }
    return [{}];
  },
};

export const invoiceEntities = {
  label: 'InvoiceEntity',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_REF_API.get('/invoice-entity');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.shortName;
      });
      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Invoice Entities', 'error');
      console.log(error);
    }
    return [{}];
  },
};

function configurePpaGroups(ppaGroups) {
  const lst = isNull(ppaGroups, []);
  let data = lst.map((c) => {
    c.value = c.groupName;
    c.label = c.groupName;
    return c;
  });
  data = data.sort((a, b) => {
    if (a.groupName < b.groupName) return -1;
    if (a.groupName > b.groupName) return 1;
    return 0;
  });
  return data;
}

export const ppaGroupTagBox = {
  label: 'Groups',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_REF_API.get('/ppa-groups');
      const results = configurePpaGroups(data);
      return results;
    } catch (error) {
      vue.$notify('Failed to load PPA Groups', 'error');
      console.log(error);
    }
    return [];
  },
};

export const ppaGroupInvoiceEntities = {
  label: 'Invoice Entities',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  watchers: [{
    propertyToWatch: 'ppaGroupTagBoxSelected',
    watchOptions: { immediate: true },
    async handler(newValue, oldValue) {
      try {
        if (!newValue || (newValue !== null && newValue.length === 0)) newValue = 'null';
        const { data: { data } } = await PPA_REF_API.get(`/ppa-groups/invoice-entities/${newValue}`);

        data.forEach((d) => {
          d.value = d.id;
          d.label = d.shortName;
        });

        data.sort(sortBy('label'));

        this.$store.commit('ciso/setParams', ['ppaGroupInvoiceEntities', data]);
      } catch (error) {
        vue.$notify('Failed to load Group Invoice Entities', 'error');
        console.log(error);
      }
    },
  }],
};

export const ppaGroupPPAs = {
  label: 'PPA',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  watchers: [{
    propertyToWatch: 'ppaGroupTagBoxSelected',
    watchOptions: { immediate: true },
    async handler(newValue, oldValue) {
      try {
        if (!newValue || (newValue !== null && newValue.length === 0)) newValue = 'null';
        const { data: { data } } = await PPA_REF_API.get(`/ppa-groups/ppas/${newValue}`);

        data.forEach((d) => {
          d.value = d.id;
          d.label = d.name;
        });

        data.sort(sortBy('label'));

        this.$store.commit('ciso/setParams', ['ppaGroupPPAs', data]);
      } catch (error) {
        vue.$notify('Failed to load Group PPAs', 'error');
        console.log(error);
      }
    },
  }],
};

export const chargeCodes = {
  label: 'Charge Code',
  value: [],
  type: 'tagbox',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_REF_API.get('/charge-codes');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.chargeCode;
      });

      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Charge Codes', 'error');
      console.error(error);
    }
    return [];
  },
};

export const ppaChargeCodeSelect = {
  label: 'Charge Code',
  value: null,
  type: 'select',
  showDropDownButton: true,
  visible: true,
  searchable: true,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_REF_API.get('/charge-codes');
      data.forEach((d, idx) => {
        d.value = d.id;
        d.label = d.chargeCode;
      });

      data.sort(sortBy('label'));
      return data;
    } catch (error) {
      vue.$notify('Failed to Load Charge Codes', 'error');
      console.error(error);
    }
    return [];
  },
  watchers: [{
    propertyToWatch: 'ppasSelected',
    watchOptions: { immediate: true },
    async handler(newValue, oldValue) {
      try {
        const params = {
          ppas: newValue,
          chargeCodes: null,
        };
        const { data: { data } } = await PPA_REF_API.post('/ppa-charge-ref/get', params);

        const charges = data.map(({ chargeCodeId, chargeCode }) => ({ value: chargeCodeId, label: chargeCode }));

        const uniqueCharges = [];
        for (let i = 0; i < charges.length; i++) {
          let found = false;
          for (let j = 0; j < uniqueCharges.length; j++) {
            if (charges[i].value === uniqueCharges[j].value) {
              found = true;
            }
          }
          if (!found) {
            uniqueCharges.push(charges[i]);
          }
        }

        uniqueCharges.sort(sortBy('label'));

        this.$store.commit('ciso/setParams', ['ppaChargeCodeSelect', uniqueCharges]);
      } catch (error) {
        vue.$notify('Failed to load Contract Group Invoice Entities', 'error');
        console.log(error);
      }
    },
  }],
};

export const cycles = {
  label: 'Settlement Cycle',
  value: null,
  type: 'select',
  visible: true,
  required: true,
  options: [],
  watchers: [{
    propertyToWatch: 'ppaSettlementsHeaders',
    handler: fileCycleHandler,
  }, {
    propertyToWatch: 'tradeDateRangeSelected',
    handler: fileCycleHandler,
  }, {
    propertyToWatch: 'ppaGroupPPAsSelected',
    handler: fileCycleHandler,
  }],
};

export const versions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'cyclesSelected',
    handler: fileVersionHandler,
  }],
};

export const hasCompare = {
  label: 'Compare',
  value: false,
  type: 'checkbox',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'hasCompareSelected',
    handler(newValue, oldValue) {
      if (!newValue) {
        this.$store.commit('ciso/setParams', ['compareCyclesSelected', null]);
        this.$store.commit('ciso/setParams', ['compareVersionsSelected', null]);
      }
      if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
        this.$nextTick(() => this.$parent.instance.repaint());
      }
      if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
        this.$nextTick(() => this.$parent.$parent.instance.repaint());
      }
      return true;
    },
  }],
};

export const compareCycles = {
  label: 'Settlement Cycle',
  value: null,
  type: 'select',
  visible: 'hasCompare',
  options: [],
  watchers: [{
    propertyToWatch: 'ppaSettlementsHeaders',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'tradeDateRangeSelected',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'ppaGroupPPAsSelected',
    handler: compareCycleHandler,
  }, {
    propertyToWatch: 'hasCompareSelected',
    handler: compareCycleHandler,
  }],
};

export const compareVersions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: 'hasCompare',
  options: [],
  watchers: [{
    propertyToWatch: 'compareCyclesSelected',
    handler: compareVersionHandler,
  }],
};

export const overrideHeaders = {
  label: 'overrideHeaders',
  value: null,
  type: 'select',
  visible: false,
  options: [],
  async fetchData() {
    try {
      const { data: { data } } = await PPA_STLMTS_API.get('/overrides/headers');
      data.forEach((d, idx) => {
        d.value = idx;
      });
      return data;
    } catch (error) {
      vue.$notify('Failed to Load PPA Override Headers', 'error');
      console.log(error);
    }
    return [];
  },
};

function overrideVersionsHandler() {
  const selectedPpas = this.params.ppasSelected;
  const { startDate, endDate } = getMomentDateStringFromRangeArray(this.params.tradeDateRangeSelected);

  if (!startDate || !endDate) { return null; }

  if (selectedPpas && selectedPpas.length === 0) {
    this.$store.commit('ciso/setParams', ['overrideVersions', [{ value: 'MAX', label: 'MAX' }]]);
    this.$store.commit('ciso/setParams', ['overrideVersionsSelected', 'MAX']);
    return true;
  }

  const filteredOverrideHeaders = this.params.overrideHeaders.filter(({
    tradingDate,
    ppaId,
  }) => selectedPpas.includes(ppaId)
        && tradingDate.split('T')[0] >= startDate
        && tradingDate.split('T')[0] <= endDate);

  const filteredVersions = [...new Set(filteredOverrideHeaders
    .map(({ version }) => (version)))]
    .map((version) => ({ label: version, value: version }));

  const orderedVersions = filteredVersions.sort((a, b) => b.value - a.value);

  const topVersion = orderedVersions.length > 0 ? orderedVersions[0] : null;

  if (endDate > startDate || selectedPpas.length > 1) { orderedVersions.push({ value: 'MAX', label: 'MAX' }); }

  this.$store.commit('ciso/setParams', ['overrideVersions', orderedVersions]);
  this.$store.commit('ciso/setParams', ['overrideVersionsSelected', topVersion ? topVersion.value : null]);
  return true;
}

export const overrideVersions = {
  label: 'Version',
  value: null,
  type: 'select',
  visible: true,
  options: [],
  watchers: [{
    propertyToWatch: 'tradeDateRangeSelected',
    handler: overrideVersionsHandler,
  }, {
    propertyToWatch: 'ppasSelected',
    handler: overrideVersionsHandler,
  }],
};

export const extractFileType = {
  label: 'Extract Type',
  value: 'EXCEL',
  type: 'select',
  options: [{
    label: 'EXCEL', value: 'EXCEL',
  }, {
    label: 'CSV', value: 'CSV',
  }],
};
