import utils from '@/utils';

let _counter = 1;

export function createNotification(source, message, state = null) {
  const item = {
    source: source.toUpperCase(),
    message,
    state,
  };

  const ldt = utils.date.toMomentLocal();
  item.timestamp = ldt;
  item.formattedTimestamp = `${ldt.clone().fromNow()} (${ldt.clone().format('YYYY-MM-DD HH:mm:ss')})`;

  if (item.source === 'WORKFLOW') item.correlation_id = _counter++;
  return item;
}

export function createRandomNotification() {
  const item = createNotification('RANDOM', 'Random Message');

  return { data: item };
}

export function createWorkflowNotification() {
  const item = createNotification('WORKFLOW', 'Workflow STARTED', 'STARTED');
  item.name = 'workflow_name';
  item.message = {
    value: 'Submission of {tagID}',
    args: [
      { name: 'tagID', value: 'Text' },
      {
        name: 'successLink',
        value: 'SuccessText',
        type: 'ParamRoute',
        format: 'route',
        meta: [
          { key: 'id', value: 'success' },
        ],
      },
      {
        name: 'errorLink',
        value: 'ErrorText',
        type: 'ParamRoute',
        format: 'route',
        meta: [
          { key: 'id', value: 'error' },
        ],
      },
    ],
    conds: [
      {
        from: 'tagID', to: 'successLink', property: 'state', value: 'completed', op: '=',
      },
      {
        from: 'tagID', to: 'errorLink', property: 'state', value: 'errored', op: '=',
      },
    ],
  };

  return { data: item };
}

export function createFailedTradeNotification(tradeId) {
  const item = {
    source: 'WORKFLOW',
    name: 'submit-etrm-trade-new',
    severity: 'Error',
    message: {
      value: 'Create Trade ({tradeId})',
      args: [{
        name: 'tradeId', value: tradeId, meta: [], query: [],
      }, {
        name: 'errorLink',
        value: tradeId,
        type: 'Trades',
        format: 'route',
        meta: [],
        query: [{ key: 'tradeId', value: tradeId }, { key: 'startTime', value: '2020-11-01T00:00:00Z' }],
      }],
      conds: [{
        from: 'tradeId', to: 'errorLink', property: 'result', value: 'INVALID', op: '=',
      }],
    },
    state: 'ERRORED',
    correlation_id: '59c74a5ab0a47757eb2d4cb5_20201015205145',
    result: 'INVALID',
    error: {
      details: [{
        name: 'VolumeSellLimit',
        value: 'Volume exceeded SELL limit of 400 vs 450.0000',
      }, {
        name: 'TradeLengthLimit',
        value: 'Trade Length exceeds limit of 1 month(s) between Start Date 2020-11-01 and End Date 2020-12-31',
      }, {
        name: 'TransactionAmountLimit',
        value: 'Transaction Amount total: $21,843,000.0000 exceeded limit of $250,000.0000',
      }, {
        name: 'EndHorizonLimit',
        value: 'Trade Window exceeds limit of 2 month(s) between Trade Date 2020-10-15 and End Date 2020-12-31',
      }, {
        name: 'CounterPartyCreditLimit',
        value: 'Trade amount of $21,843,140.0000 exceeds CounterParty Credit Limit of $1,000,000.0000 by $20,843,140.0000',
      }, {
        name: 'CounterPartyThresholdLimit',
        value: 'Trade amount of $21,843,140.0000 exceeded warning threshold PERCENTAGE of 90.00% with value 2,184.31%',
      }],
      messages: [{
        number: '',
        message: 'One or more trader/credit checks failed during validation process.',
      }],
      eventData: {
        source: 'TRADE',
        tradeId,
      },
    },
  };

  return { data: item };
}

export function createNotificationMessage(item) {
  const { message } = item;
  let format = message.value;
  const { args } = message;
  const trans = message.conds;

  const route = { name: null, params: {}, query: {} };

  const swapped = [];
  if (trans !== null && trans !== undefined && trans.length > 0) {
    Object.entries(item).forEach(([key, value]) => {
      for (let x = 0; x < trans.length; x++) {
        const tr = trans[x];
        if (tr.property.toLowerCase() === key.toLowerCase()) {
          if (tr.value.toLowerCase() === value.toLowerCase()) {
            const f = args.find((s) => s.name === tr.from);
            const t = args.find((s) => s.name === tr.to);
            format = format.replace(f.name, t.name);
            swapped.push(t);
          }
        }
      }
    });
  }

  if (args !== null && args !== undefined && args.length > 0) {
    for (let x = 0; x < args.length; x++) {
      const a = args[x];
      format = format.replace(`{${a.name}}`, a.value);

      if (a.format && a.format.toLowerCase() === 'route') {
        const i = swapped.find((s) => s.name.toLowerCase() === a.name.toLowerCase());
        if (i) {
          route.name = i.type;
          if (i.meta) {
            i.meta.forEach((m) => {
              route.params[m.key] = m.value;
            });
          }

          if (i.query) {
            i.query.forEach((q) => {
              route.query[q.key] = q.value;
            });
          }
        }
      }
    }
  }
  if (route.name !== null) return { message: format, route };
  if (format === null || format === undefined) format = message;
  return { message: format };
}
