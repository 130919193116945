import { TOOLS_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchISOEnvironment({ commit }, args) {
    const params = {
      dataSource: args.dataSourceSelected,
      environment: args.environmentSelected,
      isActive: args.isActiveSelected,
    };
    try {
      const { data } = await TOOLS_API.get('/iso-environment', { params });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load ISO Environment', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};