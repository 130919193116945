import utils from '@/utils';
import { HEColumns, clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  tableKey: -999,
  currentTermTypeRow: null,
  termTypeTableData: [],
  termTypeTableConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', sortable: true, filterable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true, filterable: true,
    }, {
      prop: 'startMonthDay', label: 'Start Month Day', sortable: true, filterable: true,
    }, {
      prop: 'endMonthDay', label: 'End Month Day', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', filterable: true,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, filterable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime', filterable: true,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    description: null,
    startMonthDay: null,
    endMonthDay: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    termTypeDefinitions: [],
  },
  childTableKey: -999,
  currentTermDefRow: null,
  termDefTableData: [],
  termDefTableConfig: {
    columns: [{
      prop: 'dayOfWeek', label: 'Day of Week', sortable: true,
    },
    ...HEColumns(),
    ],
  },
  nullTermDefTableRow: [{
    id: -1,
    termTypeId: null,
    dayOfWeek: 'SUNDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -2,
    termTypeId: null,
    dayOfWeek: 'MONDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -3,
    termTypeId: null,
    dayOfWeek: 'TUESDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -4,
    termTypeId: null,
    dayOfWeek: 'WEDNESDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -5,
    termTypeId: null,
    dayOfWeek: 'THURSDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -6,
    termTypeId: null,
    dayOfWeek: 'FRIDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -7,
    termTypeId: null,
    dayOfWeek: 'SATURDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }, {
    id: -8,
    termTypeId: null,
    dayOfWeek: 'HOLIDAY',
    he01: false,
    he02: false,
    he03: false,
    he04: false,
    he05: false,
    he06: false,
    he07: false,
    he08: false,
    he09: false,
    he10: false,
    he11: false,
    he12: false,
    he13: false,
    he14: false,
    he15: false,
    he16: false,
    he17: false,
    he18: false,
    he19: false,
    he20: false,
    he21: false,
    he22: false,
    he23: false,
    he24: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  }],
};

const getters = {
  getTermTypeTableConfig: (state) => state.termTypeTableConfig,
  getCurrentTermTypeRow: (state) => state.currentTermTypeRow,
  getTermDefTableConfig: (state) => state.termDefTableConfig,
  getCurrentTermDefRow: (state) => state.currentTermDefRow,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTermTypeTableData');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTermTypeTableData({ commit }) {
    STRUCTURES_API.get('/term-types').then(({ data }) => {
      commit('loadTermTypeTableData', data.termTypes);
      this.$notify(`${data.termTypes.length} Term Type(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Term Types', 'error');
    });
  },
  loadTermDefTableData({ dispatch, commit, state }, currentRow) {
    if (currentRow.id > 0) {
      STRUCTURES_API.get(`/term-type-definitions/term/${currentRow.id}`).then(({ data }) => {
        commit('loadTermDefTableData', data);
        currentRow.termTypeDefinitions = data.termTypeDefinitions;
        commit('currentTermTypeRowChange', currentRow);
        this.$notify(`${data.termTypeDefinitions.length} Term Definition(s) Loaded`, 'info');
      }).catch((err) => {
        currentRow.termTypeDefinitions = clone(state.nullTermDefTableRow);
        commit('currentTermTypeRowChange', currentRow);
        this.$notify('Failed to Load Term Definitions', 'error');
      });
    }
  },
  createTermTypeTableRow({ commit }) {
    commit('createTermTypeTableRow');
  },
  createTermDefTableRow({ commit }) {
    commit('createTermDefTableRow');
  },
  postTermType({ commit }, termType) {
    STRUCTURES_API.post('/term-types', termType).then(({ data: { data } }) => {
      commit('addTermType', data);
      this.$notify('Term Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Term Type', 'error');
    });
  },
  updateTermType({ commit }, termType) {
    STRUCTURES_API.put(`/term-types/${termType.id}`, termType).then(({ data }) => {
      commit('updateTermType', data);
      this.$notify('Term Type Updated', 'success');
    }).catch((err) => {
      console.err(err);
      this.$notify('Failed to Update Term Type', 'error');
    });
  },
  deleteTermTypeTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/term-types/${state.currentTermTypeRow.id}`).then(({ data }) => {
      commit('deleteTermTypeTableRow');
      dispatch('currentTermDefRowChange', clone(state.nullTermDefTableRow));
      dispatch('currentTermTypeRowChange', clone(state.nullTableRow));
      this.$notify('Term Type Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Term Type', 'error');
    });
  },
  postTermDefType({ state }, termType) {
    STRUCTURES_API.post('/term-types', termType).then(({ data }) => {
      state.termTypeTableData.push(data);
      this.$notify('Term Type Definition(s) Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Term Type Definitions', 'error');
    });
  },
  currentTermTypeRowChange({ dispatch, commit }, currentRow) {
    if (currentRow) dispatch('loadTermDefTableData', currentRow);
  },
  currentTermDefRowChange({ commit }, currentRow) {
    commit('currentTermDefRowChange', currentRow);
  },
};

const mutations = {
  updateTermType(state, termType) {
    const rowIndex = state.termTypeTableData.findIndex(({ id }) => id === termType.id);
    state.termTypeTableData.splice(rowIndex, 1, termType);
    state.termDefTableData = termType.termTypeDefinitions;
  },
  currentTermTypeRowChange(state, currentRow) {
    state.currentTermTypeRow = currentRow;
  },
  currentTermDefRowChange(state, currentRow) {
    state.currentTermDefRow = currentRow;
  },
  addTermType(state, termType) {
    state.termTypeTableData.push(termType);
  },
  resetTable(state) {
    state.termTypeTableData = [];
    state.currentTermTypeRow = clone(state.nullTableRow);
    state.termDefTableData = [];
    state.currentTermDefRow = clone(state.nullTermDefTableRow);
  },
  loadTermTypeTableData(state, termTypes) {
    state.termTypeTableData = termTypes;
  },
  loadTermDefTableData(state, { termTypeDefinitions }) {
    state.termDefTableData = termTypeDefinitions;
  },
  createTermTypeTableRow(state) {
    const termType = clone(state.nullTableRow);
    const termTypeDefinitions = clone(state.nullTermDefTableRow);
    termType.id = state.tableKey++;
    termType.createdBy = this.getters['auth/getProfile'].userName;
    termType.createdDate = utils.date.getNow().utc().format();
    termTypeDefinitions.forEach((termTypeDef) => {
      termTypeDef.termTypeId = termType.id;
      termTypeDef.termType = termType.shortName;
    });
    termType.termTypeDefinitions = termTypeDefinitions;
    state.currentTermTypeRow = termType;
    state.termDefTableData = [];
  },
  deleteTermTypeTableRow(state) {
    state.termTypeTableData = state.termTypeTableData.filter((termType) => termType.id !== state.currentTermTypeRow.id);
    state.termDefTableData = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};