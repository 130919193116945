import { getMomentDateString, getMomentDateStringFromRangeArray, formatLocalDate } from '@/utils/dateUtil';
import { BILLING_INVOICE_API } from '@/api';
import { dateRange, contractGroupTagBox, contractGroupInvoiceEntity } from '@/components/ContractBilling/BaseStore/contractsBase';

const state = {
  tableData: [],
  unsentRecords: false,
  invoiceIds: [],
  searchFiltersConfig: {
    dateType: {
      label: 'Invoice Date',
      value: 'invoiceDate',
      type: 'radio',
      options: [
        { value: 'invoiceDate', label: 'Invoice Date' },
        { value: 'tradingDate', label: 'Trade Date' },
      ],
      watchers: [{
        propertyToWatch: 'dateTypeSelected',
        handler(newValue, oldValue) {
          if (this.$parent.instance && this.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.instance.repaint());
          }
          this.$store.commit('ciso/setParams', ['dateVisibleSelected', newValue !== 'ids']);
          return true;
        },
      }],
    },
    ids: {
      label: 'ID(s)',
      value: '',
      type: 'text',
      visible: 'idVisible',
    },
    dateRange: { ...dateRange, visible: 'dateVisible' },
    contractGroupList: { ...contractGroupTagBox, visible: 'dateVisible' },
    invoiceEntityList: { ...contractGroupInvoiceEntity, visible: 'dateVisible' },
  },
};

const actions = {
  async fetchSubledger({ commit }, args = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.dateRangeSelected);
    const params = {
      invoiceEntities: args.invoiceEntityListSelected,
      startDate,
      endDate,
      dateType: args.dateTypeSelected,
    };
    try {
      const { data: { data } } = await BILLING_INVOICE_API.post('/subledgers/get', params);

      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.postingDate = getMomentDateString(x.postingDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.accountingSendDate = x.accountingSendDate === null ? null : formatLocalDate(x.accountingSendDate, 'YYYY-MM-DD HH:mm');
        });
        const unsentData = data.filter(({ sentToAccountingFlag }) => sentToAccountingFlag === false);
        commit('setUnsentRecords', (!(unsentData === undefined || unsentData.length === 0)));
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async createSummary({ commit, state }) {
    try {
      const unsentData = state.tableData.filter(({ sentToAccountingFlag, allowSendToAccounting }) => sentToAccountingFlag === false && allowSendToAccounting === true);
      const helper = {};
      const filteredInvoiceIds = unsentData.reduce((r, o) => {
        const key = `${ o.invoiceNumber }-${ o.invoiceEntityName }`;
        if (!helper[key]) {
          helper[key] = {
            invoiceNumber: o.invoiceNumber,
            invoiceEntityName: o.invoiceEntityName,
            postingAmount: o.postingAmount,
            postingVolume: o.postingVolume,
          };
          r.push(helper[key]);
        } else {
          helper[key].postingAmount += o.postingAmount;
          helper[key].postingVolume += o.postingVolume;
        }
        return r;
      }, []);
      commit('setInvoiceIds', filteredInvoiceIds);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to create summary Data', 'error');
    }
  },
  async sendToAccounting({ commit, state }) {
    const filteredInvoiceIds = state.invoiceIds.map((x) => x.invoiceNumber).filter((x, i, a) => a.indexOf(x) === i);
    const params = {
      invoiceIds: filteredInvoiceIds,
    };
    try {
      await BILLING_INVOICE_API.put('/subledgers/send-accounting', params);
      this.$notify('Data Sent', 'success');
      commit('reset');
    } catch (err) {
      console.error(err);
      commit('reset');
      this.$notify('Failed to Send Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setUnsentRecords(state, value) {
    state.unsentRecords = value;
  },
  setInvoiceIds(state, value) {
    state.invoiceIds = value;
  },
  reset(state) {
    state.tableData = [];
    state.unsentRecords = false;
    state.invoiceIds = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};