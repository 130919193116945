import { tradeDateRange, ppas } from '@/components/PPA/BaseStore/ppasBase';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'DATE AND TIME',
    dataField: 'dateAndTime',
    dataType: 'date',
    format: 'MM/dd/yyyy HH:mm:ss',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'COUNTERPARTY',
    dataField: 'counterParty',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RESOURCE',
    dataField: 'resource',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PPA NAME',
    dataField: 'ppaName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'SCHD ENTITY',
    dataField: 'schedulingEntity',
    area: 'column',
    expanded: true,
  }, {
    caption: 'METER ID',
    dataField: 'meterId',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'CHANNEL',
    dataField: 'channel',
    area: 'column',
    expanded: true,
  }],

  fieldsMv90: [{
    caption: 'PPA NAME',
    dataField: 'ppaName',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'UTC TIMESTAMP',
    dataField: 'utcTimeStamp',
    dataType: 'date',
    format: 'MM/dd/yyyy HH:mm:ss',
    area: 'row',
    expanded: true,
  }, {
    caption: 'TRADE DATE',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'tradingHour',
    area: 'row',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'value',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MULTIPLIER',
    dataField: 'multiplier',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SECOND MULTIPLIER',
    dataField: 'secondMultiplier',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'METER TYPE',
    dataField: 'meterType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'METER TYPE 3',
    dataField: 'meterType3',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'REPORT HEADER',
    dataField: 'reportHeader',
    area: 'column',
    expanded: true,
    sortingMethod(a, b) {
      if (typeof a.value !== 'string' || typeof b.value !== 'string') {
        return 0;
      }
      const aLower = a.value.toLowerCase();
      const bLower = b.value.toLowerCase();

      if (aLower < bLower) {
        return -1;
      } if (aLower > bLower) {
        return 1;
      }
      return 0;
    },
  }, {
    caption: 'METER ID',
    dataField: 'meterId',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'CHANNEL',
    dataField: 'channel',
    area: 'column',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    ppas,
  },
};