import utils from '@/utils';
import caisoUtil from '@/utils/caiso/caisoUtils';
import { getMomentDateString } from '@/utils/dateUtil';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { CISO_METER_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  coordinatorList: [],
  resourceList: [],
  nullTableRow: {
    id: -999,
    sc: null,
    rsrcId: null,
    mapRsrcName: null,
    pmax: null,
    pmin: null,
    rsrcType: null,
    uom: null,
    multiplier: null,
    multiplierDenominator: null,
    sourceEntity: null,
    hourlyAggregate: 0,
    factorCalc: 0,
    defaultZero: 1,
    effectiveDate: null,
    terminationDate: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getMapResourceList: (state) => state.resourceList.map(({ longName, resourceType }) => ({ value: longName, label: `${longName} (${resourceType})` })),
};

const actions = {
  async fetchResourceMappings({ commit }, args = {}) {
    const params = {
      scs: (args.schedulingCoordinatorsWithTagboxSelected) ? args.schedulingCoordinatorsWithTagboxSelected.join(',') : args.schedulingCoordinatorsWithTagbox.map((obj) => obj.name).join(','),
    };
    commit('setCoordinatorList', params.scs);
    const resourceList = caisoUtil.getSchedulingCoordinatorResourcesOptions(args.schedulingCoordinatorsWithTagbox,
      args.schedulingCoordinatorsWithTagboxSelected.length >= 1 ? args.schedulingCoordinatorsWithTagboxSelected : args.schedulingCoordinatorsWithTagboxUnfiltered.map((x) => x.scId));
    commit('setResourceList', resourceList);

    try {
      const { data: { data } } = await CISO_METER_API.get('/meter-mapping/map-resource', { params });
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.id = x.mapResourceId;
          x.effectiveDate = x.effectiveDate === null ? null : getMomentDateString(x.effectiveDate);
          x.terminationDate = x.terminationDate === null ? null : getMomentDateString(x.terminationDate);
        });
        commit('setTableData', data);
      } else if (data == null) {
        commit('setTableData', []);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Resource Mapping Data', 'error');
    }
  },
  postRecord({ state }, mapresource) {
    CISO_METER_API.post('/meter-mapping/map-resource', mapresource).then(({ data }) => {
      data.data[0].id = data.data[0].mapResourceId;
      state.tableData.push(data.data[0]);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, mapresource) {
    CISO_METER_API.put(`/meter-mapping/map-resource/${mapresource.id}`, mapresource).then(({ data }) => {
      data.data[0].id = data.data[0].mapResourceId;
      commit('updateRecord', data.data[0]);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    CISO_METER_API.delete(`/meter-mapping/map-resource/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  setCoordinatorList(state, value) {
    state.coordinatorList = value.split(',');
  },
  setResourceList(state, value) {
    state.resourceList = value;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};