import moment from 'moment';
import { CISO_INTERNAL_API } from '@/api';
import { getZoneName, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import dateStore from '@/utils/dateStore';

const state = {
  tableData: [],
};

const actions = {
  async fetchTagSettlementsLog({ commit }, args) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.tradeDateRangeSelected);

    const { tz } = getZoneName(dateStore.getTimeZone());
    const params = {
      startDate,
      endDate,
      searchType: args.searchTypeSelected
    };
    try {
      const { data } = await CISO_INTERNAL_API.get('/reference/tag-log', { params });
      data.forEach((obj) => {
        obj.tzLogDate = new Date(obj.logDate).toLocaleString('en-US', { timeZone: tz });
        obj.logDate = moment(obj.logDate).utc().format('MM/DD/YYYY hh:mm:ss A');
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load Tag Settlements Log', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};