import { tradeDateRange, contracts, meters } from '@/components/ContractBilling/BaseStore/contractsBase';
import { BILLING_REF_API } from '@/api';
import { sortBy } from '@/utils/dataUtil';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'TRADE DATE',
    dataField: 'trDate',
    dataType: 'date',
    format: 'MM/dd/yyyy',
    area: 'row',
    expanded: true,
  }, {
    caption: 'HOUR',
    dataField: 'trHr',
    area: 'row',
    expanded: true,
  }, {
    caption: 'INTERVAL',
    dataField: 'trItvl',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONTRACT NAME',
    dataField: 'contractName',
    dataType: 'string',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SOURCE SYSTEM',
    dataField: 'srcSys',
    area: 'column',
    expanded: true,
  }, {
    caption: 'METER ID',
    dataField: 'mtrId',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'val',
    dataType: 'number',
    area: 'data',
    summaryType: 'sum',
    format: '#,##0.#######',
    expanded: true,
  }, {
    caption: 'INTERVAL LENGTH',
    dataField: 'itvlLen',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'LAST MODIFIED',
    dataField: 'lastMod',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MSMT QUALITY',
    dataField: 'msmtQlty',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PREMISE ID',
    dataField: 'premId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'METER SOURCE ALIAS',
    dataField: 'mtrSrcAlias',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SPID',
    dataField: 'spid',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'METER TYPE',
    dataField: 'mtrType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CHANNEL',
    dataField: 'ch',
    area: 'filter',
    expanded: true,
  }],
  searchFiltersConfig: {
    tradeDateRange,
    contracts,
    meters,
    sourceSystem: {
      label: 'SourceSystem',
      value: 'ALL',
      type: 'select',
      showDropDownButton: true,
      visible: true,
      searchable: false,
      options: [],
      async fetchData() {
        try {
          const { data: { data } } = await BILLING_REF_API.get('/meter/source-systems');
          data.forEach((d, idx) => {
            d.value = d.sourceSystem;
            d.label = d.sourceSystem;
          });

          data.sort(sortBy('label'));
          data.unshift({ value: 'ALL', label: 'ALL' });
          return data;
        } catch (error) {
          vue.$notify('Failed to Load SourceSystems', 'error');
          console.log(error);
        }
        return {};
      },
    },
  },
};