const contractBilling: any = {
  menu: /contract_billing:*/,
  referenceData: {
    menu: /contract_billing:reference_data:*/,
    invoicingCompanies: 'contract_billing:reference_data:invoicing_company_ref',
    invoiceEntities: 'contract_billing:reference_data:invoice_entity_ref',
    contracts: 'contract_billing:reference_data:contract_ref',
    contractGroups: 'contract_billing:reference_data:contract_groups_ref',
    counterpartyMapping: 'contract_billing:reference_data:counterparty_mapping_ref',
    contractCharges: 'contract_billing:reference_data:contract_charge_ref',
    chargeCodes: 'contract_billing:reference_data:charge_code_ref',
    billDeterminant: 'contract_billing:reference_data:bill_determinant_ref',
    banks: 'contract_billing:reference_data:bank_ref',
    pointOfContact: 'contract_billing:reference_data:point_of_contact_ref',
    settlementReference: 'contract_billing:reference_data:settlement_ref',
    price: 'contract_billing:reference_data:price_ref',
    meter: 'contract_billing:reference_data:meter_ref',
    schedule: 'contract_billing:reference_data:schedule_ref',
    etag: 'contract_billing:reference_data:etag_ref',
    reservation: 'contract_billing:reference_data:reservation_ref',
    invoiceApprovalLimit: 'contract_billing:reference_data:invoice_approval_limit',
    interestRates: 'contract_billing:reference_data:interest_rate_ref',
  },
  sourceData: {
    menu: /contract_billing:source_data:*/,
    billingMeter: 'contract_billing:source_data:billing_meter',
    billingPrices: 'contract_billing:source_data:billing_price',
    billingSchedules: 'contract_billing:source_data:billing_schedules',
    billingReservations: 'contract_billing:source_data:billing_reservation',
    billingDeterminantData: 'contract_billing:source_data:billing_determinant',
  },
  invoicing: {
    menu: /contract_billing:invoicing:*/,
    createInvoice: 'contract_billing:invoicing:invoice_create',
    approveInvoice: 'contract_billing:invoicing:invoice_approve',
    readOnlyApprovedInvoice: 'contract_billing:invoicing:invoice_read_only_approved',
    subledger: 'contract_billing:invoicing:invoice_subledger',
    addLineItem: 'contract_billing:invoicing:add_line_item',
    generateFile: 'contract_billing:invoicing:generate_file',
    sendToAccounting: 'contract_billing:invoicing:invoice_subledger:send_to_accounting',
    reviewAccounting: 'contract_billing:invoicing:review_accounting',
    sendToCustomer: 'contract_billing:invoicing:send_to_customer',
    previewFile: 'contract_billing:invoicing:preview_file',
    suppressedOverride: 'contract_billing:invoicing:suppressed_override',
    inactivateApprovedInvoice: 'contract_billing:invoicing:invoice_inactive_approved',
  },
  calculation: 'contract_billing:calculation:billing_calc',
  reporting: 'contract_billing:reporting:billing_reports',
  dataImport: {
    menu: /contract_billing:import:*/,
    manualMeter: 'contract_billing:import:manual_meter',
    internalImports: 'contract_billing:import:internal_imports',
    counterpartyTemplate: 'contract_billing:import:counterparty_template',
    customDrForm: 'contract_billing:import:custom_dr_form',
    mdmsMeter: 'contract_billing:import:mdms_meter',
  },
  settlements: {
    menu: /contract_billing:settlements:*/,
    statement: 'contract_billing:settlements:statement',
    determinantDetail: 'contract_billing:settlements:determinant_detail',
    override: 'contract_billing:settlements:override',
  },
};

export default contractBilling;