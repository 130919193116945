import moment from 'moment';
import 'moment-timezone';
import dateStore from '@/utils/dateStore';

export const ZULU_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const TZ = {
  EST: 'Eastern Standard Time',
  EPT: 'Eastern Prevailing Time',
  CST: 'Central Standard Time',
  CPT: 'Central Prevailing Time',
  MST: 'Mountain Standard Time',
  MPT: 'Mountain Prevailing Time',
  PST: 'Pacific Standard Time',
  PPT: 'Pacific Prevailing Time',
};

// define GMT time zone without exposing it
const GMT = {
  value: 'GMT', label: 'GMT', description: 'Greenwich Mean Time', shortName: 'Greenwich', tz: 'Zulu', dstFlag: false, utcOffset: '00:00', utcDSTOffset: '00:00',
};

export const TZ_DEFINE = [{
  value: 'EST', label: 'EST', description: 'Eastern Standard Time', shortName: 'Eastern', tz: 'America/Cancun', dstFlag: false, utcOffset: '-05:00', utcDSTOffset: '-05:00',
}, {
  value: 'EPT', label: 'EPT', description: 'Eastern Prevailing Time', shortName: 'Eastern', tz: 'America/New_York', dstFlag: true, utcOffset: '-05:00', utcDSTOffset: '-04:00',
}, {
  value: 'CST', label: 'CST', description: 'Central Standard Time', shortName: 'Central', tz: 'America/Belize', dstFlag: false, utcOffset: '-06:00', utcDSTOffset: '-06:00',
}, {
  value: 'CPT', label: 'CPT', description: 'Central Prevailing Time', shortName: 'Central', tz: 'America/Chicago', dstFlag: true, utcOffset: '-06:00', utcDSTOffset: '-05:00',
}, {
  value: 'MST', label: 'MST', description: 'Mountain Standard Time', shortName: 'Mountain', tz: 'America/Phoenix', dstFlag: false, utcOffset: '-07:00', utcDSTOffset: '-07:00',
}, {
  value: 'MPT', label: 'MPT', description: 'Mountain Prevailing Time', shortName: 'Mountain', tz: 'America/Denver', dstFlag: true, utcOffset: '-07:00', utcDSTOffset: '-06:00',
}, {
  value: 'PST', label: 'PST', description: 'Pacific Standard Time', shortName: 'Pacific', tz: 'Pacific/Pitcairn', dstFlag: false, utcOffset: '-08:00', utcDSTOffset: '-08:00',
}, {
  value: 'PPT', label: 'PPT', description: 'Pacific Prevailing Time', shortName: 'Pacific', tz: 'America/Los_Angeles', dstFlag: true, utcOffset: '-08:00', utcDSTOffset: '-07:00',
}];

export function getShortName(abbr) {
  if (abbr === GMT.value) {
    return GMT.shortName;
  }
  return TZ_DEFINE.find((tz) => tz.value === abbr)?.shortName;
}

export function getTimeZone() {
  return dateStore.getTimeZone();
}

export function getZoneName(abbr) {
  if (abbr === GMT.value) {
    return GMT;
  }
  return TZ_DEFINE.find((tz) => tz.value === abbr);
}

export function getTimeZoneByZone(longName) {
  if (longName === GMT.tz) {
    return GMT;
  }
  return TZ_DEFINE.find((tz) => tz.tz === longName);
}

export function loadTimeZoneAbbreviations() {
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return TZ[abbr] || abbr;
  };
}

export function setGlobalTimeZone(tz) {
  moment.tz.setDefault(tz);
}

export function formatDateAndTime(dt) {
  return moment(dt);
}

export function getYesterday() {
  return moment().subtract(1, 'days').startOf('day');
}

export function getToday() {
  return moment().startOf('day');
}

export function getNow() {
  return moment();
}

export function getTodayLocal() {
  return getToday().toDate();
}

export function getTomorrow() {
  return moment().add(1, 'days').startOf('day');
}

export function addDays(mdt, addr) {
  return mdt.clone().add(addr, 'days');
}

export function getYesterdayLocal() {
  return addDays(getToday(), -1).toDate();
}

export function getTomorrowLocal() {
  return addDays(getToday(), 1).toDate();
}

export function getOvermorrowLocal() {
  return addDays(getToday(), 2).toDate();
}

export function getWeekLocal() {
  const start = moment(getTodayLocal()).startOf('week');
  const week = [start.toDate(), start.add(6, 'days').toDate()];
  return week;
}

export function getLastWeekLocal() {
  const start = moment(getTodayLocal()).startOf('week');
  return [start.clone().add(-7, 'days').toDate(), start.add(-1, 'days').toDate()];
}

export function getNextWeekLocal() {
  const start = moment(getTodayLocal()).startOf('week').add(7, 'days');
  return [start.toDate(), start.add(6, 'days').toDate()];
}

export function getTodayToEndOfMonth() {
  const date = moment(getTodayLocal()).startOf('day');
  const endOfMonth = date.clone().startOf('month').add(1, 'month').add(-1, 'days');
  return [date.toDate(), endOfMonth.toDate()];
}

export function getTodayToEndOfNextMonth() {
  const date = moment(getTodayLocal()).startOf('day');
  const endOfMonth = date.clone().startOf('month').add(2, 'month').add(-1, 'days');
  return [date.toDate(), endOfMonth.toDate()];
}

export function getYearBeforeToday() {
  const yesterday = getYesterdayLocal();
  const yearBefore = moment(yesterday).subtract(365, 'days');
  return [yearBefore, yesterday];
}

export function getTimeRange(type, date) {
  let start = moment(date);
  const end = moment(date).add(1, 'days');

  const times = [];
  while (start.isBefore(end)) {
    times.push({ time: start.clone() });
    start = start.add(1, 'hours');
  }

  return times;
}

export function getTimeRangeByMinutes(date, numberOfMinutes) {
  let start = moment(date);
  const end = moment(date).add(1, 'days');

  const times = [];

  while (start < end) {
    times.push({ time: start.clone() });
    start = start.add(numberOfMinutes, 'minutes');
  }

  return times;
}

export function getDateTime(dt, zone) {
  return moment.tz(dt, zone);
}

export function getStartOfDateTime(dt, zone) {
  const t = getDateTime(dt, zone);
  return t.startOf('day');
}

export function betweenStart(dt, st, et) {
  return dt >= st && dt < et;
}

export function betweenEnd(dt, st, et) {
  return dt > st && dt <= et;
}

export function formatDateTime(dt, zone, formatString) {
  return getDateTime(dt, zone).format(formatString);
}

export function formatLocalDate(dt, formatString) {
  return moment(dt).local().format(formatString);
}

export function formatUtcDate(dt, formatString) {
  return moment(dt).utc().format(formatString);
}

export function formatDate(dt, formatString) {
  if (dt === undefined || dt === null) {
    return null;
  }
  if (dt.toString().slice(-1) === 'Z') {
    return formatUtcDate(dt, formatString);
  }
  return formatLocalDate(dt, formatString);
}

export function getMomentDateString(dt) {
  return formatDate(dt, 'YYYY-MM-DD');
}

export function getMomentDateStringFromRange(dtRange) {
  const { startDate, endDate } = dtRange;
  const st = getMomentDateString(startDate);
  const ed = getMomentDateString(endDate);
  return { startDate: st, endDate: ed };
}

export function getMomentDateStringFromRangeArray(dtRangeArr) {
  return getMomentDateStringFromRange({ startDate: dtRangeArr[0], endDate: dtRangeArr[1] });
}

export function formatDataTimes(data, dateProps, zone) {
  for (let xd = 0; xd < data.length; xd++) {
    const item = data[xd];

    for (let xp = 0; xp < dateProps.length; xp++) {
      const prop = dateProps[xp];
      if (item[prop.name] !== null && item[prop.name] !== undefined) {
        const t = getDateTime(item[prop.name], zone).format(prop.format);
        item[prop.name] = t;
      }
    }
  }

  return data;
}

export function toUtcDateFromDate(dt, zone) {
  const ldt = moment.tz(dt, moment.tz.guess());
  const mdt = moment.tz(dt, zone);

  let offsetDiff = mdt.utcOffset() - ldt.utcOffset();
  offsetDiff = Math.abs(offsetDiff);

  mdt.add(offsetDiff, 'minutes');

  return mdt.clone().utc();
}

export function formatMoment(mdt, formatString) {
  return mdt.format(formatString);
}

export function toMoment(strDate, formatString, zone) {
  return moment.tz(strDate, formatString, zone);
}

export function toMomentFromDate(date, zone) {
  return moment.tz(toUtcDateFromDate(date, zone), zone);
}

export function areEqual(left, right) {
  return left.clone().utc().format() === right.clone().utc().format();
}

export function sortComparer(prev, curr) {
  if (prev.momentStartTime.isBefore(curr.momentStartTime)) return -1;

  if (prev.momentStartTime.isSame(curr.momentStartTime)) return 0;

  return 1;
}

export function sortStartTimeComparer(prev, curr) {
  if (curr === undefined) return 0;

  if (prev.momentStartTime.isAfter(curr.momentStartTime)) { return 1; }

  if (prev.momentStartTime.isBefore(curr.momentStartTime)) { return -1; }

  return 0;
}

export function sortEndTimeComparer(prev, curr) {
  if (curr === null || curr === undefined) { return 0; }

  if (prev.momentEndTime.isBefore(curr.momentEndTime)) return -1;

  if (prev.momentEndTime.isSame(curr.momentEndTime)) return 0;

  return 1;
}

export function toLocalDateFromString(stringDateTime, zone) {
  // console.log(zone);
  // console.log(moment.tz.guess());
  // console.log(stringDateTime);
  const mdt = moment.tz(stringDateTime, zone);
  const ldt = moment.tz(stringDateTime, moment.tz.guess());
  const offsetDiff = mdt.utcOffset() - ldt.utcOffset();

  ldt.add(offsetDiff, 'minutes');
  // let ldt = mdt.clone().toDate();
  return ldt.toDate();
}

export function toMomentLocal() {
  const dt = moment.tz(new Date(), moment.tz.guess());
  return dt;
}

export function duration(dt1, dt2) {
  const dur = moment.duration(dt2.diff(dt1));
  return dur;
}

export function toMomentFromUtc(stringDateTime) {
  return moment.tz(stringDateTime, moment.tz.guess());
}

export function isAfterNow(stringDateTime) {
  const futureTime = toMomentFromUtc(stringDateTime);
  const currentTime = toMomentLocal();
  return currentTime.isAfter(futureTime);
}

export function toLocalFromDate(date, tz) {
  const guessTZ = moment.tz.guess();
  const localDate = moment.tz(date, guessTZ);

  const midnightLocal = localDate.clone().startOf('Day');

  const midnightTarget = moment.tz(midnightLocal.clone().format('YYYY-MM-DD'), tz.tz);
  const offset = midnightTarget.utcOffset() - midnightLocal.utcOffset();
  localDate.subtract(-1 * offset, 'minutes');
  return localDate;
}

export function multipleDateFromOption(option, tz = dateStore.timeZone) {
  const zone = getZoneName(tz).tz;
  // Today
  let start = moment().tz(zone).startOf('day');
  let end = start.clone();

  switch (option) {
  case 'Yesterday':
    start = moment().tz(zone).add(-1, 'days').startOf('Day');
    end = start.clone();
    break;
  case 'Tomorrow':
    start = moment().tz(zone).add(1, 'days').startOf('Day');
    end = start.clone();
    break;
  case 'Overmorrow':
    start = moment().tz(zone).add(2, 'days').startOf('Day');
    end = start.clone();
    break;
  case 'This week':
    start = moment().tz(zone).startOf('week');
    end = start.clone().add(6, 'days');
    break;
  case 'Last week':
    start = moment().tz(zone).startOf('week').add('-7', 'days');
    end = start.clone().add(6, 'days');
    break;
  case 'Next week':
    start = moment().tz(zone).startOf('week').add('7', 'days');
    end = start.clone().add(6, 'days');
    break;
  case 'End of Month':
    start = moment().tz(zone).startOf('day');
    end = start.clone().startOf('month').add(1, 'month').add(-1, 'days');
    break;
  case 'End of Next Month':
    start = moment().tz(zone).startOf('day');
    end = start.clone().startOf('month').add(2, 'month').add(-1, 'days');
    break;
  default:
    break;
  }
  return [start, end];
}

export const multipleDateSelectionOptions = {
  shortcuts: [
    'Today',
    'Yesterday',
    'Tomorrow',
    'Overmorrow',
    'This week',
    'Last week',
    'Next week',
    'End of Month',
    'End of Next Month',
  ].map((text) => ({
    text,
    onClick: (picker) => picker.$emit('pick', multipleDateFromOption(text)),
  })),
};

export function singleDateFromOption(option, tz) {
  const zone = getZoneName(tz).tz;

  if (option === 'Today') {
    return moment().tz(zone).startOf('Day');
  }
  if (option === 'Yesterday') {
    return moment().tz(zone).add(-1, 'days').startOf('Day');
  }
  if (option === 'Tomorrow') {
    return moment().tz(zone).add(1, 'days').startOf('Day');
  }
  if (option === 'Overmorrow') {
    return moment().tz(zone).add(2, 'days').startOf('Day');
  }
}

export const singleDateSelectionOptions = {
  shortcuts: [
    'Today',
    'Yesterday',
    'Tomorrow',
    'Overmorrow',
  ].map((text) => ({
    text,
    onClick: (picker) => picker.$emit('pick', singleDateFromOption(text)),
  })),
};