import moment from 'moment';
import { TOOLS_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchEventLog({ commit }, args) {
    const params = {
      Date: args.tradeDateSelected,
    };
    try {
      const { data } = await TOOLS_API.get('/event-log', { params });
      data.forEach((obj) => {
        obj.tzLogDate = moment(obj.tzLogDate).utc().format('HH:mm:ss A');
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load Event Log', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};