import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { TOOLS_API, CISO_SAMC_API } from '@/api';
import config from './removeDataConfigurations';

const state = {
  tableData: [],
  selectedStatementId: '',
  selectedFileType: '',
  shadowType: config.searchFiltersConfig.detailType,
};

const actions = {
  async fetchRedundantData({ commit }, parameters) {
    const coordinatorList = parameters.coordinatorList.filter(
      (x) => parameters.coordinatorListSelected?.includes(x.value),
    );
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeListSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      StartDate: startDate,
      EndDate: endDate,
      shadowType: parameters.detailTypeSelected,
    };
    console.log(params);
    const { data } = await CISO_SAMC_API.get('statements/redundant-data', { params });
    try {
      commit('setRedundantDataTable', data.data);
    } catch {
      this.$notify('Failed to retrieve Market Schedules', 'error');
    }
  },
  async removeData({ commit }) {
    const params = {
      statementId: state.selectedStatementId,
      fileType: state.selectedFileType,
    };
    console.log(params);
    console.log(state);
    try {
      const result = TOOLS_API.delete('/remove-data', { params });
      this.$notify('Successfully deleted data', 'success');
      commit('removeRedundantData', state.selectedStatementId);
    } catch {
      this.$notify('Failed to delete data', 'error');
    }
  },
  highlightRow({ commit, state }, selected) {
    commit('setSelectedStatementId', selected.row.data.statementId);
    commit('setSelectedFileType', selected.row.data.fileType);
  },
};

const mutations = {
  setRedundantDataTable(state, value) {
    state.tableData = value;
  },
  setSelectedFileType(state, fileType) {
    state.selectedFileType = fileType;
  },
  setSelectedStatementId(state, statementId) {
    state.selectedStatementId = statementId;
  },
  removeRedundantData(state, value) {
    state.redundantDataTable = state.redundantDataTable.filter(({ statementId }) => statementId !== value[0].statementId);
  },
  reset(state) {
    state.tableData = [];
    state.selectedFileType = '';
    state.selectedStatementId = '';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};