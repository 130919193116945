
export default {
  tagID: {
    gcaCode: 1190,
    gca: 'PACE',
    pseCode: 2479,
    pse: 'ZES001',
    tagCode: 'PAC0926',
    lcaCode: 167,
    lca: 'CISO',
  },
  templateName: null,
  templateDescription: null,
  implementationDateTime: '2017-11-10T08:00:00Z',
  terminationDateTime: '2017-11-12T08:00:00Z',
  segmentList: [
    {
      marketSegmentID: 1,
      pseCode: 674,
      pse: 'PAC01',
      energyProductRef: 1,
      energyProduct: 'G-F',
      contractNumberList: [],
      miscInfoList: [],
      physicalSegmentList: [
        {
          physicalSegmentID: 1,
          tpCode: null,
          porCode: null,
          podCode: 15601,
          pod: 'CHOLLA-NNH',
          porProfileRef: null,
          podProfileRef: 1,
          schedulingEntityList: [],
          contractNumberList: [],
          miscInfoList: [],
          transmissionAllocationList: [],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 2,
          tpCode: 710,
          tp: 'PPW',
          porCode: 4329,
          por: 'CHOLLA500',
          podCode: 4329,
          pod: 'CHOLLA500',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'PACE',
              entityCode: 1190,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 1,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: '201',
              tcCode: 674,
              tc: 'PAC01',
              profileRef: 1,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 3,
          tpCode: 80,
          tp: 'AZPS',
          porCode: 1817,
          por: 'CHOLLA500',
          podCode: 122,
          pod: 'CHOLLA345',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'AZPS',
              entityCode: 81,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 2,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: '71151256',
              tcCode: 674,
              tc: 'PAC01',
              profileRef: 2,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 4,
          tpCode: 80,
          tp: 'AZPS',
          porCode: 122,
          por: 'CHOLLA345',
          podCode: 127,
          pod: 'WESTWING500',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'AZPS',
              entityCode: 81,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 3,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: '71151064',
              tcCode: 674,
              tc: 'PAC01',
              profileRef: 3,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 5,
          tpCode: 80,
          tp: 'AZPS',
          porCode: 127,
          por: 'WESTWING500',
          podCode: 121,
          pod: 'PALOVERDE500',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'SRP',
              entityCode: 833,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 4,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: '71131682',
              tcCode: 674,
              tc: 'PAC01',
              profileRef: 4,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
      ],
    },
    {
      marketSegmentID: 2,
      pseCode: 1000629,
      pse: 'SGPM',
      energyProductRef: null,
      contractNumberList: [],
      miscInfoList: [],
      physicalSegmentList: [],
    },
    {
      marketSegmentID: 3,
      pseCode: 568,
      pse: 'MSCG01',
      energyProductRef: null,
      contractNumberList: [],
      miscInfoList: [],
      physicalSegmentList: [],
    },
    {
      marketSegmentID: 4,
      pseCode: 719,
      pse: 'PSCMPS',
      energyProductRef: null,
      contractNumberList: [],
      miscInfoList: [],
      physicalSegmentList: [
        {
          physicalSegmentID: 6,
          tpCode: 165,
          tp: 'CISO',
          porCode: 1509,
          por: 'PALOVERDE500',
          podCode: 1509,
          pod: 'PALOVERDE500',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'SRP',
              entityCode: 833,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [
            {
              token: 'CAISO_CONTRACT',
              value: 'NONE',
            },
            {
              token: 'CAISO_PRODUCT',
              value: 'ENGY',
            },
            {
              token: 'CAISO_RES_ID',
              value: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
            },
          ],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 5,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
              tcCode: 719,
              tc: 'PSCMPS',
              profileRef: 5,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 7,
          tpCode: 165,
          tp: 'CISO',
          porCode: 1509,
          por: 'PALOVERDE500',
          podCode: 4485,
          pod: 'PVWEST',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'CISO',
              entityCode: 167,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [
            {
              token: 'CAISO_CONTRACT',
              value: 'NONE',
            },
            {
              token: 'CAISO_PRODUCT',
              value: 'ENGY',
            },
            {
              token: 'CAISO_RES_ID',
              value: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
            },
          ],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 6,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
              tcCode: 719,
              tc: 'PSCMPS',
              profileRef: 6,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
        {
          physicalSegmentID: 8,
          tpCode: 165,
          tp: 'CISO',
          porCode: 4485,
          por: 'PVWEST',
          podCode: 1922,
          pod: 'SP15',
          porProfileRef: 1,
          podProfileRef: 1,
          schedulingEntityList: [
            {
              entity: 'CISO',
              entityCode: 167,
              entityType: 'BA',
            },
          ],
          contractNumberList: [],
          miscInfoList: [
            {
              token: 'CAISO_CONTRACT',
              value: 'NONE',
            },
            {
              token: 'CAISO_PRODUCT',
              value: 'ENGY',
            },
            {
              token: 'CAISO_RES_ID',
              value: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
            },
          ],
          transmissionAllocationList: [
            {
              transmissionAllocationID: 7,
              transProductRef: 10,
              transProduct: '7-F',
              contractNumber: 'PSCB-PALOVRDE_ASR-APND-PVWEST-I-P-F-PSCMPS',
              tcCode: 719,
              tc: 'PSCMPS',
              profileRef: 7,
              miscInfoList: [],
              nitsResource: null,
            },
          ],
          lossAccountingList: [],
        },
      ],
    },
    {
      marketSegmentID: 5,
      pseCode: 2479,
      pse: 'ZES001',
      energyProductRef: 3,
      energyProduct: 'L',
      contractNumberList: [],
      miscInfoList: [],
      physicalSegmentList: [
        {
          physicalSegmentID: 9,
          tpCode: null,
          porCode: 107942,
          por: 'SVCEA-SP15',
          podCode: null,
          porProfileRef: 1,
          podProfileRef: null,
          schedulingEntityList: [],
          contractNumberList: [],
          miscInfoList: [
            {
              token: 'RPS_ID',
              value: '62246A',
            },
            {
              token: 'RPS_ID',
              value: '62859A',
            },
          ],
          transmissionAllocationList: [],
          lossAccountingList: [],
        },
      ],
    },
  ],
  profileSetList: [
    {
      id: 1,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'ENERGY',
    },
    {
      id: 1,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'ENERGY',
    },
    {
      id: 1,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'ENERGY',
    },
    {
      id: 1,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 1,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 1,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 2,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 2,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 2,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 3,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 3,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 3,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 4,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 4,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 4,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 5,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 5,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 5,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 6,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 6,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 6,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 7,
      start: '2017-11-10T08:00:00Z',
      stop: '2017-11-10T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 7,
      start: '2017-11-11T06:00:00Z',
      stop: '2017-11-11T14:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
    {
      id: 7,
      start: '2017-11-12T06:00:00Z',
      stop: '2017-11-12T08:00:00Z',
      mw: 50,
      limitClearing: false,
      profileProductType: 'CAPACITY',
    },
  ],
  ccList: [
    {
      entity: 'FERC',
      entityCode: 1000071,
      entityType: 'PSE',
    },
    {
      entity: 'ZES002',
      entityCode: 1000913,
      entityType: 'PSE',
    },
  ],
  requestID: 0,
  correctionID: 0,
  testFlag: false,
  transactionType: 'NORMAL',
  notes: '',
  compositeState: 'CONFIRMED',
  contactInfo: {
    contact: 'Scheduling Desk',
    phone: '(760)483-5000',
    fax: '(760)483-5000',
  },
};