export class DefaultOptions {
  constructor(name) {
    this.name = name;
    this.configurations = [];
  }

  getConfiguration(name) {
    if (this.configurations) {
      for (let x = 0; x < this.configurations.length; x++) {
        const config = this.configurations[x];
        if (config.name.toLowerCase() === name.toLowerCase()) {
          return config;
        }
      }
    }

    return null;
  }

  ignoreMessage(selectedConfigurationFilters, notificationItem) {

    if (!selectedConfigurationFilters) return false;

    if (!notificationItem) return false;    

    const { data } = notificationItem.message;
    
    if (!data) return false;

    const dataPropNames = Object.keys(data);
    const filterPropNames = Object.keys(selectedConfigurationFilters);

    for (let x = 0; x < filterPropNames.length; x++) {
      const filterProp = filterPropNames[x];

      for (let y = 0; y < dataPropNames.length; y++) {
        const dataProp = dataPropNames[y];

        if (filterProp.toLocaleLowerCase() === dataProp.toLocaleLowerCase()) {

          const dataValue = data[dataProp];
          let filterValue = selectedConfigurationFilters[filterProp];

          if (filterValue === null || filterValue === undefined)
            return true;

          if (filterValue.toLocaleLowerCase() === 'any')
            continue;
  
          const config = this.getConfiguration(filterProp);

          if (config.type === 'tagbox') filterValue = filterValue.split(',');
          
          if (Array.isArray(filterValue)) return !filterValue.includes(dataValue);
          
          return dataValue.toLocaleLowerCase() !== filterValue.toLocaleLowerCase();
        }
      }
    }

    return false;
  }
}