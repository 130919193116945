export default {
  IMPLEMENTED: {
    backgroundColor: '#a9cf54',
  },
  CONFIRMED: {
    backgroundColor: '#a9cf54',
  },
  PENDING: {
    backgroundColor: '#f7e967',
  },
  WITHDRAWN: {
    backgroundColor: '#9d9d9d',
  },
  EXPIRED: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  CANCELLED: {
    backgroundColor: '#9d9d9d',
  },
  TERMINATED: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  DENIED: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  APPROVED: {
    backgroundColor: '#a9cf54',
  },
};