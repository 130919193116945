import utils from '@/utils';
import { STRUCTURES_API } from '@/api';
import { handleError, clone } from '@/utils/dataUtil';
import LOOKUP_STORE from '@/store/lookupStore';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  billingAddress1: null,
  billingAddress2: null,
  billingCity: null,
  billingState: null,
  billingZipCode: null,
  shippingAddress1: null,
  shippingAddress2: null,
  shippingCity: null,
  shippingState: null,
  shippingZipCode: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'shortName', label: 'Short Name', sortable: true, filterable: true,
    }, {
      prop: 'fullName', label: 'Full Name', sortable: true, filterable: true,
    }, {
      prop: 'industry', label: 'Industry', sortable: true, filterable: true,
    }, {
      prop: 'parent', label: 'Parent Company', sortable: true,  filterable: true,
    }, {
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        masterDetailTemplate: 'MasterDetailShippingAndBillingCellTemplate',
      },
      label: 'Billing/Shipping Info',
      prop: 'pendingRequestCount',
       filterable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', dataType: 'date', format: 'yyyy-MM-dd', sortable: true, filterable: true,
    }, {
      prop: 'terminationDate', label: 'Termination Date', dataType: 'date', format: 'yyyy-MM-dd', sortable: true, filterable: true,
    }, {
      prop: 'website', label: 'Website', sortable: true, filterable: true,
    }, {
      prop: 'taxId', label: 'Tax Id', sortable: true, filterable: true,
    }, {
      prop: 'internalFlag', label: 'Internal?', sortable: true, filterable: true,
    }, {
      prop: 'phone', label: 'Phone', sortable: true, filterable: true,
    }, {
      prop: 'fax', label: 'Fax', sortable: true, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, visible: false,
    }, {
      prop: 'createdDate', label: 'Created Date', dataType: 'datetime', sortable: true, visible: false,
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, visible: false,
    }, {
      prop: 'updatedDate', label: 'Updated Date', dataType: 'datetime', sortable: true, visible: false,
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullTableRow: {
    id: -999,
    shortName: null,
    fullName: null,
    industry: null,
    parent: null,
    effectiveDate: null,
    terminationDate: null,
    website: null,
    taxId: null,
    internalFlag: false,
    phone: null,
    fax: null,
    billingAddress1: null,
    billingAddress2: null,
    billingCity: null,
    billingState: null,
    billingZipCode: null,
    shippingAddress1: null,
    shippingAddress2: null,
    shippingCity: null,
    shippingState: null,
    shippingZipCode: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    commodities: [],
    validationMessage: null,
  },
  industryList: [
    { value: 'ENERGY', label: 'ENERGY' },
    { value: 'MUNICIPALITY', label: 'MUNICIPALITY' },
  ],
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getIndustryList: (state) => state.industryList,
  getCompanyAddresses: (state) => state.companyAddresses,
  getBillingAddress1: (state) => state.billingAddress1,
  getBillingAddress2: (state) => state.billingAddress2,
  getBillingCity: (state) => state.billingCity,
  getBillingState: (state) => state.billingState,
  getBillingZipCode: (state) => state.billingZipCode,
  getShippingAddress1: (state) => state.shippingAddress1,
  getShippingAddress2: (state) => state.shippingAddress2,
  getShippingCity: (state) => state.shippingCity,
  getShippingState: (state) => state.shippingState,
  getShippingZipCode: (state) => state.shippingZipCode,
  getCompanyList: (state, localGetters) => localGetters['LOOKUP_STORE/getCompanyList'],
  getCommodityList: (state, localGetters) => localGetters['LOOKUP_STORE/getEnergyCommodityList'],
};

const actions = {
  async initialize({ commit, dispatch }) {
    await dispatch('LOOKUP_STORE/fetchEnergyCommodityList');
    const companyList = await dispatch('LOOKUP_STORE/fetchCompanyList');
    companyList.forEach((company) => {
      const { commodities } = company;
      if (commodities && commodities.length !== 0) {
        company.commodities = commodities.split(',');
      } else {
        company.commodities = [];
      }
    });
    commit('loadTableData', companyList);
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  async addCompany({ dispatch, commit, state }, company) {
    try {
      const { data } = await STRUCTURES_API.post('/companies', company);
      commit('addTableRow', data);
      dispatch('LOOKUP_STORE/fetchCompanyList');
      return { message: 'Company Added', state: 'success' };
    } catch (err) {
      console.error(err);
      return { message: 'Failed to Add Company', state: 'error' };
    }
  },
  async updateCompany({ dispatch, commit, state }, company) {
    try {
      const { data } = await STRUCTURES_API.put(`/companies/${company.id}`, company);
      commit('updateCompany', data);
      return { message: 'Company Updated', state: 'success' };
    } catch (err) {
      console.error(err);
      return { message: 'Failed to Update Company', state: 'error' };
    }
  },
  async removeCompany({ dispatch, commit, state }, company) {
    try {
      await STRUCTURES_API.delete(`/companies/${company.id}`);
      commit('deleteTableRow', company.id);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      return { message: 'Company Removed', state: 'success' };
    } catch (err) {
      console.error(err);
      return { message: 'Failed to Remove Company', state: 'error' };
    }
  },
  currentTableRowChange({ dispatch, commit, state }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};
const mutations = {
  updateCompany(state, company) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === company.id);
    state.tableData.splice(rowIndex, 1, company);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, companies) {
    state.tableData = companies;
  },
  createTableRow(state) {
    const company = clone(state.nullTableRow);
    company.id = state.tableKey++;
    company.versionId = 1;
    company.createdBy = this.getters['auth/getProfile'].userName;
    company.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = company;
  },
  addTableRow(state, company) {
    state.tableData.push(company);
  },
  deleteTableRow(state, id) {
    state.tableData = state.tableData.filter((company) => company.id !== id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { LOOKUP_STORE },
};