import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  currentChildTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'versionId', label: 'Version', sortable: true,
    }, {
      prop: 'shortName', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'accountType', label: 'Account Type', sortable: true,
    }, {
      prop: 'marketName', label: 'Market', sortable: true,
    }, {
      prop: 'company', label: 'Company', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
    accountTypes: [{ value: 'GENERAL', label: 'GENERAL' }, { value: 'COMPLIANCE', label: 'COMPLIANCE' }],
  },
  nullTableRow: {
    id: -999,
    versionId: null,
    accountType: null,
    shortName: null,
    description: null,
    commodityName: 'GHG',
    marketName: null,
    company: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
  childTableData: [],
  childTableConfig: {
    columns: [{
      prop: 'vintageYear', label: 'Vintage Year', sortable: true,
    }, {
      prop: 'jurisdiction', label: 'Jurisdiction', sortable: true,
    }, {
      prop: 'type', label: 'Type', sortable: true,
    }, {
      prop: 'subType', label: 'Sub Type', sortable: true,
    }, {
      prop: 'category', label: 'Category', sortable: true,
    }, {
      prop: 'projectCode', label: 'Project Code', sortable: true,
    }, {
      prop: 'totalQuantity', label: 'Quantity', sortable: true,
    }, {
      prop: 'transferQuantity', label: 'Transferred', sortable: true,
    }, {
      prop: 'remainingQuantity', label: 'Remaining', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }],
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getCurrentRow: (state) => state.currentTableRow,
  getChildTableConfig: (state) => state.childTableConfig,
  getCurrentChildRow: (state) => state.currentChildTableRow,
  getCompanyList: (state, localGetters, rootState) => rootState.lookup.companyList,
  getMarketList: (state, localGetters, rootState) => rootState.lookup.marketList,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchMarketList', null, { root: true });
    dispatch('lookup/fetchCompanyList', null, { root: true });
    dispatch('loadTableData');
  },
  resetTable({ commit }) {
    commit('resetTable');
  },
  loadTableData({ commit }) {
    STRUCTURES_API.get('/environmental/sub-accounts/GHG').then(({ data }) => {
      commit('loadTableData', data);
      this.$notify(`${data.environmentalSubAccounts.length} Greenhouse Sub Account(s) Loaded`, 'info');
    }).catch((err) => {
      this.$notify('Failed to Load Greenhouse Sub Account(s)', 'error');
    });
  },
  loadChildTableData({ dispatch, commit, state }) {
    if (state.currentTableRow) {
      STRUCTURES_API.get(`/environmental/sub-account-holdings/${state.currentTableRow.id}`).then(({ data }) => {
        this.$notify(`${data.environmentalGhgAccountHoldings.length} Greenhouse Sub Account Holding(s) Loaded`);
        commit('loadChildTableData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Greenhouse Sub Account Holdings', 'error');
      });
    }
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postGhgSubAccount({ state }, ghgSubAccount) {
    STRUCTURES_API.post('environmental/sub-accounts', ghgSubAccount).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Greenhouse Sub Account Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Greenhouse Sub Account', 'error');
    });
  },
  updateGhgSubAccount({ commit }, ghgSubAccount) {
    STRUCTURES_API.put(`/environmental/sub-accounts/${ghgSubAccount.id}`, ghgSubAccount).then(({ data }) => {
      commit('updateGhgSubAccount', data);
      this.$notify('Greenhouse Sub Account Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Greenhouse Sub Account', 'error');
    });
  },
  deleteGhgSubAccount({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/environmental/sub-accounts/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteGhgSubAccount');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      dispatch('currentChildTableRowChange', null);
      this.$notify('Greenhouse Sub Account Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Greenhouse Sub Account', 'error');
    });
  },
  currentTableRowChange({ dispatch, commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
    dispatch('loadChildTableData');
  },
  currentChildTableRowChange({ commit }, currentRow) {
    commit('currentChildTableRowChange', currentRow);
  },
};

const mutations = {
  updateGhgSubAccount(state, ghgSubAccount) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === ghgSubAccount.id);
    state.tableData.splice(rowIndex, 1, ghgSubAccount);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  currentChildTableRowChange(state, currentRow) {
    state.currentChildTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
    state.childTableData = [];
    state.currentChildTableRow = null;
  },
  loadTableData(state, { environmentalSubAccounts }) {
    state.tableData = environmentalSubAccounts;
  },
  createTableRow(state) {
    const environmentalSubAccount = clone(state.nullTableRow);
    environmentalSubAccount.id = state.tableKey++;
    environmentalSubAccount.versionId = 1;
    environmentalSubAccount.createdBy = this.getters['auth/getProfile'].userName;
    environmentalSubAccount.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = environmentalSubAccount;
  },
  deleteGhgSubAccount(state) {
    state.tableData = state.tableData.filter(({ id }) => id !== state.currentTableRow.id);
    state.childTableData = [];
  },
  loadChildTableData(state, { environmentalGhgAccountHoldings }) {
    state.currentTableRow.environmentalGhgAccountHoldings = environmentalGhgAccountHoldings;
    state.childTableData = environmentalGhgAccountHoldings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};