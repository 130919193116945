import { ETAG_API, STRUCTURES_API } from '@/api';

const state = {
  computeVisibilityFlag: false,
  computeFlag: false,
  computeResults: null,
  computationName: 'tag_cost_estimator',
  computeConfigName: 'comp-detail-config',
  computeConfig: null,
};

const getters = {
  getComputeVisibilityFlag: (state) => state.computeVisibilityFlag,
  getComputeFlag: (state) => state.computeFlag,
  getComputeResultsVisibleFlag(state) {
    if (!state.computeFlag) return false;
    if (state.computeResults === null) return false;
    if (state.computeConfig) return true;
    return false;
  },
  getComputeResultsTableConfiguration: (state) => state.computeConfig,
  getComputeResultsTableData: (state) => state.computeResults.data,
  getComputeResultsTableSummaryData: (state) => (state.computeResults.summary !== undefined) ? state.computeResults.summary : [],
};

const actions = {
  changeComputeFlag({ commit, dispatch }, state, item) {
    commit('setComputeFlag', item);
    if (state.computeVisibilityFlag && item.flag) {
      const { model } = item;
      if (model.tagID.tagCode === null) model.tagID.tagCode = '0000000';
      return ETAG_API.post(`/tags/computations/${state.computationName}`, model).then((response) => {
        commit('setComputeResults', response.data);
      }).catch((err) => {
        console.log(err);
      });
    }
  },
  // fetchConfiguration({ commit, dispatch }, state) {
  //   if (state.computeVisibilityFlag) {
  //     STRUCTURES_API.get(`/collections/etag-layout/resources/${state.computeConfigName}`).then((response) => {
  //       commit('setComputeConfiguration', response.data);
  //     }).catch((err) => {
  //       console.log(err);
  //     });
  //   }
  // },
};

const mutations = {
  initialize(state) {
    state.computeResults = null;
    state.computeFlag = false;
  },
  setComputeFlag(state, item) {
    state.computeFlag = item.flag;
    if (!state.computeFlag) state.computeResults = null;
  },
  setComputeResults(state, item) {
    state.computeResults = item;
  },
  setComputeConfiguration(state, item) {
    state.computeConfig = item;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};