import qs from 'qs';
import moment from 'moment';
import dateStore from '@/utils/dateStore';
import LOOKUP_STORE from '@/store/lookupStore';
import REFERENCE_DATA_STORE from '@/components/Scheduling/referenceDataStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { getZoneName } from '@/utils/dateUtil';
import { HEColumns, HERows } from '@/utils/dataUtil';
import { createMutations } from '@/utils/vuexHelper';
import {
  IDENTITY_API, ISO_REF_API, PRE_SCHEDULING_API, STRUCTURES_API, BIDDING_API, SCRIPTING_API, DELIVERY_MANAGEMENT_API,
} from '@/api';
import { getOpenMarkets } from '@/components/Scheduling/utils';
import { confirm } from 'devextreme/ui/dialog';
import { saveAs } from 'file-saver';
import {
  VARIANT_ORDER, generateBlotterTableConfig, generateMdConfig, generateSubTableConfig,
} from './static';

export const getMomentRangeFromSystemRange = (dateRange) => {
  const tz = dateStore.getTimeZone();
  const momentRange = dateStore.toMomentAndZoneFromJSDateArray(dateRange, tz);
  const start = momentRange[0].startOf('day').utc();
  const end = momentRange[1].startOf('day').add(1, 'days').utc();
  return [start, end];
};

const generateInterval = (d, add) => {
  const ret = {
    id: d.id,
    direction: d.direction,
    objectReference: d.objectReference,
    objectType: d.type,
    sc: d.isoReference.sc,
    counterParty: d.counterparty,
    pod : d.pod,
    netting: d.netting,
    p: d.isoReference.product,
    startTime: d.startDate?.split('T')[0],
    endTime: d.endDate?.split('T')[0],
    isoReferenceName: d.isoReferenceName,
    type: undefined,
    refType: undefined,
    variant: undefined,
    selected: false,
    // [he]: value ? (value * multiplier) : value,
    location: d.isoReference.resource,
    configuration: d.isoReference.configuration,
    schdType: d.isoReference.selfSchedOrIst,
    resType: d.isoReference.resourceType,
    audit: {
      createdBy: d.createdBy,
      createdDate: d.createdDate,
      updatedBy: d.updatedBy,
      updatedDate: d.updatedDate,
    },
  };

  Object.keys(add).forEach((key) => { ret[key] = add[key]; });
  return ret;
};

const state = {
  allSelected: false,
  isCurrentlyFetchingData: false,
  scs: [],
  companies: [],
  dailyGlobalVariablesData: [],
  variants: [
    { key: 'CONTRACT', label: 'CONTRACT', value: 'CONTRACT' },
    { key: 'CONTRACT', label: 'FORECAST', value: 'FORECAST' },
    { key: 'DA', label: 'DA_INITIAL', value: 'DA_INITIAL' },
    { key: 'DA', label: 'DA_FINAL', value: 'DA_FINAL' },
    { key: 'DA', label: 'DA_FINAL_ADJ', value: 'DA_FINAL_ADJ' },
    { key: 'RT', label: 'RT_INITIAL', value: 'RT_INITIAL' },
    { key: 'RT', label: 'RT_FINAL', value: 'RT_FINAL' },
    { key: 'RT', label: 'RT_FINAL_ADJ', value: 'RT_FINAL_ADJ' },
  ],
  editableVariants: ['DA_INITIAL', 'DA_FINAL_ADJ', 'RT_INITIAL', 'RT_FINAL_ADJ'],
  selectedDates: dateStore.getDefaultRange().map((val) => val.toDate()),
  previousDefaultDates: dateStore.getDefaultRange().map((val) => val.toDate()),
  selectedMarket: 'CAISO',
  selectedSc: [],
  selectedVariants: ['DA_INITIAL'],
  storedVariants: [],
  storedVariant: [],
  selectedLocation: undefined,
  selectedLocationGroupId: undefined,
  selectedLocationGroup: undefined,
  selectedMarketType: undefined,
  variableDialogVisibility: false,
  locationIndexMap: undefined,
  selectedDelivery: undefined,
  blotterData: [],
  locations: [],
  locationList: [],
  locationGroups: [],
  energyCommodities: [],
  termTypes: [],
  blotterConfig: { columns: [] },
  blotterMdConfig: { columns: [] },
  subTableData: [],
  subTableConfig: {},
  activeVariant: undefined,
  initialVariant: undefined,
  isoReferences: [],
  scriptCategories: undefined,
  scripts: undefined,
  locationGroupLocations: [],
  timeZone: '',
  scheduleTypeLookup: {},
  hasConfig: false,
  activeTableIndex: 0, // options: 0,1
};

const EMPTY_HOURLY_VALUE = '---';

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  numberOfDays: (state) => {
    const day1 = dateStore.toMomentFromJSDate(state.selectedDates[0]);
    const day2 = dateStore.toMomentFromJSDate(state.selectedDates[1]);
    const n = day2.diff(day1, 'days');
    return Math.abs(n);
  },
  momentDate: (state) => {
    let today = dateStore.toMomentFromJSDate(state.selectedDates[0]);
    today = today.format('YYYY-MM-DD');
    const fullTz = getZoneName(state.timeZone)?.tz;
    return dateStore.toMoment(today, fullTz);
  },
  fullTz: (state) => getZoneName(state.timeZone).tz,
  scList: (state) => _getList(state.scs, 'name'),
  companyList: (state) => _getList(state.companies, 'shortName'),
  commodityList: (state) => _getList(state.energyCommodities, 'shortName'),
  termTypeList: (state) => _getList(state.termTypes, 'shortName'),
  blotterLocationList: (state) => [...new Set(state.locations)].map((opt) => ({ value: opt, label: opt })),
  isoReferenceList: (state) => _getList(state.isoReferences, 'refName'),
  scriptList: (state) => {
    const scriptCategory = state.scriptCategories
      .find(({ name }) => name === `${ state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`);
    const scripts = (scriptCategory) ? state.scripts.filter(({ categoryId }) => categoryId === scriptCategory.id) : [];
    return [...new Set(scripts.map(({ name }) => name))];
  },
  filteredIsoRefData: (state) => state.isoReferences.filter(({ sc }) => (
    state.selectedSc && state.selectedSc.includes(sc)) || sc === 'CAISO'),
  // filteredLocationList: (state) => (state.selectedLocationGroupId)
  //   ? state.locationList
  //     .filter((location) => state.locationGroupLocations
  //       .map(({ shortName }) => shortName)
  //       .includes(location.shortName))
  //   : state.locationList,

  filteredBlotterLocationList: (state, getters) => (state.selectedLocationGroupId)
    ? getters.blotterLocationList
      .filter((location) => state.locationGroupLocations
        .map(({ shortName }) => shortName)
        .includes(location.value))
    : getters.blotterLocationList,
  tradeTypes: () => LOOKUP_STORE.state.tradeTypeList,
  hasLongDayInRange: (state, getters) => {
    let start = getters.momentRange[0].toISOString();
    const end = getters.momentRange[1].toISOString();
    // single date
    if (start === end) return getters.longDay === start;
    // date range
    while (start !== end) {
      if (getters.longDay === start) return true;
      start = moment(start).add(1, 'day').toISOString();
    }
    return false;
  },
  longDay: () => dateStore.getShortAndLongDays().longDay.toISOString(),
  momentRange: () => {
    const tz = dateStore.getTimeZone();
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(state.selectedDates, tz);
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();
    return [start, end];
  },
};

const actions = {
  async initialize({
    state, getters, dispatch, commit,
  }, meta) {
    const dateRange = dateStore.getDefaultRange().map((val) => val.toDate());
    const { timeZone } = meta;
    commit('setTimeZone', timeZone);
    if (dateRange !== state.previousDefaultDates) {
      commit('setSelectedDates', dateRange);
      commit('setPreviousDefaultDates', dateRange);
    }
    await dispatch('fetchBlotterSettings');
    dispatch('fetchCompanies');
    dispatch('fetchEnergyCommodities');
    dispatch('fetchIsoReferences');
    await dispatch('fetchScs');
    dispatch('fetchTermTypes');
    dispatch('fetchMarketType');
    dispatch('generateConfig');
    dispatch('generateSubConfig');
    await dispatch('fetchReferenceData');
    dispatch('defaultUserSettings');
  },
  clearData({ commit }) {
    commit('setBlotterData', []);
    commit('setSubTableData', []);
    commit('setLocations', []);
  },
  locationSubTypes(locationName) {
    const subTypes = REFERENCE_DATA_STORE.state.locationList.filter(({ shortName }) => shortName === locationName)
      .map(({ locationSubtypes }) => [...locationSubtypes]);
    return subTypes[0].length > 1 ? subTypes[0] : [];
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userLocationGroups = state.REFERENCE_DATA_STORE.locationGroupList;

    if (userLocationGroups?.length > 0) {
      const userLocationGroup = userLocationGroups
        .find(({ shortName }) => shortName === caisoStore.resourceGroupName)
        ?? userLocationGroups[0];

      if (userLocationGroup) {
        commit('setSelectedLocationGroupId', userLocationGroup.id);
        commit('setSelectedLocationGroup', userLocationGroup.shortName);
        dispatch('fetchLocationGroupLocations', userLocationGroup.id);
      }
    }

    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    commit('setSelectedSc', [selectedSc]);
  },
  async search({ dispatch, commit }, numberOfDays) {
    if (numberOfDays >= 5) {
      if (!state.selectedLocation) {
        this.$notify('Please Select a Location', 'warning');
        return;
      }
    } else if (!state.selectedLocationGroupId) {
      this.$notify('Please Select a Location Group', 'warning');
      return;
    }
    if (!state.selectedVariants.length) {
      this.$notify('Please Select Variants', 'warning');
      return;
    }
    let initialVariant = state.selectedVariants[0];
    let activeVariant = state.selectedVariants[0];
    state.selectedVariants.forEach((currentVariant) => {
      if (VARIANT_ORDER[activeVariant] < VARIANT_ORDER[currentVariant]) activeVariant = currentVariant;
      if (VARIANT_ORDER[initialVariant] > VARIANT_ORDER[currentVariant]) initialVariant = currentVariant;
    });
    await commit('setInitialVariant', initialVariant);
    await commit('setActiveVariant', activeVariant);
    await dispatch('fetchData', numberOfDays);
  },
  async generateConfig({ state, commit, getters }) {
    const hasLongDay = getters.hasLongDayInRange;
    const configDate = hasLongDay ? getters.longDay : getters.momentRange[0].toISOString();

    const config = await generateBlotterTableConfig(state.activeVariant, configDate);
    const mdConfig = await generateMdConfig(configDate);
    if (!state.hasConfig) {
      // find index of object in config.columns where prop === 'configuration';
      const configIndex = mdConfig.columns.findIndex(({ prop }) => prop === 'configuration');
      mdConfig.columns.splice(configIndex, 1);
    }
    const column = config?.columns?.find((column) => column.cellTemplate === 'PscsOpenMasterDetailCellTemplate');
    column.editorOptions.config = mdConfig || [];
    commit('setBlotterConfig', config);
  },
  generateSubConfig({ state, commit, getters }) {
    const hasLongDay = getters.hasLongDayInRange;
    const configDate = hasLongDay ? getters.longDay : getters.momentRange[0].toISOString();

    const config = generateSubTableConfig(state.initialVariant, configDate);
    if (!state.hasConfig) {
      // find index of object in config.columns where prop === 'configuration';
      const configIndex = config.columns.findIndex(({ prop }) => prop === 'configuration');
      config.columns[configIndex].visible = false;
    }
    commit('setSubTableConfig', config);
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'],
      market: 'CAISO',
      commodity: 'POWER',
      showSubTypes: true,
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    commit('setLocationList', REFERENCE_DATA_STORE.state.locationList);
  },
  fetchMarketType({ commit, state }) {
    if (state.storedVariants.length === 0) {
      const mt = caisoStore.getMarketType();
      commit('setSelectedMarketType', mt);
      if (mt === 'DAM') {
        commit('setSelectedVariants', ['CONTRACT', 'DA_INITIAL']);
      } else {
        commit('setSelectedVariants', ['DA_FINAL_ADJ', 'RT_INITIAL']);
      }
    }
  },
  async fetchIsoReferences({ commit }) {
    try {
      const { data } = await ISO_REF_API.get('/iso-reference/caiso');
      if (data) commit('setIsoReferences', data);
    } catch (error) {
      this.$notify('Failed to fetch Iso References', 'error');
      console.error(error);
    }
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchCompanies({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/companies');
      commit('setCompanies', data.companies);
    } catch (error) {
      this.$notify('Failed to fetch companies', 'error');
      console.error(error);
    }
  },
  async fetchEnergyCommodities({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/commodities');
      commit('setEnergyCommodities', data.energyCommodities);
    } catch (error) {
      this.$notify('Failed to fetch commodities', 'error');
      console.error(error);
    }
  },
  async fetchTermTypes({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/term-types');
      commit('setTermTypes', data.termTypes);
    } catch (error) {
      this.$notify('Failed to fetch term types', 'error');
      console.error(error);
    }
  },
  async fetchLocationGroupLocations({ commit }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    const lgl = Array.isArray(data.locationGroupLocations)
      ? data.locationGroupLocations.filter((location) => location.marketName === 'CAISO')
      : [];
    commit('setLocationGroupLocations', lgl);
  },
  async fetchLocationList({ commit, state }, params) {
    const { data } = await STRUCTURES_API.get('/locations', params);
    commit('setLocationList', data.data.locations);
  },

  async fetchMarketList({ commit }) {
    const { data } = await STRUCTURES_API.get('/markets');
    commit('setMarketList', data.energyMarkets);
  },
  async saveBatchData({ state, dispatch, getters }, event) {
    if (event.changes && event.changes?.length) {
      await Promise.all(event.changes.map(async (change) => {
        const rowData = await event.component.getDataByKeys([change.key]);
        const { data } = change;
        const keyMap = rowData[0];
        if (data) {
          const objectType = (keyMap.objectType === 'Delivery') ? 'DeliveryManagement' : keyMap.objectType;
          const { objectReference } = keyMap;

          const startDate = moment(keyMap.startTime);
          let endDate = moment(keyMap.endTime);

          // only update end date if it was actually modified
          if (data.endTime) {
            endDate = moment(data.endTime).add('days', 1);
          }
          const hours = HERows({}, true, moment(keyMap.date, 'MM/DD/YYYY'));
          const intervals = Object.keys(data).filter((key) => key.startsWith('he')).map((key) => {
            let hourKey = `${parseInt(key.replace('he', ''), 10)}`;
            if (key.replace('he', '') === '2*') hourKey = '2*';
            const hour = hours.find((x) => x.he === hourKey);
            const { startTime, endTime } = hour;
            const value = data[key] ? Math.abs(data[key]) : data[key];
            return {
              objectReference,
              objectType,
              startTime,
              endTime,
              value,
              variant: keyMap.variant,
              type: keyMap.type,
              effectiveTime: null,
              subType: null,
              note: null,
            };
          });

          await dispatch('saveIntervals', {
            startTime: startDate.toISOString(), endTime: endDate.toISOString(), intervals, objectType, objectReference,
          });
        }
      }));
    }
    dispatch('generateConfig');
    dispatch('generateSubConfig');
  },
  async saveIntervals({ dispatch }, {
    startTime,
    endTime,
    intervals,
    objectType,
    objectReference,
  }) {
    try {
      const res = await PRE_SCHEDULING_API.put(
        `/pre-schedules/${objectReference}/intervals?outputTimeZone=PPT&silent=true`,
        {
          startTime,
          endTime,
          intervals,
          objectType,
          objectReference,
        },
      );
    } catch (error) {
      console.error(error);
    }
    await dispatch('fetchData');
  },
  async fetchData({
    commit, dispatch, getters, state,
  }) {
    state.hasConfig = false;
    if (!state.selectedSc || !state.selectedVariants.length) return;
    commit('setIsCurrentlyFetchingData', true);

    const scs = state.selectedSc.length ? state.selectedSc.join() : null; // state.scs.map(({ name }) => name);
    try {
      const [start, end] = getters.momentRange;
      const params = {
        scs,
        marketName: state.selectedMarket,
        // operatingDate: getters.momentDate.toISOString(),
        startDate: start.toISOString(),
        endDate: end.toISOString(),
        variants: state.selectedVariants,
      };

      if (getters.numberOfDays >= 5) {
        if (!state.selectedLocation) { return; }
        params.resource = state.selectedLocation;
      } else params.locationGroup = state.selectedLocationGroup;
      const { data } = await PRE_SCHEDULING_API.get('/pre-schedules', {
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      if (data) {
        dispatch('mapData', data);
        dispatch('generateConfig');
        dispatch('generateSubConfig');
      }
    } catch (error) {
      console.error(error);
      dispatch('mapData', []);
      commit('setIsCurrentlyFetchingData', false);
    }
  },
  async mapData({
    state, dispatch, commit, getters,
  }, data) {
    let subTableData = [];
    const locations = [];
    const scheduleTypeLookup = {};
    const dst = `${dateStore.getDST(state.selectedDates[0])?.toISOString().split('.')[0]}Z`;

    data.forEach((d) => {
      const intervalMap = {};

      if (d.isoReference.configuration) state.hasConfig = true;
      const location = d.isoReference.resource;
      locations.push(location);
      let scheduleType = d.isoReference.refName.split(':')[2];

      if (scheduleType) {
        scheduleType = scheduleType.toLowerCase();
        if (!scheduleTypeLookup[scheduleType]) { scheduleTypeLookup[scheduleType] = []; }
        scheduleTypeLookup[scheduleType].push(location);
      }

      let multiplier = 1;
      const { resourceType, tradeType } = d.isoReference;
      const { direction } = d;
      if (resourceType === 'LOAD' || resourceType === 'ETIE' || (tradeType === 'IST' && direction === 'SELL')) {
        multiplier = -1;
      }

      // Format intervals returned in data
      d.intervals.forEach((interval) => {
        const {
          objectReference,
          objectType,
          startTime,
          type,
          value,
          variant,
        } = interval;
        const momentDay = moment(startTime);
        const date = momentDay.format('MM/DD/YYYY');

        let hour = momentDay.get('hour') + 1;
        if (dst === startTime) hour = 25;
        if (hour) {
          const he = hour <= 9 ? `he0${hour}` : `he${hour}`;
          const id = `${objectReference}-${date}-${variant}`;
          if (!intervalMap[date]) {
            intervalMap[date] = {
              [variant]: generateInterval(d, {
                id,
                type,
                refType: type,
                objectReference,
                variant,
                date,
                [he]: value ? (value * multiplier) : value,
              }),
            };
          } else if (!intervalMap[date][variant]) {
            intervalMap[date][variant] = generateInterval(d, {
              id,
              type,
              refType: type,
              objectReference,
              variant,
              date,
              [he]: value ? (value * multiplier) : value,
            });
          } else {
            intervalMap[date][variant][he] = value ? (value * multiplier) : value;
          }
        }
      });

      // For every date selected in the range, generate intervals none were returned in data
      const dateRange = state.selectedDates.map((x) => {
        if (moment.isMoment(x)) return x;
        const string = `${x.getMonth() + 1}/${x.getDate()}/${x.getFullYear()}`;
        return moment(string, 'MM/DD/YYYY');
      });

      const currentDate = dateRange[0].clone();
      while (dateRange[1].diff(currentDate) >= 0) {
        const currentDay = currentDate.format('MM/DD/YYYY');
        const tradeStart = moment(d.startDate.split('T')[0]);
        const tradeEnd = moment(d.endDate.split('T')[0]);
        if (!currentDate.isBetween(tradeStart, tradeEnd)
          && !currentDate.isSame(tradeStart)
          && !currentDate.isSame(tradeEnd)) {
          currentDate.add(1, 'day');
          continue;
        }

        let noDataVariant = intervalMap?.[currentDay]
          ? state.selectedVariants.filter((variant) => !Object.keys(intervalMap[currentDay]).includes(variant))
          : state.selectedVariants;

        // stub empty rows
        if (!intervalMap[currentDay]) {
          intervalMap[currentDay] = {};
        }
        if (d.type === 'Delivery') {
          noDataVariant = noDataVariant.filter((variant) => variant !== 'CONTRACT');
        }
        if (d.isoReference.selfSchedOrIst === 'BID AWARD') {
          const ignoreVariants = ['CONTRACT', 'DA_INITIAL', 'RT_INITIAL', 'FORECAST'];
          noDataVariant = noDataVariant.filter((variant) => !ignoreVariants.includes(variant));
        }
        noDataVariant.forEach((variant) => {
          intervalMap[currentDay][variant] = generateInterval(d, {
            id: `${d.id}-${currentDay}-${variant}`,
            objectReference: d.id,
            type: 'VOLUME',
            variant,
            date: currentDay,
            ...HEColumns({}, true, state.selectedDate, 60, null, false)
              .map(({ prop }) => prop)
              .reduce((a, c) => ({
                [c]: null,
                ...a,
              }), {}),
          });
        });
        currentDate.add(1, 'day');
      }

      Object.values(intervalMap).forEach((x) => {
        Object.values(x).forEach((interval) => {
          subTableData.push(interval);
        });
      });

      d.intervals = Object.values(intervalMap)
        .reduce((acc, curr) => {
          Object.values(curr).forEach((x) => { acc = [...acc, x]; });
          return acc;
        }, []);
      d.date = getters.momentDate.toISOString();
      d.intervalMap = intervalMap;
    });

    const groupedData = data.reduce((acc, curr, index) => {
      const location = curr?.isoReference?.resource;
      const sc = curr?.isoReference?.sc;
      const schdType = curr?.isoReference?.selfSchedOrIst;
      const p = curr?.isoReference?.product;
      const resType = curr?.isoReference?.resourceType;
      const key = `${location}-${sc}-${schdType}-${p}-${resType}`;

      if (!acc[key]) {
        acc[key] = {
          id: key,
          location,
          sc,
          schdType,
          p,
          resType,
          date: curr.date,
          intervals: curr.intervals.map((interval) => ({ ...interval, location })),
        };
      } else {
        acc[key].intervals = [...acc[key].intervals, ...curr.intervals];
      }
      return acc;
    }, {});

    // sort sub table data by objectReference and variant
    subTableData = subTableData
      .sort((a, b) => (a.objectReference < b.objectReference) ? 1 : -1)
      .sort((a, b) => {
        if (a.objectReference === b.objectReference) {
          if ((VARIANT_ORDER[a.variant] > VARIANT_ORDER[b.variant])) {
            return 1;
          }
        }
        return -1;
      });

    await commit('setSubTableData', subTableData);
    await commit('setBlotterData', Object.values(groupedData));
    await commit('setLocations', locations);
    await commit('setScheduleTypeLookup', scheduleTypeLookup);
    commit('setIsCurrentlyFetchingData', false);
  },
  async exportToExcel({ state, commit }, data) {
    const ExcelJS = await import('exceljs');
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Power Settlements';
    workbook.lastModifiedBy = 'Power Settlements';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    // Creates data sheet
    const dataSheet = workbook.addWorksheet('Data', {
      views: [{ state: 'frozen', ySplit: 1, xSplit: 2 }],
    });
    const fullHeColumns = HEColumns({}, true, state.selectedDates[0].toISOString());

    const columns = [
      {
        header: 'Id', key: 'objectReference', width: 20,
      }, {
        header: 'Date', key: 'date', width: 20,
      }, {
        header: 'ENT', key: 'sc', width: 20,
      }, {
        header: 'Location', key: 'location', width: 20,
      }, {
      }, {
        header: 'POD', key: 'pod', width: 20,
      }, {
        header: 'SCHD Type', key: 'schdType', width: 20,
      }, {
        header: 'RES Type', key: 'resType', width: 20,
      }, {
        header: 'Counter Party', key: 'counterParty', width: 20,
      }, {
        header: 'Product', key: 'p', width: 20,
      }, {
        header: 'Object Type', key: 'objectType', width: 20,
      }, {
        header: 'Direction', key: 'direction', width: 20,
      }, {
        header: 'Start', key: 'startTime', width: 20,
      }, {
        header: 'End', key: 'endTime', width: 20,
      }, {
        header: 'Variant', key: 'variant', width: 20,
      },
      ...fullHeColumns.map((column) => ({ header: column.label, key: column.prop, width: 6 })),
    ];

    dataSheet.columns = columns;
    const header = dataSheet.getRow(1);
    header.font = { bold: true, size: 10, name: 'Arial' };
    header.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFB5D3E7' } };

    const { subTableData } = state;
    const unlinkedData = subTableData.map((e) => ({ ...e }));
    const columnTotals = { total: 0 };

    unlinkedData.forEach((resource) => {
      dataSheet.addRow(resource);
    });
    dataSheet.addRow(columnTotals);
    const fileName = `Blotter-${moment(state.selectedDates[0]).format('YYYY-MM-DD')}`;

    workbook.xlsx.writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, `${fileName}.xlsx`);
      });
  },
  async postDelivery({ dispatch }, deliveryModel) {
    try {
      if (!deliveryModel.termType) {
        deliveryModel.interval = [{
          quantity: deliveryModel.quantity,
          variant: deliveryModel.variant,
          type: 'VOLUME',
        }];
      } else {
        deliveryModel.timeZone = dateStore.getTimeZone();
      }
      deliveryModel.commodity = 'POWER';
      deliveryModel.entityType = 'SC';
      deliveryModel.market = 'CAISO';
      deliveryModel.type = 'PreSchedule';
      await PRE_SCHEDULING_API.post('/pre-schedules/delivery?outputTimeZone=PPT&silent=true', deliveryModel);
      dispatch('fetchData');
    } catch (error) {
      this.$notify('Failed post delivery', 'error');
      console.error(error);
    }
  },

  // Strategies
  async fetchScriptCategories({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get('categories');
      commit('setScriptCategories', data.data);
    } catch (error) {
      console.error('Error fetching script categories', error);
    }
  },
  async fetchScripts({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get();
      commit('setScripts', data.data.map((props) => props));
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async runStrategy({ state, getters }, strategyModel) {
    const tz = dateStore.getTimeZone();
    const istLocations = [];
    const sibrLocations = [];
    const locationNames = state.subTableData.filter((data) => strategyModel.locations.includes(data.location))
      .map(({ location, sc }) => ({ location, entityName: sc }))
      .reduce((acc, curr) => (!acc.find(({ location, entityName }) => (location === curr.location) && (entityName === curr.entityName)))
        ? [...acc, curr]
        : acc, []);
    const locations = [];
    locationNames.forEach((loc) => {
      const subTypes = actions.locationSubTypes(loc.location);
      if (subTypes.length > 0) {
        subTypes.forEach((st) => {
          locations.push({ location: loc.location, entityName: loc.entityName, LocationSubType: st.shortName });
        });
      } else {
        locations.push({ location: loc.location, entityName: loc.entityName, LocationSubType: null });
      }
    });

    locations.forEach((location) => {
      if (state.scheduleTypeLookup.trade && state.scheduleTypeLookup.trade.includes(location.location)) {
        istLocations.push(location);
      }
      if (state.scheduleTypeLookup.schd && state.scheduleTypeLookup.schd.includes(location.location)) {
        sibrLocations.push(location);
      }
    });
    const startTime = getters.momentDate.toISOString();
    const endTime = getters.momentDate.clone().add(1, 'days').toISOString();

    let openMarkets = await getOpenMarkets(state.selectedDates[0], true);
    openMarkets = openMarkets.filter((x) => x.marketType === strategyModel.marketType);

    if (openMarkets.filter((x) => x.status === 'CLOSED').length > 0) {
      const result = await confirm(
        'The market has already closed for some of the schedules you are attempting to create. Are you sure you want to continue?',
        'Confirm Action',
      );
      if (!result) {
        return;
      }
    }

    const sibrReqBody = {
      runDefaultStrategy: true,
      marketType: strategyModel.marketType,
      module: 'sibrSchedules',
      commodity: 'POWER',
      market: 'CAISO',
      entityType: 'SC',
      startTime,
      endTime,
      tz,
      locations: sibrLocations,
    };

    const istReqBody = {
      runDefaultStrategy: true,
      marketType: strategyModel.marketType,
      module: 'tradeSchedules',
      commodity: 'POWER',
      market: 'CAISO',
      entityType: 'SC',
      startTime,
      endTime,
      tz,
      locations: istLocations,
    };

    try {
      if (sibrLocations && sibrLocations.length > 0) { await BIDDING_API.patch('/strategies', sibrReqBody); }
      this.$notify('Successfully Executed SIBR Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
    try {
      if (istLocations && istLocations.length > 0) { await BIDDING_API.patch('/strategies', istReqBody); }
      this.$notify('Successfully Executed IST Scripts');
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },

  async bulkCascade({ state, getters, commit }, copyModel) {
    const openMarkets = [];
    Object.keys(copyModel.openMarketsCache).forEach((key) => {
      copyModel.openMarketsCache[key].forEach((openMarket) => {
        openMarket.tradingDate = moment(key, 'MM/DD/YYYY').startOf('Day').toISOString();
        openMarkets.push(openMarket);
      });
    });
    commit('setIsCurrentlyFetchingData', true);
    const scs = state.selectedSc.length ? state.selectedSc.join() : null;
    const [start, end] = getMomentRangeFromSystemRange(copyModel.copyDateRange);

    try {
      const params = {
        startTime: start.toISOString(),
        endTime: end.clone().subtract(1, 'day').toISOString(),
        scs,
        marketName: state.selectedMarket,
        operatingDate: getters.momentDate.toISOString(),
        variants: [copyModel.fromVariant],
        timeZone: state.timeZone,
        locationGroup: state.selectedLocationGroup,
        openMarkets,
        ...copyModel,
      };
      await PRE_SCHEDULING_API.post('pre-schedules/bulk-copy', params);
    } catch (e) {
      commit('setIsCurrentlyFetchingData', false);
      console.error('error');
    }
  },
  async fetchStrategyVariables({ state, dispatch }, params = { strategy: undefined }) {
    const {
      commodity, entityType, market, moduleName, selectedDates,
    } = state;
    const reqBody = {
      commodity: 'POWER',
      market: 'CAISO',
      entityType: 'SC',
      module: 'sibrSchedules',
      startTime: selectedDates[0],
      endTime: selectedDates[1],
    };
    if (params.strategy) {
      try {
        const hourlyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get(`/variables/${ params.strategy }`, { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData, strategy: params.strategy });
      } catch (error) {
        console.error('Error Fetching Hourly Strategy Variables', error);
      }
    } else {
      try {
        const hourlyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData });
      } catch (error) {
        console.error(`Error Fetching ${params.type} Variables`, error);
      }
    }
  },
  async mapVariables({ dispatch, state, commit }, { combinedData, type, strategy }) {
    let tableData = [];
    let map = [];
    let variables = combinedData?.daily?.variables || [];
    if (variables) {
      tableData = variables.map(({
        name, valueType, value, id, marketType, location, scriptName,
      }) => ({
        name,
        valueType,
        value,
        id,
        marketType,
        location,
        variableType: 'DAILY',
        strategies: scriptName?.split(',') || [],
      }));
    }
    variables = combinedData?.hourly?.variables || [];
    if (variables) {
      map = variables.reduce((acc, curr) => {
        if (curr.id in acc) {
          acc[curr.id].values.push({
            he: moment(curr.startTime).get('hour') + 1,
            value: curr.value,
          });
        } else {
          acc[curr.id] = {
            id: curr.id,
            name: curr.name,
            valueType: curr.valueType,
            marketType: curr.marketType,
            location: curr.location,
            variableType: 'HOURLY',
            value: EMPTY_HOURLY_VALUE,
            strategies: curr.scriptName?.split(',') || undefined,
            config: {
              id: curr.id,
              name: curr.name,
              valueType: curr.valueType,
              marketType: curr.marketType,
              location: curr.location,
              strategies: curr.scriptName?.split(',') || undefined,
            },
            values: [{
              he: moment(curr.startTime).get('hour') + 1,
              value: curr.value,
            }],
          };
        }
        return acc;
      }, {});

      Object.keys(map).forEach((item) => (tableData.push(map[item])));
    }

    commit('setDailyGlobalVariablesData', tableData);
  },
  async inactivateDelivery({ state, dispatch }) {
    if (state.selectedDelivery && typeof state.selectedDelivery === 'number') {
      const result = await confirm(
        'Are you sure you want to inactivate this Delivery?',
        'Confirm Action',
      );
      if (result) {
        try {
          await DELIVERY_MANAGEMENT_API.delete(`deliveries/${state.selectedDelivery}`);
        } catch (e) {
          console.error(e);
          this.$notify('Failed to inactivate Delivery', 'error');
        } finally {
          await dispatch('fetchData');
        }
      }
    }
  },
  async fetchBlotterSettings({ commit, getters }) {
    try {
      await STRUCTURES_API.get('usercollections/blotter');

      const { data } = await STRUCTURES_API.get('usercollections/blotter/resources/blotter-settings');
      if (data && data.variant && data.variants && typeof data.layout !== 'undefined') {
        commit('setStoredVariant', data.variant);
        commit('setStoredVariants', data.variants);
        commit('setActiveTableIndex', data.layout);
        if (getters.numberOfDays === 0) {
          commit('setSelectedVariants', data.variants);
        } else {
          commit('setSelectedVariants', data.variant);
        }
      }
    } catch ({ response }) {
      if (response.status === 404) {
        await STRUCTURES_API.post('usercollections/blotter', {});
        await STRUCTURES_API.post('usercollections/blotter/resources/blotter-settings', { variant: ['DA_INITIAL'], variants: ['DA_INITIAL'], layout: 0 });
      }
    }
  },
  async updateBlotterSettings({ state }, options) {
    const { variant, variants } = options;
    if (!variant || !variants) return;
    await STRUCTURES_API.put('usercollections/blotter/resources/blotter-settings', { variant, variants, layout: state.activeTableIndex });
  },
};

const mutations = {
  ...createMutations(
    'timeZone',
    'blotterConfig',
    'blotterMdConfig',
    'isCurrentlyFetchingData',
    'allSelected',
    'selectedDates',
    'previousDefaultDates',
    'selectedMarket',
    'selectedSc',
    'selectedVariants',
    'locationIndexMap',
    'locationList',
    'blotterData',
    'locations',
    'locationGroups',
    'isoRefs',
    'scs',
    'companies',
    'energyCommodities',
    'termTypes',
    'activeVariant',
    'initialVariant',
    'isoReferences',
    'scriptCategories',
    'scripts',
    'selectedLocation',
    'selectedLocationGroupId',
    'selectedLocationGroup',
    'locationGroupLocations',
    'subTableData',
    'subTableConfig',
    'selectedMarketType',
    'scheduleTypeLookup',
    'dailyGlobalVariablesData',
    'variableDialogVisibility',
    'selectedDelivery',
    'activeTableIndex',
    'storedVariant',
    'storedVariants',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
  state,
  getters,
  actions,
  mutations,
};