
import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';
import LOOKUP_STORE from '@/store/lookupStore';

const state = {
  catalogType: null,
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'name', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'activeFlag', label: 'Active', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    name: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
  getCatalogType: (state) => state.catalogType,
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    let data = await dispatch('LOOKUP_STORE/fetchCatalogList', { types: state.catalogType });
    if (data) { data = data.filter((i) => i.type.toLowerCase() === state.catalogType.toLowerCase()); }
    commit('loadTableData', data);
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  async addCatalog({ state, commit }, catalog) {
    try {
      catalog.type = state.catalogType;
      const { data } = await STRUCTURES_API.post('/catalogs', catalog);
      commit('addTableRow', data);
      return { message: `${state.catalogType} Added`, state: 'success' };
    } catch (err) {
      console.error(err);
      return { message: `Failed to Add ${state.catalogType}`, state: 'error' };
    }
  },
  async updateCatalog({ commit, state }, catalog) {
    try {
      const { data } = await STRUCTURES_API.put(`/catalogs/${catalog.id}`, catalog);
      commit('updateCatalog', data);
      return { message: `${state.catalogType} Updated`, state: 'success' };
    } catch (err) {
      return { message: `Failed to Update ${state.catalogType}`, state: 'error' };
    }
  },
  async removeCatalog({ dispatch, commit, state }, catalog) {
    try {
      await STRUCTURES_API.delete(`/catalogs/${catalog.id}`);
      commit('deleteTableRow', catalog.id);
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      return { message: `${state.catalogType} Removed`, state: 'success' };
    } catch (err) {
      console.error(err);
      return { message: `Failed to Remove ${state.catalogType}`, state: 'error' };
    }
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateCatalog(state, item) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === item.id);
    state.tableData.splice(rowIndex, 1, item);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state, type) {
    state.catalogType = type;
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, data) {
    state.tableData = data;
  },
  createTableRow(state) {
    const item = clone(state.nullTableRow);
    item.id = state.tableKey++;
    item.createdBy = this.getters['auth/getProfile'].userName;
    item.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = item;
  },
  addTableRow(state, item) {
    state.tableData.push(item);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((item) => item.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { LOOKUP_STORE },
};