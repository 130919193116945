import moment from 'moment';
import { CISO_BASE_SCHD_API } from '@/api';
import { hasPermission } from '@/utils/authUtils';
import {
  clone, has, HEColumns, removeObjProps, cloneDeep,
} from '@/utils/dataUtil';
import { caiso, accessRight } from '@/auth/permission';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import {
  systemStatusColors, scheduleVariantColors, verifySubmitActionBase,
} from '@/components/Scheduling/utils';
import { createMutations } from '@/utils/vuexHelper';
import LOOKUP_STORE from '@/store/lookupStore';
import REFERENCE_DATA_STORE from '../../../referenceDataStore';

const SUBMIT = 0;
const CANCEL = 1;
const CREATE_PROPOSED = 2;
const COPY_PROPOSED = 3;
const CREATE_TRANSACTION = 4;
const RETRIEVE = 5;

const PENDING_VALIDATION = 'PENDING_VALIDATION';
const PROPOSED = 'PROPOSED';

// For DST
const generateConfig = (date) => ({
  columns: [{
    prop: 'bsc', label: 'BSC', fixed: 'left', sortable: true, filterable: true,
  }, {
    prop: 'sc', label: 'SC', fixed: 'left', sortable: true, filterable: true,
  }, {
    prop: 'resource',
    label: 'Resource ID',
    cellTemplate: 'PscsRouteCellTemplate',
    fixed: 'left',
    sortable: true,
    filterable: true,
  }, {
    prop: 'resourceType', label: 'Type', fixed: 'left', sortable: true, filterable: true,
  }, {
    alignment: 'center',
    cellTemplate: 'PscsColoredCellTemplate',
    label: 'Variant',
    prop: 'variant',
    editorOptions: scheduleVariantColors,
    class: true,
    filterable: true,
    sortable: true,
  },
  ...HEColumns({
    cellTemplate: 'PscsStatusCellContent',
    editorOptions: {
      systemStatusColors,
      energyField: 'BaseSchedulePointTotal',
    },
  },
  true,
  date,
  ), {
    prop: 'total', label: 'total', fixed: 'right', sortable: true,
  }],
  summary: [{
    prop: 'configuration', alignment: 'center', summaryType: 'custom', label: 'TOTAL:',
  },
  ...HEColumns({ alignment: 'left', summaryType: 'custom', label: '{0}' }, false, date),
  ],
  // eslint-disable-next-line
  customSummary: sumData,
  options: {
    filterHeader: true,
    exportExcel: true,
    multipleSelection: true,
    cellSelection: true,
    warningHighlight: true,
    highlightRow: true,
    isBidHighlight: true,
  },
  isRowSelected: (row) => {
    if ((row?.scheduleName || has(row, 'rowSelected')) && (!row.isConfig && row.variant === 'ACTUAL')) {
      return true;
    }
    return false;
  },
});

const generateTableHourConfig = (date) => ({
  columns: [{
    prop: 'row', label: ' ', fixed: 'left', width: 70, cssClass: 'header-column',
  },
  ...HEColumns({}, true, date),
  ],
});

const state = {
  date: dateStore.getDefaultDate().toISOString(),
  selectedMarketType: caisoStore.getMarketType(),
  statuses: [],
  multipleSelection: [],
  pendingStatuses: [],
  startHour: 1,
  endHour: 24,
  valueTypeFlag: false,
  hours: [],
  submitOptions: [{
  //   id: CREATE_PROPOSED, name: 'Create Proposed', icon: 'fa fa-file-text-o',
  // }, {
  //   id: COPY_PROPOSED, name: 'Copy All Proposed', icon: 'fa fa-files-o',
  // }, {
    id: CREATE_TRANSACTION, name: 'Create Transaction', icon: 'fa fa-plus-square-o',
  }],
  searchType: 'ALL',
  showOpenHours: false,
  splitConfig: false,
  storedParams: null,
  overrideSearch: true,
  tableData: [],
  tableConfig: generateConfig(),
  tableHourData: [],
  tableHourConfig: generateTableHourConfig(),
  transactionDialogVisible: false,
  isMarketOpen: true,
};

function sumData(opt) {
  const type = state.valueTypeFlag ? 'marketBaseSchedulePointTotal' : 'systemBaseSchedulePointTotal';
  let val = 0;
  if (opt.value?.[type]) val = opt.value[type];
  if (opt.value?.resourceType === 'LOAD' || opt.value?.resourceType === 'ETIE') val *= -1;

  if (opt.summaryProcess === 'start') opt.totalValue = 0;
  else if (opt.summaryProcess === 'calculate') opt.totalValue += val;
  else if (opt.summaryProcess === 'finalize') opt.totalValue = opt.totalValue.toFixed(2);
}

function highlightHours(data) {
  const types = [{ type: 'systemBaseSchedulePointTotal', typeActual: 'marketBaseSchedulePointTotal' }];

  if (Array.isArray(data)) {
    const columns = HEColumns({}, true, state.date);
    data.forEach((summary) => {
      summary.highlightRow = true;
      summary.highlight = [];
      columns.forEach(({ prop }) => {
        const val = summary[prop];
        types.forEach((type) => {
          if (val && has(val, [type.type, type.typeActual]) && val[type.type] !== val[type.typeActual]) {
            summary.highlight.push(prop);
          }
        });
      });
    });
  }

  return data;
}

function filterTableData(data, searchType, valueTypeFlag, splitConfigFlag) {
  if (!data) return [];
  const type = valueTypeFlag ? 'marketBaseSchedulePointTotal' : 'systemBaseSchedulePointTotal';
  data.forEach((status) => {
    status.valueTypeFlag = valueTypeFlag;
    let total = 0;
    Object.keys(status).forEach((key) => {
      if (key.includes('he')) {
        let factor = 1;
        if (status[key] && (status[key].resourceType === 'LOAD' || status[key].resourceType === 'ETIE')) factor = -1;
        if (status[key] && status[key][type]) total += status[key][type] * factor;
      }
    });
    status.total = total.toFixed(2);
  });

  data = data.filter((o) => {
    if (searchType === 'ALL') return o;
    if (searchType === 'SAVED') return o.variant === 'ACTUAL' || o.variant === PENDING_VALIDATION;
    if (searchType === 'PENDING') return o.variant === PENDING_VALIDATION;
    if (searchType === 'NOTSCHEDULED') return o.variant === 'NOT_EXISTS';
    return false;
  });

  data = splitConfigFlag
    ? data.filter(({ isConfig, isResource }) => isConfig || !isResource)
    : data.filter(({ isConfig, isResource }) => !isConfig || isResource);

  return data;
}

const getters = {
  getIsMarketOpen: (state) => state.isMarketOpen,
  hasWritePermission(_state, _getters, _rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], caiso.scheduling.baseSchedules, accessRight.write);
  },
  hasReadPermission(_state, _getters, _rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], caiso.scheduling.baseSchedules, accessRight.read);
  },
  getTableData: (state) => filterTableData(state.tableData, state.searchType, state.valueTypeFlag, state.splitConfig),
  getFeatureFlags: () => LOOKUP_STORE.state.userFeatureFlagList,
};

const actions = {
  async initialize({ dispatch, commit }) {
    // eslint-disable-next-line
    const payload = { referenceItemList: ['fetchLocationList', 'fetchLocationGroupList'], market: 'CAISO', commodity: 'POWER' };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    dispatch('loadHours');
    dispatch('LOOKUP_STORE/fetchUserFeatureFlagList');
    commit('setShowOpenHours', state.showOpenHours);
  },
  loadHours({ commit, state }) {
    const hours = dateStore.getTimeRange(state.date, 60).map((v) => ({ value: v, label: v.he }));

    commit('setStartHour', hours[0].value);
    commit('setEndHour', hours[hours.length - 1].value);

    const curr = moment().add(115, 'minutes');
    const date = moment(state.date);
    commit('setIsMarketOpen', true);

    if (state.selectedMarketType === 'RTM' && date.isSame(curr, 'day')) {
      const idx = curr.hours();
      commit('setStartHour', hours[idx].value);
    } else if (date.isBefore(curr)) {
      commit('setIsMarketOpen', false);
      commit('setStartHour', hours[hours.length - 1].value);
      commit('setEndHour', hours[hours.length - 1].value);
    }
    commit('setHours', hours);
  },
  async copyForward({ state }, item) {
    const scheduleNames = state.multipleSelection.filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, 'PPT');
    const [startTime, endTime] = momentRange;

    const payload = {
      startTime: startTime.toISOString(),
      endTime: endTime.add('days', 1).toISOString(),
      marketType: item.copyMarketType,
      variant: 'ACTUAL',
      actionType: 'Copy',
      scheduleNames,
    };

    try {
      await CISO_BASE_SCHD_API.put('copy', payload);
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async loadSummaries({ commit, state, dispatch }, inputParams) {
    const props = ['highlight', 'highlightRow', 'productType', 'total', 'rowType', 'valueTypeFlag',
      'isResource', 'isConfig', 'he25', ...HEColumns().map((x) => x.prop)];
    try {
      const params = {
        start: inputParams.start,
        searchType: inputParams.searchType,
        location: inputParams.selectedLocation,
        locationType: inputParams.locationType,
        locationGroup: inputParams.locationGroup,
        marketType: inputParams.marketType,
      };

      const { data } = await CISO_BASE_SCHD_API.get('summaries', { params });
      const clonedData = data.summaries.map((row) => removeObjProps(cloneDeep(row), ...props));
      const clonedTableData = state.statuses.map((row) => removeObjProps(cloneDeep(row), ...props))
        .filter(({ configuration }) => !configuration);
      if (JSON.stringify(clonedData) !== JSON.stringify(clonedTableData)) {
        commit('setStatuses', data.summaries);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch('loadHours');
    }
  },
  async verifySubmit({ state, dispatch }, item) {
    verifySubmitActionBase({ state, dispatch }, item, false);
  },
  retrieve({ dispatch }) {
    dispatch('submitAction', { id: 5 });
  },
  submitAction({ state, dispatch, commit }, item) {
    if (item.id === CREATE_TRANSACTION) {
      commit('setTransactionDialogVisible', true);
      return;
    } if (state.multipleSelection.length === 0) {
      this.$notify({ type: 'warning', message: 'No Schedules Selected' });
      return;
    } if (state.multipleSelection.find((m) => m.variant.toLowerCase() === PROPOSED)) {
      this.$notify({ type: 'warning', message: 'Cannot submit proposed schedules' });
      return;
    }

    const columns = HEColumns({}, true, state.date);
    state.multipleSelection.forEach((selected) => {
      columns.forEach(({ prop, timeTZ }) => {
        if (timeTZ.isBetween(state.startHour.timeTZ, state.endHour.timeTZ, null, '[]')) {
          if (selected && has(selected, prop)) selected[prop].systemStatus = 'IN_PROGRESS';
        }
      });
    });

    const scheduleNames = state.multipleSelection
      .filter((x) => x.variant === 'ACTUAL').map((x) => x.scheduleName);

    if (!scheduleNames || scheduleNames.length === 0) {
      this.$notify({ type: 'warning', message: 'No selected schedules can be submitted.' });
      return;
    }

    const params = {
      startTime: state.startHour.timeTZ.toISOString(),
      endTime: state.endHour.timeTZ.add(1, 'hour').toISOString(),
      marketType: state.selectedMarketType,
      variant: 'ACTUAL',
      scheduleNames,
    };

    if (item.id === RETRIEVE) dispatch('retrieveBaseSchedules', params);
    else { dispatch('submitBaseSchedules', params); }
  },
  async submitBaseSchedules({ state }, params) {
    try {
      await CISO_BASE_SCHD_API.patch('', { ...params });
      this.$notify({ type: 'success', message: 'Submit Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Submit Failed' });
      console.error(error);
    }
  },
  async retrieveBaseSchedules({ state, commit }, params) {
    try {
      const payload = { url: 'bsap', method: 'put', data: { ...params } };
      await CISO_BASE_SCHD_API.request(payload);
      this.$notify({ type: 'success', message: 'Retrieve Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Retrieve Failed' });
      console.error(error);
    }
  },
};

const mutations = {
  setIsMarketOpen(state, value) {
    state.isMarketOpen = value;
  },
  setStartHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour > state.endHour) state.startHour = state.endHour;
    else state.startHour = value;
  },
  setEndHour(state, value) {
    const hour = value.timeTZ.hours();
    if (hour < state.startHour) state.endHour = state.startHour;
    else state.endHour = value;
  },
  setTableHourData(state, item) {
    const data = ['System', 'Market', 'Status', 'Message', 'Updated By', 'Updated', 'Submitted']
      .map((row) => ({ row }));
    const type = 'systemBaseSchedulePointTotal';
    const typeActual = 'marketBaseSchedulePointTotal';

    HEColumns({}, true, state.date).forEach(({ prop }) => {
      const obj = item[prop];
      if (obj) {
        data[0][prop] = obj?.[type];
        data[1][prop] = obj?.[typeActual];
        data[2][prop] = obj.marketStatus;
        data[3][prop] = obj.errorMessage;
        data[4][prop] = obj.updatedBy;
        data[5][prop] = moment(obj.updatedDate).format('MM/DD/YY HH:mm:ss');
        data[6][prop] = obj.submittedDate ? moment(obj.submittedDate).format('MM/DD/YY HH:mm:ss') : obj.submittedDate;
      }
    });
    state.tableHourData = data;
  },
  setStatuses(state, summaries) {
    // resets config
    state.tableConfig = generateConfig(state.date);
    state.tableHourConfig = generateTableHourConfig(state.date);

    state.tableHourData = [];
    state.multipleSelection = [];
    summaries = summaries.reduce((acc, sum) => {
      sum.isResource = !!sum.configurations.length;
      sum.isConfig = false;
      acc.push(sum);
      sum.configurations.forEach((config) => {
        // Declare newSum to prevent the statuses from the base object getting overridden
        const newSum = cloneDeep(sum);
        newSum.resource = config;
        newSum.configuration = config;
        newSum.statuses = sum.statuses.filter(({ configuration }) => configuration === config);
        newSum.configurations = [];
        newSum.isConfig = true;
        newSum.isResource = false;
        acc.push(newSum);
      });
      return acc;
    }, []);
    const flattened = summaries.map((summary) => {
      const flatStatuses = summary.statuses.reduce((acc, status) => {
        const key = `he${(`0${status.hour}`).slice(-2)}`;
        if (!acc[key]) {
          acc[key] = { ...status, resourceType: summary.resourceType, configuration: null };
        } else {
          acc[key].marketBaseSchedulePointTotal += status.marketBaseSchedulePointTotal;
          acc[key].systemBaseSchedulePointTotal += status.systemBaseSchedulePointTotal;

          if (!acc[key].errorMessage && status.errorMessage) {
            acc[key].errorMessage = status.errorMessage;
          } else if (acc[key].errorMessage && status.errorMessage) {
            acc[key].errorMessage += `\n${status.errorMessage}`;
          }

          if (!acc[key].systemStatus && status.systemStatus) acc[key].systemStatus = status.systemStatus;
          if (!acc[key].submittedDate && status.submittedDate) acc[key].submittedDate = status.submittedDate;
          if (!acc[key].marketStatus && status.marketStatus) acc[key].marketStatus = status.marketStatus;
        }
        return acc;
      }, {});
      return { ...summary, ...flatStatuses };
    });
    state.statuses = flattened;
    state.tableData = highlightHours(flattened);
  },
  reset(state) {
    state.tableHourData = [];
    state.multipleSelection = [];
    state.overrideSearch = true;
  },
  setShowOpenHours(state, value) {
    state.showOpenHours = value;
    const config = generateConfig(state.date);
    if (value) {
      config.columns.forEach((col) => {
        if (
          col.isHourEndingColumn
          && state.selectedMarketType === 'RTM'
          && col.timeTZ.isBefore(moment())
        ) {
          col.visible = false;
        }
      });
    }
    state.tableConfig = config;
  },
  ...createMutations(
    'date',
    'hours',
    'multipleSelection',
    'selectedMarketType',
    'splitConfig',
    'storedParams',
    'transactionDialogVisible',
    'valueTypeFlag',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE, LOOKUP_STORE },
  state,
  getters,
  mutations,
  actions,
};