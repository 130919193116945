import dateStore from '@/utils/dateStore';
import { hasPermission } from '@/utils/authUtils';
import { ETAG_API } from '@/api';

const state = {
  tableConfiguration: {
    columns: [{
      label: 'route', cellTemplate: 'VariantSummaryRouteCell', width: 70, filterable: true, sortable: true,
    }, {
      prop: 'name', filterable: true, sortable: true,
    }, {
      prop: 'description', filterable: true, sortable: true,
    }, {
      prop: 'gca', width: 100, filterable: true, sortable: true,
    }, {
      prop: 'cpse', width: 100, filterable: true, sortable: true,
    }, {
      prop: 'lca', width: 100, filterable: true, sortable: true,
    }, {
      prop: 'source', width: 125, filterable: true, sortable: true,
    }, {
      prop: 'sink', width: 125, filterable: true, sortable: true,
    }, {
      prop: 'gpe', width: 100, filterable: true, sortable: true,
    }, {
      prop: 'lpe', width: 100, filterable: true, sortable: true,
    }, {
      prop: 'lastActionDateTime', width: 125, label: 'date created', filterable: true, sortable: true,
    }, {
      prop: 'category', label: 'Category', sortable: true, filterable: true,
    }],
    options: {
      filterRow: true,
      filterPanel: false,
      filterHeader: true,
    },
  },
  datePropsAndFormats: [{ name: 'lastActionDateTime', format: 'YYYY-MM-DD HH:mm' }],
  tableData: [],
  variant: null,
};

const getters = {
  getWriteAccessFlag(state, rootState, getters, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write');
  },
  getReadAccessFlag(state, rootState, getters, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'write')
                || hasPermission(rootGetters['auth/getPermissions'], 'etag:tag', 'read');
  },
};

const actions = {
  async loadVariantAction({ commit, dispatch, state }) {
    try {
      const { data } = await ETAG_API.get(`tags/variants/${state.variant}`);
      commit('loadVariantMutation', data.tagSummaries);
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  loadVariantMutation(state, data) {
    dateStore.formatDateTimeFields(data, state.datePropsAndFormats);
    state.tableData = data;
  },
  setVariant(state, item) {
    state.variant = item;
  },
  clear(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};