import utils from '@/utils';
import { WORKFLOW_API } from '@/api';
import dateStore from '@/utils/dateStore';

const state = {
  notificationCallback: null,
  tableConfiguration: {
    columns: [{
      prop: 'place', label: 'step', filter: true, sortable: true,
    }, {
      prop: 'tokenStatus', label: 'status', filter: true, class: true, sortable: true,
    }, {
      prop: 'createdTime', label: 'created time', filter: true, sortable: true,
    }, {
      prop: 'updatedTime', label: 'updated time', filter: true, sortable: true,
    }],
    footer: { count: true },
    style: { maxHeight: 700 },
  },
  datePropsAndFormats: [
    { name: 'createdTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'updatedTime', format: 'YYYY-MM-DD HH:mm' },
  ],
  tableData: [],
  refreshRate: 15,
  minRefreshRate: 5,
  maxRefreshRate: 60,
  counter: 0,
  workflowName: null,
  caseId: null,
  edgeNodeList: null,
};

const getters = {
  getStatusTableConfiguration: state => state.tableConfiguration,
  getStatusTableData: state => state.tableData,
  getStatusCounter: state => state.counter,
  getStatusRefreshRate: state => state.refreshRate,
  getStatusMinRefreshRate: state => state.minRefreshRate,
  getStatusMaxRefreshRate: state => state.maxRefreshRate,
  getStatusMinHeight: state => (state.tableData && state.tableData.length > 0) ? 600 : 100,
  getStatusWorkflowName: state => state.workflowName,
  getStatusCaseId: state => state.caseId,
  getStatusEdgeNodeList: state => state.edgeNodeList,
};

const actions = {
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  loadTokens({ commit, dispatch, state }) {
    const workflowName = getters.getStatusWorkflowName(state);
    const caseId = getters.getStatusCaseId(state);

    if (workflowName != null && caseId != null) {
      WORKFLOW_API.get(`${workflowName}/instances/${caseId}/tokens/`)
        .then((response) => {
          response.data.tokens.forEach((element) => {
            if (element.tokenStatus === 'CONSUMED') { element.tokenStatus = 'PROCESSED'; }
            if (element.tokenStatus === 'FREE') { element.tokenStatus = 'DONE'; }
            if (element.tokenStatus === 'SUSPENDED') { element.tokenStatus = 'PAUSED'; }
          });
          commit('loadTokenMutation', response.data.tokens);
        }).catch(error => console.log(error));
    }
  },
  loadStatusDiagram({ commit, dispatch, state }) {
    // let workflowName =  params.workflowName;
    const workflowName = getters.getStatusWorkflowName(state);
    if (workflowName != null) {
      WORKFLOW_API.get(`${workflowName}/path`).then((response) => {
        commit('setStatusEdgeNodeList', response.data);
      }).catch(error => console.log(error));
    }
  },
  updateParams({ commit }, value) {
    commit('setTokenWorkflowName', value.workflowName);
    commit('setTokenCaseId', value.caseId);
  },
  changeTokenRefreshRateAction({ commit }, value) {
    commit('refreshTokenRateMutation', value);
  },
  incrementTokenCounterAction({ dispatch, commit, state }) {
    if (state.counter >= state.refreshRate) {
      dispatch('loadTokens', { workflowName: state.workflowName, caseId: state.caseId });
      commit('resetTokenCounterMutation');
    } else {
      commit('incrementTokenCounterMutation');
    }
  },
  tokenClear({ commit }) {
    commit('tokenClear');
  },
  changeStatusEdgeNodeList({ commit }, value) {
    commit('setStatusEdgeNodeList', value);
  },

};

const mutations = {
  setTokenWorkflowName(state, value) {
    state.workflowName = value;
  },
  setTokenCaseId(state, value) {
    state.caseId = value;
  },
  refreshTokenRateMutation(state, value) {
    state.refreshRate = value;
  },
  resetTokenCounterMutation(state) {
    state.counter = 1;
  },
  incrementTokenCounterMutation(state) {
    state.counter += 1;
  },
  loadTokenMutation(state, data) {
    const dta = utils.date.formatDataTimes(data, state.datePropsAndFormats, dateStore.getTimeZoneDefinition().tz);
    state.tableData = dta;
  },
  setStatusEdgeNodeList(state, data) {
    state.edgeNodeList = data;
  },
  tokenClear(state) {
    state.tableData = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};