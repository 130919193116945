import mrisMeter from './MrisMeter/MrisMeterStore';
import mrisPreSubmission from './MrisPreSubmit/mrisPreSubmitStore';
import mrisSubmission from './MrisSubmission/mrisSubmissionStore';
import mrisRawMeter from './MrisRawMeter/mrisRawMeterStore';
import mrisMapAggregate from './MrisMapAggregate/MrisMapAggregateStore';
import mrisMapMeter from './MrisMapMeter/MrisMapMeterStore';
import mrisMapResource from './MrisMapResource/MrisMapResourceStore';
import mrisOverride from './MrisOverride/MrisOverrideStore';

export default {
  namespaced: true,
  modules: {
    mrisMeter,
    mrisPreSubmission,
    mrisSubmission,
    mrisRawMeter,
    mrisMapAggregate,
    mrisMapMeter,
    mrisMapResource,
    mrisOverride,
  },
};