import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { CISO_SIBR_API } from '@/api';
import configs from './sibrBidsConfig';

const state = {
  bidsHeaderTableData: [],
  selectedHeader: null,
  bidCurvesTableData: [],
  bidCurvesPivotedTableData: [],
  bidSelfSchedulesTableData: [],
  bidStartupCostTableData: [],
  bidTransitionTableData: [],
  bidDetailsTableData: [],
  sibrBidsConfig: configs,
  selectedIndex: 0,
};

const actions = {
  async fetchBids({ dispatch, commit, state }, inparams) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(inparams.tradeDateRangeSelected);
    const params = {
      scs: inparams.coordinatorListSelected,
      startDate,
      endDate,
      marketType: inparams.marketTypesSelected,
      resourceId: inparams.resourceListSelected ? inparams.resourceListSelected.join(',') : null,
      sourceType: inparams.sourceTypesSelected,
    };

    try {
      const { data } = await CISO_SIBR_API.get('/bids', { params });
      commit('clearSibr');
      if (data) {
        commit('setSibrBidsData', data);
      } else {
        this.$notify('No SIBR Bids Data Found');
      }
    } catch (error) {
      this.$notify('Failed to Load SIBR Bids Data', 'error');
    }
  },
};

const mutations = {
  setSibrBidsData(state, bids) {
    if (bids && bids.data) {
      bids.data.forEach((x) => {
        if (x.bidCurves) {
          x.bidCurves.forEach((y) => {
            y.tradeDate = getMomentDateString(y.tradeDate);
          });
        }
        if (x.bidCurvesPivoted) {
          x.bidCurvesPivoted.forEach((y) => {
            y.tradeDate = getMomentDateString(y.tradeDate);
          });
        }
        if (x.bidTransitions) {
          x.bidTransitions.forEach((y) => {
            y.tradeDate = getMomentDateString(y.tradeDate);
          });
        }
      });
    }
    state.bidsHeaderTableData = bids.data;
  },
  setSelectedHeader(state, {
    bidCurvesPivoted, bidSelfSchedules, bidStartupCosts, bidTransitions, bidDetails,
  }) {
    // if (bidCurves !== undefined) state.bidCurvesTableData = bidCurves || [];
    if (bidCurvesPivoted !== undefined) state.bidCurvesPivotedTableData = bidCurvesPivoted || [];
    if (bidSelfSchedules !== undefined) state.bidSelfSchedulesTableData = bidSelfSchedules || [];
    if (bidStartupCosts !== undefined) state.bidStartupCostTableData = bidStartupCosts || [];
    if (bidTransitions !== undefined) state.bidTransitionTableData = bidTransitions || [];
    if (bidDetails !== undefined) state.bidDetailsTableData = bidDetails || [];
  },
  clearSibr(state) {
    // state.bidCurvesTableData = [];
    state.bidCurvesPivotedTableData = [];
    state.bidSelfSchedulesTableData = [];
    state.bidStartupCostTableData = [];
    state.bidTransitionTableData = [];
    state.bidDetailsTableData = [];
    state.bidsHeaderTableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};