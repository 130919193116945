import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';
import { collectionService } from '@/utils/structuresHelper';
import config from './mwhConfg';
import jsonLogic from 'json-logic-js';

const findPath = (rule, tag) => {  
  const paths = tag.paths ?? [];
  
  if (rule) {
    for(let x = 0; x < paths.length; x++) {
      const prev = x > 0 ? paths[x - 1] : null;
      const curr = paths[x];
      const next = x < (paths.length - 1) ? paths[x + 1] : null;
      
      const ruleResult = jsonLogic.apply(rule.logic, { prev, curr, next, } );
      if (ruleResult) {
        return {
          se: curr.se[0],
          mpse: curr.pse,
          por: curr.por,
          pod: curr.pod,
          tp: curr.tp,
        };
      }
    }
  }
  return { se: null, mpse: null, por: null, pod: null, tp: null, };
};

const state = {  
  dateRange: null,
  pivotConfig: config.fields,
  rawData: [],
  params: null,
  pathLookupRules: [],
  pathLookupRule: null, 
};

const getters = {
  pivotData: (state) => { 
    const data = state.rawData;
    const rule = state.pathLookupRule;

    const result = [];

    for(let i = 0; i < data.length; i++) {
      const tag = data[i];
      const path = findPath(rule, tag);
      tag.values.forEach((v) => result.push({...tag, ...v, ...path, values: null, paths: null}));
    }
    
    return result.flat();
  },
}

const actions = {
  async fetchPathLookupLogic({commit}) {
    const data = await collectionService.fetchResource({ collection: 'etag-reporting', resource: 'path-lookup-logic', isUser: false });
    
    if(data && data.rules) {
      commit('setPathLookupRules', data.rules);
    }
  },
  async fetchTagMWh({ commit, state }, item) {        
    const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, item.tz);
    state.dateRange = momentRange;
    const start = momentRange[0].startOf('day').utc();
    const end = momentRange[1].startOf('day').add(1, 'days').utc();
    const params = {
      tz: item.tz,
      startTime: start.format(),
      endTime: end.format(),
    };

    if (item.cpse && item.cpse.length > 0) params.cpse = item.cpse;
    if (item.grouping && item.grouping.length > 0) params.grouping = item.grouping;
    if (item.text && item.text.length > 0) params.text = item.text;
    if (item.gca && item.gca.length > 0) params.gca = item.gca;
    if (item.lca && item.lca.length > 0) params.lca = item.lca;
    if (item.source && item.source.length > 0) params.source = item.source;
    if (item.sink && item.sink.length > 0) params.sink = item.sink;
    if (item.notes && item.notes.length > 0) params.notes = item.notes;
    if (item?.marketPath?.length > 0) params.marketPath = item.marketPath;
    if (item?.path?.length > 0) params.path = item.path;
    params.includePaths = true;

    commit('setParams', params);
    commit('setRawData', []);

    try {
      const { data : { data } } = await ETAG_API.get('/reporting', { 
        headers: {'api-version': '2.0'},
        params 
      });
      
      commit('setRawData', data);
    } catch (err) {

    }
  },
};

const mutations = {
  setParams(state, data) {
    state.params = data;
  },
  setRawData(state, data) {  
    data = dateStore.formatDateTimeFields(data, [
      { name: 'startTime', format: 'YYYY-MM-DD HH:mm' },
      { name: 'endTime', format: 'YYYY-MM-DD HH:mm' }]);    
    state.rawData = data;
  },
  setPathLookupRules(state, data) {
    if (data !== null) {
      state.pathLookupRules = data;
    } else {
      state.pathLookupRules = [];
    }
  },
  setPathLookupRule(state, item) {
    state.pathLookupRule = item;
  },
  setTimeZone(state, value) {
    state.selectedTimeZone = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
