import moment from 'moment';
import {
  clone, mergeByPropList, has, HERows,
} from '@/utils/dataUtil';

import dateStore from '@/utils/dateStore';
import { confirm, alert } from 'devextreme/ui/dialog';
import {
  CISO_SCHD_API,
} from '@/api';

export const TIMEZONE_PST = 'America/Los_Angeles';
const SUBMIT = 0;

export const systemStatusColors = {
  IN_PROGRESS: {
    backgroundColor: '#f7e967',
  },
  ERRORED: {
    backgroundColor: '#f1433f',
  },
  // SUBMITTED: {
  //   backgroundColor: '#0380fc',
  // },
  // RETRIEVED: {
  //   backgroundColor: '#a9cf54',
  // },
};

export const scheduleVariantColors = {
  PROPOSED: {
    backgroundColor: '#f7e967',
  },
  NOT_READY: {
    backgroundColor: '#9d9d9d',
  },
  NOT_EXISTS: {
    backgroundColor: '#9d9d9d',
  },
  PENDING_VALIDATION: {
    backgroundColor: '#f7e967',
  },
  VALIDATING: {
    backgroundColor: '#f7e967',
  },
  FAILED_VALIDATION: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  INVALID: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
  BIDS: {
    backgroundColor: '#a9cf54',
  },
  READY: {
    backgroundColor: '#0380fc',
  },
  SENT: {
    backgroundColor: '#ffa500',
  },
  ACTUAL: {
    backgroundColor: '#a9cf54',
  },
  IN_PROGRESS: {
    backgroundColor: '#f7e967',
  },
  ERRORED: {
    backgroundColor: '#f1433f',
  },
  REJECTED: {
    backgroundColor: '#f1433f',
  },
  CANCELED: {
    backgroundColor: '#9d9d9d',
  },
  CANCELLING: {
    backgroundColor: '#f7e967',
  },
  SUBMITTED: {
    backgroundColor: '#0380fc',
  },
  SUBMITTING: {
    backgroundColor: '#f7e967',
  },
  RETRIEVING: {
    backgroundColor: '#f7e967',
  },
  ACCEPTED: {
    backgroundColor: '#48dd48',
  },
};

const defaultOpenMarkets = [
  {
    market: 'CAISO',
    system: 'SIBR',
    marketType: 'DAM',
    status: 'OPEN',
    startHour: 1,
    endHour: 24,
  },
  {
    market: 'CAISO',
    system: 'IST',
    marketType: 'DAM',
    status: 'OPEN',
    startHour: 1,
    endHour: 24,
  },
  {
    market: 'CAISO',
    system: 'SIBR',
    marketType: 'RTM',
    status: 'OPEN',
    startHour: 1,
    endHour: 24,
  },
  {
    market: 'CAISO',
    system: 'IST',
    marketType: 'RTM',
    status: 'OPEN',
    startHour: 1,
    endHour: 24,
  },
  {
    market: 'CAISO',
    system: 'BSAP',
    marketType: 'RTM',
    status: 'OPEN',
    startHour: 1,
    endHour: 24,
  },
];

// DATA FUNCTIONS
export function mapToOptions(items) {
  return items.map((i) => ({ value: i.id, label: i.shortName }));
}

export function mapLabelValue(items, valueProp = null, labelProp = null) {
  if (!items) return null;
  if (valueProp && labelProp) return items.map((i) => ({ value: i[valueProp], label: i[labelProp] }));
  return items.map((i) => ({ value: i, label: i }));
}

export function distinctLabelValue(items, valueProp) {
  return mapLabelValue([...new Set(items.map((x) => x[valueProp]))]);
}

// STATUS FUNCTIONS
export function joinProposed(bids, proposed) {
  const result = [...bids];
  if (proposed) {
    for (let x = 0; x < proposed.length; x++) {
      const pb = proposed[x];
      let found = false;
      for (let k = 0; k < bids.length; k++) {
        const bb = bids[k];

        if (pb.resource === bb.resource) {
          found = true;
          break;
        }
      }
      if (!found) result.push(pb);
    }
  }
  return result;
}

// DETAIL FUNCTIONS
export function computeSelfScheduleTotal(s, excludeArray) {
  let tot = 0;
  const properties = Object.keys(s);
  properties.forEach((prop) => {
    let proceed = true;
    if (excludeArray) proceed = !(excludeArray.indexOf(prop) >= 0);

    if (has(s, prop) && s[prop] && proceed) {
      const f = parseFloat(s[prop]);
      tot += f;
    }
  });

  return tot;
}

export function selfScheduleTotals(selfSchedules, bidSchedules, excludeFromSum) {
  for (let b = 0; b < bidSchedules.length; b++) {
    const bi = bidSchedules[b];
    const ss = selfSchedules.filter((x) => x.startTime === bi.startTime);
    bi.ss = 0;
    ss.forEach((s) => {
      bi.ss += computeSelfScheduleTotal(s, excludeFromSum);
    });
    if (bi.ss === 0) { bi.ss = null; }
  }
}

export function defaultByConfiguration(items, configurations) {
  const scheduleItems = [];

  items.forEach((item) => {
    configurations.forEach((configuration) => {
      const configurationItem = { ...clone(item), configuration: configuration.label };
      scheduleItems.push(configurationItem);
    });
  });

  return scheduleItems;
}

export function filterScheduleData(state, key) {
  if (state.marketType === 'RTM' && key === 'bidSchedules' && state.daSelfSchedules.length > 0) {
    state.bidSchedules.forEach((bidSchedule) => {
      const daSelfSchedule = state.daSelfSchedules.find((x) => x.he === bidSchedule.he
      && x.configuration === bidSchedule.configuration);

      if (daSelfSchedule) { bidSchedule.da_ss = daSelfSchedule.pt ?? daSelfSchedule.lpt; }
    });
  }

  const msgFlag = state.configurations && state.configurations.length > 0;
  const schedule = state.scheduleType ? state.bidsVariantSchedule : state.proposedVariantSchedule;
  const opposingSchedule = !state.scheduleType ? state.bidsVariantSchedule : state.proposedVariantSchedule;
  const propList = ['startTime'];
  if (msgFlag) propList.push('configuration');
  let schedules = [];
  if (schedule) {
    if (state[key]) mergeByPropList(schedule[key], state[key], propList, ['he']);
    const item = schedule[key];
    const configuration = state.configurations.find((x) => x.value === state.configuration)?.label;
    schedules = item;
    return configuration ? schedules.filter((x) => x.configuration === configuration) : schedules;
  }
  return schedules;
}

export async function getOpenMarkets(date, disableEndBound = false) {
  try {
    const selectedDate = dateStore.toDateFromLocal(date, 'PPT');
    const params = { tradingDate: selectedDate.toISOString(), disableEndBound };

    const openMarkets = await CISO_SCHD_API.get('open-markets', { params });
    const { data } = openMarkets;
    return data;
  } catch (err) {
    console.error(err);
    return defaultOpenMarkets;
  }
}

export function deconstructScheduleName(name, properties) {
  let scheduleName = clone(name);
  const item = {};
  for (let i = 0; i < properties.length; i++) {
    const splitName = scheduleName.split(/-(.+)/);
    if (splitName && i !== properties.length - 1) {
      const [value, newName] = splitName;
      item[properties[i]] = value;
      scheduleName = newName;
    } else {
      item[properties[i]] = scheduleName;
    }
  }
  return item;
}

export function setBaseScheduleDefaults(item, state) {
  const msgFlag = state.configurations && state.configurations.length > 0;

  const baseScheduleConfig = state.baseSchedulePointTableConfig.columns
    .reduce((acc, { prop }) => ({ ...acc, [prop]: null }), {});
  delete baseScheduleConfig.undefined;
  let defaultBaseSchedulePoints = HERows(baseScheduleConfig, true, state.startTime);
  const propList = ['startTime'];
  if (msgFlag) {
    defaultBaseSchedulePoints = defaultByConfiguration(defaultBaseSchedulePoints, state.configurations);
    propList.push('configuration');
  }

  const baseSchedulePoints = mergeByPropList(defaultBaseSchedulePoints, item.baseSchedulePoints, propList, ['he']);

  const schedule = {
    ...item,
    baseSchedulePoints,
    resourceType: state.resourceType,
    msgFlag,
  };

  return schedule;
}

export function setScheduleDefaults(item, state) {
  const msgFlag = state.configurations && state.configurations.length > 0;

  const selfSchedulesColumns = {
    pt: null,
    lpt: null,
    ru: null,
    rd: null,
    sr: null,
    nr: null,
    whl: null,
    lfu: null,
    lfd: null,
  };

  delete selfSchedulesColumns.undefined;
  let defaultSelfSchedules = HERows(selfSchedulesColumns, true, state.startTime);

  const bidCurveColumns = state.bidCurveTableConfig.columns
    .reduce((acc, { prop }) => ({ ...acc, [prop]: null }), {});
  delete bidCurveColumns.undefined;
  let defaultBidSchedules = HERows(bidCurveColumns, true, state.startTime);

  const propList = ['startTime'];
  if (msgFlag) {
    defaultSelfSchedules = defaultByConfiguration(defaultSelfSchedules, state.configurations);
    defaultBidSchedules = defaultByConfiguration(defaultBidSchedules, state.configurations);
    propList.push('configuration');
  }
  const selfSchedules = mergeByPropList(defaultSelfSchedules, item.selfSchedules, propList, ['he']);
  const bidSchedules = mergeByPropList(defaultBidSchedules, item.bidSchedules, propList, ['he']);

  selfScheduleTotals(selfSchedules, bidSchedules, state.excludeFromSelfScheduleTotalSum);

  const schedule = {
    ...item,
    selfSchedules,
    bidSchedules,
    resourceType: state.resourceType,
    msgFlag,
  };

  return schedule;
}

// DELETE THESE EVENTUALLY
export function findSchedulingCoordinator(items, scId) {
  return items.find((i) => i.id === scId);
}

export function findResources(items, scId, rsrcType, resourceGroups, selectedResourceGroup) {
  let resources = [];
  const sc = findSchedulingCoordinator(items, scId);
  if (sc && scId && rsrcType) {
    resources = mapToOptions(sc.resources.filter((o) => o.resourceType === rsrcType).flat());
  } else if (sc && scId && !rsrcType) {
    resources = mapToOptions(sc.resources.flat());
  } else if (!scId && rsrcType) {
    resources = mapToOptions(items.map((i) => i.resources.filter((o) => o.resourceType === rsrcType)).flat());
  } else {
    resources = mapToOptions(items.map((i) => i.resources).flat());
  }

  if (selectedResourceGroup && resourceGroups.length > 0) {
    const rsrcs = [];
    const group = resourceGroups.find((o) => o.id === selectedResourceGroup);
    for (let i = 0; i < group.resources.length; i++) {
      const rsrc = resources.find((o) => o.label === group.resources[i].shortName);
      if (rsrc) rsrcs.push(rsrc);
    }
    resources = rsrcs;
  }
  if (resources && resources.length > 0) {
    resources = resources.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return 1;
      return 0;
    });
  }

  return resources;
}

export function findResource(items, scId, rsrcId) {
  if (rsrcId) {
    const rsrcs = findResources(items, scId);
    const rs = rsrcs.find((r) => r.value === rsrcId);
    return rs.label;
  }
  return undefined;
}

export function generateSelfScheduleHour(hourEnding, tradeDate, selectedProductType, val) {
  const start = moment(tradeDate).add(hourEnding - 1, 'hours').utc();
  const end = start.clone().add(1, 'hours').utc();

  const selfScheduleHour = {
    startTime: start.format(),
    endTime: end.format(),
    pt: null,
    lpt: null,
    whl: null,
    ru: null,
    rd: null,
    sr: null,
    nr: null,
    lfu: null,
    lfd: null,
    he: hourEnding,
  };
  selfScheduleHour[selectedProductType.toLowerCase()] = val;
  return selfScheduleHour;
}

export function joinSchedulesAndResources(
  statuses,
  scToResources,
  selectedSchedulingCoordinator,
  selectedResource,
  date,
  selectedMarketType,
  selectedResourceType,
  selectedProductType,
  selectedResourceGroup,
) {
  const sc = findSchedulingCoordinator(scToResources, selectedSchedulingCoordinator);
  const rsrc = findResource(scToResources, selectedSchedulingCoordinator, selectedResource);
  let s2r = clone(scToResources);
  if (sc) { s2r = [s2r.find((s) => s.shortName === sc.shortName)]; }

  if (selectedResourceType) {
    for (let i = 0; i < s2r.length; i++) {
      s2r[i].resources = s2r[i].resources.filter((s) => s.resourceType === selectedResourceType);
    }
  }

  if (selectedResourceGroup) {
    for (let i = 0; i < s2r.length; i++) {
      const resources = [];
      for (let j = 0; j < selectedResourceGroup.resources.length; j++) {
        const resource = s2r[i].resources.find((s) => s.shortName === selectedResourceGroup.resources[j].shortName);
        if (resource) resources.push(resource);
      }
      s2r[i].resources = resources;
    }
  }

  const result = [];
  date = new Date(date.toString());
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  const startTime = moment(date).utc();
  const endTime = startTime.clone().add(1, 'days').utc();

  if (dt < 10) dt = `0${dt}`;
  if (month < 10) month = `0${month}`;
  const dateString = `${year}-${month}-${dt}`;

  for (let i = 0; i < s2r.length; i++) {
    for (let xr = 0; xr < s2r[i].resources.length; xr++) {
      const resource = s2r[i].resources[xr];

      if (rsrc && resource.shortName !== rsrc) continue;
      if (resource.configurations && resource.configurations.length > 0) {
        for (let j = 0; j < resource.configurations.length; j++) {
          const config = resource.configurations[j];
          // eslint-disable-next-line
          const ss = statuses.find((b) => b.resource === resource.shortName && b.configuration === config.shortName && b.marketType === selectedMarketType);

          if (ss) {
            for (let i = 0; i < ss.selfSchedules.length; i++) {
              const selfSchedule = ss.selfSchedules[i];
              const { he } = selfSchedule;
              let prop = '';
              if (he < 10) {
                prop = `he0${he}`;
              } else {
                prop = `he${he}`;
              }

              const val = selfSchedule[selectedProductType.toLowerCase()];

              ss[`${prop}_val`] = val;
            }
            ss.productType = selectedProductType;
            result.push(ss);
          } else {
            const selfSchedules = [];

            for (let k = 1; k <= 24; k++) {
              const selfSchedule = generateSelfScheduleHour(k, date, selectedProductType, null);
              selfSchedules.push(selfSchedule);
            }

            result.push({
              startTime,
              endTime,
              configuration: config.shortName,
              date: dateString,
              selfSchedules: [],
              he01_val: null,
              he02_val: null,
              he03_val: null,
              he04_val: null,
              he05_val: null,
              he06_val: null,
              he07_val: null,
              he08_val: null,
              he09_val: null,
              he10_val: null,
              he11_val: null,
              he12_val: null,
              he13_val: null,
              he14_val: null,
              he15_val: null,
              he16_val: null,
              he17_val: null,
              he18_val: null,
              he19_val: null,
              he20_val: null,
              he21_val: null,
              he22_val: null,
              he23_val: null,
              he24_val: null,
              productType: selectedProductType,
              marketType: selectedMarketType,
              resource: resource.shortName,
              resourceType: resource.resourceType,
              sc: s2r[i].shortName,
              scheduleName: null,
              variant: 'NOT_EXISTS',
            });
          }
        }
      } else {
        const ss = statuses.find((b) => b.resource === resource.shortName && b.marketType === selectedMarketType);

        if (ss) {
          for (let i = 0; i < ss.selfSchedules.length; i++) {
            const selfSchedule = ss.selfSchedules[i];
            const { he } = selfSchedule;
            let prop = '';
            if (he < 10) {
              prop = `he0${he}`;
            } else {
              prop = `he${he}`;
            }

            const val = selfSchedule[selectedProductType.toLowerCase()];

            ss[`${prop}_val`] = val;
          }
          ss.productType = selectedProductType;
          result.push(ss);
        } else {
          const selfSchedules = [];

          for (let k = 1; k <= 24; k++) {
            const selfSchedule = generateSelfScheduleHour(k, date, selectedProductType, null);
            selfSchedules.push(selfSchedule);
          }

          result.push({
            startTime,
            endTime,
            configuration: null,
            date: dateString,
            selfSchedules: [],
            he01_val: null,
            he02_val: null,
            he03_val: null,
            he04_val: null,
            he05_val: null,
            he06_val: null,
            he07_val: null,
            he08_val: null,
            he09_val: null,
            he10_val: null,
            he11_val: null,
            he12_val: null,
            he13_val: null,
            he14_val: null,
            he15_val: null,
            he16_val: null,
            he17_val: null,
            he18_val: null,
            he19_val: null,
            he20_val: null,
            he21_val: null,
            he22_val: null,
            he23_val: null,
            he24_val: null,
            productType: selectedProductType,
            marketType: selectedMarketType,
            resource: resource.shortName,
            resourceType: resource.resourceType,
            sc: s2r[i].shortName,
            scheduleName: null,
            variant: 'NOT_EXISTS',
          });
        }
      }
    }
  }
  return result;
}

export async function confirmSubmission(text) {
  const result = await confirm(text, 'Confirm Action');
  if (result) return true;
  return false;
}

export async function submissionWarning(text) {
  const result = await alert(text, 'Warning');
  return false;
}

export async function verifySubmitActionBase({ state, dispatch }, item, SIBRorBase) {
  // selected date
  const currentDate = dateStore.toMoment(state.date, TIMEZONE_PST);
  // todays date
  const now = dateStore.toMoment(moment(), TIMEZONE_PST);
  let result = true;

  if (state.selectedMarketType === 'DAM') {
    const factor = (now.get('hour') >= 10) ? 1 : 0;
    // check dates to see if submitting on closed hour
    const dayAhead = now.clone().utc().add(factor, 'days').format();
    const weekAhead = now.clone().utc().add(factor + 7, 'days').format();
    // difference in date between selected date and the week ahead
    const dateDiff = moment.duration(currentDate.diff(weekAhead.slice(0, 10))).asDays();
    if ((dateDiff > 0 && SIBRorBase) || dateDiff < -7) {
      // trading day for DAM submitted is closed, prompt user for confirmation to submit.
      const text = 'You are attempting to submit on a closed day. Do you want to continue?';
      result = await confirmSubmission(text);
    }

    if (result) {
      if ((dayAhead >= currentDate.clone().utc().format()) && (currentDate.clone().utc().format() <= weekAhead)) {
        if (item.id === SUBMIT) { dispatch('showBidSubmissionDialog'); } else { dispatch('submitAction', item); }
        // hour is closed and user wants to submit, ask for confirmation
        dispatch('submitAction', item);
      } else {
        // hour is open
        dispatch('submitAction', item);
      }
    }
  } else {
    // market type is RTM
    if (state.startHour.time > state.endHour.time) {
      const text = 'Please select an ending hour that is greater than or equal to the start hour.';
      result = await submissionWarning(text);
    }
    if (result) {
      const dateDiff = moment.duration(currentDate.diff(now.format().slice(0, 10))).asDays();
      // if hours are closed for RTM ask for confirmation
      let firstOpenHour = new Date();
      if (SIBRorBase) {
        firstOpenHour = new Date(moment().add(195, 'minutes').format('LLLL')).getHours();
      } else {
        firstOpenHour = new Date(moment().add(175, 'minutes').format('LLLL')).getHours();
      }
      // eslint-disable-next-line
      if ((dateDiff > 1 && SIBRorBase) || dateDiff < 0 || (dateDiff === 1 && now.get('hour') < 13) || (state.startHour.he < firstOpenHour && dateDiff === 0)) {
        const text = 'You are attempting to submit on a closed hour. Do you want to continue?';
        result = await confirmSubmission(text);
      }
      if (result) {
        dispatch('submitAction', item);
      }
    }
  }
}