export default {
  CORRECTION: {
    backgroundColor: '#93E5FE',
  },
  MARKETCHANGE: {
    backgroundColor: '#33A4FF',
  },
  RELIABILITYCHANGE: {
    backgroundColor: '#9433FF',
    color: '#f7e967',
  },
  TERMINATE: {
    backgroundColor: '#f1433f',
    color: '#f7e967',
  },
};