// import ls from 'local-storage';

const UserStore = function () {
  this.expandPendingRequests = true;
  this.collapseNavigationMenusOnClose = true;
  this.defaultRefreshTime = 30;
  this.defaultFilters = [];
  this.landingPage = 'Settings';
  this.dock = 'Undocked';
  this.defaultContract = [];
};

UserStore.prototype.setDefaults = function (model) {
  const settings = model || this.getDefaults();
  this.expandPendingRequests = settings.expandPendingRequests;
  this.collapseNavigationMenusOnClose = settings.collapseNavigationMenusOnClose;
  this.defaultRefreshTime = settings.defaultRefreshTime;
  this.defaultFilters = settings.defaultFilters;
  this.landingPage = settings.landingPage;
  this.dock = settings.dock;
  this.defaultContract = settings.defaultContract;
};

UserStore.prototype.getExpandPendingRequests = function () {
  return this.expandPendingRequests;
};

UserStore.prototype.getCollapseNavigationMenusOnClose = function () {
  return this.collapseNavigationMenusOnClose;
};

UserStore.prototype.getDefaultRefreshTime = function () {
  return this.defaultRefreshTime;
};

UserStore.prototype.getDefaultFilters = function () {
  return this.defaultFilters;
};

UserStore.prototype.getLandingPage = function () {
  return this.landingPage || 'Settings';
};

UserStore.prototype.getDock = function () {
  return this.dock || 'Undocked';
};

UserStore.prototype.getDefaultContract = function () {
  if (this.defaultContract === undefined || this.defaultContract[0] === undefined || this.defaultContract.length === 0) return [];
  const { 0: defaultContract } = this.defaultContract;
  return defaultContract;
};

UserStore.prototype.setExpandPendingRequests = function (value) {
  this.expandPendingRequests = value;
};

UserStore.prototype.setCollapseNavigationMenusOnClose = function (value) {
  this.collapseNavigationMenusOnClose = value;
};

UserStore.prototype.setDefaultRefreshTime = function (value) {
  this.defaultRefreshTime = value;
};

UserStore.prototype.setDefaultFilters = function (value) {
  this.defaultFilters = value;
};

UserStore.prototype.setLandingPage = function (value) {
  this.landingPage = value;
};

UserStore.prototype.setDock = function (value) {
  this.dock = value;
};

UserStore.prototype.setDefaultContract = function (value) {
  this.defaultContract = value;
};

UserStore.prototype.getDefaults = function () {
  return {
    expandPendingRequests: true,
    collapseNavigationMenusOnClose: true,
    defaultFilters: [],
    defaultRefreshTime: null,
    landingPage: 'Settings',
    dock: 'Undocked',
    defaultContract: [],
  };
};

// UserStore.prototype.registerUserDataRefreshListener = function (listener) {
//   // only if the listener exists
//   if (listener) ls.on('new-default-user-options', listener.userDataRefreshCallback);
// };

// UserStore.prototype.refreshUserDataListeners = function (userOptions) {
//   userOptions = userOptions || {
//     expandPendingRequests: this.expandPendingRequests,
//     defaultFilters: this.defaultFilters,
//     landingPage: this.landingPage,
//   };

//   ls.set('new-default-user-options', userOptions);
// };

const userStore = new UserStore();
export default userStore;