import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import config from './etsrStatementsConfig';

const state = {
  tableData: [],
  tableConfig: config.fields,
  parameters: [],
  showRowTotals: true,
  compareFlag: true,
};

const actions = {
  async fetchEtsrStatements({ commit }, { params }) {
    try {
      commit('setParameters', params);
      commit('setTableData', []);

      const { data: { data } } = await CISO_SAMC_API.get('/etsr-statements', { params });
      data.forEach((obj) => {
        obj.tradingDate = getMomentDateString(obj.tradingDate);
      });

      commit('setTableData', data);
    } catch (error) {
      console.error(error);
      this.$notify('Failed to load ETSR Statements data', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableData(state, data) {
    state.tableData = Object.freeze(data);
  },
  setTableConfig(state, data) {
    state.tableConfig = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};