import { clone } from '@/utils/dataUtil';
import { STRUCTURES_API, TOOLS_API, VA_API } from '@/api';
import caisoUtil from '@/utils/caiso/caisoUtils';
import VABaseStore from '../../VABaseStore';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [
      {
        prop: 'id',
        label: 'ID',
        sortable: true,
        visible: false,
        editable: false,
      },
      {
        prop: 'type',
        label: 'Config Type',
        sortable: true,
        visible: true,
        editable: false,
      }, {
        prop: 'sc',
        label: 'SC',
        sortable: true,
        visible: true,
        editable: true,
      }, {
        prop: 'activeFlag',
        label: 'Active Flag',
        sortable: true,
        visible: false,
        editable: true,
      }, {
        prop: 'startDate',
        label: 'Start Date',
        filterable: true,
        dataType: 'date',
        sortable: true,
        visible: true,
        editable: false,
      }, {
        prop: 'endDate',
        label: 'End Date',
        filterable: true,
        dataType: 'date',
        sortable: true,
        visible: true,
        editable: false,
      }, {
        prop: 'configuration',
        label: 'Start Up Config',
        sortable: true,
        visible: true,
        editable: true,
      }],
    options: {
      // exportExcel: true,
      filterRow: true,
      filterHeader: true,
      // columnConfig: true,
      // showSelectFilter: false,
    },
  },
  nullTableRow: {
    id: -999,
    type: null,
    sc: null,
    activeFlag: null,
    startDate: null,
    endDate: null,
    configuration: null,
  },
  coordinators: [],
  configurations: [],
  options: [],
  dialogMode: '',
};

const getters = {
  coordinatorList: (state) => state.coordinators.map(({ shortName }) => ({ label: shortName, value: shortName })),
};

const actions = {
  async initialize({ commit, dispatch, state }) {
    await dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await STRUCTURES_API.get('/gencostsetup');
      commit('loadTableData', data);
    } catch (error) {
      this.$notify('Failed to Load Setups', 'error');
    }
  },
  async addGenSetup({ commit, dispatch }, setup) {
    try {
      const { data } = await STRUCTURES_API.post('/gencostsetup', setup);
      if (!data.validationMessage) {
        dispatch('loadTableData');
        dispatch('currentTableRowChange', clone(state.nullTableRow));
        this.$notify('Setup Added', 'success');
      } else {
        this.$notify(data.validationMessage, 'error');
      }
    } catch (error) {
      this.$notify('Failed to Add Setup', 'error');
    }
  },
  async updateGenSetup({ commit, dispatch }, setup) {
    try {
      const { data } = await STRUCTURES_API.put(`/gencostsetup/${setup.id}`, setup);
      if (!data.validationMessage) {
        dispatch('loadTableData');
        dispatch('currentTableRowChange', clone(state.nullTableRow));
        this.$notify('Setup Updates', 'success');
      } else {
        this.$notify(data.validationMessage, 'error');
      }
    } catch (error) {
      this.$notify('Failed to Update Setup', 'error');
    }
  },
  async inactivateGenSetup({ dispatch, commit }, id) {
    try {
      await STRUCTURES_API.delete(`/gencostsetup/${id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Setup Removed', 'success');
    } catch (error) {
      this.$notify('Failed to Remove Setup', 'error');
    }
  },
  currentTableRowChange({ dispatch, commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
  async fetchCoordinators({ commit }) {
    try {
      const { data: { schedulingCoordinators: scs } } = await TOOLS_API.get('/scheduling-coordinators/resources');
      commit('setCoordinators', caisoUtil.configureCoordinators(scs));
    } catch (error) {
      this.$notify('Failed to load Scheduling Coordinators', 'error');
    }
    return {};
  },
  async fetchConfigs({ commit }) {
    try {
      const { data: { data } } = await VA_API.get('/gencost');
      const formattedData = data.map((x) => ({ ...x, label: x.name, value: x.name }));
      commit('setConfigurations', formattedData);
    } catch (error) {
      vue.$notify('Failed to load Configurations', 'error');
      console.log(error);
    }
  },
  async fetchOptions({ commit }) {
    try {
      const { data: { data } } = await VA_API.get('/gencost/options');
      const formattedData = data.map((x) => ({ ...x, label: x.name, value: x.name }));
      commit('setOptions', formattedData);
    } catch (error) {
      vue.$notify('Failed to load Option Types', 'error');
      console.log(error);
    }
  },
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  loadTableData(state, setup) {
    state.tableData = setup;
  },
  setTableData(state, updatedTableData) {
    state.tableData = updatedTableData;
  },
  addToTableData(state, data) {
    state.tableData.push(data);
  },
  updateTableData(state, data) {
    const index = state.tableData.findIndex(({ id }) => id === data.id);
    if (index !== -1) state.tableData.splice(index, 1, data);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((setup) => setup.id !== state.currentTableRow.id);
  },
  reset(state) {
    state.currentTableRow = null;
    state.tableData = [];
  },
  setDialogMode(state, mode) {
    state.dialogMode = mode;
  },
  setCoordinators(state, value) {
    state.coordinators = value;
  },
  setConfigurations(state, value) {
    state.configurations = value;
  },
  setOptions(state, value) {
    state.options = value;
  },
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};