import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchMonthlyDailyDetails({ commit }, args) {
    const params = {
      scs: args.coordinatorListSelected,
      startDate: args.tradeDateRangeSelected[0].toISOString(),
      endDate: args.tradeDateRangeSelected[1].toISOString(),
      source: args.sourceSelected,
      settlementCycle: args.settlementCycleSelected,
      chargeCode: args.chargeCodeSelected,
      rsrcs: args.resourceListSelected ? args.resourceListSelected.join(',') : null,
      compareSource: args.compareSourceSelected,
      compareSettlementCycle: args.compareSettlementCycleSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/monthly-daily-details', { params });
      data.forEach((obj) => {
        obj.tzTradingdate = getMomentDateString(obj.tzTradingdate);
      });
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Monthly Daily Details', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};