import utils from '@/utils';
import { CISO_MASTERDATA_API, CISO_BID_SCHD_API } from '@/api';
import moment from 'moment';
import { has, cloneDeep } from '@/utils/dataUtil';
import {
  findResources, mapToOptions, joinSchedulesAndResources, generateSelfScheduleHour,
} from '@/components/Scheduling/utils';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { hasPermission } from '@/utils/authUtils';

const { setAllUneditable } = utils.data;

const DAY = 'DAY';
const LONG_DAY = 'LONG_DAY';
const SHORT_DAY = 'SHORT_DAY';
const TODAY_ISO_FORMATTED = moment().startOf('day').toISOString();

const state = {
  tableConfiguration: {
    columns: [{
      prop: 'sc', fixed: 'left', width: 100, sortable: true, filter: true, minWidth: 100,
    }, {
      prop: 'resource', width: 180, type: { name: 'CisoBidStatusRouteCellRenderer', to: 'BidDetail', overrideReadonly: true }, fixed: true, sortable: true, filter: true, minWidth: 180,
    }, {
      prop: 'configuration', fixed: 'left', width: 180, visible: true, filter: true, sortable: true, minWidth: 180,
    }, {
      prop: 'productType', width: 100, label: 'product', fixed: 'left', visible: true, minWidth: 100,
    }, {
      prop: 'he01_val', width: 100, label: 'HE01', editable: true, minWidth: 100,
    }, {
      prop: 'he02_val', width: 100, label: 'HE02', editable: true, minWidth: 100,
    }, {
      prop: 'he02*_val', width: 100, label: 'HE02*', editable: true, visible: false, minWidth: 100,
    }, {
      prop: 'he03_val', width: 100, label: 'HE03', editable: true, minWidth: 100,
    }, {
      prop: 'he04_val', width: 100, label: 'HE04', editable: true, minWidth: 100,
    }, {
      prop: 'he05_val', width: 100, label: 'HE05', editable: true, minWidth: 100,
    }, {
      prop: 'he06_val', width: 100, label: 'HE06', editable: true, minWidth: 100,
    }, {
      prop: 'he07_val', width: 100, label: 'HE07', editable: true, minWidth: 100,
    }, {
      prop: 'he08_val', width: 100, label: 'HE08', editable: true, minWidth: 100,
    }, {
      prop: 'he09_val', width: 100, label: 'HE09', editable: true, minWidth: 100,
    }, {
      prop: 'he10_val', width: 100, label: 'HE10', editable: true, minWidth: 100,
    }, {
      prop: 'he11_val', width: 100, label: 'HE11', editable: true, minWidth: 100,
    }, {
      prop: 'he12_val', width: 100, label: 'HE12', editable: true, minWidth: 100,
    }, {
      prop: 'he13_val', width: 100, label: 'HE13', editable: true, minWidth: 100,
    }, {
      prop: 'he14_val', width: 100, label: 'HE14', editable: true, minWidth: 100,
    }, {
      prop: 'he15_val', width: 100, label: 'HE15', editable: true, minWidth: 100,
    }, {
      prop: 'he16_val', width: 100, label: 'HE16', editable: true, minWidth: 100,
    }, {
      prop: 'he17_val', width: 100, label: 'HE17', editable: true, minWidth: 100,
    }, {
      prop: 'he18_val', width: 100, label: 'HE18', editable: true, minWidth: 100,
    }, {
      prop: 'he19_val', width: 100, label: 'HE19', editable: true, minWidth: 100,
    }, {
      prop: 'he20_val', width: 100, label: 'HE20', editable: true, minWidth: 100,
    }, {
      prop: 'he21_val', width: 100, label: 'HE21', editable: true, minWidth: 100,
    }, {
      prop: 'he22_val', width: 100, label: 'HE22', editable: true, minWidth: 100,
    }, {
      prop: 'he23_val', width: 100, label: 'HE23', editable: true, minWidth: 100,
    }, {
      prop: 'he24_val', width: 100, label: 'HE24', editable: true, minWidth: 100,
    }, {
      prop: 'he25_val', width: 100, label: 'HE025', editable: true, visible: false, minWidth: 100,
    }],
    footer: { count: false },
    style: { maxHeight: 800 },
    options: { searchFilter: false, warningHighlight: true, disableYAxisFill: true },
  },
  tableData: [],
  resourceTypes: [],
  selectedResourceType: null,
  date: dateStore.getDefaultDate().toDate(),
  selectedMarketType: caisoStore.getMarketType(),
  marketTypes: caisoStore.getMarketTypeOptions(),
  productTypes: [{ value: 'PT', label: 'PT' }, { value: 'LPT', label: 'LPT' }, { value: 'WHL', label: 'WHL' }, { value: 'RU', label: 'RU' }, { value: 'RD', label: 'RD' }, { value: 'SR', label: 'SR' }, { value: 'NR', label: 'NR' }, { value: 'LFU', label: 'LFU' }, { value: 'LFD', label: 'LFD' }],
  selectedProductType: 'PT',
  isSearched: false,
  statuses: [],
  scToResources: [],
  selectedSchedulingCoordinator: '',
  selectedResource: '',
  multipleSelection: [],
  startHour: 1,
  endHour: 24,
  hours: [],
  showOpenHours: false,
  schedules: [],
  resourceGroups: [],
  selectedResourceGroup: null,
  mode: '',
  is2D: dateStore.getLongDayHour(),
};

function isRowSelected(row) {
  if (row) {
    return has(row, 'scheduleName') && (row.scheduleName !== '' && row.scheduleName);
  }
  return false;
}

const getters = {
  hasWritePermissionFlag(state, getters, rootState, rootGetters) {
    return hasPermission(rootGetters['auth/getPermissions'], 'caiso_scheduling:caiso_bid_sched', 'write');
  },
  getResourceGroups(state) {
    const groups = [];
    if (state.resourceGroups && state.resourceGroups.length > 0) {
      for (let i = 0; i < state.resourceGroups.length; i++) {
        const group = { value: state.resourceGroups[i].id, label: state.resourceGroups[i].name };
        groups.push(group);
      }
    }
    return groups;
  },
  getTableConfiguration(state, getters, rootState, rootGetters) {
    const config = cloneDeep(state.tableConfiguration);

    for (let i = 0; i < config.columns.length; i++) {
      const col = config.columns[i];
      if (col.prop.includes('he')) {
        if (state.mode === LONG_DAY) {
          if (col.label.includes('02*')) { col.visible = state.is2D; }
          if (col.label.includes('25')) { col.visible = !(state.is2D); }
        } else if (state.mode === SHORT_DAY) {
          if (col.label.includes('03')) { col.visible = false; }
        } else if (col.label.includes('02*') || col.label.includes('25')) { col.visible = false; }
      }
    }

    if (state.showOpenHours && state.selectedMarketType === 'RTM') {
      const now = moment();
      const momentDate = moment(state.date);
      const diff = momentDate.diff(now, 'minutes');
      let hour = 0;
      for (let i = 0; i < config.columns.length; i++) {
        const col = config.columns[i];
        if (col.prop.includes('he')) {
          if (col.label.includes('25') && state.mode === LONG_DAY) {
            hour = 3;
          }
          if (col.label < `HE${state.startHour}` && state.date.toISOString() === TODAY_ISO_FORMATTED) {
            col.visible = false;
          }
          if (diff + ((hour) * 60) < 75) {
            col.visible = false;
          }
          if (col.label.includes('02*') && state.mode === LONG_DAY) {
            hour++;
          } else if (col.label.includes('03') && state.mode === SHORT_DAY) {
            continue;
          } else if (!col.label.includes('02*') && !col.label.includes('25')) {
            hour++;
          }
        }
      }
    }

    const writePermission = hasPermission(rootGetters['auth/getPermissions'], 'caiso_scheduling:caiso_bid_sched', 'write');
    if (writePermission) { return config; }
    return setAllUneditable(config);
  },
  getTableData(state) {
    if (state.isSearched) {
      return state.tableData;
    }
    return [];
  },
  getMinHeight(state) {
    if (state.tableData && state.tableData.length > 0) { return 600; }
    return 100;
  },
  getSchedulingCoordinators: (state) => mapToOptions(state.scToResources),
  getSchedulingCoordinator: (state) => state.selectedSchedulingCoordinator,
  getResources: (state) => findResources(state.scToResources, state.selectedSchedulingCoordinator, state.selectedResourceType, state.resourceGroups, state.selectedResourceGroup),
  getResource: (state) => state.selectedResource,
  getDate: (state) => state.date,
  getMarketType: (state) => state.selectedMarketType,
  getMarketTypes: (state) => state.marketTypes,
  getResourceTypes: (state) => state.resourceTypes,
  getResourceType: (state) => state.selectedResourceType,
  getStartHour: (state) => state.startHour,
  getEndHour: (state) => state.endHour,
  getHours: (state) => state.hours,
  getProductTypes: (state) => state.productTypes,
  getProductType: (state) => state.selectedProductType,
  getShowOpenHours: (state) => state.showOpenHours,
  getResourceGroup: (state) => state.selectedResourceGroup,
};

const actions = {
  setStoreDataFromSearchFilterParams({ dispatch }, params) {
    dispatch('changeSchedulingCoordinatorAction', params.schedulingCoordinator);
    dispatch('changeDateAction', params.startDate);
    dispatch('changeMarketTypeAction', params.marketType);
    dispatch('resourceTypeChanged', params.resourceType);
    dispatch('resourceGroupChanged', params.resourceGroup);
    dispatch('changeResourceAction', params.resource);
    dispatch('productTypeChanged', params.productType);
  },
  loadData({ dispatch }) {
    dispatch('loadSchedulingCoordinatorsWithResourcesAction');
    dispatch('loadResourceTypes');
    dispatch('loadResourceGroups');
    dispatch('loadSummaryAction');
    dispatch('checkDST', state.date);
  },
  loadResourceGroups({ commit }) {
    CISO_MASTERDATA_API.get('resourcegroups/resources?variant=MASTER_FILE')
      .then((response) => {
        if (response.data && response.data.resourceGroups && response.data.resourceGroups.length > 0) {
          commit('setResourceGroups', response.data.resourceGroups);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  dataChanged({ commit }, item) {
    commit('setData', item);
  },
  showOpenHoursChanged({ commit }) {
    commit('setShowOpenHours');
  },
  saveSchedules({ commit, state, dispatch }, callback) {
    const schedulesToSave = state.tableData.filter((o) => o.isDirty === true);
    if (schedulesToSave.length === 0) {
      callback({ type: 'warning', title: 'No Changes To Save' });
      return;
    }
    const schedules = [];
    for (let i = 0; i < schedulesToSave.length; i++) {
      const schedule = schedulesToSave[i];

      if (schedule.scheduleName) {
        for (let j = 1; j <= 24; j++) {
          const start = moment(schedule.date).add(j - 1, 'hours').utc();
          const end = cloneDeep(start).add(1, 'hours').utc();

          let prop = '';
          if (j < 10) {
            prop = `he0${j}_val`;
          } else {
            prop = `he${j}_val`;
          }

          let selfSchedule = schedule.selfSchedules.find((o) => o.startTime === start.format() && o.endTime === end.format());
          if (selfSchedule) {
            selfSchedule[state.selectedProductType.toLowerCase()] = schedule[prop];
          } else {
            selfSchedule = generateSelfScheduleHour(j, state.date, state.selectedProductType, schedule[prop]);
            schedule.selfSchedules.push(selfSchedule);
          }
        }
        schedule.isDirty = false;

        schedules.push(schedule);
      } else {
        if (schedule.configuration) {
          schedule.scheduleName = `${schedule.sc}-${schedule.marketType}-${schedule.date}-${schedule.resource}-${schedule.configuration}`;
        } else {
          schedule.scheduleName = `${schedule.sc}-${schedule.marketType}-${schedule.date}-${schedule.resource}`;
        }
        const selfSchedules = [];
        for (let j = 1; j <= 24; j++) {
          const start = moment(schedule.date).add(j - 1, 'hours').utc();
          const end = cloneDeep(start).add(1, 'hours').utc();

          let prop = '';
          if (j < 10) {
            prop = `he0${j}_val`;
          } else {
            prop = `he${j}_val`;
          }

          if (schedule[prop]) {
            const selfSchedule = generateSelfScheduleHour(j, state.date, state.selectedProductType, schedule[prop]);
            selfSchedules.push(selfSchedule);
          }
        }
        schedule.selfSchedules = selfSchedules;
        schedule.isDirty = false;

        schedules.push(schedule);
      }
    }

    if (schedules.length > 0) {
      const submitSchedules = { schedules };
      CISO_BID_SCHD_API.put('/batch', submitSchedules)
        .then((response) => {
          callback({ type: 'success', title: 'Schedules Save Initiated' });
          // dispatch('loadSummaryAction');
        })
        .catch((err) => {
          callback({ type: 'error', title: 'Schedule Save Failed' });
          console.log(err);
        });
    }
  },
  resourceTypeChanged({ commit, dispatch }, item) {
    commit('setSelectedResourceType', item);
    // dispatch('loadSummaryAction');
  },
  checkDST({ state }, item) {
    const momentStartTime = dateStore.toMoment(item, 'America/Los_Angeles');
    const momentEndTime = momentStartTime.clone().add(1, 'days');
    let currentTime = momentStartTime.clone();
    const hours = [];
    let i = 1;
    while (currentTime.isBefore(momentEndTime)) {
      currentTime = currentTime.add(1, 'hours');
      hours.push({ label: i, value: i });
      i++;
    }
    if (hours.length > 24) {
      state.mode = LONG_DAY;
      if (state.is2D) {
        hours.forEach((x) => {
          if (x.value > 2) {
            if (x.value === 3) {
              x.label = '2*';
              x.value = '2*';
            } else {
              x.label -= 1;
              x.value -= 1;
            }
          }
        });
      }
    } else if (hours.length < 24) {
      hours.forEach((x) => {
        if (x.value >= 3) {
          x.label += 1;
          x.value += 1;
        }
      });
      state.mode = SHORT_DAY;
    } else {
      state.mode = DAY;
    }
  },
  loadResourceTypes({ commit }) {
    CISO_MASTERDATA_API.get('resources/types')
      .then((response) => {
        if (response.data && response.data.length > 0) {
          commit('setResourceTypes', response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  isSearchedChanged({ commit, dispatch }) {
    commit('setIsSearched');
    dispatch('loadSummaryAction');
  },
  loadSummaryAction({ commit, state }) {
    let sc = null;
    if (state.selectedSchedulingCoordinator) {
      sc = state.scToResources.find((o) => o.id === state.selectedSchedulingCoordinator).shortName;
    }

    let resource = null;
    if (state.selectedResource) {
      resource = findResources(state.scToResources, state.selectedSchedulingCoordinator, state.selectedResourceType).find((o) => o.value === state.selectedResource).label;
    }

    const end = moment(state.date).add(1, 'days').format();

    CISO_BID_SCHD_API.get('/', {
      params: {
        start: state.date,
        end,
        sc,
        resource,
        marketType: state.selectedMarketType,
        resourceType: state.selectedResourceType,
        fullSchedules: true,
      },
    })
      .then((response) => {
        if (response.data && response.data.schedules) {
          commit('loadScheduleMutation', response.data.schedules);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  clear({ commit }) {
    commit('clear');
  },

  changeDateAction({ commit, dispatch }, value) {
    commit('changeDateMutation', value);
    dispatch('checkDST', value);
    // dispatch('loadSummaryAction');
  },
  changeMarketTypeAction({ commit, dispatch }, value) {
    commit('changeMarketTypeMutation', value);
    // dispatch('loadSummaryAction');
  },
  loadSchedulingCoordinatorsWithResourcesAction({ commit }) {
    CISO_MASTERDATA_API.get('/schedulingcoordinators/resources?includeConfig=true')
      .then((response) => {
        commit('setScToResourceMutation', response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  changeSchedulingCoordinatorAction({ commit, dispatch }, item) {
    commit('changeSchedulingCoordinatorMutation', item);
    // dispatch('loadSummaryAction');
  },
  changeResourceAction({ commit, dispatch }, item) {
    commit('changeResourceMutation', item);
    // dispatch('loadSummaryAction');
  },
  changeMultipleSelectionAction({ commit }, val) {
    commit('multipleSelectionMutation', val);
  },
  productTypeChanged({ commit, state }, item) {
    if (state.tableData) {
      let found = false;
      for (let i = 0; i < state.tableData.length; i++) {
        if (state.tableData[i].isDirty) {
          found = true;
        }
      }
      if (found) item.callback({ type: 'warning', title: 'Please save your changes' });
      else commit('setProductType', item);
      commit('updateTableData', item);
    }
  },
  resourceGroupChanged({ commit, dispatch }, value) {
    commit('setSelectedResourceGroup', value);
  },
};

const mutations = {
  updateTableData(state, value) {
    let resourceGroup = null;
    if (state.selectedResourceGroup) {
      resourceGroup = state.resourceGroups.find((o) => o.id === state.selectedResourceGroup);
    }

    state.tableData = joinSchedulesAndResources(state.schedules, state.scToResources, state.selectedSchedulingCoordinator, state.selectedResource, state.date, state.selectedMarketType, state.selectedResourceType, value, resourceGroup);
  },
  setProductType(state, value) {
    state.selectedProductType = value;
    for (let i = 0; i < state.tableData.length; i++) {
      state.tableData[i].productType = value;
    }
  },
  setStartHour(state, value) {
    state.startHour = value;
  },
  setEndHour(state, value) {
    state.endHour = value;
  },
  setResourceTypes(state, items) {
    const types = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        types.push({ value: items[i], label: items[i] });
      }
    }
    state.resourceTypes = types;
  },
  loadScheduleMutation(state, data) {
    state.schedules = data;

    let resourceGroup = null;
    if (state.selectedResourceGroup) {
      resourceGroup = state.resourceGroups.find((o) => o.id === state.selectedResourceGroup);
    }

    state.tableData = joinSchedulesAndResources(state.schedules, state.scToResources, state.selectedSchedulingCoordinator, state.selectedResource, state.date, state.selectedMarketType, state.selectedResourceType, state.selectedProductType, resourceGroup);
  },
  clear(state) {
    state.tableData = [];
    state.isSearched = false;
    state.date = dateStore.getDefaultDate().toDate();
    state.selectedResourceType = null;
    state.selectedMarketType = caisoStore.getMarketType();
    state.selectedProductType = 'PT';
    state.isSearched = false;
    state.selectedSchedulingCoordinator = '';
    state.selectedResource = '';
    state.selectedResourceGroup = null;
  },

  changeDateMutation(state, value) {
    state.date = value;
  },
  changeMarketTypeMutation(state, value) {
    state.selectedMarketType = value;
  },
  setScToResourceMutation(state, item) {
    if (item && item.schedulingCoordinators && item.schedulingCoordinators.length > 0) {
      state.selectedSchedulingCoordinator = item.schedulingCoordinators[0].id;
      state.scToResources = item.schedulingCoordinators;
    }
  },
  changeSchedulingCoordinatorMutation(state, item) {
    state.selectedSchedulingCoordinator = item;
    state.selectedResource = null;
  },
  changeResourceMutation(state, item) {
    state.selectedResource = item;
  },
  setStatusesMutation(state, item) {
    state.statuses = item.summaries;
    state.isSearched = true;
  },
  multipleSelectionMutation(state, val) {
    state.multipleSelection = val;
  },
  setProposedStatusesMutation(state, item) {
    state.proposedStatuses = item.summaries;
  },
  setSelectedResourceType(state, item) {
    if (state.selectedResourceType != item) {
      state.selectedResource = null;
    }
    state.selectedResourceType = item;
  },
  setHours(state, item) {
    state.hours = item;
    state.endHour = item.length;
  },
  setIsSearched(state) {
    state.isSearched = true;
  },
  setShowOpenHours(state) {
    state.showOpenHours = !state.showOpenHours;
  },
  setData(state, item) {
    if (item.value === '') { state.tableData[item.rowIndex][item.prop] = null; } else { state.tableData[item.rowIndex][item.prop] = item.value; }
    state.tableData[item.rowIndex].isDirty = true;

    if (state.tableData[item.rowIndex].highlight) {
      state.tableData[item.rowIndex].highlight.push(item.prop);
    } else {
      state.tableData[item.rowIndex].highlight = [];
      state.tableData[item.rowIndex].highlight.push(item.prop);
    }

    // Force re-render on data change
    const td = cloneDeep(state.tableData);
    state.tableData = td;
  },
  setIsDirtyFalse(state) {
    if (state.tableData) {
      for (let i = 0; i < state.tableData.length; i++) {
        state.tableData[i].isDirty = false;
      }
    }
  },
  setResourceGroups(state, items) {
    state.resourceGroups = items;
  },
  setSelectedResourceGroup(state, item) {
    state.selectedResourceGroup = item;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};