import baseSchedules from './BaseSchedules/baseSchedulesStore';
import balancingTests from './BalancingTests/balancingTestsStore';


export default {
  namespaced: true,
  modules: {
    baseSchedules,
    balancingTests,
  },
};