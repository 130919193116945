import { CISO_CMRI_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';

const state = {
  tableData: [],
};
const actions = {
  async fetchCommitmentCost({ commit, dispatch }, request) {
    dispatch('reset');
    const { startDate, endDate } = getMomentDateStringFromRangeArray(request.tradeDateRangeSelected);
    const params = {
      scs: request.coordinatorListSelected,
      rsrc_id: request.resourceListSelected ? request.resourceListSelected.join(',') : null,
      startDate,
      endDate,
    };
    try {
      const { data } = await CISO_CMRI_API.get('/commitment-cost', { params });
      data.forEach((obj) => { obj.tradingDate = getMomentDateString(obj.tradingDate); });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load Commitment Cost', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};