import moment from 'moment';
import { BILLING_STLMTS_API } from '@/api';
import config from './settlementsChargeDetailsConfig';

const state = {
  tableData: [],
  tableConfig: config.columns,
};

const actions = {
  async fetchChargeDetails({ dispatch }, params) {
    try {
      const response = await BILLING_STLMTS_API.put('/statements/charge-details?', params);
      dispatch('createTableData', response.data);
    } catch (error) {
      this.$notify('Failed to load CB Charge Details', 'error');
    }
  },

  async createTableData({ commit }, data) {
    if (data && data.data && data.data.length > 0) {
      commit('reset');

      const flatArray = [];
      data.data.forEach((head) => {
        head.data.forEach((mid) => {
          mid.tradingDate = moment.utc(mid.tradingDate).format('MM/DD/YYYY');
          const newObj = {};
          Object.keys(mid).forEach((key) => {
            if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
          });
          Object.keys(head).forEach((key) => {
            if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
          });
          flatArray.push(newObj);
        });
      });
      commit('setTableData', flatArray);
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
    if (value.length === 1) {
      state.tableConfig[14].visible = false;
      state.tableConfig[15].visible = false;
      state.tableConfig[16].visible = false;
      state.tableConfig[17].visible = false;
      state.tableConfig[18].visible = false;
    } else {
      state.tableConfig[14].visible = true;
      state.tableConfig[15].visible = true;
      state.tableConfig[16].visible = true;
      state.tableConfig[17].visible = true;
      state.tableConfig[18].visible = true;
    }
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  reset(state) {
    state.tableData = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};