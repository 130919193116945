import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { BILLING_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  contractList: [],
  nullTableRow: {
    id: -999,
    contract_Id: null,
    contractName: null,
    priceNode: null,
    priceType: null,
    sourceSystem: null,
    sourceType: null,
    multiplier: null,
    effectiveDate: null,
    terminationDate: null,
    updatedBy: null,
    updatedDate: null,
    createdBy: null,
    createdDate: null,
  },
};

const getters = {
  getContractList: (state) => state.contractList.map(({ id, name }) => ({ value: id, label: name })),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchContractList');
  },
  async fetchPrice({ commit }, parameters = {}) {
    const params = {
      contracts: parameters.contractsSelected,
    };
    try {
      const { data: { data } } = await BILLING_REF_API.post('/price/get', params);
      if (Array.isArray(data)) {
        data.forEach((obj) => {
          obj.effectiveDate = getMomentDateString(obj.effectiveDate);
          obj.terminationDate = obj.terminationDate === null ? null : getMomentDateString(obj.terminationDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Price Data', 'error');
    }
  },
  async fetchContractList({ commit }) {
    try {
      const { data: { data } } = await BILLING_REF_API.get('/contracts');
      commit('loadContractList', data);
    } catch (err) {
      this.$notify('Failed to fetch Contract list', 'error');
      console.error(err);
    }
  },
  async postRecord({ state }, price) {
    price.startDate = getMomentDateString(price.startDate);
    price.endDate = price.endDate === null ? null : getMomentDateString(price.endDate);

    BILLING_REF_API.post('/price', price).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = data.terminationDate === null ? null : getMomentDateString(data.terminationDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  async updateRecord({ dispatch, commit, state }, price) {
    price.effectiveDate = getMomentDateString(price.effectiveDate);
    price.terminationDate = price.terminationDate === null ? null : getMomentDateString(price.terminationDate);

    BILLING_REF_API.put(`/price/${price.id}`, price).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = data.terminationDate === null ? null : getMomentDateString(data.terminationDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  async deleteTableRow({ dispatch, commit, state }) {
    try {
      await BILLING_REF_API.delete(`/price/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Remove Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};