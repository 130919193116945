import moment from 'moment';
import { CISO_CMRI_API } from '@/api';
import config from './expectedEnergyConfig';

const state = {
  tableData: [],
  summaryTableData: [],
  tableConfig: config.fields,
  summaryTableConfig: config.summaryFields,
  parameters: [],
};

const actions = {
  async fetchExpectedEnergy({ commit }, { params, isSummary }) {
    try {
      commit('setParameters', params);
      commit('setTableData', []);
      commit('setSummaryTableData', []);
      if (isSummary) {
        const { data: { data }, data: { messages } } = await CISO_CMRI_API.get('/expected-energy/hourly-summary', { params });
        if (messages && messages[0] === 'A timeout exception occurred.') {
          this.$notify('Data volume caused timeout, please limit your search', 'error');
        } else {
          const result = data.map((m) => m.data.map((p) => ({ ...p, ...m, data: [] })));
          result.forEach((group) => {
            group.forEach((row) => {
              row.tzTradingDate = moment.utc(row.tzTradingDate).format('MM/DD/YYYY');
              row.tradingDate = moment.utc(row.tradingDate).format('MM/DD/YYYY');
            });
          });
          commit('setSummaryTableData', result.flat());
        }
      } else {
        const { data: { data }, data: { messages } } = await CISO_CMRI_API.get('expected-energy', { params });
        if (messages && messages[0] === 'A timeout exception occurred.') {
          this.$notify('Data volume caused timeout, please limit your search', 'error');
        } else {
          const result = data.map((m) => m.data.map((p) => ({ ...p, ...m, data: [] })));
          result.forEach((group) => {
            group.forEach((row) => {
              row.tzTradingDate = moment.utc(row.tzTradingDate).format('MM/DD/YYYY');
              row.tradingDate = moment.utc(row.tradingDate).format('MM/DD/YYYY');
            });
          });
          commit('setTableData', result.flat());
        }
      }
    } catch (e) {
      this.$notify('Failed to Retrieve Request');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setSummaryTableData', []);
  },
};

const mutations = {
  setParameters(state, params) {
    state.parameters = params;
  },
  setTableData(state, data) {
    state.tableData = Object.freeze(data);
  },
  setSummaryTableData(state, data) {
    state.summaryTableData = Object.freeze(data);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setSummaryTableConfig(state, value) {
    state.summaryTableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};