import { PPA_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchCustomerMasterfile');
  },
  async fetchCustomerMasterfile({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/invoice-entity/customer-masterfile');
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.ppaStartDate = getMomentDateString(x.ppaStartDate);
          x.ppaEndDate = x.ppaEndDate === null ? null : getMomentDateString(x.ppaEndDate);
        });
        commit('loadTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to fetch Customer Masterfile', 'error');
    }
  },
  resetTable({ commit, state }) {
    commit('loadTableData', []);
  },
};

const mutations = {
  loadTableData(state, records) {
    state.tableData = records;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};