import {
  coordinatorList,
  tradeDateRange,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  searchFiltersConfig: {
    tradeDateRangeList: tradeDateRange,
    coordinatorList: { ...coordinatorList, required: true },
    detailType: {
      label: '',
      value: 'allData',
      type: 'radio',
      options: [{ value: 'allData', label: 'All Data' }, { value: 'redundantData', label: 'Redundant Data' }],
    },
  },
  tableConfiguration: {
    name: 'removeData',
    columns: [{
      label: 'BA',
      prop: 'ba',
      filterable: true,
      sortable: true,
    }, {
      label: 'TRADE DATE',
      prop: 'tradeDate',
      filterable: true,
      sortable: true,
    }, {
      label: 'ID',
      prop: 'statementId',
      filterable: true,
      sortable: true,
    }, {
      label: 'FILE TYPE',
      prop: 'fileType',
      filterable: true,
      sortable: true,
    }, {
      label: 'SETTLEMENT TYPE',
      prop: 'settlementType',
      filterable: true,
      sortable: true,
    }, {
      label: 'VERSION',
      prop: 'version',
      filterable: true,
      sortable: true,
    }],
    options: {
      filterRow: true,
      columnsMovable: true,
      filterHeader: true,
    },
  },
};