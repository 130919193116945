import { createMutations } from '@/utils/vuexHelper';
import utils from '@/utils';
import { clone, cloneDeep } from '@/utils/dataUtil';
import valueFormatStore from '@/utils/valueFormatStore';
import userStore from '@/utils/userStore';

const state = {
  userScreenNumericDefaults: [],
  numericTypeOptions: valueFormatStore.numericTypeOptions,
  numericTypeSelection: 'fixedPoint',
  numericFormatSelection: {},
  tableConfig: {
    columns: [{
      label: 'Fixed Point',
      prop: 'fixedPoint',
      sortable: true,
    }, {
      label: 'Decimal',
      prop: 'decimal',
      sortable: true,
    }, {
      label: 'Percent',
      prop: 'percent',
      sortable: true,
    }, {
      label: 'Currency',
      prop: 'currency',
      sortable: true,
    }],
  },
  tableData: [{
    fixedPoint: 1.00, decimal: 1.15, percent: 1.30, currency: 1.45,
  },
  {
    fixedPoint: 10.00, decimal: 10.15, percent: 10.30, currency: 10.45,
  },
  {
    fixedPoint: 100.00, decimal: 100.15, percent: 100.30, currency: 100.45,
  },
  {
    fixedPoint: 10000.00, decimal: 10000.15, percent: 10000.30, currency: 10000.45,
  }],
  screen: userStore.getLandingPage(),
};

const actions = {
  async loadDefaults({ commit }) {
    try {
      const data = await utils.user.getDefaultOptions();
      let valueFormatOptions = data ? data.valueFormatOptions : null;
      const genericFormats = valueFormatStore.genericNumericFormats;
      genericFormats.screen = state.screen;
      if (valueFormatOptions) {
        if (!(valueFormatOptions[0].screen === 'Settings')) {
          valueFormatOptions = [...[genericFormats], ...valueFormatOptions];
        }
        commit('setUserScreenNumericDefaults', valueFormatOptions);
        commit('setTableConfigs', state.userScreenNumericDefaults[0]);
        commit('setNumericFormatType', 'fixedPoint');
      } else {
        commit('setUserScreenNumericDefaults', [genericFormats]);
        commit('setTableConfigs', genericFormats);
        commit('setNumericFormatType', 'fixedPoint');
      }
    } catch (error) {
      console.error(error);
    }
  },
  async saveDefaultSelections({ state, dispatch, commit }, selectedFormat) {
    const data = await utils.user.getDefaultOptions();
    commit('overwriteFormat', selectedFormat);

    try {
      const model = {
        ...data,
        valueFormatOptions: state.userScreenNumericDefaults,
      };

      const result = await utils.user.updateDefaultOptions(model);

      this.$notify('Defaults Saved.', 'success');
    } catch (error) {
      console.error(error);
      this.$notify('Failed to save defaults', 'error');
    }
  },
  changeScreen({ commit }, selectedScreen) {
    commit('setScreen', selectedScreen);
    const screenFormats = state.userScreenNumericDefaults.find(({ screen }) => screen === selectedScreen);
    if (!screenFormats) {
      const genericFormats = clone(valueFormatStore.genericNumericFormats);
      genericFormats.screen = selectedScreen;
      commit('setScreenFormat', genericFormats);
      commit('setTableConfigs', genericFormats);
      commit('setNumericFormatType', 'fixedPoint');
    } else {
      commit('setTableConfigs', screenFormats);
    }
  },
};

const mutations = {
  overwriteFormat(state, formatDetails) {
    let formatDetail = state.userScreenNumericDefaults.find(({ screen }) => formatDetails.screen === screen);
    formatDetail = { ...formatDetail, [formatDetails.type]: formatDetails };

    const index = state.userScreenNumericDefaults.findIndex(({ screen }) => formatDetails.screen === screen);
    state.userScreenNumericDefaults[index] = formatDetail;
  },
  setScreenFormat(state, newFormats) {
    state.userScreenNumericDefaults.push(newFormats);
  },
  setTableConfigs(state, numericConfigs) {
    const config = clone(state.tableConfig);
    Object.keys(numericConfigs).forEach((key) => {
      const formatType = config.columns.find(({ prop }) => prop === key);
      if (formatType) formatType.format = numericConfigs[key].customEnabled ? numericConfigs[key].customFormat : numericConfigs[key].basicFormat;
    });
    state.tableConfig = config;
  },
  setTableConfig(state, numericConfig) {
    const configs = clone(state.tableConfig);
    const { basicFormat: { type } } = numericConfig;
    const updateConfig = configs.columns.find(({ prop }) => prop === type);
    updateConfig.format = numericConfig.customEnabled ? numericConfig.customFormat : numericConfig.basicFormat;
    state.tableConfig = clone(configs);
  },
  setNumericFormatType(state, value) {
    state.numericTypeSelection = value;
    const formatDetails = state.userScreenNumericDefaults.find(({ screen }) => screen === state.screen);
    state.numericFormatSelection = formatDetails[value];
  },
  ...createMutations(
    'numericStyle',
    'screen',
    'userScreenNumericDefaults',
  ),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
