<template>
  <div id="pscs-application">
    <pscs-loading v-if="$auth.isLoading" />
    <pscs-page-navbar v-else ref="navbar">
      <div>
        <router-view :key="$route.fullPath" />
      </div>
    </pscs-page-navbar>
    <pscs-notification />
    <pscs-cli v-if="cliVisibility" />
    <textarea
      id="hidden_data"
      :value="hiddenContent"
      style="position: absolute; top: 0; left: -500px; width: 0; height: 0;" />
    <div id="pscs-clipboard" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PscsPageNavbar from './components/PscsPage/PscsPageNavbar';
import PscsLoading from './components/PscsLoading/PscsLoading';
import PscsNotification from './components/PscsNotification/PscsNotification';
import PscsCli from './components/PscsCli/PscsCli';

/* eslint-disable no-unused-expressions */
import(/* webpackPreload: true */ 'devextreme/dist/css/dx.common.css');
import(/* webpackPrefetch: true */ 'devextreme/dist/css/dx.light.compact.css');

import(/* webpackPrefetch: true */ 'bootstrap/dist/css/bootstrap.min.css');
import(/* webpackPrefetch: true */ 'element-ui/lib/theme-chalk/index.css');

import(/* webpackPrefetch: true */ '@fortawesome/fontawesome-free/css/all.min.css');
import(/* webpackPrefetch: true */ '@fortawesome/fontawesome-free/css/v4-shims.min.css');

export default {
  name: 'PscsApp',
  components: {
    PscsPageNavbar, PscsLoading, PscsCli, PscsNotification,
  },
  computed: mapGetters({
    cliVisibility: 'cli/getCLIVisibility',
    hiddenContent: 'clipboard/getContent',
  }),
};

</script>

<style lang="scss">
@import './styles/app.scss';

body {
  position: relative;
  min-height: 100%;
  font: 100% $font-family;
  font-size: $font-size;
  color: $color-text-lightest;
  background-color: $color-background !important;

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 6px 0;

    .header-title {
      display: flex;
      justify-content: space-between;
      height: 28px;
      font-size: 1.15em;
      line-height: 28px;
    }

    .header-middle,
    .header-end {
      display: flex;

      div[class*=pscs-] {
        margin: 1px;

        &:last-child {
          margin-right: 0;
        }
      }

      .pscs-checkbox-dx {
        .dx-checkbox-text {
          color: white;
        }
      }

      .pscs-input-count-prog-bar {
        margin-right: 10px;

        .el-input-number.el-input-number--small {
          height: 22px;
          line-height: 22px;
        }

        .el-icon-refresh {
          top: 2px;
        }
      }

      .el-date-editor {
        width: 300px;
        height: 26px;
        padding: 0 5px;
        margin-left: 10px;

        .el-range-separator {
          width: 8%;
        }
      }
    }
  }

  a:hover {
    cursor: pointer;
  }
  // Styles the scrollbar
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }

  ::-webkit-scrollbar-track {
    background-color: $gray-lightest;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $gray-light;
    outline: 1px solid $gray-dark;
  }

  .dxc-tooltip {
    z-index: 1503;
  }

  hr {
    margin: 8px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  // Enables cursor events on disabled elements
  .dx-state-disabled {
    pointer-events: auto;
  }

  #pscs-application {
    color: $gray-dark !important;

    .dx-tabpanel {
      background-color: white;
    }

    .highlight {
      background-color: yellow;
    }

    .dx-drawer-content {
      color: $whitesmoke;
    }

    .hide {
      display: none;
    }

    .pointer {
      cursor: pointer;
    }

    .v-modal {
      z-index: 999 !important;
    }

    .fade-enter {
      opacity: 0;
    }

    .fade-enter-active {
      transition: opacity 0.65s ease;
    }

    .fade-leave-active {
      opacity: 0;
      transition: opacity 0.65s ease;

      .pscs-chart {
        display: none;
      }
    }

    .pivotGridDiffCell {
      background-color: #ffe3e1;
    }

    .pivotGridDiffCellValid {
      background-color: #ffffe0;
    }

    // Sets up the toast messages to load below each other
    .dx-toast > .dx-toast-wrapper {
      right: 0;

      .dx-toast-content {
        top: auto !important;
        right: 0;
        bottom: -100vh !important;
        left: initial !important;
        display: flex;
        align-items: center;
        width: 500px !important;
        height: 8vh !important;
        margin: 20px !important;
        transform: initial !important;

        .dx-toast-icon {
          width: 42px;
          height: 100%;
          margin-left: 7px;
          background-size: auto;
        }

        .dx-toast-message {
          width: 90%;
          padding: 0;
        }
      }
    }

    .dx-toast ~ .dx-toast .dx-toast-content {
      margin: 12vh 20px !important;

      @nest .dx-toast ~ & {
        margin: 22vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ & {
        margin: 32vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ .dx-toast ~ & {
        margin: 42vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ & {
        margin: 52vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ & {
        margin: 62vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ & {
        margin: 72vh 20px !important;
      }

      @nest .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ .dx-toast ~ & {
        margin: 82vh 20px !important;
      }
    }
  }

  .el-popper[x-placement^=bottom] {
    margin-top: 2px;
  }
}
</style>