import moment, { weekdays } from 'moment';
import 'moment-timezone';
import helpers from '@/utils/helpers';

function splitProfilesToHourlySegments(profiles) {
  const hourlyProfiles = profiles.reduce((acc, profile) => {
    // get the profile's start and end time difference
    const timeDifferenceInHours = profile.momentEndTime.diff(profile.momentStartTime, 'hours');

    // if profile already spans only one hour
    if (timeDifferenceInHours === 1) {
      acc.push(profile);
    } else {
      // split profile to hourly segments
      for (let i = 0; i < timeDifferenceInHours; i++) {
        const momentStartTime = profile.momentStartTime.clone().add(i, 'hours');
        const momentEndTime = momentStartTime.clone().add(1, 'hours');
        const startTime = momentStartTime.clone().toISOString();
        const endTime = momentEndTime.clone().toISOString();

        // with the exception of start and end times the
        // hourly profiles should be identical to profile
        const hourlyProfile = {
          ...profile,
          ...{
            startTime,
            endTime,
            momentStartTime,
            momentEndTime,
          },
        };

        acc.push(hourlyProfile);
      }
    }

    return acc;
  }, []);
  return hourlyProfiles;
}

function consolidateHourlyProfiles(hourlyProfiles) {
  const sortedProfiles = helpers.sortBy(hourlyProfiles, (o) => o.momentStartTime);

  const consolidatedProfiles = sortedProfiles.reduce((acc, nextProfile) => {
    const lastProfile = acc.pop();

    if (!lastProfile) {
      // if it's the first profile in the iteration then just add it to the accumulator
      acc.push(nextProfile);
    } else {
      // compare last profile to next profile
      const isTimeSequential = lastProfile.momentEndTime.toISOString() === nextProfile.momentStartTime.toISOString();

      const timeProperties = ['startTime', 'endTime', 'momentStartTime', 'momentEndTime'];

      // 'other' meaning the non time properties
      const allOtherPropertiesEqual = Object.keys(lastProfile)
        .filter((prop) => !timeProperties.includes(prop))
        .every((prop) => lastProfile[prop] === nextProfile[prop]);

      if (isTimeSequential && allOtherPropertiesEqual) {
        const { endTime, momentEndTime } = nextProfile;
        // update the end times on last profile
        const consolidatedProfile = {
          ...lastProfile,
          ...{
            endTime,
            momentEndTime,
          },
        };
        acc.push(consolidatedProfile);
      } else {
        // push both profiles as separate profiles
        acc.push(lastProfile);
        acc.push(nextProfile);
      }
    }

    return acc;
  }, []);
  return consolidatedProfiles;
}

function mergeRangeProfiles(currentProfiles, newProfiles) {
  // check if current profiles are from initial state
  const isInitialProfileState = currentProfiles.filter((p) => p.isDefaultProfile).length > 0;
  const profiles = isInitialProfileState ? [] : currentProfiles;

  // split current and new profiles into hourly segments
  const profilesHourly = splitProfilesToHourlySegments(profiles);
  const newProfilesHourly = splitProfilesToHourlySegments(newProfiles);

  // get only the profiles that do not have overlap with the new profiles
  // new profiles take precedence if there is overlap
  const distinctProfilesHourly = profilesHourly.reduce((acc, profile) => {
    const hasOverlap = newProfilesHourly
      .filter((p) => p.momentStartTime.toISOString() === profile.momentStartTime.toISOString()
                  && p.momentEndTime.toISOString() === profile.momentEndTime.toISOString())
      .length > 0;

    if (!hasOverlap) {
      acc.push(profile);
    }
    return acc;
  }, []);

  const mergedProfilesHourly = [...distinctProfilesHourly, ...newProfilesHourly];
  const consolidatedProfiles = consolidateHourlyProfiles(mergedProfilesHourly);
  return consolidatedProfiles;
}

function createTimeMarkers(momentStartTime, momentEndTime) {
  const [, startTimeFromSettings] = moment().startOf('day').toISOString().split('T');
  const [startHourFromSettings] = startTimeFromSettings.split(':');
  const hourStartFromSettings = Number(startHourFromSettings);
  const [, startTime] = momentStartTime.clone().toISOString().split('T');
  const [startHour] = startTime.split(':');
  const hourStart = Number(startHour);
  const [, endTime] = momentEndTime.clone().toISOString().split('T');
  const [endHour] = endTime.split(':');
  const hourEnd = Number(endHour);
  const differenceInHoursStartAndEnd = Math.abs(momentStartTime.diff(momentEndTime, 'hours'));

  return {
    hourStart, hourEnd, hourStartFromSettings, differenceInHoursStartAndEnd,
  };
}

export function createDataArray(timeArray, requestArray, dateStore, zone, objectDefinition, dailyDate) {
  const resultArray = [];
  timeArray.forEach((rng) => {
    for (let idx = 0; idx < requestArray.length; idx++) {
      const requestItem = requestArray[idx];
      const newItem = { ...objectDefinition, ...requestItem };

      const [rangeMomentStartDate] = rng.momentStartTime.clone().startOf('day').toISOString().split('T');
      const [profileMomentStartDate] = requestItem.momentStartTime.clone().startOf('day').toISOString().split('T');

      // get long day
      const shortDayLongDayInfo = dateStore.getShortAndLongDays(requestItem.momentStartTime);
      const [longDate] = shortDayLongDayInfo.longDay.toISOString().split('T');
      const [shortDate] = shortDayLongDayInfo.shortDay.toISOString().split('T');
      const [dailyProfileDate] = dailyDate.clone().toISOString().split('T');

      let rangeUtcStartOfDay = moment(`${rangeMomentStartDate}T00:00:00.000Z`);
      let profileUtcStartOfDay = moment(`${profileMomentStartDate}T00:00:00.000Z`);

      // special case for long day
      let isLongDayBetweenDateRange = false;
      if (moment(rangeMomentStartDate).isBefore(profileMomentStartDate)) {
        isLongDayBetweenDateRange = moment(longDate).isBetween(rangeMomentStartDate, profileMomentStartDate, null, '[]');
        if (isLongDayBetweenDateRange) {
          profileUtcStartOfDay = moment(`${profileMomentStartDate}T01:00:00.000Z`);
        }
      } else if (moment(rangeMomentStartDate).isAfter(profileMomentStartDate)) {
        isLongDayBetweenDateRange = moment(longDate).isBetween(profileMomentStartDate, rangeMomentStartDate, null, '[]');
        if (isLongDayBetweenDateRange) {
          rangeUtcStartOfDay = moment(`${rangeMomentStartDate}T01:00:00.000Z`);
        }
      }

      // calculate differences in days
      const differenceInDays = rangeUtcStartOfDay.diff(profileUtcStartOfDay, 'days');

      newItem.momentStartTime = requestItem.momentStartTime.clone().add(differenceInDays, 'days');
      newItem.momentEndTime = requestItem.momentEndTime.clone().add(differenceInDays, 'days');

      // special cases for long day and short day
      if (dateStore.isLongDay(newItem.momentStartTime)) {
        const differenceInHoursProfile = Math.abs(requestItem.momentStartTime.clone().add(1, 'days')
          .diff(requestItem.momentEndTime.clone().add(1, 'days'), 'hours'));

        const differenceInHoursItem = Math.abs(newItem.momentStartTime.diff(newItem.momentEndTime, 'hours'));

        if (differenceInHoursItem - differenceInHoursProfile === 1) {
          newItem.momentEndTime.subtract(1, 'hours');
        }

        const markers = createTimeMarkers(newItem.momentStartTime, newItem.momentEndTime);

        if (markers.hourStart - markers.hourStartFromSettings === 0 && markers.differenceInHoursStartAndEnd > 1 && !moment(dailyProfileDate).isSame(longDate)) {
          newItem.momentEndTime.add(1, 'hours');
        } else if (markers.hourStart - markers.hourStartFromSettings === 2) {
          newItem.momentStartTime.subtract(1, 'hours');
        } else if (markers.hourEnd - markers.hourStartFromSettings === 2) {
          newItem.momentEndTime.add(1, 'hours');
        }
      } else if (dateStore.isShortDay(newItem.momentStartTime)) {
        if (newItem.momentStartTime.toISOString() === newItem.momentEndTime.toISOString()) {
          newItem.momentEndTime.add(1, 'hours');
        }

        const markers = createTimeMarkers(newItem.momentStartTime, newItem.momentEndTime);

        if (markers.hourStart - markers.hourStartFromSettings === 2 && markers.differenceInHoursStartAndEnd > 1 && moment(dailyProfileDate).isSameOrBefore(shortDate)) {
          newItem.momentStartTime.add(1, 'hours');
        }
      }

      newItem.startTime = newItem.momentStartTime.clone().utc().format();
      newItem.endTime = newItem.momentEndTime.clone().utc().format();
    }
  });

  return resultArray;
}

export function createTimeArray(rule, dateStore, zone) {
  const time = [];
  
  // we have to implement our own date valuation because RRUle is totally broken over DST dates
  const startDate = dateStore.toDateFromLocal(rule.options.dtstart.toISOString(), zone);
  const endDate =  dateStore.toDateFromLocal(rule.options.until.toISOString(), zone);
  const date = startDate.clone();

  const selectedWeekdays = rule.options.byweekday;

  // we know we only use daily expansion foor specific week days
  while (date.isBetween(startDate, endDate, null, '[]')) {
    // RRule evaluates Monday as 0 while Moment evaluates as 1, -1 to compensate
    var wd = date.weekday() - 1;
    // Handle for Sunday, since RRule evaluates sunday as 0, it will return -1 when it should be 6
    if (wd < 0)
      wd = 6;
    if (selectedWeekdays.indexOf(wd) >= 0) {
      const momentStartTime = dateStore.toDateFromLocal(date, zone).utc();
      const momentEndTime = dateStore.toDateFromLocal(date, zone).clone().add(1, 'days').utc();

      time.push({
        momentStartTime,
        momentEndTime,
        startTime: momentStartTime.clone().utc().format(),
        endTime: momentEndTime.clone().utc().format(),
      });
    }

    date.add(1, 'days');
  }

  return time;
}

export async function createWeekRule(dateRange, selectedDays) {
  const { RRule } = await import('rrule');
  return new RRule({
    freq: RRule.WEEKLY,
    byweekday: selectedDays,
    dtstart: dateRange[0],
    until: dateRange[1],
  });
}

export async function createWeekDaysObject() {
  const { RRule } = await import('rrule');
  return {
    MO: {
      text: 'MONDAY',
      selected: false,
      value: RRule.MO,
    },
    TU: {
      text: 'TUESDAY',
      selected: false,
      value: RRule.TU,
    },
    WE: {
      text: 'WEDNESDAY',
      selected: false,
      value: RRule.WE,
    },
    TH: {
      text: 'THURDAY',
      selected: false,
      value: RRule.TH,
    },
    FR: {
      text: 'FRIDAY',
      selected: false,
      value: RRule.FR,
    },
    SA: {
      text: 'SATURDAY',
      selected: false,
      value: RRule.SA,
    },
    SU: {
      text: 'SUNDAY',
      selected: false,
      value: RRule.SU,
    },
  };
}

export function resolveDateRange(dateRange) {
  // For some reason RRule cannot correctly determine date range when the
  // offset of the 'start' date is higher than the offset of the 'end' date.
  // When this happens, we need to increment the end time by an hour so that RRule
  // can correctly determine the ranges.
  let [start, end] = dateRange;
  start = moment.isMoment(start) ? start : moment.utc(start);
  end = moment.isMoment(end) ? end : moment.utc(end);

  const [, startTime] = start.clone().toISOString().split('T');
  const [, endTime] = end.clone().toISOString().split('T');
  const [startHour] = startTime.split(':');
  const [endHour] = endTime.split(':');
  const hourStart = Number(startHour);
  const hourEnd = Number(endHour);
  if (hourStart - hourEnd === 1) {
    start = start.clone();
    end = end.clone().add(1, 'hours');
  }
  return [start.toDate(), end.toDate()];
}
