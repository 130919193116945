import { tradeDateRange, coordinatorList } from '@/components/Ciso/BaseStore/CisoConfigs';

const options = {
  columnConfig: true,
  columnsMovable: true,
  exportExcel: true,
  filterHeader: true,
  filterRow: true,
};

export default {
  searchFiltersConfig: {
    tradeDateRange,
    coordinatorList,
    dateType: {
      label: 'Date Type',
      value: 'PublishedDate',
      type: 'radio',
      options: [{ value: 'PublishedDate', label: 'Publish Date' }, { value: 'TradingDate', label: 'Trade Date' }],
    },
    currentUserFlag: {
      label: 'Return My Tasks',
      value: false,
      type: 'checkbox',
    },
  },
  fields: [{
    caption: 'SCID',
    dataField: 'scid',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'File Type',
    dataField: 'fileType',
    dataType: 'string',
    area: 'row',
    expanded: true,
  }, {
    caption: 'Cycle',
    dataField: 'cycle',
    dataType: 'string',
    area: 'row',
    expanded: true,
    sortingMethod(a, b) { return a.value.localeCompare(b.value, 'en', { numeric: true }); },
  }, {
    caption: 'Trading Date',
    dataField: 'tradingDate',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
  }, {
    caption: 'Published Date',
    dataField: 'publishedDate',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'column',
    expanded: true,
  }, {
    caption: 'BA',
    dataField: 'tagCode',
    dataType: 'string',
    area: 'filter',
    expanded: true,
    visible: false,
  }, {
    caption: 'TaskID',
    dataField: 'taskId',
    dataType: 'string',
    area: 'row',
    expanded: true,
    visible: false,
  }, {
    caption: 'Task Name',
    dataField: 'taskName',
    dataType: 'string',
    area: 'column',
    expanded: true,
  }, {
    caption: 'StatusID',
    dataField: 'statusId',
    datatype: 'number',
    area: 'data',
    expanded: true,
    visible: true,
  }, {
    caption: 'UserName',
    dataField: 'userName',
    datatype: 'string',
    area: 'filter',
    expanded: true,
    visible: false,
  }, {
    caption: 'Created Time Stamp',
    dataField: 'createdTimestamp',
    dataType: 'date',
    format: 'yyyy-MM-dd',
    area: 'row',
    expanded: true,
    visible: false,
  }, {
    caption: 'Note',
    dataField: 'note',
    dataType: 'string',
    area: 'row',
    expanded: true,
    visible: false,
  }, {
    caption: 'Assignee',
    dataField: 'assignee',
    datatype: 'string',
    summaryType: 'min',
    area: 'filter',
    expanded: true,
    visible: true,
  }],
};