import { CISO_OMS_API } from '@/api';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import dateStore from '@/utils/dateStore';

const state = {
  availabilityTableData: [],
};

const actions = {
  async fetchAvailability({ commit, state }, parameters = {}) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      scs: parameters.coordinatorListSelected,
      startTime: startDate,
      endTime: endDate,
      tz: dateStore.getTimeZone(),
    };
    try {
      const { data: { data } } = await CISO_OMS_API.get('/availability', { params });
      commit('setTableData', data);
    } catch (error) {
      this.$notify({ type: 'error', message: 'Error occurred during availability retrieval' });
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, tableData) {
    state.availabilityTableData = tableData;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};