import Vue from 'vue';

Vue.filter('two_digits', (value) => {
  if (value.toString().length <= 1) {
    return `0${value.toString()}`;
  }
  return value.toString();
});

Vue.filter('json', (value) => JSON.stringify(value, null, 2));

Vue.filter('lower_case', (value) => {
  if (value !== null) {
    return value.toString().toLowerCase();
  }
  return '';
});

Vue.filter('uppercase', (value) => {
  if (!value) return '';
  value = value.toString();
  return value.toUpperCase();
});