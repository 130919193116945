import moment from 'moment';
import { TOOLS_API } from '@/api';

const state = {
  EIMTcPseMapTableData: [],
};

const actions = {
  async fetchEIMTcPseMap({ commit }, args) {
    const params = {
      date: args.tradeDateRangeSelected[0].toISOString(),
      scs: args.coordinatorListSelected,
    };

    try {
      const response = await TOOLS_API.get('/transmission-billing-info/sub-companies-pse-map', { params });
      commit('setEIMTcPseMapTableData', response.data);
    } catch {
      this.$notify('Failed to Load EIM Tc Pse Map', 'error');
    }
  },
};

const mutations = {
  setEIMTcPseMapTableData(state, data) {
    state.EIMTcPseMapTableData = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};