import Vue from 'vue';
import moment from 'moment';
import { VA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import caisoStore from '@/utils/caiso/caisoUtils';
import VABaseStore from '../VABaseStore';

const state = {
  mapCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  selectedVariant: null,
  selectedRule: null,
  selectedDataPoint: null,
  selectedDate: null,
  selectedGroup: 'ALL',
  intervalValues: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
  countsTableData: [],
  ruleCountSummaries: {},
};

const getters = {
  isRealtime: (state) => moment(state.selectedDate).isSame(moment(), 'day'),
  hours: () => Array.from({ length: 24 }, (_, idx) => ({ value: idx, label: idx })),
  intervals: (state) => state.intervalValues.map((value) => ({ value, label: value })),
  heIntervals: (state) => Array.from({ length: 24 }).reduce((acc, _, idx) => {
    // Creates 288 5 min intervals: removes 24:00
    state.intervalValues.forEach((i) => {
      let min = i;
      if (min < 10) min = `0${min}`;
      if (idx !== 24) acc.push(`${idx}:${min}`);
    });
    return acc;
  }, ['0:00']),
  formattedCountsTableData: (state) => {
    const formattedData = {};

    state.countsTableData.forEach((ruleIntervalData) => {
      const { ruleSeriesId, count, name } = ruleIntervalData;
      const he = moment(ruleIntervalData.startTime).hours();
      const interval = moment(ruleIntervalData.startTime).minutes();
      const intervalId = parseInt(`${he}${interval}`, 10);

      if (formattedData[intervalId]) {
        formattedData[intervalId][`${name}_count`] = count;
      } else {
        formattedData[intervalId] = {
          id: intervalId,
          he,
          interval,
          ruleSeriesId,
          [`${name}_count`]: count,
        };
      }
    });

    const sortedData = Object.values(formattedData).sort((a, b) => ((a.he * 60) + a.interval) - ((b.he * 60) + b.interval));

    return sortedData;
  },
  countsTableSummaryData: (state, getters) => {
    const ignoreKeys = ['id', 'he', 'interval', 'ruleSeriesId'];
    const summaries = {
      id: 0,
      summary: 'Total',
    };
    getters.formattedCountsTableData.forEach((interval) => {
      Object.keys(interval).forEach((intervalKey) => {
        if (!ignoreKeys.includes(intervalKey)) {
          const count = interval[intervalKey];
          summaries[intervalKey] = summaries[intervalKey] ? summaries[intervalKey] + count : count;
        }
      });
    });
    return [summaries];
  },
  seriesNames: (state, getters) => {
    const chartsTreeOrder = [];
    const seriesId = [];
    for (let i = 0; i < VABaseStore.state.chartsTree.length; i++) {
      for (let j = 0; j < VABaseStore.state.chartsTree[i].items.length; j++) {
        if (VABaseStore.state.chartsTree[i].text !== 'Charts' && VABaseStore.state.chartsTree[i].items[j].text.includes('Rule') === false) {
          chartsTreeOrder.push({ label: VABaseStore.state.chartsTree[i].items[j].text, id: VABaseStore.state.chartsTree[i].items[j].seriesId });
        }
      }
    }
    return chartsTreeOrder;
  },
  ruleColumns: (state) => {
    const { rules } = state.VABaseStore;
    const ruleColumnWidth = (rules.length < 30)
      ? `${95 / rules.length}%`
      : 80;

    if (!Array.isArray(rules)) return [];
    return rules.map(({ description, name }) => ({
      label: description,
      prop: `${name}_count`,
      width: ruleColumnWidth,
      fixed: 'false',
      filterable: true,
    }));
  },
  countsTableConfig: (state, getters) => ({
    tableName: 'countsTableConfig',
    columns: [{
      label: 'Hour',
      prop: 'he',
      width: '2.5%',
      fixed: 'true',
      alignment: 'left',
      filterable: true,
    }, {
      label: 'interval',
      prop: 'interval',
      width: '2.5%',
      fixed: 'true',
      alignment: 'left',
      filterable: true,
    },
    ...getters.ruleColumns],
    options: {
      filterRow: true,
      filterHeader: true,
      multipleSelection: false,
    },
  }),
  summaryTableConfig: (state, getters) => ({
    tableName: 'summaryTableConfig',
    columns: [{
      label: 'Summary',
      prop: 'summary',
      width: '5%',
      fixed: 'true',
      alignment: 'left',
    },
    ...getters.ruleColumns],
    options: {
      filterRow: false,
      filterHeader: false,
      multipleSelection: false,
    },
  }),
  ruleMap: (state, getters) => getters.rules.reduce((acc, curr) => ({ ...acc, [`${curr.name}_count`]: curr.id }), {}),
  ruleIdMap: (state, getters) => getters.rules.reduce((acc, curr) => ({ ...acc, [curr.id]: `${curr.name}_count` }), {}),
};

const actions = {
  async initialize({
    dispatch, commit, getters, state,
  }) {
    commit('setSelectedDate', dateStore.getDefaultDate().toISOString());
    commit('setSelectedGroup', caisoStore.resourceGroupName);
    await dispatch('fetchLocations');
    await dispatch('fetchBusinessRules');
    dispatch('fetchLayouts', true);
    await dispatch('fetchGroupLocations', state.selectedGroup || 'ALL');
    await dispatch('fetchRuleIntervalCount');
    commit('setLocationOnMap', getters.groupedLocations);
  },
  async fetchLocationRuleData({ commit, state, getters }) {
    try {
      const { selectedRule, selectedVariant } = state.VABaseStore;
      if (selectedRule && selectedVariant) {
        const date = moment(state.selectedDate);
        const params = {
          startTime: date.toISOString(),
          endTime: date.add(5, 'minutes').toISOString(),
          variants: getters.selectedVariant,
          objectTypes: 'series',
          realTimeFlag: getters.isRealtime,
          timeZone: dateStore.getTimeZone(),
        };
        const { data: { data } } = await VA_API.get(`business-rules/${selectedRule}/intervals`, { params });
        if (Array.isArray(data)) {
          let numberOfSecondsWaited = 0;
          while (state.mapCollection.features.length == 0 && numberOfSecondsWaited++ < 5) {
            await new Promise((r) => setTimeout(r, 1000));
          }
          commit('updateLocationData', data);
        }
      }
    } catch (error) {
      console.error(error);
      this.$notify('Error retrieving location data', 'error');
    }
  },
  async fetchLocationDataPoint({ commit, state, getters }) {
    try {
      const { selectedRule, selectedVariant } = state.VABaseStore;
      if (selectedRule && selectedVariant) {
        const date = moment(state.selectedDate);
        const params = {
          startTime: date.toISOString(),
          endTime: date.add(5, 'minutes').toISOString(),
          variants: getters.selectedVariant,
          objectTypes: 'series',
          realTimeFlag: getters.isRealtime,
          timeZone: dateStore.getTimeZone(),
        };
        const { data: { data } } = await VA_API.get(`intervals/${state.selectedDataPoint}`, { params });
        if (Array.isArray(data)) {
          let numberOfSecondsWaited = 0;
          while (state.mapCollection.features.length == 0 && numberOfSecondsWaited++ < 5) {
            await new Promise((r) => setTimeout(r, 1000));
          }
          commit('updateLocationData', data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  async fetchBusinessRules({ commit, dispatch, getters }) {
    try {
      const params = {
        startTime: state.selectedDate,
        endTime: moment(state.selectedDate).add(1, 'day').toISOString(),
        variants: getters.selectedVariant,
        realTimeFlag: getters.isRealtime,
      };
      const { data: { data } } = await VA_API.get('business-rules/rule-count', { params });
      const rules = Array.isArray(data) ? data : [];
      commit('setRules', rules);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchRuleIntervalCount({ commit, dispatch, getters }) {
    const date = moment(state.selectedDate).startOf('day');
    const locationGroup = getters.selectedLocationGroup;
    let tableData = [];
    try {
      const params = {
        startTime: date.toISOString(),
        endTime: date.add(1, 'day').toISOString(),
        variants: getters.isRealtime ? 'T' : getters.selectedVariant,
        realTimeFlag: getters.isRealtime,
        locationGroupId: null,
      };
      if (locationGroup && locationGroup !== 'ALL') params.locationGroupId = locationGroup.id;
      const { data: { data } } = await VA_API.get('business-rules/rule-interval-count', { params });
      if (data) tableData = data;
    } catch (error) {
      console.error(error);
    }
    commit('setCountsTableData', tableData);
  },
};

const mutations = {
  updateLocationData(state, data) {
    state.mapCollection.features.forEach((feat) => {
      // Clear out old properties
      feat.properties = {
        location: feat.properties.location,
        deviation: 0,
      };
      const { location } = feat.properties;
      const ruleData = data.find(({ type }) => type === location);
      if (ruleData) {
        // Filters for series values and add it to map point
        const seriesValues = Object.entries(ruleData).filter(([, val]) => val?.value);
        const ruleSeries = seriesValues.find((series) => series[0].includes('rule_'));
        const ruleSeriesNotes = ruleSeries?.[1].notes;
        if (ruleSeries) {
          const ruleSeriesIndex = seriesValues.findIndex((series) => series[0].includes('rule_'));
          if (ruleSeriesNotes && typeof (ruleSeriesNotes) === 'string') {
            const deviation = JSON.parse(ruleSeriesNotes).output;
            Vue.set(feat.properties, 'deviation', deviation);
          }
          seriesValues.splice(ruleSeriesIndex, 1);
        }
        // Generate deviation if not in data.
        if (!ruleSeries || !ruleSeriesNotes) {
          const [series1, series2] = seriesValues;
          if (series1?.[1] && series2?.[1]) {
            const value = series1[1].value - series2[1].value;
            Vue.set(feat.properties, 'deviation', value);
          }
        }
        if (seriesValues && seriesValues.length === 1) {
          Vue.set(feat.properties, 'deviation', seriesValues[0][1].value);
        }
        seriesValues.forEach(([key, val]) => {
          const seriesName = val.description || key;
          Vue.set(feat.properties, seriesName, val.value);
        });
        Vue.set(feat.properties, 'startTime', ruleData.startTime);
      } else {
        Vue.set(feat, 'properties', { location, deviation: 0, startTime: state.selectedDate });
      }
    });
  },
  setLocationOnMap(state, locations) {
    state.mapCollection.features = locations.map(({ longitude, latitude, fullName }) => ({
      type: 'Feature',
      properties: {
        location: fullName,
        deviation: 0,
      },
      geometry: { type: 'Point', coordinates: [longitude, latitude, 0] },
    }));
  },
  setSelectedDataPoint(state, value) {
    state.selectedDataPoint = value;
  },
  reset(state) {
    state.mapCollection = {
      type: 'FeatureCollection',
      features: [],
    };
  },
  ...createMutations(
    'selectedDate',
    'selectedRule',
    'selectedGroup',
    'selectedVariant',
    'countsTableData',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};