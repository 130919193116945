import customerMasterfileRefStore from './CustomerMasterfile/customerMasterfileStore';
import invoiceEntityRefStore from './InvoiceEntities/invoiceEntityStore';
import ppaRefStore from './PPAs/ppaStore';
import chargeCodeRefStore from './ChargeCodes/chargeCodeStore';
import bankRefStore from './Banks/bankStore';
import pointsOfContactRefStore from './PointsOfContact/pointsOfContactStore';
import settlementRefStore from './SettlementRef/settlementRefStore';
import meterRefStore from './Meter/meterStore';
import scheduleRefStore from './Schedule/scheduleStore';
import reservationRefStore from './Reservation/reservationStore';
import ppaChargeRefStore from './PPAChargeRef/ppaChargeRefStore';

export default {
  namespaced: true,
  modules: {
    chargeCodeRef: chargeCodeRefStore,
    settlementRef: settlementRefStore,
    meterRef: meterRefStore,
    reservationRef: reservationRefStore,
    scheduleRef: scheduleRefStore,
    ppaChargeRef: ppaChargeRefStore,
    bankRef: bankRefStore,
    pointsOfContactRef: pointsOfContactRefStore,
    invoiceEntityRef: invoiceEntityRefStore,
    ppaRef: ppaRefStore,
    customerMasterfileRef: customerMasterfileRefStore,
  },
};