const visualAnalytics: any = {
  module: 'visual_analytics',
  dailyAnalysis: 'visual_analytics:daily_analysis',
  etrmInvoices: 'visual_analytics"etrm_invoices',
  eimTransferMap: 'visual_analytics:eim_transfer_map',
  heatMap: 'visual_analytics:heat_map',
  heatMapDataPoint: 'visual_analytics:heat_map_data_points',
  outages: 'visual_analytics:outages',
  portfolioAnalysis: 'visual_analytics:portfolio_analysis',
  profitLoss: 'visual_analytics:profit_loss',
  settings: 'visual_analytics:visual_analytics_settings',
  pnlPivot: 'visual_analytics:pnl_pivot',
  runEngine: 'visual_analytics:run_engine',
  admin: 'visual_analytics:admin',
  reports: 'visual_analytics:reports',
  multiLocationAnalysis: 'visual_analytics:multi_location_analysis',
  realTimeGraphs: 'visual_analytics:real_time_analysis',
  graphConfiguration: 'visual_analytics:settings:graph_configuration',
  rulesConfig: 'visual_analytics:settings:rules_configuration',
  genCostConfig: 'visual_analytics:settings:gen_cost_configuration',
  va_push: 'visual_analytics:va_push',
  extracts: 'visual_analytics:extracts',
  va_inventory: 'visual_analytics:va_inventory',
};

export default visualAnalytics;