import { ETRM_API } from '@/api';
import { handleError } from '@/utils/dataUtil';

const state = {
  tableConfig: {
    columns: [{
      cellTemplate: 'PscsOpenMasterDetailCellTemplate',
      editorOptions: {
        masterDetailTemplate: 'PscsMasterDetailListTableTemplate',
        multipleSelect: true,
        key: 'ruleMessages',
        config: {
          columns: [{
            prop: 'id', label: 'ID',
          }, {
            prop: 'rule', label: 'Rule',
          }, {
            prop: 'message', label: 'Message',
          }, {
            prop: 'reason', label: 'Reason',
          }, {
            prop: 'statusId', label: 'Status Id', visible: false,
          }, {
            prop: 'status', label: 'Status',
          }, {
            prop: 'createdBy', label: 'Created By',
          }, {
            prop: 'createdDate', label: 'Created Date',
          }, {
            prop: 'updatedBy', label: 'Updated By',
          }, {
            prop: 'updatedDate', label: 'Updated Date',
          }],
        },
      },
      label: 'Rule Messages',
      prop: 'ruleMessages',
      sortable: true,
      calculateCellValue: (val) => val?.ruleMessages?.length,
    }, {
      prop: 'id', label: 'Validation Id', sortable: true,
    }, {
      prop: 'validationType', label: 'Validation Type', sortable: true,
    }, {
      prop: 'validationStatus', label: 'Validation Status', sortable: true,
    }, {
      prop: 'validationReason', label: 'Validation Reason', sortable: true,
    }, {
      prop: 'validationStatusId', label: 'Validation Status Id', sortable: true, visible: false,
    }, {
      prop: 'tradeId',
      label: 'Trade Id',
      sortable: true,
      cellTemplate: 'PscsRouteCellTemplate',
    }, {
      prop: 'tradeName', label: 'Trade Name', sortable: true,
    }, {
      prop: 'tradeStatus', label: 'Status', sortable: true,
    }, {
      prop: 'tradeDate', label: 'Trade Date', sortable: true, dataType: 'date',
    }, {
      prop: 'workflowCorrelationId', label: 'Workflow Correlation Id', sortable: false, visible: false,
    }, {
      prop: 'effectiveStartDate', label: 'Start Date', sortable: true, dataType: 'date',
    }, {
      prop: 'effectiveEndDate', label: 'End Date', sortable: true, dataType: 'date',
    }, {
      prop: 'ticketName', label: 'Ticket Name', sortable: true,
    }, {
      prop: 'externalName', label: 'External Name', sortable: true,
    }, {
      prop: 'commodity', label: 'Commodity', sortable: true,
    }, {
      prop: 'direction', label: 'Direction', sortable: true,
    }, {
      prop: 'companyId', label: 'Company Id', sortable: true, visible: false,
    }, {
      prop: 'company', label: 'Company', sortable: true,
    }, {
      prop: 'counterPartyId', label: 'Counterparty Id', sortable: true, visible: false,
    }, {
      prop: 'counterParty', label: 'Counterparty', sortable: true,
    }, {
      prop: 'counterPartyCreditId', label: 'Counterparty Credit Id', sortable: true, visible: false,
    }, {
      prop: 'counterPartyCredit', label: 'Counterparty Credit', sortable: true,
    }, {
      prop: 'pointOfDeliveryId', label: 'Point of Delivery Id', sortable: true, visible: false,
    }, {
      prop: 'pointOfDelivery', label: 'Point of Delivery', sortable: true,
    }, {
      prop: 'pointOfReceiptId', label: 'Point of Receipt Id', sortable: true, visible: false,
    }, {
      prop: 'pointOfReceipt', label: 'Point of Receipt', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true,
    }],
  },
  tableData: [],
  customRouteCellParam: (trade) => ({
    name: 'Trades',
    query: {
      tradeId: trade.tradeId,
      startDate: trade.effectiveStartDate,
    },
  }),
};

const getters = {
};

const actions = {
  initialize({ dispatch }) {
    dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data } = await ETRM_API.get('validations');
      commit('setTableData', data.data);
    } catch (error) {
      handleError(error, 'Failed to load trade validations.');
    }
  },
};

const mutations = {
  setTableData(state, data) {
    state.tableData = data.sort((x) => x.id).reverse();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};