import moment from 'moment';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import { CISO_SAMC_API, IDENTITY_API, STRUCTURES_API } from '@/api';

const state = {
  data: [],
  config: {
    columns: [{
      label: 'BA',
      prop: 'ba',
      editable: false,
      alignment: 'left',
      width: '70px',
      clearable: false,
      filterable: true,
      sortable: true,
      setCellValue(data, value, row) {
        // necessary for registry to recompute
      },
    }, {
      label: 'RSRC ID',
      prop: 'resourceId',
      editable: false,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
    }, {
      label: 'Start Date',
      prop: 'startDate',
      editable: true,
      alignment: 'left',
      calculateCellValue: (e) => moment(e.startDate).utc().format('YYYY-MM-DD'),
      clearable: false,
      filterable: true,
      sortable: true,
    }, {
      label: 'Start Hour',
      prop: 'startHour',
      editable: true,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
      setCellValue(data, value, row) {
        // necessary for registry to recompute
      },
    }, {
      label: 'Start Interval',
      prop: 'startInterval',
      editable: true,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
      setCellValue(data, value, row) {
        // necessary for registry to recompute
      },
    }, {
      label: 'End Hour',
      prop: 'endHour',
      editable: true,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
      setCellValue(data, value, row) {
        // necessary for registry to recompute
      },
    }, {
      label: 'End Interval',
      prop: 'endInterval',
      editable: true,
      clearable: false,
      alignment: 'left',
      filterable: true,
      sortable: true,
    }, {
      label: 'Flow Quantity',
      prop: 'flowQty',
      editable: true,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
    }, {
      label: 'Comments',
      prop: 'comments',
      editable: true,
      alignment: 'left',
      width: '200px',
      clearable: false,
      filterable: true,
      sortable: true,
    }, {
      label: 'Updated By',
      prop: 'lastUpdatedBy',
      editable: false,
      alignment: 'left',
      clearable: false,
      filterable: true,
      sortable: true,
    }, {
      label: 'Updated Date',
      prop: 'lastUpdated',
      editable: false,
      alignment: 'left',
      clearable: false,
      calculateDisplayValue: (e) => moment(e.lastUpdated).format('YYYY-MM-DD HH:mm:ss'),
      filterable: true,
      sortable: true,
    }],
    options: {
      filterHeader: true,
    },
  },
  selectedDates: null,
  selectedSc: null,
  scs: [],
  companies: [],
};

const actions = {
  async initializeDialog({ dispatch, commit }) {
    await commit('setSelectedDates', dateStore.getDefaultRange());
    await dispatch('fetchScs');
    await dispatch('fetchLocationList');
    await dispatch('fetchOperationalAdjustments');
  },
  setConfigAction({ state, commit }, action) {
    const config = { ...state.config };
    if (action === 'add') {
      config.columns[0].editable = true;
      config.columns[1].editable = true;
      config.columns[2].editable = true;
    } else {
      config.columns[0].editable = false;
      config.columns[1].editable = false;
      config.columns[2].editable = false;
    }
    commit('setConfig', config);
  },
  async fetchLocationList({ commit, state }, params) {
    const { data } = await STRUCTURES_API.get('/locations', params);
    commit('setCompanies', data.data.locations);
  },
  async fetchOperationalAdjustments({ state, commit }) {
    const params = {
      startDate: state.selectedDates[0].toISOString(),
      endDate: state.selectedDates[1].toISOString(),
      scs: state.selectedSc,
    };
    try {
      const { data } = await CISO_SAMC_API.get('/op-adjustments', { params });
      if (data && data.adjustments) {
        commit('setData', data.adjustments);
      }
    } catch (err) {
      console.error(err);
    }
  },
  async importOpAdjustments({ state, commit }, fileName) {
    try {
      await CISO_SAMC_API.post('op-adjustments/import', {
        configurationName: 'op-adjustments-upload-configuration',
        fileName,
      });
      this.$notify({ type: 'success', message: 'Operational Adjustments Import Started' });
    } catch (error) {
      this.$notify({ type: 'error', message: 'Operational Adjustments Import Failed' });
      console.error(error);
    }
  },
  async addOperationalAdjustment({ state }, data) {
    // data.startDate = moment(data.startDate)
    //   .utcOffset(0)
    //   .set({ hour: 0 })
    //   .toISOString();
    try {
      await CISO_SAMC_API.post('/op-adjustments', data);
    } catch (err) {
      this.$notify('Failed to add Operational Adjustment', 'error');
      console.error(err);
    }
  },

  async updateOperationalAdjustment({ state }, { id, updates }) {
    let data = state.data.find((x) => x.id === id);
    data = { ...data, ...updates };
    if (Object.keys(updates).includes('startDate')) {
      data.startDate = moment(data.startDate)
        .utcOffset(0)
        .set({ hour: 0 })
        .toISOString();
    }
    try {
      await CISO_SAMC_API.put(`/op-adjustments/${id}`, data);
    } catch (err) {
      this.$notify('Failed to update Operational Adjustment', 'error');
      console.error(err);
    }
  },
  async deleteOperationalAdjustment({ state }, id) {
    try {
      await CISO_SAMC_API.delete(`/op-adjustments/${id}`);
    } catch (err) {
      this.$notify('Failed to delete Operational Adjustment', 'error');
      console.error(err);
    }
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC').map(({ name }) => name));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
};

const mutations = {
  ...createMutations(
    'data',
    'config',
    'selectedDate',
    'selectedSc',
    'scs',
    'selectedDates',
    'companies',
  ),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};