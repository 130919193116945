import { accessRight, etrm } from '@/auth/permission';
import etrmFeatureNames from '@/features/etrm';

export default {
  path: '/etrm',
  meta: {
    visible: true, requiresAuth: true, text: 'ETRM', permission: { name: etrm.menu, rights: [accessRight.visible] },
  },
  component: () => import('@/components/InnerView'),
  children: [{
    path: 'trades',
    meta: {
      rank: 1,
      visible: true,
      requiresAuth: true,
      text: 'Trades',
      permission: { name: etrm.trades, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'default',
      name: 'DefaultTrades',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Default',
        permission: { name: etrm.trades, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ETRM/TradeManager/Trades/Trades'),
    }, {
      path: 'custom/:pathName',
      name: 'CustomTrades',
      meta: {
        visible: false,
        requiresAuth: true,
        text: 'Trades',
        permission: { name: etrm.trades, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ETRM/TradeManager/Trades/Trades'),
    }],
  }, {
    path: 'trade-validation',
    name: 'TradeValidation',
    meta: {
      rank: 9999,
      visible: true,
      requiresAuth: true,
      text: 'Validations',
      permission: {
        name: etrm.tradeValidations,
        rights: [accessRight.visible],
      },
    },
    component: () => import('@/components/ETRM/TradeValidation/TradeValidation'),
  }, {
    path: 'etrm-payments',
    name: 'Payments',
    meta: {
      showCert: true,
      visible: true,
      text: 'Payments',
      title: 'Payments',
      requiresAuth: true,
      permission: { name: etrm.invoices, rights: [accessRight.visible] },
    },
    component: () => import('@/components/ETRM/Payment/Payments'),
  }, {
    path: 'etrm-imports',
    name: 'ExternalImports',
    meta: {
      visible: true,
      text: 'Imports',
      title: 'External Imports',
      requiresAuth: true,
      featureFlag: etrmFeatureNames.IMPORT_FROM_ACES,
      // permission: { name: etrm.reporting, rights: [accessRight.visible] },
    },
    component: () => import('@/components/ETRM/Imports/ExternalImports'),
  }, {
    path: 'etrm-reports',
    name: 'ETRMReports',
    meta: {
      visible: true,
      text: 'Reports',
      title: 'Reports',
      requiresAuth: true,
      permission: { name: etrm.reporting, rights: [accessRight.visible] },
    },
    component: () => import('@/components/ETRM/Reporting/EtrmReports'),
  }, {
    path: 'settings',
    meta: {
      rank: 10000,
      visible: true,
      requiresAuth: true,
      text: 'Settings',
      permission: { name: etrm.configuration.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'views',
      name: 'trade-views',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Custom Views',
        permission: { name: etrm.configuration.customViews, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ETRM/Settings/ETRMSettings'),
    }, {
      path: 'trade-limits',
      name: 'TradeLimits',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Trade Limits',
        permission: {
          name: etrm.configuration.tradeGroups,
          rights: [accessRight.visible],
        },
      },
      component: () => import('@/components/ETRM/TradingGroup/TradingGroups'),
    }, {
      path: 'trade-term-groups',
      name: 'TradeTermGroups',
      meta: {
        visible: true,
        requiresAuth: false,
        text: 'Trade Term Groups',
        permission: {
          name: etrm.configuration.tradeTermGroups,
          rights: [accessRight.visible],
        },
      },
      component: () => import('@/components/ETRM/Settings/TradeTermGroup/TradeTermGroups'),
    }, {
      path: 'credit-limit',
      name: 'CreditLimits',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Credit Limits',
        permission: {
          name: etrm.configuration.creditLimits,
          rights: [accessRight.visible],
        },
      },
      component: () => import('@/components/ETRM/CreditLimit/CreditLimit'),
    },
    {
      path: 'transaction-log',
      name: 'TransactionLog',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Transaction Logs',
        permission: { name: etrm.credit.transactionLog, rights: [accessRight.visible] },
      },
      component: () => import('@/components/ETRM/TransactionLog/TransactionLogs'),
    },
    ],
  }, {
    path: 'mtm',
    meta: {
      rank: 10000,
      visible: true,
      requiresAuth: true,
      text: 'Mark to Market',
      permission: { name: etrm.mtm.menu, rights: [accessRight.visible] },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'results',
      name: 'mtm-results',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Results',
        permission: {
          name: etrm.mtm.results,
          rights: [accessRight.visible],
        },
      },
      component: () => import('@/components/ETRM/MarkToMarket/MarkToMarketResults'),
    }],
  }, {
    path: 'credit',
    meta: {
      rank: 10000,
      visible: true,
      requiresAuth: true,
      text: 'Credit',
      permission: {
        name: etrm.credit.menu,
        rights: [accessRight.visible],
      },
    },
    component: () => import('@/components/InnerView'),
    children: [{
      path: 'available-credit',
      name: 'AvailableCredit',
      meta: {
        visible: true,
        requiresAuth: true,
        text: 'Available Credit',
        permission: {
          name: etrm.credit.availableCredit,
          rights: [accessRight.visible],
        },
      },
      component: () => import('../../components/ETRM/Credit/AvailableCredit/AvailableCredit'),
    }],
  }],
};