import moment from 'moment';
import { CISO_SAMC_API } from '@/api';
import {
  tradeDateRange, coordinatorList, resourcesTagbox, chargeCodes, disputeDateType,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import config from './disputesConfig';

const state = {
  tableData: [],
  tableConfiguration: config.config,
  detailData: [],
  detailTableConfiguration: config.detailConfig.fields,
  searchParams: {},
  selectedCells: [],
  lastRequest: {},
  currentRow: null,
  searchFiltersConfig: {
    dateType: { ...disputeDateType, required: true },
    tradeDateRange,
    coordinatorList: { ...coordinatorList, required: true },
    resourceList: { ...resourcesTagbox, maxLimit: 6, required: false },
    chargeCodes,
  },
};

const actions = {
  async fetchDisputes({ commit }, request = {}) {
    commit('setTableData', []);
    commit('setLastRequest', request);

    const params = {
      scs: request.coordinatorListSelected,
      startDate: moment(request.tradeDateRangeSelected[0]).toISOString(),
      endDate: moment(request.tradeDateRangeSelected[1]).toISOString(),
      rsrcId: request.resourceListSelected ? request.resourceListSelected.join(',') : null,
      chargeCode: request.chargeCodesSelected,
      dateType: request.dateTypeSelected,
      disputeStatus: request.disputeStatus,
      disputeIds: request.disputeIds,
      isDetailsRequest: request.isDetailsRequest,
    };

    try {
      const { data: { data } } = await CISO_SAMC_API.get('/disputes', { params });
      if (Array.isArray(data)) {
        data.forEach((row) => {
          row.tradingDate = moment.utc(row.tradingDate).format('MM/DD/YYYY');
          row.awardDate = row.awardDate ? moment.utc(row.awardDate).format('MM/DD/YYYY') : null;
          row.publishedDate = moment.utc(row.publishedDate).format('MM/DD/YYYY');
          row.adjustmentPublicationDate = moment.utc(row.adjustmentPublicationDate).format('MM/DD/YYYY');
        });
        commit('setTableData', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch Disputes', 'error');
    }
  },
  async fetchDisputesDetails({ commit }, request = {}) {
    commit('setDetailsTableData', []);
    const params = {
      scs: request.coordinatorListSelected,
      startDate: request.tradeDateRangeSelected[0].toISOString(),
      endDate: request.tradeDateRangeSelected[1].toISOString(),
      rsrcId: request.resourceListSelected ? request.resourceListSelected.join(',') : null,
      chargeCode: request.chargeCodesSelected,
      dateType: request.dateTypeSelected,
      disputeStatus: request.disputeStatus,
      disputeIds: request.disputeIds,
      isDetailsRequest: request.isDetailsRequest,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/disputes', { params });
      if (Array.isArray(data) && data.length > 0 && Array.isArray(data[0].dataModel)) {
        const detailsData = data[0].dataModel;
        detailsData.forEach((detailRow) => {
          detailRow.tradingDate = moment.utc(detailRow.tradingDate).format('MM/DD/YYYY');
        });
        commit('setDetailsTableData', detailsData);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to fetch Disputes Details', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
    commit('setDetailsTableData', []);
    commit('setSelectedCells', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setTableConfiguration(state, value) {
    state.tableConfiguration = value;
  },
  setDetailsTableData(state, value) {
    state.detailData = Object.freeze(value);
  },
  setDetailsTableConfiguration(state, value) {
    state.detailTableConfiguration = value;
  },
  setSearchParams(state, value) {
    state.searchParams = value;
  },
  setSelectedCells(state, value) {
    state.selectedCells = value;
  },
  setSearchFiltersConfig(state, value) {
    state.searchFiltersConfig = value;
  },
  setLastRequest(state, value) {
    state.lastRequest = value;
  },
  setCurrentRow(state, value) {
    state.currentRow = value;
  },
  setHasAttachments(state, value) {
    if (Array.isArray(value)) {
      const rowIndex = state.tableData.findIndex(({ disputeId }) => disputeId === value[0]?.referenceId
          || disputeId === state.currentRow?.disputeId);
      if (rowIndex !== -1) state.tableData[rowIndex].hasAttachments = value.length > 0;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};