import * as tagUtils from '@/utils/etag/tagUtils';
import { MODES, USE_PSCS_AG_GRID } from './constants';
import helpers from '@/utils/helpers';
import { ETAG_API } from '@/api';

const state = {
  la_visibleFlag: false,
  la_row: null,
  la_rowIndex: null,
  la_readonlyFlag: false,
  la_types: [],
  la_data: [],
  la_tableConfiguration: {
    columns: [{
      prop: 'method',
      label: 'type',
      editable: true,
      type: {
        name: USE_PSCS_AG_GRID ? 'PscsSelectCellRenderer' : 'pscs-select-cell',
        placeholder: 'Type',
        list: 'types',
        remote: true,
        clearable: true,
      },
    }, {
      prop: 'start', label: 'start', editable: true,
    }, {
      prop: 'stop', label: 'stop', editable: true,
    }, {
      prop: 'tagCount',
      label: 't',
      width: 40,
      type: {
        name: USE_PSCS_AG_GRID ? 'PscsTagIDListCellLinkRenderer' : 'tag-loss-tag-cell-link',
        overrideReadonly: true,
      },
    }, {
      prop: 'contractCount',
      label: 'c',
      width: 40,
      type: {
        name: USE_PSCS_AG_GRID ? 'PscsTagCreateContractCellLinkRenderer' : 'tag-create-contract-cell-link',
        overrideReadonly: true,
      },
    }],
    columnList(name) {
      if (name === 'types') { return this.lossTypes; }
      return [];
    },
    lossTypes: [{ value: 'INKIND', label: 'INKIND' },
      { value: 'FINANCIAL', label: 'FINANCIAL' },
      { value: 'INTERNAL', label: 'INTERNAL' },
      { value: 'EXTERNAL', label: 'EXTERNAL' }],
  },
};

const getters = {
  getLossAccountingVisibleFlag: (state) => state.la_visibleFlag,
  getLossAccountingTableConfiguration(state, getters) {
    const configuration = helpers.cloneDeep(state.la_tableConfiguration);
    const mode = getters.getMode;
    const compositeState = getters.getCompositeState;
    if (mode === MODES.VIEW_TAG || mode === MODES.VIEW_TEMPLATE) {
      tagUtils.setEditableFalse(configuration, {});
    } else if (mode === MODES.EDIT_TAG) {
      if (compositeState !== 'PENDING') tagUtils.setEditableFalse(configuration);
      else configuration.columns[0].type.clearable = false;
    }
    return configuration;
  },
  getLossAccountingData: (state) => state.la_data,
};

const actions = {
  changeLossAccounting({ commit, state }, item) {
    if (!item.visible && !state.la_readonlyFlag) {
      const { method } = state.la_data[0];
      if (method !== null && method !== '') {
        commit('setTransmissionPhysicalPathLossAccounting', {
          physical_id: state.la_row.physical_id,
          data: state.la_data,
        });
      } else {
        commit('setTransmissionPhysicalPathLossAccounting', {
          physical_id: state.la_row.physical_id,
          data: [],
        });
      }
    }
    commit('setLossAccounting', item);
  },
  changeLossAccountingRow({ commit }, item) {
    commit('setLossAccountingRowData', item);
  },
  lossAccountingRowOperation({ commit }, item) {},
  changeMode(context, value) {
    const mode = value;
    if ([MODES.NEW_TAG, MODES.NEW_TEMPLATE, MODES.VARIANT_TAG, MODES.EDIT_TAG, MODES.EDIT_TEMPLATE].includes(mode)) {
      context.commit('setLossAccountingReadOnlyFlag', false);
    } else {
      context.commit('setLossAccountingReadOnlyFlag', true);
    }
  },
};

const mutations = {
  setLossAccounting(state, item) {
    state.la_visibleFlag = item.visible;
    if (state.la_visibleFlag) {
      state.la_row = item.row;
      state.la_rowIndex = item.rowIndex;
      const count = state.la_row.loss_accountingCount;
      state.la_data = helpers.cloneDeep(state.la_row.loss_accountingList);
      if (!state.la_readonlyFlag && count.toString() === '0') {
        if (!state.la_data) state.la_data = [];
        state.la_data.push({
          type: 'LOSS', method: null, start: null, stop: null, tagCount: 0, tagList: [], contractCount: 0, contractList: [],
        });
      }
    } else {
      state.la_rowIndex = null;
      state.la_row = null;
      state.la_data = [];
    }
  },
  setLossAccountingReadOnlyFlag(state, value) {
    state.la_readonlyFlag = value;
  },
  setLossAccountingRowData(state, item) {
    const row = state.la_data[item.rowIndex];
    row[item.prop] = item.value;
    if (item.prop === 'method' && item.value === null) {
      row.tagCount = 0;
      row.tagList = [];
      row.contractCount = 0;
      row.contractList = [];
    }
  },
  setContractListProperty(state, item) {
    if (item.type === 'LOSS') {
      const row = state.la_data[item.rowIndex];
      row[item.countProp] = item.count;
      row[item.dataProp] = item.data;
    }
  },
  setTagIDListProperty(state, item) {
    const row = state.la_data[item.rowIndex];
    row[item.countProp] = item.count;
    row[item.dataProp] = item.data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};