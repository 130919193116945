import { ETAG_API } from '@/api';
import notify from 'devextreme/ui/notify';

const state = {
  holdName: null,
  holdDescription: null,
  holdDialogVisible: false,
  variantType: null,
  messageType: null,
};

const getters = {
  getName: (state) => state.holdName,
  getDescription: (state) => state.holdDescription,
  getVisibility: (state) => state.holdDialogVisible,
  getVariantType: (state) => state.variantType,
  getHoldsSaveVisibleFlag: (state) => !((state.variantType !== null && state.variantType.toLowerCase() === 'requests')),
};

const actions = {
  changeHoldName({ commit }, item) {
    commit('setHoldName', item);
  },
  changeHoldDescription({ commit }, item) {
    commit('setHoldDescription', item);
  },
  changeHoldVisibility({ dispatch, commit }, item) {
    commit('setHoldVisibility', { dispatch }, item);
  },
  changeVariantType({ commit }, item) {
    commit('setVariantType', item);
  },
  updateHoldTag({ dispatch }, state, tagModel) {
    tagModel.name = state.holdName;
    tagModel.description = state.holdDescription;

    ETAG_API.put(`/tags/variants/${state.variantType}/${state.holdName}`, tagModel).then((response) => {
      notify({ type: 'success', message: 'Tag Updated' });
    }).catch((error) => {
      console.log(error);
      notify({ type: 'error', message: 'Incomplete Update, please check tag contents' });
    });
  },
  deleteHoldTag({ dispatch }, state, router) {
    ETAG_API.delete(`/tags/variants/${state.variantType}/${state.holdName}`).then((response) => {
      notify({ type: 'success', message: 'Tag Deleted' });
      if (state.variantType.toLowerCase() === 'requests') { router.push({ name: 'Etag' }); } else { router.push({ name: 'Holds' }); }
    }).catch((error) => {
      console.log(error);
      notify({ type: 'error', message: 'Incomplete Delete' });
    });
  },
  loadRequestTag({ dispatch, commit }, state, wfId, router) {
    ETAG_API.get(`/workflows/${wfId}`).then((response) => {
      response.data.compositeState = '';
      dispatch('loadTagModel', response.data);
    }).catch((err) => {
      if (err.response.status === 404) {
        notify({ type: 'error', message: 'Not found.' });
      } else {
        console.log(err.response);
        notify({ type: 'error', message: 'Unable to load.' });
      }
      router.push({ name: 'Etag' });
    });
  },
  loadVariantTag({ dispatch, commit }, state, name, router) {
    ETAG_API.get(`/tags/variants/${state.variantType}/${name}`).then((response) => {
      response.data.compositeState = '';
      dispatch('loadTagModel', response.data);
    }).catch((err) => {
      if (err.response.status === 404) {
        notify({ type: 'error', message: 'Not found.' });
      } else {
        console.log(err.response);
        notify({ type: 'error', message: 'Unable to load.' });
      }
      router.push({ name: 'Etag' });
    });
  },
  submitVariant({ dispatch }, model, tagName, state, router) {
    model.name = state.holdName;
    model.description = state.holdDescription;

    ETAG_API.post(`/tags/variants/${state.variantType}/${state.holdName}`, model).then((response) => {
      const result = response.data;
      if (result.state === 'SUCCESS') {
        notify({ type: 'success', message: 'Request New Tag Sent.' });
        router.push({ name: 'Etag' });
      } else if (result.state === 'FAIL') {
        result.errorList.forEach(({ message }) => notify({ type: 'error', message }));
      }
      dispatch('loading', false);
    }).catch((err) => {
      console.log(err);
      dispatch('loading', false);
    });
  },
};

const mutations = {
  setFromTagModel(state, model) {
    state.holdName = model.name;
    state.holdDescription = model.description;
  },
  setHoldName(state, item) {
    state.holdName = item;
  },
  setHoldDescription(state, item) {
    state.holdDescription = item;
  },
  setHoldVisibility(state, item) {
    state.holdName = null;
    state.holdDescription = null;
    state.holdDialogVisible = !state.holdDialogVisible;
  },
  setVariantType(state, item) {
    state.variantType = item;
  },
  setVariantMessageType(state, item) {
    state.messageType = item;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};