import { createMutations } from '@/utils/vuexHelper';
import moment from 'moment';
import { getMomentDateString } from '@/utils/dateUtil';
import { ETRM_API } from '@/api';
import dateStore from '@/utils/dateStore';
import LOOKUP_STORE from '@/store/lookupStore';
import { clone, handleError } from '@/utils/dataUtil';
import { defaultTable1Config, defaultTable2Config } from './tableConfig';

const state = {
  dateRange: dateStore.getDefaultRange(),
  searchDateRange: dateStore.getDefaultRange(),
  invoiceDate: dateStore.getDefaultDate(),
  selectedDate: dateStore.getDefaultDate().toISOString(),
  selectedCreateCompany: undefined,
  selectedSearchCompanies: undefined,
  selectedCreateCompanies: undefined,
  currentRow: undefined,
  table1Config: defaultTable1Config,
  table2Config: defaultTable2Config,
  table1Data: [],
  table2Data: [],
};

const getters = {
  companies: () => LOOKUP_STORE.state.companyList.filter((x) => x.internalFlag).map((x) => x.shortName),
  counterParties: () => LOOKUP_STORE.state.companyList.map((x) => x.shortName),
  selectedDueDate: (state) => moment(state.selectedDate).add(1, 'M').toISOString(),
};

const actions = {
  initialize({ dispatch, state, commit }) {
    dispatch('LOOKUP_STORE/fetchCompanyList');
  },

  async getInvoices({ state, commit, getters }) {
    commit('setInvoiceLineData', []);

    const params = {
      startTime: state.searchDateRange[0].toISOString(),
      endTime: state.searchDateRange[1].toISOString(),
      counterParty: !state.selectedSearchCompanies
        ? null : state.selectedSearchCompanies.join(','),
    };
    try {
      const { data } = await ETRM_API.get('/invoices', { params });
      if (Array.isArray(data.data)) {
        data.data.forEach((x) => {
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = getMomentDateString(x.endDate);
          x.createdDate = dateStore.toLocalFromDate(x.createdDate).format('YYYY-MM-DD hh:mm:ss A');
          x.invoiceLineItems.forEach((y) => {
            y.startTime = getMomentDateString(y.startTime);
            y.endTime = getMomentDateString(y.endTime);
            y.createdDate = dateStore.toLocalFromDate(y.createdDate).format('YYYY-MM-DD hh:mm:ss A');
          });
        });
      }
      commit('setInvoiceData', data.data);
      this.$notify('Payments successfully loaded', 'success');
    } catch (error) {
      handleError(error, 'Failed to get payments');
    }
  },
  async postInvoices({ state, commit, getters }) {
    if (!state.selectedCreateCompanies || state.selectedCreateCompanies.length === 0) {
      const allCounterParties = getters.counterParties;
      commit('updateSelectedCreateCompanies', allCounterParties);
    }
    const params = {
      startTime: state.dateRange[0].toISOString(),
      endTime: state.dateRange[1].toISOString(),
      companyName: state.selectedCreateCompany,
      counterParty: !state.selectedCreateCompanies
        ? null : state.selectedCreateCompanies,
      invoiceDate: state.invoiceDate.toISOString(),
      invoiceDueDate: getters.selectedDueDate,
    };
    try {
      const { data } = await ETRM_API.post('/invoices', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = getMomentDateString(x.endDate);
          x.createdDate = dateStore.toLocalFromDate(x.createdDate).format('YYYY-MM-DD hh:mm:ss A');
          x.invoiceLineItems.forEach((y) => {
            y.startTime = getMomentDateString(y.startTime);
            y.endTime = getMomentDateString(y.endTime);
            y.createdDate = dateStore.toLocalFromDate(y.createdDate).format('YYYY-MM-DD hh:mm:ss A');
          });
        });
      }
      commit('setInvoiceData', data);
      commit('setInvoiceLineData', data.invoiceLineItems);
      this.$notify('Payments successfully posted', 'success');
    } catch (error) {
      handleError(error, 'Failed to post payments');
    }
  },
  async addPayment({ state, commit }, payment) {
    if (!state.currentRow) {
      this.$notify('Please select a row first', 'warning');
      return;
    }
    try {
      const { data } = await ETRM_API.post(`/invoices/${state.currentRow.id}/payments`, {
        paymentAmount: payment,
      });
      if (data?.messages) {
        this.$notify(data.messages, 'warning');
        return;
      }
      if (Array.isArray(data.data)) {
        data.data.forEach((x) => {
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.startDate = getMomentDateString(x.startDate);
          x.endDate = getMomentDateString(x.endDate);
          x.createdDate = moment(x.createdDate).utc().format('YYYY-MM-DD hh:mm:ss A');
        });
      }
      commit('updateRowPaid', data);
      commit('setInvoiceData', state.table1Data);
      this.$notify('Payment successfully posted', 'success');
    } catch (error) {
      handleError(error, 'Failed to post invoice payment');
    }
  },
  async deleteInvoice({ state, commit }) {
    if (!state.currentRow) {
      this.$notify('Please select a row first', 'warning');
      return;
    }
    try {
      await ETRM_API.delete(`/invoices/${state.currentRow.id}`);
      const data = clone(state.table1Data);
      const rowToDelete = data.findIndex(({ id }) => id === state.currentRow.id);
      data.splice(rowToDelete, 1);
      commit('setInvoiceData', data);
      commit('setInvoiceLineData', null);
      this.$notify('Payment successfully deleted', 'success');
    } catch (error) {
      handleError(error, 'Failed to delete payment');
    }
  },
  getLineItems({ commit }, row) {
    commit('updateSelectedRow', row);
    commit('setInvoiceLineData', row.invoiceLineItems);
  },
};

const mutations = {
  setInvoiceData(state, value) {
    state.table1Data = value;
  },
  setInvoiceLineData(state, value) {
    state.table2Data = value;
  },
  updateSelectedSearchCompanies(state, value) {
    state.selectedSearchCompanies = value;
  },
  updateSelectedCreateCompanies(state, value) {
    state.selectedCreateCompanies = value;
  },
  updateSelectedCreateCompany(state, value) {
    state.selectedCreateCompany = value;
  },
  updateSelectedRow(state, value) {
    state.currentRow = value;
  },
  updateRowPaid(state, value) {
    const rowToUpdate = state.table1Data.findIndex(({ id }) => id === value.data[0].id);
    state.table1Data.splice(rowToUpdate, 1, value.data[0]);
  },
  ...createMutations(
    'dateRange',
    'searchDateRange',
    'selectedDate',
    'selectedSearchCompanies',
    'selectedCreateCompanies',
    'selectedCreateCompany',
  ),
};

export default {
  namespaced: true,
  modules: { LOOKUP_STORE },
  state,
  actions,
  getters,
  mutations,
};