import { NotificationItem } from '../notificationItem';
import DefaultMapper from './defaultMapper'
import ETagMapper from './etagMapper';

class Mapper {
  
  constructor() {
    /**
     * @type {Array<DefaultMapper>}
     */    
    this.items = [      
      new ETagMapper(),
      new DefaultMapper('default'), // if no other mapper is found, use the default one. Leave this one at the end
    ]
  }

  /**
   * 
   * @param {Object} ablyMessage 
   * @return {NotificationItem}
   */
  createNotificationItem(ablyMessage) {
    const item = ablyMessage.data;
    const mapper = this.getMapper(item.source);

    if (mapper == null) return null;
    
    return mapper.createNotificationItem(item);
  }

  getMapper(source) {
    for (let x = 0; x < this.items.length; x++){
      if (this.items[x].isSourceMapper(source))
        return this.items[x];
    }

    return null;
  }
}

const mappers = new Mapper();

export default mappers;