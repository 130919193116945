import brokerRefStore from './Brokers/brokerStore';
import collectionRefStore from './Collections/collectionStore';
import companyRefStore from './Companies/companyStore';
import companyContactRefStore from './Contacts/companyContactsStore';
import locationRefStore from './Locations/locationStore';
import locationGroupRefStore from './LocationGroups/locationGroupStore';
import termTypeRefStore from './TermTypes/termTypeStore';
import locationTypeRefStore from './LocationTypes/locationTypeStore';
import marketSelfScheduleTypeRefStore from './MarketSelfScheduleTypes/marketSelfScheduleTypeStore';
import marketProductTypeRefStore from './MarketProductTypes/marketProductTypeStore';
import energyMarketParticipantRefStore from './EnergyMarketParticipants/energyMarketParticipantStore';
import marketRecordTypeRefStore from './MarketRecordTypes/marketRecordTypeStore';
import marketTradeTypeRefStore from './MarketTradeTypes/marketTradeTypeStore';
import marketTradeProductRefStore from './MarketTradeProducts/marketTradeProductStore';
import contractTypeRefStore from './ContractTypes/contractTypeStore';
import contractStateTypeRefStore from './ContractStateTypes/contractStateTypeStore';
import commodityTypeStore from './CommodityTypes/commodityTypeStore';
import eventTypeRefStore from './EventTypes/eventTypeStore';
import tradeTypeRefStore from './TradeTypes/tradeTypeStore';
import marketRefStore from './Markets/marketStore';
import marketSystemRefStore from './MarketSystems/marketSystemStore';
import marketAccountRefStore from './MarketAccounts/marketAccountStore';
import deliverableTypeRefStore from './DeliverableType/deliverableTypeStore';
import renewableSubAccountRefStore from './EnvironmentalSubAccounts/renewableSubAccountStore';
import ghgSubAccountRefStore from './EnvironmentalSubAccounts/ghgSubAccountStore';
import occurenceLogStatusTypeRefStore from './DeliverableOccurrenceLogStatusTypes/occurenceLogStatusTypeStore';
import contractCoordinatorRefStore from './ContractCoordinators/contractCoordinatorStore';
import variantRefStore from './Variants/variantsStore';
import resourcesStore from './Resources/resourcesStore';
import resourceGroupsStore from './ResourceGroups/resourceGroupsStore';
import resourceHeatRatesStore from './ResourceHeatRates/resourceHeatRatesStore';
import priceStore from './Prices/pricesStore';
import catalogStore from './Catalogs/catalogStore';
import isoReferenceStore from './ISOReference/ISOReferenceStore';

export default {
  namespaced: true,
  modules: {
    brokerRef: brokerRefStore,
    collectionRef: collectionRefStore,
    companyRef: companyRefStore,
    companyContactsRef: companyContactRefStore,
    locationRef: locationRefStore,
    locationGroupRef: locationGroupRefStore,
    termTypeRef: termTypeRefStore,
    locationTypeRef: locationTypeRefStore,
    marketSelfScheduleTypeRef: marketSelfScheduleTypeRefStore,
    marketProductTypeRef: marketProductTypeRefStore,
    energyMarketParticipantRef: energyMarketParticipantRefStore,
    marketRef: marketRefStore,
    marketSystemRef: marketSystemRefStore,
    marketAccountRef: marketAccountRefStore,
    marketRecordTypeRef: marketRecordTypeRefStore,
    marketTradeTypeRef: marketTradeTypeRefStore,
    marketTradeProductRef: marketTradeProductRefStore,
    contractTypeRef: contractTypeRefStore,
    contractStateTypeRef: contractStateTypeRefStore,
    deliverableTypeRef: deliverableTypeRefStore,
    eventTypeRef: eventTypeRefStore,
    tradeTypeRef: tradeTypeRefStore,
    commodityTypeRef: commodityTypeStore,
    renewableSubAccountRef: renewableSubAccountRefStore,
    ghgSubAccountRef: ghgSubAccountRefStore,
    occurrenceLogStatusTypeRef: occurenceLogStatusTypeRefStore,
    contractCoordinatorRef: contractCoordinatorRefStore,
    variantRef: variantRefStore,
    resources: resourcesStore,
    resourceGroups: resourceGroupsStore,
    resourceHeatRates: resourceHeatRatesStore,
    priceRef: priceStore,
    catalogRef: catalogStore,
    isoReference: isoReferenceStore,
  },
};