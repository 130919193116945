import Vue from 'vue';
import moment from 'moment';
import { VA_API } from '@/api';
import dateStore from '@/utils/dateStore';
import { createMutations } from '@/utils/vuexHelper';
import { cloneDeep } from '@/utils/dataUtil';
import VABaseStore from '../VABaseStore';
import caisoStore from '@/utils/caiso/caisoUtils';

const state = {
  dateRange: dateStore.getDefaultRange() || [],
  constantLines: [],
  selectedGroup: null,
  selectedLocation: null,
  aggregateChartData: [],
  chartData: [],
  selectedLocations: [],
  locationsConfig: {
    columns: [{
      label: 'MARKET',
      prop: 'marketName',
      sortable: true,
      visible: false,
    }, {
      label: 'LOCATION',
      prop: 'shortName',
      sortable: true,
      fixed: 'left',
    }, {
      label: 'COMMODITY',
      prop: 'commodityName',
      sortable: true,
      fixed: 'left',
    }],
    options: {
      exportExcel: true,
    },
  },
  filteredLocations: [],
  chartName: '',
};

const getters = {
  argsArray: (state) => state.chartData.length ? state.chartData.map(({ time }) => time) : [],
  isRealTime: (state) => moment().isSame(state.dateRange[0], 'day') && moment().isSame(state.dateRange[1], 'day'),
  portfolioChartSeries(state) {
    const { selectedLayout } = state.VABaseStore;
    if (selectedLayout && Array.isArray(selectedLayout.charts)) {
      const chartSeriesList = selectedLayout.charts.reduce((acc, { name, chartSeries }) => {
        const seriesMeta = chartSeries.map((meta) => ({
          pane: name,
          selected: true,
          text: meta.chartSeriesName,
          color: meta.fillColor,
          type: meta.markerType,
          format: meta.format,
          seriesId: meta.seriesId,
          originalSeriesName: meta.seriesName,
          seriesDescription: meta.seriesDescription,
          chartSeriesId: meta.chartSeriesId,
          chartSeriesName: meta.chartSeriesName,
        }));
        return [...acc, ...seriesMeta];
      }, []).filter(({ text }) => !(text.includes('rl_') || text.toLowerCase().includes('rule'))); // TODO: do this better
      return chartSeriesList;
    }
    return [];
  },
  heArray: (state) => state.chartData.length ? state.chartData.map(({ HE }) => HE) : [],
};

const getChartSeriesMeta = (layout) => layout.reduce((acc, { chartSeries }) => {
  chartSeries.forEach((cs) => {
    if (acc[cs.seriesName]) {
      acc[`${cs.seriesName}-${cs.seriesId}`] = cs;
    } else {
      acc[cs.seriesName] = cs;
    }
  });
  return acc;
}, {});

const getNullBase = (layout) => layout.reduce((acc, { chartSeries }) => {
  chartSeries.forEach(({ chartSeriesName }) => {
    acc[chartSeriesName] = null;
  });
  return acc;
}, {});

const actions = {
  async initialize({ commit, dispatch }) {
    commit('reset');
    dispatch('fetchLayouts');
    dispatch('fetchLocations');
    await dispatch('fetchLocationGroups');
    commit('setSelectedGroup', caisoStore.resourceGroupName);
  },
  async fetchPortfolioData({ commit, state, getters }) {
    if ((state.selectedGroup || Number.isInteger(state.VABaseStore.selectedLayoutId))
      && state.dateRange.length !== 0) {
      commit('reset');
      const layout = state.VABaseStore.selectedLayout;
      const locationGroup = state.VABaseStore.groups.find(({ shortName }) => shortName === state.selectedGroup).id;
      const tz = dateStore.getTimeZone();
      let start;
      let end;

      if (state.dateRange.length !== 0) {
        const momentRange = dateStore.toMomentAndZoneFromJSDateArray(cloneDeep(state.dateRange), tz);
        start = momentRange[0].startOf('day');
        end = momentRange[1].startOf('day');
        state.dateRange = momentRange;
      }

      const params = {
        realTimeFlag: getters.isRealTime,
        startTime: start.toISOString(),
        endTime: end.clone().add(1, 'days').toISOString(),
        aggregate: true,
        variants: getters.selectedVariant,
        layout: layout.id,
        locationGroup,
        types: state.selectedLocations.join(','),
      };
      if (params.locationGroup === 'ALL') params.locationGroup = null;
      try {
        const { data: { data } } = await VA_API.get('portfolio', { params });

        if (data?.length) {
          const payload = { layout, data };
          commit('setChartData', payload);
        }
      } catch (error) {
        if (error?.response?.data?.messages) {
          const message = error.response.data.messages[0];
          this.$notify(message, 'error');
        } else {
          this.$notify('Error loading data', 'error');
        }
        console.error(error);
      }
    }
  },
  async fetchLocationGroupData({ commit, state, getters }, dateTime) {
    const locationGroup = state.VABaseStore.groups.find(({ shortName }) => shortName === state.selectedGroup).id;

    const intervalDay = dateStore.toMoment(dateTime).startOf('day');
    const params = {
      aggregate: false,
      startTime: intervalDay.toISOString(),
      variants: getters.selectedVariant,
      endTime: intervalDay.add(1, 'day').toISOString(),
      flatten: false,
      layout: state.VABaseStore.selectedLayout.id,
      locationGroup,
      types: state.selectedLocations.join(','),
    };

    try {
      const response = await VA_API.get('portfolio', { params });
      const { data } = response.data;

      const chartSeriesMapped = getters.portfolioChartSeries.reduce((acc, cs) => {
        if (acc[cs.originalSeriesName]) {
          acc[`${cs.originalSeriesName}-${cs.chartSeriesId}`] = cs;
        } else {
          acc[cs.originalSeriesName] = cs;
        }
        return acc;
      }, {});

      const intervalTime = {
        start: dateStore.toMoment(dateTime).toISOString(),
        end: dateStore.toMoment(dateTime).add(5, 'minutes').toISOString(),
      };
      commit('setLocationData', { chartSeriesMapped, data, intervalTime });
    } catch (error) {
      if (error?.response?.data?.messages) {
        const message = error.response.data.messages[0];
        this.$notify(message, 'error');
      } else {
        this.$notify('Error loading data', 'error');
      }
      console.error(error);
    }
  },
  async fetchLocationData({ commit, state, getters }, location) {
    if (location === state.selectedLocation) return;
    if (location === '_ALL' && state.aggregateChartData.length) {
      if (state.selectedGroup != 'ALL') {
        commit('setChartName', state.selectedGroup);
        commit('setAggregateChartData');
      } else {
        commit('setChartName', state.selectedLocations.join(', '));
        commit('setAggregateChartData');
      }
    } else if (location !== '_ALL') {
      const layout = getters.selectedLayout;
      // Extracts series id from layout
      const seriesIds = layout.charts.map(({ chartSeries }) => chartSeries).flat().map(({ seriesId }) => seriesId);
      const params = {
        realTimeFlag: getters.isRealTime,
        startTime: moment(state.dateRange[0]).toISOString(),
        endTime: moment(state.dateRange[1]).add(1, 'day').toISOString(),
        variants: getters.selectedVariant,
        objectReferences: seriesIds.map((seriesId) => seriesId).join(','),
        objectTypes: 'series',
        types: location,
      };
      const { data: { data } } = await VA_API.get('intervals', { params });
      const payload = { layout, data };
      commit('setChartName', location);
      commit('setLocationChartData', payload);
      commit('setSelectedLocation', location);
    }
  },
  changeSelectedVariant({ commit, state, getters }, selectedVariant) {
    VABaseStore.mutations.setSelectedVariant(VABaseStore.state, selectedVariant);
  },
  async changePortfolioSelectedLayout({ commit, dispatch, getters }, layout) {
    await dispatch('changeSelectedLayout', layout);
    commit('setChartData', { layout: getters.selectedLayout, data: [] });
  },
  changeChartName({ commit, state }) {
    let chartName;
    if (state.selectedLocation) {
      chartName = state.selectedLocation;
    } else if (state.selectedGroup === 'ALL' && state.selectedLocations.length) {
      chartName = state.selectedLocations.join(', ');
    } else {
      chartName = state.selectedGroup;
    }
    commit('setChartName', chartName);
  },
};

const mutations = {
  reset(state) {
    state.chartData = [];
    state.constantLines = [];
    state.filteredLocations = [];
    state.aggregateChartData = [];
    state.selectedLocation = null;
  },
  setChartData(state, { layout, data }) {
    state.chartData = [];
    state.aggregateChartData = [];
    if (layout) {
      const chartSeriesMeta = getChartSeriesMeta(layout.charts);

      const chartSeriesBase = getNullBase(layout.charts);

      const dailyAggSeries = {};
      Object.values(chartSeriesMeta)
        .filter(({ seriesName }) => seriesName.indexOf('_DAILYAGG') >= 0)
        .forEach(({ seriesName, chartSeriesName }) => {
          const seriesNameWithoutDAILYAGG = seriesName.substring(0, seriesName.indexOf('_DAILYAGG'));
          dailyAggSeries[seriesNameWithoutDAILYAGG] = { chartSeriesName };
        });

      data.forEach((d) => {
        const startMom = dateStore.toMoment(d.startTime);
        const interval = {
          location: d.type,
          startTime: startMom.toISOString(),
          HE: startMom.format('HH:mm'),
          time: startMom.toISOString(),
          ...chartSeriesBase,
        };
        Object.entries(d).forEach(([key, val]) => {
          if (typeof val === 'object' && chartSeriesMeta[key]) {
            const currentSeriesArray = Object.keys(chartSeriesMeta).filter((series) => series.split('-')[0] === key);
            currentSeriesArray.forEach((series) => {
              const { chartSeriesName, multiplier } = chartSeriesMeta[series];
              const value = multiplier !== 1 ? multiplier * val.value : val.value;
              interval[chartSeriesName] = value;

              // if there is an daily aggregate data point start doing daily aggregations here
              if (dailyAggSeries[key]) {
                const startOfDay = dateStore.toMoment(d.startTime).startOf('day').toISOString();
                dailyAggSeries[key][startOfDay] = dailyAggSeries[key][startOfDay] !== undefined
                  ? dailyAggSeries[key][startOfDay] + value
                  : value;
              }
            });
          }
        });
        state.chartData.push(interval);
      });

      state.chartData.forEach((interval) => {
        const startOfDay = dateStore.toMoment(interval.startTime).startOf('day').toISOString();
        Object.values(dailyAggSeries).forEach((dailySeries) => {
          interval[dailySeries.chartSeriesName] = dailySeries[startOfDay];
        });
      });
      state.aggregateChartData = state.chartData;
    }
  },
  setLocationChartData(state, { layout, data }) {
    state.chartData = [];
    const chartSeriesMeta = getChartSeriesMeta(layout.charts);
    // Nulls out data points to prevent chart error
    const chartSeriesBase = getNullBase(layout.charts);
    data.forEach((d) => {
      const startMom = dateStore.toMoment(d.startTime);
      const interval = {
        location: d.type,
        startTime: startMom.toISOString(),
        HE: startMom.format('HH:mm'),
        time: startMom.toISOString(),
        ...chartSeriesBase,
      };
      Object.entries(d).forEach(([key, val]) => {
        if (typeof val === 'object' && chartSeriesMeta[key]) {
          const currentSeriesArray = Object.keys(chartSeriesMeta).filter((series) => series.split('-')[0] === key);
          currentSeriesArray.forEach((series) => {
            const { chartSeriesName, multiplier } = chartSeriesMeta[series];
            interval[chartSeriesName] = (multiplier !== null && multiplier !== 0) ? multiplier * val.value : val.value;
            // Add notes
            if (val.notes) interval[`${chartSeriesName}/notes`] = val.notes;
          });
        }
      });
      state.chartData.push(interval);
    });
  },
  setAggregateChartData(state) {
    state.chartData = state.aggregateChartData;
    state.selectedLocation = null;
  },
  setLocationData(state, { data, chartSeriesMapped, intervalTime }) {
    // Keeps _ALL row at the top of locations table
    const allRow = state.filteredLocations[0];
    if (state.selectedGroup === 'ALL') {
      state.filteredLocations = [
        allRow,
        ...state.VABaseStore.locations.filter(({ shortName }) => state.selectedLocations.includes(shortName)),
      ];
    } else {
      state.filteredLocations = [
        allRow,
        ...state.VABaseStore.locations.filter(({ shortName }) => data.map(({ type }) => type).includes(shortName)),
      ];
    }
    // contains the data from all the other locations
    // key is series name, value is the aggregated data
    const allLocationAggregatedData = {};

    state.filteredLocations.forEach((location) => {
      const locationName = location.shortName;
      if (locationName !== '_ALL') {
        let seriesValue;

        // find the intervals for the given location
        const locationIntervals = data.filter((interval) => interval.type === locationName);
        // find the interval data for the specified interval
        const intervalData = locationIntervals.find(
          (interval) => dateStore.toMoment(interval.startTime).isSame(intervalTime.start)
                      && dateStore.toMoment(interval.endTime).isSame(intervalTime.end));
        Object.entries(chartSeriesMapped).forEach(([seriesName, chartSeries]) => {
          const { chartSeriesName, multiplier } = chartSeries;
          // try to find value in intervalData
          seriesValue = intervalData?.[seriesName]?.value;
          if (seriesValue === undefined
            // there is no datapoint with the given seriesName
            // we check if it is an daily aggregate datapoint
            && seriesName.search(VABaseStore.DAILY_AGGREGATION_SUFFIX)
            === (seriesName.length - VABaseStore.DAILY_AGGREGATION_SUFFIX.length)) {
            // initialize value
            seriesValue = 0;
            // find series that is supposed to be aggregated
            seriesName = seriesName.substring(0, seriesName.length - VABaseStore.DAILY_AGGREGATION_SUFFIX.length);
            // aggregate all the values for a given location and series name
            locationIntervals.forEach((dataSeriesData) => {
              seriesValue += dataSeriesData?.[seriesName]?.value ?? 0;
            });
          }
          // apply multiplier if applicable
          if (multiplier > 0) { seriesValue *= multiplier; }
          // set chart data
          Vue.set(location, chartSeriesName, seriesValue?.toFixed(2));

          if (seriesValue) {
            allLocationAggregatedData[chartSeriesName] = allLocationAggregatedData[chartSeriesName] === undefined
              ? seriesValue
              : allLocationAggregatedData[chartSeriesName] + seriesValue;
          }
        });
      }
    });
    Object.entries(allLocationAggregatedData).forEach(([chartSeriesName, value]) => {
      allRow[chartSeriesName] = value.toFixed(2);
    });
  },
  updateLocationsConfig(state, chartSeries) {
    // Hides all of the newly generated columns
    state.locationsConfig.columns.forEach((col, idx) => {
      if (idx > 2) Vue.set(col, 'visible', false);
    });
    chartSeries.forEach((label) => {
      const column = state.locationsConfig.columns.find((col) => col.label === label);
      if (column) {
        column.visible = true;
      } else {
        state.locationsConfig.columns.push({
          label,
          prop: label,
          sortable: true,
        });
      }
    });

    const allRow = state.locationsConfig.columns.reduce((acc, col) => {
      acc[col.prop] = '_ALL';
      return acc;
    }, {});
    state.filteredLocations.unshift(allRow);
  },
  ...createMutations(
    'dateRange',
    'selectedLocation',
    'constantLines',
    'selectedGroup',
    'selectedLocations',
    'selectedLayoutId',
    'chartName',
  ),
};

export default {
  namespaced: true,
  modules: { VABaseStore },
  state,
  getters,
  actions,
  mutations,
};