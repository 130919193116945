import Vue from 'vue';

const state = {
  params: {},
};

const getters = {
  getParams: (state) => state.params,
};

const actions = {
  selectedValueChanged({ commit }, eventArgs) {
    commit('setParams', [`${eventArgs.prop}Selected`, eventArgs.$event]);
  },
};

const mutations = {
  setParams(state, [prop, value]) {
    if (!state.params[prop] && state.params[prop] !== null) {
      Vue.set(state.params, prop, value);
    } else {
      state.params[prop] = value;
    }
  },
};

export default {
  getters,
  state,
  actions,
  mutations,
};