const requireRoutes = require.context(
  // search for files in current directory
  '.',
  // search for files in sub directories
  false,
  // include any .js files that are not unit tests
  /^((?!\.unit\.).)*\.js/,
);

const routes = requireRoutes
  .keys()
  .filter((fileName) => fileName !== './index.js')
  .reduce((acc, route) => {
    const r = requireRoutes(route);
    const item = r.default || r;
    if (Array.isArray(item)) return [...acc, ...item];
    return [...acc, item];
  }, []);

export default routes;
