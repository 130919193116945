import { CISO_SAMC_API } from '@/api';
import moment from 'moment';
import {
  tradeDateRange,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import config from './settlementReviewCalendarConfigurations';

const state = {
  tableData: [],
  tableConfiguration: config,
  searchParams: {},
  selectedCells: [],
  searchFiltersConfig: {
    tradeDateRange,
    dateType: {
      label: 'Date Type',
      value: 'PublishedDate',
      type: 'radio',
      options: [{ value: 'PublishedDate', label: 'Publish Date' }, { value: 'TradingDate', label: 'Trade Date' }],
    },
  },
};

const actions = {
  async fetchSettlementReviewCalendar({ commit, dispatch }, parameters = {}) {
    const params = {
      startDate: parameters.tradeDateRangeSelected[0].toISOString(),
      endDate: parameters.tradeDateRangeSelected[1].toISOString(),
      dateType: parameters.dateTypeSelected,
    };

    try {
      const { data } = await CISO_SAMC_API.get('/settlementapproval/calendar', { params });
      if (Array.isArray(data)) {
        data.forEach((row) => {
          row.publishDate = moment.utc(row.publishDate).format('yyyy-MM-DD');
          row.flowDate = moment.utc(row.flowDate).format('yyyy-MM-DD');
          row.dueDate = moment.utc(row.dueDate).format('yyyy-MM-DD');
        });
        commit('setTableData', data);
      }
    } catch (error) {
      console.error(error);
      this.$notify('Failed to Load Settlement Review Dashboard', 'error');
      dispatch('reset');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};