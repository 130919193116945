import { tradeDateRange, coordinatorList, dateType } from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'LEFT',
  },
  fields: [{
    caption: 'ALIAS DESCRIPTION', dataType: 'string', dataField: 'aliasDescription', area: 'filter', expanded: true,
  }, {
    caption: 'GROUP', dataType: 'string', dataField: 'group', area: 'filter', expanded: true,
  }, {
    caption: 'ACCOUNT', dataType: 'string', dataField: 'account', area: 'filter', expanded: true,
  }, {
    caption: 'TYPE', dataType: 'string', dataField: 'type', area: 'filter', expanded: true,
  }, {
    caption: 'BA', dataType: 'string', dataField: 'ba', area: 'row', expanded: true,
  }, {
    caption: 'YEAR', dataType: 'string', dataField: 'year', area: 'row', expanded: true,
  }, {
    caption: 'BILLING MONTH', dataType: 'string', dataField: 'billingMonth', area: 'row', expanded: true,
  }, {
    caption: 'DAY', dataType: 'number', dataField: 'day', area: 'row', expanded: true,
  }, {
    caption: 'DATE PUBLISHED', dataType: 'datetime', dataField: 'publishedDate', area: 'row', expanded: true,
  }, {
    caption: 'INVOICE NUM', dataType: 'number', dataField: 'invoiceNum', area: 'row', expanded: true,
  }, {
    caption: 'BILL PERIOD', dataType: 'string', dataField: 'billPeriod', area: 'row', expanded: true,
  }, {
    caption: 'ALIAS NAME', dataType: 'string', dataField: 'aliasName', area: 'row', expanded: true,
  }, {
    caption: 'PUBLICATION',
    dataType: 'string',
    dataField: 'publication',
    area: 'column',
    expanded: true,
    customizeText(cellInfo) {
      if (cellInfo && cellInfo.value && cellInfo.value.indexOf('.') !== -1) return cellInfo.value.substr(cellInfo.value.indexOf('.') + 1);
      return cellInfo.value;
    },
  }, {
    caption: 'AMOUNT', dataType: 'number', dataField: 'netAmount', area: 'data', expanded: true, summaryType: 'sum', format: { type: 'currency', precision: 2 },
  }, {
    caption: 'CC', dataType: 'string', dataField: 'cc', area: 'filter', expanded: true,
  }],
  searchConfig: {
    tradeDateRange,
    coordinatorList: Object.assign(coordinatorList, { divider: true, clearable: true }),
    dateType,
    aliasName: {
      label: 'Alias Name',
      value: 'ALL',
      type: 'select',
      options: ['ALL', 'BILL_PERIOD', 'PARENT_CHARGE_GROUP', 'CHARGE_GROUP', 'CHARGE_CODE', 'PTB_ITEM'].map((value) => ({ label: value, value })),
    },
  },
};