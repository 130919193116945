import moment from 'moment';
import Vue from 'vue';
import { clone, cloneDeep, has } from '@/utils/dataUtil';
import { CISO_SAMC_API } from '@/api';
import config from './gridConfig';
import { upsertFieldConfig } from '../samcUtil';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  currentTableConfig: null,
  selectedCells: [],
  compareFlag: false,
  showRowTotals: false,
};

const actions = {
  async fetchAttributes({ commit }, params) {
    const { data } = await CISO_SAMC_API.get('/statements/attributes', {
      params: {
        chargeCode: params.chargeCode,
        tradingDate: params.tradingDate,
      },
    });

    const defaultFields = cloneDeep(state.currentTableConfig ?? state.tableConfig ?? config.configData.fields);
    const hasCompareId = has(params, 'compareId');
    if (hasCompareId) commit('setShowRowTotals', true);
    if (!hasCompareId || (hasCompareId && params.compareId?.toString() === '0')) {
      commit('setCompareFlag', false);
      for (let x = 0; x < defaultFields.length; x++) {
        if (defaultFields[x].caption === 'COMPARE') {
          defaultFields[x].visible = false;
          break;
        }
      }
    } else {
      commit('setCompareFlag', true);
    }

    if (data && data.data) {
      commit('setTableConfig', upsertFieldConfig(defaultFields, data.data));
    }
  },
  async fetchDeterminantSummary({ commit }, params) {
    params = {
      chargeCode: params.chargeCode, fileId: params.fileId, compareId: params.compareId, tradingDate: params.tradingDate,
    };
    try {
      const { data } = await CISO_SAMC_API.get('/determinant-summary', { params });

      if (data && data.data) {
        data.data.forEach((element) => {
          element.tradingDate = moment.utc(element.tradingDate).format('MM/DD/YYYY');
          element.tzTradingDate = moment.utc(element.tradingDate).format('MM/DD/YYYY');
        });

        commit('setTableData', data.data);
      }
    } catch (error) {
      this.$notify('Failed to load Determinant Summary', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    if (value && value.length > 0)
      state.tableConfig = value;
    else
      state.tableConfig = config.configData.fields;
  },
  setCurrentTableConfig(state, value) {
    state.currentTableConfig = value;
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  reset(state) {
    state.tableData = [];
    state.selectedCells = [];
    state.compareFlag = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};