import utils from '@/utils';
import { clone, buildApiQuery } from '@/utils/dataUtil';
import { PPA_REF_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  contractList: [],
  nullTableRow: {
    id: -999,
    ppaName: null,
    ppaId: null,
    recorderId: null,
    invoiceEntity: null,
    reportHeader: null,
    meterType: null,
    meterType2: null,
    meterType3: null,
    sch3a: null,
    namePlate: null,
    genReg: null,
    channel: null,
    sourceInterval: null,
    displayInterval: null,
    timeZone: null,
    multiplier: null,
    effectiveDate: null,
    terminationDate: null,
    activeFlag: null,
    meterGroup: null,
    pds: null,
    dss: null,
    distVoltage: null,
    secondMultiplier: null,
    sch4Loss: null,
    unreservedUse: null,
    peakNoLoss: null,
    excludeEnergy: null,
    meterType4: null,
    meterType5: null,
    updatedBy: null,
    updatedDate: null,
    createdBy: null,
    createdDate: null,
  },
};

const getters = {
  getContractList: (state) => state.contractList.map(({ id, name }) => ({ value: id, label: name })),
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchContractList');
  },
  async fetchMeter({ commit }, parameters = {}) {
    const params = {
      contracts: parameters.ppasSelected,
    };
    try {
      const { data: { data } } = await PPA_REF_API.post('/meter/get', params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.effectiveDate = getMomentDateString(x.effectiveDate);
          x.terminationDate = x.terminationDate === null ? null : getMomentDateString(x.terminationDate);
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Meter Data', 'error');
    }
  },
  async fetchContractList({ commit }) {
    PPA_REF_API.get('/ppa').then(({ data }) => {
      commit('loadContractList', data.data);
    }).catch((err) => {
      console.error(err);
    });
  },
  async postRecord({ state }, meter) {
    PPA_REF_API.post('/meter', meter).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = (data.terminationDate === null) ? null : getMomentDateString(data.terminationDate);

      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      console.error(err);
      if (err.response.data === 'Reference data already exists') {
        this.$notify('Failed to Add Data. Data already exists', 'error');
      } else {
        this.$notify('Failed to Add Data', 'error');
      }
    });
  },
  async updateRecord({ commit }, meter) {
    PPA_REF_API.put(`/meter/${meter.id}`, meter).then(({ data }) => {
      data.effectiveDate = getMomentDateString(data.effectiveDate);
      data.terminationDate = (data.terminationDate === null) ? null : getMomentDateString(data.terminationDate);

      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      console.error(err);
      this.$notify('Failed to Update Data', 'error');
    });
  },
  async deleteTableRow({ commit, state }) {
    try {
      await PPA_REF_API.delete(`/meter/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      commit('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Remove Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  reset(state) {
    state.tableData = [];
  },
  loadContractList(state, contracts) {
    state.contractList = contracts;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.versionId = 1;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};