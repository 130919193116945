import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { PPA_STLMTS_API } from '@/api';
import { tradeDateRange, ppas } from '@/components/PPA/BaseStore/ppasBase';
import config from './invoiceDetailsConfig';

const state = {
  tableData: [],
  tableConfig: config.fields,
  searchFiltersConfig: {
    tradeDateRange,
    ppas,
  },
};

const actions = {
  async fetchinvoiceDetailsData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      ppas: parameters.ppasSelected,
      startDate,
      endDate,
    };
    try {
      const { data: { data } } = await PPA_STLMTS_API.put('/statements/invoice-bd-details', params);

      data.forEach((x) => {
        x.tradingDate = moment(x.tradingDate).utc().format('MM/DD/YYYY');
      });

      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load invoice bd details', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};