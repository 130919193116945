import {
  coordinatorList,
  tradeDateRange,
  samcHeaders,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
  resourcesTagboxEtsr,
} from '@/components/Ciso/BaseStore/CisoConfigs';

export default {
  dockConfig: {
    visible: false,
    alignment: 'left',
  },
  fields: [{
    caption: 'BD ORDER',
    dataField: 'depth',
    dataType: 'number',
    area: 'row',
    areaIndex: 1,
    expanded: true,
    visible: true,
  }, {
    caption: 'BA',
    dataField: 'ba',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'SETTLEMENT TYPE',
    dataField: 'settlementType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'COMPARE',
    dataField: 'compare',
    area: 'row',
    areaIndex: 999,
    expanded: true,
  }, {
    caption: 'VERSION',
    dataField: 'version',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PROCESS STARTED',
    dataField: 'processStarted',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PROCESS ENDED',
    dataField: 'processEnded',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PUBLICATION',
    dataField: 'publication',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BD NAME',
    dataField: 'bdName',
    dataType: 'string',
    area: 'row',
    areaIndex: 2,
    expanded: true,
  }, {
    caption: 'APN ID',
    dataField: 'apnId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'APN TYPE',
    dataField: 'apnType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BA ID',
    dataField: 'baId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BID SEGMENT NUM',
    dataField: 'bidSegmentNum',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CHANNEL ID',
    dataField: 'channelId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONTRACT REF ID',
    dataField: 'contractRefId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONTRACT TYPE',
    dataField: 'contractType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'COUNTER BA ID',
    dataField: 'counterBaId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'ENERGY TYPE',
    dataField: 'energyType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXCP DISPATCH TYPE',
    dataField: 'excpDispatchType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'EXT CONT AREA',
    dataField: 'extContArea',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'INTERTIE ID',
    dataField: 'intertieId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'LOAD FOLLOWING FLAG',
    dataField: 'loadFollowingFlag',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'LOSS INTERTIE ID',
    dataField: 'lossIntertieId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MARKET TYPE',
    dataField: 'marketType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MSS GROSS OR NET FLAG',
    dataField: 'mssGrossOrNetFlag',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'MSS SUBGROUP',
    dataField: 'mssSubgroup',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PENALTY RSRC ID',
    dataField: 'penaltyRsrcId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PENALTY TYPE',
    dataField: 'penaltyType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PERIOD ID',
    dataField: 'periodId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'PRICE NODE ID',
    dataField: 'priceNodeId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RESOURCE SUBTYPE',
    dataField: 'resourceSubtype',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'RSRC ID',
    dataField: 'rsrcId',
    area: 'column',
    expanded: true,
  }, {
    caption: 'RSRC TYPE',
    dataField: 'rsrcType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TAC AREA ID',
    dataField: 'tacAreaId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'UDC ID',
    dataField: 'udcId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BID TYPE',
    dataField: 'BidType',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'BAL AUTHORITY AREA',
    dataField: 'balAuthorityArea',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'CONSTRAINT ID',
    dataField: 'constraintId',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING DATE',
    dataField: 'tradingDate',
    dataType: 'datetime',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING HOUR',
    dataField: 'tradingHour',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'TRADING INTERVAL',
    dataField: 'tradingInterval',
    area: 'filter',
    expanded: true,
  }, {
    caption: 'VALUE',
    dataField: 'intValue',
    dataType: 'number',
    area: 'data',
    expanded: true,
    summaryType: 'sum',
    format: '#.##',
  }],
  searchFilterConfig: {
    samcHeaders,
    tradeDateRange,
    coordinatorList,
    resourceList: { ...resourcesTagboxEtsr, maxLimit: 6, required: true },
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
  options: {
    exportExcel: true,
    filterRow: true,
    filterHeader: true,
  },
};