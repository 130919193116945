import { Howl } from 'howler';
import { EventEmitter } from '@/utils/eventEmitter';

const SOUND_FILES = {
  bottle: 'https://i.cloudup.com/y29czRwU3R.m4a',
  funk: 'https://i.cloudup.com/KkfWRzYC77.m4a',
  glass: 'https://i.cloudup.com/E021I9zUG3.m4a',
  morse: 'https://i.cloudup.com/h7r7MsF4q3.m4a',
  pop: 'https://i.cloudup.com/vTka9yOizT.m4a',
  purr: 'https://i.cloudup.com/5HJSHCtOzZ.m4a',
  submarine: 'https://i.cloudup.com/r4ZENSF0Hu.m4a',
  tink: 'https://i.cloudup.com/nCtoNq3kJN.m4a',
  police: 'https://wwwsettlecore.blob.core.windows.net/www/police_siren.m4a',
  ambulance: 'https://wwwsettlecore.blob.core.windows.net/www/ambulance_siren.m4a',
};

export default class NotificationSound extends EventEmitter {
  constructor() {
    super();
    this.required = false;
    this.enabled = false;
    this.name = null;
    this.loopCount = 0;
    this._player = null;
    this.durationMilliseconds = 500;
    this.audioType = 'Sound';
    this.speechText = null;
  }

  get isPlaying() {
    return this.enabled === true;
  }

  enable() {
    if (this.required) {
      this.enabled = true;
      const self = this;
      if (this.audioType === 'Sound') {
        if (this._player === null) {
          const howl = new Howl({
            src: [SOUND_FILES[this.name]],
            loop: true,
            preload: true,
          });
          this._player = howl;
          this._player.play();
          setTimeout(() => {
            self.disable();
          }, this.durationMilliseconds);
        }
      }

      if (this.audioType === 'Speech' && !!this.speechText) {
        const msg = new SpeechSynthesisUtterance(this.speechText);
        msg.lang = 'en-US';
        let count = 1;
        window.speechSynthesis.speak(msg);
        const maxCount = this.durationMilliseconds / 1000;
        msg.onend = function () {
          if (self.enabled) {
            if (count < maxCount) {
              count++;
              window.speechSynthesis.speak(msg);
            } else {
              self.disable();
            }
          }
        };
      }
    }
  }

  disable() {
    this.required = false;
    this.enabled = false;
    if (this._player) this._player.stop();
    this._player = null;
    this.emit('disabled');
  }

  setName(name) {
    this.name = name;
  }

  setSpeechText(text) {
    this.speechText = text;
  }

  setDuration(durationSeconds) {
    this.durationMilliseconds = durationSeconds * 1000;
  }

  setAudioType(audioType) {
    if (audioType) {
      if (audioType === 'Sound' || audioType === 'Speech') {
        this.audioType = audioType;
        return;
      }
    }
    this.audioType = 'Sound';
  }
}