<template>
  <div class="create-a-report">
    <pscs-tab-cards-dx
      title="Create a Report"
      :base-config="baseConfig"
      :tabs="tabs"
      submit-text="Generate"
      @tab-rendered="onTabRendered()"
      @submit="submit" />
  </div>
</template>

<script>

import { TOOLS_API, REPORTING_API } from '@/api';
import {
  tradeDateRange, schedulingCoordinatorsWithTagbox, resourcesTagbox, extractFileType, SamcTradeDateRangeSelectedHandler,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import PermissionMixin from '@/mixins/PermissionMixin';
import tabs from './reportCards';

export default {
  name: 'CreateAReport',
  mixins: [PermissionMixin],
  data: () => ({
    baseConfig: {
      tradeDateRange,
      coordinatorList: { ...schedulingCoordinatorsWithTagbox },
      resourceList: resourcesTagbox,
      extractType: extractFileType,
    },
    tabs,
  }),
  beforeMount() {
    Object.entries(this.tabs).forEach(([tabKey, tabVal], tabIndex) => {
      Object.entries(tabVal.cards).forEach(([key, val], index) => {
        try {
          val.visible = false;
          if (val.permission && this.hasAccess(val.permission)) {
            val.visible = true;
          } else {
            delete tabVal.cards[key];
          }
        } catch (ex) {
          val.visible = false;
        }
      });
    });
  },
  methods: {
    async onTabRendered() {
      SamcTradeDateRangeSelectedHandler();
    },
    async submit(request) {
      const { payload, api, endpoint } = request;
      const { beforeSubmit } = request.config;

      if (beforeSubmit) {
        const result = beforeSubmit(payload);
        if (!result.isValid) {
          if (result.errorMessage) this.$notify(result.errorMessage, 'error');
          return;
        }
      }

      if (api === 'REPORTING') {
        payload.entities = (payload.coordinators) ? payload.coordinators.join(',') : '';
        const { data: { successful, message } } = await REPORTING_API.post(endpoint, payload);
        if (successful === true) {
          this.$notify('Successfully initiated report', 'success');
        } else {
          console.error(message);
          this.$notify('Failed to generate report', 'error');
        }
      } else {
        payload.coordinators = (payload.coordinators) ? payload.coordinators : Array.from(request.unfilteredParams.coordinatorList, (x) => x.scId);
        await TOOLS_API.post(endpoint, payload);
        this.$notify('Successfully initiated report', 'success');
      }
    },
  },
};
</script>

<style lang="scss">
.create-a-report {
  .dx-texteditor-input {
    font-size: 1em !important;
  }

  .pscs-tab-cards-dx {
    .dx-tabpanel {
      div[class*=tab-content-] {
        grid-template-columns: repeat(auto-fill, minmax(550px, 1fr));

        .card {
          height: 250px;
        }
      }
    }
  }
}
</style>