import { cloneDeep, has } from '@/utils/dataUtil';
import { CISO_SAMC_API } from '@/api';
import {
  tradeDateRange,
  coordinatorList,
  samcHeaders,
  chargeCodes,
  resources,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { upsertFieldConfig } from '../samcUtil';
import config from './gridConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  tableName: 'Drmt Details Resource Drill',
  selectedCells: [],
  searchFiltersConfig: {
    samcHeaders,
    tradeDateRange,
    coordinatorList,
    chargeCodes: { ...chargeCodes, searchable: true, required: true },
    resourceList: { ...resources, searchable: true, required: true },
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
  bdTree: [],
  currentBd: '',
  currentBdFormula: '',
  currentBpmFormula: '',
  initialParams: {},
  compareFlag: false,
  showRowTotals: false,
};

const actions = {
  async fetchAttributes({ commit }, params) {
    const hasCompareId = has(params, 'compareFileTypesSelected');
    if (hasCompareId) commit('setShowRowTotals', true);
    params.tradeDateRangeSelected[0] = typeof params.tradeDateRangeSelected[0] === 'string' ? new Date(params.tradeDateRangeSelected[0]) : params.tradeDateRangeSelected[0];

    const defaultFields = cloneDeep(state.tableConfig);
    if (!hasCompareId || !params.compareFileTypesSelected) {
      commit('setCompareFlag', false);
      for (let x = 0; x < defaultFields.length; x++) {
        if (defaultFields[x].caption === 'COMPARE') {
          defaultFields[x].visible = false;
          break;
        }
      }
    } else {
      commit('setCompareFlag', true);
    }

    const apiParams = {
      SCs: params.coordinatorListSelected,
      chargeCode: params.chargeCodesSelected,
      rsrcId: params.resourceListSelected,
      tradingDate: params.tradeDateRangeSelected[0].toISOString(),
      fileType: params.fileTypesSelected,
      filePublication: params.publicationsSelected,
      fileVersion: params.fileVersionsSelected,
      compareFileType: params.compareFileTypesSelected,
      comparePublication: params.comparePublicationsSelected,
      compareVersion: params.compareVersionsSelected,
      isFullDrill: true,
    };

    commit('setTableName', apiParams);

    const { data } = await CISO_SAMC_API.get('/determinant-details/attributes-full-drill', {
      params: apiParams,
    });

    if (data && data.data) {
      commit('setTableConfig', upsertFieldConfig(defaultFields, data.data));
    }
  },
  async fetchDeterminantDetails({ dispatch, commit, state }, params) {
    commit('setTableData', []);
    try {
      const apiParams = {
        SCs: params.coordinatorListSelected,
        chargeCode: params.chargeCodesSelected,
        rsrcId: params.resourceListSelected,
        tradingDate: params.tradeDateRangeSelected[0].toISOString(),
        fileType: params.fileTypesSelected,
        filePublication: params.publicationsSelected,
        fileVersion: params.fileVersionsSelected,
        compareFileType: params.compareFileTypesSelected,
        comparePublication: params.comparePublicationsSelected,
        compareVersion: params.compareVersionsSelected,
        isFullDrill: true,
      };

      const response = await CISO_SAMC_API.get('/determinant-details', {
        headers: { 'api-version': '2.0' },
        params: apiParams,
      });

      dispatch('createTableData', response.data);
      commit('setCurrentBd', params.bdName);

      if (!state.bdTree.find((x) => x.bdName === params.bdName)) {
        commit('appendBdToTree', { bdName: params.bdName, params });
      }
    } catch (error) {
      this.$notify('Failed to load Determinant Details. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
  async fetchFormula({ dispatch, commit, state }, params) {
    try {
      const response = await CISO_SAMC_API.get('/configuration-file/formulas', {
        params,
      });
      commit('setCurrentBdFormula', `${response.data.data[0].name} = ${response.data.data[0].equation}`);
      commit('setCurrentBpmFormula', `${response.data.data[1].name} = ${response.data.data[1].equation}`);
    } catch (error) {
      this.$notify('Failed to load Formula. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
  createTableData({ commit, dispatch }, data) {
    if (data && data.data && data.data.length > 0) {
      const flatArray = [];
      data.data.forEach((head) => {
        head.attrs.forEach((mid) => {
          const dataArray = mid.data || [];
          dataArray.forEach((d) => {
            const newObj = {};
            Object.keys(d).forEach((key) => {
              if (!Array.isArray(d[key])) { newObj[key] = d[key]; }
            });
            Object.keys(mid).forEach((key) => {
              if (!Array.isArray(mid[key])) { newObj[key] = mid[key]; }
            });
            Object.keys(head).forEach((key) => {
              if (!Array.isArray(head[key])) { newObj[key] = head[key]; }
            });
            flatArray.push(newObj);
          });
        });
      });

      commit('setTableData', flatArray);
    }
  },

  async nextBdClicked({ dispatch }) {
    const currentBdNode = state.bdTree.find((x) => x.bdName === state.currentBd);
    const indexOfBd = state.bdTree.indexOf(currentBdNode);

    if (indexOfBd === state.bdTree.length - 1) {
      this.$notify('End of calculation tree reached', 'info', 3000);
    } else {
      const nextBdNode = state.bdTree[state.bdTree.indexOf(currentBdNode) + 1];
      dispatch('fetchAttributes', nextBdNode.params);
      dispatch('fetchDeterminantDetails', nextBdNode.params);
    }
  },
  async prevBdClicked({ dispatch }) {
    const currentBdNode = state.bdTree.find((x) => x.bdName === state.currentBd);
    const indexOfBd = state.bdTree.indexOf(currentBdNode);

    if (indexOfBd === 0) {
      this.$notify('Beginning of calculation tree reached', 'info', 3000);
    } else {
      const prevBdNode = state.bdTree[state.bdTree.indexOf(currentBdNode) - 1];
      dispatch('fetchAttributes', prevBdNode.params);
      dispatch('fetchDeterminantDetails', prevBdNode.params);
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setCurrentBd(state, value) {
    state.currentBd = value;
  },
  setCurrentBdFormula(state, value) {
    state.currentBdFormula = value;
  },
  setCurrentBpmFormula(state, value) {
    state.currentBpmFormula = value;
  },
  setInitialParams(state, value) {
    state.initialParams = value;
  },
  appendBdToTree(state, value) {
    state.bdTree.push(value);
  },
  clearTableData(state) {
    state.tableData = [];
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  setSelectedCells(state, value) {
    state.selectedCells = value;
  },
  setTableName(state, value) {
    const file1 = `${value.SCs}-${value.chargeCode}-${value.rsrcId}-${value.fileType}-${value.filePublication}-v${value.fileVersion}`;
    const file2 = value.compareFileType ? `-Vs-${value.compareFileType}-${value.comparePublication}-v${value.compareVersion}` : '';
    state.tableName = file1 + file2;
  },

  reset(state) {
    state.tableData = [];
    state.tableConfig = [];
    state.tableName = 'Drmt Details Resource Drill';
    state.selectedCells = [];
    state.bdTree = [];
    state.currentBd = '';
    state.initialParams = [];
    state.compareFlag = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};