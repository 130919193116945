import { CISO_OASIS_API } from '@/api';
import { createMutations } from '@/utils/vuexHelper';
import { clone } from '@/utils/dataUtil';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import configs from './sufficiencyEvaluationForecastConfigurations';

const state = {
  isAverageHourlyValues: false,
  isRounded: false,
  tableData: [],
  tableConfig: configs.fields,
};

const actions = {
  async fetchSufficiencyEvaluationForecast({ dispatch, commit }, args) {
    commit('setTableData', []);
    const { startDate, endDate } = getMomentDateStringFromRangeArray(args.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      baa: args.balancingAreaAuthoritySelected,
    };
    try {
      const { data: { sufficiencyEvaluationForecast } } = await CISO_OASIS_API.get('/sf-eval-forecast', { params });
      sufficiencyEvaluationForecast.forEach((element) => {
        element.data_type = element.data_type.substring(2, element.data_type.length);
        element.tradingDate = getMomentDateString(element.tradingDate);
      });
      dispatch('tableConfigAction', args.roundNearestMWhSelected);
      commit('setTableData', sufficiencyEvaluationForecast);
      commit('setIsRounded', args.roundNearestMWhSelected);
      commit('setDifferenceValues', args.showDifferenceSelected);
      if (state.isAverageHourlyValues) {
        commit('setAverageHourlyValues');
      }
    } catch {
      this.$notify('Failed to Load Sufficiency Evaluation Forecast', 'error');
    }
  },
  averageHourlyValuesAction({ commit }, isAverageHourlyValues) {
    commit('setIsAverageHourlyValues', isAverageHourlyValues);
    commit('setAverageHourlyValues');
  },
  async tableConfigAction({ commit }, flag) {
    const tc = state.tableConfig;
    const valueField = tc.find(({ dataField }) => dataField === 'value');
    if (valueField && flag) {
      valueField.format = '#,##0';
    } else {
      valueField.format = '#,##0.##';
    }
    commit('setTableConfig', tc);
  },
};

const mutations = {
  ...createMutations(
    'isAverageHourlyValues',
    'isRounded',
    'dockedConfig',
    'tableConfig',
  ),
  setAverageHourlyValues(state) {
    const avgValuesSet = clone(state.tableData);
    avgValuesSet.forEach((elem) => {
      if (state.isAverageHourlyValues) {
        if (elem.data_type === '5min Forecast Actual') {
          elem.value /= 12;
        }
      } else if (elem.data_type === '5min Forecast Actual') {
        elem.value *= 12;
      }
    });
    state.tableData = avgValuesSet;
  },
  setDifferenceValues(state, flag) {
    if (flag) {
      state.tableData = clone(state.tableData).filter((x) => !x.data_type.includes('diff'));
    }
  },
  reset(state) {
    state.tableData = [];
    state.isAverageHourlyValues = false;
    state.isRounded = false;
  },
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};