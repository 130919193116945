import {
  sortEndTimeComparer, toMoment, getDateTime, sortComparer,
} from '../dateUtil';
import { uuid } from '../dataUtil';
import dateStore from '../dateStore';
import helpers from '../helpers';

const SOURCE_KEY = 'reg_sources';
const SINK_KEY = 'reg_sinks';
const PSE_KEY = 'reg_pses';
const BA_KEY = 'reg_bas';
const TP_KEY = 'reg_tps';
const PORPOD_KEY = 'reg_porpods';

export function getTagIDPartsFromName(tagIDName) {
  const split = tagIDName.split('_');
  return {
    gca: split[0],
    tagCode: split[1].substring(split[1].length - 7),
    pse: split[1].substring(0, split[1].length - 7),
    lca: split[2],
  };
}

export function getTagIDName(tagID) {
  if (tagID) {
    return `${tagID.gca}_${tagID.pse}${tagID.tagCode}_${tagID.lca}`;
  }
  return '';
}

export function getProducts(productList, productTypeID) {
  return helpers.filter(productList, (p) => p.productTypeID === productTypeID);
}

export function getEntityType(value) {
  const leftParen = value.indexOf('(');
  const rightParen = value.indexOf(')');
  const type = value.substring(leftParen + 1, rightParen);

  return type;
}

export function createEntityValueLabel(value, label, type, includeType = true) {
  let lbl = label;

  if (includeType) { lbl = `${lbl} (${type})`; }
  return {
    value,
    label: lbl,
  };
}

export function setEditableFalse(configuration, objectDefintion = {}, exclusionList = null) {
  for (let cx = 0; cx < configuration.columns.length; cx++) {
    const column = configuration.columns[cx];
    if (exclusionList != null && exclusionList.includes(column.prop)) {
      continue;
    }
    column.editable = false;
    if (objectDefintion.hasOwnProperty(`${column.prop}_name`)) column.prop += '_name';
  }

  if (configuration.hasOwnProperty('options') && configuration.options.hasOwnProperty('showAddRemove')) {
    configuration.options.showAddRemove = false;
  }
}

export function formatProfileSetList(profileSetList) {
  const zone = dateStore.getTimeZoneDefinition().tz;
  const format = dateStore.getZuluFormat();

  for (let x = 0; x < profileSetList.length; x++) {
    const ps = profileSetList[x];
    ps.id = ps.id.toString();
    ps.profileProductType = ps.profileProductType.toUpperCase();
    ps.profileType = ps.profileType.toUpperCase();
    ps.momentStartTime = toMoment(ps.start, format, zone);
    ps.momentEndTime = toMoment(ps.stop, format, zone);
  }

  return profileSetList;
}

export function findTransmissionProfiles(segmentList, segmentListCount, columns, findTransmission) {
  findTransmission = findTransmission || function (col, tran) { return col.prop === tran.guid; };

  const transmissionProfiles = [];
  for (let mx = 0; mx < segmentListCount; mx++) {
    const ms = segmentList[mx];
    if (ms.physicalSegmentList && ms.physicalSegmentList.length > 0) {
      for (let px = 0; px < ms.physicalSegmentList.length; px++) {
        const ps = ms.physicalSegmentList[px];
        for (let tx = 0; tx < ps.transmissionAllocationList.length; tx++) {
          const ta = ps.transmissionAllocationList[tx];
          const col = helpers.find(columns, (c) => findTransmission(c, ta));
          ta.column = col.prop;
          transmissionProfiles.push(ta);
        }
      }
    }
  }
  return transmissionProfiles;
}

export function findProfileWithinRespectiveProfileSetList(momentStartTime, momentEndTime, profileRef, profileSetList) {
  const zone = dateStore.getTimeZoneDefinition().tz;
  const format = dateStore.getZuluFormat();

  for (let x = 0; x < profileSetList.length; x++) {
    const ps = profileSetList[x];
    if (ps.id !== profileRef) continue;

    const startTime = ps.momentStartTime ? ps.momentStartTime : toMoment(ps.start, format, zone);
    const endTime = ps.momentEndTime ? ps.momentEndTime : toMoment(ps.stop, format, zone);

    if (momentEndTime.isAfter(startTime) && momentStartTime.isBefore(endTime)) {
      return ps;
    }
  }

  return null;
}

export function findProfile(momentStartTime, momentEndTime, profileRef, profileProductType, profileType, profileSetList) {
  const zone = dateStore.getTimeZoneDefinition().tz;
  const format = dateStore.getZuluFormat();

  for (let x = 0; x < profileSetList.length; x++) {
    const ps = profileSetList[x];
    if (!(ps.profileProductType === profileProductType
            && ps.profileType === profileType
            && ps.id.toString() === profileRef.toString())) {
      continue;
    }

    const startTime = ps.momentStartTime ? ps.momentStartTime : toMoment(ps.start, format, zone);
    const endTime = ps.momentEndTime ? ps.momentEndTime : toMoment(ps.stop, format, zone);

    if (momentEndTime.isAfter(startTime) && momentStartTime.isBefore(endTime)) {
      return ps;
    }
  }

  return null;
}

export function getProfileProductTypeTimeRanges(profileSetList, product, type) {
  const timeRanges = new Set();

  const productProfiles = profileSetList.filter(({ profileProductType, profileType }) => profileProductType === product
    && profileType === type);

  for (let x = 0; x < productProfiles.length; x++) {
    const p = productProfiles[x];
    timeRanges.add({
      momentStartTime: p.momentStartTime.clone(),
      momentEndTime: p.momentEndTime.clone(),
      startTime: p.start,
      endTime: p.stop,
    });
  }

  return Array.from(timeRanges);
}

export function distinctTimeRanges(timeRanges) {
  const result = [];

  for (let x = 0; x < timeRanges.length; x++) {
    const tm = timeRanges[x];

    if (result.length === 0) {
      result.push({
        momentStartTime: tm.momentStartTime.clone(),
        momentEndTime: tm.momentEndTime.clone(),
        startTime: tm.startTime,
        endTime: tm.endTime,
      });
    } else {
      const tm_key = `${tm.startTime}_${tm.endTime}`;
      const found = helpers.findIndex(result, (r) => (`${r.startTime}_${r.endTime}`) === tm_key);

      if (found === -1) {
        result.push({
          momentStartTime: tm.momentStartTime.clone(),
          momentEndTime: tm.momentEndTime.clone(),
          startTime: tm.startTime,
          endTime: tm.endTime,
        });
      }
    }
  }

  return result;
}

export function mergeTimeRanges(timeRanges, product = '') {
  const distinctTimes = timeRanges; // distinctTimeRanges(timeRanges);

  let sortedTimeRanges = distinctTimes.sort((a, b) => a.momentStartTime.valueOf() - b.momentStartTime.valueOf());

  let i = 0;

  while (true) {
    if ((i + 1) >= sortedTimeRanges.length) break;

    const first = sortedTimeRanges[i];
    const second = sortedTimeRanges[i + 1];

    if (first.momentStartTime.isSame(first.momentEndTime)) { break; }

    if (first.momentStartTime.isSame(second.momentStartTime)) {
      if (first.momentEndTime.isBefore(second.momentEndTime)) {
        const smallerMomentEndTime = first.momentEndTime.clone();
        const smallerEndTime = first.endTime;
        const largerMomentEndTime = second.momentEndTime.clone();
        const largerEndTime = second.endTime;
        sortedTimeRanges[i + 1] = {
          momentStartTime: smallerMomentEndTime,
          momentEndTime: largerMomentEndTime,
          startTime: smallerEndTime,
          endTime: largerEndTime,
        };
      } else if (first.momentEndTime.isSame(second.momentEndTime)) {
        sortedTimeRanges.splice(i + 1, 1);
      } else if (first.momentEndTime.isAfter(second.momentEndTime)) {
        const smallerMomentEndTime = second.momentEndTime.clone();
        const smallerEndTime = second.endTime;
        const largerMomentEndTime = first.momentEndTime.clone();
        const largerEndTime = first.endTime;

        sortedTimeRanges[i] = {
          momentStartTime: first.momentStartTime.clone(),
          momentEndTime: smallerMomentEndTime,
          startTime: first.startTime,
          endTime: smallerEndTime,
        };

        sortedTimeRanges[i + 1] = {
          momentStartTime: smallerMomentEndTime.clone(),
          momentEndTime: largerMomentEndTime.clone(),
          startTime: smallerEndTime,
          endTime: largerEndTime,
        };
      }

      // sortedTimeRanges = helpers.sortBy(sortedTimeRanges, [function (o) { return o.momentStartTime.clone().utc(); }]);
      sortedTimeRanges = sortedTimeRanges.sort((a, b) => a.momentStartTime.valueOf() - b.momentStartTime.valueOf());
      continue;
    } else if (first.momentEndTime.isAfter(second.momentStartTime)) {
      // if(first.momentEndTime.isSameOrAfter(second.momentEndTime)){
      //     sortedTimeRanges.splice(i + 1, 1);
      // }else {
      sortedTimeRanges[i] = {
        momentStartTime: first.momentStartTime.clone(),
        momentEndTime: second.momentStartTime.clone(),
        startTime: first.startTime,
        endTime: second.startTime,
      };

      if (!first.momentEndTime.isSame(second.momentEndTime)) {
        if (first.momentEndTime.isAfter(second.momentEndTime)) {
          sortedTimeRanges.push({
            momentStartTime: second.momentEndTime.clone(),
            momentEndTime: first.momentEndTime.clone(),
            startTime: second.endTime,
            endTime: first.endTime,
          });
        } else {
          sortedTimeRanges[i + 1] = {
            momentStartTime: first.momentEndTime.clone(),
            momentEndTime: second.momentEndTime.clone(),
            startTime: first.endTime,
            endTime: second.endTime,
          };

          sortedTimeRanges.push({
            momentStartTime: second.momentStartTime.clone(),
            momentEndTime: first.momentEndTime.clone(),
            startTime: second.startTime,
            endTime: first.endTime,
          });
        }
      }
      // }
      // sortedTimeRanges = helpers.sortBy(sortedTimeRanges, [function (o) { return o.momentStartTime.clone().utc(); }]);
      sortedTimeRanges = sortedTimeRanges.sort((a, b) => a.momentStartTime.valueOf() - b.momentStartTime.valueOf());
      continue;
    }

    i++;
  }

  return sortedTimeRanges;
}

export function getProfilesFromTimeRanges(timeRanges, columns) {
  const profiles = [];
  const filteredColumns = columns.filter((col) => (!col.isParent || col.isParent === false) && (col.prop !== 'startTime' && col.prop !== 'endTime' && col.prop !== 'he'));
  for (let x = 0; x < timeRanges.length; x++) {
    const time = timeRanges[x];
    const obj = {
      momentStartTime: time.momentStartTime,
      momentEndTime: time.momentEndTime,
      startTime: time.startTime,
      endTime: time.endTime,
    };
    filteredColumns.forEach((col) => {
      obj[col.prop] = null;
    });
    profiles.push(obj);
  }
  return profiles;
}

export function getProfileTimeRanges(profileSetList) {
  // console.log('reducing energy time ranges');
  const energyTimeRanges = mergeTimeRanges(getProfileProductTypeTimeRanges(profileSetList, 'ENERGY', 'CURRENT'), 'ENERGY');
  // console.log(energyTimeRanges);

  // console.log('reducing capacity time ranges');
  const capacityTimeRanges = mergeTimeRanges(getProfileProductTypeTimeRanges(profileSetList, 'CAPACITY', 'RESERVATION'), 'CAPACITY');
  // console.log(capacityTimeRanges);

  const timeRanges = energyTimeRanges.concat(capacityTimeRanges);
  // console.log(cloneDeep(energyTimeRanges));
  // console.log(cloneDeep(capacityTimeRanges));

  // console.log('reducing concat of energy and capacity time ranges');
  const sortedTimeRanges = mergeTimeRanges(timeRanges);

  return sortedTimeRanges;
}

export function getProfileTimeRangesByProduct(profileSetList) {
  const zone = dateStore.getTimeZoneDefinition().tz;
  const format = dateStore.getZuluFormat();

  const stringTimeRanges = new Set();
  // an object is not a valid item to insert in set. because it will always evaluate as a new entry
  profileSetList.forEach(({ start, stop }) => stringTimeRanges.add(`${start}|${stop}`));

  const timeRanges = [];
  stringTimeRanges.forEach((t) => {
    const [startTime, endTime] = t.split('|');
    timeRanges.push({
      momentStartTime: toMoment(startTime, format, zone),
      momentEndTime: toMoment(endTime, format, zone),
      startTime,
      endTime,
    });
  });

  return mergeTimeRanges(timeRanges);
}

function arePropertyEqual(left, right, prop) {
  let leftValue = left[prop];
  let rightValue = right[prop];

  leftValue = (leftValue === null || leftValue === undefined) ? '' : leftValue.toString().toLowerCase();
  rightValue = (rightValue === null || rightValue === undefined) ? '' : rightValue.toString().toLowerCase();

  return leftValue === rightValue;
}

function areEqual(columns, left, right) {
  for (let cx = 0; cx < columns.length; cx++) {
    const col = columns[cx];

    if (col.prop === 'startTime' || col.prop === 'endTime' || col.prop === 'he') { continue; }

    if (!arePropertyEqual(left, right, col.prop)) { return false; }
  }

  return true;
}

function arePropertyNulls(columns, item) {
  const columnsLength = columns.length;

  for (let cx = 0; cx < columnsLength; cx++) {
    const { prop } = columns[cx];

    if (prop === 'startTime' || prop === 'endTime' || prop === 'he') { continue; }

    if (item[prop] !== null && item[prop] !== undefined) { return false; }
  }

  return true;
}

export function setDefaultPropertyValues(obj, columns, value = null, fillCapacity = true) {
  const { length } = columns;
  for (let cx = 0; cx < length; cx++) {
    const col = columns[cx];

    if (col.isParent && col.isParent === true) continue;

    const { prop } = col;

    if (prop === 'startTime' || prop === 'endTime' || prop === 'he') { continue; }

    if (!fillCapacity) if (prop !== 'source' && prop !== 'sink') continue;

    obj[prop] = value;
  }
}

export function copyPropertyValues(columns, source, destination) {
  const { length } = columns;
  for (let cx = 0; cx < length; cx++) {
    const col = columns[cx];

    if (col.isParent && col.isParent === true) continue;

    if (col.prop === 'startTime' || col.prop === 'endTime' || col.prop === 'he') { continue; }
    destination[col.prop] = source[col.prop];
  }
}

export function copyProfiles(columns, profiles, objectDefinition) {
  const copy = [];

  const { length } = profiles;
  for (let x = 0; x < length; x++) {
    const p = profiles[x];
    const obj = helpers.clone(objectDefinition);
    obj.momentStartTime = p.momentStartTime.clone();
    obj.momentEndTime = p.momentEndTime.clone();
    obj.startTime = p.momentStartTime.clone().utc().format();
    obj.endTime = p.momentEndTime.clone().utc().format();

    setDefaultPropertyValues(obj, columns);
    copyPropertyValues(columns, p, obj);
    copy.push(obj);
  }

  return copy;
}

export function reduceProfiles(profiles, columns, objectDefinition) {
  const sortedProfiles = profiles.sort(sortComparer);
  let i = 0;
  while (true) {
    if ((i + 1) >= sortedProfiles.length) break;

    const first = sortedProfiles[i];

    if (arePropertyNulls(columns, first)) {
      sortedProfiles.splice(i, 1);
      continue;
    }

    const second = sortedProfiles[i + 1];

    if (arePropertyNulls(columns, second)) {
      sortedProfiles.splice(i + 1, 1);
      continue;
    }
    if (first.momentEndTime.isSame(second.momentStartTime) && areEqual(columns, first, second)) {
      first.momentEndTime = second.momentEndTime.clone();
      first.endTime = second.momentEndTime.clone().utc().format();
      sortedProfiles.splice(i + 1, 1);

      continue;
    }

    i++;
  }

  return sortedProfiles;
}

export function createSegmentList(generation, transmissions, load) {
  const segmentList = [];

  const marketSegmentID = 1;
  const physicalSegmentID = 1;
  const transmissionAllocationID = 1;
  const genSegment = createGenerationMarketSegment(generation);

  segmentList.push(genSegment);
  if (transmissions != null && transmissions.length > 0) {
    let segment = genSegment;
    for (let tx = 0; tx < transmissions.length; tx++) {
      const tran = transmissions[tx];

      if (segment.marketSegmentID !== tran.market_id) {
        segment = createTranmissionMarketSegment(tran);
        segmentList.push(segment);
      }

      if (tran.physical_id !== null && tran.physical_id !== '') {
        let foundPhysicalPath = false;
        for (let px = 0; px < segment.physicalSegmentList.length; px++) {
          const physicalSegment = segment.physicalSegmentList[px];

          if (physicalSegment.physicalSegmentID === tran.physical_id) {
            foundPhysicalPath = true;

            const allocation = createTransmissionAllocationSegment(tran);
            physicalSegment.transmissionAllocationList.push(allocation);
            physicalSegment.prop.push(tran.guid);
            break;
          }
        }

        if (!foundPhysicalPath) {
          const newPhysicalSegment = createTransmissionPhysicalSegment(tran);
          const allocation = createTransmissionAllocationSegment(tran);
          newPhysicalSegment.transmissionAllocationList.push(allocation);
          newPhysicalSegment.prop.push(tran.guid);
          segment.physicalSegmentList.push(newPhysicalSegment);
        }
      }
    }
  }

  const lastSegment = segmentList[segmentList.length - 1];

  if (lastSegment.marketSegmentID === load.market_id) {
    lastSegment.energyProductRef = 3;
    lastSegment.energyProduct = 'L';
    lastSegment.physicalSegmentList.push(createLoadPhysicalSegment(load));
  } else {
    const loadSegment = createLoadMarketSegment(load);
    segmentList.push(loadSegment);
  }
  return segmentList;
}

function createGenerationMarketSegment(generation) {
  const contract = generation.trans_contract;

  const marketContractNumberList = [];
  if (generation.market_contractList) {
    for (let i = 0; i < generation.market_contractList.length; i++) {
      const cont = generation.market_contractList[i];
      marketContractNumberList.push(cont);
    }
  }

  const physicalContractNumberList = [];
  if (contract !== null && contract !== '') { physicalContractNumberList.push(contract); }

  return {
    marketSegmentID: generation.market_id,
    pseCode: null,
    pse: generation.market_pse_name,
    energyProductRef: null,
    energyProduct: generation.market_product_name,
    contractNumberList: marketContractNumberList,
    miscInfoList: generation.market_miscInfoList,
    physicalSegmentList: [{
      physicalSegmentID: generation.physical_id,
      tpCode: null,
      tp: null,
      porCode: null,
      por: null,
      podCode: null,
      pod: generation.physical_source_name,
      mo: generation.physical_mo_name,
      moCode: null,
      porProfileRef: null,
      podProfileRef: null,
      schedulingEntityList: [],
      contractNumberList: physicalContractNumberList,
      miscInfoList: generation.physical_miscInfoList,
      transmissionAllocationList: [],
      lossAccountingList: [],
      prop: ['source'],
    }],
  };
}

function createTranmissionMarketSegment(transmission) {
  const contracts = [];
  if (transmission.market_contractList) {
    for (let i = 0; i < transmission.market_contractList.length; i++) {
      const contract = transmission.market_contractList[i];
      contracts.push(contract);
    }
  }

  return {
    marketSegmentID: transmission.market_id,
    pseCode: null,
    pse: transmission.market_pse_name,
    energyProductRef: null,
    energyProduct: transmission.market_product_name,
    contractNumberList: contracts,
    miscInfoList: transmission.market_miscInfoList,
    physicalSegmentList: [],
  };
}

function createTransmissionPhysicalSegment(transmission) {
  const seCode = transmission.physical_se;
  const seName = transmission.physical_se_name;
  const seType = seName ? getEntityType(seName) : null;

  const lossAccountList = [];

  if (transmission.loss_accountingCount.toString() !== '0') {
    for (let x = 0; x < transmission.loss_accountingList.length; x++) {
      const la = transmission.loss_accountingList[x];

      const acct = {
        physicalSegmentRef: transmission.physical_id,
        lossMethod: la.method,
        start: la.start,
        stop: la.stop,
        tagIDList: [],
        contractNumberList: [],
      };

      if (la.method === 'INTERNAL') {
        acct.tagIDList = la.tagList;
        acct.contractNumberList = la.contractList;
      } else if (la.method === 'EXTERNAL') {
        acct.tagIDList = la.tagList;
      }

      lossAccountList.push(acct);
    }
  }

  return {
    physicalSegmentID: transmission.physical_id,
    tpCode: null,
    tp: transmission.physical_tp_name,
    porCode: null,
    por: transmission.physical_por_name,
    podCode: null,
    pod: transmission.physical_pod_name,
    mo: transmission.physical_mo_name,
    moCode: null,
    porProfileRef: null,
    podProfileRef: null,
    schedulingEntityList: [{ entity: seName, entityCode: 0, entityType: seType }],
    contractNumberList: [],
    miscInfoList: transmission.physical_miscInfoList,
    transmissionAllocationList: [],
    lossAccountingList: lossAccountList,
    prop: [],
  };
}

function createTransmissionAllocationSegment(transmission) {
  return {
    transmissionAllocationID: transmission.trans_id,
    transProductRef: -1,
    transProduct: transmission.trans_product_name,
    contractNumber: transmission.trans_contract,
    tcCode: 0,
    tc: transmission.trans_tc_name,
    profileRef: -1,
    nitsResource: transmission.trans_nits,
    miscInfoList: transmission.trans_miscInfoList,
    prop: transmission.guid,
  };
}

function createLoadMarketSegment(load) {
  const contractNumberList = [];
  if (load.market_contractList) {
    for (let i = 0; i < load.market_contractList.length; i++) {
      const contract = load.market_contractList[i];
      contractNumberList.push(contract);
    }
  }

  return {
    marketSegmentID: load.market_id,
    pse: load.market_pse_name,
    pseCode: null,
    energyProductRef: 3,
    energyProduct: 'L',
    contractNumberList,
    miscInfoList: load.market_miscInfoList,
    physicalSegmentList: [
      createLoadPhysicalSegment(load),
    ],
  };
}

function createLoadPhysicalSegment(load) {
  const contract = load.trans_contract;
  const contractNumberList = [];
  if (contract !== null && contract !== '') { contractNumberList.push(contract); }

  return {
    physicalSegmentID: load.physical_id,
    tpCode: null,
    tp: null,
    porCode: null,
    por: load.physical_sink_name,
    podCode: null,
    pod: null,
    mo: load.physical_mo_name,
    moCode: null,
    porProfileRef: null,
    podProfileRef: null,
    schedulingEntityList: [],
    contractNumberList,
    transmissionAllocationList: [],
    miscInfoList: load.physical_miscInfoList,
    lossAccountingList: [],
    prop: ['sink'],
  };
}

export function createProfileColumnDictionary(columns, profiles) {
  const dictionary = [];

  let energyProfileRef = 1;

  for (let cx = 0; cx < columns.length; cx++) {
    const column = columns[cx];
    if (column.prop === 'startTime' || column.prop === 'endTime'
         || column.prop === 'rampStart' || column.prop === 'rampStop' || column.prop === 'he') { continue; }

    const { prop } = column;

    const di = { key: column.prop, c_type: column.p_type, values: [] };

    let porProfileRef = null;
    let podProfileRef = null;

    if (column.p_type === 'gen') {
      di.profileProductType = 'ENERGY';
      podProfileRef = energyProfileRef;
    } else if (column.p_type === 'tran') {
      if (helpers.endsWith(column.prop, '_energy')) {
        di.profileProductType = 'ENERGY';
        porProfileRef = energyProfileRef++;
        podProfileRef = energyProfileRef;
      } else {
        di.profileProductType = 'CAPACITY';
      }
    } else if (column.p_type === 'load') {
      di.profileProductType = 'ENERGY';
      porProfileRef = energyProfileRef++;
    }

    di.prop = prop;
    di.porProfileRef = porProfileRef;
    di.podProfileRef = podProfileRef;
    dictionary.push(di);
  }

  setProfileDictionaryValues(profiles, dictionary);
  return dictionary;
}

function setProfileDictionaryValues(profiles, dictionary) {
  for (let ix = 0; ix < profiles.length; ix++) {
    const item = profiles[ix];

    const start = item.momentStartTime.clone().utc().format();
    const end = item.momentEndTime.clone().utc().format();

    for (let dx = 0; dx < dictionary.length; dx++) {
      const dicItem = dictionary[dx];
      if (dicItem.profileProductType === 'ENERGY') {
        dicItem.values.push({
          startTime: start,
          endTime: end,
          momentStartTime: item.momentStartTime.clone(),
          momentEndTime: item.momentEndTime.clone(),
          rampStart: item.rampStart,
          rampStop: item.rampStop,
          mw: item[dicItem.key],
        });
      } else {
        dicItem.values.push({
          startTime: start,
          endTime: end,
          momentStartTime: item.momentStartTime.clone(),
          momentEndTime: item.momentEndTime.clone(),
          rampStart: null,
          rampStop: null,
          mw: item[dicItem.key],
        });
      }
    }
  }
}

export function associateSegmentToProfile(segmentList, profileMappingList) {
  const energyProfiles = helpers.cloneDeep(helpers.filter(profileMappingList, (p) => p.profileProductType === 'ENERGY'));

  for (let x = 0; x < energyProfiles.length; x++) {
    energyProfiles[x].profileType = 'MARKETLEVEL';
  }

  const capacityProfiles = helpers.cloneDeep(helpers.filter(profileMappingList, (p) => p.profileProductType === 'CAPACITY'));

  for (let x = 0; x < capacityProfiles.length; x++) {
    capacityProfiles[x].profileType = 'RESERVATION';
  }

  let previousEnergyProfile = null;

  for (let sx = 0; sx < segmentList.length; sx++) {
    const segment = segmentList[sx];

    for (let px = 0; px < segment.physicalSegmentList.length; px++) {
      const physicalSegment = segment.physicalSegmentList[px];

      previousEnergyProfile = setPhysicalSegmentProfileID(physicalSegment, energyProfiles, previousEnergyProfile);

      delete physicalSegment.prop;
    }
  }

  for (let ex = 0; ex < energyProfiles.length; ex++) {
    const ep = energyProfiles[ex];
    const maxProfileID = Math.max(ep.porProfileRef, ep.podProfileRef);

    delete ep.porProfileRef;
    delete ep.podProfileRef;

    ep.profileID = maxProfileID;
  }

  /*
    let maxEnergyProfileID = energyProfiles[energyProfiles.length - 1].profileID + 1;

    for(let cx = 0; cx < capacityProfiles.length; cx++){
        let cp = capacityProfiles[cx];
        delete cp.porProfileRef;
        delete cp.podProfileRef;

        cp.profileID = maxEnergyProfileID++;
    }
    */

  for (let sx = 0; sx < segmentList.length; sx++) {
    const segment = segmentList[sx];

    for (let px = 0; px < segment.physicalSegmentList.length; px++) {
      const physicalSegment = segment.physicalSegmentList[px];

      if (physicalSegment.transmissionAllocationList.length > 0) {
        for (let ax = 0; ax < physicalSegment.transmissionAllocationList.length; ax++) {
          const transmissionAllocation = physicalSegment.transmissionAllocationList[ax];

          setTransmissionAllocationProfileID(transmissionAllocation, capacityProfiles);

          delete transmissionAllocation.prop;
        }
      }
    }
  }

  const allProfiles = helpers.concat(energyProfiles, capacityProfiles);

  reduceDictionaryValues(allProfiles);

  const profiles = createProfileSetList(allProfiles);

  const minStartTime = findMinStartTime(profiles);
  const maxEndTime = findMaxEndTime(profiles);

  for (let sx = 0; sx < segmentList.length; sx++) {
    const segment = segmentList[sx];

    for (let px = 0; px < segment.physicalSegmentList.length; px++) {
      const physicalSegment = segment.physicalSegmentList[px];

      if (physicalSegment.lossAccountingList !== undefined && physicalSegment.lossAccountingList !== null && physicalSegment.lossAccountingList.length > 0) {
        for (let lx = 0; lx < physicalSegment.lossAccountingList.length; lx++) {
          const lossAccounting = physicalSegment.lossAccountingList[lx];

          if ((lossAccounting.start === null || lossAccounting.start === '')
                        && (lossAccounting.stop === null || lossAccounting.stop === '')) {
            lossAccounting.start = minStartTime;
            lossAccounting.stop = maxEndTime;
          }
        }
      }
    }
  }

  return profiles;
}

function reduceProfilesByType(dictionary, profileProductType) {
  let dx = 0;

  const items = helpers.cloneDeep(helpers.filter(dictionary, (d) => d.profileProductType === profileProductType));

  while (true) {
    if ((dx + 1) >= items.length) break;

    const first = items[dx];
    const second = items[dx + 1];

    if (areProfileValuesAMatch(first.values, second.values)) {
      first.props.push(second.props[0]);

      items.splice(dx + 1, 1);
    } else {
      dx++;
    }
  }

  for (let x = 0; x < items.length; x++) {
    delete items[x].key;
    delete items[x].c_type;
  }
  return items;
}

function reduceDictionaryValues(dictionary) {
  for (let dx = 0; dx < dictionary.length; dx++) {
    const item = dictionary[dx];

    const { values } = item;

    let vx = 0;

    while (true) {
      if ((vx + 1) >= values.length) break;

      const first = values[vx];
      const second = values[vx + 1];

      if (first.endTime === second.startTime
                && first.mw === second.mw) {
        first.endTime = second.endTime;
        values.splice(vx + 1, 1);
      } else { vx++; }
    }
    item.values = values;
  }
}

function areProfileValuesAMatch(leftValues, rightValues) {
  return doProfileValuesMatch(leftValues, rightValues, (left, right) => left === right);
}

function doProfileValuesMatch(leftValues, rightValues, mwEvaluator) {
  if (leftValues.length !== rightValues.length) { return false; }

  for (let ix = 0; ix < leftValues.length; ix++) {
    const left = leftValues[ix];
    const right = rightValues[ix];

    if (!(left.startTime === right.startTime
                && left.endTime === right.endTime
                && mwEvaluator(helpers.toNumber(left.mw), helpers.toNumber(right.mw)))) {
      return false;
    }
  }

  return true;
}

function setPhysicalSegmentProfileID(physicalSegment, profileMappingList, previousEnergyProfile) {
  const energyProfiles = [];
  for (let px = 0; px < physicalSegment.prop.length; px++) {
    const prop = physicalSegment.prop[px];

    for (let mx = 0; mx < profileMappingList.length; mx++) {
      const pm = profileMappingList[mx];
      if (pm.prop === prop) {
        energyProfiles.push({
          key: pm.key,
          prop: pm.prop,
          porProfileRef: pm.porProfileRef,
          podProfileRef: pm.podProfileRef,
          values: helpers.cloneDeep(pm.values),
        });
      }
    }
  }

  if (previousEnergyProfile === null) {
    physicalSegment.porProfileRef = energyProfiles[0].porProfileRef;
    physicalSegment.podProfileRef = energyProfiles[0].podProfileRef;

    return energyProfiles[0];
  }

  if (energyProfiles.length == 0) {
    let { porProfileRef } = previousEnergyProfile;
    let { podProfileRef } = previousEnergyProfile;

    const profileRef = porProfileRef !== null ? porProfileRef : podProfileRef;

    if (porProfileRef === null) { porProfileRef = profileRef; }

    if (podProfileRef === null) { podProfileRef = profileRef; }

    physicalSegment.porProfileRef = porProfileRef;
    physicalSegment.podProfileRef = podProfileRef;

    return previousEnergyProfile;
  }

  const newValues = aggregateProfiles(energyProfiles);

  const isMatch = doProfileValuesMatch(previousEnergyProfile.values,
    newValues,
    (left, right) => left <= right,
  );

  if (isMatch) {
    const ep = energyProfiles[0];
    let { porProfileRef } = ep;
    let { podProfileRef } = ep;

    const profileRef = previousEnergyProfile.porProfileRef !== null ? previousEnergyProfile.porProfileRef : previousEnergyProfile.podProfileRef;

    if (porProfileRef !== null) { porProfileRef = profileRef; }

    if (podProfileRef !== null) { podProfileRef = profileRef; }

    physicalSegment.porProfileRef = porProfileRef;
    physicalSegment.podProfileRef = podProfileRef;

    for (let ex = energyProfiles.length - 1; ex >= 0; ex--) {
      const { key } = energyProfiles[ex];
      const idx = helpers.findIndex(profileMappingList, (p) => p.key === key);
      profileMappingList.splice(idx, 1);
    }

    return previousEnergyProfile;
  }
}

function aggregateProfiles(energyProfiles) {
  const newValues = helpers.cloneDeep(energyProfiles[0].values);
  for (let ex = 1; ex < energyProfiles.length; ex++) {
    const p = energyProfiles[ex];

    for (let vx = 0; vx < newValues.length; vx++) {
      const value = newValues[vx];
      const pValue = p.values[vx];

      value.mw += pValue.mw;

      newValues[vx] = value;
    }
  }

  return newValues;
}

function setTransmissionAllocationProfileID(allocation, profileMappingList) {
  const pm = helpers.find(profileMappingList, (p) => p.prop === allocation.prop);

  if (pm) pm.profileID = allocation.transmissionAllocationID;
  allocation.profileRef = allocation.transmissionAllocationID;
}

function createProfileSetList(profileMappingList) {
  const result = [];

  for (let ix = 0; ix < profileMappingList.length; ix++) {
    const item = profileMappingList[ix];

    for (let vx = 0; vx < item.values.length; vx++) {
      const value = item.values[vx];

      if (value.mw === null || value.mw === undefined) continue;

      result.push({
        id: item.profileID,
        start: value.startTime,
        stop: value.endTime,
        mw: value.mw,
        rampStart: value.rampStart,
        rampStop: value.rampStop,
        profileProductType: item.profileProductType,
        profileType: item.profileType,
      });
    }
  }

  return result;
}

export function findMaxEnergyColumn(columns) {
  let maxEnergyColumnIndex = 0;

  for (let x = 0; x < columns.length; x++) {
    const col = columns[x];

    if (col.profileProductType === 'ENERGY'
            && col.prop !== 'sink') {
      maxEnergyColumnIndex = x;
    }
  }

  return {
    col: helpers.cloneDeep(columns[maxEnergyColumnIndex]),
    index: maxEnergyColumnIndex,
  };
}

export function modelTolossAccountingState(lossAccountingList) {
  const result = [];

  if (lossAccountingList != null) {
    for (let x = 0; x < lossAccountingList.length; x++) {
      const la = lossAccountingList[x];

      const lossContractList = [];
      const lossTagIDList = [];

      if (la.tagIDList) {
        for (let i = 0; i < la.tagIDList.length; i++) {
          const id = la.tagIDList[i];
          lossTagIDList.push({ value: id });
        }
      }

      if (la.contractNumberList) {
        for (let i = 0; i < la.contractNumberList.length; i++) {
          const contract = la.contractNumberList[i];
          lossContractList.push({ value: contract });
        }
      }

      result.push({
        type: 'LOSS',
        method: la.lossMethod,
        start: la.start,
        stop: la.stop,
        contractCount: lossContractList.length,
        contractList: lossContractList,
        tagCount: lossTagIDList.length,
        tagList: lossTagIDList,
      });
    }
  }

  return result;
}

export function modelToTransmissionState(tagModel, nullTransmissionRow, readonly) {
  const tranList = [];
  const segmentCount = tagModel.segmentList.length;

  for (let mx = 0; mx < tagModel.segmentList.length; mx++) {
    const ms = tagModel.segmentList[mx];

    if (ms.physicalSegmentList && ms.physicalSegmentList.length > 0) {
      for (let px = 0; px < ms.physicalSegmentList.length; px++) {
        const ps = ms.physicalSegmentList[px];

        if ((mx === 0 && px === 0)
                    || (mx === segmentCount - 1 && px == ms.physicalSegmentList.length - 1)) {
          // IGNORE
        } else {
          const la = modelTolossAccountingState(ps.lossAccountingList);

          for (let tx = 0; tx < ps.transmissionAllocationList.length; tx++) {
            const ta = ps.transmissionAllocationList[tx];
            let se = null;

            if (ps.schedulingEntityList && ps.schedulingEntityList.length > 0) { se = ps.schedulingEntityList[0]; }

            const contractList = [];
            for (let i = 0; i < ms.contractNumberList.length; i++) {
              const contract = ms.contractNumberList[i];
              contractList.push(contract);
            }

            let energyProductRef = null;
            let energyProduct = null;

            if (mx !== 0 && mx !== (segmentCount - 1)) {
              energyProductRef = ms.energyProductRef;
              energyProduct = ms.energyProduct;
            }

            const guid = uuid();
            tranList.push({
              guid,
              type: 'TRAN',
              market_id: ms.marketSegmentID,
              market_pse_name: ms.pse,
              market_product: energyProductRef,
              market_product_name: energyProduct,
              market_contractCount: contractList.length,
              market_contractList: contractList,
              market_miscInfoCount: ms.miscInfoList.length,
              market_miscInfoList: ms.miscInfoList,
              physical_id: ps.physicalSegmentID,
              physical_se_name: se.entity,
              physical_tp_name: ps.tp,
              physical_por_name: ps.por,
              physical_pod_name: ps.pod,
              physical_mo_name: ps.mo,
              physical_miscInfoCount: ps.miscInfoList.length,
              physical_miscInfoList: ps.miscInfoList,
              trans_num: ta.transmissionAllocationID,
              trans_id: ta.transmissionAllocationID,
              trans_product: ta.transProductRef == null ? ta.transProductRef : ta.transProductRef.toString(),
              trans_product_name: ta.transProduct,
              trans_contract: ta.contractNumber,
              trans_tc_name: ta.tc,
              trans_miscInfoCount: ta.miscInfoList.length,
              trans_miscInfoList: ta.miscInfoList,
              trans_nits: ta.nitsResource,
              trans_state: readonly ? 'clean' : 'dirty',
              loss_accountingCount: la.length,
              loss_accountingList: la,
            });

            ta.guid = guid;
          }
        }
      }
    } else {
      const contractList = [];
      for (let i = 0; i < ms.contractNumberList.length; i++) {
        const contract = ms.contractNumberList[i];
        contractList.push({ value: contract });
      }

      tranList.push({
        guid: uuid(),
        type: 'TRAN',
        market_id: ms.marketSegmentID,
        market_pse: ms.pseCode.toString(),
        market_pse_name: ms.pse,
        market_product: (ms.energyProductRef) ? ms.energyProductRef : null,
        market_product_name: (ms.energyProduct) ? ms.energyProduct : null,
        market_contractCount: contractList.length,
        market_contractList: contractList,
        market_miscInfoCount: ms.miscInfoList.length,
        market_miscInfoList: ms.miscInfoList,
        physical_id: null,
        physical_se_name: null,
        physical_tp_name: null,
        physical_por_name: null,
        physical_pod_name: null,
        physical_mo_name: null,
        physical_miscInfoCount: 0,
        physical_miscInfoList: [],
        trans_id: null,
        trans_num: null,
        trans_product: null,
        trans_product_name: null,
        trans_contract: null,
        trans_tc_name: null,
        trans_miscInfoCount: 0,
        trans_miscInfoList: [],
        trans_state: readonly ? 'clean' : 'dirty',
        loss_accountingCount: 0,
        loss_accountingList: [],
      });
    }
  }
  if (tranList === null || tranList.length <= 0) { tranList.push(helpers.clone(nullTransmissionRow)); }

  return tranList;
}

export function findMinStartTime(profiles) {
  const result = dateStore.momentizeData(helpers.cloneDeep(profiles), [
    { prop: 'start', momentProp: 'momentStartTime' },
    { prop: 'stop', momentProp: 'momentEndTime' },
  ]);

  const starttimes = helpers.map(result, 'momentStartTime');
  return dateStore.findMin(starttimes);
}

export function findMaxEndTime(profiles) {
  const result = dateStore.momentizeData(helpers.cloneDeep(profiles), [
    { prop: 'start', momentProp: 'momentStartTime' },
    { prop: 'stop', momentProp: 'momentEndTime' },
  ]);

  const endtimes = helpers.map(result, 'momentEndTime');
  return dateStore.findMax(endtimes);
}

export function shiftProfilesAndLossAccountingByOffset(tag, offset) {
  const endTime = dateStore.toMoment(tag.terminationDateTime).utc();

  const profileList = [];
  for (let i = 0; i < tag.profileSetList.length; i++) {
    const profile = tag.profileSetList[i];
    const start = dateStore.toMoment(profile.start).utc().add(offset, 'days');
    let stop = dateStore.toMoment(profile.stop).utc().add(offset, 'days');

    if (start.isSameOrAfter(endTime)) continue;
    if (stop.isAfter(endTime)) stop = endTime;

    profile.stop = stop.format();
    profile.start = start.format();
    profileList.push(profile);
  }
  tag.profileSetList = profileList;

  for (let i = 0; i < tag.segmentList.length; i++) {
    const segment = tag.segmentList[i];

    if (segment.physicalSegmentList !== null && segment.physicalSegmentList.length > 0) {
      for (let j = 0; j < segment.physicalSegmentList.length; j++) {
        const physicalSegment = segment.physicalSegmentList[j];
        if (physicalSegment.lossAccountingList !== null && physicalSegment.lossAccountingList.length > 0) {
          const lossAccountList = [];
          for (let t = 0; t < physicalSegment.lossAccountingList.length; t++) {
            const lossAccounting = physicalSegment.lossAccountingList[t];
            const start = dateStore.toMoment(lossAccounting.start).add(offset, 'days').utc();
            let stop = dateStore.toMoment(lossAccounting.stop).add(offset, 'days').utc();

            if (start.isSameOrAfter(endTime)) continue;
            if (stop.isAfter(endTime)) stop = endTime;

            lossAccounting.start = start.format();
            lossAccounting.stop = stop.format();
            lossAccountList.push(lossAccounting);
          }
          physicalSegment.lossAccountList = lossAccountList;
        }
      }
    }
  }
}

export async function resetSegmentIDs(api, tag) {
  const { segmentList } = tag;
  const messages = [];

  if (segmentList !== null && segmentList !== undefined) {
    for (let sx = 0; sx < segmentList.length; sx++) {
      const seg = segmentList[sx];

      // let pseList = await api.queryPSEs( {name: seg.pse, eq: true});

      let pseList = JSON.parse(window.localStorage.getItem(PSE_KEY));
      pseList = pseList.find((pse) => pse.label === seg.pse);

      if (pseList !== null && pseList !== undefined) {
        if (seg.pse === pseList.label) { messages.push(`MP (${seg.marketSegmentID}) - PSE ${seg.pse} updated to new id`); }

        seg.pseCode = null;
      } else {
        messages.push(`MP (${seg.marketSegmentID}) - PSE ${seg.pse} could not be found`);

        seg.pseCode = null;
        seg.pse = null;
      }

      const { physicalSegmentList } = seg;

      if (physicalSegmentList !== null && physicalSegmentList !== undefined && physicalSegmentList.length > 0) {
        for (let px = 0; px < physicalSegmentList.length; px++) {
          const phy = physicalSegmentList[px];

          if (sx === 0 && px === 0) { // generation
            // sourceList = await api.querySources( {pse: seg.pse, ba: tag.tagID.gca, name: phy.pod, eq: true} );

            let sourceList = JSON.parse(window.localStorage.getItem(SOURCE_KEY));
            sourceList = sourceList.find((source) => source.label === phy.pod);

            if (sourceList !== null && sourceList !== undefined) {
              if (phy.pod !== sourceList.label) { messages.push(`Source ${phy.pod} updated to new id`); }

              phy.podCode = null;
            } else {
              messages.push(`Source ${phy.pod} could not be found`);

              phy.podCode = null;
              phy.pod = null;
            }
          } else if (sx === (segmentList.length - 1) && px === (physicalSegmentList.length - 1)) { // load
            // let sinkList = await api.querySinks( {pse: seg.pse, ba: tag.tagID.lca, name: phy.por, eq: true} );

            let sinkList = JSON.parse(window.localStorage.getItem(SINK_KEY));
            sinkList = sinkList.find((sink) => sink.label === phy.por);

            if (sinkList !== null && sinkList !== undefined) {
              if (phy.por !== sinkList.label) { messages.push(`Sink ${phy.por} updated to new id`); }

              phy.porCode = null;
            } else {
              messages.push(`Sink ${phy.por} could not be found`);

              phy.porCode = null;
              phy.por = null;
            }
          } else if (phy.tpCode !== null && phy.tp !== null) {
            if (phy.schedulingEntityList !== null && phy.schedulingEntityList !== undefined && phy.schedulingEntityList.length > 0) {
              for (let x = 0; x < phy.schedulingEntityList.length; x++) {
                const se = phy.schedulingEntityList[x];

                // let baList = await api.queryBAs({name: se.entity, eq: true});

                let baList = JSON.parse(window.localStorage.getItem(BA_KEY));
                baList = baList.find((ba) => ba.label === se.entity);

                if (baList !== null && baList !== undefined) {
                  se.entityCode = null;
                  se.entity = baList.label;
                }
              }
            }

            // let tpList = await api.queryTPs({name: phy.tp, eq: true});
            let tpList = JSON.parse(window.localStorage.getItem(TP_KEY));
            tpList = tpList.find((tp) => tp.label === phy.tp);

            if (tpList !== null && tpList !== undefined) {
              if (phy.tp !== tpList.label) { messages.push(`PP (${phy.physicalSegmentID}) - TP ${phy.tp} updated to new id`); }

              phy.tpCode = null;
              phy.tp = tpList.label;
            } else {
              messages.push(`PP (${phy.physicalSegmentID}) - TP ${phy.tp} could not be found`);
              phy.tpCode = null;
              phy.tp = null;
            }

            // let porList = await api.queryPORs({name: phy.por, tsp: phy.tp, eq: true});
            // let porPodList = JSON.parse(window.localStorage.getItem(PORPOD_KEY));
            // let pointOfReciept = porPodList.find((por) => {return por.label === phy.por});
            phy.porCode = null;

            // if(pointOfReciept !== null && pointOfReciept !== undefined){

            //     if(phy.por !== pointOfReciept.label)
            //         messages.push(`PP (${phy.physicalSegmentID}) - POR ${phy.por} updated to new id`);

            //         console.log("Por");

            //     phy.porCode = null;
            //     phy.por = porList.label;
            // } else {

            //     messages.push(`PP (${phy.physicalSegmentID}) - POR ${phy.por} could not be found`);

            //     phy.porCode = null;
            //     phy.por = null;
            // }

            // let podList = await api.queryPODs({name: phy.pod, tsp: phy.tp, eq: true});
            // let pointOfDelivery = porPodList.find((pod) => {return pod.label === phy.por});
            phy.podCode = null;

            // if(pointOfDelivery !== null && pointOfDelivery !== undefined){
            //     if(phy.pod !== pointOfDelivery.label)
            //         messages.push(`PP (${phy.physicalSegmentID}) - POD ${phy.pod} updated to new id`);

            //     phy.podCode = null;
            //     phy.pod = podList.label;
            // } else {

            //     messages.push(`PP (${phy.physicalSegmentID}) - POD ${phy.pod} could not be found`);

            //     phy.podCode = null;
            //     phy.pod = null;
            // }

            const { transmissionAllocationList } = phy;

            if (transmissionAllocationList !== null && transmissionAllocationList !== undefined && transmissionAllocationList.length > 0) {
              for (let tx = 0; tx < transmissionAllocationList.length; tx++) {
                const tran = transmissionAllocationList[tx];

                // let pseList = await api.queryPSEs({name: tran.tc, eq: true});
                let pseList = JSON.parse(window.localStorage.getItem(PSE_KEY));
                pseList = pseList.find((pse) => pse.label === tran.tc);

                if (pseList !== null && pseList !== undefined) {
                  if (tran.tc !== pseList.label) { messages.push(`A (${tran.transmissionAllocationID}) - TC ${phy.tc} updated to new id`); }

                  tran.tcCode = 0;
                } else {
                  messages.push(`A (${tran.transmissionAllocationID}) - TC ${phy.tc} could not be found`);

                  tran.tcCode = null;
                  tran.tc = null;
                }
              }
            }
          }
        }
      }
    }
  }

  return messages;
}