
// Set property on state
/*
 * const mutations = {
 *    ...createMutations(stateProp1, stateProp2, ...stateProps);
 * };
 * action => commit('propName', propValue);
*/

export const createMutations = (...args) => args.reduce((acc, stateVariable) => {
  const pascalCase = stateVariable.charAt(0).toUpperCase() + stateVariable.slice(1);
  const mutation = `set${pascalCase}`;
  acc[mutation] = (state, value) => {
    state[stateVariable] = value;
  };
  return acc;
}, {});