import dateStore from '@/utils/dateStore';
import { ETAG_API } from '@/api';
import cellColors from '../util';

const state = {
  tableConfiguration: {
    tableName: 'tagSearch',
    columns: [{
      prop: 'toEntity', label: 'ToEntity', filterable: true, sortable: true,
    }, {
      prop: 'gca', label: 'gca', filterable: true, sortable: true,
    }, {
      prop: 'cpse', label: 'cpse', filterable: true, sortable: true,
    }, {
      cellTemplate: 'PscsRouteCellTemplate',
      editorOptions: {
        to: 'ViewTag',
        params: [{ key: 'tagId', dataKey: 'tagID' }, { key: 'entity', dataKey: 'toEntity' }],
        query: {    
          handler(data) {
            return {tagSummaryRef: data.id};
          },
        },
      },
      prop: 'tagCode',
      label: 'code',
      filterable: true,
      width: 80,
      sortable: true,
    }, {
      prop: 'lca', label: 'lca', filterable: true, sortable: true,
    }, {
      prop: 'source', label: 'source', filterable: true, sortable: true,
    }, {
      prop: 'sink', label: 'sink', filterable: true, sortable: true,
    }, {
      prop: 'gpe', label: 'gpe', filterable: true, sortable: true,
    }, {
      prop: 'lpe', label: 'lpe', filterable: true, sortable: true,
    }, {
      prop: 'implementationDateTime', label: 'start', filterable: true, sortable: true,
    }, {
      prop: 'terminationDateTime', label: 'end', filterable: true, sortable: true,
    }, {
      prop: 'compositeState',
      label: 'status',
      filterable: true,
      sortable: true,
      alignment: 'center',
      cellTemplate: 'PscsColoredCellTemplate',
      editorOptions: { ...cellColors },
    }, {
      prop: 'transactionType', label: 'type', filterable: true, sortable: true,
    }, {
      prop: 'currentPath', label: 'path', filterable: true, sortable: true,
    }, {
      prop: 'notes', label: 'notes', filterable: true, sortable: true,
    }],
    options: {
      excludeColumnExcel: ['tagID'],
      exportExcel: true,
      columnsMovable: true,
      filterRow: true,
      filterPanel: false,
      filterHeader: true,
      columnChooser: true,
    },
  },
  tableData: [],
  datePropsAndFormats: [
    { name: 'implementationDateTime', format: 'YYYY-MM-DD HH:mm' },
    { name: 'terminationDateTime', format: 'YYYY-MM-DD HH:mm' },
  ],
};

const getters = {
  getTableConfiguration: (state) => state.tableConfiguration,
  getTableData: (state) => state.tableData,
  getMinHeight: (state) => (state.tableData && state.tableData.length > 0) ? 600 : 100,
};

const actions = {
  async searchAction({ commit }, item) {
    const tz = dateStore.getTimeZone();
    let start = dateStore.toMoment('1900-01-01').startOf('day').utc();
    let end = dateStore.toMoment('2099-12-31').startOf('day').utc();

    if (item.dateRange.length !== 0) {
      const momentRange = dateStore.toMomentAndZoneFromJSDateArray(item.dateRange, tz);

      start = momentRange[0].startOf('day').utc();
      end = momentRange[1].startOf('day').add(1, 'days').utc();
    }

    const params = {
      timeZone: tz,
      start: start.format(),
      end: end.format(),
      inc_requests: false,
    };

    if (item.cpse && item.cpse.length > 0) params.cpse = item.cpse;
    if (item.grouping && item.grouping.length > 0) params.grouping = item.grouping;
    if (item.text && item.text.length > 0) params.text = item.text;
    if (item.gca && item.gca.length > 0) params.gca = item.gca;
    if (item.lca && item.lca.length > 0) params.lca = item.lca;
    if (item.source && item.source.length > 0) params.source = item.source;
    if (item.sink && item.sink.length > 0) params.sink = item.sink;
    if (item.notes && item.notes.length > 0) params.notes = item.notes;
    if (item?.path?.length > 0) params.path = item.path;

    try {
      const response = await ETAG_API.get('/tags', { params });
      if (response.status === 200) {
        commit('loadSearchResults', response.data.tagSummaries);
      }
    } catch (err) { }
  },
  clear({ commit }) {
    commit('clear');
  },
};

const mutations = {
  loadSearchResults(state, data) {
    dateStore.formatDateTimeFields(data, state.datePropsAndFormats);

    const format = dateStore.getZuluFormat();

    data.forEach((v) => {
      const rt = dateStore.toMomentFromStringWithFormat(v.actOnByTime, format);
      if (rt._i) {
        v.timeAgo = rt.clone().fromNow();
        v.formattedTimestamp = rt.clone().format('YYYY-MM-DD HH:mm:ss');
        v.actOnByTime = `${v.timeAgo} (${v.formattedTimestamp})`;
      }
      v.routeText = v.tagCode;
    });

    state.tableData = data;
  },
  clear(state) {
    state.tableData = [];
  },
  setConfig(state, value) {
    state.tableConfiguration = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};