import { STRUCTURES_API } from '@/api';
import { clone } from '@/utils/dataUtil';
import utils from '@/utils';

const state = {
  notificationCallback: null,
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  tableConfig: {
    columns: [{
      prop: 'name', label: 'Name', sortable: true,
    }, {
      prop: 'description', label: 'Description', sortable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true,
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true,
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime',
    }],
  },
  nullTableRow: {
    id: -999,
    name: null,
    description: null,
    activeFlag: true,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentRow: (state) => state.currentTableRow,
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  notify({ state }, msg) {
    state.notificationCallback(msg);
  },
  reset({ commit, dispatch }) {
    commit('setNotificationCallback', null);
    commit('reset');
  },
  resetTable({ dispatch, commit, state }) {
    commit('resetTable');
  },
  loadTableData({ dispatch, commit, state }) {
    STRUCTURES_API.get('/contract-state-types').then(({ data }) => {
      commit('loadTableData', data.contractStates);
      this.$notify(`${data.contractStates.length} Contract State Type(s) Loaded`);
    }).catch((err) => {
      this.$notify('Failed to Load Contract State Types', 'error');
    });
  },
  createTableRow({ commit }) {
    commit('createTableRow');
  },
  postContractStateType({ dispatch, commit, state }, contractStateType) {
    STRUCTURES_API.post('/contract-state-types', contractStateType).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Contract State Type Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Contract State Type', 'error');
    });
  },
  updateContractStateType({ dispatch, commit, state }, contractStateType) {
    STRUCTURES_API.put(`/contract-state-types/${contractStateType.id}`, contractStateType).then(({ data }) => {
      this.$notify('Contract State Type Updated', 'success');
      commit('updateContractStateType', data);
    }).catch((err) => {
      this.$notify('Failed to Update Contract State Type', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    STRUCTURES_API.delete(`/contract-state-types/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Contract State Type Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Contract State Type', 'error');
    });
  },
  currentTableRowChange({ commit }, currentRow) {
    commit('currentTableRowChange', currentRow);
  },
};

const mutations = {
  updateContractStateType(state, contractStateType) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === contractStateType.id);
    state.tableData.splice(rowIndex, 1, contractStateType);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, contractStateTypes) {
    state.tableData = contractStateTypes;
  },
  createTableRow(state) {
    const contractStateType = clone(state.nullTableRow);
    contractStateType.id = state.tableKey++;
    contractStateType.createdBy = this.getters['auth/getProfile'].userName;
    contractStateType.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = contractStateType;
  },
  insertTableRow(state, contractStateType) {
    state.tableData.unshift(contractStateType);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((contractStateType) => contractStateType.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};