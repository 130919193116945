import moment from 'moment';
import { createMutations } from '@/utils/vuexHelper';
import {
  BIDDING_API, SCRIPTING_API, IDENTITY_API, STRUCTURES_API,
} from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { has, HEColumns, cloneDeep } from '@/utils/dataUtil';
import REFERENCE_DATA_STORE from '../../referenceDataStore';
import { EMPTY_HOURLY_VALUE } from '../static';
import detailConfigs from '../../ConsolidatedSchedule/store/static/detailConfigs';
import { mergeTradesAndTradeSchedules, tableModelToScheduleModel } from '../../Trades/Submission/store/util';

const state = {
  // page params
  commodity: undefined,
  entityType: undefined,
  market: '',
  tz: 'PPT',
  
  executions: [],
  
  tableData: [],
  selectedExecutions: [],
  marketType: caisoStore.getMarketType(),
  startDate: dateStore.getDefaultDate().toISOString(),
  
  moduleName: 'sibrSchedules',
  scs: [],
  selectedSc: [],
  selectedLocationGroupId: undefined,
  selectedLocationGroup: undefined,

  detailPreviewData: [],
  detailPreviewConfig: detailConfigs.sibr,
};

const _getList = (options, key) => options.map((opt) => ({ value: opt[key], label: opt[key] }));

const getters = {
  scList: (state) => _getList(state.scs, 'name'),
};

const actions = {
  async init({ dispatch }) {
    await dispatch('fetchScs');
    await dispatch('fetchReferenceData');
    dispatch('defaultUserSettings');
  },
  defaultUserSettings({ state, commit, dispatch }) {
    const userLocationGroup = state.REFERENCE_DATA_STORE.locationGroupList
      .find(({ shortName }) => shortName === caisoStore.resourceGroupName);
    if (userLocationGroup) {
      commit('setSelectedLocationGroupId', userLocationGroup.id);
      commit('setSelectedLocationGroup', userLocationGroup.shortName);
      dispatch('fetchLocationGroupLocations', userLocationGroup.id);
    }

    const userSc = state.scs.find(({ name }) => name === caisoStore.coordinator);
    const selectedSc = userSc ? userSc.name : state.scs?.[0]?.name;
    commit('setSelectedSc', [selectedSc]);
  },

  setPageParams({ commit }, {
    commodity,
    entityType,
    market,
    moduleName,
  }) {
    commit('setCommodity', commodity);
    commit('setEntityType', entityType);
    commit('setMarket', market);
    commit('setModuleName', moduleName);
  },
  
  async fetchExecutions({ state, commit }) {
    const {
      commodity, entityType, market, marketType, location, moduleName, startDate, selectedSc, selectedLocationGroup,
    } = state;

    const reqBody = {
      commodity,
      market,
      entityType,
      marketType,
      locationGroup: selectedLocationGroup,
      startDate: moment(startDate).startOf('day').utc().toISOString(),
      // endDate: moment(startDate).add(1, 'days').format('MM/DD/YYYY'),
      module: moduleName,
      scs: selectedSc.length ? selectedSc.join() : null,
    };
    try {
      const { data } = await BIDDING_API.get('/executions', { params: reqBody });
      
      data.forEach(e => {
        e.strategies = e.executionResults?.map((result) => `${result.scriptCategory}.${result.script}`).join(', ');
        e.location = e.location || e.output?.location || e.output?.tradeSchedules?.[0]?.location;
        e.locationType = e.output?.resourceType;
      });
      
      commit('setTableData', data);
    } catch (error) {
      console.error('Error Fetching Executions', error);
    }
  },
  async fetchDetails({ commit, state }, execution) {
    const {
      module,
      marketType,
      location,
      output,
      status,
    } = execution;
    
    const configType = module === 'sibrSchedules' ? 'sibr' : 'ist'; 
    const config = detailConfigs[configType];
    let columns = HEColumns({}, true, state.startDate);
    switch (configType) {
    // case 'base': {
    //   const baseDetails = await CISO_SCHD_API.get(`base/${scheduleName}`, { params: { variant } });
    //   baseDetails.data.baseSchedulePoints.forEach((heData) => {
    //     const columnIndex = columns.indexOf(columns.find((he) => he.he === heData.he.toString()));
    //     columns[columnIndex] = heData;
    //   });
    //   break;
    // }
    case 'sibr': {
    const sibrSchedules = output;
      sibrSchedules?.bidSchedules?.forEach((heData) => {
        const columnIndex = columns.indexOf(columns.find((he) => he.he === heData.he.toString()));
        columns[columnIndex] = heData;
      });
      sibrSchedules?.selfSchedules?.forEach((heData) => {
        const columnIndex = columns.indexOf(columns.find((he) => he.he === heData.he.toString()));
        columns[columnIndex].ss = heData.pt;
      });
      break;
    }
    // case 'virtual': {
    //   const cbDetails = await CISO_SCHD_API.get(`virtual/${scheduleName}?variant=${ variant }`);
    //   cbDetails.data.bidSchedules.forEach((heData) => {
    //     const columnIndex = columns.indexOf(columns.find((he) => he.he === heData.he.toString()));
    //     columns[columnIndex] = heData;
    //   });
    //   break;
    // }
    case 'ist': {
      const istDetails = output?.tradeSchedules;

      // const tradeDetails = await CISO_SCHD_API.get('ist', { params: { date: state.selectedDate, marketType } });
      const tradeDetail = istDetails; //tradeDetails.data.trades.filter(({ name }) => name === resource);
      const heColumns = HEColumns().map(({ prop }) => prop);

      const [ist_schedule] = mergeTradesAndTradeSchedules(
        tradeDetail,
        istDetails,
        [],
        state.startDate,
      );
      
      columns = Object.keys(ist_schedule).filter((key) => heColumns.includes(key)).map((he) => ({
        he,
        heVal: parseInt(he.replace('he', ''), 10),
        counterQuantity: ist_schedule[he].counterQuantity,
        adjustedQuantity: ist_schedule[he].adjustedQuantity,
        quantity: ist_schedule[`${he}_val`],
      }));
      break;
    }
    default: break;
    }

    commit('setDetailPreviewData', columns);
    commit('setDetailPreviewConfig', config);
  },
  async fetchScs({ commit }) {
    try {
      const { data: { entities } } = await IDENTITY_API.get('entities');
      commit('setScs', entities.filter((x) => x.type === 'SC'));
    } catch (error) {
      this.$notify('Failed to fetch SCs', 'error');
      console.error(error);
    }
  },
  async fetchLocationGroupLocations({ commit }, groupId) {
    const { data } = await STRUCTURES_API.get(`/location-groups/${groupId}/locations`);
    const lgl = Array.isArray(data.locationGroupLocations)
      ? data.locationGroupLocations.filter((location) => location.marketName === 'CAISO')
      : [];
    commit('setLocationGroupLocations', lgl);
  },
  async fetchReferenceData({ state, dispatch, commit }) {
    const payload = {
      referenceItemList: ['fetchLocationGroupList'],
      market: 'CAISO',
      commodity: 'POWER',
    };
    await dispatch('REFERENCE_DATA_STORE/initializeReferenceData', payload);
    // commit('setLocationGroups', state.REFERENCE_DATA_STORE.locationGroupList);
  },
  async acceptSchedules({ state, dispatch }) {
    
    state.selectedExecutions.forEach(async execution => {
      try {
        if (execution.status === 'READY') {
          await BIDDING_API.put(`/executions/status/${execution.id}/accepted`);
          this.$notify(`Successfully Saved Schedule for Resource ${execution.location}, Execution ${execution.id}`);
        }
      } catch (error) {
        console.error('Error Saving Schedules', error);
      }
    });
    
    await dispatch('fetchExecutions');
  },
};

const mutations = {
  ...createMutations(
    'startDate',
    'tableData',
    'selectedExecutions',
    'marketType',
    'location',
    'commodity',
    'entityType',
    'market',
    'moduleName',
    'scs',
    'selectedSc',
    'selectedLocationGroupId',
    'selectedLocationGroup',
    'locationGroupLocations',
    'detailPreviewData',
    'detailPreviewConfig',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};
