import moment from 'moment';
import { CISO_SAMC_API } from '@/api';
import { getMomentDateString } from '@/utils/dateUtil';

const state = {
  tableData: [],
};

const actions = {
  async fetchConfigFile({ commit }, parameters = {}) {
    const params = {
      confImportId: parameters.configurationFileHeadersSelected.confImportId,
      confSource: parameters.configurationFileHeadersSelected.confSource,
      timeDate: moment(parameters.configurationFileHeadersSelected.timeDate).toISOString(),
      chargeType: parameters.chargeCodesWithPreCalcSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/configuration-file', { params });
      data.forEach((row) => {
        row.effectiveDate = getMomentDateString(row.effectiveDate);
        row.terminationDate = getMomentDateString(row.terminationDate);

        row.components.forEach((comp) => {
          comp.effectiveDate = getMomentDateString(comp.effectiveDate);
          comp.terminationDate = getMomentDateString(comp.terminationDate);

          comp.billDeterminants.forEach((bd) => {
            bd.effectiveDate = getMomentDateString(bd.effectiveDate);
            bd.terminationDate = getMomentDateString(bd.terminationDate);
          });
        });
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to Load Configuration File Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};