import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import config from './settlementCycleConfig';

const state = {
  tableData: [],
  tableConfig: config.pivotGridFieldGroups.defaultFields,
};

const actions = {
  async fetchSettlementCycleSummary({ commit }, {
    coordinatorListSelected, chargeCodeListSelected, resourceListSelected, tradeDateRangeSelected, runTypeSelected, granularitySelected, dateTypeSelected, latestCycleSelected,
  }) {
    const params = {
      scs: coordinatorListSelected  ? coordinatorListSelected.join(',') : null ,
      chargeCode: chargeCodeListSelected ? chargeCodeListSelected.join(',') : null,
      startDate: tradeDateRangeSelected[0].toISOString(),
      endDate: tradeDateRangeSelected[1].toISOString(),
      type: runTypeSelected,
      granularity: granularitySelected,
      resource: resourceListSelected ? resourceListSelected.join(',') : null,
      dateType: dateTypeSelected,
      latestCycle: latestCycleSelected,
    };
    try {
      const { data: { data } } = await CISO_SAMC_API.get('/settlement-cycle-summary', { params });
      data.forEach((m) => {
        m.tzTradingDate = getMomentDateString(m.tzTradingDate);
      });
      commit('setTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load settlement cycle summary data', 'error');
    }
  },
};

const mutations = {
  reset(state) {
    state.tableData = [];
  },
  setTableData(state, data) {
    state.tableData = Object.freeze(data);
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};