import { gridConfig } from '@/components/Ciso/BaseStore/CisoConfigs';

const disputeDetails = {
  name: 'disputeDetails',
  columns: [{
    label: 'DISPUTE CHILD #',
    prop: 'isodisputeId',
    filterable: true,
    sortable: true,
    width: 150,
  }, {
    label: 'DISPUTE #',
    prop: 'disputeId',
    filterable: true,
    sortable: true,
    width: 150,
  }, {
    label: 'BA',
    prop: 'ba',
    filterable: true,
    sortable: true,
    width: 75,
  }, {
    label: 'TRADING DATE',
    prop: 'tradingDate',
    format: 'yyyy/MM/dd',
    dataType: 'date',
    filterable: true,
    sortable: true,
    width: 125,
  }, {
    label: 'TRADING HOUR',
    prop: 'tradingHour',
    filterable: true,
    sortable: true,
    width: 150,
  }, {
    label: 'TRADING INTERVAL',
    prop: 'tradingInterval',
    filterable: true,
    sortable: true,
    width: 150,
  }, {
    label: 'CHARGE CODE',
    prop: 'chargeCode',
    filterable: true,
    sortable: true,
    width: 150,
  }, {
    label: 'RESOURCE ID',
    prop: 'rsrcId',
    filterable: true,
    sortable: true,
  }, {
    label: 'RESOURCE TYPE',
    prop: 'rsrcType',
    filterable: true,
    sortable: true,
  }, {
    label: 'AMOUNT',
    prop: 'amount',
    filterable: true,
    sortable: true,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
  }],
  options: {
    exportExcel: false,
    filterRow: false,
    filterHeader: true,
    columnsMovable: false,
  },
};

const editConfig = {
  name: 'disputesFormConfig',
  columns: [{
    cellTemplate: 'PscsOpenMasterDetailCellTemplate',
    editorOptions: {
      masterDetailTemplate: 'PscsMasterDetailListTableTemplate',
      multipleSelect: true,
      tableName: 'Dispute Details',
      key: 'details',
      config: disputeDetails,
      height: 'calc(15vh)',
    },
    label: 'DISPUTE #',
    prop: 'disputeId',
    sortable: false,
    visible: true,
    editable: false,
  }, {
    label: 'ISO DISPUTE #',
    prop: 'isodisputeID',
    sortable: true,
    visible: true,
    width: 125,
  }, {
    label: 'BA',
    prop: 'ba',
    sortable: true,
    visible: true,
    editable: false,
    width: 75,
  }, {
    label: 'TRADING DATE',
    prop: 'tradingDate',
    format: 'yyyy/MM/dd',
    dataType: 'date',
    sortable: true,
    visible: true,
    editable: false,
  }, {
    label: 'CAISO AMOUNT',
    prop: 'caisoAmount',
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'CALC AMOUNT',
    prop: 'calcAmount',
    sortable: true,
    visible: true,
    editable: false,
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'DISPUTE AMOUNT',
    prop: 'disputeAmount',
    sortable: true,
    visible: true,
    editorOptions: {
      format: '$#,##0.00',
    },
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 150,
  }, {
    label: 'CAISO APPROVED AMOUNT',
    prop: 'caisoApprovedAmount',
    sortable: true,
    visible: true,
    editorOptions: {
      format: '$#,##0.00',
    },
    format(value) {
      if (value === null || (parseFloat(value, 10) < 0.01 && parseFloat(value, 10) > -0.01)) { return null; }
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      return formatter.format(value);
    },
    width: 175,
  }, {
    label: 'AWARD DATE',
    prop: 'awardDate',
    sortable: true,
    visible: true,
    dataType: 'date',
    format: 'yyyy/MM/dd',
    width: 150,
  }, {
    label: 'DISPUTE STATUS',
    prop: 'disputeStatus',
    sortable: true,
    visible: true,
    width: 150,
  }, {
    label: 'CASE REASON',
    prop: 'caseReason',
    sortable: true,
    visible: true,
    width: 150,
  }, {
    label: 'ORIGINATING GROUP',
    prop: 'originatingGroup',
    sortable: true,
    visible: true,
    width: 175,
  }, {
    label: 'PUBLISHED DATE',
    prop: 'publishedDate',
    sortable: true,
    visible: true,
    width: 150,
    dataType: 'date',
    format: 'yyyy/MM/dd',
    type: {
      name: 'PscsDateTimeCellRenderer',
    },
  }, {
    label: 'ADJUSTMENT PUBLICATION DATE',
    prop: 'adjustmentPublicationDate',
    sortable: true,
    visible: true,
    width: 150,
    dataType: 'date',
    format: 'yyyy/MM/dd',
    type: {
      name: 'PscsDateTimeCellRenderer',
    },
  }, {
    label: 'CREATED BY',
    prop: 'createdBy',
    filterable: true,
    sortable: true,
    visible: true,
    width: 100,
  }],
  options: {
    exportExcel: false,
    filterHeader: true,
    filterRow: false,
    columnConfig: true,
    filterOptions: true,
  },
};

export default {
  editConfig,
};