export default {
  columns: [{
    prop: 'loss_type',
    label: 'type',
    editable: true,
    type: {
      name: 'PscsSelectCellRenderer', placeholder: 'Type', list: 'types', remote: true, clearable: true,
    },
  }, {
    prop: 'loss_start', label: 'start', editable: true, type: { name: 'PscsDateTimeCellRenderer', placeholder: 'Start' },
  }, {
    prop: 'loss_stop', label: 'stop', editable: true, type: { name: 'PscsDateTimeCellRenderer', placeholder: 'Stop' },
  }, {
    prop: 'loss_tagCount', label: 't', width: 40, type: { name: 'PscsTagIDListCellLinkRenderer', overrideReadonly: true },
  }, {
    prop: 'loss_contractCount', label: 'c', width: 40, type: { name: 'PscsTagCreateContractCellLinkRenderer', overrideReadonly: true },
  }],
  columnList(name) {
    if (name === 'types') return this.lossTypes;
  },
  registry: {},
  lossTypes: [],
};
