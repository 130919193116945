import moment from 'moment';
import dateStore from '@/utils/dateStore';
import dateUtil from '@/utils/dateUtil';
import utils from '@/utils';
import { clone, handleError } from '@/utils/dataUtil';
import { STRUCTURES_API } from '@/api';
import PRICE_INTERVALS_STORE from './priceIntervalsStore';

const state = {
  tableData: [],
  priceIndexList: [],
  currentTableRow: null,
  isFormulaValid: true,
  tableConfig: {
    columns: [{
      prop: 'id', label: 'Id', sortable: true, filterable: true, alignment: 'right',
    }, {
      prop: 'name', label: 'Name', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'description', label: 'Description', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'externalName', label: 'External Name', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'type', label: 'Type', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'product', label: 'Product', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'commodity', label: 'Commodity', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'pointOfReceipt', label: 'POR', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'pointOfReceiptDefaultFlag', label: 'Def. POR Idx', filterable: true, sortable: false,
    }, {
      prop: 'pointOfDelivery', label: 'POD', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'pointOfDeliveryDefaultFlag', label: 'Def. POD Idx', sortable: false, filterable: true,
    }, {
      prop: 'formula', label: 'Formula', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'marketType', label: 'Market Type', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'market', label: 'Market', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'termType', label: 'Term Type', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'termInterval', label: 'Term Int', sortable: true, filterable: true,
    }, {
      prop: 'effectiveDate', label: 'Effective Date', sortable: true, dataType: 'date', filterable: true, alignment: 'left',
    }, {
      prop: 'terminationDate', label: 'Termination Date', sortable: true, dataType: 'date', filterable: true, alignment: 'left',
    }, {
      prop: 'carryForwardFlag', label: 'Carry Forward', sortable: false, filterable: true,
    }, {
      prop: 'createdBy', label: 'Created By', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate', label: 'Created Date', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', sortable: true, filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate', label: 'Updated Date', sortable: true, dataType: 'datetime', filterable: true, alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
      exportExcel: true,
    },
  },
  nullTableRow: {
    id: -999,
    name: null,
    description: null,
    externalName: null,
    type: null,
    product: null,
    commodity: null,
    pointOfReceipt: null,
    pointOfReceiptDefaultFlag: false,
    pointOfDelivery: null,
    pointOfDeliveryDefaultFlag: false,
    formula: null,
    marketType: null,
    market: null,
    termType: null,
    termInterval: null,
    effectiveDate: null,
    terminationDate: null,
    carryForwardFlag: false,
  },

  dialogMode: '', // ADD or EDIT

  ...PRICE_INTERVALS_STORE.state,
};

const getters = {
  getPrices: (state) => state.prices,
  getCommodityList: (state, localGetters, rootState) => rootState?.lookup?.energyCommodityList,
  getLocationList: (state, localGetters, rootState) => rootState?.lookup?.locationList,
  getTermTypeList: (state, localGetters, rootState) => rootState?.lookup?.termTypeList,

  getTableConfig: (state) => state.tableConfig,
  getTableData: (state) => state.tableData,
  getCurrentTableRow: (state) => state.currentTableRow,

  ...PRICE_INTERVALS_STORE.getters,
};

const actions = {
  initialize({ dispatch }) {
    dispatch('lookup/fetchEnergyCommodityList', null, { root: true });
    dispatch('lookup/fetchLocationList', null, { root: true });
    dispatch('lookup/fetchTermTypeList', null, { root: true });
    dispatch('loadTableData');
  },
  async loadPrices({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/prices');
      commit('setPriceData', data.data);
    } catch (error) {
      this.$notify('Failed to Load Prices', 'error');
    }
  },
  async loadTableData({ commit }) {
    try {
      const { data } = await STRUCTURES_API.get('/prices');
      const priceIndexList = data.data.map(({ name }) => name);

      // fix dates
      data.data.forEach((price) => {
        const start = dateStore.toLocalStartOfDayFromDate(price.effectiveDate);
        const end = dateStore.toLocalStartOfDayFromDate(price.terminationDate);

        price.effectiveDate = start.toISOString();
        price.terminationDate = end.toISOString();
      });

      commit('loadTableData', data.data);
      commit('setPriceIndexList', priceIndexList);
    } catch (error) {
      this.$notify('Failed to Load Prices', 'error');
    }
  },
  async addPrice({ dispatch, commit, state }, price) {
    await STRUCTURES_API.post('/prices', price).then(({ data }) => {
      commit('addToTableData', data);
      dispatch('currentTableRowChange', clone(data));
      this.$notify('Price Added', 'success');
    }).catch((err) => {
      this.$notify(`Failed to Add Price: ${err}`, 'error');
    });
  },
  async updatePrice({ commit, dispatch }, price) {
    await STRUCTURES_API.put(`/prices/${price.id}`, price).then(({ data }) => {
      commit('updateTableData', data);
      dispatch('currentTableRowChange', clone(data));
      this.$notify('Price Updated', 'success');
    }).catch((err) => {
      this.$notify(`Failed to Update Price: ${err}`, 'error');
    });
  },
  async inactivatePrice({ dispatch, commit }, id) {
    await STRUCTURES_API.delete(`/prices/${id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Price Removed', 'success');
    }).catch((err) => {
      this.$notify(`Failed to Remove Price: ${err}`, 'error');
    });
  },
  async importPrices({ dispatch, commit, state }, price) {
    await STRUCTURES_API.post('/prices/imports', price).then(({ data }) => {
      this.$notify(`Price import submitted: ${data.message}`, 'success');
    }).catch((err) => {
      this.$notify(`Failed to submit Price Import: ${err}`, 'error');
    });
  },
  async calculatePrices({ dispatch, commit, state }, price) {
    await STRUCTURES_API.patch('/prices', price).then(({ data }) => {
      this.$notify(`Price calculation submitted: ${data.message}`, 'success');
    }).catch((err) => {
      this.$notify(`Failed to submit Price calculations: ${err}`, 'error');
    });
  },
  currentTableRowChange({ dispatch, commit, rootState }, currentRow) {
    commit('currentTableRowChange', currentRow);
    commit('setIntervalsDateRange', [utils.date.getTodayLocal(), currentRow.terminationDate]);
    dispatch('fetchPriceIntervals');
  },

  async validateFormula({ commit }, formula) {
    await STRUCTURES_API.put('/prices/validate-formula', { formula }).then(({ data }) => {
      if (data.isValid) this.$notify(data.message, 'success');
      else this.$notify(data.message, 'error');
      commit('setIsFormulaValid', data.isValid);
    }).catch((error) => {
      handleError(error, 'Failed to validate formula');
    });
  },

  ...PRICE_INTERVALS_STORE.actions,
};

const mutations = {
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  setPriceData(state, data) {
    state.tableData = data || [];
  },
  loadTableData(state, data) {
    state.tableData = data || [];
  },
  addToTableData(state, data) {
    state.tableData.push(data);
  },
  updateTableData(state, data) {
    const index = state.tableData.findIndex(({ id }) => id === data.id);
    if (index !== -1) state.tableData.splice(index, 1, data);
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((price) => price.id !== state.currentTableRow.id);
  },
  reset(state) {
    state.currentTableRow = null;
    state.tableData = [];
  },
  setDialogMode(state, mode) {
    state.dialogMode = mode;
  },
  setIsFormulaValid(state, isValid) {
    state.isFormulaValid = isValid;
  },
  setPriceIndexList(state, data) {
    state.priceIndexList = data;
  },

  ...PRICE_INTERVALS_STORE.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};