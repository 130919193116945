import utils from '@/utils';
import { clone } from '@/utils/dataUtil';
import { PPA_REF_API } from '@/api';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  nullTableRow: {
    id: -999,
    counterParty_Id: null,
    name: null,
    address: null,
    city: null,
    stateOrProvince: null,
    postalCode: null,
    bankABA: null,
    accountName: null,
    accountNumber: null,
    routingNumber: null,
    swift: null,
    ref: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  initialize({ commit, dispatch, state }) {
    dispatch('loadTableData');
  },
  async loadTableData({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/banks');
      commit('loadTableData', data);
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Load Data', 'error');
    }
  },
  postRecord({ state }, Bank) {
    PPA_REF_API.post('/bank', Bank).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  updateRecord({ dispatch, commit, state }, Bank) {
    PPA_REF_API.put(`/bank/${Bank.id}`, Bank).then(({ data }) => {
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Data', 'error');
    });
  },
  deleteTableRow({ dispatch, commit, state }) {
    PPA_REF_API.delete(`/bank/${state.currentTableRow.id}`).then(({ data }) => {
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    }).catch((err) => {
      this.$notify('Failed to Remove Data', 'error');
    });
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  resetTable(state) {
    state.tableData = [];
    state.currentTableRow = clone(state.nullTableRow);
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },

  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};