import moment from 'moment';
import { createMutations } from '@/utils/vuexHelper';
import { BIDDING_API, SCRIPTING_API } from '@/api';
import dateStore from '@/utils/dateStore';
import caisoStore from '@/utils/caiso/caisoUtils';
import { HERows } from '@/utils/dataUtil';
import REFERENCE_DATA_STORE from '../../referenceDataStore';
import { EMPTY_HOURLY_VALUE, HOURLY_VALUES_CONFIG } from '../static';

const state = {
  startDate: dateStore.getDefaultDate().toISOString(),
  hourlyType: undefined,
  hourlyValuesData: [],
  variableDialogVisibility: false,
  dailyGlobalVariablesData: [],
  hourlyValuesConfig: undefined,
  auditData: [],
  commodity: '',
  market: '',
  entityType: '',
  moduleName: null,
  scriptCategories: [],
  scripts: [],
};

const getters = {
  scriptList: (state) => {
    const scriptCategory = state.scriptCategories
      .find(({ name }) => name === `${ state.market.toLowerCase() }-${ state.moduleName.toLowerCase() }-strategies`);
    const scripts = (scriptCategory) ? state.scripts.filter(({ categoryId }) => categoryId === scriptCategory.id) : [];
    return [...new Set(scripts.map(({ name }) => name))];
  },
};

const actions = {
  async init({ dispatch }) {
    await dispatch('fetchScripts');
    await dispatch('fetchStrategyVariables');
    await dispatch('fetchScriptCategories');
  },
  setPageParams({ commit }, {
    commodity,
    entityType,
    market,
    moduleName,
  }) {
    commit('setCommodity', commodity);
    commit('setEntityType', entityType);
    commit('setMarket', market);
    commit('setModuleName', moduleName);
  },
  async fetchScripts({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get();
      commit('setScripts', data.data.map((props) => props));
    } catch (error) {
      console.error('Error Fetching Strategies', error);
    }
  },
  async createVariable({ state, dispatch }, { variableData, type, strategy }) {
    let resp;
    const reqBody = {
      commodity: state.commodity,
      market: state.market,
      entityType: state.entityType,
      module: state.moduleName,
      marketType: variableData.marketType,
      location: variableData.location,
      variables: [{
        ...variableData,
        startTime: state.startDate,
        endTime: null,
        type,
        scriptName: strategy,
      }],
      tradingDate: state.startDate,
      tz: dateStore.getTimeZone(),
    };

    if (strategy) {
      try {
        resp = await BIDDING_API.post(`/variables/${strategy}`, reqBody);
      } catch (error) {
        console.error('Error Posting Strategy Variable', error);
      }
    } else {
      try {
        resp = await BIDDING_API.post('/variables', reqBody);
      } catch (error) {
        console.error('Error Posting Global Variable', error);
      }
    }
    await dispatch('fetchStrategyVariables');
    return resp.data;
  },
  async updateVariable({ state, dispatch }, {
    variableData, type, strategy, marketType, location, visualOrder,
  }) {
    let variables;
    if (type === 'DAILY') {
      variables = [{
        ...variableData,
        startTime: state.startDate,
        endTime: null,
        type,
        scriptName: strategy,
      }];
    } else {
      const config = variableData || state.hourlyValuesConfig;
      const { id, name, valueType } = config;
      const formattedValuesData = [];
      marketType = state.hourlyValuesConfig?.marketType;
      strategy = strategy || state.hourlyValuesConfig?.strategies?.toString();

      const indexMap = HOURLY_VALUES_CONFIG(state.startDate).columns.map((x) => x.prop);

      indexMap.forEach((x) => {
        if (x !== 'id') {
          formattedValuesData.push({
            he: x.replace('he', ''),
            value: state.hourlyValuesData[0][x],
          });
        }
      });
      variables = formattedValuesData.map(({ he, value }, index) => ({
        id,
        name,
        type,
        value,
        valueType,
        startTime: moment(state.startDate).add(index, 'hours').toISOString(),
        endTime: moment(state.startDate).add(index + 1, 'hours').toISOString(),
        scriptName: strategy,
        marketType,
        location,
        visualOrder,
      }),
      );
    }
    const marketTypeParsed = marketType || null;
    const reqBody = {
      commodity: state.commodity,
      market: state.market,
      entityType: state.entityType,
      module: state.moduleName,
      marketType: marketTypeParsed,
      location,
      variables,
      tradingDate: state.startDate,
      tz: dateStore.getTimeZone(),
    };
    if (strategy) {
      try {
        await BIDDING_API.post(`/variables/${strategy}`, reqBody);
      } catch (error) {
        console.error('Error Posting Strategy Variable', error);
      }
    } else {
      try {
        await BIDDING_API.post('/variables', reqBody);
      } catch (error) {
        console.error('Error Posting Global Variable', error);
      }
    }
    dispatch('fetchStrategyVariables');
  },
  async deleteVariable({ state, commit }, id) {
    try {
      await BIDDING_API.delete(`/variables/${id}`);
      const hourlyValues = HERows({}, true, state.startDate).map(({ he }) => ({ value: undefined, he }));
      commit('setHourlyValuesData', hourlyValues);
    } catch (error) {
      console.error('Error Deleting Variable', error);
    }
  },
  async fetchStrategyVariables({ state, dispatch }, params = { strategy: undefined }) {
    const {
      commodity, entityType, market, moduleName, startDate,
    } = state;
    const reqBody = {
      commodity,
      market,
      entityType,
      module: moduleName,
      tradingDate: startDate,
      tz: dateStore.getTimeZone(),
    };
    if (params.strategy) {
      try {
        const hourlyData = await BIDDING_API.get(
          `/variables/${ params.strategy }`,
          { params: { ...reqBody, type: 'HOURLY' } },
        );
        const dailyData = await BIDDING_API.get(
          `/variables/${ params.strategy }`,
          { params: { ...reqBody, type: 'DAILY' } },
        );
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData, strategy: params.strategy });
      } catch (error) {
        console.error('Error Fetching Hourly Strategy Variables', error);
      }
    } else {
      try {
        const hourlyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'HOURLY' } });
        const dailyData = await BIDDING_API.get('/variables', { params: { ...reqBody, type: 'DAILY' } });
        const combinedData = { hourly: hourlyData.data, daily: dailyData.data };
        dispatch('mapVariables', { combinedData });
      } catch (error) {
        console.error(`Error Fetching ${params.type} Variables`, error);
      }
    }
  },
  updateHourlyValuesData({ state, commit }, { id, variableTypeChanged }) {
    const hourlyValues = [{ id }];
    const iteratingOverMap = state.dailyGlobalVariablesData;
    if (iteratingOverMap.length < 1) return;
    let hourlyData = {};
    iteratingOverMap.forEach((object) => {
      if (object.id === id) {
        hourlyData = object;
      }
    });
    if (variableTypeChanged) {
      [...Array(24).keys()].forEach((x) => { hourlyValues[0][`he${ x + 1}`] = hourlyData.value; });
      hourlyData.config = {
        id,
        name: hourlyData.name,
        valueType: hourlyData.valueType,
        marketType: hourlyData.marketType,
        location: hourlyData.location,
      };
    } else {
      hourlyData.values.forEach((data) => { hourlyValues[0][`he${ data.he}`] = data.value; });
    }

    commit('setHourlyType', 'GLOBAL');
    commit('setHourlyValuesData', hourlyValues);
    commit('setHourlyValuesConfig', hourlyData.config);
  },
  async fetchAuditData({ commit }, { selectedRecord }) {
    const { id, variableType } = selectedRecord;
    let mappedData = [];
    try {
      const params = {
        tz: dateStore.getTimeZone(),
      };
      const { data } = await BIDDING_API.get(`/variables/${id}/revisions`, { params });
      if (data) {
        if (variableType === 'HOURLY') {
          data.forEach((variableData) => {
            variableData.hourlyValues.forEach((hourlyValue) => {
              // he01, 02 10
              variableData[`he${hourlyValue.hourEnding}`] = hourlyValue.value;
            });
          });
        }
        mappedData = data;

        mappedData.forEach(d => {
          d.createdDate = moment(d.createdDate).format('YYYY-MM-DD hh:mm:ss A');
          d.effectiveDate =  moment(d.effectiveDate).format('YYYY-MM-DD');
        })
      }
    } catch (ex) {
      mappedData = [];
      this.$notify('Failed to load audit logs for Variables', 'error');
    } finally {
      commit('setAuditData', mappedData);
    }
  },
  async mapVariables({ dispatch, state, commit }, { combinedData, type, strategy }) {
    let tableData = [];
    let map = [];
    let variables = combinedData?.daily?.variables || [];
    if (variables) {
      tableData = variables.map(({
        name, valueType, visualOrder, value, id, marketType, location, scriptName,
      }) => ({
        name,
        valueType,
        value,
        id,
        marketType,
        location,
        visualOrder,
        variableType: 'DAILY',
        strategies: scriptName?.split(',') || [],
      }));
    }
    variables = combinedData?.hourly?.variables || [];
    if (variables) {
      map = variables.reduce((acc, curr) => {
        let currHe = moment(curr.startTime).get('hour') + 1;
        if (curr.id in acc) {
          if (acc[curr.id].values.filter(({ he }) => he === currHe).length) {
            currHe = 25;
          }
          acc[curr.id].values.push({
            he: currHe,
            value: curr.value,
          });
        } else {
          acc[curr.id] = {
            id: curr.id,
            name: curr.name,
            valueType: curr.valueType,
            marketType: curr.marketType,
            location: curr.location,
            variableType: 'HOURLY',
            visualOrder: curr.visualOrder,
            value: EMPTY_HOURLY_VALUE,
            strategies: curr.scriptName?.split(',') || undefined,
            config: {
              id: curr.id,
              name: curr.name,
              valueType: curr.valueType,
              marketType: curr.marketType,
              location: curr.location,
              strategies: curr.scriptName?.split(',') || undefined,
            },
            values: [{
              he: currHe,
              value: curr.value,
            }],
          };
        }
        return acc;
      }, {});

      Object.keys(map).forEach((item) => (tableData.push(map[item])));
    }

    // if (state.strategiesSelected.length > 0) {
    // tableData = tableData.filter((element) => state.strategiesSelected
    // .some((val) => !!element.strategies && element.strategies.indexOf(val) !== -1));
    // }

    commit('setDailyGlobalVariablesData', tableData);
  },
  async fetchScriptCategories({ commit }) {
    try {
      const { data } = await SCRIPTING_API.get('categories');
      commit('setScriptCategories', data.data);
    } catch (error) {
      console.error('Error fetching script categories', error);
    }
  },
};

const mutations = {
  changeHourlyValuesData(state, { rowIndex, prop, value }) {
    state.hourlyValuesData[0][prop] = value;
  },
  ...createMutations(
    'startDate',
    'variableDialogVisibility',
    'hourlyValuesData',
    'hourlyType',
    'hourlyValuesConfig',
    'auditData',
    'dailyGlobalVariablesData',
    'scriptCategories',
    'scripts',
    'moduleName',
    'entityType',
    'market',
    'commodity',
  ),
};

export default {
  namespaced: true,
  modules: { REFERENCE_DATA_STORE },
  state,
  getters,
  actions,
  mutations,
};
