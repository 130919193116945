import { PPA_REF_API } from '@/api';
import { clone } from '@/utils/dataUtil';
import utils from '@/utils';

const state = {
  tableKey: -999,
  currentTableRow: null,
  tableData: [],
  bankList: [],
  ppaList: [],
  ppaListOptions: [],
  bankListOptions: [],
  contactList: [],
  contactListOptions: [],
  nullTableRow: {
    id: -999,
    shortName: null,
    longName: null,
    ppaGroup: null,
    accountNumber: null,
    accountType: null,
    bankId: null,
    contactId: null,
    ieCode: null,
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    invoiceField1: null,
    invoiceField2: null,
    invoiceField3: null,
    invoiceField4: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const actions = {
  initialize({ dispatch }) {
    dispatch('fetchPpaList');
    dispatch('fetchBankList');
    dispatch('fetchContactList');
  },
  async fetchInvoiceEntities({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/invoice-entity');
      if (Array.isArray(data)) {
        commit('loadTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to fetch Invoice Entities', 'error');
    }
  },
  async fetchPpaList({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/ppa-groups');
      commit('setPpaList', data);
      commit('setPpaListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Ppa Group list', 'error');
      console.error(err);
    }
  },
  async fetchBankList({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/banks');
      commit('setBankList', data);
      commit('setBankListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Bank list', 'error');
      console.error(err);
    }
  },
  async fetchContactList({ commit }) {
    try {
      const { data: { data } } = await PPA_REF_API.get('/points-of-contact');
      commit('setContactList', data);
      commit('setContactListOptions', data);
    } catch (err) {
      this.$notify('Failed to fetch Contact list', 'error');
      console.error(err);
    }
  },
  postRecord({ state }, InvoiceEntity) {
    PPA_REF_API.post('/invoice-entity', InvoiceEntity).then(({ data }) => {
      state.tableData.push(data);
      this.$notify('Data Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Data', 'error');
    });
  },
  async updateRecord({ commit }, record) {
    try {
      const { data } = await PPA_REF_API.put(`/invoice-entity/${record.id}`, record);
      commit('updateRecord', data);
      this.$notify('Data Updated', 'success');
    } catch (err) {
      this.$notify('Failed to Update Data', 'error');
      console.error(err);
    }
  },
  async deleteRecord({ dispatch, commit, state }) {
    try {
      await PPA_REF_API.delete(`/invoice-entity/${state.currentTableRow.id}`);
      commit('deleteTableRow');
      dispatch('currentTableRowChange', clone(state.nullTableRow));
      this.$notify('Data Removed', 'success');
    } catch (err) {
      this.$notify('Failed to Remove Data', 'error');
      console.error(err);
    }
  },
  currentTableRowChange({ commit }, currentTableRow) {
    commit('currentTableRowChange', currentTableRow);
  },
  resetTable({ commit, state }) {
    commit('loadTableData', []);
    commit('currentTableRowChange', clone(state.nullTableRow));
  },
  reset({ commit }) {
    commit('currentTableRowChange', null);
    commit('loadTableData', []);
    commit('setPpaList', []);
    commit('setBankList', []);
    commit('setContactList', []);
  },
};

const mutations = {
  updateRecord(state, record) {
    const rowIndex = state.tableData.findIndex(({ id }) => id === record.id);
    state.tableData.splice(rowIndex, 1, record);
  },
  currentTableRowChange(state, currentRow) {
    state.currentTableRow = currentRow;
  },
  loadTableData(state, records) {
    state.tableData = records;
  },
  createTableRow(state) {
    const record = clone(state.nullTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  copyTableRow(state) {
    const record = clone(state.currentTableRow);
    record.id = state.tableKey++;
    record.createdBy = this.getters['auth/getProfile'].userName;
    record.createdDate = utils.date.getNow().utc().format();
    state.currentTableRow = record;
  },
  deleteTableRow(state) {
    state.tableData = state.tableData.filter((record) => record.id !== state.currentTableRow.id);
  },
  setPpaList(state, ppas) {
    state.ppaList = ppas;
  },
  setPpaListOptions(state, ppaList) {
    state.ppaListOptions = ppaList?.map(({ groupName }) => ({ value: groupName, label: groupName }));
  },
  setBankList(state, banks) {
    state.bankList = banks;
  },
  setBankListOptions(state, bankList) {
    state.bankListOptions = bankList?.map(({ id, accountName }) => ({ value: id, label: accountName }));
  },
  setContactList(state, contacts) {
    state.contactList = contacts;
  },
  setContactListOptions(state, contactList) {
    state.contactListOptions = contactList?.map(({ id, fullName }) => ({ value: id, label: fullName }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};