import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_STLMTS_API } from '@/api';
import { clone } from '@/utils/dataUtil';

const state = {
  tableData: [],
  selectedRows: [],
};

const actions = {
  async fetchBDOverrides({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      contracts: parameters.contractsSelected,
      startDate,
      endDate,
      version: parameters.overrideVersionsSelected === 'MAX' ? -1 : parameters.overrideVersionsSelected,
    };
    try {
      const { data: { data } } = await BILLING_STLMTS_API.put('/overrides/override-details?', params);
      data.forEach((x) => {
        x.tradingDate = moment(x.tradingDate).utc().format('MM/DD/YYYY');
      });
      commit('setTableData', data);
    } catch {
      this.$notify('Failed to retrieve BD Overrides', 'error');
    }
  },
  async updateSelectedBDActiveFlag({ commit, state }, activeFlag) {
    const overrideRecords = clone(state.selectedRows);
    if (overrideRecords) {
      overrideRecords.forEach((bd) => { bd.activeFlag = activeFlag; });
      const params = {
        overrides: overrideRecords,
      };
      try {
        const { data: { data } } = await BILLING_STLMTS_API.put('overrides/batch', params);
        commit('updateTable', data.overrides);
        this.$notify('Records Updated Successfully', 'success');
      } catch (error) {
        console.error(error);
        this.$notify('Records Updated Failed', 'error');
      }
    }
  },
  async addImportData({ commit, state }, fileData) {
    const importRecords = { Data: fileData };
    try {
      await BILLING_STLMTS_API.post('/overrides', importRecords);
      this.$notify('Overrides Loaded', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Load Overrides', 'error');
    }
  },
  reset({ commit }) {
    commit('setTableData', []);
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
  updateTable(state, items) {
    const tableData = clone(state.tableData);
    for (let i = 0; i < items.length; i++) {
      const item = tableData.find((x) => x.recordId === items[i].recordId && x.contractHeaderId === items[i].contractHeaderId);
      if (item) {
        item.activeFlag = items[i].activeFlag;
      }
    }
    state.tableData = tableData;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};