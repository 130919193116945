import dateStore from '@/utils/dateStore';
import { map, result } from 'lodash';
import {
  tradeDate,
  // tradeDateRange,
  coordinatorList,
  samcHeaders,
  chargeCodes,
  billDeterminants,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import { cloneDeep, has } from '@/utils/dataUtil';
import moment from 'moment';
import Vue from 'vue';
import { CISO_SAMC_API } from '@/api';
import { upsertFieldConfig } from '../samcUtil';
import old from './olddata';

// Returns new data (top node id, state data) as inputs
const recurse = (node, data, ret) => {
  for (let i = 0; i < data.length; i++) {
    // if top node id is in data append to ret
    if (node === data[i].id) {
      ret.push(data[i]);
      // if length of next is more than zero it has children
      if (data[i].next.length > 0) {
        for (let j = 0; j < data[i].next.length; j++) {
          recurse(data[i].next[j], data, ret);
        }
      }
    }
  }
  return ret;
};

// Returns new data (top node id, state data) as inputs
const oneLayer = (node, data, ret) => {
  for (let i = 0; i < data.length; i++) {
    // if top node id is in data append to ret
    if (node === data[i].id) {
      ret.push(data[i]);
      // if length of next is more than zero it has children
      if (data[i].next.length > 0) {
        for (let j = 0; j < data[i].next.length; j++) {
          for (let n = 0; n < data.length; n++) {
            if (data[n].id === data[i].next[j]) {
              ret.push(data[n]);
            }
          }
        }
      }
    }
  }
  return ret;
};

const findNodes = (node, data) => {
  // iterate through data
  let ret = [];
  ret = recurse(node, data, ret);
  return ret;
};

const findLevel = (node, data) => {
  // iterate through data
  let ret = [];
  ret = oneLayer(node, data, ret);
  return ret;
};

const checkType = (id) => {
  const flag = 'fill:#DDD,stroke:#333,stroke-width:3px';
  const factor = 'fill:#DDD,stroke:#333,stroke-width:3px';
  const quantity = 'fill:#DDD,stroke:#333,stroke-width:3px';
  const price = 'fill:#DDD,stroke:#333,stroke-width:3px';
  if (/FLAG$/.test(id)) {
    return { edgeType: 'asymetric', style: flag };
  }
  if (/QTY$/.test(id) || /QUANTITY$/.test(id)) {
    return { edgeType: 'hexagon', style: quantity };
  }
  if (/FACTOR$/.test(id)) {
    return { edgeType: 'stadium', style: factor };
  }
  if (/PRC$/.test(id) || /PRICE$/.test(id)) {
    return { edgeType: 'subroutine', style: price };
  }
  return { edgeType: 'round', style: '' };
};

const createTree = (data, toggle) => {
  const ret = [];
  data.map((d) => {
    const { edgeType, style } = checkType(d.BDName);
    let text;
    if (toggle) {
      text = d.BPMName;
    } else {
      text = d.BDName.replace('@', '%');
    }
    const node = {
      id: d.BPMName,
      text,
      link: '---',
      next: d.children,
      editable: true,
      // edgeType,
      style,
    };
    ret.push(node);
    return 1;
  });
  return ret;
};

const state = {
  rawData: [], // unprocessed data from db
  treeData: [], // vue mermaid data
  filteredTreeData: [], // filtered vue mermaid data
  breadcrumbs: [],
  searchFiltersConfig: {
    samcHeaders,
    tradeDate,
    coordinatorList,
    chargeCodes: { ...chargeCodes, searchable: true, required: true },
    billDeterminants,
  },
  toggle: false,
  levelToggle: false,
};

const actions = {
  // fetches tree data (CasioSAMC/determinant-tree)
  async fetchDeterminantTree({ commit }, params) {
    commit('rawData', []);
    commit('setTreeData', []);
    commit('setFilteredTreeData', []);
    commit('setBreadcrumbs', []);
    try {
      const apiParams = {
        SCs: params.coordinatorListSelected,
        chargeCode: params.chargeCodesSelected,
        tradingDate: params.tradeDateSelected,
        bdName: params.billDeterminantsSelected,
      };

      const response = await CISO_SAMC_API.get('/determinant-tree', {
        headers: { 'api-version': '1.0' },
        params: apiParams,
      });
      // console.log(response.data);
      // const response = old;
      const dataDict = {};
      // console.log(dataDict);
      const { data } = response.data;
      data.map((d) => {
        d.inputBPMName = d.inputBPMName.replace('@', '');
        d.outputBPMName = d.outputBPMName.replace('@', '');
        // d.inputBDName.replace('@', '');
        // d.outputBDName.replace('@', '');
        if (d.outputBPMName === 'BADailyBidSegmentFeeAmount') {
          console.log('found');
        }
        if (d.outputBPMName in dataDict) {
          if (d.outputBPMName === 'BADailyBidSegmentFeeAmount') {
            console.log('inside push child', dataDict);
          }
          dataDict[d.outputBPMName].children.push(d.inputBPMName);
        } else {
          dataDict[d.outputBPMName] = {
            BDName: d.outputBDName,
            BPMName: d.outputBPMName,
            Granularity: d.outputGranularity,
            OrderNum: d.orderNum,
            children: [],
          };
          dataDict[d.outputBPMName].children.push(d.inputBPMName);
          if (d.outputBPMName === 'BADailyBidSegmentFeeAmount') {
            console.log('creating new key val', dataDict);
          }
        }
        return 0;
      });
      const treeData = [];
      Object.keys(dataDict).forEach((key) => {
        treeData.push(dataDict[key]);
        // console.log(`${key } ${ obj[key]}`);
      });

      commit('setRawData', treeData);

      console.log(treeData);
      // const temp = treeData.slice(60, 65);
      // const tempData = temp.concat(treeData.slice(100, 103));

      const processedData = createTree(treeData, state.toggle);
      commit('setTreeData', processedData);
      commit('setFilteredTreeData', processedData);
    } catch (error) {
      this.$notify('Failed to load Determinant Details. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
  // create sub tree from main tree
  async filterTree({ commit, state }, node) {
    commit('setFilteredTreeData', []);
    const ret = findNodes(node, state.treeData);

    commit('setFilteredTreeData', ret);
  },
  resetTree({ commit, state }) {
    commit('setBreadcrumbs', []);
    commit('setFilteredTreeData', state.treeData);
  },
  // adds route to breadcrumb state
  updateBreadcrumbs({ state, commit }, payload) {
    const updateBread = state.breadcrumbs;
    updateBread.push(payload);
    commit('setBreadcrumbs', updateBread);
  },
  // updates breadcrumb state on backtrack
  removeBreadcrumbs({ state, commit }, idx) {
    console.log(idx);
    const temp = state.breadcrumbs;
    const currLen = state.breadcrumbs.length;

    for (let i = idx; i < currLen - 1; i++) {
      temp.pop();
    }
    commit('setBreadcrumbs', temp);
  },
  toggleNodeName({ state, commit }, query) {
    // commit('setToggle', toggle);
    commit('setTreeData', []);
    commit('setFilteredTreeData', []);
    const ret = createTree(state.rawData, state.toggle);
    commit('setTreeData', ret);
    if (query) {
      const filtered = findNodes(query, state.treeData);
      commit('setFilteredTreeData', filtered);
    } else {
      commit('setFilteredTreeData', ret);
    }
  },
  toggleLevel({ state, commit }, query) {
    commit('setTreeData', []);
    commit('setFilteredTreeData', []);
    const ret = createTree(state.rawData, state.toggle);
    commit('setTreeData', ret);
    if (query) {
      if (state.levelToggle) {
        const level = findLevel(query, state.treeData);
        commit('setFilteredTreeData', level);
      } else {
        const filtered = findNodes(query, state.treeData);
        commit('setFilteredTreeData', filtered);
      }
    } else {
      commit('setFilteredTreeData', ret);
    }
  },
};

const mutations = {
  setTreeData(state, treeData) {
    state.treeData = treeData;
  },
  setFilteredTreeData(state, filteredTreeData) {
    state.filteredTreeData = filteredTreeData;
  },
  setBreadcrumbs(state, bread) {
    state.breadcrumbs = bread;
  },
  setRawData(state, data) {
    state.rawData = data;
  },
  setToggle(state, toggle) {
    state.toggle = toggle;
  },
  setLevelToggle(state, levelTog) {
    state.levelToggle = levelTog;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};