import settlementsSummary from './SettlementsSummary/settlementsSummaryStore';
import chargeDetails from './SettlementsChargeDetails/settlementsChargeDetailsStore';
import determinantDetails from './DeterminantDetails/determinantDetailsStore';
import determinantDebug from './DeterminantDebug/determinantDebugStore';
import bdOverrides from './BDOverrides/bdOverridesStore';

export default {
  namespaced: true,
  modules: {
    settlementsSummary,
    chargeDetails,
    determinantDetails,
    determinantDebug,
    bdOverrides,
  },
};