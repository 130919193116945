import moment from 'moment';
import { getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_SOURCE_API } from '@/api';

const state = {
  tableData: [],
};

const actions = {
  async fetchBillingReservationData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const params = {
      contracts: parameters.contractsSelected,
      startDate,
      endDate,
      dataType: parameters.dataTypesSelected,
    };
    try {
      const { data: { data } } = await BILLING_SOURCE_API.post('/reservation/get', params);
      if (Array.isArray(data)) {
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Reservation Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};