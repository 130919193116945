import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRange } from '@/utils/dateUtil';
import { clone } from '@/utils/dataUtil';
import dateStore from '@/utils/dateStore';
import { CMS_API, STRUCTURES_API } from '@/api';

const TWENTY_MINUTES = 1;
const ONE_HOUR = 2;
const THREE_HOURS = 3;
const TOMORROW = 4;
const NEXT_WEEK = 5;

const state = {
  deliverable: null,
  deliverableTableKey: -999,
  currentOccurrence: null,
  occurrences: [],
  occurrenceDateRange: dateStore.getDefaultRange(),
  occurrencesConfig: {
    columns: [{
      prop: 'startDate',
      label: 'Start Date',
      filterable: false,
      dataType: 'datetime',
      format: 'EEE, MM/dd/yyyy, hh:mm a',
      alignment: 'left',
    }, {
      prop: 'endDate',
      label: 'End Date',
      filterable: false,
      dataType: 'datetime',
      format: 'EEE, MM/dd/yyyy, hh:mm a',
      alignment: 'left',
    }, {
      prop: 'daysRemaining', label: 'Days Remaining', filterable: true, alignment: 'left',
    }, {
      prop: 'logCount', label: 'Log Count', filterable: true, alignment: 'left',
    }, {
      prop: 'taskCompletionRatio', label: 'Tasks Completed', filterable: true, alignment: 'left',
    }, {
      prop: 'status', label: 'Status', filterable: true, alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullOccurrence: {
    startDate: null,
    endDate: null,
  },
  occurrenceLogTableKey: -999,
  currentOccurrenceLog: null,
  occurrenceLogs: [],
  occurrenceLogsConfig: {
    columns: [{
      prop: 'description', label: 'Description', filterable: true, alignment: 'left',
    }, {
      prop: 'statusType', label: 'Status Type', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullOccurrenceLog: {
    description: null,
    statusType: null,
    startDate: null,
    endDate: null,
    deliverableId: null,
  },
  deliverableTaskTableKey: -999,
  currentDeliverableTask: null,
  deliverableTasks: [],
  deliverableTasksConfig: {
    columns: [{
      prop: 'shortName', label: 'Name', filterable: true, alignment: 'left',
    }, {
      prop: 'description', label: 'Description', filterable: true, alignment: 'left',
    }, {
      prop: 'assignedUser', label: 'Assigned To', filterable: true, alignment: 'left',
    }, {
      prop: 'taskComplete', label: 'Task Completed', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      dataType: 'datetime',
      filterable: true,
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullDeliverableTask: {
    deliverableId: null,
    shortName: null,
    description: null,
    startDate: null,
    endDate: null,
    assignedUser: null,
    taskComplete: false,
  },
  reminderOptions: [{
    id: TWENTY_MINUTES, name: 'In 20 Minutes', value: 20,
  }, {
    id: ONE_HOUR, name: 'In 1 Hour', value: 60,
  }, {
    id: THREE_HOURS, name: 'In 3 Hours', value: 180,
  }, {
    id: TOMORROW, name: 'Tomorrow', value: 1440,
  }, {
    id: NEXT_WEEK, name: 'Next Week', value: 10080,
  }],
  markAsStatusOptions: [{
    label: 'Completed', value: 'Completed',
  }, {
    label: 'In Progress', value: 'In Progress',
  }, {
    label: 'Past Due', value: 'Past Due',
  }, {
    label: 'Pending', value: 'Pending',
  }],
  currentAttachment: null,
  attachmentView: 'Deliverable',
  attachmentTableData: [],
  attachmentTableConfig: {
    columns: [{
      prop: 'displayName', label: 'Name', filterable: true, alignment: 'left',
    }, {
      prop: 'fileSize', label: 'File Size', filterable: true, alignment: 'left',
    }, {
      prop: 'fileType', label: 'File Type', filterable: true, alignment: 'left',
    }, {
      prop: 'createdBy', label: 'Created By', filterable: true, alignment: 'left',
    }, {
      prop: 'createdDate',
      label: 'Created Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }, {
      prop: 'updatedBy', label: 'Updated By', filterable: true, alignment: 'left',
    }, {
      prop: 'updatedDate',
      label: 'Updated Date',
      filterable: true,
      dataType: 'datetime',
      alignment: 'left',
    }],
    options: {
      filterRow: true,
      filterHeader: true,
    },
  },
  nullAttachmentTableRow: {
    id: null,
    displayName: null,
    fileName: null,
    fileSize: null,
    fileType: null,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
  },
};

const getters = {
  getCurrentOccurrenceLog: (state) => state.currentOccurrenceLog,
  getCurrentDeliverableTask: (state) => state.currentDeliverableTask,
  getDeliverable: (state) => state.deliverable,
  getOccurrences: (state) => state.occurrences,
};

const actions = {
  async initialize({ dispatch }, params) {
    dispatch('fetchDeliverable', params);
    dispatch('lookup/fetchDeliverableTypeList', null, { root: true });
    dispatch('lookup/fetchContractCoordinatorList', null, { root: true });
    dispatch('lookup/fetchDeliverableOccurrenceLogStatusTypeList', null, { root: true });
  },
  fetchDeliverable({ commit, dispatch }, { id, startDate, endDate }) {
    CMS_API.get(`deliverables/${id}`).then(({ data }) => {
      commit('setDeliverable', data);
      commit('setInitialDateRange', { startDate, endDate });
      dispatch('fetchOccurrences');
      dispatch('loadAttachmentTableData');
    }).catch((err) => {
      this.$notify('Failed to Retrieve Deliverable Details', 'error');
    });
  },

  async fetchCoordinatorUsers({ state, rootState }) {
    const coordinators = [];
    state.deliverable.coordinator.forEach((coordinator) => {
      const contractCoordinator = rootState.lookup.contractCoordinatorList.find(({ shortName }) => shortName === coordinator);
      if (contractCoordinator) coordinators.push(contractCoordinator);
    });

    const coordinatorUsers = (await Promise.all(
      coordinators.map(({ id }) => STRUCTURES_API.get(`contract-coordinators/${id}/users`)),
    )).map(({ data }) => data)
      .flatMap(({ contractCoordinatorUsers }) => contractCoordinatorUsers);

    return [...new Set(coordinatorUsers.map(({ userName }) => userName))]
      .map((userName) => ({ value: userName, label: userName }));
  },
  resetData({ dispatch }) {
    dispatch('resetOccurrences');
    dispatch('resetOccurrenceLogs');
    dispatch('resetDeliverableTasks');
    dispatch('resetDateRange');
    dispatch('resetAttachments');
  },
  resetOccurrences({ commit }) {
    commit('resetOccurrences');
  },
  resetOccurrenceLogs({ commit }) {
    commit('resetOccurrenceLogs');
  },
  resetDeliverableTasks({ commit }) {
    commit('resetDeliverableTasks');
  },
  resetDateRange({ commit }) {
    commit('resetDateRange');
  },
  resetAttachments({ commit }) {
    commit('resetAttachments');
  },
  changeOccurrenceDateRange({ commit, dispatch }, value) {
    dispatch('resetOccurrences');
    dispatch('resetOccurrenceLogs');
    commit('changeOccurrenceDateRange', value);
    dispatch('fetchOccurrences');

    if (state.attachmentView === 'Occurrence') {
      commit('loadAttachmentTableData');
    }
  },
  fetchOccurrences({ state, commit, dispatch }) {
    const params = {
      startDate: state.occurrenceDateRange[0].format('YYYY-MM-DD'),
      endDate: state.occurrenceDateRange[1].format('YYYY-MM-DD'),
    };

    CMS_API.get(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrences`, { params }).then(({ data }) => {
      const timeZoneName = dateStore.getTimeZone();

      data.deliverableOccurrences.forEach((occurrence) => {
        occurrence.daysRemaining = moment(occurrence.endDate).diff(moment(), 'days');
        occurrence.startDate = moment(occurrence.startDate).utc();
        occurrence.endDate = moment(occurrence.endDate).utc();
        
        occurrence.startDate = dateStore.toLocalFromDate(occurrence.startDate, timeZoneName);
        occurrence.endDate = dateStore.toLocalFromDate(occurrence.endDate, timeZoneName);
        
      });
      commit('setOccurrences', data.deliverableOccurrences);
    }).catch((err) => {
      this.$notify('Failed to Load Occurrence Logs', 'error');
    });
  },
  updateDeliverableDetails({ commit, dispatch }, deliverable) {
    CMS_API.put(`/contracts/${deliverable.contractGuid}/deliverables/${deliverable.id}/details`, deliverable).then(({ data }) => {
      commit('updateDeliverableDetails', data);
      this.$notify(`${data.shortName} Updated`, 'success');
      
      dispatch('fetchOccurrences');
      dispatch('loadAttachmentTableData');
      
    }).catch((err) => {
      this.$notify(`Failed to Update ${deliverable.shortName}`, 'error');
    });
  },
  fetchOccurrenceLogs({ state, commit }) {
    const { startDate, endDate } = getMomentDateStringFromRange(state.currentOccurrence);
    const params = {
      startDate,
      endDate,
    };
    CMS_API.get(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-logs`, { params }).then(({ data }) => {
      
      const timeZoneName = dateStore.getTimeZone();
        
      data?.deliverableOccurrenceLogs?.forEach((d, idx) => {
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });
      
      commit('setOccurrenceLogs', data.deliverableOccurrenceLogs);
    }).catch((err) => {
      this.$notify('Failed to Load Occurrence Logs', 'error');
    });
  },
  postOccurrenceLog({ state, commit }, occurrenceLog) {
    CMS_API.post(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-log`, occurrenceLog).then(({ data }) => {
      commit('insertOccurrenceLogToTable', data);
      commit('currentOccurrenceLogChange', data);
      commit('updateOccurrenceLogCount');
      this.$notify('Occurrence Log Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Occurrence Log', 'error');
    });
  },
  updateOccurrenceLog({ commit }, occurrenceLog) {
    CMS_API.put(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-log`, occurrenceLog).then(({ data }) => {
      commit('updateOccurrenceLog', data);
      this.$notify('Occurrence Log Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Occurrence Log', 'error');
    });
  },
  deleteOccurrenceLog({ dispatch, commit, state }) {
    CMS_API.delete(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-log/${state.currentOccurrenceLog.id}`).then(({ data }) => {
      commit('updateOccurrenceLogCount', 'deleting');
      commit('deleteOccurrenceLog');
      dispatch('currentOccurrenceLogChange', clone(state.nullOccurrenceLog));
      this.$notify('Occurrence Log Deleted', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Occurrence Log', 'error');
    });
  },
  fetchDeliverableTasks({ state, commit }) {
    const { startDate, endDate } = getMomentDateStringFromRange(state.currentOccurrence);
    const params = {
      startDate,
      endDate,
    };

    CMS_API.get(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/tasks?`, { params }).then(({ data }) => {
      
      const timeZoneName = dateStore.getTimeZone();
        
      data?.deliverableTasks?.forEach((d, idx) => {
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });
      
      commit('setDeliverableTasks', data.deliverableTasks);
    }).catch((err) => {
      this.$notify('Failed to Load Deliverable Tasks', 'error');
    });
  },
  postDeliverableTask({ state, commit }, deliverableTask) {
    CMS_API.post(`contracts/${state.deliverable.contractGuid}/deliverables/{id}/task`, deliverableTask).then(({ data }) => {
      commit('insertDeliverableTaskToTable', data);
      commit('currentDeliverableTaskChange', data);
      commit('updateDeliverableTaskCount');
      commit('updateDeliverableTaskCompletionRatio');
      this.$notify('Deliverable Task Added', 'success');
    }).catch((err) => {
      this.$notify('Failed to Add Deliverable Task', 'error');
    });
  },
  updateDeliverableTask({ commit }, deliverableTask) {
    CMS_API.put(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/task`, deliverableTask).then(({ data }) => {
      commit('updateDeliverableTask', data);
      commit('updateDeliverableTaskCompletionCount');
      commit('updateDeliverableTaskCompletionRatio');
      this.$notify('Deliverable Task Updated', 'success');
    }).catch((err) => {
      this.$notify('Failed to Update Deliverable Task', 'error');
    });
  },
  deleteDeliverableTask({ dispatch, commit, state }) {
    CMS_API.delete(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/task/${state.currentDeliverableTask.id}`).then(({ data }) => {
      commit('updateDeliverableTaskCount', 'deleting');
      commit('deleteDeliverableTask');
      commit('updateDeliverableTaskCompletionCount');
      commit('updateDeliverableTaskCompletionRatio');
      dispatch('currentDeliverableTaskChange', clone(state.nullDeliverableTask));
      this.$notify('Deliverable Task Deleted', 'success');
    }).catch((err) => {
      this.$notify('Failed to Delete Deliverable Task', 'error');
    });
  },
  postReminder({ state }, option) {
    const request = {
      messageType: 'Reminder',
      startDate: state.currentOccurrence.startDate,
      duration: option.value,
      referenceId: state.deliverable.id,
      referenceType: 'Deliverable',
    };

    CMS_API.post(`contracts/${request.messageType}/reminder`, request).then(({ data }) => {
      this.$notify(`Reminder Set ${(option.id < 4 ? '' : 'for')} ${option.name}`, 'success');
    }).catch((err) => {
      this.$notify('Failed to Create Reminder', 'error');
    });
  },
  async postOccurrenceAttachmentMapping({ state }, attachmentId) {
    const tz = dateStore.getTimeZone();
    const momentDate = dateStore.toMomentAndZoneFromJSDate(state.currentOccurrence.startDate, tz);
    const startDate = momentDate.startOf('day').utc();

    const parameters = {
      deliverableId: state.deliverable.id,
      attachmentId,
      startDate: getMomentDateString(startDate),
    };

    try {
      await CMS_API.post(
          `contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-attachment`,
          parameters,
      );
    } catch (err) {
      this.$notify('Failed to Add Attachment to Occurrence', 'error');
      console.error(err);
    }
  },
  updateOccurrenceStatus({ commit, state }, deliverableOccurrence) {
    CMS_API.put(`contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence/${state.currentOccurrence.startDate}/status`, deliverableOccurrence).then(({ data }) => {
      commit('setOccurrenceStatus', data);
      this.$notify(`Occurrence Marked as ${deliverableOccurrence.status}`, 'success');
    }).catch((err) => {
      commit('setOccurrenceStatus', null);
      this.$notify(`Failed to Mark Occurrence as ${deliverableOccurrence.status}`, 'error');
    });
  },
  createOccurrenceLog({ commit }) {
    commit('createOccurrenceLog');
  },
  createDeliverableTask({ commit }) {
    commit('createDeliverableTask');
  },
  currentOccurrenceChange({ state, commit, dispatch }, currentRow) {
    commit('currentOccurrenceChange', currentRow);
    dispatch('fetchOccurrenceLogs');
    dispatch('fetchDeliverableTasks');
    if (state.attachmentView === 'Occurrence') dispatch('loadAttachmentTableData');
  },
  currentOccurrenceLogChange({ commit }, currentRow) {
    commit('currentOccurrenceLogChange', currentRow);
  },
  currentDeliverableTaskChange({ commit }, currentRow) {
    commit('currentDeliverableTaskChange', currentRow);
  },
  async loadAttachmentTableData({ commit, state }) {
    if (state.attachmentView === 'Deliverable') {
      STRUCTURES_API.get(`/attachments/deliverable/referenceId/${state.deliverable.id}`).then(({ data }) => {
        
        const timeZoneName = dateStore.getTimeZone();
        
        data?.forEach((d, idx) => {
          d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
          d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
        });
          
        commit('loadAttachmentTableData', data);
      }).catch((err) => {
        this.$notify('Failed to Load Attachments', 'error');
      });
    } else if (state.currentOccurrence.startDate != null) {
      const params = {
        startDate: getMomentDateString(state.currentOccurrence.startDate),
      };
      const { data } = await CMS_API.get(
        `contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-attachments`,
        { params },
      );

      const ids = data.map(({ attachmentId }) => attachmentId);
      const promises = [];

      ids.forEach((id) => {
        promises.push(STRUCTURES_API.get(`/attachments/occurrence/attachmentId/${id}`));
      });

      const attachments = await Promise.all(promises);
      const timeZoneName = dateStore.getTimeZone();
        
      data?.forEach((d, idx) => {
        d.createdDate = dateStore.toLocalFromDate(d.createdDate, timeZoneName);
        d.updatedDate = dateStore.toLocalFromDate(d.updatedDate, timeZoneName);
      });

      commit('loadAttachmentTableData', attachments.map(({ data }) => data));
    } else {
      commit('loadAttachmentTableData', []);
    }
  },
  async deleteAttachmentTableRow({ dispatch, commit, state }) {
    try {
      if (state.attachmentView === 'Occurrence') {
        await CMS_API.delete(
          `contracts/${state.deliverable.contractGuid}/deliverables/${state.deliverable.id}/occurrence-attachment/${state.currentAttachment.id}`,
        );
      }

      await STRUCTURES_API.delete(`/attachments/${state.attachmentView}/${state.currentAttachment.id}`);

      commit('deleteAttachmentTableRow');
      dispatch('currentAttachmentTableRowChange', null);

      this.$notify('Attachment Deleted', 'success');
    } catch (err) {
      this.$notify('Failed to Delete Attachment', 'error');
    }
  },
  viewAttachmentTableRow({ state }) {
    STRUCTURES_API.get(`/attachments/${state.attachmentView}/attachmentId/${state.currentAttachment.id}`).then(({ data }) => {
      // reconstruct file contents from byte array
      const byteCharacters = atob(data.fileData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // create file from blob
      const blob = new Blob([byteArray], { type: data.fileType });
      const link = document.createElement('a');
      const URL = window.URL || window.webkitURL;
      const { fileName } = data;

      link.target = '_blank';
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);

      link.click();

      // cleanup: remove element and revoke object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);

      this.$notify(`Downloaded ${data.fileName}`, 'success');
    }).catch((err) => {
      this.$notify('Attachment Failed to Download', 'error');
    });
  },
  currentAttachmentTableRowChange({ commit }, currentRow) {
    commit('currentAttachmentTableRowChange', currentRow);
  },
  attachmentViewChange({ commit, dispatch }, isAttachmentViewDeliverable) {
    const attachmentView = isAttachmentViewDeliverable ? 'Deliverable' : 'Occurrence';
    commit('setAttachmentView', attachmentView);
    commit('currentAttachmentTableRowChange', null);
    dispatch('loadAttachmentTableData');
  },
};

const mutations = {
  setDeliverable(state, deliverable) {
    deliverable.createdBy = deliverable.createdBy.toUpperCase();
    deliverable.updatedBy = deliverable.updatedBy.toUpperCase();
    state.deliverable = deliverable;
  },
  updateDeliverableDetails(state, deliverable) {
    deliverable.createdBy = deliverable.createdBy.toUpperCase();
    deliverable.updatedBy = deliverable.updatedBy.toUpperCase();
    state.deliverable = deliverable;
  },
  setInitialDateRange(state, { startDate, endDate }) {
    if (!state.deliverable.recurrenceRule) {
      startDate = state.deliverable.startDate;
      endDate = state.deliverable.endDate ?? state.deliverable.startDate;
    }

    if (startDate && endDate) {
      startDate = moment(startDate).startOf('day');
      endDate = moment(endDate).endOf('day');
    } else {
      startDate = moment(state.deliverable.startDate).startOf('month');

      if (moment(state.deliverable.startDate).isAfter(moment())) {
        endDate = moment(state.deliverable.startDate).endOf('month');
      } else {
        endDate = moment().endOf('month');
      }
    }

    state.occurrenceDateRange = [
      startDate,
      endDate.add(1, 'days'),
    ];
  },
  setOccurrences(state, occurrences) {
    state.occurrences = occurrences;
  },
  setOccurrenceLogs(state, occurrenceLogs) {
    state.occurrenceLogs = occurrenceLogs;
  },
  setDeliverableTasks(state, deliverableTasks) {
    state.deliverableTasks = deliverableTasks;
  },
  resetOccurrences(state) {
    state.occurrences = [];
    state.currentOccurrence = clone(state.nullOccurrence);
  },
  resetOccurrenceLogs(state) {
    state.occurrenceLogs = [];
    state.currentOccurrence = clone(state.nullOccurrenceLog);
  },
  resetDeliverableTasks(state) {
    state.deliverableTasks = [];
    state.currentDeliverableTask = clone(state.nullDeliverableTask);
  },
  resetDateRange(state) {
    state.occurrenceDateRange = dateStore.getDefaultRange();
  },
  resetAttachments(state) {
    state.attachmentTableData = [];
    state.currentAttachment = null;
  },
  createOccurrenceLog() {
    const occurrenceLog = clone(state.nullOccurrenceLog);
    occurrenceLog.id = ++state.occurrenceLogTableKey;
    occurrenceLog.deliverableId = state.deliverable.id;
    occurrenceLog.startDate = moment(state.currentOccurrence.startDate);
    occurrenceLog.endDate = moment(state.currentOccurrence.endDate);
    state.currentOccurrenceLog = occurrenceLog;
  },
  insertOccurrenceLogToTable(state, task) {
    state.occurrenceLogs.push(task);
  },
  createDeliverableTask() {
    const deliverableTask = clone(state.nullDeliverableTask);
    deliverableTask.id = ++state.deliverableTableKey;
    deliverableTask.deliverableId = state.deliverable.id;
    deliverableTask.startDate = moment(state.currentOccurrence.startDate);
    deliverableTask.endDate = moment(state.currentOccurrence.endDate);
    state.currentDeliverableTask = deliverableTask;
  },
  loadAttachmentTableData(state, attachments) {
    state.attachmentTableData = attachments;
    state.currentAttachment = null;
  },
  setAttachmentView(state, attachmentView) {
    state.attachmentView = attachmentView;
  },
  insertDeliverableTaskToTable(state, task) {
    state.deliverableTasks.push(task);
  },
  changeOccurrenceDateRange(state, value) {
    state.occurrenceDateRange = value.map((date) => moment(date));
  },
  updateOccurrenceLog(state, occurrenceLog) {
    const rowIndex = state.occurrenceLogs.findIndex(({ id }) => id === occurrenceLog.id);
    state.occurrenceLogs.splice(rowIndex, 1, occurrenceLog);
  },
  updateDeliverableTask(state, deliverableTask) {
    const rowIndex = state.deliverableTasks.findIndex(({ id }) => id === deliverableTask.id);
    state.deliverableTasks.splice(rowIndex, 1, deliverableTask);
  },
  deleteOccurrenceLog(state) {
    state.occurrenceLogs = state.occurrenceLogs.filter(({ id }) => id !== state.currentOccurrenceLog.id);
  },
  deleteDeliverableTask(state) {
    state.deliverableTasks = state.deliverableTasks.filter(({ id }) => id !== state.currentDeliverableTask.id);
  },
  currentOccurrenceChange(state, currentRow) {
    state.currentOccurrence = currentRow;
  },
  currentOccurrenceLogChange(state, currentRow) {
    state.currentOccurrenceLog = currentRow;
  },
  currentDeliverableTaskChange(state, currentRow) {
    state.currentDeliverableTask = currentRow;
  },
  currentAttachmentTableRowChange(state, currentRow) {
    state.currentAttachment = currentRow;
  },
  setOccurrenceStatus(state, occurrence) {
    const generatedOccurrence = state.occurrences.find(({ startDate }) => moment(startDate).format('MM/DD/YYYY') === moment(state.currentOccurrence.startDate).format('MM/DD/YYYY'));
    if (occurrence !== null) {
      generatedOccurrence.status = occurrence.status;
    }
  },
  updateOccurrenceLogCount(state, actionType = 'adding') {
    const currentOccurrence = state.occurrences.find((occurrence) => occurrence === state.currentOccurrence);
    if (actionType === 'adding') {
      ++currentOccurrence.logCount;
    } else {
      --currentOccurrence.logCount;
    }
  },
  updateDeliverableTaskCompletionCount(state) {
    const currentOccurrence = state.occurrences.find((occurrence) => occurrence === state.currentOccurrence);
    const completionCount = state.deliverableTasks.filter(({ taskComplete }) => taskComplete);

    if (completionCount && completionCount.length > 0) {
      currentOccurrence.taskCompletionCount = completionCount.length;
    } else {
      currentOccurrence.taskCompletionCount = 0;
    }
  },
  updateDeliverableTaskCount(state, actionType = 'adding') {
    const currentOccurrence = state.occurrences.find((occurrence) => occurrence === state.currentOccurrence);
    if (actionType === 'adding') {
      ++currentOccurrence.taskCount;
    } else {
      --currentOccurrence.taskCount;
    }
  },
  updateDeliverableTaskCompletionRatio(state) {
    const currentOccurrence = state.occurrences.find((occurrence) => occurrence === state.currentOccurrence);
    currentOccurrence.taskCompletionRatio = `${currentOccurrence.taskCompletionCount}/${currentOccurrence.taskCount}`;
  },
  deleteAttachmentTableRow(state) {
    state.attachmentTableData = state.attachmentTableData.filter((attachment) => {
      if (attachment.id !== state.currentAttachment.id) { return attachment; }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};