import qs from 'qs';
import { getMomentDateString } from '@/utils/dateUtil';
import { CISO_SAMC_API } from '@/api';
import {
  tradeDateRange,
  coordinatorList,
  samcHeaders,
  caisoStatementFileTypes,
  caisoStatementPublications,
  caisoStatementFileVersions,
  bdGroup,
  resources,
  caisoStatementHasCompare,
  caisoStatementCompareFileTypes,
  caisoStatementComparePublications,
  caisoStatementCompareFileVersions,
} from '@/components/Ciso/BaseStore/CisoConfigs';
import config from './gridConfig';

const state = {
  tableData: [],
  tableConfig: config.configData.fields,
  selectedCells: [],
  compareFlag: false,
  gridConfig: config,
  mapTableData: [],
  showRowTotals: true,
  searchFiltersConfig: {
    samcHeaders,
    tradeDateRange,
    coordinatorList,
    fileTypes: caisoStatementFileTypes,
    publications: caisoStatementPublications,
    fileVersions: caisoStatementFileVersions,
    bdGroups: { ...bdGroup, searchable: true, required: true },
    resourceList: { ...resources, searchable: true },
    hasCompare: caisoStatementHasCompare,
    compareFileTypes: caisoStatementCompareFileTypes,
    comparePublications: caisoStatementComparePublications,
    compareVersions: caisoStatementCompareFileVersions,
  },
  currentBd: '',
  currentBdFormula: '',
  currentBpmFormula: '',
};

const actions = {
  async fetchChargeCodeCategories({ commit }, params) {
    commit('setTableData', []);
    try {
      const apiParams = {
        SCs: params.coordinatorListSelected,
        startDate: params.tradeDateRangeSelected[0].toISOString(),
        endDate: params.tradeDateRangeSelected[1].toISOString(),
        fileType: params.fileTypesSelected,
        publication: params.publicationsSelected,
        fileVersion: params.fileVersionsSelected,
        bdGroup: params.bdGroupsSelected.join(','),
        rsrc_Id: params.resourceListSelected,
        compareFileType: params.compareFileTypesSelected,
        comparePublication: params.comparePublicationsSelected,
        compareVersion: params.compareVersionsSelected,
      };
      const { data } = await CISO_SAMC_API.get('/charge-code-categories', {
        params: apiParams,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });

      data.data.forEach((obj) => { obj.tzTradingDate = getMomentDateString(obj.tzTradingDate); });
      commit('setTableData', data.data);
      const map = await CISO_SAMC_API.get('/charge-codes/bd-mapping');
      commit('setMapData', map.data.data);
    } catch (error) {
      this.$notify('Failed to load Charge Code Categories', 'error');
    }
  },
  async fetchFormula({ dispatch, commit, state }, params) {
    try {
      const response = await CISO_SAMC_API.get('/configuration-file/formulas', {
        params,
      });

      commit('setCurrentBdFormula', `${response.data.data[0].name} = ${response.data.data[0].equation}`);
      commit('setCurrentBpmFormula', `${response.data.data[1].name} = ${response.data.data[1].equation}`);
    } catch (error) {
      this.$notify('Failed to load Formula. If the issue persists please contact PSCS support.', 'error', 5000);
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = Object.freeze(value);
  },
  setMapData(state, value) {
    state.mapTableData = value;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
  setCurrentBd(state, value) {
    state.currentBd = value;
  },
  setCurrentBdFormula(state, value) {
    state.currentBdFormula = value;
  },
  setCurrentBpmFormula(state, value) {
    state.currentBpmFormula = value;
  },
  setInitialParams(state, value) {
    state.initialParams = value;
  },
  setCompareFlag(state, value) {
    state.compareFlag = value;
  },
  setShowRowTotals(state, value) {
    state.showRowTotals = value;
  },
  setSelectedCells(state, value) {
    state.selectedCells = value;
  },
  reset(state) {
    state.tableData = [];
    state.mapTableData = [];
    state.tableConfig = [];
    state.selectedCells = [];
    state.currentBd = '';
    state.initialParams = [];
    state.compareFlag = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};