import { CISO_OASIS_API } from '@/api';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import config from './transTieReportConfigurations';

const state = {
  isLoading: false,
  pivotGridModels: [],
  chartDailyModels: [],
  chartWeeklyModels: [],
  chartMonthlyModels: [],
  tableConfig: config.fields,
};

const actions = {
  async fetchTransTieReportData({ commit, state }, searchArgs) {
    commit('clearTransTieReportData');
    const { startDate, endDate } = getMomentDateStringFromRangeArray(searchArgs.tradeDateRangeSelected);
    const params = {
      startDate,
      endDate,
      marketType: searchArgs.marketTypeListSelected,
      baa: searchArgs.balancingAreaAuthorityListSelected,
      isWeekendOnly: searchArgs.isWeekEndOnlySelected,
    };
    try {
      const { data } = await CISO_OASIS_API.get('/trans-tie-report', { params });
      if (data) {
        commit('setTransTieReportData', data);
      } else {
        this.$notify('No trans tie report data found');
      }
    } catch (error) {
      this.$notify('Failed to load trans tie report data', 'error');
    }
  },
  reset({ commit }) {
    commit('clearTransTieReportData');
  },
};

const mutations = {
  setTransTieReportData(state, value) {
    const format = 'YYYY-MM-DD';
    value.transTieReportChartDailyModels.forEach((x) => { x.tradingDate = getMomentDateString(x.tradingDate); });
    value.transTieReportChartWeeklyModels.forEach((x) => { x.tradingDate = getMomentDateString(x.tradingDate); });
    value.transTieReportPivotGridModels.forEach((x) => { x.trading_Date = getMomentDateString(x.trading_Date); });

    state.chartDailyModels = Object.freeze(value.transTieReportChartDailyModels);
    state.chartWeeklyModels = Object.freeze(value.transTieReportChartWeeklyModels);
    state.chartMonthlyModels = Object.freeze(value.transTieReportChartMonthlyModels);
    state.pivotGridModels = Object.freeze(value.transTieReportPivotGridModels);
  },
  clearTransTieReportData(state) {
    state.chartDailyModels = [];
    state.chartWeeklyModels = [];
    state.chartMonthlyModels = [];
    state.pivotGridModels = [];
  },
  setIsLoading(state, value) {
    state.isLoading = value;
  },
  setTableConfig(state, value) {
    state.tableConfig = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};