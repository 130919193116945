import dateStore from '@/utils/dateStore';
import { has } from '@/utils/dataUtil';

export default {
  computed: {
    hasDateListenerCallback() {
      return has(this, 'dateListenerCallback');
    },
  },
  beforeMount() {
    if (this.hasDateListenerCallback) {
      dateStore.addDateRefreshListener(this.dateListenerCallback);
    } else {
      throw Error('You must have a method called "dateListenerCallback" to handle date updates');
    }
  },
  beforeDestroy() {
    if (this.hasDateListenerCallback) {
      dateStore.removeDateRefreshListener(this.dateListenerCallback);
    }
  },
};