import billingMeterStore from './BillingMeter/billingMeterStore';
import billingPricesStore from './BillingPrices/billingPricesStore';
import billingSchedulesStore from './BillingSchedules/billingSchedulesStore';
import billingReservationsStore from './BillingReservations/billingReservationsStore';
import billingDeterminantDataStore from './BillingDeterminantData/billingDeterminantDataStore';
import meterDataCountsStore from './MeterDataCounts/meterDataCountsStore';

export default {
  namespaced: true,
  modules: {
    billingMeter: billingMeterStore,
    billingPrices: billingPricesStore,
    billingSchedules: billingSchedulesStore,
    billingReservations: billingReservationsStore,
    billingDeterminantData: billingDeterminantDataStore,
    meterDataCounts: meterDataCountsStore,
  },
};