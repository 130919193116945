import moment from 'moment';
import { getMomentDateString, getMomentDateStringFromRangeArray } from '@/utils/dateUtil';
import { BILLING_INVOICE_API } from '@/api';
import { contractGroupList, contractGroupInvoiceEntity, contractGroupTagBox } from '@/components/ContractBilling/BaseStore/contractsBase';
import dateUtil from '@/utils/dateStore';

const state = {
  tableData: [],
  detailsData: [],
  selectedRows: [],
  currentTableRow: null,
  editAction: 'Update',
  searchFiltersConfig: {
    dateType: {
      label: 'Date Type',
      value: 'TradingDate',
      type: 'radio',
      options: [{ value: 'TradingDate', label: 'Trade Date' }, { value: 'RunDate', label: 'CBM Calc Run Date' }],
    },
    addRange: {
      label: 'Add Additional Trade Date Range',
      value: 1,
      grouped: true,
      type: 'button',
      clickListener: (params) => { params.addRangeSelected++; },
      watchers: [{
        propertyToWatch: 'addRangeSelected',
        handler(newValue, oldValue) {
          if (newValue === 2) this.config.tradeDateRange2.visible = true;
          if (newValue === 3) this.config.tradeDateRange3.visible = true;
          if (newValue === 4) this.config.tradeDateRange4.visible = true;
          if (newValue === 5) {
            this.config.tradeDateRange5.visible = true;
            this.config.addRange.visible = false;
          }
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }, {
        propertyToWatch: 'dateTypeSelected',
        handler(newValue, oldValue) {
          if (newValue === 'RunDate') {
            this.params.addRangeSelected = 1;
            this.config.tradeDateRange2.visible = false;
            this.config.tradeDateRange3.visible = false;
            this.config.tradeDateRange4.visible = false;
            this.config.tradeDateRange5.visible = false;
            this.config.addRange.visible = false;
          }
          if (newValue === 'TradingDate') {
            this.config.addRange.visible = true;
          }
          if (this.$parent && this.$parent.$parent.instance && this.$parent.$parent.instance.NAME === 'dxPopup') {
            this.$nextTick(() => this.$parent.$parent.instance.repaint());
          }
          return true;
        },
      }],
    },
    tradeDateRange5: {
      label: 'Trade Dates',
      visible: false,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    tradeDateRange4: {
      label: 'Trade Dates',
      visible: false,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    tradeDateRange3: {
      label: 'Trade Dates',
      visible: false,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    tradeDateRange2: {
      label: 'Trade Dates',
      visible: false,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    tradeDateRange: {
      label: 'Trade Dates',
      visible: true,
      divider: true,
      value: (() => dateUtil.getDefaultRange()),
      type: 'dateRange',
    },
    contractGroupList: { ...contractGroupTagBox },
    invoiceEntityList: { ...contractGroupInvoiceEntity },
  },
};

const actions = {
  async fetchCreateInvoiceData({ commit }, parameters = {}) {
    const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRangeSelected);
    const dateRanges = [{ startDate, endDate }];
    if (parameters.addRangeSelected >= 2) {
      const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRange2Selected);
      dateRanges.push({ startDate, endDate });
    }
    if (parameters.addRangeSelected >= 3) {
      const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRange3Selected);
      dateRanges.push({ startDate, endDate });
    }
    if (parameters.addRangeSelected >= 4) {
      const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRange4Selected);
      dateRanges.push({ startDate, endDate });
    }
    if (parameters.addRangeSelected >= 5) {
      const { startDate, endDate } = getMomentDateStringFromRangeArray(parameters.tradeDateRange5Selected);
      dateRanges.push({ startDate, endDate });
    }
    const params = {
      invoiceEntities: parameters.invoiceEntityListSelected,
      startDate,
      endDate,
      dateRanges,
    };
    if (params.invoiceEntities.length === 0 && parameters.contractGroupListSelected.length > 0) {
      params.invoiceEntities = parameters.invoiceEntityList.map((i) => i.id);
    }

    let apiRoute = '/create-invoices?';
    if (parameters.dateTypeSelected === 'RunDate') apiRoute = '/create-invoices/run-date?';
    try {
      const { data: { data } } = await BILLING_INVOICE_API.put(apiRoute, params);
      if (Array.isArray(data)) {
        data.forEach((x) => {
          x.approvedDate = x.approvedDate === null ? null : getMomentDateString(x.approvedDate);
          x.createdDate = x.createdDate === null ? null : getMomentDateString(x.createdDate);
          x.invoiceDate = getMomentDateString(x.invoiceDate);
          x.dueDate = getMomentDateString(x.dueDate);
          x.updatedDate = x.updatedDate === null ? null : getMomentDateString(x.updatedDate);
          x.details.forEach((y) => {
            y.tradingDate = getMomentDateString(y.tradingDate);
          });
          if (x.interest) {
            x.interest.forEach((interestItem) => {
              x.details.push({
                invoiceId: interestItem.invoiceId,
                tradingDate: x.invoiceDate,
                chargeCodeName: interestItem.interestDescription,
                currentAmount: interestItem.interestAmount,
                netAmount: interestItem.interestAmount,
                previousAmount: 0,
                contractName: 'Interest',
                settlementCycle: 'Interest',
              });
            });
          }
        });
        commit('setTableData', data);
      }
    } catch (err) {
      console.error(err);
      this.$notify('Failed to load Invoice Data', 'error');
    }
  },
  async createInvoice({ commit, state }, record) {
    const invoices = { Data: state.selectedRows };
    try {
      await BILLING_INVOICE_API.post('/create-invoices', invoices);
      commit('resetTable');
      this.$notify('Invoices Created', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Create Invoices', 'error');
    }
  },
  async generateRecord({ state }) {
    const invoice = { Data: state.selectedRows };
    try {
      await BILLING_INVOICE_API.post('/create-invoices/generate', invoice);
      this.$notify('Data Added', 'success');
    } catch (err) {
      console.error(err);
      this.$notify('Failed to Add Data', 'error');
    }
  },
};

const mutations = {
  setTableData(state, value) {
    state.tableData = value;
  },
  resetTable(state) {
    state.tableData = [];
    state.detailsData = [];
    state.lineItemData = [];
  },
  setCurrentRow(state, currentRow) {
    state.lineItemData = [];
    state.currentTableRow = currentRow;
    state.detailsData = currentRow.details;
  },
  setSelectedRows(state, value) {
    state.selectedRows = value;
  },
  setEditAction(state, action) {
    state.editAction = action;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};